import { isEqual, isObject, transform } from 'lodash';

export function deepDiff(object, base) {
  function changes(object, base) {
    return transform(object, function (result: any, value, key) {
      if (isEqual(value, base[key])) {
        return;
      }
      if (value === '' && base[key] === null) {
        return;
      }

      if (isObject(value) && isObject(base[key])) {
        const nestedChanges = changes(value, base[key]);
        if (Object.keys(nestedChanges).length) {
          result[key] = nestedChanges;
        }
      } else {
        result[key] = value;
      }
    });
  }

  return changes(object, base);
}
