import React, { ComponentType, useState } from 'react';
import { compose } from 'redux';
import { Banner } from 'components/banner';
import { ICONS } from 'shared/components/icon';
import { Link as TextLink } from 'components/text/link';
import { Heading } from 'components/text/heading';
import calendarEventsModel, {
  CalendarEventData
} from 'data/models/entities/calendar-events';
import { withModel } from '@rexlabs/model-generator';
import { withCalendarContext } from 'view/context/calendar';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { getIsReadOnlyEvent } from 'features/calendar/utils/get-perms-event';

const NO_RECORDS_EXIST_MESSAGE =
  'This viewing is pending confirmation because there are no guests and no attached listing.';
const ONLY_GUEST_RECORDS_EXIST_MESSAGE =
  'This viewing is pending confirmation because no listing has been added.';
const ONLY_LISTING_RECORDS_EXIST_MESSAGE =
  'This viewing is pending confirmation because no guests have been added.';
const NONE_CONFIRMED_MESSAGE =
  'Guests and listing owner/s are pending confirmation.';
const ONLY_GUESTS_CONFIRMED_MESSAGE =
  'Listing owner/s are pending confirmation.';
const ONLY_LISTING_OWNERS_CONFIRMED_MESSAGE =
  'Guests are pending confirmation.';

const defaultStyles = StyleSheet({
  textLink: {
    paddingTop: PADDINGS.XXS
  }
});

const UnconfirmedAppointmentIcon = ICONS.UNCONFIRMED_APPOINTMENT as any;

interface PendingBannerProps {
  eventData: CalendarEventData;
  guests: string[];
  togglePopout: () => void;
  // Todo: Add types here - CH: https://app.clubhouse.io/rexlabs/story/60262/add-types-to-pending-banner-prop
  confirmationMessageDialog: any;
  calendarEvents: any;
  context: any;
}

function PendingBanner({
  eventData,
  guests,
  togglePopout,
  calendarEvents,
  context,
  confirmationMessageDialog
}: PendingBannerProps) {
  const s = useStyles(defaultStyles);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const getRecordsExistMessage = () => {
    const numberOfGuests = guests.length;
    const numberOfListings = eventData?.records.filter(
      (record) => record.service === 'Listings'
    ).length;

    if (numberOfGuests === 0 && numberOfListings === 0) {
      return NO_RECORDS_EXIST_MESSAGE;
    }

    if (numberOfGuests !== 0 && numberOfListings === 0) {
      return ONLY_GUEST_RECORDS_EXIST_MESSAGE;
    }

    if (numberOfGuests === 0 && numberOfListings !== 0) {
      return ONLY_LISTING_RECORDS_EXIST_MESSAGE;
    }

    return null;
  };

  const getUnconfirmedMessage = () => {
    const guestsConfirmed = eventData.guests_confirmed;
    const vendorsConfirmed = eventData.vendors_confirmed;

    if (!guestsConfirmed && !vendorsConfirmed) {
      return NONE_CONFIRMED_MESSAGE;
    }

    if (guestsConfirmed && !vendorsConfirmed) {
      return ONLY_GUESTS_CONFIRMED_MESSAGE;
    }

    if (!guestsConfirmed && vendorsConfirmed) {
      return ONLY_LISTING_OWNERS_CONFIRMED_MESSAGE;
    }

    return null;
  };

  const getConfirmationDialogParams = () => {
    const eventListingIds = eventData.records
      .map((record) => {
        if (record.service === 'Listings') {
          return record.id;
        }
      })
      .filter(Boolean);

    const guestRecords = eventData.records.filter(
      (record) => record.service === 'Contacts'
    );

    return {
      eventId: eventData.id,
      guests: guestRecords,
      listingIds: eventListingIds
    };
  };

  const markRecordsAsConfirmed = () => {
    setLoadingState(true);

    calendarEvents
      .markRecordsAsConfirmed({
        eventId: eventData.id,
        calendarId: eventData.calendar.id,
        updateRecurringEvents: !!eventData.update_recurring_events
      })
      .then(() => {
        confirmationMessageDialog.open(getConfirmationDialogParams());
        context.refresh();
        togglePopout();
      });
  };

  const bannerState = getRecordsExistMessage() || getUnconfirmedMessage();
  const isReadOnlyEvent = getIsReadOnlyEvent(eventData);

  return (
    <Banner
      light
      padding={PADDINGS.XS}
      Icon={() => <UnconfirmedAppointmentIcon width={12} height={12} />}
      isLoading={loadingState}
    >
      <Heading level={4}>{bannerState}</Heading>

      {!getRecordsExistMessage() && !isReadOnlyEvent && (
        <TextLink small onClick={markRecordsAsConfirmed} {...s('textLink')}>
          Mark all as confirmed
        </TextLink>
      )}
    </Banner>
  );
}

export default compose<ComponentType<PendingBannerProps>>(
  withCalendarContext(['refresh']),
  withModel(calendarEventsModel)
)(PendingBanner);
