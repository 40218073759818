import React, { PureComponent } from 'react';

import DefaultValue from './default';

import User from 'assets/icons/user.svg';

class UsersValue extends PureComponent {
  render() {
    const { valueIsStyled } = this.props;

    if (!valueIsStyled) {
      return <DefaultValue {...this.props} />;
    }

    return <DefaultValue {...this.props} Icon={User} />;
  }
}

export default UsersValue;
