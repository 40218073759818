import React from 'react';
import { isNumber } from 'lodash';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'utils/formatters';
import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';

export function AmountToAgentColumn({ data, value, node }) {
  const region = useRegion();
  const recordData = value || data;
  const amountValue = recordData?.net_comm_amount;

  if (recordData?.net_comm_amount_unit?.id === 'percentage') {
    return (
      <div className='ag-right-aligned-cell'>
        {isNumber(amountValue) ? `${amountValue}%` : getEmptyValue({ node })}
      </div>
    );
  }

  const symbol = region?.financial?.currency?.symbol || '$';
  return (
    <div className='ag-right-aligned-cell'>
      {isNumber(amountValue) ? (
        <>
          {symbol}
          {formatCurrency(amountValue)}
        </>
      ) : (
        getEmptyValue({ node })
      )}
    </div>
  );
}

export const commissionByAgentFields = ({
  whichWord: ww
}): ReportColumnConfig => {
  return [
    {
      headerName: 'Worksheet ID',
      field: 'worksheet.id',
      type: 'worksheetDialogColumn',
      subresource: 'worksheet'
    },
    {
      headerName: 'Agent',
      field: 'agent.name',
      subresource: 'agent',
      type: 'agentValueListColumn'
    },
    { headerName: 'Role', field: 'agent_role.text', type: 'valueListColumn' },
    {
      headerName: `Gross commission (excl ${ww('GST')})`,
      field: 'worksheet.comm_amount_net_of_tax',
      type: 'currencyColumn',
      subresource: 'worksheet'
    },
    {
      headerName: `Gross commission (incl ${ww('GST')})`,
      field: 'worksheet.comm_amount_inc_tax',
      type: 'currencyColumn',
      subresource: 'worksheet'
    },

    {
      headerName: 'Distributable commission',
      field: 'cache_gross_comm_net_deductions',
      type: 'currencyColumn'
    },
    {
      headerName: 'Agent attribution',
      field: 'gross_comm_alloc_percentage',
      // here we want right-aligned but string filter
      type: 'numericColumn',
      valueFormatter: ({ value, node }) =>
        value ? `${value}%` : getEmptyValue({ node })
    },
    {
      headerName: 'Max payable commission',
      field: 'cache_gross_comm',
      type: 'currencyColumn'
    },
    {
      headerName: 'Amount to agent',
      colId: 'net_comm_amount',
      cellRenderer: AmountToAgentColumn,
      valueFormatter: ({ value, data, node, context }) => {
        const recordData = value || data;
        return recordData?.net_comm_amount || getEmptyValue({ node, context });
      }
    },
    {
      headerName: 'Net payable commission',
      field: 'cache_final_comm',
      type: 'currencyColumn'
    }
  ];
};
