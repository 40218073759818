import React from 'react';

import { ICONS } from 'shared/components/icon';

import { ReportingFilterButton } from './index';

type ColumnsReportingFilterButtonProps = Omit<
  React.ComponentProps<typeof ReportingFilterButton>,
  'Icon'
>;

export function ColumnsReportingFilterButton({
  showArrowIcon = true,
  ...props
}: ColumnsReportingFilterButtonProps) {
  return (
    <ReportingFilterButton
      Icon={ICONS.COLUMN}
      showArrowIcon={showArrowIcon}
      {...props}
    />
  );
}
