import { documentsBlock } from '../blocks/documents-block';
import { keyDetailsBlock } from '../blocks/key-details-block';

export const projectsBlocks = {
  // Default mode
  default: [
    {
      id: 'key_details',
      label: 'Key Details',
      blocks: [keyDetailsBlock]
    },
    {
      id: 'documents',
      label: 'Documents',
      blocks: [documentsBlock]
    }
  ],

  // Financial mode
  financials: [
    {
      id: 'vpa',
      label: 'VPA',
      blocks: [{ id: 'vpa' }]
    },
    {
      id: 'invoicesAndPayments',
      label: 'Invoices & Payments',
      blocks: [{ id: 'invoicesAndPayments' }]
    },
    {
      id: 'trustAccounting',
      label: 'Trust Accounting',
      blocks: [{ id: 'trustAccounting' }]
    }
  ],

  // Invoicing mode
  invoicing: [
    {
      id: 'stages',
      label: 'Stages',
      blocks: [{ id: 'stages' }]
    },
    {
      id: 'listings',
      label: 'Listings',
      blocks: [{ id: 'listings' }]
    },
    {
      id: 'contracts',
      label: 'Current Contracts',
      blocks: [{ id: 'contracts' }]
    },
    {
      id: 'holds',
      label: 'Active Holds',
      blocks: [{ id: 'holds' }]
    }
  ]
};
