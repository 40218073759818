import { query } from '@rexlabs/model-generator';
import amlChecksModel from 'data/models/entities/contact-aml-checks';

function getCriteria(id) {
  return id
    ? [
        {
          name: 'contact_id',
          type: 'in',
          value: [id]
        }
      ]
    : [];
}

export const getAmlQuery = (getId) => query`{
  amlChecks:${amlChecksModel} (criteria: ${(props) =>
  getCriteria(getId(props))}) {
    id
    status
    check_type
    submission_errors
    updated_or_submitted_at
    updated_or_submitted_by_user
    contact
    thirdparty_connection_id
  }
}`;
