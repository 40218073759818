import React, { ChangeEvent, useState } from 'react';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import PercentageInput from 'src/view/components/input/percentage';
import { SlidingScaleTier } from '../data/types';

interface PercentageCellProps extends DefaultCellProps {
  index: number;
  prefix: string;
  name: string;
  onChange: (e: ChangeEvent, index: number) => void;
  data: SlidingScaleTier;
}

function PercentageCell({
  width,
  index,
  prefix,
  name,
  onChange,
  data,
  ...rest
}: PercentageCellProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <DefaultCell
      {...rest}
      width={width}
      value={
        <PercentageInput
          decimals={0}
          name={name}
          onChange={(e: ChangeEvent) => onChange(e, index)}
          value={data[name]}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          meta={{
            focused: isFocused,
            error: null
          }}
          width={'unset'}
        />
      }
    />
  );
}

export default PercentageCell;
