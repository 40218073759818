import React, { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';

import { ICONS } from 'shared/components/icon';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { IconValueStatusButton } from 'components/button/icon-value-status-button';

import { useSendMailMerge } from 'hooks/use-send-mail-merge';

import { getMailMergeData } from 'features/leads/utils/get-mail-merge-data';
import { getActivityStatus } from 'features/leads/utils/get-activity-status';

type ButtonProps = Pick<
  ComponentProps<typeof IconValueStatusButton>,
  'status' | 'helpText'
>;
interface SendEmailActionButtonProps extends ButtonProps {
  data: LeadsResponse;
  callback: () => any;
  activitiesList?: Activity[];
}
export function SendSmsActionButton({
  data,
  activitiesList,
  callback
}: SendEmailActionButtonProps) {
  const smsStatus = getActivityStatus('sent_sms', activitiesList);

  const mailMergeData = useMemo(() => {
    return getMailMergeData(data);
  }, [data]);

  const onSendSmsClick = useSendMailMerge({
    data: mailMergeData,
    medium: 'sms',
    callback
  });

  return (
    <IconValueStatusButton
      iconType={ICONS.SMS}
      status={smsStatus && 'Sent'}
      onClick={onSendSmsClick}
      helpText={
        smsStatus &&
        `SMS sent ${dayjs(smsStatus.system_ctime * 1000).fromNow()} by ${
          smsStatus.system_created_user.name
        }`
      }
    >
      Send SMS
    </IconValueStatusButton>
  );
}
