import React from 'react';
import { Select, DateSelect } from 'view/components/input/select';
import { FiltersConfig } from 'components/record-list-screen/types';
import { TextInput } from '@rexlabs/text-input';

export function useBillsFilters() {
  return React.useMemo<FiltersConfig>(
    () => ({
      columns: 2,
      groups: [
        [
          {
            name: 'system_total_amount',
            label: 'amount',
            Input: TextInput,
            inputProps: {
              type: 'number',
              step: 1
            }
          },
          {
            name: 'system_total_balance',
            label: 'balance',
            Input: TextInput,
            inputProps: {
              type: 'number',
              step: 1
            }
          },
          {
            name: 'system_total_paid',
            label: 'paid',
            Input: TextInput,
            inputProps: {
              type: 'number',
              step: 1
            }
          },
          {
            name: 'invoice_date',
            label: 'bill date',
            Input: DateSelect
          },
          {
            name: 'due_date',
            label: 'due date',
            Input: DateSelect
          },
          {
            name: 'reference',
            label: 'reference',
            type: 'like',
            Input: TextInput
          },
          {
            name: 'invoice_number',
            label: 'bill number',
            Input: TextInput
          },
          {
            name: 'system_invoice_status',
            label: 'status',
            type: 'in',
            Input: Select,
            inputProps: {
              multi: true,
              options: [
                { value: 'active', label: 'Active' },
                { value: 'paid', label: 'Paid' },
                { value: 'void', label: 'Void' }
              ]
            }
          }
        ]
      ]
    }),
    []
  );
}
