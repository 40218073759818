import React, { Component } from 'react';
import _ from 'lodash';
import { Popout } from 'view/components/popout';
import { DefaultView } from 'view/components/tooltip/entity';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';
import { autobind } from 'core-decorators';
import PaddingBox from 'view/components/padding-box';
import { ICONS } from 'shared/components/icon';

@autobind
class ProjectTooltipCore extends Component {
  openFeedbackDialog(toggle) {
    const { project } = this.props;

    // Note: Have to use listing as record type to create feedback
    // but then send the project stage in the data.
    const feedbackType = 'listing';

    const feedbackDialogFunc = (callback) => {
      DialogsBridge.editFeedback.openClassic({
        data: {
          project: project
        },
        mode: feedbackType,
        callback
      });
      toggle();
    };

    if (feedbackType === 'listing') {
      return feedbackDialogFunc;
    }
  }

  renderContent({ toggle }) {
    const { isLoading, project, data, id, ...rest } = this.props;
    const { image } = data;

    const recordType = _.get(data, 'recordType');
    const serviceName = _.get(data, 'serviceName');

    const heading = _.get(project, 'name');
    const secondHeading = _.get(data, 'address');

    // Note: Need to customise entity for 200x100 images - so projects and stages
    const imageURL = _.get(image, 'thumbs.200x100.url');

    return (
      <PaddingBox white>
        <DefaultView
          width={310}
          image={imageURL}
          heading={heading}
          secondHeading={secondHeading}
          isLoading={isLoading}
          ImageIcon={recordType && ICONS[recordType.toUpperCase()]}
          isLandscape
          onReminderClick={(callback) => {
            DialogsBridge.editReminder.openClassic({
              data: {
                data: { project: project }
              },
              callback
            });
            toggle();
          }}
          onNoteClick={(callback) => {
            const data = {
              _related: {
                note_projects: [
                  {
                    project: project,
                    project_id: id
                  }
                ]
              }
            };
            DialogsBridge.editNote.openClassic({
              data,
              callback
            });
            toggle();
          }}
          onFeedbackClick={this.openFeedbackDialog(toggle)}
          viewPath={`/${serviceName}/#id=${id}`}
          viewLabel={'go to project'}
          {...rest}
        />
      </PaddingBox>
    );
  }

  render() {
    return (
      <Popout {...this.props} distance={'11px'} Content={this.renderContent} />
    );
  }
}

export default ProjectTooltipCore;
