import { SelectItem } from 'types/select';
import { ValueListItem } from 'data/models/value-lists/value-list';
import React, { ComponentProps, ComponentType, useCallback } from 'react';
import { Select } from 'view/components/input/select/index';

function mapApiValueToSelectOption(
  value: ValueListItem | null | ValueListItem[]
) {
  const mapper = (v: ValueListItem) => ({ value: v.id, label: v.text });

  if (Array.isArray(value)) {
    return value.map(mapper);
  }

  return value === null || value === undefined ? null : mapper(value);
}

function mapSelectionOptionToApiValue(
  selectOption: SelectItem | null | SelectItem[]
) {
  const mapper = (v: SelectItem) => ({ id: v.value, text: v.label });

  if (Array.isArray(selectOption)) {
    return selectOption.map(mapper);
  }

  return selectOption ? mapper(selectOption) : null;
}

type APIFieldSelectProps<T extends ComponentType<any>> = {
  SelectComponent: T;
  toSelectOption: typeof mapApiValueToSelectOption;
  toApiValue: typeof mapSelectionOptionToApiValue;
  mapInitialOptions?: boolean;
  options?: any[];
} & ComponentProps<T>;

export function APIFieldSelect<T extends ComponentType<any>>({
  SelectComponent = Select,
  toSelectOption = mapApiValueToSelectOption,
  toApiValue = mapSelectionOptionToApiValue,
  options,
  mapInitialOptions,
  onChange: onChangeProp,
  ...rest
}: APIFieldSelectProps<T>) {
  const onChange = useCallback(
    (e) => {
      if (onChangeProp) {
        e.target.value = toApiValue(e.target.value);
        onChangeProp(e);
      }
    },
    [toApiValue, onChangeProp]
  );
  return (
    <SelectComponent
      {...rest}
      value={
        rest.value !== null && rest.value !== ''
          ? toSelectOption(rest.value)
          : null
      }
      options={
        mapInitialOptions && options ? options.map(toSelectOption) : options
      }
      onChange={onChange}
      valueAsObject={true}
    />
  );
}
