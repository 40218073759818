import React, { Component } from 'react';
import DefaultValue from './default';
import { ICONS } from 'shared/components/icon';

class ListingsValue extends Component {
  render() {
    const { valueIsStyled } = this.props;

    if (!valueIsStyled) {
      return <DefaultValue {...this.props} />;
    }

    return <DefaultValue {...this.props} Icon={ICONS.SELECT.VALUE.TOWN_CITY} />;
  }
}

export default ListingsValue;
