import { gql } from 'hooks/use-graphql-query';

export const ListingEpc = gql`
  fragment ListingEpc on ListingEpc {
    epc_lodgement_date
    epc_expiry_date
    epc_not_required
    epc_not_required_reason
  }
`;
export const Listing = gql`
  fragment Listing on Listing {
    id
    location {
      text
    }
    listing_category {
      text
    }
    status {
      text
    }
    price_match
    price_advertise_as
    price_rent
    price_rent_per_m2
    price_rent_period {
      text
    }
    authority_date_start
    system_publication_time
    available_from_date
    authority_date_expires
    sold_price
    leased_price
    state_value_price_rent_period {
      text
    }
    marked_as_sold_date
    marked_as_leased_date
    marked_as_withdrawn_date
    withdrawn_reason {
      text
    }
    comm_amount_percentage
    comm_structure {
      text
    }
    comm_est_amount_inc_tax
    comm_est_amount_net_of_tax
    legal_vendor_name
    inbound_unique_id
    system_ctime
    system_modtime
    property_address: property {
      system_search_key
    }
    system_created_user {
      name
    }
    record_stats(
      keys: [
        application_count
        contract_count
        inspection_count
        lead_completed
        lead_count
        lead_new_and_in_prog
        ofi_attendees_count
      ]
    ) {
      key
      value
    }
  }
`;
