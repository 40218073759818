import { useAccessibleUsers } from 'hooks/use-accessible-users';
import { useValueList } from 'hooks/use-value-list';

import accountUsersModel from 'data/models/value-lists/account-users';

import {
  getFilterHeader,
  GetFilterHeaderArgs,
  getIsAllOptions
} from 'features/custom-reporting/utils/get-filter-header';

interface GetUserFilterHeaderAgs
  extends Omit<GetFilterHeaderArgs, 'allOptions'> {
  postText?: string;
  hasViewAllPermission: boolean;
}

export function useUserFilterHeaderSettings({
  recordType,
  selectedOptions = [],
  postText,
  hasViewAllPermission
}: GetUserFilterHeaderAgs) {
  const { items: accountUsers } = useValueList(accountUsersModel);
  const allAccessibleUsers = useAccessibleUsers({ hasViewAllPermission });

  const hasAccessToAllUsers = allAccessibleUsers.length === accountUsers.length;
  const isPartOfGroup = allAccessibleUsers.length !== accountUsers.length;

  const isAllOptions = getIsAllOptions({
    allOptions: allAccessibleUsers,
    selectedOptions
  });

  /**
   * Note: When we send our request to BE for 'All Users', if we have access to all users,
   * we send an empty array. If we have restricted access, we send all the users we
   * have access to.
   * But this looks a little crazy on the FE, as we don't really want to show a multi select
   * with all the options in it.
   * This functions either returns
   *   - an empty array - if all or no users selected
   *   - a list of users - if some users are selected, or the current user is 'can view own' and not in a group
   */
  const visibleSelectedUsers =
    allAccessibleUsers?.length === 1
      ? allAccessibleUsers
      : allAccessibleUsers?.length === selectedOptions.length
      ? []
      : selectedOptions;

  const filterHeader = `${getFilterHeader({
    recordType,
    selectedOptions,
    allOptions: allAccessibleUsers
  })}${isAllOptions && isPartOfGroup ? ' in Your Group' : ''}${
    !isAllOptions && postText ? ` ${postText}` : ''
  }`;

  return {
    filterHeader,
    visibleSelectedUsers,
    allAccessibleUsers,
    hasAccessToAllUsers
  };
}
