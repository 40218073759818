import { useEffect } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import uiModel from 'data/models/custom/ui';

export function useListLoadingIndicator(status: string) {
  // TODO: Once the UI Model is typed, we should be able to remove any type
  // https://app.shortcut.com/rexlabs/story/64595/type-ui-model
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  useEffect(() => {
    // set loading indicator when list is loading or refreshing
    if (status === 'loading') {
      loadingIndicatorOn({ message: 'Loading data...' });
    } else if (status === 'fetching') {
      loadingIndicatorOn({ message: 'Loading page data...' });
    } else if (status === 'refreshing') {
      loadingIndicatorOn({ message: 'Refreshing data...' });
    } else {
      loadingIndicatorOff();
    }
  }, [loadingIndicatorOff, loadingIndicatorOn, status]);
}
