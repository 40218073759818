export const oabFields = () => [
  {
    headerName: 'OAB Date',
    field: 'oab_date',
    type: 'dateColumn',
    subresource: 'oab'
  },
  {
    headerName: 'OAB Type',
    field: 'board_type.text',
    subresource: 'oab'
  },
  {
    headerName: 'OAB Agency',
    field: 'agency.text',
    subresource: 'oab'
  },
  {
    headerName: 'OAB Price',
    field: 'price',
    type: 'currencyColumn',
    subresource: 'oab'
  },
  {
    headerName: 'First Price',
    field: 'first_price',
    type: 'currencyColumn',
    subresource: 'oab'
  },
  {
    headerName: 'First Seen On',
    field: 'first_seen_date',
    type: 'dateColumn',
    subresource: 'oab'
  },
  {
    headerName: 'Web Link',
    field: 'web_link',
    subresource: 'oab'
  }
];
