import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import { Body } from 'components/text/body';
import Requires from 'view/containers/requires';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    paddingLeft: '60px',
    pointerEvents: 'none'
  },

  mainList: {},

  fixedList: {
    position: 'absolute',
    top: 0
  },

  item: {
    transition: '0.15s ease-out !important',
    transitionProperty: 'opacity, transform !important',
    transformOrigin: 'center left',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '25px',
    '@media (min-height: 685px)': {
      paddingLeft: '10px'
    },
    '@media (min-height: 795px)': {
      height: '60px'
    },
    paddingTop: '2px',
    position: 'fixed'
  },

  tooltip: {
    padding: '9px 13px',
    backgroundColor: '#191A1A',
    color: '#fff',
    boxShadow: '0 1px 4px rgba(0,0,0, 0.6)',
    display: 'block',
    marginLeft: '5px',
    borderRadius: '1px'
  },

  tooltipArrow: {
    width: '11px',
    height: '11px',
    background: '#191A1A',
    boxShadow: '0 0 5px -1px rgba(0,0,0, 0.6)',
    position: 'absolute',
    transform: 'rotateZ(45deg)'
  }
});

@styled(defaultStyles)
@autobind
class MenuTooltips extends PureComponent {
  static propTypes = {
    fixedItems: types.array,
    mainItems: types.array,
    activeHoverId: types.string
  };

  // Avoid excessive getBoundingClientRect calls
  getPositionForItemId = _.memoize(
    (id) => {
      const mainListItem = document.getElementById(`menu-list-item-${id}`);
      return mainListItem ? mainListItem.getBoundingClientRect().y : undefined;
    },
    (id, windowHeight, listWrapperScroll, YPosition) =>
      `${id}-${windowHeight}-${listWrapperScroll}-${YPosition}`
  );

  renderItem(item) {
    const { activeHoverId, styles: s } = this.props;
    const mainListItem = document.getElementById(`menu-list-item-${item.id}`);
    const menuListWrapper = document.getElementById('menu-list-wrapper');

    const top = mainListItem
      ? this.getPositionForItemId(
          item.id,
          window.innerHeight,
          menuListWrapper ? menuListWrapper.scrollTop : 0,
          document
            .getElementById(`menu-list-item-${item.id}`)
            .getBoundingClientRect().y
        )
      : undefined;

    if (!item.name) return null;

    return (
      <Requires
        key={`tooltip-${item.id}`}
        accessRights={item.accessRights}
        countries={item.countries}
        withFlags={item.withFlags}
        withoutFlags={item.withoutFlags}
      >
        <li
          key={item.id}
          {...s('item')}
          style={{
            opacity: activeHoverId === item.id ? 1 : 0,
            transform: activeHoverId === item.id ? 'scale(1)' : 'scale(0.97)',
            transition: activeHoverId === item.id ? 'none' : undefined,
            top
          }}
        >
          <div {...s('tooltipArrow')} />
          <span {...s('tooltip')}>
            <Body normal white>
              {item.name}
            </Body>
          </span>
        </li>
      </Requires>
    );
  }

  render() {
    const { mainItems, fixedItems, styles: s } = this.props;

    return (
      <div {...s('container')}>
        <ul {...s('mainList')}>{mainItems.map(this.renderItem)}</ul>
        <ul {...s('fixedList')}>
          {fixedItems.map(this.renderItem)}
          {this.renderItem({
            id: 'toggle',
            name: 'Expand Menu'
          })}
        </ul>
      </div>
    );
  }
}

export default MenuTooltips;
