import React from 'react';

import Box, { BoxProps } from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PADDINGS, COLORS } from 'theme';

// Checked in the vivid repo and they're also having issues with this
// and they 'fixed' it using this approach
// Remove this when we update to latest Box.
// PR - https://github.com/rexlabsio/rex-app/pull/2979
type AdjustedBoxProps = Omit<BoxProps, 'ref'>;

export interface ScreenProps extends Pick<AdjustedBoxProps, 'flexDirection'> {
  roomForIntercom?: boolean;
  minWidth?: string | number;
  maxWidth?: string | number;
  withPadding?: boolean;
  background?: string;
  children: React.ReactNode;
}

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    color: COLORS.PRIMARY.GREY_DARK,
    position: 'relative',
    height: '100%'
  },

  innerContainer: {
    height: '100%',
    overflow: 'auto'
  },

  withPadding: {
    padding: PADDINGS.XL,

    '@media (max-height: 1000px)': {
      padding: `${PADDINGS.L} ${PADDINGS.L} 0`
    }
  },

  roomForIntercom: {
    paddingBottom: '100px',

    '@media (max-height: 1000px)': {
      paddingBottom: `100px`
    }
  }
});

export function Screen({
  children,
  roomForIntercom = false,
  withPadding = true,
  minWidth = '960px',
  maxWidth,
  background,
  flexDirection = 'column',
  ...props
}: ScreenProps & AdjustedBoxProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box flexDirection='column' {...s.with('container')({ background })}>
      <Box
        data-testid='screen-inner-container'
        {...s.with('innerContainer', {
          withPadding,
          roomForIntercom
        })({
          minWidth,
          maxWidth
        })}
        flexDirection={flexDirection}
        {...props}
      >
        {children}
      </Box>
    </Box>
  );
}
