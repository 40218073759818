import { PrivacySettingsItem } from './contact-privacy';

/**
 * NOTE: Copy this file to shell/src/data/models/custom/privacy/mock-privacy-settings-response.ts
 * Once you've done that, you can edit the mock data in that file, to test the feature on contact
 * records. It should be fully typed, so it will show you errors if you make a mistake.
 * Once feature has been released, we can delete this file.
 */
export const mockPrivacySettingsWingsResponse: PrivacySettingsItem = {
  match_campaigns: {
    consent_given: true,
    communication_methods: {
      letter: {
        consent_given: false,
        action: null
      },
      email: {
        consent_given: false,
        action: null
      },
      sms: {
        consent_given: false,
        action: null
      },
      phone: {
        consent_given: false,
        action: null
      }
    },
    action: null
  },
  single_merge: {
    consent_given: false,
    communication_methods: {
      letter: {
        consent_given: false,
        action: {
          id: 'block',
          text: 'Block'
        }
      },
      email: {
        consent_given: false,
        action: {
          id: 'block',
          text: 'Block'
        }
      },
      sms: {
        consent_given: false,
        action: {
          id: 'block',
          text: 'Block'
        }
      },
      phone: {
        consent_given: false,
        action: null
      }
    },
    action: {
      id: 'block',
      text: 'Block'
    }
  },
  bulk_merge: {
    consent_given: true,
    communication_methods: {
      letter: {
        consent_given: false,
        action: null
      },
      email: {
        consent_given: false,
        action: null
      },
      sms: {
        consent_given: false,
        action: null
      },
      phone: {
        consent_given: false,
        action: null
      }
    },
    action: null
  },
  newsletters: {
    consent_given: true,
    communication_methods: {
      letter: {
        consent_given: false,
        action: null
      },
      email: {
        consent_given: false,
        action: null
      },
      sms: {
        consent_given: false,
        action: null
      },
      phone: {
        consent_given: false,
        action: null
      }
    },
    action: null
  },
  phone_calls: {
    consent_given: false,
    communication_methods: {
      letter: {
        consent_given: false,
        action: null
      },
      email: {
        consent_given: false,
        action: null
      },
      sms: {
        consent_given: false,
        action: null
      },
      phone: {
        consent_given: false,
        action: {
          id: 'warn',
          text: 'Warn'
        }
      }
    },
    action: {
      id: 'block',
      text: 'Block'
    }
  }
};
