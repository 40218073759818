import React, { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';

import { ICONS } from 'shared/components/icon';

import { IconValueStatusButton } from 'components/button/icon-value-status-button';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { useAddReminder } from 'hooks/use-add-reminder';

import { getActivityStatus } from 'features/leads/utils/get-activity-status';
import { getReminderData } from 'features/leads/utils/get-reminder-data';
import { ErrorDialog, withErrorDialog } from 'src/hocs/with-error-dialog';

type ButtonProps = Pick<
  ComponentProps<typeof IconValueStatusButton>,
  'status' | 'helpText'
>;

interface AddReminderActionButtonProps extends ButtonProps {
  data: LeadsResponse;
  callback: () => void;
  activitiesList?: Activity[];
  errorDialog: ErrorDialog;
}

function AddReminderActionButton({
  data,
  activitiesList,
  callback,
  errorDialog
}: AddReminderActionButtonProps) {
  const appointmentStatus = getActivityStatus('reminder_added', activitiesList);
  const reminderData = useMemo(() => {
    return getReminderData(data);
  }, [data]);

  const onAddReminderClick = useAddReminder({
    reminderData,
    callback,
    errorDialog
  });

  return (
    <IconValueStatusButton
      iconType={ICONS.REMINDER}
      onClick={onAddReminderClick}
      status={appointmentStatus && 'Added'}
      helpText={
        appointmentStatus &&
        `Reminder added ${dayjs(
          appointmentStatus.system_ctime * 1000
        ).fromNow()} by ${appointmentStatus.system_created_user.name}`
      }
    >
      Add Reminder
    </IconValueStatusButton>
  );
}

export default withErrorDialog(AddReminderActionButton);
