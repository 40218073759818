import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useModelActions } from '@rexlabs/model-generator';

import { ICONS } from 'shared/components/icon';

import projectsModel from 'data/models/entities/projects';
import { Container } from 'components/_experiment/record-title-block/container';
import { Heading } from 'components/_experiment/record-title-block/heading';
import { SubHeading } from 'components/_experiment/record-title-block/sub-heading';
import PaddingBox from 'view/components/padding-box';
import withClassicDialog from 'view/containers/with-classic-dialog';

const ProjectSvg: any = ICONS.PROJECT;

const defaultStyles = StyleSheet({
  container: {
    maxWidth: '640px'
  },

  thumbnail: {
    width: '200px',
    height: '200px',
    background: '#B4B1A9'
  },

  defaultIcon: {
    height: '80px',
    width: 'auto',
    opacity: 0.1
  },

  estateLogo: {
    width: '160px',
    height: '80px',
    objectFit: 'contain'
  },

  developerText: {
    marginTop: '20px'
  },

  developerLogo: {
    width: '160px',
    height: '80px',
    objectFit: 'contain'
  }
});

export const ProjectTitleBlock = withClassicDialog('editProject')(
  ({ data, editProject }) => {
    const s = useStyles(defaultStyles);
    const { refreshItem } = useModelActions(projectsModel);
    return (
      <Container padding={0} flexDirection='row' {...s('container')}>
        <Box
          {...s.with('thumbnail')({ backgroundColor: data?.brand_color })}
          flexShrink={0}
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          {data?.estate_logo?.uri ? (
            <img src={data?.estate_logo?.uri} {...s('estateLogo')} />
          ) : (
            <ProjectSvg {...s('defaultIcon')} />
          )}

          {!!data?.developer_logo?.uri && (
            <>
              <span {...s('developerText')}>developed by</span>
              <img
                src={data?.developer_logo?.uri}
                alt={data?.developer_name}
                {...s('developerLogo')}
              />
            </>
          )}
        </Box>
        <PaddingBox flex={1} flexDirection='column' alignItems='flex-start'>
          <Heading
            onEdit={() =>
              editProject.open({
                id: data.id,
                callback: () => refreshItem({ id: data.id })
              })
            }
          >
            {data?.name}
          </Heading>
          {data?.adr_suburb_or_town && (
            <SubHeading>
              {data?.adr_suburb_or_town} {data?.adr_postcode}
            </SubHeading>
          )}
        </PaddingBox>
      </Container>
    );
  }
);
