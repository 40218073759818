import { useMemo } from 'react';

import { useReportingDateValues } from 'features/custom-reporting/hooks/use-reporting-date-values';

import { useFilterConfigItem } from 'features/custom-reporting/utils/get-filter-field-config';
import { UserSelectedReportState } from 'features/custom-reporting/hooks/use-user-selected-report-state';

function getDateCriteria({ name, start, end }) {
  return [
    { name, type: '>=', value: start },
    { name, type: '<=', value: end }
  ];
}

export function useDateCriteria({
  fieldPreference,
  shouldFilterByDate,
  userSelectedReportState
}: {
  moduleName: string;
  fieldPreference?: string;
  shouldFilterByDate?: boolean;
  userSelectedReportState?: UserSelectedReportState;
}) {
  const dateRangeOption = userSelectedReportState?.selectedDateFilter;

  // translate date option (e.g. previous month) into values
  const reportingDateValues = useReportingDateValues(dateRangeOption);

  const { getCriteria = getDateCriteria, fieldName } =
    useFilterConfigItem({
      filterType: 'date',
      fieldPreference
    }) || {};

  const criteria = useMemo(() => {
    // Note: The shouldFilterByDate prop indicates whether the date filter has a none value or not.
    // It’ll often be undefined if it’s not none and false if it is.
    if (shouldFilterByDate === false || dateRangeOption?.preset === 'none') {
      return [];
    }

    return getCriteria({
      name: fieldName,
      start: reportingDateValues?.start,
      end: reportingDateValues?.end
    });
  }, [
    shouldFilterByDate,
    dateRangeOption?.preset,
    getCriteria,
    fieldName,
    reportingDateValues?.start,
    reportingDateValues?.end
  ]);

  return criteria;
}
