import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import React from 'react';
import Checkbox from 'src/view/components/input/checkbox';

interface ListingChattelFieldProps {
  label: string;
  name: string;
  checked: boolean;
  count: string | null;
  noCount?: boolean;
  onCheckboxChange: (name: string, checked: boolean) => void;
  onCountChange: (name: string, value: string) => void;
}

const ListingChattelField = ({
  label,
  name,
  noCount,
  onCheckboxChange,
  onCountChange,
  count,
  checked
}: ListingChattelFieldProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(name, event.target.checked);
  };

  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCountChange(name, event.target.value);
  };
  return (
    <Box justifyContent='space-between' alignItems='center'>
      <Checkbox label={label} value={checked} onChange={handleCheckboxChange} />
      {!noCount && (
        <Box w={60}>
          <TextInput
            type='number'
            step={1}
            value={count}
            inputMode='numeric'
            disabled={!checked}
            onChange={handleCountChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default ListingChattelField;
