import { mapContactToSelectValue } from 'features/listings/dialogs/view-edit-offer/utils';
import {
  PurchTenantSelectValue,
  ContractFormValues
} from 'features/listings/dialogs/view-edit-offer/types';
import { ContractsItem } from 'features/listings/data/contracts/types';

export function mapContractDataToFormValues({
  contractData,
  customTabs
}: {
  contractData: ContractsItem;
  customTabs: any;
}): ContractFormValues {
  const {
    agent,
    chattels,
    date_expec_settlement,
    date_expec_unconditional,
    detail_deposit_full_or_prepayment,
    detail_finance_lender_contact,
    disclosable_interest_exists,
    disclosable_interest_note,
    purchtenant_legal_name,
    purchtenant_position,
    purchtenant_residence,
    purchtenant_solicitor,
    purchtenant_solicitor_contact,
    related,
    stability_indicator
  } = contractData;

  const purchtenants = related.listing_contract_purchtenants || [];

  const customFields = {};

  Object.keys(customTabs.values).forEach((key) => {
    customFields[key] = customTabs.values[key] || '';
  });

  const formValues = {
    date_expec_settlement: date_expec_settlement || '',
    date_expec_unconditional: date_expec_unconditional || '',
    detail_deposit_full_or_prepayment: detail_deposit_full_or_prepayment || '',
    disclosable_interest_exists: disclosable_interest_exists || '',
    disclosable_interest_note: disclosable_interest_note || '',
    purchtenant_legal_name: purchtenant_legal_name || '',
    purchtenant_residence: purchtenant_residence || '',

    // Key Details
    agent_id: agent?.id || '',

    // Purchaser Details
    purchtenants: purchtenants.map(({ purchtenant, id }) => {
      const contact = mapContactToSelectValue(purchtenant);
      const contactWithRelationID: PurchTenantSelectValue = {
        ...contact,
        relationId: id
      };
      return contactWithRelationID;
    }),
    listing_contract_conditions: related?.listing_contract_conditions || [],
    listing_contract_documents: related?.listing_contract_documents || [],

    // Fittings and Fixtures
    chattels: chattels || '',

    purchtenant_position_id: purchtenant_position?.id || '',
    purchtenant_solicitor: purchtenant_solicitor
      ? mapContactToSelectValue(purchtenant_solicitor)
      : '',
    purchtenant_solicitor_contact: purchtenant_solicitor_contact
      ? mapContactToSelectValue(purchtenant_solicitor_contact)
      : '',
    detail_finance_lender_contact: detail_finance_lender_contact
      ? mapContactToSelectValue(detail_finance_lender_contact)
      : '',
    stability_indicator_id: stability_indicator?.id || '',
    customFields
  };

  return formValues;
}
