/*
|-------------------------------------------------------------------------------
| Menu Recents
|-------------------------------------------------------------------------------
|
| The following component handles rendering the loading-in state, result options,
| empty search and an invalid search.
|
| Actions:
|  - renderAssistant
|  - renderLoadingInStates
|  - renderEntities
|
| Helpers:
|  - getOptionProps
|
*/

import _ from 'lodash';
import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { selectController } from '@rexlabs/select-input';
import { VIEW_MODES } from '../utils';

@selectController
@styled(
  StyleSheet({
    container: {
      position: 'absolute',
      background: 'white',
      top: '0',
      right: '0',
      left: '0',
      height: '100%'
    },
    relativeContainer: {
      background: 'white',
      height: '100%'
    },
    quickFilterContainer: {},
    quickFilterButton: {
      display: 'inline',
      backgroundColor: '#F1F1F1',
      borderRadius: '10px',
      padding: '5px'
    },
    primary: {
      display: 'block'
    },
    secondary: {
      display: 'block'
    },
    wrapper: {}
  })
)
@autobind
class MenuEntities extends PureComponent {
  getShowLoadingInState() {
    const { showLoadingInState, select } = this.props;
    const {
      state: { isLoading }
    } = select;
    return showLoadingInState || isLoading;
  }

  getIsScoped() {
    const { viewMode } = this.props;
    return viewMode !== VIEW_MODES.GLOBAL_SEARCH;
  }

  EmptySearch = (props) => {
    const { emptySearch: EmptyComponent } = this.props;
    const { value } = props;

    return (
      <Box>
        {this.getIsScoped() && this.renderSectionBack()}
        {EmptyComponent && <EmptyComponent {...props} />}
        {!EmptyComponent && (
          <Box>{`We couldn't find a result for ${value}`}</Box>
        )}
      </Box>
    );
  };

  render() {
    const { EmptySearch } = this;
    const { styles: s, select, searchTerm, partiallyLoading } = this.props;
    const {
      state: { value, options }
    } = select;
    // NOTE: Only show when user has entered a single character so we can show recent entries more often
    const isInvalidQuery = _.get(value, 'length', 0) === 1;

    const children = this.getShowLoadingInState() ? (
      this.doRenderLoadingInState()
    ) : options.length || partiallyLoading ? (
      this.renderEntities()
    ) : (
      <EmptySearch
        key='empty'
        value={searchTerm}
        isScoped={this.getIsScoped()}
      />
    );

    return (
      <Fragment key={'loaded'}>
        <div
          {...s(
            'container',
            'primaryContainer',
            !this.getShowLoadingInState() && !isInvalidQuery && 'loaded'
          )}
        >
          {children}
        </div>
      </Fragment>
    );
  }

  doRenderLoadingInState() {
    const {
      styles: s,
      select,
      renderLoadingInState,
      canQuickFilter
    } = this.props;
    const {
      state: { options }
    } = select;

    return (
      <span {...s('container', 'wrapper', 'loadingIn')} key='loading'>
        {[
          canQuickFilter && this.renderQuickFilters(),
          this.getIsScoped() && this.renderSectionBack(),
          (!options.length || this.getShowLoadingInState()) &&
            renderLoadingInState()
        ].filter(Boolean)}
      </span>
    );
  }

  renderEntities() {
    const {
      styles: s,
      select,
      renderOptions,
      viewMode,
      styledClass
    } = this.props;
    const {
      state: { options }
    } = select;
    const optionsProps = this.getOptionProps();

    return (
      <span
        {...s('container', 'entities', `${styledClass}`)}
        key={`${viewMode.toLowerCase()}-loaded`}
      >
        {renderOptions({ options: options, ...optionsProps }, viewMode)}
      </span>
    );
  }

  renderQuickFilters() {
    const { styles: s, quickFilterTypes, searchTerm } = this.props;
    const currentFilters = quickFilterTypes.filter(
      (type) => type.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );

    if (!currentFilters.length) {
      return null;
    }

    return (
      <div {...s('quickFilterContainer')}>
        Filter:{' '}
        {currentFilters.map((type) => (
          <div key={type} {...s('quickFilterButton')}>
            {_.capitalize(type)}
          </div>
        ))}
      </div>
    );
  }

  renderSectionBack() {
    const { SectionBack, handleBack, viewMode, shouldShowSectionBack } =
      this.props;
    if (!SectionBack || !shouldShowSectionBack) {
      return null;
    }

    return (
      <SectionBack key={`section-back-${viewMode}`} onClick={handleBack}>
        {viewMode}
      </SectionBack>
    );
  }

  getOptionProps() {
    const { select, onHover, onSelect, scrollIntoView } = this.props;
    const {
      state: { index, value }
    } = select;
    return {
      activeIndex: index,
      term: value,
      onHover: onHover,
      onSelect: onSelect,
      scrollIntoView: scrollIntoView
    };
  }
}

export default MenuEntities;
