import { useCallback } from 'react';

import { push } from '@rexlabs/whereabouts';

import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';

export function useRecordNavigation() {
  const whereabouts = useWhereaboutsWithViewpath();
  const { hashQuery, path, query } = whereabouts;

  // eslint-disable-next-line
  // @ts-ignore
  const allIdsFromState: id[] = whereabouts.state?.fromListIds || [];
  const currentId = hashQuery?.id;
  const currIndex =
    allIdsFromState?.findIndex?.((i) => `${i}` === `${currentId}`) ?? -1;

  const goToPrev = useCallback(() => {
    if (currIndex > 0) {
      push(
        {
          config: {
            path,
            query,
            hash: undefined,
            hashQuery: { ...hashQuery, id: allIdsFromState[currIndex - 1] }
          }
        },
        { state: { fromListIds: allIdsFromState } }
      );
    }
  }, [allIdsFromState, currIndex, hashQuery, path, query]);

  const goToNext = useCallback(() => {
    if (currIndex < allIdsFromState.length - 1) {
      push(
        {
          config: {
            path,
            query,
            hash: undefined,
            hashQuery: { ...hashQuery, id: allIdsFromState[currIndex + 1] }
          }
        },
        { state: { fromListIds: allIdsFromState } }
      );
    }
  }, [allIdsFromState, currIndex, hashQuery, path, query]);

  const goToList = useCallback(() => {
    // TODO: determine page based on current index and page size stored
    // in local storage
    push({
      config: {
        path,
        query,
        hash: undefined,
        // eslint-disable-next-line
        // @ts-ignore
        hashQuery: { ...hashQuery, id: undefined }
      }
    });
  }, [hashQuery, path, query]);

  const goToRecord = useCallback(
    ({ id, allIds = [] }: { id: string; allIds?: string[] }) => {
      push(
        {
          config: {
            path,
            query,
            hash: undefined,
            hashQuery: {
              ...hashQuery,
              id: id
            }
          }
        },
        { state: { fromListIds: allIds } }
      );
    },
    [hashQuery, path, query]
  );

  return {
    allIds: allIdsFromState,
    currIndex,
    currentId,
    goToPrev,
    goToNext,
    goToList,
    goToRecord
  };
}
