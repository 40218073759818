import React, { ComponentType } from 'react';
import { compose } from 'redux';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, StylesProps } from '@rexlabs/styling';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';

import session, { SessionModel } from 'data/models/custom/session';

import { createKey } from 'shared/utils/react';

import Requires from 'view/containers/requires';

const defaultStyles = StyleSheet({
  container: {
    width: 'inherit',
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.75)',
    minHeight: '50px',
    fontSize: '14px',
    lineHeight: '22px',
    cursor: 'pointer',

    '& svg': {
      marginRight: '15px',
      marginLeft: '20px',

      width: '15px',
      height: '15px'
    },

    '&:hover': {
      backgroundColor: '#F7F8F9',
      color: 'black'
    }
  },
  label: {
    paddingLeft: '20px'
  },
  badge: {
    textAlign: 'center',
    marginRight: '20px',
    padding: '0 7.5px',
    backgroundColor: '#29a2e2',
    color: 'white',
    borderRadius: '85px',
    minWidth: '23px'
  },
  separator: {
    height: '1px',
    backgroundColor: '#E1E1E1',
    margin: '13px 20px',
    opacity: '0.5'
  }
});

interface MenuConfig {
  name: string;
  count?: number;
  href?: string;
  accessRights?: any;
  isSeparator?: boolean;
  envConfigs?: string;
  countries?: any;
  withFlags?: string[];
  withoutFlags?: string[];
}
interface NotificationsComponent {
  MENU_CONFIG: MenuConfig[];
}

interface NotificationItem {
  failedMessages?: number;
  myLeads?: number;
  othersLeads?: number;
  failedScheduledMessages?: number;
  teamLeads?: number;
  totalLeads?: number;
  totalNotifications?: number;
  unassignedLeads?: number;
  workflowsAwaitingInput?: number;
}

interface NotificationsComponentProps extends NotificationItem {
  closePopout?: () => void;
  session?: SessionModel;
}

type NotificationsComponentType = NotificationsComponentProps &
  StylesProps<typeof defaultStyles>;

@autobind
class NotificationsComponent extends React.Component<NotificationsComponentType> {
  constructor(props: NotificationsComponentType) {
    super(props);
    const { session } = props;
    const userId = session && session?.user_details?.id;
    this.MENU_CONFIG = [
      {
        name: 'Workflows Awaiting Input',
        count: props.workflowsAwaitingInput,
        href: '/workflows/#lens=awaiting',
        accessRights: 'addon.workflows || addon.system_workflows'
      },
      {
        isSeparator: true,
        name: 'Separator 1',
        accessRights: 'addon.workflows || addon.system_workflows'
      },
      {
        name: 'My Leads',
        count: props.myLeads,
        href: '/leads/#lens=me',
        accessRights: 'addon.leads'
      },
      {
        name: 'Team Leads',
        count: props.teamLeads,
        href: '/leads/#lens=team',
        accessRights: 'addon.leads'
      },
      {
        name: 'Unassigned Leads',
        count: props.unassignedLeads,
        href: '/leads/#lens=unassigned',
        accessRights: 'addon.leads'
      },
      {
        name: 'All Leads',
        count: props.totalLeads,
        href: '/leads/#lens=all',
        accessRights: 'addon.leads'
      },
      {
        isSeparator: true,
        name: 'Separator 1',
        envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
      },
      {
        name: 'Scheduled Messages',
        href: `/scheduled-messages#filters=[["system_send_status_id","pending"],["mail_merge_sends.from_user_id","${userId}"]]`,
        envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
      },
      {
        name: 'Failed Scheduled Messages',
        count: props.failedScheduledMessages,
        href: `scheduled-messages#filters=[["system_send_status_id","failed"],["mail_merge_sends.from_user_id","${userId}"]]`,
        envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
      },
      {
        name: 'Failed Messages',
        count: props.failedMessages,
        href: `/messages-failed/#filters=[["from_user_id","${userId}"]]&orderBy=system_ctime`,
        envConfigs: 'ENABLE_SCHEDULED_MESSAGES'
      }
    ];
  }

  handleNavigation(url: string) {
    const { closePopout } = this.props;
    return () => {
      push({ config: parseUrlToRoute(url) });
      if (closePopout) {
        closePopout();
      }
    };
  }

  render() {
    const { styles: s } = this.props;

    return (
      <Box {...s('container')}>
        {this.MENU_CONFIG.map((menuItem) => {
          const {
            name,
            count,
            href,
            isSeparator,
            countries,
            accessRights,
            envConfigs,
            withFlags,
            withoutFlags
          } = menuItem;
          const highNumber = 999;
          const sensibleCount = count && count > highNumber ? '999+' : count;
          const shouldShowCount = !!(count && count > 0);
          const key = createKey(menuItem.name);

          if (
            ['Failed Messages', 'Failed Scheduled Messages'].includes(name) &&
            count === 0
          ) {
            return null;
          }

          return (
            <Requires
              key={key}
              accessRights={accessRights}
              countries={countries}
              envConfigs={envConfigs}
              withFlags={withFlags}
              withoutFlags={withoutFlags}
            >
              {isSeparator ? (
                <Box key={key} {...s('separator')} />
              ) : (
                <span
                  {...s('menuItem')}
                  onClick={href ? this.handleNavigation(href) : _.noop}
                >
                  <Box {...s('label')}>{name}</Box>
                  {shouldShowCount && (
                    <Box {...s('badge')}>{sensibleCount}</Box>
                  )}
                </span>
              )}
            </Requires>
          );
        })}
      </Box>
    );
  }
}

const Notifications = compose<ComponentType<NotificationsComponentProps>>(
  styled(defaultStyles),
  withModel(session)
)(NotificationsComponent);

export default Notifications;
