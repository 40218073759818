import React, { PureComponent } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import Dialog from 'view/components/dialog';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton } from 'view/components/button';
import List, { RexBasicCell } from 'view/components/list';
import PaddingBox from 'view/components/padding-box';
import Box from '@rexlabs/box';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'theme';
import { Link } from 'components/text/link';
import { Hint } from 'components/text/hint';
import ToggleStatusDialog from './toggle-status';
import { withDialog } from 'shared/hocs/with-dialog';

const defaultStyles = {
  serviceIcon: {
    position: 'relative',
    top: '-3px',
    left: '-3px',
    marginRight: '3px',
    color: COLORS.STATES.RED
  },
  overriddenIcon: {
    color: COLORS.STATES.SLATE
  },
  itemText: {
    whiteSpace: 'normal'
  },
  infoTextContainer: {
    paddingLeft: '28px'
  },
  infoText: {
    display: 'inline',
    cursor: 'auto'
  },
  externalLinkIcon: {
    position: 'relative',
    left: '-3px',
    top: '1px',
    color: COLORS.PRIMARY.SAND
  }
};

const listStyles = StyleSheet({
  separator: {
    display: 'none'
  },

  wrapItem: {
    '&:nth-child(even)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

@withDialog(ToggleStatusDialog, { propName: 'toggleStatus' })
@styled(defaultStyles)
@autobind
class RegistryWarningDialog extends PureComponent {
  static propTypes = {
    washItems: types.array.isRequired
  };

  state = {
    washItems: this.props.washItems
  };

  onToggleOverrideClick(item) {
    const { toggleStatus } = this.props;

    toggleStatus.open({
      ...item,
      override: item.user_override,
      callback: (washResponse) => this.overrideCallback(item, washResponse)
    });
  }

  overrideCallback(item, washResponse) {
    const { washItems } = this.state;
    const washStatus = washResponse.status === 'allow' ? 'allow' : 'disallow';

    const override = washItems.map((washItem) =>
      washItem.recordString === item.recordString
        ? { ...washItem, user_override: washStatus }
        : washItem
    );

    this.setState(
      {
        washItems: override
      },
      () => {
        item.callback(washStatus);
      }
    );
  }

  renderComponent(item) {
    const { styles: s } = this.props;
    const cellStyles = {
      padding: 0,
      height: 'auto'
    };

    const overrideString =
      item.user_override === 'allow'
        ? `. ${item.service} Override - Contact Permitted`
        : ' - Contact not permitted.';

    const Icon =
      item.service === 'TPS' ? (
        <div
          {...s('serviceIcon', {
            overriddenIcon: item.user_override === 'allow'
          })}
        >
          <ICONS.DONT_CALL />
        </div>
      ) : (
        <div
          {...s('serviceIcon', {
            overriddenIcon: item.user_override === 'allow'
          })}
        >
          <ICONS.DONT_MAIL />
        </div>
      );

    const ServiceLink =
      item.service === 'TPS' ? (
        <Link
          light
          href={'https://www.tpsonline.org.uk/tps/index.html'}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          Telephone Preference Service
        </Link>
      ) : (
        <Link
          light
          href={'https://www.mpsonline.org.uk/'}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          Mailing Preference Service
        </Link>
      );

    return (
      <PaddingBox flexDirection={'row'}>
        <RexBasicCell
          align={'left'}
          style={{
            ...cellStyles,
            flex: 1,
            marginRight: '20px'
          }}
        >
          <Box alignItems={'flex-start'}>
            {Icon}
            <Hint normal dark {...s('itemText')}>
              {item.recordString} on {item.service} Registry
              {overrideString}
            </Hint>
          </Box>
          <Box alignItems={'center'} {...s('infoTextContainer')}>
            <Link
              {...s('infoText')}
              is={'p'}
              light
              undecorated
              unlinked
              regular
            >
              Information provided by the {ServiceLink}
            </Link>
            <ICONS.EXTERNAL_LINK_DEPRECATED {...s('externalLinkIcon')} />
          </Box>
        </RexBasicCell>
        <RexBasicCell align={'right'} style={{ ...cellStyles }}>
          <DefaultButton light onClick={() => this.onToggleOverrideClick(item)}>
            {item.user_override === 'allow' ? 'Remove Override' : 'Override'}
          </DefaultButton>
        </RexBasicCell>
      </PaddingBox>
    );
  }

  render() {
    const { closeDialog } = this.props;
    const { washItems, isLoading } = this.state;

    return (
      <Dialog
        title={'Registry Warning'}
        closeDialog={closeDialog}
        height={300}
        width={630}
        isLoading={isLoading}
        hasPadding={false}
      >
        <List
          styles={listStyles}
          renderItem={this.renderComponent}
          items={washItems}
        />
        <PaddingBox>
          <ButtonBar hasPadding={false}>
            <DefaultButton dark onClick={closeDialog}>
              Close
            </DefaultButton>
          </ButtonBar>
        </PaddingBox>
      </Dialog>
    );
  }
}

export default RegistryWarningDialog;
