import React from 'react';

import { ArrowDropdown } from 'view/components/action-menu';
import { ActionMenuItem } from 'view/components/action-menu/core';

interface ActionMenuProps {
  handledActionMenuToogle: (isOpen: boolean) => any;
  ActionMenuItems: ActionMenuItem[];
}

export function SavedListItemActionMenu(props: ActionMenuProps) {
  const { ActionMenuItems, handledActionMenuToogle } = props;

  return (
    <div data-hover>
      <ArrowDropdown
        onChange={(isOpen) => handledActionMenuToogle(isOpen)}
        small
        stopPropagation
        items={ActionMenuItems}
      />
    </div>
  );
}
