import React from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { OptionLabel } from 'components/text/option-label';
import { OptionDetail } from 'components/text/option-detail';

import { COLORS, PADDINGS } from 'src/theme';

import { get } from 'lodash';

const defaultOptionStyles = StyleSheet({
  container: {
    cursor: 'pointer'
  },
  isHighlighted: {
    backgroundColor: COLORS.STATES.BLUE
  },
  isSelected: {
    backgroundColor: COLORS.STATES.BLUE
  },
  icon: {
    color: COLORS.PRIMARY.SLATE
  },
  label: {
    color: COLORS.PRIMARY.SLATE_DARK
  },
  overflow: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

function DefaultOption({
  styles: s,
  item,
  isHighlighted,
  isSelected,
  description,
  Icon,
  ...props
}) {
  return (
    <Box
      {...props}
      {...s('container', { isHighlighted, isSelected })}
      flex={1}
      p={PADDINGS.XXS}
    >
      <Box
        alignItems='center'
        justifyContent='center'
        flex='0 0 auto'
        height='20px'
        width='20px'
        marginRight={PADDINGS.XXS}
      >
        {Icon && <Icon {...s('icon')} />}
      </Box>
      <Box {...s('overflow')} flexDirection='column'>
        <OptionLabel {...s('label', 'overflow')}>
          {get(item, 'label')}
        </OptionLabel>
        {description && (
          <OptionDetail {...s('description', 'overflow')}>
            {description}
          </OptionDetail>
        )}
      </Box>
    </Box>
  );
}

export default styled(defaultOptionStyles)(DefaultOption);
