import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';
import { AiLogo } from 'features/ai/components/ai-logo';

const styles = StyleSheet({
  button: {
    fontSize: '13px',
    padding: '10px 14px',
    borderRadius: '6px',
    webKitAppearance: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'color 0.15s ease-out, box-shadow 0.15s ease-out',
    fontWeight: 600,
    '&[disabled]': {
      opacity: 0.6,
      filter: 'grayscale(100%)',
      cursor: 'default'
    }
  },
  default: {
    color: COLORS.DARK_GREY,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 0px 10px 0px rgba(0, 20, 40, 0.08)',
    '&:hover:not[disabled]': {
      color: 'rgba(66, 66, 66, 0.7)',
      boxShadow: '0px 3px 12px -1px rgba(0, 20, 40, 0.15)'
    }
  },
  ghost: {
    color: '#213A6A',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    '&:hover:not[disabled]': {
      color: 'rgba(33, 58, 106, 0.7)'
    }
  }
});

interface AiButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'ghost';
  children: ReactNode;
  withIcon?: boolean;
}
export function AiButton({
  variant = 'default',
  children,
  className,
  withIcon,
  ...rest
}: AiButtonProps) {
  const s = useStyles(styles);

  return (
    <button
      className={classnames(
        s('button', { [variant]: true }).className,
        className
      )}
      {...rest}
    >
      {withIcon ? <AiLogo style={{ width: '14px', height: '14px' }} /> : null}
      {children}
    </button>
  );
}
