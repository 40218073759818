import { useOverlay } from 'hooks/use-overlay';
import { useCallback, useEffect, useRef } from 'react';
import apiClient from 'data/classic-bridges/api-client';
import { getBeeFreeContentForNewsletter } from 'features/newsletter-builder/utils/api';
import { NewsletterJson } from 'features/newsletter-builder/types';
import { toggleLocksForNewsletterSections } from 'features/newsletter-builder/utils/json';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';

export default function NewsletterBuilder({
  newsletterId,
  isActive,
  nextStep
}: {
  newsletterId: string | number;
  isActive: true;
  nextStep: () => void;
}) {
  const blockRef = useRef<number | null>(null);
  const openedRef = useRef(false);
  const session = useModelState(sessionModel);
  const defaultLocation = session.office_locations?.find(
    (l) =>
      String(l.id) ===
      String(session.user_details.settings.default_location?.id)
  );

  const newsletterOverlay = useOverlay('newsletterBuilder');

  const loadInitialJson = useCallback(async () => {
    const content = await getBeeFreeContentForNewsletter(String(newsletterId));

    content.json = toggleLocksForNewsletterSections(content.json, true);

    blockRef.current = content.blockId;
    return content.json;
  }, [newsletterId]);

  const onSave = useCallback(
    async (json: NewsletterJson) => {
      await apiClient.post('NewsletterCampaignBlocks::update', {
        data: {
          id: blockRef.current,
          settings: json
        }
      });
      nextStep();
    },
    [blockRef, nextStep]
  );

  useEffect(() => {
    if (openedRef.current) return;
    if (isActive) {
      newsletterOverlay.open({
        newsletterId,
        isActive,
        loadInitialJson,
        onCancel: () => nextStep(),
        onSave
      });
      openedRef.current = true;
    } else {
      openedRef.current = false;
      newsletterOverlay.close();
    }

    return () => {
      openedRef.current = false;
      newsletterOverlay.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return null;
}
