import _ from 'lodash';
const REGEXS = { PROP_PREFIX: /(^|\.)_/g };

const stripPrefixes = (str) => str.replace(REGEXS.PROP_PREFIX, '$1');

/**
 * Same as {@link _.get}, but will do extra work to strip prefix's from property
 * names, such as underscores in Wings data.
 * @param {Object} target
 * @param {String|String[]} propertyChain
 * @returns {*}
 */
export function getHazardly(target, propertyChain, defaultValue) {
  if (!target || !propertyChain.length) return target;
  if (_.isString(propertyChain)) {
    propertyChain = stripPrefixes(propertyChain);
  } else if (_.isArray(propertyChain)) {
    propertyChain = _.map(propertyChain, (prop) =>
      _.isString(prop) ? stripPrefixes(prop) : prop
    );
  }
  return _.get(target, propertyChain, defaultValue);
}

// Copied from classic
export function stripPrefixFromProperties(data, prefix = '_') {
  // Return immediately if this is a simple value string / null etc. Note: array is an object
  if (!_.isObject(data)) {
    return data;
  } else {
    // _.reduce cannot be used here, as it will deem any objects with a length
    // property to be arrays and that's just plain wrong. For example, rooms
    // have a length property. Object.keys will return the keys for objects and
    // indices for arrays.
    const result = Object.keys(data).reduce(function (result, key) {
      const val = data[key];
      let _key = key;
      if (_.isString(key)) {
        _key =
          key.substr(0, prefix.length) === prefix
            ? key.substr(prefix.length)
            : key;
      }

      // Call ourselves recursively
      result[_key] = stripPrefixFromProperties(val, prefix);
      return result;
    }, {});

    // If data was an array in the first place, rather than an object, convert it back to an array
    return _.isArray(data) ? _.values(result) : result;
  }
}
