import React from 'react';
import { capitalize, isString } from 'lodash';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { DefaultButton } from 'view/components/button';
import { getLabelForCriteria } from '../utils/filters';
import { DisplayCriteria } from '../types';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    maxWidth: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 400
  },

  filteredBy: {
    textTransform: 'uppercase',
    paddingRight: '4px',
    fontSize: '12px'
  },

  name: {
    fontWeight: 700,
    textTransform: 'capitalize'
  },

  value: {},

  separator: {},

  clearButton: {
    border: '0 none',
    background: 'transparent',
    font: 'inherit',
    color: '#fff',
    fontSize: '22px',
    fontWeight: 900,
    transition: 'opacity .2s',
    cursor: 'pointer',
    padding: '4px',
    marginLeft: '4px',

    '&:hover': {
      opacity: 0.5
    }
  }
});

interface FilterTagProps {
  displayCriteria: DisplayCriteria[];
  setCriteria: any;
  truncateDisplayedFilters: boolean;
  onClick?: (e: any) => void;
}

export function FilterTag({
  displayCriteria,
  setCriteria,
  truncateDisplayedFilters,
  onClick
}: FilterTagProps) {
  const s = useStyles(defaultStyles);
  return (
    <DefaultButton
      blue
      onClick={onClick}
      IconRight={() => (
        <button
          {...s('clearButton')}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCriteria([]);
          }}
        >
          &times;
        </button>
      )}
    >
      <span {...s('container')}>
        <span {...s('filteredBy')}>Filtered by:</span>
        {truncateDisplayedFilters && displayCriteria.length > 1 ? (
          <span>
            <b {...s('name')}>{displayCriteria.length}</b>{' '}
            <span {...s('value')}>fields</span>
          </span>
        ) : (
          displayCriteria.map((criteriaItem, i) => {
            const filterLabel = criteriaItem?.filterConfig?.label;
            const nameLabel = isString(filterLabel)
              ? capitalize(filterLabel)
              : getLabelForCriteria(criteriaItem);

            return (
              <span key={i}>
                {i > 0 && <span {...s('separator')}>, </span>}
                <b {...s('name')}>{nameLabel}</b>{' '}
                <span {...s('value')}>{criteriaItem.humanReadableValue}</span>
              </span>
            );
          })
        )}
      </span>
    </DefaultButton>
  );
}
