import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import Fixture from './default';
import { Bridges } from 'data/classic-bridges';

@autobind
class PropertiesFixture extends Component {
  handleFixtureClick() {
    const { option: meta } = this.props;

    Bridges.Dialogs.editProperties.openClassic({
      data: {
        category: _.get(meta, 'fixturePrefillData.category'),
        business_name: _.get(meta, 'fixturePrefillData.business_name'),
        adr_building: _.get(meta, 'fixturePrefillData.adr_building'),
        adr_unit_number: _.get(meta, 'fixturePrefillData.adr_unit_number'),
        adr_street_number: _.get(meta, 'fixturePrefillData.adr_street_number'),
        adr_street_name: _.get(meta, 'fixturePrefillData.adr_street_name'),
        adr_state_or_region: _.get(
          meta,
          'fixturePrefillData.adr_state_or_region'
        ),
        adr_locality: _.get(meta, 'fixturePrefillData.adr_locality'),
        adr_suburb_or_town: _.get(
          meta,
          'fixturePrefillData.adr_suburb_or_town'
        ),
        adr_postcode: _.get(meta, 'fixturePrefillData.adr_postcode')
      },
      callback: _.get(meta, 'fixturePrefillData.callback')
    });
  }

  render() {
    return (
      <Fixture addNewEntity={this.handleFixtureClick}>ADD NEW PROPERTY</Fixture>
    );
  }
}

export default PropertiesFixture;
