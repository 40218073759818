import { useMemo } from 'react';
import { useListExportAction } from 'components/record-list-screen/utils/use-list-export-action';

const service_name = 'Invoices';

export function useBillsActions() {
  const exportList = useListExportAction({ service_name });

  return useMemo(
    () => [
      {
        label: 'Export',
        onClick: exportList
      }
    ],
    [exportList]
  );
}
