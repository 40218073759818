import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  subHeading: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px'
  }
});

export function Info({ children }) {
  const s = useStyles(defaultStyles);
  return <h3 {...s('subHeading')}>{children}</h3>;
}
