import React, { useEffect, useMemo } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { query, useEntityQuery } from '@rexlabs/model-generator';
import listingsModel, { ListingItem } from 'data/models/entities/listings';
import { TextGenerationListingDetailsLabelValue } from 'features/ai/components/text-generation/text-generation-listing-details-label-value';
import { COLORS, TEXTS } from 'theme';
import { AiBackButton } from 'features/ai/components/primitives/ai-back-button';
import { ICONS } from 'shared/components/icon';
import { formatCurrency } from 'utils/formatters/index';
import { PropertyItem } from 'data/models/entities/properties';
import { Region, useRegion } from 'hooks/use-region';
import { EVENTS } from 'shared/utils/analytics/index';
import Analytics from 'shared/utils/vivid-analytics';

const InfoIcon = ICONS.INFO as any;

const getListingQuery = (id) => query`{
  ${listingsModel} (id: ${id}) {
    id
    listing_category
    property {
    system_search_key
      attr_bedrooms
      attr_bathrooms
      attr_carports
      attr_build_year
      attr_buildarea_m2
      price_match
    }
    related {
      property_features
      listing_subcategories
      listing_highlights
      listing_idealfors
      property_views
      listing_views
    }
  }
}`;

const styles = StyleSheet({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    padding: '40px',
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    color: COLORS.DARK_GREY,
    gap: '20px'
  },
  heading: {
    ...TEXTS.HEADING_2,
    fontWeight: 600,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
  },
  body: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 400
  },
  infoIcon: {
    width: 14,
    height: 14,
    position: 'relative',
    top: '-1px'
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '20px',
    '& div': {
      flexBasis: '50%'
    }
  },
  warningBanner: {
    background: COLORS.PRIMARY.SUNSHINE_YELLOW,
    padding: '10px',
    color: COLORS.DARK_GREY,
    borderRadius: '6px',
    fontSize: '14px',
    marginBottom: '20px',
    '& strong': {
      fontWeight: 600
    }
  },
  warningBannerLink: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

const getValues = (listing: ListingItem, region: Region) => {
  const property = listing?.property as PropertyItem;
  const related = listing?.related;
  const currencySymbol = region.financial.currency.symbol;

  if (!listing) return {};

  return {
    Address: property?.system_search_key,
    Features: related?.property_features
      ?.map((f) => f.feature?.text)
      .join(', '),
    Price: listing?.price_match
      ? currencySymbol + formatCurrency(listing?.price_match)
      : null,
    'Year Built': property?.attr_build_year,
    'Listing Type': listing?.listing_category?.text,
    'Build Area (m2)': property?.attr_buildarea_m2,
    'Listing Subcategory': related?.listing_subcategories[0]?.subcategory?.text,
    Views: related?.property_views?.map((f) => f.view?.text).join(', '),
    'Property Attributes': [
      `${property?.attr_bedrooms} beds`,
      `${property?.attr_bathrooms} baths`,
      `${property?.attr_carports} cars`
    ]
      .filter((a) => !!Number(a[0]))
      .join(', '),
    'Ideal For': related?.listing_idealfors
      ?.map((f) => f.idealfor?.text)
      .join(', ')
  };
};

type TextGenerationListingDetailsProps = {
  listingId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export function TextGenerationListingDetails({
  listingId,
  isOpen,
  setIsOpen
}: TextGenerationListingDetailsProps) {
  const listingQuery = useMemo(() => getListingQuery(listingId), [listingId]);
  const { data } = useEntityQuery(listingQuery);
  const region = useRegion();
  const s = useStyles(styles);

  useEffect(() => {
    if (isOpen) {
      Analytics.track({
        event: EVENTS.AI_AD_TEXT.AI_AD_TEXT_LEARN_MORE
      });
    }
  }, [isOpen]);

  if (!data) return null;

  const values = getValues(data, region);

  const isListingDetailsMissing = doesListingLackDetails(data, region);

  return (
    <>
      {isListingDetailsMissing ? (
        <div
          {...s('warningBanner')}
          style={{ visibility: isOpen ? 'hidden' : 'visible' }}
        >
          <strong>Listing information is insufficient. </strong> Add more
          listing details for better results.{' '}
          <span
            {...s('warningBannerLink')}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Learn more
          </span>
        </div>
      ) : null}
      <div
        {...s('container')}
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      >
        <AiBackButton
          onClick={() => setIsOpen(false)}
          label='Back to Ad Generation'
        />
        <h3 {...s('heading')}>
          <InfoIcon {...s('infoIcon')} />
          How does Rex AI generate this text?
        </h3>
        <p {...s('body')}>
          Responses are generated based on the following listing details. The
          more details the listing has, the better the response. AI can
          sometimes be inaccurate. Consider checking important information.
        </p>
        <div {...s('details')}>
          {Object.entries(values).map(([label, value]) => (
            <TextGenerationListingDetailsLabelValue
              key={label}
              label={label}
              value={value}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export function doesListingLackDetails(listing: ListingItem, region: Region) {
  const values = getValues(listing, region);
  return (
    Object.values(values).filter((value) => value !== null && value !== '')
      .length < 5
  );
}
