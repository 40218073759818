export const FONT = {
  SIZES: {
    SMALL: '12px',
    TAG: '13px',
    REGULAR: '14px',
    LARGE: '16px',
    XL: '18px',

    NAV_MENU: {
      TIER_ONE: '15px',
      TIER_TWO: '14px'
    }
  },
  LINE_HEIGHTS: {
    NAV_MENU: '22px'
  },
  WEIGHTS: {
    LIGHT: 100,
    REGULAR: 400,
    MEDIUM: 500,
    SEMIBOLD: 600,
    BOLD: 900
  },
  SPACING: {
    REGULAR: '0.35px',
    LARGE: '0.5px'
  },
  TRANSFORM: {
    UPPERCASE: 'uppercase'
  },
  FAMILY: {
    PROXIMA_NOVA: 'proxima-nova, lato, arial, serif'
  }
} as const;
