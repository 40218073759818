import React from 'react';

import { LeadsResponse } from 'data/models/entities/leads';

import { DarkTag } from 'components/tags/dark-tag';
import { NormalTag } from 'components/tags/normal-tag';
import { WarningTag } from 'components/tags/warning-tag';

export function LeadsDialogTag({ data }: { data: LeadsResponse }) {
  const leadStatus = data.lead_status;

  if (leadStatus?.id === 'new') {
    return <NormalTag>{leadStatus.text}</NormalTag>;
  }

  if (leadStatus?.id === 'completed') {
    return <DarkTag>{leadStatus.text}</DarkTag>;
  }

  return <WarningTag>{leadStatus.text}</WarningTag>;
}
