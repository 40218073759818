// TODO: make entity model

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import moment from 'moment';
import _ from 'lodash';

const initialState = {
  items: [],
  fetched: null
};

const actionCreators = {
  fetch: {
    request: (payload, actions, dispatch, getState) =>
      new Promise((resolve, reject) => {
        if (
          (!payload || !payload.force) &&
          getState().announcements.items.length
        ) {
          resolve(getState().announcements.items);
          return;
        }
        api
          .post('Announcements::search', {
            criteria: [
              {
                name: 'display_area',
                value: 'application'
              },
              {
                name: 'user_dismissed_time',
                value: 0
              },
              {
                name: 'display_until',
                type: '>=',
                value: moment().unix()
              }
            ]
          })
          .then(({ data }) => {
            resolve(_.get(data, 'result.rows') || []);
          })
          .catch((e) => {
            reject(e);
          });
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          items: action.payload,
          fetched: new Date().getTime()
        };
      },
      failure: (state) => {
        return { ...state, items: [], fetched: null };
      }
    }
  },
  dismiss: {
    request: (payload, actions) =>
      api
        .post('Announcements::toggleDismissal', { id: payload.id })
        .then(() => {
          return actions.fetch({ force: true });
        }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export const getAppAnnouncement = (items) => {
  const filteredAnnouncements = items.filter(
    (a) => a.display_area === 'application'
  );

  return filteredAnnouncements.length
    ? filteredAnnouncements[filteredAnnouncements.length - 1]
    : null;
};

const selectors = {
  items: (state) => state.announcements.items,
  appAnnouncement: (state) => getAppAnnouncement(state.announcements.items)
};

export default new Generator('announcements').createModel({
  initialState,
  actionCreators,
  selectors
});
