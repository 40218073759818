import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo
} from 'react';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { RecordListTable } from 'components/record-list-screen/table';
import thirdPartyServiceXeroModel from 'features/apps/xero/data/third-party-service-xero';
import {
  InvoicePagination,
  ThirdPartyServiceXeroModel
} from 'features/apps/xero/types/third-party-service-xero';
import { compose } from 'redux';
import { Body } from 'components/text/body';
import { ColumnConfig, Selection } from 'components/record-list-screen/types';
import { DateCell } from 'components/record-list-screen/cells/date-cell';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';
import { ContactCell } from 'components/record-list-screen/cells/contact-cell';
import Spinner from 'shared/components/spinner';
import { EntityCell } from 'features/apps/xero/components/entity-cell';
import { pick } from 'lodash';
import { Payment } from 'features/apps/xero/types/payment';
import { Pagination } from 'view/components/record-details-screen/related-table/pagination';
import ErrorList from '../error-list';
import { StateView } from 'components/record-list-screen/state-view';

interface ImportPaymentsProps {
  thirdPartyServiceXero: ThirdPartyServiceXeroModel;
  selection: Selection;
  setSelection: Dispatch<SetStateAction<Selection>>;
  ignoredPayments: string[];
  setIgnoredPayments: Dispatch<SetStateAction<string[]>>;
  payments: Payment[];
  isLoading: boolean;
  selectedPayments: (string | number)[];
  pagination: InvoicePagination;
  errors: string[];
  paymentIds: string[];
}

const ImportPayments = ({
  payments,
  isLoading,
  selection,
  setSelection,
  ignoredPayments,
  setIgnoredPayments,
  selectedPayments,
  pagination,
  errors,
  paymentIds
}: ImportPaymentsProps) => {
  const region = useRegion();
  const currencySymbol = region.financial.currency.symbol;

  const toggleRecord = useCallback(
    (row: Payment) => {
      if (ignoredPayments.includes(row.id)) {
        return [
          {
            label: 'Revert',
            onClick: () => {
              setIgnoredPayments((prev) =>
                prev.filter((prev) => prev !== row.id)
              );
            }
          }
        ];
      } else {
        return [
          {
            label: 'Ignore',
            onClick: () => {
              setIgnoredPayments((prev) => [...prev, row.id]);
              setSelection((prev) => {
                if (prev.type === 'include') {
                  return {
                    ...prev,
                    ids: prev.ids.filter((prevId) => prevId !== row.id)
                  };
                } else {
                  return {
                    ...prev,
                    ids: [...prev.ids, row.id]
                  };
                }
              });
            }
          }
        ];
      }
    },
    [ignoredPayments, setIgnoredPayments, setSelection]
  );

  const columns: ColumnConfig<Payment>[] = useMemo(
    () => [
      {
        id: 'invoice.invoice_number',
        label: 'Invoice No.',
        forced: true
      },
      {
        id: 'invoice.listing',
        label: region.isUK ? 'Listing, Project or Project Stage' : 'Listing',
        forced: true,
        selector: (row) =>
          pick(row.invoice, ['listing', 'project', 'project_stage']),
        Cell: EntityCell as FC,
        cellProps: {
          sequence: ['listing', 'project', 'project_stage'],
          items: toggleRecord
        }
      },
      {
        id: 'invoice.system_ctime',
        label: 'Date',
        forced: true,
        Cell: DateCell
      },
      {
        id: 'amount',
        label: 'Amount',
        selector: ({ amount }) => currencySymbol + formatCurrency(amount),
        forced: true
      },
      {
        id: 'invoice.contact',
        label: 'Person',
        selector: ({ invoice }) => ({
          id: invoice.contact_id,
          name: invoice.contact_name
        }),
        forced: true,
        Cell: ContactCell
      },
      {
        id: 'invoice.invoice_type',
        label: 'Type',
        selector: ({ invoice }) => invoice.invoice_type?.text,
        forced: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencySymbol, region, ignoredPayments]
  );

  return (
    <Box>
      <Box justifyContent='space-between' alignItems='center' mb={15}>
        <Body dark semibold>
          Import Payments from Xero
        </Body>
        <Body small light normal regular>
          {selectedPayments.length} items selected
        </Body>
      </Box>

      {errors.length > 0 && <ErrorList errors={errors} />}

      <RecordListTable
        name='all-payments'
        allIds={paymentIds}
        items={payments}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        selection={selection}
        setSelection={setSelection}
        disabledSelections={ignoredPayments}
        hasSelection={true}
        setOrderBy={() => null}
        isLoading={isLoading}
        LoadingView={() => (
          <Box
            flexDirection='row'
            width={'100%'}
            height={100}
            alignItems='center'
            justifyContent='center'
            spacing={20}
          >
            <Body dark semibold>
              Searching for payments
            </Body>
            <Spinner small dark />
          </Box>
        )}
        EmptyView={() => <StateView noPadding>No payments found</StateView>}
        variant={'compact'}
      />

      {pagination.pagination.totalPages > 1 && (
        <Box flexDirection='row' justifyContent='flex-end' mt={5}>
          <Pagination
            pagination={pagination.pagination}
            limit={10}
            page={pagination.page}
            setPage={pagination.setPage}
            variant='arrow'
          />
        </Box>
      )}
    </Box>
  );
};

export default compose<
  React.ComponentType<Omit<ImportPaymentsProps, 'thirdPartyServiceXero'>>
>(withModel(thirdPartyServiceXeroModel))(ImportPayments);
