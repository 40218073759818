import { gql } from 'hooks/use-graphql-query';
import { CustomFields, User } from 'features/custom-reporting/data/fragments';
import {
  Contact,
  ContactEmails,
  ContactNames,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';

export const contactReportQueryDefaultVariables = {
  include_subresource_emails: false,
  include_subresource_names: false,
  include_subresource_phones: false,
  include_subresource_system_owner_user: false,
  include_subresource_latest_match_profile: false,
  include_contact_custom_fields: false,
  contact_custom_field_ids: []
};

export const ContactReportQuery = gql`
  ${User}
  ${Contact}
  ${ContactNames}
  ${ContactEmails}
  ${ContactPhones}
  ${MatchProfile}
  ${CustomFields}

  query (
    $include_subresource_emails: Boolean!
    $include_subresource_phones: Boolean!
    $include_subresource_names: Boolean!
    $include_subresource_system_owner_user: Boolean!
    $include_subresource_latest_match_profile: Boolean!
    $include_contact_custom_fields: Boolean!
    $contact_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: contacts(
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...Contact
        custom_field_values(field_ids: $contact_custom_field_ids)
          @include(if: $include_contact_custom_fields) {
          ...CustomFields
        }
        system_owner_user @include(if: $include_subresource_system_owner_user) {
          ...User
        }
        names @include(if: $include_subresource_names) {
          ...ContactNames
        }
        phones @include(if: $include_subresource_phones) {
          ...ContactPhones
        }
        emails @include(if: $include_subresource_emails) {
          ...ContactEmails
        }
        latest_match_profile
          @include(if: $include_subresource_latest_match_profile) {
          ...MatchProfile
        }
      }
    }
  }
`;
