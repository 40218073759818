import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import DefaultValue from 'view/components/input/select/values/default';

const selectStyles = StyleSheet({
  container: { backgroundColor: 'transparent' }
});

@styled(selectStyles)
export class SelectValue extends PureComponent {
  render() {
    const { styles, option, pluckLabel } = this.props;
    return <span {...styles('container')}>{pluckLabel(option)}</span>;
  }
}

class ModelValue extends PureComponent {
  render() {
    const { value, selected, valueAsObject } = this.props;
    const hasSelected = selected && selected.length > 0;

    return hasSelected
      ? selected.map((option) => {
          /**
           * Find the current value so we can grab the model from it if it does not exist on the
           * selected option. The can occur when a new suggestive value has been added as the
           * options don't contain a model.
           */
          const currentValue = Array.isArray(value)
            ? value.find((currentValue) => {
                return valueAsObject
                  ? currentValue.value === option.value
                  : currentValue === option.value;
              })
            : valueAsObject
            ? _.get(value, 'value')
            : value;

          const Value = _.get(
            option,
            'model.select.Value',
            _.get(
              currentValue,
              'model.select.Value',
              this.props.multi ? DefaultValue : SelectValue
            )
          );
          const key = `${_.get(option, 'value')}-${_.get(
            option,
            'model.modelName',
            _.get(currentValue, 'model.modelName', 'suggestive')
          )}`;
          return (
            <Value
              {...this.props}
              key={key}
              entitySelect
              selected={this.props.selected}
              option={option}
            />
          );
        })
      : null;
  }
}

export default ModelValue;
