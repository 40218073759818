import _ from 'lodash';
import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import ui from 'data/models/custom/ui';
import { withModel } from '@rexlabs/model-generator';
import Spinner from 'shared/components/spinner';
import { TRANSITIONS, ZINDEX } from 'src/theme';
import { getOverlaySync } from 'utils/overlays';
import { stopPropagation } from 'utils/events';
import SaveCancelContext from 'data/save-cancel-context';

const defaultStyles = StyleSheet({
  container: {
    pointerEvents: 'none',
    transform: 'translateZ(0)', // HACK: Draws a separate layer for this element
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.78)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: ZINDEX.SAVE_CANCEL_OVERLAY,
    top: 0,
    color: 'white',
    transition: TRANSITIONS.SLOW.OPACITY
  },

  isVisible: {
    pointerEvents: 'auto',
    opacity: 1
  },

  button: {
    fontSize: '13px',
    fontWeight: '600',
    padding: '9px 15px',
    margin: 0,
    letterSpacing: '0.3px',
    cursor: 'pointer',
    color: 'white',

    transition: `${TRANSITIONS.REGULAR.BACKGROUND}, ${TRANSITIONS.REGULAR.COLOR}`
  },

  save: {
    background: 'rgb(193, 29, 23)',
    color: 'white',

    '&:hover': {
      background: 'rgb(173, 9, 3)'
    }
  },

  cancel: {
    background: 'none repeat scroll 0 0 rgba(0, 0, 0, 0)',
    textDecoration: 'underline',

    '&:hover': {
      opacity: 0.75
    }
  },

  outsideHeader: {
    height: '70px'
  },

  overlayPosition: {
    justifyContent: 'flex-end',
    right: '60px'
  }
});

@withModel(ui)
@styled(defaultStyles)
class SaveCancelOverlay extends PureComponent {
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getOverlayParams() {
    const { ui } = this.props;
    const overlaySync = getOverlaySync(ui);
    // isOverlayPositionis used to check if a full overlay is active or not below
    const isOverlayPosition = ui.isOverlayActive && overlaySync.isBlocking;
    const isVisible =
      (ui.saveCancelOverlayState === 'ON' ||
        ui.saveCancelOverlayState === 'IN_PROGRESS') &&
      !isOverlayPosition;
    return { isVisible, isOverlayPosition };
  }

  componentDidUpdate() {
    if (this.getOverlayParams().isVisible) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  render() {
    const { styles: s, ui, outsideHeader } = this.props;
    const currentContext = _.last(SaveCancelContext.stack);
    const cancelClickHandler = _.get(currentContext, 'cancel', _.noop);
    const saveClickHandler = stopPropagation(
      _.get(currentContext, 'save', _.noop)
    );

    const { isVisible, isOverlayPosition } = this.getOverlayParams();

    const loading = ui.saveCancelOverlayState === 'IN_PROGRESS';

    return (
      <Box
        key={'saveCloseOverlay'}
        {...s('container', {
          isVisible,
          outsideHeader,
          overlayPosition: isOverlayPosition
        })}
      >
        {
          !loading &&
            [
              <span
                key={'cancelChanges'}
                {...s('button', 'cancel')}
                onClick={cancelClickHandler}
              >
                Cancel Changes
              </span>,
              <span
                key={'saveChanges'}
                {...s('button', 'save')}
                onClick={saveClickHandler}
              >
                Save Changes
              </span>
            ].filter(Boolean) // TODO: Replace this with a Fragment once we are on React 16+
        }
        {loading && !isOverlayPosition && <Spinner />}
      </Box>
    );
  }
}

export default SaveCancelOverlay;
