import React, { ReactElement, ReactNode } from 'react';
import Heading from './heading';
import HeadingContainer from './heading-container';
import IconButton from './icon-button';
import { ICONS } from 'shared/components/icon';
import BaseIconButton from 'view/components/button/icon';

interface HeadingButtonProps {
  heading: ReactNode;
  /**
   * The button is hidden if onClick function is missing
   */
  iconButtonOnClick?: Function;
  iconButtonIcon?: any;
  showButton?: boolean;
  buttonProps?: React.ComponentProps<typeof BaseIconButton>;
}

function HeadingWithButton({
  heading,
  iconButtonIcon = ICONS.ADD,
  iconButtonOnClick,
  buttonProps,
  showButton = true
}: HeadingButtonProps): ReactElement {
  return (
    <HeadingContainer>
      <Heading>{heading}</Heading>
      {iconButtonOnClick && showButton && (
        <IconButton
          {...buttonProps}
          Icon={iconButtonIcon}
          onClick={iconButtonOnClick}
        />
      )}
    </HeadingContainer>
  );
}

export default HeadingWithButton;
