import React from 'react';

import { ICONS } from 'shared/components/icon';

import { IconTooltip as IconTooltipComponent } from 'view/components/tooltip';

import { SavedListItemTooltipContainer } from '../tooltip-container';

interface SavedListItemIconTooltipProps
  extends React.ComponentProps<typeof IconTooltipComponent> {
  isVisible: boolean;
}

export function SavedListItemIconTooltip(props: SavedListItemIconTooltipProps) {
  const { isVisible, content, TooltipIcon } = props;

  return (
    <SavedListItemTooltipContainer>
      {isVisible && (
        <IconTooltipComponent TooltipIcon={TooltipIcon} content={content} />
      )}
    </SavedListItemTooltipContainer>
  );
}
