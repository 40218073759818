import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

import {
  BaseModelGeneratorModel,
  ModelGeneratorLoaded
} from '@rexlabs/model-generator';

// TODO: move to userModel
interface UserItem {
  email_address: string;
  first_name: string;
  id: number | string;
  last_name: string;
  name: string;
}

// TODO: Move to ContactModel
interface ContactItem {
  address: string;
  address_postal: string;
  email_address: string;
  fax_number: string;
  id: number | string;
  name: string;
  phone_number: string;
}

interface EventItem {
  connection_id: number;
  custom_content_preview: string;
  id: number | string;
  include_letterhead: boolean;
  print_status: any;
  provide_print_job_response: any;
  scheduled_to_send_at: number;
  send_failed_reason: string;
  send_from_user: UserItem;
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_scheduled_send_attempted_at: null;
  system_scheduled_user: null;
  system_send_status: {
    id: number | string;
    text: string;
  };
  system_sent_at: number;
  system_sent_user: UserItem;
}

export interface MailMergeEventLog {
  attachments: string;
  bccd_contacts: ContactItem[];
  ccd_contacts: ContactItem[];
  chain_link: any;
  contact: ContactItem;
  content_type: 'custom' | 'otf';
  contract_id: string;
  custom: {
    subject: string;
    body: string;
  };
  custom_content_preview: string;
  custom_merge_data: any;
  engagement_status: string;
  event: EventItem;
  fallback_recipient_name: string;
  from_user: UserItem;
  id: number | string;
  ignore_compliance_errors: any;
  ignore_compliance_warnings: any;
  ignore_wash: any;
  invalid_print_reason: any;
  listing: any;
  location_id: string;
  merge_count_contacts: string;
  merge_count_listings: string;
  merge_count_project_stages: string;
  merge_count_projects: string;
  merge_count_properties: string;
  merge_type: 'sms' | 'email' | 'letter';
  print_status: any;
  project: any;
  project_stage: any;
  property: any;
  reminder_id: number | string;
  send_connection_id: number | string;
  send_failed_reason: string;
  send_from_user: UserItem;
  system_created_user: UserItem;
  system_ctime: string;
  system_record_state: string;
  system_send_status_id: string;
  template: any;
}

interface ReadPayload {
  id: string | number;
  fields: {
    name: string;
    type: string;
    value: string;
  };
  extraFields: string[];
}

interface SearchMailMergeEventLogsItem {
  name: string;
  type: string;
  value: string | number;
}

interface SearchMailMergeEventLogsPayload {
  criteria: SearchMailMergeEventLogsItem[];
}

interface ArchivePayload {
  id: string | number;
}

interface MailMergeEventLogsActionCreators {
  read: ReadPayload;
  searchMailMergeEventLogs: SearchMailMergeEventLogsPayload;
  archive: ArchivePayload;
}

const TYPE = 'mailMergeEventLogs';

const actionCreators = {
  read: {
    request: ({ id, fields, extraFields }) =>
      api.post('MailMergeEventLogs::read', {
        id,
        fields,
        extra_fields: extraFields
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  searchMailMergeEventLogs: {
    request: (criteria) =>
      api.post('MailMergeEventLogs::search', {
        ...criteria
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  archive: {
    request: ({ id }) =>
      api.post('MailMergeEventLogs::archive', {
        id
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const mailMergeEventLogsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export type MailMergeEventLogItem = MailMergeEventLog & BaseModelGeneratorModel;

export interface MailMergeEventLogsModel
  extends ModelGeneratorLoaded<MailMergeEventLogItem, any> {
  read: ({
    id,
    fields,
    extraFields
  }: ReadPayload) => Promise<MailMergeEventLogItem>;
  searchMailMergeEventLogs: (
    args: SearchMailMergeEventLogsPayload
  ) => Promise<MailMergeEventLogItem>;
  archive: ({ id }: ArchivePayload) => Promise<MailMergeEventLogItem>;
}

export default mailMergeEventLogsModel;
