import React, { PureComponent } from 'react';
import { styled } from '@rexlabs/styling';
import Box from '@rexlabs/box';

const defaultStyles = {
  circle: {
    borderRadius: '100%'
  },
  avatar: {
    backgroundColor: '#a0abbf'
  },
  darkBox: {
    backgroundColor: '#bec2c9'
  },
  washedBox: {
    backgroundColor: '#C1CBD5'
  },
  lightBox: {
    backgroundColor: '#e8eef6'
  }
};

@styled(defaultStyles)
class GreyBox extends PureComponent {
  render() {
    const {
      styles,
      circle,
      dark,
      washed,
      light,
      avatar,
      width,
      height,
      ...props
    } = this.props;
    const _styles = styles.with({
      lightBox: light || (!dark && !washed && !avatar),
      washedBox: washed,
      darkBox: dark,
      circle,
      avatar
    })(
      {
        width,
        height,
        opacity: 0.5
      },
      props
    );
    return <Box {..._styles} />;
  }
}

export default GreyBox;
