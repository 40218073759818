import React from 'react';

import { ICONS } from 'shared/components/icon';

import { ActionMenuProps } from './core';
import { IconActionMenu } from './icon-action-menu';

interface AddDropdownProps extends ActionMenuProps {
  addButton?: boolean;
}

function AddDropdown({
  items,
  addButton,
  ...props
}: AddDropdownProps): JSX.Element {
  return (
    <IconActionMenu
      Icon={addButton ? ICONS.ADD_MEDIUM : ICONS.ADD_DROPDOWN}
      red
      items={items}
      {...props}
    />
  );
}

export default AddDropdown;
