import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import zenscroll from 'zenscroll';
import { connect } from 'react-redux';

import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';

import { COLORS, FONT, HEIGHTS, PADDINGS, TEXTS } from 'src/theme';

import sessionModel from 'data/models/custom/session';

import Icon, { ICONS } from 'shared/components/icon';

import {
  getServiceTypeForOptionType,
  headerColorContrast,
  OPTION_TYPES
} from 'view/components/navigation/app-search/utils';
import AppSearch from 'view/components/navigation/app-search/base';
import SearchSectionBack from 'view/components/navigation/app-search/sections/section-back';
import EmptySearch from 'view/components/navigation/app-search/empty-search';
import RecentProvider from 'view/components/navigation/app-search/providers/recent-provider';
import generateProviderClasses from 'view/components/navigation/app-search/providers';

import { onElasticSearch } from './on-elastic-search';
import { SEARCH_INPUT_WIDTH } from 'view/components/navigation/shell/header/desktop-tablet';

const toOption = (type, pluckLabel, pluckValue, data) => ({
  data,
  type,
  label: pluckLabel(data),
  value: pluckValue(data)
});

/**
 * The higher this number, the lower the likelihood of
 * unnecessary requests being fired whilst a user types
 * in the search input... but it has the side effect
 * of delaying the initial search request
 *
 * Setting this too low (i.e. below ~350) may have a
 * noticeable impact on our overall infrastructure
 * load, given how frequently this component is used
 *
 * Setting too high makes the search sluggish
 *
 * Needs a good value based on "typical user" typing
 * speeds. Change with caution!
 *
 * @type {number}
 */
export const SEARCH_DEBOUNCE_RATE = 425; // ms

const styleOverrides = {
  AppSearchInput: StyleSheet({
    container: {
      zIndex: 1,
      maxWidth: SEARCH_INPUT_WIDTH,
      width: '100%'
    }
  }),

  TextInput: StyleSheet({
    container: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: 'none',
      background: COLORS.WHITE_30_OPACITY
    },
    input: {
      color: COLORS.WHITE,
      padding: 0,
      height: '36px',
      lineHeight: '17px',
      paddingLeft: PADDINGS.S,
      boxSizing: 'border-box'
    }
  }),

  SelectInput: StyleSheet({
    indicators: {
      marginRight: PADDINGS.S,
      lineHeight: 'initial',
      height: '100%'
    }
  }),

  ClearIndicator: StyleSheet({
    icon: {
      cursor: 'pointer',
      color: COLORS.WHITE,
      width: '17px',
      height: '17px'
    }
  }),

  MenuRecents: StyleSheet({
    container: {
      position: 'relative'
    }
  }),

  MenuSwitcher: StyleSheet({
    container: {
      maxHeight: 'calc(100vh - 100px)',
      minHeight: HEIGHTS.APP_MENU.EMPTY_STATE,
      width: SEARCH_INPUT_WIDTH,
      maxWidth: '100vw',
      right: 0
    }
  }),

  MenuEntities: StyleSheet({
    primary: {
      position: 'relative',
      paddingTop: `${PADDINGS.M}`,
      boxSizing: 'border-box'
    },
    secondary: {
      position: 'relative',
      paddingTop: `${PADDINGS.M}`,
      boxSizing: 'border-box'
    },
    wrapper: {
      paddingTop: `${PADDINGS.M}`,
      boxSizing: 'border-box'
    },
    loaded: {
      position: 'relative'
      // HACK: More details on why we do this can be found in shell/src/utils/styles.md
    }
  })
};

const searchSuffixStyles = {
  width: '17px',
  height: '17px'
};

const indicatorStyles = StyleSheet({
  icon: {}
});

@styled(indicatorStyles)
class ClearIndicator extends PureComponent {
  render() {
    const { styles: s, className, currentAgencyColor, ...props } = this.props;
    return (
      <div className={className} {...props}>
        <Icon
          {...s.with('icon')({ className })}
          color={headerColorContrast(currentAgencyColor)}
          type={ICONS.CLOSE}
        />
      </div>
    );
  }
}

const searchStyles = StyleSheet({
  wrapper: {
    width: '100%',
    maxWidth: SEARCH_INPUT_WIDTH,
    position: 'relative',

    // Inheritable Defaults
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    fontSize: FONT.SIZES.REGULAR,
    fontWeight: FONT.WEIGHTS.REGULAR,
    lineHeight: 1,
    borderSize: 'border-box',
    letterSpacing: FONT.SPACING.REGULAR,
    color: COLORS.DARK_GREY,

    // Override Rex Defaults (App Search Resets)
    '& input': {
      '&:hover': {
        border: 'none'
      },
      '&:focus': {
        border: 'none'
      },
      '&:active': {
        border: 'none'
      },
      '&::placeholder': {
        ...TEXTS.APP_SEARCH.HEADING,
        color: 'white',
        fontStyle: 'initial',
        textTransform: 'initial',
        fontSize: FONT.SIZES.REGULAR
      }
    },
    '& textarea, p': {
      lineHeight: 'inherit',
      fontSize: 'inherit'
    }
  },
  hidden: {
    display: 'none'
  },
  dark: {
    '& input': {
      color: 'black !important'
    },
    '& input::placeholder': {
      color: 'black !important'
    }
  }
});

@withModel(sessionModel)
@styled(searchStyles)
@autobind
class RexSearch extends PureComponent {
  metaKey = false;

  handleKeyDown(event) {
    this.metaKey = event.metaKey || event.ctrlKey || this.metaKey;
  }

  handleKeyUp() {
    this.metaKey = false;
  }

  componentDidMount() {
    // HACKIEST HACK EVER: since vivid's AppSearch is not passing through the original
    //   event (and I don't wanna touch that code atm) we listen to the key events here and
    //   set an internet variable whenever the meta (=command) key is pressed ... this doesn't
    //   cause a re-render, but we'll be able to check weather or not command is currently
    //   pressed when navigating to a record. In case it is pressed, we open the url in a
    //   new window (to mimic default browser behaviour!)
    // NOTE: the better way to solve this is to rewrite AppSearch ... there is A no need
    //   for it to be a Select abstraction, and B it should use <a> tags to be able to just
    //   use the browser behaviour when right clicking or command+click
    window.document.addEventListener('keydown', this.handleKeyDown);
    window.document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keydown', this.handleKeyDown);
    window.document.removeEventListener('keyup', this.handleKeyUp);
  }

  onChosenHandler(selectedOption) {
    this.handleOptionNavigation(selectedOption);
    this.props.onChosen && this.props.onChosen(selectedOption);
  }

  /**
   * Handles navigating the user to the selected options ID. This is fired when ever an option
   * is chosen, either from a recent or from a standard result menu.
   * @param option - The option that the user has clicked on from the list of results.
   * @returns {*} - Navigates to the chosen options record page.
   */
  handleOptionNavigation(option) {
    if (!option) return;
    const { data, type, value, ...rest } = option;
    const recordId = _.get(data, 'id') || value || _.get(rest, 'id');

    let recordUrl = _.get(rest, 'recordUrl');
    if (!recordUrl) {
      const serviceType = getServiceTypeForOptionType(type).toLowerCase();
      recordUrl =
        type === OPTION_TYPES.INVOICE
          ? `/listings/#id=${_.get(data, 'listing.id')}` +
            `&mode=financial&invoice_id=${_.get(data, 'id')}` +
            '&financialTab=invoicing'
          : `/${serviceType}/#id=${recordId}`;
    }

    if (recordUrl) {
      if (this.metaKey) {
        window.open(recordUrl, '_blank', 'noopener,noreferrer');
        return;
      }

      const route = { config: parseUrlToRoute(recordUrl) };
      return push(route);
    }
  }

  render() {
    const {
      styles: s,
      currentAgencyColor,
      hidden,
      recentActivity,
      appCuesId,
      session
    } = this.props;

    const app = session.isRosieAccount ? 'Rosie' : 'Rex';

    return (
      <StylesProvider
        components={styleOverrides}
        prioritiseParentStyles={false}
      >
        <div
          {...s.with('wrapper', {
            hidden: hidden,
            dark: headerColorContrast(currentAgencyColor) === '#000000'
          })({
            className: 'needsclick'
          })}
        >
          <AppSearch
            appCuesId={appCuesId}
            debounce={SEARCH_DEBOUNCE_RATE}
            isClearable
            hasScopedScroll
            shouldOpenOnFocus
            placeholder={`Search ${app}`}
            zenscroll={zenscroll}
            providers={generateProviderClasses()}
            doSearch={onElasticSearch}
            recentProvider={new RecentProvider()}
            recentOptions={recentActivity}
            shouldShowSectionBack
            SectionBack={SearchSectionBack}
            selectSuffix={
              <Icon
                color={headerColorContrast(currentAgencyColor)}
                type={ICONS.SEARCH}
                style={searchSuffixStyles}
              />
            }
            emptySearch={EmptySearch}
            onChosen={this.onChosenHandler}
            ClearIndicator={ClearIndicator}
            clearIndicatorProps={{
              className: 'needsclick',
              currentAgencyColor
            }}
            isHidden={hidden}
          />
        </div>
      </StylesProvider>
    );
  }
}

@connect((state) => ({
  recentActivity: _.get(state, 'session.recent_activity')
}))
class RexSearchStateful extends PureComponent {
  render() {
    const { recentActivity, ...props } = this.props;
    return <RexSearch {...props} recentActivity={recentActivity} />;
  }
}

export default RexSearchStateful;
