import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';

import { styled, StyleSheet } from '@rexlabs/styling';

import { DefaultButton } from 'shared/components/button';

import { COLORS, FONT } from 'shared/theme';
import { ICONS } from 'shared/components/icon';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    width: '100%',
    height: '100%'
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },

  heading: {
    color: COLORS.DARKER_GREY,
    fontWeight: 700,
    fontSize: '36px',
    paddingBottom: '19px'
  },

  paragraph: {
    maxWidth: '400px',
    color: COLORS.DARKER_GREY,
    fontSize: '16px',
    lineHeight: '22px',
    paddingBottom: '26px',
    textAlign: 'center',

    '& a': {
      color: 'inherit',
      fontWeight: 600,
      fontSize: '16px',
      textDecoration: 'underline',

      '&:hover': {
        opacity: '0.7'
      }
    }
  },

  button: {
    width: '100%',
    maxWidth: '400px'
  },

  illustration: {
    marginBottom: '40px'
  }
});

/**
 * This is used to override the DefaultButton component styles.
 */
const buttonStyles = StyleSheet({
  label: {
    fontSize: '16px'
  }
});

const handleCrashReload = () => (window.location.href = '/?crashed=true');

@styled(defaultStyles)
class ErrorScreen extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <Box {...s('container')}>
        <Box {...s('wrapper')}>
          <ICONS.CATASTROPHIC_ERROR {...s('illustration')} />
          <h1 {...s('heading')}>Something went wrong</h1>
          <p {...s('paragraph', 'paddingTop')}>
            We&apos;re sorry 😔 You&apos;ll need to reload. If this keeps
            happening, please{' '}
            <a href='mailto:support@rexsoftware.com.au'>contact</a> our Support
            team.
          </p>
          <DefaultButton
            styles={buttonStyles}
            {...s('button')}
            softblue
            large
            onClick={handleCrashReload}
          >
            OK, Reload
          </DefaultButton>
        </Box>
      </Box>
    );
  }
}

export default ErrorScreen;
