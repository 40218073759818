import _ from 'lodash';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';
import {
  hasPermission,
  isOwner,
  OPTION_TYPES
} from 'view/components/navigation/app-search/utils';
import { APP_MENU } from 'theme/legacy/components';
import ImageWithError from 'view/components/image-with-error';

import Option from './option';
import { getHazardly } from 'shared/utils/prefix-hell';

@styled(APP_MENU.RECORDS)
class ListingOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles: s, option, isActive } = this.props;
    const { data: listing, value, label } = option;

    const address = _.get(listing, 'address', '');
    const image = getHazardly(listing, 'image._thumbs.80x60._url');
    const owner = getHazardly(listing, 'system_owner_user.name', '');
    const status = _.capitalize(_.get(listing, 'status', ''));
    const type = _.capitalize(_.get(listing, 'category.text', ''));
    const userSecurityRights = getHazardly(listing, '_security_user_rights');
    const systemOwnerUser = _.get(listing, 'system_owner_user');
    const myListing = isOwner(systemOwnerUser);
    const ListingIcon = (
      <Icon key='listing-icon' type={ICONS.APP_SEARCH[OPTION_TYPES.LISTING]} />
    );

    return (
      <Option
        isActive={isActive}
        label={label}
        IconBox={[
          myListing && (
            <Icon
              key='owner-flag'
              {...s('ownerIcon')}
              type={ICONS.APP_SEARCH.OWNER}
            />
          ),
          !hasPermission(userSecurityRights, 'read') && (
            <Icon
              key='permission-flag'
              {...s('permissionIcon')}
              type={ICONS.APP_SEARCH.LOCK}
            />
          ),
          image && (
            <ImageWithError
              key='image'
              src={image}
              width='45px'
              height='45px'
              ErrorProp={ListingIcon}
            />
          ),
          !image && ListingIcon
        ]}
        OptionMeta={[
          <span key='address' {...s('name', 'ellipsis')}>
            {address}
          </span>,
          <span key='owner' {...s('meta', 'ellipsis')}>
            Owner: {owner}
          </span>,
          <span key='type' {...s('meta', 'ellipsis')}>
            Type: {type}
          </span>
        ]}
        OptionExtraMeta={[
          <span key='value' {...s('meta', 'extra', 'light')}>
            #{value}
          </span>,
          <span key='status' {...s('meta', 'extra', 'dark')}>
            {status}
          </span>
        ]}
      />
    );
  }
}

export default ListingOption;
