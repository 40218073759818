import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'deals';
const dealsModel = new Generator(TYPE).createEntityModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
dealsModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('Deals::autocomplete', { search_string: searchTerm })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((deal) => ({
          value: deal.id,
          label: deal.name,
          data: deal,
          model: dealsModel
        }))
      )
};

export default dealsModel;
