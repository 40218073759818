import React, { useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';

// Vivid components

import { PLACEMENTS } from '@rexlabs/tooltip';

// Shell
import RadioGroupInput from 'view/components/input/radio-buttons/group';
import DateSelect from 'view/components/input/select/date-select';
import { ReactForms, Form, FormField } from 'view/components/form';

import { useRegion } from 'hooks/use-region';

import { Popout } from 'view/components/popout';

import {
  useFilterConfigItem,
  useAvailableFieldsSelectOptions
} from 'features/custom-reporting/utils/get-filter-field-config';

import {
  getFixedPeriod,
  getDateRanges,
  DateRangesItem,
  radioButtonOptions,
  DateRangeSelectValue
} from 'features/custom-reporting/utils/date-ranges';
import { DateReportingFilterButton } from 'components/button/reporting-filter-button/date-reporting-filter-button';

// Shared
import ApplyCancelButtonBar from 'shared/components/button-bar/apply-cancel';
import { createValidationRules } from 'shared/utils/form';
import { Column, Grid } from 'shared/components/grid';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { getDateLabel } from 'utils/get-date-label';

import PaddingBox from 'view/components/padding-box';

import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import { useReportingDateValues } from 'features/custom-reporting/hooks/use-reporting-date-values';

interface FromValues {
  date_range: DateRangeSelectValue;
  fixed_from?: string;
  fixed_to?: string;
  date_filter_preference: string;
}

function handleValidation(values: FromValues, extra) {
  const messages = {
    'required.fixed_to': 'Please select both dates for the period',
    'required.fixed_from': 'Please select both dates for the period'
  };
  const errors = {};

  if (values.date_range === 'fixed_period') {
    const fixedFromValue = values?.fixed_from;
    const fixedToValue = values?.fixed_to;

    const fromDate = fixedFromValue && dayjs(fixedFromValue);
    const toDate = fixedToValue && dayjs(fixedToValue);

    // If no values, throw error
    if (!fixedFromValue && !fixedToValue) {
      _.set(errors, 'fixed_to', ['required', 'fixed_to']);
      _.set(errors, 'fixed_from', ['required', 'fixed_from']);
    }
    // If there is a from date, but if's after today and there is no to date, throw error
    if (fromDate && fromDate.isAfter(dayjs()) && !fixedToValue) {
      _.set(errors, 'fixed_to', ['required', 'fixed_to']);
    }
    // If to date, but no from, throw error
    if (toDate && !fromDate) {
      _.set(errors, 'fixed_from', ['required', 'fixed_from']);
    }
  }

  return createValidationRules(errors, messages)(values, extra);
}

// TODO - make this component dumber and less opinionated by passing in
// the reporting-specific data
// SC - https://app.shortcut.com/rexlabs/story/67576/date-filter-split-up-by-creating-dumber-popout-component-with-report-specific-wrapper
// TODO: pull config from useReportState
export function DateFilterPopout() {
  const region = useRegion();

  const dateFilterPreferenceOptions = useAvailableFieldsSelectOptions('date');

  const {
    userSelectedReportState: {
      selectedDateFilter,
      shouldFilterByDate,
      selectedDateFieldPreference
    },
    setDateFilterState
  } = useReportState();

  const filterConfig = useFilterConfigItem({
    filterType: 'date',
    fieldPreference: selectedDateFieldPreference
  });

  const selectedDateRange = useReportingDateValues(selectedDateFilter);

  const { preset, range } = selectedDateFilter || {};

  const isFixedPeriod = !!(preset === 'fixed_period');

  const initialValues = useMemo(() => {
    const initialValues = {
      date_range: shouldFilterByDate === false ? 'none' : preset,
      date_filter_preference: filterConfig?.fieldName
    };
    if (isFixedPeriod) {
      const [start, end] = range || [];
      _.set(initialValues, 'fixed_from', start);
      _.set(initialValues, 'fixed_to', end);
    }
    return initialValues;
  }, [
    shouldFilterByDate,
    preset,
    filterConfig?.fieldName,
    isFixedPeriod,
    range
  ]);

  const selectedDatePreference = dateFilterPreferenceOptions.find(
    (option) => option.value === selectedDateFieldPreference
  );

  const filterHeader = getDateLabel({
    range: selectedDateRange,
    suffix: selectedDatePreference?.label
      ? ` (${selectedDatePreference.label})`
      : ''
  });

  function handleSave(values: FromValues) {
    Analytics.track({
      event: EVENTS.CUSTOM_REPORTING.MODIFY_DATE_FILTER,
      properties: {
        preset: values?.date_range
      }
    });

    const isFixedPeriod = values?.date_range === 'fixed_period';

    const newDateRange = radioButtonOptions.find((opt) => {
      return opt.value === values?.date_range;
    });

    const range: DateRangesItem = isFixedPeriod
      ? getFixedPeriod({
          fixedTo: values.fixed_to,
          fixedFrom: values.fixed_from
        })
      : (newDateRange && getDateRanges(region.code)[newDateRange.value]) || [];

    setDateFilterState({
      dateRangeValue: {
        preset: values?.date_range,
        ...(isFixedPeriod
          ? {
              range: [range?.start, range?.end]
            }
          : {})
      },
      dateFilterPreference: values.date_filter_preference
    });
  }

  return (
    <Popout
      placement={PLACEMENTS.BOTTOM_START}
      Content={({ close }) => (
        <PaddingBox width='340px'>
          <ReactForms
            handleSubmit={async (values) => {
              await handleSave(values);
              close();
            }}
            initialValues={initialValues}
            validate={handleValidation}
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => {
              return (
                <Form hasErrorPadding>
                  <FormField
                    name='date_range'
                    Input={RadioGroupInput}
                    onChange={({ target }) => {
                      const { value }: { value: string } = target;
                      setFieldValue('date_range', value);
                      if (value === 'fixed_period' && values?.date_range) {
                        const range: DateRangesItem = getDateRanges(
                          region.code
                        )[values.date_range];
                        setFieldValue('fixed_from', range.start);
                        setFieldValue('fixed_to', range.end);
                      }
                    }}
                    inputProps={{
                      /*
                       * @TODO: currently these options will throw prop type warnings due to
                       *   @rexlabs/radio-input/RadioGroupInput AND RadioButton propTypes requiring label to be a string.
                       *   It works fine but logs an error in the console. Only proceeding because this will disappear
                       *   when we upgrade to the latest version of vivid.
                       */
                      options: radioButtonOptions,
                      valueAsObject: true
                    }}
                  />
                  {/* We do this because it will throw an error when we prefill the fixed range inputs without them mounted */}
                  <div
                    style={{
                      display:
                        values?.date_range === 'fixed_period' ? 'block' : 'none'
                    }}
                  >
                    <Grid>
                      <Column width={6}>
                        <FormField
                          sendImmediate
                          name='fixed_from'
                          label='From'
                          Input={DateSelect}
                          inputProps={{
                            sendImmediately: false,
                            placeholder: dayjs().format('D/MM/YY')
                          }}
                        />
                      </Column>
                      <Column width={6}>
                        <FormField
                          sendImmediate
                          name='fixed_to'
                          label='To'
                          Input={DateSelect}
                          inputProps={{
                            placeholder: dayjs().format('D/MM/YY')
                          }}
                        />
                      </Column>
                    </Grid>
                  </div>

                  {dateFilterPreferenceOptions.length > 1 && (
                    <FormField
                      name='date_filter_preference'
                      label='Filter by date of:'
                      Input={RadioGroupInput}
                      onChange={({ target }) => {
                        const { value }: { value: string } = target;
                        setFieldValue('date_filter_preference', value);
                      }}
                      inputProps={{
                        options: dateFilterPreferenceOptions,
                        valueAsObject: true
                      }}
                    />
                  )}
                  <ApplyCancelButtonBar
                    isLoading={isSubmitting}
                    onCancel={() => close()}
                    onSave={submitForm}
                    isDisabled={false}
                  />
                </Form>
              );
            }}
          </ReactForms>
        </PaddingBox>
      )}
    >
      <DateReportingFilterButton>{filterHeader}</DateReportingFilterButton>
    </Popout>
  );
}
