import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';
import { styled, StyleSheet } from '@rexlabs/styling';

import { MENU_ITEM_HEIGHT } from '../constants';

const defaultStyles = StyleSheet({
  header: {
    position: 'fixed',
    height: `${MENU_ITEM_HEIGHT}px`,
    top: 0,
    left: 0
  },
  scopedHeader: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center'
  },
  backIcon: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',

    '& > svg': {
      transform: 'rotate(90deg)'
    }
  },
  label: {
    paddingLeft: '20px'
  },
  scrollableShadow: {
    boxShadow: '0 -3px 20px rgba(154, 164, 180, 0.2)',
    border: 'none'
  }
});

@styled(defaultStyles)
class MenuHeader extends PureComponent {
  render() {
    const {
      styles: s,
      isScoped,
      headingLabel,
      showTopScrollShadow,
      handleViewModeChange
    } = this.props;

    const currentHeader = isScoped ? (
      <Box {...s('scopedHeader')} onClick={handleViewModeChange('MAIN')}>
        <Icon
          width={'100%'}
          height={'100%'}
          type={ICONS.CHEVRON}
          {...s('backIcon')}
        />
        <span {...s('label')}>{headingLabel}</span>
      </Box>
    ) : (
      headingLabel
    );

    return (
      <Box {...s('header', { scrollableShadow: showTopScrollShadow })}>
        {currentHeader}
      </Box>
    );
  }
}

export default MenuHeader;
