// import { useClassicDialog } from 'hooks/use-classic-dialog';
import { useMemo } from 'react';

// function mapSelectionToCriteria({ selection: { type, ids } }) {
//   return [
//     {
//       name: 'id',
//       type: type === 'include' ? 'in' : 'notin',
//       value: ids
//     }
//   ];
// }

export function useListingsListActions() {
  // const mailMergeWizard = useClassicDialog('mailMergeWizard');

  return useMemo(
    () => [
      {
        label: 'Merge',
        items: [
          {
            label: 'Email',
            onClick: () => {
              // { selection }
              // const criteria = mapSelectionToCriteria({ selection });
              // mailMergeWizard.open({
              //   module: 'listings',
              //   medium: 'email',
              //   criteriaOrReminders: criteria
              // });
            }
          },
          {
            label: 'SMS',
            onClick: () => {
              // { selection }
              // const criteria = mapSelectionToCriteria({ selection });
              // mailMergeWizard.open({
              //   module: 'listings',
              //   medium: 'sms',
              //   criteriaOrReminders: criteria
              // });
            }
          },
          {
            label: 'Letter',
            onClick: () => {
              // { selection }
              // const criteria = mapSelectionToCriteria({ selection });
              // mailMergeWizard.open({
              //   module: 'listings',
              //   medium: 'letter',
              //   criteriaOrReminders: criteria
              // });
            }
          }
        ]
      },
      {
        label: 'Report *',
        items: [
          {
            label: 'Public List (Incomplete)',
            onClick: ({ selection }) => console.info('Email', selection)
          },
          {
            label: 'Full Advert (Incomplete)',
            onClick: ({ selection }) => console.info('SMS', selection)
          },
          {
            label: 'Public List (compact) (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Internal List (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Internal List (compact) (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Project Stocklist (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Mass Brochure (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          }
        ]
      },
      {
        label: 'Mass Actions *',
        items: [
          {
            label: 'Add Tags (Incomplete)',
            onClick: ({ selection }) => console.info('Email', selection)
          },
          {
            label: 'Remove Tags (Incomplete)',
            onClick: ({ selection }) => console.info('SMS', selection)
          },
          {
            label: 'Reassign Reminders (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Reassign Owner (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Assign Permissions (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Remove Permissions (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          }
        ]
      },
      {
        label: 'Publication Actions *',
        items: [
          {
            label: 'Change Publication Channels (Incomplete)',
            onClick: ({ selection }) => console.info('Email', selection)
          },
          {
            label: 'Publish (Incomplete)',
            onClick: ({ selection }) => console.info('SMS', selection)
          },
          {
            label: 'Reset Portal Statuses (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Change Portal Feed Status (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Force Portal Upload (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          },
          {
            label: 'Remove Permissions (Incomplete)',
            onClick: ({ selection }) => console.info('Letter', selection)
          }
        ]
      },
      {
        label: 'Export *',
        items: [
          {
            label: 'Data Export (Incomplete)',
            onClick: ({ selection }) => console.info('Email', selection)
          },
          {
            label: 'Label / Mail Merge Export (Incomplete)',
            onClick: ({ selection }) => console.info('SMS', selection)
          }
        ]
      },
      {
        label: 'Go To Related *',
        onClick: ({ selection }) => console.info('SMS', selection)
      },
      {
        label: 'Pocket Agenda *',
        onClick: ({ selection }) => console.info('SMS', selection)
      }
    ],
    []
    // [mailMergeWizard]
  );
}
