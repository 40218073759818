import { ProjectsRecordScreen } from '../screens/projects-record-screen';

export const projectsRoutes = {
  PROJECTS_RECORD: {
    config: ({ hasFlags }) => ({
      // only replace classic route if the user has the respective feature flag active
      path: hasFlags('record_screen_projects')
        ? '/projects/'
        : '/experiment/projects/',
      Component: ProjectsRecordScreen
    })
  }
};
