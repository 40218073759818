import React from 'react';

import Fixture from './default';

interface Props {
  onClick: Function;
  term: string;
  fixturePrefillData: { callback: Function };
}

const OriginFixture: React.FC<Props> = ({
  onClick,
  term,
  fixturePrefillData
}) => {
  return (
    <Fixture addNewEntity={() => onClick(term, fixturePrefillData.callback)}>
      ADD NEW ORIGIN
    </Fixture>
  );
};

export default OriginFixture;
