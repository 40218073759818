/**
 * Wings has two ways to describe an object, its module name (or object type) and its
 * service name:
 *
 * e.g. for listings:
 *   module name = listings
 *   service name = Listings
 *
 * The helpers here are aiming to simplify our references on the FE, ideally we can
 * purely deal with the service name and infer the module name wherever needed from
 * that service name
 */

import { snakeCase } from 'lodash';

const MAP = {
  // TODO: map any module names that are not just snake case versions
  // of their respective service name
};

export function getModuleName(serviceName) {
  return MAP[serviceName] || snakeCase(serviceName);
}
