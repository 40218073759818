import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'adminWorkflows';

const actionCreators = {
  getServiceTriggerMap: {
    request: () =>
      api
        .post('AdminWorkflows::getServiceTriggerMap')
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getNextCronRun: {
    request: (payload) =>
      api
        .post('AdminWorkflows::getEvaluatedCronExpression', payload)
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  archiveItem: {
    request: (payload) =>
      api
        .post('AdminWorkflows::archive', payload)
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getValidatedManualTriggerData: {
    request: (payload) =>
      api
        .post('AdminWorkflows::getValidatedManualTriggerData', payload)
        .then((response) =>
          _.get(response, 'data.result.required_input.context')
        ),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getRelatedServices: {
    request: (payload) =>
      api
        .post('AdminWorkflows::getRelatedServices', payload)
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const adminWorkflowsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default adminWorkflowsModel;
