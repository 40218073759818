import React, { Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import env from 'shared/utils/config';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'shared/theme/index';

import { withDialog } from 'shared/hocs/with-dialog';
import EnvironmentDialog from 'view/dialogs/environment';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

const { REX_LOGO: RexLogo } = ICONS;

const defaultStyles = StyleSheet({
  container: {},

  rexLogo: {
    width: 'auto',
    height: '2.8rem',
    position: 'relative',
    left: '1.5rem',
    marginRight: '1.7rem',
    color: COLORS.WHITE
  },

  rosieLogo: {
    left: '1.9rem',
    height: '2.6rem',
    width: '2.2rem',
    marginRight: '3rem',
    position: 'relative'
  },

  separator: {
    width: '0.1rem',
    height: '3rem',
    background: 'rgba(255,255,255,0.25)',
    borderRadius: '.1rem',
    marginLeft: '2rem'
  },

  agencyName: {
    color: COLORS.WHITE,
    fontSize: '2rem',
    fontWeight: '600',
    lineHeight: '2.6rem',
    marginLeft: '2rem',
    position: 'relative'
  },

  environment: {
    background: 'rgba(0,0,0,0.15)',
    color: COLORS.WHITE,
    padding: '0.4rem 0.8rem',
    borderRadius: '0.2rem',
    marginLeft: '0.8rem',
    fontSize: '1.1rem',
    fontWeight: 600,
    textDecoration: 'none'
  },
  separatorDark: {
    background: 'rgba(0,0,0,0.25)'
  },
  dark: {
    filter: 'invert(1)'
  }
});

function AgencyIdentity({
  name = 'Rex',
  logo,
  styles: s,
  environment,
  currentAgencyColor
}) {
  return (
    <Box justifyContent='center' alignItems='center' {...s('container')}>
      <div
        {...s({
          dark: headerColorContrast(currentAgencyColor) === '#000000'
        })}
      >
        {name === 'Rex' ? (
          <RexLogo {...s('rexLogo')} />
        ) : (
          <img {...s('rosieLogo')} src={logo} alt={name} />
        )}
      </div>
      {!!name && (
        <Fragment>
          {name !== 'Rex' && (
            <span
              {...s('agencyName')}
              style={{ color: headerColorContrast(currentAgencyColor) }}
            >
              {name}
            </span>
          )}
          {!!env.ENVIRONMENT && (
            <a
              {...s('environment')}
              href='#'
              onClick={(e) => {
                e.preventDefault();
                environment.open();
              }}
            >
              {env.ENVIRONMENT}
            </a>
          )}
          <div
            {...s('separator', {
              separatorDark:
                headerColorContrast(currentAgencyColor) === '#000000'
            })}
          />
        </Fragment>
      )}
    </Box>
  );
}

export default withDialog(EnvironmentDialog, { propName: 'environment' })(
  styled(defaultStyles)(AgencyIdentity)
);
