import React, { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';

import { ICONS } from 'shared/components/icon';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { FeedbackData } from 'utils/dialogs/mapping-classic-interfaces';

import { useAddFeedback } from 'hooks/use-add-feedback';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import { IconValueStatusButton } from 'components/button/icon-value-status-button';

import { getActivityStatus } from 'features/leads/utils/get-activity-status';

type ButtonProps = Pick<
  ComponentProps<typeof IconValueStatusButton>,
  'status' | 'helpText'
>;

interface AddFeedbackActionButtonProps extends ButtonProps {
  data: LeadsResponse;
  errorDialog: ErrorDialog;
  callback: () => any;
  activitiesList: Activity[];
}

function getFeedbackData(data: LeadsResponse): FeedbackData {
  const {
    assignee,
    contact,
    listing,
    project_stage,
    project,
    id,
    system_ctime,
    lead_source,
    lead_type
  } = data;

  const agent = assignee
    ? {
        id: assignee.id,
        name: assignee.name
      }
    : null;

  const relatedData = {
    feedback_contacts: contact ? [{ contact }] : []
  };

  const projectStageData = (!listing && project_stage) || null;

  const projectData = (!listing && !project_stage && project) || null;

  const dateOfData = system_ctime
    ? dayjs(parseInt(system_ctime) * 1000).format('YYYY-MM-DD')
    : null;

  const feedbackTypeData =
    lead_type?.id === 'listing_enquiry'
      ? { id: 'enquiry', text: 'Enquiry' }
      : null;

  return {
    related: relatedData,
    listing,
    project_stage: projectStageData,
    project: projectData,
    lead_id: id,
    date_of: dateOfData,
    agent,
    feedback_type: feedbackTypeData,
    enquiry_source: lead_source
  };
}

export const AddFeedbackActionButton = withErrorDialog(
  ({
    data,
    errorDialog,
    activitiesList,
    callback
  }: AddFeedbackActionButtonProps) => {
    const feedbackStatus = getActivityStatus('feedback_added', activitiesList);

    const feedbackData = useMemo(() => {
      return getFeedbackData(data);
    }, [data]);

    const onFeedbackClick = useAddFeedback({
      data: feedbackData,
      mode: 'both',
      errorDialog,
      callback
    });

    const isListingEnquiry = data?.lead_type?.id === 'listing_enquiry';

    return (
      <IconValueStatusButton
        iconType={ICONS.FEEDBACK}
        onClick={onFeedbackClick}
        status={
          feedbackStatus
            ? 'Added'
            : data.auto_created_feedback_id
            ? 'Auto-added'
            : null
        }
        helpText={
          feedbackStatus &&
          `Feedback added ${dayjs(
            feedbackStatus.system_ctime * 1000
          ).fromNow()} by ${feedbackStatus.system_created_user.name}`
        }
      >
        {isListingEnquiry ? 'Add Enquiry Feedback' : 'Add Feedback'}
      </IconValueStatusButton>
    );
  }
);
