import React from 'react';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import { PADDINGS } from 'theme';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  subheading: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export function RecordPreviewSubheading({
  children,
  testId
}: {
  children: React.ReactNode;
  testId?: string;
}) {
  const s = useStyles(defaultStyles);
  return (
    <Box pt={PADDINGS.XXS}>
      <Body
        {...s('subheading')}
        regular
        black
        normal
        data-testid={testId || 'RecordPreview.SubHeading'}
      >
        {children}
      </Body>
    </Box>
  );
}
