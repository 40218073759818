/* eslint-disable max-lines */
import React, { PureComponent, Fragment } from 'react';

import { FormField, FieldArray } from 'view/components/form';
import { Grid, Column } from 'shared/components/grid';
import { Select, DateSelect, EntitySelect } from 'view/components/input/select';
import Box from '@rexlabs/box';
import PaddingBox from 'view/components/padding-box';
import { autobind } from 'core-decorators';
import contactsModel from 'data/models/entities/contacts';
import listingsModel from 'data/models/entities/listings';
import UploaderWithForm from 'view/components/upload/file-upload-multi/uploader-with-form.js';
import { ListingOfferInput } from 'view/components/input/repeating-fields';
import { IconButton, DefaultButton } from 'view/components/button';
import { Body } from 'components/text/body';
import { SubHeading } from 'components/text/sub-heading';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS, COLORS, PADDINGS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import _ from 'lodash';
import Requires from 'view/containers/requires';
import { connect } from 'react-redux';
import { checkUserHasPermission } from 'utils/rights';

import { withModel } from '@rexlabs/model-generator';
import workflowInstancesModel from 'data/models/entities/workflow-instances';
import adminWorkflowsModel from 'data/models/entities/admin-workflows';

import { withDialog } from 'shared/hocs/with-dialog';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { ConfirmWorkflowDialog } from 'view/dialogs/workflow';

const defaultStyles = StyleSheet({
  label: {
    width: '50%',
    marginBottom: '5px',
    ...TEXTS.CONTENT.FORM_FIELD_LABEL_1
  },

  first: {
    marginRight: '-10px'
  },

  second: {
    marginRight: '30px'
  },

  limitToOfferHeader: {
    ...TEXTS.CONTENT.INPUT_TEXT_SEMIBOLD,
    fontSize: '17px',
    marginBottom: '14px'
  },

  field: {
    width: '100%'
  },

  infoIcon: {
    color: COLORS.PRIMARY.SAND_LIGHT,
    marginRight: PADDINGS.XXS
  }
});

@withErrorDialog
@withModel(adminWorkflowsModel)
@withModel(workflowInstancesModel)
@withDialog(ConfirmWorkflowDialog, { propName: 'confirmWorkflow' })
@connect((state) => ({ session: state.session }))
@styled(defaultStyles)
@autobind
class TabSubstantiation extends PureComponent {
  substantiationStatusOptions = [];
  substantiationPositionOptions = [];
  substantiationFundSourceOptions = [];
  substantiationMethodOptions = [];

  state = {
    isStartingWorkflow: false
  };

  shapifyContacts(substantiationContact) {
    const { contact } = substantiationContact;

    return {
      data: contact,
      model: contactsModel,
      label: _.get(contact, 'name') || _.get(contact, 'system_search_key'),
      value: _.get(contact, 'id')
    };
  }

  shapifyListingOptions(contractListing) {
    return {
      data: contractListing,
      label: contractListing.property.system_search_key,
      model: listingsModel,
      value: contractListing.id
    };
  }

  mapLimitToOfferInitialOptions(intitialLTO) {
    const initialListingOptions = [];
    const initialContractOptions = [];
    intitialLTO.map((lto) => {
      initialListingOptions.push(
        this.shapifyListingOptions(lto.contract.listing)
      );
      initialContractOptions.push(lto.contract);
    });
    return {
      initialListingOptions,
      initialContractOptions
    };
  }

  handleFailedWorkflow(error) {
    this.setState({ isStartingWorkflow: false });
    this.props.errorDialog.open(error);
  }

  startWorkflow() {
    const { adminWorkflows, workflowId, confirmWorkflow, context } = this.props;

    this.setState({ isStartingWorkflow: true }, () => {
      adminWorkflows
        .fetchItem({ id: workflowId })
        .then(({ data }) => {
          const executeWorkflow = this.executeWorkflow(workflowId);

          const formDefinition = _.get(
            data,
            'definition.required_input.form',
            null
          );

          if (formDefinition) {
            confirmWorkflow.open({
              context,
              formSchema: formDefinition,
              onSubmit: executeWorkflow,
              onClose: () => {
                this.setState({ isStartingWorkflow: false });
              },
              workflowName: _.get(data, 'name'),
              workflowDescription: _.get(data, 'description')
            });
          } else {
            executeWorkflow().catch(this.handleFailedWorkflow);
          }
        })
        .catch(this.handleFailedWorkflow);
    });
  }

  executeWorkflow(workflowId) {
    return (formValues) => {
      return new Promise((resolve, reject) => {
        const { context, workflowInstances, closeDialog } = this.props;

        workflowInstances
          .execute({ id: workflowId, context, formValues })
          .then((response) => {
            closeDialog();
            resolve(response);
          })
          .catch(reject);
      });
    };
  }

  withWorkflow() {
    const { isStartingWorkflow } = this.state;

    return (
      <Fragment>
        <Body semibold>
          You do not have permission to update the substantiation status.
        </Body>
        <Body semibold>
          Please run the workflow {'"Request offer substantiation"'}.
        </Body>
        <Box paddingTop='15px'>
          <DefaultButton
            onClick={this.startWorkflow}
            isDisabled={isStartingWorkflow}
            IconLeft={ICONS.WAND}
          >
            Start Workflow
          </DefaultButton>
        </Box>
      </Fragment>
    );
  }

  withoutWorkflow() {
    const { styles: s } = this.props;

    return (
      <Fragment>
        <Icon {...s('infoIcon')} type={ICONS.INFO} />
        <Body semibold>
          You do not have permission to update the substantiation status.
        </Body>
      </Fragment>
    );
  }

  permissionsSwitch() {
    const hasWorkflow =
      (this.checkUserHasPermission('addon.workflows') ||
        this.checkUserHasPermission('addon.system_workflows')) &&
      this.props.workflowId !== null;
    const displayPermissionMessage = hasWorkflow
      ? this.withWorkflow
      : this.withoutWorkflow;

    const boxProps = {
      light: hasWorkflow,
      flex: !hasWorkflow,
      mt: '30px'
    };
    if (!hasWorkflow) {
      boxProps.alignItems = 'center';
      boxProps.mt = '0';
      boxProps.pl = '0';
      boxProps.pb = '0';
    }

    return <PaddingBox {...boxProps}>{displayPermissionMessage()}</PaddingBox>;
  }

  checkUserHasPermission(accessRights) {
    const { session } = this.props;
    return checkUserHasPermission((dotNotation) => _.get(session, dotNotation))(
      accessRights
    );
  }

  render() {
    const {
      substantiationContacts,
      valueLists: {
        substantiationStatus,
        substantiationPosition,
        substantiationFundSource
      },

      initialLimitToOffers,
      initialFiles,
      initialLTOvalues,
      form,
      styles: s
    } = this.props;

    return (
      <PaddingBox>
        <FormField
          label='substantiation applies to'
          name='substantiationContacts'
          Input={EntitySelect}
          inputProps={{
            models: [contactsModel],
            multi: true,
            hasFixtures: false,
            options: _.map(substantiationContacts, this.shapifyContacts)
          }}
        />
        <Requires accessRights={'!contacts.update_substantiation_status'}>
          {this.permissionsSwitch()}
        </Requires>

        <Grid>
          <Column width={12}>
            <div style={{ zIndex: '4000' }}>
              <FormField
                label='status'
                name='statusId'
                Input={Select}
                inputProps={{
                  // TODO: plug in the correct permissions
                  disabled: !this.checkUserHasPermission(
                    'contacts.update_substantiation_status'
                  ),
                  options: substantiationStatus.items
                }}
              />
            </div>
          </Column>

          <Column width={6}>
            <FormField
              sendImmediate
              label='expires on'
              name='expiryDate'
              Input={DateSelect}
              inputProps={{
                disabled: !this.checkUserHasPermission(
                  'contacts.update_substantiation_status'
                )
              }}
            />
          </Column>

          <Column width={6}>
            <FormField
              label='buyer purchasing position'
              name='positionId'
              Input={Select}
              inputProps={{
                options: substantiationPosition.items
              }}
            />
          </Column>

          <Column width={6}>
            <FormField
              label='source of funds for deposit'
              name='sourceOfFundsDepositId'
              Input={Select}
              inputProps={{
                options: substantiationFundSource.items
              }}
            />
          </Column>

          <Column width={6}>
            <FormField
              label='source of funds for purchase price'
              name='sourceOfFundsBalanceId'
              Input={Select}
              inputProps={{
                options: substantiationFundSource.items
              }}
            />
          </Column>
        </Grid>

        <Box mt={25}>
          <UploaderWithForm
            initialFiles={initialFiles}
            form={form}
            fieldName='documents'
            fileUploadProps={{
              acceptExtensions: '*',
              acceptTypes: '*'
            }}
          />
        </Box>

        <Box mt={25}>
          <SubHeading semibold>Limit to Offer</SubHeading>
        </Box>
        <Box mt={10}>
          <Body normal regular>
            Select a listing and an offer to limit this substantiation to that
            offer only.
          </Body>
        </Box>

        <FieldArray
          initialFields={_.map(initialLTOvalues, (lto) => {
            return {
              initialValue: {
                listingValue: lto.contract.listing.id,
                offerValue: lto.contract.id,
                ltoID: lto.id
              }
            };
          })}
          name='listingLimitToOffer'
          Input={ListingOfferInput}
          inputProps={{
            ...this.mapLimitToOfferInitialOptions(initialLimitToOffers)
          }}
        >
          {({ fields, getFieldProps, push, remove }) => {
            return (
              <Fragment>
                {_.get(form, 'values.listingLimitToOffer', []).length > 0 && (
                  <Box {...'container'} flexDirection={'row'} mt={10}>
                    <Box {...s('label', 'first')}>listing</Box>
                    <Box {...s('label', 'second')}>offer</Box>
                  </Box>
                )}

                {fields.map((field, index) => {
                  const { key, ...rest } = getFieldProps(field, index);

                  return (
                    // NOTE: it's very important that the key is used here
                    <Box
                      key={key}
                      {...s('fieldContainer', {
                        fieldPadding: index !== fields.length - 1
                      })}
                      flexDirection={'row'}
                    >
                      <Box {...s('field')}>
                        <FormField {...rest} />
                      </Box>
                      <IconButton
                        Icon={ICONS.REMOVE}
                        circle
                        red
                        type='button'
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </Box>
                  );
                })}
                <IconButton
                  Icon={ICONS.ADD_MEDIUM}
                  type='button'
                  onClick={() => {
                    push({
                      initialValue: { listingValue: '', offerValue: '' }
                    });
                  }}
                >
                  Limit to an offer
                </IconButton>
              </Fragment>
            );
          }}
        </FieldArray>
      </PaddingBox>
    );
  }
}

export default TabSubstantiation;
