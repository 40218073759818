import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'addressLookup';

const actionCreators = {
  getAddresses: {
    request: ({ postcode }) =>
      api.post('AddressLookup::getAddresses', {
        postcode
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const addressLookupModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

addressLookupModel.select = {
  autocomplete: (searchTerm, { postcode }) =>
    api
      .post('AddressLookup::getAddresses', {
        postcode
      })
      .then(({ data }) => {
        return (_.get(data, 'result') || []).map((address) => {
          return {
            value: _.get(address, 'formats.single_line'),
            label: _.get(address, 'formats.single_line'),
            data: address,
            model: addressLookupModel
          };
        });
      })
};

export default addressLookupModel;
