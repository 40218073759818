import React, { ReactElement, ReactNode } from 'react';
import Container from './container';
import ContentContainer from './content-container';
import LoadingSpinner from './loading-spinner';
import HeadingWithButton, {
  Heading
} from 'view/components/heading-with-button';

import { ICONS } from 'shared/components/icon';
import useHoverToggle from 'src/hooks/use-hover-toggle';
import { HeadingWithIcon } from 'view/components/heading';

interface ActionWidgetProps {
  heading: ReactNode;
  icon?: (typeof ICONS)[string];
  action?: {
    iconButton: (typeof ICONS)[string];
    onClick: (args?: unknown) => void;
  };
  children: ReactNode;
  isLoading?: boolean;
}

function ActionWidget({
  heading,
  action,
  isLoading = false,
  children,
  icon
}: ActionWidgetProps): ReactElement {
  const { hoverProps, hover } = useHoverToggle();

  return (
    <Container {...hoverProps} data-testid={'Widget.Container'}>
      {heading && icon && <HeadingWithIcon heading={heading} icon={icon} />}
      {heading && !icon && <Heading>{children}</Heading>}
      {action && (
        <HeadingWithButton
          heading={heading}
          showButton={!isLoading && hover}
          iconButtonIcon={action.iconButton}
          iconButtonOnClick={action.onClick}
          buttonProps={{ 'data-testid': 'Widget.IconButton' }}
        />
      )}

      <ContentContainer>
        {isLoading ? <LoadingSpinner /> : children}
      </ContentContainer>
    </Container>
  );
}

export default ActionWidget;
