import React, { useMemo } from 'react';

import { useStyles, StyleSheet } from '@rexlabs/styling';

interface GetStylesArgs {
  border?: React.CSSProperties['border'];
  borderRadius?: React.CSSProperties['borderRadius'];
  width?: React.CSSProperties['width'];
}

type WrapperButtonProps = GetStylesArgs &
  React.PropsWithChildren<{
    onClick?: (args: any) => any;
  }>;

function getStyles({
  width = 'auto',
  border = 'none',
  borderRadius = '0'
}: GetStylesArgs) {
  return StyleSheet({
    wrapperButton: {
      border: border,
      backgroundColor: 'transparent',
      borderRadius: borderRadius,
      padding: '0',
      cursor: 'pointer',
      width
    }
  });
}

export function WrapperButton({
  children,
  width,
  border,
  borderRadius,
  ...rest
}: WrapperButtonProps) {
  const defaultStyles = useMemo(() => {
    return getStyles({ width, border, borderRadius });
  }, [width, border, borderRadius]);

  const s = useStyles(defaultStyles);

  return (
    <button {...rest} {...s('wrapperButton')}>
      {children}
    </button>
  );
}
