import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import React, { ReactNode } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { AiLogo } from 'features/ai/components/ai-logo';

export const popoutContentStyles = StyleSheet({
  container: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  header: {
    fontSize: '12px',
    lineHeight: '14px',
    padding: '7px',
    color: COLORS.DARK_GREY,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '6px'
  },
  button: {
    backgroundColor: COLORS.WHITE,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px',
    border: 0,
    height: '27px',
    fontSize: '14px',
    lineHeight: '17px',
    webkitAppearance: 'none',
    borderRadius: '4px',
    color: COLORS.DARK_GREY,
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(208, 231, 246, 1)'
    }
  }
});

interface AiPopoutContentsProps {
  items: { label: string; onClick: () => void; isChecked?: boolean }[];
  children?: ReactNode;
  minWidth?: number;
}

export function AiPopoutContents({
  items,
  children,
  minWidth = 325
}: AiPopoutContentsProps) {
  const s = useStyles(popoutContentStyles);

  return (
    <div {...s('container')} style={{ minWidth: minWidth }}>
      <span {...s('header')}>
        <AiLogo style={{ width: '15px', height: '15px' }} />
        Use Rex AI to refine your text
      </span>
      {items.map((item) => (
        <div key={item.label} {...s('button')} onClick={item.onClick}>
          {item.label}
          {item.isChecked ? (
            <Icon
              style={{ position: 'relative', top: '2px', left: '4px' }}
              type={ICONS.TICK}
            />
          ) : null}
        </div>
      ))}
      {children}
    </div>
  );
}
