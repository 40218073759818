import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { InputTag } from 'view/components/tag';
import { TEXTS } from 'src/theme';
import _ from 'lodash';

const errorOverride = {
  InputTag: StyleSheet({
    item: {
      backgroundColor: '#F6E5E4'
    },
    icon: {
      color: '#B13024'
    },
    close: {
      color: 'black'
    }
  })
};

const defaultStyles = StyleSheet({
  container: {
    ...TEXTS.CONTENT.INPUT_TEXT_REGULAR
  }
});

@styled(defaultStyles)
@autobind
class DefaultValue extends Component {
  handleOptionClick(value) {
    const { onClick } = this.props;
    onClick && onClick(value);
  }

  handleClose(value) {
    const { handleSelect, selected } = this.props;
    const index = _.findIndex(selected, (item) => {
      return item.value === value;
    });
    const newSelected = [
      ...selected.slice(0, index),
      ...selected.slice(index + 1, selected.length)
    ];
    handleSelect(newSelected);
  }

  renderInputTag(option, key) {
    const {
      multi,
      disabled,
      Icon,
      Tooltip,
      hideCloseButton,
      inputError,
      meta,
      valueLoading
    } = this.props;

    const error = !!(inputError && _.get(meta, 'error'));

    return (
      <StylesProvider
        components={inputError && error ? errorOverride : {}}
        prioritiseParentStyles={false}
      >
        <InputTag
          disabled={disabled}
          clickable={!!Tooltip}
          key={key}
          item={option.value}
          label={option.label}
          multi={multi}
          Icon={Icon}
          onClose={!hideCloseButton && this.handleClose}
          loading={valueLoading}
        />
      </StylesProvider>
    );
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  renderTagWithTooltip(option, key) {
    const { multi, Tooltip, onTooltipViewClick, tooltipProps } = this.props;

    const model = _.get(option, 'model');
    const safeKey =
      key !== undefined
        ? `${key}-${option.value}`
        : `${_.get(model, 'modelName')}-${option.value}`;
    const recordId = _.get(option, 'data.id') || _.get(option, 'value');

    return Tooltip ? (
      <Box
        key={safeKey}
        width={!multi ? '100%' : undefined}
        height={!multi ? '100%' : undefined}
        onMouseDown={this.stopPropagation}
      >
        <Tooltip
          id={recordId}
          onViewClick={onTooltipViewClick}
          {...tooltipProps}
        >
          {this.renderInputTag(option, safeKey)}
        </Tooltip>
      </Box>
    ) : (
      this.renderInputTag(option, safeKey)
    );
  }

  render() {
    const {
      styles: s,
      selected = [],
      multi,
      option,
      entitySelect
    } = this.props;
    const hasSelectedValues = selected.length > 0;

    /**
     * NOTE: The entitySelect prop is here to fix a bug we were facing with the multi select when
     * it was attempting to render model Value components. Each model may have their own Value
     * component and previously we were only rendering the Value component from a single option's
     * model and not from each individual option.
     *
     * We have moved the mapping logic out of this Component to the Model value component which
     * lives in the same directory as this file.
     */
    return hasSelectedValues ? (
      <Box
        {...s('container')}
        alignItems='center'
        flexWrap='wrap'
        width={!multi ? '100%' : undefined}
        height={!multi ? '100%' : undefined}
        spacing={!multi ? 0 : 2}
        style={{ maxWidth: !multi ? '100%' : undefined }}
      >
        {!entitySelect && selected.map(this.renderTagWithTooltip)}
        {entitySelect && this.renderTagWithTooltip(option)}
      </Box>
    ) : null;
  }
}

export default DefaultValue;
