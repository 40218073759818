import { useState } from 'react';
import { pick } from 'lodash';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { decodeParams } from 'features/custom-reporting/utils/url-state-persist';
import { UserSelectedReportState } from 'features/custom-reporting/hooks/use-user-selected-report-state';
import { ColumnState } from 'features/custom-reporting/components/data-grid';
import {
  findColumn,
  findColumnById,
  flattenColumns
} from 'features/custom-reporting/modules/helpers';

const filterUndefined = (object: Record<string, any>): Record<string, any> => {
  return Object.keys(object).reduce((newObject, key) => {
    return object[key] !== undefined
      ? { ...newObject, [key]: object[key] }
      : newObject;
  }, {});
};

const columnStateProps = [
  'colId',
  'hide',
  'width',
  'flex',
  'sort',
  'sortIndex',
  'aggFunc',
  'pivot',
  'pivotIndex',
  'pinned',
  'rowGroup',
  'rowGroupIndex'
] as const;

// TODO: Test this
//  https://rexsoftware.atlassian.net/browse/RADI-5413
function recreateColumnStateFromVisibleColumns(
  selectedColumns,
  allColumns
): ColumnState[] {
  const selectedColumnState = selectedColumns
    .filter((selectedColumn) => !!selectedColumn.colId)
    .map((selectedColumn) => {
      const fullColumn = findColumn(
        allColumns,
        (ac) => ac.colId === selectedColumn.colId
      );
      return {
        ...pick(fullColumn, columnStateProps),
        ...selectedColumn,
        hide: false
      };
    });

  const remainingColumns = flattenColumns(allColumns)
    .filter(
      (column) =>
        !!column.colId && !findColumnById(selectedColumnState, column.colId)
    )
    .map((column) => ({
      ...pick(column, columnStateProps),
      hide: true
    }));

  return [...selectedColumnState, ...remainingColumns];
}
export function useIncomingUrlState({
  allColumnsMapped
}): Partial<UserSelectedReportState> {
  const { hashQuery = {} } = useWhereabouts();

  const {
    gridFilters,
    cols,
    pivot,
    userField,
    dateField,
    datePreset,
    dateRange,
    users,
    location,
    reportId
  } = hashQuery;

  const incomingUrlState: Partial<UserSelectedReportState> = filterUndefined({
    reportId,
    selectedGridColumns: cols
      ? recreateColumnStateFromVisibleColumns(
          decodeParams(cols),
          allColumnsMapped
        )
      : undefined,
    selectedGridFilters: gridFilters ? decodeParams(gridFilters) : undefined,
    selectedPivotMode: pivot,
    selectedDateFieldPreference: dateField,
    selectedUserFieldPreference: userField,
    shouldFilterByDate: datePreset !== 'none',
    selectedLocationFilter: location ? decodeParams(location) : undefined,
    selectedUserFilter: users !== undefined ? decodeParams(users) : undefined,
    ...(datePreset
      ? {
          selectedDateFilter: {
            preset: datePreset,
            ...(dateRange && datePreset === 'fixed_period'
              ? { range: decodeParams(dateRange) }
              : {})
          }
        }
      : {})
  }) as Partial<UserSelectedReportState>;

  const [pinnedResult] = useState(incomingUrlState);

  return pinnedResult;
}
