import React, { useRef, useEffect } from 'react';
import lagRadar from 'utils/lag-radar';
import { StyleSheet, styled } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    background: '#fff',
    padding: 20,
    boxShadow: '0 0 10px rgba(0, 0, 0, .1)'
  }
});

function LagRadar({ styles: s }) {
  const ref = useRef();

  useEffect(() => {
    const destroy = lagRadar({
      frames: 50,
      speed: 0.0017,
      size: 80,
      inset: 3,
      parent: ref.current
    });
    return () => destroy();
  });

  return <div ref={ref} {...s('container')} />;
}

export default styled(defaultStyles)(LagRadar);
