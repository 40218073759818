import React from 'react';

import Box from '@rexlabs/box';

interface IconContainerProps {
  children: React.ReactNode;
}

export function SavedListItemIconContainer(props: IconContainerProps) {
  const { children } = props;

  return (
    <Box width={75} flexDirection='row' justifyContent='end'>
      {children}
    </Box>
  );
}
