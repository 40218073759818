import React, { useCallback } from 'react';

import { query, useModelActions } from '@rexlabs/model-generator';

import leadsModel from 'data/models/entities/leads';

import { RecordListScreen } from 'components/record-list-screen';

import { usePermissions } from 'hooks/use-permissions';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';

import { useLeadDialog } from 'features/leads/hooks/use-lead-dialog';
import { ProcessLead } from 'features/leads/dialogs/process';

import { useLeadsListColumns } from './use-leads-list-columns';
import { useLeadsListActions } from './use-leads-list-actions';
import { useLeadsListFilters } from './use-leads-list-filters';
import { useLeadsListLenses } from './use-leads-list-lenses';

const leadsQuery = query`{
  ${leadsModel} {
    id
  }
}`;

export function LeadsListScreen(props) {
  const { check } = usePermissions();
  const { refreshLists } = useModelActions(leadsModel);

  const canCreateLeads = check('leads.create');

  // TODO: Decide on strategy for list refreshes from dialogs and implement a standardised approach.
  //  https://app.shortcut.com/rexlabs/story/66996/clean-up-our-refreshlist-implementation
  const callback = useCallback(() => {
    refreshLists();
  }, [refreshLists]);

  // TODO: Rename to useEditLeadDialog
  //  https://app.shortcut.com/rexlabs/story/66997/react-list-rename-and-move-files
  const onAdd = useLeadDialog({ callback });

  const columns = useLeadsListColumns();
  const actions = useLeadsListActions();
  const filtersConfig = useLeadsListFilters();
  const lenses = useLeadsListLenses();

  const whereabouts = useWhereaboutsWithViewpath();

  const { hashQuery, path } = whereabouts;

  return (
    // HACK: So we really need to work out what is going on here...
    // I've had to add this path check as changing from one shell screen
    // to another, causes the list to load again, and a read request.
    // So looks like this page is refreshing during the transient state.
    // This extra check makes sure the path is what we're after. If not
    // it clears out the ui.
    // https://app.shortcut.com/rexlabs/story/67425/react-lists-investigate-why-list-views-performs-api-requests-when-changing-to-another-react-screen
    <>
      {path === '/leads/' && (
        <>
          {hashQuery?.id && <ProcessLead id={hashQuery?.id} />}
          <RecordListScreen
            {...props}
            query={leadsQuery}
            columns={columns}
            filtersConfig={filtersConfig}
            serviceName='Leads'
            actions={actions}
            onAdd={canCreateLeads ? onAdd : null}
            lenses={lenses}
          />
        </>
      )}
    </>
  );
}
