import { Id, query } from '@rexlabs/model-generator';
import leadsModel from 'data/models/entities/leads';

// TODO: Remove anything from this list that we don't end up needing.
// https://app.shortcut.com/rexlabs/story/63713/remove-used-keys-from-model-query
export function getLeadQuery(leadId: Id) {
  return query`{
    ${leadsModel} (id: ${leadId}) {
      id
      assignee
      contact
      email_id
      html_body
      is_email_lead
      is_spam
      last_activity
      lead_source
      lead_status
      lead_type
      listing
      note
      plain_body
      project
      project_stage
      property
      received_from_email
      subject
      system_assigned_by_user
      system_account_reassigned_from_account
      system_assigned_time
      system_completed_by_user
      system_completed_time
      system_created_user
      system_ctime
      system_modified_user
      system_modtime
      system_record_state
      security_user_rights
    }
  }`;
}
