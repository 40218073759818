import _ from 'lodash';

export function verbFromAttribute(attribute) {
  if (_.isEqual(attribute.previous, attribute.new)) {
    return null;
  }
  if (attribute.previous === null) {
    return 'Added';
  }
  if (attribute.new === null) {
    return 'Deleted';
  }
  return 'Updated';
}

export function labelFromKey(key) {
  return key
    .replace(/^\w/, (m) => m.toUpperCase())
    .replace(/_\w/g, (m) => ` ${m.substring(1).toUpperCase()}`);
}

export function recordTypeFromServiceName(serviceName) {
  return serviceName
    .replace(/[a-z][A-Z]/g, (m) => `${m[0]} ${m[1]}`)
    .toLowerCase()
    .replace(/ies$/, 'y')
    .replace(/s$/, '');
}
