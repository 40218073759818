import dayjs from 'dayjs';
import _ from 'lodash';

const NDASH = '–';

const parseHour = (hour, value) => {
  const isAfternoon = value.includes('PM') || value.includes('pm');
  const isMorning = value.includes('AM') || value.includes('am');
  const newHour = hour < 12 && isAfternoon ? hour + 12 : hour;
  return String((hour === 12 && isMorning) || hour === 24 ? 0 : newHour);
};

// Strips alpha characters and returns {hour, minute} object
const parseNumberAsTime = (value) => {
  // Array preserves initial '0' characters and allows stripping out alpha characters
  const valueArray = value.split('');
  const numberArray = valueArray.filter((value) => !isNaN(parseInt(value), 10));

  if (!numberArray.length || numberArray.length > 7) {
    return false;
  }
  // This closely mimics the behaviour of the google calendar time select -
  // We assume an even-length implies 2 digit hour, odd length implies single digit hour.
  // (We accept up to 6 digits because 2 digits are allowed on the end for seconds.)
  if (numberArray.length % 2 === 0) {
    const hour = parseInt(numberArray[0] + numberArray[1], 10);
    const minute = parseInt(numberArray[2] + numberArray[3], 10) || 0;
    return hour <= 24 && minute < 60
      ? { hour: String(hour), minute: String(minute) }
      : false;
  } else {
    const hour = parseInt(numberArray[0], 10);
    const minute = parseInt(numberArray[1] + numberArray[2], 10) || 0;
    return numberArray.length === 1 || minute < 60
      ? { hour: String(hour), minute: String(minute) }
      : false;
  }
};

// for simple 23:55pm, 11-32 type values.
const getDividedValue = (value, divider) => {
  const [hour, minute] = value.split(divider);
  const numberHour = parseInt(hour, 10);
  const numberMinute = parseInt(minute, 10) || 0;
  if (
    (numberHour || numberHour === 0) &&
    numberHour < 25 &&
    numberMinute < 60
  ) {
    const newHour = parseHour(numberHour, value);
    const newMinute = String(numberMinute);
    return {
      hour: newHour,
      minute: newMinute,
      time: `${newHour.padStart(2, '0')}:${newMinute.padStart(2, '0')}:00`
    };
  }
  return value;
};

export const getTimeValue = (initialValue) => {
  // This util is for parsing strings of the format '12:30', '12-30' or '1230'
  // the current implementation of 'suggestive' (type anything) input only
  // provides string values so this SHOULD only ever get a string.

  // in any case .includes will break if the input isn't a string or an array
  // so return if it's not a string.
  if (!_.isString(initialValue)) {
    return initialValue;
  }
  if (initialValue.includes('-')) {
    return getDividedValue(initialValue, '-');
  } else if (initialValue.includes(':')) {
    return getDividedValue(initialValue, ':');
  } else {
    // strips alpha characters and returns either an {hour: 'hour', minute: 'minute'}
    // object, or false if the time is invalid.
    const time = parseNumberAsTime(initialValue);
    if (time) {
      const hour = parseHour(parseInt(time.hour), initialValue);
      const { minute } = time;
      const value = {
        hour,
        minute,
        time: `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`
      };
      return value;
    }
  }
  // If we can't parse the initial value, we return it so can appear in the select and be validated -
  // on the principle that even if the the user's input is invalid, it shouldn't disappear.
  return initialValue;
};

export const getDurationLabel = (durationStartTime, hour, minute) => {
  const { time } = durationStartTime;
  const startHour = +time.hour;
  const startMinute = +time.minute;
  if (hour < startHour) {
    return '';
  }
  if (hour === startHour && minute <= startMinute) {
    return '';
  }
  const minutes = hour * 60 + minute - (startHour * 60 + startMinute);
  return minutes < 60
    ? `(${minutes} mins)`
    : minutes === 60
    ? '(1 hour)'
    : `(${Math.floor(minutes / 60)}:${(minutes % 60)
        .toString()
        .padStart(2, '0')} hours)`;
};

const formatShortTime = (time) =>
  dayjs(time).format('mm') === '00'
    ? dayjs(time).format('h')
    : dayjs(time).format('h:mm');

export const formatTimeRange = (start, end, options = {}) => {
  if (options.end === false) {
    return options.short && dayjs(start).format('mm') === '00'
      ? dayjs(start).format('ha')
      : dayjs(start).format('h:mma');
  }
  const startMeridian = dayjs(start).format('a');
  const endMeridian = dayjs(end).format('a');
  const optionalStartMeridian =
    startMeridian === endMeridian ? '' : startMeridian;
  return options.short
    ? `${formatShortTime(
        start
      )}${optionalStartMeridian} ${NDASH} ${formatShortTime(end)}${endMeridian}`
    : `${dayjs(start).format('h:mm')}${optionalStartMeridian} ${NDASH} ${dayjs(
        end
      ).format('h:mma')}`;
};

export const formatDateRange = (start, end, options = {}) => {
  const DATE_FORMAT = 'dddd, D MMM YYYY';
  const TIME_FORMAT = 'h:mma';
  const areSameDay = dayjs(start).date() === dayjs(end).date();
  if (areSameDay) {
    return options.time === false
      ? dayjs(start).format(DATE_FORMAT)
      : `${dayjs(start).format(DATE_FORMAT)} at ${formatTimeRange(start, end)}`;
  }
  return options.time === false
    ? `${dayjs(start).format(DATE_FORMAT)} ${NDASH} ${dayjs(end).format(
        DATE_FORMAT
      )}`
    : `${dayjs(start).format(DATE_FORMAT)} at ${dayjs(start).format(
        TIME_FORMAT
      )} ${NDASH} ${dayjs(end).format(DATE_FORMAT)} at ${dayjs(end).format(
        TIME_FORMAT
      )}`;
};

export const formatTravelTime = (travelTimeMinutes) => {
  const hours = Math.floor(travelTimeMinutes / 60);
  const minutes = travelTimeMinutes % 60;
  const formattedHours = hours ? `${hours} hr${hours > 1 ? 's' : ''}` : '';
  const formattedMinutes = minutes
    ? `${minutes} min${minutes > 1 ? 's' : ''}`
    : '';
  return formattedHours && formattedMinutes
    ? `${formattedHours} ${formattedMinutes}`
    : formattedHours || formattedMinutes;
};
