import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import PostcodeOption from 'view/components/input/select/options/postcode';

const TYPE = 'postcodes';

const postcodesModel = new Generator(TYPE).createEntityModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
postcodesModel.select = {
  autocomplete: (searchTerm, { suburb }) => {
    return api
      .post('SystemValues::autocompleteUkPostcodes', {
        search_postcode: searchTerm,
        suburb: typeof suburb === 'function' ? suburb() : suburb
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((postcode) => ({
          value: postcode.postcode,
          label: postcode.postcode,
          data: postcode,
          model: postcodesModel
        }))
      );
  },
  Option: PostcodeOption
};

export default postcodesModel;
