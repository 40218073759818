import {
  DisplayCriteria,
  FILTER_TYPES,
  FilterConfig,
  FiltersConfig
} from 'components/record-list-screen/types';
import { Criteria } from 'types/criteria';
import { flatten, get, startCase } from 'lodash';
import { compose } from 'redux';
import dayjs from 'dayjs';

export function parseFilterFromUrlToObject(filterString: string): Criteria[] {
  if (!filterString) {
    return [];
  }
  const parsed = JSON.parse(`{"value":${filterString}}`);
  return parsed?.value?.map?.((item) =>
    item.length === 2
      ? { name: item[0], value: item[1] }
      : { name: item[0], type: item[1], value: item[2] }
  );
}

function recursivelySerialiseCriteriaToUrl(value): string {
  // TODO: add uri encoding
  return Array.isArray(value)
    ? `[${value
        .map((val) => recursivelySerialiseCriteriaToUrl(val))
        .join(',')}]`
    : JSON.stringify(value);
}

export function serialiseCriteriaToUrl(criteria: Criteria[]): string {
  const mapped = criteria?.map(({ name, id, type, value }) => {
    return type ? [id || name, type, value] : [id || name, value];
  });
  return recursivelySerialiseCriteriaToUrl(mapped);
}

export function findFilterConfigByName(
  name: string,
  filtersConfig: FiltersConfig
) {
  return flatten(filtersConfig.groups).find(
    (filterConfig) => name === (filterConfig.id || filterConfig.name)
  );
}

export function filterCriteriaToQueryCriteria(
  filterCriteria,
  filtersConfig: FiltersConfig
) {
  const filterConfig = findFilterConfigByName(
    filterCriteria.name,
    filtersConfig
  );

  return {
    ...filterCriteria,
    name: filterConfig?.name || filterCriteria.name
  };
}

function lookupLabelForSystemListValue({
  criteriaItem,
  filterConfig,
  searchFieldDefinitions
}) {
  const field = get(searchFieldDefinitions?.data, filterConfig?.name);
  const systemListItems =
    searchFieldDefinitions?.lists?.[field.options.list] || [];
  // NOTE: we cast the ids to strings here to ensure we can properly
  // compare them with strict equality, cause there might be differences
  // in types coming from the criteria and other API endpoints (strings
  // vs numbers)

  const valueArray = Array.isArray(criteriaItem.value)
    ? criteriaItem.value
    : [criteriaItem.value];

  const labels = valueArray
    .map((criteriaValue) => {
      const label = systemListItems.find(
        (item) => `${item.id}` === `${criteriaValue}`
      )?.text;

      if (label === undefined) {
        console.error(
          `List item not found for field: ${criteriaItem.name} value: ${criteriaItem.value}`
        );
      }
      return label;
    })
    .filter(Boolean);

  return labels.join(', ');
}

export function getHumanReadableValueForCriteria({
  criteriaItem,
  filterConfig,
  searchFieldDefinitions
}) {
  const field = get(searchFieldDefinitions?.data, filterConfig?.name);

  if (field?.options?.source === 'remote') {
    return lookupLabelForSystemListValue({
      criteriaItem,
      filterConfig,
      searchFieldDefinitions
    });
  }

  if (
    (field?.type === 'date' || field?.type === 'timestamp') &&
    criteriaItem.value !== 'null'
  ) {
    return dayjs(criteriaItem.value).format('DD MMM YYYY');
  }

  if (Array.isArray(criteriaItem.value)) {
    return criteriaItem.value.join(', ');
  }

  /**
   * TODO: Currently we're just taking the raw api criteria and converting that into something human readable.
   * So e.g. if we have a filter dropdown for "Show Spam: Yes" that equates to a raw api criteria of "["is_spam","isnot",null]"
   * and so when we convert that to a human readable format we convert it to "is spam isn't empty" when really I think the
   * human readable value should be "Show Spam: Yes". This requires input from UX and isn't in scope at present.
   */
  switch (criteriaItem?.type) {
    case FILTER_TYPES.IS:
      return 'is empty';
    case FILTER_TYPES.IS_NOT:
      return "isn't empty";
  }

  return (
    startCase(filterConfig?.criteriaToValue?.({ criteriaItem })) ||
    startCase(criteriaItem.value)
  );
}

export function getCriteriaMap(criteriaItem, searchFieldDefinitions) {
  // NOTE: `map` is used internally in classic e.g. in the `Advanced Filters`
  // dialog, so we set it here with the value list data when available
  const field = get(searchFieldDefinitions?.data, criteriaItem.name);
  if (field?.options?.source === 'remote') {
    const searchFieldOptions =
      searchFieldDefinitions?.lists?.[field.options.list] || [];
    return searchFieldOptions?.reduce?.(
      (options, item) => ({
        ...options,
        [item.id]: item.text
      }),
      {}
    );
  }
  return {};
}

const commonBackEndAbbreviations = {
  ctime: 'Created Time',
  modtime: 'Last Modified Time',
  modified_user: 'Last Modified By'
};

export function getLabelForCriteria(criteriaItem?: DisplayCriteria): string {
  const removeSystemPrefix = (string) => string.replace(/^system_/, '');
  const removeIdSuffix = (string) => string.replace(/_id$/, '');
  const getLastWord = (string) => string.split(/\./).pop() || '';
  const convertToSentence = (string) => string.replace(/_/g, ' ');
  const replaceCommonBackEndAbbreviations = (string) =>
    Object.keys(commonBackEndAbbreviations).reduce((newString, key) => {
      return newString.replace(`${key}`, `${commonBackEndAbbreviations[key]}`);
    }, string);
  /*
  NOTE: The classic list would just derive the filter label from the field name.
  I don't think this is correct but alternatives will require some ux changes.

  So you have a filter field that might be "Show Spam" with a value of yes or no, which translates to a BE filter of
  is_spam isnot null. So then when we then display the filter we display it as "Is Spam isn't empty". Really I think
  we should be mapping the filter back to the selected value and showing "Show Spam: 'Yes'" but that's not something
  we'll solve right now.
   */
  const rawLabel = criteriaItem?.id || criteriaItem?.name || '';

  return compose(
    convertToSentence,
    replaceCommonBackEndAbbreviations,
    removeSystemPrefix,
    removeIdSuffix,
    getLastWord
  )(rawLabel);
}

function filterToCriteria(filterConfig: FilterConfig, values): Criteria[] {
  const value = get(values, filterConfig.id || filterConfig.name);

  if (value === null || value === undefined || value === '') {
    return [];
  }

  if (filterConfig?.filterToCriteria) {
    return filterConfig.filterToCriteria({
      filterConfig,
      value
    });
  }

  return [{ ...filterConfig, value }];
}

export function filtersToCriteria(
  values,
  filtersConfig: FiltersConfig
): Criteria[] {
  return filtersConfig?.groups?.reduce((newCriteria, group) => {
    group.forEach((filterConfig) => {
      const criteria = filterToCriteria(filterConfig, values);
      newCriteria = [...newCriteria, ...criteria];
    });
    return newCriteria;
  }, [] as Criteria[]);
}

function criteriaToValue(criteriaItem: DisplayCriteria) {
  const { filterConfig, value } = criteriaItem;

  if (filterConfig?.criteriaToValue) {
    return filterConfig.criteriaToValue({ criteriaItem, filterConfig });
  }

  return value;
}

export function criteriaToFilters(criteria: DisplayCriteria[]) {
  return criteria.reduce((newFilters, obj) => {
    const identifier = obj.id || obj.name;
    if (obj.filterConfig?.valueType === 'range') {
      const duplicate = criteria.find((c) => (c.id || c.name) === identifier);
      if (duplicate) {
        return {
          ...newFilters,
          [identifier]: criteriaToValue({
            ...obj,
            value: (obj.type === FILTER_TYPES.GTE
              ? [obj.value, duplicate.value]
              : [duplicate.value, obj.value]) as Criteria['value']
          })
        };
      } else {
        return {
          ...newFilters,
          [identifier]: criteriaToValue({
            ...obj,
            value: (obj.type === FILTER_TYPES.GTE
              ? [obj.value, null]
              : [null, obj.value]) as Criteria['value']
          })
        };
      }
    }
    return { ...newFilters, [identifier]: criteriaToValue(obj) };
  }, {});
}

export function dateRangeFiltersToCriteria({ filterConfig, value }) {
  const res: Criteria[] = [];
  if (value[0]) {
    res.push({
      ...filterConfig,
      type: FILTER_TYPES.GTE,
      value: value[0]
    });
  }
  if (value[1]) {
    res.push({
      ...filterConfig,
      type: FILTER_TYPES.LTE,
      value: value[1]
    });
  }
  return res;
}
