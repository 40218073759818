import dayjs from 'dayjs';
import { Criteria } from 'types/criteria';
import { PipelineStageData } from 'features/pipelines/data/admin-pipeline-stages';
import { PipelineGroupBy } from 'data/models/entities/appraisals';

export function getGroupIdForDateString(
  dateString: string | null,
  pipelineStage: PipelineStageData | null
) {
  if (isSystemPipelineStage(pipelineStage)) return pipelineStage?.id as string;
  if (dateString === null) return 'unqualified';

  const upperLimit = 90;
  const bucketSize = 30;

  const dayNumber = dayjs(dateString).diff(dayjs(), 'day') + 1;

  if (dayNumber <= bucketSize) {
    return `0-${bucketSize}`;
  } else {
    const bucketCount = Math.ceil(upperLimit / bucketSize);
    for (let i = 1; i <= bucketCount; i++) {
      const lowerBound = (i - 1) * bucketSize + 1;
      const upperBound = i * bucketSize;
      if (dayNumber >= lowerBound && dayNumber <= upperBound) {
        return `${lowerBound}-${upperBound}`;
      }
    }
    return `${upperLimit + 1}+`;
  }
}

export function getDateStringForGroupId(groupId: string) {
  let daysToAdd = groupId.split('-')[1];
  if (!parseInt(daysToAdd)) {
    daysToAdd = groupId.split('+')[0];
  }

  return dayjs().add(Number(daysToAdd), 'day').format('YYYY-MM-DD');
}

export function getGroupIdFromDateCriteria(
  criteria: Criteria[],
  dateField: string,
  pipelineStageId: string | number
) {
  const criteriaValue = (value: string | string[]) =>
    criteria.find(
      (c) =>
        c.name === dateField &&
        JSON.stringify(c.value) === JSON.stringify(value)
    )?.value;

  if (criteriaValue('~91 days')) {
    return '91+';
  }

  if (criteriaValue('~61 days') && criteriaValue('~90 days')) {
    return '61-90';
  }

  if (criteriaValue('~31 days') && criteriaValue('~60 days')) {
    return '31-60';
  }

  if (criteriaValue(['~-30 days', '~30 days'])) {
    return '0-30';
  }

  return criteriaValue('null') ? 'unqualified' : pipelineStageId;
}

export function getDateCriteriaForDateStage(
  pipelineStage: PipelineStageData,
  dateField: string
): Criteria[] {
  const id = pipelineStage.id;
  if (id === '91+') {
    return [
      { name: dateField, type: '>=', value: '~91 days' },
      { name: dateField, type: 'isnot', value: 'null' },
      { name: 'appraisal_state', type: '=', value: 'active' }
    ];
  } else if (id === '61-90') {
    return [
      { name: dateField, type: '>=', value: '~61 days' },
      { name: dateField, type: '<=', value: '~90 days' },
      { name: 'appraisal_state', type: '=', value: 'active' }
    ];
  } else if (id === '31-60') {
    return [
      { name: dateField, type: '>=', value: '~31 days' },
      { name: dateField, type: '<=', value: '~60 days' },
      { name: 'appraisal_state', type: '=', value: 'active' }
    ];
  } else if (id === '0-30') {
    return [
      { name: dateField, type: 'between', value: ['~-30 days', '~30 days'] },
      { name: 'appraisal_state', type: '=', value: 'active' }
    ];
  } else if (isSystemPipelineStage(pipelineStage)) {
    return [
      { name: 'pipeline_stage_id', type: '=', value: id },
      {
        name: 'archive_date',
        type: '>=',
        value: dayjs().subtract(30, 'day').format('YYYY-MM-DD')
      }
    ];
  } else {
    return [
      { name: dateField, type: 'is', value: 'null' },
      { name: 'appraisal_state', type: '=', value: 'active' }
    ];
  }
}

export function getCriteriaForGroupBy(
  pipelineStage: PipelineStageData,
  groupBy: PipelineGroupBy
): Criteria[] {
  if (groupBy === 'pipeline_stage') {
    return [
      {
        name: 'pipeline_stage_id',
        type: pipelineStage.id === 'unqualified' ? 'is' : '=',
        value: pipelineStage.id === 'unqualified' ? 'null' : pipelineStage.id
      },
      ...(!isSystemPipelineStage(pipelineStage)
        ? [
            {
              name: 'appraisal_state',
              type: '=',
              value: 'active'
            } as const
          ]
        : [
            {
              name: 'archive_date',
              type: '>=',
              value: dayjs().subtract(30, 'day').format('YYYY-MM-DD')
            } as const
          ])
    ];
  } else {
    return getDateCriteriaForDateStage(pipelineStage, 'expected_close_date');
  }
}

export function isSystemPipelineStage(
  data: PipelineStageData | null | undefined
) {
  return (
    data?.system_purpose?.id === 'won' || data?.system_purpose?.id === 'lost'
  );
}
