import React, { PureComponent } from 'react';

import { autobind } from 'core-decorators';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import { Context } from 'components/text/context';
import { SubHeading } from 'components/text/sub-heading';
import { Link as LinkText } from 'components/text/link';
import { TEXTS, COLORS, PADDINGS, BREAKPOINTS } from 'theme';
import _ from 'lodash';
import Spinner from 'shared/components/spinner';
import dayjs from 'dayjs';
import Link from 'src/view/components/link';
import ROUTES from 'src/routes/app';
import List from 'view/components/list';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ViewAppointmentPopout from 'view/popouts/calendar/view-appointment';
import { connect } from 'react-redux';
import { makeTetherStyles } from 'utils/calendar';

import arrowPng from 'assets/icons/size-30-30/navigate-forward.png';

export const enterAnimationRight = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate3d(0, -20px, 0)'
  },
  '20%': {
    opacity: 1
  },
  '100%': {
    opacity: 1,
    transform: 'translate3d(10px, -20px, 0)'
  }
});

const exitAnimationRight = keyframes({
  '0%': {
    opacity: 1,
    transform: 'translate3d(10px, -20px, 0)'
  },
  '100%': {
    opacity: 0,
    transform: 'translate3d(0, -20px, 0)'
  }
});

const enterAnimationLeft = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate3d(-70px, -20px, 0)'
  },
  '80%': {
    opacity: 1
  },
  '100%': {
    opacity: 1,
    transform: 'translate3d(-80px, -20px, 0)'
  }
});

const exitAnimationLeft = keyframes({
  '0%': {
    opacity: 1,
    transform: 'translate3d(-80px, -20px, 0)'
  },
  '80%': {
    opacity: 0
  },
  '100%': {
    opacity: 0,
    transform: 'translate3d(-70px, -20px, 0)'
  }
});

const tetherStyles = makeTetherStyles({
  enterAnimationRight,
  exitAnimationRight,
  enterAnimationLeft,
  exitAnimationLeft
});

const listStyles = StyleSheet({
  separator: {
    display: 'none'
  },
  container: {
    marginBottom: PADDINGS.XS
  }
});

const defaultStyles = StyleSheet({
  arrow: {
    marginRight: '0px',
    '&:hover': { transform: 'translate(5px)' },
    transition: 'transform 0.3s',
    cursor: 'pointer'
  },
  icon: {
    backgroundImage: `url("${arrowPng}")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '30px',
    width: '30px'
  },

  headingText: {
    ...TEXTS.HEADING_2,
    ...TEXTS.SEMIBOLD,
    [BREAKPOINTS.DESKTOP_XL]: {
      ...TEXTS.HEADING
    }
  },
  loadingState: {
    height: '92px',
    backgroundColor: '#f1f1f0',
    marginTop: PADDINGS.M
  },
  item: {
    padding: `${PADDINGS.XS} 0`,
    borderTop: `2px solid ${COLORS.BACKGROUNDS.SAND_LIGHT}`
  },
  dates: {
    width: '60px'
  },
  emptyState: {
    height: '92px',
    backgroundColor: '#f1f1f0'
  }
});

const mapStateToProps = (state) => ({
  userId: _.get(state, 'session.user_details.id'),
  calendarsSettings: _.get(
    state,
    'session.global_settings.calendars_settings',
    {}
  )
});

@connect(mapStateToProps)
@withErrorDialog
@styled(defaultStyles)
@autobind
class AppointmentsWidget extends PureComponent {
  getEventColor = _.memoize(
    (event) => {
      const colorIndicator = _.get(
        this,
        'props.calendarsSettings.color_indicator'
      );

      if (colorIndicator === 'calendar') {
        const calendar = _.get(this, 'props.userCalendars', []).find(
          (userCalendar) =>
            _.get(userCalendar, 'calendar.id') === _.get(event, 'calendar.id')
        );

        return calendar && calendar.color;
      }

      const appointmentType = _.get(this, 'props.appointmentTypes', []).find(
        (type) => type._id === _.get(event, 'appointment_type.id')
      );

      return appointmentType && appointmentType.color;
    },
    (event) => event.id
  );

  renderItem(item) {
    const {
      styles: s,
      deleteEvent,
      cancelEvent,
      uncancelEvent,
      updateEvent
    } = this.props;

    const start = dayjs(item.starts_at.time).local();
    const end = dayjs(item.ends_at.time).local();
    const endsToday = dayjs().endOf('day').isAfter(end);

    // All day events are represented in the db as starting at 12:00am on the day,
    // and finishing at 12:00am the next day
    const isAllDay =
      start.isSame(start.startOf('day')) && end.isSame(end.startOf('day'));

    return (
      <Box alignItems={'center'} {...s('item')}>
        <Box flexDirection={'column'} {...s('dates')} mr={PADDINGS.S}>
          {isAllDay ? (
            <SubHeading semibold>All day</SubHeading>
          ) : (
            <Box>
              <Box alignItems={'baseline'}>
                <SubHeading semibold>
                  {start.isValid() ? start.format('h:mm') : ' - '}
                </SubHeading>
                <Context semibold dark>
                  {start.isValid() && start.format('a')}
                </Context>
              </Box>
              {endsToday && (
                <Box alignItems={'baseline'}>
                  <SubHeading semibold>
                    {end.isValid() ? end.format('h:mm') : ' - '}
                  </SubHeading>
                  <Context semibold dark>
                    {end.isValid() && end.format('a')}
                  </Context>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box>
          <ViewAppointmentPopout
            tetherStyles={tetherStyles}
            event={{ ...item, start, end, allDay: isAllDay }}
            calendarsSettings={_.get(this, 'props.calendarsSettings', {})}
            context={{
              updateEvent: updateEvent,
              deleteEvent: deleteEvent,
              cancelEvent: cancelEvent,
              uncancelEvent: uncancelEvent,
              getEventColor: this.getEventColor
            }}
          >
            <LinkText
              blue
              record
              lineThrough={_.get(item, 'event_status.id') === 'cancelled'}
            >
              {item.title}
            </LinkText>
          </ViewAppointmentPopout>
        </Box>
      </Box>
    );
  }

  renderEmptyState() {
    const { styles: s } = this.props;

    return (
      <Box {...s('emptyState')} alignItems='center' justifyContent='center'>
        <Body dark italic>
          No upcoming appointments
        </Body>
      </Box>
    );
  }

  render() {
    // NOTE: we're passing the events in from classic, this is more of a quick fix (because
    // we render this widget multiple times but only wanna load the events once)
    const { styles: s, events, isLoading } = this.props;

    return (
      <Box flexDirection={'column'}>
        <Box
          mt={PADDINGS.M}
          mb={PADDINGS.S}
          alignItems='center'
          justifyContent='space-between'
        >
          <Box {...s('headingText')}>Upcoming Appointments</Box>
          <Box {...s('arrow')} flexDirection='row' alignItems='center'>
            <Link to={ROUTES.CALENDAR}>
              {({ onClick }) => (
                <Box alignItems='center' p={2} onClick={onClick}>
                  <Box {...s('icon')} />
                </Box>
              )}
            </Link>
            {/*
              Keep this - the below uses SVG but currently this stands out too much
              compared the PNG in the widget directly above this one on the dashboard (Agenda).
              Ideally we can make both into SVGs later and use this code.
              Styles are provided for reference.
              (styles:
               icon: { border: '2px solid black', borderRadius: '100%', marginLeft: '7px' })
              <Box
                {...s('icon')}
                width='30px'
                height='30px'
                alignItems='center'
                justifyContent='center'
              >
                <Box pl={2} pt={1}>
                  <ICONS.CHEVRON_RIGHT />
                </Box>
              </Box> */}
          </Box>
        </Box>
        {isLoading ? (
          <Box
            {...s('loadingState')}
            alignItems='center'
            justifyContent='center'
          >
            <Spinner />
          </Box>
        ) : (
          <List
            items={events}
            renderItem={this.renderItem}
            styles={listStyles}
            EmptyView={this.renderEmptyState}
            isLoading={isLoading}
          />
        )}
      </Box>
    );
  }
}

export default AppointmentsWidget;
