import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';

import { Link } from 'components/text/link';

const defaultStyles = StyleSheet({
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

interface ItemNameProps {
  children: React.ReactNode;
}

export function SavedListItemDescription(props: ItemNameProps) {
  const { children } = props;

  const s = useStyles(defaultStyles);

  return (
    <Link undecorated blue {...s('name')}>
      {children}
    </Link>
  );
}
