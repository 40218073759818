import React from 'react';

import { ICONS } from 'shared/components/icon';

import { ActionMenuProps } from './core';
import { IconActionMenu } from './icon-action-menu';

interface EditActionMenuProps extends ActionMenuProps {
  addButton?: boolean;
}

export function EditActionMenu({
  items,
  ...props
}: EditActionMenuProps): JSX.Element {
  return <IconActionMenu Icon={ICONS.EDIT} red items={items} {...props} />;
}
