import React from 'react';
import { api } from 'shared/utils/api-client';
import { RexTrainingRedirect } from './rex-training-redirect';
import Overlay from 'view/components/overlay';
import { ICONS } from 'shared/components/icon';

function RexUniversityRedirect({ redirectOverlay }) {
  return (
    <Overlay title={'Rex Training'}>
      <RexTrainingRedirect
        fetchRedirectUrl={() => api.post('Help::getCoAssembleUrl')}
        onClose={redirectOverlay.close}
        Icon={ICONS.REX_UNIVERSITY_LOADING}
        title={'Rex Training'}
      />
    </Overlay>
  );
}

export default RexUniversityRedirect;
