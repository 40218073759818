import React from 'react';
import {
  Select,
  EntitySelect,
  ValueListSelect,
  DateRangeSelect
} from 'view/components/input/select';
import { FiltersConfig } from 'components/record-list-screen/types';
import listingsModel from 'data/models/entities/listings';
import contactsModel from 'data/models/entities/contacts';
import accountUsersModel from 'data/models/value-lists/account-users';
import { dateRangeFiltersToCriteria } from 'components/record-list-screen/utils/filters';

export function useApplicationsFilters() {
  return React.useMemo<FiltersConfig>(
    () => ({
      columns: 2,
      groups: [
        [
          {
            name: 'application.listing_id',
            label: 'listing',
            Input: EntitySelect,
            inputProps: {
              models: [listingsModel],
              valueAsObject: true,
              searchOnMount: true
            },
            filterToCriteria: ({ filterConfig, value }) => {
              return [
                {
                  ...filterConfig,
                  value: [`${value.value}-${value.label}`]
                }
              ];
            },
            criteriaToValue: ({ criteriaItem }) => {
              const split = criteriaItem.value[0].split(/-(.*)/s);
              return {
                id: split[0],
                value: split[0],
                label: split[1],
                model: listingsModel
              };
            }
          },
          {
            name: 'application.application_status_id',
            label: 'application status',
            Input: Select,
            inputProps: {
              options: [
                { value: 'received', label: 'Received' },
                { value: 'communicated', label: 'Communicated' },
                { value: 'accepted', label: 'Accepted' }
              ]
            }
          },
          {
            name: 'tenant.id',
            label: 'tenant',
            Input: EntitySelect,
            inputProps: {
              models: [contactsModel],
              valueAsObject: true,
              searchOnMount: true,
              multi: true
            },
            filterToCriteria: ({ filterConfig, value }) => {
              return [
                {
                  ...filterConfig,
                  value: value.map((val) => `${val.value}-${val.label}`)
                }
              ];
            },
            criteriaToValue: ({ criteriaItem }) => {
              return (criteriaItem.value as string[]).map((value) => {
                const split = value.split(/-(.*)/s);
                return {
                  id: split[0],
                  value: split[0],
                  label: split[1],
                  model: contactsModel
                };
              });
            }
          },
          {
            name: 'application.agent_id',
            label: 'application agent',
            Input: ValueListSelect,
            inputProps: {
              models: [accountUsersModel]
            }
          },
          {
            name: 'application.date_received',
            label: 'application received date',
            valueType: 'range',
            Input: DateRangeSelect,
            filterToCriteria: dateRangeFiltersToCriteria
          },
          {
            name: 'application.date_accepted',
            label: 'application accepted date',
            valueType: 'range',
            Input: DateRangeSelect,
            filterToCriteria: dateRangeFiltersToCriteria
          },
          {
            name: 'application.start_date',
            label: 'tenancy start date',
            valueType: 'range',
            Input: DateRangeSelect,
            filterToCriteria: dateRangeFiltersToCriteria
          }
        ]
      ]
    }),
    []
  );
}
