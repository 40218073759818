/* API caching implementation (primarily for API requests which are called in Classic)
 *
 * There are several API requests which are frequent and for which the data rarely changes.
 * (These are hardcoded into this file, visible below.)
 * This provides a cache in Redux for those calls, to speed up page loading.
 *
 * This model is one part of the implementation. The BatchFetch action populates the cache
 * (see populateCache below). This is run on shell load and currently occurs in
 * layouts/shell.js, because the withModel HOC allows easy access to BatchFetch via props.
 *
 * Classic accesses this model via classic bridges, see data/classic-bridges/api-cache.
 * The classic bridge implementation (data/classic-bridges/index) takes what is essentially
 * a reference to the fetch action and connects it to the store with the bindActionCreators
 * function. This turns fetch into a function that can be run to dispatch the action.
 *
 * Fetch (below) takes the method and args of an API call, and if present,
 * returns the response from the cache.
 *
 * In Classic, fetch is called via apiCache like this:
 *
 * window.r2.u.api.apiCache = (args, endpoint, force) =>
 * r2.shell(Shell =>
 *  Shell.Bridges.ApiCache.fetch({ method: endpoint, args, force })
 * );
 *
 * This is additionally made available as a mixin to underscore (_.apiCache(...))
 *
 * For further notes on how to use apiCache in Classic, see public/lib/r2/u/r2.u.api.js
 */

import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const defaultCalls = {
  thirdPartyModules: {
    method: 'ThirdPartyServices::describeConnectableServiceTypes',
    args: {}
  },
  thirdPartyConnections: {
    method: 'ThirdPartyServices::getConnections',
    args: { include_inherited: true }
  },
  connectedThirdParty: {
    method: 'ThirdPartyServices::getConnections',
    args: { connection_state: 'connected', include_inherited: true }
  },
  embeddedApps: {
    method: 'EmbeddedApps::search',
    args: {}
  },
  connectedThirdPartyIds: {
    method: 'ThirdPartyServices::getConnections',
    args: {
      connection_state: 'connected',
      include_inherited: true,
      ids_only: true
    }
  }
};

// each request needs to have a key now, to support easy retrieval of data on output
const buildCustomFieldCalls = (modules) => {
  let defRequests = {};
  modules.forEach((row) => {
    defRequests = {
      ...defRequests,
      [`${row.module_name}CustomFields`]: {
        method: 'AdminCustomFields::getDefinition',
        args: { module_name: row.module_name }
      },
      [`${row.module_name}CustomFieldsHidden`]: {
        method: 'AdminCustomFields::getDefinition',
        args: { include_hidden: true, module_name: row.module_name }
      }
    };
  });

  return defRequests;
};

// 'AdminCustomFields::getModuleAndCoreTabDefinitions' is called before
// the other calls because its data is needed to build most of the
// 'getDefinition' requests.
const populateCache = (cache) => {
  cache
    .fetch({
      method: 'AdminCustomFields::getModuleAndCoreTabDefinitions',
      args: {}
    })
    .then((modules) => {
      // Batching these calls separately, since they are hitting separate endpoints,
      // so there is no BE perf benefit of putting everything into one batch +
      // this way the two requests can potentially run in parallel
      cache.batchFetch({
        requests: defaultCalls,
        forceAll: false
      });
      cache.batchFetch({
        requests: buildCustomFieldCalls(modules),
        forceAll: false
      });
    });
};

const getOrderedArgString = (args) => {
  const sortedKeys = _.sortBy(_.keys(args));
  return sortedKeys.reduce(
    (resultString, key) =>
      // as described in the below TODO - arg values cannot be arrays or objects.
      `${resultString} ${_.toString(key)}: ${JSON.stringify(args[key])},`,
    ''
  );
};

// TODO - array order and object keys within args will currently affect the ability to access a cache key.
// This is not an immediate concern since atm we're limiting this to only the above calls.
// If deep sorting is required, an implementation is provided at the bottom of the file.

// At some point, if we use this for more complicated and lengthy API calls,
// we will want to hash the key so it doesn't get unwieldy.

const getCacheKey = (payload) => {
  const { args, method } = payload;
  return `${method} ${getOrderedArgString(args)}`;
};

// actual model-generator stuff

const actionCreators = {
  fetch: {
    request: (payload, actions, dispatch, getState) => {
      return new Promise((resolve, reject) => {
        const cacheKey = getCacheKey(payload);
        if (
          !payload.force &&
          getState().apiCache[cacheKey] // if key exists then we've made the request already
        ) {
          // New object with clone deep to protect the Redux store from
          // unexpected mutation. + nonenumerable value to indicate cache origin
          // without messing with existing loop functionality.
          const data = _.cloneDeep(getState().apiCache[cacheKey]);

          // Some API endpoints unfortunately return strings, which breaks this implementation
          // As a quick workaround we check for that here, further down where we actually check
          // for `fromCache` we now also `===` compare the old with the new state before creating
          // a new state object reference
          const cachedData = _.isString(data)
            ? data
            : Object.defineProperty(data, 'fromCache', {
                value: true,
                writable: true
              });
          return resolve(cachedData);
        }
        api
          .post(payload.method, payload.args)
          .then(({ data }) => {
            resolve(data.result || []);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        if (!action.payload.fromCache) {
          const key = getCacheKey(_.get(action, 'meta.originalPayload'));

          // This is for edge cases, e.g. for strings we cannot add the `fromCache` property
          // but we can catch it here that the cache didn't/wouldn't change
          if (state[key] === action.payload) {
            return state;
          }

          return {
            ...state,
            [key]: action.payload,
            fetched: new Date().getTime()
          };
        }
        return state;
      },
      failure: (state) => {
        return { ...state };
      }
    }
  },

  batchFetch: {
    request: (payload, actions, dispatch, getState) => {
      const cachedData = {};

      const requests = _.reduce(
        payload.requests,
        (requestsResult, requestValue, requestKey) => {
          const cacheKey = getCacheKey(requestValue);
          if (
            !payload.forceAll &&
            !requestValue.force &&
            getState().apiCache[cacheKey] // if key exists then we've made the request already
          ) {
            // The key could be cacheKey, but in order to have any hope of
            // matching up data when we run this, we need to output a coherent identifier.
            // since it certainly won't be in the same order.
            // We could export the getCacheKey() function... to Classic... to get the
            // cache key again after output. That seems excessive.

            cachedData[requestKey] = getState().apiCache[cacheKey];
            return requestsResult;
          }

          return {
            ...requestsResult,
            [requestKey]: requestValue
          };
        },
        {}
      );

      return api
        .post('BatchRequests::execute', { requests })
        .then((response) => {
          const result = _.get(response, 'data.result');
          return { ...result, ...cachedData };
        });
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        const newState = _.reduce(
          action.payload,
          (resultForCache, responseValue, responseKey) => {
            // make cache key with the original args provided
            // bc - output needs one key (responseKey), so the user can identify
            // different requests in the output. Cache needs another.
            const cacheKey = getCacheKey(
              _.get(action, `meta.originalPayload.requests.${responseKey}`)
            );
            return { ...resultForCache, [cacheKey]: responseValue };
          },
          {}
        );

        return {
          ...state,
          ...newState,
          fetched: new Date().getTime()
        };
      },
      failure: (state) => {
        return { ...state };
      }
    }
  },

  refresh: {
    request: (payload, actions) =>
      actions
        .fetch({
          method: 'AdminCustomFields::getModuleAndCoreTabDefinitions',
          args: {},
          force: true
        })
        .then((modules) => {
          actions.batchFetch({
            requests: { ...defaultCalls, ...buildCustomFieldCalls(modules) },
            forceAll: true
          });
        }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export { populateCache, getCacheKey };

export default new Generator('cache').createModel({ actionCreators });

// Recursive function for deeper arg sorting - use when we have more complex requests.
// Will analyse each object passed into it and turn what it can into a string
// and otherwise will pass anything nested through the function again
// (so it can be analysed and turned into a string)

// thoroughly commented for your convenience (bc fu recursion)

// argLayer should be just your initial args
// const orderArgs = argLayer => {
//   if (_.isArray(argLayer)) {   // note that orderArgs is *this function*,
//                                // so each value in the array is then passed
//                                // into this function from the top.
//     return _.toString(_.sortBy(argLayer.map(orderArgs)));

//   } else if (_.isObject(argLayer)) {
//     const sortedKeys = _.sortBy(_.keys(argLayer)); // sortBy will perform a regular sort,
//                                                                // whereas array.sort will depend on browser
//                                                                // implementation.
//                                                                // identity means it'll just sort based on the thing itself
//     const string = sortedKeys.reduce( // make a string from obj
//       (resultString, key) =>
//         `${resultString} ${_.toString(key)}: ${orderArgs(argLayer[key])}`, // put value through this function again.
//       ''
//     );
//     return string;
//   }
//   return _.toString(argLayer) + ','; // if there's nothing nested, just return the toString. And comma for readability.
// };
