import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    padding: PADDINGS.M,
    background: '#fff',
    position: 'relative',
    width: '100%',

    '&:nth-child(even)': {
      background: '#f4f4f1'
    }
  },

  containerEditable: {
    '&:hover, &.edit': {
      background: '#e9e9e5',

      '&:before': {
        content: "' '",
        position: 'absolute',
        right: '100%',
        top: 0,
        bottom: 0,
        width: '5px',
        background: '#b4b1a9',
        borderRadius: '3px 0px 0px 3px'
      },

      '&:after': {
        content: "' '",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '1px',
        background: '#b4b1a9'
      }
    }
  },

  hidden: {
    display: 'none'
  }
});

interface RecordDetailsBlockProps {
  block: any;
  data: any;
  values: any;
}

export function RecordDetailsBlock({
  block,
  data,
  values
}: RecordDetailsBlockProps) {
  const s = useStyles(defaultStyles);

  const [editMode, setEditMode] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    setEditMode(true);
  }, []);

  useEffect(() => {
    if (!setEditMode || !containerRef.current) {
      return;
    }

    function handleOutsideClick(e) {
      if (!containerRef.current?.contains(e.target)) {
        setEditMode(false);
      }
    }
    window.document.addEventListener('click', handleOutsideClick);
    return () =>
      window.document.removeEventListener('click', handleOutsideClick);
  }, [editMode]);

  return (
    <Box
      ref={containerRef}
      onClick={block.Edit ? handleClick : undefined}
      {...s.with('container', {
        containerEditable: block.Edit
      })({ className: editMode ? 'edit' : '' })}
    >
      {!!block.Edit && (
        <Box {...s({ hidden: !editMode })}>
          <block.Edit data={data} values={values} />
        </Box>
      )}
      {!!block.View && (
        <Box {...s({ hidden: editMode })}>
          <block.View data={data} values={values} />
        </Box>
      )}
    </Box>
  );
}
