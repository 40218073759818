import React, { PureComponent } from 'react';

import LocalStorage from 'shared/utils/local-storage';
import sessionModel from 'data/models/custom/session';
import { getDisplayName } from 'shared/utils/react';
import { connect } from 'react-redux';
import _ from 'lodash';
import config from 'shared/utils/config';

function withLoggedInUser(WrappedComponent) {
  @connect((state) => ({ api_token: _.get(state, 'session.api_token') }), {
    refresh: sessionModel.actionCreators.refresh,
    setApiToken: sessionModel.actionCreators.setApiToken
  })
  class WithLoggedInUser extends PureComponent {
    constructor(props) {
      super(props);

      // Setup Account Info
      const apiToken = LocalStorage.get('api_token', null);
      const storeToken = props.api_token;

      // Only login if we don't have the stores information or any local storage information
      this.canRender = true;

      // if (!apiToken) {
      //   // The Classic App, on the /login page, will handle setting an api_token
      //   this.canRender = false;
      //   window.parent.location.href = '/login';
      // }

      if (apiToken && !storeToken) {
        props.setApiToken(apiToken);
        props.refresh().then((session) => {
          const account = session.find((details) => details.account_user_id);
          if (config.ENABLE_APPCUES) {
            if (window.Appcues) {
              window.Appcues.identify(account.id, account);
            }
          }
        });
      }
    }

    render() {
      return this.canRender ? <WrappedComponent /> : null;
    }
  }

  WithLoggedInUser.displayName = `withLoggedInUser(${getDisplayName(
    WrappedComponent
  )})`;
  return WithLoggedInUser;
}

export default withLoggedInUser;
