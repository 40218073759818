export function convertFeetToInches(feet) {
  const matched = feet.match(/([0-9]+)[']{0,1}\s*([0-9]*)["]{0,1}/);
  return parseInt(matched[1] || 0) * 12 + parseInt(matched[2] || 0);
}

export function convertInchesToFeet(inches) {
  const finalInches = inches % 12;
  const feet = (inches - finalInches) / 12;
  return `${parseInt(feet)}'${parseInt(finalInches)}"`;
}

export function convertInchesToMeters(inches) {
  return parseFloat((inches / 39.37).toFixed(2));
}

export function convertMetersToInches(meters) {
  return parseFloat((meters * 39.37).toFixed(2));
}

export function renderDimensions(width, length, unit) {
  if (unit) {
    const mWidth = unit === 'm' ? width : convertInchesToMeters(width);
    const mLength = unit === 'm' ? length : convertInchesToMeters(length);

    const fWidth =
      unit === 'm'
        ? convertInchesToFeet(convertMetersToInches(mWidth))
        : convertInchesToFeet(width);
    const fLength =
      unit === 'm'
        ? convertInchesToFeet(convertMetersToInches(mLength))
        : convertInchesToFeet(length);

    return unit === 'm'
      ? `${mWidth}m × ${mLength}m (${fWidth} × ${fLength})`
      : `${fWidth} × ${fLength} (${mWidth}m × ${mLength}m)`;
  }
  return null;
}

export const METER_FEET_CONVERSION = 0.3048;
