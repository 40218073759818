import { gql } from 'hooks/use-graphql-query';
import { Property, User } from 'features/custom-reporting/data/fragments';
import { Oab } from './oab-data-fragments';
import { Contact } from '../contacts/contact-data-fragments';

export const oabsReportQueryDefaultVariables = {
  include_subresource_property: false,
  include_subresource_property_owner: false
};

export const OabsReportQuery = gql`
  ${User}
  ${Oab}
  ${Property}
  ${Contact}

  query (
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
    $include_subresource_property: Boolean!
    $include_subresource_property_owner: Boolean!
  ) {
    rowData: oabs(
      limit: $limit
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...Oab
        property @include(if: $include_subresource_property) {
          ...Property
        }
        property @include(if: $include_subresource_property_owner) {
          ... on Property {
            owner: contact_relationships(limit: 1, type: "owner") {
              relationship_type {
                id
              }
              contact {
                ...Contact
              }
            }
          }
        }
        system_modified_user {
          ...User
        }
        system_created_user {
          ...User
        }
      }
    }
  }
`;
