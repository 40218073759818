import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { OptionLabel } from 'components/text/option-label';
import { OptionDetail } from 'components/text/option-detail';
import { COLORS, PADDINGS } from 'src/theme';
import _ from 'lodash';

import Option from './core';

const defaultStyles = StyleSheet({
  container: {
    cursor: 'pointer'
  },
  isActive: {
    backgroundColor: COLORS.STATES.BLUE
  },
  isActiveText: {
    color: COLORS.SLATE.MIDNIGHT
  },
  isSelected: {
    backgroundColor: COLORS.STATES.BLUE
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    width: '24px',
    marginLeft: '-3px',
    marginRight: '3px',
    marginTop: '-3px',
    fill: COLORS.PRIMARY.SLATE,
    color: COLORS.PRIMARY.SLATE
  }
});

@styled(defaultStyles)
class EntityOption extends Component {
  static defaultProps = {
    isPassive: false,
    onHover: _.noop,
    onSelect: _.noop,
    pluckStatus: (option) => _.toUpper(_.get(option, 'data.status'))
  };

  renderDescription(desc) {
    const { isActive } = this.props;

    return _.isArray(desc) ? (
      desc.map((data, index) => (
        <OptionDetail key={`${index}-option`} active={isActive}>
          {data}
        </OptionDetail>
      ))
    ) : (
      <OptionDetail active={isActive}>{desc}</OptionDetail>
    );
  }

  render() {
    const {
      styles: s,
      option,
      pluckLabel,
      pluckStatus,
      isActive,
      isPassive,
      isSelected,
      Icon
    } = this.props;

    const isFixture = _.get(option, 'isFixture');
    const Fixture = _.get(option, 'model.select.Fixture');
    const description = pluckStatus(option);

    return isFixture ? (
      <Fixture {...this.props} />
    ) : (
      <Option
        {...this.props}
        {...s('container', {
          isActive: isActive && !isPassive,
          isSelected: isSelected && !isPassive,
          isPassive
        })}
      >
        <Box {...s('container')} flex={1} p={PADDINGS.XS}>
          {Icon && (
            <Box>
              <Icon {...s('icon')} />
            </Box>
          )}
          <Box flexDirection='column'>
            <OptionLabel>{pluckLabel(option)}</OptionLabel>
            {this.renderDescription(description)}
          </Box>
        </Box>
      </Option>
    );
  }
}

export default EntityOption;
