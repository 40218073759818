import React, { useCallback } from 'react';

import Box from '@rexlabs/box';
import { useModelActions } from '@rexlabs/model-generator';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import leadsModel, { LeadsResponse } from 'data/models/entities/leads';

import { PADDINGS } from 'theme';

import { Heading } from 'components/text/heading';

import { SendEmailActionButton } from './buttons/send-email-action-button';
import { SendSmsActionButton } from './buttons/send-sms-action-button';
import { AddNoteActionButton } from './buttons/add-note-action-button';
import { AddFeedbackActionButton } from './buttons/add-feedback-action-button';
import { usePermissions } from 'hooks/use-permissions';
import AddReminderActionButton from './buttons/add-reminder-button';
import { BookAppointmentActionButton } from './buttons/book-appointment-action-button';

interface LeadsDialogActionsContainerProps {
  data: LeadsResponse;
  // TODO: Type this when we have model generator working again
  // https://app.shortcut.com/rexlabs/story/60659/fix-usemodelactions-and-usemodelstate-types-once-model-generator-setup-is-cleaned-up
  activities: any;
}

function sendLeadActionAnalytics({ event, leadType }) {
  Analytics.track({
    event,
    properties: {
      lead_type: leadType
    },
    options: { integrations: { Intercom: true } }
  });
}

export function LeadsDialogActionsContainer({
  data,
  activities
}: LeadsDialogActionsContainerProps) {
  const { refreshItem } = useModelActions(leadsModel);

  const { check } = usePermissions();

  const canCreateNotes = check('notes.create');
  const canSendSms = check('mail_merge.send_sms');
  const canCreateFeedback = check('feedback.create');

  const refreshList = activities.actions?.refreshList;
  const leadType = data?.lead_type?.id;

  const emailCallback = useCallback(() => {
    refreshList?.();
    refreshItem?.({ id: data.id });
    sendLeadActionAnalytics({
      event: EVENTS.LEADS_DIALOG.SEND_EMAIL_ACTION,
      leadType
    });
  }, [refreshList, refreshItem, data.id, leadType]);

  const appointmentCallback = useCallback(() => {
    refreshList?.();
    refreshItem?.({ id: data.id });
    sendLeadActionAnalytics({
      event: EVENTS.LEADS_DIALOG.BOOK_APPOINTMENT_ACTION,
      leadType
    });
  }, [refreshList, refreshItem, data.id, leadType]);

  const smsCallback = useCallback(() => {
    refreshList?.();
    refreshItem?.({ id: data.id });
    sendLeadActionAnalytics({
      event: EVENTS.LEADS_DIALOG.SEND_SMS_ACTION,
      leadType
    });
  }, [refreshList, refreshItem, data.id, leadType]);

  const noteCallback = useCallback(() => {
    refreshList?.();
    refreshItem?.({ id: data.id });
    sendLeadActionAnalytics({
      event: EVENTS.LEADS_DIALOG.ADD_NOTE_ACTION,
      leadType
    });
  }, [refreshList, refreshItem, data.id, leadType]);

  const feedbackCallback = useCallback(() => {
    refreshList?.();
    refreshItem?.({ id: data.id });
    sendLeadActionAnalytics({
      event: EVENTS.LEADS_DIALOG.ADD_FEEDBACK_ACTION,
      leadType
    });
  }, [refreshList, refreshItem, data.id, leadType]);

  const reminderCallback = useCallback(() => {
    refreshList?.();
    refreshItem?.({ id: data.id });
    sendLeadActionAnalytics({
      event: EVENTS.LEADS_DIALOG.ADD_REMINDER_ACTION,
      leadType
    });
  }, [refreshList, refreshItem, data.id, leadType]);

  return (
    // NOTE: For anyone wondering why have nested boxes, it is because of the spacing prop.
    // Because we use sy in the parent component, it overrides the sy in this component.
    // Wrapping in a box stops this from happening, as it adds an additionally layer.
    <Box p={PADDINGS.S}>
      <Box sy={PADDINGS.XS}>
        <Heading level={2}>Actions</Heading>
        <SendEmailActionButton
          data={data}
          activitiesList={activities?.data}
          callback={emailCallback}
        />

        {canSendSms && (
          <SendSmsActionButton
            data={data}
            activitiesList={activities?.data}
            callback={smsCallback}
          />
        )}

        <BookAppointmentActionButton
          data={data}
          activitiesList={activities?.data}
          callback={appointmentCallback}
        />

        {canCreateFeedback && leadType !== 'appraisal_request' && (
          <AddFeedbackActionButton
            data={data}
            activitiesList={activities?.data}
            callback={feedbackCallback}
          />
        )}
        <AddReminderActionButton
          data={data}
          activitiesList={activities?.data}
          callback={reminderCallback}
        />

        {canCreateNotes && (
          <AddNoteActionButton
            data={data}
            activitiesList={activities?.data}
            callback={noteCallback}
          />
        )}
      </Box>
    </Box>
  );
}
