import { useCallback } from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import { EditNoteProps } from 'utils/dialogs/mapping-classic-interfaces';

import notesModel, { NoteData } from 'data/models/entities/notes';

import { useClassicDialog } from './use-classic-dialog';

interface UseAddNoteArgs extends Pick<EditNoteProps, 'data' | 'options'> {
  callback?: (args?: any) => any;
}
export function useAddNote({ data, options, callback }: UseAddNoteArgs) {
  const editNoteDialog = useClassicDialog('editNote');

  const notes = useModelActions(notesModel);

  return useCallback(() => {
    editNoteDialog.open({
      data,
      options,
      callback: (noteData: NoteData, deferred) => {
        return (
          notes
            .createItem({
              data: { note_type: noteData?.noteType, ...noteData }
            })
            .then(() => callback?.())
            .then(deferred.resolve)
            // Error catching is handled from the note dialog
            .catch(deferred.reject)
        );
      }
    });
  }, [callback, editNoteDialog, data, notes, options]);
}
