import React, { useEffect, useMemo, useState } from 'react';
import Box from '@rexlabs/box';

import { SubHeading } from 'components/text/sub-heading';
import { Body } from 'components/text/body';
import { RecordListActions } from 'components/record-list-screen/actions';

import { PADDINGS } from 'shared/theme';
import { ColumnConfig } from 'components/record-list-screen/types';
import { ContractCondition } from 'features/contract-conditions/data/contract-conditions';

import { SettingsPopout } from '../components/contracts-conditions/settings-popout';
import { useDialog } from 'hooks/use-dialog';
import { Criteria } from 'src/types/criteria';
import { ContractConditionsFilter } from 'features/contract-conditions/data/types';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { RelatedTable } from 'src/view/components/record-details-screen/related-table';
import { getFormattedListingCategories } from 'features/contract-conditions/utils/format';
import { ContractConditionsProps } from './contract-conditions';
import { useRecordNavigation } from 'components/_experiment/record-details-screen/utils/use-record-navigation';
import { DefaultButton } from 'src/view/components/button';
import Icon, { ICONS } from 'shared/components/icon';
import {
  useModelState,
  useModelActions,
  withModel
} from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { IconCell } from 'components/record-list-screen/cells/icon-cell';
import { isBoolean } from 'lodash';
import uiModel from 'data/models/custom/ui';
import adminTemplateLibrariesModel, {
  AdminTemplateLibrariesModel
} from 'data/models/entities/admin-template-libraries';
import { compose } from 'redux';
import { AxiosResponse } from 'axios';

const HamburgerIcon: any = ICONS.HAMBURGER_18;

const styles = StyleSheet({
  heading: { marginLeft: 0, marginBottom: 5 },
  subheading: {
    marginLeft: 0,
    marginBottom: PADDINGS.M,
    whiteSpace: 'pre-wrap'
  }
});

interface ContractConditionsSettingsProps extends ContractConditionsProps {
  adminTemplateLibraries: AdminTemplateLibrariesModel;
}

function ContractConditionsSettings({
  contractConditions: conditions,
  adminTemplateLibraries
}: ContractConditionsSettingsProps) {
  const errorDialog = useErrorDialog();
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;
  const { office_details, managed_libraries } = useModelState(sessionModel);
  const editTemplateLibrarySettings = useDialog('editTemplateLibrarySettings');
  const s = useStyles(styles);

  const [isLoading, setIsLoading] = useState<boolean>();
  const [savedFilters, setSavedFilters] = useState<ContractConditionsFilter>();
  const [contractConditions, setContractConditions] = useState<
    ContractCondition[]
  >([]);
  const addCondition = useDialog('addCondition');
  const confirmation = useDialog('confirmation');
  const manageCondition = useDialog('manageConditions');

  const { goToRecord } = useRecordNavigation();

  const {
    fetchContractConditions,
    removeContractCondition,
    updateContractCondition,
    sortContractConditions
  } = conditions;

  const onEdit = ({ data: { id } }) => {
    goToRecord({
      id,
      allIds: contractConditions.map((condition) => condition.id)
    });
  };

  const onEnableDisable = async ({ data }: { data: ContractCondition }) => {
    loadingIndicatorOn({
      message: data.is_enabled ? 'Disabling condition' : 'Enabling condition'
    });
    try {
      const result = await updateContractCondition({
        ...data,
        is_enabled: !data.is_enabled
      });
      setContractConditions((prev) =>
        prev.map((condition) => (condition.id === data.id ? result : condition))
      );
      confirmation.close();
    } catch (error) {
      errorDialog.open(error as Error);
    }
    loadingIndicatorOff();
  };

  const onHideUnhide = async ({ data }: { data: ContractCondition }) => {
    loadingIndicatorOn({
      message: 'Loading'
    });
    try {
      let response: AxiosResponse<{ result: boolean }> | null = null;
      if (data.is_hidden) {
        response = await adminTemplateLibraries.unhide({
          service_name: 'AdminContractConditions',
          service_object_id: data.id
        });
      } else {
        response = await adminTemplateLibraries.hide({
          service_name: 'AdminContractConditions',
          service_object_id: data.id
        });
      }
      if (response.data.result) {
        setContractConditions((prev) =>
          prev.filter((condition) => condition.id !== data.id)
        );
      }
      confirmation.close();
    } catch (error) {
      errorDialog.open(error as Error);
    }
    loadingIndicatorOff();
  };

  const onRemove = async ({ data: { id, name } }) => {
    confirmation.open({
      title: 'Delete Condition',
      message: `Are you sure you want to delete ‘${name}’?`,
      confirmText: 'Delete',
      onConfirm: async () => {
        setIsLoading(true);
        try {
          await removeContractCondition(id);
          setContractConditions((prev) => prev.filter((c) => c.id !== id));
          confirmation.close();
        } catch (error) {
          errorDialog.open(error as Error);
        }
        setIsLoading(false);
      }
    });
  };

  const onSort = () => {
    manageCondition.open({
      conditions: contractConditions,
      actions: ['order'],
      onSave: async (sortedConditions: ContractCondition[]) => {
        setIsLoading(true);
        try {
          await sortContractConditions(sortedConditions);
          setContractConditions(sortedConditions);
        } catch (error) {
          errorDialog.open(error as Error);
        }
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    const fetchConditions = async () => {
      setIsLoading(true);
      try {
        const criterias: Criteria[] = [];

        if (savedFilters) {
          const { name, is_enabled } = savedFilters;

          if (name)
            criterias.push({
              name: 'name',
              type: 'like',
              value: `%${name}%`
            });
          if (is_enabled)
            criterias.push({
              name: 'is_enabled',
              value: savedFilters.is_enabled === 'enabled'
            });
        }

        // Default to '0' because search endpoint always returns hidden records
        criterias.push({
          name: 'is_hidden',
          type: '=',
          value: savedFilters?.is_hidden === 'yes' ? '1' : '0'
        });

        const response = await fetchContractConditions({
          criteria: criterias,
          limit: 100
        });
        setContractConditions(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorDialog.open(error as Error);
      }
    };

    fetchConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFilters]);

  const columns: ColumnConfig<ContractCondition>[] = useMemo(
    () => [
      {
        id: 'templates',
        label: '',
        Cell: IconCell as ColumnConfig['Cell'],
        width: '25px',
        emptyValue: '',
        cellProps: {
          isVisible: (data: ContractCondition) => !!data.library,
          style: { padding: '8px 0px 8px 15px' },
          Icon: (
            <Icon
              type={ICONS.TEMPLATE}
              hasControlledColor={false}
              style={{ display: 'flex' }}
            />
          )
        }
      },
      {
        id: 'name',
        label: 'Name',
        cellProps: {
          items: ({ is_enabled, account_id, id, library, is_hidden }) => [
            {
              id: 'edit',
              label: 'Edit',
              onClick: onEdit
            },
            ...(library
              ? [
                  {
                    id: is_hidden ? 'show' : 'hide',
                    label: is_hidden ? 'Show' : 'Hide',
                    onClick: onHideUnhide
                  }
                ]
              : []),
            ...(account_id === office_details?.id
              ? [
                  {
                    id: is_enabled ? 'disable' : 'enable',
                    label: is_enabled ? 'Disable' : 'Enable',
                    onClick: onEnableDisable
                  }
                ]
              : []),
            ...(account_id === office_details?.id &&
            managed_libraries?.length > 0
              ? [
                  {
                    id: 'library_settings',
                    label: 'Library settings',
                    onClick: () =>
                      editTemplateLibrarySettings.open({
                        data: library
                          ? {
                              id: library.id,
                              _library_name: library.library_name
                            }
                          : null,
                        recordId: id,
                        className: 'AdminContractConditions',
                        callback: (
                          res: boolean | { id: string; _library_name: string }
                        ) => {
                          if (isBoolean(res) && res) {
                            setContractConditions((prev) =>
                              prev.map((prevCondition) =>
                                prevCondition.id === id
                                  ? { ...prevCondition, library: null }
                                  : prevCondition
                              )
                            );
                          } else if (res) {
                            setContractConditions((prev) =>
                              prev.map((prevCondition) =>
                                prevCondition.id === id
                                  ? {
                                      ...prevCondition,
                                      library: {
                                        ...res,
                                        library_name: res._library_name
                                      }
                                    }
                                  : prevCondition
                              )
                            );
                          }
                        }
                      })
                  }
                ]
              : []),
            ...(account_id === office_details?.id
              ? [
                  {
                    id: 'delete',
                    label: 'Delete',
                    onClick: onRemove
                  }
                ]
              : [])
          ]
        }
      },
      {
        id: 'applied_listings',
        label: 'Apply to listing type(s)',
        selector: getFormattedListingCategories
      },
      {
        id: 'is_enabled',
        label: 'Status',
        selector: ({ is_enabled }) => (is_enabled ? 'Enabled' : 'Disabled')
      },
      {
        id: 'library',
        label: 'Library',
        selector: ({ library }) => library?.library_name
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contractConditions]
  );

  return (
    <Box p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <Box m={0}>
        <SubHeading semibold {...s('heading')}>
          Contract Conditions
        </SubHeading>
        <Body regular dark {...s('subheading')}>
          Create and manage conditions to use on listing contracts. Each
          condition can be configured to customise the visibility settings and
          editing capabilities.
        </Body>
      </Box>
      <Box w={'calc(100% - 15px)'}>
        <RelatedTable
          Heading={() => (
            <Box display='flex' flexDirection='row' spacing={4}>
              <RecordListActions
                onAdd={addCondition.open}
                selection={{ type: 'include', ids: [] }}
              />
              <DefaultButton lightGrey wide onClick={onSort}>
                <HamburgerIcon />
              </DefaultButton>
              <SettingsPopout
                onClearFilter={() => setSavedFilters(undefined)}
                savedFilter={savedFilters}
                onFilter={setSavedFilters}
              />
            </Box>
          )}
          columns={columns}
          limit={20} // 20 items per page
          items={contractConditions}
          isLoading={isLoading}
          emptyMessage='No conditions found'
          colorScheme='light'
          showFooterPagination={true}
        />
      </Box>
    </Box>
  );
}

export default compose<React.FC<ContractConditionsProps>>(
  withModel(adminTemplateLibrariesModel)
)(ContractConditionsSettings);
