import { ICONS } from 'shared/components/icon';

const RecordModeSvg: any = ICONS.OTHER;
const FinanceModeSvg: any = ICONS.FINANCE;

export const listingsModes = [
  {
    id: 'default',
    label: 'Record Mode',
    Icon: RecordModeSvg
  },
  {
    id: 'financials',
    label: 'Finance Mode',
    Icon: FinanceModeSvg,
    isFullWidth: true
  }
];
