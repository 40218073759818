import React, { ReactNode } from 'react';

import { RedirectParagraph } from './redirect-paragraph-text';
import { RedirectHeading } from './redirect-heading';

export interface RedirectContentProps {
  loading?: boolean;
  heading: ReactNode;
  bodyText: ReactNode;
}

export function RedirectContent({
  loading,
  heading,
  bodyText
}: RedirectContentProps) {
  return (
    <>
      <RedirectHeading loading={loading}>{heading}</RedirectHeading>
      <RedirectParagraph>{bodyText}</RedirectParagraph>
    </>
  );
}
