import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS } from 'theme';
import TextButton from 'view/components/button/text';
import React, { ReactElement } from 'react';

const textButtonStyles = {
  Button: StyleSheet({
    container: {
      // HACK: to increate specificity
      '&&': {
        paddingLeft: 0,
        height: 'auto'
      }
    },
    content: {
      '&&': {
        justifyContent: 'flex-start',
        color: COLORS.PRIMARY.SAND
      }
    }
  })
};

type TextButtonProps = React.ComponentProps<typeof TextButton>;

function RightBarWidgetTextButton(props: TextButtonProps): ReactElement {
  return (
    <StylesProvider
      components={textButtonStyles}
      prioritiseParentStyles={false}
    >
      <TextButton {...props} />
    </StylesProvider>
  );
}

export default RightBarWidgetTextButton;
