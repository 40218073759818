import React, { PureComponent, Fragment } from 'react';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { withForm, Form, FormField } from 'view/components/form';
import { ValueListSelect } from 'view/components/input/select';
import CheckboxInput from 'view/components/input/checkbox';
import Box from '@rexlabs/box';

import ui from 'data/models/custom/ui';
import userProfile from 'data/models/entities/user-profile';
import accountUsers from 'data/models/value-lists/account-users';
import Spinner from 'shared/components/spinner';
import { autobind } from 'core-decorators';

import _ from 'lodash';
import { connect } from 'react-redux';
import SaveCancelContext from 'data/save-cancel-context';

const delegateInputForm = {
  name: 'delegateInput',
  handleSubmit: (values, { props }) => {
    props.updateSaveCancelOverlayState('IN_PROGRESS');

    return props
      .setGlobalSettings({
        settings: {
          delegate_workflows_to_user_id: values.enableDelegateInput
            ? values.assignee
            : null
        }
      })
      .then(() => {
        props.updateSaveCancelOverlayState('OFF');
      })
      .catch((e) => {
        props.errorDialog.open(e);
        props.updateSaveCancelOverlayState('ON');
      });
  },
  mapPropsToValues: (props) => {
    const { userProfileSettings } = props;
    return {
      assignee: _.get(userProfileSettings, 'delegate_workflows_to_user.id', ''),
      enableDelegateInput:
        _.get(userProfileSettings, 'delegate_workflows_to_user', null) !== null
    };
  },
  asyncValuesReady: (props) => {
    const { userProfileSettings, accountUsers } = props;
    return userProfileSettings !== null && accountUsers.status !== 'loading';
  }
};

const checkBoxStyles = {
  InnerField: StyleSheet({
    field: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    fieldLabel: {
      marginBottom: '0px'
    }
  })
};

@withErrorDialog
@connect(null, {
  updateSaveCancelOverlayState: ui.actionCreators.updateSaveCancelOverlayState,
  setGlobalSettings: userProfile.actionCreators.setGlobalSettings,
  accountUsers: accountUsers
})
@withForm(delegateInputForm)
@autobind
class DelegateInputContent extends PureComponent {
  componentDidMount() {
    const { delegateInput, updateSaveCancelOverlayState } = this.props;
    const newContext = {
      save: delegateInput.submitForm,
      cancel: () => {
        delegateInput.resetForm();
        updateSaveCancelOverlayState('OFF');
      }
    };

    // Setup the save/cancel overlay
    SaveCancelContext.push(newContext);
  }

  componentWillUnmount() {
    const { updateSaveCancelOverlayState, delegateInput } = this.props;
    updateSaveCancelOverlayState('OFF');
    delegateInput.resetForm();
    SaveCancelContext.pop();
  }

  showSaveCancel() {
    this.props.updateSaveCancelOverlayState('ON');
  }

  handleEnable({ target }) {
    const { value: enabledValue } = target;
    // Clear the assignee select value when the user disables this feature
    if (enabledValue !== 'on') {
      this.props.delegateInput.setFieldValue('assignee', null, false);
    }

    this.showSaveCancel();
  }

  render() {
    const { delegateInput } = this.props;

    return (
      <Form>
        <StylesProvider
          components={checkBoxStyles}
          prioritiseParentStyles={false}
        >
          <FormField
            name='enableDelegateInput'
            label='reassign my input requests'
            Input={CheckboxInput}
            onChange={this.handleEnable}
            sendImmediate
          />
        </StylesProvider>
        <FormField
          name='assignee'
          label='assignee'
          Input={ValueListSelect}
          onChange={this.showSaveCancel}
          inputProps={{
            disabled: !delegateInput.values.enableDelegateInput,
            models: [accountUsers]
          }}
        />
      </Form>
    );
  }
}

@connect(null, {
  getGlobalSettings: userProfile.actionCreators.getGlobalSettings,
  setGlobalSettings: userProfile.actionCreators.setGlobalSettings
})
class DelegateInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userProfileSettings: null
    };

    props.getGlobalSettings().then((response) => {
      this.setState({ userProfileSettings: response });
    });
  }

  render() {
    const { userProfileSettings } = this.state;
    const isLoading = userProfileSettings === null;

    return isLoading ? (
      <Box
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Spinner dark small />
      </Box>
    ) : (
      <Fragment>
        <div className='vspace-m'>
          <p className='h2 vspace-s'>Delegate Input Requests</p>
          <p className='vspace-s'>
            Any pending workflow input requests or future input requests can be
            reassigned to another user to complete.
          </p>
        </div>

        <DelegateInputContent
          {...this.props}
          userProfileSettings={userProfileSettings}
        />
      </Fragment>
    );
  }
}

export default DelegateInput;
