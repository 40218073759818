import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { ReactElement } from 'react';
import IconButton from 'view/components/button/icon';
import { ICONS } from 'shared/components/icon';

type IconButtonProps = React.ComponentProps<typeof IconButton>;

type RightBarWidgetIconButtonProps = {
  Icon: any;
} & IconButtonProps;

const defaultStyles = StyleSheet({
  iconButton: {
    width: '12.5px'
  }
});

function RightBarWidgetIconButton({
  Icon = ICONS.ADD,
  ...props
}: RightBarWidgetIconButtonProps): ReactElement {
  const s = useStyles(defaultStyles);
  return <IconButton red circle Icon={Icon} {...s('iconButton')} {...props} />;
}

export default RightBarWidgetIconButton;
