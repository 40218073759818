import { Generator } from 'shared/utils/models';

/*
|-------------------------------------------------------------------------------
| Model
*/

const initialState = {
  list: [],
  serviceId: null,
  recordId: null
};

const actionCreators = {
  updateRecordDetails: {
    reduce: (state, action) => ({
      ...state,
      ...action.payload
    })
  },
  refresh: {
    reduce: () => initialState
  },
  create: {
    reduce: (state, action) => ({
      ...state,
      list: [...state.list, action.payload.newNotification]
    })
  }
};

const selectors = {
  list: (state) => state?.notification?.list,
  recordId: (state) => state?.notification?.recordId || null,
  serviceId: (state) => state?.notification?.serviceId || null
};

export default new Generator('notification').createModel({
  initialState,
  actionCreators,
  selectors
});
