import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';

import Icon, { ICONS } from 'shared/components/icon';
import { createKey } from 'shared/utils/react';
import { TEXTS, PADDINGS, COLORS } from 'theme';

import { Link } from 'components/text/link';
import env from 'shared/utils/config';

import withClassicDialog from 'view/containers/with-classic-dialog';
import { withPermissions } from 'src/hocs/with-permissions';

import Dialogs from 'data/classic-bridges/dialogs-shell';
import sessionModel from 'data/models/custom/session';

const defaultStyles = StyleSheet({
  container: {
    width: 'inherit',
    minWidth: '345px',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    ...TEXTS.GLOBAL_NAVIGATION.TIER_2_HEADER,
    letterSpacing: '0.6px',
    fontSize: '12px',
    display: 'flex',
    borderBottom: `1px solid ${COLORS.BACKGROUNDS.SAND}`,
    margin: `0 ${PADDINGS.S}`,
    marginTop: `-${PADDINGS.XXS}`,
    height: '50px',
    alignItems: 'center'
  },
  footer: {
    letterSpacing: '0.6px',
    fontSize: '12px',
    display: 'flex',
    borderTop: `1px solid ${COLORS.BACKGROUNDS.SAND}`,
    margin: `0 ${PADDINGS.S}`,
    height: '50px',
    alignItems: 'center'
  },
  searchInput: {
    ...TEXTS.CONTENT.INPUT_TEXT_SEMIBOLD,
    width: '100%',
    minHeight: '42px',
    backgroundColor: COLORS.NAV_MENU.GREY_SLATE_LIGHT_ALT,
    borderRadius: '3px',
    paddingLeft: PADDINGS.XS,
    border: 0,

    '::placeholder': {
      ...TEXTS.GLOBAL_NAVIGATION.SEARCH_PLACEHOLDER_IDLE,
      fontSize: '15px',
      color: COLORS.PRIMARY.SLATE,
      fontStyle: 'italic'
    }
  },
  searchIcon: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    right: '0',
    height: '100%',
    width: '42px',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(-50%)',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    color: COLORS.PRIMARY.SLATE,
    cursor: 'pointer'
  },
  searchIconFocused: {
    color: COLORS.PRIMARY.WHITE,
    backgroundColor: COLORS.PRIMARY.BLUE
  },
  searchContainer: {
    position: 'relative',
    margin: `${PADDINGS.XS} ${PADDINGS.S}`
  },
  loginLink: {
    marginRight: '20px',
    marginLeft: 'auto',
    '& .login-link svg': {
      display: 'flex',
      width: '25px',
      height: '25px',
      margin: '0px',
      color: COLORS.BLUE_REX
    }
  },

  // OLD STUFF
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.75)',
    minHeight: '50px',
    fontSize: '15px',
    lineHeight: '22px',
    cursor: 'pointer',

    '& svg': {
      marginRight: '15px',
      marginLeft: '20px',

      width: '25px'
    },

    '&:hover': {
      backgroundColor: '#F7F8F9',
      color: 'black'
    }
  },
  iconLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  label: {},
  badge: {
    textAlign: 'center',
    marginRight: '20px',
    padding: '0 7.5px',
    backgroundColor: '#29a2e2',
    color: 'white',
    borderRadius: '85px'
  },
  separator: {
    height: '1px',
    backgroundColor: '#E1E1E1',
    margin: '13px 20px',
    opacity: '0.5'
  },

  metaWrapper: {
    display: 'flex',
    padding: '15px'
  },
  profilePicture: {
    height: '56px',
    minWidth: '56px',
    marginRight: '15px',
    borderRadius: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',

    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '20px'
  },
  metaInfo: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: '600',
    width: '100%'
  },
  fullName: {
    color: '#424242',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 20px)'
  },
  role: {
    color: '#A8AFB4'
  }
});

const iconStyles = StyleSheet({
  container: {
    height: '22px',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    height: '20px'
  }
});

@withPermissions
@withClassicDialog('helpCentreContactSupport')
@withModel(sessionModel)
@styled(defaultStyles)
@autobind
class HelpCentreContent extends PureComponent {
  constructor(props) {
    super(props);

    this.app = props.session.isRosieAccount ? 'Rosie' : 'Rex';

    this.MENU_CONFIG = [
      {
        icon: ICONS.DOCUMENT,
        name: 'Help Articles',
        href: '//support.rexsoftware.com/'
      },
      {
        icon: ICONS.SUPPORT,
        name: 'My Support Status',
        href: '//support.rexsoftware.com/'
      },
      {
        icon: ICONS.FEEDBACK,
        name: 'Suggest a Feature',
        href: '//rexsoftware.uservoice.com/'
      },
      {
        icon: ICONS.EMAIL,
        name: 'Contact Support Team',
        onClick: () => {
          props.closePopout();
          props.helpCentreContactSupport.open();
        }
      },
      ...(env.ENVIRONMENT
        ? [
            {
              icon: ICONS.HELP,
              name: `About the ${this.app} ${env.ENVIRONMENT}`,
              onClick: () => {
                props.closePopout();
                Dialogs.environment.open();
              }
            }
          ]
        : [])
    ];

    this.state = {
      focused: false,
      search: ''
    };
  }

  handleNavigation(url) {
    const { closePopout } = this.props;
    return () => {
      closePopout();
      window.parent.open(url, '_blank');
    };
  }

  onFocus() {
    this.setState({ focused: true });
  }

  onBlur() {
    this.setState({ focused: false });
  }

  handleSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  render() {
    const {
      styles: s,

      handleRexUniversityLogin
    } = this.props;

    return (
      <Box {...s('container')}>
        <Box {...s('header')}>
          <Icon styles={iconStyles} type={ICONS.HELP_ALT} />
          <span style={{ paddingLeft: PADDINGS.XXS }}>HELP CENTRE</span>
        </Box>

        {/* https://rexsoftware.slack.com/archives/C044MTB40/p1581919982267800?thread_ts=1581903972.256800&cid=C044MTB40
        <Box {...s('searchContainer')}>
          <input
            {...s('searchInput')}
            value={this.state.search}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onClick={this.onFocus}
            onChange={this.handleSearchChange}
            placeholder={`Search ${this.app} Help Centre`}
            onKeyDown={onEnterPress(this.openSupportSearch)}
          />
          <Icon
            onClick={this.openSupportSearch}
            {...s('searchIcon', { searchIconFocused: this.state.focused })}
            type={ICONS.SEARCH}
          />
        </Box> */}

        {this.MENU_CONFIG.map((menuItem) => {
          const { name, href, onClick, icon } = menuItem;
          const onClickHandler = onClick || this.handleNavigation(href);
          const key = createKey(menuItem.name);

          return (
            <span key={key} {...s('menuItem')} onClick={onClickHandler}>
              <Box {...s('iconLabelWrapper')}>
                <Icon
                  style={{ display: 'flex', color: COLORS.PRIMARY.SLATE }}
                  type={icon}
                />
                <Box {...s('label')}>{name}</Box>
              </Box>
            </span>
          );
        })}

        <Box {...s('separator')} />
        <span {...s('menuItem')} onClick={handleRexUniversityLogin}>
          <Box {...s('iconLabelWrapper')}>
            <Box flexDirection='row' alignItems='center'>
              <Icon
                style={{ display: 'flex', color: COLORS.PRIMARY.SLATE }}
                type={ICONS.REX_UNIVERSITY}
              />
              <Box {...s('label')}>Rex Training</Box>
            </Box>
            <Box flexDirection='row' alignItems='center' {...s('loginLink')}>
              <Link blue regular>
                Open
              </Link>
              <Icon
                className='login-link'
                type={ICONS.EXTERNAL_LINK_DEPRECATED}
              />
            </Box>
          </Box>
        </span>
      </Box>
    );
  }
}

export default HelpCentreContent;
