import { DateCell } from 'components/record-list-screen/cells/date-cell';
import { ColumnsConfig } from 'components/record-list-screen/types';

export const campaignColumns: ColumnsConfig = [
  {
    id: 'name',
    label: 'Campaign Name',
    forced: true
  },
  {
    id: 'type.text',
    label: 'Type',
    forced: true
  },
  {
    id: 'status.text',
    label: 'Status',
    forced: true,
    sortable: true
  },
  {
    id: 'start_date',
    label: 'Start Date',
    Cell: DateCell,
    forced: true,
    sortable: true
  },
  {
    id: 'end_date',
    label: 'End Date',
    Cell: DateCell,
    forced: true,
    sortable: true
  },
  {
    id: 'id',
    label: 'ID'
  }
];
