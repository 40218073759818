import _ from 'lodash';
import { Id } from '@rexlabs/model-generator';
import { Generator } from 'shared/utils/models';
import { ListingItem } from './listings';
import { SystemProperties } from '../types/common';
import { TrustAccount } from './trust-accounts';

export interface TrustLedger extends SystemProperties {
  id: Id;
  etag: string;
  ledger_name: string;
  listing: ListingItem;
  related_contact_names: string;
  system_ledger_balance?: string | number;
  system_ledger_state: string;
  trust_account: TrustAccount;
}

export type TrustLedgerResponse = TrustLedger | { result: TrustLedger };

const TYPE = 'trustLedgers';

const trustLedgersModel = new Generator<TrustLedger>(TYPE).createEntityModel();

export default trustLedgersModel;
