import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    columnGap: PADDINGS.S
  }
});

type SavedListItemLeftHandContainerProps = Pick<
  React.ComponentProps<typeof Box>,
  'children' | 'width'
>;

export function SavedListItemLeftHandContainer(
  props: SavedListItemLeftHandContainerProps
) {
  const { children, width = '80%' } = props;
  const s = useStyles(defaultStyles);

  return (
    <Box
      width={width}
      flexDirection='row'
      alignItems='center'
      {...s('container')}
    >
      {children}
    </Box>
  );
}
