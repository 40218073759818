import { LeadsResponse } from 'data/models/entities/leads';

import { formatAddress } from 'utils/formatters';

import {
  MailMergeData,
  MailMergeRelatedRecordData
} from 'hooks/use-send-mail-merge';

export function getMailMergeData(leadData: LeadsResponse): MailMergeData {
  const {
    id,
    contact,
    listing,
    property,
    project_stage: projectStage,
    project
  } = leadData;

  const contactRecord: MailMergeRelatedRecordData = contact?.id
    ? {
        id: contact.id,
        name: contact.name
      }
    : null;

  const listingRecord: MailMergeRelatedRecordData = listing?.id
    ? {
        id: listing.id,
        name: formatAddress(listing.property)
      }
    : null;

  const propertyRecord: MailMergeRelatedRecordData = property?.id
    ? {
        id: property.id,
        name: formatAddress(property)
      }
    : null;

  const projectStageRecord: MailMergeRelatedRecordData = projectStage?.id
    ? {
        id: projectStage.id,
        name: projectStage.full_name
      }
    : null;

  const projectRecord: MailMergeRelatedRecordData = project?.id
    ? {
        id: project.id,
        name: project.name
      }
    : null;

  return {
    contact: contactRecord,
    listing: listingRecord,
    property: propertyRecord,
    project_stage: projectStageRecord,
    project: projectRecord,
    lead_id: id
  };
}
