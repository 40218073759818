import React, { useCallback } from 'react';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import Tooltip from 'view/components/tooltip';

import { Body } from 'components/text/body';
import { FiltersReportingFilterButton } from 'components/button/reporting-filter-button/filters-reporting-filter-button';

import { useDataGrid } from '../hooks/use-data-grid';

export function FiltersButton() {
  const { visiblePanel, togglePanel } = useDataGrid();

  const filtersClick = useCallback(() => {
    togglePanel?.('filters', ({ instance }) => {
      if (instance.displayed) {
        Analytics.track({
          event: EVENTS.CUSTOM_REPORTING.OPEN_FILTER_PANEL
        });
      }
      instance.collapseFilterGroups();
      // TODO: put this back when we get to advancedFilters
      //  https://rexsoftware.atlassian.net/browse/RADI-5131
      // // we want to ensure the advancedFilters section is always expanded by default.
      // instance.expandFilters(['advancedFilters']);
    });
  }, [togglePanel]);

  return (
    <Tooltip
      inline
      hoverTimeout={1}
      distance={'10px'}
      placement='top'
      Content={() => (
        <Body normal white>
          Filters are available for every column, but some filters will only
          appear if the column has first been enabled.
        </Body>
      )}
    >
      <FiltersReportingFilterButton
        showArrowIcon={false}
        onClick={filtersClick}
        isActive={visiblePanel === 'filters'}
      >
        Filters
      </FiltersReportingFilterButton>
    </Tooltip>
  );
}
