import { ErrorDialog, ErrorDialogProps } from 'components/error-dialog';
import { withDialog } from 'shared/hocs/with-dialog';

// While we have the types for ErrorDialogProps, unless you're manually
// creating an error, I would recommend passing the props in as 'any'.
// The BE responses are always the same so makes no sense been thorough
// with the props we're passing into the  dialog.
// Ideally we want to update ModelGenerator so we we get the shape of the
// error for free.
// https://app.shortcut.com/rexlabs/story/65845/modelgenerator-add-the-types-for-the-error-that-is-returned
export type ErrorDialog = { open: (props: ErrorDialogProps) => void };

export interface WithErrorDialog {
  errorDialog?: ErrorDialog;
}
/**
 * Higher order component that provides an error dialog. The dialog's `open`
 * method can be passed either an error - to show a conventional error message -
 * or an errors prop that is an array of strings - to show an error message
 * formatted like a form validation error.
 * @param optionsOrComponent
 * @returns {ErrorDialog}
 *
 * @example
 * catch(errorDialog)
 *
 * @example
 * catch(({ message }) => errorDialog.open({ errors: [message] }))
 */
export const withErrorDialog = withDialog(ErrorDialog, {
  propName: 'errorDialog'
});
