import React, { useMemo } from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { COLORS, PADDINGS } from 'src/theme';

import { SavedListItemFavouriteButton } from './favourite-button';
import { SavedListItemDescription } from './description';
import { SavedListItemRightHandContainer } from './right-hand-container';
import { SavedListItemActionMenu } from './action-menu';
import { SavedListItemIconContainer } from './icon-container';
import { SavedListItemLockedTooltip } from './tooltips/locked-tooltip';
import { SavedListItemPublicTooltip } from './tooltips/public-tooltip';
import { SavedListiItemInfoTooltip } from './tooltips/info-tooltip';
import { SavedListItemGroupTooltip } from './tooltips/group-tooltip';
import { SavedListItemLibraryLabel } from './library-label';
import { SavedListItemLeftHandContainer } from './left-hand-container';

interface SavedListItemProps {
  children: React.ReactNode;
  onClick: (args?: any) => any;
  isActionMenuOpen: boolean;
}

const defaultStyles = StyleSheet({
  container: {
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.BACKGROUNDS.SAND_LIGHT
    },

    '& [data-hover]': {
      opacity: 0,
      transition: 'opacity .2s'
    },

    '&:hover [data-hover]': {
      opacity: 1
    }
  }
});

export function SavedListItem(props: SavedListItemProps) {
  const { children, onClick, isActionMenuOpen } = props;

  const s = useStyles(defaultStyles);

  return (
    <li>
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        p={`${PADDINGS.XS} ${PADDINGS.M}`}
        m={`0 -${PADDINGS.M}`}
        {...s('container', { containerActive: isActionMenuOpen })}
        onClick={onClick}
      >
        {children}
      </Box>
    </li>
  );
}

SavedListItem.FavouriteButton = SavedListItemFavouriteButton;
SavedListItem.Description = SavedListItemDescription;
SavedListItem.RightHandContainer = SavedListItemRightHandContainer;
SavedListItem.ActionMenu = SavedListItemActionMenu;
SavedListItem.IconContainer = SavedListItemIconContainer;
SavedListItem.LockedTooltip = SavedListItemLockedTooltip;
SavedListItem.PublicTooltip = SavedListItemPublicTooltip;
SavedListItem.GroupTooltip = SavedListItemGroupTooltip;
SavedListItem.InfoTooltip = SavedListiItemInfoTooltip;
SavedListItem.LeftHandContainer = SavedListItemLeftHandContainer;
SavedListItem.LibraryLabel = SavedListItemLibraryLabel;
