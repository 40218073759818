import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';

import { RedirectParagraph } from 'view/components/external-link-redirect/redirect-paragraph-text';
import { RedirectContent } from 'view/components/external-link-redirect/redirect-content';
import { RedirectSuccessContent } from 'view/components/external-link-redirect/redirect-success-content';
import { RedirectHeading } from 'view/components/external-link-redirect/redirect-heading';

import { FirstLoginContent } from './first-login-content';
import { ErrorContent } from './error-content';

export interface RexTrainingContentProps {
  onClose?: () => void;
  title: string;
  errorText?: ReactNode;
  loading?: boolean;
  isFirstLogin?: boolean;
  redirectURL?: string | null;
  closeOverlay: () => void;
}

export function RexTrainingContent({
  errorText,
  loading,
  isFirstLogin,
  title,
  closeOverlay,
  redirectURL
}: RexTrainingContentProps) {
  if (errorText) {
    return <ErrorContent errorText={errorText} />;
  }

  if (loading) {
    return (
      <RedirectContent
        loading
        heading='Checking your details'
        bodyText='This should only take a moment.'
      />
    );
  }

  if (isFirstLogin) {
    return <FirstLoginContent closeOverlay={closeOverlay} />;
  }

  return (
    <>
      <RedirectHeading>Ready for you to log in</RedirectHeading>
      <RedirectSuccessContent
        title={title}
        redirectURL={redirectURL}
        closeOverlay={closeOverlay}
      >
        <Box mb={PADDINGS.XL}>
          <RedirectParagraph>
            Use your Rex login email and the password you created for Rex
            Training
          </RedirectParagraph>
        </Box>
      </RedirectSuccessContent>
    </>
  );
}
