import feedbackModel from 'data/models/entities/feedback';
import { useModelActions } from '@rexlabs/model-generator';

import {
  EditFeedbackProps,
  FeedbackData
} from 'utils/dialogs/mapping-classic-interfaces';
import { useCallback } from 'react';
import { noop } from 'lodash';
import { ErrorDialog } from 'hocs/with-error-dialog';
import { AxiosResponse } from 'axios';
import { useDialog } from './use-dialog';

export function useAddFeedback({
  data,
  mode,
  options = {},
  callback = noop,
  id,
  listingId,
  // TODO: Remove this when we have a useDialog hook
  // https://app.shortcut.com/rexlabs/story/55677/create-usedialog-hook
  errorDialog
}: EditFeedbackProps & { errorDialog?: ErrorDialog }) {
  // TODO: type the feedback model
  const feedback = useModelActions(feedbackModel);

  const editFeedback = useDialog('editFeedback');

  return useCallback(() => {
    editFeedback?.open({
      id,
      data,
      options,
      listingId,
      mode,
      callback: async (feedbackData) => {
        try {
          let response: AxiosResponse<{ result: FeedbackData }> | null = null;
          if (options.isEditing && id) {
            response = await feedback.updateItem({
              id,
              data: feedbackData
            });
            // Only try to set approval status for new feedbacks because backend
            // already set it by default
            await feedback.trySetApprovalStatus({
              id,
              status: feedbackData.system_approval_status
            });
          } else {
            response = await feedback.createItem({
              data: feedbackData
            });

            // Set approval status when approve for reporting is checked
            if (feedbackData.system_approval_status) {
              await feedback.trySetApprovalStatus({
                id: response.data.result.id,
                status: feedbackData.system_approval_status
              });
            }
          }

          callback(response);

          return response;
        } catch (error: any) {
          errorDialog?.open(error);
        }
      }
    });
  }, [
    callback,
    data,
    editFeedback,
    errorDialog,
    feedback,
    id,
    listingId,
    mode,
    options
  ]);
}
