import { useMemo } from 'react';
import { useRegion } from 'hooks/use-region';
import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  KeyActivityReportQuery,
  KeyActivityReportQueryDefaultVariables
} from './key-activity-report.query';
import { OrderByDirectionType, ModuleConfigItem } from '../module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';
import { useWhichWordFactory } from 'hooks/use-which-word';

export const defaultColumns = [
  'key_register.listing',
  'key_register.property-property_address',
  'key_register.label',
  'system_checked_out_time',
  'system_checked_out_user.name',
  'checked_out_to',
  'reason',
  'system_checked_in_time',
  'checked_in_by_user.name'
];

export function useKeyActivityModule() {
  const region = useRegion();
  const { listings: listingCustomFields, properties: propertyCustomFields } =
    useCustomFields();

  const ww = useWhichWordFactory();

  const keyActivityModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.key_activity,
      moduleLabel: 'Key Activity',
      exportPrivilege: 'exporting.listings',
      queryConfig: {
        graphQlQuery: KeyActivityReportQuery,
        queryVariables: KeyActivityReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'system_checked_out_time',
            direction: 'descending' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'key_register.listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'key_register.property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Key Activity',
          children: makeEntity({
            entity: 'keyActivity',
            prefix: ''
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'last_30_days',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'activity_date',
              label: 'Date the user either checked out or returned the key'
            }
          ]
        },
        user: {
          availableFields: [
            {
              label: 'User who either checked out or returned the key',
              fieldName: 'activity_user'
            }
          ]
        }
      }
    }),
    [region, listingCustomFields, propertyCustomFields]
  );
  return keyActivityModuleConfig;
}
