import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  viewMore: {
    fontWeight: 600,
    textDecoration: 'underline',
    color: COLORS.PRIMARY.INFORMATIVE,
    fontSize: '12px',
    marginTop: '5px',
    marginRight: 'auto',
    display: 'inline-block',
    width: 'max-content',
    border: 'none',
    background: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  arrow: {
    content: '""',
    background:
      'url(/assets/images/icons/expand-collapse-arrows.png) top left no-repeat',
    width: '7px',
    height: '4px',
    display: 'inline-block',
    marginLeft: '4px',
    marginBottom: '2px'
  },
  expanded: {
    backgroundPosition: '-7px -4px'
  }
});

interface ViewMoreProps {
  expanded: boolean;
  moreCount: number;
  onClick: () => void;
}

const ViewMore = ({ expanded, moreCount, onClick }: ViewMoreProps) => {
  const s = useStyles(defaultStyles);
  return (
    <button {...s('viewMore')} onClick={onClick}>
      view {moreCount} {expanded ? 'less' : 'more'}
      <span {...s('arrow', { expanded })} />
    </button>
  );
};

export default ViewMore;
