// Note: our column definitions and column types might end up needing to be region aware, which would warrant converting them to hooks.
import dayjs from 'dayjs';

import { get, capitalize, isString } from 'lodash';
import { ColDef, RowNode } from 'ag-grid-community';

import { getFormattedDate } from 'utils/date';
import { convertUtcToLocalDate } from 'utils/calendar';

import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';

import { CurrencyColumn } from './columns/currency-column';

import { customFieldColumnTypes } from 'features/custom-reporting/components/custom-field-column-types';
import { recordColumnTypes } from 'features/custom-reporting/components/record-column-types';

export const EMPTY_STATE = '-';

export const getEmptyValue = ({
  node,
  context
}: {
  node: RowNode | null;
  context?: any;
}) => {
  if (context === 'export' || node?.group) {
    return '';
  }
  if (context === 'keyCreator') {
    return 'N/A';
  }
  return EMPTY_STATE;
};

export const defaultValueFormatter = ({
  value,
  node,
  context
}: {
  value: any;
  node: RowNode | null;
  context: any;
}) => {
  return value || getEmptyValue({ node, context });
};

export const dataGridColumnTypes: Record<string, ColDef> = {
  dateColumn: {
    valueFormatter: ({ value, node, context }) =>
      // toDate used in the comparator function converts to the local timezone,
      // so we also want to turn this into a local date
      // this is especially important for calendar dates, which are stored in UTC
      value
        ? getFormattedDate({ date: convertUtcToLocalDate(value) })
        : getEmptyValue({ node, context }),
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        // to use the `equals` filter we need to make sure the date has the
        // exact same time, i.e. midnight
        const cellDate = dayjs(convertUtcToLocalDate(cellValue))
          .startOf('day')
          .toDate();

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      }
    }
  },

  localDateTimeColumn: {
    valueFormatter: ({ value, node, context }) =>
      // toDate used in the comparator function converts to the local timezone,
      // so we also want to turn this into a local date
      // this is especially important for calendar, which is stored in UTC
      value
        ? getFormattedDate({
            date: convertUtcToLocalDate(value),
            format: 'D MMM YYYY [at] h:mm a'
          })
        : getEmptyValue({ node, context }),
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const cellDate = dayjs(cellValue).toDate();
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      }
    }
  },
  localTimeColumn: {
    valueGetter: ({ data, colDef }) => {
      const value = get(data, colDef?.field || '');
      return value
        ? getFormattedDate({
            date: convertUtcToLocalDate(value),
            format: 'h:mm a'
          })
        : '';
    },
    valueFormatter: defaultValueFormatter
  },
  booleanColumn: {
    valueFormatter: ({ value, node }) => {
      if (node?.group) {
        return value === 'true' ? 'Yes' : value === 'N/A' ? 'No' : '';
      }
      return value ? 'Yes' : 'No';
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: ({ value }) => (value === 'true' ? 'Yes' : 'No'),
      values: ['true', 'N/A']
    }
  },
  // TODO - use graphQL to get the right email
  // once BE allows for fetching only email_primary.
  // JIRA https://rexsoftware.atlassian.net/browse/RADI-5063
  primaryEmailColumn: {
    valueGetter: ({ data, colDef }) => {
      const value = get(data, colDef?.field || '');
      return value?.find?.(({ email_primary }) => email_primary)?.email_address;
    },
    valueFormatter: defaultValueFormatter,
    sortable: false
  },
  // TODO - use graphQL to get the right phone
  // once BE allows for fetching only phone_primary.
  // JIRA https://rexsoftware.atlassian.net/browse/RADI-5063
  primaryPhoneColumn: {
    valueGetter: ({ data, colDef }) => {
      // ts workaround for field being maybe undefined
      const value = get(data, colDef?.field || '');
      return value?.find?.(({ phone_primary }) => phone_primary)?.phone_number;
    },
    valueFormatter: defaultValueFormatter,
    sortable: false
  },
  numberColumn: {
    filter: 'agNumberColumnFilter',
    headerClass: 'ag-right-aligned-header',
    cellClass: 'ag-right-aligned-cell',
    enableValue: true,
    // TODO: better limiting of decimal places
    //  JIRA: https://rexsoftware.atlassian.net/browse/RADI-5727
    valueFormatter: ({ value, node, context }) => {
      return value === null || typeof value === 'undefined'
        ? getEmptyValue({ node, context })
        : value;
    }
  },
  currencyColumn: {
    filter: 'agNumberColumnFilter',
    cellRenderer: CurrencyColumn,
    headerClass: 'ag-right-aligned-header',
    enableValue: true
  },
  tagColumn: {
    valueFormatter: ({ value, node, context }) => {
      if (isString(value)) {
        return value;
      }

      return (
        value?.filter?.(Boolean).join(', ') || getEmptyValue({ node, context })
      );
    },
    filter: 'agSetColumnFilter'
  },
  textJoinColumn: {
    // Workaround - the better way of doing this would be to use textFormatter +
    // a custom textMatcher for the filter. Unfortunately using textFormatter causes the textMatcher to
    // not get the search value for the filter. So falling back to valueGetter again.
    // Which is annoying because this seems like a very reusable usecase.
    valueGetter: ({ data, colDef }) => {
      const value = get(data, colDef?.field || '');
      return value
        ?.map((item) => item?.text)
        ?.filter?.(Boolean)
        ?.join(', ');
    },
    valueFormatter: defaultValueFormatter
  },
  percentageColumn: {
    valueGetter: ({ data, colDef, node, context }) => {
      const value = get(data, colDef?.field || '');
      return value === null || value === undefined
        ? getEmptyValue({ node, context })
        : value + '%';
    },
    valueFormatter: defaultValueFormatter
  },
  valueListColumn: {
    valueFormatter: defaultValueFormatter,
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressSorting: true
    }
  },
  capitalizedValueListColumn: {
    valueFormatter: ({ value, node, context }) =>
      value
        ? capitalize(value?.text ? value.text : value)
        : getEmptyValue({ node, context }),
    filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: ({ value }) =>
        value === 'N/A' ? value : capitalize(value)
    }
  },
  // BE is for some reason returning "System User" instead of a blank value
  agentValueListColumn: {
    valueFormatter: ({ value, node, context }) => {
      const emptyValue = getEmptyValue({ node, context });
      return value === 'System User' ? emptyValue : value || emptyValue;
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'account_users' }),
      valueFormatter: ({ value }) => (value === 'System User' ? 'N/A' : value)
    }
  },
  ...recordColumnTypes,
  ...customFieldColumnTypes,

  defaultColumn: {
    valueFormatter: defaultValueFormatter
  }
};
