import React from 'react';
import { SubHeading } from 'components/text/sub-heading';

type RightBarWidgetHeadingProps = React.PropsWithChildren<{}>;

function RightBarWidgetHeading({
  children
}: RightBarWidgetHeadingProps): React.ReactElement {
  return <SubHeading semibold>{children}</SubHeading>;
}

export default RightBarWidgetHeading;
