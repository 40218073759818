import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

const TYPE = 'accountSettingsDefaultAccounts';

const actionCreators = {
  getEffectiveSettings: {
    request: () =>
      api.post('AccountSettingsDefaultAccounts::getEffectiveSettings'),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  updateSettings: {
    request: (payload) =>
      api.post('AccountSettingsDefaultAccounts::updateSettings', payload),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const accountSettingsDefaultAccountsModel = new Generator(
  TYPE
).createEntityModel({
  actionCreators
});

export default accountSettingsDefaultAccountsModel;
