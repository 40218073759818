import React, { PureComponent } from 'react';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS } from 'theme';
import { DatePicker } from '@rexlabs/date-input';
import Icon, { ICONS } from 'shared/components/icon';
import Down from 'assets/icons/arrow-down.svg';
import { Autobind } from 'utils/decorators';
import moment from 'moment';
import dayjs from 'dayjs';

const dateInputOverrides = {
  Branch: StyleSheet({
    today: {
      '> button': {
        textDecoration: 'underline !important',
        fontSize: '14px',
        fontWeight: 500
      }
    }
  }),
  Tether: StyleSheet({
    wrapContent: {
      zIndex: '9999 !important'
    }
  }),
  CalendarMonth: StyleSheet({
    title: {
      color: '#fff !important',
      padding: '3.5px 0 0 0 !important'
    }
  }),
  DayPickerNavigation: StyleSheet({
    prev: {
      height: 'auto !important',
      marginBottom: '0 !important'
    },
    next: {
      height: 'auto !important',
      marginBottom: '0 !important'
    }
  }),
  TextInput: StyleSheet({
    container: {
      minHeight: 'unset',
      border: 'none',
      outline: 'none',
      '&:hover, &:focus, &:focus-within': {
        border: 'none',
        outline: 'none'
      }
    },
    suffix: {
      display: 'none',
      '& > *:first-child': {
        marginLeft: '0 !important'
      }
    },
    prefix: {
      display: 'none !important'
    }
  })
};

const defaultStyles = StyleSheet({
  wrapDateInput: {
    position: 'relative',
    display: 'flex',
    gap: '20px',
    width: '100%',
    height: '28px',
    border: `2px solid ${COLORS.INPUT.BORDER}`,
    '&:hover, &:active': {
      borderColor: COLORS.INPUT.BORDER_ACTIVE
    },
    '&:focus-within': {
      outline: `2px solid ${COLORS.INPUT.OUTLINE}`,
      borderColor: COLORS.INPUT.BORDER_ACTIVE,
      boxShadow: 'none'
    }
  },
  slash: {
    position: 'absolute',
    pointerEvents: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1
  }
});

@styled(defaultStyles)
@Autobind
class DateRangeSelect extends PureComponent {
  render() {
    const {
      styles: s,
      onChange,
      onBlur,
      value,
      placeholder = `e.g. ${dayjs().format('DD/MM/YYYY')}`,
      ...props
    } = this.props;

    function fakeEvent(name, value) {
      return {
        persist: () => null,
        target: {
          type: 'date',
          name,
          id: name,
          value
        }
      };
    }

    return (
      <StylesProvider
        components={dateInputOverrides}
        prioritiseParentStyles={false}
      >
        <div {...s('wrapDateInput')}>
          <ICONS.MIN_MAX {...s('slash')} />
          <DatePicker
            debounce={0}
            padding={0}
            monthPadding={2}
            paddingBottom={5}
            placeholder={placeholder}
            onChange={({ target }) => {
              queueMicrotask(() => {
                onChange(
                  fakeEvent(
                    props.name,
                    target.value
                      ? [target.value.format('YYYY-MM-DD'), value[1] ?? null]
                      : [target.value, value[1] ?? null]
                  )
                );
              });
            }}
            onBlur={({ target }) => {
              onBlur?.(
                fakeEvent(
                  props.name,
                  target.value
                    ? [
                        moment(target.value).format('YYYY-MM-DD'),
                        value[1] ?? null
                      ]
                    : [target.value, value[1] ?? null]
                )
              );
            }}
            position='bottom'
            daySize={26}
            navPrev={
              <Icon
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={Down}
              />
            }
            navNext={
              <Icon
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'flex',
                  transform: 'rotate(180deg)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={Down}
              />
            }
            value={
              moment.isMoment(value[0]) ||
              moment(value[0], 'YYYY-MM-DD', true).isValid()
                ? moment(value[0])
                : undefined
            }
            displayFormat='D MMM YY'
            {...props}
          />
          <DatePicker
            debounce={0}
            padding={0}
            monthPadding={2}
            paddingBottom={5}
            placeholder={placeholder}
            onChange={({ target }) => {
              queueMicrotask(() => {
                onChange(
                  fakeEvent(
                    props.name,
                    target.value
                      ? [value[0] ?? null, target.value.format('YYYY-MM-DD')]
                      : [value[0] ?? null, target.value]
                  )
                );
              });
            }}
            onBlur={({ target }) => {
              onBlur?.(
                fakeEvent(
                  props.name,
                  target.value
                    ? [
                        value[0] ?? null,
                        moment(target.value).format('YYYY-MM-DD')
                      ]
                    : [value[0] ?? null, target.value]
                )
              );
            }}
            position='bottom'
            daySize={26}
            navPrev={
              <Icon
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={Down}
              />
            }
            navNext={
              <Icon
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'flex',
                  transform: 'rotate(180deg)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={Down}
              />
            }
            value={
              moment.isMoment(value[1]) ||
              moment(value[1], 'YYYY-MM-DD', true).isValid()
                ? moment(value[1])
                : undefined
            }
            displayFormat='D MMM YY'
            {...props}
          />
        </div>
      </StylesProvider>
    );
  }
}

export default DateRangeSelect;
