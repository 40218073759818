import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  container: {
    marginBottom: '12px'
  },

  heading: {
    fontSize: '17px',
    fontWeight: 600
  }
});

export function Heading({ children }) {
  const s = useStyles(defaultStyles);
  return (
    <Box {...s('container')}>
      <h1 {...s('heading')}>{children}</h1>
    </Box>
  );
}

export function HeadingWithButtons({ Buttons, children }) {
  const s = useStyles(defaultStyles);
  return (
    <Box {...s('container')} sx='6px' flexDirection='row' alignItems='center'>
      <Box mr='8px'>
        <h1 {...s('heading')}>{children}</h1>
      </Box>
      <Buttons />
    </Box>
  );
}
