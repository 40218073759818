import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'view/components/button';
import { Dropdown } from 'view/components/action-menu';

import { useMode } from './utils/use-mode';
import { useRecordNavigation } from './utils/use-record-navigation';

const defaultStyles = StyleSheet({
  modes: {
    '& > button': {
      textTransform: 'lowercase',
      padding: '0 8px'
    },

    '& > button:not(:first-child):not(:last-child)': {
      borderRadius: 0
    },

    '& > button:first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },

    '& > button:last-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  },

  modeIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px'
  },

  modeLabel: {
    marginLeft: '6px'
  }
});

const BackSvg: any = ICONS.ARROW_BACK;

export function RecordDetailActions({ actions, data, modes }) {
  const s = useStyles(defaultStyles);

  const [currentMode, setMode] = useMode();
  const { allIds, goToList } = useRecordNavigation();

  return (
    <Box sx='4px' flexDirection='row' alignItems='center'>
      <DefaultButton
        wide
        dark
        onClick={() => goToList()}
        // eslint-disable-next-line
        // @ts-ignore
        isDisabled={!allIds?.length}
      >
        <BackSvg />
      </DefaultButton>

      {/* Actions */}
      {actions?.map?.((action, i) => {
        if (action.items) {
          return (
            <Dropdown
              key={i}
              placement='bottom-start'
              items={action.items.map((item) => ({
                ...item,
                onClick: () => item.onClick?.({ data })
              }))}
            >
              {action.label}
            </Dropdown>
          );
        }
        return (
          <DefaultButton onClick={() => action.onClick?.({ data })}>
            {action.label}
          </DefaultButton>
        );
      })}

      {/* Modes */}
      {!!modes?.length && (
        <Box {...s('modes')} flexDirection='row' alignItems='center'>
          {modes.map((mode) => (
            <DefaultButton
              key={mode.id}
              dark={mode.id === currentMode}
              onClick={() => {
                setMode(mode.id);
              }}
            >
              <span {...s('modeIcon')}>
                <mode.Icon />
              </span>
              {mode.id === currentMode && (
                <span {...s('modeLabel')}>in {mode.label}</span>
              )}
            </DefaultButton>
          ))}
        </Box>
      )}
    </Box>
  );
}
