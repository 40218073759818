import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useModelActions } from '@rexlabs/model-generator';
import { useWhereabouts } from '@rexlabs/whereabouts';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import savedFiltersModel, {
  SavedFilterItem
} from 'data/models/entities/saved-filters';
import uiModel from 'data/models/custom/ui';

import { useErrorDialog } from 'hooks/use-error-dialog';

import { SavedListItem } from 'components/saved-list-item';

import { SavedFiltersPopoutContentProps } from './content';

export interface FilterItemProps extends SavedFiltersPopoutContentProps {
  filter: SavedFilterItem;
  onFavouriteChange: (filter: SavedFilterItem) => any;
}

export function FilterItem({
  filter,
  setCriteria,
  serviceName,
  onFavouriteChange,
  close,
  savedFilterDialog
}: FilterItemProps) {
  const whereabouts = useWhereabouts();

  const errorDialog = useErrorDialog();

  const { fetchItem, trashItem, updateItem } =
    useModelActions(savedFiltersModel);

  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const [isOpen, setOpen] = useState(false);

  const filterId = filter.id.toString();

  const applyFilter = useCallback(async () => {
    try {
      const response = await fetchItem({ id: filter.id });

      if (response.data?.criteria) {
        setCriteria(response.data.criteria, filter.id);
        close?.();
      }
    } catch (error) {
      errorDialog?.open(error as any);
    }
  }, [close, errorDialog, fetchItem, filter.id, setCriteria]);

  const editFilter = useCallback(() => {
    savedFilterDialog?.open?.({
      serviceName,
      filter
    });
  }, [savedFilterDialog, serviceName, filter]);

  const trashFilter = useCallback(() => {
    loadingIndicatorOn({ message: 'Trashing saved filter' });

    trashItem({ id: filterId })
      .then(() => {
        if (whereabouts.hashQuery?.filterId === filterId) {
          setCriteria([]);
        }
        loadingIndicatorOff();
      })
      .catch(errorDialog?.open);
  }, [
    errorDialog,
    filterId,
    loadingIndicatorOff,
    loadingIndicatorOn,
    setCriteria,
    trashItem,
    whereabouts.hashQuery
  ]);

  const toggleFavourite = useCallback(() => {
    loadingIndicatorOn({ message: 'Updating saved filter' });

    return onFavouriteChange(filter)
      .then(() => {
        if (!filter.favourite) {
          Analytics.track({
            event: EVENTS.RECORD_LIST_SCREEN.SAVED_FILTERS.FAVOURITED,
            properties: {
              filterId: filter.id
            }
          });
        }
      })
      .then(loadingIndicatorOff)
      .catch(errorDialog?.open);
  }, [
    loadingIndicatorOn,
    onFavouriteChange,
    filter,
    loadingIndicatorOff,
    errorDialog
  ]);

  const togglePrivate = useCallback(() => {
    if (filterId) {
      loadingIndicatorOn({ message: 'Updating saved filter' });
      return updateItem({
        id: filterId,
        data: {
          newData: { is_public: !filter.is_public },
          prevData: filter
        }
      })
        .then(loadingIndicatorOff)
        .catch(errorDialog?.open);
    }
  }, [
    errorDialog,
    filter,
    filterId,
    loadingIndicatorOff,
    loadingIndicatorOn,
    updateItem
  ]);

  const arrowDropdownOptions = useMemo(
    () => [
      {
        label: 'Run',
        onClick: applyFilter
      },
      ...(filter.security_user_rights.includes('update')
        ? [
            {
              label: 'Edit',
              onClick: editFilter
            },
            {
              label: filter.is_public ? 'Make private' : 'Make public',
              onClick: togglePrivate
            },
            {
              label: 'Trash',
              onClick: trashFilter
            }
          ]
        : [])
    ],
    [
      applyFilter,
      editFilter,
      filter.is_public,
      filter.security_user_rights,
      togglePrivate,
      trashFilter
    ]
  );

  return (
    <SavedListItem isActionMenuOpen={isOpen} onClick={applyFilter}>
      <SavedListItem.LeftHandContainer>
        <SavedListItem.FavouriteButton
          isFavourite={filter.favourite}
          toggleFavourite={toggleFavourite}
        />
        <SavedListItem.Description>{filter.name}</SavedListItem.Description>
      </SavedListItem.LeftHandContainer>
      <SavedListItem.RightHandContainer>
        <SavedListItem.ActionMenu
          handledActionMenuToogle={setOpen}
          ActionMenuItems={arrowDropdownOptions}
        />
        <SavedListItem.IconContainer>
          <SavedListItem.LockedTooltip
            isVisible={!filter.security_user_rights.includes('update')}
            content={'You do not have the permissions to update this filter.'}
          />
          <SavedListItem.PublicTooltip
            isVisible={!!filter.is_public}
            content={'This filter is public.'}
          />
          <SavedListItem.InfoTooltip
            content={`Created ${dayjs(
              parseInt(filter.system_ctime) * 1000
            ).format('D MMM YYYY')} by ${filter.system_created_user.name}`}
          />
        </SavedListItem.IconContainer>
      </SavedListItem.RightHandContainer>
    </SavedListItem>
  );
}
