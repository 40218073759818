export const PADDINGS = {
  '3XS': '4px',
  XXS: '5px',
  XS: '10px',
  S: '15px',
  M: '20px',
  L: '25px',
  XL: '30px',
  XXL: '35px',
  '3XL': '40px'
} as const;
