import React, { ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { FONT, COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  link: {
    fontWeight: 600,
    fontSize: FONT.SIZES.LARGE,
    cursor: 'pointer',

    '&:hover': {
      opacity: '0.7'
    }
  }
});

export function RedirectLink({
  children,
  href,
  onClick
}: {
  children: ReactNode;
  href?: string;
  onClick?: any;
}) {
  const s = useStyles(defaultStyles);

  return (
    <a
      {...s('link')}
      // HACk - increase specificity to overcome styles when this component is used in classic
      style={{ color: COLORS.DARKER_GREY, textDecoration: 'underline' }}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  );
}
