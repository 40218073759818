import { lazy } from 'react';

const ConfirmWorkflowDialog = lazy(() => import('./confirm-workflow'));
const StartSelectWorkflowDialog = lazy(() => import('./start-select-workflow'));
const WorkflowDetailsDialog = lazy(() =>
  import('./details/workflow-details-dialog')
);
const EndWorkflowDialog = lazy(() => import('./end-workflow'));

export {
  ConfirmWorkflowDialog,
  StartSelectWorkflowDialog,
  WorkflowDetailsDialog,
  EndWorkflowDialog
};
