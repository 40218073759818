import { usePermissions } from 'hooks/use-permissions';
import { ListingItem } from 'data/models/entities/listings';
import { ColumnsConfig } from 'components/record-list-screen/types';
import { useMemo } from 'react';
import { DateTimeCell } from 'components/record-list-screen/cells/date-time-cell';

export function useListingsListColumns() {
  const permissions = usePermissions();

  const listingsColumns: ColumnsConfig = useMemo<ColumnsConfig>(
    (): ColumnsConfig<ListingItem> => [
      {
        id: 'id',
        label: 'ID'
      },
      {
        label: 'Address',
        id: 'fullAddress',
        sortable: true,
        selector: (row) => row.property.adr_street_name || ''
      },
      {
        label: 'Suburb',
        id: 'propertySuburb',
        selector: 'property.adr_suburb_or_town',
        sortable: true
      },
      {
        label: 'Category',
        id: 'category',
        selector: 'listing_category.text',
        sortable: true
      },
      {
        label: 'Publication',
        id: 'publicationStatus',
        sortable: true,
        // sortable: 'system_publication_status',
        selector: (row) =>
          row.system_publication_status === 'published' ? 'Published' : 'Draft'
      },
      {
        label: 'Contract Status',
        id: 'contractStatus'
      },
      {
        id: 'system_owner_user.name',
        label: 'Owned By'
      },
      {
        id: 'system_ctime',
        label: 'Created Date',
        Cell: DateTimeCell,
        sortable: true
      },
      {
        id: 'system_created_user.name',
        label: 'Created By'
      },
      {
        id: 'system_modtime',
        label: 'Last Modified',
        Cell: DateTimeCell,
        sortable: true
      },
      {
        id: 'system_modified_user.name',
        label: 'Modified By'
      },
      ...(permissions.hasAddon('projects')
        ? [
            {
              label: 'Project Listing Status',
              id: 'projectListingStatus'
            }
          ]
        : []),
      ...(permissions.hasAddon('projects')
        ? [
            {
              label: 'Project Stage',
              id: 'projectStageName',
              sortable: true,
              selector: 'project_stage.full_name'
            }
          ]
        : [])
    ],
    [permissions]
  );

  return listingsColumns;
}
