import React, { useCallback } from 'react';
import { compose } from 'redux';

import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';

import { WithErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import PaddingBox from 'view/components/padding-box';

import { Link } from 'components/text/link';
import { Body } from 'components/text/body';

import { useLeadDialog } from 'features/leads/hooks/use-lead-dialog';

type LeadsDialogWarningBannerProps = {
  id: string;
  leadType?: string;
  missingRequiredRecordTypes: string[];
};

function LeadsDialogWarningBannerComponent({
  id,
  leadType,
  missingRequiredRecordTypes
}: LeadsDialogWarningBannerProps & WithErrorDialog) {
  const actionInfo =
    missingRequiredRecordTypes.length === 1
      ? missingRequiredRecordTypes[0]
      : 'records';

  const warningInfo =
    missingRequiredRecordTypes.length === 1
      ? missingRequiredRecordTypes[0]
      : `${missingRequiredRecordTypes[0]} or ${missingRequiredRecordTypes[1]}`;

  const warning = `This lead is not associated with a ${warningInfo}.`;
  const actionLabel = `Add related ${actionInfo}`;

  const editLead = useLeadDialog({ id });

  const onEditClick = useCallback(() => {
    Analytics.track({
      event: EVENTS.LEADS_DIALOG.ADD_MISSING_RECORDS_ACTION,
      properties: {
        lead_type: leadType
      },
      options: { integrations: { Intercom: true } }
    });
    return editLead();
  }, [editLead, leadType]);

  return (
    <PaddingBox yellow p={PADDINGS.S}>
      <Box flexDirection='column' sy={PADDINGS.S}>
        <Body regular dark>
          {warning}
        </Body>

        <Link onClick={onEditClick}>
          <span>{actionLabel}</span>
        </Link>
      </Box>
    </PaddingBox>
  );
}
export const LeadsDialogWarningBanner = compose<
  React.FC<LeadsDialogWarningBannerProps>
>(withErrorDialog)(LeadsDialogWarningBannerComponent);
