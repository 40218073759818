import React, { Fragment, PureComponent } from 'react';

import { styled, StyleSheet } from '@rexlabs/styling';
import { Portal } from '@rexlabs/portal';
import { autobind } from 'core-decorators';
import { COLORS } from 'theme';
import Spinner from 'shared/components/spinner';
import Analytics from 'shared/utils/vivid-analytics';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    background: 'rgba(0, 0, 0, .90)',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'auto'
  },
  content: {
    zIndex: 10,
    color: COLORS.WHITE,
    padding: '100px 10px 10px 75px',
    width: '100%',
    overflow: 'auto'
  },

  contentLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh'
  },

  equalPadding: {
    padding: '100px 10px 10px'
  },

  alwaysRenderChildren: {
    width: '100%',
    height: '100%'
  },

  alwaysRenderChildrenLoading: {
    display: 'none'
  }
});

@styled(defaultStyles)
@autobind
class Overlay extends PureComponent {
  handleSegment() {
    if (this.props.title) {
      Analytics.track({
        event: 'Dialog Open',
        properties: {
          name: this.props.title
        }
      });
    } else {
      // Throw an error - ideally the stack trace will
      // give us more information about which dialog
      Analytics.error({
        error: new Error('No overlay title found')
      });
    }
  }

  onLoad() {
    this.handleSegment();
  }

  componentDidUpdate() {
    if (!this.props.isLoading) {
      this.onLoad();
    }
  }

  componentDidMount() {
    if (!this.props.isLoading) {
      this.onLoad();
    }
  }

  render() {
    const {
      styles: s,

      children,
      isLoading,
      equalPadding,
      // NOTE: the `alwaysRenderChildren` prop is a hack, to make sure whatever the
      // children need to do can be done while the overlay is showing the loading state
      // I discourage using it unless there are good reasons for it, in 99.9% this
      // can be avoided! ;)
      alwaysRenderChildren,
      title,
      ...props
    } = this.props;
    return (
      <Portal target='dialogOverlayStack'>
        <div {...s('container')} {...props}>
          <div {...s('content', { contentLoading: isLoading, equalPadding })}>
            {alwaysRenderChildren ? (
              <Fragment>
                {isLoading && <Spinner />}
                <div
                  {...s('alwaysRenderChildren', {
                    alwaysRenderChildrenLoading: isLoading
                  })}
                >
                  {children}
                </div>
              </Fragment>
            ) : isLoading ? (
              <Spinner />
            ) : (
              children
            )}
          </div>
        </div>
      </Portal>
    );
  }
}

export default Overlay;
