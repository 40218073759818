import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';

import { useWhichWordFactory } from 'hooks/use-which-word';
import { useRegion } from 'hooks/use-region';
import { ReportColumn } from 'features/custom-reporting/modules/module-config-types';
import { propertyFields } from 'features/custom-reporting/modules/properties/property-fields';
import { contactFields } from 'features/custom-reporting/modules/contacts/contact-fields';
import { appointmentFields } from 'features/custom-reporting/modules/appointments/appointment-fields';
import { userFields } from 'features/custom-reporting/modules/users/user-fields';
import { feedbackFields } from 'features/custom-reporting/modules/feedback/feedback-fields';
import { listingFields } from 'features/custom-reporting/modules/listings/listing-fields';
import {
  appraisalFields,
  propertyAppraisalFields,
  appointmentAppraisalFields
} from 'features/custom-reporting/modules/appraisals/appraisal-fields';
import { contractFields } from 'features/custom-reporting/modules/contracts/contract-fields';
import { keyActivityFields } from 'features/custom-reporting/modules/key-activity/key-activity-fields';
import { keyLocationFields } from 'features/custom-reporting/modules/key-locations/key-location-fields';
import { commissionBySaleFields } from 'features/custom-reporting/modules/commission-by-sale/commission-by-sale-fields';
import { commissionByAgentFields } from 'features/custom-reporting/modules/commission-by-agent/commission-by-agent-fields';
import { contractConditionFields } from 'features/custom-reporting/modules/contract-conditions/contract-condition-fields';
import { leadsFields } from 'features/custom-reporting/modules/leads/leads-fields';
import { FilterModel } from 'features/custom-reporting/components/data-grid';
import { CustomFieldDefinitions } from 'features/custom-reporting/utils/custom-field-utils';
import { projectFields } from 'features/custom-reporting/modules/projects/project-fields';
import { projectStageFields } from 'features/custom-reporting/modules/project-stages/project-stage-fields';
import { oabFields } from 'features/custom-reporting/modules/oab/oab-fields';
import { tenancyApplicationFields } from './tenancy-applications/tenancy-applications-fields';
import dayjs from 'dayjs';

const entities = {
  user: userFields,
  property: propertyFields,
  listing: listingFields,
  contact: contactFields,
  appointment: appointmentFields,
  feedback: feedbackFields,
  appraisal: appraisalFields,
  contract: contractFields,
  keyLocation: keyLocationFields,
  keyActivity: keyActivityFields,
  commissionBySale: commissionBySaleFields,
  commissionByAgent: commissionByAgentFields,
  contractConditions: contractConditionFields,
  leads: leadsFields,
  project: projectFields,
  projectStage: projectStageFields,
  oab: oabFields,
  appointmentAppraisal: appointmentAppraisalFields,
  propertyAppraisal: propertyAppraisalFields,
  tenancyApplication: tenancyApplicationFields
};

interface MakeEntityArgs {
  entity: keyof typeof entities;
  subresource?: string;
  prefix: string;
  whichWord?: ReturnType<typeof useWhichWordFactory>;
  region?: ReturnType<typeof useRegion>;
  customFields?: CustomFieldDefinitions;
}

interface DateRange {
  startDate: string;
  endDate: string;
}

interface DurationComparatorProps {
  startRange: DateRange;
  endRange: DateRange;
}

export const makeEntity = ({
  entity,
  subresource,
  prefix,
  whichWord,
  region,
  customFields
}: MakeEntityArgs) => {
  const fieldsOrFunction = entities[entity];
  const fields =
    typeof fieldsOrFunction === 'function'
      ? fieldsOrFunction({
          whichWord,
          region,
          customFields,
          subresource
        })
      : (fieldsOrFunction as ReportColumn[]);

  return fields.map((fieldConfig) => {
    const individualColId =
      fieldConfig.colId || fieldConfig.field?.replace(/\.|\[|\]/g, '-');

    const colId = [prefix, individualColId].filter(Boolean).join('-');
    const field = [prefix, fieldConfig.field].filter(Boolean).join('.');

    return {
      ...fieldConfig,
      ...(subresource ? { subresource } : {}),
      ...(field ? { field } : {}),
      ...(colId ? { colId } : {})
    };
  });
};

export function findColumn(
  columns: ReportColumn[],
  findFunction: (column: ReportColumn) => boolean
): ReportColumn | undefined {
  return columns.reduce((foundColumn: ReportColumn | undefined, column) => {
    if (findFunction(column)) {
      return column;
    }
    if (column.children) {
      const foundInChildren = findColumn(column.children, findFunction);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
    return foundColumn;
  }, undefined);
}

export function findColumnById(
  columns: ReportColumn[],
  columnId: string
): ReportColumn | undefined {
  return findColumn(columns, (column) => column.colId === columnId);
}

export function filterColumns(
  columns: ReportColumn[],
  filterFunction: (column: ReportColumn) => boolean
): ReportColumn[] {
  return columns.reduce((foundColumns: ReportColumn[], column) => {
    if (filterFunction(column)) {
      foundColumns.push(column);
    }
    if (column.children) {
      const foundInChildren = filterColumns(column.children, filterFunction);
      if (foundInChildren) {
        foundColumns = [...foundColumns, ...foundInChildren];
      }
    }
    return foundColumns;
  }, []);
}

export function flattenColumns(columns: ReportColumn[]): ReportColumn[] {
  return filterColumns(columns, () => true);
}

export function mapColumns(
  columns: ReportColumn[],
  mapFunction: (column: ReportColumn) => ReportColumn
): ReportColumn[] {
  return columns.map((column) => {
    const mappedColumn = mapFunction(column);
    if (column.children) {
      mappedColumn.children = mapColumns(column.children, mapFunction);
    }
    return mappedColumn;
  });
}

export const timeDifference = ({ startDate, endDate }: DateRange) => {
  const currentCtime = dayjs(startDate);
  const currentDoneTime = dayjs(endDate);

  return currentDoneTime.diff(currentCtime, 'minute');
};

export const durationComaparator = ({
  startRange,
  endRange
}: DurationComparatorProps) => {
  const startRangeDiff = timeDifference(startRange);
  const endRangeDiff = timeDifference(endRange);

  if (isNaN(startRangeDiff) && isNaN(endRangeDiff)) return 0;
  if (isNaN(startRangeDiff)) return -1;
  if (isNaN(endRangeDiff)) return 1;

  return startRangeDiff - endRangeDiff;
};

export function getColumnsWeNeedDataFor({
  columnState,
  filterState,
  allColumnsMapped
}: {
  columnState: ColumnState[];
  filterState: FilterModel;
  allColumnsMapped: ReportColumn[];
}): ReportColumn[] {
  const hasColumnState = columnState?.length > 0;
  if (!hasColumnState && !filterState) {
    return filterColumns(allColumnsMapped, (column) => column.hide !== true);
  }

  const filteredColumns = filterState
    ? Object.keys(filterState).map(
        (columnId) => findColumnById(allColumnsMapped, columnId) as ReportColumn
      )
    : [];

  if (hasColumnState) {
    const visibleColumns = columnState
      ?.filter((column) => !column.colId || column?.hide !== true)
      .map(
        (selectedColumn) =>
          findColumnById(
            allColumnsMapped,
            selectedColumn.colId as string
          ) as ReportColumn
      );
    return [...visibleColumns, ...filteredColumns];
  }

  const defaultVisibleColumns = filterColumns(
    allColumnsMapped,
    (column) => column.hide !== true
  );
  return [...defaultVisibleColumns, ...filteredColumns];
}
