import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS, COLORS } from 'theme';

@styled(
  StyleSheet({
    inputContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      borderRadius: '0.2rem',
      border: `2px solid ${COLORS.STATES.IDLE}`,
      backgroundColor: COLORS.PRIMARY.WHITE,

      '&:hover': {
        borderColor: COLORS.STATES.HOVER
      },

      '& label': {
        border: 'none',
        width: 'auto',
        background: 'none',
        padding: '0'
      },

      '& label input': {
        height: '3rem',
        border: '2px solid transparent',

        '&:focus': {
          border: `2px solid ${COLORS.STATES.ACTIVE}`
        }
      }
    },
    labelLeft: {
      display: 'flex',

      '& > label': {
        marginRight: '1rem'
      }
    },
    label: {
      ...TEXTS.CONTENT.FORM_FIELD_LABEL_1
    },
    divider: {
      width: '0',
      height: '2.2rem',
      border: `1px solid ${COLORS.STATES.IDLE}`,
      transform: 'rotate(15deg)',
      alignSelf: 'center',
      margin: '0 0.5rem'
    },
    error: {
      border: `2px solid ${COLORS.PRIMARY.RED}`,
      '&:hover': {
        borderColor: COLORS.PRIMARY.RED
      }
    }
  })
)
@autobind
class SplitValueInput extends PureComponent {
  static propTypes = {
    LeftInput: types.func.isRequired,
    RightInput: types.func.isRequired
  };

  static defaultProps = {
    inputProps: {}
  };

  handleLeftChange(e) {
    const { value, onChange } = this.props;
    onChange &&
      onChange({
        ...e,
        target: {
          ...e.target,
          value: { ...value, left: e.target.value }
        }
      });
  }

  handleRightChange(e) {
    const { value, onChange } = this.props;
    onChange &&
      onChange({
        ...e,
        target: {
          ...e.target,
          value: { ...value, right: e.target.value }
        }
      });
  }

  handleLeftBlur(e) {
    const { value, onBlur } = this.props;
    onBlur &&
      onBlur({
        ...e,
        target: {
          ...e.target,
          value: { ...value, left: e.target.value }
        }
      });
  }

  handleRightBlur(e) {
    const { value, onBlur } = this.props;
    onBlur &&
      onBlur({
        ...e,
        target: {
          ...e.target,
          value: { ...value, right: e.target.value }
        }
      });
  }

  render() {
    const {
      styles: s,
      LeftInput,
      RightInput,
      inputProps: { leftInputProps, rightInputProps },
      value,
      meta
    } = this.props;

    return (
      <div {...s('inputContainer', { error: meta.error && meta.touched })}>
        <LeftInput
          {...leftInputProps}
          value={value.left || ''}
          onChange={this.handleLeftChange}
          onBlur={this.handleLeftBlur}
        />
        <span {...s('divider')} />
        <RightInput
          {...rightInputProps}
          value={value.right || ''}
          onChange={this.handleRightChange}
          onBlur={this.handleRightBlur}
        />
      </div>
    );
  }
}

export default SplitValueInput;
