import _ from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

export type MailMergeService =
  | 'listing'
  | 'property'
  | 'project'
  | 'project_stage'
  | 'contact';

export type MailMergeMode = MailMergeService | 'quick';

export type MergeObject = {
  contact_id?: string;
  property_id?: string;
  listing_id?: string;
  project_id?: string;
  contract_id?: string;
  project_stage_id?: string;
  mail_merge_template_id?: string;
  attachments?: any[];
  cc_contact_ids?: string[];
  bcc_contact_ids?: string[];
  fallback_recipient_name?: string;
  ignore_wash?: boolean;
  calendar_event_id?: string;
  chain_link_id?: string;
  reminder_id?: string;
  send_from_user_id: string;
  custom?: {
    body: string;
    subject: string;
  };
};

export type MergedStringSet = {
  body: string;
  id: string;
  subject: string;
};

export type MergeType = 'email' | 'sms' | 'letter';

interface BaseSendData {
  merge_type: MergeType;
}

interface CreateAndSendData extends BaseSendData {
  merge_objects: MergeObject[];
}

interface CheckMergeObjectData extends BaseSendData {
  merge_object: MergeObject;
}

interface CreateAndSendArgs {
  data: CreateAndSendData;
  async?: boolean;
}

const TYPE = 'mailMerge';

const actionCreators = {
  // TODO: When/If we start using this, add tracking similar to r2.u.api (search "MailMerge analytics tracking")
  send: {
    request: ({ id, async = true }) =>
      api.post('MailMerge::send', {
        id,
        async
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  // TODO: When/If we start using this, add tracking similar to r2.u.api (search "MailMerge analytics tracking")
  createAndSend: {
    request: ({ data, async = true }: CreateAndSendArgs) =>
      api.post('MailMerge::createAndSend', {
        data,
        async
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  // TODO: When/If we start using this, add tracking similar to r2.u.api (search "MailMerge analytics tracking")
  resendBulk: {
    request: ({ id, data, async = true }) =>
      api.post('MailMerge::resendBulk', {
        id,
        data,
        async
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  // TODO: When/If we start using this, add tracking similar to r2.u.api (search "MailMerge analytics tracking")
  resendSingle: {
    request: ({ eventSendId, data, async = true }) =>
      api.post('MailMerge::resendSingle', {
        event_send_id: eventSendId,
        data,
        async,
        archive_original: true
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getMergeObjectsAndMeta: {
    request: (args) =>
      api.post('MailMerge::getMergeObjectsAndMeta', {
        ...args
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getMergedStringSet: {
    request: (args: CheckMergeObjectData) =>
      api
        .post('MailMerge::getMergedStringSet', {
          ...args
        })
        .then(({ data }) => data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getSentContent: {
    request: ({ id }) =>
      api.post('MailMerge::getSentContent', {
        id
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  searchMailMergeMessages: {
    request: (args) =>
      api.post('MailMerge::search', {
        ...args
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  validateMergeObject: {
    request: (args: CheckMergeObjectData) =>
      api
        .post('MailMerge::validateMergeObject', {
          ...args
        })
        .then(({ data }) => data.result),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const mailMergeModel = new Generator<
  Record<string, any>,
  typeof actionCreators
>(TYPE).createModel({
  actionCreators
});

export default mailMergeModel;
