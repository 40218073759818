import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';
import { APP_MENU } from 'theme/legacy/components';
import { OPTION_TYPES } from 'view/components/navigation/app-search/utils';
import Option from './option';
import { getHazardly } from 'shared/utils/prefix-hell';

@styled(APP_MENU.RECORDS)
class ProjectStageOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles, option, isActive } = this.props;
    const { data: projectStage, value, label } = option;
    const projectName = getHazardly(projectStage, 'project._name', '');

    return (
      <Option
        isActive={isActive}
        label={label}
        IconBox={[
          <Icon
            key='project-icon'
            type={ICONS.APP_SEARCH[OPTION_TYPES.PROJECT]}
          />
        ]}
        OptionMeta={[
          <span key='label' {...styles('name', 'ellipsis')}>
            {label}
          </span>,
          <span key='project-name' {...styles('meta', 'ellipsis')}>
            Project: {projectName}
          </span>
        ]}
        OptionExtraMeta={[
          <span key='value' {...styles('meta', 'extra', 'light')}>
            #{value}
          </span>
        ]}
      />
    );
  }
}

export default ProjectStageOption;
