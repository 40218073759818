import React from 'react';

import { ProjectStageStub } from 'data/models/entities/project-stages';

import ProjectStageTooltip from 'view/components/popout/project-stage';

import {
  BasePopoutCellProps,
  DefaultRecordPopout,
  PopoutCell
} from './popout-cell';

interface ProjectStageCellProps extends BasePopoutCellProps {
  value?: Partial<ProjectStageStub> | null;
}

export function ProjectStageCell(props: ProjectStageCellProps) {
  const { value, ...rest } = props;
  const projectStageId = value?.id;

  // Because the Project Stage Tooltip (Popout) is not typed, we're forcing it's type
  // When it is updated, we can remove this
  // https://app.shortcut.com/rexlabs/story/60592/popouts-migrate-project-stage-popout-to-react
  const RecordPopout: DefaultRecordPopout = ProjectStageTooltip as any;

  return (
    <PopoutCell
      RecordPopout={RecordPopout}
      recordId={projectStageId}
      projectId={value?.project?.id}
      {...rest}
    >
      {value?.full_name}
    </PopoutCell>
  );
}
