import React from 'react';
import { LinkArrowButton } from 'components/button/link-arrow-button';
import ActionMenu, { ActionMenuProps } from 'view/components/action-menu/core';

type TextActionMenuProps = Pick<ActionMenuProps, 'placement' | 'items'> &
  React.ComponentProps<typeof LinkArrowButton>;

export function LinkActionMenu({
  items,
  placement = 'bottom-start',
  ...props
}: TextActionMenuProps) {
  return (
    <ActionMenu
      Button={({ isOpen }) => <LinkArrowButton {...props} active={isOpen} />}
      placement={placement}
      items={items}
      distance='14px'
      offset='0px'
    />
  );
}
