import React, { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';

import { ICONS } from 'shared/components/icon';

import { IconValueStatusButton } from 'components/button/icon-value-status-button';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { useSendMailMerge } from 'hooks/use-send-mail-merge';

import { getMailMergeData } from 'features/leads/utils/get-mail-merge-data';
import { getActivityStatus } from 'features/leads/utils/get-activity-status';

type ButtonProps = Pick<
  ComponentProps<typeof IconValueStatusButton>,
  'status' | 'helpText'
>;

interface SendEmailActionButtonProps extends ButtonProps {
  data: LeadsResponse;
  callback: () => any;
  activitiesList?: Activity[];
}

export function SendEmailActionButton({
  data,
  activitiesList,
  callback
}: SendEmailActionButtonProps) {
  const emailStatus = getActivityStatus('sent_email', activitiesList);
  const mailMergeData = useMemo(() => {
    return getMailMergeData(data);
  }, [data]);

  const onSendEmailClick = useSendMailMerge({
    data: mailMergeData,
    medium: 'email',
    callback
  });

  return (
    <IconValueStatusButton
      iconType={ICONS.EMAIL}
      onClick={onSendEmailClick}
      status={emailStatus && 'Sent'}
      helpText={
        emailStatus &&
        `Email sent ${dayjs(emailStatus.system_ctime * 1000).fromNow()} by ${
          emailStatus.system_created_user.name
        }`
      }
    >
      Send Email
    </IconValueStatusButton>
  );
}
