import { useMemo } from 'react';
import { find } from 'lodash';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { ICONS } from 'shared/components/icon';
import { RegionCode, useRegion } from 'hooks/use-region';
import { usePermissions } from 'hooks/use-permissions';

import { ModuleName } from './module-config-types';
import { useWhichWordFactory } from 'hooks/use-which-word';

const CalendarIcon = ICONS.CALENDAR_12 as unknown;
const ContactsIcon = ICONS.CONTACTS_12 as unknown;
const ListingsIcon = ICONS.LISTINGS_12 as unknown;
const ContractsIconAU = ICONS.CIRCLE_DOLLAR_12 as unknown;
const ContractsIconEU = ICONS.CIRCLE_POUND_12 as unknown;
const KeyIcon = ICONS.KEY_12 as unknown;
const CommissionIconAU = ICONS.CURRENCY_DOLLAR_12 as unknown;
const CommissionIconEU = ICONS.CURRENCY_POUND_12 as unknown;
const PropertiesIcon = ICONS.PROPERTIES_12 as unknown;
const LeadsIcon = ICONS.LEADS_12 as unknown;
const ProjectsIcon = ICONS.PROJECTS_12 as unknown;
const InspectionIcon = ICONS.INSPECTION_12 as unknown;
const OabIcon = ICONS.OAB_12 as unknown;

export interface GroupDetails {
  labelPlural: string;
  labelSingular: string;
  modules: string[];
  Icon: unknown;
  withRegions?: RegionCode[];
  groupId: string;
}

export type GroupConfig = Record<string, GroupDetails>;

export function useGroupConfig() {
  const { isEU } = useRegion();
  const ww = useWhichWordFactory();
  const { check, hasAddon } = usePermissions();
  const hasViewAllPermissions = check('custom_reports.view_all');
  const hasFinancePermissions = check('custom_reports.view_financial');
  const hasProjectsAddon = hasAddon('projects');

  const groupConfig = useMemo<GroupConfig>(() => {
    return {
      appointments: {
        labelPlural: 'Appointments',
        labelSingular: 'Appointment',
        modules: ['appointments'],
        Icon: CalendarIcon,
        groupId: 'appointments'
      },
      appraisals: {
        labelPlural: ww('valuations'),
        labelSingular: ww('valuation'),
        modules: [ww('valuations').toLowerCase()],
        Icon: CalendarIcon,
        groupId: 'appraisals'
      },
      ...(!isEU && hasFinancePermissions
        ? {
            commissions: {
              // special case, never pluralise commission in the UI
              labelPlural: 'Commission',
              labelSingular: 'Commission',
              modules: hasViewAllPermissions
                ? ['commission_by_agent', 'commission']
                : ['commission_by_agent'],
              Icon: isEU ? CommissionIconEU : CommissionIconAU,
              groupId: 'commissions'
            }
          }
        : {}),
      contacts: {
        labelPlural: 'Contacts',
        labelSingular: 'Contact',
        modules: ['contacts'],
        Icon: ContactsIcon,
        groupId: 'contacts'
      },
      ...(!isEU
        ? {
            contracts: {
              labelPlural: 'Contracts',
              labelSingular: 'Contract',
              modules: hasFeatureFlags('custom_reporting_contract_conditions')
                ? ['contracts', 'contract_conditions']
                : ['contracts'],
              Icon: ContractsIconAU,
              groupId: 'contracts'
            }
          }
        : {}),

      ...(isEU
        ? {
            contracts: {
              labelPlural: 'Offers',
              labelSingular: 'Offer',
              modules: hasFeatureFlags('custom_reporting_contract_conditions')
                ? ['offers', 'offer_conditions']
                : ['offers'],
              Icon: ContractsIconEU,
              groupId: 'contracts'
            }
          }
        : {}),
      ...(hasViewAllPermissions
        ? {
            keys: {
              labelPlural: 'Keys',
              labelSingular: 'Key',
              modules: ['key_locations', 'key_activity'],
              Icon: KeyIcon,
              groupId: 'keys'
            }
          }
        : {}),
      leads: {
        labelPlural: 'Leads',
        labelSingular: 'Lead',
        modules: ['leads'],
        Icon: LeadsIcon,
        groupId: 'leads'
      },
      listings: {
        labelPlural: 'Listings',
        labelSingular: 'Listing',
        modules: ['listings'],
        Icon: ListingsIcon,
        groupId: 'listings'
      },
      ...(hasProjectsAddon
        ? {
            projects: {
              labelPlural: 'Projects',
              labelSingular: 'Project',
              modules: ['project_stages', 'projects'],
              Icon: ProjectsIcon,
              groupId: 'projects'
            }
          }
        : {}),
      properties: {
        labelPlural: 'Properties',
        labelSingular: 'Property',
        modules: ['properties'],
        Icon: PropertiesIcon,
        groupId: 'properties'
      },
      oabs: {
        labelPlural: 'OABs',
        labelSingular: 'OAB',
        modules: ['oabs'],
        Icon: OabIcon,
        groupId: 'oabs'
      },
      tenancy_applications: {
        labelPlural: 'Tenancy Applications',
        labelSingular: 'Tenancy Application',
        modules: ['tenancy_applications'],
        Icon: InspectionIcon,
        groupId: 'tenancy_applications'
      }
    };
  }, [
    isEU,
    hasViewAllPermissions,
    hasFinancePermissions,
    ww,
    hasProjectsAddon
  ]);

  return groupConfig;
}

export function useGroupForModuleName(moduleName: ModuleName) {
  const groupConfig = useGroupConfig();
  return find(groupConfig, (groupConfigItem) =>
    groupConfigItem.modules.includes(moduleName)
  );
}
