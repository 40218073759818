import React from 'react';
import Box from '@rexlabs/box';
import { Link } from '@rexlabs/whereabouts';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { RecordDetailsWidget } from 'components/_experiment/record-details-screen/widget';
import { HeadingWithButtons } from 'components/_experiment/record-widget/heading';
import { Info } from 'components/_experiment/record-widget/info';

import { AddContactPopout } from './add-contact-popout';
import { EditContactPopout } from './edit-contact-popout';

const ContactSvg: any = ICONS.APP_SEARCH.CONTACT;

const defaultStyles = StyleSheet({
  contact: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    font: 'inherit',
    color: 'inherit',
    textDecoration: 'none',

    '& [data-edit-button]': {
      opacity: 0,
      transition: 'opacity .2s'
    },

    '&:hover [data-edit-button], [data-force-show=true]': {
      opacity: 1
    }
  },

  avatar: {
    width: '56px',
    height: '56px',
    background: '#e6e6e2',
    marginRight: '10px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 2
    },

    '& svg': {
      color: '#B4B1A9',
      height: '24px',
      width: 'auto',
      position: 'relative',
      zIndex: 1
    }
  },

  name: {
    color: '#2aa2e3',
    fontSize: '14px',
    fontWeight: 600,
    height: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  },

  type: {
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '2px'
  }
});

interface RelatedContactsWidgetProps {
  contacts: any;
  onAdd?: any;
  onEdit?: any;
  onRemove?: any;
}

export function RelatedContactsWidget({
  contacts,
  onAdd,
  onEdit,
  onRemove
}: RelatedContactsWidgetProps) {
  const s = useStyles(defaultStyles);

  return (
    <RecordDetailsWidget>
      <HeadingWithButtons Buttons={() => <AddContactPopout onAdd={onAdd} />}>
        Related Contacts
      </HeadingWithButtons>

      {contacts?.length ? (
        <Box flexDirection='column' sy='6px'>
          {contacts.map((rel) => (
            <Link
              key={rel.id}
              {...s('contact')}
              to={{
                config: {
                  path: '/contacts/',
                  hashQuery: { id: rel.contact.id }
                }
              }}
            >
              <Box {...s('avatar')}>
                <ContactSvg />
                {rel.contact?.contact_image?.thumbs?.['80x80']?.url && (
                  <img src={rel.contact.contact_image.thumbs['80x80'].url} />
                )}
              </Box>
              <Box flexDirection='column' flex={1}>
                <Box {...s('name')}>{rel?.contact?.name}</Box>
                <Box {...s('type')}>
                  {rel?.relationship_type?.text === 'Other'
                    ? rel?.custom_reln_desc
                    : rel?.relationship_type?.text}
                </Box>
              </Box>
              <EditContactPopout
                data={rel}
                onEdit={onEdit}
                onRemove={onRemove}
              />
            </Link>
          ))}
        </Box>
      ) : (
        <Info>No contacts found</Info>
      )}
    </RecordDetailsWidget>
  );
}
