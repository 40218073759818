import React from 'react';

import { TemplateProps } from '../';

function TemplateListMarker({
  row: {
    data: { library }
  }
}: TemplateProps) {
  if (library?.library_name) {
    if (!library?.isHidden) {
      if (library?.id === 0 || library?.id === '1') {
        return <i className='icon-system size-25-25' />;
      } else {
        return <i className='icon-template-library size-25-25' />;
      }
    } else {
      return <i className='icon-invisible size-25-25' />;
    }
  } else {
    return null;
  }
}

export default TemplateListMarker;
