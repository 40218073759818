import React from 'react';
import { Helmet } from 'react-helmet';
import { useWhereabouts } from '@rexlabs/whereabouts';

import { constructRouteTitle, routeMapping } from 'utils/routing/route-mapping';

export default function HelmetScreen({ title, children }) {
  const { path, hash } = useWhereabouts();
  return (
    <>
      <Helmet>
        <title>
          {title} - {constructRouteTitle({ path, hash, mapping: routeMapping })}
        </title>
      </Helmet>
      {children}
    </>
  );
}
