import React, { Component } from 'react';
import EntityOption from 'view/components/input/select/options/entity';
import { ICONS } from 'shared/components/icon';

class ExternalAddressOption extends Component {
  pluckStatus() {
    return 'from Mapping Service';
  }

  render() {
    return (
      <EntityOption
        {...this.props}
        Icon={ICONS.SELECT.VALUE.POSTCODE}
        pluckStatus={this.pluckStatus}
      />
    );
  }
}

export default ExternalAddressOption;
