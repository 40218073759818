import { useWhereabouts } from '@rexlabs/whereabouts';

export function useLens(lenses) {
  const whereabouts = useWhereabouts();
  const lensId = whereabouts.hashQuery?.lens;

  return lensId && lenses?.[lensId]
    ? {
        ...lenses[lensId],
        criteria: lenses[lensId].criteria?.map?.((c) => ({
          ...c,
          isLens: true
        })),
        orderBy: lenses[lensId].orderBy?.field,
        orderDir: lenses[lensId].orderBy?.direction
      }
    : { criteria: [], orderBy: '', orderDir: '' };
}
