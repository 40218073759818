import _ from 'lodash';

/**
 * Creates a canvas element and uses it to measure the width of text provided. It also takes into account the font size and font family supplied.
 *
 * @param {string} testText The text that you are wanting to determine the width of e.g. Street Address
 * @param {object} options Either a current styles that you would like to copy the font of, or fontSize and fontFamily, else uses defaults defined below.
 * @returns {number} The width of the testText as a number in pixels
 */
export function getWidthOfText(testText, options) {
  let fontSize = _.get(options, 'fontSize') || '14px';
  let fontFamily =
    _.get(options, 'fontFamily') ||
    'proxima-nova, helvetica, arial, sans-serif';

  const componentStyles = _.get(options, 'componentStyles');

  let testCanvas;

  // Replace the passed style options with the rendered elements computed styles
  // Only if there is a valid renderedElement
  if (componentStyles) {
    fontSize = _.get(componentStyles, 'fontSize') || '14px';
    fontFamily =
      _.get(componentStyles, 'fontFamily') ||
      'proxima-nova, helvetica, arial, sans-serif';
  }

  if (testCanvas === undefined) {
    testCanvas = document.createElement('canvas');
    testCanvas.canvasContext = testCanvas.getContext('2d');
  }

  testCanvas.canvasContext.font = fontSize + ' ' + fontFamily;

  return testCanvas.canvasContext.measureText(testText).width;
}

/**
 * Checks whether the provided text overflows the provided container DOM element. The DOM
 * element must have a max-width as this is what is used to determine any overflow.
 *
 * @param {string} testText The text that you are wanting to determine the overflow of e.g. Street Address
 * @param {object} componentStyles The styles that we are going to use to compare the max-width against the texts actual rendered width.
 * @param {boolean} forceLowerCase if the text should be forced to lowercase
 * @returns {boolean} True if the texts width is greater than the elements max-width
 */

export function doesTextOverflowContainer(
  testText,
  componentStyles,
  forceLowerCase
) {
  if (!componentStyles) {
    throw new Error(
      'Please pass in some componentStyles to compare against text width.'
    );
  }
  if (forceLowerCase) {
    testText = testText.toLowerCase();
  }

  return (
    getWidthOfText(testText, {
      componentStyles: componentStyles
    }) >= +_.first(_.get(componentStyles, 'maxWidth').split('px'))
  );
}
