import { SideNoteCell } from 'components/record-list-screen/cells/side-note-cell';
import { IconCell } from 'components/record-list-screen/cells/icon-cell';
import {
  ColumnConfig,
  ListActionMenuProps
} from 'components/record-list-screen/types';
import Icon, { ICONS } from 'shared/components/icon';
import React from 'react';

export const useCommonColumns = <ModelType,>() => ({
  name: ({
    note,
    dropdownItems
  }: {
    note: (data: ModelType) => string;
    dropdownItems: (data: ModelType) => ListActionMenuProps[];
  }) => ({
    id: 'name',
    label: 'Name',
    width: '65%',
    Cell: SideNoteCell,
    cellProps: {
      note,
      blue: true,
      items: dropdownItems
    }
  }),
  libraryIcon: () => ({
    id: 'templates',
    label: '',
    Cell: IconCell as ColumnConfig['Cell'],
    width: '25px',
    emptyValue: '',
    cellProps: {
      isVisible: (data) => !!data.library,
      style: { padding: '8px 0px 8px 15px' },
      Icon: (
        <Icon
          type={ICONS.TEMPLATE}
          hasControlledColor={false}
          style={{ display: 'flex' }}
        />
      )
    }
  }),
  libraryName: () => ({
    id: 'library',
    label: 'Library',
    selector: (data) => data.library?.library_name
  })
});
