/* eslint-disable max-lines */
/*
|-------------------------------------------------------------------------------
| App "theme"
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/

import utils from './utils';

import { COLORS } from './legacy/colors';
import { PADDINGS } from './legacy/spacing';
import { FONT } from './legacy/font';
import {
  UTILS,
  BORDER_RADIUS,
  ZINDEX,
  BREAKPOINTS,
  BORDERS,
  CONSTANTS,
  HEIGHTS,
  BASE64_IMAGES,
  STATUS_CIRCLE
} from './legacy/misc';
import { NEW_TEXTS, TEXTS } from './legacy/text';
import { TRANSITIONS, TIMINGS, EASINGS, KEYFRAMES } from './legacy/animation';
import { SHADOWS } from './legacy/shadows';

import { TOKENS } from './tokens';

import { initTheme } from './init';

export {
  COLORS,
  STATUS_CIRCLE,
  PADDINGS,
  FONT,
  UTILS,
  BORDER_RADIUS,
  ZINDEX,
  BREAKPOINTS,
  BORDERS,
  NEW_TEXTS,
  TEXTS,
  TRANSITIONS,
  TIMINGS,
  EASINGS,
  KEYFRAMES,
  CONSTANTS,
  HEIGHTS,
  BASE64_IMAGES,
  SHADOWS,
  TOKENS,
  initTheme
};

export const hexToHSL = utils.hexToHSL;
