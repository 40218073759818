import {
  ListingEvent,
  NewsletterJson,
  OfficeLocation
} from 'features/newsletter-builder/types';
import { api } from 'shared/utils/api-client/index';
import apiClient from 'data/classic-bridges/api-client';
import { TokenStatus } from '@mailupinc/bee-plugin/dist/types/bee';
import Bee from '@mailupinc/bee-plugin';
import { AdminNewsletterTemplate } from 'features/newsletter-builder/data/admin-newsletter-templates';
import { updateNewsletterBranding } from 'features/newsletter-builder/utils/json';

export async function enhanceEventsWithPublishedListings(
  events: ListingEvent[]
) {
  const { data: publishedListings } = await api.post(
    'PublishedListings::search',
    {
      extra_options: {
        extra_fields: ['advert_stocklist', 'images']
      },
      criteria: [
        {
          name: 'id',
          type: 'in',
          value: events.map((row) => row.listing.id)
        }
      ]
    }
  );

  return events.map((row) => {
    const listing = publishedListings.result.rows.find(
      (listing) => Number(listing.id) === Number(row.listing.id)
    );
    return {
      ...row,
      listing
    };
  });
}

export async function getDefaultNewsletter() {
  const {
    data: { result }
  } = await apiClient.post<{ result: { rows: AdminNewsletterTemplate[] } }>(
    'AdminNewsletterTemplates::search',
    {
      criteria: [
        {
          name: 'is_default',
          type: '=',
          value: true
        }
      ],
      limit: 1
    }
  );

  return result.rows[0];
}

export async function getBeeFreeContentForNewsletter(newsletterId: string) {
  const {
    data: { result }
  } = await apiClient.post<{ result: any[] }>(
    'NewsletterCampaignBlocks::getBlocksForNewsletter',
    {
      newsletter_id: newsletterId
    }
  );

  const existingBlock = result.find((b) => b.block_type_id === 'beefree');

  return {
    blockId: existingBlock?.id,
    json: existingBlock?.settings
  };
}

export async function initialiseBee(BeePlugin: any): Promise<Bee> {
  const {
    data: {
      result: { access_token }
    }
  } = await api.post<{ result: { access_token: string } }>(
    'NewsletterCampaigns::getBeefreeToken'
  );

  return new BeePlugin({
    access_token,
    status: TokenStatus.OK,
    v2: true
  });
}

export async function initialiseBeefreeContentForNewsletter(
  newsletterId: string | number,
  templateJson: NewsletterJson,
  officeLocations: OfficeLocation[],
  defaultLocationId: string
) {
  const {
    data: { result }
  } = await apiClient.post<{ result: { id: string | number } }>(
    'NewsletterCampaignBlocks::create',
    {
      data: {
        newsletter_id: Number(newsletterId),
        settings: updateNewsletterBranding(
          templateJson,
          officeLocations,
          defaultLocationId
        ),
        block_type_id: 'beefree'
      }
    }
  );
  return {
    blockId: String(result.id),
    json: templateJson
  };
}
