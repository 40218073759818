import { getCustomFieldColumns } from 'features/custom-reporting/utils/custom-field-utils';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import _ from 'lodash';

const getTenantsFields = () => {
  return [0, 1, 2, 3, 4]
    .map((num) => [
      {
        field: `tenants.${num}.contact`,
        headerName: `Tenant ${num + 1}`,
        type: 'contactRecordColumn'
      },
      ...[0, 1].map((guarantorNum) => {
        return {
          field: `tenants.${num}.guarantors.${guarantorNum}`,
          headerName: `Tenant ${num + 1} Guarantor ${guarantorNum + 1}`,
          type: 'contactRecordColumn',
          keyCreator: ({ value }) => {
            return value?.contact_name;
          },
          valueGetter: ({ data, colDef }) => {
            return _.get(data, colDef.field)?.contact;
          }
        };
      }),
      {
        field: `tenants.${num}.net_income`,
        headerName: `Tenant ${num + 1} Net Income`,
        type: 'currencyColumn'
      },
      {
        field: `tenants.${num}.net_income_period`,
        headerName: `Tenant ${num + 1} Net Period`
      }
    ])
    .flat(1);
};

export const tenancyApplicationFields = ({
  subresource,
  customFields,
  whichWord: ww,
  region
}) => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'listing'
  });

  return [
    {
      field: 'id',
      headerName: 'ID'
    },
    {
      headerName: 'Agent',
      field: 'agent.name',
      subresource: 'agent',
      type: 'agentValueListColumn'
    },
    ...getTenantsFields(),
    {
      headerName: `Application status`,
      field: 'application_status.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'contract_status' })
      }
    },
    {
      field: 'agreement_length_months',
      headerName: 'Fixed Agreement Length'
    },
    {
      field: 'bond_amount_paid',
      headerName: 'Amount Paid Direct',
      type: 'currencyColumn'
    },
    {
      field: 'bond_amount_required',
      headerName: 'Amount Required',
      type: 'currencyColumn'
    },
    {
      field: 'bond_deposit_registration_number',
      headerName: 'Deposit Registration Number'
    },
    {
      field: 'bond_deposit_type.text',
      headerName: 'Deposit Type'
    },
    {
      field: 'conditions',
      headerName: 'Conditions'
    },

    {
      headerName: 'Date Accepted',
      field: 'date_accepted',
      type: 'dateColumn'
    },
    {
      field: 'date_received',
      headerName: 'Date Received',
      type: 'dateColumn'
    },
    {
      headerName: 'Date Communicated',
      field: 'date_communicated',
      type: 'dateColumn'
    },
    {
      headerName: 'Date Unsuccessful',
      field: 'date_unsuccessful',
      type: 'dateColumn'
    },
    {
      field: 'has_pets',
      headerName: 'Pets',
      type: 'booleanColumn'
    },
    {
      field: 'lease_type.name',
      headerName: 'Lease Type'
    },
    {
      field: 'notes',
      headerName: 'Notes'
    },
    {
      field: 'num_of_dependents',
      headerName: 'Dependents'
    },
    {
      field: 'offer_amount',
      headerName: `${ww('letting')} offer`,
      type: 'currencyColumn'
    },
    {
      field: 'offer_amount_period.text',
      headerName: 'Rent Period',
      type: 'currencyColumn'
    },
    {
      field: 'system_affordability_percentage',
      headerName: 'Affordability %',
      type: 'percentageColumn'
    },
    {
      field: 'holding_deposit_amount',
      headerName: 'Holding Deposit Amount',
      type: 'currencyColumn'
    },
    {
      headerName: 'Start Date',
      field: 'start_date',
      type: 'dateColumn'
    },
    {
      headerName: 'End Date',
      field: 'end_date',
      type: 'dateColumn'
    },

    { headerName: 'Created Date', field: 'system_ctime', type: 'dateColumn' },
    {
      headerName: 'Created by',
      field: 'system_created_user.name',
      type: 'agentValueListColumn'
    },
    {
      headerName: 'Record Owner',
      field: 'system_owner_user.name',
      type: 'agentValueListColumn',
      subresource: 'record_owner'
    },
    {
      headerName: 'Last Modified',
      field: 'system_modtime',
      type: 'dateColumn'
    },
    ...customFieldColumns
  ];
};
