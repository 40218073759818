import { useCallback } from 'react';

import { omit } from 'lodash';

import { useModelActions } from '@rexlabs/model-generator';
import { push, useWhereabouts } from '@rexlabs/whereabouts';

import { useDialog } from 'hooks/use-dialog';
import uiModel from 'data/models/custom/ui';

import { CustomReportItem, customReports } from '../data/custom-reports-model';

export function useDeleteCustomReport(customReport: CustomReportItem) {
  const { refreshLists, trashItem } = useModelActions(customReports);

  const confirmationDialog = useDialog('confirmation');
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const { path, hashQuery } = useWhereabouts();

  const reportId = hashQuery?.reportId;

  const onDeleteConfirm = useCallback(async () => {
    // NOTE: No error handler here as confirmation dialog includes it.
    loadingIndicatorOn({ message: 'Deleting report' });

    await trashItem({ id: customReport?.id })
      .then(() => refreshLists())
      .then(loadingIndicatorOff)
      .then(() => {
        if (reportId) {
          push({
            config: {
              path,
              hashQuery: omit(hashQuery, 'reportId')
            }
          });
        }
      });
  }, [
    customReport?.id,
    hashQuery,
    loadingIndicatorOff,
    loadingIndicatorOn,
    path,
    refreshLists,
    reportId,
    trashItem
  ]);

  return useCallback(() => {
    confirmationDialog.open({
      title: 'Delete Report',
      confirmText: 'Delete',
      onConfirm: onDeleteConfirm,
      message:
        'Are you sure you want to delete this report? Any users that this report is shared with will also lose access'
    });
  }, [confirmationDialog, onDeleteConfirm]);
}
