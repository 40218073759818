/* eslint-disable max-lines */
import _ from 'lodash';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { PureComponent } from 'react';
import { COLORS, PADDINGS } from 'src/theme';
import { ToastPortal, withToast } from '@rexlabs/toast';
import Icon, { ICONS } from 'shared/components/icon';
import { Heading } from 'components/text/heading';
import WorkflowStatus from 'view/components/workflow/status';
import { TextButton, IconButton } from 'view/components/button';
import { connect } from 'react-redux';
import { withRegion } from 'src/hocs/with-region';
import { withDialog } from 'shared/hocs/with-dialog';
import { WorkflowDetailsDialog } from 'view/dialogs/workflow';
import { api } from 'shared/utils/api-client';

import { withModel } from '@rexlabs/model-generator';
import workflowInstancesModel from 'data/models/entities/workflow-instances';
import notificationModel from 'data/models/custom/notification';
import { contractsModel } from 'features/listings/data';
import invoicesModel from 'data/models/entities/invoices';
import substantiationsModel from 'data/models/entities/substantiations';
import dayjs from 'dayjs';
import { withWhereabouts } from '@rexlabs/whereabouts';

const defaultStyles = StyleSheet({
  container: {
    width: '280px',
    backgroundColor: 'white'
  },

  wrapper: {
    boxShadow: '0 0px 10px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: '2px'
  },

  closeButton: {
    border: '0 none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  },

  closeIcon: {
    color: 'red',
    width: '12px',
    height: '12px'
  },

  instanceContainer: {
    '&:first-of-type': {
      paddingTop: 0
    },
    '&:last-of-type': {
      border: 'none',
      paddingBottom: 0
    },
    padding: `${PADDINGS.S} 0`,
    borderBottom: '1px solid lightgrey'
  },

  downArrow: {
    width: '0px',
    height: '0px',
    borderLeft: '4.25px solid transparent',
    borderRight: '4.25px solid transparent',
    borderTop: `4.25px solid ${COLORS.PRIMARY.SAND}`,
    marginLeft: PADDINGS.XXS
  }
});

@withToast({ propName: 'workflowToast', duration: 0 })
@withDialog(WorkflowDetailsDialog, { propName: 'workflowDetails' })
@withRegion
@styled(defaultStyles)
@autobind
class WorkflowToast extends PureComponent {
  get showViewMore() {
    return this.state.notificationInstances.length > 4;
  }

  get viewMoreCount() {
    return this.state.notificationInstances.length - 3;
  }

  constructor(props) {
    super(props);

    this.state = {
      showAllInstances: false,
      hiddenWorkflows: [],
      subRecords: [],
      notificationInstances: []
    };
    this.fetchingInstances = false;

    // At this point we know if we need to do a global fetch or a sub-record fetch
    if (props.region.isEU) {
      if (props.globalNotifications) {
        this.fetchGlobalInstances();
      } else {
        this.fetchAllWorkflowInstances();
      }
    }
  }

  fetchGlobalInstances() {
    const { workflowInstances } = this.props;
    workflowInstances
      .fetchList({
        args: {
          criteria: [
            {
              name: 'status_id',
              type: 'in',
              value: ['awaiting_input']
            },
            {
              name: 'can_submit',
              value: true
            },
            {
              name: 'system_ctime',
              type: '>=',
              value: dayjs().subtract(60, 'days').unix()
            }
          ],
          order_by: {
            id: 'DESC'
          }
        },
        id: 'workflowNotificationInstances'
      })
      .then(this.updateNotifications);
  }

  updateNotifications(response) {
    const { workflowToast } = this.props;
    if (!response.data.length) return;

    this.setState(
      {
        notificationInstances: response.data
          .map((instance) => instance.item) // Pluck out the item as we don't need the related data
          .filter((instance) => instance.workflow_version !== null) // Ensure no invalid workflows are shown
          .filter((instance) => instance.status_id !== 'terminated') // TODO: revert to include terminated workflow notifications if backend creates a "seen" property
          .map(this.transformWorkflowInstances)
      },
      () => {
        if (!workflowToast.isVisible) {
          workflowToast.show({});
        }
      }
    );
  }

  /**
   * Transforms the workflow instance that we get from WorkflowInstances service into a usable
   * format for this toast to render it correctly.
   *
   * @param {*} instance - The instance that we want to transform
   */
  transformWorkflowInstances(instance) {
    return {
      ...instance,
      name: _.get(instance, 'workflow_version.workflow.name')
    };
  }

  /**
   * Transforms a notification payload into the required format that we will use to render the
   * toast notification correctly.
   *
   * @param {*} notification - The notification that we want to transform
   */
  transformNotification(notification) {
    return {
      ...notification,
      id: parseInt(_.get(notification, 'record_id')),
      name: _.get(notification, 'data.workflow_name'),
      status_message: _.get(notification, 'message'),
      status_id: _.get(notification, 'data.status_id'),
      started_from_record_id: parseInt(
        _.get(notification, 'data.started_from_record_id', '0')
      ),
      started_from_service_id: _.get(
        notification,
        'data.started_from_service_id'
      )
    };
  }

  handleViewMore() {
    this.setState({ showAllInstances: true });
  }

  relatedRecordSearchCriteria(relatedRecordIds) {
    return Object.keys(relatedRecordIds).reduce((result, currentServiceId) => {
      return result.concat(
        relatedRecordIds[currentServiceId].map((recordId) => ({
          service_name: currentServiceId,
          record_id: recordId
        }))
      );
    }, []);
  }

  getRelatedWorkflowInstances({ serviceIds, criteria }) {
    const {
      workflowInstances,
      notification: { recordId: parentRecordId, serviceId: parentServiceId }
    } = this.props;

    const requests = {};

    serviceIds.forEach((serviceId) => {
      requests[serviceId] = [
        `${serviceId}::search`,
        {
          criteria
        }
      ];
    });

    return api
      .batch(requests)
      .then((response) => {
        const { result } = response.data;
        const allRelatedRecordIds = {};

        Object.keys(result).forEach((serviceId) => {
          const serviceRecordIds = result[serviceId].rows.map((relatedRecord) =>
            _.get(relatedRecord, 'id')
          );

          allRelatedRecordIds[serviceId] = serviceRecordIds;
        });

        return this.relatedRecordSearchCriteria(allRelatedRecordIds);
      })
      .then((relatedRecordSearchCriteria) => {
        return workflowInstances.fetchList({
          args: {
            criteria: [
              {
                name: 'service_name_and_record_id',
                type: 'in',
                value: [
                  {
                    service_name: parentServiceId,
                    record_id: parentRecordId
                  },
                  ...(relatedRecordSearchCriteria.length
                    ? relatedRecordSearchCriteria
                    : [])
                ]
              },
              {
                name: 'status_id',
                type: 'in',
                value: ['awaiting_input']
              },
              {
                name: 'can_submit',
                value: true
              }
            ],
            order_by: {
              id: 'DESC'
            }
          },
          id: 'workflowNotificationInstances'
        });
      });
  }

  /**
   * Retrieves the workflow instances for the current record the user is viewing. This is a basic
   * fetch when there is no related records to fetch workflow instances for.
   */
  getWorkflowInstances() {
    const {
      notification: { recordId, serviceId },
      workflowInstances
    } = this.props;
    return workflowInstances.fetchList({
      args: {
        criteria: [
          {
            name: 'service_name_and_record_id',
            type: 'in',
            value: [
              {
                service_name: serviceId,
                record_id: recordId
              }
            ]
          },
          {
            name: 'status_id',
            type: 'in',
            value: ['awaiting_input']
          },
          {
            name: 'can_submit',
            value: true
          },
          {
            name: 'system_ctime',
            type: '>=',
            value: dayjs().subtract(60, 'days').unix()
          }
        ],
        order_by: {
          id: 'DESC'
        }
      },
      id: 'workflowNotificationInstances'
    });
  }

  async fetchAllWorkflowInstances() {
    const {
      notification: { recordId, serviceId }
    } = this.props;

    const relatedServicesCriteria = {
      Listings: {
        serviceIds: [
          'Contracts',
          'CommissionWorksheets',
          'Invoices',
          'InvoiceTransactions'
        ],
        criteria: [
          {
            name: 'listing_id',
            value: recordId
          }
        ]
      },
      Contacts: {
        serviceIds: ['Substantiations'],
        criteria: [
          {
            name: 'related_contact.id',
            type: 'in',
            value: [recordId]
          },
          {
            name: 'system_record_state',
            value: 'active'
          }
        ]
      },
      Properties: {
        serviceIds: ['Appraisals', 'Oabs'],
        criteria: [
          {
            name: 'property_id',
            value: recordId
          }
        ]
      }
    };

    /**
     * If the user currently has a Listing, Contact or Property record open then we
     * also need to fetch the related record workflow instances. Otherwise we just
     * want to do a standard workflow instance search for the current record.
     */
    const workflowInstancesPromise = relatedServicesCriteria[serviceId]
      ? this.getRelatedWorkflowInstances(relatedServicesCriteria[serviceId])
      : this.getWorkflowInstances();

    const allWorkflowInstances = await workflowInstancesPromise;

    this.updateNotifications(allWorkflowInstances);
  }

  componentDidUpdate(prevProps) {
    const {
      notification: { recordId: prevRecordId, list: prevList }
    } = prevProps;
    const { workflowToast, notification, region } = this.props;
    const { recordId, list } = notification;
    const { notificationInstances } = this.state;

    if (prevList.length !== list.length) {
      // NOTE: We only want WorkflowInstance notifications and don't care about others
      // NOTE: We also need to make sure we don't show notifications for status types other than awaiting_input or error
      // NOTE: We store the notifications as they come in chronological order but when trying to show the user the most recent notification of an instance we are using the uniq lodash function which will only grab the first instance it finds
      const newWorkflowInstances = _.uniqBy(
        list
          .filter(
            (notification) =>
              _.get(notification, 'service_id', '') === 'WorkflowInstances'
          )
          .map(this.transformNotification)
          .reverse(),
        (notification) => notification.id
      );

      const combinedInstances = newWorkflowInstances.concat(
        notificationInstances
      );

      const allNewNotifications = _.uniqBy(
        combinedInstances,
        (instance) => instance.id
      ).filter((notification) => {
        // TODO: revert to include terminated workflow notifications if backend creates a "seen" property
        // (
        //   !this.state.hiddenWorkflows.includes(notification.id) &&
        //   (notification.status_id === 'terminated' ||
        //     (notification.status_id === 'awaiting_input' &&
        //       _.get(notification, 'data.can_submit', false)))
        // );
        return (
          !this.state.hiddenWorkflows.includes(notification.id) &&
          notification.status_id === 'awaiting_input' &&
          _.get(notification, 'data.can_submit', false)
        );
      });

      this.setState(
        {
          notificationInstances: allNewNotifications
        },
        () => {
          if (!workflowToast.isVisible) {
            workflowToast.show({});
          }
        }
      );
    }

    // We only do this when the user has dismissed notifications
    // and has opened up a different record
    if (region.isEU && prevRecordId !== recordId && !workflowToast.isVisible) {
      this.fetchAllWorkflowInstances();
    }
  }

  removeWorkflow(workflowInstanceId) {
    const { hiddenWorkflows } = this.state;
    return () => {
      this.setState({
        hiddenWorkflows: [...hiddenWorkflows, workflowInstanceId]
      });
    };
  }

  handleOpenWorkflowDetails(workflowExecutionId) {
    const { workflowDetails } = this.props;
    return () =>
      workflowDetails.open({
        id: workflowExecutionId,
        onEndWorkflow: this.removeWorkflow(workflowExecutionId)
      });
  }

  render() {
    const { showAllInstances, notificationInstances } = this.state;
    const { styles: s, workflowToast } = this.props;

    const limitedNotifications =
      this.showViewMore && !showAllInstances
        ? notificationInstances.slice(0, 3)
        : notificationInstances;

    const hasNotifications =
      notificationInstances && notificationInstances.length > 0;

    return (
      (workflowToast.isVisible && hasNotifications && (
        <ToastPortal target='workflowNotifications'>
          <div {...workflowToast.eventHandlers} {...s('container')}>
            <Box {...s('wrapper')}>
              <Box pl={PADDINGS.M} pr={PADDINGS.M}>
                <Box
                  alignItems={'center'}
                  pt={PADDINGS.M}
                  pb={PADDINGS.S}
                  style={{
                    borderBottom: '1px solid #E1E1E1',
                    color: COLORS.PRIMARY.SLATE_DARK
                  }}
                >
                  <Box pr={PADDINGS.XXS}>
                    <Icon type={ICONS.WAND} />
                  </Box>
                  <Box
                    flex={1}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    height={'16px'}
                  >
                    <Heading level={3}>Pending Workflows</Heading>
                    <IconButton
                      onClick={workflowToast.hide}
                      Icon={() => <Icon type={ICONS.CLOSE_SMALL} />}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                p={PADDINGS.M}
                pb={(!showAllInstances && PADDINGS.XS) || PADDINGS.S}
              >
                {limitedNotifications.map((instance) => (
                  <Box {...s('instanceContainer')} key={instance.id}>
                    <WorkflowStatus
                      notification
                      instance={instance}
                      key={instance.id}
                    />
                    <TextButton
                      sand
                      onClick={this.handleOpenWorkflowDetails(instance.id)}
                    >
                      View details
                    </TextButton>
                  </Box>
                ))}
                {this.showViewMore && !showAllInstances && (
                  <Box
                    pt={PADDINGS.S}
                    onClick={this.handleViewMore}
                    alignItems={'center'}
                  >
                    <TextButton sand padding={'0px'}>
                      view {this.viewMoreCount} more
                    </TextButton>
                    <span {...s('downArrow')} />
                  </Box>
                )}
              </Box>
            </Box>
          </div>
        </ToastPortal>
      )) ||
      null
    );
  }
}

@withModel(invoicesModel)
@withModel(contractsModel)
@withModel(notificationModel)
@withModel(substantiationsModel)
@withModel(workflowInstancesModel)
@connect((state) => ({ isSwitching: _.get(state, 'session.isSwitching') }))
@withWhereabouts
@autobind
class WorkflowNotifications extends PureComponent {
  state = {
    shouldShowToasts: false,
    isViewingRecord: false
  };

  componentDidUpdate(prevProps) {
    const {
      notification: { recordId, serviceId },
      whereabouts: { hashQuery },
      isSwitching
    } = this.props;
    const {
      notification: { prevRecordId, prevServiceId }
    } = prevProps;
    const currentlyViewingRecord = !!_.get(hashQuery, 'id', false);
    const hasNotificationRecord = !!recordId && !!serviceId;

    /**
     * Show toasts when the following scenarios are valid:
     *
     * 1. The user is on a record screen and redux is aware of it
     * 2. The user is not on a record screen and redux isn't aware of it either
     */
    if (
      !prevRecordId &&
      !prevServiceId &&
      hasNotificationRecord &&
      currentlyViewingRecord &&
      !isSwitching
    ) {
      this.setState({ shouldShowToasts: true, isViewingRecord: true });
    }

    if (!currentlyViewingRecord && !hasNotificationRecord && !isSwitching) {
      this.setState({ shouldShowToasts: true });
    }

    if (isSwitching && !prevProps.isSwitching) {
      this.setState({ shouldShowToasts: false, isViewingRecord: false });
    }
  }

  render() {
    const { shouldShowToasts, isViewingRecord } = this.state;

    return shouldShowToasts ? (
      <WorkflowToast globalNotifications={!isViewingRecord} {...this.props} />
    ) : null;
  }
}

export default WorkflowNotifications;
