/*
|-------------------------------------------------------------------------------
| Migration for jStorage
|-------------------------------------------------------------------------------
|
| The jStorage library from Classic Rex is made redundant by the shared
| "LocalStorage" library that exclusively uses localStorage.
|
*/

import LocalStorage from 'shared/utils/local-storage';

export default function migrateLocalStorage() {
  const nativeLocalStorage = window.parent.localStorage;
  const gross = nativeLocalStorage.getItem('jStorage');

  if (gross) {
    try {
      const s = JSON.parse(gross);

      // Kill the jStorage localStorage key as we don't need it anymore
      // NOTE: we remove this before moving the local storage to the new
      // place, since otherwise we potentially run into max
      nativeLocalStorage.removeItem('jStorage');

      LocalStorage.set('api_token', s.api_token);
      LocalStorage.set('account_info', s.account_info);
      LocalStorage.set('announcement_cache', s.announcement_cache);
      LocalStorage.set('custom_tabs', s.custom_tabs);
      LocalStorage.set('leads_cache', s.leads_cache);
      LocalStorage.set('selected_trust_account', s.selected_trust_account);
      LocalStorage.set('trust_account_worksheet', s.trust_account_worksheet);
      LocalStorage.set('user_imitate', s.r2_user_imitate);
      LocalStorage.set('column_picker', s.r2_column_picker);
      LocalStorage.set('list_per_page', s.r2_list_per_page);
      LocalStorage.set('once_flags', s.r2_once_flags);
      LocalStorage.set('after_login_url', s.r2_after_login_url);

      // Wrap recent activity in another try catch, just in case this block
      // is too large size wise, the rest of the storage will still be migrated
      // properly this way
      try {
        LocalStorage.set('recent_activity', s.r2_recently_accessed);
      } catch (e) {
        console.error('Unabled to migrate recent activity.');
      }

      // eslint-disable-next-line
      console.log('Successfully migrated localstorage.');
    } catch (err) {
      console.error('Unabled to migrate jStorage.');
      console.error(err);
    }
  }
}
