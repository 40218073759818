import React, { ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  paragraph: {
    width: '340px',
    color: COLORS.DARKER_GREY,
    fontSize: FONT.SIZES.LARGE,
    lineHeight: '22px',
    textAlign: 'center'
  }
});

export function RedirectParagraph({ children }: { children: ReactNode }) {
  const s = useStyles(defaultStyles);

  return <p {...s('paragraph')}>{children}</p>;
}
