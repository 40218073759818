import React from 'react';

import { RecordScreen } from 'components/_experiment/record-screen';

import { CampaignDetailsScreen } from './campaign-details-screen';
import { CampaignsListScreen } from './campaigns-list-screen';

export function CampaignsRecordScreen() {
  return (
    <RecordScreen
      RecordListScreen={CampaignsListScreen}
      RecordDetailsScreen={CampaignDetailsScreen}
    />
  );
}
