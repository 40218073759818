import React, { PureComponent } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import Dialog from 'view/components/dialog';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { ReactForms, FormField, Form } from 'view/components/form';
import { TextArea } from '@rexlabs/text-input';
import { Hint } from 'components/text/hint';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import washListsModel from 'data/models/custom/wash-lists';
import { createSingleValidationRule } from '@rexlabs/validator';

@withModel(washListsModel)
@withErrorDialog
@autobind
class ToggleStatusDialog extends PureComponent {
  static propTypes = {
    phone: types.string,
    nameFirst: types.string,
    nameLast: types.string,
    address: types.string,
    service: types.string.isRequired,
    override: types.string,
    callback: types.func
  };

  onSaveClick({ reason }) {
    const { override, errorDialog, callback, closeDialog } = this.props;

    const method =
      override === 'allow' ? this.removeOverride : this.setOverride;

    method(reason)
      .then(({ data: { result } }) => {
        callback(result);
        closeDialog();
      })
      .catch(errorDialog.open);
  }

  setOverride(reason) {
    const {
      washLists,
      service,
      phone: phoneNumber,
      nameFirst,
      nameLast,
      address
    } = this.props;

    const requestBody = {
      nameFirst,
      nameLast,
      address,
      phoneNumber,
      reason,
      status: 'allow'
    };

    const method =
      service === 'TPS'
        ? washLists.setPhoneNumberOverride
        : washLists.setMailAddressOverride;

    return method(requestBody);
  }

  removeOverride() {
    const {
      washLists,
      service,
      phone: phoneNumber,
      nameFirst,
      nameLast,
      address
    } = this.props;

    const requestBody = {
      nameFirst,
      nameLast,
      address,
      phoneNumber
    };

    const method =
      service === 'TPS'
        ? washLists.removePhoneNumberOverride
        : washLists.removeMailAddressOverride;

    return method(requestBody);
  }

  renderRestoreBody() {
    const { service } = this.props;

    return (
      <Hint normal dark>
        Are you sure you want to remove your override and revert to the original{' '}
        {service} Registry status - Contact not permitted?
      </Hint>
    );
  }

  renderOverrideBody() {
    return (
      <FormField
        name={'reason'}
        label={'reason for override'}
        Input={TextArea}
        validate={createSingleValidationRule('reason', 'required')}
      />
    );
  }

  render() {
    const { closeDialog, override, service } = this.props;
    const titleText = `${
      override === 'allow' ? 'Restore' : 'Override'
    } ${service} Status`;

    return (
      <Dialog
        title={titleText}
        closeDialog={closeDialog}
        height={244}
        width={480}
      >
        <ReactForms
          handleSubmit={this.onSaveClick}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ submitForm }) => (
            <Form>
              <Box pb={15}>
                {override === 'allow'
                  ? this.renderRestoreBody()
                  : this.renderOverrideBody()}
              </Box>
              <SaveCancelButtonBar onCancel={closeDialog} onSave={submitForm} />
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default ToggleStatusDialog;
