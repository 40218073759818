import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import AddressLookup from 'view/components/address-lookup';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';
import _ from 'lodash';
import { TextButton, DefaultButton } from 'view/components/button';
import { ButtonBar } from 'view/components/button-bar';

const defaultStyles = StyleSheet({
  label: {
    marginBottom: '2px',
    textTransform: 'lowercase',
    ...TEXTS.CONTENT.FORM_FIELD_LABEL_1
  }
});

@autobind
class AddressLookupDialog extends PureComponent {
  state = {
    data: null
  };

  handleSubmit() {
    if (this.props.callback) {
      this.props.callback({
        data: this.state.data
      });
    }
    this.props.closeDialog();
  }

  render() {
    const { closeDialog, onLoad } = this.props;
    return (
      <Dialog
        title='Address Lookup'
        closeDialog={closeDialog}
        width={400}
        top={Math.max(window.innerHeight / 2 - 200, 0)}
        onLoad={onLoad}
      >
        <Box mb='20px'>
          <AddressLookup
            styles={defaultStyles}
            onChange={({ address }) =>
              this.setState({ data: _.get(address, 'data') })
            }
          />
        </Box>

        <ButtonBar>
          <TextButton blue onClick={closeDialog}>
            Cancel
          </TextButton>
          <DefaultButton
            onClick={this.handleSubmit}
            isDisabled={!this.state.data}
          >
            Done
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default AddressLookupDialog;
