import { api } from 'shared/utils/api-client/index';
import { AIExceptionError } from 'features/ai/errors/ai-exception-error';

export function apiStreamRequest(
  endpoint: string,
  data: Record<string, unknown>,
  onProgress: (text: string) => void,
  onFinish: () => void,
  onError: (err: Error) => void
): void {
  // Axios can't handle a ReadableStream in a browser, so we have to use fetch
  fetch(`${api.axiosInstance.defaults.baseURL}${endpoint}`, {
    body: JSON.stringify({ data }),
    mode: 'cors',
    method: 'POST',
    headers: {
      // Inherit token, app identifier, etc. from the API client
      ...api.headers
    }
  })
    .then(async (response) => {
      // The response body is a ReadableStream
      const reader = response?.body?.getReader();

      const decoder = new TextDecoder();

      // eslint-disable-next-line no-unmodified-loop-condition
      while (reader) {
        const { value, done } = await reader.read();
        if (done && onFinish) {
          onFinish();
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        const chunkObjects = chunk
          .split('\n')
          .filter(Boolean)
          .map((line) => JSON.parse(line.split('data: ')[1]));

        if (chunkObjects.find((c) => c.error)) {
          throw new AIExceptionError(chunkObjects.find((c) => c.error)?.error);
        }

        const result = chunkObjects
          .map((chunkObject) => chunkObject.result)
          .join('');

        if (onProgress) {
          onProgress(result);
        }
      }
    })
    .catch((err) => {
      if (onError) {
        onError(err);
      } else {
        throw err;
      }
    });
}
