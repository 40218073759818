import React, { RefObject } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import classnames from 'classnames';
import { AiLogo } from 'features/ai/components/ai-logo';

const styles = StyleSheet({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '20px',
    paddingLeft: '20px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px'
  },
  text: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
    color: 'rgba(33,58,106,1)'
  }
});
export function TextGenerationWritingIndicator({
  isVisible
}: {
  isVisible: boolean;
}) {
  const s = useStyles(styles);

  return (
    <div
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
      className={classnames(s('container').className, 'ai__writing-indicator')}
    >
      <AiLogo />
      <span {...s('text')}>Rex AI is writing...</span>
    </div>
  );
}

export function getWritingIndicatorPosition(
  completionRef: RefObject<HTMLSpanElement>
) {
  if (!completionRef.current) return { x: 0, y: 0 };
  const completion = completionRef.current;
  const completionRect = completion.getBoundingClientRect();
  const cursor = completion.querySelector('.ai__cursor') as HTMLSpanElement;
  const cursorRects = cursor?.getClientRects();
  return {
    y:
      (cursorRects?.[0]?.y || completionRect.y) -
      completionRect.y +
      (completionRect.y ? 20 : 0),
    x: 0
  };
}

export function updateWritingIndicatorPosition(
  completionRef: RefObject<HTMLSpanElement>
) {
  if (!completionRef.current) return;
  const progressPosition = getWritingIndicatorPosition(completionRef);
  const progress = document.querySelector(
    '.ai__writing-indicator'
  ) as HTMLSpanElement;
  progress.style.left = progressPosition.x + 'px';
  progress.style.top = progressPosition.y + 'px';
}
