import React, {
  ComponentType,
  ReactNode,
  useState,
  useCallback,
  CSSProperties
} from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Query } from '@rexlabs/model-generator';

import { usePersistedState } from 'shared/hooks/use-persisted-state';
import Spinner from 'shared/components/spinner';

import { Screen, ScreenProps } from 'components/screen';

import { StateView } from './state-view';
import { RecordListTable } from './table';
import { RecordListPagination } from './pagination';
import { RecordListFilters } from './filters';
import { RecordListActions } from './actions';
import { useCriteria } from './utils/use-criteria';
import { usePage } from './utils/use-page';
import { useListData } from './utils/use-list-data';
import { convertOrderByStringToObject, useOrderBy } from './utils/use-order-by';
import { useListLoadingIndicator } from './utils/use-list-loading-indicator';

import {
  ColumnsConfig,
  FiltersConfig,
  LensesConfig,
  ListActionsConfig,
  Selection
} from './types';

const defaultStyles = StyleSheet({
  topBar: {
    marginBottom: '20px'
  },

  bottomBar: {
    marginTop: '20px'
  },

  list: {
    background: '#fff',
    paddingBottom: '15px'
  },

  listTitle: {
    padding: '20px'
  }
});

export interface RecordListScreenProps {
  serviceName: string; // TODO: enum with available types
  query: Query<any>;
  columns: ColumnsConfig;
  onAdd?: () => any;
  actions?: ListActionsConfig;
  title?: ReactNode;
  filtersConfig?: FiltersConfig;
  LoadingView?: ComponentType<any>;
  emptyMessage?: string;
  EmptyView?: ComponentType<any>;
  onItemClick?: (item: any, allIds: any) => void;
  lenses?: LensesConfig;
  fetchAllIds?: boolean;
  screenProps?: Omit<ScreenProps, 'children'>;
  inlinePagination?: boolean;
  titleStyles?: CSSProperties;
}

const DefaultLoadingView = () => (
  <StateView>
    <Spinner small dark />
  </StateView>
);

const initialSelection: Selection = {
  ids: [],
  type: 'include'
};

export function RecordListScreen({
  serviceName,
  query,
  columns,
  onAdd,
  actions,
  title = serviceName,
  filtersConfig,
  LoadingView = DefaultLoadingView,
  emptyMessage = `No ${title} found`,
  EmptyView,
  onItemClick,
  lenses,
  fetchAllIds,
  screenProps = {},
  inlinePagination,
  titleStyles
}: RecordListScreenProps) {
  const s = useStyles(defaultStyles);

  const defaultEmptyView = useCallback(
    () => <StateView>{emptyMessage}</StateView>,
    [emptyMessage]
  );

  const EmptyViewComponent = EmptyView || defaultEmptyView;

  const [page, setPage] = usePage();
  const {
    displayCriteria,
    queryCriteria,
    setCriteria,
    savedFilter,
    isLoading: criteriaLoading
  } = useCriteria({ serviceName, lenses, filtersConfig });
  const { orderBy, orderDir, setOrderBy } = useOrderBy({ lenses });

  const [selection, setSelection] = useState<Selection>(initialSelection);

  const clearSelection = () => {
    setSelection(initialSelection);
  };

  const [limit, setLimit] = usePersistedState('@rex/listLimit', 20);
  const [visibleColumns, setVisibleColumns] = usePersistedState(
    `@rex/${serviceName}-listVisibleColumns`,
    columns?.filter((column) => !column.hidden).map((column) => column.id)
  );

  const { status, pageData, pagination, loadedPage, allIds } = useListData({
    query,
    limit,
    page,
    criteria: queryCriteria,
    orderBy,
    orderDir,
    lenses,
    fetchAllIds
  });

  // Set loading indicator based on list loading status
  useListLoadingIndicator(status);

  return (
    <Screen roomForIntercom {...screenProps}>
      {!inlinePagination && (
        <Box
          {...s('topBar')}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box
            {...s('actions')}
            flexDirection='row'
            alignItems='center'
            sx='5px'
          >
            <RecordListActions
              actions={actions}
              onAdd={onAdd}
              selection={selection}
              queryCriteria={queryCriteria}
              afterMassAction={clearSelection}
            />
          </Box>
          <Box {...s('pagination')}>
            <RecordListPagination
              pagination={pagination}
              limit={limit}
              setLimit={setLimit}
              page={loadedPage}
              setPage={setPage}
            />
          </Box>
        </Box>
      )}
      <Box {...s('list')}>
        <Box
          {...s('listTitle')}
          flexDirection='row'
          justifyContent={inlinePagination ? 'space-between' : 'flex-start'}
          alignItems='center'
          style={titleStyles}
        >
          <RecordListFilters
            title={title}
            filtersConfig={filtersConfig}
            displayCriteria={displayCriteria}
            setCriteria={setCriteria}
            serviceName={serviceName}
            savedFilter={savedFilter}
            lenses={lenses}
            isLoading={criteriaLoading}
            orderBy={convertOrderByStringToObject(orderBy)}
          />
          {inlinePagination && (
            <RecordListPagination
              pagination={pagination}
              limit={limit}
              setLimit={setLimit}
              page={loadedPage}
              setPage={setPage}
            />
          )}
        </Box>
        <RecordListTable
          items={pageData}
          columns={columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          selection={selection}
          setSelection={setSelection}
          hasSelection={!!actions?.length}
          orderBy={orderBy}
          orderDir={orderDir}
          setOrderBy={setOrderBy}
          isLoading={pageData === undefined}
          LoadingView={LoadingView}
          EmptyView={EmptyViewComponent}
          onItemClick={onItemClick}
          allIds={allIds}
        />
      </Box>
      <Box
        {...s('bottomBar')}
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'
      >
        <Box {...s('pagination')}>
          <RecordListPagination
            pagination={pagination}
            limit={limit}
            setLimit={setLimit}
            page={loadedPage}
            setPage={setPage}
          />
        </Box>
      </Box>
    </Screen>
  );
}
