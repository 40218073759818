import React, { Component } from 'react';
import EntityOption from 'view/components/input/select/options/entity';
import { ICONS } from 'shared/components/icon';

class ListingsOption extends Component {
  render() {
    return <EntityOption {...this.props} Icon={ICONS.SELECT.LISTINGS} />;
  }
}

export default ListingsOption;
