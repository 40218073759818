import React, { ReactNode, useCallback } from 'react';
import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import { RecordListActions } from 'components/record-list-screen/actions';
import { PADDINGS } from 'shared/theme';
import { ColumnConfig } from 'components/record-list-screen/types';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { RelatedTable } from 'src/view/components/record-details-screen/related-table';
import { Actions, useEntityListQuery } from '@rexlabs/model-generator';
import {
  FilterPopout,
  FilterPopoutConfig,
  filterPopoutFieldConfigs
} from 'components/popouts/filter-popout';
import { Criteria } from 'types/criteria';
import { Body } from 'components/text/body';

/**
 * Props for the AdminListScreen componentasdasda
 */

export interface AdminListScreenProps<ModelType> {
  columns: ColumnConfig<ModelType>[];
  name: string;
  /**
   * The service name is used to generate the API endpoint for the model.
   * e.g. AdminNewsletterTemplates
   */
  serviceName: string;
  /**
   * Renders below the heading
   */
  renderSubheading?: (props: AdminListScreenProps<ModelType>) => ReactNode;
  /**
   * Config for the filter popout
   */
  filters?: FilterPopoutConfig;
  /**
   * Initial criteria to filter the list by.
   * Defaults sensibly (show non-hidden, non-archived templates)
   */
  initialCriteria?: Criteria[];
  /**
   * Callback for when the user clicks the add button
   */
  onAdd: (props: AdminListScreenProps<ModelType>) => Promise<unknown>;
  /**
   * Callback for when the user clicks on a row
   */
  onItemClick: (data: ModelType) => void;
  /**
   * Return type of useEntityListQuery (in other words, you are
   * expected to manage your own query and pass it to this component)
   */
  entityList: ReturnType<typeof useEntityListQuery>;
  /**
   * Current criteria state, used by the query and the filter popout
   */
  criteria: Criteria[] | null;
  /**
   * Set the criteria state
   */
  setCriteria: (criteria: Criteria[]) => void;
}

const styles = StyleSheet({
  heading: { marginLeft: 0, marginBottom: 5 },
  subheading: {
    marginLeft: 0,
    marginBottom: PADDINGS.M,
    whiteSpace: 'pre-wrap'
  }
});

/**
 * The AdminListScreen component is a wrapper around the RelatedTable component
 * that provides a standard layout for showing records in the admin area.
 */
export function AdminListScreen<ModelType>(
  props: AdminListScreenProps<ModelType>
) {
  const {
    columns,
    name,
    renderSubheading = ({ name }) => (
      <Body regular dark {...s('subheading')}>
        Manage your {name.toLowerCase()}
      </Body>
    ),
    filters = [
      {
        field: 'is_hidden',
        label: 'Hidden Templates',
        ...filterPopoutFieldConfigs.boolean()
      },
      {
        field: 'system_record_state',
        label: 'Record State',
        ...filterPopoutFieldConfigs.select([
          { label: 'Active', value: 'active' },
          { label: 'Archived', value: 'archived' }
        ])
      }
    ],
    criteria,
    setCriteria,
    onAdd,
    onItemClick,
    entityList
  } = props;
  const onItemClickHandler = useCallback(
    (data: ModelType) => {
      onItemClick(data);
    },
    [onItemClick]
  );

  const s = useStyles(styles);

  return (
    <Box p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <Box m={0}>
        <SubHeading semibold {...s('heading')}>
          {name}s
        </SubHeading>
        {renderSubheading(props)}
      </Box>
      <RelatedTable
        Heading={() => (
          <Box display='flex' flexDirection='row' spacing={4}>
            <RecordListActions
              onAdd={async () => {
                await onAdd(props);
                await entityList.actions.refreshList();
              }}
              selection={{ type: 'include', ids: [] }}
            />
            <FilterPopout
              title={`Filter ${name}`}
              filters={filters}
              criteria={criteria}
              onFilter={setCriteria}
            />
          </Box>
        )}
        columns={columns}
        onItemClick={onItemClickHandler}
        limit={20}
        items={entityList?.data || []}
        isLoading={entityList?.status === 'loading'}
        emptyMessage={`No ${name.toLowerCase()}s found`}
        colorScheme='light'
        showFooterPagination={true}
      />
    </Box>
  );
}
