import React, {
  ComponentType,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';

const defaultStyles = StyleSheet({
  toggleTruncate: {
    display: 'none',
    border: '0 none',
    text: 'inherit',
    fontSize: '12px',
    padding: 0,
    margin: '0 0 0 6px',
    color: COLORS.PRIMARY.BLUE,
    cursor: 'pointer',
    fontWeight: 600,
    background: 'transparent'
  },

  toggleTruncateVisible: {
    display: 'inline'
  }
});

interface MultiLineTruncateProps {
  as?: string | ComponentType<any>;
  maxLines?: number;
  style?: any;
  className?: string;
}

export function MultiLineTruncate({
  children,
  maxLines,
  as: Element = 'span',
  style,
  className
}: PropsWithChildren<MultiLineTruncateProps>) {
  const s = useStyles(defaultStyles);
  const [expanded, setExpanded] = useState(false);
  const [toggleVisible, setToggleVisible] = useState(false);

  const containerRef = useRef<HTMLHeadingElement>(null);
  const contentRef = useRef<HTMLSpanElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);

  const originalContent = useRef<any>(null);
  useLayoutEffect(() => {
    originalContent.current = contentRef.current?.innerHTML;
  }, []);

  useLayoutEffect(() => {
    if (
      containerRef.current &&
      contentRef.current &&
      toggleRef.current &&
      maxLines
    ) {
      // Reset content
      contentRef.current.innerHTML = originalContent.current;

      if (expanded) {
        return;
      }

      // HACK: the state change won't actually take effect until after the
      // `useLayoutEffect` has resolved :/
      toggleRef.current.style.display = 'none';
      setToggleVisible(false);

      // HACK: cannot use `lineClamp` for multi line truncation because we want the
      // `show less/more` button inline, so we do the truncation here via JS
      // We're basically checking if the current element height is larger than the
      // elements line height times the `maxLines` (the `+ lineHeight / 2` is just
      // adding an extra buffer just in case :/)
      const lineHeight = parseInt(
        window.document.defaultView
          ?.getComputedStyle?.(containerRef.current)
          ?.getPropertyValue?.('line-height') || '0'
      );
      if (lineHeight) {
        while (
          containerRef.current.clientHeight >
          lineHeight * maxLines + lineHeight / 2
        ) {
          toggleRef.current.style.display = 'inline';
          setToggleVisible(true);

          // Remove one word at a time until the client height of the container is lower
          // than the amount of max lines given the current line height
          const words = contentRef.current?.innerText?.split(' ');
          words?.pop?.();

          contentRef.current.innerText = `${words?.join(' ')}...`;
        }
      }
    }
  }, [expanded, maxLines]);

  return (
    <Element
      ref={containerRef}
      {...s.with('container', {
        containerTruncated: !!maxLines && !expanded
      })({ style, className })}
    >
      <span ref={contentRef}>{children}</span>
      {!!maxLines && (
        <button
          ref={toggleRef}
          {...s('toggleTruncate', { toggleTruncateVisible: toggleVisible })}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'show less' : 'show more'}
        </button>
      )}
    </Element>
  );
}
