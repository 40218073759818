import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface SubTextProps extends BaseTextProps {
  informative?: boolean;
  regular?: boolean;
  bold?: boolean;
  linked?: boolean;
}

export function SubText({
  informative,
  regular,
  bold,
  linked,
  ...props
}: SubTextProps) {
  return (
    <Text
      is='p'
      {...props}
      type={map({
        SUBTEXT: true,
        INFORMATIVE_SUBTEXT: informative,
        REGULAR: regular,
        BOLD: bold,
        LINKED: linked
      })}
    />
  );
}
