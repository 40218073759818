import { styled, StyleSheet } from '@rexlabs/styling';
import { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';
import React, { Component } from 'react';
import { COLORS } from 'src/theme';

const commTypeMap = {
  phone: 'phone calls',
  email: 'emails',
  sms: 'SMS'
};

function getIcon(props, action) {
  let icon = ICONS.WARNING;

  if (action === 'block') {
    switch (props.commType) {
      case 'phone':
        icon = ICONS.DONT_CALL;
        break;
      case 'email':
        icon = ICONS.DONT_EMAIL;
        break;
      case 'sms':
        icon = ICONS.DONT_SMS;
        break;
    }
  }

  return icon;
}

function getMessage(props) {
  let message = 'This contact has ';
  const mappedCommType = commTypeMap[props.commType];

  if (props.consent && props.comm) {
    message += `not given consent to receive ${mappedCommType}, and has opted out of receiving ${mappedCommType}`;
  } else if (props.consent) {
    message += `not given consent to receive ${mappedCommType}`;
  } else if (props.comm) {
    message += `opted out of receiving ${mappedCommType}`;
  }

  // Additional text for dialog 'warning' banners
  if (props.inDialog === true && props.action === 'warn') {
    message += `. You can still send ${
      (props.commType === 'sms' && 'SMS') ||
      (props.commType === 'email' && 'email')
    } alerts, but it will not comply with the contact’s communication preferences.`;
  }

  return message;
}

function getActionLevel(props) {
  let action = 'warn';

  if (props.consent === 'block' || props.comm === 'block') {
    action = 'block';
  }

  return action;
}

@styled(
  StyleSheet({
    error: {
      backgroundColor: '#FDE8E8'
    },
    warning: {
      backgroundColor: '#DFDEDC'
    },
    banner: {
      borderRadius: '2px',
      paddingRight: '10px',
      fontSize: '12px',
      lineSize: '16px',
      height: '29px'
    },
    icon: {
      height: '25px',
      marginLeft: '5px'
    },
    errorIcon: {
      color: COLORS.PRIMARY.RED
    },
    warningIcon: {
      color: COLORS.PRIMARY.SAND
    }
  })
)
class Banner extends Component {
  constructor(props) {
    super(props);
    const action = getActionLevel(props);

    this.state = {
      action: action,
      BannerIcon: getIcon(props, action),
      message: getMessage(props)
    };
  }

  getDerivedStateFromProps(props) {
    const action = getActionLevel(props);

    return {
      action: action,
      BannerIcon: getIcon(props, action),
      message: getMessage(props)
    };
  }

  render() {
    const { styles: s, consent, comm, inDialog } = this.props;
    const { BannerIcon, message, action } = this.state;

    // Don't show consent warnings when in a dialog (will have another warning component take care of this case)
    if (consent === 'warn' && inDialog) {
      return null;
      // Don't show if we don't have a reason or action
    } else if (!consent && !comm) {
      return null;
    }

    return (
      <Box
        {...s('banner', {
          error: action === 'block',
          warning: action === 'warn'
        })}
        direction={'column'}
        alignItems={'center'}
      >
        <Box {...s('icon')}>
          <BannerIcon
            {...s({
              errorIcon: action === 'block',
              warningIcon: action === 'warn'
            })}
          />
        </Box>
        <Box ml={5}>{message}</Box>
      </Box>
    );
  }
}

export default Banner;
