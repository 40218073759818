import invariant from 'invariant';

function normalizer(street) {
  if (street.label && street.value) {
    return street;
  }

  const id = street._id || street.id;
  const name = street._text || street.text || street._value || street.value;

  invariant(
    id && name,
    "Items must have either an '_id' or 'id' and '_text' or 'text'"
  );

  return {
    label: name,
    value: {
      id,
      name
    },
    type: 'street'
  };
}

export function normalizerNoId(street) {
  if (street.label && street.value) {
    return street;
  }

  const id = street._id || street.id;
  const name = street._text || street.text || street._value || street.value;

  return {
    label: name,
    value: {
      id: `${name}-street`,
      originalId: id,
      name
    },
    type: 'street'
  };
}

export default normalizer;
