import _ from 'lodash';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import accountUsers from 'data/models/value-lists/account-users';
import { useMemo } from 'react';
import { useValueList } from 'hooks/use-value-list';

export function usePermissionGroupOptions() {
  const session = useModelState(sessionModel);

  const { items: accountUserItems, status } = useValueList(accountUsers);

  const groupOptions = useMemo(() => {
    // Grab the other member ids of the user's permission group
    const groupMemberIds = session.group_member_ids || [];
    const groupMemberUsers: { value: string; label: string }[] = [];

    // Map them to a new array
    groupMemberIds.forEach((i) => {
      const user = accountUserItems?.find?.((u) => u.value === i);
      if (user) {
        groupMemberUsers.push(user);
      }
    });

    // Add the current user to the array
    if (_.isArray(groupMemberUsers)) {
      groupMemberUsers?.push({
        value: session.user_details.id,
        label: session.user_details.full_name
      });
    }

    return groupMemberUsers;
  }, [
    accountUserItems,
    session.group_member_ids,
    session.user_details.full_name,
    session.user_details.id
  ]);

  return { status, groupOptions };
}
