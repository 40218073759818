import React from 'react';

import { Value, ValueProps } from '.';

interface ArrayValueProps extends Omit<ValueProps, 'value'> {
  value?: string[];
}

export function ArrayValue({ value, ...props }: ArrayValueProps) {
  return <Value value={value?.join?.(', ')} {...props} />;
}
