import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'contactIdentityVerifications';

const actionCreators = {
  verifyIdentity: {
    request: ({ id, verified }) =>
      api.post('ContactIdentityVerifications::toggleIdentityVerification', {
        identity_verification_id: id,
        verified
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  checkFormValidation: {
    request: ({ id, data }) =>
      api.post('ContactIdentityVerifications::getIdentityVerificationIssues', {
        identity_verification_id: id,
        data_overlay: data
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const identityVerificationsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default identityVerificationsModel;
