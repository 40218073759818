/*
|-------------------------------------------------------------------------------
| Shell Overlay Sync
|-------------------------------------------------------------------------------
|
| This syncs the overlay status to Shell, when opening an overlay in Classic,
| so we can render the backdrop on top of all Shell elements accordingly
|
| Allows the following:
|  * Rex2 action changes in overlay state.
|  * Rex2 to listen to click events on the Shell, when overlays are active.
|
*/
import ui from 'data/models/custom/ui';
import uiClassicHeader from 'data/models/custom/ui-classic-header';

const OverlaySyncBridge = {
  actionCreators: {
    updateDialogActive: ui.actionCreators.updateDialogActive,
    updateClassicDialogActive: ui.actionCreators.updateClassicDialogActive,
    updateOverlayActive: ui.actionCreators.updateOverlayActive,
    updateHeaderHidden: ui.actionCreators.updateHeaderHidden,
    updateQuickNav: ui.actionCreators.updateQuickNav,
    updateOverlayBannerActive:
      uiClassicHeader.actionCreators.updateOverlayBannerActive,
    updateOverlayCloseShowing: ui.actionCreators.updateOverlayCloseShowing,
    hideComponents: ui.actionCreators.hideComponents,
    showComponents: ui.actionCreators.showComponents
  },

  overlaySyncWrapperSub(fn, event) {
    // Avoid handling events from other frames
    // Note: We bubble some child frame events up, like in
    //       "view/classic-bridges/app-frame.js"
    if (event.view !== window) return;

    if (event.keyCode) {
      if (event.keyCode === 27) {
        // Escape Code
        fn();
      }
    } else {
      fn();
    }
  },

  onOverlayExit(fn) {
    const bindFn = this.overlaySyncWrapperSub.bind(this, fn);
    window.addEventListener('keyup', bindFn);
    return () => window.removeEventListener('keyup', bindFn);
  }
};

export default OverlaySyncBridge;
