import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { ReactForms, Form, FormField } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';
import { Grid, Column } from 'shared/components/grid';
import { ValueListSelect } from 'view/components/input/select';
import { autobind } from 'core-decorators';
import Checkbox from 'view/components/input/checkbox';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { createValidationRules } from 'shared/utils/form';
import _ from 'lodash';

import mwrMetricLabelTypeModel from 'data/models/system-lists/mwr-metric-label-type';

@withErrorDialog
@autobind
class EditRelatedDataDialog extends PureComponent {
  static defaultProps = {
    invertable: true
  };

  valdidate(values, extra) {
    const { currentData, data } = this.props;

    if (_.get(extra, 'submitCount') < 1) {
      return {};
    }

    const errors = createValidationRules({
      'value.type': 'required',
      'value.value': 'required'
    })(values);

    if (
      currentData &&
      _.get(data, 'label') !== values.label &&
      currentData.find((c) => c.label === values.label)
    ) {
      errors.label =
        'Label already in use. Please choose a unique label for the related data field.';
    }

    return errors;
  }

  handleSubmit(values) {
    const { callback, closeDialog, errorDialog } = this.props;
    return Promise.all([callback(values)])
      .then(closeDialog)
      .catch(errorDialog.open);
  }

  render() {
    const { onLoad, closeDialog, data, invertable, forceLiteral } = this.props;
    return (
      <Dialog
        title='Related Data'
        height={200}
        onLoad={onLoad}
        closeDialog={closeDialog}
      >
        <ReactForms handleSubmit={this.handleSubmit} initialValues={data}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <FormField name='label' label='label' Input={TextInput} />
              <Grid>
                {!forceLiteral && (
                  <Column width={3}>
                    <FormField
                      name='value.type'
                      label='type'
                      Input={ValueListSelect}
                      inputProps={{
                        models: [mwrMetricLabelTypeModel]
                      }}
                    />
                  </Column>
                )}
                <Column width={forceLiteral ? 12 : 9}>
                  <FormField
                    name='value.value'
                    label='value'
                    Input={TextInput}
                  />
                </Column>
                <Column width={3}>&nbsp;</Column>
                {!!invertable && (
                  <Column width={9}>
                    <FormField
                      name='value.invert'
                      Input={Checkbox}
                      inputProps={{ label: 'invert value' }}
                    />
                  </Column>
                )}
              </Grid>
              <ButtonBar isLoading={isSubmitting}>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton
                  dark={!forceLiteral}
                  red={forceLiteral}
                  onClick={submitForm}
                >
                  {forceLiteral ? 'Save' : 'Done'}
                </DefaultButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default EditRelatedDataDialog;
