import React from 'react';
import { StyleSheet } from '@rexlabs/styling';

import { ActionMenu } from 'view/components/action-menu';
import { IconButton } from 'view/components/button';

import { ActionMenuProps } from './core';

const tetherStyles = StyleSheet({
  container: {
    zIndex: 10
  }
});

interface IconActionMenuProps extends ActionMenuProps {
  addButton?: boolean;
  Icon: any;
}

export function IconActionMenu({
  items,
  placement,
  addButton,
  Icon,
  ...props
}: IconActionMenuProps) {
  return (
    <ActionMenu
      style={{ zIndex: '100' }}
      items={items}
      tetherStyles={tetherStyles}
      placement={placement}
      Button={() => <IconButton Icon={Icon} circle {...props} />}
      distance='14px'
      offset='0px'
    />
  );
}
