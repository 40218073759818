import _ from 'lodash';
import React from 'react';

function isReactComponentClass(maybeComponent) {
  return (
    _.isFunction(maybeComponent) &&
    maybeComponent.prototype &&
    !!maybeComponent.prototype.isReactComponent
  );
}

/**
 * Renders an element, an array of elements, or a render prop.
 * @param {ReactElement|ReactElement[]|String|Function|null} element
 * @param props
 * @param conditional
 *        The conditional isn't used when the element is a function/component,
 *        because it is assumed to handle boolean render logic.
 */
export function renderProp(element, props, conditional = true) {
  if (element == null) {
    return element;
  } else if (isReactComponentClass(element)) {
    return React.createElement(element, props);
  } else if (_.isFunction(element)) {
    return element(props);
  } else if (
    React.isValidElement(element) ||
    _.isString(element) ||
    _.isArray(element)
  ) {
    return conditional ? element : null;
  } else {
    throw new Error('The element provided to renderProp was invalid.');
  }
}

/**
 * Simple function that helps creating a key without spaces and all lowercase.
 * It will also ensure that the returned item is a string in the case of an integer being passed.
 * @param keyToBe - The key that you desire
 * @returns {string}
 */
export function createKey(keyToBe) {
  return keyToBe.toString().toLowerCase().replace(/\s/g, '-');
}

export function getDisplayName(ReactComponent) {
  return ReactComponent.displayName || ReactComponent.name;
}
