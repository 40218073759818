import React, { Fragment, useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { Form, FormField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import FilterButton from 'src/view/components/button/filter-button';
import { upperFirst } from 'lodash';
import { Criteria } from 'types/criteria';
import { Select } from 'view/components/input/select/index';
import { TextInput } from '@rexlabs/text-input';
import SelectOption from 'view/components/input/select/options/default';

/**
 * Component to render a filter popout based on a simple
 * filters config
 */
interface FilterPopoutProps<T extends Filter[] | Readonly<Filter[]>> {
  onFilter: (criteria: Criteria[]) => void;
  criteria: Criteria[] | null;
  filters: T;
  title: string;
}

const defaultStyles = StyleSheet({
  container: {
    width: '400px'
  },
  form: {
    '& input': {
      '&:hover': {
        border: '0px'
      },
      '&:focus': {
        border: '0px'
      }
    }
  },
  icon: {
    display: 'flex',
    gap: 5,
    margin: 0
  }
});

export type FilterPopoutConfig = Filter[];

type Filter = {
  field: string;
  label: string;
  Input: React.ComponentType<any>;
  inputProps?: Record<string, any>;
  toCriteria?: (filter: Filter, value: any) => Criteria[];
  fromCriteria?: (filter: Filter, criteria: Criteria[]) => any;
};

const defaultToCriteria = (filter: Filter, value: any): Criteria[] => [
  { name: String(filter.field), type: '=', value }
];

const defaultFromCriteria = (filter: Filter, criteria: Criteria[]): any =>
  criteria.find((c) => c.name === filter.field)?.value;

function parseReadableValue(readableValue: any) {
  if (readableValue === true) return 'Yes';
  if (readableValue === false) return 'No';
  return upperFirst(String(readableValue));
}

export const filterPopoutFieldConfigs: Record<
  'text' | 'boolean' | 'select',
  (...args: any[]) => Omit<Filter, 'label' | 'field'>
> = {
  text: () => ({
    Input: TextInput,
    toCriteria: (filter, value) => [
      { name: filter.field, type: 'like', value: `%${value}%` }
    ],
    fromCriteria: (filter, criteria) =>
      criteria.find((c) => c.name === filter.field)?.value
  }),
  boolean: () => ({
    Input: Select,
    toCriteria: (filter, value) => {
      return [{ name: filter.field, type: '=', value: value === 'yes' }];
    },
    fromCriteria: (filter, criteria) =>
      criteria.find((c) => c.name === filter.field)?.value === true
        ? 'yes'
        : 'no',
    inputProps: {
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ]
    }
  }),
  select: (options: SelectOption[]) => ({
    Input: Select,
    toCriteria: (filter, value) => {
      return [{ name: filter.field, type: 'in', value: [value] }];
    },
    fromCriteria: (filter, criteria) =>
      criteria.find((c) => c.name === filter.field)?.value[0],
    inputProps: {
      options
    }
  })
} as const;

export function FilterPopout<T extends Filter[] | Readonly<Filter[]>>({
  onFilter,
  criteria,
  filters,
  title
}: FilterPopoutProps<T>) {
  type Values = Record<(typeof filters)[number]['field'], any>;

  const s = useStyles(defaultStyles);
  const [initialCriteria] = useState(criteria || []);

  const hasChanged = useMemo(() => {
    return criteria !== null && initialCriteria !== criteria;
  }, [initialCriteria, criteria]);

  const valuesFromCriteria = useMemo(() => {
    return filters.reduce((acc, filter) => {
      const fromCriteria = filter.fromCriteria || defaultFromCriteria;
      acc[filter.field] = fromCriteria(filter, criteria || []) || null;
      return acc;
    }, {} as any) as Values;
  }, [criteria, filters]);

  const filterFieldSummary = useMemo(() => {
    if (!hasChanged) return undefined;
    return Object.keys(valuesFromCriteria).map((field) => ({
      readableField: filters.find((f) => f.field === field)?.label as string,
      readableValue: parseReadableValue(valuesFromCriteria[field])
    }));
  }, [valuesFromCriteria, filters, hasChanged]);

  return (
    <Popout
      Content={({ close }) => {
        const handleSubmit = (values: Values) => {
          const criteriaValues = filters
            .map((f) => {
              const toCriteria = f.toCriteria || defaultToCriteria;
              return toCriteria(f, values[f.field]);
            })
            .flat();
          onFilter(criteriaValues);
          close();
        };

        return (
          <Box {...s('container')}>
            <ReactForms<Values, unknown>
              initialValues={valuesFromCriteria}
              handleSubmit={handleSubmit}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <PaddingBox {...s('form')}>
                    {filters.map(
                      ({ field, label, Input, inputProps = {} }, index) => (
                        <Box
                          key={String(field)}
                          width='calc(100% - 4px)'
                          mt={index === 0 ? 0 : 15}
                        >
                          <FormField
                            name={String(field)}
                            label={label}
                            Input={Input}
                            inputProps={inputProps}
                          />
                        </Box>
                      )
                    )}
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      marginTop={20}
                    >
                      <Fragment>
                        <TextButton
                          style={{
                            paddingLeft: 0
                          }}
                          blue
                          onClick={() => {
                            onFilter(initialCriteria);
                            close();
                          }}
                        >
                          Clear Filter
                        </TextButton>
                      </Fragment>

                      <ButtonBar isLoading={isSubmitting} hasPadding={false}>
                        <TextButton blue onClick={close}>
                          Cancel
                        </TextButton>
                        <DefaultButton blue onClick={submitForm}>
                          Apply Filter
                        </DefaultButton>
                      </ButtonBar>
                    </Box>
                  </PaddingBox>
                </Form>
              )}
            </ReactForms>
          </Box>
        );
      }}
      placement='bottom-start'
    >
      <FilterButton displayCriterias={filterFieldSummary} title={title} />
    </Popout>
  );
}
