import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import FramePairers from 'shared/utils/frame-pairers';
import { Autobind } from 'utils/decorators';
import { dialogEmitter, DIALOG_ACTIONS } from 'data/emitters/dialogs';
import invariant from 'invariant';
import { Portal } from '@rexlabs/portal';
import _ from 'lodash';
import { PressAndEscapePropagator } from 'utils/events';
import config from 'shared/utils/config';
import Analytics from 'shared/utils/vivid-analytics';

const defaultStyles = StyleSheet({
  container: {
    margin: 0,
    padding: 0,
    background: 'rgba(0,0,0,0.2)',
    opacity: 0
  },

  stretched: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1
  }
});

@styled(defaultStyles)
@Autobind
class ClassicDialogFrame extends PureComponent {
  static propTypes = {
    systemName: types.string,
    callName: types.string,
    args: types.any,
    onLoad: types.func
  };

  componentWillUnmount() {
    if (window.Rex2DialogFrameWindow) {
      window.Rex2DialogFrameWindow.dispatchEvent(new Event('teardown'));
      PressAndEscapePropagator.unregister(window.Rex2DialogFrameWindow);
      window.Rex2DialogFrameWindow = null;
    }
  }

  render() {
    const { styles: s, systemName } = this.props;
    const { protocol, host } = window.location;
    return (
      <Portal target='dialogOverlayStack'>
        <iframe
          {...s('container', 'stretched')}
          key={systemName}
          title={`Rex Dialog: ${systemName}`}
          frameBorder={0}
          width='100%'
          height='100%'
          src={`${protocol}//${host}/dialog/${systemName}?__releaseHash=${config.RELEASE?.HASH}`}
          ref={this.setRef}
          onLoad={this.handlePageLoad}
          id='ClassicDialogFrame'
        />
      </Portal>
    );
  }

  setRef(iframe) {
    if (!iframe) {
      return;
    }

    // Classic uses this to identify weather or not the source is loaded within
    // the shell dialog iframe, e.g. to adjust styles
    iframe.__IS_DIALOGFRAME = true;

    // Open the actual dialog in the iframe
    const { systemName, callName, args, onLoad, uuid } = this.props;

    const { contentWindow } = iframe;
    window.Rex2DialogFrameWindow = contentWindow;

    const openDialog = (iframe) => {
      iframe.style.opacity = 1;
      const dialog = _.get(contentWindow, `AVM.dialogs.${callName}`);
      invariant(
        dialog && dialog.open,
        `The dialog "${callName}" can't be opened from the dialog stack frame. ` +
          "It doesn't exist, or hasn't implemented the 'open' function."
      );

      if (_.isFunction(onLoad)) {
        onLoad();
      }

      // Open the actual dialog in the iframe.
      if (args) {
        dialog.open(...args);
      } else {
        dialog.open();
      }

      // Subscribe to the internal dialog stack in the iframe, to
      // automatically remove this Dialog (and any other dialogs it
      // opens).
      contentWindow.r2.ui.ModalInstance.eventer.subscribe('flushed', () => {
        // Wait for duration of dialog's fadeOut before closing
        setTimeout(() => {
          dialogEmitter.emit(DIALOG_ACTIONS.CLOSE, {
            uuid,
            systemName,
            callName,
            args
          });
        }, 150);
      });
    };

    FramePairers.prepareParentFrame(contentWindow, () => {
      openDialog(iframe);
    });
  }

  handlePageLoad(ev) {
    // TODO: find out if this is a real problem we need this protection for.
    //  it currently throws errors when displaying the export progress dialog from classic in shell.
    //  So here I've disabled the error throwing and am logging to bugsnag instead
    // invariant(
    //   ev.target.contentWindow === window.Rex2DialogFrameWindow,
    //   'The same window must be passed to register and unregister.'
    // );

    if (ev.target.contentWindow !== window.Rex2DialogFrameWindow) {
      Analytics.error({
        error: `The same window must be passed to register and unregister. Dialog: ${this.props.systemName}`,
        properties: {
          metaData: {
            dialog: this.props.systemName,
            caughtBy: 'components/dialog/frame'
          }
        }
      });
    }

    PressAndEscapePropagator.register(ev.target.contentWindow);
  }
}

export default ClassicDialogFrame;
