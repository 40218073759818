import React from 'react';
import Box from '@rexlabs/box';
import PaddingBox from 'view/components/padding-box';

interface RecordPreviewContentContainerProps {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}

export function RecordPreviewContentContainer({
  leftColumn,
  rightColumn
}: RecordPreviewContentContainerProps) {
  return (
    <PaddingBox white flexDirection='row'>
      <Box flexDirection='column' pr={10} width={110}>
        {leftColumn}
      </Box>
      {/* We have set widths here so that we don't have text overflowing */}
      <Box flexDirection='column' pl={10} width={230}>
        {rightColumn}
      </Box>
    </PaddingBox>
  );
}
