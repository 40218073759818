import React, { useCallback, useMemo } from 'react';
import { query, useEntityQuery, Id } from '@rexlabs/model-generator';

import listingsModel from 'data/models/entities/listings';
import { RecordDetailsScreen } from 'components/_experiment/record-details-screen';

import { ListingTitleBlock } from '../components/record-title-block';
import { listingsModes } from '../data/record-modes';
import { listingBlocks } from '../data/record-blocks';
import { useListingWidgets } from '../data/record-widgets';
import { listingStreams } from '../data/record-streams';
import { listingDetailsActions } from '../data/record-details-actions';

const getListingQuery = (id) => query`{
  ${listingsModel} (id: ${id}, extra_fields: ${['_security_user_rights']}) {
    id
    related {
      listing_documents
      listing_reln_contact
      listing_tags
    }
  }
}`;

interface ListingDetailsScreenProps {
  id: Id;
}

export function ListingDetailsScreen({ id }: ListingDetailsScreenProps) {
  const listingWidgets = useListingWidgets();

  const listingQuery = useMemo(() => getListingQuery(id), [id]);
  const { data, status, actions } = useEntityQuery(listingQuery);

  const handleSubmit = useCallback(
    ({ changedValues }) =>
      actions.updateItem({ id: id.toString(), data: changedValues }),
    [actions, id]
  );

  return (
    <RecordDetailsScreen
      serviceName='Listings'
      id={id}
      data={data}
      status={status}
      modes={listingsModes}
      actions={listingDetailsActions}
      blocks={listingBlocks}
      widgets={listingWidgets}
      streams={listingStreams}
      TitleBlock={ListingTitleBlock}
      handleSubmit={handleSubmit}
    />
  );
}
