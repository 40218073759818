/**
 * PLEASE NOTE
 * Here we've taken the luna tooltip component as it was at 2.2.6-rc.8x6, copied and modified it to suit our purposes.
 * The reasons for the duplication are as follows:
 * 1. the rex use cases are quite specific and not something we would necessarily want to work into the luna codebase
 * 2. even if we did, we're on an old version of luna and would have to create a forked version in order to make the
 *     necessary changes.
 * 3. the rex use cases are temporary. when we have migrated away from classic these are no longer needed
 *
 * The goal will be to migrate everything over to react, and once complete we can kill this file and (hopefully) use the
 * luna components directly.
 *
 * Please compare this file with ./vivid-tooltip-old.md to see what's been changed.
 *
 * Note also that the luna components have been split into tooltip and popout as 2 separate components. This component
 * is quite complex because it has to support both use cases so we should look to split them
 */
import React, { Component, PureComponent } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import Tether, { PLACEMENTS } from '@rexlabs/tether';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';
import _ from 'lodash';
import { withState } from 'recompose';

import Arrow from '@rexlabs/tooltip/module/arrow';

@styled(
  StyleSheet({
    hoverContainer: { display: 'inline-block', width: 'fit-content' }
  })
)
@autobind
class HoverTarget extends PureComponent {
  render() {
    const { styles: s, ...rest } = this.props;
    return <div {...s('hoverContainer')} {...rest} />;
  }
}

const enterAnimation = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
});

const exitAnimation = keyframes({
  '0%': {
    opacity: 1
  },
  '100%': {
    opacity: 0
  }
});

const tetherStyles = StyleSheet({
  opening: {
    animation: `${enterAnimation} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
  },
  closing: {
    animation: `${exitAnimation} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
  },

  content: {
    transition: 'none',
    transformOrigin: 'center'
  },

  contentTop: {
    transform: 'translateY(-.8rem)'
  },

  contentBottom: {
    transform: 'translateY(.8rem)'
  },

  contentLeft: {
    transform: 'translateX(-.8rem)'
  },

  contentRight: {
    transform: 'translateX(.8rem)'
  }
});

const defaultStyles = StyleSheet({
  tooltip: {
    display: 'block',
    padding: '1rem',
    minWidth: '10rem',
    maxWidth: '30rem',
    boxSizing: 'border-box',
    margin: 0,
    background: 'rgba(0, 0, 0, .9)',
    boxShadow: '0px 0px 21px rgba(0, 0, 0, .3)',
    position: 'relative',
    zIndex: 3,
    color: 'white',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 600
  },

  hoverable: {
    cursor: 'pointer',
    position: 'relative',
    display: 'inline-block',

    '&:active:focus': {
      outline: 'none'
    }
  },

  overlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 2,
    left: 0,
    top: 0
  },
  container: {
    display: 'inline-block'
  }
});

@styled(defaultStyles)
@autobind
class Tooltip extends Component {
  /** Enum values for the `placement` prop. */
  static PLACEMENTS = PLACEMENTS;

  _refs = {};
  state = {
    isClosing: false
  };

  static propTypes = {
    /**
     * Content to render inside the tooltip.
     */
    content: types.node,
    /**
     * Tether placement.
     */
    placement: types.oneOf(Object.values(PLACEMENTS)),
    /**
     * Mouse out delay in ms.
     * Increase to keep the tooltip open for longer when mouse has left.
     */
    hoverTimeout: types.number,
    /**
     * Offset arrow to move it closer towards the middle of the tooltip. Any CSS unit.
     */
    arrowMargin: types.string,
    /**
     * Tooltip distance from button.
     */
    distance: types.string,
    /**
     * Offset the tooltip on its primary axis
     */
    offset: types.string,
    /**
     * Where or not to automatically reposition
     */
    autoFlip: types.bool,
    /**
     * Whether or not the Tether is about to close. Allows you to hook into
     * Tether to display closing animations.
     */
    isClosing: types.bool,
    /**
     * Function that will be run when the tooltip open/closed/closing state
     * changes. Receives an object with `isOpen` and `isClosing` properties.
     */
    onChange: types.func
  };

  static defaultProps = {
    arrowMargin: '1.4rem',
    distance: '20px',
    offset: '0px',
    hoverTimeout: 250,
    closeDuration: 250,
    tetherStyles,
    Arrow,
    onChange: _.noop
  };

  constructor() {
    super();
    this.interactionSuspended = false;
    this.tooltipRef = React.createRef();
  }

  closeTooltip() {
    const { setOpen, closeDuration, onChange } = this.props;
    if (!this.state.isClosing || this.props.isOpen) {
      const setClosingToFalse = () => {
        setOpen(false);
        onChange({ isOpen: false, isClosing: false });
        this.setState(() => ({ isClosing: false }));
      };

      clearTimeout(this.closeTimer);
      if (closeDuration > 0) {
        this.setState(() => ({ isClosing: true }));
        onChange({ isOpen: false, isClosing: true });
        this.closeTimer = setTimeout(setClosingToFalse, closeDuration);
      } else {
        setClosingToFalse();
      }
    }
  }

  openTooltip() {
    clearTimeout(this.closeTimer);
    this.setState(
      () => ({ isClosing: false }),
      () => {
        this.interactionSuspended = true;
        this.suspendedTimer = setTimeout(() => {
          this.interactionSuspended = false;
        }, 500);
        this.props.setOpen(true);
        this.props.onChange({ isOpen: true, isClosing: false });
      }
    );
  }

  onMouseEnter() {
    // Bail on closing the tooltip from hovering out,
    // if we can.
    clearTimeout(this.mouseOutTimer);

    this.openTooltip();
  }

  onMouseLeave() {
    clearTimeout(this.mouseOutTimer);
    this.mouseOutTimer = setTimeout(this.closeTooltip, this.props.hoverTimeout);
  }

  renderContent({ placement }) {
    const { Content, styles: s } = this.props;
    return (
      <div ref={this.tooltipRef} onMouseLeave={this.onMouseLeave}>
        <div {...s('tooltip')} onMouseLeave={this.onMouseLeave}>
          {_.isString(Content) ? (
            <Content onMouseLeave={this.onMouseLeave} />
          ) : (
            <Content onMouseLeave={this.onMouseLeave} placement={placement} />
          )}
        </div>
      </div>
    );
  }

  renderChild({ ref }) {
    const { children, styles: s, isOpen } = this.props;
    const onlyChild = React.Children.only(children);
    return (
      <div
        ref={ref}
        tabIndex={0}
        {...s('hoverable')}
        style={{
          zIndex: isOpen ? 3 : 0
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {onlyChild}
      </div>
    );
  }

  render() {
    const {
      styles: s,
      style,
      className,
      children,
      openOn,
      closeOn,
      arrowMargin,
      placement,
      offset,
      autoFlip,
      Arrow,
      Content,
      tetherStyles,
      isOpen,
      hoverTimeout,
      distance,
      ...rest
    } = this.props;

    const { isClosing } = this.state;

    return (
      <HoverTarget onMouseLeave={this.onMouseLeave}>
        {isOpen ? (
          <Tether
            {...rest}
            offset={offset}
            styles={tetherStyles}
            arrowMargin={arrowMargin}
            distance={distance}
            placement={placement}
            Content={this.renderContent}
            autoFlip={autoFlip}
            Arrow={Arrow}
            isClosing={isClosing}
            openOn='HOVER'
            closeOn='HOVER'
            onMouseLeave={this.onMouseLeave}
          >
            {this.renderChild}
          </Tether>
        ) : (
          this.renderChild({})
        )}
      </HoverTarget>
    );
  }
}

export default Tooltip;
const TooltipStateful = withState('isOpen', 'setOpen', false)(Tooltip);
export { TooltipStateful };
