import React, { ComponentType, ReactNode } from 'react';
import Box from '@rexlabs/box';

import { COLORS, PADDINGS } from 'theme';

import { NormalTag } from 'components/tags/normal-tag';
import { WarningTag } from 'components/tags/warning-tag';
import { DarkTag } from 'components/tags/dark-tag';

import { DefaultCell, DefaultCellProps } from './default';

const tagComponents = {
  normal: NormalTag,
  warning: WarningTag,
  dark: DarkTag
} as const;

interface TagProps {
  onClick?: (e?: MouseEvent) => void;
  children: ReactNode;
}
// Note - choose one of style or Component (preferably stick with style,
// Component is an escape hatch for when the designers really really need something custom)
interface TagConfigShape {
  style?: keyof typeof tagComponents;
  color?: string;
  backgroundColor?: string;
  Component?: ComponentType<TagProps>;
  text: string;
  onClick?: (e?: MouseEvent) => void;
}

export type TagCellConfig = TagConfigShape[];
interface TagCellProps extends Omit<DefaultCellProps, 'value'> {
  value?: TagCellConfig;
}

interface TextTagCellProps extends DefaultCellProps {
  tag?: TagConfigShape;
}

function TagContent({
  style = 'normal',
  Component,
  text,
  ...otherTagProps
}: TagConfigShape) {
  const TagComponent = Component || tagComponents[style];

  return (
    <TagComponent {...otherTagProps}>
      <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
    </TagComponent>
  );
}

export function TextTagCell({ value, tag, ...props }: TextTagCellProps) {
  return (
    <DefaultCell
      value={
        <Box flexDirection='row' alignItems='center'>
          <span>{value}</span>
          {tag && (
            <Box ml={PADDINGS.XS}>
              <TagContent
                {...tag}
                color={COLORS.DARK_GREY}
                backgroundColor={COLORS.BANNER.GREY}
              />
            </Box>
          )}
        </Box>
      }
      {...props}
    />
  );
}

export function TagCell({ value, ...props }: TagCellProps) {
  return (
    <DefaultCell
      value={
        value &&
        value.length && (
          <Box sx={PADDINGS.XXS} flexDirection='row'>
            {value.map((tagConfig) => (
              <TagContent {...tagConfig} key={tagConfig.text} />
            ))}
          </Box>
        )
      }
      {...props}
    />
  );
}
