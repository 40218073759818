import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import EntityOption from 'view/components/input/select/options/entity';
import { withRegion } from 'src/hocs/with-region';
import _ from 'lodash';
import dayjs from 'dayjs';

@withRegion
@autobind
class AppraisalsOption extends Component {
  getOption() {
    const { option, region } = this.props;

    /**
     * If the option is a fixture we don't need to format it, so we just return
     * the option as is.
     */
    if (_.get(option, 'isFixture')) {
      return option;
    }

    const type = region.isEU ? 'Valuation' : 'Appraisal';
    const status = _.startCase(_.get(option, 'data.appraisal_state'));

    let date = _.get(option, 'data.appraisal_date');

    if (status === 'Archived') {
      date = _.get(option, 'data.archived_date');
    }

    /**
     * Fallback to system created time encase either appraisal or archived dates are null.
     */
    let formattedDate = dayjs
      .unix(_.get(option, 'data.system_ctime'))
      .format('D MMM YYYY');

    if (date) {
      formattedDate = dayjs(date).format('D MMM YYYY');
    }

    const label = `${status} ${type} from ${formattedDate}`;

    return {
      ...option,
      label
    };
  }

  pluckStatus(option) {
    return _.toUpper(_.get(option, 'data.interest_level', 'N/A') || 'N/A');
  }

  render() {
    return (
      <EntityOption
        {...this.props}
        option={this.getOption()}
        pluckStatus={this.pluckStatus}
      />
    );
  }
}

export default AppraisalsOption;
