import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import { Id, query, useEntityListQuery } from '@rexlabs/model-generator';

import campaignMembers from 'data/models/entities/campaign-members';

import { PADDINGS } from 'theme';

import PaddingBox from 'view/components/padding-box';

import { Placeholder } from 'components/placeholder';
import { Body } from 'components/text/body';

import { StatusTotal } from '../components/status-total';

const getCampaignMembersQuery = (id: Id) => query`{
  ${campaignMembers} (criteria: ${[
  { name: 'campaign.id', type: 'in', value: [id] }
]}id: ${id} limit: 100){
    campaign
    last_activity
    service_record
    service_type
    status
    system_created_user
    system_modified_user
    system_modtime
  }
}`;

export const recordsBlock = {
  id: 'records',
  View: ({ data }) => {
    const campaignMembersQuery = useMemo(
      () => getCampaignMembersQuery(data.id),
      [data.id]
    );

    const campaignMembersEntityList = useEntityListQuery(
      campaignMembersQuery
    ) as any;

    const campaignStatuses = data?.related?.campaign_member_statuses as any[];
    const campaignMembers = campaignMembersEntityList.data as any[];

    const isLoading = campaignMembersEntityList.status !== 'loaded';

    return (
      !isLoading && (
        <>
          {campaignStatuses && campaignMembers && (
            <PaddingBox light p={0}>
              {campaignMembers.length === 0 ? (
                <Body normal regular medium dark>
                  No records added - get started by adding records to this
                  campaign
                </Body>
              ) : (
                <Box flexDirection='row'>
                  <Box pr={PADDINGS.M}>
                    <StatusTotal
                      total={campaignMembers.length}
                      status='Records'
                    />
                  </Box>
                  {campaignStatuses.map((status) => (
                    <Box pr={PADDINGS.M} key={status.id}>
                      <StatusTotal
                        total={
                          campaignMembers.filter(
                            (member) => member.status.id === status.id
                          ).length
                        }
                        status={status.label}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </PaddingBox>
          )}
          <Placeholder data={data} />;
        </>
      )
    );
  }
};
