import { ListingsRecordScreen } from '../screens/listing-record-screen';

export const listingsRoutes = {
  LISTINGS_RECORD: {
    config: {
      path: '/experiment/listings/',
      Component: ListingsRecordScreen
    }
  }
};
