import React from 'react';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { Popout } from 'view/components/popout';
import { Form, FormField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import { SelectItem } from 'types/select';
import Box from '@rexlabs/box';
import ApplyCancelButtonBar from 'shared/components/button-bar/apply-cancel';
import { Select } from 'shared/components/input/select';
import { ICONS } from 'shared/components/icon';
import { ReportingFilterButton } from 'components/button/reporting-filter-button';
import { useWhichWordFactory } from 'hooks/use-which-word';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';

interface OrderByDropdownProps {
  onChange: (val: OrderBy) => void;
  value: OrderBy | null;
}

export type OrderBy = Record<string, 'asc' | 'desc'>;

function getOrderByField(orderBy: OrderBy | null) {
  return orderBy
    ? Object.keys(orderBy)[0]
    : 'property.system_owner_last_contacted_date';
}

function getOrderByDirection(orderBy: OrderBy | null) {
  return orderBy ? Object.values(orderBy)[0] : 'asc';
}

function OrderByDropdown({ onChange, value }: OrderByDropdownProps) {
  const ww = useWhichWordFactory();

  function getTextFromOrderBy(orderBy: OrderBy | null, options: SelectItem[]) {
    const option = options.find((o) => o.value === getOrderByField(orderBy));
    const direction = getOrderByDirection(orderBy);
    return `Sort: ${
      option?.label?.toLowerCase() || `${ww('valuation').toLowerCase()} date`
    }, ${direction}`;
  }

  async function handleSave(values: { field: string; direction: string }) {
    Analytics.track({
      event: EVENTS.PIPELINES.ORDER_BY_CHANGED,
      properties: {
        orderByField: values.field,
        orderByDirection: values.direction
      }
    });
    onChange({ [values.field]: values.direction } as OrderBy);
  }

  const options: SelectItem[] = [
    {
      value: 'property.system_owner_last_contacted_date',
      label: 'Owner last contacted date'
    },
    { value: 'appraisal_date', label: `${ww('valuation')} date` },
    { value: 'expected_close_date', label: 'Expected close date' },
    { value: 'system_ctime', label: 'Created date' },
    { value: 'system_modtime', label: 'Modified date' }
  ];

  return (
    <Popout
      placement={PLACEMENTS.BOTTOM_END}
      distance={'3px'}
      Content={({ close }) => (
        <PaddingBox width='380px'>
          <ReactForms
            handleSubmit={async (values) => {
              await handleSave(values);
              close();
            }}
            initialValues={{
              field: getOrderByField(value),
              direction: getOrderByDirection(value)
            }}
          >
            {({ submitForm, isSubmitting }) => {
              return (
                <Form>
                  <Box
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-end'}
                    style={{ gap: '10px' }}
                  >
                    <Box flex={2}>
                      <FormField
                        name='field'
                        label={'sort by'}
                        Input={Select}
                        inputProps={{
                          options: options
                        }}
                      />
                    </Box>
                    <Box flex={1}>
                      <FormField
                        name='direction'
                        Input={Select}
                        inputProps={{
                          options: [
                            { value: 'asc', label: 'Ascending' },
                            { value: 'desc', label: 'Descending' }
                          ]
                        }}
                      />
                    </Box>
                  </Box>
                  <ApplyCancelButtonBar
                    isLoading={isSubmitting}
                    onCancel={() => close()}
                    onSave={submitForm}
                    isDisabled={false}
                  />
                </Form>
              );
            }}
          </ReactForms>
        </PaddingBox>
      )}
    >
      <div data-intercom-target='Appraisals Pipeline Sort By'>
        <ReportingFilterButton Icon={ICONS.DASHBOARDS.FILTER} showArrowIcon>
          {getTextFromOrderBy(value, options)}
        </ReportingFilterButton>
      </div>
    </Popout>
  );
}

export { OrderByDropdown };
