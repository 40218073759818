import { useCallback } from 'react';

import { useDialog } from 'hooks/use-dialog';
import { useGetViewstateIdForBulkActions } from 'components/record-list-screen/utils/mass-action-utils';

export function useArchiveLeadsBatchAction({ service_name }) {
  const bulkArchiveLeads = useDialog('bulkArchiveLeads');

  const getViewstateId = useGetViewstateIdForBulkActions({ service_name });

  return useCallback(
    ({ selectionCriteria, afterMassAction }) => {
      getViewstateId({ selectionCriteria }).then(({ viewstate_id, total }) => {
        const apiData = {
          service_name,
          viewstate_id,
          new_state: 'archived'
        };

        bulkArchiveLeads.open({
          total,
          apiData,
          afterMassArchiveAction: afterMassAction
        });
      });
    },
    [bulkArchiveLeads, getViewstateId, service_name]
  );
}
