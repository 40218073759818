import { ColumnState } from 'ag-grid-community/dist/lib/columns/columnModel';
import {
  differenceBy,
  find,
  findIndex,
  first,
  flatten,
  get,
  isObject,
  last,
  unionBy
} from 'lodash';

import { ReportColumn } from 'features/custom-reporting/modules/module-config-types';
import { UserSelectedReportState } from 'features/custom-reporting/hooks/use-user-selected-report-state';
import { flattenColumns } from '../modules/helpers';
import { ColDef } from 'ag-grid-community';

interface ColumnsStateWithParentId extends ColumnState {
  parentId?: string;
}

function getDefaultColumnValues(
  column: ColumnState | ColumnsStateWithParentId
): ColumnState {
  const {
    aggFunc = null,
    colId,
    flex = null,
    hide = null,
    pinned = null,
    pivot = false,
    pivotIndex = null,
    rowGroup = false,
    rowGroupIndex = null,
    sort = null,
    sortIndex = null,
    width = 200
  } = column;
  return {
    aggFunc,
    colId,
    flex,
    hide,
    pinned,
    pivot,
    pivotIndex,
    rowGroup,
    rowGroupIndex,
    sort,
    sortIndex,
    width
  };
}

function hasColumnsChanged(
  allColumns: ReportColumn[],
  selectedReportColumns: ColumnState[] = []
) {
  const allColumnsFlattened = flattenColumns(allColumns).filter(
    (selectedColumn) => !!get(selectedColumn, 'colId')
  );

  return (
    !!differenceBy(allColumnsFlattened, selectedReportColumns, 'colId')
      .length ||
    !!differenceBy(selectedReportColumns, allColumnsFlattened, 'colId').length
  );
}

/**
 * PLEASE READ: The purpose of this function is to ensure that as we add/remove fields for the various
 * report types, we don't show that the users saved reports have been modified. How we show the user
 * that their report is modified is:
 *
 *   - we store the selected report in the state of use-user-selected-report-state
 *   - we then have a duplicate state, and when the user modifies the report, we set the state of the duplicate state
 *   - we then comapre the two report states, and if they are different, we show the user that the report has been modified
 *
 * But, if we add a new report type, or add some more fields to an existing report type, this breaks the above logic. * We end up with a selected report state that immediately doesn't match the modified state, as it gets populated with
 * all the new columns.
 *
 * When we first noticed this, we were putting the new columns at the end of the existing column array, but this
 * caused multiple column groups to appear which wasn't really ideal. What we achieve below is as close as we can get
 * to not modifying the exisitng reports
 */
export function sanitizeIncomingReportState(
  allColumns: ReportColumn[],
  reportDetails: UserSelectedReportState
) {
  const allColumnsFlattened = flattenColumns(allColumns).filter(
    (selectedColumn) => !!get(selectedColumn, 'colId')
  );

  const selectedReportColumns = reportDetails.selectedGridColumns || [];

  // If the selected report includes all of columns, we don't need to do anything
  if (!hasColumnsChanged(allColumns, reportDetails.selectedGridColumns)) {
    return reportDetails;
  }

  // This is pretty complicated, and I would only look at fixing this if you realy have to...
  // I've left as many comments as I could to explain what's going on.
  const selectedReportColumnsGrouped = selectedReportColumns
    // Here we're getting all the columns of the selected report and creating a new array
    // with the parentId added to each column. If the parentId is null, we're skipping it.
    .reduce((columns: ColumnsStateWithParentId[], column: ColumnState) => {
      const parentGroup = allColumns.find((group, index) =>
        (group.children || []).find(
          (groupColumn) => get(groupColumn, 'colId') === column.colId
        )
      );

      if (!parentGroup) {
        return [...columns];
      }

      return [...columns, { ...column, parentId: parentGroup?.headerName }];
    }, [])
    // Next we're grouping the columns into arrays by their parent group. The reason we're not
    // using something like lodash group, which creates an object of arrays, grouped by the parent
    // group, is beacuse the parent groups can be split up into multiple groups. Doing it like below
    // retains the ordering.
    .reduce(
      (
        colGroups: ColumnsStateWithParentId[][],
        column: ColumnsStateWithParentId
      ) => {
        const currentColumnParentId = column.parentId;

        // If the parentId is null, we're skipping it. This filters out any columns that no longer exist.
        if (!currentColumnParentId) {
          return [...colGroups];
        }

        const currentColGroupLength = colGroups.length;

        // Next we need to create the initial group array on our first pass.
        if (!currentColGroupLength) {
          return [...colGroups, [column]];
        }

        const currentColGroup = last(colGroups) || [];
        const previouColumnParentId = first(currentColGroup)?.parentId;

        // Then if the current column's parentId is the same as the columns in the current column group,
        // we add it to the current column group
        if (
          currentColGroupLength &&
          previouColumnParentId === currentColumnParentId
        ) {
          const newArr = [...colGroups[currentColGroupLength - 1], column];
          const newParentArr = colGroups.slice(0, currentColGroupLength - 1);

          return [...newParentArr, newArr];
        }

        // Otherwise, we create a new column group and add the current column
        return [...colGroups, [column]];
      },
      []
    )
    // Next we go through each column and determine if we want to merge the missing columns from
    // the allColumns. How we determine this is:
    // - If there is only one instance of the matching column group we merge the data
    // - If the current column group is the largest group of columns for that parent id type &
    //   the columns haven't already been merged (for the case where multiple groups have the same length)
    .reduce(
      (
        reducedColumnGroups: (ColDef | ColumnsStateWithParentId)[][],
        columnGroup: ColumnsStateWithParentId[],
        index: number,
        columnGroups: ColumnsStateWithParentId[][]
      ) => {
        // This is the the matching group from the allColumns array based on the parentId
        const matchingAllColumnsGroup =
          allColumns.find(
            (group) => group.headerName === first(columnGroup)?.parentId
          )?.children || [];

        // Getting all the column groups that have the same parentId as the current column group
        const allColumnGroupsWithSameParent = columnGroups.filter(
          (group) => first(group)?.parentId === first(columnGroup)?.parentId
        );

        // Getting all the column groups that have the same parentId as the current column group,
        // that have all ready been reduced
        const allColumnGroupsReducedWithSameParent = reducedColumnGroups.filter(
          (group: ColumnsStateWithParentId[]) =>
            first(group)?.parentId === first(columnGroup)?.parentId
        );

        // work out if the current column group is the largest group of columns for that parent id type.
        // Note - we may havea a case of multiple groups with the same length, so we'll merge the
        // first one in that case. We'll combine this check with the check below to determine if we
        // need to merge the columns
        const isLargestColumnGroup = allColumnGroupsWithSameParent.reduce(
          (isLargest, otherColumnGroup) => {
            return isLargest && columnGroup.length >= otherColumnGroup.length;
          },
          true
        );

        // Check to see if we we have already merged the columns for this parent id
        const isAlreadyMerged = !differenceBy(
          matchingAllColumnsGroup,
          flatten(allColumnGroupsReducedWithSameParent),
          'colId'
        ).length;

        // With all of that, we can now determin if we want to merge the missing columns by:
        // - If there is only one instance of the matching column group
        // - If the current column group is the largest group of columns for that parent id type &
        //   the columns haven't already been merged (for the case where multiple groups have the same length)
        const shouldMergeGroup =
          allColumnGroupsWithSameParent.length === 1 ||
          (isLargestColumnGroup && !isAlreadyMerged);

        if (shouldMergeGroup) {
          // now that we're merging, we only want to merge the content that is doesn't already exist
          const differentColumnsForParentId = differenceBy(
            matchingAllColumnsGroup,
            flatten(allColumnGroupsWithSameParent),
            'colId'
          );
          return [
            ...reducedColumnGroups,
            [...columnGroup, ...differentColumnsForParentId]
          ];
        }
        return [...reducedColumnGroups, [...columnGroup]];
      },
      []
    );

  // Now that we've merged all of the missing columns into the selected report columns for current
  // types, we then flatten the array groups into a sinlge array, then do another merge. This is to
  // make sure that we add fields for new types that have been added - for example, project stages
  // and projects, which were added after we had the listings.
  // Once we have all those, we map over all the columns and reset the data to the default key values
  // that we use for the grid.
  const selectedReportWithMissingFields = unionBy(
    flatten(selectedReportColumnsGrouped),
    allColumnsFlattened,
    'colId'
  ).map(getDefaultColumnValues);

  // Wait... just when you thought we had finished, we need to do one last thing...
  // If the selected report has any sort of grouping, there is a phantom column that we need to add
  // back into the columns array.
  // For this we look to see if that column existed in the original selected report columns, and grab
  // it's index, and the data.
  const autoColumn = {
    index: findIndex(
      reportDetails.selectedGridColumns,
      (column) => column.colId === 'ag-Grid-AutoColumn'
    ),
    column:
      find(
        reportDetails.selectedGridColumns,
        (column) => column.colId === 'ag-Grid-AutoColumn'
      ) || null
  };

  // If that column exists, we add it back into the columns array. While it will usually be the first
  // column, we need to make sure we pop it back in at the same place it was in the original array.
  // So we look in the original array for the index of the column before it, then find the index of
  // that column in the new array. Then we put the autoColumn in the new array after that index...
  if (isObject(autoColumn.column)) {
    const colIdOfItemBeforeAutoGrid =
      reportDetails?.selectedGridColumns?.[autoColumn.index - 1]?.colId;

    const indexOfItemBeforeAutoGrid = findIndex(
      selectedReportWithMissingFields,
      (column) => get(column, 'colId') === colIdOfItemBeforeAutoGrid
    );

    selectedReportWithMissingFields.splice(
      indexOfItemBeforeAutoGrid + 1,
      0,
      autoColumn.column
    );
  }

  return {
    ...reportDetails,
    selectedGridColumns: selectedReportWithMissingFields
  };
}
