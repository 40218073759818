import { api } from 'shared/utils/api-client';

// TODO: when we have a hook for value lists we should convert the fields files to hooks
//       and use the value list hook with actual modelgenerator models.
//       JIRA: https://rexsoftware.atlassian.net/browse/RADI-6080
export function getAsyncFilterValues({ listName }) {
  return ({ success }) => {
    api
      .post('SystemValues::getCategoryValues', {
        list_name: listName
      })
      .then((response) => [
        ...(response?.data?.result || []).map((category) => category.text),
        'N/A'
      ])
      .then(success);
  };
}
