import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import React, { ComponentProps } from 'react';
import { COLORS, TEXTS } from 'theme';
import { Select } from 'view/components/input/select';

const inputStyleOverrides = {
  TextInput: StyleSheet({
    cosmeticWrapper: {
      maxWidth: '100%'
    },

    input: {
      ...TEXTS.CONTENT.INPUT_TEXT_REGULAR
    },

    cosmetic: {
      zIndex: 2,
      padding: '0',
      overflow: 'visible',
      maxWidth: '100%'
    },

    container: {
      paddingLeft: '6px !important',
      height: '34px !important',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px',
      border: 'none !important',
      outline: 'none !important',
      boxShadow: 'none !important'
    }
  }),

  SelectValue: StyleSheet({
    container: {
      paddingLeft: '6px'
    }
  }),
  SelectInput: StyleSheet({
    indicators: {
      height: '23px'
    }
  }),
  SelectMenu: StyleSheet({
    container: {
      border: 'none !important',
      boxShadow: '0px 1.2px 29.92px 0px rgba(69, 42, 124, 0.1) !important',
      width: 'calc(100% - 8px)',
      padding: '5px 0px',
      borderRadius: '6px',
      marginTop: '-4px',
      marginLeft: '4px',
      overflow: 'hidden',
      '&::before': {
        content: '"Suggestions from Rex AI"',
        fontSize: '12px',
        fontWeight: 600,
        color: COLORS.DARK_GREY,
        padding: '10px'
      }
    }
  })
};

export function AiSelectInput(props: ComponentProps<typeof Select>) {
  return (
    <StylesProvider
      components={inputStyleOverrides}
      prioritiseParentStyles={false}
    >
      <Select {...props} />
    </StylesProvider>
  );
}
