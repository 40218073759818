import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';
import { api } from 'shared/utils/api-client';

export const keyLocationFields: ReportColumnConfig = [
  {
    headerName: 'Key',
    field: 'label'
  },
  {
    headerName: 'Key type',
    field: 'type',
    valueGetter: ({ data }) => {
      // This column specifies no column type and so will have the default column type.
      // This means that the data will pass through the default valueFormatter
      // and the empty state will correctly be displayed as '-'
      if (!data) {
        return '';
      }
      if (data.listing?.id) {
        return 'Listing';
      }
      return 'Property';
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Listing', 'Property']
    }
  },
  {
    headerName: 'Current location',
    field: 'location.name',
    type: 'valueListColumn',
    subresource: 'key_location',
    filterParams: {
      values: ({ success }) => {
        api
          .post('AdminKeyLocations::search')
          .then((response) => [
            ...(response?.data?.result?.rows || []).map(({ name }) => name),
            'N/A'
          ])
          .then(success);
      }
    }
  }
];
