import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontFamily: FONT.FAMILY.PROXIMA_NOVA,
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: COLORS.WHITE
  }
});

export function ExternalLinkScreenLayout({
  children
}: {
  children: ReactNode;
}) {
  const s = useStyles(defaultStyles);
  return (
    <Box
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100%'
      {...s('container')}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        width='450px'
        height='450px'
      >
        {children}
      </Box>
    </Box>
  );
}
