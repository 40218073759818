import React, { PureComponent, Fragment } from 'react';
import TabDetails from './tab-details';
import { Body } from 'components/text/body';
import { SubHeading } from 'components/text/sub-heading';
import { TabsStatefulDeprecated } from 'view/components/tabs';
import { Dropdown } from 'view/components/action-menu';
import SaveCancelButtonBar from 'view/components/button-bar/save-cancel';
import Dialog from 'view/components/dialog';
import PaddingBox from 'view/components/padding-box';
import Box from '@rexlabs/box';
import {
  withValueLists,
  withQuery,
  query,
  withModel
} from '@rexlabs/model-generator';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { withForm, Form } from 'view/components/form';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import dayjs from 'dayjs';
import { withDialog } from 'shared/hocs/with-dialog';
import { withRegion } from 'src/hocs/with-region';
import { connect } from 'react-redux';

import TabSubstantiation from './tab-substantiation';
import substantiationsForm from './substantiations-form';

import substantiationStatusModel from 'data/models/system-lists/substantiation-status';
import substantiationPositionModel from 'data/models/system-lists/substantiation-position';
import substantiationFundSourceModel from 'data/models/system-lists/substantiation-fund-source';
import substantiationMethodModel from 'data/models/system-lists/substantiation-method';
import substantiationsModel from 'data/models/entities/substantiations';
import accountUsersModel from 'data/models/value-lists/account-users';
import accountSettingsComplianceModel from 'data/models/custom/account-settings-compliance';

import MetricHistoryDialog from 'view/dialogs/admin/metric-write-rules/metric-history';
import WarningBox from 'view/components/warning-box';

const substantiationsQuery = query`{
  ${substantiationsModel} (id: ${(p) => p.id}) {
    id
    amount
    date_of
    expiry_date
    note
    status
    position
    method
    source_of_funds_deposit
    source_of_funds_balance
    substantiated_by_user
    related
    system_record_state
  }
 }`;

@withQuery(substantiationsQuery)
@withValueLists(
  substantiationStatusModel,
  substantiationPositionModel,
  substantiationFundSourceModel,
  substantiationMethodModel,
  accountUsersModel
)
class Core extends PureComponent {
  render() {
    const { onLoad, closeDialog, valueLists, id, substantiations } = this.props;

    // Wait for all value lists and substantiation (if we have an id) to be loaded
    // before showing the dialog
    const isLoading =
      valueLists.substantiationStatus.status === 'loading' ||
      valueLists.substantiationPosition.status === 'loading' ||
      valueLists.substantiationFundSource.status === 'loading' ||
      valueLists.substantiationMethod.status === 'loading' ||
      valueLists.accountUsers.status === 'loading' ||
      (id && substantiations.item.status === 'loading');

    return (
      <Dialog
        title='Substantiation'
        width={500}
        height={900}
        closeDialog={closeDialog}
        onLoad={onLoad}
        isLoading={isLoading}
        hasPadding={false}
      >
        <EditSubstantiation {...this.props} />
      </Dialog>
    );
  }
}

const formErrorStyles = StyleSheet({
  container: {
    paddingTop: '0'
  }
});

const tabStyles = {
  TabBar: StyleSheet({ list: { padding: '20px 20px 0' } })
};

@withDialog(MetricHistoryDialog, { propName: 'metricHistory' })
@withModel(accountSettingsComplianceModel)
@withRegion
@withDialog(Core, { propName: 'newDialog' })
@withErrorDialog
@connect((state) => ({ session: state.session }))
@withForm(substantiationsForm)
@autobind
class EditSubstantiation extends PureComponent {
  state = {
    system_record_state: _.get(
      this.props,
      'substantiations.item.data.system_record_state',
      'active'
    )
  };

  componentDidMount() {
    const { accountSettingsCompliance, errorDialog } = this.props;

    accountSettingsCompliance
      .getEffectiveSetting({ key: 'substantiation_workflow_id' })
      .then(({ data }) => {
        this.setState({
          workflowId: _.get(data, 'result', null)
        });
      })
      .catch(errorDialog.open);
  }

  openAddDialog() {
    const { newDialog, listDialog, contacts } = this.props;

    const propContacts =
      contacts ||
      _.get(
        this.props,
        'substantiations.item.data.related.substantiation_contacts',
        []
      );

    newDialog.open({
      contacts: propContacts,
      listDialog
    });
  }

  actionItems() {
    const {
      substantiations,
      id,
      substantiationCount,
      listDialog,
      metricHistory,
      region: { isEU }
    } = this.props;
    const isActive =
      _.get(substantiations, 'item.data.system_record_state', 'active') ===
      'active';
    const contacts = _.get(
      substantiations,
      'item.data.related.substantiation_contacts',
      []
    );

    const items = [
      {
        label: 'Mark as archived',
        onClick: () => this.markAsArchived(),
        visible: id && isActive
      },
      {
        label: 'Recover archived substantiation',
        onClick: () => this.recoverFromArchives(),
        visible: id && !isActive
      },
      {
        label: 'View all substantiations',
        onClick: () => listDialog({ contacts }),
        visible: substantiationCount <= 1
      },
      {
        label: 'View Metric History',
        onClick: () =>
          metricHistory.open({
            service: 'Substantiations',
            id
          }),
        visible: isEU && !!id
      }
    ].filter((i) => i && i.visible);

    return items;
  }

  markAsArchived() {
    const {
      substantiations,

      errorDialog,
      refreshList
    } = this.props;

    this.setState({ isLoading: true }, () => {
      substantiations
        .archiveSubstantiation({
          id: this.props.id
        })
        .then(() => {
          this.setState(
            { system_record_state: 'archived', isLoading: false },
            () => {
              substantiations.refresh().then(() => refreshList());
            }
          );
        })
        .catch(errorDialog.open);
    });
  }

  recoverFromArchives() {
    const { substantiations, substantiationsForm, errorDialog } = this.props;

    this.setState({ isLoading: true }, () => {
      substantiations
        .recoverArchivedSubstantiation({
          id: this.props.id
        })
        .then(() => {
          this.setState({ system_record_state: 'active' }, () => {
            substantiationsForm.submitForm();
          });
        })
        .catch(errorDialog.open);
    });
  }

  checkExpiry() {
    const expiryDate = _.get(
      this.props,
      'substantiationsForm.values.expiryDate'
    );

    if (!expiryDate) {
      return false;
    }

    const isArchived = this.state.system_record_state === 'archived';
    const isExpired = dayjs(expiryDate).isBefore(dayjs());
    return !isArchived && isExpired;
  }

  renderNote() {
    const isArchived = this.state.system_record_state === 'archived';
    const isExpired = this.checkExpiry();
    const message = isArchived
      ? '   This substantiation has been archived.'
      : '   This substantiation has passed its expiry date.';

    if (isArchived || isExpired) {
      return <WarningBox message={message} prefix={'Note'} />;
    }
  }

  renderMainTab() {
    const { contacts, substantiations, substantiationsForm, valueLists } =
      this.props;

    const substantiationData = _.get(substantiations, 'item.data');
    const related = _.get(substantiationData, 'related');

    return (
      <TabSubstantiation
        workflowId={this.state.workflowId}
        substantiationContacts={
          contacts || _.get(related, 'substantiation_contacts')
        }
        ltoValues={_.get(substantiationsForm, 'values.listingLimitToOffer', [])}
        initialLimitToOffers={_.get(
          related,
          'substantiation_limit_to_contracts',
          []
        )}
        initialLTOvalues={_.get(
          related,
          'substantiation_limit_to_contracts',
          []
        )}
        valueLists={valueLists}
        form={substantiationsForm}
        initialFiles={_.get(related, 'substantiation_documents', [])}
      />
    );
  }

  renderDetailsTab() {
    const { substantiations, valueLists } = this.props;
    return (
      <TabDetails
        initialSubstantiatedByUserIdValue={_.get(
          substantiations,
          'item.data.substantiated_by_user',
          null
        )}
        valueLists={valueLists}
      />
    );
  }

  render() {
    const { closeDialog, substantiationsForm } = this.props;

    const actions = this.actionItems();

    return (
      <Fragment>
        {this.renderNote()}
        <PaddingBox paddingBottom={30}>
          <Box justifyContent='space-between'>
            <Box>
              <Box mb={10}>
                <SubHeading semibold>
                  {this.state.system_record_state === 'active'
                    ? 'Active'
                    : 'Archived'}{' '}
                  substantiation
                </SubHeading>
              </Box>
              <Body normal regular>
                Details for the current substantiation of this contact
              </Body>
            </Box>
            {actions.length > 0 && (
              <div style={{ zIndex: '100' }}>
                <Dropdown items={actions}>Action</Dropdown>
              </div>
            )}
          </Box>
        </PaddingBox>

        <Form
          hasErrorPadding
          formErrorStyles={formErrorStyles}
          name='substantiationsForm'
        >
          <StylesProvider components={tabStyles} prioritiseParentStyles={false}>
            <TabsStatefulDeprecated
              alwaysRenderAll
              nested
              items={[
                {
                  name: 'main',
                  label: 'Substantiation',
                  Tab: this.renderMainTab
                },
                {
                  name: 'details',
                  label: 'Substantiation Details',
                  Tab: this.renderDetailsTab
                }
              ]}
            />
          </StylesProvider>
        </Form>

        <PaddingBox>
          <SaveCancelButtonBar
            hasPadding={false}
            isLoading={substantiationsForm.isSubmitting || this.state.isLoading}
            onSave={substantiationsForm.submitForm}
            onCancel={closeDialog}
            isDisabled={_.get(substantiationsForm, 'meta.fileUploading')}
          />
        </PaddingBox>
      </Fragment>
    );
  }
}

export default Core;
