/**
 * Note: this implementation is basd off view/components/utils/and-list.tsx
 * That is a component, so returns a jsx element, which works in a lot of cases,
 * but can be limiting if you really only want a string back.
 */
interface AndListProps {
  list: string[];
}
// thing, other thing and last thing
export function andList({ list }: AndListProps) {
  if (!list || list.length === 0) {
    return null;
  }

  if (list.length === 1) {
    return list[0];
  }
  const mostOfList = list.slice(0, -2);

  const secondLastElement = list.slice(-2, -1)[0];

  const lastElement = list[list.length - 1];

  return `${mostOfList.map(
    (item) => `${item}, `
  )}${secondLastElement} and ${lastElement}`;
}
