import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { OptionLabel } from 'components/text/option-label';
import { COLORS, PADDINGS } from 'src/theme';
import AddIcon from 'assets/icons/add-medium.svg';

const defaultStyles = StyleSheet({
  container: {
    borderTop: `1px solid ${COLORS.BORDERS.GREY_LIGHT}`,
    cursor: 'pointer',
    minHeight: '16px',
    '&:hover': {
      backgroundColor: COLORS.STATES.BLUE
    }
  },
  icon: {
    height: '10px',
    width: '10px',
    marginLeft: PADDINGS.XXS
  }
});

@styled(defaultStyles)
class Fixture extends Component {
  render() {
    const { styles: s, addNewEntity, children } = this.props;

    return (
      <Box
        {...s('container')}
        alignItems='center'
        height='3rem'
        width='100%'
        onMouseDown={addNewEntity}
      >
        <AddIcon {...s('icon')} />
        <OptionLabel>{children}</OptionLabel>
      </Box>
    );
  }
}

export default Fixture;
