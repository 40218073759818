import { PropertyStub } from 'data/models/entities/properties';
import { formPropertyPayload } from '../utils/properties';
import { PropertyAddressStub } from 'src/view/components/input/select/postcode-address/types';
import { AxiosResponse } from 'axios';
import { push } from '@rexlabs/whereabouts';
import { omit } from 'lodash';

type FindDuplicateResponse = AxiosResponse<{ result: string | null }>;
type CreatePropertyResponse = AxiosResponse<{ result: PropertyStub }>;

interface PropertyActionProps {
  errorDialog: { open: (error: Error) => void };
  notice: { open: (args) => void };
  properties: {
    findPossibleDuplicates: (
      property: PropertyAddressStub
    ) => Promise<FindDuplicateResponse>;
    createItem: (property) => Promise<CreatePropertyResponse>;
  };
}

export const propertyActions = ({
  errorDialog,
  notice,
  properties
}: PropertyActionProps) => {
  const { findPossibleDuplicates, createItem } = properties;

  const goToProperty = (id: string) => {
    push({ config: { path: `/properties/#id=${id}` } });
  };

  const findDuplicateProperty = (
    property: Omit<PropertyAddressStub, 'adr_building'> & {
      adr_building_id?: string;
    }
  ) => {
    return findPossibleDuplicates(property).then(
      (res: FindDuplicateResponse) => res.data.result
    );
  };

  const createProperty = async (newProperty) => {
    const property = formPropertyPayload(newProperty);
    const duplicatePropertyId = await findDuplicateProperty({
      ...omit(property, ['adr_building', 'property_subcategory']),
      adr_building_id: property.adr_building?.id
    });

    if (!duplicatePropertyId) {
      return createItem({
        data: property
      })
        .then((res: CreatePropertyResponse) => {
          if (res.data.result.id) goToProperty(res.data.result.id);
        })
        .catch(errorDialog.open);
    } else {
      notice.open({
        title: 'Duplicate Property',
        message: `The property you entered already exists`,
        closeButtonText: 'Go to property',
        closeButtonColor: 'red',
        callback: () => goToProperty(duplicatePropertyId)
      });
    }
  };

  return { findDuplicateProperty, createProperty, goToProperty };
};
