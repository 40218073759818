import React, { Component } from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS, TEXTS } from 'theme/index';

const defaultStyles = StyleSheet({
  container: {
    ...TEXTS.BUTTON.CTA_WHITE_REGULAR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
    boxSizing: 'border-box',
    width: 'auto',
    textTransform: 'none',
    height: '34px',
    color: COLORS.PRIMARY.WHITE,
    backgroundColor: COLORS.STATES.IDLE,
    borderTopRightRadius: '2px',
    borderTopLeftRadius: '2px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: COLORS.PRIMARY.WHITE
    },
    '&:active': {
      transform: 'translateY(4px)',
      transition: 'all 0ms'
    }
  },

  containerActive: {
    backgroundColor: COLORS.PRIMARY.GREY_DARK,
    color: COLORS.PRIMARY.WHITE
  },

  containerDisabled: {
    color: 'lightgrey',
    cursor: 'default',
    '&:hover': {
      color: 'lightgrey'
    }
  }
});

@styled(defaultStyles)
@autobind
class Tab extends Component {
  setHandledRef(r) {
    const { setRef, name, label } = this.props;
    if (setRef) {
      setRef(r, { name, label });
    }
  }

  handleClick(event) {
    const { onClick, name, label, isDisabled } = this.props;
    if (!isDisabled && onClick) {
      onClick(event, { name, label });
    }
  }

  render() {
    const { styles: s, name, label, isActive, isDisabled, nested } = this.props;
    return (
      <li
        key={name}
        role='tab'
        aria-selected={isActive.toString()}
        ref={this.setHandledRef}
        onClick={this.handleClick}
        {...s('container', {
          containerActive: isActive,
          containerDisabled: isDisabled,
          containerNested: nested,
          containerNestedActive: nested && isActive
        })}
      >
        {label}
      </li>
    );
  }
}

export default Tab;
