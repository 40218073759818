import React from 'react';
import Box from '@rexlabs/box';
import DefaultButton from 'src/view/components/button/default-button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';
import { useRecordNavigation } from 'components/_experiment/record-details-screen/utils/use-record-navigation';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { ICONS } from 'shared/components/icon';
import { useSlidingScaleSetting } from './hooks/use-sliding-scale-setting';

const ArrowIcon: any = ICONS.ARROW_RIGHT;
const ArrowBack: any = ICONS.ARROW_BACK;

const defaultStyles = StyleSheet({
  page: {
    marginRight: '4px',
    fontSize: '14px',
    fontSeight: 400
  },
  icon: {
    color: '#fff'
  },
  iconRight: {
    transform: 'rotate(180deg)'
  }
});

export const RecordActions = ({ slidingScaleSetting, slidingScaleActions }) => {
  const s = useStyles(defaultStyles);
  const { path, query, hashQuery } = useWhereaboutsWithViewpath();
  const { allIds, currIndex, goToPrev, goToNext } = useRecordNavigation();
  const { onArchive, onRemove, onDuplicate } = useSlidingScaleSetting({
    slidingScaleActions
  });

  const goBack = () => {
    push({
      config: {
        path,
        query,
        hash: undefined,
        // eslint-disable-next-line
        // @ts-ignore
        hashQuery: { ...hashQuery, id: undefined }
      }
    });
  };

  return (
    <Box alignItems='center' justifyContent='space-between'>
      <Box alignItems='center' spacing={5}>
        <DefaultButton wide dark onClick={goBack}>
          <ArrowBack />
        </DefaultButton>

        <DefaultButton
          onClick={() => {
            onArchive(slidingScaleSetting, goBack);
          }}
        >
          Archive
        </DefaultButton>
        <DefaultButton
          onClick={() => {
            onRemove(slidingScaleSetting, goBack);
          }}
        >
          Delete
        </DefaultButton>
        <DefaultButton
          onClick={() => {
            onDuplicate(slidingScaleSetting);
          }}
        >
          Duplicate
        </DefaultButton>
      </Box>
      {allIds.length > 1 && (
        <Box alignItems='center' spacing={2}>
          <Box {...s('page')}>
            {currIndex + 1} of {allIds.length}
          </Box>
          <DefaultButton
            padding={false}
            onClick={goToPrev}
            isDisabled={currIndex === 0}
          >
            <ArrowIcon {...s('icon')} />
          </DefaultButton>
          <DefaultButton
            padding={false}
            onClick={goToNext}
            isDisabled={currIndex === allIds.length - 1}
          >
            <ArrowIcon {...s('icon', 'iconRight')} />
          </DefaultButton>
        </Box>
      )}
    </Box>
  );
};
