import { gql } from 'hooks/use-graphql-query';

export const Lead = gql`
  fragment Lead on Leads {
    id
    lead_type {
      text
    }
    lead_source {
      text
    }
    lead_status {
      text
    }
    last_activity {
      outcomes {
        type {
          text
        }
      }
    }
    activities(limit: 1, sort: { field: system_ctime, direction: asc }) {
      system_ctime
    }
    system_completed_time
    system_ctime
  }
`;
