import { ComponentType, FC, ReactNode } from 'react';
import { Criteria, FilterType, ValueType } from 'types/criteria';
import { TagCell, TagCellConfig } from './cells/tag-cell';
import { ContactCell } from './cells/contact-cell';
import { SelectCell } from './cells/select-cell';

export type Selection = {
  type: 'include' | 'exclude';
  ids: (number | string)[];
};

export const FILTER_TYPES = {
  IS: 'is',
  IS_NOT: 'isnot',
  LIKE: 'like',
  IN: 'in',
  NOT_IN: 'notin',
  GTE: '>=',
  LTE: '<='
} as const;

export type QueryCriteria = Omit<Criteria, 'id'>;

export type DisplayCriteria = Criteria & {
  map: any;
  humanReadableValue: string;
  filterConfig?: FilterConfig;
};

export type Pagination = {
  totalPages: number;
  totalItems: number;
  endReached: boolean;
};

type DefaultRow = Record<string, any>;

type RecordObj = Record<string, any> | null | undefined;

type RowValue = RecordObj | string | number | null | undefined;

type RowSelector<Model = DefaultRow> = (row: Model) => RowValue | TagCellConfig;

// List Columns
export type ColumnConfig<Model = any> = {
  /**
   * Column: id
   *
   * Description:
   * Usually supplied with the property intended to be display on this cell.
   *
   * Syntax:
   * id: 'system_ctime'
   * id: 'contact'
   */
  id: string;
  /**
   * Column: selector
   *
   * Description:
   * Allows us to define the property that we want to display based on the data supplied to the table.
   * Alternative selector, if defined will be used instead of the `id` field to pull the data out of the item object
   *
   * Syntax:
   * selector: ({ first_name }) => first_name
   * selector: (row) => row.first_name
   */
  selector?: string | RowSelector<Model>;
  /**
   * Column: label
   *
   * Description:
   * Column header label
   *
   * Syntax:
   * label: 'First Name'
   */
  label: string | ReactNode;
  // TODO: Would be good to get some union between the selector/model and the RowValue
  /**
   * Column: Cell
   *
   * Description:
   * Allows us to take advantage of the different type of cells instead of just a plain one.
   *
   * CellTypes:
   * DefaultCell: The default cell type used when `Cell` property is not defined. Displays plain text.
   * DateCell - Plain date format. Ex. 08 April 2022
   * DateTimeCell - Plain date format with time. Ex. 08 April 2022 06:50 AM
   * DateTimeRelativeCell - Contains the duration of time from the moment the row data was created. Ex. 5 months ago, 2 minutes ago
   * ContactCell - Clicking on the value of ContactCell will display the `ContactPreviewPopout` containing basic contact information.
   * ListingCell - Clicking on the value of ListingCell will display the `ListingPreviewPopout` containing basic listing information.
   * PropertyCell - Clicking on the value of PropertyCell will display the PropertyPreviewPopout containing basic property information.
   *
   * Syntax:
   * Cell: DefaultCell
   * Cell: ListingCell
   */
  Cell?:
    | ComponentType<{ value: RowValue; data: Model }>
    | ComponentType<typeof TagCell>
    | ComponentType<typeof ContactCell>
    | ComponentType<typeof SelectCell>
    | FC;

  /**
   * Column: sortable
   *
   * Description:
   * If marked as true, the column header will be clickable and thus can be sorted based on `id` of the column.
   * If supplied with a string (which should be one of the data props), it will sort according to it instead of the `id`.
   *
   * Syntax:
   * sortable: true,
   * sortable: 'contact.id'
   * sortable: 'first_name'
   */
  sortable?: boolean | string;

  /**
   * Column: sortable
   *
   * Description:
   * This column is forced to be visible, therefore it won't appear in the column selection popout
   *
   * Syntax:
   * forced: true
   */
  forced?: boolean;

  /**
   * Column: hidden
   *
   * Description:
   * Whether or not the column is hidden by default. If false, it can still be visible via the column selection
   *
   * Syntax:
   * hidden: true
   */
  hidden?: boolean;

  /**
   * Column: emptyValue
   *
   * Description:
   * Placeholder if data supplied to column is empty.
   *
   * Syntax:
   * emptyValue: '--'
   * emptyValue: 'None specified'
   * emptyValue: <EmptyComponent />
   */
  emptyValue?: string | ReactNode;

  /**
   * Column: cellProps
   *
   * Description:
   * Whatever you place on `cellProps` it will be spread on the `Cell` component and thus passed as props.
   * Ex. If `Cell: ListingCell`, then the output would be `<ListinCell {...cellProps} />`.
   *
   * If you want to embed a dropdown action on a specific cell, you may pass `{ items: (args?) => any | [] }` here.
   *
   * Syntax:
   * Ex. cellProps: { items: [
   *    {
   *      label: 'Mark as complete',
   *      onClick: () => doSomething();
   *    }
   * ]}
   *
   * Ex. (with data)
   * cellProps: {
   *     items: (row) => [
   *        {
   *          label: row.first_name ? 'Has First Name' : 'No First Name',
   *          onClick: (row) => doSomething(row);
   *        }
   *     ]
   * }
   */
  cellProps?: any; // TODO: make this smarter based on the `Cell` attribute

  /**
   * Column: width
   *
   * Description:
   * You may control the width of the column by adding a width value to this prop.
   *
   * Syntax:
   * width: 200
   * width: '200px'
   */
  width?: string | number;
  rightAlign?: boolean;
};

export type ColumnsConfig<Model = any> = ColumnConfig<Model>[];

export type ListActionMenuProps = {
  label: string | ReactNode;
  onClick: (any) => void;
  hasPermission?: boolean;
};

// List Actions
export type ListActionConfig = {
  label: string | ReactNode;
  onClick?: (args: {
    selection: Selection;
    selectionCriteria: QueryCriteria[];
    afterMassAction?: () => void;
  }) => void;
  /**
   * You can define nested actions (to a maximum of 1 level deep), which
   * will be rendered in form of an action menu button
   */
  items?: Omit<ListActionConfig, 'items'>[];
  hasPermission?: boolean;
};

export type ListActionsConfig = ListActionConfig[];

// List Filters
export type FilterConfig = {
  /** the name of the back end field to filter by **/
  name: string;
  /** a unique field id (optional) only needed if you have 2 filters that filter by the same back end field **/
  id?: string;
  label: string | ReactNode;
  Input?: ComponentType<any>;
  type?: FilterType;
  inputProps?: any; // TODO: infer from `Input`?
  width?: number;
  valueType?: ValueType;
  filterToCriteria?: ({
    filterConfig,
    value
  }: {
    filterConfig: FilterConfig;
    value: any;
  }) => Criteria[];
  criteriaToValue?: ({
    filterConfig,
    criteriaItem
  }: {
    filterConfig: FilterConfig;
    criteriaItem: DisplayCriteria;
  }) => any;
};

export type FiltersConfigGroup = FilterConfig[];
export type FiltersConfig = {
  width?: 'small' | 'default';
  columns?: number;
  groups: FiltersConfigGroup[];
};

// List Lenses
export type LensConfig = {
  title: string | ReactNode;
  criteria: Criteria[];
  orderBy: { field: string; direction: 'asc' | 'desc' };
};

export type LensesConfig = { [lensId: string]: LensConfig };
