import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'adminCampaignTemplates';
const adminCampaignTemplatesModel = new Generator(TYPE).createEntityModel();

adminCampaignTemplatesModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('AdminCampaignTemplates::autocomplete', {
        search_string: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((template) => ({
          value: template.id,
          label: template.name,
          data: template,
          model: adminCampaignTemplatesModel
        }))
      )
};

export default adminCampaignTemplatesModel;
