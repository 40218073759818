import { store } from 'src/store';
import { stripPrefixFromProperties } from 'shared/utils/prefix-hell';

const TYPE = 'bridge/ADD_ENTITY_RECORD';

// This middleware just listenes for the bridge action we trigger from within the classic
// API client ... from here we collect the information we have, put it into the right
// structure and dispatch a fake "*/FETCH_ITEM/SUCCESS" action, which will add the data
// to the redux entity store (with a `null` consumer)
export function bridgeEntityMiddleware(store) {
  return (next) => (action) => {
    if (action.type === TYPE) {
      const models = Object.keys(store.getState().entities);
      if (models.includes(action.payload.model)) {
        const data = stripPrefixFromProperties(action.payload.data);
        store.dispatch({
          type: `${action.payload.model}/FETCH_ITEM/SUCCESS`,
          payload: { data },
          meta: {
            originalPayload: { id: data.id },
            args: {}
          }
        });
      }
    }
    next(action);
  };
}

export default {
  addRecordData: (model, data) =>
    store.dispatch({ type: TYPE, payload: { model, data } })
};
