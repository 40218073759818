/* eslint-disable max-lines */
import React, { PureComponent, Fragment } from 'react';

import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { StatusCircle } from 'view/components/status-circle';
import { RightBarStatusLabel } from 'components/text/right-bar-status-label';

import { AMLDialog } from 'view/dialogs/verifications';
import IDVerificationDialog, {
  TYPES as ID_VERIFICATION_TYPES
} from 'view/dialogs/verifications/id-verification';
import { withDialog } from 'shared/hocs/with-dialog';
import EditSubstantiationDialog from 'view/dialogs/substantiations/edit';
import SubstantiationsListDialog from 'view/dialogs/substantiations/list';
import { getActiveSubstantiationsQuery } from 'data/queries/substantiations';
import { getVerificationsQuery } from 'data/queries/id-verifications';
import { getAmlQuery } from 'data/queries/contact-aml-checks';
import { withQuery } from '@rexlabs/model-generator';
import _ from 'lodash';
import dayjs from 'dayjs';

let counter = 0;

const getContactId = (props) => {
  return _.get(props, 'contact.id');
};

@withDialog(SubstantiationsListDialog, { propName: 'substantiationsList' })
@withDialog(EditSubstantiationDialog, { propName: 'editSubstantiation' })
@withDialog(AMLDialog, { propName: 'amlCheck' })
@withDialog(IDVerificationDialog, { propName: 'idVerification' })
@autobind
class VerificationsWidget extends PureComponent {
  // HACK: because this component doesn't seem to get updates from the fetchLists that occur outside of it
  // I'm using state to manually track statuses
  state = {
    // At the moment the only real problem save time status is idVerificationRecord
    idVerificationRecord: {},
    idVerificationStatus: null
  };

  // HACK: since KO seems to keep dead instances around, we have an incremental counter here
  // that we can use to check weather the current instance is the active one!
  uuid = ++counter;

  getAMLStatus() {
    const { amlChecks } = this.props;

    const amlRecord = _.get(amlChecks, 'list.items.0');
    const status = _.get(amlRecord, 'status.id', 'incomplete');

    // Manual status defs
    let statusDefs = [
      {
        id: 'incomplete',
        color: { grey: true },
        label: 'AML check incomplete'
      },
      {
        id: 'complete',
        color: { green: true },
        label: 'AML check complete'
      },
      {
        id: 'is_expired',
        color: { yellow: true },
        label: 'AML check expired'
      }
    ];

    if (amlRecord && _.get(amlRecord, 'check_type.id') === 'external') {
      // Submission status defs
      statusDefs = [
        {
          id: 'incomplete',
          color: { grey: true },
          label: 'AML check incomplete'
        },
        {
          id: 'submitted',
          color: { green: true },
          label: 'AML check submitted'
        },
        {
          id: 'complete',
          color: { green: true },
          label: 'AML check complete'
        },
        {
          id: 'updates_required',
          color: { yellow: true },
          label: 'AML updates required'
        },
        {
          id: 'is_expired',
          color: { yellow: true },
          label: 'AML check expired'
        }
      ];
    }

    return statusDefs.find((def) => def.id === status);
  }

  getIDVerificationStatus() {
    const { idVerifications } = this.props;

    // If we are maintaining with state, use state
    if (this.state.idVerificationStatus !== null) {
      return this.state.idVerificationStatus
        ? { id: 'complete', color: { green: true }, label: 'ID verified' }
        : {
            id: 'incomplete',
            color: { grey: true },
            label: 'ID check incomplete'
          };
    } else {
      return _.get(idVerifications, 'list.items[0].verified_at')
        ? { id: 'complete', color: { green: true }, label: 'ID verified' }
        : {
            id: 'incomplete',
            color: { grey: true },
            label: 'ID check incomplete'
          };
    }
  }

  getSubstantiationStatus() {
    const { substantiations } = this.props;

    const checkExpiry = (expiryDate) => {
      if (!expiryDate) {
        return false;
      }
      const isExpired = dayjs(expiryDate).isBefore(dayjs());
      return isExpired;
    };

    // Filter out archived substantiations or ones without status
    // Then map over to get a plain array of status ids
    const statuses = _.get(substantiations, 'list.items', [])
      .map((s) => {
        if (checkExpiry(_.get(s, 'expiry_date'))) {
          return 'expired';
        }
        return _.get(s, 'status.id');
      })
      .filter(Boolean);

    const statusDefs = [
      {
        id: 'incomplete',
        color: { grey: true },
        label: 'To be substantiated'
      },
      {
        id: 'in_progress',
        color: { yellow: true },
        label: 'Substantiation in progress'
      },
      {
        id: 'successful',
        color: { green: true },
        label: 'Substantiation successful'
      },
      { id: 'failed', color: { red: true }, label: 'Substantiation failed' },
      {
        id: 'refused',
        color: { yellow: true },
        label: 'Substantiation refused'
      },
      {
        id: 'expired',
        color: { yellow: true },
        label: 'Substantiation expired'
      }
    ];

    // 0 is lowest, 5 is highest
    const precedence = {
      incomplete: 0,
      failed: 1,
      refused: 2,
      expired: 3,
      in_progress: 4,
      successful: 5
    };

    /**
     * Used in a reduce loop to return a single item from the statuses array based on precedence.
     * Checks the current highest precedence status against the next status in the array
     * then continues the loop with the higher precedence between them.
     * in the case of equal precedence, the previous value is kept.
     */
    const getHighestPrecedence = (acc, status) => {
      return precedence[acc] < precedence[status] ? status : acc;
    };

    const status = statuses.reduce(getHighestPrecedence, 'incomplete');
    return statusDefs.find((def) => def.id === status);
  }

  renderStatus(type) {
    let status;

    switch (type) {
      case 'aml':
        status = this.getAMLStatus();
        break;
      case 'id':
        status = this.getIDVerificationStatus();
        break;
      case 'substantiation':
        status = this.getSubstantiationStatus();
        break;
    }

    if (!status) {
      status = { color: {}, label: '' };
      console.warn('No status found.', { type, status });
    }

    return (
      <Fragment>
        <StatusCircle {...status.color} />
        <RightBarStatusLabel>{status.label}</RightBarStatusLabel>
      </Fragment>
    );
  }

  onAMLStatusClick() {
    const { amlCheck, contact, amlChecks, idVerifications } = this.props;
    amlCheck.open({
      contact,
      callback: () => {
        amlChecks.refreshList();
        idVerifications.refreshList();
      }
    });
  }

  onIDVerificationStatusClick() {
    const { idVerification, contact, idVerifications } = this.props;
    const { idVerificationRecord } = this.state;
    const id = _.get(
      idVerifications,
      'list.items[0].id',
      _.get(idVerificationRecord, 'id', null)
    );

    idVerification.open({
      id,
      contactId: _.get(contact, 'id'),
      type: _.get(contact, 'type', ID_VERIFICATION_TYPES.PERSON),
      onClose: ({ data }) => {
        this.setState({
          idVerificationRecord: data,
          idVerificationStatus:
            !!_.get(data, 'result.verified_at') &&
            !!_.get(data, 'result.verified_by_user')
        });
      }
    });
  }

  listDialogProp() {
    const {
      contact,
      substantiations,

      substantiationsList
    } = this.props;

    return substantiationsList.open({
      contacts: [contact],
      callback: () => substantiations.refreshList()
    });
  }

  onSubstantiationStatusClick() {
    const {
      contact,
      substantiations,
      editSubstantiation,
      substantiationsList
    } = this.props;

    const items = _.get(substantiations, 'list.items', []);

    if (!items.length) {
      editSubstantiation.open({
        listDialog: () => {
          return substantiationsList.open({
            contacts: [{ contact }],
            callback: () => substantiations.refreshList()
          });
        },
        substantiationCount: items.length,
        contacts: [{ contact }],
        callback: () => {
          substantiations.refreshList();
        }
      });
      return;
    }

    if (items.length === 1) {
      editSubstantiation.open({
        listDialog: () => {
          return substantiationsList.open({
            contacts: [{ contact }],
            callback: () => substantiations.refreshList()
          });
        },
        substantiationCount: items.length,
        id: items[0].id,
        callback: () => {
          substantiations.refreshList();
        }
      });
      return;
    }

    substantiationsList.open({
      contacts: [{ contact }],
      callback: () => {
        substantiations.refreshList();
      }
    });
  }

  isLoading = true;

  render() {
    const { amlChecks, substantiations, idVerifications, loadingCallback } =
      this.props;

    const isLoading =
      _.get(substantiations, 'list.status') === 'loading' ||
      _.get(idVerifications, 'list.status') === 'loading' ||
      _.get(amlChecks, 'list.status') === 'loading';

    if (this.isLoading && !isLoading) {
      this.isLoading = false;
      loadingCallback();
    }

    if (this.uuid < counter) {
      // HACK: This is just an attempt for performance reasons, since the KO binding seems to
      // keep dead instances around instead of unmounting them?!?!
      return null;
    }

    if (this.isLoading) {
      return null;
    }

    return (
      <Fragment>
        <Box
          mb={3}
          alignItems={'center'}
          onClick={this.onIDVerificationStatusClick}
        >
          {this.renderStatus('id')}
        </Box>
        <Box mb={3} alignItems={'center'} onClick={this.onAMLStatusClick}>
          {this.renderStatus('aml')}
        </Box>
        <Box alignItems={'center'} onClick={this.onSubstantiationStatusClick}>
          {this.renderStatus('substantiation')}
        </Box>
      </Fragment>
    );
  }
}

class Core extends PureComponent {
  constructor(props) {
    super(props);

    // HACK: this is super hacky and I have no idea why this works!!! Please consider
    // removing if core issue is fixed

    // Core issue: the widget is embedded in classic, but when navigating from one contact
    // back to the contact list and to another contact, the widget never unmounts but instead
    // creates a new instance ... the new instance doesn't receive the new data properly
    // for some reason, so I guess pulling the `withQuery`s out into here caused the new instances
    // to get a completely fresh fetch of data?!?

    const amlHoc = withQuery(getAmlQuery(getContactId));
    const verificationsHoc = withQuery(getVerificationsQuery(getContactId));
    const idHoc = withQuery(getActiveSubstantiationsQuery(getContactId));

    this.Component = amlHoc(verificationsHoc(idHoc(VerificationsWidget)));
  }

  render() {
    const { Component } = this;

    return <Component {...this.props} />;
  }
}

export default Core;
