import { keyframes } from '@rexlabs/styling';

import { CONSTANTS } from './misc';

export const TRANSITIONS = {
  REGULAR: {
    BACKGROUND: 'background 0.3s',
    COLOR: 'color 0.3s',
    OPACITY: 'opacity 0.3s',
    HEIGHT: 'height 0.3s'
  },
  SLOW: {
    TRANSFORM: 'transform 0.6s'
  }
} as const;

export const TIMINGS = {
  NAVIGATION: {
    ACTIVE_ITEM: '150ms'
  }
} as const;

export const EASINGS = {};

export const KEYFRAMES = {
  NAV_APPFRAME_SCALE_IN: keyframes({
    '0%': {
      width: `calc(100% - ${CONSTANTS.ICON_BAR_WIDTH})`,
      transform: `translate(${CONSTANTS.ICON_BAR_WIDTH}, 0)`
    },
    '100%': {
      width: `calc(100% - ${CONSTANTS.PRIMARY_TIER_WIDTH})`,
      transform: `translate(${CONSTANTS.PRIMARY_TIER_WIDTH}, 0)`
    }
  }),

  NAV_APPFRAME_SCALE_OUT: keyframes({
    '0%': {
      width: `calc(100% - ${CONSTANTS.PRIMARY_TIER_WIDTH})`,
      transform: `translate(${CONSTANTS.PRIMARY_TIER_WIDTH}, 0)`
    },
    '100%': {
      width: `calc(100% - ${CONSTANTS.ICON_BAR_WIDTH})`,
      transform: `translate(${CONSTANTS.ICON_BAR_WIDTH}, 0)`
    }
  }),

  SCREEN_FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),

  SCREEN_FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 0 }
  }),

  DIALOG_FADE_IN: keyframes({
    '0%': { opacity: 0, transform: 'scale(.95)', overflowY: 'hidden' },
    '99%': { overflowY: 'hidden' },
    '100%': { opacity: 1, transform: 'scale(1)', overflowY: 'scroll' }
  }),

  DIALOG_FADE_OUT: keyframes({
    '0%': { opacity: 1, transform: 'scale(1)', overflowY: 'scroll' },
    '1%': { overflowY: 'hidden' },
    '100%': { opacity: 0, transform: 'scale(.95)', overflowY: 'hidden' }
  }),

  OVERLAY_FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),

  OVERLAY_FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  })
} as const;
