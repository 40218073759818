import React from 'react';

import { Value, ValueProps } from '.';

interface ArrayValueProps extends Omit<ValueProps, 'value'> {
  value?: {
    description: string;
    uri: string;
    url: string;
  };
}

export function FileValue({ value, ...props }: ArrayValueProps) {
  return <Value value={value?.description} {...props} />;
}
