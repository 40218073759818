import _ from 'lodash';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';

import adminMergeTemplatesModel from 'data/models/custom/admin-merge-templates';

import { Grid, Column } from 'shared/components/grid';
import { PADDINGS } from 'theme';

import Dialog from 'view/components/dialog';
import { SectionHeading } from 'components/text/section-heading';
import { DialogBody } from 'components/text/dialog-body';
import { ReactForms, Form, FormField } from 'view/components/form';
import { Select } from 'view/components/input/select';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import PaddingBox from 'view/components/padding-box';

@withErrorDialog
@withModel(adminMergeTemplatesModel)
@autobind
class TemplateField extends Component {
  state = {
    categoryOptions: [],
    loadingCategoryOptions: true
  };

  defaultProps = {
    title: 'Template Field'
  };

  mergeTagOptions = [];

  componentDidMount() {
    const { adminMergeTemplates, moduleId } = this.props;

    adminMergeTemplates
      .getMergeTagsForRecordType({ recordType: moduleId })
      .then((response) => {
        const data = response.data.result;
        const categoryOptions = data.map((item) => ({
          value: _.get(item, 'category_id'),
          label: _.get(item, 'category_description'),
          category_id: _.get(item, 'category_id'),
          tags: _.get(item, 'tags')
        }));

        this.setState({
          categoryOptions: categoryOptions,
          loadingCategoryOptions: false
        });
      });
  }

  onCategorySelect({ target }) {
    const tags = _.get(target, 'value.tags');

    this.mergeTagOptions = tags.map((t) => ({
      value: _.get(t, 'tag'),
      label: _.get(t, 'description')
    }));

    this.setFieldValue('mergeTag', { value: null });
  }

  handleSubmit(values) {
    const { closeDialog, onSubmit } = this.props;

    onSubmit && onSubmit(values);
    closeDialog();
  }

  getInitialValues() {
    const { data } = this.props;
    const { categoryOptions } = this.state;

    const mergeTagCategory = _.get(data, 'merge_tag_category');
    let mergeTagCategoryOption = categoryOptions.find(
      (option) => option.label === mergeTagCategory
    );
    let mergeTag = null;
    let mergeTagOption = null;

    if (_.get(mergeTagCategoryOption, 'value', null) !== null) {
      mergeTag = _.get(data, 'merge_tag').replace('{{', '').replace('}}', '');

      this.mergeTagOptions = mergeTagCategoryOption.tags.map((tag) => ({
        value: _.get(tag, 'tag'),
        label: _.get(tag, 'description')
      }));

      mergeTagCategoryOption.tags.forEach((option) => {
        if (option.tag === mergeTag) {
          mergeTagOption = {
            value: _.get(option, 'tag'),
            label: _.get(option, 'description')
          };
        }
      });
    } else {
      mergeTagCategoryOption = { value: null };
      mergeTagOption = { value: null };
    }

    return {
      category: mergeTagCategoryOption,
      mergeTag: mergeTagOption
    };
  }

  handleValidation(values, extra) {
    if (_.get(extra, 'submitCount') < 1) {
      return {};
    }

    const messages = {};

    if (values.category.value === null) {
      messages.category = 'The category field is required.';
    }
    if (values.mergeTag.value === null) {
      messages.mergeTag = 'The merge tag field is required.';
    }

    return messages;
  }

  render() {
    const { onLoad, closeDialog, data, title } = this.props;
    const { loadingCategoryOptions, categoryOptions } = this.state;

    const fieldType = _.get(data, 'provider_field_type');
    const label = _.get(data, 'provider_field_name');

    return (
      <Dialog
        title={title}
        onLoad={onLoad}
        isLoading={loadingCategoryOptions}
        closeDialog={closeDialog}
        hasPadding={false}
        height={369}
      >
        {fieldType && label && (
          <PaddingBox light>
            <Grid>
              <Column width={5}>
                <Box pt={PADDINGS.XXS} pb={PADDINGS.XXS}>
                  <SectionHeading>field type</SectionHeading>
                  <DialogBody>{fieldType}</DialogBody>
                </Box>
              </Column>
              <Column width={7}>
                <Box pt={PADDINGS.XXS} pb={PADDINGS.XXS}>
                  <SectionHeading>label</SectionHeading>
                  <DialogBody>{label}</DialogBody>
                </Box>
              </Column>
            </Grid>
          </PaddingBox>
        )}
        <ReactForms
          handleSubmit={this.handleSubmit}
          validate={this.handleValidation}
          initialValues={this.getInitialValues()}
          asyncValuesReady={!loadingCategoryOptions}
        >
          {({ setFieldValue, submitForm, isSubmitting, values }) => {
            this.values = values;
            this.setFieldValue = setFieldValue;

            return (
              <PaddingBox>
                <Form>
                  <FormField
                    name='category'
                    label='category'
                    Input={Select}
                    onChange={this.onCategorySelect}
                    inputProps={{
                      options: categoryOptions,
                      valueAsObject: true
                    }}
                    sendImmediate
                  />
                  <FormField
                    name='mergeTag'
                    label='merge tag'
                    Input={Select}
                    inputProps={{
                      disabled: !_.get(values, 'category.value'),
                      options: this.mergeTagOptions,
                      valueAsObject: true
                    }}
                    sendImmediate
                  />
                  <ButtonBar isLoading={isSubmitting}>
                    <TextButton blue onClick={closeDialog}>
                      Cancel
                    </TextButton>
                    <DefaultButton red onClick={submitForm}>
                      Insert
                    </DefaultButton>
                  </ButtonBar>
                </Form>
              </PaddingBox>
            );
          }}
        </ReactForms>
      </Dialog>
    );
  }
}

export default TemplateField;
