import React, { useEffect, useMemo } from 'react';
import Box from '@rexlabs/box';

import { SubHeading } from 'components/text/sub-heading';
import { Body } from 'components/text/body';
import { RecordListActions } from 'components/record-list-screen/actions';

import { PADDINGS } from 'shared/theme';
import { ColumnConfig } from 'components/record-list-screen/types';

import { useStyles, StyleSheet } from '@rexlabs/styling';
import { RelatedTable } from 'src/view/components/record-details-screen/related-table';

import { SettingsPopout } from 'features/settings/components/sliding-scale-settings/settings-popout';
import { useDialog } from 'hooks/use-dialog';
import { useSlidingScaleSetting } from 'features/settings/components/sliding-scale-settings/hooks/use-sliding-scale-setting';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { push } from '@rexlabs/whereabouts';
import { SlidingScaleSetting } from 'features/settings/components/sliding-scale-settings/data/types';
import { ApiActions } from 'data/models/custom/api-model';

const styles = StyleSheet({
  heading: { marginLeft: 0, marginBottom: 5 },
  subheading: {
    marginLeft: 0,
    marginBottom: PADDINGS.M,
    whiteSpace: 'pre-wrap'
  }
});

function SlidingScaleSettings({
  slidingScaleActions
}: {
  slidingScaleActions: ApiActions<SlidingScaleSetting>;
}) {
  const s = useStyles(styles);
  const whereabouts = useWhereaboutsWithViewpath();
  const addSlidingScale = useDialog('addSlidingScaleSetting');
  const { hashQuery, path, query } = whereabouts;
  const {
    fetchSlidingScaleSettings,
    isLoading,
    savedFilters,
    setSavedFilters,
    slidingScaleSettings
  } = useSlidingScaleSetting({ slidingScaleActions });

  useEffect(() => {
    fetchSlidingScaleSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFilters]);

  const columns: ColumnConfig<SlidingScaleSetting>[] = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name'
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box width={'100%'} p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <Box m={0}>
        <SubHeading semibold {...s('heading')}>
          Agent Sliding Scale Commissions
        </SubHeading>
        <Body regular dark {...s('subheading')}>
          Create and manage your sliding scale commission structures and apply
          these to Agent Defaults.
        </Body>
      </Box>
      <Box w={'calc(100% - 15px)'}>
        <RelatedTable
          Heading={() => (
            <Box display='flex' flexDirection='row' spacing={4}>
              <RecordListActions
                onAdd={addSlidingScale.open}
                selection={{ type: 'include', ids: [] }}
              />
              <SettingsPopout
                onClearFilter={() => setSavedFilters(undefined)}
                savedFilter={savedFilters}
                onFilter={setSavedFilters}
              />
            </Box>
          )}
          columns={columns}
          limit={20} // 20 items per page
          items={slidingScaleSettings}
          isLoading={isLoading}
          emptyMessage='No sliding commissions found'
          colorScheme='light'
          showFooterPagination={true}
          onItemClick={(data: SlidingScaleSetting) =>
            push(
              {
                config: {
                  path,
                  query,
                  hash: undefined,
                  hashQuery: {
                    ...hashQuery,
                    id: data.id
                  }
                }
              },
              {
                state: {
                  fromListIds: slidingScaleSettings.map((setting) => setting.id)
                }
              }
            )
          }
        />
      </Box>
    </Box>
  );
}

export default SlidingScaleSettings;
