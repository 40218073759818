import { isArray, isObject } from 'lodash';
/**
 * Utilities for encoding and decoding javascript objects for the purposes of embedding in a url.
 *
 * Why not just use JSON.stringify/parse?
 * 1) because ":" characters cause whereabouts to throw an error. whereabouts assumes ":" characters are part of a
 * path regex that will be replaced with params.
 * 2) Because JSON is quite verbose. By removing quotes around keys and values, we reduce the url a little and make
 * it easier to read.
 *
 * JSON.stringify:
 * `{"cols":[{"colId":"id","width":200,"hide":false,"pinned":null},{"colId":"title","width":200,"hide":false,"pinned":null}],"filters":{"guests-0-stub-type":{"values":["person"],"filterType":"set"},"appointment_type-name":{"values":["Appraisal"],"filterType":"set"}},"pivot":true}`
 * This encoder:
 * `{cols~[{colId~"id",width~200,hide~false,pinned~null},{colId~"title",width~200,hide~false,pinned~null}],filters~{guests-0-stub-type~{values~["person"],filterType~"set"},appointment_type-name~{values~["Appraisal"],filterType~"set"}},pivot~true}
 * */
const urlSafeChars = '[A-Z0-9 \\-_%]+';

// btoa alternative:
function unicodeSafeEncode(str) {
  return window.btoa(encodeURIComponent(str));
}
// atob alternative:
export function unicodeSafeDecode(str) {
  try {
    return decodeURIComponent(window.atob(str));
  } catch (e) {
    // this will work for legacy strings encoded with btoa
    return window.atob(str);
  }
}

function encodeString(string) {
  // this string is url safe
  const urlSafeCharsRegex = new RegExp(`^${urlSafeChars}$`, 'gi');
  if (string.match(urlSafeCharsRegex)) {
    return string;
  }
  // this string is not url safe, let's encode it and prefix it so we know we need to decode it
  return '$' + unicodeSafeEncode(string);
}

function decodeString(string) {
  // this is a non-url safe string so we've encoded it
  if (string.match(/^\$/gi)) {
    return unicodeSafeDecode(string.replace(/^\$/gi, ''));
  }
  return string;
}

function recursivelyEncode(objectToEncode: any) {
  if (isObject(objectToEncode)) {
    if (isArray(objectToEncode)) {
      return objectToEncode.map(recursivelyEncode);
    } else {
      return Object.keys(objectToEncode).reduce(
        (objectToEncode, key) => ({
          ...objectToEncode,
          [key]: recursivelyEncode(objectToEncode[key])
        }),
        objectToEncode
      );
    }
  } else if (typeof objectToEncode === 'string') {
    return encodeString(objectToEncode);
  } else {
    return objectToEncode;
  }
}

function recursivelyDecode(encodedObject: any) {
  if (isObject(encodedObject)) {
    if (isArray(encodedObject)) {
      return encodedObject.map((o, i) => recursivelyDecode(o));
    } else {
      return Object.keys(encodedObject).reduce(
        (encodedObject, key) => ({
          ...encodedObject,
          [key]: recursivelyDecode(encodedObject[key])
        }),
        encodedObject
      );
    }
  } else if (typeof encodedObject === 'string') {
    return encodedObject.length > 0 ? decodeString(encodedObject) : '';
  } else {
    return encodedObject;
  }
}

export function encodeParams(params) {
  const urlSafeCharsRegex = new RegExp(`"(${urlSafeChars})":`, 'gi');
  return JSON.stringify(recursivelyEncode(params)).replace(
    urlSafeCharsRegex,
    '$1~'
  );
}

export function decodeParams(params) {
  const urlSafeCharsRegex = new RegExp(`(${urlSafeChars})~`, 'gi');
  const decoded = params.replace(urlSafeCharsRegex, '"$1":');
  return recursivelyDecode(JSON.parse(decoded));
}
