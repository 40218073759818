/**
 * See ./use-criteria.md for documentation and development notes
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { get, differenceBy } from 'lodash';
import { parseRouteToUrl, push, useWhereabouts } from '@rexlabs/whereabouts';
import {
  query,
  useEntityQuery,
  useModelActions,
  Id
} from '@rexlabs/model-generator';

import { api } from 'shared/utils/api-client';
import savedFiltersModel from 'data/models/entities/saved-filters';
import { useLens } from './use-lens';
import { useViewstate } from './use-viewstate';

import {
  parseFilterFromUrlToObject,
  filterCriteriaToQueryCriteria,
  findFilterConfigByName,
  serialiseCriteriaToUrl,
  getCriteriaMap,
  getHumanReadableValueForCriteria
} from './filters';

import { DisplayCriteria, FiltersConfig, LensesConfig } from '../types';
import { OrderBy } from 'features/pipelines/components/order-by-dropdown';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';

const getSavedFilterQuery = (id) => query`{
  ${savedFiltersModel} (id: ${id}) {
    id
    name
  }
}`;

type Cache = {
  [key: string]: {
    data: {
      [fieldName: string]: any; // TODO: type as `SearchField`
    };
    lists: {
      [listName: string]: { id: Id; text: string }[];
    };
  };
};

const cache: Cache = {};

function mapToCriteria({ userCriteria, viewstateCriteria, lensCriteria }) {
  // Remove any lens criteria that are overridden by filters added by the user
  const additionalLensCriteria = differenceBy(
    lensCriteria,
    userCriteria,
    'name'
  );
  return [...userCriteria, ...viewstateCriteria, ...additionalLensCriteria];
}

export function useCriteria({
  serviceName,
  lenses,
  filtersConfig = { groups: [] },
  setOrderBy
}: {
  serviceName: string;
  lenses?: LensesConfig;
  filtersConfig?: FiltersConfig;
  setOrderBy?: (orderBy: OrderBy) => void;
}) {
  const whereabouts = useWhereabouts();
  const { criteria: lensCriteria } = useLens(lenses);
  const { criteria: viewstateCriteria } = useViewstate();

  /**
   * Currently active filter criteria from the url
   */
  const urlCriteria = useMemo(
    () => parseFilterFromUrlToObject(whereabouts.hashQuery?.filters) || [],
    [whereabouts.hashQuery]
  );

  /**
   * Combine this with criteria that comes from any selected lens, and any selected viewstate.
   * TODO: this is currently a simple merge but there may be duplicates here from lenses that need to be deduped.
   */
  const queryCriteria = useMemo(() => {
    const mappedCriteria = urlCriteria.map((criteria) =>
      filterCriteriaToQueryCriteria(criteria, filtersConfig)
    );

    return mapToCriteria({
      userCriteria: mappedCriteria,
      lensCriteria,
      viewstateCriteria
    });
  }, [urlCriteria, lensCriteria, viewstateCriteria, filtersConfig]);

  /**
   * Set criteria. This just persists the criteria to the url. Whereabouts will then take care of passing down the new
   * criteria to this hook and the subsequent handling of the criteria.
   */
  const setCriteria = useCallback(
    (displayCriteria, filterId) => {
      const newRoute = {
        ...whereabouts,
        hash: undefined,
        hashQuery: {
          ...whereabouts.hashQuery,
          filters: displayCriteria?.length ? '-----FILTERS-----' : undefined,
          filterId
        }
      };

      // HACK: because we don't want whereabouts to parse the filter (because it would encode
      // the brackets) we manually set the filter hash value here :/
      // eslint-disable-next-line
      // @ts-ignore
      const path = parseRouteToUrl(newRoute)?.replace(
        '-----FILTERS-----',
        serialiseCriteriaToUrl(displayCriteria)
      );
      push({ config: { path } });
    },
    [whereabouts]
  );

  /**
   * Load search fields
   */
  const [isLoading, setLoading] = useState(!cache[serviceName]);

  // Use data from cache so we only load the search fields once per page load
  // for each module
  // UNKNOWN: Is this going to be too long-lived a cache?
  const searchFieldDefinitions = cache[serviceName];

  // fetch the list of search field definitions. We need this in order to look up the value for a given resource
  // e.g. taking a lead type id and converting it to a lead type name
  useEffect(() => {
    if (searchFieldDefinitions === undefined) {
      setLoading(true);
      api
        .post(`${serviceName}::describeSearchFields`)
        .then((response) => {
          cache[serviceName] = { data: response?.data?.result, lists: {} };
          setLoading(false);
        })
        .catch((e) => {
          // TODO: error dialog?
          console.error(e);
          cache[serviceName] = { data: {}, lists: {} };
          setLoading(false);
        });
    }
  }, [searchFieldDefinitions, isLoading, serviceName]);

  // list of value lists we require to populate the values of the currently selected filters
  // TODO: we could potentially move this into the filter-tag component
  const requiredValueLists = useMemo(
    () => {
      return (
        queryCriteria
          ?.map?.((obj) => {
            const field = get(searchFieldDefinitions?.data, obj.name);
            if (field?.options?.source === 'remote') {
              return field.options.list;
            }
          })
          .filter(Boolean) || []
      );
    },
    // eslint-disable-next-line
    [queryCriteria, searchFieldDefinitions?.data]
  );

  // do a call to fetch the missing value lists that aren't already in the cache
  useEffect(
    () => {
      const thereAreMissingListsToFetch = requiredValueLists.find(
        (listName) => !searchFieldDefinitions?.lists?.[listName]
      )?.length;
      if (thereAreMissingListsToFetch) {
        setLoading(true);
        api
          .post('SystemValues::getCategoryValues', {
            list_name: requiredValueLists
          })
          .then((response) => {
            cache[serviceName].lists = requiredValueLists.reduce(
              (all, listName) => {
                all[listName] = response?.data?.result?.[listName] || [];
                return all;
              },
              {}
            );
            setLoading(false);
          })
          .catch((e) => {
            console.error(e);
            cache[serviceName].lists = requiredValueLists.reduce(
              (all, listName) => {
                all[listName] = [];
                return all;
              },
              {}
            );
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [requiredValueLists, searchFieldDefinitions?.lists, serviceName]
  );

  const displayCriteria = useMemo<DisplayCriteria[]>(
    () => {
      return urlCriteria.map((criteriaItem) => {
        const map = getCriteriaMap(criteriaItem, searchFieldDefinitions);

        const filterConfig = findFilterConfigByName(
          criteriaItem.name,
          filtersConfig
        );

        const humanReadableValue = getHumanReadableValueForCriteria({
          criteriaItem,
          filterConfig,
          searchFieldDefinitions
        });

        return { ...criteriaItem, map, humanReadableValue, filterConfig };
      });
    },
    // eslint-disable-next-line
    [
      urlCriteria,
      searchFieldDefinitions?.data,
      searchFieldDefinitions?.lists,
      filtersConfig
    ]
  );

  /**
   * Load active saved filter
   */
  const filterId = whereabouts.hashQuery?.filterId;
  const savedFiltersQuery = useMemo(
    () => (filterId ? getSavedFilterQuery(filterId) : undefined),
    [filterId]
  );

  useEffect(() => {
    if (filterId) {
      Analytics.track({
        event: EVENTS.RECORD_LIST_SCREEN.SAVED_FILTERS.APPLIED,
        properties: {
          filterId
        }
      });
    }
  }, [filterId]);

  const { data: savedFilter, status } = useEntityQuery(savedFiltersQuery, {
    fetch: !!filterId
  });

  /* HACK: This hack helps to stop an issue where the saved filter is set to undefined, which causes
    the filter buttons to get stuck loading in the list screen. Issue is that the saved filter model garbage
    collects the data, after we have selected a saved filter from the popout. With the hack below, we're
    just making sure we always have last known saved filter.
    https://app.shortcut.com/rexlabs/story/65948/investigate-further-why-usecriteria-inside-recordlistscreen-returns-a-null-savedfilter-when-switching-saved-filter
  */

  const [selectedSavedFilter, setSelectedSavedFilter] = useState(savedFilter);
  const [isSavedFilterLoading, setIsSavedFilterLoading] = useState(
    status === 'loading'
  );

  useEffect(() => {
    if (savedFilter && filterId) {
      setSelectedSavedFilter(savedFilter);
      if (setOrderBy && savedFilter.order_by) {
        setOrderBy(savedFilter.order_by);
      }
    } else if (!savedFilter && !filterId) {
      setSelectedSavedFilter(undefined);
    }

    setIsSavedFilterLoading(false);
  }, [savedFilter, filterId]);

  const { touchLastRunDate } = useModelActions(savedFiltersModel);
  useEffect(() => {
    if (filterId) {
      touchLastRunDate({ id: filterId });
    }
  }, [filterId, touchLastRunDate]);

  return {
    isLoading: isSavedFilterLoading || isLoading,
    queryCriteria: queryCriteria,
    displayCriteria: displayCriteria,
    lensCriteria,
    setCriteria,
    savedFilter: selectedSavedFilter,
    savedFilterId: filterId
  };
}
