import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import PaddingBox from 'view/components/padding-box';
import { IframeSandboxed } from 'components/iframe/iframe-sandboxed';
import { Body } from 'components/text/body';

const defaultStyles = StyleSheet({
  messageContentWrapper: {
    display: 'flex',
    overflow: 'hidden',
    flex: 1
  },
  preWrappedContent: {
    overflow: 'auto',
    whiteSpace: 'pre-wrap' as const
  }
});

function MessageContentWrapper({ children }) {
  const s = useStyles(defaultStyles);
  return <PaddingBox {...s('messageContentWrapper')}>{children}</PaddingBox>;
}

// TODO: if we find more uses for pre-wrap content we should probably add this to typography instead of here.
function PreWrappedContent({ children }) {
  const s = useStyles(defaultStyles);
  return (
    <Box flex={1} {...s('preWrappedContent')}>
      <Body normal>{children}</Body>
    </Box>
  );
}

export function LeadsDialogMessageContent({ lead, isEmailLead }) {
  return (
    <MessageContentWrapper>
      {isEmailLead ? (
        <IframeSandboxed html={lead?.html_body} style={{ display: 'flex' }} />
      ) : (
        <PreWrappedContent>{lead?.note}</PreWrappedContent>
      )}
    </MessageContentWrapper>
  );
}
