import { AiGradientButton } from 'features/ai/components/primitives/ai-gradient-button';
import React from 'react';
import { TextGenerationFormType } from 'features/ai/components/text-generation/text-generation-form';
import { useDialog } from 'hooks/use-dialog';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { RefinementType } from 'features/ai/data/refinement-type';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';
import { usePermissions } from 'hooks/use-permissions';

export type AiTextGenerationButtonProps = {
  onInsert: (args: { headline: string; body: string }) => void;
  label: string;
  type: TextGenerationFormType;
  listingId: string;
  initialRefinement?: RefinementType;
  initialData?: {
    headline?: string;
    body?: string;
  };
};

const styles = StyleSheet({
  adText: {
    position: 'relative',
    marginTop: '20px',
    float: 'left'
  },
  email: {
    position: 'relative',
    marginTop: '20px'
  },
  sms: {
    position: 'relative',
    marginTop: '20px'
  }
});

export function AiTextGenerationButton({
  type,
  onInsert,
  label,
  listingId,
  initialRefinement,
  initialData
}: AiTextGenerationButtonProps) {
  const s = useStyles(styles);
  const adTextDialog = useDialog('generateAdText');
  const communicationsDialog = useDialog('generateCommunication');
  const permissions = usePermissions();

  if (
    !permissions.hasAddon('ai_features') ||
    !hasFeatureFlags('initial_ai_features')
  ) {
    return null;
  }

  return (
    <div
      {...s({
        adText: type === TextGenerationFormType.AdText,
        email: type === TextGenerationFormType.Email,
        sms: type === TextGenerationFormType.SMS
      })}
    >
      <AiGradientButton
        withIcon
        onClick={() => {
          const dialog =
            type === TextGenerationFormType.AdText
              ? adTextDialog
              : communicationsDialog;
          Analytics.track({
            event:
              type === TextGenerationFormType.AdText
                ? EVENTS.AI_AD_TEXT.AI_AD_TEXT_OPENED
                : EVENTS.AI_COMMUNICATIONS.AI_COMMUNICATIONS_OPENED,
            properties: {
              isEditingExisting: !!initialData?.body,
              communicationType: type
            }
          });
          dialog.open({
            type,
            onInsert,
            listingId,
            initialData,
            initialRefinement
          });
        }}
      >
        {label}
      </AiGradientButton>
    </div>
  );
}
