import { useMemo } from 'react';

import { ActionMenuItem } from 'view/components/action-menu/core';

import { CustomReportItem } from '../data/custom-reports-model';
import { useDeleteCustomReport } from './use-delete-custom-report';
import { useEditCustomReportDialog } from './use-edit-custom-report-dialog';
import { useToggleFavourite } from '../hooks/use-toggle-favourite';
import { useReportPermissions } from './use-report-permissions';

interface UserReportActionsProps {
  customReport: CustomReportItem;
}

export function useReportActions({
  customReport
}: UserReportActionsProps): ActionMenuItem[] {
  const toggleFavourite = useToggleFavourite(customReport);

  const deleteReport = useDeleteCustomReport(customReport);

  const { canEditReport } = useReportPermissions({ customReport });

  const { openEditCustomReportDialog } = useEditCustomReportDialog({
    customReportId: customReport.id
  });

  return useMemo(
    () => [
      {
        label: `${
          customReport.is_favourite ? 'Remove from' : 'Add to'
        } Favourites`,
        onClick: toggleFavourite
      },
      ...(canEditReport
        ? [{ label: 'Edit Name/Sharing', onClick: openEditCustomReportDialog }]
        : []),
      ...(canEditReport ? [{ label: 'Delete', onClick: deleteReport }] : [])
    ],
    [
      canEditReport,
      customReport.is_favourite,
      deleteReport,
      openEditCustomReportDialog,
      toggleFavourite
    ]
  );
}
