import { ContactAutocomplete } from 'src/data/models/entities/contacts';

interface AmlCheckStatus {
  id: 'complete' | 'incomplete';
  text: 'Complete' | 'Complete';
}

interface Officeholder {
  aml_check_status: AmlCheckStatus;
  contact: ContactAutocomplete;
  id: number;
  id_verified: boolean;
  relationship_type: { id: string; text: string };
}

export function getOfficeholdersIdVerification(
  newOfficeHolders: Officeholder[]
) {
  return newOfficeHolders.every((officeHolder) => !!officeHolder?.id_verified);
}

export function getOfficeholdersAmlCompleted(newOfficeHolders: Officeholder[]) {
  return newOfficeHolders.every(
    (officeHolder) => officeHolder?.aml_check_status?.id === 'complete'
  );
}
