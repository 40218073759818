import React from 'react';

import { Heading } from 'components/text/heading';

interface SavedListHeadingProps {
  children: React.ReactNode;
}

export function SavedListHeading(props: SavedListHeadingProps) {
  const { children } = props;

  return <Heading level={2}>{children}</Heading>;
}
