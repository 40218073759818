import React, { useEffect, useMemo } from 'react';
import SyncInvoicesHeader from '../../components/sync-invoices-header';
import Box from '@rexlabs/box';
import ExportInvoices from '../../components/export-invoices';
import { WingsXero } from '../../types/third-party-service-xero';
import ApiError from '../../components/api-error';
import TotalInvoices, {
  TotalInvoicesProps
} from '../../components/total-invoices';
import ImportPayments from '../../components/import-payments';
import { useInvoicesToSync } from '../../hooks/use-invoices-to-sync';
import { usePayments } from '../../hooks/use-payments';
import { filter } from 'lodash';
import Icon, { ICONS } from 'shared/components/icon';
import { Body } from 'components/text/body';
import DefaultButton from 'src/view/components/button/default-button';
import Spinner from 'shared/components/spinner';

const SyncInvoices = ({
  wingsXero,
  onFailed
}: {
  wingsXero: WingsXero;
  onFailed: () => void;
}) => {
  const {
    invoicesSelection,
    setInvoicesSelection,
    ignoredInvoices,
    setIgnoredInvoices,
    invoices,
    isLoading: isInvoicesLoading,
    getInvoices,
    syncInvoices,
    selectedInvoices,
    apiError,
    invoicesPagination,
    syncErrors: invoicesSyncErrors,
    lastSynced,
    invoiceIds
  } = useInvoicesToSync({ wingsXero, onFailed });

  const {
    paymentsSelection,
    setPaymentsSelection,
    ignoredPayments,
    setIgnoredPayments,
    payments,
    getPayments,
    isLoading: isPaymentsLoading,
    selectedPayments,
    apiError: apiPaymentsError,
    paymentsPagination,
    syncPayments,
    syncErrors: paymentsSyncErrors,
    paymentIds
  } = usePayments({ wingsXero, onFailed });

  useEffect(() => {
    getPayments();
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selections: TotalInvoicesProps['selections'] = useMemo(
    () => [
      {
        selection: invoicesSelection,
        setSelection: setInvoicesSelection,
        ignoredSelection: ignoredInvoices
      },
      {
        selection: paymentsSelection,
        setSelection: setPaymentsSelection,
        ignoredSelection: ignoredPayments
      }
    ],
    [
      ignoredInvoices,
      ignoredPayments,
      invoicesSelection,
      paymentsSelection,
      setInvoicesSelection,
      setPaymentsSelection
    ]
  );

  const hasInvoicesOrPayments = invoices.length > 0 || payments.length > 0;

  return (
    <Box width={'100%'}>
      <Box p={25}>
        <SyncInvoicesHeader
          getInvoices={getInvoices}
          syncInvoices={syncInvoices}
          selectedInvoices={selectedInvoices}
          ignoredInvoices={ignoredInvoices}
          getPayments={getPayments}
          syncPayments={syncPayments}
          selectedPayments={selectedPayments}
          ignoredPayments={ignoredPayments}
          hasInvoicesOrPayments={hasInvoicesOrPayments}
          lastSynced={lastSynced}
          wingsXero={wingsXero}
          isLoading={isInvoicesLoading || isPaymentsLoading}
        />

        {!isInvoicesLoading && !isPaymentsLoading ? (
          apiError ? (
            <ApiError
              apiErrors={filter([apiError, apiPaymentsError])}
              onRetry={() => {
                getInvoices(true);
                getPayments(true);
              }}
            />
          ) : hasInvoicesOrPayments ? (
            <>
              {invoices.length > 0 && <TotalInvoices selections={selections} />}

              <Box flexDirection='column' spacing={30}>
                <ExportInvoices
                  invoiceIds={invoiceIds}
                  selection={invoicesSelection}
                  setSelection={setInvoicesSelection}
                  ignoredInvoices={ignoredInvoices}
                  setIgnoredInvoices={setIgnoredInvoices}
                  invoices={invoices}
                  isLoading={isInvoicesLoading}
                  selectedInvoices={selectedInvoices}
                  pagination={invoicesPagination}
                  errors={invoicesSyncErrors}
                />

                <ImportPayments
                  paymentIds={paymentIds}
                  selection={paymentsSelection}
                  setSelection={setPaymentsSelection}
                  ignoredPayments={ignoredPayments}
                  setIgnoredPayments={setIgnoredPayments}
                  payments={payments}
                  selectedPayments={selectedPayments}
                  isLoading={isPaymentsLoading}
                  pagination={paymentsPagination}
                  errors={paymentsSyncErrors}
                />
              </Box>
            </>
          ) : (
            <Box
              flexDirection='column'
              width={'100%'}
              alignItems='center'
              justifyContent='center'
              spacing={20}
            >
              <Icon
                type={ICONS.ROUND_CHECK_BIG}
                width={40}
                height={40}
                hasControlledColor={false}
              />
              <Body normal dark>
                You&apos;re up to date
              </Body>
              <DefaultButton dark onClick={() => getInvoices(true)}>
                <Icon
                  type={ICONS.REFRESH}
                  style={{ display: 'flex', marginRight: 8 }}
                />
                Search for invoices
              </DefaultButton>
            </Box>
          )
        ) : (
          <Box
            flexDirection='row'
            width={'100%'}
            alignItems='center'
            justifyContent='center'
            spacing={20}
            mt={30}
          >
            <Body dark semibold>
              Searching for invoices
            </Body>
            <Spinner small dark />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SyncInvoices;
