import _ from 'lodash';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import Tabs from './tabs';

@autobind
class TabsStateful extends PureComponent {
  static propTypes = {
    initialTab: types.string,
    items: types.array
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.initialTab
        ? props.initialTab
        : _.get(props, 'items.length')
        ? _.get(props, 'items[0].name')
        : null
    };
  }

  onChange({ name }) {
    this.setState({ activeTab: name });
  }

  render() {
    const { nested, ...rest } = this.props;
    return (
      <Tabs
        activeTab={this.state.activeTab}
        onChange={this.onChange}
        {...rest}
        nested={nested}
      />
    );
  }
}

export default TabsStateful;
