import Box from '@rexlabs/box';
import React, { PureComponent, Fragment } from 'react';
import Dialog from 'view/components/dialog';
import { ButtonBar } from 'shared/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { Body } from 'components/text/body';
import { SubHeading } from 'components/text/sub-heading';
import { SectionHeading } from 'components/text/section-heading';
import { styled, StyleSheet } from '@rexlabs/styling';
import PaddingBox from 'view/components/padding-box';
import { FormRenderer } from 'view/components/form';
import { PADDINGS } from 'theme';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import workflowInstances from 'data/models/entities/workflow-instances';
import { withModel } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  actionDescription: {
    paddingBottom: PADDINGS.M
  },
  workflowName: {
    paddingBottom: PADDINGS.S
  },
  buttonBar: {
    padding: PADDINGS.M
  },
  greyBox: {
    marginLeft: `-${PADDINGS.M}`,
    width: 'calc(100% + 40px)'
  }
});

@styled(defaultStyles)
@autobind
class ConfirmWorkflowContent extends PureComponent {
  state = {
    isSubmitting: false
  };

  /**
   * This is called so we can have custom close logic fired from the parent
   * Component.
   */
  handleClose() {
    const { onClose, closeDialog } = this.props;
    onClose && onClose();
    closeDialog();
  }

  handleSubmit(values) {
    const { errorDialog, onSubmit } = this.props;

    return onSubmit(values)
      .then(this.handleClose)
      .catch(({ message }) => errorDialog.open({ message, alwaysOnTop: true }));
  }

  render() {
    const {
      styles: s,
      workflowDescription,
      workflowName,
      formSchema,
      context
    } = this.props;
    const description = _.get(formSchema, 'definition.description');

    return (
      <Fragment>
        <PaddingBox>
          <FormRenderer
            context={context}
            schema={formSchema}
            handleSubmit={this.handleSubmit}
          >
            {({
              form: { submitForm, isSubmitting },
              defaultDescriptionViewProps,
              defaultViewProps,
              DefaultDescriptionView,
              DefaultFormView
            }) => (
              <Fragment>
                <Body normal regular dark {...s('actionDescription')}>
                  This workflow requires input before it can start.
                </Body>
                <PaddingBox light {...s('greyBox')}>
                  <SubHeading semibold {...s('workflowName')}>
                    {workflowName}
                  </SubHeading>
                  {workflowDescription && (
                    <Fragment>
                      <SectionHeading semibold>description</SectionHeading>
                      <Body normal dark>
                        {workflowDescription}
                      </Body>
                    </Fragment>
                  )}
                </PaddingBox>
                {description && (
                  <Box mb={PADDINGS.M}>
                    {/* The description field can include HTML that we need to render so the
                    form-renderer will put this into a sandboxed iframe while copying styles
                    from the parent document to the iframe to ensure consistency */}
                    <DefaultDescriptionView {...defaultDescriptionViewProps} />
                  </Box>
                )}
                <DefaultFormView columns={1} {...defaultViewProps} />
                <ButtonBar isLoading={isSubmitting}>
                  <TextButton blue onClick={this.handleClose}>
                    Cancel
                  </TextButton>
                  <DefaultButton red onClick={submitForm}>
                    Start Workflow
                  </DefaultButton>
                </ButtonBar>
              </Fragment>
            )}
          </FormRenderer>
        </PaddingBox>
      </Fragment>
    );
  }
}

const HALF_DIALOG_HEIGHT = 530 / 2; // px
const TOP = Math.max(window.innerHeight / 2 - HALF_DIALOG_HEIGHT, 0);

@withErrorDialog
@withModel(workflowInstances)
@autobind
class ConfirmWorkflow extends PureComponent {
  static defaultProps = {
    alwaysOnTop: true
  };

  // This is called when the user clicks on the X in the top right corner
  handleClose() {
    const { onClose, closeDialog } = this.props;
    onClose && onClose();
    closeDialog();
  }

  render() {
    const { alwaysOnTop } = this.props;
    return (
      <Dialog
        title={'Start Workflow'}
        closeDialog={this.handleClose}
        width={500}
        hasPadding={false}
        top={TOP}
        alwaysOnTop={alwaysOnTop}
      >
        <ConfirmWorkflowContent {...this.props} />
      </Dialog>
    );
  }
}

export default ConfirmWorkflow;
