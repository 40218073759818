import React, { ComponentType } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { compose } from 'redux';

import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, StylesProps } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';

import notificationModel from 'data/models/custom/notification';

import Icon, { ICONS } from 'shared/components/icon';

import NotificationBadge from 'view/components/navigation/shell/header/components/notification-badge';
import Circle from 'view/components/navigation/shell/header/components/circle';
import Notifications from 'view/components/navigation/shell/header/popouts/notifications';

import { NavigationPopout } from './navigation-popout';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

interface NotificationItem {
  failedMessages?: number;
  myLeads?: number;
  othersLeads?: number;
  pendingMessages?: number;
  teamLeads?: number;
  totalLeads?: number;
  totalNotifications?: any;
  unassignedLeads?: number;
  workflowsAwaitingInput?: number;
}

interface AllNotificationsComponentState {
  liveCounts: {};
}

interface AllNotificationsComponentProps {
  counts: NotificationItem;
  currentAgencyColor: string;
  notification: any;
}

const notificationStyles = StyleSheet({
  circle: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  }
});

@autobind
class AllNotificationsComponent extends React.Component<
  StylesProps<typeof notificationStyles> & AllNotificationsComponentProps,
  AllNotificationsComponentState
> {
  /**
   * Iterates over each key in the counts prop and then checks to see if we have any new updated
   * live count totals available. If we do then we add them together and the new counts are shown
   * to the user.
   */
  get combinedCounts() {
    const { counts } = this.props;
    const { liveCounts } = this.state;

    const combinedCounts = Object.keys(counts).reduce(
      (result, currentCount) => {
        const hasLiveCount = !!liveCounts[currentCount];

        if (!hasLiveCount) {
          return {
            ...result,
            [currentCount]: counts[currentCount]
          };
        }

        if (hasLiveCount) {
          return {
            ...result,
            [currentCount]: counts[currentCount] + liveCounts[currentCount]
          };
        }

        return result;
      },
      {}
    );

    return combinedCounts;
  }

  constructor(props) {
    super(props);

    this.state = {
      liveCounts: {} // Used to store a count of any notifications in the Redux store
    };
  }

  componentDidUpdate(prevProps) {
    const { notification } = this.props;
    const { notification: prevNotification } = prevProps;

    // If the notification have changed lets update the counts
    if (notification.list.length !== prevNotification.list.length) {
      this.updateLiveCounts(notification.list);
    }
  }

  updateLiveCounts(newNotifications) {
    // We only care about WorkflowInstances & if the user can submit them
    const workflowCount = newNotifications.filter(
      (notification) =>
        _.get(notification, 'service_id', '') === 'WorkflowInstances' &&
        _.get(notification, 'data.can_submit', false)
    ).length;

    this.setState({
      liveCounts: {
        ...this.props.counts,
        workflowsAwaitingInput: workflowCount,
        totalNotifications: workflowCount
      }
    });
  }

  render() {
    const { styles: s, currentAgencyColor } = this.props;
    const counts: NotificationItem = this.combinedCounts;

    return (
      <NavigationPopout
        placement={PLACEMENTS.BOTTOM_END}
        Content={({ close }) => (
          <Notifications closePopout={close} {...counts} />
        )}
      >
        <Circle
          {...s('circle')}
          render={() => {
            return (
              <span data-testid='Header.AllNotifications'>
                <NotificationBadge count={counts.totalNotifications} />
                <Icon
                  style={{ color: headerColorContrast(currentAgencyColor) }}
                  type={ICONS.LEADS}
                />
              </span>
            );
          }}
        />
      </NavigationPopout>
    );
  }
}

const AllNotifications = compose<ComponentType<AllNotificationsComponentProps>>(
  styled(notificationStyles),
  withModel(notificationModel)
)(AllNotificationsComponent);

export default AllNotifications;
