import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const actionCreators = {
  getWashObjectsForEachContact: {
    request: ({ contactIds }) =>
      api.post('WashLists::getMailAddressWashObjectsForEachContact', {
        contact_ids: contactIds
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getMailAddressOverride: {
    request: ({ nameFirst, nameLast, address }) =>
      api.post('WashLists::getMailAddressOverride', {
        name_first: nameFirst,
        name_last: nameLast,
        address
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getPhoneNumberOverride: {
    request: ({ phoneNumber }) =>
      api.post('WashLists::getPhoneNumberOverride', {
        phone_number: phoneNumber
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  setMailAddressOverride: {
    request: ({ nameFirst, nameLast, address, reason, status }) =>
      api.post('WashLists::setMailAddressOverride', {
        name_first: nameFirst,
        name_last: nameLast,
        address,
        reason,
        status
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  setPhoneNumberOverride: {
    request: ({ phoneNumber, reason, status }) =>
      api.post('WashLists::setPhoneNumberOverride', {
        phone_number: phoneNumber,
        reason,
        status
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  removeMailAddressOverride: {
    request: ({ nameFirst, nameLast, address }) =>
      api.post('WashLists::removeMailAddressOverride', {
        name_first: nameFirst,
        name_last: nameLast,
        address
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  removePhoneNumberOverride: {
    request: ({ phoneNumber }) =>
      api.post('WashLists::removePhoneNumberOverride', {
        phone_number: phoneNumber
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('washLists').createModel({
  actionCreators
});
