export default {
  hexToHSL: (hexColor, dimmingPercentage) => {
    if (!hexColor) return null;

    // Hex to RGB
    hexColor = hexColor.replace('#', '');
    const bigint = parseInt(hexColor, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const rgbArr = [r, g, b];

    // RGB to HSL
    const r1 = rgbArr[0] / 255;
    const g1 = rgbArr[1] / 255;
    const b1 = rgbArr[2] / 255;

    const maxColor = Math.max(r1, g1, b1);
    const minColor = Math.min(r1, g1, b1);
    // Calculate L:
    let L = (maxColor + minColor) / 2;
    let S = 0;
    let H = 0;
    // eslint-disable-next-line
    if (maxColor != minColor) {
      // Calculate S:
      if (L < 0.5) {
        S = (maxColor - minColor) / (maxColor + minColor);
      } else {
        S = (maxColor - minColor) / (2.0 - maxColor - minColor);
      }
      // Calculate H:
      // eslint-disable-next-line
      if (r1 == maxColor) {
        H = (g1 - b1) / (maxColor - minColor);
        // eslint-disable-next-line
      } else if (g1 == maxColor) {
        H = 2.0 + (b1 - r1) / (maxColor - minColor);
      } else {
        H = 4.0 + (r1 - g1) / (maxColor - minColor);
      }
    }

    L = L * 100;
    S = S * 100;
    H = H * 60;
    if (H < 0) {
      H += 360;
    }

    return `hsl(${H}, ${S}%, ${L - dimmingPercentage}%)`;
  },
  intersperse: (arr = [], delim = ', ') =>
    arr
      .map((x) => [delim, x])
      .reduce((a, b) => a.concat(b), [])
      .slice(1)
};
