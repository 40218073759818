import React, { ReactElement, ReactNode } from 'react';
import Container from './container';
import ContentContainer from './content-container';
import LoadingSpinner from './loading-spinner';
import HeadingWithButton from 'view/components/heading-with-button';

import { ICONS } from 'shared/components/icon';
import useHoverToggle from 'src/hooks/use-hover-toggle';

/**
 * @deprecated This `Widget` component is deprecated and
should be avoided as much as possible. Consider using `ActionWidget` instead.
 */
interface WidgetProps {
  heading: ReactNode;
  iconButtonOnClick?: Function;
  children: ReactNode;
  iconButtonIcon?: any;
  isLoading?: boolean;
}

function RightBarWidget({
  heading,
  iconButtonIcon = ICONS.ADD,
  iconButtonOnClick,
  isLoading = false,
  children
}: WidgetProps): ReactElement {
  const { hoverProps, hover } = useHoverToggle();

  return (
    <Container {...hoverProps} data-testid={'Widget.Container'}>
      <HeadingWithButton
        heading={heading}
        showButton={!isLoading && hover}
        iconButtonIcon={iconButtonIcon}
        iconButtonOnClick={iconButtonOnClick}
        buttonProps={{ 'data-testid': 'Widget.IconButton' }}
      />
      <ContentContainer>
        {isLoading ? <LoadingSpinner /> : children}
      </ContentContainer>
    </Container>
  );
}

export default RightBarWidget;
