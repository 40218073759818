import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';

import { Heading } from 'components/text/heading';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  container: {
    whiteSpace: 'nowrap'
  },
  heading: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export function RecordPreviewHeading({
  children
}: {
  children: React.ReactNode;
}) {
  const s = useStyles(defaultStyles);
  return (
    <Box h={20} w={220} {...s('container')}>
      <Heading {...s('heading')} level={2}>
        {children}
      </Heading>
    </Box>
  );
}
