import React, { PureComponent } from 'react';
import Option from './core';
import Box from '@rexlabs/box';
import { Input } from 'components/text/input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    ...TEXTS.SETTINGS_NAVIGATION.MENU_ITEM,
    padding: '4px',
    paddingLeft: '10px',

    '&:hover': {
      backgroundColor: '#CAE8F8',
      cursor: 'pointer'
    }
  },

  suffix: {
    marginLeft: '5px'
  }
});

@styled(defaultStyles)
class SuffixOption extends PureComponent {
  render() {
    const { styles: s, option, ...rest } = this.props;
    const { label, suffix } = option;
    return (
      <Option {...rest}>
        <Box {...s('container')} alignItems='center'>
          <Input>{label}</Input>
          {suffix && (
            <Input light italic semibold {...s('suffix')}>
              {suffix}
            </Input>
          )}
        </Box>
      </Option>
    );
  }
}

export default SuffixOption;
