import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { get, isEmpty } from 'lodash';
import { compose } from 'redux';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  match,
  RenderMatchedRoutes,
  useWhereabouts
} from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import { ToastTarget } from '@rexlabs/toast';

import ROUTES from 'routes/app';
import { COLORS } from 'src/theme';
import { withPermissions } from 'hocs/with-permissions';
import { openDialogsFromHash } from 'utils/routing';
import cacheModel, { populateCache } from 'data/models/custom/api-cache';
import ShellDialogs from 'data/classic-bridges/dialogs-shell';
import ShellOverlays from 'data/classic-bridges/overlays';
import ClassicDialogs from 'data/classic-bridges/dialogs-classic';
import navModel from 'data/models/custom/nav';
import uiModel from 'data/models/custom/ui';
import withLoggedInUser from 'view/containers/with-logged-in-user';
import ShellNavigation from 'view/components/navigation/shell';
import DialogOverlayStack from 'view/components/dialog-overlay-stack';
import { DialogOverlayPortal } from 'view/components/portal';
import ErrorBoundary from 'view/components/error-boundary';
import LagRadar from 'view/components/lag-radar';
import ClassicRexScreen from 'view/screens/classic-rex';
import ErrorScreen from 'view/screens/error';
import Requires from 'view/containers/requires';

import Analytics from 'shared/utils/vivid-analytics';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import ClassicStepthroughFrame from 'view/classic-bridges/stepthrough-frame';
import Box from '@rexlabs/box';

const Dialogs = { ...ShellDialogs, ...ShellOverlays, ...ClassicDialogs };

const WorkflowNotificationToast = lazy(() =>
  import('view/components/toast/workflow')
);

const defaultStyles = StyleSheet({
  container: {
    boxSizing: 'border-box',
    backgroundColor: COLORS.WARM_GREY,
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch'
  },

  wrapScreens: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',

    '& > div:nth-child(2)': {
      width: '100%',
      height: '100%',
      position: 'absolute'
    }
  },

  errorBoundary: {
    fontSize: '17px',
    lineHeight: '28px',

    '& a': {
      color: '#2aa2e3'
    }
  },

  workflowNotifications: {
    position: 'fixed',
    top: 90,
    right: 20,
    zIndex: 13
  },

  hide: {
    display: 'none'
  },

  announcementToast: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2000
  },

  content: {
    height: '100%',
    transition:
      'transform .25s cubic-bezier(0.25,0.1,0.25,1), width .25s cubic-bezier(0.25,0.1,0.25,1)',
    transform: 'translate(60px, 0)',
    width: 'calc(100% - 60px)',
    overflow: 'hidden'
  },

  contentNavExtended: {
    transform: 'translate(200px, 0)',
    width: 'calc(100% - 200px)'
  },

  wrapScreen: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },

  screen: {
    height: '100%'
  },

  screenWithStepthrough: {
    height: 'calc(100% - 145px)'
  },
  screenWithMinimizedStepthrough: {
    height: '100%'
  },

  stepthrough: {
    height: '145px', // sourced from the classic implementation
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    margin: 0,
    padding: 0
  },

  stepthroughMinimized: {
    height: '53px', // sourced from the classic implementation
    width: '460px', // calculated from the classic implementation: 335+80+20+25,
    left: 'initial' // Make sure the stepthrough is right aligned
  },

  stepthroughOverflowed: {
    height: 'calc(145px + 220px)'
  },

  stepthroughOverflowedMinimized: {
    width: 'calc(460px + 200px)'
  }
});

const ANIMATION_BUFFER_MS = 300;

const decorate = compose(
  withLoggedInUser,
  withModel(cacheModel),
  withModel(navModel),
  withModel(uiModel),
  withPermissions
);

function ShellApp({ cache, session, ui, nav }) {
  const s = useStyles(defaultStyles);
  const [hasMounted, setMounted] = useState(false);

  const realWhereabouts = useWhereabouts();
  const whereabouts = useWhereaboutsWithViewpath();

  const isStepthrough = match(realWhereabouts, { path: '/stepthrough' });

  const [isOverflowing, setOverflowing] = useState(false);
  const [isMinimized, setMinimized] = useState(false);

  useEffect(
    () => {
      populateCache(cache);
      setTimeout(() => setMounted(true), ANIMATION_BUFFER_MS);
      openDialogsFromHash(whereabouts, Dialogs);

      if (!get(session, 'user_details.segmentation_role')) {
        Dialogs.userSegmentation.open();
      }

      Analytics.performance({
        task: 'Shell-FullInit',
        event: 'shellRoutingStart'
      });
    },
    // eslint-disable-next-line
    []
  );

  const Placeholder = useCallback(
    () => (
      <div {...s('container')}>
        <ShellNavigation />
        <ErrorScreen />
      </div>
    ),
    [s]
  );

  const enhanceRoutes = useCallback(
    (routes) =>
      Object.keys(routes).reduce((all, key) => {
        const route = routes[key] || {};
        const { config, ...otherRoutes } = route;

        all[key] = { config };

        if (typeof config === 'function') {
          // NOTE: this is where we're enhancing the routes, if we need other
          // information like the session/region/etc it would need to be
          // added here
          all[key] = { config: config({ hasFlags: hasFeatureFlags }) };
        }

        if (
          all[key].config?.enabled !== undefined &&
          !all[key].config.enabled
        ) {
          delete all[key];
          return all;
        }

        if (otherRoutes) {
          all[key] = { ...all[key], ...enhanceRoutes(otherRoutes) };
        }

        return all;
      }, {}),

    []
  );

  const enhancedRoutes = useMemo(() => enhanceRoutes(ROUTES), [enhanceRoutes]);

  return (
    <div {...s('container')}>
      <Requires countries={['EU']} addons={['workflows']}>
        <div {...s({ hide: !hasMounted })}>
          <div {...s('workflowNotifications')}>
            <ToastTarget name='workflowNotifications' />
          </div>

          <Suspense fallback={null}>
            <WorkflowNotificationToast />
          </Suspense>
        </div>
      </Requires>

      <ErrorBoundary Placeholder={Placeholder}>
        <DialogOverlayPortal />

        <ShellNavigation />

        <Box {...s('content', { contentNavExtended: nav.isOpen })}>
          <Box {...s('wrapScreen')}>
            <Box
              {...s('screen', {
                screenWithStepthrough: isStepthrough,
                screenWithMinimizedStepthrough: isStepthrough && isMinimized
              })}
            >
              <RenderMatchedRoutes
                location={whereabouts}
                routes={enhancedRoutes}
                NotFound={ClassicRexScreen}
              >
                {(matches) => matches}
              </RenderMatchedRoutes>
            </Box>

            {isStepthrough && (
              <ClassicStepthroughFrame
                {...s('stepthrough', {
                  stepthroughMinimized: isMinimized,
                  stepthroughOverflowed: isOverflowing,
                  stepthroughOverflowedMinimized: isOverflowing && isMinimized
                })}
                whereabouts={realWhereabouts}
                isOverflowed={isOverflowing}
                isMinimized={isMinimized}
                onUpdateOverflow={(state, callback) =>
                  setOverflowing(state) && callback()
                }
                onUpdateMinimized={(state, callback) =>
                  setMinimized(state) && callback()
                }
              />
            )}
          </Box>
        </Box>

        <DialogOverlayStack />

        {__DEV__ && ui.showLagRadar && <LagRadar />}
      </ErrorBoundary>
    </div>
  );
}

export default decorate(ShellApp);
