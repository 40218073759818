import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'view/components/button';

import { PaginationPopout } from './popouts/pagination-popout';
import { Pagination } from './types';

const ArrowIcon: any = ICONS.ARROW_RIGHT;

const defaultStyles = StyleSheet({
  info: {
    color: '#424242',
    marginRight: '6px',
    border: '2px solid transparent',
    background: 'transparent',
    font: 'inherit',
    fontSize: '14px',
    fontSeight: 400,
    padding: '5px',
    transition: 'border 0.3s',
    cursor: 'pointer',

    '&:hover': {
      borderColor: '#b4b1a9'
    }
  },

  icon: {
    color: '#fff'
  },

  iconLeft: {},

  iconRight: {
    transform: 'rotate(180deg)'
  }
});

interface RecordListPaginationProps {
  pagination: Pagination;
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
}

export function RecordListPagination({
  pagination,
  limit,
  setLimit,
  page,
  setPage
}) {
  const s = useStyles(defaultStyles);

  const first = page * limit + 1;
  const last = Math.min((page + 1) * limit, pagination?.totalItems);

  const prevDisabled = page <= 0;
  const nextDisabled = page >= pagination?.totalPages - 1;

  if (!pagination?.totalPages) {
    return null;
  }

  return (
    <Box flexDirection='row' alignItems='center' sx='4px'>
      <PaginationPopout
        pagination={pagination}
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
      >
        <button {...s('info')}>
          {first} - {last} of {pagination?.totalItems}
        </button>
      </PaginationPopout>
      <DefaultButton
        padding={false}
        isDisabled={prevDisabled}
        onClick={() => setPage(page - 1)}
      >
        <ArrowIcon {...s('icon', 'iconLeft')} />
      </DefaultButton>
      <DefaultButton
        padding={false}
        isDisabled={nextDisabled}
        onClick={() => setPage(page + 1)}
      >
        <ArrowIcon {...s('icon', 'iconRight')} />
      </DefaultButton>
    </Box>
  );
}
