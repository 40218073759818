import React from 'react';
import _ from 'lodash';

import Box, { BoxProps } from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

import { PADDINGS } from 'src/theme';

import { InputLabel } from 'components/text/input-label';
import { Body } from 'components/text/body';

// Due to the specific requirements of the design, when using a non-default line height (default is 17px) in your value,
// you may need to nudge the value up slightly so that the baseline of your text aligns with the label.
// The default offset is 5px, larger line heights require a smaller offset.

// this has no effect when the value isn't inline with the label

export const TEXT_PRESETS = {
  DEFAULT: 'Default',
  MEDIUM: 'Medium',
  LARGE: 'Large'
} as const;

const lineHeightOffsets = {
  Default: '5px', // for line height 17px
  Medium: '4px', // for line height 20px
  Large: '2px' // for line height 22px
};

const presetValues = Object.values(TEXT_PRESETS);
type TextPreset = (typeof presetValues)[number];
interface LabelledValueProps extends BoxProps {
  label: React.ReactNode;
  children?: React.ReactNode;
  /**
   * optionally, you may set the label width manually so that when using multiple instances of this component
   * the labels occur in a column (of labelWidth width) with the values aligned in a column next to them
   */
  labelWidth?: number;
  /**
   * The design component allows for the label to be next to the value or above it.
   */
  inline?: boolean;
  textPreset?: TextPreset;
}

interface ValueProps {
  children?: React.ReactNode;
  textPreset?: TextPreset;
}

const defaultStyles = StyleSheet({
  // when used in a classic r3 component, we inherit some different styles
  // so I'm locking this down here.
  // to be clear, it's the div wrapping the label rather than the text component
  // that is inheriting the wrong styles
  labelWrapper: { lineHeight: '12px' }
});

function ValuePresetText({
  children,
  textPreset = TEXT_PRESETS.DEFAULT
}: ValueProps) {
  if (!children) {
    return (
      <Box pt={PADDINGS.XXS}>
        <Body dark>&mdash;</Body>
      </Box>
    );
  }

  return <Box pt={lineHeightOffsets[textPreset]}>{children}</Box>;
}

function ValueMediumText({ children }: { children?: React.ReactNode }) {
  return (
    <ValuePresetText textPreset={TEXT_PRESETS.MEDIUM}>
      {children}
    </ValuePresetText>
  );
}

function ValueLargeText({ children }: { children?: React.ReactNode }) {
  return (
    <ValuePresetText textPreset={TEXT_PRESETS.LARGE}>
      {children}
    </ValuePresetText>
  );
}

function ValueDefault({ children }: { children?: React.ReactNode }) {
  if (!children) {
    return (
      <Box pt={PADDINGS.XXS}>
        <Body dark>&mdash;</Body>
      </Box>
    );
  }

  if (_.isString(children)) {
    return (
      <Box pt={PADDINGS.XXS}>
        <Body dark>{children}</Body>
      </Box>
    );
  }

  return <ValuePresetText>{children}</ValuePresetText>;
}

const TextPresetComponents = {
  Default: ValuePresetText,
  Medium: ValueMediumText,
  Large: ValueLargeText
};

export function LabelledValue({
  label,
  inline = true,
  children,
  labelWidth,
  textPreset,
  ...boxProps
}: LabelledValueProps) {
  const s = useStyles(defaultStyles);

  const ValueComponent = textPreset
    ? TextPresetComponents[textPreset]
    : ValueDefault;

  return (
    <Box
      flexDirection={inline ? 'row' : 'column'}
      alignItems='flex-start'
      {...boxProps}
    >
      <Box
        pt={PADDINGS.XXS}
        pr={PADDINGS.XXS}
        width={labelWidth}
        {...s('labelWrapper')}
        alignItems={'flex-start'}
      >
        <InputLabel formField>{label}</InputLabel>
      </Box>
      <ValueComponent>{children}</ValueComponent>
    </Box>
  );
}
