import _ from 'lodash';

class UIMiddleware {
  constructor(store) {
    this.store = store;
  }

  middleware(next) {
    return (action) => {
      if (this.shouldResetSaveCancelOverlay(action)) {
        this.resetSaveCancelOverlay();
      }

      if (window.Rex2IFrame && this.shouldHideRexFrame(action)) {
        window.Rex2IFrame.style.visibility = 'hidden';
      }

      if (window.Rex2IFrame && this.shouldShowRexFrame(action)) {
        window.Rex2IFrame.style.visibility = 'visible';
      }

      return next(action);
    };
  }

  shouldResetSaveCancelOverlay(action) {
    const { type } = action;
    return (
      type === 'whereabouts/PUSH' &&
      _.get(this.store.getState(), 'ui.saveCancelOverlayState', 'OFF') !== 'OFF'
    );
  }

  resetSaveCancelOverlay() {
    this.store.dispatch({
      type: 'ui/RESET_SAVE_CANCEL_CONTEXT'
    });
    this.store.dispatch({
      type: 'ui/UPDATE_SAVE_CANCEL_OVERLAY_STATE',
      payload: 'OFF'
    });
  }

  shouldShowRexFrame(action) {
    const { type } = action;
    return type === 'ui/LOADING_INDICATOR_OFF';
  }

  shouldHideRexFrame(action) {
    const { type } = action;

    const currentLocation = _.get(this.store.getState(), 'whereabouts.current');
    const currentPath = _.get(
      this.store.getState(),
      `whereabouts.locations.${currentLocation}.path`
    );
    const hasDifferentPath = currentPath !== window.parent.location.pathname;

    return type === 'whereabouts/PUSH' && hasDifferentPath;
  }
}

function uiMiddleware(store) {
  const instance = new UIMiddleware(store);
  return instance.middleware.bind(instance);
}

export default uiMiddleware;
