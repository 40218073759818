import { useMemo } from 'react';
import { ListingRelatedContactsWidget } from '../widgets/related-contacts-widget';

export function useListingWidgets() {
  return useMemo(
    () => ({
      default: [{ Component: ListingRelatedContactsWidget }]
    }),
    []
  );
}
