import React from 'react';
import { AiPopoutContents } from 'features/ai/components/primitives/ai-popout-contents';
import { AiPopout } from 'features/ai/components/primitives/ai-popout';
import { RefinementType } from 'features/ai/data/refinement-type';
import _ from 'lodash';

type AudiencePopoutProps = {
  onRefinement: (refinementType: RefinementType) => void;
  children: React.ReactNode;
  audience: RefinementType | null;
};
export function AudiencePopout({
  onRefinement,
  audience,
  children
}: AudiencePopoutProps) {
  return (
    <AiPopout
      renderContent={({ close }) => (
        <AiPopoutContents
          minWidth={250}
          items={Object.values(RefinementType)
            .filter((r) => r.startsWith('audience_'))
            .map((r) => ({
              isChecked: r === audience,
              label: _.startCase(r.replaceAll('audience_', '')),
              onClick: () => {
                onRefinement(r);
                close();
              }
            }))}
        />
      )}
    >
      {children}
    </AiPopout>
  );
}
