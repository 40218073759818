import { Id, ModelGeneratorLoaded } from '@rexlabs/model-generator';
import { Generator } from 'shared/utils/models';
import { UserItemCopy, UserItem } from 'data/models/value-lists/account-users';
import { ContactStub } from './contacts';
import { ListingStub } from './listings';
import { MarketLeadStub } from './market-leads';
import { ProjectStub } from './projects';
import { PropertyStub } from './properties';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';
import { ValueListItem } from '../value-lists/value-list';
import { AxiosResponse } from 'axios';

export type ReminderModel = ModelGeneratorLoaded<ReminderItem, any>;

export interface ReminderApprovalEvent {
  event_type: ValueListItem;
  note: string | null;
  system_created_user: UserItemCopy;
  _id: string;
  system_ctime: string;
}

// Note: Most of this typing is a guess - please update me if you come across issues.
export interface ReminderItem {
  calendar_event_id: Id | null;
  contact: ContactStub | null;
  etag: Id;
  listing: ListingStub | null;
  mail_merge_event: Id | null;
  market_lead: MarketLeadStub | null;
  priority: { id: string; text: string };
  project: ProjectStub | null;
  project_stage: ProjectStub | null;
  property: PropertyStub | null;
  recur_after_mode: string | null;
  recur_frequency: number | null;
  recur_month_day: string | null;
  recur_month_type: string | null;
  recur_month_week_number: number | null;
  recur_stop_after_date: number | null;
  recur_unit: number | null;
  recur_week_day: string | null;
  recurring_reminder_id: Id | null;
  remindee: UserItem;
  reminder_date: string;
  reminder_description: string;
  reminder_type: { id: string; text: string };
  scheduled_send: any;
  scheduled_send_connection_id: Id;
  scheduled_send_from_user: UserItem | null;
  scheduled_send_status: any;
  scheduled_send_time: number | null;
  system_completed_by_user: UserItem | null;
  system_completed_time: number | null;
  system_created_user: UserItem;
  system_ctime: number | null;
  system_modified_user: UserItem;
  system_modtime: number | null;
  system_record_state: string;
  template: Id | null;
  track: Id | null;
  track_instance_id: Id | null;
  deferred: boolean | null;
  id: string;
  is_approver?: boolean;
  cannot_approve?: boolean;
  cannot_request_approval: boolean;
  is_remindee?: boolean;
  completed?: boolean;
  _id?: string;
  approval_status?: ValueListItem | null;
  _related: {
    reminder_approval_events: ReminderApprovalEvent[];
  };
}

export interface ReminderActionRequest {
  id: string;
  note: string | null;
}

const TYPE = 'reminders';
const REDUCE = {
  initial: identity,
  success: identity,
  failure: identity
};

export interface RemindersModel {
  approve: (args: ReminderActionRequest) => Promise<unknown>; // need to replace
  requestApproval: (
    args: ReminderActionRequest
  ) => Promise<AxiosResponse<{ result: number }>>; // need to replace
  reject: (payload: ReminderActionRequest) => Promise<unknown>; // need to replace
}

const actionCreators = {
  approve: {
    request: (payload: ReminderActionRequest) =>
      api
        .post('Reminders::approve', payload)
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: REDUCE
  },
  requestApproval: {
    request: (payload: ReminderActionRequest) =>
      api
        .post('Reminders::requestApproval', payload)
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: REDUCE
  },
  reject: {
    request: (payload: ReminderActionRequest) =>
      api
        .post('Reminders::reject', payload)
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: REDUCE
  }
};

const remindersModel = new Generator<ReminderItem>(TYPE).createEntityModel({
  actionCreators
});

export default remindersModel;
