import React, { PureComponent } from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import RexBasicCell from './basic';
import Icon from 'shared/components/icon';

const defaultStyles = StyleSheet({
  container: {}
});

const iconStyles = {
  container: {
    marginLeft: '0.5rem',
    display: 'inline-block',
    verticalAlign: 'middle'
  }
};

@styled(defaultStyles)
class RexIconCell extends PureComponent {
  render() {
    const { text, iconType, align, ...props } = this.props;
    return (
      <RexBasicCell align={align} {...props}>
        {text} <Icon styles={iconStyles} type={iconType} />
      </RexBasicCell>
    );
  }
}

export default RexIconCell;
