import React from 'react';

import Spinner from 'shared/components/spinner';

import { DataStateContainer } from './data-state-container';

export function SavedListLoadingSate() {
  return (
    <DataStateContainer>
      <Spinner small dark />
    </DataStateContainer>
  );
}
