import React, { CSSProperties } from 'react';
import { TextInput, TextInputProps } from '@rexlabs/text-input';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { Input } from 'components/text/input';
import { COLORS, PADDINGS } from 'src/theme';
import { formatNumber } from 'shared/utils/formatters';

const inputStyles = (width: CSSProperties['width']) => ({
  TextInput: StyleSheet({
    prefix: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: PADDINGS['3XS'],
      background: COLORS.WHITE,
      position: 'absolute',
      width: width || '90%',
      '& span': {
        width: '100%'
      }
    }
  })
});

const inputStylesDisabled = (width: CSSProperties['width']) => ({
  TextInput: StyleSheet({
    prefix: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: PADDINGS['3XS'],
      background: COLORS.BACKGROUNDS.SAND,
      position: 'absolute',
      width: width || '90%',
      '& span': {
        width: '100%'
      }
    }
  })
});

type PercentageInputProps = TextInputProps & {
  decimals: number;
  meta?: {
    focused?: boolean;
  };
  width?: CSSProperties['width'];
};

function PercentageInput({
  disabled,
  value,
  decimals,
  width,
  ...rest
}: PercentageInputProps) {
  return (
    <StylesProvider
      components={disabled ? inputStylesDisabled(width) : inputStyles(width)}
      prioritiseParentStyles={false}
    >
      <TextInput
        {...rest}
        value={value}
        pattern='[0-9]*'
        prefix={
          (!rest?.meta?.focused ? (
            value !== null ? (
              <Input>{`${formatNumber(value, decimals)}%`}</Input>
            ) : undefined
          ) : undefined) as any
        }
      />
    </StylesProvider>
  );
}

export default PercentageInput;
