export class AIFailureError extends Error {
  public explanation: string;
  constructor(message = 'AI failed to generate a response due to bad inputs') {
    super(message);
    this.name = 'AIFailureError';
    this.explanation =
      'When using AI, you may not get a response if your prompts are off-topic, ask for something' +
      'impossible, or are hard to understand. Try rephrasing your prompts to be clear and specific.';
  }
}
