import { useCallback } from 'react';

import { useDialog } from 'hooks/use-dialog';

import { EditCustomReportProps } from '../dialogs/edit-custom-report';

export function useEditCustomReportDialog({
  customReportId
}: EditCustomReportProps) {
  const editCustomReport: {
    open: (props: EditCustomReportProps) => void;
  } = useDialog('editCustomReport');

  const openEditCustomReportDialog = useCallback(() => {
    editCustomReport.open({
      customReportId
    });
  }, [customReportId, editCustomReport]);

  return {
    openEditCustomReportDialog
  };
}
