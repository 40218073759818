import React, { useMemo, ReactNode } from 'react';
import { compose } from 'redux';

import { Id, query, useEntityQuery } from '@rexlabs/model-generator';

import listingsModel from 'data/models/entities/listings';

import { ICONS } from 'shared/components/icon';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import { Popout } from 'view/components/popout';

import { useWhichWord } from 'hooks/use-which-word';

import { RecordPreviewImage } from 'components/record-preview/record-preview-image';
import { RecordPreviewRecordType } from 'components/record-preview/record-preview-record-type';
import { RecordPreviewHeading } from 'components/record-preview/record-preview-heading';
import { RecordPreviewSubheading } from 'components/record-preview/record-preview-subheading';
import { RecordPreviewGreyTag } from 'components/record-preview/record-preview-grey-tag';
import { RecordPreviewContentContainer } from 'components/record-preview/record-preview-content-container';
import {
  RecordPreviewActionBar,
  RecordPreviewActionBarMoreMenu
} from 'components/record-preview/record-preview-action-bar';
import { RecordPreviewLoadingSpinner } from 'components/record-preview/record-preview-loading-spinner';
import { RecordPreviewAddNoteAction } from 'components/record-preview/actions/record-preview-add-note-action';
import { RecordPreviewAddReminderAction } from 'components/record-preview/actions/record-preview-add-reminder-action';
import { RecordPreviewViewButton } from 'components/record-preview/record-preview-view-button';
import { RecordPreviewBlackTag } from 'components/record-preview/record-preview-black-tag';
import { RecordPreviewAddAppoinmentAction } from 'components/record-preview/actions/record-preview-add-appointment-action';
import { useSendEmail } from 'components/record-preview/actions/record-preview-send-email';
import { useSendSMS } from 'components/record-preview/actions/record-preview-send-sms';
import { useAddFeedback } from 'components/record-preview/actions/record-preview-add-feedback';
import { RecordPreviewContainer } from 'components/record-preview/record-preview-container';
import { RecordPreviewLatestNote } from 'components/record-preview/record-preview-latest-note';

import { getListingPreviewPopoutData } from './utils';
import { RecordPreviewTagContainer } from 'components/record-preview/record-preview-tag-container';
import { capitalize } from 'lodash';
import { useRegion } from 'hooks/use-region';

interface ListingPreviewPopoutProps {
  id: Id;
}

const getListingQuery = (listingId) => {
  return query`{
  ${listingsModel} (id: ${listingId}) {
    id
    contact_name
    system_label
    lead_status
    contact
    property
    listing_category
    system_listing_state
    related
    system_publication_status,
    security_user_rights
  }
}`;
};

export function ListingPreviewPopoutCoreComponent({
  id,
  errorDialog
}: ListingPreviewPopoutProps & { errorDialog: ErrorDialog }) {
  const listingQuery = useMemo(
    () => (id && getListingQuery(id)) || undefined,
    [id]
  );
  const listings = useEntityQuery(listingQuery);
  const region = useRegion();
  const listing = listings?.data;

  const isLoading = listings.status !== 'loaded';
  const hasReadRights = listing?.security_user_rights?.includes('read');

  const viewPath = `/listings/#id=${id}`;

  const {
    listingImage,
    recordTypeHeading,
    listingStatus,
    isCurrent,
    isDraft,
    streetAddress,
    suburb,
    newNoteData,
    newReminderData,
    newAppointmentData,
    newMailMergeData
  } = useMemo(
    () => getListingPreviewPopoutData({ listing, region }),
    [listing, region]
  );

  const onSendEmailClick = useSendEmail({ record: newMailMergeData });

  const onSendSMSClick = useSendSMS({ record: newMailMergeData });

  const onAddFeedbackClick = useAddFeedback({
    feedbackData: { listing },
    listingId: id,
    errorDialog
  });

  const listingStatusWord = useWhichWord('leased');

  const localListingStatus =
    listingStatus === 'leased' ? listingStatusWord : listingStatus;

  return (
    <RecordPreviewContainer>
      <RecordPreviewContentContainer
        leftColumn={
          <>
            <RecordPreviewImage
              height={75}
              recordImage={listingImage}
              placeHolderIcon={ICONS.LISTINGS}
            />
            <RecordPreviewViewButton viewPath={viewPath}>
              View
            </RecordPreviewViewButton>
          </>
        }
        rightColumn={
          <>
            <RecordPreviewRecordType>
              {recordTypeHeading}
            </RecordPreviewRecordType>
            {isLoading ? (
              <RecordPreviewLoadingSpinner />
            ) : (
              <>
                <RecordPreviewHeading>{streetAddress}</RecordPreviewHeading>
                <RecordPreviewSubheading>{suburb}</RecordPreviewSubheading>
                <RecordPreviewTagContainer>
                  {isCurrent && listingStatus && (
                    <RecordPreviewGreyTag>
                      {capitalize(localListingStatus)}
                    </RecordPreviewGreyTag>
                  )}
                  {!isCurrent && listingStatus && (
                    <RecordPreviewBlackTag>
                      {capitalize(localListingStatus)}
                    </RecordPreviewBlackTag>
                  )}
                  {isDraft && (
                    <RecordPreviewGreyTag>Draft</RecordPreviewGreyTag>
                  )}
                </RecordPreviewTagContainer>
                <RecordPreviewLatestNote id={id} serviceName='Listings' />
              </>
            )}
          </>
        }
      />
      {/* TODO: Really need to reduce the number of conditions here to show this bar.
      https://app.shortcut.com/rexlabs/story/64802/record-popout-preview-clean-up-actions-so-less-conditions-to-render-the-action-bar */}
      <RecordPreviewActionBar>
        {hasReadRights && !isLoading && (
          <>
            {newNoteData && (
              <RecordPreviewAddNoteAction noteData={newNoteData} />
            )}
            {newReminderData && (
              <RecordPreviewAddReminderAction reminderData={newReminderData} />
            )}
            {newAppointmentData && (
              <RecordPreviewAddAppoinmentAction
                appointmentData={newAppointmentData}
              />
            )}
            <RecordPreviewActionBarMoreMenu
              items={[
                {
                  label: 'Send Email',
                  onClick: onSendEmailClick
                },
                {
                  label: 'Send SMS',
                  onClick: onSendSMSClick
                },
                {
                  label: 'Add Feedback',
                  onClick: onAddFeedbackClick
                }
              ]}
            />
          </>
        )}
      </RecordPreviewActionBar>
    </RecordPreviewContainer>
  );
}

export const ListingPreviewPopoutCore = compose<
  React.ComponentType<ListingPreviewPopoutProps>
>(withErrorDialog)(ListingPreviewPopoutCoreComponent);

export function ListingPreviewPopout({
  id,
  children
}: {
  id: Id;
  children: ReactNode;
}) {
  // HACK: current version of popout doesn't let us manage state and causes rerender
  // loop if we don't memoize.
  // https://app.shortcut.com/rexlabs/story/58232/popouts-the-vivid-popout-component-cannot-maintain-state-within-the-popout-content
  const PopoutContent = useMemo(
    () => () => <ListingPreviewPopoutCore id={id} />,
    [id]
  );

  return (
    <Popout distance='11px' Content={PopoutContent}>
      {children}
    </Popout>
  );
}
