import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'contactAntiMoneyLaunderingChecks';

const actionCreators = {
  manualVerifyToggle: {
    request: ({ id, verified }) =>
      api.post('ContactAntiMoneyLaunderingChecks::toggleManualVerification', {
        contact_identity_verification_id: id,
        verified
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  submitExternalCheck: {
    request: ({ id, serviceId }) =>
      api.post('ContactAntiMoneyLaunderingChecks::submitExternalCheck', {
        contact_identity_verification_id: id,
        connection_id: Number(serviceId)
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const amlChecksModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default amlChecksModel;
