import { FieldDefinition } from 'features/custom-reporting/modules/module-config-types';

import { useCurrentModuleConfig } from 'features/custom-reporting/modules/module-provider';

type FilterType = 'date' | 'user' | 'location';

export function useAvailableFields(filterType: FilterType) {
  const { topLevelFilters } = useCurrentModuleConfig();
  return topLevelFilters?.[filterType]?.availableFields || [];
}

export function useAvailableFieldsSelectOptions(filterType: FilterType) {
  return useAvailableFields(filterType).map((field) => ({
    label: field.label,
    value: field.fieldName
  }));
}

export function useFilterConfigItem({
  filterType,
  fieldPreference
}: {
  filterType: FilterType;
  fieldPreference?: string;
}) {
  const availableFields = useAvailableFields(filterType);

  const selectedFieldDefinition = availableFields?.find?.(
    (fieldDefinition: FieldDefinition) =>
      fieldDefinition?.fieldName === fieldPreference
  );

  if (selectedFieldDefinition) {
    return selectedFieldDefinition;
  }

  return (
    availableFields?.find?.((field) => field?.isDefault) || availableFields?.[0]
  );
}
