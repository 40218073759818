import { Generator } from 'shared/utils/models';

export interface ContactTitleItem {
  id?: string;
  text?: string;
  value: string;
  label: string;
}

const TYPE = 'contactTitle';
export default new Generator(TYPE).createSystemListModel();
