import _ from 'lodash';

import { Generator } from 'shared/utils/models';

import {
  getDateRanges,
  DateRangesItem,
  radioButtonOptions,
  SelectItem as DateRangeSelectedItem
} from 'features/dashboards/popouts/filters/utils';
import { SelectItem } from 'types/select';

export interface DateRangeItem {
  value: [string, string];
  selectedOption?: DateRangeSelectedItem;
  comparison?: [string, string];
}

interface ModelState {
  dateRange: DateRangeItem;
  category: SelectItem[];
  users: SelectItem[];
  agent: SelectItem;
  soldStatus: SelectItem;
  account: number;
}

export interface Payload {
  dateRange?: DateRangeItem;
  category?: SelectItem[] | [];
  users?: SelectItem[];
  agent?: SelectItem;
  soldStatus?: SelectItem;
  account?: number;
}

export interface DashboardFiltersModel extends ModelState {
  filters: ModelState;
  refresh: () => ModelState;
  update: (arg: Payload) => ModelState;
}

const modelName = 'dashboardFilter';

const defaultDateRange = radioButtonOptions.find((opt) => {
  return opt.value === 'this_year_to_date';
});

export const defaultStartAndEnd: DateRangesItem =
  defaultDateRange && getDateRanges('AUS')[defaultDateRange.value];

const initialState = {
  dateRange: {
    value: [defaultStartAndEnd.start, defaultStartAndEnd.end],
    selectedOption: defaultDateRange,
    comparison: [
      defaultStartAndEnd.comparison.start,
      defaultStartAndEnd.comparison.end
    ]
  },
  category: [
    { value: 'residential', label: 'Residential' },
    { value: 'land', label: 'Land' },
    { value: 'rural', label: 'Rural' }
  ],
  users: [],
  soldStatus: { value: 'settled', label: 'Settled' },
  agent: {
    value: 'agent_id',
    label: 'user is agent 1 or agent 2'
  },
  account: null
};

const actionCreators = {
  refresh: {
    reduce: () => initialState
  },
  update: {
    reduce: (state: ModelState, action: { payload: Payload }) => {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
  }
};

const selectors = {
  filters: (state: ModelState) => _.get(state, `${modelName}`)
};

export default new Generator(modelName).createModel({
  initialState,
  actionCreators,
  selectors
});
