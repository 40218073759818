import React, { PureComponent } from 'react';
import Types from 'prop-types';
import Box from '@rexlabs/box';
import ui from 'data/models/custom/ui';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS, ZINDEX } from 'theme';

import { withModel } from '@rexlabs/model-generator';
import AppSearch from 'view/components/navigation/app-search';

import SaveCancelOverlay from 'view/components/save-cancel-overlay';

import Notifications from '../popouts/all-notifications';

import GlobalAdd from '../popouts/global-add';
import Changelog from '../components/changelog';
import UserProfile from '../popouts/user-profile';
import AgencySelector from '../popouts/agency-selector';
import HelpCentre from '../popouts/help-centre';
import AgencyIdentity from 'view/components/navigation/shell/header/components/agency-identity';
import { withRegion } from 'src/hocs/with-region';
import { connect } from 'react-redux';
import { checkUserHasPermission } from 'utils/rights';
import _ from 'lodash';

import AnnouncementToast from 'view/components/toast/announcement';
import RosieLogo from 'assets/logo/shell-logo-rosie.png';

export const SEARCH_INPUT_WIDTH = 640; // pixels wide
const GLOBAL_ADD_WIDTH = 80; // pixels wide

// TODO: Move these somewhere like a global theme file
const appSearchOverrides = {
  Skeleton: StyleSheet({
    iconBox: {
      // borderRadius: '50%'
    }
  }),

  ContactOption: StyleSheet({
    ownerIcon: {
      top: -4,
      left: -4
    }
  }),

  TextInput: StyleSheet({
    wrapper: {
      position: 'relative',
      zIndex: ZINDEX.APP_SEARCH_INPUT
    },
    container: {
      borderRadius: '0 3px 3px 0',
      border: 'none',
      lineHeight: '17px',
      background: COLORS.WHITE_30_OPACITY,

      '&:focus': {
        background: 'white'
      }
    },
    input: {
      color: COLORS.WHITE,
      padding: 0,
      height: '36px',
      paddingLeft: '12px',
      boxSizing: 'border-box'
    }
  })
};

const agencySelectorOverrides = {
  Tether: StyleSheet({
    container: {
      '& > div:nth-of-type(2)': {
        position: 'fixed !important',
        transform: 'translate3d(75px, 60px, 0px) !important'
      }
    }
  })
};

const desktopHeaderStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#3AA6F1',
    height: '70px',
    width: '100vw',
    zIndex: 21,
    userSelect: 'none',
    position: 'relative'
  },
  containerWithAnnouncement: {
    height: '112px'
  },
  innerContainer: {
    width: '100%',
    height: '70px'
  },
  loadingIndicator: {
    bottom: 'auto',
    top: '-55px'
  },
  supportCentreButton: {
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
    userSelect: 'none'
  }
};

@withModel(ui)
@withRegion
@connect((state) => ({ session: state.session }))
@styled(StyleSheet(desktopHeaderStyles))
@autobind
class DesktopTabletHeader extends PureComponent {
  static propTypes = {
    agencies: Types.array.isRequired,
    currentAgencyName: Types.string.isRequired,
    currentAgencyColor: Types.string.isRequired,
    handleHamburgerClick: Types.func.isRequired
  };

  render() {
    const {
      styles: s,
      agencies,
      currentAgencyName,
      region,
      localState,
      disabled,
      session,
      currentAgencyColor,
      announcement
    } = this.props;
    const { notifications, user } = localState;

    const agencySelectorProps = {
      agencies,
      currentAgencyName,
      currentAgencyColor
    };

    return (
      <Box
        {...s.with('container', {
          containerWithAnnouncement: announcement,
          inverted: true
        })({
          backgroundColor: currentAgencyColor
        })}
        data-appcues={'header'}
        flexDirection='column'
        flexShrink={0}
      >
        <SaveCancelOverlay />

        {announcement && <AnnouncementToast announcement={announcement} />}

        <Box
          flexDirection='row'
          alignItems='center'
          flexShrink={0}
          {...s('innerContainer')}
        >
          <AgencyIdentity
            name={session.isRosieAccount ? 'Rosie' : 'Rex'}
            currentAgencyColor={currentAgencyColor}
            logo={session.isRosieAccount ? RosieLogo : undefined}
          />

          <Box
            {...s.with({ disabled })({
              display: 'flex',
              padding: '0 11px 0 20px',
              // Removes the width of the padding above and the width of the Rex Logo
              width: '100%',
              alignItems: 'center'
            })}
          >
            <Box>
              <StylesProvider
                components={agencySelectorOverrides}
                prioritiseParentStyles={false}
              >
                <AgencySelector {...agencySelectorProps} />
              </StylesProvider>
            </Box>

            <Box flex={1} justifyContent={'flex-end'}>
              <GlobalAdd currentAgencyColor={currentAgencyColor} />
              <StylesProvider
                components={appSearchOverrides}
                prioritiseParentStyles={false}
              >
                <AppSearch
                  currentAgencyColor={currentAgencyColor}
                  appCuesId={'new-global-search'}
                />
              </StylesProvider>
            </Box>
            <HelpCentre currentAgencyColor={currentAgencyColor} />

            <Changelog currentAgencyColor={currentAgencyColor} />

            {(region.isEU ||
              checkUserHasPermission((dotNotation) =>
                _.get(session, dotNotation)
              )('addon.leads')) && (
              <Notifications
                counts={notifications}
                currentAgencyColor={currentAgencyColor}
              />
            )}
            <UserProfile currentAgencyColor={currentAgencyColor} meta={user} />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default DesktopTabletHeader;
