import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import { withRegion } from 'src/hocs/with-region';
import sessionModel from 'data/models/custom/session';
import listingsModel from 'data/models/entities/listings';
import propertiesModel from 'data/models/entities/properties';
import EntitySelect from 'view/components/input/select/entity-select';
import externalAddressModel from 'data/models/custom/external-address';

@withModel(sessionModel)
@withRegion
@autobind
class AddressSelect extends Component {
  componentDidUpdate(prevProps) {
    const { session, brandingId } = this.props;

    if (brandingId !== prevProps.brandingId) {
      session.getLocationCoords(brandingId);
    }
  }

  componentDidMount() {
    const { session, brandingId } = this.props;
    if (brandingId) {
      session.getLocationCoords(brandingId);
    }
  }

  getApiRegion() {
    const { region, useRegion } = this.props;
    return useRegion || region.isNZ ? 'nz' : region.isUK ? 'gb' : 'au';
  }

  // If the value has originally come from classic, the value should contain a recordType
  // instead of the model (since the model is shell exclusive), which we use
  // to map to the correct model
  mapRecordTypeToModel(value) {
    const modelMap = {
      Listings: listingsModel,
      Properties: propertiesModel
    };

    if (!value?.recordType) {
      return value;
    }

    return {
      ...value,
      model: modelMap[value.recordType] || externalAddressModel
    };
  }

  render() {
    const {
      session: { locationCoords },
      brandingId,
      value,
      options
    } = this.props;

    const proximityCoords = _.get(locationCoords, `${brandingId}.coords`);

    return (
      <EntitySelect
        {...this.props}
        value={this.mapRecordTypeToModel(value)}
        options={(options || []).map(this.mapRecordTypeToModel)}
        models={[listingsModel, propertiesModel, externalAddressModel]}
        proximityCoords={proximityCoords}
        region={this.getApiRegion()}
        valueAsObject={true}
        suggestive={false}
      />
    );
  }
}

export default AddressSelect;
