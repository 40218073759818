import Types from 'prop-types';
import Menu from './desktop-tablet/index';
import MobileMenu from './mobile/index';

const NAVIGATION_DELAY = 420; // ms
const delayNavigation = (func) => setTimeout(func, NAVIGATION_DELAY);

const tierTwoItemProp = Types.shape({
  heading: Types.bool, // If this menu item is to be rendered as a header
  icon: Types.any, // SVG File
  name: Types.string, // Name that will be rendered to the user
  accessRights: Types.string, // Used to hide/show the current menu item depending on the users permissions
  meta: Types.func, // localState prop on the top Menu component will be passed to this function,
  isSeparator: Types.bool // Shows a separator line
});

const tierOneItemProp = Types.shape({
  icon: Types.any, // SVG File
  id: Types.string, // Used to quickly locate a menu item config, was using 'name' but they can be dynamic and loaded via the meta function
  meta: Types.func, // localState prop on the top Menu component will be passed to this function
  name: Types.string, // The name to be shown when rendering this menu item
  href: Types.string, // The URL we want to navigate the user to
  match: Types.oneOfType(
    // The Regular Expressions that will cause the current menu item to show an active style
    [Types.string, Types.arrayOf(Types.string)]
  ),
  onClick: Types.func, // Fires when a menu item is clicked
  mobileOnly: Types.bool, // Only render this on mobile
  isSeparator: Types.bool, // Shows a separator line
  isFixedMobile: Types.bool, // Render this item in a fixed position on mobile only
  isFixedDesktop: Types.bool, // Render this item in a fixed position on desktop only
  accessRights: Types.string, // Used to hide/show the current menu item depending on the users permissions
  subMenu: Types.arrayOf(
    // The sub menu that will be rendered after selecting or hovering this main menu item
    tierTwoItemProp
  )
});

export {
  Menu,
  MobileMenu,
  NAVIGATION_DELAY,
  tierTwoItemProp,
  tierOneItemProp,
  delayNavigation
};
