import React, { ReactNode } from 'react';

import Tooltip from 'view/components/tooltip';
import { Body } from 'components/text/body';

interface Props {
  content: ReactNode;
  showTooltip: boolean;
  children?: React.ReactNode;
}

export function ConditionalTooltip({
  content,
  showTooltip = true,
  children
}: Props) {
  return showTooltip ? (
    <Tooltip
      inline
      hoverTimeout={1}
      distance={'10px'}
      placement='top'
      Content={() => (
        <Body normal white>
          {content}
        </Body>
      )}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
