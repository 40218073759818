import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Bridges } from 'data/classic-bridges';
import Default from './default';

@autobind
class BuildingsFixture extends Component {
  handleFixtureClick() {
    const { option: meta, term, buildings } = this.props;
    const { buildingsEdit } = Bridges.Dialogs;

    buildingsEdit.openClassic({
      data: term,
      // This deferred parameter has be passed through from the Classic dialog specifically
      // so Shell can return what the dialog expects then reject/resolve on Shell api client result
      callback: (data, deferred) => {
        buildings
          .createItem({ data })
          .then((res) => {
            _.get(meta, 'fixturePrefillData.callback') &&
              meta.fixturePrefillData.callback(res);
            deferred.resolve();
          })
          .catch(deferred.reject);

        return deferred;
      }
    });
  }

  render() {
    return (
      <Default addNewEntity={this.handleFixtureClick}>ADD NEW BUILDING</Default>
    );
  }
}

export default BuildingsFixture;
