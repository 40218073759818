import { Channel, formatKey } from 'utils/pusher';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

function auth(channel) {
  return {
    authorize: (socketId, callback) => {
      return api
        .post('RealTimeWebNotifications::authenticate', {
          channel_names: [channel.name],
          session_id: socketId
        })
        .then((response) => {
          /* eslint-disable */
          response.data.result?.forEach?.((r) =>
            r.auth
              ? callback(false, r.auth)
              : console.warn(
                  `No auth in response for real-time channel '${channel.name}'`
                )
          );
          /* eslint-enable */
        });
    }
  };
}

class CollisionAvoidanceMiddleware {
  constructor(store) {
    this.store = store;
    this.presenceChannels = null;
    this.channelSet = false;

    if (global.__DEV__) {
      window.collisionAvoidance = {
        viewSelf: (value) => {
          this.__dev__viewSelf = value;
        }
      };
    }
  }

  middleware(next) {
    return (action) => {
      const channelTypes = this.getChannelTypes();

      if (this.shouldRefreshChannels(action)) {
        this.refreshChannels(action);
        return next(action);
      }

      if (!channelTypes || !channelTypes.recordPresence) {
        return next(action);
      }

      try {
        let channel;
        switch (action.type) {
          case 'collisionAvoidance/OPEN_RECORD':
            channel = new Channel(
              channelTypes.recordPresence,
              auth,
              action.payload
            );
            channel
              .open()
              .then(() => {
                channel.bind(
                  [
                    'pusher:subscription_succeeded',
                    'pusher:member_added',
                    'pusher:member_removed'
                  ],
                  () => {
                    const viewers = [];
                    const me = channel.members?.me;
                    channel.members?.each?.((member) => {
                      if (this.__dev__viewSelf || member.id !== me.id) {
                        viewers.push(member.info);
                      }
                    });
                    this.store.dispatch({
                      type: 'collisionAvoidance/UPDATE_VIEWERS',
                      payload: {
                        ...action.payload,
                        viewers
                      }
                    });
                  }
                );
                this.presenceChannels[channel.key] = channel;
              })
              .catch((error) => {
                console.error(error);
              });
            break;

          case 'collisionAvoidance/CLOSE_RECORD': {
            const key = formatKey(action.payload);
            channel = this.presenceChannels[key];
            if (channel) {
              channel.close();
              delete this.presenceChannels[key];
            }
            break;
          }

          case 'collisionAvoidance/CLOSE_ALL_RECORDS':
            _.forEach(this.presenceChannels, (channel) => channel.close());
            this.presenceChannels = {};
            break;
        }
      } catch (error) {
        console.error(error);
      }

      return next(action);
    };
  }

  shouldRefreshChannels({ type }) {
    return type === 'session/INIT_WEBSOCKETS/SUCCESS';
  }

  getChannelTypes() {
    return _.get(this.store.getState(), 'session.websocketChannelTypes');
  }

  refreshChannels({ type, payload }) {
    if (this.channelSet) {
      this.store.dispatch({ type: 'collisionAvoidance/CLOSE_ALL_RECORDS' });
      this.channelSet = false;
    }

    const channelTypes =
      type === 'session/INIT_WEBSOCKETS/SUCCESS'
        ? payload
        : this.getChannelTypes();

    if (!channelTypes || !channelTypes.recordChanges) {
      return;
    }

    this.presenceChannels = {};
    const changeChannel = new Channel(channelTypes.recordChanges, auth);
    changeChannel
      .open()
      .then(() => {
        changeChannel.bind(['update'], (changePayload) => {
          const shouldUpdate =
            this.wasChangedBySomeoneElse(changePayload) &&
            this.isViewing(changePayload);
          if (shouldUpdate) {
            this.store.dispatch({
              type: 'collisionAvoidance/UPDATE_CHANGE',
              payload: {
                recordId: changePayload.record_id,
                serviceName: changePayload.service_name,
                change: changePayload
              }
            });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  isViewing(changePayload) {
    const key = formatKey(changePayload);
    return !!this.presenceChannels[key];
  }

  wasChangedBySomeoneElse(changePayload) {
    const id = this.store.getState()?.session?.user_details?.id;
    return this.__dev__viewSelf || changePayload.user.id !== id;
  }
}

function collisionAvoidanceMiddleware() {
  return (store) => {
    const instance = new CollisionAvoidanceMiddleware(store);
    return instance.middleware.bind(instance);
  };
}

export default collisionAvoidanceMiddleware;
