import React, { ReactElement } from 'react';
import Box from '@rexlabs/box';
import { COLORS } from 'theme';
import { ICONS } from 'shared/components/icon';
import useHoverToggle from 'src/hooks/use-hover-toggle';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.BACKGROUNDS.SAND_LIGHT,
    cursor: 'pointer'
  },
  containerHover: {
    background: COLORS.BACKGROUNDS.SAND_DARK
  },
  icon: {
    marginRight: '10px'
  }
});

type BoxProps = React.ComponentProps<typeof Box>;

interface PlaceholderProps extends BoxProps {
  Icon?: any;
}

function RightBarWidgetPlaceholder({
  Icon = ICONS.ADD,
  children,
  ...props
}: PlaceholderProps): ReactElement {
  const s = useStyles(defaultStyles, 'RightBarWidgetPlaceholder');
  const { hover: containerHover, hoverProps } = useHoverToggle();
  return (
    <Box
      {...hoverProps}
      {...s('container', { containerHover })}
      {...props}
      height={'44px'}
      p={'14px'}
    >
      <Box flexDirection={'row'}>
        <Box mr={10}>
          <Icon />
        </Box>
        {children}
      </Box>
    </Box>
  );
}

export default RightBarWidgetPlaceholder;
