import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Hint } from 'components/text/hint';

import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import List, {
  RexHeadingCell,
  RexBasicCell,
  RexPrimaryTextCell
} from 'view/components/list';
import { COLORS } from 'theme';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  emptyContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    minHeight: '50px',
    width: '100%'
  }
});

const listStyles = StyleSheet({
  separator: {
    display: 'none'
  },

  wrapItem: {
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

@styled(defaultStyles)
@autobind
class MetricRelatedDataList extends PureComponent {
  static defaultProps = {
    invertable: true
  };

  renderEmptyState() {
    const { styles: s, isLoading } = this.props;
    return (
      <Box
        {...s('emptyContainer')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {isLoading ? (
          <Spinner small dark />
        ) : (
          <Hint semibold>No related data added.</Hint>
        )}
      </Box>
    );
  }

  renderHeader() {
    const { invertable } = this.props;
    return (
      <Box flexDirection='row' width='100%'>
        <RexHeadingCell width={150}>label</RexHeadingCell>
        <RexHeadingCell flex={1}>value</RexHeadingCell>
        {!!invertable && <RexHeadingCell width={80}>inverted</RexHeadingCell>}
      </Box>
    );
  }

  renderItem(item, index) {
    const { onEdit, onDelete, invertable, readOnly } = this.props;
    return (
      <Box width='100%' flexDirection='row'>
        {!onEdit ? (
          <RexBasicCell width={150}>{_.get(item, 'label')}</RexBasicCell>
        ) : (
          <RexPrimaryTextCell
            ellipsis
            width={150}
            actionMenuItems={[
              {
                label: readOnly ? 'View data' : 'Edit data',
                onClick: () => onEdit(item, index)
              },
              ...(item.isSystem || !onDelete
                ? []
                : [
                    {
                      label: 'Delete',
                      onClick: () => onDelete(item, index)
                    }
                  ])
            ]}
          >
            {_.get(item, 'label')}
          </RexPrimaryTextCell>
        )}
        <RexBasicCell flex={1}>
          {_.get(item, 'value.value') || _.get(item, 'value')}
        </RexBasicCell>
        {!!invertable && (
          <RexBasicCell width={80}>
            {_.get(item, 'value.invert') ? 'Yes' : 'No'}
          </RexBasicCell>
        )}
      </Box>
    );
  }

  render() {
    const { items } = this.props;

    return (
      <List
        styles={listStyles}
        Header={this.renderHeader}
        EmptyView={this.renderEmptyState}
        items={items}
        renderItem={this.renderItem}
      />
    );
  }
}

export default MetricRelatedDataList;
