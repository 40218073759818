import React, { Component } from 'react';
import EntityOption from 'view/components/input/select/options/entity';
import { ICONS } from 'shared/components/icon';

class ProjectsOption extends Component {
  render() {
    return (
      <EntityOption
        {...this.props}
        pluckStatus={() => 'Project'}
        Icon={ICONS.PROJECT}
      />
    );
  }
}

export default ProjectsOption;
