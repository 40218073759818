import { useEffect, useMemo } from 'react';
import LocalStorage from 'shared/utils/local-storage';
import { Criteria } from 'types/criteria';

export function useLocalStoredFilter({
  filterKey,
  displayCriteria,
  setCriteria,
  savedFilterId,
  serviceName
}: {
  filterKey: string;
  serviceName: string;
  displayCriteria: Criteria[];
  setCriteria: (criteria: Criteria[], savedFilterId: string) => void;
  savedFilterId: string;
}) {
  const storageKey = `pipeline-${serviceName}-${filterKey}`;

  useEffect(() => {
    const storedValue = LocalStorage.get(storageKey);

    if (storedValue && storedValue !== 'all') {
      setCriteria(
        [
          {
            name: filterKey,
            type: '=',
            value: storedValue
          }
        ],
        savedFilterId
      );
    }
  }, []);

  const value = useMemo(
    () => displayCriteria.find((c) => c.name === filterKey)?.value,
    [displayCriteria]
  );

  function setValue(newValue: string | boolean | string[]) {
    const filteredCriteria = displayCriteria.filter(
      (c) => c.name !== filterKey
    );

    setCriteria(
      newValue !== 'all'
        ? [
            ...filteredCriteria,
            {
              name: filterKey,
              type: Array.isArray(newValue) ? 'in' : '=',
              value: newValue
            }
          ]
        : filteredCriteria,
      savedFilterId
    );

    LocalStorage.set(storageKey, newValue, true);
  }

  return [value, setValue] as const;
}
