import { Generator } from 'shared/utils/models';

export interface ReminderTypeValue {
  id: 'phone' | 'task' | 'sms' | 'letter' | 'email';
  text: 'Phone' | 'Task' | 'Sms' | 'Letter' | 'Email';
}

const TYPE = 'reminderType';

export default new Generator(TYPE).createSystemListModel();
