import React, { useEffect, useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { DefaultButton } from 'view/components/button';
import { ArrowDropdown } from 'view/components/action-menu';

import { useRecordTabs } from './utils/use-record-tabs';
import { RecordDetailsBlock } from './block';

const defaultStyles = StyleSheet({
  container: {},

  tabBar: {
    overflow: 'hidden',

    '& button': {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

      '&:active': {
        transform: 'translateY(2px)'
      }
    }
  },

  tab: {},

  tabContent: {},

  tabContentHidden: {
    display: 'none'
  }
});

interface RecordDetailsTabsProps {
  tabs: any;
  customTabs?: any;
  data: any;
  values: any;
}

export function RecordDetailsTabs({
  tabs,
  customTabs,
  data,
  values
}: RecordDetailsTabsProps) {
  const s = useStyles(defaultStyles);

  const allTabs = useRecordTabs({ tabs, customTabs });

  const [activeTab, setActiveTab] = useState(allTabs?.[0]);
  const [visitedTabs, setVisitedTabs] = useState([activeTab]);

  useEffect(() => {
    setVisitedTabs((state) =>
      !state.includes(activeTab) ? [...state, activeTab] : state
    );
  }, [activeTab]);

  // Reset state when tabs change
  useEffect(() => {
    setActiveTab(allTabs?.[0]);
    setVisitedTabs([allTabs?.[0]]);
  }, [allTabs]);

  const { mainTabs, moreTabs } = useMemo(() => {
    // Sort tabs into main (non-custom) and custom tabs first, then split the
    // custom ones additionally up into visited and not visited. This is needed
    // later to display different action menu item groups for those!
    const { mainTabs, moreTabsVisited, moreTabsNotVisited } = allTabs.reduce(
      (all, tab) => {
        if (tab.isCustom) {
          if (visitedTabs.find((v) => v.id === tab.id)) {
            all.moreTabsVisited.push(tab);
          } else {
            all.moreTabsNotVisited.push(tab);
          }
        } else {
          all.mainTabs.push(tab);
        }
        return all;
      },
      { mainTabs: [], moreTabsVisited: [], moreTabsNotVisited: [] }
    );

    let moreTabs: any[] = [];
    if (moreTabsVisited.length > 0) {
      moreTabs = moreTabs.concat([
        { isGroupHeading: true, label: 'Active Tabs' },
        ...moreTabsVisited
      ]);
    }
    if (moreTabsNotVisited.length > 0) {
      moreTabs = moreTabs.concat([
        { isGroupHeading: true, label: 'Available Tabs' },
        ...moreTabsNotVisited
      ]);
    }

    return { mainTabs, moreTabs };
  }, [allTabs, visitedTabs]);

  return (
    <Box {...s('container')}>
      <Box {...s('tabBar')} flexDirection='row' alignItems='center' sx='4px'>
        {mainTabs?.map?.((tab) => (
          <DefaultButton
            dark={activeTab?.id === tab?.id}
            key={tab?.id}
            onClick={() => setActiveTab(tab)}
          >
            {tab.label}
          </DefaultButton>
        ))}
        {!!moreTabs?.length && (
          <ArrowDropdown
            dark={moreTabs?.find?.((tab) => tab.id === activeTab?.id)}
            items={moreTabs?.map?.((tab) =>
              tab.isGroupHeading
                ? tab
                : { label: tab.label, onClick: () => setActiveTab(tab) }
            )}
          />
        )}
      </Box>
      {visitedTabs?.map?.((tab) => (
        <Box
          key={tab?.id}
          {...s('tabContent', { tabContentHidden: activeTab?.id !== tab?.id })}
        >
          {tab?.blocks?.map?.((block) => (
            <RecordDetailsBlock block={block} data={data} values={values} />
          ))}
        </Box>
      ))}
    </Box>
  );
}
