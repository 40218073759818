import React from 'react';
import { isNumber } from 'lodash';

import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'utils/formatters';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';

export function CurrencyColumn({ value, column, node }) {
  const region = useRegion();

  const aggregationValue = value?.value;
  // don't format the count aggregation like currency!
  if (column.aggFunc === 'count') {
    return <div className='ag-right-aligned-cell'>{aggregationValue}</div>;
  }

  const symbol = region?.financial?.currency?.symbol || '$';
  const currencyValue = isNumber(aggregationValue) ? aggregationValue : value;

  return (
    <div className='ag-right-aligned-cell'>
      {isNumber(currencyValue) ? (
        <>
          {symbol}
          {formatCurrency(currencyValue)}
        </>
      ) : (
        getEmptyValue({ node })
      )}
    </div>
  );
}
