import React, { RefObject } from 'react';
import {
  TextGenerationWritingIndicator,
  updateWritingIndicatorPosition
} from 'features/ai/components/text-generation/text-generation-writing-indicator';
import classnames from 'classnames';
import { htmlToNewlines } from 'features/ai/utils/html';
import { TextGenerationFormError } from 'features/ai/components/text-generation/text-generation-form-error';
import { TextGenerationFormType } from 'features/ai/components/text-generation/text-generation-form';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  scrollContainer: {
    flex: 1,
    position: 'relative',
    scrollbarGutter: 'stable',
    marginBottom: '94px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'rgba(0,0,0,0)',
      width: '8px',
      height: '16px',
      zIndex: 9999
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(255,255,255,0.3)',
      borderRadius: 8,
      padding: 2,
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 30, 50, 0.2)',
      borderRadius: 8
    },
    '&::-webkit-scrollbar-button': {
      display: 'none'
    }
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none'
  },
  completion: {
    whiteSpace: 'break-spaces',
    fontSize: '14px',
    color: 'rgba(55, 55, 60, 1)',
    lineHeight: '18px',
    display: 'block',
    padding: '20px',
    '& .ai__headline, & .ai__body': {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 600
    }
  }
});

type TextGenerationFormCompletionProps = {
  isLoading: boolean;
  error: Error | null;
  headline: string;
  completionRef: RefObject<HTMLSpanElement>;
  body: string;
  initialData?: {
    headline?: string;
    body?: string;
  };
  outputs: string[];
  type: TextGenerationFormType;
  setError: (error: Error | null) => void;
};

export function TextGenerationFormCompletion({
  isLoading,
  error,
  headline,
  body,
  initialData,
  completionRef,
  type,
  setError,
  outputs
}: TextGenerationFormCompletionProps) {
  const s = useStyles(styles);
  return (
    <>
      <div {...s('scrollContainer', { hidden: !!error })}>
        <TextGenerationWritingIndicator isVisible={isLoading} />

        <span
          className={classnames(s('completion').className, 'ai__completion')}
          ref={completionRef}
        >
          {initialData?.headline &&
          initialData?.body &&
          type === TextGenerationFormType.AdText ? (
            <>
              <span className='ai__headline'>Headline</span>
              {'\n'}
              {headline}
              {'\n'}
              {'\n'}
              <span className='ai__body'>Body</span>
              {'\n'}
              {body}
            </>
          ) : initialData?.body && type !== TextGenerationFormType.AdText ? (
            htmlToNewlines(body)
          ) : (
            ''
          )}
        </span>
      </div>
      {error ? (
        <TextGenerationFormError
          type={type}
          canReset={outputs.length > 0}
          error={error}
          setError={setError}
        />
      ) : null}
    </>
  );
}

export function setCompletionHtml(
  completionRef: RefObject<HTMLSpanElement>,
  html: string
) {
  if (!completionRef.current) return;

  completionRef.current.innerHTML = html;

  updateWritingIndicatorPosition(completionRef);
}

export function getCompletionText(completionRef: RefObject<HTMLSpanElement>) {
  if (!completionRef.current) return '';

  return completionRef.current.innerText;
}

export function getCompletionHtml(completionRef: RefObject<HTMLSpanElement>) {
  if (!completionRef.current) return '';

  return completionRef.current.innerHTML;
}

export function printCompletion({
  chunk,
  type,
  completionRef
}: {
  chunk: string;
  type: TextGenerationFormType;
  completionRef: React.RefObject<HTMLSpanElement>;
}) {
  if (!completionRef.current) return;

  if (type === TextGenerationFormType.AdText) {
    const stillPrintingHeading =
      !completionRef.current.innerHTML.includes('\n\n');
    if (!completionRef.current.innerHTML) {
      completionRef.current.innerHTML =
        "<span class='ai__headline'>Headline</span>\n";
    }

    if (stillPrintingHeading) {
      if (chunk.includes('\n\n')) {
        const remainingHeadlineTokens = chunk
          .split('\n\n')[0]
          .replaceAll('"', '');
        const startOfBodyTokens = chunk.split('\n\n')[1].replaceAll('\n', '');

        completionRef.current.innerHTML += `${remainingHeadlineTokens}\n\n<span class="ai__body">Body</span>\n${startOfBodyTokens}`;
      } else {
        completionRef.current.innerHTML += chunk.replaceAll('"', '');
      }
    } else {
      completionRef.current.innerHTML += chunk;
    }
  } else {
    completionRef.current.innerText += chunk;
  }

  completionRef.current.innerHTML = completionRef.current.innerHTML.replaceAll(
    '<span class="ai__cursor"></span>',
    ''
  );
  completionRef.current.innerHTML += '<span class="ai__cursor"></span>';

  updateWritingIndicatorPosition(completionRef);

  const scrollContainer = completionRef.current.parentElement;
  if (scrollContainer) {
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }
}
