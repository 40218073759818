import React, { Component } from 'react';
import DefaultValue from './default';
import { ICONS } from 'shared/components/icon';

class ExternalAddressValue extends Component {
  render() {
    return <DefaultValue {...this.props} Icon={ICONS.SELECT.VALUE.POSTCODE} />;
  }
}

export default ExternalAddressValue;
