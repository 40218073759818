import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { api } from 'shared/utils/api-client';
import PafFileView from './paf-file-view';
import { PafErrorListDialog, RollbackDialog } from '../dialogs';
import Box from '@rexlabs/box';
import { PADDINGS, FONT } from 'theme';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    marginTop: PADDINGS.XL,
    paddingRight: PADDINGS.M,
    paddingLeft: PADDINGS.M
  },
  tableHeading: {
    fontWeight: FONT.WEIGHTS.BOLD
  }
});

@withErrorDialog
@styled(defaultStyles)
@autobind
class UploadHistoryContainer extends PureComponent {
  state = {
    currentFile: {},
    previousFile: {},
    hasFiles: false,
    hasConfirmedRollback: false,

    errorDialog: false,
    errors: []
  };

  componentDidMount() {
    api
      .post('ThirdPartyServicePostcodeFile::getUploadedFiles')
      .then(({ data }) => {
        // ASSUMPTION: files are in reverse chronological order
        const files = _.get(data, 'result', [])
          .filter((file) => file.status.id === 'complete')
          .sort((file) => file.system_ctime);

        if (files.length < 1) {
          return;
        }

        const currentFile = files[0];
        currentFile.highErrorRate = this.hasHighErrorRate(currentFile);

        const previousFile = files[1];
        currentFile.highErrorRate = this.hasHighErrorRate(previousFile);

        this.setState({
          currentFile,
          previousFile,
          hasFiles: true
        });
      });
  }

  hasHighErrorRate(fileData) {
    if (!fileData) {
      return false;
    }

    const { rows_total: total, rows_ingested: ingested } = fileData;
    return ingested / total < 0.9;
  }

  openErrorDialog(fileData) {
    const fileId = fileData.file_id;

    return () => {
      api
        .post('ThirdPartyServicePostcodeFile::getFileProcessLogs', {
          file_id: fileId
        })
        .then(({ data }) => {
          const errors = data.result;

          this.setState({
            errorDialog: true,
            highErrorRate: fileData.highErrorRate,
            errors
          });
          // TODO: Handle errors with error dialog
        });
    };
  }

  closeErrorDialog() {
    this.setState({
      errors: [],
      errorDialog: false,
      highErrorRate: false
    });
  }

  openConfirmRollbackDialog() {
    this.setState({
      isConfirmingRollback: true
    });
  }

  confirmRollback() {
    this.setState(
      {
        isConfirmingRollback: false,
        hasConfirmedRollback: true
      },
      this.rollbackFile(this.state.previousFile.file_id)
    );
  }

  cancelRollback() {
    this.setState({
      isConfirmingRollback: false,
      hasConfirmedRollback: false
    });
  }

  rollbackFile(fileId) {
    const { hasConfirmedRollback } = this.state;
    const { errorDialog, onRollback } = this.props;

    if (!hasConfirmedRollback) {
      this.openConfirmRollbackDialog();
    }

    api
      .post('ThirdPartyServicePostcodeFile::rollbackToFile', {
        file_id: fileId
      })
      .then(onRollback)
      .catch((error) => {
        errorDialog.open(error);
        this.setState({
          hasConfirmedRollback: false
        });
      });
  }

  render() {
    if (!this.state.hasFiles) {
      return null;
    }

    const { styles: s } = this.props;
    const { currentFile, previousFile } = this.state;

    return (
      <Box>
        {this.state.errorDialog ? (
          <PafErrorListDialog
            errors={this.state.errors}
            highErrorRate={this.state.highErrorRate}
            onCancel={this.closeErrorDialog}
          />
        ) : null}
        {this.state.isConfirmingRollback ? (
          <RollbackDialog
            onSubmit={this.confirmRollback}
            onCancel={this.cancelRollback}
          />
        ) : null}
        {currentFile ? (
          <Box {...s('container')}>
            <h1 {...s('tableHeading')}>Current File</h1>
            <PafFileView
              isCurrent
              rollbackFile={this.rollbackFile}
              title='Current File'
              fileData={currentFile}
              openErrorDialog={this.openErrorDialog}
            />
          </Box>
        ) : null}
        {previousFile ? (
          <Box {...s('container')}>
            <h1 {...s('tableHeading')}>Previous File</h1>
            <PafFileView
              title='Previous File'
              fileData={previousFile}
              openErrorDialog={this.openErrorDialog}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
}

export default UploadHistoryContainer;
