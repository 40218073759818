import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'adminReferralCategories';
const adminReferralCategoriesModel = new Generator(TYPE).createEntityModel();

adminReferralCategoriesModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('AdminReferralCategories::autocomplete', {
        search_string: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((category) => ({
          value: category.id,
          label: category.name,
          data: category,
          model: adminReferralCategoriesModel
        }))
      )
};

export default adminReferralCategoriesModel;
