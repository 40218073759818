import React, { PureComponent } from 'react';
import { styled } from '@rexlabs/styling';
import GreyBox from './grey-box';
import Box from '@rexlabs/box';
import { TEXTS } from 'theme';
import { APP_MENU } from 'theme/legacy/components';

@styled(APP_MENU.HEADER)
class SearchSkeletonHeader extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <Box {...s('container')}>
        <Box {...s('inner')}>
          <div
            {...s.with('wrapper')({
              ...TEXTS.APP_SEARCH.HEADING,
              width: '100%'
            })}
          >
            <GreyBox
              light
              style={{
                width: '100%',
                height: 14,
                marginBottom: '5px'
              }}
            />
          </div>
        </Box>
      </Box>
    );
  }
}

export default SearchSkeletonHeader;
