import { Generator } from 'shared/utils/models';
import { api, transformValueList } from 'shared/utils/api-client';

const config = {
  valueLists: {
    api: {
      fetch: () =>
        api
          .post('AdminValueLists::getListValues', {
            list_name: 'listing_room_type'
          })
          .then(transformValueList)
          .then(({ data }) => ({
            data: data.map((v) => ({
              value: v.label,
              label: v.label
            }))
          }))
    }
  }
};

const TYPE = 'listingRoomType';
export default new Generator(TYPE, config).createValueListModel();
