import { ListingDocument } from 'features/listings/types/common';

// Below implementation is same as r2.ui.formatters for filesize
export function formatFileSize(size: string): string {
  let sizeNum = parseInt(size, 10);
  if (sizeNum) {
    let exp = 0;
    while (sizeNum > 1000) {
      exp++;
      sizeNum /= 1000;
    }
    const suffixes = ['Bytes', 'KB', 'MB', 'GB'];
    return sizeNum.toFixed(2) + ' ' + suffixes[exp];
  } else {
    return size;
  }
}

export function sortMailMergeListingDocuments(
  a: ListingDocument,
  b: ListingDocument
) {
  if (a.exclude_from_mail_merge && !b.exclude_from_mail_merge) {
    return 1;
  }
  if (!a.exclude_from_mail_merge && b.exclude_from_mail_merge) {
    return -1;
  }
  if (a.privacy.id === 'public' && b.privacy.id !== 'public') {
    return -1;
  }
  if (a.privacy.id !== 'public' && b.privacy.id === 'public') {
    return 1;
  }
  return 0;
}
