import { useModelState } from '@rexlabs/model-generator';
import { CustomReportItem } from '../data/custom-reports-model';
import sessionModel from 'data/models/custom/session';

interface UseReportPermissionsProps {
  customReport: CustomReportItem | null;
}

export function useReportPermissions({
  customReport
}: UseReportPermissionsProps) {
  const { office_details } = useModelState(sessionModel);

  const isLibraryTemplate = customReport?.library?.id;

  const canEditReport = (customReport?.security_user_rights || []).includes(
    'update'
  );

  // NOTE: this is to make sure that if a user created the template, they can only
  // edit in the account the template was created in.
  const isReportCreatedInAccount =
    customReport?.account_id === office_details.id;

  const canEditLibraryTemplate = isReportCreatedInAccount && canEditReport;

  return {
    canEditReport: isLibraryTemplate ? canEditLibraryTemplate : canEditReport,
    isLibraryTemplate,
    isReportCreatedInAccount
  };
}
