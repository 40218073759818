import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Generator } from 'shared/utils/models';

const TYPE = 'adminCommGrossStructures';

const actionCreators = {
  calculateCommission: {
    request: ({ data }) =>
      api.post(
        'AdminCommGrossStructures::applyRawCommissionModelToAmount',
        data
      ),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const AdminCommGrossStructuresModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default AdminCommGrossStructuresModel;
