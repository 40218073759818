import { useMemo } from 'react';

import { mapCustomGroup } from 'components/_experiment/record-block/custom-group';

import { useMode } from './use-mode';

export function useRecordTabs({ tabs, customTabs }) {
  const [mode] = useMode();

  return useMemo(() => {
    // We only want to show custom tabs on the default mode
    if (mode !== 'default') {
      return tabs;
    }

    return [
      ...tabs.map((tab) => ({
        ...tab,
        blocks: tab.blocks.reduce((all, block, i) => {
          // This loop is collecting the custom groups from the custom tabs config
          // so we can inject them in the next step
          const [before, after] = customTabs?.groups_on_core_tabs?.reduce?.(
            (all, group) => {
              // Find custom groups that are associated to the tab
              if (group.core_tab_id === tab.id) {
                // Then check if they are associated to the system group as well,
                // in which case `priority_type` tells us where to put the custom
                // group
                if (group.priority_core_group_id === group.id) {
                  if (group.priority_type === 'before_core_groups') {
                    all[0].push(mapCustomGroup(group));
                  } else {
                    all[1].push(mapCustomGroup(group));
                  }
                } else if (group.priority_core_group_id === null) {
                  // Otherwise, if `priority_core_group_id` is null, we assume the
                  // custom group is positioned at the very beginning or the very
                  // end of the tab, based on `priority_type`
                  if (i === 0 && group.priority_type === 'before_core_groups') {
                    all[0].push(mapCustomGroup(group));
                  } else if (
                    i === tab.blocks.length - 1 &&
                    group.priority_type === 'after_core_groups'
                  ) {
                    all[1].push(mapCustomGroup(group));
                  }
                }
              }
              return all;
            },
            [[], []]
          );

          return all.concat([...before, block, ...after]);
        }, [])
      })),
      ...customTabs?.tabs?.map?.((customTab) => ({
        id: customTab.id,
        label: customTab.label,
        blocks: customTab.groups?.map?.(mapCustomGroup),
        isCustom: true
      }))
    ];
  }, [mode, tabs, customTabs]);
}
