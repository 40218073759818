import { Channel } from 'utils/pusher';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

function auth(channel) {
  return {
    authorize: (socketId, callback) => {
      return api
        .post('RealTimeWebNotifications::authenticate', {
          channel_names: [channel.name],
          session_id: socketId
        })
        .then((response) => {
          /* eslint-disable */
          response.data.result?.forEach?.(
            (r) => r.auth && callback(false, r.auth)
          );
          /* eslint-enable */
        });
    }
  };
}

class NotificationMiddleware {
  constructor(store) {
    this.store = store;
    this.changeChannel = null;
  }

  middleware(next) {
    return (action) => {
      switch (action.type) {
        case 'session/INIT_WEBSOCKETS/SUCCESS':
          this.refreshChannels(action);
          break;
        case 'session/REFRESH/SUCCESS':
          this.store.dispatch({ type: 'notification/REFRESH' });
          break;
      }

      return next(action);
    };
  }

  refreshChannels({ type, payload }) {
    const channelTypes =
      type === 'session/INIT_WEBSOCKETS/SUCCESS'
        ? payload
        : _.get(this.store.getState(), 'session.websocketChannelTypes');

    if (!channelTypes || !channelTypes.accountUser) {
      return;
    }

    this.changeChannel = new Channel(channelTypes.accountUser, auth, {
      userId: _.get(this.store.getState(), 'session.user_details.id')
    });
    this.changeChannel
      .open()
      .then(() => {
        this.changeChannel.bind(['notification'], (newNotification) => {
          this.store.dispatch({
            type: 'notification/CREATE',
            payload: {
              newNotification
            }
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

function notificationMiddleware() {
  return (store) => {
    const instance = new NotificationMiddleware(store);
    return instance.middleware.bind(instance);
  };
}

export default notificationMiddleware;
