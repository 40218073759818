/* eslint-disable max-lines */
import { StyleSheet } from '@rexlabs/styling';

import { COLORS } from './colors';
import { PADDINGS } from './spacing';
import { TEXTS } from './text';
import { ZINDEX, HEIGHTS } from './misc';
import { FONT } from './font';
import { TIMINGS } from './animation';

import _ from 'lodash';

const inputClasses = {
  container: {
    borderRadius: 0,
    width: '100%',
    height: 'auto',
    minHeight: '26px',
    border: `2px solid ${COLORS.INPUT.BORDER}`,
    padding: '0 0 0 2.5px',

    // This is needed because data-es-keys of TextArea is not TextArea but TextInput causing any TextAreaStyles to not work
    textarea: {
      padding: '5px 0 0 2.5px'
    },

    '&:hover, &:active': {
      borderColor: COLORS.INPUT.BORDER_ACTIVE
    },

    '&:focus-within': {
      outline: `2px solid ${COLORS.INPUT.OUTLINE}`,
      borderColor: COLORS.INPUT.BORDER_ACTIVE,
      boxShadow: 'none'
    }
  },

  containerError: {},

  inputContainer: {},

  textAreaContainer: {}
};

const inputsStyles = {
  input: {
    ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
    padding: '0 0 0 2.5px',

    // We apply hover, focus, and active styling in TextInput.container
    // We added this because classic has styling that puts border in the container
    '&:hover, &:active, &:focus': {
      border: 'none !important'
    },

    '::placeholder': {
      ...TEXTS.CONTENT.PLACEHOLDER
    },

    ':disabled::placeholder': {
      ...TEXTS.CONTENT.PLACEHOLDER,
      fontStyle: 'normal'
    }
  },

  wrapper: {},

  cosmetic: {},

  cosmeticWrapper: {},

  containerError: {
    borderColor: COLORS.PRIMARY.RED,

    '&:hover': {
      borderColor: COLORS.PRIMARY.RED
    },

    ':focus-within': {
      borderColor: COLORS.PRIMARY.RED
    },

    ':active': {
      borderColor: COLORS.PRIMARY.RED
    }
  },

  containerValid: {
    borderColor: COLORS.STATES.IDLE
  },

  containerFocused: {
    boxShadow: 'none'
  },

  charLimitContainer: {},

  disabled: {
    backgroundColor: COLORS.INPUT.DISABLED_BG,

    '&:hover': {
      borderColor: COLORS.STATES.IDLE
    },

    '&:active': {
      borderColor: COLORS.STATES.IDLE
    }
  },

  prefix: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 5,
    '& span': {
      fontSize: '14px'
    },
    '& > *:first-child': {
      marginRight: 0
    }
  },

  suffix: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 5
  }
};

const innerFieldStyles = {
  container: {
    borderColor: COLORS.GREY,
    flexShrink: 0
  },

  containerError: {
    borderColor: COLORS.PRIMARY.RED
  },

  field: {
    position: 'relative',
    marginTop: PADDINGS.S,

    '&:first-child': {
      marginTop: 0
    }
  },

  fieldLabel: {
    marginBottom: PADDINGS.XXS,
    ...TEXTS.CONTENT.FORM_FIELD_LABEL_1
  },

  inlineFieldLabel: {
    ...TEXTS.CONTENT.FORM_FIELD_LABEL_1
  },

  error: {
    display: 'none'
  }
};

const TextInputStyles = StyleSheet(
  _.merge(
    {},
    { input: { height: '23px' } },
    { container: inputClasses.container },
    inputsStyles
  )
);
const TextAreaStyles = StyleSheet(
  _.merge(
    {},
    { input: { height: '100%' } },
    { container: inputClasses.textAreaContainer },
    { container: inputClasses.container },
    inputsStyles
  )
);

const ELEMENT_STYLE_COMPONENTS = {
  /*
  |-------------------------------------------------------------------------------
  | Apply Core App Layout Sizes
  */
  HeaderMenu: StyleSheet({
    container: {
      zIndex: ZINDEX.UNDER_OVERLAY,
      backgroundColor: COLORS.DARK_GREY,
      width: '100%',
      flex: `0 0 4.375rem`
    }
  }),

  SideMenu: StyleSheet({
    container: {
      backgroundColor: COLORS.DARK_GREY,
      width: '20px'
    }
  }),

  Rex2Frame: StyleSheet({
    container: {
      zIndex: ZINDEX.REXFRAME,
      flex: 1
    }
  }),

  InnerField: StyleSheet(innerFieldStyles),

  TextInput: TextInputStyles,
  TextArea: TextAreaStyles,

  Button: StyleSheet({
    container: {
      '&:hover': {
        opacity: 1
      },
      '&:focus': {
        outline: 'none'
      }
    }
  }),

  List: StyleSheet({
    separator: {
      display: 'none'
    }
  }),

  Tab: StyleSheet({
    container: {
      ...TEXTS.BUTTON.CTA_WHITE_REGULAR,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '4px',
      padding: '15px 14px',
      boxSizing: 'border-box',
      width: 'auto',
      textTransform: 'none',
      height: '34px',
      color: COLORS.PRIMARY.WHITE,
      backgroundColor: COLORS.STATES.IDLE,
      borderRadius: '2px 2px 0 0',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      transition: '0.5s',
      '&:active': {
        transform: 'translateY(4px)',
        transition: 'all 0ms'
      }
    },

    containerActive: {
      backgroundColor: COLORS.PRIMARY.GREY_DARK,
      color: COLORS.PRIMARY.WHITE
    },
    containerNested: {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
      color: COLORS.PRIMARY.SAND_LIGHT,

      '&:hover': {
        color: COLORS.PRIMARY.SAND_LIGHT
      }
    },
    containerNestedActive: {
      backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
      color: COLORS.PRIMARY.WHITE,

      '&:hover': {
        color: COLORS.PRIMARY.WHITE
      }
    },
    notActive: {
      '&:hover': {
        backgroundColor: COLORS.STATES.HOVER
      }
    },

    containerDisabled: {
      color: 'lightgrey',
      cursor: 'default',
      '&:hover': {
        color: 'lightgrey'
      }
    }
  }),
  Tabs: StyleSheet({
    container: {
      width: 'auto',
      margin: '0'
    },

    tabHidden: {
      display: 'none'
    },

    tabContent: {
      width: '100%',
      position: 'relative',
      zIndex: '100'
    },

    topBorder: {
      borderTop: '2px solid #b4b1a9'
    }
  }),
  TabBar: StyleSheet({
    list: {
      display: 'flex',
      width: 'auto',
      flexDirection: 'row',
      marginBottom: '0px',
      alignItems: 'flex-start',
      ' > li': {
        height: '35px'
      }
    },
    container: {
      position: 'relative',
      zIndex: '50'
    },

    indicator: {
      height: '0'
    },

    hideTabButton: {
      display: 'none'
    },

    noActionMenu: {
      '& > li:last-child': {
        marginRight: 0
      }
    },

    bottomBar: {
      borderBottom: '5px solid white',
      height: '7px',
      position: 'absolute',
      backgroundColor: COLORS.DARK_GREY,
      width: '100%',
      zIndex: '1'
    },

    lightBar: {
      backgroundColor: '#b4b1a9'
    }
  }),

  SelectMenu: StyleSheet({
    container: {
      border: '0px',
      boxShadow: '0 2px 5px 2px rgba(0,0,0,0.3)',
      width: '100%',
      padding: '5px 0px',
      borderRadius: '0px',
      zIndex: 9000,
      maxHeight: '40vh'
    }
  }),

  SelectOption: StyleSheet({
    container: {
      backgroundColor: '#FFFFFF',
      ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
      paddingLeft: '10px',

      '&:hover': {
        backgroundColor: COLORS.STATES.BLUE
      },

      '&:focus': {
        backgroundColor: COLORS.STATES.BLUE
      }
    },

    isSelected: {
      background: '#EAE9E8',
      color: '#424242'
    },

    isActive: {
      background: '#EAE9E8',
      color: '#424242'
    }
  }),

  SelectValue: StyleSheet({
    container: {
      ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
      paddingLeft: '2.5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }),

  CalendarMonth: StyleSheet({
    week: {
      transition: 'none',
      ' > td': {
        border: 'none'
      }
    },

    title: {
      borderRadius: '0px',
      padding: '7.5px 0 0 0',
      margin: '0px -22px 38px -22px',
      textTransform: 'uppercase',
      // width: '150px',
      color: 'white',
      backgroundColor: COLORS.PRIMARY.GREY_DARK,
      fontSize: '14px',
      height: '30px',
      position: 'relative',
      left: '-1px'
    },

    container: {
      padding: '0 !important'
    }
  }),

  CalendarMonthGrid: StyleSheet({
    horizontal: {
      left: '7px !important'
    }
  }),

  Branch: StyleSheet({
    selected: {
      backgroundColor: '#e6e6e2 !important',
      borderRadius: '50% !important',
      color: '#000000 !important'
    },

    day: {
      transition: 'none',
      fontSize: '14px',
      fontWeight: 500,

      '&:hover': {
        backgroundColor: COLORS.PRIMARY.GREY_DARK,
        borderRadius: '50%',
        color: 'white'
      }
    },

    isOutsideDay: {
      color: '#999 !important'
    },

    hoveredSpan: {
      background: 'transparent'
    }
  }),

  DayPickerNavigation: StyleSheet({
    container: {
      height: '30px',
      ' > div': {
        borderRadius: '0px'
      },
      ' > button': {
        border: 'none',
        borderRadius: '50%',
        backgroundColor: 'white',
        padding: '0',
        ':active': {
          outline: 'none',
          borderColor: 'none',
          boxShadow: '0 0 5px dimgrey'
        }
      }
    },
    horizontal: { top: '0', padding: '0px 10px' }
  }),

  DayPicker: StyleSheet({
    container: {
      boxShadow: 'none',
      filter: 'none',
      border: '1px solid rgba(0,0,0,.15)'
    },

    headers: {
      zIndex: 301,
      position: 'relative',

      '> ul': {
        top: '30px',
        backgroundColor: '#e6e6e2',
        lineHeight: '30px',
        margin: '0',
        minWidth: '100%',
        color: '#000000',
        fontSize: '14px',
        fontWeight: 600
      }
    },

    region: {
      position: 'relative',
      zIndex: 300,
      background: 'white',

      ' > div:last-of-type > div': {
        // TODO: remove translateX animation here
      },

      '>div:last-of-type': {
        borderRadius: '0px'
      }
    }
  }),

  DateSelectInput: StyleSheet({
    wrapDateInput: {
      '>div>div>div': {
        boxShadow: '0 0 21px rgba(0,0,0,.17)',
        borderRadius: '0'
      }
    }
  }),

  ActionMenu: StyleSheet({
    container: {
      transform: 'translateX(10px)'
    },

    menu: {
      display: 'block',
      padding: 0,
      minWidth: '100px',
      margin: 0,
      listStyleType: 'none',
      overflowX: 'hidden'
    },

    menuItem: {
      ...TEXTS.BUTTON.CTA_DARK_GREY,
      fontWeight: 400,
      lineHeight: 'normal',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '100%',
      padding: '4px 20px 4px 10px',

      '&:hover': {
        backgroundColor: '#CFE8F9'
      },

      '& a': {
        display: 'block',
        width: 'auto'
      },

      '& a:hover': {
        opacity: 0.8
      },

      '& a:active:focus': {
        outline: 'none'
      }
    }
  }),

  Tether: StyleSheet({
    wrapContent: {
      zIndex: ZINDEX.POPOUT,
      top: '15px'
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      '&:before': {
        content: '" "'
      }
    }
  }),

  Checkbox: StyleSheet({
    input: {
      '& + label': {
        color: COLORS.COAL_GREY,
        fontSize: '12px',
        fontWeight: 600,

        '&:hover': {
          textDecoration: 'none'
        }
      },
      '&:disabled ~ .styled-label': {
        backgroundColor: COLORS.CHECKBOX.DISABLED
      }
    }
  }),

  FileUploadInput: StyleSheet({
    container: {
      borderRadius: '2px',
      background: COLORS.WHITE,
      padding: '3px',
      maxWidth: '100%'
    }
  }),

  Fixture: StyleSheet({
    icon: {
      color: COLORS.PRIMARY.GREY_LIGHTER
    }
  }),

  Table: StyleSheet({
    tr: {
      flex: '0 1 auto' // TODO: IDK why this is required, make the table row not extend screen height
    }
  }),

  // SELECT
  SingleEntitySelectMenu: StyleSheet({
    items: {
      maxHeight: '250px'
    },
    menu: {
      zIndex: 9000
    }
  }),

  MultiEntitySelectMenu: StyleSheet({
    items: {
      maxHeight: '250px'
    },
    menu: {
      zIndex: 9000
    }
  }),

  ...[
    'EntityMultiSelectInput',
    'EntitySingleSelectInput',
    'BasicSingleSelectInput',
    'BasicMultiSelectInput'
  ].reduce(
    (all, selector) => ({
      ...all,
      [selector]: StyleSheet({
        icon: {
          height: '20px',
          width: '20px',
          color: COLORS.STATES.IDLE,
          cursor: 'pointer'
        },
        error: {
          borderColor: COLORS.PRIMARY.RED
        }
      })
    }),
    {}
  )
};

export default ELEMENT_STYLE_COMPONENTS;

/**
 * SHARED DEFAULTS
 */
export const APP_MENU = {
  RECORDS: StyleSheet({
    container: {
      userSelect: 'none',
      cursor: 'pointer',
      width: '100%',
      minHeight: '62px',
      backgroundColor: COLORS.WHITE
    },
    media: {
      marginRight: PADDINGS.M
    },
    body: {
      width: '100%',
      '& > *:first-child': {
        marginBottom: PADDINGS.XXS
      }
    },
    isActive: {
      background: COLORS.COOL_GREY
    },
    iconBox: {
      position: 'relative',
      backgroundColor: COLORS.APP_SEARCH.TILE_BACKGROUND,
      color: COLORS.BLUE_GREY,
      minWidth: '45px',
      height: '45px',
      margin: `8px ${PADDINGS.S} 9px`
    },
    noIconBox: {
      margin: `8px ${PADDINGS.S} 9px`
    },
    extra: {
      paddingBottom: PADDINGS.XXS
    },
    feint: {
      color: COLORS.BLACK_30_OPACITY
    },
    light: {
      color: COLORS.BLACK_50_OPACITY
    },
    details: {
      overflow: 'hidden',
      marginRight: PADDINGS.M
    },
    extraDetails: {
      margin: `8px ${PADDINGS.S} 9px 0`,
      textAlign: 'right'
    },
    name: {
      ...TEXTS.APP_SEARCH.RECORD_NAME
    },
    meta: {
      ...TEXTS.APP_SEARCH.META_DATA
    },
    id: {
      textAlign: 'right'
    },
    ownerIcon: {
      position: 'absolute',
      top: '-8px',
      left: '-8px',
      '& svg': {
        color: COLORS.BLUE_REX,
        height: '16px',
        width: '16px',
        border: '2px solid white',
        borderRadius: '50%',
        background: 'white'
      }
    },
    permissionIcon: {
      position: 'absolute',
      top: '-8px',
      left: '-8px',
      '& svg': {
        color: COLORS.GREY,
        height: '18px',
        width: '18px',
        border: '2px solid white',
        borderRadius: '50%',
        background: 'white'
      }
    },
    hideIcon: {
      display: 'none'
    }
  }),

  ROWS: StyleSheet({
    container: {},
    optionContainer: {
      flex: 1,
      '& > div:last-of-type': {
        paddingBottom: PADDINGS.M
      }
    },
    viewInList: {}
  }),

  HEADER: StyleSheet({
    container: {
      userSelect: 'none',
      padding: `0 ${PADDINGS.S} ${PADDINGS.XS}`,
      width: '100%'
      // background: COLORS.GREY_COOL
    },
    inner: {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderBottom: `1px solid ${COLORS.BLACK_7_OPACITY}`
    },
    wrapper: {
      display: 'flex',
      paddingBottom: PADDINGS.XXS
    },
    icon: {
      paddingRight: PADDINGS.XS
    },
    heading: {
      marginLeft: '10px',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 900,
      color: COLORS.APP_SEARCH.HEADING
    }
  })
};

const menuListStylesheet = StyleSheet({
  container: {
    backgroundColor: COLORS.WHITE
  },
  labelWrapper: {
    ...TEXTS.NAV_MENU.TIER_ONE_DEFAULT,
    backgroundColor: COLORS.WHITE,
    transition: `background-color ${TIMINGS.NAVIGATION.ACTIVE_ITEM} ease-in-out`
  },
  subMenuLabelWrapper: {
    ...TEXTS.NAV_MENU.TIER_TWO_DEFAULT
  },
  headingWrapper: {
    color: COLORS.NAV_MENU.GREY_SLATE
  },
  headingLabelWrapper: {
    ...TEXTS.NAV_MENU.TIER_TWO_HEADING
  },
  fixedContainer: {
    backgroundColor: COLORS.WHITE
  },
  activeItem: TEXTS.NAV_MENU.TIER_ONE_HOVER,
  activeItemTierTwo: TEXTS.NAV_MENU.TIER_TWO_HOVER,
  activeRoute: TEXTS.NAV_MENU.TIER_ONE_ACTIVE,
  underline: {
    backgroundColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
  },
  separator: {
    borderBottomColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
  }
});

/**
 * Desktop/Tablet Nav Theme
 */
export const DESKTOP_TABLET_MENU_COMPONENTS = ({ agencyColor }) => ({
  DesktopTabletMenu: StyleSheet({
    container: {
      zIndex: '12',
      height: 'calc(100% - 70px)',
      top: '70px'
    },
    containerWithAnnouncement: {
      height: 'calc(100% - 112px)',
      top: '112px'
    },
    primaryMenuList: {
      borderRightColor: '#F7F8F9'
    }
  }),
  MenuList: menuListStylesheet,
  Applications: menuListStylesheet,
  IconBar: StyleSheet({
    container: {
      backgroundColor: COLORS.WHITE
    },
    iconWrapper: {
      background: COLORS.WHITE,
      position: 'relative',
      transition: `background-color ${TIMINGS.NAVIGATION.ACTIVE_ITEM} ease-in-out`,
      '& svg': {
        transition: `color ${TIMINGS.NAVIGATION.ACTIVE_ITEM} ease-in-out`
      }
    },
    iconSvg: {
      color: COLORS.NAV_MENU.GREY_SLATE_DARK
    },
    fixedContainer: {
      backgroundColor: COLORS.WHITE
    },
    activeStateBorder: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      height: '50px',
      width: '3px',
      borderRadius: '0 1px 1px 0',
      backgroundColor: agencyColor || COLORS.NAV_MENU.BLUE
    },
    activeItem: {
      backgroundColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT,
      '& svg': {
        color: COLORS.BLACK
      }
    },
    activeRoute: {
      '& svg': {
        color: COLORS.NAV_MENU.GREY_DARK
      }
    },
    separator: {
      borderBottomColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
    }
  })
});

const menuEntitiesStylesheet = (agencyColor) =>
  StyleSheet({
    container: {
      backgroundColor: COLORS.WHITE
    },
    fixed: {
      borderTopColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
    },
    entityContainer: {
      color: COLORS.NAV_MENU.GREY_DARK
    },
    headingEntityContainer: {
      pointerEvents: 'none'
    },
    genericLabelWrapper: {
      ...TEXTS.NAV_MENU.TIER_ONE_DEFAULT,
      fontSize: '18px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      backgroundColor: 'inherit',
      color: '#424242'
    },
    mobileHeadingLabelWrapper: {
      color: '#A1A7B7',
      fontSize: '16px',
      letterSpacing: '0.70px'
    },
    fixedContainer: {
      backgroundColor: COLORS.WHITE
    },
    activeItem: TEXTS.NAV_MENU.TIER_ONE_HOVER,
    activeItemTierTwo: TEXTS.NAV_MENU.TIER_TWO_HOVER,
    activeRoute: {
      ...TEXTS.NAV_MENU.TIER_ONE_ACTIVE, // TODO: Refactor with mobile defaults
      fontSize: '18px',
      color: 'black',
      '& svg': {
        color: 'black'
      }
    },
    headingUnderline: {
      backgroundColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
    },
    separator: {
      borderBottomColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
    },
    iconWrapper: {
      backgroundColor: 'inherit',
      position: 'relative',
      color: '#7F8289'
    },
    headingIconWrapper: {
      color: '#C1C6D2'
    },
    iconSvg: {},
    activeStateBorder: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      height: '50px',
      width: '3px',
      borderRadius: '0 1px 1px 0',
      backgroundColor: agencyColor
    },
    subMenuLabelWrapper: {
      paddingLeft: '30px',
      paddingRight: '20px'
    }
  });

export const MOBILE_MENU_COMPONENTS = ({ agencyColor }) => {
  const menuEntityStyles = menuEntitiesStylesheet(
    agencyColor || COLORS.NAV_MENU.BLUE
  );

  return {
    MobileMenu: StyleSheet({
      container: {
        zIndex: '13'
      }
    }),
    MenuSwitcher: StyleSheet({
      closeButton: {
        color: COLORS.NAV_MENU.GREY_DARK
      }
    }),
    MenuEntities: menuEntityStyles,
    Applications: menuEntityStyles,
    MenuHeader: StyleSheet({
      header: {
        ...TEXTS.NAV_MENU.MOBILE.TIER_TWO_HEADING,
        fontWeight: FONT.WEIGHTS.SEMIBOLD,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 'inherit',
        padding: '20px',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: COLORS.NAV_MENU.GREY_ULTRA_LIGHT
      }
    })
  };
};
