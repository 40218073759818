import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AnnounceKit from 'announcekit-react';

import { StylesProvider } from '@rexlabs/styling';

import Icon, { ICONS } from 'shared/components/icon';
import Analytics from 'shared/utils/vivid-analytics';
import Circle from 'view/components/navigation/shell/header/components/circle';
import { EVENTS } from 'shared/utils/analytics';
import NotificationBadge from 'view/components/navigation/shell/header/components/notification-badge';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

const widgetStyle = {
  display: 'none'
};

function Changelog({
  currentAgencyColor = 'black'
}: {
  currentAgencyColor?: string;
}) {
  const [announcementCount, setAnnouncementCount] = useState(0);
  const user_details = useSelector(
    (state: any) => state?.session?.user_details
  );
  const apiRegion = useSelector((state: any) => state?.session?.api_region);
  const { country_code: region } = apiRegion;
  const { account_user_id: id, full_name: name, email } = user_details;
  const parent_account_id = useSelector(
    (state: any) => state?.session?.parentAccountIds
  );

  const data = useMemo(
    () => ({
      parent_account_id,
      countryCode: region,
      currentAgencyColor
    }),
    [parent_account_id, region, currentAgencyColor]
  );

  const user = useMemo(() => {
    return {
      id,
      name,
      email,
      region
    };
  }, [id, name, email, region]);

  const resetAnnouncementCount = useCallback(() => {
    setAnnouncementCount(0);
  }, []);

  const handleTrackAnalytics = useCallback(() => {
    Analytics.track({
      event: EVENTS.RELEASE_NOTES.OPEN
    });
  }, []);

  return (
    <StylesProvider>
      <Circle>
        <span>
          <NotificationBadge count={announcementCount} />
          {/* @ts-ignore */}
          <AnnounceKit
            widget='https://announcekit.co/widgets/v2/4voeS4'
            widgetStyle={widgetStyle}
            onWidgetUnread={setAnnouncementCount}
            onWidgetClose={resetAnnouncementCount}
            onWidgetOpen={handleTrackAnalytics}
            user={user}
            data={data}
          >
            <Icon
              color={headerColorContrast(currentAgencyColor)}
              type={ICONS.PRESENT}
            />
          </AnnounceKit>
        </span>
      </Circle>
    </StylesProvider>
  );
}

export default Changelog;
