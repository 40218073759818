import { COLORS, PADDINGS } from 'theme';
import { AiTextInput } from 'features/ai/components/primitives/ai-text-input';
import { AiSelectInput } from 'features/ai/components/primitives/ai-select-input';
import { AiGradientButton } from 'features/ai/components/primitives/ai-gradient-button';
import Box from '@rexlabs/box';
import React, { useEffect } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useWhichWordFactory } from 'hooks/use-which-word';
import _ from 'lodash';

const styles = StyleSheet({
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    flex: 1
  },
  label: {
    fontWeight: 600,
    fontSize: '12px',
    color: COLORS.DARK_GREY
  }
});

const communicationTopics = (ww: ReturnType<typeof useWhichWordFactory>) => [
  {
    label: `${_.startCase(ww('buyer'))} Marketing`,
    value: 'buyer_marketing',
    calls_to_action: [
      'Submit offer',
      'Subscribe for updates',
      'Stay in touch',
      'View other properties'
    ]
  },
  {
    label: 'Prospecting',
    value: 'prospecting',
    calls_to_action: [`Book ${ww('valuation')}`]
  },
  {
    label: `${_.startCase(ww('valuation'))} Follow-up`,
    value: 'appraisal_follow-up',
    calls_to_action: ['List with us']
  },
  {
    label: 'Contracts/Financials',
    value: 'contracts_financials',
    calls_to_action: []
  },
  { label: 'Vendor Updates', value: 'vendor_updates', calls_to_action: [] }
];

export function TextGenerationCommunicationInputs({
  onSubmit,
  isLoading,
  formValues,
  setFormValues,
  setIsFormValid
}) {
  const s = useStyles(styles);
  const ww = useWhichWordFactory();
  const { subject, topic, call_to_action } = formValues;

  const isFormValid = !!subject && !!topic;

  useEffect(() => {
    setIsFormValid(isFormValid);
  }, [isFormValid]);

  return (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='wrap'
      alignItems='flex-end'
      mb={PADDINGS.M}
      style={{ gap: '15px' }}
    >
      <div {...s('field')} style={{ flexBasis: '100%' }}>
        <label htmlFor='subject' {...s('label')}>
          subject
        </label>
        <AiTextInput
          // @ts-ignore
          autoFocus
          name='subject'
          value={subject}
          onChange={(e) => setFormValues({ subject: e.target.value })}
        />
      </div>

      <div {...s('field')}>
        <label htmlFor='topic' {...s('label')}>
          topic
        </label>
        <AiSelectInput
          name='topic'
          options={communicationTopics(ww).map((t) => ({
            label: t.label,
            value: t.value
          }))}
          value={topic}
          suggestive
          alwaysShowAll
          onChange={(e) => {
            setFormValues({ topic: e.target.value });
          }}
        />
      </div>

      <div {...s('field')}>
        <label htmlFor='call_to_action' {...s('label')}>
          call to action (optional)
        </label>
        <AiSelectInput
          suggestive
          alwaysShowAll
          options={
            communicationTopics(ww)
              .find((t) => t.value === topic)
              ?.calls_to_action?.map((t) => ({ label: t, value: t })) || []
          }
          name='call_to_action'
          value={call_to_action}
          onChange={(e) => setFormValues({ call_to_action: e.target.value })}
        />
      </div>

      <div>
        <AiGradientButton
          withIcon
          loading={isLoading}
          disabled={isLoading || !isFormValid}
          onClick={() => onSubmit({ generateFresh: true })}
        >
          Smart Compose
        </AiGradientButton>
      </div>
    </Box>
  );
}
