import React from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { OptionLabel } from 'components/text/option-label';

import { ICONS } from 'shared/components/icon';

import { COLORS, PADDINGS } from 'src/theme';

const { ADD_MEDIUM: AddIcon } = ICONS;

const defaultFixtureStyles = StyleSheet({
  container: {
    cursor: 'pointer'
  },
  isHighlighted: {
    backgroundColor: COLORS.STATES.BLUE
  },
  icon: {
    height: '100%',
    width: '10px'
  }
});

function DefaultFixture({
  styles: s,

  isHighlighted,
  children,
  action,
  setFixtureAction,
  ...props
}) {
  React.useEffect(() => {
    if (action) {
      setFixtureAction(action);
    }
  }, [action, setFixtureAction]);

  return (
    <Box
      {...props}
      {...s('container', { isHighlighted })}
      alignItems='center'
      padding='7px'
    >
      <Box marginRight={PADDINGS.XXS}>
        <AddIcon {...s('icon')} />
      </Box>
      <OptionLabel>{children}</OptionLabel>
    </Box>
  );
}

export default styled(defaultFixtureStyles)(DefaultFixture);
