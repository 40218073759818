import { gql } from 'hooks/use-graphql-query';

export const ProjectStage = gql`
  fragment ProjectStage on ProjectStage {
    id
    name
    project_marketing_state {
      text
    }
    marketing_tag_line
    construction_start_date
    construction_date_quoted
    construction_current_projection
    construction_last_updated
    construction_actual_finished_date
    release_date_quoted
    release_current_projection
    release_last_updated
    release_actual_finished_date
    title_date_quoted
    title_current_projection
    title_last_updated
    title_actual_finished_date
    system_ctime
    system_modtime
    system_created_user {
      name
    }
    project {
      id
    }
  }
`;
