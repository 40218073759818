import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Id } from '@rexlabs/model-generator';
import { NoteItem } from '../entities/notes';
import dayjs from 'dayjs';

export type RecordStreamServiceName =
  | 'Listings'
  | 'Contacts'
  | 'Properties'
  | 'ProjectStages'
  | 'Projects';

export interface LatestNoteData {
  date: string;
  text: string;
  type: string;
}

const actionCreators = {
  getLatestNote: {
    request: ({
      id,
      serviceName
    }: {
      id: Id;
      serviceName: RecordStreamServiceName;
    }) =>
      api
        .post('RecordStream::getRecordStream', {
          service_name: serviceName,
          source_services: ['Notes'],
          record_id: id,
          limit: 1
        })
        .then(({ data }) => {
          const streamEntry: { record: NoteItem; timestamp: number } =
            data?.result?.[0];

          if (!streamEntry) {
            return;
          }

          const note: LatestNoteData = {
            type: 'Note',
            text: streamEntry.record.note,
            date: dayjs.unix(streamEntry?.timestamp).format('DD MMM YYYY')
          };

          if (note.text) {
            const maxNoteLength = 70;
            if (note.text.length > maxNoteLength) {
              note.text = note.text.slice(0, maxNoteLength) + '...';
            }
          }
          return note;
        }),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator<LatestNoteData, typeof actionCreators>(
  'recordStream'
).createModel({
  actionCreators
});
