import { upperFirst, identity } from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { Criteria } from 'src/types/criteria';

type Id = string | number;
export interface ApiActions<T> {
  search: (args?: {
    criteria: Criteria[];
    offset?: number;
    limit?: number;
  }) => Promise<T[]>;
  create: (args: T) => Promise<T>;
  read: (id: Id) => Promise<T>;
  update: (args: T) => Promise<T>;
  duplicate: (args: { id: Id; name: string }) => Promise<T>;
  remove: (id: Id) => void;
  archive: (id: Id) => void;
  sort: (conditions: T[]) => void;
}

const REDUCE = {
  initial: identity,
  success: identity,
  failure: identity
};

const getBaseActions = <T>(entity: string) => {
  const ENTITY = upperFirst(entity);
  return {
    read: {
      request: (id: string) =>
        api
          .post(`${ENTITY}::read`, { id })
          .then((response) => response.data.result),
      reduce: REDUCE
    },
    search: {
      request: (args?: T) =>
        api
          .post(`${ENTITY}::search`, args)
          .then((response) =>
            response.data.result?.rows.sort((a, b) => a.order - b.order)
          ),
      reduce: REDUCE
    },
    create: {
      request: (args: T) =>
        api
          .post(`${ENTITY}::create`, { data: args })
          .then((response) => response.data.result),
      reduce: REDUCE
    },
    update: {
      request: (args: T) =>
        api
          .post(`${ENTITY}::update`, { data: args })
          .then((response) => response.data.result),
      reduce: REDUCE
    },
    remove: {
      request: (id: string) => api.post(`${ENTITY}::purge`, { id }),
      reduce: REDUCE
    },
    archive: {
      request: (id: string) => api.post(`${ENTITY}::archive`, { id }),
      reduce: REDUCE
    },
    duplicate: {
      request: (payload: { id: string; name: string }) =>
        api
          .post(`${ENTITY}::duplicate`, payload)
          .then((response) => response.data.result),
      reduce: REDUCE
    },
    sort: {
      request: (conditions: T[]) =>
        api.post(`${ENTITY}::sort`, { data: conditions }),
      reduce: REDUCE
    }
  };
};

function createEntityModel<T>(
  apiBaseUrl: string,
  customActionCreators = {},
  excludeBaseActionCreators = false
) {
  const baseActions = getBaseActions<T>(apiBaseUrl);
  const extendedActionCreators = {
    ...(!excludeBaseActionCreators ? baseActions : {}),
    ...customActionCreators
  };
  return new Generator<T, typeof extendedActionCreators>(
    apiBaseUrl
  ).createEntityModel({
    actionCreators: extendedActionCreators
  });
}

export { createEntityModel };
