import { Id } from '@rexlabs/model-generator';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { SystemProperties } from '../types/common';

export interface TrustAccount extends SystemProperties {
  account_name: string;
  account_number: string;
  bank_code?: string;
  bank_name?: string;
  direct_entry_user_id: string;
  etag: string;
  id: Id;
  is_valid_account_number: number;
  is_valid_routing_code: number;
  listing_id: Id;
  location: { id: Id; text: string };
  max_transactions?: number;
  name: string;
  project_id?: Id;
  project_stage_id?: Id;
  routing_code: string;
  self_balancing_record: number;
  trust_account_type: string;
}

const TYPE = 'trustAccounts';

const trustAccountsModel = new Generator<TrustAccount>(
  TYPE
).createEntityModel();

trustAccountsModel.select = {
  autocomplete: (searchTerm: string) =>
    api
      .post('TrustAccounts::search', {
        criteria: [
          {
            name: 'trust_account_type',
            value: 'standard'
          },
          { name: 'name', type: 'like', value: `%${searchTerm}%` }
        ]
      })
      .then(({ data }) => {
        return data.result.rows.map((account: TrustAccount) => ({
          value: account.id,
          label: account.name,
          model: trustAccountsModel,
          data: account
        }));
      })
};

export default trustAccountsModel;
