import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Option from './core';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: '#FFFFFF',
    ...TEXTS.SETTINGS_NAVIGATION.MENU_ITEM,
    padding: '4px',
    paddingLeft: '10px',

    '&:hover': {
      backgroundColor: '#CAE8F8'
    }
  }
});

@styled(defaultStyles)
class PostcodeOption extends PureComponent {
  render() {
    const { option, styles: s } = this.props;
    const { data } = option;
    const suburbOrTown = _.get(data, 'suburb_or_town');

    return (
      <Option {...this.props}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          {...s('container')}
        >
          <Box width='80px'>{data.postcode}</Box>
          {suburbOrTown && <Box flex={1}>{suburbOrTown}</Box>}
        </Box>
      </Option>
    );
  }
}

export default PostcodeOption;
