import React from 'react';
import Tooltip from 'view/components/tooltip';
import { Body } from 'components/text/body';

import { TemplateProps } from '../';

function RightsTooltip({ content, children }) {
  return (
    <Tooltip
      inline
      style={{ display: 'flex' }}
      width={192}
      hoverTimeout={1}
      placement='top'
      target={
        // TODO: individual consumers should not care about the use of Rex2FrameWindow
        //  https://app.clubhouse.io/rexlabs/stories/space/50138/rex-code-cleanup
        window && window.top && window.top.Rex2FrameWindow
          ? window.top.Rex2FrameWindow.document.body
          : window.document.body
      }
      Content={() => (
        <Body normal white>
          {content}
        </Body>
      )}
    >
      {children}
    </Tooltip>
  );
}

function TemplateRights({ row, col }: TemplateProps) {
  const rights = row[col.id];
  if (!rights) {
    return null;
  }

  if (!rights.includes('update')) {
    const tooltipContent = rights.includes('read')
      ? 'You have no permission to update this record'
      : 'You have no permission to view/update this record';

    const indicator = rights.includes('read')
      ? 'locked-read-only'
      : 'locked-no-read';

    return (
      <RightsTooltip content={tooltipContent}>
        <span className='list-lock'>
          <span className={indicator} style={{ boxSizing: 'content-box' }} />
        </span>
      </RightsTooltip>
    );
  }

  return null;
}

export default TemplateRights;
