import React, { useMemo } from 'react';

import { useEntityListQuery } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';

import { PADDINGS } from 'shared/theme';
import { LeadsResponse } from 'data/models/entities/leads';
import { getActivitiesQuery } from '../data/activities-query';
import { getRequiredRecordStatus } from '../utils/get-required-record-status';

import { LeadsDialogActionsContainer } from './leads-dialog-actions-container';
import LeadsDialogStatusSection from './leads-dialog-status-section';
import LeadsDialogActionButton, {
  LeadsDialogActions
} from './leads-dialog-action-button';
import { LeadsDialogLeftHandPaneContainer } from './leads-dialog-left-hand-pane-container';
import { LeadsDialogWarningBanner } from './leads-dialog-warning-banner';

interface LeadsDialogLeftHandPanelProps {
  data: LeadsResponse;
  leadsDialogActions: LeadsDialogActions[];
  isLoading: boolean;
  status: 'loaded' | 'error';
  primaryButtonAction: () => void;
  closeDialog?: () => void;
  buttonLabel: string;
}

export function LeadsDialogLeftHandPane({
  data,
  status,
  leadsDialogActions,
  isLoading,
  primaryButtonAction,
  closeDialog,
  buttonLabel
}: LeadsDialogLeftHandPanelProps) {
  // The Activities list model lives in here because we need to use it
  // for the last activity in the status section. While we do have the last activity
  // in the lead itself, we would need to refresh the item to see it. Much easier
  // to take the last activity from the list we already have, then refresh the whole
  // lead each time we take an action.
  const activitiesQuery = useMemo(() => getActivitiesQuery(data.id), [data.id]);
  const activities = useEntityListQuery(activitiesQuery);
  const activitiesList = activities.data || [];
  const lastActivity = activitiesList[0];

  const { hasRequiredRelatedRecords, missingRequiredRecordTypes } = useMemo(
    () => getRequiredRecordStatus(data),
    [data]
  );

  return (
    <LeadsDialogLeftHandPaneContainer
      header={
        <>
          <LeadsDialogStatusSection
            status={status}
            closeDialog={closeDialog}
            data={data}
            lastActivity={lastActivity}
          />

          {!hasRequiredRelatedRecords && (
            <Box pt={PADDINGS.S} pb={PADDINGS.S}>
              <LeadsDialogWarningBanner
                missingRequiredRecordTypes={missingRequiredRecordTypes}
                id={data.id}
                leadType={data.lead_type?.text}
              />
            </Box>
          )}

          <LeadsDialogActionsContainer data={data} activities={activities} />
        </>
      }
      footer={
        <Box p={PADDINGS.S}>
          <LeadsDialogActionButton
            data={data}
            onClick={primaryButtonAction}
            leadsDialogActions={leadsDialogActions}
            isLoading={isLoading}
          >
            {buttonLabel}
          </LeadsDialogActionButton>
        </Box>
      }
    />
  );
}
