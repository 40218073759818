/*
|-------------------------------------------------------------------------------
| Local Storage Interface
|-------------------------------------------------------------------------------
|
| NOTE: One dependency: `window`
|
| Always defer to the top most `localStorage` instance.
|
| This implementation of localStorage splits data into two concerns:
|  1. User data, which can be reset easily
|  2. Persisted app data, which should be kept around after user does a reset
|
*/

// TODO: This should be swapped out to use LocalForage
//  Ticket: https://app.clubhouse.io/rexlabs/story/55180/roll-out-localforage-app-wide

const storage = window.parent.localStorage;
const STORE_DEFAULT_KEY = 'rex_store';
const STORE_PERSISTED_KEY = 'rex_persisted_store';
const STORE_INDEX_KEY = 'rex_store_index';

function getStore(key) {
  const store = storage.getItem(key);
  return typeof store === 'string' ? JSON.parse(store) : {};
}

function getIndex() {
  return getStore(STORE_INDEX_KEY);
}

const LocalStorage = {
  /**
   * Add's data to the local storage. If isPersisted is true, the reset()
   * function won't remove the data for storage.
   * @param domain
   * @param data
   * @param isPersisted
   */
  set(domain, data, isPersisted) {
    // Get the (persistent?) store.
    const storeKey = isPersisted ? STORE_PERSISTED_KEY : STORE_DEFAULT_KEY;

    // Hydrate the store, and set our passed data.
    const store = getStore(storeKey);
    store[domain] = data;

    // Update the index so that we know our passed data is in a particular store.
    const index = getIndex();
    index[domain] = typeof data === 'undefined' ? undefined : storeKey;

    // Persist all operations back into localStorage, as strings.
    storage.setItem(storeKey, JSON.stringify(store));
    storage.setItem(STORE_INDEX_KEY, JSON.stringify(index));
  },

  /**
   * Get data from the store.
   * @param domain
   * @param [defaultVal]
   * @returns {*}
   */
  get(domain, defaultVal) {
    const storeKey = getIndex()[domain];
    let val;
    if (storeKey) {
      const store = getStore(storeKey);
      val = store[domain];
    }

    return typeof val === 'undefined' ? defaultVal : val;
  },

  /**
   * Remove data from the store.
   * @param domain
   * @param wasPersisted
   */
  remove(domain, wasPersisted) {
    LocalStorage.set(domain, undefined, wasPersisted);
  },

  /**
   * Removes all stored data. Will remove persisted data if given a truthy value.
   * @param persistedOnly
   */
  reset(persistedOnly) {
    const storeKey = persistedOnly ? STORE_PERSISTED_KEY : STORE_DEFAULT_KEY;
    const index = getStore(STORE_INDEX_KEY);
    for (const domain in index) {
      const sourceStoreKey = index[domain];
      if (sourceStoreKey === storeKey) {
        index[domain] = undefined;
      }
    }
    storage.removeItem(storeKey);
    storage.setItem(STORE_INDEX_KEY, JSON.stringify(index));
  },

  /**
   * Whether local storage exists in this browser.
   * @returns {boolean}
   */
  isAvailable() {
    return !!storage;
  },

  /**
   * Returns an array of keys for the persisted object
   * @returns {string[]}
   */
  index() {
    return Object.getOwnPropertyNames(getIndex());
  }
};

export default LocalStorage;
