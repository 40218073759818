import { AgentAllocation } from 'features/commission-worksheet/types/commission-worksheet';
import { Body } from 'components/text/body';
import { formatCurrency } from 'utils/formatters';
import React from 'react';
interface MaximumPayableCommissionFormulaProps {
  currencySymbol: string;
  distributableCommissions: number;
  agentAllocation: AgentAllocation;
  availableCommission: number;
}
export const MaximumPayableCommissionFormula = ({
  currencySymbol,
  distributableCommissions,
  agentAllocation,
  availableCommission
}: MaximumPayableCommissionFormulaProps) => {
  return (
    <>
      <Body dark small semibold>
        maximum payable commission
      </Body>
      <Body dark normal>
        {currencySymbol}
        {formatCurrency(distributableCommissions, 2)} ×{' '}
        {formatCurrency(agentAllocation.gross_comm_alloc_percentage, 2)}% ={' '}
        {currencySymbol}
        {agentAllocation.net_comm_amount_unit.id === 'exact_amount'
          ? formatCurrency(availableCommission, 2)
          : `(${formatCurrency(availableCommission, 2)})`}
      </Body>
    </>
  );
};
