// TODO: refactor to be much more generic
import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';

import Box from '@rexlabs/box';
import EntitySelect from 'view/components/input/select/entity-select';
import { Select } from 'view/components/input/select';
import SaveCancelButtonBar from 'view/components/button-bar/save-cancel';
import Dialog from 'view/components/dialog';
import { withValueLists, withModel } from '@rexlabs/model-generator';
import contactsModel from 'data/models/entities/contacts';
import { Form, FormField, ReactForms } from 'view/components/form';

import _ from 'lodash';

import { withErrorDialog } from 'src/hocs/with-error-dialog';
import contactCompanyRelnType from 'data/models/system-lists/contact-company-reln-type';

@withErrorDialog
@withModel(contactsModel)
@autobind
class AddReleatedContactContent extends PureComponent {
  state = {
    options: []
  };

  handleSubmit(values) {
    const {
      valueLists: { contactCompanyRelnType },
      onSave,
      closeDialog
    } = this.props;

    const relationTypes = _.get(contactCompanyRelnType, 'items');

    return onSave(values, relationTypes).then(closeDialog);
  }

  render() {
    const {
      closeDialog,
      valueLists: { contactCompanyRelnType },

      baseRecordId,
      contacts
    } = this.props;

    const { options } = this.state;

    return (
      <Box>
        <ReactForms handleSubmit={this.handleSubmit}>
          {({ submitForm, isSubmitting, setValues }) => {
            return (
              <Form>
                <FormField
                  name='contact'
                  label='contact'
                  Input={EntitySelect}
                  onChange={console.warn}
                  inputProps={{
                    autoFocus: true,
                    models: [contactsModel],
                    relatedDropdownSettings: {
                      service: 'Contacts',
                      recordIds: [baseRecordId]
                    },
                    options: options,
                    valueAsObject: true,
                    hasFixtures: true,
                    fixturePrefillData: {
                      callback: (newContact) => {
                        contacts
                          .createItem({ data: newContact })
                          .then(({ data }) => {
                            const contactOption = {
                              value: data.result,
                              label: data.result.system_search_key,
                              data: data.result,
                              model: contactsModel
                            };

                            this.setState({
                              options: this.state.options.concat([
                                contactOption
                              ])
                            });

                            setValues({ contact: contactOption });
                          });
                      }
                    }
                  }}
                />
                <FormField
                  name='role'
                  label='role'
                  Input={Select}
                  inputProps={{
                    options: _.get(contactCompanyRelnType, 'items'),
                    isLoading:
                      _.get(contactCompanyRelnType, 'status') === 'loading',
                    valueAsObject: true
                  }}
                />

                <SaveCancelButtonBar
                  isLoading={isSubmitting}
                  onSave={submitForm}
                  onCancel={closeDialog}
                />
              </Form>
            );
          }}
        </ReactForms>
      </Box>
    );
  }
}

@withValueLists(contactCompanyRelnType)
class AddRelatedContact extends PureComponent {
  render() {
    const {
      closeDialog,
      onLoad,
      valueLists: { contactCompanyRelnType }
    } = this.props;

    return (
      <Dialog
        title={'Add Officeholder'} // TODO: we may want to conditionally change title
        width={500}
        closeDialog={closeDialog}
        onLoad={onLoad}
        isLoading={_.get(contactCompanyRelnType, 'status') === 'loading'}
        top={Math.max(window.innerHeight / 2 - 100, 0)}
      >
        <AddReleatedContactContent {...this.props} />
      </Dialog>
    );
  }
}

export default AddRelatedContact;
