import _ from 'lodash';
import React from 'react';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import PropertiesOption from 'view/components/input/select/options/properties';
import Value from 'view/components/input/select/values';
import PropertiesFixture from 'view/components/input/select/fixtures/properties';
import { ModelGeneratorLoaded } from '@rexlabs/model-generator';
import { UserItem } from 'data/models/value-lists/account-users';
import { ValueListItem } from '../value-lists/value-list';

const TYPE = 'properties';

export interface PropertySelectOption {
  label: string;
  value: string | number;
  model: typeof propertiesModel;
  data: PropertyStub;
}

export interface LeadsPropertyStub {
  id: string;
  adr_country: string | null;
  adr_postcode: string | null;
  adr_state_or_region: string | null;
  adr_street_name: string | null;
  adr_street_number: string | null;
  adr_suburb_or_town: string | null;
  adr_unit_number: string | null;
}

export interface PropertyAutocomplete {
  id: string;
  category: PropertyCategory;
}

export interface PropertyItem extends PropertyStub {
  attr_bedrooms: number | null;
  attr_bathrooms: number | null;
  attr_carports: number | null;
  attr_buildarea_m2: number | null;
  attr_build_year: number | null;
}

export interface PropertyStub extends LeadsPropertyStub {
  system_search_key: string;
  adr_longitude: string | null;
  adr_latitude: string | null;
  adr_locality: string | null;
  adr_estate_name: string | null;
  adr_estate_stage: string | null;
  adr_building: null | {
    id: string;
    name: string;
  };
  property_category: { id: string; text: string };
  property_subcategory: { id: string; text: string } | null;
  system_owner_last_contacted_date: string | null;
  property_image: {
    uri: string;
    url: string;
    thumbs: {
      '800x600': {
        uri: string;
        url: string;
      };
      '400x300': {
        uri: string;
        url: string;
      };
      '200x150': {
        uri: string;
        url: string;
      };
      '80x60': {
        uri: string;
        url: string;
      };
    };
  };
  default_property_image: {
    uri: string;
    url: string;
    thumbs: {
      '800x600': {
        uri: string;
        url: string;
      };
      '400x300': {
        uri: string;
        url: string;
      };
      '200x150': {
        uri: string;
        url: string;
      };
      '80x60': {
        uri: string;
        url: string;
      };
    };
  };
  etag: string;
  security_user_rights: string[];
  system_owner_user: UserItem;
  attr_tenure: ValueListItem;
  attr_landarea: string;
  attr_landarea_unit: ValueListItem;
}

export type PropertyModel = ModelGeneratorLoaded<PropertyStub, any>;

export type PropertyCategory = {
  id: string;
  text: string;
};

const actionCreators = {
  findPossibleDuplicates: {
    request: (payload) =>
      api.post('Properties::findPossibleDuplicate', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const propertiesModel = new Generator<PropertyStub, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

propertiesModel.select = {
  resultsToSelectOptions: (results) =>
    (results || []).map((property) => ({
      value: property.id,
      label: property.system_search_key,
      data: property,
      model: propertiesModel
    })),
  autocomplete: (searchTerm) =>
    api
      .post('Properties::autocomplete', {
        search_string: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((property) => {
          return {
            value: property.id,
            label: property.address,
            data: property,
            model: propertiesModel
          };
        })
      ),
  Option: PropertiesOption,
  Value: (props) => <Value {...props} service={TYPE} />,
  Fixture: PropertiesFixture,
  getPopoutData: (id) => api.post('Properties::read', { id })
};

export default propertiesModel;
