import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'view/components/button';

import { useRecordNavigation } from './utils/use-record-navigation';

const ArrowIcon: any = ICONS.ARROW_RIGHT;

const defaultStyles = StyleSheet({
  page: {
    marginRight: '4px',
    fontSize: '14px',
    fontSeight: 400
  },

  icon: {
    color: '#fff'
  },

  iconLeft: {},

  iconRight: {
    transform: 'rotate(180deg)'
  }
});

export function RecordDetailsPagination() {
  const s = useStyles(defaultStyles);

  const { allIds, goToNext, goToPrev, currIndex } = useRecordNavigation();
  if (currIndex < 0) {
    return null;
  }

  return (
    <Box flexDirection='row' alignItems='center' sx='4px'>
      <Box>
        <Box {...s('page')}>
          {currIndex + 1} of {allIds.length}
        </Box>
      </Box>
      <DefaultButton
        padding={false}
        isDisabled={currIndex <= 0}
        onClick={() => goToPrev()}
      >
        <ArrowIcon {...s('icon', 'iconLeft')} />
      </DefaultButton>
      <DefaultButton
        padding={false}
        isDisabled={currIndex >= allIds.length - 1}
        onClick={() => goToNext()}
      >
        <ArrowIcon {...s('icon', 'iconRight')} />
      </DefaultButton>
    </Box>
  );
}
