import { store } from 'store';
import { formatKey } from 'utils/pusher';

const CollisionAvoidanceBridge = {
  hasChange: ({ recordId, serviceName }) => {
    const state = store.getState();
    const key = formatKey({ recordId, serviceName });
    return !!state.collisionAvoidance.changes[key];
  }
};

export default CollisionAvoidanceBridge;
