import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';

import Select from './select';

@autobind
class TimeZoneSelect extends PureComponent {
  state = {
    options: [],
    isLoading: true
  };

  componentDidMount() {
    const { value } = this.props;

    // Using Regexp because when you use a string with the replace method
    // It only replaces the first occurrence
    const getLabel = (code) =>
      (code || '').replace(/_/gi, ' ').replace(/\//gi, ' / ');

    import('compact-timezone-list').then(({ minimalTimezoneSet }) => {
      const options = minimalTimezoneSet.map((tz) => ({
        ...tz,
        label: getLabel(tz.tzCode),
        value: tz.tzCode
      }));

      if (!options.find((opt) => opt.tzCode === value)) {
        options.push({
          label: getLabel(value),
          value,
          tzCode: value
        });
      }

      this.setState({
        isLoading: false,
        options
      });
    });
  }

  render() {
    const { isLoading, options } = this.state;
    return (
      <Select
        isLoading={isLoading}
        shouldSelectResetInput={true}
        options={options}
        multi={false}
        {...this.props}
      />
    );
  }
}

export default TimeZoneSelect;
