import { useCallback } from 'react';
import _ from 'lodash';

import { Id } from '@rexlabs/model-generator';

import {
  MailMergeMode,
  MailMergeService
} from 'data/models/entities/mail-merge';

import { useDialog } from './use-dialog';

export type MailMergeRelatedRecordData = { id: Id; name: string } | null;

export type RecordServices = {
  [key in MailMergeService]?: MailMergeRelatedRecordData;
};

export interface MailMergeData extends RecordServices {
  chain_link_id?: Id;
  calendar_event_id?: Id;
  lead_id?: Id;
}

export interface UseSendMailMergeArgs {
  data: MailMergeData;
  callback?: () => any;
  mode?: MailMergeMode;
  options?: {
    title?: string;
  };
  medium: 'email' | 'sms';
  chain_link_id?: Id;
  calendar_event_id?: Id;
  lead_id?: Id;
}

export function useSendMailMerge({
  data,
  callback,
  mode = 'quick',
  options = {},
  medium
}: UseSendMailMergeArgs) {
  const editMailMergeDialog = useDialog('editMailMerge');

  return useCallback(() => {
    editMailMergeDialog.open({
      data: {
        mode,
        medium,
        data
      },
      options: {
        ...options,
        title: `Send ${_.capitalize(medium)}`
      },
      callback
    });
  }, [editMailMergeDialog, mode, medium, data, options, callback]);
}
