import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';

import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';

import { COLORS, PADDINGS } from 'theme';

import Icon, { ICONS } from 'shared/components/icon';

import Circle from 'view/components/navigation/shell/header/components/circle';
import withOverlay from 'view/containers/with-overlay';

import RexUniversityRedirect from 'features/rex-training';

import HelpCentreContent from './help-centre-content';
import { NavigationPopout } from '../navigation-popout';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

const userProfileTooltipOverrides = {
  Tooltip: StyleSheet({
    tooltip: {
      borderRadius: '3px',
      padding: PADDINGS.XXS,
      paddingBottom: PADDINGS.XS,
      maxWidth: 'none',
      background: COLORS.WHITE
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      '&:before': {
        background: COLORS.WHITE
      }
    }
  })
};

@withOverlay(RexUniversityRedirect, { propName: 'redirectOverlay' })
@autobind
class HelpCentre extends PureComponent {
  handleRexUniversityLogin() {
    const { redirectOverlay } = this.props;
    redirectOverlay.open({
      redirectOverlay: redirectOverlay
    });
  }

  render() {
    const { currentAgencyColor } = this.props;
    return (
      <StylesProvider
        components={userProfileTooltipOverrides}
        prioritiseParentStyles={false}
      >
        <NavigationPopout
          placement={PLACEMENTS.BOTTOM_END}
          Content={({ close }) => (
            <HelpCentreContent
              handleRexUniversityLogin={this.handleRexUniversityLogin}
              closePopout={close}
            />
          )}
        >
          <Circle
            style={{
              color: 'black',
              overflow: 'hidden',
              marginLeft: PADDINGS.XS
            }}
            render={() => {
              return (
                <span>
                  <Icon
                    color={headerColorContrast(currentAgencyColor)}
                    type={ICONS.HELP}
                  />
                </span>
              );
            }}
          />
        </NavigationPopout>
      </StylesProvider>
    );
  }
}

export default HelpCentre;
