import { PureComponent } from 'react';
import types from 'prop-types';
import _ from 'lodash';

import { renderProp } from 'shared/utils/react';
import { withRegion } from 'src/hocs/with-region';
import { connect } from 'react-redux';
import { checkUserHasPermission } from 'utils/rights';
import config from 'shared/utils/config';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

const stringOrArray = types.oneOfType([
  types.string,
  types.arrayOf(types.string)
]);

@withRegion
@connect((state) => ({ session: state.session }))
class Requires extends PureComponent {
  static propTypes = {
    accessRights: stringOrArray,
    countries: stringOrArray,
    addons: stringOrArray,
    envConfigs: stringOrArray,
    withFlags: stringOrArray,
    withoutFlags: stringOrArray,
    region: types.shape({
      code: types.string,
      isUK: types.bool,
      isAUS: types.bool,
      isNZ: types.bool,
      isAE: types.bool
    })
  };

  static defaultProps = {
    accessRights: null,
    countries: null,
    addons: null,
    flags: null
  };

  checkUserHasPermission(accessRights) {
    const { session } = this.props;
    return checkUserHasPermission((dotNotation) => _.get(session, dotNotation))(
      accessRights
    );
  }

  hasCountry(countries) {
    const { region } = this.props;

    if (countries.includes('UK') && region.isUK) {
      return true;
    }
    if (countries.includes('NZ') && region.isNZ) {
      return true;
    }
    if (countries.includes('AUS') && region.isAUS) {
      return true;
    }
    if (countries.includes('EU') && region.isEU) {
      return true;
    }
    if (countries.includes('AE') && region.isAE) {
      return true;
    }
    return false;
  }

  hasAddons(addons) {
    const { session } = this.props;
    let passes = true;
    const check = _.isArray(addons) ? addons : [addons];
    check.forEach((addon) => {
      if (!_.get(session, `subscription_limits.add_ons.${addon}`)) {
        passes = false;
      }
    });
    return passes;
  }

  hasEnvConfig(configs) {
    let passes = true;
    const check = _.isArray(configs) ? configs : [configs];
    check.forEach((envConfig) => {
      if (!_.get(config, `${envConfig}`)) {
        passes = false;
      }
    });
    return passes;
  }

  // TODO: add functionality to check for third_party_extensions here

  render() {
    const {
      accessRights,
      countries,
      addons,
      children,
      envConfigs,
      withFlags,
      withoutFlags,
      region,
      ...props
    } = this.props;

    const conditionalRender = () => {
      if (accessRights && !this.checkUserHasPermission(accessRights)) {
        return false;
      }

      if (countries && !this.hasCountry(countries)) {
        return false;
      }

      if (addons && !this.hasAddons(addons)) {
        return false;
      }

      if (envConfigs && !this.hasEnvConfig(envConfigs)) {
        return false;
      }

      if (withFlags && !!hasFeatureFlags && !hasFeatureFlags(withFlags)) {
        return false;
      }
      if (
        withoutFlags &&
        !!hasFeatureFlags &&
        !!hasFeatureFlags(withoutFlags)
      ) {
        return false;
      }

      return true;
    };

    return renderProp(children, { region, ...props }, conditionalRender());
  }
}

export default Requires;
