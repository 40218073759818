import React, { lazy, PureComponent, Suspense } from 'react';
import { setRedirect } from 'shared/utils/api-client';

const WorkflowRequestScreen = lazy(() => import('./workflow-form-request'));

/**
 * WARNING: Do not lazy load this component
 * the setRedirect needs to be run immediately on instantiation or else
 * we get redirected. This breaks the intended behaviour of the WorkflowRequestScreen
 */
class WorkflowRequestScreenContainer extends PureComponent {
  constructor(props) {
    super(props);
    setRedirect(false); // We don't want this page to cause redirect to login
  }

  componentWillUnmount() {
    setRedirect(true);
  }

  render() {
    return (
      <Suspense fallback={null}>
        <WorkflowRequestScreen {...this.props} />
      </Suspense>
    );
  }
}

export default WorkflowRequestScreenContainer;
