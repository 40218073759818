import React, { useCallback, useEffect } from 'react';
import { Body } from 'components/text/body';
import { FormField } from 'view/components/form';
import Checkbox, { CheckboxGroup } from 'view/components/input/checkbox';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import { PADDINGS } from 'shared/theme';
import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import { Grid, Column } from 'shared/components/grid';
import { useRegion } from 'hooks/use-region';
import { StyleSheet } from '@rexlabs/styling';
import { InfoTooltip } from 'view/components/tooltip';
import { TextInput } from '@rexlabs/text-input';
import { COLORS } from 'src/theme';
import { BuyerMatchSettingsData } from 'data/models/entities/admin-buyer-match';
import { SettingsType } from './types';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { SetFieldValue } from '@rexlabs/form';

export const radioButtonOverrides = {
  Checkbox: StyleSheet({
    container: {
      width: 'max-content',
      position: 'relative',
      paddingTop: 0,
      paddingBottom: 0,
      // Suffix
      '& span': {
        position: 'absolute',
        right: -26,
        display: 'flex'
      }
    },

    label: {
      '&&': {
        fontWeight: 600,
        fontSize: '12px',
        paddingLeft: 0
      }
    },

    input: {
      '&& ~ .styled-label': {
        margin: '6px 2px 6px 0',
        height: '17px',
        width: '17px',
        border: `2px solid ${COLORS.STATES.IDLE}`,

        '&:focus': {
          // NOTE: this is bad practise from a11y standpoint and only
          // here to match backwards compatibility of styles :/
          outline: 'none'
        },

        '&:before': {
          background: `${COLORS.DARK_GREY}`
        }
      },

      '&:checked ~ .styled-label': {
        borderColor: `${COLORS.STATES.IDLE} !important`
      },

      '&:hover ~ .styled-label': {
        borderColor: `${COLORS.STATES.HOVER} !important`
      }
    }
  })
};

interface AutomaticResidentialProps {
  values?: BuyerMatchSettingsData & { apply_to_child_accounts: boolean };
  type: SettingsType;
  showSettings: (
    values?: BuyerMatchSettingsData & {
      apply_to_child_accounts: boolean;
    }
  ) => boolean;
  setFieldValue: SetFieldValue<string>;
}

export function AutomaticResidential({
  values,
  type,
  showSettings,
  setFieldValue
}: AutomaticResidentialProps) {
  const { isUK } = useRegion();
  const settingsType = type === 'res_sale' ? 'Sale' : 'Rental';

  // Running this as a side effect because it will have render loop if applied in onChange
  useEffect(() => {
    if (!showSettings(values)) return;
    // Didn't do !values?.[`${type}_suburbs`] because we can't do setFieldValue on undefined params
    if (
      values?.[`${type}_suburbs`] === null ||
      values?.[`${type}_suburbs`] === ''
    ) {
      setFieldValue(`${type}_suburbs`, 'limit');
    }
    if (
      values?.[`${type}_bedroom_min_count`] === null ||
      values?.[`${type}_bedroom_min_count`] === ''
    ) {
      setFieldValue(`${type}_bedroom_min_count`, 'equal');
    }
  }, [values, showSettings, setFieldValue, type]);

  return (
    <Box p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <SubHeading semibold style={{ marginLeft: 0 }}>
        Automatic Residential {settingsType} Match Profiles
      </SubHeading>
      {hasFeatureFlags('auto_match_rentals') ? (
        <Body regular dark style={{ marginLeft: 0 }}>
          Configure settings for your automated residential{' '}
          {settingsType.toLowerCase()} match profiles.
        </Body>
      ) : (
        <Body regular dark style={{ marginLeft: 0 }}>
          {isUK
            ? 'Create match profiles for new listing enquiry leads and guests of viewing appointments. The match profile will be based on the details of the related listing. If the contact already has a Residential Sale match profile, an automatic profile will not be created.'
            : 'Create match profiles for new listing enquiry leads and attendees of open homes or private inspection appointments. The match profile will be based on the details of the related listing. If the contact already has a Residential Sale match profile, an automatic profile will not be created.'}
        </Body>
      )}
      <Grid>
        <Column width={8}>
          <FormField
            name={`${type}_create_from`}
            label={`Automatically create Residential ${settingsType} match profiles for:`}
            Input={CheckboxGroup}
            sendImmediate
            inputProps={{
              items: isUK
                ? [
                    {
                      value: `${type}_create_from_leads`,
                      label: 'listing enquiry leads'
                    },
                    {
                      value: `${type}_create_from_calendar_events`,
                      label: 'guests of viewing appointments'
                    },
                    ...(type === 'res_rental'
                      ? [
                          {
                            value: `res_rental_create_from_tenancy_applications`,
                            label: 'applicants of tenancy applications'
                          }
                        ]
                      : [])
                  ]
                : [
                    {
                      value: `${type}_create_from_leads`,
                      label: 'listing enquiry leads'
                    },
                    {
                      value: `${type}_create_from_feedback`,
                      label: 'open home attendees'
                    },
                    {
                      value: `${type}_create_from_calendar_events`,
                      label: 'guests of private inspection appointments'
                    },
                    ...(type === 'res_rental'
                      ? [
                          {
                            value: `res_rental_create_from_tenancy_applications`,
                            label: 'applicants of tenancy applications'
                          }
                        ]
                      : [])
                  ]
            }}
          />
          {showSettings(values) && (
            <>
              <FormField
                name={`${type}_suburbs`}
                label={
                  <Body semibold dark>
                    {isUK ? 'Postcode' : 'Suburbs'}
                  </Body>
                }
                Input={RadioGroupInput}
                sendImmediate
                inputProps={{
                  overrides: radioButtonOverrides,
                  options: [
                    {
                      label: `limit to ${
                        isUK ? 'district' : 'suburb'
                      } of related listing`,
                      value: 'limit',
                      props: {
                        suffix: isUK && (
                          <InfoTooltip
                            content={
                              <Body normal white>
                                The district is the first 3–4 characters of the
                                postcode, e.g. ‘SW6’
                              </Body>
                            }
                          />
                        )
                      }
                    },
                    {
                      label: `match against any ${
                        isUK ? 'postcode' : 'suburb'
                      }`,
                      value: 'any'
                    }
                  ]
                }}
              />
              <FormField
                name={`${type}_has_min_price`}
                label={
                  <Body semibold dark>
                    Price
                  </Body>
                }
                Input={Checkbox}
                sendImmediate
                inputProps={{
                  value: 'has_min_price',
                  label: 'set a minimum price'
                }}
              />
              {values?.[`${type}_has_min_price`] && (
                <Box flexDirection='row'>
                  <Box style={{ flex: 1 }}>
                    <FormField
                      name={`${type}_min_price`}
                      label='minimum price'
                      Input={TextInput}
                      sendImmediate
                      inputProps={{
                        suffix: '%'
                      }}
                    />
                  </Box>
                  <Box style={{ flex: 4 }} ml={10} mt={46}>
                    <Body regular normal dark>
                      less than related listing match price
                    </Body>
                  </Box>
                </Box>
              )}
              <Box flexDirection='row'>
                <Box style={{ flex: 1 }}>
                  <FormField
                    name={`${type}_max_price`}
                    label='maximum price'
                    Input={TextInput}
                    sendImmediate
                    inputProps={{
                      suffix: '%'
                    }}
                  />
                </Box>
                <Box style={{ flex: 4 }} ml={10} mt={46}>
                  <Body regular normal dark>
                    more than related listing match price
                  </Body>
                </Box>
              </Box>
              <FormField
                name={`${type}_bedroom_min_count`}
                label={
                  <Body semibold dark>
                    Beds
                  </Body>
                }
                Input={RadioGroupInput}
                sendImmediate
                inputProps={{
                  overrides: radioButtonOverrides,
                  options: [
                    {
                      label:
                        'set minimum beds as number of beds in related listing',
                      value: 'equal'
                    },
                    {
                      label:
                        'set minimum beds as one less than number of beds in related listing',
                      value: 'one_less'
                    },
                    {
                      label: 'don’t set minimum beds',
                      value: 'none'
                    }
                  ]
                }}
              />
            </>
          )}
        </Column>
      </Grid>
    </Box>
  );
}
