import { insertCss, reset } from '@rexlabs/styling';

export function initTheme() {
  reset();
  insertCss(`
    html {
      font-size: 62.5% !important;
      height: 100%;
      overflow: hidden;

      box-sizing: border-box;
      font-family: "proxima-nova", 'Proxima Nova', helvetica, arial, sans-serif;
      -webkit-font-smoothing: antialiased;
    }

    body, #app {
      height: 100%;
    }

    button, input, textarea, select {
      font: inherit;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }
  `);
}
