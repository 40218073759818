import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import PaddingBox from 'view/components/padding-box';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',

    '& [data-hover]': {
      opacity: 0,
      transition: 'opacity .2s'
    },

    '&:hover [data-hover]': {
      opacity: 1
    }
  }
});

export function Container({ children, ...props }) {
  const s = useStyles(defaultStyles);
  return (
    <PaddingBox
      white
      flexDirection='column'
      alignItems='flex-start'
      {...props}
      {...s.with('container')(props)}
    >
      {children}
    </PaddingBox>
  );
}
