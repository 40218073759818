import { ProjectRelatedContactsWidget } from '../widgets/related-contacts-widget';
import { ProjectTagsWidget } from '../widgets/tags-widget';
import { ProjectPermissionsWidget } from '../widgets/permissions-widget';
import { ProjectRelatedWorkflowsWidget } from '../widgets/related-workflows-widget';

export const projectsWidgets = {
  default: [
    { Component: ProjectRelatedContactsWidget },
    { Component: ProjectRelatedWorkflowsWidget },
    { Component: ProjectTagsWidget },
    { Component: ProjectPermissionsWidget }
  ]
};
