import React, { ReactElement } from 'react';
import Box from '@rexlabs/box';
import { TEXTS } from 'theme';
import { StyleSheet, useStyles } from '@rexlabs/styling';

type BoxProps = React.ComponentProps<typeof Box>;

const defaultStyles = StyleSheet({
  container: {
    background: 'white',
    marginTop: '20px',
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1
  }
});

function RightBarWidgetContainer(props: BoxProps): ReactElement {
  const s = useStyles(defaultStyles, 'RightBarWidgetContainer');
  return <Box {...s('container')} flexDirection={'column'} {...props} />;
}

export default RightBarWidgetContainer;
