import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import { AiLogo } from 'features/ai/components/ai-logo';

type AiGradientButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  withIcon?: boolean;
  loading?: boolean;
};

const buttonStyles = StyleSheet({
  button: {
    background:
      'radial-gradient(ellipse at left, rgba(248, 250, 255, 1), transparent 200%) padding-box, radial-gradient(ellipse at right, rgba(216, 226, 255, 1), transparent 200%) padding-box, linear-gradient(45deg, rgba(95, 190, 236, 0.75), rgba(180, 85, 255, 0.75)) border-box',
    padding: '10px 14px 10px 14px',
    border: '1px solid transparent',
    fontSize: '13px',
    lineHeight: '14px',
    fontWeight: 600,
    webkitAppearance: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    color: COLORS.DARK_GREY,
    textAlign: 'center',
    transition:
      'color 0.15s ease-out, box-shadow 0.15s ease-out, filter 0.15s ease-out',
    filter: 'grayscale(0%)',
    boxShadow:
      '0px 2px 4px 0px rgba(100, 100, 120, 0.2), 0px 0px 10px 0px rgba(95, 190, 236, 0.25)',
    '&:hover:not([disabled])': {
      color: 'rgba(130, 80, 200, 1)',
      boxShadow:
        '0px 3px 8px 0px rgba(100, 100, 120, 0.2), 0px 4px 10px -3px rgba(95, 190, 236, 0.5)',
      background:
        'radial-gradient(ellipse at left, rgba(248, 250, 255, 1), transparent 200%) padding-box, radial-gradient(ellipse at right, rgba(216, 226, 255, 1), transparent 200%) padding-box, linear-gradient(90deg, rgba(95, 190, 236, 0.9), rgba(180, 85, 255, 0.9)) border-box'
    },
    '&[disabled]': {
      boxShadow: 'none',
      opacity: 0.7,
      filter: 'grayscale(100%)',
      cursor: 'default',
      background:
        'radial-gradient(ellipse at left, rgba(248, 250, 255, 1), transparent 200%) padding-box, radial-gradient(ellipse at right, rgba(216, 226, 255, 1), transparent 200%) padding-box'
    }
  },
  loading: {
    cursor: 'progress !important'
  }
});

export function AiGradientButton({
  onClick,
  children,
  withIcon,
  disabled,
  loading
}: AiGradientButtonProps) {
  const s = useStyles(buttonStyles);

  return (
    <button {...s('button', { loading })} onClick={onClick} disabled={disabled}>
      {withIcon ? <AiLogo style={{ width: '14px', height: '14px' }} /> : null}
      {children}
    </button>
  );
}
