import React from 'react';
import { query } from '@rexlabs/model-generator';
import { RecordListScreen } from 'components/record-list-screen';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import billsModel from 'features/bills/data/bills';
import { useBillsColumns } from 'features/bills/hooks/use-bills-columns';
import { useBillsLenses } from 'features/bills/hooks/use-bills-lenses';
import { useBillsActions } from 'features/bills/hooks/use-bills-actions';
import { useBillsFilters } from 'features/bills/hooks/use-bills-filters';

const billsQuery = query`{
  ${billsModel} {
    id
  }
}`;

function AllBills() {
  const columns = useBillsColumns();
  const lenses = useBillsLenses();
  const actions = useBillsActions();
  const filters = useBillsFilters();

  const whereabouts = useWhereaboutsWithViewpath();

  const { path } = whereabouts;

  return (
    // HACK: So we really need to work out what is going on here...
    // I've had to add this path check as changing from one shell screen
    // to another, causes the list to load again, and a read request.
    // So looks like this page is refreshing during the transient state.
    // This extra check makes sure the path is what we're after. If not
    // it clears out the ui.
    // https://app.shortcut.com/rexlabs/story/67425/react-lists-investigate-why-list-views-performs-api-requests-when-changing-to-another-react-screen
    <>
      {path?.startsWith('/bills') && (
        <>
          <RecordListScreen
            query={billsQuery}
            columns={columns}
            title={'Bills'}
            serviceName='Invoices'
            lenses={lenses}
            actions={actions}
            filtersConfig={filters}
          />
        </>
      )}
    </>
  );
}

export default AllBills;
