import React from 'react';

import { useDownshiftContext } from '@rexlabs/core-select';

import { normalizer } from 'view/components/input/select/street-suburb-postcode-eu';
import DefaultFixture from 'view/components/input/select/defaults/fixture';

export default [
  ({ ...props }) => {
    const { inputValue, selectedItems, selectItem } = useDownshiftContext();
    const fixtureAction = () => {
      const normalizerValues = () => {
        const value = inputValue.toUpperCase();
        const defaultLabel = [{ id: value, text: value }];

        const fullregx = RegExp(
          '^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$'
        );
        const sectregx = RegExp('^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9])$');
        const distregx = RegExp('^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]?)$');
        const arearegx = RegExp('^([A-Z][A-HJ-Y]?)$');

        if (fullregx.test(value)) {
          return { postcodes: defaultLabel };
        }
        if (distregx.test(value)) {
          return { postcodeDistricts: defaultLabel };
        }
        if (sectregx.test(value)) {
          return { postcodeSectors: defaultLabel };
        }
        if (arearegx.test(value)) {
          return { postcodeAreas: defaultLabel };
        }

        // If doesn't match anything, returning invalid postcode as BE will validate on save
        return { postcodes: defaultLabel };
      };

      inputValue &&
        selectItem([...selectedItems, ...normalizer(normalizerValues())]);
    };
    return (
      <DefaultFixture {...props} action={fixtureAction}>
        Add as postcode
      </DefaultFixture>
    );
  },
  ({ ...props }) => {
    const { inputValue, selectedItems, selectItem } = useDownshiftContext();
    const fixtureAction = () =>
      inputValue &&
      selectItem([
        ...selectedItems,
        ...normalizer({
          streets: [{ id: inputValue, text: inputValue }]
        })
      ]);
    return (
      <DefaultFixture {...props} action={fixtureAction}>
        Add as custom street
      </DefaultFixture>
    );
  },
  ({ ...props }) => {
    const { inputValue, selectedItems, selectItem } = useDownshiftContext();
    const fixtureAction = () =>
      inputValue &&
      selectItem([
        ...selectedItems,
        ...normalizer({
          suburbs: [{ suburb_or_town: inputValue, country: 'UK' }]
        })
      ]);
    return (
      <DefaultFixture {...props} action={fixtureAction}>
        Add as custom town / city
      </DefaultFixture>
    );
  },
  ({ ...props }) => {
    const { inputValue, selectedItems, selectItem } = useDownshiftContext();
    const fixtureAction = () =>
      inputValue &&
      selectItem([
        ...selectedItems,
        ...normalizer({
          localities: [{ id: inputValue, text: inputValue }]
        })
      ]);
    return (
      <DefaultFixture {...props} action={fixtureAction}>
        Add as custom locality
      </DefaultFixture>
    );
  }
];
