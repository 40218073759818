import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import Box from '@rexlabs/box';
import { DefaultButton } from 'view/components/button';
import types from 'prop-types';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  toggleIcon: {
    color: COLORS.PRIMARY.WHITE,
    height: '24px',
    width: '24px'
  }
});

@styled(defaultStyles)
@autobind
class CoreToggle extends PureComponent {
  static propTypes = {
    buttons: types.arrayOf(
      types.shape({
        id: types.string.isRequired,
        text: types.string,
        Icon: types.func.isRequired
      })
    ).isRequired,
    onChange: types.func,
    alwaysShowLabel: types.bool
  };

  static defaultProps = {
    onChange: _.noop
  };

  state = {
    active: this.props.value || this.props.buttons[0].id
  };

  handleChange(id) {
    const { onChange } = this.props;
    const { active } = this.state;

    if (id === active) {
      return;
    }

    this.setState({ active: id }, () => {
      onChange({ target: { value: id } });
    });
  }

  mapButtons() {
    const { styles: s, buttons, alwaysShowLabel } = this.props;
    const { active } = this.state;
    return buttons.map(({ Icon, text, id }, index) => {
      return (
        <DefaultButton
          key={index}
          style={{
            boxShadow: active === id ? 'inset 0 0 4px black' : undefined,
            padding:
              active !== id && !alwaysShowLabel ? '0 0 0 3px' : undefined,
            borderTopRightRadius: buttons.length - 1 === index ? undefined : 0,
            borderBottomRightRadius:
              buttons.length - 1 === index ? undefined : 0,
            borderTopLeftRadius: index === 0 ? undefined : 0,
            borderBottomLeftRadius: index === 0 ? undefined : 0
          }}
          onClick={() => this.handleChange(id)}
          IconLeft={Icon ? () => <Icon {...s('toggleIcon')} /> : undefined}
          dark={active === id}
        >
          {active === id || alwaysShowLabel ? text : null}
        </DefaultButton>
      );
    });
  }

  render() {
    return (
      <Box flexDirection={'row'} alignItems={'center'}>
        {this.mapButtons()}
      </Box>
    );
  }
}

export default CoreToggle;
