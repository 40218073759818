import _ from 'lodash';
import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import { UserItem } from 'data/models/value-lists/account-users';

interface TemplateFieldItem {
  merge_tag?: string;
  merge_tag_category?: string;
  provider_field_id: string;
  provider_field_name: string;
  provider_field_type: string;
  provider_role_id: string;
  read_only: string;
}

export interface RoleItem {
  provider_role_id: 'Agent' | 'Contact';
  role_type: {
    id: string;
    text: string;
  };
}

interface TemplateData {
  account_id: string | number;
  description: string;
  email_body: string;
  email_editable: number;
  email_subject: string;
  esign_template_fields: TemplateFieldItem[];
  esign_template_roles: RoleItem[];
  etag: string;
  id: string | number;
  is_hidden: string;
  library: string;
  module: {
    id: string;
    text: string;
  };
  name: string;
  provider_template_id: string;
  provider_template_name: string;
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_owner_user: UserItem;
}

export interface TemplateValue {
  id: string | number;
  label: string;
  templateData: TemplateData;
  value: string | number;
}

const actionCreators = {
  getTemplatesList: {
    request: ({ connectionId }) =>
      api.post('ThirdPartyServiceHelloSign::getTemplatesList', {
        connection_id: connectionId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  createEsignTemplate: {
    request: ({ connectionId, data }) =>
      api.post('ThirdPartyServiceHelloSign::createEsignTemplate', {
        connection_id: connectionId,
        data
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getAvailableRexTemplates: {
    request: ({ connectionId, moduleIds }) =>
      api.post('ThirdPartyServiceHelloSign::getAvailableRexTemplates', {
        connection_id: connectionId,
        limit_to_module_ids: moduleIds
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getIssuesPreventingUse: {
    request: ({ connectionId, templateId }) =>
      api
        .post('ThirdPartyServiceHelloSign::getIssuesPreventingUse', {
          connection_id: connectionId,
          esign_template_id: templateId
        })
        .then((response) => response.data.result),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  sendSignRequest: {
    request: ({ connectionId, data }) =>
      api.post('ThirdPartyServiceHelloSign::sendSignRequest', {
        connection_id: connectionId,
        data
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getConnectionDetails: {
    request: ({ connectionId }) =>
      api.post('ThirdPartyServiceHelloSign::getConnectionDetails', {
        connection_id: connectionId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  setSettings: {
    request: ({ connectionId, settings }) =>
      api.post('ThirdPartyServiceHelloSign::setSettings', {
        connection_id: connectionId,
        settings
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getSettings: {
    request: ({ connectionId }) =>
      api.post('ThirdPartyServiceHelloSign::getSettings', {
        connection_id: connectionId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getTemplateDetails: {
    request: ({ connectionId, providerTemplateId }) =>
      api.post('ThirdPartyServiceHelloSign::getTemplateDetails', {
        connection_id: connectionId,
        provider_template_id: providerTemplateId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  refreshTemplateDetails: {
    request: ({ connectionId, templateId }) =>
      api.post('ThirdPartyServiceHelloSign::refreshTemplateDetails', {
        connection_id: connectionId,
        template_id: templateId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('thirdPartyServiceHelloSign').createModel({
  actionCreators
});
