import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { ICONS } from 'shared/components/icon';

import { PADDINGS } from 'theme';

import Tooltip from 'view/components/tooltip';

import { Body } from 'components/text/body';

const DocumentSvg: any = ICONS.DOCUMENT_12;
const DocumentCogSvg: any = ICONS.DOCUMENT_COG_12;

const defaultStyles = StyleSheet({
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '140px'
  },
  container: {
    columnGap: PADDINGS.XS
  }
});

interface ItemNameProps {
  library: { id: string; library_name: string };
}

export function SavedListItemLibraryLabel(props: ItemNameProps) {
  const { library } = props;

  const s = useStyles(defaultStyles);

  return (
    <Box flexDirection='row' alignItems={'center'} {...s('container')}>
      <Tooltip
        inline
        hoverTimeout={1}
        distance={'10px'}
        placement='top'
        Content={() => (
          <Body normal white>
            {`${library.library_name} template library`}
          </Body>
        )}
      >
        {/* NOTE: id = 1 is reserved for Rexlabs System Template therefore, Document Cog icon is used for demarkation
         */}
        {library.id !== '1' ? <DocumentSvg /> : <DocumentCogSvg />}
      </Tooltip>
    </Box>
  );
}
