import React from 'react';

import { LeadStreamItem } from 'components/_experiment/stream-items/lead-stream-item';
import { NoteStreamItem } from 'components/_experiment/stream-items/note-stream-item';

// Maps `source_service` to stream item components
const MAP = {
  Leads: LeadStreamItem,
  Notes: NoteStreamItem
};

interface StreamItemProps {
  data: any;
  pinned?: boolean;
}

export function StreamItem({ data, pinned }: StreamItemProps) {
  const Item = MAP[data?.source_service];
  if (!Item) {
    console.error(`Item for "${data?.source_service}" not defined!`);
    return null;
  }

  return <Item data={data} pinned={pinned} />;
}
