import { useCallback, useEffect, useRef, useState } from 'react';
import { UseGraphqlQueryArgs, makeGraphqlQuery } from 'hooks/use-graphql-query';
import { Canceler } from 'axios';
import { useStabilizedObject } from 'hooks/use-stabilized-object';

interface UseBatchedGraphQlQueryArgs extends UseGraphqlQueryArgs {
  pages: number;
}

interface GetNextPageArgs extends UseBatchedGraphQlQueryArgs {
  previousResult?: PaginatedResultFormat;
  cancelFunctionRef: React.MutableRefObject<Canceler | null>;
  pageNumber?: number;
}

function getNextPage({
  query,
  variables,
  cancelFunctionRef,
  previousResult,
  pages,
  pageNumber = 0
}: GetNextPageArgs): Promise<PaginatedResultFormat> {
  const cursor = previousResult?.rowData?.pagination.next_page?.cursor;
  const previousRowData = previousResult?.rowData || {};
  const previousData = previousResult?.rowData?.rows || [];

  return makeGraphqlQuery<PaginatedResultFormat>({
    query,
    variables: {
      ...variables,
      ...(cursor ? { cursor } : {})
    },
    cancelFunctionRef
  }).then((newResult) => {
    const rowData = newResult.rowData.rows;
    if (pageNumber < pages && newResult.rowData.pagination.next_page?.cursor) {
      return getNextPage({
        query,
        variables,
        cancelFunctionRef,
        previousResult: {
          ...newResult,
          rowData: {
            ...newResult.rowData,
            rows: [...previousData, ...rowData]
          }
        },
        pages,
        pageNumber: pageNumber + 1
      });
    }

    return {
      ...previousResult,
      rowData: {
        ...newResult.rowData,
        ...previousRowData,
        rows: [...previousData, ...rowData]
      }
    };
  });
}

export interface PaginatedResultFormat {
  rowData: {
    pagination: {
      next_page?: {
        cursor: string;
      };
    };
    rows: Record<string, any>[];
  };
}

/**
 * Do a batch request to fetch multiple pages of data.
 * Won't return anything until all pages have been fetched.
 * Note that this implementation is opinionated to reporting in that it relies on the data being present in a 'rowData'
 * key which is a reporting-specific alias.  If we want to use this elsewhere we might have to make it generic as to which key to use
 *
 * @param pages
 * @param query
 * @param variables
 */
export function useBatchedGraphQlQuery({
  pages,
  query,
  variables
}: UseBatchedGraphQlQueryArgs) {
  const [data, setData] = useState<PaginatedResultFormat | null>(null);
  const [status, setStatus] = useState<'ready' | 'loading'>('ready');
  const cancelFunctionRef = useRef<Canceler | null>(null);
  // we want to make sure that we only re-query when the variables REALLY change
  const stabilizedQueryVariables = useStabilizedObject(variables);

  const doQuery = useCallback(() => {
    if (status === 'loading') {
      cancelFunctionRef.current?.('Cancel any previous request');
    }
    setStatus('loading');
    return getNextPage({
      query,
      variables: stabilizedQueryVariables,
      cancelFunctionRef,
      pages
    })
      .then((data) => {
        setData(data);
        setStatus('ready');
        return data;
      })
      .catch((e) => {
        if (e?.problem === 'CANCEL_ERROR') {
          // Do nothing, we cancelled in favour of an incoming request
        } else {
          throw e;
        }
      });
  }, [query, stabilizedQueryVariables]);

  useEffect(() => {
    doQuery();
  }, [doQuery]);

  return {
    data,
    status,
    reload: doQuery
  };
}
