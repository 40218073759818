import React from 'react';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import { IconButton } from 'src/view/components/button';
import { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';

interface DownloadCellProps extends DefaultCellProps {
  value: string;
  label: string;
  onClick: (data: unknown) => void;
}

function DownloadCell({
  label,
  width,
  onClick,
  data,
  ...rest
}: DownloadCellProps) {
  return (
    <DefaultCell
      {...rest}
      width={width}
      emptyValue={''}
      value={
        <Box flexDirection='row' justifyContent='flex-end' width={width}>
          <IconButton
            red
            circle
            Icon={ICONS.DOWNLOAD_SMALL}
            iconStyles={{ width: 16, height: 16 }}
            onClick={() => onClick(data)}
          >
            {label}
          </IconButton>
        </Box>
      }
    />
  );
}

export default DownloadCell;
