import { useCurrentModuleConfig } from '../modules/module-provider';

export function useDefaultModuleSettings() {
  const {
    defaultUserFieldPreference,
    defaultDatePreset,
    defaultDateFieldPreference
  } = useCurrentModuleConfig();

  return {
    ...(defaultUserFieldPreference
      ? {
          selectedUserFieldPreference: defaultUserFieldPreference
        }
      : {}),
    ...(defaultDateFieldPreference
      ? {
          selectedDateFieldPreference: defaultDateFieldPreference
        }
      : {}),
    ...(defaultDatePreset
      ? {
          selectedDateFilter: {
            preset: defaultDatePreset
          }
        }
      : {})
  };
}
