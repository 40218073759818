/*
|-------------------------------------------------------------------------------
| DialogsBridge
|-------------------------------------------------------------------------------
|
| This file creates the bridge methods for classic to deal with dialogs in
| Shell. Dialogs handled through the bridge are opened and closed via simple
| events emitted here and listened to in the DialogOverlayStack component.
|
| NOTE: we're just looping over the dialogs mapping here, which is where
| all the meta information regarding dialog is and should be defined!
|
*/

import { DIALOG_ACTIONS, dialogEmitter } from 'data/emitters/dialogs';
import mappings from 'utils/dialogs/mapping-shell';
import invariant from 'invariant';
import { createBridgeProxy } from 'utils/bridge-proxy';

let counter = 0;

// TODO - type properly.
// Should be possible to indicate what keys we can have here by using the type of mapping-shell
// also compare dialogs-classic.ts
// SC - https://app.shortcut.com/rexlabs/story/61580/type-shell-dialogsbridge
const DialogsBridge: Record<string, any> = Object.keys(mappings).reduce(
  (all, callName) => {
    all[callName] = {
      // Open shell dialog (from classic)
      open: (props) => {
        const { ShellComponent } = mappings[callName];

        invariant(
          ShellComponent,
          `No shell component defined for dialog with callName "${callName}"!`
        );

        const uuid = ++counter;

        dialogEmitter.emit(DIALOG_ACTIONS.OPEN, {
          DialogComponent: ShellComponent,
          callName,
          uuid,
          props: createBridgeProxy(props) || {}
        });
      },

      // Close dialog (doesn't matter if classic or shell!)
      close: (uuid) => {
        dialogEmitter.emit(DIALOG_ACTIONS.CLOSE, {
          uuid,
          callName
        });
      }
    };
    return all;
  },
  {}
);

DialogsBridge.closeAll = function () {
  dialogEmitter.emit(DIALOG_ACTIONS.CLOSE_ALL);
};

export default DialogsBridge;
