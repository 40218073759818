import _ from 'lodash';
import axios from 'axios';
import config from 'shared/utils/config';
import ExternalAddressValue from 'view/components/input/select/values/external-address';
import ExternalAddressOption from 'view/components/input/select/options/external-address';
import { Generator } from 'shared/utils/models';

const actionCreators = {
  autocomplete: {
    request: (searchTerm, { proximityCoords, region }, model) =>
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json` +
            `?access_token=${config.MAPBOX_TOKEN}` +
            `&types=address&limit=5${
              proximityCoords ? '&proximity=' + proximityCoords.join(',') : ''
            }&country=${region}`
        )
        .then((result) => {
          return _.get(result, 'data.features', []).map((feature) => ({
            value: feature.place_name,
            label: feature.place_name // take out the country from the end of the string
              .split(',')
              .slice(0, -1)
              .join(','),
            model,
            data: {
              name: feature.place_name,
              longitude: _.get(feature, 'center.0'),
              latitude: _.get(feature, 'center.1'),
              mapbox: feature
            }
          }));
        })
        .catch((e) => {
          console.error(e);
        }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const externalAddressModel = new Generator('externalAddress').createModel({
  actionCreators
});

externalAddressModel.select = {
  autocomplete: (searchTerm, props) =>
    actionCreators.autocomplete.request(
      searchTerm,
      props,
      externalAddressModel
    ),
  Value: ExternalAddressValue,
  Option: ExternalAddressOption
};

export default externalAddressModel;
