import React, { PureComponent, Fragment } from 'react';

import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import Box from '@rexlabs/box';
import PaddingBox from 'view/components/padding-box';
import { ReactForms, FormField, Form } from 'view/components/form';
import { Select } from 'view/components/input/select';
import { SubText } from 'components/text/sub-text';
import { Link } from 'components/text/link';
import { Hint } from 'components/text/hint';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'theme';
import { withDialog } from 'shared/hocs/with-dialog';
import IDVerificationDialog, {
  TYPES as ID_VERIFICATION_TYPES
} from 'view/dialogs/verifications/id-verification';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import _ from 'lodash';
import dayjs from 'dayjs';
import { api } from 'shared/utils/api-client';
import WarningBox from 'view/components/warning-box';

const defaultStyles = StyleSheet({
  errorIcon: {
    color: COLORS.PRIMARY.RED
  },
  infoIcon: {
    color: COLORS.PRIMARY.SAND
  },
  correctionsChild: {
    display: 'inline-block'
  }
});

@withErrorDialog
@withDialog(IDVerificationDialog, { propName: 'idVerification' })
@styled(defaultStyles)
@autobind
class AMLSubmissionDialog extends PureComponent {
  onChangeToManualClick() {
    const { setManual } = this.props;
    setManual(true);
  }

  onGoToIDClick() {
    const { contact, contactIdentityVerifications } = this.props;
    this.props.idVerification.open({
      contactId: _.get(contact, 'id'),
      type: _.get(contact, 'type', ID_VERIFICATION_TYPES.PERSON),
      onClose: contactIdentityVerifications.refreshList
    });
  }

  isUnverified() {
    return !_.get(
      this.props.contactIdentityVerifications,
      'list.items.0.verified_at'
    );
  }

  hasPermissions(submit) {
    const { session } = this.props;

    if (submit) {
      return session.checkUserHasPermission(
        'contacts.submit_to_aml_check_provider'
      );
    }

    return session.checkUserHasPermission(
      'contacts.change_to_manual_aml_check'
    );
  }

  handleSubmit(values) {
    const {
      setSubmitted,
      amlChecks,
      errorDialog,
      contact,
      contactIdentityVerifications,
      callback,

      providers
    } = this.props;
    // TODO: All of this needs values verified and testing
    const amlRecord = _.get(amlChecks, 'list.items.0');
    const method = _.get(amlRecord, 'id') ? 'Update' : 'Create';

    const externalCheckData = {
      contact_identity_verification_id: _.get(
        contactIdentityVerifications,
        'list.items.0.id'
      ),
      connection_id:
        _.get(values, 'provider_id', _.get(providers, '0.id')) &&
        Number(_.get(values, 'provider_id', _.get(providers, '0.id')))
    };

    if (!isNaN(externalCheckData.connection_id)) {
      const requests = [
        [
          `ContactAntiMoneyLaunderingChecks::${method}`,
          {
            data: {
              id: _.get(amlRecord, 'id'),
              contact_id: _.get(contact, 'id'),
              check_type_id: 'external',
              status_id: 'submitted'
            }
          }
        ],
        [
          'ContactAntiMoneyLaunderingChecks::submitExternalCheck',
          externalCheckData
        ]
      ];

      return api
        .batch(requests, true)
        .then(({ data: { result } }) => {
          result = _.last(result);
          const wait = [];
          if (callback) {
            wait.push(callback());
          }

          if (_.get(result, 'submission_errors')) {
            wait.push(amlChecks.refreshList());
            return Promise.all(wait);
          }

          return Promise.all(wait).then(() => setSubmitted(true));
        })
        .catch(errorDialog.open);
    }
  }

  renderUnverified() {
    const { styles: s } = this.props;
    return (
      this.isUnverified() && (
        <Box p={'20px 20px 0 20px'} flexDirection={'row'} alignItems={'center'}>
          <ICONS.WARNING {...s('errorIcon')} />
          <Box pl={'5px'} pr={'10px'}>
            <SubText informative>
              Please verify ID before submitting AML check
            </SubText>
          </Box>
          <Link regular onClick={this.onGoToIDClick}>
            Go to ID Verification
          </Link>
        </Box>
      )
    );
  }

  renderSubmitted() {
    const { amlChecks } = this.props;
    const amlRecord = _.get(amlChecks, 'list.items.0');

    const modified = _.get(amlRecord, 'updated_or_submitted_at');
    const modifiedAt = modified
      ? dayjs
          .unix(_.get(amlRecord, 'updated_or_submitted_at'))
          .format('DD/MM/YY')
      : null;

    const modifiedBy = modified
      ? `${_.get(amlRecord, 'updated_or_submitted_by_user.name')}`
      : null;

    return (
      modified && (
        <Box pt={'10px'}>
          <SubText normal>
            Last submitted by {modifiedBy} {modifiedAt}
          </SubText>
        </Box>
      )
    );
  }

  renderExpired() {
    const { amlChecks } = this.props;
    const amlRecord = _.get(amlChecks, 'list.items.0');

    const isExpired = _.get(amlRecord, 'status.id') === 'is_expired';

    return (
      isExpired && (
        <WarningBox
          prefix={'Note'}
          message={'This AML submission has expired - please resubmit'}
        />
      )
    );
  }

  renderNoSubmitPermissions() {
    const { styles: s } = this.props;
    return (
      !this.isUnverified() &&
      !this.hasPermissions(true) && (
        <Box p={'20px 20px 0 20px'} flexDirection={'row'} alignItems={'center'}>
          <ICONS.INFO {...s('infoIcon')} />
          <Box pl={'5px'}>
            <Hint dark regular normal>
              You do not have permission to manually change the AML status
            </Hint>
          </Box>
        </Box>
      )
    );
  }

  renderCorrectionsRequired() {
    const { styles: s, amlChecks, providers = [] } = this.props;
    const amlRecord = _.get(amlChecks, 'list.items.0', []);

    const thirdPartyId = _.get(amlRecord, 'thirdparty_connection_id', '');
    const provider = providers.find(
      (p) =>
        _.get(p, 'id', '').toString() === thirdPartyId &&
        thirdPartyId.toString()
    );

    const message = _.get(amlRecord, 'submission_errors');

    return (
      message && (
        <PaddingBox yellow>
          <Hint dark semibold normal>
            <Box {...s('correctionsChild')} pr={'5px'}>
              <Hint dark bold normal>
                Corrections required from {_.get(provider, 'connection_name')}
              </Hint>
            </Box>
            {message}
            <Box {...s('correctionsChild')} pl={'10px'}>
              <Link regular onClick={this.onGoToIDClick}>
                Go to ID Verification
              </Link>
            </Box>
          </Hint>
        </PaddingBox>
      )
    );
  }

  render() {
    const { closeDialog, amlChecks, providers } = this.props;
    const amlRecord = _.get(amlChecks, 'list.items.0', []);

    const disabled = this.isUnverified();

    return (
      <Fragment>
        {this.renderExpired()}
        {this.renderCorrectionsRequired()}
        {this.renderUnverified()}
        {this.renderNoSubmitPermissions()}
        <ReactForms
          initialValues={{
            provider_id:
              _.get(amlRecord, 'thirdparty_connection_id') ||
              _.get(providers, '0.id', [])
          }}
          handleSubmit={this.handleSubmit}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <PaddingBox>
                {providers.length > 1 ? (
                  <Box>
                    <Hint dark semibold normal>
                      Submit this contact record to an external AML check.
                    </Hint>
                    <Box pt={'10px'}>
                      <FormField
                        name={'provider_id'}
                        label={'aml provider'}
                        Input={Select}
                        inputProps={{
                          options: providers,
                          disabled: disabled || !this.hasPermissions(true)
                        }}
                      />
                    </Box>
                  </Box>
                ) : providers.length === 1 ? (
                  <Hint dark semibold normal>
                    Submit this contact record to an external AML check via{' '}
                    {_.get(providers, '0.connection_name')}.
                  </Hint>
                ) : null}
                {this.renderSubmitted()}
              </PaddingBox>
              <Box p={'0 20px 20px 20px'}>
                <ButtonBar hasPadding={false} isLoading={isSubmitting}>
                  {!disabled && (
                    <DefaultButton
                      left
                      light
                      onClick={this.onChangeToManualClick}
                      isDisabled={!this.hasPermissions()}
                    >
                      Change to manual check
                    </DefaultButton>
                  )}

                  <TextButton blue onClick={closeDialog}>
                    Cancel
                  </TextButton>
                  <DefaultButton
                    red
                    onClick={submitForm}
                    isDisabled={disabled || !this.hasPermissions(true)}
                  >
                    {_.get(amlRecord, 'updated_or_submitted_at')
                      ? 'Resubmit for AML check'
                      : 'Submit for AML check'}
                  </DefaultButton>
                </ButtonBar>
              </Box>
            </Form>
          )}
        </ReactForms>
      </Fragment>
    );
  }
}

export default AMLSubmissionDialog;
