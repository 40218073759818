import capitalize from 'lodash/capitalize';
import Types from 'prop-types';
import ProviderInterface from './provider';

export const OptionType = Types.arrayOf(
  Types.shape({ data: Types.object, index: Types.number, value: Types.string })
);

export const ProviderPropType = Types.instanceOf(ProviderInterface);

export const OPTION_TYPES = {
  CONTACT: 'CONTACT',
  LISTING: 'LISTING',
  PROPERTY: 'PROPERTY',
  FAVOURITE_FILTER: 'FAVOURITE_FILTER',
  SAVED_FILTER: 'SAVED_FILTER',
  AGENT_LEDGER: 'AGENT_LEDGER',
  ARCHIVED_LISTING: 'ARCHIVED_LISTING',
  COMMISSION_WORKSHEET: 'COMMISSION_WORKSHEET',
  CONTRACT: 'CONTRACT',
  HOLD: 'HOLD',
  INVOICE: 'INVOICE',
  INVOICE_TRANSACTION: 'INVOICE_TRANSACTION',
  NEWSLETTER: 'NEWSLETTER',
  PROJECT: 'PROJECT',
  PROJECT_STAGE: 'PROJECT_STAGE',
  REPORTING: 'REPORTING',
  REPORT: 'REPORT',
  TRUST_ACCOUNT: 'TRUST_ACCOUNT'
};
export const VIEW_MODES = {
  RECENT: 'RECENT',
  GLOBAL_SEARCH: 'ALL'
};

export const toOption = (type, pluckLabel, pluckValue, data) => ({
  data,
  type,
  label: pluckLabel(data),
  value: pluckValue(data)
});

export const getNull = () => null;

export const preventDefault = (e) => {
  e.stopPropagation();
  e.preventDefault();
};

export const mapToOptions = (options = []) => {
  let i = 0;
  const optionWithIndex = (option) => ({ ...option, index: i++ });
  return options.map(optionWithIndex);
};

let subscribers = [];
let subscription = null;
export const subscribeToOuterClick = (subscriber) => {
  subscribers = subscribers.concat(subscriber);
  const unsubscribe = () => {
    subscribers = subscribers.filter((sub) => sub !== subscriber);
  };
  if (!subscribers.length) {
    window.removeEventListener('mousedown', subscription);
    subscription = null;
  }

  if (!subscription) {
    subscription = (event) => subscribers.forEach((sub) => sub(event));
    window.addEventListener('mousedown', subscription);
  }

  return unsubscribe;
};

export const separateAndCapitalize = (words, separationKey) =>
  words
    .split(separationKey)
    .map((word) => capitalize(word))
    .join(' ');

export const ignoreFilter = (options) => options;
