// TODO: Covert to hook
export const campaignDetailsActions = [
  {
    label: 'Archive'
  },
  {
    label: 'More',
    items: [
      { label: 'Copy Records to Another Campaign' },
      { label: 'Delete' },
      { label: 'Go to Related' }
    ]
  }
];
