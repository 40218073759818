import { ColumnsConfig } from 'components/record-list-screen/types';
import React, { Fragment, useEffect, useState } from 'react';
import {
  ValidXeroAccount,
  WingsXero,
  XeroRexMapAccount
} from '../../types/third-party-service-xero';
import { RelatedTable } from 'view/components/record-details-screen/related-table';
import { withModel } from '@rexlabs/model-generator';
import thirdPartyServiceXeroModel from '../../data/third-party-service-xero';
import { SelectCell } from 'components/record-list-screen/cells/select-cell';
import { useErrorDialog } from 'hooks/use-error-dialog';
import SaveCancelOverlayHandler from 'src/view/components/save-cancel-overlay-handler';
import { capitalize } from 'lodash';

const InvoiceSettings = ({
  wingsXero,
  onFailed
}: {
  wingsXero: WingsXero;
  onFailed: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const errorDialog = useErrorDialog();
  const [hasChanges, setHasChanges] = useState(false);
  const [mappedXeroAccounts, setMappedXeroAccounts] =
    useState<XeroRexMapAccount>();

  const [updatedAccounts, setUpdatedAccounts] = useState({});

  useEffect(() => {
    wingsXero({}, 'ThirdPartyServiceXero::getAccountMapping')
      .then((res) => {
        setMappedXeroAccounts(res as XeroRexMapAccount);
        setIsLoading(false);
      })
      .catch((error) => {
        onFailed();
        setIsLoading(false);
        errorDialog.open(error as Error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAccountChanges = async () => {
    try {
      const res = await wingsXero(
        { data: updatedAccounts },
        'ThirdPartyServiceXero::setAccountMapping'
      );
      setMappedXeroAccounts(res as XeroRexMapAccount);
      setHasChanges(false);
      setUpdatedAccounts({});
    } catch (error) {
      errorDialog.open(error as Error);
    }
  };

  const onChange = ({ target }) => {
    if (!hasChanges) setHasChanges(true);
    setUpdatedAccounts((prev) => ({
      ...prev,
      [target.value.rex_account_id]: target.value.value
    }));
  };

  const columns: ColumnsConfig<ValidXeroAccount> = [
    {
      id: 'rex_account.text',
      label: 'account name',
      hidden: false,
      width: '50%'
    },
    {
      id: 'xero_account.id',
      label: 'xero account',
      Cell: SelectCell,
      cellProps: ({ rex_account: rex, xero_account: xero }) => ({
        onChange,
        options: mappedXeroAccounts?.valid_xero_accounts.map(
          ({ id, text, type }) => ({
            value: id,
            label: text,
            type,
            rex_account_id: rex.id
          })
        ),
        groupOptions: (option) => ({
          label: capitalize(option.type),
          value: option.type
        }),
        value: { label: xero.text, value: xero.id },
        valueAsObject: true,
        width: '100%'
      })
    }
  ];

  return (
    <Fragment>
      <RelatedTable
        items={mappedXeroAccounts?.mapping || []}
        columns={columns}
        colorScheme='dark'
        isLoading={isLoading}
      />
      {/* HACK: Force re-render so that saveAccountChanges uses the latest updatedAccount */}
      <div
        key={Object.keys(updatedAccounts)
          .map((key) => `${key}.${updatedAccounts[key]}`)
          .join('')}
      >
        <SaveCancelOverlayHandler
          isVisible={hasChanges}
          onSave={saveAccountChanges}
          onCancel={() => setUpdatedAccounts([])}
        />
      </div>
    </Fragment>
  );
};

export default withModel(thirdPartyServiceXeroModel)(InvoiceSettings);
