/*
 ** Note: This is same with invoices except that we need to add type_id = 'payable'
 ** We created this as a separate model for clarity
 */
import { identity } from 'lodash';
import { api, transformList } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import { Criteria } from 'src/types/criteria';

const TYPE = 'invoices';

interface FetchListPayload {
  args?: {
    criteria?: Criteria[];
  };
}

const actionCreators = {
  fetchList: {
    request: (payload: FetchListPayload = {}) =>
      api
        .post('Invoices::search', {
          ...payload.args,
          criteria: [
            ...(payload.args?.criteria ? payload.args.criteria : []),
            {
              name: 'type_id',
              type: '=',
              value: 'payable'
            }
          ]
        })
        .then(transformList)
        .then((pagination) => ({
          ...pagination,
          data: pagination.data.map((data) => ({ item: data }))
        })),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const billsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});
export default billsModel;
