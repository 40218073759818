import React, { PureComponent } from 'react';
import types from 'prop-types';
import { StyleSheet, styled } from '@rexlabs/styling';
import { COLORS, TEXTS } from 'theme';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
    height: '2.4rem',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_3
  },
  left: {
    justifyContent: 'flex-start'
  },
  center: {
    justifyContent: 'center'
  },
  right: {
    justifyContent: 'flex-end'
  }
});

@styled(defaultStyles)
class RexHeadingCell extends PureComponent {
  static propTypes = { align: types.oneOf(['left', 'center', 'right']) };
  static defaultProps = { align: 'left' };
  render() {
    const { styles: s, children, align, style, ...props } = this.props;
    return (
      <Box {...s.with('container', align)(style)} {...props}>
        <span>{children}</span>
      </Box>
    );
  }
}

export default RexHeadingCell;
