import React, { useCallback } from 'react';

import { withDialog } from 'shared/hocs/with-dialog';
import { Popout } from 'view/components/popout';

import {
  SavedFilterDialog,
  SavedFilterDialogType
} from 'components/record-list-screen/dialogs/saved-filter-dialog';
import { SavedFiltersPopoutContent } from './content';

export interface SavedFiltersPopoutProps {
  serviceName: string;
  setCriteria: any;
  savedFilterDialog: SavedFilterDialogType;
}

// NOTE: the outer component needs to be connected via `withDialog`, even though
// the content is using it, since the way dialogs work rn means they get rendered
// by their consumer, which would mean the dialog would close when the popout
// closes if we connect the content component directly :/
// ANOTHER NOTE: In classic, if you're viewing the saved filter popout, you can edit
// a saved filter - while editing, the popout persists, so when you save the edit
// saved filter dialog, you can pick the saved filter you want. When we attempt to do
// the same thing in shell, the popout closes when the edit saved filter dialog opens.
// https://app.shortcut.com/rexlabs/story/65230/react-lists-saved-filter-popout-closes-when-editing-saved-filter
export const SavedFiltersPopout = withDialog(SavedFilterDialog)(
  function SavedFiltersPopout({
    serviceName,
    setCriteria,
    children,
    savedFilterDialog
  }: SavedFiltersPopoutProps & { children: React.ReactNode }) {
    const Content = useCallback(
      (props) => (
        <SavedFiltersPopoutContent
          serviceName={serviceName}
          setCriteria={setCriteria}
          savedFilterDialog={savedFilterDialog}
          {...props}
        />
      ),
      // eslint-disable-next-line
      [serviceName]
    );
    return (
      <Popout Content={Content} placement='bottom-start'>
        {children}
      </Popout>
    );
  }
);
