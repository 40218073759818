import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { UserItem } from '../value-lists/account-users';
import { Id } from '@rexlabs/model-generator';
import { SystemOptionObj } from '../system-lists/types';
import { api } from 'shared/utils/api-client';
interface RecordType {
  record_type: SystemOptionObj;
}
export interface EmbeddedApp {
  account_id: number;
  id: Id;
  is_enabled: boolean;
  is_hidden: boolean;
  etag: string;
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_owner_user: UserItem;
  name: string;
  record_types: RecordType[];
  height: string | null;
  width: string | null;
  library: { id: number; _library_name: string } | null;
  related: any;
  type: SystemOptionObj;
  url: string;
  merged_url?: string;
}

export interface EmbeddedAppMergedUrlPayload {
  recordId: Id;
  recordType: string;
  ids: string[];
}

const actionCreators = {
  getMergedUrls: {
    request: ({ recordType, recordId, ids }: EmbeddedAppMergedUrlPayload) =>
      api.post('EmbeddedApps::getMergedUrls', {
        record_type_id: recordType,
        record_id: recordId,
        app_ids: ids
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator<EmbeddedApp>('embeddedApps').createEntityModel();

// Note: the reason we have 2 models here is these are actually different resources, and we dont want
// cache collissions where a query for a list of embedded apps without urls blows away the values of the
// merged_url field.
export const embeddedAppsWithUrlModel = new Generator<EmbeddedApp>(
  'embeddedAppsWithUrl'
).createEntityModel({
  actionCreators
});
