import React from 'react';
import {
  EntitySelect,
  ValueListSelect,
  DateRangeSelect
} from 'view/components/input/select';
import { FiltersConfig } from 'components/record-list-screen/types';
import listingsModel from 'data/models/entities/listings';
import contactsModel from 'data/models/entities/contacts';
import accountUsersModel from 'data/models/value-lists/account-users';
import { dateRangeFiltersToCriteria } from 'components/record-list-screen/utils/filters';

export function useReinzFormsFilters() {
  return React.useMemo<FiltersConfig>(
    () => ({
      columns: 2,
      groups: [
        [
          {
            name: 'reinz_form_contract.contract.agent_id',
            label: 'agent',
            Input: ValueListSelect,
            inputProps: {
              models: [accountUsersModel]
            }
          },
          {
            name: 'reinz_form_contract.contract.listing_id',
            label: 'listing',
            Input: EntitySelect,
            inputProps: {
              models: [listingsModel],
              valueAsObject: true,
              searchOnMount: true
            },
            filterToCriteria: ({ filterConfig, value }) => {
              return [
                {
                  ...filterConfig,
                  value: [`${value.value}-${value.label}`]
                }
              ];
            },
            criteriaToValue: ({ criteriaItem }) => {
              const split = criteriaItem.value[0].split(/-(.*)/s);
              return {
                id: split[0],
                value: split[0],
                label: split[1],
                model: listingsModel
              };
            }
          },
          {
            name: 'reinz_form_contract.purchtenant.id',
            label: 'purchasers',
            Input: EntitySelect,
            inputProps: {
              models: [contactsModel],
              valueAsObject: true,
              searchOnMount: true,
              multi: true
            },
            filterToCriteria: ({ filterConfig, value }) => {
              return [
                {
                  ...filterConfig,
                  value: value.map((val) => `${val.value}-${val.label}`)
                }
              ];
            },
            criteriaToValue: ({ criteriaItem }) => {
              return (criteriaItem.value as string[]).map((value) => {
                const split = value.split(/-(.*)/s);
                return {
                  id: split[0],
                  value: split[0],
                  label: split[1],
                  model: contactsModel
                };
              });
            }
          },
          {
            name: 'reinz_form_contract.system_ctime',
            label: 'created between',
            valueType: 'range',
            Input: DateRangeSelect,
            filterToCriteria: dateRangeFiltersToCriteria
          }
        ]
      ]
    }),
    []
  );
}
