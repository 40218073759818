export const projectDetailsActions = [
  {
    label: 'Merge',
    items: [
      { label: 'Send Email' },
      { label: 'Send SMS' },
      { label: 'Send Letter' }
    ]
  },
  {
    label: 'Reports',
    items: [{ label: 'Vendor Report' }]
  },
  { label: 'Trash' },
  {
    label: 'More',
    items: [{ label: 'Go to Related' }, { label: 'Start a Workflow' }]
  }
];
