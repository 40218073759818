import React, { ComponentType, PropsWithChildren } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { PADDINGS } from 'src/theme';
import { ArrowDropdown } from 'view/components/action-menu';

import { Statuses } from './statuses';

const NoteSvg: any = ICONS.NOTE;

const defaultStyles = StyleSheet({
  container: {},

  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    marginRight: '24px',
    flexShrink: 0,
    marginTop: '-4px',

    '& svg': {
      height: '100%',
      width: 'auto'
    }
  },

  content: {
    display: 'flex',
    flex: 1,
    background: '#fff',
    padding: PADDINGS.M,
    position: 'relative',

    '&:before': {
      content: '" "',
      position: 'absolute',
      right: '100%',
      top: 0,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 15px 15px 0',
      borderColor: 'transparent',
      borderRightColor: '#fff'
    },

    '& [data-hover]': {
      opacity: 0,
      transition: 'opacity .2s'
    },

    '&:hover [data-hover]': {
      opacity: 1
    }
  },

  contentPinned: {
    background: '#f3faff',

    '&:before': {
      borderRightColor: '#f3faff'
    }
  },

  actions: {
    position: 'absolute',
    top: '-4px',
    right: '-4px'
  }
});

interface ContainerProps {
  Icon?: ComponentType<any>;
  pinned?: boolean;
  data: any;
  actions?: any;
}

export function Container({
  Icon = NoteSvg,
  pinned,
  data,
  actions,
  children
}: PropsWithChildren<ContainerProps>) {
  const s = useStyles(defaultStyles);
  return (
    <Box flexDirection='row' alignItems='flex-start'>
      <Box {...s('icon')}>
        <Icon />
      </Box>
      <Box
        {...s('content', { contentPinned: pinned })}
        flexDirection='column'
        sy='3px'
      >
        {children}

        <Statuses data={data} />

        {!!actions?.length && (
          <Box data-hover {...s('actions')}>
            <ArrowDropdown small items={actions} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
