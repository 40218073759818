import { LeadsPropertyStub } from 'data/models/entities/properties';
import { get } from 'lodash';

interface PropertyType extends LeadsPropertyStub {
  adr_locality: string;
  property_category_id: string;
  _id?: string;
  property_category?: { id: string };
  property_subcategory?: { id: string };
  business_name?: string;
  adr_building: null | {
    id: string;
    name: string;
  };
}

export const formPropertyPayload = (property: PropertyType) => {
  const propertyCopy = property;

  propertyCopy.property_category_id = get(property, 'property_category.id');

  delete propertyCopy.property_category;
  delete propertyCopy._id;
  delete propertyCopy.business_name;

  return propertyCopy;
};
