import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';

import sessionModel from 'data/models/custom/session';
import uiModel from 'data/models/custom/ui';

import Icon, { ICONS } from 'shared/components/icon';
import { createKey } from 'shared/utils/react';

import { COLORS, HEIGHTS, TEXTS, UTILS } from 'theme';

import { withErrorDialog } from 'hocs/with-error-dialog';
import { NavigationPopout } from './navigation-popout';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

const inputOverrides = {
  TextInput: StyleSheet({
    wrapper: {
      flex: '1 1 auto'
    },
    container: {
      borderRadius: '0',
      border: 'none !important',
      padding: '0'
    },
    cosmeticWrapper: {},
    input: {
      color: '#424242',
      border: 'none',
      lineHeight: '22px',
      fontSize: '17px',
      height: '17px',
      padding: '0',
      margin: '0',

      '::placeholder': {
        ...TEXTS.GLOBAL_NAVIGATION.SEARCH_PLACEHOLDER_IDLE,
        color: '#7F7F7F',
        opacity: 1
      }
    }
  })
};

const agencyListStyles = {
  container: {
    width: '340px',
    display: 'flex',
    flexDirection: 'column'
  },
  agencyName: {
    color: '#424242',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '22px',
    padding: '14px 15px 14px 20px',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '50px',

    '&:hover': {
      backgroundColor: '#F9FAFB',
      color: '#000000'
    }
  },
  filterContainer: {
    paddingBottom: '5px',
    margin: '0 20px'
  },
  filterWrapper: {
    color: '#7F7F7F',
    fontSize: '17px',
    lineHeight: '22px',
    fontWeight: '500',
    display: 'flex',
    paddingTop: '20px',
    paddingBottom: '12px'
  },
  separator: {
    width: 'inherit',
    height: '1px',
    opacity: 0.5,
    backgroundColor: '#E1E1E1'
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',

    '& svg': {
      color: '#79818D'
    }
  },
  agenciesContainer: {
    overflow: 'auto',
    overflowX: 'hidden',
    maxHeight: '340px',
    display: 'flex',
    flexDirection: 'column',

    '::-webkit-scrollbar': {
      width: '6px'
    },

    '::-webkit-scrollbar-thumb': {
      background: '#B2B2B2',
      borderRadius: '4px'
    }
  },
  noMaxHeight: {
    maxHeight: 'none',
    height: 'calc(100vh - 92px)' // Remove the height of the filter and title
  },
  closeContainer: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    padding: '21px'
  },
  closeButton: {
    cursor: 'pointer',
    width: 'auto',
    height: '18px',

    '& > svg': {
      height: 'inherit',
      width: 'inherit'
    }
  },
  agencyTitleContainer: {
    width: '100%',
    minHeight: HEIGHTS.SHELL_HEADER.MOBILE,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.BACKGROUNDS.SAND,
    display: 'flex',
    alignItems: 'center'
  },
  agencyTitle: {
    paddingLeft: '25px',
    fontSize: '18px',
    lineHeight: '26px',
    color: COLORS.DARK_GREY,
    maxWidth: '74%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
};

@withErrorDialog
@withModel(sessionModel)
@withModel(uiModel)
@styled(StyleSheet(agencyListStyles))
@autobind
class AgencyList extends PureComponent {
  state = { term: '' };

  setSearchTerm(event) {
    this.agenciesContainer.scrollTop = 0;
    this.setState({ term: event.target.value });
  }

  handleAccountSwitch(agencyId) {
    const { ui, session, errorDialog } = this.props;
    ui.loadingIndicatorOn({ message: 'switching account' });
    session.switchToAccount({ id: agencyId }).catch(errorDialog.open);
  }

  renderAgencyTitle() {
    const { styles: s, currentAgencyName } = this.props;

    return (
      <div {...s('agencyTitleContainer')}>
        <span {...s('agencyTitle')}>{currentAgencyName}</span>
      </div>
    );
  }

  setListRef(ref) {
    this.agenciesContainer = ref;
  }

  render() {
    const { styles: s, agencies, isMobile, closePopout } = this.props;

    const shouldShowFilter = agencies.length > 5;
    const prefixIcon = <Icon type={ICONS.SEARCH} {...s('searchIcon')} />;

    return (
      <Box {...s('container')}>
        {isMobile && (
          <div {...s('closeContainer')} onClick={closePopout}>
            <Icon type={ICONS.CLOSE} {...s('closeButton')} />
          </div>
        )}
        {isMobile && this.renderAgencyTitle()}
        {shouldShowFilter && (
          <Box {...s('filterContainer')}>
            <Box {...s('filterWrapper')}>
              <StylesProvider
                components={inputOverrides}
                prioritiseParentStyles={false}
              >
                <TextInput
                  ref={this.props.setInputFocus}
                  prefix={prefixIcon}
                  name='agency-search'
                  autoComplete={false}
                  placeholder='Filter Agencies'
                  onChange={this.setSearchTerm}
                />
              </StylesProvider>
            </Box>
            <Box {...s('separator')} />
          </Box>
        )}
        <div
          ref={this.setListRef}
          {...s('agenciesContainer', { noMaxHeight: isMobile })}
        >
          {agencies
            .filter((agency) => {
              return (
                (`${agency.name}`
                  .toLowerCase()
                  .includes(this.state.term.toLowerCase()) ||
                  agency.account_id.toString() ===
                    this.state.term.toString()) &&
                agency.application.toLowerCase() === 'rex'
              );
            })
            .map((agency) => {
              const agencyName = `${agency.name}`;
              const onClickHandler = () => {
                closePopout();
                this.handleAccountSwitch(agency.account_id);
              };
              const key = createKey(`${agencyName}-${agency.account_id}`);
              return (
                <span key={key} onClick={onClickHandler} {...s('agencyName')}>
                  {agencyName}
                </span>
              );
            })}
        </div>
      </Box>
    );
  }
}

const agencySelectorStyles = StyleSheet({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '26px',
    color: 'white',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '26px',
    cursor: 'pointer',
    fontFamily: 'proxima-nova, lato, arial, serif',
    width: 'auto'
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    width: 'fit-content',
    ...UTILS.truncate
  },
  chevron: {
    minWidth: '34px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px',
    marginRight: 'auto'
  }
});

@styled(agencySelectorStyles)
@autobind
class AgencySelector extends PureComponent {
  static setInputFocus(element) {
    setTimeout(() => {
      element && element.focus();
    }, 250);
  }

  render() {
    const {
      styles: s,
      currentAgencyName,
      agencies,
      isMobile,
      currentAgencyColor
    } = this.props;
    return (
      <NavigationPopout
        placement={PLACEMENTS.BOTTOM_START}
        offset={'-10px'}
        distance={'10px'}
        Content={({ close }) => (
          <AgencyList
            agencies={agencies}
            isMobile={isMobile}
            closePopout={close}
            currentAgencyName={currentAgencyName}
            setInputFocus={AgencySelector.setInputFocus}
          />
        )}
      >
        <Box {...s('container')}>
          <span
            {...s('name')}
            style={{ color: headerColorContrast(currentAgencyColor) }}
          >
            {currentAgencyName}
          </span>
          <Icon
            tabIndex={'-1'}
            type={ICONS.CHEVRON}
            {...s('chevron')}
            color={headerColorContrast(currentAgencyColor)}
          />
        </Box>
      </NavigationPopout>
    );
  }
}

export default AgencySelector;
