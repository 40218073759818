/**
 * TODO: very similar to the paginaion components in the record list view
 * and the record details view!
 */

import React, { useMemo } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';

import { DefaultButton } from 'view/components/button';

const ChevronPageIcon: any = ICONS.PAGINATION.CHEVRON_ARROW_LEFT;
const ArrowPageIcon: any = ICONS.PAGINATION.ARROW_LEFT;

const defaultStyles = StyleSheet({
  page: {
    marginRight: '6px',
    fontSize: '12px',
    fontSeight: 400
  },
  icon: {
    color: '#fff'
  },
  flipToRight: {
    color: '#fff',
    transform: 'rotate(180deg)'
  }
});

interface PaginationProps {
  pagination: any;
  page?: number;
  setPage?: any;
  limit?: number;
  variant?: 'chevron' | 'arrow';
}

export function Pagination({
  pagination,
  page = 0,
  setPage,
  limit = 10,
  variant = 'chevron'
}: PaginationProps) {
  const s = useStyles(defaultStyles);

  const from = page * limit + 1;
  const to = page * limit + limit;

  const PageIcon = useMemo(
    () => (variant === 'chevron' ? ChevronPageIcon : ArrowPageIcon),
    [variant]
  );

  return (
    <Box flexDirection='row' alignItems='center' sx='4px'>
      <Box>
        <span {...s('page')}>
          {from} -{' '}
          {page >= pagination.totalPages - 1 ? pagination.totalItems : to} of{' '}
          {pagination.totalItems}
        </span>
      </Box>
      <DefaultButton
        padding={false}
        isDisabled={page <= 0}
        onClick={() => setPage(page - 1)}
        ghost={variant === 'arrow'}
      >
        <PageIcon {...s('icon')} />
      </DefaultButton>
      <DefaultButton
        padding={false}
        isDisabled={page >= pagination.totalPages - 1}
        onClick={() => setPage(page + 1)}
        ghost={variant === 'arrow'}
      >
        <PageIcon {...s('flipToRight')} />
      </DefaultButton>
    </Box>
  );
}
