import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  DraggableAttributes,
  DraggableSyntheticListeners,
  UniqueIdentifier
} from '@dnd-kit/core';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';
import Tooltip from '../tooltip';

interface Context {
  attributes: DraggableAttributes | Record<string, never>;
  listeners: DraggableSyntheticListeners;
  isSorting: boolean;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  isSorting: false
});

export function SortableItem(props: {
  id: UniqueIdentifier;
  children: ReactNode;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting
  } = useSortable({ id: props.id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      isSorting
    }),
    [attributes, listeners, isSorting]
  );

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li ref={setNodeRef} style={style}>
        {props.children}
      </li>
    </SortableItemContext.Provider>
  );
}

const handleStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    cursor: 'move',
    display: 'flex',

    '&:hover': {
      cursor: 'move'
    },

    '& svg': {
      width: '8px',
      height: 'auto',
      verticalAlign: 'centre',
      color: '#c8c7c3'
    },

    '& *': {
      pointerEvents: 'none'
    }
  }
});

interface DragHandleProps {
  tooltip?: () => string;
}

export function DragHandle({ tooltip }: DragHandleProps) {
  const { attributes, listeners, isSorting } = useContext(SortableItemContext);
  const s = useStyles(handleStyles);
  if (tooltip && !isSorting) {
    return (
      <Tooltip inline distance={'10px'} placement='top' Content={tooltip}>
        <Box
          alignItems='center'
          justifyContent='center'
          flexShrink='0'
          {...s('container')}
          {...attributes}
          {...listeners}
        >
          <Icon type={ICONS.DRAG_HANDLE} />
        </Box>
      </Tooltip>
    );
  }
  return (
    <Box
      alignItems='center'
      justifyContent='center'
      flexShrink='0'
      {...s('container')}
      {...attributes}
      {...listeners}
    >
      <Icon type={ICONS.DRAG_HANDLE} />
    </Box>
  );
}
