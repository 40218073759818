import React from 'react';

import { useStyles, StyleSheet } from '@rexlabs/styling';

import { COLORS, PADDINGS } from 'theme';

import PaddingBox from 'view/components/padding-box';

import { LabelledValue } from 'components/labelled-value';

const defaultStyles = StyleSheet({
  container: {
    borderBottom: `2px solid ${COLORS.BACKGROUNDS.SAND}`
  }
});

export function LeadsDialogMessageHeader({
  from,
  subject
}: {
  from: React.ReactNode;
  subject?: React.ReactNode;
}) {
  const s = useStyles(defaultStyles);

  return (
    <PaddingBox {...s('container')} pt={PADDINGS.XS} pb={PADDINGS.S}>
      <LabelledValue label={'from'}>{from}</LabelledValue>
      {subject && <LabelledValue label={'subject'}>{subject}</LabelledValue>}
    </PaddingBox>
  );
}
