import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { FONT } from 'theme';

import {
  DataGrid,
  GridReadyEvent,
  ColumnEverythingChangedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  ModelUpdatedEvent
} from 'features/custom-reporting/components/data-grid';
import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import { useDataGrid } from 'features/custom-reporting/hooks/use-data-grid';
import {
  getColumnsWeNeedDataFor,
  mapColumns
} from 'features/custom-reporting/modules/helpers';
import { Column } from 'ag-grid-community';

// TODO: Type the props
export function CustomReportingGrid({ ...props }) {
  const reportState = useReportState();

  const {
    allColumns,
    reportRows,
    userSelectedReportState: {
      selectedGridFilters,
      selectedGridColumns,
      selectedPivotMode
    },
    setSelectedPivotMode,
    setSelectedGridFilters,
    setSelectedGridColumns
  } = reportState;

  const { setRef: gridRef } = useDataGrid();
  const [columnsApplied, setColumnsApplied] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const availableSubresources = useMemo(() => {
    const columns = getColumnsWeNeedDataFor({
      columnState: selectedGridColumns || [],
      filterState: selectedGridFilters || [],
      allColumnsMapped: allColumns
    });

    return columns.reduce((acc, col) => {
      if (col?.subresource) {
        acc[col.subresource] = true;
      }

      return acc;
    }, {});
  }, [selectedGridFilters, selectedGridColumns, allColumns]);

  const allColumnsWithFiltersDisabled = useMemo(() => {
    return mapColumns(allColumns, (col) => ({
      ...col,
      // disable filtering on columns we don't have the subresource for
      filter: col.subresource
        ? availableSubresources[col.subresource]
          ? col.filter
          : false
        : col.filter
    }));
  }, [allColumns, selectedGridColumns]);

  // Re-set the column state on change of the allColumns definition.
  // This is a bit ugly, but if we don't do this then ag-grid loses the column state on every change. It's a byproduct
  // of the way ag-grid uses a combination of imperative and declarative APIs.
  // To avoid this, we would need to manually re-populate the entire grid column state based on a combination of
  // allColumns and the selectedGridColumns.  applyColumnState is a bit janky so eventually we should do this but
  // I would like to avoid it for now.
  // We may be getting rid of this and just allow filtering by all columns.  If we don't, we should look at cleaning up
  // this implementation using `setFilterLayout` https://www.ag-grid.com/javascript-data-grid/tool-panel-filters/#custom-filters-layout
  useEffect(() => {
    if (columnsApplied) {
      gridRef?.current?.columnApi?.applyColumnState({
        state: selectedGridColumns,
        applyOrder: true
      });
    }
    // we only want to react to changes to the column definitions.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [allColumnsWithFiltersDisabled]);

  const onGridReady = useCallback(
    (grid: GridReadyEvent) => {
      if (selectedGridColumns) {
        grid.columnApi.applyColumnState({
          state: selectedGridColumns,
          applyOrder: true
        });
      }
      if (selectedPivotMode) {
        grid.columnApi.setPivotMode(true);
      }
      setColumnsApplied(true);
    },
    [selectedGridColumns, selectedPivotMode]
  );

  // unfortunately you can't set the filter state onGridReady so you have to do it
  // onFirstDataRendered, which is a bit late and causes a flicker in the UI.
  // if it's a major issue we might have to set the opacity to 0 and display a spinner until firstDataRender
  const onFirstDataRendered = useCallback(
    (grid: GridReadyEvent) => {
      if (selectedGridFilters) {
        grid.api.setFilterModel(selectedGridFilters);
      }
      setFiltersApplied(true);
    },
    [selectedGridFilters]
  );

  // When new data is passed to the grid, it's currently resetting the filters
  // and we haven't figured out how to stop it.
  // Our workaround is just to set them again from the URL when the row data changes.
  const onRowDataChanged = useCallback(
    (grid: GridReadyEvent) => {
      if (selectedGridFilters) {
        grid.api.setFilterModel(selectedGridFilters);
      }
    },
    [selectedGridFilters]
  );

  const onColumnChange = useCallback(
    (e: Partial<ColumnEverythingChangedEvent>) => {
      let columnState = e?.columnApi?.getColumnState();
      // auto select column when they are grouped
      if (e?.type === 'columnRowGroupChanged' && columnState) {
        const colId = (e as { column: Column })?.column?.getColId();
        if (colId) {
          columnState = columnState.map((column) => ({
            ...column,
            hide: column.colId === colId ? false : column.hide
          }));
        }
      }
      // onColumnChange can get fired multiple times with the same state, so we dedupe here to avoid multiple
      // expensive state updates.
      if (!isEqual(columnState, selectedGridColumns)) {
        return setSelectedGridColumns(columnState);
      }
    },
    [setSelectedGridColumns, selectedGridColumns]
  );

  const onFilterChanged = useCallback(
    (grid: FilterChangedEvent) => {
      setSelectedGridFilters(grid.api.getFilterModel());
    },
    [setSelectedGridFilters]
  );

  const onColumnPivotModeChanged = useCallback(
    (grid: GridReadyEvent) => {
      const pivotMode = grid.columnApi.isPivotMode();

      const columnState = grid?.columnApi?.getColumnState();
      // onColumnChange can get fired multiple times with the same state, so we dedupe here to avoid multiple
      // expensive state updates.
      if (!isEqual(columnState, selectedGridColumns)) {
        return setSelectedGridColumns(columnState);
      }
      if (pivotMode) {
        Analytics.track({
          event: EVENTS.CUSTOM_REPORTING.ENABLE_PIVOT
        });
      }
      setSelectedPivotMode(pivotMode);
    },
    [setSelectedPivotMode]
  );

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      // grid.columnApi.applyColumnState results in columnVisible being called, which causes problems because
      //  we don't want new column data until the user updates the columns
      return filtersApplied && columnsApplied && onColumnChange(e);
    },
    [onColumnChange, filtersApplied, columnsApplied]
  );

  const onCellContextMenu = useCallback(() => {
    Analytics.track({
      event: EVENTS.CUSTOM_REPORTING.OPEN_CONTEXT_MENU
    });
  }, []);

  const getRowStyles = useCallback((params) => {
    if (params.node.group) {
      return { fontWeight: FONT.WEIGHTS.SEMIBOLD };
    }
  }, []);

  const onRowModelUpdated = useCallback((event: ModelUpdatedEvent) => {
    if (event.api.getModel().getRowCount() === 0) {
      event.api.showNoRowsOverlay();
    } else {
      // NOTE: If the grid is showing column groups, and previously there was no data, the overlay is not
      // removed by default. Below just makes sure  we remove the overlay, if theres data.
      event.api.hideOverlay();
    }
  }, []);

  return (
    <DataGrid
      getRowStyle={getRowStyles}
      rowData={reportRows}
      columnDefs={allColumnsWithFiltersDisabled}
      hideSidebar={true}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
      onColumnVisible={onColumnVisible}
      onColumnMoved={onColumnChange}
      onColumnPivotModeChanged={onColumnPivotModeChanged}
      onSortChanged={onColumnChange}
      onColumnRowGroupChanged={onColumnChange}
      onColumnResized={onColumnChange}
      onFilterChanged={onFilterChanged}
      onRowDataChanged={onRowDataChanged}
      onColumnPinned={onColumnChange}
      onColumnValueChanged={onColumnChange}
      onCellContextMenu={onCellContextMenu}
      groupDefaultExpanded={2}
      onModelUpdated={onRowModelUpdated}
      {...props}
    />
  );
}
