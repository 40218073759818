import { gql } from 'hooks/use-graphql-query';

export const Contract = gql`
  fragment Contract on Contract {
    id
    contract_status {
      text
    }
    verified_status {
      text
    }
    detail_sale_price_or_lease_pa
    detail_deposit_full_or_prepayment
    purchtenant_legal_name
    purchtenant_position {
      text
    }
    stability_indicator {
      text
    }
    date_actual_offer
    date_actual_accepted
    date_expec_unconditional
    date_actual_unconditional
    verified_unconditional
    date_expec_settlement
    date_actual_settlement
    verified_settled
    days_until_accepted
    days_from_accepted_to_settled
    date_actual_fallen
    fallen_reason {
      text
    }
    is_primary_accepted
    is_backup_offer
  }
`;
