import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

export interface AdminNewsletterTemplate {
  id: number;
  system_record_state: string;
  account_id: number;
  system_ctime: number;
  system_modtime: number;
  name: string;
  is_default: boolean;
  template_type_id: 'beefreehtml';
  template_content: string;
  library?: {
    id: string;
    library_name: string;
  } | null;
  is_hidden: boolean | null;
}

const TYPE = 'adminNewsletterTemplates';

const actionCreators = {
  purge: {
    request: (id: string | number) =>
      api.post('AdminNewsletterTemplates::purge', { id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  archive: {
    request: (id: string | number) =>
      api.post('AdminNewsletterTemplates::archive', { id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  duplicate: {
    request: (id: string | number) =>
      api.post('AdminNewsletterTemplates::duplicate', { id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  recoverFromArchive: {
    request: (id: string | number) =>
      api.post('AdminNewsletterTemplates::recoverFromArchive', { id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  setAsDefault: {
    request: (id: string | number) =>
      api.post('AdminNewsletterTemplates::setAsDefaultTemplate', {
        id
      }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const adminNewsletterTemplatesModel = new Generator<
  AdminNewsletterTemplate,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});

export default adminNewsletterTemplatesModel;
