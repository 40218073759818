import React, { ComponentProps, ComponentType, useCallback } from 'react';
import { Id } from '@rexlabs/model-generator';

import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { RecordDetailsScreen } from 'components/_experiment/record-details-screen';
import { RecordListScreen } from 'components/record-list-screen';
import { useRecordNavigation } from '../record-details-screen/utils/use-record-navigation';
export interface RecordScreenProps {
  RecordDetailsScreen?: ComponentType<
    Omit<Partial<ComponentProps<typeof RecordDetailsScreen>>, 'id'> & {
      id: Id;
    }
  >;
  RecordListScreen: ComponentType<
    Partial<ComponentProps<typeof RecordListScreen>>
  >;
}

export function RecordScreen({
  RecordDetailsScreen,
  RecordListScreen
}: RecordScreenProps) {
  const whereabouts = useWhereaboutsWithViewpath();

  const { goToRecord } = useRecordNavigation();

  const handleOnItemClick = useCallback(
    (item, allIds) => goToRecord({ id: item.id, allIds }),
    [goToRecord]
  );

  return whereabouts.hashQuery?.id && RecordDetailsScreen ? (
    <RecordDetailsScreen id={whereabouts.hashQuery?.id} />
  ) : (
    <RecordListScreen fetchAllIds onItemClick={handleOnItemClick} />
  );
}
