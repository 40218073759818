import React, { useEffect, useState, useRef, ReactNode } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';

import HeadingIllustration from 'view/components/external-link-redirect/heading-illustration';
import { ExternalLinkScreenLayout } from 'view/components/external-link-redirect/external-link-screen-layout';

import { SupportLink } from 'view/components/external-link-redirect/support-link';
import { RedirectButton } from 'view/components/external-link-redirect/redirect-button';
import { RexTrainingContent } from './rex-training-content';

export interface RexTrainingRedirectProps {
  onClose: () => void;
  title: string;
  fetchRedirectUrl: () => Promise<{
    data: { result: { login_url: string; first_login: string } };
  }>;
  Icon?: any;
  errorMessage?: ReactNode;
  showBackButton?: boolean;
}

export function RexTrainingRedirect({
  onClose,
  title,
  errorMessage,
  showBackButton = true,
  fetchRedirectUrl,
  Icon
}: RexTrainingRedirectProps) {
  const [error, setError] = useState<{ message: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [redirectURL, setRedirectURL] = useState<string | null>(null);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    fetchRedirectUrl()
      .then(({ data }) => {
        if (!isMounted.current) return;
        setLoading(false);
        setRedirectURL(data.result.login_url);
        setIsFirstLogin(!!data.result.first_login);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });

    return () => {
      isMounted.current = false;
    };
  }, [fetchRedirectUrl]);

  return (
    <ExternalLinkScreenLayout>
      {!!Icon && (
        <HeadingIllustration Icon={Icon} error={error} hasPermission />
      )}

      <RexTrainingContent
        errorText={error ? error.message || errorMessage : ''}
        loading={loading}
        isFirstLogin={isFirstLogin}
        title={title}
        closeOverlay={onClose}
        redirectURL={redirectURL}
      />

      {error && showBackButton && (
        <Box mt={PADDINGS.XL} width='100%' justifyContent='center'>
          <RedirectButton onClick={onClose}>Return to Rex</RedirectButton>
        </Box>
      )}

      {!error && (
        <Box mt={PADDINGS.XL}>
          <SupportLink />
        </Box>
      )}
    </ExternalLinkScreenLayout>
  );
}
