import React, { useMemo } from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { COLORS, PADDINGS } from 'theme';

import PaddingBox from 'view/components/padding-box';

import { Link } from 'components/text/link';
import { Body } from 'components/text/body';
import { ConditionalTooltip } from 'components/tooltip/conditional-tooltip';

import { WrapperButton } from './wrapper-button';

type Width = number | string;

interface IconValueStatusButtonProps {
  iconType: any; // SVG
  width?: Width;
  children: React.ReactNode;
  status?: React.ReactNode;
  helpText?: React.ReactChild;
  onClick: (event: React.MouseEvent<HTMLElement>) => any;
}

const defaultStyles = StyleSheet({
  icon: {
    width: 16,
    height: 14,
    color: COLORS.GREY_DARK
  }
});

export function IconValueStatusButton(props: IconValueStatusButtonProps) {
  const {
    iconType: IconComponent,
    width = '100%',
    children,
    status,
    helpText,
    onClick,
    ...rest
  } = props;

  const s = useStyles(defaultStyles);

  return (
    <WrapperButton
      width={width}
      border='none'
      borderRadius={2}
      onClick={onClick}
      {...rest}
    >
      <PaddingBox
        padding={PADDINGS.XS}
        width='100%'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        white
      >
        {/* This section (down to just above helpText) is essentially a copy of components/text/link-icon
            with the x-spacing and icon width slightly changed.
            If you duplicate this, I recommend making it a new component and using it here also.
        */}
        <Box flexDirection='row' alignItems='center' sx={'10px'}>
          <IconComponent {...s('icon')} />
          <Link dark>{children}</Link>
        </Box>
        {status && helpText ? (
          <ConditionalTooltip content={helpText} showTooltip={!!helpText}>
            <Box mr={PADDINGS.XXS}>
              <Body normal small>
                {status}
              </Body>
            </Box>
          </ConditionalTooltip>
        ) : (
          status && (
            <Box mr={PADDINGS.XXS}>
              <Body normal small>
                {status}
              </Body>
            </Box>
          )
        )}
      </PaddingBox>
    </WrapperButton>
  );
}
