/* eslint-disable max-lines */
import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { PADDINGS, TEXTS, COLORS, FONT } from 'theme';
import { DefaultButton } from 'view/components/button';
import Box from '@rexlabs/box';
import PafUploadProgress from './paf-upload-progress';
import { api } from 'shared/utils/api-client';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

import {
  CancelUploadDialog,
  UploadNewFileDialog,
  SuccessDialog,
  ResumeUploadDialog,
  ColumnMatchingDialog
} from './dialogs';

@styled(
  StyleSheet({
    container: {
      position: 'relative',
      display: 'flex',
      width: 'min-content',
      background: 'transparent'
    },
    input: {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: -1
    }
  })
)
@autobind
class InputContainer extends Component {
  onChange({ target }) {
    this.props.onChange(target.files);
    target.value = '';
  }

  render() {
    const {
      styles: s,
      name,
      tabIndex,
      shouldAllowMultiple,
      accept,
      setInputRef,

      onFocus,
      onBlur,
      children
    } = this.props;
    return (
      <label>
        {children}
        <input
          {...s('input')}
          ref={setInputRef}
          type='file'
          id={name}
          tabIndex={tabIndex}
          multiple={shouldAllowMultiple}
          accept={accept}
          onChange={this.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </label>
    );
  }
}

const uploadBodyStyles = StyleSheet({
  container: {
    padding: PADDINGS.M
  },
  heading: {
    ...TEXTS.HEADERS.HEADING_2,
    paddingBottom: PADDINGS.M
  },
  infoText: {
    ...TEXTS.CONTENT.INFORMATIVE_SUBTEXT_1,
    color: COLORS.GREY_MEDIUM,
    paddingBottom: PADDINGS.L
  },
  buttonText: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },
  filename: {
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1,
    paddingLeft: PADDINGS.M,
    FONT
  },
  bodyParagraph: {
    ...TEXTS.CONTENT.INFORMATIVE_SUBTEXT_1,
    color: COLORS.GREY_MEDIUM,
    paddingBottom: PADDINGS.L
  }
});

@withErrorDialog
@styled(uploadBodyStyles)
@autobind
class FileUploadBody extends Component {
  state = {
    file: undefined,
    isResuming: false,
    isMapping: false,
    sourceColumns: [],
    destinationColumns: [],
    hasConfirmedReUpload: false,
    isConfirmingReUpload: false
  };

  addFile(files) {
    const file = files[0];

    Promise.all([
      api.post('ThirdPartyServicePostcodeFile/getDestinationColumns'),
      this.getColumns(file)
    ]).then(([dest, src]) => {
      this.setState({
        file,
        sourceColumns: src,
        destinationColumns: dest.data.result,
        isMapping: true,
        isConfirmingReUpload: false,
        hasConfirmedReUpload: false
      });
    });
  }

  saveColumnsAndSubmit() {
    const { file } = this.state;
    const { pafUpload } = this.props;

    this.setState({ isMapping: false }, () => {
      this.props.onSubmit(file);
      pafUpload.setFile({
        filename: file.name,
        filesize: file.size
      });
    });
  }

  getColumns(file) {
    const { errorDialog } = this.props;
    let data = '';
    let offset = 0;
    return new Promise((resolve) => {
      const onLoadHandler = (event) => {
        data += event.target.result;
        const arr = data.split(/(?:\n|\r|\tr)/).filter(Boolean);

        if (arr.length > 3) {
          const columns = {
            // Get each column and remove any wrapping quotes if present
            columns: arr[0]
              .split(',')
              .map((column) =>
                column.match(/(^").*("$)/g)
                  ? column.replace(/(^")|("$)/g, '')
                  : column
              ),
            previews: arr.slice(1).map((row) => row.split(','))
          };

          resolve(columns);
          return;
        }

        if (offset >= file.size) {
          errorDialog.open({
            message: 'The file uploaded has no content. Please try another one.'
          });
          throw new Error('no lines');
        } else {
          offset += data.length;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          chunkReaderBlock(offset);
        }
      };

      const chunkReaderBlock = (currentOffset) => {
        const reader = new FileReader();
        const blob = file.slice(currentOffset, 512 + currentOffset);
        reader.onload = onLoadHandler;
        reader.readAsText(blob);
      };

      chunkReaderBlock(0);
    });
  }

  closeMappingDialog() {
    this.setState({ isMapping: false, file: undefined });
  }

  onResume() {
    const { onResume, onSubmit } = this.props;
    const { file } = this.state;

    if (!file) {
      this.setState({
        isResuming: true
      });
      return;
    }

    onResume();
    onSubmit(file);
  }

  onResumeConfirm(file) {
    this.setState(
      {
        file,
        isResuming: false
      },
      () => {
        this.onResume();
      }
    );
  }

  onResumeReject() {
    this.setState({
      isResuming: false
    });
  }

  onCancel() {
    if (!this.state.hasConfirmedCancel) {
      this.setState({
        isConfirmingCancel: true
      });
      return;
    }

    this.setState(
      {
        file: undefined,
        columns: [],
        hasConfirmedCancel: false,
        isConfirmingCancel: false
      },
      () => {
        this.props.onCancel();
      }
    );
  }

  onCancelConfirm() {
    this.setState(
      {
        hasConfirmedCancel: true
      },
      this.onCancel
    );
  }

  onCancelReject() {
    this.setState({
      isConfirmingCancel: false
    });
  }

  onUploadSuccessConfirm() {
    this.setState(
      {
        file: undefined,
        hasConfirmedCancel: true
      },
      this.props.onUploadSuccessConfirm
    );
  }

  triggerInput(openInputDialog) {
    if (this.props.isUploading && !this.state.hasConfirmedReUpload) {
      this.setState({ isConfirmingReUpload: true });
      return;
    }

    openInputDialog();
  }

  confirmReUpload() {
    this.setState(
      {
        hasConfirmedReUpload: true,
        isConfirmingReUpload: false,
        file: undefined
      },
      () => {
        this.props.pafUpload.clear();
        this.props.onCancel();
        this.triggerInput(this.openInputDialog);
      }
    );
  }

  rejectReUpload() {
    this.setState({
      hasConfirmedReUpload: false,
      isConfirmingReUpload: false
    });
  }

  getUploadStatus() {
    const {
      isPaused,
      timeRemaining,
      isPending,
      isProcessing,
      hasConnectionError,
      hasFailed
    } = this.props;

    if (hasFailed) {
      return 'File upload failed';
    }

    if (hasConnectionError) {
      return 'Internet Connection Failure';
    }

    if (isPaused) {
      return 'Paused';
    }

    if (isPending) {
      return 'Pending Processing';
    }

    if (isProcessing) {
      return 'Processing...';
    }

    // TODO proper upload estimate
    return `${timeRemaining} left...`;
  }

  render() {
    const {
      inputProps,
      dragEvents,

      triggerInput,

      // Progress component props
      onPause,
      isPaused,
      isPending,
      isProcessing,
      hasConnectionError,

      hasCompleted,
      hasFailed,
      percent,
      timeRemaining,

      // submittion passed in by FileUploadInputChunked

      pafUpload,
      styles: s
    } = this.props;

    const {
      isMapping,
      sourceColumns,
      destinationColumns,
      isResuming,
      isConfirmingCancel,
      isConfirmingReUpload
    } = this.state;

    this.openInputDialog = triggerInput;

    return (
      <Box>
        {isResuming ? (
          <ResumeUploadDialog
            onSubmit={this.onResumeConfirm}
            onCancel={this.onResumeReject}
            percent={percent}
            previousFile={{
              filename: pafUpload.filename,
              filesize: pafUpload.filesize
            }}
          />
        ) : null}
        {isConfirmingCancel ? (
          <CancelUploadDialog
            onSubmit={this.onCancelConfirm}
            onCancel={this.onCancelReject}
          />
        ) : null}
        {hasCompleted ? (
          <SuccessDialog onSubmit={this.onUploadSuccessConfirm} />
        ) : null}
        <div style={{ padding: PADDINGS.M }}>
          {isMapping ? (
            <ColumnMatchingDialog
              pafUpload={pafUpload}
              sourceColumns={sourceColumns}
              destinationColumns={destinationColumns}
              closeDialog={this.closeMappingDialog}
              onSave={this.saveColumnsAndSubmit}
            />
          ) : null}
          <h1 style={{ ...TEXTS.HEADERS.HEADING_2, paddingBottom: PADDINGS.M }}>
            Upload a PAF File
          </h1>
          <p {...s('bodyParagraph')}>
            A Postcode Address File contains information about addresses and
            corresponding postcodes. Adding a PAF file allows for a faster
            address lookup in Rex using postcodes
          </p>
          <InputContainer
            {...inputProps}
            {...dragEvents}
            onChange={this.addFile}
          >
            <DefaultButton onClick={() => this.triggerInput(triggerInput)} blue>
              <span style={{ fontWeight: FONT.WEIGHTS.SEMIBOLD }}>
                Upload File
              </span>
            </DefaultButton>
            {isConfirmingReUpload ? (
              <UploadNewFileDialog
                onSubmit={this.confirmReUpload}
                onCancel={this.rejectReUpload}
              />
            ) : null}
          </InputContainer>
        </div>
        {this.props.isUploading ? (
          <Box>
            <PafUploadProgress
              timeRemaining={timeRemaining}
              onPause={onPause}
              onResume={this.onResume}
              isPaused={isPaused}
              isPending={isPending}
              isProcessing={isProcessing}
              hasFailed={hasFailed || hasConnectionError}
              onCancel={this.onCancel}
              percent={percent}
              status={this.getUploadStatus()}
              filename={pafUpload.filename || ''}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
}

export default FileUploadBody;
