import { identity } from 'lodash';
import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import { CommissionWorksheetItem } from '../../../features/commission-worksheet/types/commission-worksheet';
import { AxiosResponse } from 'axios';
import { SlidingScaleTier } from 'features/settings/components/sliding-scale-settings/data/types';
import { ValueListItem } from '../value-lists/value-list';

export interface AffectedListing {
  address: string;
  agent_sliding_comm_period_id: string;
  comms_worksheet_id: string;
  contract_id: string;
  date_actual_settlement: string;
  date_actual_unconditional: string;
  listing_id: string;
  total: string;
}

export interface UpstreamCommissionPayload {
  agent_id: number | string;
  worksheet_id: number | string;
}

export interface SlidingScalePeriod {
  start_date: string;
  end_date: string;
  period_iterations: number;
}

export interface Commission {
  contract_id: number;
  listing_id: number;
  comms_worksheet_id: number;
  is_locked: '1' | '0' | null;
  address: string;
  amount: number;
  actual_date: string | null;
  expected_date: string | null;
  gross_comm_alloc_percentage: string;
}

export interface UpstreamCommissionSummary {
  sliding_scale_tiers: SlidingScaleTier[];
  sliding_scale_structure: string;
  calculation_base: ValueListItem;
  calculation_listing_status: ValueListItem;
  summary: {
    start_date: string;
    end_date: string;
    total_amount: number;
    base_amount: number;
    commissions: Commission[];
  };
}

export interface AgentCommissionsModel {
  getAffectedListings: (
    id: number
  ) => Promise<AxiosResponse<{ result: AffectedListing[] }>>;
  getUpstreamCommissionSummary: (
    payload: UpstreamCommissionPayload
  ) => Promise<AxiosResponse<{ result: UpstreamCommissionSummary }>>;
}

const TYPE = 'agentCommissions';

const actionCreators = {
  getAffectedListings: {
    request: (id: number) =>
      api.post('AgentCommissions::getUnlockedCommissionForAgentsInWorksheet', {
        worksheet_id: id
      }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getUpstreamCommissionSummary: {
    request: (payload: UpstreamCommissionPayload) =>
      api.post('AgentCommissions::getUpstreamCommissionSummary', payload),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const agentCommissionsModel = new Generator<
  CommissionWorksheetItem,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});

export default agentCommissionsModel;
