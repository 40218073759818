import PostcodeSectorValue from 'view/components/input/select/postcode-sector/value';
import PostcodeSectorOption from 'view/components/input/select/postcode-sector/option';

import normalizer, {
  normalizerNoId
} from 'view/components/input/select/postcode-sector/normalizer';

export {
  PostcodeSectorOption,
  PostcodeSectorValue,
  normalizer,
  normalizerNoId
};
