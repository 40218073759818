import { creatingStoreBindingUtils } from 'shared/utils/redux';

import React from 'react';
import ReactDOMClient from 'react-dom/client';
import ReactDOM from 'react-dom';

import { mapValues } from 'lodash';

import EventPropagator from './event-propagator';
import CallToAction from './call-to-action';
import OverlaySync from './overlay-sync';
import ScrollSync from './scroll-sync';
import Navigator from './navigator';
import Session from './session';
import DialogsClassic from './dialogs-classic';
import DialogsShell from './dialogs-shell';
import Overlays from './overlays';
import Components from './components';
import ElementStyles from './element-styles';
import ApiClient from './api-client';
import CollisionAvoidance from './collision-avoidance';
import Notification from './notification';
import ApiCache from './api-cache';
import Popouts from './popouts';
import Entities from './entities';
import Analytics from './analytics';
import { normalizer as streetSuburbPostcodeNormalizer } from 'view/components/input/select/street-suburb-postcode-eu';
import { PrivacyBridge } from './privacy';
import { FlagsBridge } from './flagsbridge';
import { DevConsole } from 'utils/dev-console';

const { bindActionCreators, middleware } = creatingStoreBindingUtils();
const combineBridges = (bridges) =>
  mapValues(bridges, ({ actionCreators, ...rest }) => {
    return actionCreators
      ? { ...bindActionCreators(actionCreators), ...rest }
      : rest;
  });

const Bridges = combineBridges({
  EventPropagator,
  CallToAction,
  OverlaySync,
  ScrollSync,
  Navigator,
  Session,
  Dialogs: { ...DialogsClassic, ...DialogsShell },
  Overlays,
  Components,
  ElementStyles,
  ApiClient,
  CollisionAvoidance,
  Notification,
  ApiCache,
  Popouts,
  Entities,
  Analytics,
  Utils: {
    DevConsole
  },
  React,
  ReactDOMClient,
  ReactDOM,
  Normalizers: { streetSuburbPostcodeNormalizer },
  Privacy: PrivacyBridge,
  FeatureFlags: FlagsBridge
});

export { Bridges, middleware };
