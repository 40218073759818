import { TextGenerationFormType } from 'features/ai/components/text-generation/text-generation-form';
import Box from '@rexlabs/box';
import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { AiButton } from 'features/ai/components/primitives/ai-button';
import { AIFailureError } from 'features/ai/errors/ai-failure-error';
import { AIExceptionError } from 'features/ai/errors/ai-exception-error';

const styles = StyleSheet({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    paddingBottom: '50px'
  },
  h2: {
    fontSize: '20px',
    fontWeight: 600,
    color: 'rgba(33,58,106,1)'
  },
  message: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    maxWidth: '500px',
    marginBottom: '30px'
  }
});

export function TextGenerationFormError({
  type,
  error,
  setError,
  canReset
}: {
  type: TextGenerationFormType;
  canReset: boolean;
  error: Error | null;
  setError: (error: Error | null) => void;
}) {
  const s = useStyles(styles);
  const nounMap = {
    [TextGenerationFormType.AdText]: 'listing marketing text',
    [TextGenerationFormType.Email]: 'email',
    [TextGenerationFormType.SMS]: 'sms'
  };

  return (
    <Box {...s('container')}>
      <h2 {...s('h2')}>
        {error instanceof AIExceptionError
          ? 'Unexpected server error'
          : `Unable to generate ${nounMap[type]}`}
      </h2>
      <p {...s('message')}>
        {error instanceof AIFailureError || error instanceof AIExceptionError
          ? error.explanation
          : new AIExceptionError().explanation}
      </p>
      {canReset ? (
        <AiButton
          onClick={() => {
            setError(null);
          }}
        >
          Go Back To Editor
        </AiButton>
      ) : null}
    </Box>
  );
}
