import React from 'react';

import DefaultValue from 'view/components/input/select/defaults/value';

import { ICONS } from 'shared/components/icon';

const {
  SELECT: {
    VALUE: { TOWN_CITY: SuburbIcon }
  }
} = ICONS;

function SuburbValue(props) {
  return <DefaultValue {...props} Icon={SuburbIcon} />;
}

export default SuburbValue;
