import React from 'react';

import { useRegion } from 'src/hooks/use-region';
import { getDisplayName } from 'shared/utils/react';

const withRegion = (WrappedComponent) => {
  function WithRegion(props) {
    const region = useRegion();
    return <WrappedComponent {...props} region={region} />;
  }

  WithRegion.displayName = `withRegion(${getDisplayName(WrappedComponent)})`;
  return WithRegion;
};

export { withRegion };
