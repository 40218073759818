import React, { useCallback } from 'react';
import { compose } from 'redux';

import { withValueLists } from '@rexlabs/model-generator';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { Form, ReactForms } from '@rexlabs/form';

import { SelectItem } from 'types/select';

import { ICONS } from 'shared/components/icon';
import ApplyCancelButtonBar from 'shared/components/button-bar/apply-cancel';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import officeLocationModel from 'data/models/system-lists/office-locations';

import { Popout } from 'view/components/popout';
import PaddingBox from 'view/components/padding-box';
import { FormField } from 'view/components/form';
import { Select } from 'view/components/input/select';

import { SetLocationFilterStateFunction } from 'features/custom-reporting/hooks/use-user-selected-report-state';
import { getFilterHeader } from 'features/custom-reporting/utils/get-filter-header';

import { ReportingFilterButton } from 'components/button/reporting-filter-button';

interface FormValues {
  location: SelectItem;
}

interface LocationsPopoutComponentProps {
  onSave: SetLocationFilterStateFunction;
  location?: SelectItem[];
}

interface ContentProps {
  close: () => void;
  handleSave: (values: FormValues) => any;
  selectedLocation?: SelectItem;
  locations: SelectItem[];
}

const ALL_LOCATIONS_OPTION = { value: 'all_locations', label: 'All Locations' };

function Content(props: ContentProps) {
  const { close, handleSave, locations, selectedLocation } = props;
  return (
    <PaddingBox width='340px'>
      <ReactForms
        handleSubmit={(values) => {
          handleSave(values);
          close();
        }}
        initialValues={{
          location: selectedLocation || ALL_LOCATIONS_OPTION
        }}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <Form>
              <FormField
                name='location'
                label='Location'
                Input={Select}
                inputProps={{
                  options: locations,
                  placeholder: 'select users',
                  isSearchable: true,
                  valueAsObject: true,
                  hideSelected: true
                }}
              />
              <ApplyCancelButtonBar
                isLoading={isSubmitting}
                onCancel={close}
                onSave={submitForm}
              />
            </Form>
          );
        }}
      </ReactForms>
    </PaddingBox>
  );
}

// TODO: pull config from useReportState
function LocationFilterPopoutComponent(
  props: LocationsPopoutComponentProps & {
    valueLists?: { officeLocations: any };
  }
) {
  const { valueLists, onSave, location: selectedLocation } = props;

  const handleSave = useCallback(
    (values: FormValues) => {
      Analytics.track({
        event: EVENTS.CUSTOM_REPORTING.MODIFY_LOCATION_FILTER
      });
      const isAllLocations = values.location.value === 'all_locations';

      return onSave({
        locationValue: !isAllLocations ? [values.location] : []
      });
    },
    [onSave]
  );

  const allLocations =
    [ALL_LOCATIONS_OPTION, ...valueLists?.officeLocations?.items] || [];

  const filterHeader = getFilterHeader({
    recordType: 'locations',
    selectedOptions: selectedLocation,
    allOptions: allLocations
  });

  return (
    <Popout
      placement={PLACEMENTS.BOTTOM_START}
      Content={({ close }) => (
        <Content
          close={close}
          locations={allLocations}
          selectedLocation={selectedLocation?.[0]}
          handleSave={handleSave}
        />
      )}
    >
      <ReportingFilterButton
        Icon={ICONS.DASHBOARDS.COMPANY_LOCATION}
        showArrowIcon
      >
        {filterHeader}
      </ReportingFilterButton>
    </Popout>
  );
}

export const LocationFilterPopout = compose(
  withValueLists(officeLocationModel)
)(LocationFilterPopoutComponent);
