import { Generator } from 'shared/utils/models';
import {
  CreateCustomValuePayload,
  ReinzCustomValue
} from '../types/custom-value';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

const TYPE = 'reinzCustomValues';

const actionCreators = {
  createItem: {
    request: (data: CreateCustomValuePayload) => {
      return api.post('ReinzCustomValues::create', { data });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  deleteItem: {
    request: (data: Pick<ReinzCustomValue, 'id'>) => {
      return api.post('ReinzCustomValues::purge', data);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  updateItem: {
    request: (data: ReinzCustomValue) => {
      return api.post('ReinzCustomValues::update', { data });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const reinzCustomValuesModel = new Generator<
  ReinzCustomValue,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});

export default reinzCustomValuesModel;
