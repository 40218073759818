import substantiationsModel from 'data/models/entities/substantiations';
import { query } from '@rexlabs/model-generator';

function getArgs(id, state) {
  return id
    ? [
        {
          name: 'related_contact.id',
          type: 'in',
          value: [id]
        },
        {
          name: 'system_record_state',
          value: state
        }
      ]
    : [];
}

export const getActiveSubstantiationsQuery = (getId) => {
  return query`{
    ${substantiationsModel} (criteria: ${(props) =>
    getArgs(getId(props), 'active')}) {
      id
      status
      date_of
      expiry_date
      system_record_state
    }
  }`;
};
export const getArchivedSubstantiationsQuery = (getId) => {
  return query`{
    ${substantiationsModel} (criteria: ${(props) =>
    getArgs(getId(props), 'archived')}) {
      id
      status
      date_of
      expiry_date
      system_record_state
    }
  }`;
};
