import React from 'react';

import Box from '@rexlabs/box';

interface SavedListGroupProps {
  children: React.ReactNode;
}

export function SavedListGroup(props: SavedListGroupProps) {
  const { children } = props;

  return <Box>{children}</Box>;
}
