import React, { useEffect } from 'react';
import {
  AgGridReact,
  AgGridColumnProps,
  AgGridColumnGroupProps
} from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import {
  SideBarDef,
  GridReadyEvent,
  ColumnState,
  ColumnEverythingChangedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  ModelUpdatedEvent
} from 'ag-grid-community';
import { insertCss } from '@rexlabs/styling';
import { FONT } from 'theme';
import config from 'shared/utils/config';
import { useDataGrid } from 'features/custom-reporting/hooks/use-data-grid';
import { dataGridColumnTypes } from 'features/custom-reporting/components/data-grid-column-types';

LicenseManager.setLicenseKey(config.AG_GRID_KEY);

const defaultColDef: AgGridColumnProps = {
  // make every column not editable
  editable: false,
  // hide every column by default so we can opt-in, otherwise the default mode is opt-out
  hide: true,
  // make every column use 'text' filter by default
  filter: 'agTextColumnFilter',
  // disable floating (column header) filters by default
  floatingFilter: false,
  resizable: true,
  sortable: true,
  enableRowGroup: true,
  enablePivot: true,
  filterParams: {
    buttons: ['reset'],
    debounceMs: 500
  }
};

const sideBar: SideBarDef = {
  hiddenByDefault: false,
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      width: 320,
      toolPanelParams: {
        suppressRowGroups: false,
        // suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: false,
        suppressColumnFilter: false,
        // select all and expand all will get intensive with larger column numbers
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true
      }
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      width: 320,
      toolPanelParams: {
        suppressExpandAll: true,
        suppressFilterSearch: false,
        suppressSyncLayoutWithGrid: true
      }
    }
  ]
};

type AgGridProps = React.ComponentProps<typeof AgGridReact>;

type DataGridProps = AgGridProps & {
  onColumnChange?: ({ columns }) => any;
  hideSidebar?: boolean;
};

// TODO: type these props
export function DataGrid({ hideSidebar = false, ...props }: DataGridProps) {
  const { setRef } = useDataGrid();

  // TODO: Currently the filter panel shows ALL columns, but some columns aren't even fetched, so you can't filter by
  // data that isn't there. We need to update the filter model so it only shows available columns.

  useEffect(() => {
    insertCss(`
        .ag-theme-alpine {
          font-family: ${FONT.FAMILY.PROXIMA_NOVA};
        }
    `);
  }, []);
  // HACK. We want to be able to display the column and filter panels but NOT the sidebar,
  // which the grid doesn't give us access to do.
  useEffect(() => {
    if (hideSidebar) {
      insertCss(`
        .ag-theme-alpine .ag-side-buttons {
          display: none;
        }
        `);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  return (
    <div className='ag-theme-alpine' style={{ flex: 1 }}>
      <AgGridReact
        ref={setRef}
        sideBar={sideBar}
        defaultColDef={defaultColDef}
        groupDisplayType={'singleColumn'}
        columnTypes={dataGridColumnTypes}
        {...props}
      />
    </div>
  );
}

export type DataGridApi = AgGridReact;

export type FilterModel = { [key: string]: any };

export type DataGridColumn = Partial<
  AgGridColumnProps & AgGridColumnGroupProps
>;

export {
  ColumnEverythingChangedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  ColumnState,
  GridReadyEvent,
  ModelUpdatedEvent
};
