import { useMemo } from 'react';
import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  TenancyApplicationReportQuery,
  tenancyApplicationReportQueryDefaultVariables
} from './tenancy-applications-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { useRegion } from 'hooks/use-region';

export const defaultColumns = [
  'listing.owner.0.contact',
  'listing',
  'listing.property',
  'tenants.0.contact',
  'tenants.1.contact',
  'tenants.2.contact',
  'application_status.text',
  'system_affordability_percentage',
  'offer_amount',
  'offer_amount_period.text'
];

export function useTenancyApplicationModule() {
  const ww = useWhichWordFactory();
  const region = useRegion();
  const {
    listings: listingCustomFields,
    contacts: contactCustomFields,
    properties: propertyCustomFields
  } = useCustomFields();
  const tenancyApplicationModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.tenancy_applications,
      moduleLabel: 'Tenancy Applications',
      exportPrivilege: '',
      queryConfig: {
        graphQlQuery: TenancyApplicationReportQuery,
        queryVariables: tenancyApplicationReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'system_modtime',
            direction: 'desc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'listing.property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Listing Owner',
          children: makeEntity({
            entity: 'contact',
            prefix: 'listing.owner.0.contact',
            subresource: 'listing_owner',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Application',
          children: makeEntity({
            entity: 'tenancyApplication',
            prefix: '',
            whichWord: ww,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'system_owner_user_id',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: 'Creation'
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'system_owner_user_id',
              label: 'Record owner',
              isDefault: true
            },
            {
              fieldName: 'application.agent_id',
              label: 'Agent',
              isDefault: false
            }
          ]
        }
      }
    }),
    [contactCustomFields, listingCustomFields, propertyCustomFields, region, ww]
  );

  return tenancyApplicationModuleConfig;
}
