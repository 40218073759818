import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { IconButton } from 'view/components/button';
import { RecordDetailsWidget } from 'components/_experiment/record-details-screen/widget';
import { HeadingWithButtons } from 'components/_experiment/record-widget/heading';
import { Info } from 'components/_experiment/record-widget/info';

const defaultStyles = StyleSheet({
  tag: {
    background: '#2aa2e3',
    padding: '5px 9px',
    fontWeight: 600,
    fontSize: '12px',
    color: '#fff'
  }
});

interface TagsWidgetProps {
  tags: any;
}

export function TagsWidget({ tags }: TagsWidgetProps) {
  const s = useStyles(defaultStyles);
  return (
    <RecordDetailsWidget>
      <HeadingWithButtons
        Buttons={() => <IconButton red circle Icon={ICONS.EDIT} />}
      >
        Tags
      </HeadingWithButtons>

      {tags?.length ? (
        <Box flexDirection='row' flexWrap='wrap' spacing='3px'>
          {tags.map((tag) => (
            <Box key={tag?.id} {...s('tag')}>
              {tag?.tag}
            </Box>
          ))}
        </Box>
      ) : (
        <Info>No tags found</Info>
      )}
    </RecordDetailsWidget>
  );
}
