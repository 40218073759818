import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

export function RightBarHeading(props: BaseTextProps) {
  return (
    <Text
      is='h4'
      {...props}
      type={map({
        RIGHTBAR_HEADING: true
      })}
    />
  );
}
