/*
|-------------------------------------------------------------------------------
| Overlay Mappings
|-------------------------------------------------------------------------------
|
| We're mapping shell overlay components to their callNames here. The callName
| is the key that's used to expose the overlays in the OverlaysBridge and
| in `AVM.shell.overlays.*.open()`. The only purpose of this is to be able
| to open shell overlays from within classic!
|
| NOTE: unlike dialogs we don't provide a way to open classic overlays within
| shell. Classic overlays will open within the classic frame and will just
| trigger an event that will keep the backdrop in sync.
|
*/
import { lazy } from 'react';

export default {
  listingsImageUploader: {
    ShellComponent: lazy(() => import('view/overlays/listings/image-upload'))
  },
  roomImages: {
    ShellComponent: lazy(() => import('view/overlays/listings/room-images'))
  },
  watermarkPreview: {
    ShellComponent: lazy(() => import('view/overlays/watermarks/preview'))
  },
  addContact: {
    ShellComponent: lazy(() => import('view/overlays/contacts/add'))
  },
  newsletterBuilder: {
    ShellComponent: lazy(() =>
      import('features/newsletter-builder/overlays/newsletter-builder-overlay')
    )
  },
  debugWorkflow: {
    ShellComponent: lazy(() => import('view/overlays/workflows/debug'))
  }
};
