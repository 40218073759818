import _ from 'lodash';
import { checkUserHasPermission } from 'utils/rights';

const stripValues = (obj) => {
  return _.get(obj, 'contact.id');
};

const substantiationsForm = {
  name: 'substantiationsForm',
  // TODO: add any more relevant validations
  validate: (values) => {
    const errors = {};
    errors.listingLimitToOffer = [];
    const ltoValues = _.get(values, 'listingLimitToOffer', []);

    const ltoErrors = [];
    ltoValues.map((lto, i) => {
      if (!lto.offerValue) {
        ltoErrors.push(
          ` Limit to Offer ${i + 1} must have a contract selected`
        );
      }
    });

    if (
      values.substantiationContacts &&
      !values.substantiationContacts.length > 0
    ) {
      errors.substantiationContacts =
        'There must be at least one contact attached to a substantiation.';
    }

    if (ltoErrors.length > 0) {
      errors.listingLimitToOffer.push(
        `Limit to Offer field errors [${ltoErrors.join()} ].`
      );
    }

    return errors;
  },
  mapPropsToValues: ({ substantiations, contacts }) => {
    // substantiation tab values
    const sourceOfFundsDepositId = _.get(
      substantiations,
      'item.data.source_of_funds_deposit.id',
      null
    );
    const sourceOfFundsBalanceId = _.get(
      substantiations,
      'item.data.source_of_funds_balance.id',
      null
    );
    const expiryDate = _.get(substantiations, 'item.data.expiry_date', null);
    const positionId = _.get(substantiations, 'item.data.position.id', null);
    const statusId = _.get(
      substantiations,
      'item.data.status.id',
      'in_progress'
    );

    const substantiationContactsRaw =
      contacts ||
      _.get(substantiations, 'item.data.related.substantiation_contacts');

    const substantiationContacts = _.map(
      substantiationContactsRaw,
      stripValues
    ).filter(Boolean);

    // details tab values
    const amount = _.get(substantiations, 'item.data.amount', null);
    const note = _.get(substantiations, 'item.data.note', null);
    const methodId = _.get(substantiations, 'item.data.method.id', null);
    const substantiationDate = _.get(
      substantiations,
      'item.data.date_of',
      null
    );
    const substantiatedByUserId = _.get(
      substantiations,
      'item.data.substantiated_by_user.id',
      null
    );

    return {
      // substantiation tab values
      sourceOfFundsDepositId,
      sourceOfFundsBalanceId,
      expiryDate,
      positionId,
      statusId,
      substantiationContacts,
      documents: [],
      docsFlaggedForDeletion: [],
      // listingLimitToOffer,

      // details tab values
      amount,
      methodId,
      substantiatedByUserId,
      substantiationDate,
      note
    };
  },
  handleSubmit: async (values, { props, meta }) => {
    const {
      // substantiation tab values
      sourceOfFundsDepositId,
      sourceOfFundsBalanceId,
      expiryDate,
      positionId,

      substantiationContacts,
      listingLimitToOffer,

      // details tab values
      amount,
      methodId,
      substantiatedByUserId,
      substantiationDate,
      note
    } = values;

    const contactRecords = _.get(
      props,
      'substantiations.item.data.related.substantiation_contacts',
      []
    );
    const ltoRecords = _.get(
      props,
      'substantiations.item.data.related.substantiation_limit_to_contracts',
      []
    );

    const contactsFlaggedForDeletion = [];
    for (const record of contactRecords) {
      const destroyMe = substantiationContacts.find(
        (id) => id === record.contact.id
      );
      if (!destroyMe) {
        contactsFlaggedForDeletion.push({
          _destroy: true,
          _id: record.id,
          contact_id: record.contact.id
        });
      }
    }

    const newContacts = [];
    for (const id of substantiationContacts) {
      const newContact = contactRecords.find(
        (record) => id === record.contact.id
      );
      if (!newContact) {
        newContacts.push({
          contact_id: id
        });
      }
    }

    const ltOffersFlaggedForDeletion = [];

    // `ltoRecords` contains the original records that you see
    // on open or after save.
    for (const record of ltoRecords) {
      // `listingLimitToOffer` contains the actual records
      // that you can see on UI and is affected add/remove updates
      // before saving. If you've removed all 'Limit to Offer' records,
      // this will be 'undefined'.
      const destroyMe = (listingLimitToOffer || []).find(
        (lto) => lto.ltoID === record.id
      );

      if (!destroyMe) {
        ltOffersFlaggedForDeletion.push({
          _destroy: true,
          _id: record.id,
          contract_id: record.contract.id
        });
      }
    }

    const newLtOffers = [];
    if (listingLimitToOffer) {
      for (const lto of listingLimitToOffer) {
        const newContact = ltoRecords.find(
          (record) => lto.offerValue === record.contract.id
        );
        if (!newContact && lto.offerValue >= 0) {
          newLtOffers.push({
            contract_id: lto.offerValue
          });
        }
      }
    }

    const deletedFiles = _.get(meta, 'deletedFiles', []);
    const activeFiles = _.get(values, 'documents', []);
    const allDocuments = [...activeFiles, ...deletedFiles];

    // TODO: The field names should probably just match what the backend expects
    // that way there is no need to map majority of the data
    const data = {
      note: note || null,
      substantiated_by_user_id: substantiatedByUserId || null,
      method_id: methodId || null,
      amount: amount || null,
      expiry_date: expiryDate || null,
      date_of: substantiationDate || null,
      status_id: values.statusId || null,
      position_id: positionId || null,
      source_of_funds_deposit_id: sourceOfFundsDepositId || null,
      source_of_funds_balance_id: sourceOfFundsBalanceId || null,

      _related: {
        substantiation_contacts: [
          ...contactsFlaggedForDeletion,
          ...newContacts
        ],
        substantiation_documents: allDocuments,
        substantiation_limit_to_contracts: [
          ...ltOffersFlaggedForDeletion,
          ...newLtOffers
        ]
      }
    };

    if (props.id) {
      data.id = props.id;
    }

    const formCheckUserHasPermission = (accessRights) => {
      const { session } = props;
      return checkUserHasPermission((dotNotation) =>
        _.get(session, dotNotation)
      )(accessRights);
    };

    if (
      !formCheckUserHasPermission('contacts.view_edit_substantiation_details')
    ) {
      delete data.status_id;
      delete data.expiry_date;
    }

    const action = props.id ? 'updateItem' : 'createItem';
    return props.substantiations[action]({ data })
      .then((res) => {
        if (props.callback) {
          props.callback(res.data);
        }
        props.closeDialog();
      })
      .catch((e) => {
        props.errorDialog.open(e);
      });
  }
};

export default substantiationsForm;
