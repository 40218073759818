import React from 'react';
import dayjs from 'dayjs';

import leadType from 'data/models/system-lists/lead-type';
import enquirySource from 'data/models/system-lists/enquiry-source';

import {
  Select,
  ValueListSelect,
  DateSelect
} from 'view/components/input/select';
import { FiltersConfig } from 'components/record-list-screen/types';

export function useLeadsListFilters() {
  return React.useMemo<FiltersConfig>(
    () => ({
      columns: 2,
      groups: [
        [
          {
            name: 'lead.lead_status_id',
            label: 'status',
            Input: Select,
            inputProps: {
              options: [
                { value: 'new', label: 'New' },
                { value: 'in_progress', label: 'In Progress' },
                { value: 'completed', label: 'Completed' }
              ],
              multi: true,
              withTags: true
            }
          },
          {
            name: 'lead.lead_type_id',
            label: 'lead type',
            Input: ValueListSelect,
            inputProps: {
              models: [leadType]
            }
          },
          {
            name: 'lead.lead_source_id',
            label: 'lead source',
            Input: ValueListSelect,
            inputProps: {
              models: [enquirySource]
            }
          },
          {
            name: 'system_record_state',
            label: 'show archived',
            type: 'in',
            Input: Select,
            placeholder: 'No',
            inputProps: {
              options: [
                { value: 'yes', label: 'Yes' }, // record state is either active or archived
                { value: 'no', label: 'No' } // record state is only active
              ]
            },
            filterToCriteria: ({ filterConfig, value }) =>
              value === 'yes'
                ? [{ ...filterConfig, value: ['archived', 'active'] }]
                : [{ ...filterConfig, value: ['active'] }],
            criteriaToValue: ({ criteriaItem }) =>
              (criteriaItem.value as string[]).includes('archived') &&
              (criteriaItem.value as string[]).includes('active')
                ? 'yes'
                : 'no'
          },

          // you can have multiple filters with the same name, but the id must be unique.
          {
            name: 'system_ctime',
            id: 'system_ctime_min',
            type: '>=',
            label: 'received from',
            Input: DateSelect,
            placeholder: dayjs().format('DD/MM/YYYY')
          },
          {
            name: 'system_ctime',
            id: 'system_ctime_max',
            type: '<=',
            label: 'received to',
            Input: DateSelect,
            placeholder: dayjs().format('DD/MM/YYYY')
          }
        ]
      ]
    }),
    []
  );
}
