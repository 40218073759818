import { gql } from 'hooks/use-graphql-query';

export const Calendar = gql`
  fragment Calendar on Calendar {
    id
    name
  }
`;

export const AppointmentType = gql`
  fragment AppointmentType on AppointmentType {
    id
    name
    category {
      text
    }
  }
`;
export const FollowupReminder = gql`
  fragment FollowupReminder on FollowupReminder {
    reminder {
      system_completed_time
    }
    custom_due_date
    due_date_type {
      text
    }
    type {
      text
    }
  }
`;
export const CalendarEvent = gql`
  fragment CalendarEvent on CalendarEvent {
    id
    title
    system_ctime
    description
    starts_at {
      time
      tzid
    }
    ends_at {
      time
      tzid
    }
    event_location {
      description
    }
    appointment_confirmed
    reminder_to_follow_up_status {
      text
    }
    show_event_details
  }
`;
