import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box, { BoxProps } from '@rexlabs/box';
import { PADDINGS, COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: 'transparent'
  },

  containerLight: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  },

  containerYellow: {
    backgroundColor: COLORS.PRIMARY.YELLOW_LIGHT
  },

  containerWhite: {
    backgroundColor: COLORS.WHITE
  },

  containerError: {
    backgroundColor: COLORS.STATUS.DANGER_LIGHT
  }
});

export interface PaddingBoxProps extends BoxProps {
  light?: boolean;
  yellow?: boolean;
  white?: boolean;
  error?: boolean;
}

export default function PaddingBox({
  style,
  light,
  yellow,
  white,
  error,
  padding,
  className,
  ...props
}: PaddingBoxProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box
      p={padding ?? PADDINGS.M}
      {...s.with('container', {
        containerLight: light,
        containerYellow: yellow,
        containerWhite: white,
        containerError: error
      })({ className, style })}
      {...props}
    />
  );
}
