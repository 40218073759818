import React, { PureComponent } from 'react';
import types from 'prop-types';
import Button from '@rexlabs/button';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'theme';
import _ from 'lodash';

const createHash = (...args) =>
  args.reduce((all, arg) => `${all}-${arg || !!arg}`, '');

const getStyles = _.memoize(
  (white, blue, sand, padding, textDecoration, height, fontSize) => {
    return {
      Button: StyleSheet({
        container: {
          backgroundColor: 'transparent',
          border: '0 none',
          color: white
            ? COLORS.WHITE
            : blue
            ? COLORS.PRIMARY.BLUE
            : sand
            ? COLORS.PRIMARY.SAND
            : COLORS.GREY_DARK,
          height: height || '34px',
          fontSize: '13px',
          fontWeight: 600,
          padding: padding || `0 ${PADDINGS.S}`
        },

        content: {
          transition: 'color 0.3s',
          color: white
            ? COLORS.WHITE
            : blue
            ? COLORS.PRIMARY.BLUE
            : sand
            ? COLORS.PRIMARY.SAND
            : COLORS.GREY_DARK,
          textDecoration: textDecoration || 'underline',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          '> span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        },

        label: {
          fontSize: fontSize || '12px'
        }
      })
    };
  },
  createHash
);

const defaultStyles = StyleSheet({
  icon: {
    display: 'flex',
    flexShrink: 0,

    '& svg': {
      width: '22px',
      height: 'auto',

      '& *': {
        fill: 'currentColor !important'
      }
    }
  },

  iconRight: {}
});

@styled(defaultStyles)
class TextButton extends PureComponent {
  static propTypes = {
    white: types.bool,
    blue: types.bool,
    sand: types.bool
  };

  render() {
    const {
      white,
      blue,
      sand,
      padding,
      textDecoration,
      height,
      children,
      styles: s,
      IconRight,
      IconLeft,
      fontSize,
      form,
      ...props
    } = this.props;

    const overrides = getStyles(
      white,
      blue,
      sand,
      padding,
      textDecoration,
      height,
      fontSize
    );

    return (
      <StylesProvider components={overrides} prioritiseParentStyles={false}>
        <Button type={form ? 'submit' : 'button'} form={form} {...props}>
          {IconLeft && <span>{IconLeft}</span>}
          <span {...s('label')}>{children}</span>
          {IconRight && (
            <span {...s('icon')}>
              <IconRight />
            </span>
          )}
        </Button>
      </StylesProvider>
    );
  }
}

export default TextButton;
