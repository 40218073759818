import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import {
  CustomField,
  getCustomFieldColumns
} from 'features/custom-reporting/utils/custom-field-utils';
import { Region } from 'hooks/use-region';
import { WhichWord } from 'hooks/use-which-word';

export const listingFields = ({
  region,
  customFields,
  subresource,
  whichWord
}: {
  region?: Region;
  customFields?: Record<string, CustomField>;
  subresource?: string;
  whichWord?: WhichWord;
}): ReportColumnConfig => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'listing'
  });
  return [
    {
      headerName: 'Listing ID',
      field: 'id'
    },
    {
      field: 'inbound_unique_id',
      headerName: 'Custom ID'
    },
    {
      headerName: 'Listing address',
      colId: 'listing_address',
      type: 'listingRecordColumn',
      keyCreator: ({ value, data }) => {
        const recordData = value || data;
        return recordData?.property_address?.system_search_key;
      },
      filterParams: {
        valueGetter: ({ data }) => data?.property_address?.system_search_key
      },
      width: 400
    },
    {
      headerName: 'Location',
      field: 'location.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'office_locations' })
      }
    },
    {
      headerName: 'Listing type',
      field: 'listing_category.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'listing_category' })
      }
    },
    {
      headerName: 'Listing status',
      field: 'status.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'listing_status' })
      }
    },
    {
      headerName: 'Match price',
      field: 'price_match',
      type: 'currencyColumn'
    },
    {
      headerName: 'Advertise As',
      field: 'price_advertise_as'
    },
    {
      headerName: 'Rent price',
      field: 'price_rent',
      type: 'currencyColumn'
    },
    {
      headerName: 'Rent p/a/m2',
      field: 'price_rent_per_m2',
      type: 'currencyColumn'
    },
    {
      headerName: 'Rent price period',
      field: 'price_rent_period.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'rent_amount_period' })
      }
    },
    {
      headerName: 'Listing agent 1',
      field: 'listing_agent_1.name',
      type: 'agentValueListColumn',
      subresource: 'listing_agent_1'
    },
    {
      headerName: 'Listing agent 2',
      field: 'listing_agent_2.name',
      type: 'agentValueListColumn',
      subresource: 'listing_agent_2'
    },
    {
      headerName: 'Authority date',
      field: 'authority_date_start',
      type: 'dateColumn',
      withRegions: ['AUS', 'NZ']
    },

    {
      headerName: 'Instruction date',
      field: 'authority_date_start',
      type: 'dateColumn',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Publish date',
      field: 'system_publication_time',
      type: 'dateColumn'
    },
    {
      headerName: 'Available date',
      field: 'available_from_date',
      type: 'dateColumn'
    },
    {
      headerName: 'Expiry date',
      field: 'authority_date_expires',
      type: 'dateColumn'
    },
    {
      headerName: 'Sold price',
      field: 'sold_price',
      type: 'currencyColumn'
    },
    {
      headerName: 'Leased price',
      field: 'leased_price',
      type: 'currencyColumn'
    },
    {
      headerName: 'Leased price period',
      field: 'state_value_price_rent_period.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'rent_amount_period' })
      }
    },
    {
      headerName: 'Marked as sold date',
      field: 'marked_as_sold_date',
      type: 'dateColumn'
    },
    {
      headerName: 'Marked as leased date',
      field: 'marked_as_leased_date',
      type: 'dateColumn'
    },
    {
      headerName: 'Marked as withdrawn date',
      field: 'marked_as_withdrawn_date',
      type: 'dateColumn'
    },
    {
      headerName: 'Withdrawn reason',
      field: 'withdrawn_reason.text'
    },
    {
      headerName: 'Comm estimate rate',
      field: 'comm_amount_percentage',
      type: 'numberColumn'
    },
    {
      headerName: 'Comm estimate structure',
      field: 'comm_structure.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({
          listName: 'commission_structures_in_archived'
        })
      }
    },
    {
      headerName: 'Comm estimate (incl GST)',
      field: 'comm_est_amount_inc_tax',
      type: 'currencyColumn',
      withRegions: ['AUS', 'NZ']
    },
    {
      headerName: 'Comm estimate (excl GST)',
      field: 'comm_est_amount_net_of_tax',
      type: 'currencyColumn',
      withRegions: ['AUS', 'NZ']
    },
    {
      headerName: 'Comm estimate (incl VAT)',
      field: 'comm_est_amount_inc_tax',
      type: 'currencyColumn',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Comm estimate (excl VAT)',
      field: 'comm_est_amount_net_of_tax',
      type: 'currencyColumn',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Vendor legal name',
      field: 'legal_vendor_name'
    },
    ...(region?.isEU
      ? [
          {
            headerName: 'EPC lodgement date',
            field: 'epc.epc_lodgement_date',
            type: 'dateColumn',
            subresource: 'epc'
          },

          {
            headerName: 'EPC expiry date',
            field: 'epc.epc_expiry_date',
            type: 'dateColumn',
            subresource: 'epc'
          },
          {
            headerName: 'EPC not required',
            field: 'epc.epc_not_required',
            type: 'booleanColumn',
            subresource: 'epc'
          },
          {
            headerName: 'EPC not required reason',
            field: 'epc.epc_not_required_reason',
            subresource: 'epc'
          }
        ]
      : []),
    {
      headerName: 'Record owner',
      subresource: 'record_owner',
      field: 'system_owner_user.name',
      type: 'agentValueListColumn'
    },
    {
      field: 'system_ctime',
      headerName: 'Created date',
      type: 'dateColumn'
    },
    {
      headerName: 'Created by',
      field: 'system_created_user.name',
      type: 'agentValueListColumn',
      subresource: 'system_created_user'
    },
    {
      field: 'system_modtime',
      headerName: 'Last modified',
      type: 'dateColumn'
    },
    {
      headerName: 'Applications',
      field: 'record_stats.application_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'application_count')?.value
    },
    {
      headerName: 'Contracts',
      field: 'record_stats.contract_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'contract_count')?.value
    },
    {
      headerName: whichWord?.('inspection') + 's' || 'Inspections',
      field: 'record_stats.inspection_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'inspection_count')?.value
    },
    {
      headerName: 'Leads',
      field: 'record_stats.lead_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'lead_count')?.value
    },
    {
      headerName: 'Completed Leads',
      field: 'record_stats.lead_completed',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'lead_completed')?.value
    },
    {
      headerName: 'New/In-progress Leads',
      field: 'record_stats.lead_new_and_in_prog',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'lead_new_and_in_prog')?.value
    },
    {
      headerName: 'Open Home Attendees',
      field: 'record_stats.ofi_attendees_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'ofi_attendees_count')?.value
    },
    ...customFieldColumns
  ];
};
