import React, { CSSProperties } from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface BodyProps extends BaseTextProps {
  normal?: boolean;
  light?: boolean;
  white?: boolean;
  small?: boolean;
  short?: boolean;
  large?: boolean;
  regular?: boolean;
  medium?: boolean;
  semibold?: boolean;
  dark?: boolean;
  bold?: boolean;
  black?: boolean;
  slate?: boolean;
  italic?: boolean;
  informative?: boolean;
  underline?: boolean;
  style?: CSSProperties;
  is?: string;
}

export function Body({
  normal,
  light,
  white,
  small,
  short,
  large,
  regular,
  medium,
  semibold,
  dark,
  bold,
  black,
  slate,
  italic,
  informative,
  underline,
  ...props
}: BodyProps) {
  return (
    <Text
      is='p'
      {...props}
      type={map({
        // Note: to see these styles, search for the key in capitals in src/theme/index
        BODY: true,
        BODY_DARK: !slate && dark,
        SLATE_DARK: slate && dark,
        REGULAR: regular,
        MEDIUM: medium,
        SEMIBOLD: semibold,
        BOLD: bold,
        LIGHT: light,
        WHITE: white,
        SMALL: small,
        SHORT: short,
        LARGE: large,
        NORMAL: normal,
        SLATE: slate,
        BLACK: black,
        ITALIC: italic,
        INFORMATIVE: informative,
        UNDERLINE: underline
      })}
    />
  );
}
