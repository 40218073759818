import React, { ComponentPropsWithoutRef } from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface LinkProps extends BaseTextProps {
  record?: boolean;
  blue?: boolean;
  white?: boolean;
  light?: boolean;
  dark?: boolean;
  regular?: boolean;
  undecorated?: boolean;
  unlinked?: boolean;
  large?: boolean;
  small?: boolean;
  lineThrough?: boolean;
  bold?: boolean;
  dialogBody?: boolean;
  onClick?: Function;
}

// We use type for something else, so remove the default anchor prop
type AdjustedAnchorProps = Omit<ComponentPropsWithoutRef<'a'>, 'type'>;

type LinkTextProps = LinkProps & AdjustedAnchorProps;

export function Link({
  record,
  blue,
  white,
  light,
  dark,
  regular,
  undecorated,
  unlinked,
  large,
  small,
  lineThrough,
  bold,
  dialogBody,
  ...props
}: LinkTextProps) {
  return (
    <Text
      is='a'
      {...props}
      type={map({
        LINK: true,
        BLUE: blue,
        WHITE: white,
        LIGHT: light,
        DARK: dark,
        UNDECORATED: undecorated,
        RECORD_LINK: record,
        REGULAR: regular,
        UNLINKED: unlinked,
        LARGE: large,
        SMALL: small,
        LINE_THROUGH: lineThrough,
        BOLD: bold,
        BODY_DIALOG: dialogBody
      })}
    />
  );
}
