import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS, TEXTS, PADDINGS } from 'theme';
import Dialog from 'view/components/dialog';
import ICONS from 'shared/components/icon';

const Warning = ICONS.WARNING;

@styled(
  StyleSheet({
    heading: {
      ...TEXTS.HEADERS.HEADING_2,
      paddingBottom: PADDINGS.XS
    },
    content: {
      ...TEXTS.CONTENT.DEFAULT_TEXT,
      paddingBottom: PADDINGS.XS
    },
    buttonBar: {
      marginTop: PADDINGS.M
    },
    icon: {
      color: COLORS.PRIMARY.RED,
      marginRight: PADDINGS.S,
      width: '50px',
      height: '50px'
    }
  })
)
@autobind
class PafFatalErrorDialog extends PureComponent {
  render() {
    const { styles: s, ...rest } = this.props;
    return (
      <Dialog title='Fatal Error' top={200} {...rest}>
        <Box flexDirection='row'>
          <Warning {...s('icon')} color={'red'} />
          <Box>
            <h2 {...s('heading')}>A fatal error occurred during processing</h2>
            <p {...s('content')}>
              Please try uploading a different file. Alternatively you can
              contact the provider of your file to ensure there are no issues.
            </p>
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default PafFatalErrorDialog;
