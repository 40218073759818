import React, { useMemo, useCallback } from 'react';
import { Id } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';

import { PADDINGS } from 'src/theme';
import { Heading } from 'components/text/heading';

import { ColumnsConfig } from 'components/record-list-screen/types';
import { Table } from 'src/view/components/record-details-screen/related-table/table';
import { DateTimeCell } from 'components/record-list-screen/cells/date-time-cell';

interface MatchProfileRecord {
  id: Id;
  profile_name: string;
  _category_name: string;
  system_modtime: string;
}

interface MatchProfileProps {
  profile: MatchProfileRecord;
  actions: {
    unarchive: () => void;
    purge: (any) => void;
  };
}

interface ArchivedMatchProfilesProps {
  items: MatchProfileProps[];
}

export function ArchivedMatchProfiles({ items }: ArchivedMatchProfilesProps) {
  const unarchiveProfile = useCallback((profile) => {
    profile.data.actions.unarchive();
  }, []);

  const deleteProfile = useCallback((profile) => {
    profile.data.actions.purge(true);
  }, []);

  const getRowActions = useCallback((rowData) => {
    return [
      {
        label: 'Unarchive',
        onClick: unarchiveProfile
      },
      {
        label: 'Delete',
        onClick: deleteProfile
      }
    ];
  }, []);

  const columns = useMemo(
    (): ColumnsConfig<MatchProfileProps> => [
      {
        label: 'name',
        id: 'profile_name',
        selector: ({ profile }) => {
          return profile.profile_name;
        },
        hidden: false,
        cellProps: { items: getRowActions }
      },
      {
        label: 'category',
        id: 'category_name',
        selector: ({ profile }) => profile._category_name,
        hidden: false
      },
      {
        label: 'date archived',
        id: 'system_modtime',
        selector: ({ profile }) => profile.system_modtime,
        Cell: DateTimeCell
      }
    ],
    []
  );

  return (
    <Box mt={PADDINGS.S}>
      <Heading level={3}>Archived Match Profiles</Heading>
      <Table columns={columns} items={items} />
    </Box>
  );
}
