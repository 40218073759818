import { gql } from 'hooks/use-graphql-query';

export const Contact = gql`
  fragment Contact on Contact {
    id
    address
    address_postal
    company_abn
    company_name
    interest_level
    is_dnd
    last_contacted_at
    last_contacted_date
    marketing_birthday
    marketing_enquiry_method {
      text
    }
    marketing_enquiry_source
    marketing_gender
    marketing_postcode
    name_addressee
    name_legal
    name_salutation
    notes
    system_ctime
    system_modtime
    system_record_state
    # system_search_key exists in a number of modules,
    # which caused a clash in the popout columns
    # contact_name is both unique and more informative
    contact_name: system_search_key
    tags
    type
    website_url
    system_created_user {
      name
    }
    record_stats(
      keys: [appraisal_count, contract_count, inspection_count, ofi_count]
    ) {
      key
      value
    }
  }
`;
export const ContactNames = gql`
  fragment ContactNames on ContactName {
    name_title
    name_first
    name_middle
    name_last
  }
`;
export const ContactPhones = gql`
  fragment ContactPhones on ContactPhone {
    phone_number
    phone_primary
  }
`;
export const ContactEmails = gql`
  fragment ContactEmails on ContactEmail {
    email_address
    email_primary
    email_secondary
  }
`;
export const MatchProfile = gql`
  fragment MatchProfile on MatchProfile {
    id
    price_match_min
    price_match_max
    attr_bedrooms_min
    attr_bedrooms_max
    attr_bathrooms_min
    attr_bathrooms_max
    attr_garages_min
    attr_garages_max
    suburbs {
      text: suburb_or_town
    }
    localities: locations(type: locality) {
      type
      text: value
    }
    postcode_area: locations(type: postcode_area) {
      type
      text: value
    }
    postcode_district: locations(type: postcode_district) {
      type
      text: value
    }
    postcode_sector: locations(type: postcode_sector) {
      type
      text: value
    }
    postcodes: locations(type: postcode) {
      type
      text: value
    }
    streets: locations(type: street) {
      type
      text: value
    }
    towns: locations(type: town) {
      type
      text: value
    }
    category {
      text
    }
    system_modtime
  }
`;
