import React from 'react';
import adminNewsletterTemplates, {
  AdminNewsletterTemplate
} from 'features/newsletter-builder/data/admin-newsletter-templates';
import { useDialog } from 'hooks/use-dialog';
import {
  AdminListScreen,
  AdminListScreenProps
} from 'components/admin-list-screen/index';
import { useCommonColumns } from 'components/admin-list-screen/utils/common-columns';
import { useCommonDropdownItems } from 'components/admin-list-screen/utils/common-actions';
import { useAdminListScreenQuery } from 'components/admin-list-screen/hooks/use-admin-list-screen-query';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';

export default function AdminNewsletterTemplates() {
  const addEditNewsletterTemplate = useDialog('addEditNewsletterTemplate');

  const name = 'Newsletter Templates';
  const serviceName = 'AdminNewsletterTemplates';

  const session = useModelState(sessionModel);

  const { entityList, setCriteria, criteria } = useAdminListScreenQuery(
    adminNewsletterTemplates,
    [
      {
        name: 'is_hidden',
        type: '=',
        value: false
      },
      {
        name: 'system_record_state',
        type: 'in',
        value: ['active']
      }
    ]
  );

  const { actions } = entityList;

  const commonColumns = useCommonColumns<AdminNewsletterTemplate>();
  const commonDropdownItems = useCommonDropdownItems<AdminNewsletterTemplate>(
    { name, serviceName },
    actions
  );

  const listScreenProps: AdminListScreenProps<AdminNewsletterTemplate> = {
    name,
    serviceName,
    onAdd: () =>
      new Promise((resolve) =>
        addEditNewsletterTemplate.open({ callback: resolve })
      ),
    onItemClick: ({ id, account_id }) => {
      if (String(account_id) === String(session.office_details?.id)) {
        addEditNewsletterTemplate.open({ id });
      }
    },
    columns: [
      commonColumns.libraryIcon(),
      commonColumns.name({
        note: (data) => (data.is_default ? ' default' : ''),
        dropdownItems: (data) => [
          commonDropdownItems.edit(
            () => addEditNewsletterTemplate.open({ id: data.id }),
            data
          ),
          commonDropdownItems.duplicate(actions.duplicate),
          commonDropdownItems.setAsDefault(actions.setAsDefault, data),
          commonDropdownItems.manageLibrary(data),
          commonDropdownItems.hide(data),
          commonDropdownItems.show(data),
          commonDropdownItems.archive(actions.archive, data),
          commonDropdownItems.unarchive(actions.recoverFromArchive, data),
          commonDropdownItems.delete(actions.purge, data)
        ]
      }),
      commonColumns.libraryName()
    ],
    entityList,
    criteria,
    setCriteria
  };

  return <AdminListScreen {...listScreenProps} />;
}
