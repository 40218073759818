import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Generator } from 'shared/utils/models';

const TYPE = 'listingPublication';

const actionCreators = {
  getImageCyclingSettings: {
    request: (payload) =>
      api.post('ListingPublication::getImageCyclingSettings', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  setImageCyclingSettings: {
    request: (payload) =>
      api.post('ListingPublication::setImageCyclingSettings', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getPublicationIssues: {
    request: (payload) =>
      api.post('ListingPublication::getPublicationIssues', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const listingPublicationModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default listingPublicationModel;
