import { useMemo } from 'react';
import { query, useEntityQuery } from '@rexlabs/model-generator';
import { useSendEmail } from 'components/record-preview/actions/record-preview-send-email';
import { useSendSMS } from 'components/record-preview/actions/record-preview-send-sms';
import { getPropertyPreviewPopoutData } from 'features/properties/popouts/property-preview-popout/utils';
import propertiesModel from 'data/models/entities/properties';
import { Region, useRegion } from 'hooks/use-region';

const getPropertyQuery = (propertyId) => {
  return query`{
  ${propertiesModel} (id: ${propertyId}) {
    id
    security_user_rights
  }
}`;
};

export function usePropertyPreviewPopout({
  id,
  region
}: {
  id: string;
  region?: Region;
}) {
  const propertyQuery = useMemo(
    () => (id && getPropertyQuery(id)) || undefined,
    [id]
  );

  const { data: property, status } = useEntityQuery(propertyQuery);

  const isLoading = status !== 'loaded';
  const hasReadRights = property?.security_user_rights?.includes('read');

  const viewPath = `/properties/#id=${id}`;

  const { newMailMergeData, ...rest } = useMemo(
    () => getPropertyPreviewPopoutData({ property, region }),
    [property, region]
  );

  const onSendEmailClick = useSendEmail({ record: newMailMergeData });

  const onSendSMSClick = useSendSMS({ record: newMailMergeData });

  return {
    ...rest,
    isLoading,
    hasReadRights,
    viewPath,
    onSendEmailClick,
    onSendSMSClick
  };
}
