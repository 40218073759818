import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS, PADDINGS } from 'theme';

interface SavedListGroupListProps {
  children: React.ReactNode;
}

const defaultStyles = StyleSheet({
  list: {
    '& li:nth-child(odd) div': {
      backgroundColor: COLORS.PRIMARY.WHITE
    },
    '& li:nth-child(even) div': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    },
    '& li:hover div': {
      background: COLORS.BACKGROUNDS.GREY,
      cursor: 'pointer'
    }
  }
});

export function SavedListGroupList(props: SavedListGroupListProps) {
  const { children } = props;
  const s = useStyles(defaultStyles);

  return (
    <Box pt={PADDINGS.XXS} pb={PADDINGS.XXS}>
      <ul {...s('list')}>{children}</ul>
    </Box>
  );
}
