import React, { Component } from 'react';
import { withModel } from '@rexlabs/model-generator';
import projectStagesModel from 'data/models/entities/project-stages';
import projectsModel from 'data/models/entities/projects';
import { formatAddress } from 'utils/formatters';
import _ from 'lodash';
import Core from './core';
import { autobind } from 'core-decorators';

@withModel(projectsModel)
@withModel(projectStagesModel)
@autobind
class ProjectStageTooltip extends Component {
  state = {
    isLoading: true
  };

  /**
   * Handles fetching the stream data which is shown inside of the popout. We want to fetch this extra data
   * when the popout is opened by the user and no sooner.
   *
   * The call should only ever be made once per record.
   *
   * @param {Object} { isOpen, isClosing } - Indicates the current transitioning states of the popout
   */
  handleFetchPopoutData({ isOpen }) {
    const { projects, projectStages, id, projectId } = this.props;
    const { isLoading } = this.state;

    if (isOpen && isLoading) {
      Promise.all([
        projectStages.fetchItem({ id }),
        projects.fetchItem({ id: projectId })
      ]).then(([{ data: projectStageData }, { data: projectData }]) => {
        this.setState({
          project: projectData,
          projectStage: projectStageData,
          isLoading: false
        });
      });
    }
  }

  render() {
    const { projectStage, project, isLoading } = this.state;

    const serviceName = 'project_stages';
    const recordType = 'project_stage';

    const data = {
      address: formatAddress(
        _.pick(project, ['adr_postcode', 'adr_locality', 'adr_suburb_or_town'])
      ),
      image:
        _.get(projectStage, 'project.estate_logo') ||
        _.get(projectStage, 'project.developer_logo'),
      project,
      projectStageName: _.get(projectStage, 'name'),
      serviceName,
      recordType
    };

    return (
      <Core
        {...this.props}
        data={data}
        projectStage={projectStage}
        isLoading={isLoading}
        onChange={this.handleFetchPopoutData}
      />
    );
  }
}

export default ProjectStageTooltip;
