// TODO: should make this a key - value generic repeater
import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { TextInput } from '@rexlabs/text-input';

import { autobind } from 'core-decorators';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  inputs: {
    marginRight: PADDINGS.XS,
    width: '100%'
  }
});

@styled(defaultStyles)
@autobind()
class TypeReferenceInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      charactersRequiredMap: {},
      humanReadableNameMap: {}
    };
  }

  handleHeaderKeyChange({ target }) {
    const { value, onChange } = this.props;

    const fakeEvent = {
      target: {
        value: {
          headerKey: target.value,
          headerValue: _.get(value, 'headerValue')
        }
      }
    };
    onChange(fakeEvent);
  }

  handleHeaderValueChange({ target }) {
    const { value, onChange } = this.props;

    const fakeEvent = {
      target: {
        value: {
          headerKey: _.get(value, 'headerKey'),
          headerValue: target.value
        }
      }
    };
    onChange(fakeEvent);
  }

  render() {
    const { styles: s, value, ...props } = this.props;
    return (
      <Box flexDirection={'row'}>
        <Box {...s('inputs')}>
          <TextInput
            name='headerKey'
            value={_.get(value, 'headerKey')}
            {...props}
            onChange={this.handleHeaderKeyChange}
          />
        </Box>
        <Box {...s('inputs')}>
          <TextInput
            name='headerValue'
            value={_.get(value, 'headerValue', '')}
            {...props}
            onChange={this.handleHeaderValueChange}
          />
        </Box>
      </Box>
    );
  }
}

export default TypeReferenceInput;
