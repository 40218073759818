import { useDialog } from 'hooks/use-dialog';
import {
  Actions,
  useModelActions,
  useModelState
} from '@rexlabs/model-generator';
import { AdminListScreenProps } from 'components/admin-list-screen/index';
import sessionModel from 'data/models/custom/session';
import adminTemplateLibrariesModel from 'data/models/entities/admin-template-libraries';
import uiModel from 'data/models/custom/ui';
import { useErrorDialog } from 'hooks/use-error-dialog';

export const useCommonDropdownItems = <
  ModelType extends {
    id: string | number;
    is_hidden: boolean | null;
    system_record_state: string;
    is_default: boolean | null;
    account_id: string | number;
    library?: { id: string | number; library_name: string } | null;
  }
>(
  {
    name,
    serviceName
  }: Pick<AdminListScreenProps<ModelType>, 'name' | 'serviceName'>,
  actions: Actions<ModelType> & {
    purge: (id: string) => Promise<unknown>;
    duplicate: (id: string) => Promise<unknown>;
    recoverFromArchive: (id: string) => Promise<unknown>;
    archive: (id: string) => Promise<unknown>;
  }
) => {
  const confirmationDialog = useDialog('confirmation');
  const editTemplateLibrarySettings = useDialog('editTemplateLibrarySettings');
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(uiModel);
  const session = useModelState(sessionModel);
  const { loadingIndicator } = useModelState(uiModel);
  const templateLibraryActions = useModelActions(adminTemplateLibrariesModel);
  const errorDialog = useErrorDialog();

  function handleErrors(callback: (...args: any) => unknown) {
    try {
      callback();
    } catch (error: any) {
      errorDialog.open(error);
      if (loadingIndicator === 'ON') {
        loadingIndicatorOff({});
      }
    }
  }

  return {
    edit: (
      editFunction: (id: string) => Promise<unknown>,
      record: ModelType
    ) => ({
      id: 'edit',
      label: 'Edit',
      hasPermission: record.account_id === session.office_details?.id,
      onClick: ({ data }: { data: ModelType }) => {
        handleErrors(() => editFunction(String(data.id)));
      }
    }),
    delete: (
      deleteFunction: (id: string) => Promise<unknown>,
      record: ModelType
    ) => ({
      id: 'delete',
      label: 'Delete',
      hasPermission: record.account_id === session.office_details?.id,
      onClick: ({ data }: { data: ModelType }) => {
        handleErrors(() => {
          confirmationDialog.open({
            title: `Delete ${name}`,
            message: `Are you sure you want to permanently delete this ${name.toLowerCase()}? This action cannot be undone.`,
            confirmText: `Delete Permanently`,
            onConfirm: async () => {
              await deleteFunction(String(data.id));
              await actions.refreshList();
            }
          });
        });
      }
    }),
    archive: (
      archiveFunction: (id: string) => Promise<unknown>,
      record: ModelType
    ) => ({
      id: 'archive',
      label: 'Archive',
      hasPermission:
        record.account_id === session.office_details?.id &&
        record.system_record_state !== 'archived',
      onClick: ({ data }: { data: ModelType }) => {
        handleErrors(() => {
          confirmationDialog.open({
            title: `Archive ${name}`,
            message: `Are you sure you want to archive this ${name.toLowerCase()}?`,
            confirmText: `Confirm Archive`,
            onConfirm: async () => {
              await archiveFunction(String(data.id));
              await actions.refreshList();
            }
          });
        });
      }
    }),
    unarchive: (
      unarchiveFunction: (id: string) => Promise<unknown>,
      record: ModelType
    ) => ({
      id: 'unarchive',
      label: 'Unarchive',
      hasPermission:
        record.account_id === session.office_details?.id &&
        record.system_record_state === 'archived',
      onClick: ({ data }: { data: ModelType }) => {
        handleErrors(() => {
          confirmationDialog.open({
            title: `Archive ${name}`,
            message: `Are you sure you want to unarchive and restore this ${name.toLowerCase()}?`,
            confirmText: `Confirm Unarchive`,
            onConfirm: async () => {
              await unarchiveFunction(String(data.id));
              await actions.refreshList();
            }
          });
        });
      }
    }),
    hide: (record: ModelType) => ({
      id: 'hide',
      label: 'Hide',
      hasPermission:
        !record.is_hidden &&
        !!record.library &&
        record.account_id !== session.office_details?.id,
      onClick: async () => {
        handleErrors(async () => {
          loadingIndicatorOn({ message: 'Hiding template' });
          await templateLibraryActions.hide({
            service_name: serviceName,
            service_object_id: record.id
          });
          await actions.refreshList();
          loadingIndicatorOff({});
        });
      }
    }),
    show: (record: ModelType) => ({
      id: 'show',
      label: 'Show',
      hasPermission:
        record.is_hidden &&
        !!record.library &&
        record.account_id !== session.office_details?.id,
      onClick: async () => {
        handleErrors(async () => {
          loadingIndicatorOn({ message: 'Showing template' });

          await templateLibraryActions.unhide({
            service_name: serviceName,
            service_object_id: record.id
          });
          await actions.refreshList();
          loadingIndicatorOff({});
        });
      }
    }),
    duplicate: (duplicateFunction: (id: string) => Promise<unknown>) => ({
      id: 'duplicate',
      label: 'Duplicate',
      onClick: async ({ data }: { data: ModelType }) => {
        handleErrors(async () => {
          loadingIndicatorOn({ message: 'Duplicating template' });
          await duplicateFunction(String(data.id));
          await actions.refreshList();
          loadingIndicatorOff({});
        });
      }
    }),
    setAsDefault: (
      setAsDefaultFunction: (id: string) => Promise<unknown>,
      record: ModelType
    ) => ({
      id: 'setAsDefault',
      label: 'Set as default',
      hasPermission:
        !record.is_default &&
        !record.is_hidden &&
        record.system_record_state === 'active',
      onClick: async ({ data }: { data: ModelType }) => {
        handleErrors(async () => {
          loadingIndicatorOn({ message: 'Setting as default' });
          await setAsDefaultFunction(String(data.id));
          await actions.refreshList();
          loadingIndicatorOff({});
        });
      }
    }),
    manageLibrary: (record: ModelType) => {
      return {
        id: 'library_settings',
        label: 'Library settings',
        hasPermission:
          record.account_id === session.office_details?.id &&
          session.managed_libraries?.length > 0,
        onClick: ({ data: record }: { data: ModelType }) => {
          handleErrors(() => {
            editTemplateLibrarySettings.open({
              data: record.library
                ? {
                    id: record.library.id,
                    _library_name: record.library.library_name
                  }
                : null,
              recordId: record.id,
              className: serviceName,
              callback: (
                res: boolean | { id: string; _library_name: string }
              ) => {
                actions.refreshList();
              }
            });
          });
        }
      };
    }
  };
};
