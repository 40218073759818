import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS } from 'theme';

import ImagePlaceholder from 'view/components/image-placeholder';

interface RecordPreviewImageProps {
  placeHolderIcon?: any;
  recordImage?: string | null;
  height?: number;
}

const defaultStyles = StyleSheet({
  image: {
    objectFit: 'cover'
  },
  imageContainer: {
    // We have this here as there is a moment where the placeholder disapears and the image
    // isn't quite ready.
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  }
});

export function RecordPreviewImage({
  placeHolderIcon,
  recordImage,
  // The image container should be a square for contacts, market leads and campaigns.
  // Everything else, height should be 75px.
  height = 100
}: RecordPreviewImageProps) {
  const s = useStyles(defaultStyles);

  return (
    <Box
      width={100}
      height={height}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      {...s('imageContainer')}
    >
      {/* We need to make sure we have a placeholderIcon before rendering this. Normally we know
      the record type, so it should always be true. But with Contacts, we need to wait for BE so
      we can tell if it's a contact or a company */}
      {!recordImage && placeHolderIcon && (
        <ImagePlaceholder Icon={placeHolderIcon} />
      )}
      {recordImage && (
        <img
          src={recordImage}
          width='100%'
          height='100%'
          {...s('image')}
          alt='image'
          data-testid='RecordPreview.Image'
        />
      )}
    </Box>
  );
}
