import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { NEW_TEXTS, PADDINGS } from 'theme';
import { Link } from './link';

import { map } from 'shared/utils/text';

const { BLUE, WHITE, LIGHT, DARK } = NEW_TEXTS;

const defaultStyles = StyleSheet({
  BLUE,
  WHITE,
  LIGHT,
  DARK,
  boxContainer: {
    gap: PADDINGS.XXS
  },
  icon: {
    height: '14px'
  }
});

export interface LinkIconProps extends React.ComponentProps<typeof Link> {
  iconType: any;
}

export function LinkIcon(props: LinkIconProps) {
  const { iconType, blue, white, light, dark } = props;

  const s = useStyles(defaultStyles);

  const ICON = iconType;

  const colorsMapped = map({
    BLUE: blue,
    WHITE: white,
    LIGHT: light,
    DARK: dark
  });

  const iconColor = colorsMapped[colorsMapped.length - 1] || 'DARK';

  return (
    <Box flexDirection='row' alignItems='center' {...s('boxContainer')}>
      <ICON {...s('icon', iconColor)} />
      <Link {...props} />
    </Box>
  );
}
