import React from 'react';
import { noop } from 'lodash';
import { StyleSheet } from '@rexlabs/styling';

import Spinner from 'shared/components/spinner';

import { ActionMenu } from 'view/components/action-menu';
import DropdownButton from 'view/components/button/dropdown';
import { Body } from 'components/text/body';

import { ActionMenuProps } from './core';

const loadingOrNoResultItemStyles = StyleSheet({
  menuItem: {
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',

    '> a': {
      display: 'flex !important',
      justifyContent: 'center !important'
    }
  }
});

interface DynamicDropdownProps extends Omit<ActionMenuProps, 'items'> {
  items?: ActionMenuProps['items'];
  isLoading?: boolean;
}

function DynamicDropdown({
  items = [],
  placement,
  isLoading,
  ...props
}: DynamicDropdownProps) {
  // TODO: should do this in vivid(?) and use an actual spinner + not use `items` for this
  // (although this is how Classic does it..)
  const isEmpty = !isLoading && items.length === 0;
  const loadingItem = [{ label: <Spinner small dark />, onClick: noop }];
  const noResultsItem = [
    { label: <Body italic>No Results</Body>, onClick: noop }
  ];
  items = isLoading ? loadingItem : isEmpty ? noResultsItem : items;

  return (
    <ActionMenu
      Button={({ isOpen }) => <DropdownButton {...props} active={isOpen} />}
      {...props}
      style={{ zIndex: '100' }}
      items={items}
      placement={placement}
      distance='14px'
      offset='0px'
      styles={(isLoading || isEmpty) && loadingOrNoResultItemStyles}
    />
  );
}

export default DynamicDropdown;
