import React from 'react';
import _ from 'lodash';
import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';
import { formatAddress } from 'utils/formatters/addresses';
import { ICONS } from 'shared/components/icon';
import { LeadsResponse } from 'data/models/entities/leads';

import HeadingWithButton from 'view/components/heading-with-button';
import ProjectTooltip from 'view/components/popout/project';
import ProjectStageTooltip from 'view/components/popout/project-stage';

import { Link } from 'components/text/link';
import { LabelledValue, TEXT_PRESETS } from 'components/labelled-value';
import { ContactDetailsItem } from 'components/contact-details-item';

import { ListingPreviewPopout } from 'features/listings/popouts/listing-preview-popout';
import { PropertyPreviewPopout } from 'features/properties/popouts/property-preview-popout';

interface LeadsDialogDetailsSectionProps {
  data: LeadsResponse;
  editLead?: (event: React.MouseEvent<HTMLElement>) => any;
}

export function LeadsDialogDetailsSection(
  props: LeadsDialogDetailsSectionProps
) {
  const { data, editLead } = props;

  return (
    // NOTE: For anyone wondering why have nested boxes, it is because of the spacing prop.
    // Because we use sy in the parent component, it overrides the sy in this component.
    // Wrapping in a box stops this from happening, as it adds an additionally layer.
    <Box>
      <Box sy={PADDINGS.XXS}>
        <HeadingWithButton
          heading='Details'
          iconButtonIcon={ICONS.EDIT}
          iconButtonOnClick={editLead}
        />

        <LabelledValue label='type'>{data?.lead_type?.text}</LabelledValue>

        <LabelledValue label='source'>{data?.lead_source?.text}</LabelledValue>

        <LabelledValue label='contact' textPreset={TEXT_PRESETS.MEDIUM}>
          {data?.contact && <ContactDetailsItem contact={data.contact} />}
        </LabelledValue>

        {(data?.lead_type?.id === 'listing_enquiry' || data?.listing?.id) && (
          <LabelledValue label='listing' textPreset={TEXT_PRESETS.MEDIUM}>
            {data?.listing?.id && (
              <ListingPreviewPopout id={data.listing.id}>
                <Box flexDirection='row' flexWrap='wrap'>
                  <Box pr={PADDINGS.XXS}>
                    <Link blue undecorated record>
                      {formatAddress(
                        _.pick(data?.listing?.property, [
                          'adr_street_name',
                          'adr_unit_number',
                          'adr_street_number',
                          'adr_locality',
                          'adr_suburb_or_town',
                          'property_category'
                        ])
                      )}
                    </Link>
                  </Box>
                  <Box>
                    <Link blue undecorated record>
                      ({data.listing.listing_category.text})
                    </Link>
                  </Box>
                </Box>
              </ListingPreviewPopout>
            )}
          </LabelledValue>
        )}

        {(data?.lead_type?.id === 'appraisal_request' || data?.property) && (
          <LabelledValue label='property' textPreset={TEXT_PRESETS.MEDIUM}>
            {data?.property && (
              <PropertyPreviewPopout id={data?.property?.id}>
                <Link blue undecorated record>
                  {formatAddress(
                    _.pick(data?.property, [
                      'adr_street_name',
                      'adr_unit_number',
                      'adr_street_number',
                      'adr_locality',
                      'adr_suburb_or_town',
                      'property_category'
                    ])
                  )}
                </Link>
              </PropertyPreviewPopout>
            )}
          </LabelledValue>
        )}
        {data?.project && (
          <LabelledValue label='project' textPreset={TEXT_PRESETS.MEDIUM}>
            <ProjectTooltip id={data?.project?.id}>
              <Link blue undecorated record>
                {data?.project?.name}
              </Link>
            </ProjectTooltip>
          </LabelledValue>
        )}
        {data?.project_stage && (
          <LabelledValue label='project stage' textPreset={TEXT_PRESETS.MEDIUM}>
            <ProjectStageTooltip
              id={data?.project_stage?.id}
              projectId={data?.project_stage.project?.id}
            >
              <Link blue undecorated record>
                {data?.project_stage?.full_name}
              </Link>
            </ProjectStageTooltip>
          </LabelledValue>
        )}
      </Box>
    </Box>
  );
}
