import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '60px',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  iconWrapper: {
    width: '18px',
    height: '16px',
    color: 'rgb(255, 255, 255)',

    '& > svg': {
      width: 'inherit',
      height: '13.5px'
    }
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none',
    userSelect: 'none'
  }
});

@styled(defaultStyles)
class Hamburger extends PureComponent {
  render() {
    const { styles: s, disabled, ...props } = this.props;
    return (
      <Box tabIndex={'-1'} {...s('container', { disabled })} {...props}>
        <Icon {...s('iconWrapper')} type={ICONS.HAMBURGER} />
      </Box>
    );
  }
}

export default Hamburger;
