import { useCallback } from 'react';
import { push, useWhereabouts } from '@rexlabs/whereabouts';

export function usePage(): [number, (newPage: number) => void] {
  const whereabouts = useWhereabouts();

  const page = whereabouts.hashQuery?.page
    ? parseInt(whereabouts.hashQuery?.page)
    : 0;
  const setPage = useCallback(
    (newPage) => {
      const newRoute = {
        ...whereabouts,
        hash: undefined,
        hashQuery: {
          ...whereabouts.hashQuery,
          page: newPage === 0 ? undefined : newPage
        }
      };
      push({ config: newRoute });
    },
    [whereabouts]
  );

  return [page, setPage];
}
