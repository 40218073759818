import React from 'react';
import { isString } from 'lodash';
import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';
import { Link } from 'components/text/link';

import { getCustomFieldValue } from 'features/custom-reporting/utils/custom-field-utils';
import { ListingPreviewPopout } from 'features/listings/popouts/listing-preview-popout';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';

export const customFieldColumnTypes = {
  customFieldBooleanColumn: {
    valueGetter: getCustomFieldValue('boolean')
  },
  customFieldDateColumn: {
    valueGetter: getCustomFieldValue('date')
  },
  customFieldStringColumn: {
    valueGetter: getCustomFieldValue('string')
  },
  customFieldNumberColumn: {
    valueGetter: getCustomFieldValue('number')
  },
  customFieldSelectColumn: {
    valueGetter: getCustomFieldValue('string_array')
  },
  customFieldRecordColumn: {
    valueGetter: getCustomFieldValue('record.record')
  },
  customFieldRecordArrayColumn: {
    valueGetter: getCustomFieldValue('record_array')
  },
  customFieldFileColumn: {
    valueGetter: getCustomFieldValue('file.description')
  },
  customFieldTimeColumn: {
    valueGetter: getCustomFieldValue('time')
  },
  customFieldDefaultRecordColumn: {
    valueFormatter: ({ value }) => value?.id
  },
  customFieldMultiRecordColumn: {
    valueGetter: getCustomFieldValue('record_array')
  },
  customFieldMultiListingColumn: {
    valueFormatter: ({ value, node }) => {
      return (
        value?.map?.(
          (listing) => listing?.record?.property_address?.system_search_key
        ) || getEmptyValue({ node })
      );
    },
    cellRenderer: ({ value, node }) => {
      // value is a string when grouping
      if (isString(value)) {
        return value;
      }
      return (
        <Box flexDirection={'row'} sx={PADDINGS.XXS}>
          {value?.map?.((listing, index) => {
            const id = listing?.record?.id;
            const address =
              listing?.record?.property_address?.system_search_key;
            return address ? (
              <>
                <ListingPreviewPopout id={id}>
                  <Link blue undecorated record>
                    {address}
                  </Link>
                </ListingPreviewPopout>
                {index === value.length - 1 ? '' : ','}
              </>
            ) : (
              getEmptyValue({ node })
            );
          }) || getEmptyValue({ node })}
        </Box>
      );
    }
  }
};
