import React, { useCallback, useEffect, useState } from 'react';

import { ICONS } from 'shared/components/icon';
import { api } from 'shared/utils/api-client';
import { DefaultButton } from 'view/components/button';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import { Form, FormField, ReactForms } from 'view/components/form';
import { Select } from 'view/components/input/select';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';

const FilterSvg: any = ICONS.FILTER;

function FilterPopoutContent({ serviceName, close }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    api
      .post('RecordStream::getAvailableSourceServices', {
        service_name: serviceName
      })
      .then(({ data }) => {
        setOptions(data.result);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [serviceName]);

  return (
    <PaddingBox width='300px'>
      <ReactForms
        handleSubmit={() => {
          // eslint-disable-next-line
          alert('Foo');
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormField
              Input={Select}
              inputProps={{
                options: options.map((type: string) => ({
                  // NOTE: turning the camelCase types we get from the API into
                  // human readable sentance case
                  label: type
                    .replace(/([A-Z])/g, (match) => ` ${match}`)
                    .replace(/^./, (match) => match.toUpperCase())
                    .trim(),
                  value: type
                })),
                multi: true
              }}
              name='type'
              label='show only entries of type'
            />
            <SaveCancelButtonBar
              blue
              saveLabel='Apply'
              onSave={submitForm}
              onCancel={close}
              isLoading={isSubmitting}
            />
          </Form>
        )}
      </ReactForms>
    </PaddingBox>
  );
}

export function FilterPopout({ serviceName }) {
  const Content = useCallback(
    ({ close }) => (
      <FilterPopoutContent close={close} serviceName={serviceName} />
    ),
    [serviceName]
  );

  return (
    <Popout Content={Content}>
      <DefaultButton blue IconLeft={FilterSvg}>
        Filter
      </DefaultButton>
    </Popout>
  );
}
