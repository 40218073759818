import React from 'react';

import { LinkIcon } from 'components/text/link-icon';

import { WrapperButton } from './wrapper-button';

export function LinkIconButton(props: React.ComponentProps<typeof LinkIcon>) {
  return (
    <WrapperButton>
      <LinkIcon {...props} />
    </WrapperButton>
  );
}
