import React, { PureComponent, useContext } from 'react';
import _ from 'lodash';
import { Field } from '@rexlabs/form';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { InfoTooltip } from 'view/components/tooltip';
import { FormContext } from './form';

const defaultStyles = StyleSheet({
  container: {
    display: 'block'
  }
});

const createFieldLabel = _.memoize(
  (disabled, helpText) => {
    @styled(defaultStyles)
    class FieldLabel extends PureComponent {
      render() {
        const { children, styles: s, ...labelProps } = this.props;

        if (!children) {
          return null;
        }

        return (
          <Box
            {...labelProps}
            onClick={disabled ? undefined : labelProps.onClick}
          >
            <Box {...s('container')}>
              {children}
              {!!helpText && <InfoTooltip small inline content={helpText} />}
            </Box>
          </Box>
        );
      }
    }
    return FieldLabel;
  },
  (disabled, helpText) => `${disabled}--${helpText}`
);

const FormField = ({ fieldStyles = {}, ...props }) => {
  const { helpText, isInlineLabel, ...rest } = props;
  const { disableFields, sendImmediate } = useContext(FormContext);

  const disabled = props.disabled || rest.inputProps?.disabled;

  return (
    <Field
      styles={fieldStyles}
      isInlineLabel={isInlineLabel}
      sendImmediate={sendImmediate}
      {...rest}
      inputProps={{
        ...rest.inputProps,
        disabled: disableFields || disabled
      }}
      FieldLabel={createFieldLabel(disableFields || disabled, helpText)}
    />
  );
};

export default FormField;
