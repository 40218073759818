import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS } from 'theme';

import { RedirectParagraph } from 'view/components/external-link-redirect/redirect-paragraph-text';
import { RedirectLink } from 'view/components/external-link-redirect/redirect-link-text';
import { RedirectContent } from 'view/components/external-link-redirect/redirect-content';

export interface ErrorContentProps {
  errorText: ReactNode;
}

export function ErrorContent({ errorText }: ErrorContentProps) {
  return (
    <>
      <RedirectContent heading='There was a problem' bodyText={errorText} />
      <Box mt={PADDINGS.S}>
        <RedirectParagraph>
          Please try again or{' '}
          <RedirectLink href='mailto:support@rexsoftware.com.au'>
            contact our support team
          </RedirectLink>
        </RedirectParagraph>
      </Box>
    </>
  );
}
