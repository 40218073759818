import React from 'react';
import { TOKENS } from 'theme';
import { legacyMergeClasses } from 'utils/styles';
import LEGACY_COMPONENTS from 'theme/legacy/components';
import { COMPONENTS } from 'theme/components';
import { StylesProvider } from '@rexlabs/styling';

const COMBINED_COMPONENTS = {
  ...LEGACY_COMPONENTS,
  ...COMPONENTS
};

export default function ShellStylesProvider({ children }) {
  return (
    <StylesProvider
      components={COMBINED_COMPONENTS}
      tokens={TOKENS}
      debug={__DEV__}
      prioritiseParentStyles={false}
      mergeClasses={legacyMergeClasses}
    >
      {children}
    </StylesProvider>
  );
}
