import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { COLORS, PADDINGS } from 'src/theme';

const PinnedSvg: any = ICONS.PINNED;

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    right: PADDINGS.M,
    bottom: PADDINGS.M,
    fontSize: '12px',
    lineHeight: '19px',
    fontWeight: 600,
    color: '#b4b1a9',

    '& svg': {
      height: '14px',
      width: 'auto'
    }
  },

  icon: {
    color: COLORS.PRIMARY.BLUE
  }
});

export function Statuses({ data }) {
  const s = useStyles(defaultStyles);

  if (!data?.is_pinned) {
    return null;
  }

  return (
    <Box {...s('container')} flexDirection='row' alignItems='center' sx='6px'>
      <span>pinned</span>
      <PinnedSvg {...s('icon')} />
    </Box>
  );
}
