import Box from '@rexlabs/box';
import React, { useEffect, useState } from 'react';
import { RecordActions } from '../components/contracts-conditions/record-actions';
import { IconButton } from 'src/view/components/button';
import Icon, { ICONS } from 'shared/components/icon';
import { ReactForms } from '@rexlabs/form';
import { Form, FormField } from 'src/view/components/form';
import SaveCancelOverlayHandler from 'view/components/save-cancel-overlay-handler';
import { Body } from 'components/text/body';
import Checkbox from 'src/view/components/input/checkbox';
import { PADDINGS } from 'shared/theme';
import { TextArea } from '@rexlabs/text-input';
import { ListingTypesTableForm } from '../components/contracts-conditions/listing-types-table-form';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { ContractCondition } from 'features/contract-conditions/data/contract-conditions';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import Spinner from 'shared/components/spinner';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { ContractConditionsProps } from './contract-conditions';
import { useDialog } from 'hooks/use-dialog';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';

const defaultStyles = StyleSheet({
  name: {
    fontSize: '27px',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  }
});

export const ContractConditionsRecord = ({
  contractConditions
}: ContractConditionsProps) => {
  const { office_details, managed_libraries } = useModelState(sessionModel);
  const s = useStyles(defaultStyles);
  const errorDialog = useErrorDialog();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [contractCondition, setContractCondition] =
    useState<ContractCondition>();
  const editCondition = useDialog('editCondition');
  const whereabouts = useWhereaboutsWithViewpath();
  const isParentAccount = contractCondition?.account_id === office_details?.id;

  const { fetchContractCondition, updateContractCondition } =
    contractConditions;

  const onSubmit = async (values: ContractCondition, { resetForm }) => {
    try {
      await updateContractCondition({
        ...values,
        id: whereabouts.hashQuery?.id
      });
      resetForm(values);
    } catch (error) {
      resetForm();
      errorDialog.open(error as Error);
    }
  };

  useEffect(() => {
    const fetchCondition = async (id: string) => {
      setIsLoading(true);
      try {
        const result = await fetchContractCondition(id);
        setContractCondition(result);
      } catch (error) {
        errorDialog.open(error as Error);
      }
      setIsLoading(false);
    };

    fetchCondition(whereabouts.hashQuery?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whereabouts.hashQuery?.id]);

  if (isLoading)
    return (
      <Box flex={1} justifyContent={'center'} alignItems={'center'}>
        <Spinner dark small />
      </Box>
    );

  return (
    <Box w='100%' p={`24px ${PADDINGS.M} ${PADDINGS.M}`}>
      <RecordActions
        editCondition={editCondition}
        contractCondition={contractCondition}
        setContractCondition={setContractCondition}
        contractConditions={contractConditions}
        errorDialog={errorDialog}
        isParentAccount={isParentAccount}
        hasManagedLibraries={managed_libraries?.length > 0}
      />
      <Box alignItems={'center'} flexWrap='wrap' mb={20} mt={20} spacing={10}>
        <Box alignItems={'center'}>
          <h2 {...s('name')}>{contractCondition?.name}</h2>
          {isParentAccount && (
            <IconButton
              circle
              red
              Icon={ICONS.EDIT}
              onClick={() => {
                editCondition.open({
                  contractCondition,
                  onSuccess: setContractCondition
                });
              }}
            />
          )}
        </Box>
        {contractCondition?.library && (
          <Body dark semibold style={{ display: 'flex' }}>
            <Icon
              type={ICONS.TEMPLATE}
              hasControlledColor={false}
              style={{ display: 'flex', marginRight: 5 }}
            />
            Part of library ‘{contractCondition.library.library_name}’
            {!isParentAccount ? ' , cannot be modified' : ''}
          </Body>
        )}
      </Box>
      <ReactForms<ContractCondition, unknown>
        handleSubmit={onSubmit}
        initialValues={contractCondition}
      >
        {({ submitForm, isDirty, resetForm, setFieldValue, values }) => (
          <Form hasErrorPadding disableFields={!isParentAccount}>
            <Box mb={30}>
              <FormField
                label={'description'}
                name={'description'}
                Input={TextArea}
                sendImmediate
              />
            </Box>
            <Box mb={30}>
              <Body semibold dark large style={{ marginBottom: 5 }}>
                Listing Types
              </Body>
              <Body regular dark style={{ marginBottom: 20 }}>
                Set up your listing types so the condition is available to
                agents to select from and/or shown by default
              </Body>
              <ListingTypesTableForm
                values={values}
                setFieldValue={setFieldValue}
              />
            </Box>
            <Box mb={25}>
              <Body semibold dark large style={{ marginBottom: 5 }}>
                Edit Condition
              </Body>
              <Body regular dark style={{ marginBottom: -5 }}>
                Allow individuals to remove or edit the condition in the
                contract.
              </Body>
              <FormField
                name={'is_editable'}
                Input={Checkbox}
                sendImmediate
                inputProps={{
                  label: 'Allow'
                }}
              />
            </Box>
            <SaveCancelOverlayHandler
              isVisible={isDirty}
              onSave={submitForm}
              onCancel={resetForm}
            />
          </Form>
        )}
      </ReactForms>
    </Box>
  );
};
