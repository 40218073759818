import React from 'react';

import { ContactStub } from 'data/models/entities/contacts';

import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';

import { BasePopoutCellProps, PopoutCell } from './popout-cell';
import { DefaultCell } from './default';
import Box from '@rexlabs/box';
import { Link } from 'components/text/link';

interface MultiContactCellProps extends BasePopoutCellProps {
  value?: ContactStub[] | null;
}

export function MultiContactCell(props: MultiContactCellProps) {
  const { value, ...rest } = props;

  return (
    <DefaultCell {...rest}>
      {value?.length ? (
        <Box
          flexDirection='row'
          alignItems='center'
          flexWrap='wrap'
          spacing={3}
        >
          {value?.map((contact, index) => (
            <Box
              key={contact.id}
              width={'fit-content'}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ContactPreviewPopout id={contact.id}>
                <Link blue undecorated>
                  {contact.name}
                  {index + 1 !== value.length && ','}
                </Link>
              </ContactPreviewPopout>
            </Box>
          ))}
        </Box>
      ) : (
        '—'
      )}
    </DefaultCell>
  );
}
