import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import { PADDINGS, TEXTS, COLORS } from 'theme';
import { styled, StyleSheet } from '@rexlabs/styling';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';
import FileUploadInput from '@rexlabs/file-upload-input';

const fileProps = {
  acceptTypes: ['application/pdf', 'application/csv', 'text/plain', 'text/csv'],
  acceptExtensions: ['.csv', '.txt', '.pdf'],
  maxFileSize: 16 * 1024 * 1024 * 1024
};

@styled(
  StyleSheet({
    input: {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: -1
    }
  })
)
class InputContainer extends PureComponent {
  render() {
    const {
      styles: s,
      name,
      tabIndex,
      shouldAllowMultiple,
      accept,
      setInputRef,
      onChange,
      onFocus,
      onBlur,
      children
    } = this.props;
    return (
      <label>
        {children}
        <input
          {...s('input')}
          ref={setInputRef}
          type='file'
          id={name}
          tabIndex={tabIndex}
          multiple={shouldAllowMultiple}
          accept={accept}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </label>
    );
  }
}

const dialogStyles = StyleSheet({
  bodyText: {
    ...TEXTS.CONTENT.INPUT_TEXT_REGULAR
  },
  cancelButton: {
    marginRight: PADDINGS.M,
    lineHeight: '34px'
  },
  fileHeading: {
    ...TEXTS.HEADERS.HEADING_2,
    marginBottom: '12px',
    marginTop: '24px'
  },
  fileInputContainer: {
    width: '100%',
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
    padding: '9px 16px'
  },
  fileInputButton: {
    marginLeft: '8px'
  },
  buttonText: {
    color: COLORS.COAL_GREY
  },
  helperText: {
    height: '31px',
    marginTop: '5px',
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1
  },
  helperIcon: {
    marginRight: '5px'
  },
  helperMessage: {
    marginTop: '3px'
  },
  error: {
    color: COLORS.PRIMARY.RED
  },
  success: {
    color: COLORS.PRIMARY.GREEN
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

@styled(dialogStyles)
@autobind
class ResumeUploadDialog extends PureComponent {
  static propTypes = {
    previousFile: PropTypes.shape({
      filename: PropTypes.string,
      filesize: PropTypes.number
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    percentageComplete: PropTypes.string.isRequired
  };

  state = {
    file: null,
    success: false,
    error: false
  };

  onResumeClick() {
    const { onSubmit } = this.props;
    const { file, success } = this.state;

    if (success) {
      onSubmit(file);
    }
  }

  addFile(e) {
    const { previousFile } = this.props;

    const file = e.target.files[0];

    if (!file) {
      // If they hit cancel on the file dialog
      return;
    }

    const error =
      file.name !== previousFile.filename ||
      file.size !== previousFile.filesize;
    const success = !error;

    this.setState({
      file,
      success,
      error
    });
  }

  getFileSizeGB(filesize) {
    return (filesize / 1024 / 1024 / 1024).toFixed(2);
  }

  render() {
    const { styles: s, percent, onCancel, previousFile } = this.props;

    const { file, error, success } = this.state;

    return (
      <Dialog
        title={'Resume Upload'}
        top={Math.floor(window.innerHeight / 2 - 200) - 200}
        closeDialog={onCancel}
      >
        <p {...s('bodyText')}>
          This upload was previously aborted at {percent}
          %. You can resume the upload where it left off by resubmitting the
          same file.
        </p>
        <div>
          <h2 {...s('fileHeading')}>Previous File</h2>
          <div {...s('fileInputContainer')}>
            {`${previousFile.filename} (${this.getFileSizeGB(
              previousFile.filesize
            )}GB)`}
          </div>
          <h2 {...s('fileHeading')}>Re-upload</h2>
          <Box flexDirection={'row'}>
            <div {...s('fileInputContainer')}>
              {file
                ? `${file.name} (${this.getFileSizeGB(file.size)}GB)`
                : 'No file chosen'}
            </div>
            <FileUploadInput {...fileProps}>
              {(props) => (
                <InputContainer
                  {...props.inputProps}
                  {...props.dragEvents}
                  onChange={this.addFile}
                >
                  <DefaultButton
                    {...s('fileInputButton')}
                    backgroundColor={COLORS.STATES.IDLE}
                    onClick={props.triggerInput}
                  >
                    <span {...s('buttonText')}>Reconfirm File</span>
                  </DefaultButton>
                </InputContainer>
              )}
            </FileUploadInput>
          </Box>
          <div {...s('helperText', { error, success })}>
            {(error || success) && (
              <Box flexDirection={'row'} alignItems={''}>
                <Icon
                  {...s('helperIcon')}
                  type={error ? ICONS.WARNING : ICONS.TICK}
                />
                <span {...s('helperMessage')}>
                  {error && 'Files do not match. Please choose another file.'}
                  {success && 'Files match.'}
                </span>
              </Box>
            )}
          </div>

          <ButtonBar>
            <Box {...s('container')}>
              <span {...s('cancelButton')} onClick={onCancel}>
                <TextButton blue onClick={this.onCancel}>
                  Cancel
                </TextButton>
              </span>
              <DefaultButton
                red
                onClick={this.onResumeClick}
                disabled={!file || error}
              >
                Resume Upload
              </DefaultButton>
            </Box>
          </ButtonBar>
        </div>
      </Dialog>
    );
  }
}

export default ResumeUploadDialog;
