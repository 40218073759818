import { RecordWithId } from 'components/infinite-list';
import { useDraggable } from '@dnd-kit/core';
import React from 'react';
import { KanbanBoardColumnData } from 'components/kanban-board/kanban-board';
import { Criteria } from 'types/criteria';

export function KanbanBoardItem<
  ItemData extends RecordWithId,
  ColumnCriteria extends Criteria[]
>(props: {
  index: number;
  columnProps: KanbanBoardColumnData<ItemData, ColumnCriteria>;
  item: ItemData;
}) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: props.item.id,
    disabled: !props.columnProps.isDraggingEnabled(props.item),
    data: {
      item: props.item,
      columnProps: props.columnProps
    }
  });

  const style = {
    width: '100%'
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      data-intercom-target={`Appraisals Pipeline Item ${props.index}`}
    >
      {!isDragging
        ? props.columnProps.renderItem(props.item, props.index)
        : null}
    </div>
  );
}
