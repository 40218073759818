import React from 'react';

import { query, useModelActions } from '@rexlabs/model-generator';
import { RecordListScreen } from 'components/record-list-screen';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { useDialog } from 'hooks/use-dialog';
import reinzFormsModel from 'features/reinz-forms/data/reinz-forms';
import { useReinzFormsColumns } from '../../hooks/use-reinz-forms-columns';
import { useReinzFormsFilters } from '../../hooks/use-reinz-forms-filters';
import Box from '@rexlabs/box';

const reinzFormsQuery = query`{
  ${reinzFormsModel} {
    id
  }
}`;

function ReinzForms() {
  const { refreshLists } = useModelActions(reinzFormsModel);
  const editReinzForm = useDialog('editReinzForm');
  const columns = useReinzFormsColumns();
  const filtersConfig = useReinzFormsFilters();

  const whereabouts = useWhereaboutsWithViewpath();

  const { path } = whereabouts;

  // HACK: So we really need to work out what is going on here...
  // I've had to add this path check as changing from one shell screen
  // to another, causes the list to load again, and a read request.
  // So looks like this page is refreshing during the transient state.
  // This extra check makes sure the path is what we're after. If not
  // it clears out the ui.
  // https://app.shortcut.com/rexlabs/story/67425/react-lists-investigate-why-list-views-performs-api-requests-when-changing-to-another-react-screen
  if (!path?.includes('/apps/reinz/reinz_forms')) return null;

  return (
    <Box pb={25}>
      <RecordListScreen
        query={reinzFormsQuery}
        columns={columns}
        filtersConfig={filtersConfig}
        title={'REINZ Forms'}
        serviceName='ReinzForms'
        onItemClick={(item) => {
          editReinzForm.open({ reinzForm: item, onSuccess: refreshLists });
        }}
        inlinePagination
        screenProps={{
          roomForIntercom: false,
          maxWidth: 1500
        }}
        titleStyles={{
          padding: '0 0 30px'
        }}
      />
    </Box>
  );
}

export default ReinzForms;
