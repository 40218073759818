import React, { createContext, useContext, useMemo } from 'react';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { useAppointmentModule } from './appointments/appointment-module-provider';
import { useContactModule } from './contacts/contact-module-provider';
import { useContractsModule } from './contracts/contract-module-provider';
import { useKeyActivityModule } from 'features/custom-reporting/modules/key-activity/key-activity-module-config';
import { useKeyLocationsModule } from './key-locations/key-locations-module-config';
import { useListingModule } from './listings/listing-module-provider';
import { useAppraisalModule } from './appraisals/appraisal-module-provider';

import {
  emptyConfig,
  ModuleConfigItem,
  ModuleName
} from './module-config-types';
import {
  useGroupConfig,
  GroupDetails,
  GroupConfig,
  useGroupForModuleName
} from './group-config';
import { useCommissionBySaleModule } from 'features/custom-reporting/modules/commission-by-sale/commission-by-sale-module-provider';
import { usePropertyModule } from 'features/custom-reporting/modules/properties/property-module-provider';
import { useContractConditionsModule } from 'features/custom-reporting/modules/contract-conditions/contract-conditions-module-provider';
import { useCommissionByAgentModule } from 'features/custom-reporting/modules/commission-by-agent/commission-by-agent-module-provider';
import { useLeadsModule } from 'features/custom-reporting/modules/leads/leads-module-provider';
import { useProjectModule } from 'features/custom-reporting/modules/projects/projects-module-provider';
import { useProjectStageModule } from 'features/custom-reporting/modules/project-stages/project-stage-module-provider';
import { useOabModule } from './oab/oab-module-provider';
import { useTenancyApplicationModule } from './tenancy-applications/tenancy-applications-module-provider';

interface ModuleConfigContext {
  currentModule: ModuleConfigItem;
  modules: Record<string, ModuleConfigItem>;
  groups: () => GroupConfig;
  groupForModule: (moduleName: ModuleName) => GroupDetails | undefined;
}

const mockGroup: GroupDetails = {
  labelPlural: 'Hellos',
  labelSingular: 'Hello',
  modules: [],
  groupId: 'hello',
  Icon: 'a'
};

const ModuleConfigContext = createContext<ModuleConfigContext>({
  currentModule: emptyConfig,
  modules: {},
  groups: () => ({ group: mockGroup }),
  groupForModule: () => mockGroup
});

export function ModuleProvider({ moduleName, children }) {
  const ww = useWhichWordFactory();

  const contractsLocale = ww('contract').toLocaleLowerCase();
  const appraisalLocale = ww('valuation').toLocaleLowerCase();

  const appointmentModule = useAppointmentModule();
  const appraisalModule = useAppraisalModule();
  const commissionBySaleModule = useCommissionBySaleModule();
  const commissionByAgentModule = useCommissionByAgentModule();
  const contactsModule = useContactModule();
  const contractsModule = useContractsModule();
  const contractConditionsModule = useContractConditionsModule();
  const leadsModule = useLeadsModule();
  const listingModule = useListingModule();
  const keyActivityModule = useKeyActivityModule();
  const keyLocationsModule = useKeyLocationsModule();
  const projectsModule = useProjectModule();
  const projectStageModule = useProjectStageModule();
  const propertyModule = usePropertyModule();
  const oabModule = useOabModule();
  const tenancyApplicationModule = useTenancyApplicationModule();
  const contractsModuleKey = `${contractsLocale}s`;
  const appraisalModuleKey = `${appraisalLocale}s`;
  const contractConditionsModuleKey = `${contractsLocale}_conditions`;

  const modules = useMemo(
    () => ({
      appointments: appointmentModule,
      [appraisalModuleKey]: appraisalModule,
      commission: commissionBySaleModule,
      commission_by_agent: commissionByAgentModule,
      contacts: contactsModule,
      [contractsModuleKey]: contractsModule,
      [contractConditionsModuleKey]: contractConditionsModule,
      key_activity: keyActivityModule,
      key_locations: keyLocationsModule,
      leads: leadsModule,
      listings: listingModule,
      projects: projectsModule,
      project_stages: projectStageModule,
      properties: propertyModule,
      oabs: oabModule,
      tenancy_applications: tenancyApplicationModule
    }),
    [
      appointmentModule,
      appraisalModuleKey,
      appraisalModule,
      commissionBySaleModule,
      commissionByAgentModule,
      contactsModule,
      contractsModuleKey,
      contractsModule,
      contractConditionsModuleKey,
      contractConditionsModule,
      keyActivityModule,
      keyLocationsModule,
      leadsModule,
      listingModule,
      projectsModule,
      projectStageModule,
      propertyModule,
      oabModule,
      tenancyApplicationModule
    ]
  );

  const moduleConfigValue = useMemo(
    () => ({
      currentModule: modules[moduleName],
      modules,
      groups: useGroupConfig,
      groupForModule: useGroupForModuleName
    }),
    [modules, moduleName]
  );

  return (
    <ModuleConfigContext.Provider
      children={children}
      value={moduleConfigValue}
    />
  );
}

export function useModuleConfig(moduleName: ModuleName) {
  const config = useContext(ModuleConfigContext);
  return config.modules?.[moduleName];
}

export function useAllModuleConfigs() {
  return useContext(ModuleConfigContext)?.modules;
}

export function useCurrentModuleConfig() {
  return useContext(ModuleConfigContext)?.currentModule;
}

export function useAllGroupConfig() {
  return useContext(ModuleConfigContext)?.groups();
}
export function useGroupForModule(moduleName: ModuleName) {
  const groupForModule = useContext(ModuleConfigContext)?.groupForModule;
  return groupForModule?.(moduleName);
}

export function useModuleContext() {
  return useContext(ModuleConfigContext);
}
