import React from 'react';
import _ from 'lodash';

import { TooltipProps } from '@rexlabs/tooltip';

import { DialogTooltipConsumer } from 'view/context/tooltip';
import { withPopoutContext } from 'view/context/popout';

import VividPopout, { VividPopoutStateful } from './vivid-popout';

export type PopoutProps = TooltipProps;

function PopoutComponent({
  context,
  ...props
}: PopoutProps & { context: Document }) {
  const { isOpen, setOpen, ...statefulProps } = props;
  return (
    <DialogTooltipConsumer>
      {(value) => {
        const target = _.get(value, 'target.current') || context;
        return (
          <>
            {'isOpen' in props ? (
              <VividPopout
                target={target}
                placement='bottom'
                isOpen={props.isOpen}
                {...props}
              />
            ) : (
              <VividPopoutStateful
                target={target}
                placement='bottom'
                {...statefulProps}
              />
            )}
          </>
        );
      }}
    </DialogTooltipConsumer>
  );
}

export const Popout = withPopoutContext(PopoutComponent);
