import { query, Id } from '@rexlabs/model-generator';
import activitiesModel from 'data/models/entities/activities-model';

export function getActivitiesQuery(id: Id) {
  return query`{
    ${activitiesModel} (
      order_by: ${{ system_ctime: 'desc' }},
      criteria: ${[
        {
          name: 'parent_object_type',
          value: 'lead'
        },
        {
          name: 'parent_object_id',
          value: id
        },
        {
          name: 'type_id',
          value: 'lead_processed'
        }
      ]}) {
      id
      related
    }
  }`;
}
