import React, { Fragment } from 'react';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';

import Confirmation from 'view/dialogs/confirmation';
import { Body } from 'components/text/body';
import env from 'shared/utils/config';
import { PADDINGS } from 'src/theme';

import sessionModel from 'data/models/custom/session';

function EnvironmentDialog({ closeDialog, session }) {
  const app = session.isRosieAccount ? 'Rosie' : 'Rex';
  return (
    <Confirmation
      closeDialog={closeDialog}
      title={`${app} ${env.ENVIRONMENT}`}
      width={500}
      height={200}
      onConfirm={() => {
        window.location.href = 'https://app.rexsoftware.com';
      }}
      cancelText={`Keep using ${env.ENVIRONMENT}`}
      confirmText={'Switch to Live'}
    >
      {env.ENVIRONMENT === 'Alpha' ? (
        <Fragment>
          <Body dark>
            You are using the {app} Alpha, which is an early testing ground for
            upcoming {app} changes.
          </Body>
          <Box mt={PADDINGS.S}>
            <Body dark>
              Using the Alpha version of {app} is <b>not recommended</b> as you
              are likely to experience bugs and stability issues not present in
              the live version of {app}.
            </Body>
          </Box>
        </Fragment>
      ) : env.ENVIRONMENT === 'Beta' ? (
        <Fragment>
          <Body dark>
            You are using the {app} Beta, which is a preview of upcoming changes
            to {app}.
          </Body>
          <Box mt={PADDINGS.S}>
            <Body dark>
              For a more stable experience, we <b>strongly recommend</b> using
              the live version of {app} for day-to-day usage.
            </Body>
          </Box>
        </Fragment>
      ) : null}
    </Confirmation>
  );
}

export default withModel(sessionModel)(EnvironmentDialog);
