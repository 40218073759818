import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'substantiations';

const actionCreators = {
  archiveSubstantiation: {
    request: (payload) => api.post('Substantiations::archive', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  recoverArchivedSubstantiation: {
    request: (payload) =>
      api.post('Substantiations::recoverFromArchive', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getArchivedSubstantiations: {
    request: (payload) =>
      api.post('Substantiations::search', {
        criteria: [
          {
            name: 'related_contact.id',
            type: 'in',
            value: payload.ids
          },
          {
            name: 'system_record_state',
            value: 'archived'
          }
        ]
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getActiveSubstantiations: {
    request: (payload) =>
      api.post('Substantiations::search', {
        criteria: [
          {
            name: 'related_contact.id',
            type: 'in',
            value: payload.ids
          },
          {
            name: 'system_record_state',
            value: 'active'
          }
        ]
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const substantiationsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default substantiationsModel;
