import { useMemo } from 'react';
import { Criteria } from 'types/criteria';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { usePermissions } from 'hooks/use-permissions';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  ProjectsReportQuery,
  projectsReportQueryDefaultVariables
} from './projects-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'project_name',
  'type.text',
  'adr_locality',
  'adr_suburb_or_town',
  'adr_postcode',
  'developer_name',
  'system_owner_user.name'
];

export function useProjectModule() {
  const region = useRegion();

  const { projects: projectCustomFields } = useCustomFields();

  const projectModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.projects,
      moduleLabel: 'Projects',
      exportPrivilege: 'exporting.projects',
      queryConfig: {
        graphQlQuery: ProjectsReportQuery,
        queryVariables: projectsReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'name',
            direction: 'asc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Project',
          children: makeEntity({
            entity: 'project',
            prefix: '',
            customFields: projectCustomFields,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'system_owner_user_id',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: 'Project creation',
              isDefault: true
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'system_owner_user_id',
              label: 'Record owner',
              isDefault: true
            }
          ]
        }
      }
    }),
    [region, projectCustomFields]
  );
  return projectModuleConfig;
}
