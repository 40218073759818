import { useMemo } from 'react';

import { useRegion } from 'hooks/use-region';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  LeadsReportQuery,
  leadsReportQueryDefaultVariables
} from './leads-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';
import { useWhichWordFactory } from 'hooks/use-which-word';

export const defaultColumns = [
  'lead_type.text',
  'assignee.name',
  'system_ctime',
  'lead_status.text',
  'system_completed_time',
  'time_to_first_action',
  'time_to_completion',
  'contact',
  'contact.emails',
  'contact.phones',
  'listing',
  'property-property_address'
];

export function useLeadsModule() {
  const region = useRegion();
  const {
    contacts: contactCustomFields,
    listings: listingCustomFields,
    properties: propertyCustomFields
  } = useCustomFields();

  const ww = useWhichWordFactory();

  const leadsModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.leads,
      moduleLabel: 'Leads',
      exportPrivilege: 'exporting.leads',
      queryConfig: {
        graphQlQuery: LeadsReportQuery,
        queryVariables: leadsReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'system_ctime',
            direction: 'descending' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Lead',
          children: makeEntity({
            entity: 'leads',
            prefix: ''
          })
        },
        {
          headerName: 'Contact',
          children: makeEntity({
            entity: 'contact',
            prefix: 'contact',
            subresource: 'contact',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },

        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'last_30_days',
      defaultUserFieldPreference: 'lead.assignee_id',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: 'Lead creation',
              isDefault: true
            },
            {
              fieldName: 'lead.system_completed_time',
              label: 'Lead completion'
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'lead.assignee_id',
              label: 'Assignee',
              isDefault: true
            }
          ]
        }
      }
    }),
    [region, contactCustomFields, listingCustomFields, propertyCustomFields]
  );
  return leadsModuleConfig;
}
