/*
|-------------------------------------------------------------------------------
| Shell Scroll Sync
|-------------------------------------------------------------------------------
|
| Allows the Classic Rex header to collapse/uncollapse
|
*/

import uiClassicHeader from 'data/models/custom/ui-classic-header';

const ScrollSyncBridge = {
  actionCreators: {
    aboveHeader: () =>
      uiClassicHeader.actionCreators.updateHeaderCollapse(false),
    belowHeader: () => uiClassicHeader.actionCreators.updateHeaderCollapse(true)
  }
};

export default ScrollSyncBridge;
