import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS, TEXTS } from 'theme';
import { Form, FormField, withForm } from 'view/components/form';
import { Grid, Column } from 'shared/components/grid';
import Box from '@rexlabs/box';
import { Select } from 'view/components/input/select';
import SaveCancelButtonBar from 'view/components/button-bar/save-cancel';

import Dialog from 'view/components/dialog';
import Option from 'view/components/input/select/options/core';

const formStyles = StyleSheet({
  info: {
    ...TEXTS.CONTENT.INFORMATIVE_SUBTEXT_1,
    color: COLORS.GREY_MEDIUM,
    paddingBottom: PADDINGS.L
  },
  infoLink: {
    color: COLORS.PRIMARY.BLUE
  },
  columnHeading: {
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1,
    paddingBottom: PADDINGS.S,
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const rowStyles = StyleSheet({
  row: {
    paddingTop: PADDINGS.XS,
    paddingBottom: PADDINGS.XS
  },
  container: {
    backgroundColor: '#FFFFFF',
    ...TEXTS.SETTINGS_NAVIGATION.MENU_ITEM,
    padding: '4px',
    paddingLeft: '10px',

    '&:hover': {
      backgroundColor: '#CAE8F8'
    }
  },
  label: {
    ...TEXTS.CONTENT.INFORMATIVE_SUBTEXT_1,
    fontStyle: 'none'
  },
  preview: {
    ...TEXTS.CONTENT.INFORMATIVE_SUBTEXT_1,
    color: COLORS.GREY_MEDIUM,
    fontStyle: 'none'
  }
});

function getValue(options, expectedValue) {
  return options.find((option) => option === expectedValue) || '';
}

const columnMatchingForm = {
  name: 'pafColumnMatching',
  validate: (values) => {
    const valuesArray = Object.values(values).filter((value) => value !== '');
    const valuesSet = new Set(valuesArray);

    if (valuesArray.length !== valuesSet.size) {
      return {
        postcode: 'Please assign columns uniquely.'
      };
    } else {
      return {};
    }
  },
  mapPropsToValues: (props) => {
    const { sourceColumns, destinationColumns } = props;

    return destinationColumns.reduce((prev, next) => {
      prev[next.id] = getValue(sourceColumns.columns, next.label);
      return prev;
    }, {});
  },
  handleSubmit: (values, { props }) => {
    const { onSave, pafUpload } = props;

    return new Promise((resolve) => {
      const valueEntries = Object.entries(values);
      const returnValue = valueEntries
        .filter((entry) => entry[0] !== 'file' && entry[1] !== '')
        .map((entry) => {
          return {
            destination: entry[0],
            source: entry[1]
          };
        });

      pafUpload.setColumns({
        columnMappings: returnValue
      });

      onSave(returnValue);
      // closeDialog();
      resolve();
    });
  },
  validateOnChange: false,
  validateOnBlur: false
};

@styled(rowStyles)
@autobind
class MappingRow extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired
  };

  getPreviews(index) {
    const { previews } = this.props;

    return previews.map((row) => row[index]).join(', ');
  }

  getOptions() {
    const { row } = this.props;

    row.value = row.id;
    row.name = row.id;
    row.label = row.id;

    return row.options.map((columnName, index) => ({
      label: columnName,
      text: columnName,
      value: columnName,
      preview: this.getPreviews(index)
    }));
  }

  render() {
    const { styles: s, row } = this.props;
    const { label, name } = row;

    return (
      <div {...s('row')}>
        {/* TODO: Move these into classes */}
        <Box
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'flex-end'
          }}
        >
          <Column
            style={{
              height: '34px',
              justifyContent: 'center',
              width: '33.33333%'
            }}
          >
            <Box alignItems={'center'}>
              <span {...s('label')}>{label}</span>
            </Box>
          </Column>
          <Column width={8}>
            <FormField
              name={name}
              Input={Select}
              inputProps={{
                options: this.getOptions(),
                Option: (props) => {
                  const { label, preview } = props.option;
                  return (
                    <Option {...props}>
                      <Box {...s('container')}>
                        <p>{label}</p>
                        <p {...s('preview')}>{preview}</p>
                      </Box>
                    </Option>
                  );
                }
              }}
            />
          </Column>
        </Box>
      </div>
    );
  }
}

@withForm(columnMatchingForm)
@styled(formStyles)
@autobind
class ColumnMatchingForm extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    sourceColumns: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.string).isRequired,
      previews: PropTypes.arrayOf(PropTypes.any).isRequired
    }).isRequired,
    destinationColumns: PropTypes.arrayOf(PropTypes.any).isRequired
  };

  render() {
    const {
      styles: s,
      sourceColumns,
      destinationColumns,
      pafColumnMatching
    } = this.props;

    const rows = destinationColumns.map((dest) => {
      dest.options = sourceColumns.columns;

      // To satisfy formik
      dest.text = dest.label;
      dest.name = dest.label;

      return dest;
    });

    return (
      <Form>
        <Grid>
          <Column width={12}>
            <span {...s('info')}>
              To ensure the PAF file data is uploaded correctly, please select
              which columns in the file match the target columns. For
              definitions of the target columns see the{' '}
              <span {...s('infoLink')}>help article</span>
            </span>
            {/* should this be a link or something? at least link styling */}
          </Column>
          <Column width={4}>
            <span {...s('columnHeading')}>target column</span>
          </Column>
          <Column width={8}>
            <span {...s('columnHeading')}>paf file</span>
          </Column>
          <Column width={12}>
            {rows.map((row) => {
              return (
                <MappingRow
                  key={row.name}
                  row={row}
                  previews={sourceColumns.previews}
                />
              );
            })}
          </Column>
        </Grid>
        {/* Dialog save cancel button bar */}
        <SaveCancelButtonBar
          onSave={pafColumnMatching.submitForm}
          // submitButton={() => <DefaultButton onClick={onSave}>Start Upload</DefaultButton>}
        />
      </Form>
    );
  }
}

class ColumnMatchingDialog extends PureComponent {
  render() {
    const { closeDialog } = this.props;
    return (
      <Dialog
        title={'Column Matching'}
        top={Math.max(window.innerHeight / 2 - 225, 0)}
        width={540}
        closeDialog={closeDialog}
      >
        <ColumnMatchingForm {...this.props} />
      </Dialog>
    );
  }
}

export default ColumnMatchingDialog;
