/**
 * PLEASE NOTE
 * This Component is a copy of vivid-tooltip.js. We decided to split this component in two, as
 * it didn't make a lot of sense to have the one component for two very different use cases.
 * This should also make it easier to solve problems with tooltips/popouts as we progress, as
 * the logic only affects one not the other.
 * Changes include:
 *   - Styles from popout.tsx have been moved to this component
 *   - Where naming refered to tooltip, it has been renamed to popout
 *   - Removed hover actions, as out popouts open on a click event
 */
import React, { Component } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import Tether, { PLACEMENTS } from '@rexlabs/tether';
import { keyframes, styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { withState } from 'recompose';

import Arrow from '@rexlabs/tooltip/module/arrow';

const enterAnimation = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
});

const exitAnimation = keyframes({
  '0%': {
    opacity: 1
  },
  '100%': {
    opacity: 0
  }
});

const tetherStyles = StyleSheet({
  opening: {
    animation: `${enterAnimation} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
  },
  closing: {
    animation: `${exitAnimation} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
  },

  content: {
    transition: 'none',
    transformOrigin: 'center'
  },

  contentTop: {
    transform: 'translateY(-.8rem)'
  },

  contentBottom: {
    transform: 'translateY(.8rem)'
  },

  contentLeft: {
    transform: 'translateX(-.8rem)'
  },

  contentRight: {
    transform: 'translateX(.8rem)'
  }
});

const defaultStyles = StyleSheet({
  popout: {
    display: 'block',
    minWidth: '10rem',
    maxWidth: 'none',
    boxSizing: 'border-box',
    margin: 0,
    background: 'white',
    boxShadow: '0 0 21px rgba(0,0,0,0.3)',
    position: 'relative',
    zIndex: 3,
    color: '#404040'
  },

  clickable: {
    cursor: 'pointer',
    position: 'relative',
    // NOTE: We want to the clickable to be as tall as the child it is wrapping
    // IF we don't do this, and the container holding the popout is large, the
    // popout may not tether to the correct target.
    height: 'fit-content',

    '&:active:focus': {
      outline: 'none'
    }
  },

  overlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 2,
    left: 0,
    top: 0
  },
  container: {
    display: 'inline-block'
  }
});

@styled(defaultStyles)
@autobind
class Popout extends Component {
  /** Enum values for the `placement` prop. */
  static PLACEMENTS = PLACEMENTS;

  _refs = {};
  clickCaptured = false;

  state = {
    isClosing: false
  };

  static propTypes = {
    /**
     * Content to render inside the popout.
     */
    content: types.func,
    /**
     * Tether placement.
     */
    placement: types.oneOf(Object.values(PLACEMENTS)),
    /**
     * Offset arrow to move it closer towards the middle of the popout. Any CSS unit.
     */
    arrowMargin: types.string,
    /**
     * Popout distance from button.
     */
    distance: types.oneOfType([types.string, types.number]),
    /**
     * Offset the popout on its primary axis
     */
    offset: types.string,
    /**
     * Where or not to automatically reposition
     */
    autoFlip: types.bool,
    /**
     * Whether or not the Tether is about to close. Allows you to hook into
     * Tether to display closing animations.
     */
    isClosing: types.bool,
    /**
     * Whether or not to stop propagation when clicking on the popout button
     * Useful if you dont want your popout triggering other click events
     * higher in the DOM hierarchy!
     */
    stopPropagation: types.bool,
    /**
     * Function that will be run when the popout open/closed/closing state
     * changes. Receives an object with `isOpen` and `isClosing` properties.
     */
    onChange: types.func
  };

  static defaultProps = {
    arrowMargin: '1.4rem',
    distance: '10px',
    offset: '0px',
    closeDuration: 250,
    tetherStyles,
    stopPropagation: false,
    Arrow,
    onChange: _.noop
  };

  constructor() {
    super();
    this.contentProps = {
      toggle: this.togglePopout,
      open: this.openPopout,
      close: this.closePopout
    };
    this.interactionSuspended = false;
    this.popoutRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // only register the event listener when the popout is opened
    // todo: this would be replaced with a useEffect if changed to a functional component
    if (this.props.isOpen && !prevProps.isOpen) {
      // detect click outside the iframe
      document.body.addEventListener('pointerdown', this.onBodyMouseDown);
      // detect click inside the iframe
      if (window.Rex2FrameWindow) {
        window.Rex2FrameWindow.addEventListener(
          'pointerdown',
          this.onBodyMouseDown
        );
      }
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('pointerdown', this.onBodyMouseDown);
  }

  preventBodyMouseDown = false;
  onBodyMouseDown(e) {
    if (!this.clickCaptured && this.props.isOpen) {
      this.closePopout();
    }

    this.clickCaptured = false;
  }

  onEnterPress(e, callback) {
    if (e.key === 'Enter') {
      this.togglePopout(e);
    }
    if (callback) callback(e);
  }

  innerContentClick() {
    this.clickCaptured = true;
  }

  closePopout(event) {
    const { setOpen, closeDuration, onChange } = this.props;
    if (!this.state.isClosing || this.props.isOpen) {
      const setClosingToFalse = () => {
        setOpen(false);
        onChange({ isOpen: false, isClosing: false, event });
        this.setState(() => ({ isClosing: false }));
        document.body.removeEventListener('mousedown', this.onBodyMouseDown);
      };

      clearTimeout(this.closeTimer);
      if (closeDuration > 0) {
        this.setState(() => ({ isClosing: true }));
        onChange({ isOpen: false, isClosing: true, event });
        this.closeTimer = setTimeout(setClosingToFalse, closeDuration);
      } else {
        setClosingToFalse();
      }
    }
  }

  openPopout(event) {
    clearTimeout(this.closeTimer);
    this.setState(
      () => ({ isClosing: false }),
      () => {
        this.interactionSuspended = true;
        this.suspendedTimer = setTimeout(() => {
          this.interactionSuspended = false;
        }, 500);
        this.props.setOpen(true);
        this.props.onChange({ isOpen: true, isClosing: false, event });
      }
    );
  }

  togglePopout(event) {
    if (this.props.isOpen) {
      this.closePopout(event);
    } else {
      this.openPopout(event);
    }
  }

  onKeyPress(e) {
    const onlyChild = React.Children.only(this.props.children);
    this.onEnterPress(e, _.get(onlyChild, 'props.onKeyPress'));
  }

  onClick(e) {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }

    // HACK: this is pretty Rex specific, when having a popout within an
    // iframe the iframe body will receive the click after the iframe content,
    // triggering the `onBodyMouseDown` which immediately closes the popout
    this.preventBodyMouseDown = true;
    setTimeout(() => {
      this.preventBodyMouseDown = false;
    }, 50);

    const onlyChild = React.Children.only(this.props.children);
    if (_.get(onlyChild, 'props.onClick')) {
      onlyChild.props.onClick(e);
    }

    if (this.props.isOpen) {
      this.closePopout(e);
    }

    if (!this.props.isOpen) {
      clearTimeout(this.closeTimer);
      clearTimeout(this.mouseOutTimer);
      this.openPopout(e);
    }
  }

  renderContent({ placement }) {
    const { Content, styles: s } = this.props;

    return (
      <div ref={this.popoutRef} onPointerDown={this.innerContentClick}>
        <div {...s('popout')}>
          {_.isString(Content) ? (
            <Content {...this.contentProps} />
          ) : (
            Content({ ...this.contentProps, placement })
          )}
        </div>
      </div>
    );
  }

  renderChild({ ref }) {
    const { children, styles: s, isOpen } = this.props;
    const onlyChild = React.Children.only(children);
    return (
      <div
        ref={ref}
        tabIndex={0}
        {...s('clickable')}
        style={{
          zIndex: isOpen ? 3 : 0
        }}
        onKeyPress={this.onKeyPress}
        onClick={this.onClick}
      >
        {onlyChild}
      </div>
    );
  }

  render() {
    const {
      styles: s,
      style,
      className,
      children,
      arrowMargin,
      placement,
      offset,
      autoFlip,
      Arrow,
      Content,
      tetherStyles,
      isOpen,
      distance,
      ...rest
    } = this.props;

    const { isClosing } = this.state;

    return (
      <>
        {isOpen ? (
          <Tether
            {...rest}
            offset={offset}
            styles={tetherStyles}
            arrowMargin={arrowMargin}
            distance={distance}
            placement={placement}
            Content={this.renderContent}
            autoFlip={autoFlip}
            Arrow={Arrow}
            isClosing={isClosing}
            openOn='CLICK'
            closeOn='CLICK'
          >
            {this.renderChild}
          </Tether>
        ) : (
          this.renderChild({})
        )}
      </>
    );
  }
}

export default Popout;
const VividPopoutStateful = withState('isOpen', 'setOpen', false)(Popout);
export { VividPopoutStateful };
