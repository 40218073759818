import React, {
  ComponentType,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import { SecurityUserRights } from 'data/models/entities/contacts';
import useResizeObserver, { ObservedSize } from 'use-resize-observer';

export interface InfiniteListProps<ItemData extends RecordWithId> {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: ItemData[];
  loadNextPage: () => void;
  renderItem: (item: ItemData, index?: number) => ReactNode;
  itemHeight: number;
  itemSpacing?: number;
  itemGutter?: number;
  renderFooter?: () => ReactNode;
}

export type RecordWithId = {
  id: string | number;
  security_user_rights?: SecurityUserRights[];
};

export type InfiniteListItemProps<ItemData extends RecordWithId> = {
  data: ItemData;
};
export type InfiniteListItemComponent<ItemData extends RecordWithId> =
  ComponentType<InfiniteListItemProps<ItemData>>;

const InfiniteList = memo(function InfiniteList<ItemData extends RecordWithId>({
  hasNextPage,
  isNextPageLoading,
  items,
  renderItem,
  loadNextPage,
  itemHeight,
  itemSpacing = 4,
  itemGutter = 12,
  renderFooter
}: InfiniteListProps<ItemData>) {
  const footerOffset = renderFooter ? 1 : 0;
  const spinnerOffset = hasNextPage ? 1 : 0;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = items.length + spinnerOffset + footerOffset;

  const footerIndex = items.length - 1 + spinnerOffset + footerOffset;
  const spinnerIndex = items.length - 1 + spinnerOffset;

  const infiniteLoaderRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<ObservedSize>({
    width: undefined,
    height: undefined
  });

  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: (size) => requestAnimationFrame(() => setSize(size))
  });

  const parentHeight = size.height || 0;

  useEffect(() => {
    ref(infiniteLoaderRef.current?.parentElement as HTMLDivElement);
  }, [infiniteLoaderRef]);

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => null : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !hasNextPage || index < items.length;

  return (
    <div
      ref={infiniteLoaderRef}
      style={{
        width: '100%',
        height: parentHeight - 5,
        paddingTop: itemSpacing
      }}
    >
      {parentHeight ? (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={1}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
              height={parentHeight - 5}
              itemSize={itemHeight + itemSpacing * 2}
              ref={ref}
            >
              {({ index, style }) => {
                return (
                  <div
                    style={{
                      ...style,
                      paddingBottom: itemSpacing,
                      paddingTop: itemSpacing,
                      paddingLeft: itemGutter,
                      paddingRight: itemGutter
                    }}
                  >
                    {index === spinnerIndex && !items[index] ? (
                      <Box
                        height={itemHeight}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Spinner small dark />
                      </Box>
                    ) : renderFooter &&
                      index === footerIndex &&
                      !items[index] ? (
                      renderFooter()
                    ) : (
                      renderItem(items[index], index)
                    )}
                  </div>
                );
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      ) : null}
    </div>
  );
});

export { InfiniteList };
