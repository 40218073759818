import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@rexlabs/box';

import { COLORS } from 'src/theme';
import Widget, { Placeholder } from 'view/components/widgets/widget';
import Tag from 'view/components/tag/index';

interface TagsWidgetProps {
  tags: [
    {
      id: number;
      tag: string;
    }
  ];
  editTags: BoxProps['onClick'];
  tagsLoading: boolean;
}

function TagsWidget({
  tags,
  editTags,
  tagsLoading
}: TagsWidgetProps): ReactNode {
  return (
    <Widget
      heading={'Tags'}
      iconButtonOnClick={editTags}
      isLoading={tagsLoading}
    >
      {tags && tags.length ? (
        <Box
          flexDirection='row'
          flexWrap='wrap'
          data-testid={'TagsWidget.TagList'}
        >
          {tags.map(({ tag, id }) => (
            <Tag
              truncate
              color={COLORS.WHITE}
              backgroundColor={COLORS.BLUE_REX}
              key={id}
              data-testid={'TagsWidget.Tag'}
            >
              {tag}
            </Tag>
          ))}
        </Box>
      ) : (
        <Placeholder onClick={editTags}>Add Tag</Placeholder>
      )}
    </Widget>
  );
}

export default TagsWidget;
