import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import DefaultButton from './default-button';

const styles = StyleSheet({
  filterContainer: {
    width: '100%',
    maxWidth: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 400
  },

  filteredBy: {
    textTransform: 'uppercase',
    paddingRight: '4px',
    fontSize: '12px'
  },

  name: {
    fontWeight: 700,
    textTransform: 'capitalize'
  },

  value: {
    textTransform: 'capitalize'
  }
});

interface FilterButtonProps {
  title: string;
  displayCriterias?: { readableField: string; readableValue: string }[];
}

const FilterButton = ({ title, displayCriterias }: FilterButtonProps) => {
  const s = useStyles(styles);
  return (
    <DefaultButton blue>
      {!displayCriterias ? (
        title
      ) : (
        <span {...s('filterContainer')}>
          <span {...s('filteredBy')}>Filtered by:</span>
          {displayCriterias.map(({ readableField, readableValue }, i) => (
            <span key={i}>
              {i > 0 && <span>, </span>}
              <b {...s('name')}>{readableField}</b>{' '}
              <span {...s('value')}>{readableValue}</span>
            </span>
          ))}
        </span>
      )}
    </DefaultButton>
  );
};

export default FilterButton;
