import React from 'react';

import { RecordScreen } from 'components/_experiment/record-screen';

import { LeadsListScreen } from './leads-list-screen';

export function LeadsRecordScreen() {
  return <RecordScreen RecordListScreen={LeadsListScreen} />;
}

// Exporting a default here to use with lazy import in the shell routes, found in app.js
export default LeadsRecordScreen;
