import { useMemo } from 'react';
import { useRegion } from 'hooks/use-region';
import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  KeyLocationsReportQuery,
  KeyLocationsReportQueryDefaultVariables
} from './key-locations-report.query';
import { OrderByDirectionType, ModuleConfigItem } from '../module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';
import { useWhichWordFactory } from 'hooks/use-which-word';

export const defaultColumns = [
  'property-property_address',
  'listing',
  'label',
  'location.name'
];

export function useKeyLocationsModule() {
  const region = useRegion();
  const { listings: listingCustomFields, properties: propertyCustomFields } =
    useCustomFields();

  const ww = useWhichWordFactory();

  const keyLocationsModuleConfig = useMemo(
    () => ({
      ...moduleNameMap.key_locations,
      moduleLabel: 'Key Locations',
      exportPrivilege: 'exporting.listings',
      queryConfig: {
        graphQlQuery: KeyLocationsReportQuery,
        queryVariables: KeyLocationsReportQueryDefaultVariables,
        defaultSort: [
          {
            direction: 'asc' as OrderByDirectionType,
            name: 'label'
          }
        ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Key locations',
          children: makeEntity({
            entity: 'keyLocation',
            prefix: ''
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      topLevelFilters: {}
    }),
    [region, listingCustomFields, propertyCustomFields]
  );
  return keyLocationsModuleConfig;
}
