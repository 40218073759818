import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { Body } from 'components/text/body';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';

class ConfirmCampaignStatusUpdateDialog extends PureComponent {
  render() {
    const { onLoad, closeDialog, onConfirm, onCancel } = this.props;
    return (
      <Dialog
        title='Change Campaign Type'
        height={250}
        width={400}
        onLoad={onLoad}
        closeDialog={closeDialog}
      >
        <Body dark>
          If you change the campaign type you will loose any changes to tecord
          statuses.
        </Body>
        <ButtonBar>
          <TextButton
            blue
            onClick={() => {
              onCancel();
              closeDialog();
            }}
          >
            Cancel
          </TextButton>
          <DefaultButton
            onClick={() => {
              onConfirm();
              closeDialog();
            }}
          >
            Okay
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default ConfirmCampaignStatusUpdateDialog;
