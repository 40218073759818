import { Generator } from 'shared/utils/models';

export interface PropertyCategoryItem {
  id?: string;
  text?: string;
  value: string;
  label: string;
}

export interface PropertyCategoriesModel {
  errors: null;
  fetch: Function;
  items: PropertyCategoryItem[];
  realStatus: string;
  status: string;
}

const TYPE = 'propertyCategory';
export default new Generator(TYPE).createSystemListModel();
