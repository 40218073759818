import { TextInput } from '@rexlabs/text-input';

import campaignStatus from 'data/models/system-lists/campaign-status';
import campaignType from 'data/models/system-lists/campaign-type';

import Checkbox from 'view/components/input/checkbox';
import { ValueListSelect } from 'view/components/input/select';

import { FiltersConfig } from 'components/record-list-screen/types';

export const campaignFilters: FiltersConfig = {
  width: 'small',
  columns: 1,
  groups: [
    [
      {
        name: 'name',
        label: 'campaign name',
        Input: TextInput
      },
      {
        name: 'type_id',
        label: 'campaign type',
        Input: ValueListSelect,
        inputProps: {
          models: [campaignType]
        }
      },
      {
        name: 'status_id',
        label: 'campaign status',
        Input: ValueListSelect,
        inputProps: {
          models: [campaignStatus]
        }
      },
      {
        name: 'system_record_state',
        label: 'show archived campaigns',
        // TODO: We currently don't show checkboxes with inline labels - something we need to add.
        Input: Checkbox
      }
    ]
  ]
};
