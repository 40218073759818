import React, { useCallback, useState } from 'react';
import { TextInput } from '@rexlabs/text-input';

import { ICONS } from 'shared/components/icon';

import contactsModel from 'data/models/entities/contacts';
import { Form, FormField, ReactForms } from 'view/components/form';
import { IconButton } from 'view/components/button';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import RecordSelect from 'view/components/custom-fields/record-select';
import { Heading } from 'components/_experiment/record-block/heading';

function AddContactPopoutContent({ onAdd, close }) {
  return (
    <PaddingBox width='290px'>
      <Heading>Add Related Contact</Heading>
      <ReactForms
        handleSubmit={async (...args) => {
          await onAdd?.(...args);
          close();
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormField
              name='contact'
              Input={RecordSelect}
              inputProps={{ models: [contactsModel] }}
              label='contact'
            />
            <FormField name='type' Input={TextInput} label='relationship' />
            <SaveCancelButtonBar
              saveLabel='Add Contact'
              isLoading={isSubmitting}
              onSave={submitForm}
              onCancel={close}
            />
          </Form>
        )}
      </ReactForms>
    </PaddingBox>
  );
}

export function AddContactPopout({ onAdd }) {
  const [isOpen, setOpen] = useState(false);

  const Content = useCallback(
    (props) => <AddContactPopoutContent {...props} onAdd={onAdd} />,
    [onAdd]
  );
  return (
    <Popout Content={Content} onChange={({ isOpen }) => setOpen(isOpen)}>
      <IconButton red circle Icon={ICONS.ADD_MEDIUM} data-force-show={isOpen} />
    </Popout>
  );
}
