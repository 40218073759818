import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

export const LIMIT = 10;

const TYPE = 'thirdPartyServiceXero';

const actionCreators = {
  getInvoicesToSync: {
    request: (payload) => {
      return api.post('ThirdPartyServiceXero::getInvoicesToSync', payload);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getPaymentsToSync: {
    request: (payload) => {
      return api.post('ThirdPartyServiceXero::getPaymentsToSync', payload);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  syncInvoices: {
    request: (payload) => {
      return api.post('ThirdPartyServiceXero::syncInvoicesToXero', payload);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  syncPayments: {
    request: (payload) => {
      return api.post('ThirdPartyServiceXero::syncPaymentsFromXero', payload);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getAccountMapping: {
    request: () => {
      return api.post('ThirdPartyServiceXero::getAccountMapping');
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  setAccountMapping: {
    request: (payload) => {
      return api.post('ThirdPartyServiceXero::setAccountMapping', {
        data: payload
      });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getSyncTotals: {
    request: (payload) => {
      return api.post('ThirdPartyServiceXero::getSyncTotals', payload);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const thirdPartyServiceXeroModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default thirdPartyServiceXeroModel;
