import React, { PureComponent } from 'react';
import { Grid } from 'shared/components/grid';
import Spinner from 'shared/components/spinner';

class ButtonBarSpinner extends PureComponent {
  render() {
    return (
      <Grid>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '30px'
          }}
        >
          <Spinner dark small />
        </div>
      </Grid>
    );
  }
}

export default ButtonBarSpinner;
