import { gql } from 'hooks/use-graphql-query';

export const TenancyApplication = gql`
  fragment TenancyApplication on TenancyApplication {
    agreement_length_months
    application_status {
      id
      text
    }
    bond_amount_paid
    bond_amount_required
    bond_deposit_registration_number
    bond_deposit_type {
      id
      text
    }
    conditions
    date_accepted
    date_communicated
    date_received
    date_unsuccessful
    end_date
    has_pets
    holding_deposit_amount
    id
    lease_type {
      id
      text
    }
    notes
    num_of_dependents
    offer_amount
    offer_amount_period {
      id
      text
    }
    start_date
    system_accepted_set_at
    system_affordability_percentage
    system_communicated_set_at
    system_created_user {
      id
      name
    }
    system_ctime
    system_modified_user {
      id
      name
    }
    system_modtime
    system_owner_user {
      id
      name
    }
    agent {
      id
      name
    }
    system_total_income
    system_unsuccessful_set_at
  }
`;

export const Tenant = gql`
  fragment Tenant on TenancyApplicationTenant {
    dob
    employment_status {
      id
      text
    }
    guarantors {
      contact {
        ...Contact
      }
    }
    id
    is_primary
    net_income
    net_income_period {
      id
      text
    }
  }
`;
