import _ from 'lodash';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { styled } from '@rexlabs/styling';
import { push, parseUrlToRoute } from '@rexlabs/whereabouts';
import { APP_MENU } from 'theme/legacy/components';

import SearchSectionHeader from 'view/components/navigation/app-search/sections/section-header';
import SearchSectionViewAll from 'view/components/navigation/app-search/sections/section-view-all';
import SearchSectionViewInList from 'view/components/navigation/app-search/sections/section-view-in-list';
import SearchSectionBack from 'view/components/navigation/app-search/sections/section-back';
import { SearchSectionOptions } from 'view/components/navigation/app-search/base';
import { getServiceTypeForOptionType } from '../utils';
import { HEIGHTS } from 'theme';

@styled(APP_MENU.ROWS)
@autobind
class ProviderOptionScreen extends Component {
  handleViewInList(event) {
    const { type, viewAllInListValue } = this.props;
    const serviceType = getServiceTypeForOptionType(type);

    // HACK: Closes the menu after clicking
    const globalSearchInput = [...document.querySelectorAll('input')].find(
      (i) => i.name.includes('global_search')
    );

    if (globalSearchInput) {
      globalSearchInput.blur();
    }

    const url = `/${serviceType}/#viewstate=${viewAllInListValue}`;
    if (event && event.metaKey) {
      window.open(url, '_blank', 'noopener,noreferrer');
      return;
    }

    const route = {
      config: parseUrlToRoute(url)
    };
    return push(route);
  }

  render() {
    const {
      options,
      isScoped,
      type,
      scopedType,
      onShowAll,
      onBack,
      totalCount,
      optionCount,
      Option,
      styles: s,
      // Prevent sending this prop further
      ...props
    } = this.props;

    return (
      <Box
        flexDirection={'column'}
        flex={'1'}
        {...s('container')}
        data-testid={`appSearch.option.${type}`}
      >
        {isScoped && (
          <SearchSectionBack
            key='back'
            onClick={onBack}
            {...s.with()('needsclick')}
          >
            {_.startCase(_.lowerCase(type))}
          </SearchSectionBack>
        )}
        <Box
          flexDirection={'column'}
          justifyContent={'space-between'}
          mt={isScoped ? HEIGHTS.APP_MENU.HEADING : 0}
        >
          <SearchSectionHeader
            isScoped={isScoped}
            onClick={_.noop}
            type={type}
          />
          <SearchSectionOptions
            {...props}
            options={options}
            Option={Option}
            {...s('optionContainer', isScoped && 'viewInList')}
          />
          <SearchSectionViewAll
            className='needsclick'
            totalCount={totalCount}
            type={type.toLowerCase()}
            scopedType={scopedType}
            onClick={onShowAll(type)}
          />
          <SearchSectionViewInList
            optionCount={optionCount}
            totalCount={totalCount}
            isScoped={isScoped}
            onClick={this.handleViewInList}
          />
        </Box>
      </Box>
    );
  }
}

export default ProviderOptionScreen;
