/*
|-------------------------------------------------------------------------------
| Section Options
|-------------------------------------------------------------------------------
|
| The following component renders an array of options using their custom Option component.
|
*/

import Types from 'prop-types';
import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { OptionType } from '../utils';
import OptionEventHandler from '../components/select-option-event-handler';

class SearchSectionOptions extends PureComponent {
  static propTypes = {
    /** Option component to use when rendering. */
    Option: Types.any.isRequired,
    /** Collection of opportunity entities */
    options: OptionType.isRequired,
    /** Index of the actively highlighted option */
    activeIndex: Types.number,
    /** Current search term */
    term: Types.string,
    /** Fires when an Options is hovered: function (event) {} */
    onHover: Types.func,
    /** Fires when a button is clicked: function (option, event) {} */
    onSelect: Types.func,
    /** Helper to call when an Option needs to scroll into view */
    scrollIntoView: Types.func
  };

  static defaultProps = {
    activeIndex: -1
  };

  render() {
    const {
      Option,
      options,
      activeIndex,

      onHover,
      onSelect,
      scrollIntoView,
      ...props
    } = this.props;

    return (
      <Box {...props}>
        {options.map((option) => {
          const isActive = activeIndex.toString() === option.index.toString();
          return (
            <OptionEventHandler
              key={option.value}
              index={option.index}
              option={option}
              onSelect={onSelect}
              onHover={onHover}
              scrollIntoView={scrollIntoView}
            >
              <Option option={option} isActive={isActive} />
            </OptionEventHandler>
          );
        })}
      </Box>
    );
  }
}

export default SearchSectionOptions;
