import { Body } from 'components/text/body';
import React from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'src/view/components/button';
import PaddingBox from 'src/view/components/padding-box';
import { CommissionWorksheetItem } from '../types/commission-worksheet';
import { useDialog } from 'hooks/use-dialog';
import dayjs from 'dayjs';
interface LockWorksheetFooterProps {
  worksheet: CommissionWorksheetItem;
  lockWorksheet: () => void;
}

const LockWorksheetFooter = ({
  worksheet,
  lockWorksheet
}: LockWorksheetFooterProps) => {
  const lockWorksheetDialog = useDialog('lockWorksheetDialog');

  return (
    <PaddingBox
      light
      flexDirection='row'
      justifyContent='flex-end'
      alignItems='center'
      spacing={25}
    >
      <Body dark regular>
        {worksheet.is_locked && worksheet.locked_by_user
          ? `Worksheet locked by ${worksheet.locked_by_user._name} ${dayjs(
              worksheet.locked_at
            ).format('D MMM YYYY h:m A')}`
          : 'Lock this commission worksheet to prevent any further changes'}
      </Body>
      <DefaultButton
        dark
        IconLeft={() => (
          <Icon
            type={ICONS.PADLOCK}
            style={{ display: 'flex', width: 18, marginLeft: 5 }}
          />
        )}
        onClick={() => lockWorksheetDialog.open({ worksheet, lockWorksheet })}
        isDisabled={worksheet.is_locked}
      >
        Lock Worksheet
      </DefaultButton>
    </PaddingBox>
  );
};

export default LockWorksheetFooter;
