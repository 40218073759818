import _ from 'lodash';
import { checkUserHasPermission } from 'utils/rights';

export const matchesActiveRoute = (activeRoute, matches) => {
  let matched = false;

  if (_.isArray(matches)) {
    matched = matches.some((match) => {
      return new RegExp(match).exec(activeRoute) !== null;
    });
  }

  if (_.isString(matches)) {
    matched = new RegExp(matches).exec(activeRoute) !== null;
  }

  return matched;
};

export const getHref = (item, session) => {
  const { href, hrefs } = item;
  if (hrefs) {
    const found = hrefs.find(({ accessRights }) =>
      checkUserHasPermission((dotNotation) => _.get(session, dotNotation))(
        accessRights
      )
    );
    if (!found) {
      return undefined;
    }
    return found.href;
  }
  return href;
};
