import React from 'react';
import classnames from 'classnames';

import { TemplateProps } from '../';

function TemplateSentStatus({ row, col }: TemplateProps) {
  const data = row[col.id];
  return (
    <div>
      <ul className='tag-list no-margin'>
        <li className={classnames('tag-item', { sent: !!data, draft: !data })}>
          {!data ? 'draft' : 'sent'}
        </li>
      </ul>
    </div>
  );
}

export default TemplateSentStatus;
