import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import dayjs from 'dayjs';
import DefaultValue from './default';
import { withRegion } from 'src/hocs/with-region';

import { ICONS } from 'shared/components/icon';

@withRegion
@autobind
class AppraisalsValue extends PureComponent {
  getOption() {
    const { option, region } = this.props;

    const status = _.startCase(_.get(option, 'data.appraisal_state'));

    let date = _.get(option, 'data.appraisal_date');

    if (status === 'Archived') {
      date = _.get(option, 'data.archived_date');
    }

    /**
     * Fallback to system created time encase either appraisal or archived dates are null.
     */
    let formattedDate = dayjs
      .unix(_.get(option, 'data.system_ctime'))
      .format('D MMM YYYY');

    if (date) {
      formattedDate = dayjs(date).format('D MMM YYYY');
    }

    const type = region.isEU ? 'Valuation' : 'Appraisal';
    const label = `${status} ${type} from ${formattedDate}`;

    return {
      ...option,
      label
    };
  }

  render() {
    return (
      <DefaultValue
        {...this.props}
        Icon={ICONS.APPRAISAL}
        option={this.getOption()}
      />
    );
  }
}

export default AppraisalsValue;
