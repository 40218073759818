import React, { ComponentProps } from 'react';
import Box from '@rexlabs/box';

import MergeTemplateSelect from 'view/components/input/select/merge-template-select';
import { InfoTooltip } from 'view/components/tooltip';

import { SubText } from 'components/text/sub-text';
import { PADDINGS } from 'shared/theme';

export function LeadsAutoResponderTemplateSelector({
  value,
  onChange,
  propertyId
}: ComponentProps<typeof MergeTemplateSelect>) {
  return (
    <>
      <Box flex={1} flexDirection='row' mb={PADDINGS.XS} alignItems='center'>
        <SubText>auto-response</SubText>
        <InfoTooltip
          content={
            'When a new lead is received with this listing attached, the auto-response will be sent to them from the listing agent within a few minutes. If a lead is manually created or the listing is attached after the lead is received, the auto-response will not be sent.'
          }
        />
      </Box>

      <MergeTemplateSelect
        value={value}
        modules={['listing']}
        medium='email'
        onChange={onChange}
        enableOnTheFly={true}
        propertyId={propertyId}
      />
    </>
  );
}
