import React from 'react';
import { get } from 'lodash';

import { Value } from 'components/_experiment/value';

import { Heading } from '../heading';
import { Grid, Column } from '../grid';
import { MAP } from './map';

export function View({ values, group }) {
  return (
    <>
      <Heading>{group.label}</Heading>
      <Grid columns={2}>
        {Object.keys(group.fields)?.map?.((fieldId) => {
          const field = group.fields[fieldId];
          const fieldMap = MAP[field.field_type?.id] || MAP.text;

          const ValueComponent = fieldMap?.Value || Value;
          return (
            <Column width={fieldMap?.width || 1}>
              <ValueComponent
                label={field.label}
                value={get(values, `__customFields.${field.id}`)}
                vertical={fieldMap?.width === 2}
              />
            </Column>
          );
        })}
      </Grid>
    </>
  );
}
