import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Hint } from 'components/text/hint';

import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import List, {
  RexHeadingCell,
  RexBasicCell,
  RexPrimaryTextCell
} from 'view/components/list';
import { COLORS } from 'theme';
import _ from 'lodash';
import dayjs from 'dayjs';

const defaultStyles = StyleSheet({
  emptyContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    minHeight: '50px',
    width: '100%'
  },

  viewLink: {
    color: COLORS.PRIMARY.SAND_LIGHT,
    cursor: 'pointer',
    fontWeight: 600
  }
});

const listStyles = StyleSheet({
  separator: {
    display: 'none'
  },

  wrapItem: {
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

@styled(defaultStyles)
@autobind
class MetricRelatedDataList extends PureComponent {
  renderEmptyState() {
    const { styles: s, isLoading } = this.props;
    return (
      <Box
        {...s('emptyContainer')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {isLoading ? (
          <Spinner small dark />
        ) : (
          <Hint semibold>No metrics found.</Hint>
        )}
      </Box>
    );
  }

  renderHeader() {
    return (
      <Box flexDirection='row' width='100%'>
        <RexHeadingCell flex={1}>identifier</RexHeadingCell>
        <RexHeadingCell width={80}>value</RexHeadingCell>
        {/* <RexHeadingCell flex={1}>metric rule</RexHeadingCell> */}
        <RexHeadingCell width={170}>date written</RexHeadingCell>
        <RexHeadingCell width={120} />
      </Box>
    );
  }

  renderItem(item) {
    const { styles: s, onViewClick, onDuplicate } = this.props;

    return (
      <Box width='100%' flexDirection='row'>
        <RexPrimaryTextCell
          flex={1}
          actionMenuItems={[
            { label: 'Duplicate', onClick: () => onDuplicate(item) }
          ]}
        >
          {_.get(item, 'metric')}
        </RexPrimaryTextCell>
        <RexBasicCell width={80}>{_.get(item, 'value')}</RexBasicCell>
        {/* <RexBasicCell flex={1}>{_.get(item, 'rule.name')}</RexBasicCell> */}
        <RexBasicCell width={170}>
          {dayjs(_.get(item, 'recorded_at') * 1000).format(
            'DD MMM YYYY, h:mma'
          )}
        </RexBasicCell>
        <RexBasicCell width={120}>
          <a {...s('viewLink')} onClick={(e) => onViewClick(item, e)}>
            view details
          </a>
        </RexBasicCell>
      </Box>
    );
  }

  render() {
    const { ...props } = this.props;
    return (
      <List
        styles={listStyles}
        Header={this.renderHeader}
        EmptyView={this.renderEmptyState}
        renderItem={this.renderItem}
        {...props}
      />
    );
  }
}

export default MetricRelatedDataList;
