import React from 'react';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import { IconTooltip } from 'view/components/tooltip';

type IconTooltipProps = React.ComponentProps<typeof IconTooltip>;

export default function InfoTooltip(props: IconTooltipProps) {
  const { small } = props;
  return (
    <IconTooltip
      color={COLORS.PRIMARY.INFORMATIVE}
      TooltipIcon={small ? ICONS.INFO_SMALL : ICONS.INFO}
      {...props}
    />
  );
}
