import React from 'react';

import { ListingItem } from 'data/models/entities/listings';

import { formatAddress } from 'utils/formatters';

import { ListingPreviewPopout } from 'features/listings/popouts/listing-preview-popout';

import { BasePopoutCellProps, PopoutCell } from './popout-cell';

interface ListingCellProps extends BasePopoutCellProps {
  value?: Partial<ListingItem> | null;
}

export function ListingCell(props: ListingCellProps) {
  const { value, ...rest } = props;
  const listingId = value?.id;

  return (
    <PopoutCell
      RecordPopout={ListingPreviewPopout}
      recordId={listingId}
      data-testid='RecordList.ListingCell'
      {...rest}
    >
      {formatAddress(value?.property)}
    </PopoutCell>
  );
}
