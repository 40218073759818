import React from 'react';
import { COLORS } from 'theme';
import Tag from 'view/components/tag';

interface TagProps {
  children: React.ReactNode;
  onClick?: (clickProps: any) => void;
}

export function WarningTag({ children, ...props }: TagProps) {
  return (
    <Tag
      color={COLORS.PRIMARY.GREY_DARK}
      backgroundColor={COLORS.PRIMARY.YELLOW}
      roundedEdges
      noMargin
      {...props}
    >
      {children}
    </Tag>
  );
}
