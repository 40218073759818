import React from 'react';
import dayjs from 'dayjs';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useModelActions } from '@rexlabs/model-generator';

import { ICONS } from 'shared/components/icon';
import { withDialog } from 'shared/hocs/with-dialog';

import projectsModel from 'data/models/entities/projects';

import { COLORS, PADDINGS } from 'theme';

import EditCampaign from 'view/dialogs/campaigns/edit';
import ImagePlaceholder from 'view/components/image-placeholder';
import Tag from 'view/components/tag';
import PermalinkPopout from 'view/components/popout/permalink-popout';

import { Info } from 'components/_experiment/record-title-block/info';
import { Container } from 'components/_experiment/record-title-block/container';
import { Heading } from 'components/_experiment/record-title-block/heading';

const CampaignSvg: any = ICONS.CAMPAIGN;
const LockSvg: any = ICONS.PADLOCK;

const defaultStyles = StyleSheet({
  container: {
    minWidth: '940px'
  },

  defaultIcon: {
    height: '80px',
    width: 'auto',
    opacity: 0.1
  },

  estateLogo: {
    width: '160px',
    height: '80px',
    objectFit: 'contain'
  },

  developerText: {
    marginTop: '20px'
  },

  recordIcon: {
    width: '100px',
    height: '100px',
    objectFit: 'contain'
  },

  svgIcon: {
    width: '25px',
    height: '25px',
    color: COLORS.PRIMARY.SAND_LIGHT
  }
});

export const CampaignTitleBlock = withDialog(EditCampaign, {
  propName: 'editCampaign'
})(function CampaignTitleBlock({ data, editCampaign }) {
  const s = useStyles(defaultStyles);
  const { refreshItem } = useModelActions(projectsModel);
  const dateFormat = 'D MMM YYYY';
  const startTime = dayjs(data?.start_date).format(dateFormat);
  const endTime = dayjs(data?.end_date).format(dateFormat);

  // TODO: We need to add a more comprehensive suite of components for the TitleBlock.
  return (
    <Container padding={25} flexDirection='row' {...s('container')}>
      <Box flex={1} flexDirection='column' alignItems='flex-start'>
        <Box flexDirection='row' alignItems='center'>
          <CampaignSvg {...s('svgIcon')} />
          <Heading
            onEdit={() =>
              editCampaign.open({
                id: data.id,
                callback: () => refreshItem({ id: data.id })
              })
            }
          >
            {data?.name}
          </Heading>
        </Box>
        <Info>{`${data?.type?.text} • ${startTime} until ${endTime}`}</Info>
        <Box pt={PADDINGS.XXS}>
          <Tag
            backgroundColor={COLORS.PRIMARY.INFORMATIVE}
            color={COLORS.PRIMARY.WHITE}
          >
            {data?.status?.text}
          </Tag>
        </Box>
      </Box>
      <Box
        pr={PADDINGS.M}
        flexDirection='column'
        height='100px'
        justifyContent='end'
      >
        <Box flexDirection='row'>
          <LockSvg {...s('svgIcon')} />
          <PermalinkPopout id={data?.id} serviceName='campaigns' />
        </Box>
      </Box>
      <Box
        width={100}
        height={100}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <ImagePlaceholder Icon={CampaignSvg} />
      </Box>
    </Container>
  );
});
