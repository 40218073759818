import React, { PureComponent, Fragment } from 'react';

import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import Box from '@rexlabs/box';
import PaddingBox from 'view/components/padding-box';
import { ReactForms, FormField, Form } from 'view/components/form';
import { Select } from 'view/components/input/select';
import { SubText } from 'components/text/sub-text';
import { Link } from 'components/text/link';
import { Hint } from 'components/text/hint';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'theme';
import IDVerificationDialog, {
  TYPES as ID_VERIFICATION_TYPES
} from 'view/dialogs/verifications/id-verification';
import { withDialog } from 'shared/hocs/with-dialog';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { withModel } from '@rexlabs/model-generator';
import amlChecksModel from 'data/models/entities/contact-aml-checks';
import accountSettingsComplianceModel from 'data/models/custom/account-settings-compliance';
import adminWorkflowsModel from 'data/models/entities/admin-workflows';
import workflowInstancesModel from 'data/models/entities/workflow-instances';
import ConfirmWorkflowDialog from 'view/dialogs/workflow/confirm-workflow';
import _ from 'lodash';
import dayjs from 'dayjs';
import { createValidationRules } from 'shared/utils/form';
import WarningBox from 'view/components/warning-box';

const defaultStyles = StyleSheet({
  errorIcon: {
    color: COLORS.PRIMARY.RED
  },
  infoIcon: {
    color: COLORS.PRIMARY.SAND
  }
});

@withModel(accountSettingsComplianceModel)
@withModel(amlChecksModel)
@withModel(adminWorkflowsModel)
@withModel(workflowInstancesModel)
@withErrorDialog
@withDialog(ConfirmWorkflowDialog, { propName: 'confirmWorkflow' })
@withDialog(IDVerificationDialog, { propName: 'idVerification' })
@styled(defaultStyles)
@autobind
class AMLCheckDialog extends PureComponent {
  state = {
    isStartingWorkflow: false
  };

  onChangeToExternalClick() {
    this.props.setManual(false);
  }

  onGoToIDClick() {
    const { contact, contactIdentityVerifications } = this.props;
    this.props.idVerification.open({
      contactId: _.get(contact, 'id'),
      type: _.get(contact, 'type', ID_VERIFICATION_TYPES.PERSON),
      onClose: contactIdentityVerifications.refreshList
    });
  }

  handleFailedWorkflow(error) {
    this.setState({ isStartingWorkflow: false });
    this.props.errorDialog.open(error);
  }

  onStartWorkflowClick() {
    const { context, adminWorkflows, workflowId, confirmWorkflow } = this.props;

    this.setState({ isStartingWorkflow: true }, () => {
      adminWorkflows
        .fetchItem({ id: workflowId })
        .then(({ data }) => {
          const executeWorkflow = this.executeWorkflow(workflowId);

          const formDefinition = _.get(
            data,
            'definition.required_input.form',
            null
          );

          if (formDefinition) {
            confirmWorkflow.open({
              context,
              formSchema: formDefinition,
              onSubmit: executeWorkflow,
              onClose: () => {
                this.setState({ isStartingWorkflow: false });
              },
              workflowName: _.get(data, 'name'),
              workflowDescription: _.get(data, 'description')
            });
          } else {
            executeWorkflow().catch(this.handleFailedWorkflow);
          }
        })
        .catch(this.handleFailedWorkflow);
    });
  }

  executeWorkflow(workflowId) {
    return (formValues) => {
      return new Promise((resolve, reject) => {
        const { context, workflowInstances, closeDialog } = this.props;

        return workflowInstances
          .execute({ id: workflowId, context, formValues })
          .then((response) => {
            closeDialog();
            resolve(response);
          })
          .catch(reject);
      });
    };
  }

  handleSubmit(values) {
    const {
      amlChecks,
      callback,
      closeDialog,
      errorDialog,
      contact,
      contactIdentityVerifications
    } = this.props;

    const amlRecord = _.get(amlChecks, 'list.items.0');
    const method = _.get(amlRecord, 'id')
      ? amlChecks.updateItem
      : amlChecks.createItem;

    return method({
      id: _.get(amlRecord, 'id'),
      data: {
        id: _.get(amlRecord, 'id'),
        contact_id: _.get(contact, 'id'),
        check_type_id: 'manual',
        status_id: values.status_id
      }
    })
      .then(({ data }) => {
        const verified = values.status_id === 'complete';

        return amlChecks
          .manualVerifyToggle({
            id: _.get(contactIdentityVerifications, 'list.items.0.id'),
            verified
          })
          .then(() => {
            const wait = [];
            if (callback) {
              wait.push(callback(data));
            }

            return Promise.all(wait);
          })
          .then(closeDialog)
          .catch(errorDialog.open);
      })
      .catch(errorDialog.open);
  }

  isUnverified() {
    const { contactIdentityVerifications } = this.props;
    return !_.get(contactIdentityVerifications, 'list.items.0.verified_at');
  }

  hasPermissions() {
    const { session } = this.props;

    return session.checkUserHasPermission(
      'contacts.change_manual_aml_check_status'
    );
  }

  hasWorkflowAddon() {
    const { session } = this.props;

    return (
      session.checkUserHasPermission('addon.workflows') ||
      session.checkUserHasPermission('addon.system_workflows')
    );
  }

  renderUnverified() {
    const { styles: s } = this.props;
    return (
      this.isUnverified() && (
        <Box p={'20px 20px 0 20px'} flexDirection={'row'} alignItems={'center'}>
          <ICONS.WARNING {...s('errorIcon')} />
          <Box pl={'5px'} pr={'10px'}>
            <SubText informative>
              Please verify ID before updating AML status
            </SubText>
          </Box>
          <Link regular onClick={this.onGoToIDClick}>
            Go to ID Verification
          </Link>
        </Box>
      )
    );
  }

  renderModified() {
    const { amlChecks } = this.props;
    const amlRecord = _.get(amlChecks, 'list.items.0');

    const modified = _.get(amlRecord, 'updated_or_submitted_at');
    const modifiedAt = modified
      ? dayjs
          .unix(_.get(amlRecord, 'updated_or_submitted_at'))
          .format('DD/MM/YY')
      : null;

    const modifiedBy = modified
      ? `${_.get(amlRecord, 'updated_or_submitted_by_user.name')}`
      : null;

    return (
      modified && (
        <Box pt={'10px'}>
          <SubText normal>
            Last modified by {modifiedBy} {modifiedAt}
          </SubText>
        </Box>
      )
    );
  }

  renderExpired() {
    const { amlChecks } = this.props;
    const amlRecord = _.get(amlChecks, 'list.items.0');

    const isExpired = _.get(amlRecord, 'status.id') === 'is_expired';

    return (
      isExpired && (
        <WarningBox
          message={'This AML submission has expired - please resubmit'}
          prefix={'Note'}
        />
      )
    );
  }

  renderNoPermissions() {
    const { isStartingWorkflow } = this.state;
    const { styles: s, workflowId, workflowName } = this.props;

    return (
      !this.isUnverified() &&
      !this.hasPermissions() && (
        <Box p={'20px 20px 0 20px'}>
          {this.hasWorkflowAddon() && workflowId ? (
            <PaddingBox light>
              <Box pb={'10px'}>
                <Hint dark semibold normal>
                  You do not have permission to manually change the AML status.
                </Hint>
                <Hint dark semibold normal>
                  Please run this workflow &#34;
                  {workflowName}
                  &#34;.
                </Hint>
              </Box>
              <DefaultButton
                isDisabled={isStartingWorkflow}
                onClick={this.onStartWorkflowClick}
                IconLeft={ICONS.WAND}
              >
                Start Workflow
              </DefaultButton>
            </PaddingBox>
          ) : (
            <Box flexDirection={'row'} alignItems={'center'}>
              <ICONS.INFO {...s('infoIcon')} />
              <Box pl={'5px'}>
                <Hint dark regular normal>
                  You do not have permission to manually change the AML status
                </Hint>
              </Box>
            </Box>
          )}
        </Box>
      )
    );
  }

  render() {
    const { closeDialog, amlChecks, contact } = this.props;

    const disabled = this.isUnverified() || !this.hasPermissions();

    const integration = _.find(
      window.Rex2IFrame.contentWindow.r2.u.api.getAccountInfo(
        'third_party_extensions'
      ),
      (extensions) => extensions.service_type_id === 'smart_search'
    );

    const amlRecord = _.get(amlChecks, 'list.items.0');
    const isCompany = amlRecord
      ? _.get(amlRecord, 'contact.type') === 'company'
      : _.get(contact, 'type') === 'company';

    return (
      <Fragment>
        {this.renderExpired()}
        {this.renderUnverified()}
        {this.renderNoPermissions()}
        <ReactForms
          initialValues={{
            status_id: _.get(amlRecord, 'status.id', 'incomplete')
          }}
          handleSubmit={this.handleSubmit}
          validate={createValidationRules({ status_id: 'required' })}
        >
          {({ submitForm, isSubmitting }) => (
            <PaddingBox>
              <Form>
                <FormField
                  name={'status_id'}
                  label={'aml check status'}
                  Input={Select}
                  inputProps={{
                    disabled,
                    options: [
                      { value: 'incomplete', label: 'Incomplete' },
                      { value: 'complete', label: 'Complete' }
                    ]
                  }}
                />
                {this.renderModified()}
                <ButtonBar isLoading={isSubmitting}>
                  {!isCompany && integration && !disabled && (
                    <DefaultButton
                      left
                      light
                      onClick={this.onChangeToExternalClick}
                    >
                      Change to external check
                    </DefaultButton>
                  )}
                  <TextButton blue onClick={closeDialog}>
                    Cancel
                  </TextButton>
                  <DefaultButton red onClick={submitForm} isDisabled={disabled}>
                    Save
                  </DefaultButton>
                </ButtonBar>
              </Form>
            </PaddingBox>
          )}
        </ReactForms>
      </Fragment>
    );
  }
}

export default AMLCheckDialog;
