import React, { PureComponent } from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import { TEXTS, FONT } from 'theme';

@styled(
  StyleSheet({
    container: {
      padding: '1.5rem 1rem',
      height: '44px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      ...TEXTS.CONTENT.INPUT_TEXT_MEDIUM
    },

    reportingContainer: {
      height: '3.6rem',
      paddingLeft: '1.6rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontSize: '12px',
      color: '#212529',
      width: '100%'
    }
  })
)
class RexCell extends PureComponent {
  render() {
    const { styles: s, children, reporting } = this.props;

    return (
      <div
        {...s({
          reportingContainer: reporting,
          container: !reporting
        })}
      >
        {children || (reporting ? '\u2014' : '')}
      </div>
    );
  }
}

export default RexCell;
