import _ from 'lodash';
import { formatNumber } from 'shared/utils/formatters';
import { contractsModel } from 'features/listings/data';

export function mapContractToOption(contract, region) {
  const amount = _.get(contract, 'detail_sale_price_or_lease_pa')
    ? `${_.get(region, 'financial.currency.symbol')}` +
      `${formatNumber(_.get(contract, 'detail_sale_price_or_lease_pa'))}, `
    : '';

  const address = _.get(contract, 'listing.property.system_search_key');

  const purchtenants = _.get(
    contract,
    'related.listing_contract_purchtenants',
    []
  ).map((p) => _.get(p, 'purchtenant.name'));
  const names = purchtenants.length ? purchtenants.join(', ') : 'No purchaser';

  return {
    value: contract.id,
    label: `${amount}${names} - ${address}`,
    labelMeta: {
      amount,
      names,
      address
    },
    data: contract,
    model: contractsModel
  };
}
