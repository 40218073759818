import { Criteria } from 'types/criteria';
import { DataGridColumn } from 'features/custom-reporting/components/data-grid';

import { RegionCode } from 'hooks/use-region';

import { DateRangeSelectValue } from '../utils/date-ranges';

export type ReportColumn = DataGridColumn & {
  subresource?: string;
  withRegions?: RegionCode[];
  customFieldId?: string;
  customFieldResource?: string;
};

export type ReportColumnConfig = ReportColumn[];

export interface FieldDefinition {
  fieldName?: string;
  label: string;
  getCriteria?: (value: any) => Criteria[];
  isDefault?: boolean;
}

type GraphQlQueryDefinition = string;
type QueryVariablesDefinition = Record<string, any>;

export interface QueryConfig {
  graphQlQuery: GraphQlQueryDefinition;
  queryVariables: QueryVariablesDefinition;
  defaultSort?: OrderBy[];
}

interface OrderBy {
  direction: 'asc' | 'desc' | 'ascending' | 'descending';
  name: string;
}

export type OrderByDirectionType = OrderBy['direction'];

export interface ModuleConfigItem {
  // the module ID as defined on the BACK END
  moduleId: string;
  moduleLabel: string;
  moduleName: ModuleName;
  shortLabel?: string;
  exportPrivilege: string;
  queryConfig: QueryConfig;
  defaultReportHeading?: string;
  columns: ReportColumnConfig; // these columns might come from a hook
  defaultVisibleColumns: string[];
  defaultDatePreset?: DateRangeSelectValue;
  defaultUserFieldPreference?: string;
  defaultDateFieldPreference?: string;
  // Potentially temporary config to limit the appointments report to
  // appointments that aren't private or cancelled
  reportScopeCriteria?: Criteria[];
  topLevelFilters: {
    date?: {
      availableFields: FieldDefinition[];
    };
    user?: { availableFields: FieldDefinition[] };
    location?: {
      availableFields: FieldDefinition[];
    };
  };
}

export const emptyConfig: ModuleConfigItem = {
  moduleId: '',
  moduleName: '',
  moduleLabel: '',
  exportPrivilege: '',
  queryConfig: {
    graphQlQuery: '',
    queryVariables: []
  },
  columns: [],
  defaultVisibleColumns: [],
  topLevelFilters: {
    user: {
      availableFields: []
    },
    date: {
      availableFields: []
    }
  }
};

export type ModuleName = string;
