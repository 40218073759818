import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface InputProps extends BaseTextProps {
  light?: boolean;
  italic?: boolean;
  semibold?: boolean;
  regular?: boolean;
}

// TODO - this isn't only used for inputs so rename to something more appropriate
// We're using this as a header style in shell/src/features/chains/components/chain-link/chain-link-item.tsx
// and it appears also shell/src/view/dialogs/calendar/settings.js
// CH - https://app.clubhouse.io/rexlabs/story/56889/input-text-rename-to-match-how-we-re-using-it
export function Input({
  light,
  italic,
  semibold,
  regular,
  ...props
}: InputProps) {
  return (
    <Text
      is='p'
      {...props}
      type={map({
        INPUT: true,
        LIGHT: light,
        ITALIC: italic,
        SEMIBOLD: semibold,
        REGULAR: regular
      })}
    />
  );
}
