import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type RegionCode = 'AUS' | 'NZ' | 'UK' | 'FR' | 'AE';

export interface Region {
  code_alpha2: string;
  country: string;
  country_region: string;
  date: {
    short: string;
  };
  financial: {
    abn_label: string;
    currency: {
      code: string;
      decimal_sep: string;
      decimals: number;
      symbol: string;
      thousands_sep: string;
    };
    routing_code_label: string;
    tax: {
      name: string;
      primary_rate: string;
    };
  };
  phone: {
    area_code: string;
  };

  code: RegionCode;

  isAUS: boolean;
  isNZ: boolean;
  isUK: boolean;
  isFR: boolean;
  isAE: boolean;

  /**
   * Either UK or FR
   */
  isEU: boolean;
}

export function useRegion(): Region {
  const locale = useSelector(
    (state: any) => state?.session?.office_details?.locale
  );
  const code = locale?.code?.toUpperCase?.();

  return useMemo(
    () => ({
      ...locale,

      code,

      isAUS: !code || code === 'AUS',
      isNZ: code === 'NZ',
      isUK: code === 'UK',
      isFR: code === 'FR',
      isEU: ['UK', 'FR'].includes(code),
      isAE: code === 'AE'
    }),
    // eslint-disable-next-line
    [code]
  );
}
