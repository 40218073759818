import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { RowType } from '../';

interface Props {
  row: RowType;
  useCheckbox?: boolean;
  isChecked?: boolean;
  onRowClick: (RowType, Event) => any | void;
  className?: string;
  handleCheckboxUpdate?: (RowType, Event) => any | void;
  children?: ReactNode;
}

function ClassicListRow({
  row,
  isChecked,
  useCheckbox,
  onRowClick,
  className,
  handleCheckboxUpdate,
  children
}: Props) {
  return (
    <tr
      onClick={(e) => onRowClick(row, e)}
      onTouchStart={(e) => onRowClick(row, e)}
      className={className}
    >
      {useCheckbox && (
        <td
          className='checkbox'
          style={{ cursor: 'default' }}
          onClick={(e) => handleCheckboxUpdate?.(row, e)}
          onTouchStart={(e) => handleCheckboxUpdate?.(row, e)}
        >
          <button className={classnames('checkbox', { checked: isChecked })}>
            <span className='icon' />
          </button>
        </td>
      )}
      {children}
    </tr>
  );
}

export default ClassicListRow;
