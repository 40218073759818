import React, {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useState
} from 'react';
import { CustomFieldDefinitions } from 'features/custom-reporting/utils/custom-field-utils';

import { query, useEntityQuery } from '@rexlabs/model-generator';

import { SelectItem } from 'types/select';

import { CustomReportItem, customReports } from '../data/custom-reports-model';

import { useGetSelectedUsers } from 'features/custom-reporting/hooks/use-get-selected-users';
import { api } from 'shared/utils/api-client';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

interface AsyncReportDataProviderProps {
  reportId: string;
  children: React.ReactNode;
}
const SUPPORTED_CUSTOM_FIELD_SERVICES = [
  'properties',
  'contacts',
  'listings',
  'projects',
  'project_stages',
  'contracts',
  'tenancy_applications'
];
type CustomFieldService = (typeof SUPPORTED_CUSTOM_FIELD_SERVICES)[number];

interface AsyncReportDataValue {
  savedReportState: { selectedSavedReport: CustomReportItem | null };
  selectedUsersForPermissions: SelectItem[];
  customFields: Record<CustomFieldService, CustomFieldDefinitions>;
}

const AsyncReportDataContext = createContext<AsyncReportDataValue>({
  savedReportState: { selectedSavedReport: null },
  selectedUsersForPermissions: [],
  customFields: {}
});

const getCustomReportQuery = (reportId: string) =>
  reportId
    ? query`{
        ${customReports} (id: ${reportId}) {
          id
          related {
            listing_contract_purchtenants
          }
        }
      }`
    : undefined;

// This requests the saved report and provides selected users after we check the user's permissions.
export function AsyncReportDataProvider({
  reportId,
  children
}: AsyncReportDataProviderProps) {
  const customReportQuery = useMemo(
    () => getCustomReportQuery(reportId),
    [reportId]
  );
  const { data, status: savedReportStatus } = useEntityQuery(customReportQuery);
  const hasCustomFields = hasFeatureFlags('custom_reporting_custom_fields');

  const [customFields, setCustomFields] = useState({});
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  useEffect(() => {
    if (hasCustomFields) {
      Promise.all(
        SUPPORTED_CUSTOM_FIELD_SERVICES.map((service) =>
          api
            .post('AdminCustomFields::getFlatDefinition', {
              module_name: service
            })
            .then(({ data }) => {
              setCustomFields((customFields) => ({
                ...customFields,
                [service]: data.result
              }));
            })
        )
      )
        .then(() => setCustomFieldsLoading(false))
        .catch(console.error);
    } else {
      setCustomFieldsLoading(false);
    }
  }, []);

  const { selectedUsersForPermissions, status: selectedUsersStatus } =
    useGetSelectedUsers();

  const isDataLoaded = selectedUsersStatus === 'loaded' && !customFieldsLoading;

  const renderChildren = reportId
    ? savedReportStatus === 'loaded' && isDataLoaded
    : isDataLoaded;

  const asyncState = useMemo(
    () => ({
      savedReportState: { selectedSavedReport: data || null },
      selectedUsersForPermissions,
      customFields
    }),
    [data, selectedUsersForPermissions, customFields]
  );
  return (
    <AsyncReportDataContext.Provider value={asyncState}>
      {renderChildren && children}
    </AsyncReportDataContext.Provider>
  );
}

export function useAsyncReportData() {
  return useContext(AsyncReportDataContext);
}
export function useCustomFields() {
  return useContext(AsyncReportDataContext).customFields;
}

export function useSavedReport() {
  return useContext(AsyncReportDataContext).savedReportState;
}

export function useSelectedUsersForPermissions() {
  return useContext(AsyncReportDataContext).selectedUsersForPermissions;
}
