import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    padding: '14px 25px',

    '& [data-show-on-hover] button': {
      opacity: 0,
      transition: 'opacity .1s'
    },

    '&:hover [data-show-on-hover] button, button[data-force-show=true]': {
      opacity: 1
    }
  }
});

export function RecordDetailsWidget({ children }) {
  const s = useStyles(defaultStyles);
  return <Box {...s('container')}>{children}</Box>;
}
