import React from 'react';

import { PLACEMENTS } from '@rexlabs/tooltip';

import { LeadsResponse } from 'data/models/entities/leads';

import DefaultButton from 'view/components/button/default-button';
import { ButtonBar } from 'view/components/button-bar';
import { SplitButton } from 'view/components/action-menu';

import { getRequiredRecordStatus } from '../utils/get-required-record-status';

export interface LeadsDialogActions {
  label: string;
  onClick: () => void;
}
interface LeadsDialogActionButtonProps {
  data: LeadsResponse;
  children: any;
  onClick: () => void;
  leadsDialogActions: LeadsDialogActions[];
  isLoading: boolean;
}

export default function LeadsDialogActionButton({
  data,
  children,
  onClick,
  leadsDialogActions,
  isLoading
}: LeadsDialogActionButtonProps) {
  const { hasRequiredRelatedRecords } = getRequiredRecordStatus(data);

  const isComplete = data?.lead_status?.text.toLowerCase() === 'completed';
  const hasLeadsDialogActions = leadsDialogActions.length > 0;

  // TODO: Maybe there are better ways to handle this button instead
  // of using conditional rendering.
  // https://app.shortcut.com/rexlabs/story/64450/leads-dialog-improve-new-leads-dialog-action-button-code-instead-of-using-conditional-rendering
  return (
    <ButtonBar isLoading={isLoading} justifyContent='flex-start'>
      {isComplete || !hasLeadsDialogActions ? (
        <DefaultButton
          red={!isComplete && !hasLeadsDialogActions}
          isDisabled={!hasRequiredRelatedRecords}
          left
          onClick={onClick}
        >
          {children}
        </DefaultButton>
      ) : (
        <SplitButton
          red
          items={leadsDialogActions}
          onClick={onClick}
          placement={PLACEMENTS.BOTTOM_START}
          isDisabled={!hasRequiredRelatedRecords}
          actionButtonProps={{
            isDisabled: false
          }}
        >
          {children}
        </SplitButton>
      )}
    </ButtonBar>
  );
}
