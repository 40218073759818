import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';

export const appraisalFields = ({ whichWord: ww }) => [
  {
    field: 'id',
    headerName: `${ww('valuation')} ID`
  },
  {
    field: 'appraisal_type.text',
    headerName: 'Appraisal type',
    type: 'valueListColumn'
  },
  {
    field: 'pipeline_stage.name',
    headerName: 'Pipeline stage',
    type: 'valueListColumn'
  },
  {
    field: 'agent_1.name',
    headerName: 'Agent 1',
    subresource: 'agent_1',
    type: 'agentValueListColumn'
  },
  {
    field: 'agent_2.name',
    headerName: 'Agent 2',
    subresource: 'agent_2',
    type: 'agentValueListColumn'
  },
  {
    field: 'appraisal_date',
    headerName: `${ww('valuation')} date`,
    type: 'appraisalDialogColumn'
  },
  {
    field: 'expected_close_date',
    headerName: 'Expected close date',
    type: 'dateColumn'
  },
  {
    field: 'price_asking',
    headerName: 'Asking',
    type: 'currencyColumn'
  },
  {
    field: 'price_min',
    headerName: 'Min price',
    type: 'currencyColumn'
  },
  {
    field: 'price_max',
    headerName: 'Max price',
    type: 'currencyColumn'
  },
  {
    field: 'price_asking',
    headerName: 'Asking',
    type: 'currencyColumn'
  },

  {
    field: 'price_rent',
    headerName: 'Rent (p/w)',
    type: 'currencyColumn'
  },
  {
    headerName: 'Rent price period',
    field: 'price_rent_period.text',
    type: 'valueListColumn',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'rent_amount_period' })
    }
  },
  {
    headerName: 'Rent tax',
    field: 'price_rent_tax.text',
    type: 'valueListColumn',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'rent_amount_tax' })
    }
  },
  {
    field: 'interest_level',
    headerName: 'Interest level',
    type: 'capitalizedValueListColumn',
    // There is a value list for this, but it comes back capitalized when the
    // values from graphQL are lowercase. Changing the values in the filter
    // could break existing saved reports, so I'm hardcoding and keeping the values the same.
    filterParams: { values: ['hot', 'warm', 'cold', 'N/A'] }
  },
  {
    headerName: 'Comm estimate model',
    field: 'comm_amount_model.text',
    type: 'valueListColumn',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'commission_amount_model' })
    }
  },
  {
    headerName: 'Comm estimate rate',
    field: 'comm_amount_percentage',
    type: 'percentageColumn'
  },
  {
    headerName: 'Comm estimate structure',
    field: 'comm_structure.text',
    type: 'valueListColumn',
    filterParams: {
      values: getAsyncFilterValues({
        listName: 'commission_structures_in_archived'
      })
    }
  },
  {
    headerName: 'Comm estimate (incl GST)',
    field: 'comm_est_amount_inc_tax',
    type: 'currencyColumn',
    withRegions: ['AUS', 'NZ']
  },
  {
    headerName: 'Comm estimate (excl GST)',
    field: 'comm_est_amount_net_of_tax',
    type: 'currencyColumn',
    withRegions: ['AUS', 'NZ']
  },
  {
    headerName: 'Comm estimate (incl VAT)',
    field: 'comm_est_amount_inc_tax',
    type: 'currencyColumn',
    withRegions: ['UK', 'FR']
  },
  {
    headerName: 'Comm estimate (excl VAT)',
    field: 'comm_est_amount_net_of_tax',
    type: 'currencyColumn',
    withRegions: ['UK', 'FR']
  },
  {
    field: 'archive_date',
    headerName: 'Archive Date',
    subresource: 'archive_date',
    type: 'dateColumn'
  },
  {
    field: 'archive_reason.text',
    headerName: 'Archive Reason',
    subresource: 'archive_reason'
  },
  {
    field: 'archive_lost_agency.text',
    headerName: 'Archive Lost Agency',
    subresource: 'archive_lost_agency'
  }
];

export const appointmentAppraisalFields = ({ whichWord }) => {
  return appraisalFields({ whichWord }).map((column) => {
    if (column.field === 'appraisal_date') {
      return {
        field: 'appraisal_date',
        headerName: `${whichWord('valuation')} date`,
        type: 'appointmentAppraisalColumn'
      };
    }
    return column;
  });
};

export const propertyAppraisalFields = ({ whichWord }) => {
  return appraisalFields({ whichWord }).map((column) => {
    if (column.field === 'appraisal_date') {
      return {
        field: 'appraisal_date',
        headerName: `${whichWord('valuation')} date`,
        type: 'propertyAppraisalColumn'
      };
    }
    return column;
  });
};
