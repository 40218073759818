/*
|-------------------------------------------------------------------------------
| Core app component
|-------------------------------------------------------------------------------
|
| This middle layer between the index.js and the actual layouts is meant
| for the business logic, that decides which layout to show. This could be
| depending on the current route, session state, etc.
|
*/

import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { match, withWhereabouts } from '@rexlabs/whereabouts';

import WrapperLayout from 'shared/layouts/wrapper';
import Analytics from 'shared/utils/vivid-analytics';

import PublicActionsLayout from 'view/layouts/public-actions';
import ShellLayout from 'view/layouts/shell';
import HelmetScreen from 'view/components/helmet-screen';
import { constructRouteTitle, routeMapping } from 'utils/routing/route-mapping';

@connect((state) => ({ session: state.session }))
@withWhereabouts
@autobind
class RexApp extends PureComponent {
  notifyRouteChange() {
    const { path, hash } = this.props.whereabouts;

    Analytics.navigation({
      page: constructRouteTitle({
        path,
        hash,
        mapping: routeMapping
      }),
      properties: {
        path,
        hash
      }
    });
  }

  componentDidMount() {
    this.notifyRouteChange();

    Analytics.performance({
      task: 'Shell-FullInit',
      event: 'RexAppRender'
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.whereabouts?.key !== this.props.whereabouts?.key) {
      this.notifyRouteChange();
    }
  }

  render() {
    const { whereabouts, session } = this.props;

    const isPublicAction = match(whereabouts, {
      path: '/actions/(.*)'
    });
    const title = session.isRosieAccount ? 'Rosie' : 'Rex';

    if (isPublicAction) {
      return (
        <WrapperLayout>
          <HelmetScreen title={title}>
            <PublicActionsLayout {...this.props} />
          </HelmetScreen>
        </WrapperLayout>
      );
    }

    const { ready } = session;

    return (
      <WrapperLayout loadingMessage={!ready ? 'Authenticating user' : null}>
        <HelmetScreen title={title}>
          <ShellLayout {...this.props} />
        </HelmetScreen>
      </WrapperLayout>
    );
  }
}

export default RexApp;
