import React, { useCallback } from 'react';

import { ICONS } from 'shared/components/icon';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { RecordPreviewActionBarButton } from '../record-preview-action-bar';
import {
  AppointmentData,
  useEditAppointment
} from 'hooks/use-edit-appointment';

interface RecordPreviewAddNoteActionProps {
  appointmentData: AppointmentData;
}

export function RecordPreviewAddAppoinmentAction({
  appointmentData
}: RecordPreviewAddNoteActionProps) {
  const appointmentCallback = useCallback(() => {
    Analytics.track({
      event: EVENTS.CALENDAR.CREATE_APPOINTMENT
    });
    Analytics.track({
      event: EVENTS.PREVIEW_POPOUTS.ADD_APPOINTMENT
    });
  }, []);

  const onAddAppointmentClick = useEditAppointment({
    data: appointmentData,
    callback: appointmentCallback
  });

  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.CALENDAR_CALENDAR}
      onClick={onAddAppointmentClick}
    >
      Appointment
    </RecordPreviewActionBarButton>
  );
}
