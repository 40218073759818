import React from 'react';

import { Id } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';

import { MailMergeService } from 'data/models/entities/mail-merge';

import { PADDINGS } from 'theme';
import PaddingBox from 'view/components/padding-box';

import { LinkIconButton } from 'components/button/link-icon-button';
import { LinkActionMenu } from 'components/action-menu/link-action-menu';

export interface SendMailMergeRecordData {
  id: Id;
  name: string;
  service: MailMergeService;
}

export function RecordPreviewActionBarButton({
  iconType,
  onClick,
  children
}: React.ComponentProps<typeof LinkIconButton>) {
  return (
    <Box>
      <LinkIconButton undecorated dark iconType={iconType} onClick={onClick}>
        {children}
      </LinkIconButton>
    </Box>
  );
}

export function RecordPreviewActionBarMoreMenu({
  items
}: React.ComponentProps<typeof LinkActionMenu>) {
  return (
    <Box
      width='100%'
      flexDirection='row'
      alignItems='center'
      justifyContent='flex-end'
      // NOTE: I don't like this... but because the icon is 25px x 25px it's breaking the
      // height of the parent container.
      height='17px'
    >
      <LinkActionMenu light undecorated placement='bottom-start' items={items}>
        More
      </LinkActionMenu>
    </Box>
  );
}

export function RecordPreviewActionBar({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <PaddingBox light>
      <Box
        sx={PADDINGS.M}
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        {children}
      </Box>
    </PaddingBox>
  );
}
