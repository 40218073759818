import Analytics from 'shared/utils/vivid-analytics';
import { merge } from 'lodash';

// NOTE: this is just to identify classic errors in the shell bugsnag client,
// since we using the same client for both apps to get session data and FS
// session link for free :)
export default merge({}, Analytics, {
  error: ({ error, ...args }) => {
    const classicError = error;
    classicError.isClassic = true;
    return Analytics.error?.({ error: classicError, ...args });
  }
});
