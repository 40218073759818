import React, { ReactNode, ComponentType } from 'react';
import VividActionMenu from '@rexlabs/action-menu';
import { StyleSheet, keyframes, StylesProvider } from '@rexlabs/styling';

import { withPopoutContext } from 'view/context/popout';
import { DialogTooltipConsumer } from 'view/context/tooltip';
import { DefaultButtonProps } from 'src/view/components/button/default-button';

const enterAnimation = keyframes({
  '0%': {
    opacity: 1,
    position: 'relative',
    top: '0'
  },
  '100%': {
    opacity: 1,
    top: '0px',
    position: 'relative'
  }
});

const tetherStyles = StyleSheet({
  opening: {
    animation: `${enterAnimation} forwards normal 0.2s cubic-bezier(0, 0, 0.16, 0.99)`
  }
});

const overrides = {
  Tooltip: StyleSheet({
    tooltip: {
      display: 'block',
      minWidth: '100px',
      boxSizing: 'border-box',
      margin: 0,
      background: 'white',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      position: 'relative',
      padding: '5px 0px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      top: '-15px',
      zIndex: 3,
      border: '1px solid #cfcfcf'
    },

    clickable: {
      cursor: 'pointer',
      position: 'relative',

      '&:active:focus': {
        outline: 'none'
      }
    },

    overlay: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: 2,
      left: 0,
      top: 0
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      display: 'none'
    }
  })
};

export type ActionMenuItem = {
  label: ReactNode;
  checkRights?: string;
  onClick: ((args?: any) => any) | undefined;
};

// TODO: add proper types to `typings` (or upgrade vivid component to get acurate types for free)
export interface ActionMenuProps extends DefaultButtonProps {
  items: ActionMenuItem[];
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end';
  children?: ReactNode;
  // TODO: this is only used for usage in ButtonBar, this is an anti-pattern and we
  // should look into refactoring it and removing the prop
  left?: boolean;
  style?: any;
  distance?: string | number;
  offset?: string | number;
  Button?: ComponentType<{ isOpen: boolean }>;
  context?: Document;
}

function ActionMenu(props: ActionMenuProps): JSX.Element {
  return (
    <StylesProvider components={overrides} prioritiseParentStyles={false}>
      <DialogTooltipConsumer>
        {(value) => {
          return (
            <VividActionMenu
              tetherStyles={tetherStyles}
              enterAnimation={enterAnimation}
              distance='14px'
              offset='0px'
              Arrow={null}
              {...props}
              target={value?.target?.current || props.context}
            />
          );
        }}
      </DialogTooltipConsumer>
    </StylesProvider>
  );
}

export default withPopoutContext(ActionMenu);
