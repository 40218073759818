import { useMemo } from 'react';
import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  OabsReportQuery,
  oabsReportQueryDefaultVariables
} from './oab-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useRegion } from 'hooks/use-region';

export const defaultColumns = [
  'oab_date',
  'board_type.text',
  'agency.text',
  'price',
  'first_price',
  'first_seen_date',
  'web_link'
];

export function useOabModule() {
  const region = useRegion();
  const oabModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.oabs,
      moduleLabel: 'OAB',
      exportPrivilege: 'exporting.oabs',
      queryConfig: {
        graphQlQuery: OabsReportQuery,
        queryVariables: oabsReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'oab_state',
            direction: 'asc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'OAB',
          children: makeEntity({
            entity: 'oab',
            prefix: ''
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property',
            subresource: 'property'
          })
        },
        {
          headerName: 'Property Owner',
          children: makeEntity({
            entity: 'contact',
            prefix: 'property.owner.0.contact',
            subresource: 'property_owner',
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'system_created_user_id',
      defaultDateFieldPreference: 'oab_date',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'oab_date',
              label: 'OAB date',
              isDefault: true
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'system_created_user_id',
              label: 'Record owner',
              isDefault: true
            },
            {
              fieldName: 'agent_id',
              label: 'Agent',
              isDefault: false
            }
          ]
        }
      }
    }),
    [region]
  );
  return oabModuleConfig;
}
