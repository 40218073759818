import React, { PureComponent } from 'react';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS } from 'theme';
import { DatePicker } from '@rexlabs/date-input';
import Icon, { ICONS } from 'shared/components/icon';
import Down from 'assets/icons/arrow-down.svg';
import { Autobind } from 'utils/decorators';
import moment from 'moment';

const dateInputOverrides = {
  Branch: StyleSheet({
    today: {
      '> button': {
        textDecoration: 'underline !important',
        fontSize: '14px',
        fontWeight: 500
      }
    }
  }),
  Tether: StyleSheet({
    wrapContent: {
      zIndex: '9999 !important'
    }
  }),
  CalendarMonth: StyleSheet({
    title: {
      color: '#fff !important',
      padding: '3.5px 0 0 0 !important'
    }
  }),
  DayPickerNavigation: StyleSheet({
    prev: {
      height: 'auto !important',
      marginBottom: '0 !important'
    },
    next: {
      height: 'auto !important',
      marginBottom: '0 !important'
    }
  }),
  TextInput: StyleSheet({
    container: {
      height: '27px'
    },
    suffix: {
      display: 'none',
      '& > *:first-child': {
        marginLeft: '0 !important'
      }
    },
    prefix: {
      display: 'none !important'
    }
  })
};

const defaultStyles = StyleSheet({
  wrapDateInput: {
    position: 'relative'
  }
});

@styled(defaultStyles)
@Autobind
class DateSelect extends PureComponent {
  render() {
    const {
      styles: s,
      onChange,
      onSelect,
      onBlur,
      value,
      placeholder = '',
      ...props
    } = this.props;

    function fakeEvent(name, value) {
      return {
        persist: () => null,
        target: {
          type: 'date',
          name,
          id: name,
          value: value ?? ''
        }
      };
    }

    return (
      <StylesProvider
        components={dateInputOverrides}
        prioritiseParentStyles={false}
      >
        <div {...s('wrapDateInput')}>
          <DatePicker
            debounce={0}
            padding={0}
            monthPadding={2}
            paddingBottom={5}
            placeholder={placeholder}
            onChange={({ target }) => {
              queueMicrotask(() => {
                onSelect?.(
                  target.value
                    ? target.value.format('YYYY-MM-DD')
                    : target.value
                );
                onChange(
                  fakeEvent(
                    props.name,
                    target.value
                      ? target.value.format('YYYY-MM-DD')
                      : target.value
                  )
                );
              });
            }}
            onBlur={({ target }) => {
              onBlur?.(
                fakeEvent(
                  props.name,
                  target.value
                    ? moment(target.value).format('YYYY-MM-DD')
                    : target.value
                )
              );
            }}
            position='bottom'
            daySize={26}
            navPrev={
              <Icon
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={Down}
              />
            }
            navNext={
              <Icon
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'flex',
                  transform: 'rotate(180deg)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={Down}
              />
            }
            value={
              moment.isMoment(value) ||
              moment(value, 'YYYY-MM-DD', true).isValid()
                ? moment(value)
                : undefined
            }
            suffix={<ICONS.CALENDAR style={{ color: COLORS.STATES.IDLE }} />}
            displayFormat='D MMM YY'
            {...props}
          />
        </div>
      </StylesProvider>
    );
  }
}

export default DateSelect;
