import { useCallback } from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import leadsModel from 'data/models/entities/leads';

export function useLeadsDialogActions(id: string) {
  const { updateCompletionStatus, archive } = useModelActions(leadsModel);

  const markAsComplete = useCallback(async () => {
    return updateCompletionStatus({ id, is_completed: true }).then(() => {
      return Analytics.track({
        event: EVENTS.LEADS_DIALOG.MARK_AS_COMPLETE,
        options: { integrations: { Intercom: true } }
      });
    });
  }, [id, updateCompletionStatus]);

  const markAsIncomplete = useCallback(async () => {
    return updateCompletionStatus({ id, is_completed: false });
  }, [id, updateCompletionStatus]);

  const archiveLead = useCallback(
    async (leadId) => {
      return archive({ id: leadId });
    },
    [archive]
  );

  return {
    markAsComplete,
    markAsIncomplete,
    archiveLead
  };
}
