import React, { PureComponent } from 'react';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { keyframes, styled, StyleSheet } from '@rexlabs/styling';
import nav from 'data/models/custom/nav';
import { withModel } from '@rexlabs/model-generator';
import Icon, { ICONS } from 'shared/components/icon';
import { connect } from 'react-redux';

import { delayNavigation } from '../';
import MenuEntities from './menu-entities';

const defaultStyles = StyleSheet({
  container: {
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    transform: 'translateZ(0)', // Ensure fixed position items are relative to this container,
    userSelect: 'none'
  },
  animationWrapper: {
    height: 'inherit',
    width: 'inherit'
  },
  closeButton: {
    position: 'fixed',
    zIndex: 2,
    top: '21px',
    right: '20px',
    cursor: 'pointer',
    width: '16px',
    height: '16px'
  },
  globalMenu: {
    overflow: 'hidden',
    height: 'inherit',
    width: 'inherit',
    position: 'inherit',
    zIndex: 0
  },
  scopedMenu: {
    overflow: 'hidden',
    height: 'inherit',
    width: 'inherit',
    position: 'inherit',
    pointerEvents: 'auto',
    zIndex: 1
  },
  globalMenuEnterAnimation: {
    animationName: keyframes({
      '0%': { transform: 'translateX(-100%)' },
      '100%': { transform: 'translateX(0)' }
    }),
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease'
  },
  globalMenuExitAnimation: {
    animationName: keyframes({
      '0%': { transform: 'translateX(0)' },
      '100%': { transform: 'translateX(-100%)' }
    }),
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease'
  },
  scopedMenuEnterAnimation: {
    animationName: keyframes({
      '0%': { transform: 'translateX(100%)' },
      '100%': { transform: 'translateX(0)' }
    }),
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease'
  },
  scopedMenuExitAnimation: {
    animationName: keyframes({
      '0%': { transform: 'translateX(0)' },
      '100%': { transform: 'translateX(100%)' }
    }),
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease'
  },
  hideScopedMenu: {
    pointerEvents: 'none'
  }
});

@withModel(nav)
@connect(({ session }) => ({ session }))
@styled(defaultStyles)
@autobind
class MenuSwitcher extends PureComponent {
  constructor(props) {
    super(props);
    this.previousScopedItems = [];
    this.previousScopedItem = null;
  }

  get isScoped() {
    const { viewMode } = this.props;
    return viewMode !== 'MAIN';
  }

  get activeMenuItem() {
    const { viewMode, items } = this.props;
    if (!this.isScoped) return null;
    return items.filter((item) => item.id === viewMode)[0];
  }

  get scopedMenuItems() {
    return _.get(this.activeMenuItem, 'subMenu', []);
  }

  get scopedMenuComponent() {
    const { localState } = this.props;
    const Component = _.get(this.activeMenuItem, 'SubMenuComponent', null);
    const props = _.get(this.activeMenuItem, 'props', (data) => data);

    if (Component) {
      return (
        <Component
          isMobile
          onNavigation={this.handleClose}
          {...props(localState)}
        />
      );
    }

    return null;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.viewMode !== this.props.viewMode) {
      this.previousScopedItems = this.scopedMenuItems;
      this.previousScopedItem = this.props.items.find(
        (item) => item.id === this.props.viewMode
      );
    }
  }

  handleClose() {
    const {
      nav: { toggleOpen },
      onViewModeChange
    } = this.props;
    toggleOpen();
    delayNavigation(() => {
      onViewModeChange('MAIN');
    });
  }

  mapLocalStateToMeta(item) {
    const { localState } = this.props;
    return (
      (item && item.meta && _.isFunction(item.meta) && item.meta(localState)) ||
      null
    );
  }

  render() {
    const {
      styles: s,
      items,
      isOpen,
      viewMode,
      localState,
      activeRoute,
      onClickParams,
      onViewModeChange,
      session
    } = this.props;

    const menuProps = {
      viewMode,
      localState,
      activeRoute,
      onClickParams,
      onViewModeChange,
      isScoped: this.isScoped,
      onClose: this.handleClose
    };

    const currentItem =
      items.find((item) => item.id === viewMode) || this.previousScopedItem;
    const metaData = this.mapLocalStateToMeta(currentItem);
    const name = _.get(metaData, 'name');
    const scopedHeading = _.get(currentItem, 'name', name);

    return (
      <Box {...s('container')}>
        <Box
          {...s(
            'globalMenu',
            { globalMenuExitAnimation: this.isScoped },
            { globalMenuEnterAnimation: !this.isScoped }
          )}
        >
          <MenuEntities
            {...menuProps}
            key='global'
            items={items}
            headingLabel={
              _.get(session, 'office_details.name') || 'Error loading...'
            }
          />
        </Box>

        <Box
          {...s(
            'scopedMenu',
            { hideScopedMenu: !isOpen },
            { scopedMenuEnterAnimation: this.isScoped },
            { scopedMenuExitAnimation: !this.isScoped }
          )}
        >
          <MenuEntities
            {...menuProps}
            scoped
            key={`scoped-${viewMode}`}
            headingLabel={scopedHeading}
            items={this.scopedMenuItems}
            Component={this.scopedMenuComponent}
            previousItems={this.previousScopedItems}
          />
        </Box>

        <Box {...s('closeButton')} onClick={this.handleClose}>
          <Icon height={'100%'} width={'100%'} type={ICONS.CLOSE} />
        </Box>
      </Box>
    );
  }
}

export default MenuSwitcher;
