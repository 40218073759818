import React, { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { RelatedContactsWidget } from 'features/_experiment-contacts/widgets/related-contacts-widget';

import listingsModel from 'data/models/entities/listings';

export function ListingRelatedContactsWidget({ data }) {
  const { updateItem } = useModelActions(listingsModel);

  const handleAdd = useCallback(
    (values) =>
      updateItem({
        id: data.id,
        data: {
          related: {
            listing_reln_contact: [
              {
                contact_id: values.contact,
                relationship_type: { id: 'other' },
                custom_reln_desc: values.type
              }
            ]
          }
        }
      }),
    [data.id, updateItem]
  );

  return (
    <RelatedContactsWidget
      contacts={data?.related?.listing_reln_contact}
      onAdd={handleAdd}
    />
  );
}
