import React, {
  createContext,
  useMemo,
  useContext,
  useRef,
  useState,
  useCallback
} from 'react';
import { isFunction, omit } from 'lodash';
import {
  CsvExportParams,
  ExcelExportParams,
  ProcessCellForExportParams
} from 'ag-grid-community';

import { DataGridApi } from 'features/custom-reporting/components/data-grid';

export interface DataGridContext {
  setRef: React.RefObject<DataGridApi> | null;
  exportDataAsCsv?: (params?: CsvExportParams) => void;
  exportDataAsExcel?: (params?: ExcelExportParams) => void;
  togglePanel?: (panel: string, onOpen: (args?: any) => void) => void;
  visiblePanel?: string | null;
}
// NOTE: This generates values for the export - most fields will have a value, but we have some values
// which we use a formatter on, are an object, or are values not related to the id.
// If you find a column is not generating the correct info on export, add a valueFormatter to the coldDef
// of the affected column, in features/custom-reporting/components/data-grid-column-types
function processCellCallback(params: ProcessCellForExportParams) {
  const colParams = omit(params, ['type', 'accumulatedRowIndex']);

  const { node, value } = colParams;

  const colDef = colParams.column.getColDef();

  const valueFormatter =
    colDef?.valueFormatter && isFunction(colDef.valueFormatter)
      ? colDef.valueFormatter
      : null;

  return valueFormatter
    ? valueFormatter({
        ...colParams,
        colDef,
        data: node?.data,
        node: node || null,
        value,
        context: 'export'
      })
    : value;
}

export const DataGridContext = createContext<DataGridContext>({
  setRef: null
});

export function DataGridProvider(props) {
  const dataGridRef = useRef<DataGridApi>(null);

  const [visiblePanel, setVisiblePanel] = useState<
    'columns' | 'filters' | null
  >(null);

  const togglePanel = useCallback(
    (panel, onOpen) => {
      if (visiblePanel === panel) {
        setVisiblePanel(null);
        dataGridRef.current?.api.closeToolPanel();
      } else {
        dataGridRef.current?.api.setSideBarVisible(true);
        dataGridRef.current?.api.openToolPanel(panel);
        setVisiblePanel(panel);

        const instance = dataGridRef.current?.api.getToolPanelInstance(panel);
        onOpen?.({ instance });
      }
    },
    [dataGridRef, visiblePanel]
  );

  const value = useMemo(
    () => ({
      exportDataAsCsv: () =>
        dataGridRef.current?.api.exportDataAsCsv({ processCellCallback }),
      exportDataAsExcel: () =>
        dataGridRef.current?.api.exportDataAsExcel({ processCellCallback }),
      togglePanel,
      visiblePanel,
      setRef: dataGridRef
    }),
    [togglePanel, visiblePanel]
  );

  return <DataGridContext.Provider value={value} {...props} />;
}

export function useDataGrid(): DataGridContext {
  return useContext(DataGridContext);
}
