import {
  EntityModel,
  Query,
  useEntityListQuery,
  useModelActions,
  useModelState
} from '@rexlabs/model-generator';
import { KanbanBoardColumn } from 'components/kanban-board/kanban-board-column';
import { KanbanBoardColumnData } from 'components/kanban-board/kanban-board';
import React, { ComponentProps, ReactNode, useEffect, useMemo } from 'react';
import uiModel from 'data/models/custom/ui';
import { PipelineGroupBy } from 'data/models/entities/appraisals';
import { Criteria } from 'types/criteria';
import { usePipelineBulkActions } from 'features/pipelines/modules/appraisals/hooks/use-pipeline-bulk-actions';

type PipelineColumnProps<M extends EntityModel<any>> = {
  query: Query<M> | null;
  onQueryChange?: (query: Query<M> | null, groupBy: PipelineGroupBy) => void;
  itemHeight: number;
  renderColumnHeader: (
    props: KanbanBoardColumnData<any, Criteria[]>
  ) => ReactNode;
  renderColumnFooter?: ComponentProps<
    typeof KanbanBoardColumn
  >['renderColumnFooter'];
  groupBy: PipelineGroupBy;
  serviceName: string;
  bulkActions: ReturnType<typeof usePipelineBulkActions>;
  name: string;
} & Pick<
  KanbanBoardColumnData<any, Criteria[]>,
  'id' | 'pipelineStage' | 'renderItem' | 'isDraggingEnabled' | 'bulkActions'
>;

export function PipelineColumn<M extends EntityModel<any>>({
  query,
  onQueryChange,
  groupBy,
  serviceName,
  ...rest
}: PipelineColumnProps<M>) {
  const queryMemo = useMemo(() => query, [JSON.stringify(query?.args)]);
  useEffect(() => {
    onQueryChange?.(query, groupBy);
  }, [queryMemo]);
  const { data, status, pagination, actions } = useEntityListQuery(queryMemo);
  const { loadingIndicator } = useModelState(uiModel);
  const { loadingIndicatorOff } = useModelActions(uiModel);

  useEffect(() => {
    if (status === 'loaded' && loadingIndicator === 'ON')
      loadingIndicatorOff({});
  }, [status, loadingIndicator]);

  // When manually removing items from lists, model
  // generator can sometimes return null rows. This
  // compensates for that.
  const items = useMemo(() => (data || []).filter(Boolean), [data]);

  return (
    <KanbanBoardColumn
      items={items}
      criteria={queryMemo?.args?.criteria}
      hasNextPage={
        (pagination?.totalPages || 0) > (pagination?.currentPage || 0)
      }
      isNextPageLoading={status === 'loading'}
      loadNextPage={async () => {
        await actions.fetchPage({
          args: { page: (pagination?.currentPage || 0) + 1 }
        });
      }}
      {...rest}
    />
  );
}
