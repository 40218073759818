/* eslint-disable babel/no-invalid-this */
/**
 * NOTE: the implementation in this file is not good/violating best practises,
 * we're disabling the eslint rule for now but should ideally be looking at
 * fixing code parts like this where possible
 * https://rexsoftware.atlassian.net/browse/RADI-5282
 */

import React from 'react';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'components/text/link';

function getConnectionId() {
  const integration = _.find(
    window.Rex2IFrame.contentWindow.r2.u.api.getAccountInfo(
      'third_party_extensions'
    ),
    (extensions) => extensions.service_type_id === 'open_data_communities'
  );

  if (!integration || !integration.id) {
    // return Promise.reject(
    //   new Error('Open data communications integration not available')
    // );
    return null;
  }

  return parseInt(integration.id, 10);
}

function retrieveConnectedData(retrieveFreshData) {
  const { manageEpc, errorDialog, _id } = this.props;

  this.setState(
    {
      ...this.state,
      isLoading: true
    },
    () => {
      const integration = _.find(
        window.Rex2IFrame.contentWindow.r2.u.api.getAccountInfo(
          'third_party_extensions'
        ),
        (extensions) => extensions.service_type_id === 'open_data_communities'
      );

      if (!integration) {
        errorDialog.open({
          message:
            'You do not have the open data communities integration enabled.'
        });
        this.setState({ isLoading: false });
        return;
      }

      const connectionId = parseInt(integration.id, 10);

      api
        .post('ThirdPartyServiceOdc::getEpcStatsForListing', {
          listing_id: _id,
          connection_id: connectionId,
          use_linked_if_available: !retrieveFreshData
        })
        .then(({ data }) => {
          const {
            current_eer: currentEer,
            current_eir: currentEir,
            potential_eer: potentialEer,
            potential_eir: potentialEir,
            not_required: notRequired,
            not_required_reason: notRequiredReason,
            property_type: propertyType,
            lodgement_date: lodgementDate,
            address,
            id: certificateId
          } = data.result;

          if (notRequired && !!notRequiredReason) {
            manageEpc.setValues({
              epc_not_required: notRequired,
              epc_not_required_reason: notRequiredReason
            });

            this.setState({
              ...this.state,
              notRequired: true
            });
            return;
          }

          if (!(currentEer && currentEir && potentialEer && potentialEir)) {
            this.setState({
              hasRetrieved: false,
              isLoading: false
            });
            errorDialog.open({
              title: 'No Result',
              message: (
                <div>
                  <p>
                    We’ve had trouble finding records for this address. Please
                    enter the EPC data manually.
                  </p>
                  <p>
                    You can search for{' '}
                    <Link
                      target='_blank'
                      rel='noopener'
                      blue
                      href='https://www.epcregister.com/reportSearchAddressByPostcode.html'
                    >
                      EPC records here.
                    </Link>
                  </p>
                </div>
              )
            });
            return;
          }

          const payload = {
            ...manageEpc.values,
            epc_current_eer: currentEer,
            epc_current_eir: currentEir,
            epc_potential_eer: potentialEer,
            epc_potential_eir: potentialEir,
            epc_lodgement_date: lodgementDate,
            epc_expiry_date: moment(lodgementDate)
              .add(10, 'years')
              .format('YYYY-MM-DD'),
            propertyType,
            address
          };

          manageEpc.setValues(payload);

          this.setState({
            ...this.state,
            hasRetrieved: true,
            isLoading: false,
            connectionId,
            certificateId
          });
        })
        .catch((err) => {
          errorDialog.open(err);
          this.setState({
            ...this.state,
            hasRetrieved: false,
            isLoading: false
          });
        });
    }
  );
}

export { retrieveConnectedData, getConnectionId };
