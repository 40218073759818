import _ from 'lodash';
import LocalStorage from 'shared/utils/local-storage';
import { Emitter } from 'utils/events';

const upp = (obj) => `${obj.type.toUpperCase()}-${obj.id}`;
const userSelection = (userId, officeId) => {
  userId = userId || _.get(LocalStorage.get('account_info'), 'user_details.id');
  officeId =
    officeId || _.get(LocalStorage.get('account_info'), 'office_details.id');
  return `user_${userId}_office_${officeId}`;
};

const DEFAULT_ARRAY = [];
const DEFAULT_OBJECT = {};
const STORE_RECENT_KEY = 'recent_activity';
const EMITTER = new Emitter();

/**
 * NOTE: This class is used primarily as LocalStorage communication and not to be directly accessed
 * throughout Shell or Classic. Please see the session model under shell/src/data/models/custom/session.js
 * and use the recent activity through this model.
 */
const RecentActivity = {
  /**
   * Prepend a new recent activity entry to the current logged in session.
   * @param newActivity
   */
  pushRecentItem(newActivity) {
    const recentActivity = RecentActivity.getRecentActivity();
    const recentWithoutNewActivity = recentActivity.filter(
      (currentRecentItem) => upp(currentRecentItem) !== upp(newActivity)
    );

    const allRecentData = LocalStorage.get(STORE_RECENT_KEY, DEFAULT_OBJECT);
    allRecentData[userSelection()] = [newActivity]
      .concat(recentWithoutNewActivity)
      .slice(0, 20);

    EMITTER.emit('push', allRecentData);
    LocalStorage.set(STORE_RECENT_KEY, allRecentData);
  },

  /**
   * Gets the recent activity for the current logged in session.
   * @returns {Array} Array of recent activity.
   */
  getRecentActivity(userId, officeId) {
    return _.get(
      LocalStorage.get(STORE_RECENT_KEY, DEFAULT_OBJECT),
      userSelection(userId, officeId),
      DEFAULT_ARRAY
    );
  },

  subscribe(fn) {
    return EMITTER.subscribe('push', fn);
  },

  unsubscribe(fn) {
    return EMITTER.unsubscribe('push', fn);
  }
};

export default RecentActivity;
