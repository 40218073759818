import React from 'react';
import Box from '@rexlabs/box';

interface RecordPreviewTagContainerProps {
  children: React.ReactNode;
}

export function RecordPreviewTagContainer({
  children
}: RecordPreviewTagContainerProps) {
  return <Box flexDirection='row'>{children}</Box>;
}
