import { useMemo } from 'react';
import listingsModel from 'data/models/entities/listings';
import projectsModel from 'data/models/entities/projects';
import projectStagesModel from 'data/models/entities/project-stages';
import { contractsModel } from 'features/listings/data';

import {
  query,
  useEntityListQuery,
  useEntityQuery
} from '@rexlabs/model-generator';

const getListingQuery = (recordId: number) => {
  return query`{
      ${listingsModel} (id: ${recordId}, criteria: []) {
        id
        related {
          contact_reln_listing
        }
      }
    }`;
};

const getProjectsQuery = (recordId: number) => {
  return query`{
      ${projectsModel} (id: ${recordId}, criteria: []) {
        id
        related {
          project_reln_listing
        }
      }
    }`;
};

const getProjectStageQuery = (recordId: number) => {
  return query`{
      ${projectStagesModel} (id: ${recordId}, criteria: []) {
        id
        related {
          project_reln_listing
        }
      }
    }`;
};

function getContractCriteria(recordKey: string, recordId?: number) {
  return [{ name: recordKey, value: recordId }];
}

const getContractsQuery = (recordKey: string, recordId?: number) => query`{
    ${contractsModel} (criteria: ${getContractCriteria(
  recordKey,
  recordId
)}, limit: 20) {
      id
    }
  }`;

export interface RecordContactsProps {
  recordId: number;
  recordKey: 'listing_id' | 'project_id' | 'project_stage_id';
}

export function useRecordContacts({
  recordId,
  recordKey
}: RecordContactsProps) {
  // determine what query model to use
  const dataQuery = useMemo(() => {
    if (!recordId && !recordKey) return null;

    if (recordKey === 'listing_id') return getListingQuery(recordId);
    else if (recordKey === 'project_id') return getProjectsQuery(recordId);
    else if (recordKey === 'project_stage_id')
      return getProjectStageQuery(recordId);

    return null;
  }, [recordId, recordKey]);

  const contractsQuery = useMemo(
    () =>
      recordId && recordKey === 'listing_id'
        ? getContractsQuery(recordKey, recordId)
        : null,
    [recordKey, recordId]
  );
  const { status, data } = useEntityQuery(dataQuery);

  const { status: contractsStatus, data: contracts } =
    useEntityListQuery(contractsQuery);

  const owners = useMemo(() => {
    if (!data && recordKey !== 'listing_id') return '';
    return (data as any)?.related?.contact_reln_listing
      ?.filter((contact) => contact.reln_type.id === 'owner')
      .map((c) => c.contact.name_legal || c.contact.name)
      .join();
  }, [data, recordKey]);

  const buyers = useMemo(() => {
    if (!contracts && recordKey !== 'listing_id') return '';
    return contracts
      ?.filter((cd) => cd.is_accepted)
      .map((cd) => cd.purchtenant_legal_name)
      .join();
  }, [contracts, recordKey]);

  const hasLoaded =
    status === 'loaded' &&
    (contractsStatus === 'loaded' || contractsStatus === null);

  return { contracts, owners, buyers, hasLoaded };
}
