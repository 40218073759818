import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';

interface TagProps extends BaseTextProps {
  light?: boolean;
}

export function Tag({ light, ...props }: TagProps) {
  return <Text is='p' {...props} type={[light ? 'TAG_LIGHT' : 'TAG']} />;
}
