import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import Icon, { ICONS, mapWorkflowStatus } from 'shared/components/icon';
import { TEXTS, COLORS, PADDINGS } from 'theme';
import { styled, StyleSheet } from '@rexlabs/styling';

import { autobind } from 'core-decorators';
import get from 'lodash/get';

const defaultStyles = StyleSheet({
  label: {
    paddingLeft: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  notificationLabel: {
    fontSize: '14px',
    lineHeight: '17px'
  },
  message: {
    marginLeft: '17px',
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1,
    color: COLORS.PRIMARY.SAND
  },
  subheader: {
    marginLeft: '17px',
    marginTop: PADDINGS.XXS,
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1,
    color: COLORS.PRIMARY.SAND
  },
  notificationMessage: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  clickable: {
    cursor: 'pointer'
  }
});

@styled(defaultStyles)
@autobind
class WorkflowStatus extends PureComponent {
  static propTypes = {
    instance: PropTypes.object.isRequired
  };

  static defaultProps = {
    /** We use this prop to differentiate when we are using this component in a the record sidebar or in the notification toasts. */
    notification: false,
    onClick: false
  };

  getSubHeading(statusId) {
    const { instance } = this.props;
    let subHeading =
      statusId === 'terminated' ? 'An action has failed' : 'Pending your input';

    if (statusId === 'terminated' && instance.message) {
      subHeading = `${instance.message}`;
    }

    return subHeading;
  }

  getStatusDetails() {
    const {
      notification,
      instance: { status_message: statusMessage, status_id: statusId, data }
    } = this.props;

    // Adjust the values that we show when using this component for workflow notification toasts
    if (data && notification) {
      return {
        icon: get(data, 'status_id'),
        heading: get(data, 'message'),
        subHeading: this.getSubHeading(get(data, 'status_id'))
      };
    }

    return {
      icon: statusId,
      heading: statusMessage,
      subHeading: this.getSubHeading(statusId)
    };
  }

  render() {
    const {
      styles: s,
      notification,
      instance: { name, status_id: statusId },
      onClick
    } = this.props;
    const isTermination = statusId === 'terminated';
    const { icon, heading, subHeading } = this.getStatusDetails();

    return (
      <Box onClick={onClick} {...s({ clickable: !!onClick })}>
        <Box alignItems={'center'}>
          <Icon
            hasControlledColor={false}
            style={{
              display: 'flex',
              minWidth: '12px',
              justifyContent: 'center'
            }}
            type={ICONS[mapWorkflowStatus[icon]]}
          />
          <div {...s('label', { notificationLabel: notification })}>
            {isTermination && 'Terminated: '}
            {name}
          </div>
        </Box>
        <div {...s('message', { notificationMessage: notification })}>
          {heading}
        </div>
        {notification && <div {...s('subheader')}>{subHeading}</div>}
      </Box>
    );
  }
}

export default WorkflowStatus;
