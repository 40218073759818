import { useMemo } from 'react';
import { useFilterConfigItem } from 'features/custom-reporting/utils/get-filter-field-config';
import { UserSelectedReportState } from 'features/custom-reporting/hooks/use-user-selected-report-state';

const getLocationCriteria = ({ name, value }) => [{ name, value }];

export function useLocationCriteria({
  moduleName,
  userSelectedReportState
}: {
  moduleName: string;
  userSelectedReportState?: UserSelectedReportState;
}) {
  const location = userSelectedReportState?.selectedLocationFilter?.[0];

  const { getCriteria = getLocationCriteria, fieldName } =
    useFilterConfigItem({
      filterType: 'location'
    }) || {};

  const criteria = useMemo(
    () =>
      location && location?.value
        ? getCriteria({ name: fieldName, value: location?.value })
        : [],
    [fieldName, getCriteria, location]
  );

  return criteria;
}
