import { isString, startCase } from 'lodash';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import { getCustomFieldColumns } from 'features/custom-reporting/utils/custom-field-utils';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

export const contractFields = ({
  whichWord: ww,
  region,
  subresource,
  customFields
}) => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'contract'
  });

  return [
    {
      headerName: `${ww('contract')} ID`,
      field: 'id'
    },
    {
      headerName: 'Agent',
      field: 'agent.name',
      type: 'agentValueListColumn',
      subresource: 'agent'
    },
    {
      headerName: `${ww('contract')} status`,
      field: 'contract_status.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'contract_status' })
      },
      valueFormatter: ({ value }) => {
        if (value === 'Fallen') return startCase(ww('fallen'));
        return value;
      }
    },
    {
      headerName: 'Verified status',
      field: 'verified_status.text',
      type: 'valueListColumn',
      filterParams: {
        // No BE value list is available for this
        values: [ww('settled'), ww('unconditional'), 'N/A']
      }
    },
    {
      headerName: 'Sale price',
      field: 'detail_sale_price_or_lease_pa',
      type: 'currencyColumn'
    },
    {
      headerName: 'Deposit amount',
      field: 'detail_deposit_full_or_prepayment',
      type: 'currencyColumn'
    },
    {
      headerName: 'Purchaser legal name',
      field: 'purchtenant_legal_name'
    },
    {
      headerName: 'Purchaser status',
      field: 'purchtenant_position.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({
          listName: 'contract_purchtenant_position'
        })
      }
    },
    ...(region.isEU
      ? [
          {
            headerName: 'Stability indicator',
            field: 'stability_indicator.text',
            type: 'valueListColumn',
            filterParams: {
              values: getAsyncFilterValues({
                listName: 'contract_stability_indicator'
              })
            }
          }
        ]
      : []),
    {
      headerName: 'Active',
      field: 'is_primary_accepted',
      type: 'booleanColumn'
    },
    {
      headerName: 'Backup',
      field: 'is_backup_offer',
      type: 'booleanColumn'
    },
    {
      headerName: 'Received',
      field: 'date_actual_offer',
      type: 'dateColumn'
    },
    {
      headerName: 'Accepted',
      field: 'date_actual_accepted',
      type: 'dateColumn'
    },
    {
      headerName: ww('expected unconditional'),
      field: 'date_expec_unconditional',
      type: 'dateColumn'
    },
    {
      headerName: ww('unconditional'),
      field: 'date_actual_unconditional',
      type: 'dateColumn'
    },
    {
      headerName: ww('verified unconditional'),
      field: 'verified_unconditional',
      type: 'booleanColumn'
    },
    {
      headerName: ww('expected settlement'),
      field: 'date_expec_settlement',
      type: 'dateColumn'
    },
    {
      headerName: ww('settled'),
      field: 'date_actual_settlement',
      type: 'dateColumn'
    },
    ...(hasFeatureFlags('agent_sliding_comms') && !region?.isUK
      ? [
          {
            headerName: 'Expected deposit release date',
            field: 'date_expec_deposit_release',
            type: 'dateColumn'
          },
          {
            headerName: 'Deposit release date',
            field: 'date_actual_deposit_release',
            type: 'dateColumn'
          },
          {
            headerName: 'Expected agent commission payment date',
            field: 'date_expec_agent_comm_payment',
            type: 'dateColumn'
          },
          {
            headerName: 'Agent commission payment date',
            field: 'date_actual_agent_comm_payment',
            type: 'dateColumn'
          }
        ]
      : []),
    {
      headerName: ww('verified settled'),
      field: 'verified_settled',
      type: 'booleanColumn'
    },
    {
      headerName: 'Days until accepted',
      field: 'days_until_accepted',
      type: 'numberColumn'
    },
    {
      headerName: `Accepted to ${ww('settled').toLowerCase()} (days)`,
      field: 'days_from_accepted_to_settled',
      type: 'numberColumn'
    },
    {
      headerName: 'Fallen date',
      field: 'date_actual_fallen',
      type: 'dateColumn'
    },
    {
      headerName: 'Fallen reason',
      field: 'fallen_reason.text'
    },
    ...(region?.isEU
      ? [
          {
            headerName: 'Chain assignee',
            field: 'chain_instance.assignee_user.name',
            type: 'agentValueListColumn',
            subresource: 'chain'
          },
          {
            headerName: 'Chain links',
            field: 'chain_instance.chain.link_count',
            type: 'numberColumn',
            subresource: 'chain'
          },
          {
            headerName: 'Chain start/end',
            field: 'chain_instance.chain',

            valueFormatter: ({ value, node }) => {
              // if there's no chain attached, we want to show nothing
              if (!value) {
                return getEmptyValue({ node });
              }
              // When grouping by a column, the keyCreator output gets
              // directly passed into valueFormatter, so spit it right out again
              if (isString(value)) {
                return value;
              }
              // Otherwise wrangle the chain object to show what we want in the column
              return value?.chain_is_complete ? 'Complete' : 'Incomplete';
            },
            // Key creator provides the value for grouping and filtering
            keyCreator: ({ value }) => {
              if (!value) {
                return 'N/A';
              }
              return value.chain_is_complete ? 'Complete' : 'Incomplete';
            },
            filter: 'agSetColumnFilter',
            subresource: 'chain'
          }
        ]
      : []),
    ...customFieldColumns
  ];
};
