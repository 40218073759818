import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { AxiosResponse } from 'axios';

import Box from '@rexlabs/box';
import { ValidationFunction } from '@rexlabs/form';
import { TextInput } from '@rexlabs/text-input';
import { useModelActions } from '@rexlabs/model-generator';
import { createValidationRules } from '@rexlabs/validator';

import Dialog from 'view/components/dialog';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton } from 'view/components/button';
import { Select, EntitySelect } from 'view/components/input/select';
import { Form, FormField, ReactForms } from 'view/components/form';
import { LoadingButton } from 'components/button/loading-button';

import trustAccountsModel from 'data/models/entities/trust-accounts';
import trustLedgersModel, {
  TrustLedger
} from 'data/models/entities/trust-ledgers';

import { PADDINGS } from 'theme';
import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';
import { isEmpty } from 'lodash';
import Spinner from 'shared/components/spinner';
import {
  RecordContactsProps,
  useRecordContacts
} from '../hooks/use-record-contacts';

export interface AddTrustLedgerDialogProps {
  closeDialog: () => void;
  errorDialog?: ErrorDialog;
  onSubmit: (responseData) => void;
  recordId: number; // id of the record.
  recordKey: RecordContactsProps['recordKey'];
  trustLedgerData: TrustLedger;
  onLoad: () => void;
}

const ledgerNameOptions = [
  {
    value: 'Marketing Ledger',
    label: 'Marketing Ledger'
  },
  {
    value: 'Deposit Ledger',
    label: 'Deposit Ledger'
  },
  {
    value: 'Trust Ledger',
    label: 'Trust Ledger'
  }
];

function AddTrustLedgerDialog({
  closeDialog,
  errorDialog,
  onSubmit,
  recordId,
  recordKey,
  trustLedgerData,
  onLoad
}: AddTrustLedgerDialogProps) {
  const handleCloseDialog = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  // removes the loading state after render
  useEffect(() => {
    return onLoad();
  }, [onLoad]);

  const hasTrustLedgerData = !!trustLedgerData?.id;
  const notInOptions =
    ledgerNameOptions.findIndex(
      (l) => l.value === trustLedgerData?.ledger_name
    ) === -1;
  const { createItem, updateItem } = useModelActions(trustLedgersModel);

  const { buyers, owners, hasLoaded } = useRecordContacts({
    recordId,
    recordKey
  });

  const [isLoading, setIsLoading] = useState(false);

  const ledgerOptions = useMemo(
    () => [
      ...ledgerNameOptions,
      ...(hasTrustLedgerData &&
      notInOptions &&
      !isEmpty(trustLedgerData.ledger_name)
        ? [
            {
              value: trustLedgerData.ledger_name,
              label: trustLedgerData.ledger_name
            }
          ]
        : [])
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasTrustLedgerData, notInOptions]
  );

  const onAttach = useCallback(
    (values: TrustLedger) => {
      const upsertItem = !hasTrustLedgerData ? createItem : updateItem;
      const trustLedgerId = hasTrustLedgerData
        ? trustLedgerData?.id
        : undefined;
      setIsLoading(true);
      upsertItem({
        id: trustLedgerId,
        data: { [recordKey]: recordId, ...values }
      })
        .then((response: AxiosResponse<TrustLedger>) => {
          const responseData = hasTrustLedgerData
            ? response.data
            : response.data['result'];
          onSubmit(responseData);
        })
        .then(closeDialog)
        .catch((e) => {
          setIsLoading(false);
          errorDialog?.open(e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmit]
  );

  const validate = createValidationRules({
    ledger_name: 'required',
    related_contact_names: 'required'
  }) as ValidationFunction<TrustLedger>;

  const initialState = hasTrustLedgerData
    ? {
        ...trustLedgerData,
        ledger_name: trustLedgerData.ledger_name || 'Trust Ledger',
        related_contact_names: trustLedgerData.related_contact_names || owners
      }
    : { ledger_name: 'Trust Ledger', related_contact_names: owners };

  return (
    <Dialog
      title={hasTrustLedgerData ? 'Edit Ledger Details' : 'Add Ledger'}
      closeDialog={handleCloseDialog}
      width={500}
      hasPadding={true}
      height={hasTrustLedgerData ? 291 : 364}
    >
      <Box>
        {!hasLoaded ? (
          <Box
            justifyContent='center'
            alignItems='center'
            width={500}
            height={hasTrustLedgerData ? 225 : 298}
          >
            <Spinner dark small />
          </Box>
        ) : (
          <ReactForms<TrustLedger, void>
            validate={validate}
            initialValues={initialState}
            handleSubmit={onAttach}
          >
            {({ submitForm, setFieldValue }) => {
              return (
                <Form>
                  {!hasTrustLedgerData && (
                    <FormField
                      name='trust_account_id'
                      label='trust account'
                      Input={EntitySelect}
                      inputProps={{
                        models: [trustAccountsModel],
                        searchOnMount: true
                      }}
                    />
                  )}

                  <FormField
                    name='ledger_name'
                    label='ledger name'
                    Input={Select}
                    inputProps={{
                      options: ledgerOptions,
                      suggestive: true
                    }}
                    onChange={(e) => {
                      if (e.target.value === 'Deposit Ledger') {
                        setFieldValue('related_contact_names', buyers);
                      } else {
                        setFieldValue('related_contact_names', owners);
                      }
                    }}
                  />

                  <FormField
                    name='related_contact_names'
                    label='related contact name(s)'
                    Input={TextInput}
                  />

                  <Box pt={PADDINGS.M}>
                    <ButtonBar hasPadding={false} isLoading={false}>
                      <TextButton blue onClick={closeDialog}>
                        Cancel
                      </TextButton>

                      <LoadingButton
                        isLoading={isLoading}
                        red
                        onClick={submitForm}
                        darkSpinner={false}
                      >
                        {hasTrustLedgerData ? 'Save' : 'Attach'}
                      </LoadingButton>
                    </ButtonBar>
                  </Box>
                </Form>
              );
            }}
          </ReactForms>
        )}
      </Box>
    </Dialog>
  );
}

export default compose(withErrorDialog)(AddTrustLedgerDialog);
