import React from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    ...TEXTS.CONTENT.PLACEHOLDER
  }
});

function OptionNotFound({ styles: s }) {
  return (
    <Box {...s('container')} padding='7px'>
      No results
    </Box>
  );
}

export default styled(defaultStyles)(OptionNotFound);
