import React, { useEffect, useMemo, useState } from 'react';
import Box from '@rexlabs/box';

import { SubHeading } from 'components/text/sub-heading';
import { Body } from 'components/text/body';
import { RecordListTable } from 'components/record-list-screen/table';
import { TextTagCell } from 'components/record-list-screen/cells/tag-cell';
import { DefaultCellProps } from 'components/record-list-screen/cells/default';
import { RecordListActions } from 'components/record-list-screen/actions';
import { StateView } from 'components/record-list-screen/state-view';
import Spinner from 'shared/components/spinner';
import { useDialog } from 'hooks/use-dialog';
import { PADDINGS } from 'shared/theme';
import { Connection } from 'data/models/entities/single-view-connection-model';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { useConnections } from '../hooks/use-connections';

export function SingleViewConnections() {
  const [connections, setConnections] = useState<Connection[]>([]);
  const errorDialog = useErrorDialog();
  const disconnectDialog = useDialog('disconnectConnection');
  const markConnectionType = useDialog('markConnectionType');
  const connectionToken = useDialog('connectionToken');
  const { isLoading, fetch: fetchConnections } = useConnections({
    connections,
    setConnections
  });

  useEffect(() => {
    fetchConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentConsentAccount = useMemo(
    () => connections.filter((c) => c.is_consent_account)?.[0],
    [connections]
  );

  return (
    <Box p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <SubHeading semibold style={{ marginLeft: 0 }}>
        Connections
      </SubHeading>
      <Body
        regular
        normal
        italic
        style={{
          marginLeft: 0,
          marginBottom: PADDINGS.M,
          whiteSpace: 'pre-wrap'
        }}
      >
        Connect and manage data sources to start building a complete view of
        your customers. Once connected, your contacts will be automatically
        synced in real-time with all data sources connected in Single View.
        <br />
        <br />
        By default, the first connected data source will be marked as the
        ‘Consent’ and ‘View’ account. You can change this at any time if
        multiple accounts are linked. Consent tag will determine which account
        to use for consent types and privacy preferences. View tag will
        determine which account to use to display a single record view.
      </Body>
      <Box m='0px'>
        <Box mb={PADDINGS.M}>
          <RecordListActions
            onAdd={() => connectionToken.open({ setConnections })}
            selection={{ type: 'include', ids: [] }}
          />
        </Box>

        <RecordListTable
          items={connections}
          columns={[
            {
              id: 'name',
              label: 'Name',
              Cell: (props: DefaultCellProps) => (
                <TextTagCell
                  {...props}
                  tag={
                    props?.data?.is_consent_account
                      ? { text: 'Consent' }
                      : undefined
                  }
                  items={[
                    ...(!props?.data?.is_consent_account
                      ? [
                          {
                            label: 'Mark as Consent Account',
                            onClick: ({
                              data: connection
                            }: {
                              data: Connection;
                            }) => {
                              markConnectionType.open({
                                connection,
                                currentConsentAccount,
                                closeDialog: markConnectionType.close,
                                setConnections
                              });
                            }
                          }
                        ]
                      : []),
                    {
                      label: 'Disconnect',
                      onClick: ({ data }) => {
                        if (connections.length > 1 && data.is_consent_account) {
                          errorDialog.open({
                            message:
                              "A connection marked as 'consent' cannot be disconnected. Please select a new connection to set as the ‘consent’ account and try again."
                          });
                        } else {
                          disconnectDialog.open({
                            connection: data,
                            setConnections
                          });
                        }
                      }
                    }
                  ]}
                />
              )
            }
          ]}
          visibleColumns={['name']}
          setVisibleColumns={() => null}
          selection={{ type: 'include', ids: [] }}
          setSelection={() => null}
          hasSelection={false}
          setOrderBy={() => null}
          isLoading={isLoading}
          LoadingView={() => (
            <StateView noPadding>
              <Spinner small dark />
            </StateView>
          )}
          EmptyView={() => <StateView noPadding>No connections</StateView>}
        />
      </Box>
    </Box>
  );
}
