export enum RefinementType {
  SHORTER = 'shorter',
  LONGER = 'longer',
  TONE_FORMAL = 'tone_formal',
  TONE_FRIENDLY = 'tone_friendly',
  TONE_CASUAL = 'tone_casual',
  TONE_SALESLIKE = 'tone_saleslike',
  TONE_QUIRKY = 'tone_quirky',
  AUDIENCE_UPSIZERS = 'audience_upsizers',
  AUDIENCE_DOWNSIZERS = 'audience_downsizers',
  AUDIENCE_LARGE_FAMILIES = 'audience_large_families',
  AUDIENCE_FAMILIES = 'audience_families',
  AUDIENCE_COUPLES = 'audience_couples',
  AUDIENCE_FIRST_HOME_BUYERS = 'audience_first_home_buyers',
  AUDIENCE_DEVELOPERS = 'audience_developers',
  AUDIENCE_INVESTORS = 'audience_investors',
  AUDIENCE_RETIREES = 'audience_retirees',
  AUDIENCE_INTERNATIONAL_INTERSTATE_BUYERS = 'audience_international_interstate_buyers',
  AUDIENCE_STUDENTS = 'audience_students'
}
