import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, TEXTS, PADDINGS, FONT } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import Dialog from 'view/components/dialog';
import RexList, { RexBasicCell } from 'view/components/list';
import { autobind } from 'core-decorators';

// Put styles in-line to work around Maximum update depth exceeded
@styled(
  StyleSheet({
    icon: {
      paddingRight: PADDINGS.XXS,
      color: COLORS.PRIMARY.YELLOW
    },
    cell: {
      width: '100%'
    },
    rowNum: {
      fontWeight: FONT.WEIGHTS.SEMIBOLD
    },
    content: {
      fontWeight: FONT.WEIGHTS.MEDIUM
    }
  })
)
class RenderComponent extends PureComponent {
  render() {
    const { styles: s, data } = this.props;

    const backgroundColor = data.index % 2 === 0 ? '#f4f4f1' : 'white';

    return (
      <RexBasicCell
        {...s('cell')}
        styles={{
          container: {
            backgroundColor,
            paddingLeft: PADDINGS.XS,
            paddingTop: PADDINGS.L,
            paddingBottom: PADDINGS.L
          }
        }}
      >
        <Box {...s('cell')}>
          <Icon {...s('icon')} type={ICONS.WARNING} />
          <span {...s('content')}>
            {`Row ${data.cell.row};  ${data.type.label}`}
          </span>
          <span {...s('rowNum')}> {data.cell.column}</span>
        </Box>
      </RexBasicCell>
    );
  }
}

const styles = {
  separator: {
    display: 'none'
  },
  wrapItem: {
    '&:nth-child(even)': {
      backgroundColor: COLORS.PRIMARY.WHITE
    },
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
};

@styled(
  StyleSheet({
    heading: {
      ...TEXTS.HEADERS.HEADING_2,
      paddingBottom: PADDINGS.XS
    },
    content: {
      ...TEXTS.CONTENT.DEFAULT_TEXT,
      paddingBottom: PADDINGS.XS
    },
    buttonBar: {
      marginTop: PADDINGS.M
    },
    icon: {
      color: COLORS.PRIMARY.RED,
      marginRight: PADDINGS.S,
      width: '50px',
      height: '50px'
    },
    listContainer: {
      boxShadow: '1px 1px 5px black',
      padding: '1rem'
    },
    highErrorRate: {
      width: '100%',
      backgroundColor: '#f4f4f1',
      display: 'flex',
      justifyContent: 'flex-start',
      borderBottom: `2px solid lightgrey`
    },
    highErrorRateIcon: {
      color: COLORS.STATES.RED,
      paddingLeft: PADDINGS.S,
      paddingTop: PADDINGS.M
    },
    highErrorRateText: {
      ...TEXTS.BODY,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontSize: '12px',
      fontStyle: 'none',
      paddingRight: PADDINGS['3XL'],
      paddingLeft: PADDINGS.XS,
      paddingBottom: PADDINGS.M,
      paddingTop: PADDINGS.M
    },
    emptyDialog: {
      display: 'flex',
      justifyContent: 'center',
      padding: PADDINGS.XL
    }
  })
)
@autobind
class PafErrorListDialog extends PureComponent {
  errors() {
    return this.props.errors.map((error, index) => ({
      ...error,
      index
    }));
  }

  highErrorRateWarning() {
    const { highErrorRate, styles: s } = this.props;

    if (!highErrorRate) {
      return null;
    }

    return (
      <Box {...s('highErrorRate')}>
        <Icon {...s('highErrorRateIcon')} type={ICONS.WARNING} />
        <Box>
          <p {...s('highErrorRateText')}>
            More than 10% of the rows in this file were unable to be processed
            due to errors If you are unable to source a better quality file,
            consider rolling back this file.
          </p>
        </Box>
      </Box>
    );
  }

  renderContent() {
    const { styles: s, columnNames, errors } = this.props;

    if (errors.length === 0) {
      return (
        <Box {...s('emptyDialog')}>
          <h3>No results found.</h3>
        </Box>
      );
    }

    return (
      <RexList
        renderItem={(item) => <RenderComponent data={item} />}
        items={this.errors()}
        styles={styles}
        columnNames={columnNames}
        isLoading={false}
        loadErrors={[]}
        LoadingView={() => <div>Loading...</div>}
        ErrorView={() => <div>Has Errors</div>}
      />
    );
  }

  render() {
    const { errors, onCancel } = this.props;
    return (
      <Dialog
        title={`Errors (${errors.length} rows)`}
        closeDialog={onCancel}
        styles={{ content: { padding: 0 } }}
      >
        <Box style={{ maxHeight: '480px', overflow: 'scroll' }}>
          {this.highErrorRateWarning()}
          {this.renderContent()}
        </Box>
      </Dialog>
    );
  }
}

export default PafErrorListDialog;
