import {
  StreetOption,
  StreetValue,
  normalizerNoId as streetNormalizer
} from 'view/components/input/select/street';
import {
  SuburbOption,
  SuburbValue,
  normalizerNoId as suburbNormalizer
} from 'view/components/input/select/suburb';
import {
  PostcodeOption,
  PostcodeValue,
  normalizerNoId as postcodeNormalizer
} from 'view/components/input/select/postcode';
import {
  PostcodeAreaOption,
  PostcodeAreaValue,
  normalizerNoId as postcodeAreaNormalizer
} from 'view/components/input/select/postcode-area';
import {
  PostcodeSectorOption,
  PostcodeSectorValue,
  normalizerNoId as postcodeSectorNormalizer
} from 'view/components/input/select/postcode-sector';
import {
  PostcodeDistrictOption,
  PostcodeDistrictValue,
  normalizerNoId as postcodeDistrictNormalizer
} from 'view/components/input/select/postcode-district';
import {
  LocalityOption,
  LocalityValue,
  normalizerNoId as localityNormalizer
} from 'view/components/input/select/locality';

function normalizer({
  streets = [],
  suburbs = [],
  postcodes = [],
  postcodeAreas = [],
  postcodeSectors = [],
  postcodeDistricts = [],
  localities = []
}) {
  return [
    ...streets.map((street) => ({
      ...streetNormalizer(street),
      Option: StreetOption,
      Value: StreetValue
    })),
    ...suburbs.map((suburb) => ({
      ...suburbNormalizer(suburb),
      Option: SuburbOption,
      Value: SuburbValue
    })),
    ...postcodes.map((postcode) => ({
      ...postcodeNormalizer(postcode),
      Option: PostcodeOption,
      Value: PostcodeValue
    })),
    ...postcodeAreas.map((postcodeArea) => ({
      ...postcodeAreaNormalizer(postcodeArea),
      Option: PostcodeAreaOption,
      Value: PostcodeAreaValue
    })),
    ...postcodeSectors.map((postcodeSector) => ({
      ...postcodeSectorNormalizer(postcodeSector),
      Option: PostcodeSectorOption,
      Value: PostcodeSectorValue
    })),
    ...postcodeDistricts.map((postcodeDistrictSector) => ({
      ...postcodeDistrictNormalizer(postcodeDistrictSector),
      Option: PostcodeDistrictOption,
      Value: PostcodeDistrictValue
    })),
    ...localities.map((locality) => ({
      ...localityNormalizer(locality),
      Option: LocalityOption,
      Value: LocalityValue
    }))
  ];
}

export default normalizer;
