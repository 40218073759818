/*
|-------------------------------------------------------------------------------
| Shell Propagator for `AVM.events`
|-------------------------------------------------------------------------------
|
| Across all Classic frames (app, dialogs, stepthrough), we have events being
| fired in the individual `window.AVM.events` instances; they should be getting
| propagated to other iframes too!
|
*/

let eventers = [];
const EventPropagatorBridge = {
  /**
   * Register to all events of a new "eventer", which will trigger the same event
   * @param eventer
   */
  registerClassicEventer(eventer) {
    eventers.push(eventer);
    let subscription = eventer.subscribe('*', (data, ev) => {
      // Ignore feedback from our own attempts at propagation
      if (ev.triggerSource === 'EventPropagatorBridge') return;
      const eventersWithoutErrors = [];
      eventers.forEach((anEventer) => {
        try {
          if (anEventer !== eventer) {
            anEventer.trigger(ev.namespace, ev.data, 'EventPropagatorBridge');
          }
          eventersWithoutErrors.push(anEventer);
        } catch (error) {
          console.error(error);
        }
      });
      eventers = eventersWithoutErrors;
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        eventers = eventers.filter((e) => e !== eventer);
        eventer = null;
        subscription = null;
      }
    };
  }
};

export default EventPropagatorBridge;
