import React, { PureComponent } from 'react';
import Table from '@rexlabs/table';
import { RexCell, RexHeadingCell } from './';

function getCellMethod(type, dataKey, reporting, i) {
  function getTextCell({ datum }) {
    return (
      <RexCell reporting={reporting} columnIndex={i}>
        {datum[dataKey]}
      </RexCell>
    );
  }

  switch (type) {
    default:
      return getTextCell;
  }
}

function mapColumns(column, DataCell, HeaderCell, reporting, i) {
  const { label, type, dataKey, align, width } = column;
  return {
    label: HeaderCell ? (
      HeaderCell(label)
    ) : (
      <RexHeadingCell reporting={reporting} align={align}>
        {label}
      </RexHeadingCell>
    ),
    Cell: DataCell
      ? DataCell(dataKey, i, reporting, column)
      : getCellMethod(type, dataKey, i),
    width: width || '25%'
  };
}

class RexTable extends PureComponent {
  render() {
    const { tableData, reporting, ...props } = this.props;
    const columns = tableData.columns.map((col, i) => {
      return mapColumns(
        col,
        tableData.DataCell,
        tableData.HeaderCell,
        reporting,
        i
      );
    });

    return (
      <div>
        <Table data={tableData.data} columns={columns} {...props} />
      </div>
    );
  }
}

export default RexTable;
