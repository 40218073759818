import { get } from 'lodash';

export const contractConditionFields = () => {
  return [
    {
      headerName: 'Condition',
      field: 'condition_type',
      valueFormatter: ({ value }) => {
        // Not using capitalize here because it forces all the other chars to be lowercase
        // BE returns contract events - e.g. 'finance', 'settlement' - as all lowercase.
        // But don't want to force the rest of user-entered condition types to lowercase
        return value ? value[0].toUpperCase() + value.slice(1) : '-';
      }
    },
    { headerName: 'Condition notes', field: 'condition_notes' },
    {
      headerName: 'Condition status',
      field: 'condition_date_completed',
      colId: 'condition_status',
      valueGetter: ({ data, colDef }) => {
        const value = get(data, colDef?.field || '');
        return value ? 'Complete' : 'Incomplete';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Complete', 'Incomplete']
      }
    },
    {
      headerName: 'Condition date',
      field: 'condition_date_of',
      type: 'dateColumn'
    },
    {
      headerName: 'Condition completed',
      field: 'condition_date_completed',
      type: 'dateColumn'
    }
  ];
};
