import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    minWidth: '15px',
    maxWidth: '24px',
    width: 'auto',
    height: '15px',
    color: 'white',
    fontWeight: '600',
    fontSize: '10px',
    borderRadius: '85px',
    padding: '0 4px',
    lineHeight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: '-9px',
    backgroundColor: '#C11D17'
  }
});

@styled(defaultStyles)
class NotificationBadge extends PureComponent {
  render() {
    const { styles: s, count } = this.props;
    const sensibleCount = count > 99 ? '99+' : count;

    return count > 0 ? <Box {...s('container')}>{sensibleCount}</Box> : null;
  }
}

export default NotificationBadge;
