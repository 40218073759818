/*
|-------------------------------------------------------------------------------
| Shell Call To Actions
|-------------------------------------------------------------------------------
|
| Allows the following:
|  * Rex2 primary mutative actions, like saving.
|  * Rex2 triggering of unideal states, like loading.
|
*/

import ui from 'data/models/custom/ui';
import SaveCancelContext from 'data/save-cancel-context';
import { store } from 'store';

const CallToActionBridge = {
  actionCreators: {
    updateSaveCancelOverlayState:
      ui.actionCreators.updateSaveCancelOverlayState,
    updateSaveCancelOverlaySpinner:
      ui.actionCreators.updateSaveCancelOverlaySpinner,
    loadingIndicatorOn: ui.actionCreators.loadingIndicatorOn,
    loadingIndicatorOff: ui.actionCreators.loadingIndicatorOff
  },
  SaveCancelContext,

  getSaveCancelOverlayState: function getSaveCancelOverlay() {
    const state = store.getState();
    return state.ui.saveCancelOverlayState;
  },

  isMessageShowing: function isMessageShowing({ message }) {
    const state = store.getState();
    const isLoading = state.ui.loadingIndicator === 'ON';
    const currentLoadingMessage = state.ui.loadingIndicatorMessage;
    return isLoading && currentLoadingMessage === message;
  },

  isLoadingIndicatorHiden: function isLoadingIndicatorHiden() {
    const state = store.getState();
    return state.ui.loadingIndicator === 'OFF';
  }
};

export default CallToActionBridge;
