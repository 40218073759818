import React from 'react';

import Box from '@rexlabs/box';

interface SavedListItemTooltipContainerProps {
  children: React.ReactNode;
}

export function SavedListItemTooltipContainer(
  props: SavedListItemTooltipContainerProps
) {
  const { children } = props;

  return <Box width={25}>{children}</Box>;
}
