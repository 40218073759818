import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';

import { withModel } from '@rexlabs/model-generator';
import ui from 'data/models/custom/ui';
import { getOverlaySync } from 'utils/overlays';
import OverlayCloseButton from 'view/components/overlay/close-button';

@withModel(ui)
@autobind
class OverlayClose extends PureComponent {
  handleQuickNavClose(event) {
    // NOTE: This is recursive until the Rex2Frame is available
    if (window.Rex2FrameWindow) {
      // NOTE: We fire the event to close the open overlay instance
      window.Rex2FrameWindow.r2.ui.OverlayInstance.eventer.trigger('close');
    } else {
      setTimeout(() => this.handleQuickNavClose(event), 80); // Slight delay so we aren't causing major lag when the Rex2FrameWindow isn't available
    }
  }

  render() {
    const { ui } = this.props;
    const overlaySync = getOverlaySync(ui);
    const isFullOverlayActive = ui.isOverlayActive && overlaySync.isBlocking;

    return (
      <OverlayCloseButton
        hide={!ui.isOverlayCloseShowing || !isFullOverlayActive}
        show={ui.isOVerlayCloseShowing && isFullOverlayActive}
        onClick={this.handleQuickNavClose}
      />
    );
  }
}

export default OverlayClose;
