import React from 'react';
import ui from 'data/models/custom/ui';
import LoadingIndicator from 'view/components/loading-indicator';
import { compose } from 'redux';
import { withModel } from '@rexlabs/model-generator';

interface LoadingIndicatorPureProps {
  ui?: any;
}

function LoadingComponent({ ui }: LoadingIndicatorPureProps) {
  const shouldShowLoading = ui.loadingIndicator === 'ON';
  const currentLoadingMessage = ui.loadingIndicatorMessage;

  return (
    <LoadingIndicator
      data-testid={'Header.LoadingIndicator'}
      outsideHeader
      message={currentLoadingMessage}
      show={shouldShowLoading}
    />
  );
}

const LoadingIndicatorPure = compose(withModel(ui))(
  React.memo(LoadingComponent)
);

export default LoadingIndicatorPure;
