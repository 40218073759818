import { useMemo } from 'react';
import { ColumnsConfig } from 'components/record-list-screen/types';
import { Invoice } from 'features/apps/xero/types/invoice';
import { ContactCell } from 'components/record-list-screen/cells/contact-cell';
import { ListingCell } from 'components/record-list-screen/cells/listing-cell';
import { DateCell } from 'components/record-list-screen/cells/date-cell';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';
import { TagCell } from 'components/record-list-screen/cells/tag-cell';
import dayjs from 'dayjs';
import { COLORS } from 'shared/theme';
import { upperCase } from 'lodash';

export function useBillsColumns() {
  const region = useRegion();
  const currencySymbol = region.financial.currency.symbol;

  return useMemo(
    (): ColumnsConfig<Invoice> => [
      {
        label: 'Bill from',
        id: 'contact',
        selector: (row) => ({
          id: row.contact_id,
          name: row.contact_name
        }),
        Cell: ContactCell,
        hidden: false
      },
      {
        label: 'Bill number',
        id: 'invoice_number',
        hidden: false
      },
      {
        label: 'Listing',
        id: 'listing',
        Cell: ListingCell,
        selector: (row) => row.listing,
        hidden: false
      },
      {
        label: 'Due',
        id: 'due_date',
        Cell: DateCell,
        hidden: false
      },
      {
        label: 'Reference',
        id: 'reference',
        hidden: false
      },
      {
        label: 'Amount',
        id: 'system_total_amount',
        selector: (row) =>
          currencySymbol + formatCurrency(row.system_total_amount),
        hidden: false
      },
      {
        label: 'Balance',
        id: 'system_total_balance',
        selector: (row) =>
          currencySymbol + formatCurrency(row.system_total_balance),
        hidden: false
      },
      {
        label: 'Status',
        id: 'system_invoice_status',
        selector: ({ system_invoice_status, due_date }) => {
          const isOverdue = due_date && dayjs().isAfter(due_date);
          const isHidden =
            system_invoice_status === 'active' ||
            system_invoice_status === 'void';
          if (isHidden) return [];
          return [
            {
              style: 'normal',
              text: isOverdue ? 'LATE' : upperCase(system_invoice_status),
              backgroundColor: isOverdue
                ? COLORS.STATUS.RED
                : COLORS.STATUS.GREEN
            }
          ];
        },
        Cell: TagCell,
        cellProps: {
          emptyValue: ''
        },
        hidden: false
      }
    ],
    [currencySymbol]
  );
}
