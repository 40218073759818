import _ from 'lodash';
import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled } from '@rexlabs/styling';
import { HEIGHTS } from 'theme';
import { APP_MENU } from 'theme/legacy/components';
import GreyBox from './grey-box';
import SearchSkeletonHeader from './header';

@styled(APP_MENU.RECORDS)
class Skeleton extends PureComponent {
  render() {
    const { styles: s } = this.props;

    return (
      <Box
        {...s('container')}
        flexDirection='row'
        flexWrap='no-wrap'
        justifyContent='space-between'
      >
        <Box justifyContent='center' alignItems='center' {...s('iconBox')} />
        <Box
          flexDirection='column'
          flexWrap='no-wrap'
          justifyContent={'center'}
          flex={'1'}
          {...s('details')}
        >
          <GreyBox
            {...s('ellipsis')}
            style={{ height: '14px', marginBottom: '5px' }}
          />
          <GreyBox
            {...s('ellipsis')}
            style={{ height: '12px', marginBottom: '4px' }}
          />
          <GreyBox {...s('ellipsis')} style={{ height: '12px' }} />
        </Box>
        <Box flexDirection='column' {...s('extraDetails')}>
          <GreyBox
            style={{
              width: '55px',
              height: '12px',
              marginBottom: '4px'
            }}
          />
          <GreyBox style={{ width: '55px', height: '12px' }} />
        </Box>
      </Box>
    );
  }
}

@styled(APP_MENU.ROWS)
class SearchSkeletons extends PureComponent {
  render() {
    const { count, hasHeader, isScoped, type, styles: s } = this.props;
    return (
      <Box flexDirection='column' {...s('container')}>
        <Box
          {...s.with('wrapper')(
            isScoped && { paddingTop: HEIGHTS.APP_MENU.HEADING }
          )}
        >
          {hasHeader && <SearchSkeletonHeader />}
          <Box {...s('optionContainer')}>
            {_.times(count, (i) => (
              <Skeleton
                key={`skeleton-${type.toLowerCase()}-${i}`}
                isScoped={isScoped}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default SearchSkeletons;
