import Box from '@rexlabs/box';
import { ColumnConfig } from 'components/record-list-screen/types';
import { Body } from 'components/text/body';
import React, { useEffect, useMemo, useState } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import { IconButton } from 'src/view/components/button';
import {
  ReinzCustomValue,
  ReinzCustomValuesModel
} from '../../types/custom-value';
import dayjs from 'dayjs';
import { RecordListTable } from 'components/record-list-screen/table';
import { StateView } from 'components/record-list-screen/state-view';
import Spinner from 'shared/components/spinner';
import { withModel } from '@rexlabs/model-generator';
import reinzCustomValuesModel from '../../data/custom-value';
import { uniqueId } from 'lodash';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { useDialog } from 'hooks/use-dialog';
import { DialogBody } from 'components/text/dialog-body';

interface CustomValuesProps {
  reinzCustomValues: ReinzCustomValuesModel;
}

const CustomValues = ({ reinzCustomValues }: CustomValuesProps) => {
  const errorDialog = useErrorDialog();
  const addEditReinzCustomValue = useDialog('addEditReinzCustomValue');
  const confirmationDialog = useDialog('confirmation');
  const { fetchList, deleteItem } = reinzCustomValues;
  const [customValues, setCustomValues] = useState<ReinzCustomValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: ColumnConfig<ReinzCustomValue>[] = useMemo(
    () => [
      {
        id: 'value',
        label: 'value',
        forced: true,
        cellProps: {
          items: [
            {
              label: 'Edit',
              onClick: ({ data }) =>
                addEditReinzCustomValue.open({
                  reinzCustomValue: data,
                  onSuccess: (newCustomValue: ReinzCustomValue) => {
                    setCustomValues((prev) =>
                      prev.map((prevCustomValue) =>
                        prevCustomValue.id === newCustomValue.id
                          ? newCustomValue
                          : prevCustomValue
                      )
                    );
                  }
                })
            },
            {
              label: 'Delete',
              onClick: ({ data }) =>
                confirmationDialog.open({
                  title: 'Delete Report',
                  confirmText: 'Delete',
                  onConfirm: async () => {
                    try {
                      const res = await deleteItem({ id: data.id });
                      if (res.data.result) {
                        setCustomValues((prev) =>
                          prev.filter(
                            (prevCustomValue) => prevCustomValue.id !== data.id
                          )
                        );
                      }
                    } catch (error) {
                      errorDialog.open(error as Error);
                    }
                  },
                  message: (
                    <>
                      <DialogBody style={{ marginBottom: 15 }}>
                        Are you sure you want to delete this value?
                      </DialogBody>
                      <DialogBody>
                        Deleting this value will not affect published Sales and
                        Purchase Agreement forms, but will affect those
                        currently in progress.
                      </DialogBody>
                    </>
                  )
                })
            }
          ]
        }
      },
      {
        id: 'system_ctime',
        label: 'date added',
        selector: (row) => dayjs.unix(row.system_ctime).format('D MMM YYYY'),
        forced: true
      },
      {
        id: 'system_created_user',
        label: 'added by',
        selector: (row) => row.system_created_user.name,
        forced: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setIsLoading(true);
    fetchList({
      id: uniqueId(),
      args: {
        limit: 100
      }
    })
      .then((res) => {
        setCustomValues(res.data.map((row) => row.item));
        setIsLoading(false);
      })
      .catch((error) => {
        errorDialog.open(error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pt={20} pb={20}>
      <Box
        p={20}
        mb={20}
        justifyContent='center'
        style={{ backgroundColor: COLORS.BLACK }}
      >
        <Icon type={ICONS.REINZ_LOGO} hasControlledColor={false} />
      </Box>
      <Box p={20}>
        <h2 className='h2' style={{ marginBottom: 10 }}>
          Deposit
        </h2>
        <Body regular dark style={{ marginBottom: 30 }}>
          Add a custom value for ‘Deposit (refer to clause 2.0)’ in the Payment
          of Purchase Price section in a Sale & Purchase agreement.
        </Body>
        <Box alignItems={'center'} mb={20}>
          <h2 className='h2'>User Added Values</h2>
          <IconButton
            circle
            red
            Icon={ICONS.ADD_MEDIUM_THICK}
            onClick={() =>
              addEditReinzCustomValue.open({
                onSuccess: (newCustomValue: ReinzCustomValue) => {
                  setCustomValues((prev) => [newCustomValue, ...prev]);
                }
              })
            }
          >
            add new value
          </IconButton>
        </Box>
        <RecordListTable
          items={customValues}
          columns={columns}
          visibleColumns={columns.map((c) => c.id)}
          setVisibleColumns={() => null}
          hasSelection={false}
          setOrderBy={() => null}
          isLoading={isLoading}
          LoadingView={() => (
            <StateView noPadding>
              <Spinner small dark />
            </StateView>
          )}
          EmptyView={() => <StateView noPadding>No custom value</StateView>}
          variant={'compact'}
        />
      </Box>
    </Box>
  );
};

export default withModel(reinzCustomValuesModel)(CustomValues);
