import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

export function RightBarStatusLabel(props: BaseTextProps) {
  return (
    <Text
      is='span'
      {...props}
      type={map({
        RIGHTBAR_STATUSLABEL: true
      })}
    />
  );
}
