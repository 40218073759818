import React, { Component, PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PressAndEscapePropagator } from 'utils/events';
import { withModel } from '@rexlabs/model-generator';
import {
  parseRouteToUrl,
  parseUrlToRoute,
  buildQueryString
} from '@rexlabs/whereabouts';
import ui from 'data/models/custom/ui';
import uiClassicHeader from 'data/models/custom/ui-classic-header';
import invariant from 'invariant';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import config from 'shared/utils/config';

/**
 * Rex2 app as a React Component. :O
 */
@styled(
  StyleSheet({
    container: {
      height: '145px', // sourced from the classic implementation
      flex: '0 0 auto',
      margin: 0,
      padding: 0
    },
    minimized: {
      height: '53px', // sourced from the classic implementation
      width: '460px', // calculated from the classic implementation: 335+80+20+25
      position: 'absolute',
      bottom: 0,
      right: 0
    }
  })
)
@autobind
class ClassicStepthroughIFrame extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    // Note: See notes in componentWillReceiveProps as to why we set the url once.
    this.initialUrl = props.url;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { url: nextUrl } = nextProps;
    const { url: pastUrl } = this.props;
    if (nextUrl !== pastUrl) {
      if (__DEV__) {
        const routeUrl = nextUrl.replace(new RegExp(`^.*${location.host}`), '');
        const ourLocation = parseUrlToRoute(routeUrl);
        ourLocation.hashQuery.viewpath = '...';
      }

      /*
      |-------------------------------------------------------------------------
      | We want to use `location.replace` instead of updating the `src` attr on
      | the <iframe />, because we NEED to avoid unintentionally adding another
      | history entry to the Browser's tab.
      */
      if (nextUrl.replace(/#.*/, '') !== pastUrl.replace(/#.*/, '')) {
        window.Rex2StepthroughFrameWindow.dispatchEvent(new Event('teardown'));
      }
      window.Rex2StepthroughFrameWindow.location.replace(nextUrl);
    }
  }

  componentWillUnmount() {
    if (window.Rex2StepthroughFrameWindow) {
      window.Rex2StepthroughFrameWindow.dispatchEvent(new Event('teardown'));
      PressAndEscapePropagator.unregister(window.Rex2StepthroughFrameWindow);
      window.Rex2StepthroughFrameWindow = null;
      window.Rex2StepthroughIFrame = null;
    }
  }

  render() {
    const {
      styles: s,
      onPageLoad,
      isOverflowed,
      isMinimized,

      ...props
    } = this.props;

    return (
      <iframe
        {...s.with('container', {
          minimized: isMinimized,
          overflowed: isOverflowed
        })(props)}
        referrerPolicy='origin'
        title='StepthroughFrame'
        id='StepthroughFrame'
        frameBorder={0}
        width='100%'
        height='100%'
        src={this.initialUrl}
        ref={this.setRef}
        onLoad={onPageLoad}
      />
    );
  }

  setRef(iFrame) {
    if (iFrame) {
      window.Rex2StepthroughFrameWindow = iFrame.contentWindow;
      window.Rex2StepthroughIFrame = iFrame;
      window.Rex2StepthroughIFrame.__IS_STEPTHROUGH = true;
      window.Rex2StepthroughIFrame.__SHELL_STEPTHROUGH_TOGGLE_MINIMIZE =
        this.toggleStepthroughMinimize;
      window.Rex2StepthroughIFrame.__SHELL_STEPTHROUGH_ASSIGN_CLASSIC_DIALOGS_TO_STEPTHROUGH =
        this.swapStepthroughDialogsWithClassicDialogs;
      window.Rex2StepthroughIFrame.__SHELL_STEPTHROUGH_OVERFLOW_RAISE =
        this.setOverflowBuffer.bind(this, true);
      window.Rex2StepthroughIFrame.__SHELL_STEPTHROUGH_OVERFLOW_LOWER =
        this.setOverflowBuffer.bind(this, false);
    }
  }

  toggleStepthroughMinimize(shouldMinimize, callback) {
    this.props.onUpdateMinimized(shouldMinimize);
    callback();
  }

  setOverflowBuffer(isOn) {
    this.props.onUpdateOverflow(isOn);
  }

  swapStepthroughDialogsWithClassicDialogs() {
    // Forgive me
    // This is to make sure that framed Stepthrough will delegate it's dialog
    // opening to the Classic App Frame, because it's setup for that already.
    const doThang = () => {
      window.Rex2StepthroughFrameWindow.r2.ui.__dialogs =
        window.Rex2StepthroughFrameWindow.r2.ui.dialogs;
      window.Rex2StepthroughFrameWindow.r2.ui.dialogs =
        window.Rex2FrameWindow.r2.ui.dialogs;

      window.Rex2StepthroughFrameWindow.AVM.__dialogs =
        window.Rex2StepthroughFrameWindow.AVM.dialogs;
      window.Rex2StepthroughFrameWindow.AVM.dialogs =
        window.Rex2FrameWindow.AVM.dialogs;
    };
    if (window.Rex2FrameWindow.document.readyState !== 'complete') {
      window.Rex2FrameWindow.addEventListener('load', doThang);
    } else {
      doThang();
    }
  }
}

const CLASSIC_SCREENS_TO_REDIRECT = [/\/classic\/?.+/];

@withModel(uiClassicHeader)
@withModel(ui)
@styled({ container: '' })
@autobind
class ClassicStepthroughFrame extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      url: this.getEmbeddedStepthroughUrl(props)
    };
  }

  componentDidMount() {
    Analytics.track({
      event: EVENTS.STEPTHROUGH,
      options: { integrations: { Intercom: true } }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextUrl = parseRouteToUrl(nextProps.whereabouts);
    if (this.shouldHardRedirect(nextProps)) {
      window.parent.location.href = nextUrl;
    } else {
      // We can't be sure that query param or hash fragments mean we're changing screens!
      const currentUrl = parseRouteToUrl(this.props.whereabouts);
      if (nextUrl !== currentUrl) {
        // TODO: Review why this was being used as we don't need to update the Stepthrough's URL
        // this.setState(() => ({ url: this.getEmbeddedStepthroughUrl(nextProps) }))
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    // Block the render if we're going to do a hard redirect.
    return !this.shouldHardRedirect(nextProps);
  }

  render() {
    const {
      styles: s,
      isOverflowed,
      isMinimized,
      onUpdateOverflow,
      onUpdateMinimized,
      ...props
    } = this.props;
    const styles = s.with('container')(props);
    return (
      <ClassicStepthroughIFrame
        {...styles}
        url={this.state.url}
        onPageLoad={this.handlePageLoad}
        isOverflowed={isOverflowed}
        isMinimized={isMinimized}
        onUpdateOverflow={onUpdateOverflow}
        onUpdateMinimized={onUpdateMinimized}
      />
    );
  }

  handlePageLoad(ev) {
    invariant(
      ev.target.contentWindow === window.Rex2StepthroughFrameWindow,
      'The same window must be passed to register and unregister.'
    );
    PressAndEscapePropagator.register(ev.target.contentWindow);
  }

  shouldHardRedirect(props = this.props) {
    return CLASSIC_SCREENS_TO_REDIRECT.some((regexp) =>
      regexp.test(props.whereabouts.path)
    );
  }

  getEmbeddedStepthroughUrl(props = this.props) {
    const { whereabouts: w } = props;
    const { protocol, host } = window.location;

    const path = w.path ? w.path : '';
    let query = buildQueryString(w.query);
    query = query ? `&${query}` : '';
    const hash = w.hash ? `#${w.hash}` : '';

    // Note: This doesn't change between ClassicAppFrame, because stepthrough
    //       screen is setup to change it's *own* render based on whether
    //       it's being rendered into the Shell.
    return `${protocol}//${host}/embedded${path}?__releaseHash=${config.RELEASE?.HASH}${query}${hash}`;
  }
}

export default ClassicStepthroughFrame;
