import { Generator } from 'shared/utils/models';
import {
  CreateReinzFormPayload,
  DuplicateReinzFormPayload,
  ExportReinzFormPayload,
  PublishReinzFormPayload,
  ReinzForm
} from '../types/reinz-form';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

const TYPE = 'reinzForms';

const actionCreators = {
  createItem: {
    request: (data: CreateReinzFormPayload) => {
      return api.post('ReinzForms::create', { data });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  updateItem: {
    request: (data: ReinzForm) => {
      return api.post('ReinzForms::update', { data });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  deleteItem: {
    request: (data: Pick<ReinzForm, 'id'>) => {
      return api.post('ReinzForms::purge', data);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  duplicateItem: {
    request: (data: DuplicateReinzFormPayload) => {
      return api.post('ReinzForms::duplicate', data);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  publish: {
    request: (data: PublishReinzFormPayload) => {
      return api.post('ReinzForms::publish', data);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  exportItem: {
    request: (data: ExportReinzFormPayload) => {
      return api.post('ReinzForms::export', data);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const reinzFormsModel = new Generator<ReinzForm, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});

export default reinzFormsModel;

export const reinzTemplateType = new Generator(
  'reinzTemplateType'
).createSystemListModel();

export const reinzPropertyEstate = new Generator(
  'reinzPropertyEstate'
).createSystemListModel();

export const reinzNominee = new Generator(
  'reinzNominee'
).createSystemListModel();

export const reinzCustomValuesDeposit = new Generator(
  'reinzCustomValuesDeposit'
).createSystemListModel();

export const reinzPriceBalanceOptions = new Generator(
  'reinzPriceBalanceOptions'
).createSystemListModel();

export const reinzVendorEmailForServiceNotices = new Generator(
  'reinzVendorEmailForServiceNotices'
).createSystemListModel();

export const reinzPurchaserEmailForServiceNotices = new Generator(
  'reinzPurchaserEmailForServiceNotices'
).createSystemListModel();
