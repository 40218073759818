import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Id, ModelGeneratorLoaded } from '@rexlabs/model-generator';
import { UserItem } from 'data/models/value-lists/account-users';
import { ChainStub } from './chains';
import { ChainLinkResponseItem } from './chain-link-types';
import { ChainInstanceOption } from 'view/components/input/select/options/chain-instance';

export interface AssigneeItem {
  text: string;
  id: Id;
}
interface ChainInstanceItem {
  system_record_state: string;
  system_ctime: number;
  system_modtime: number;
  etag: string;
  system_modified_user: UserItem;
  system_created_user: UserItem;
  chain: ChainStub;
  primary_link: ChainLinkResponseItem;
  archive_reason: any;
  assignee_user?: AssigneeItem;
  id: string;
}

interface ArchivePayload {
  id: Id;
  reason: any;
}

interface UpdateAssigneePayload {
  id: Id;
  assignee_user_id: Id;
}

export interface ChainInstanceModel
  extends ModelGeneratorLoaded<ChainInstanceItem, any> {
  unarchive: ({ id }: { id: Id }) => Promise<ChainInstanceItem>;
  purgeItem: ({ id }: { id: Id }) => Promise<ChainInstanceItem>;
  archive: ({ id, reason }: ArchivePayload) => Promise<ChainInstanceItem>;
  updateAssignee: ({
    id,
    assignee_user_id
  }: UpdateAssigneePayload) => Promise<ChainInstanceItem>;
}

const actionCreators = {
  archive: {
    request: ({ id, reason }, actions) =>
      api
        .post('ChainInstances::archiveWithReason', {
          id,
          archive_reason_id: reason
        })
        .then(() => actions.fetchItem({ id })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  unarchive: {
    request: ({ id }, actions) =>
      api
        .post('ChainInstances::recoverFromArchive', { id })
        .then(() => actions.fetchItem({ id })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  purgeItem: {
    request: ({ id }) =>
      api
        .post('ChainInstances::purge', { id })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  updateAssignee: {
    request: ({ id, assignee_user_id }) =>
      api
        .post('ChainInstances::updateAssignee', { id, assignee_user_id })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const TYPE = 'chainInstances';
const chainInstancesModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

chainInstancesModel.select = {
  Option: ChainInstanceOption,
  autocomplete: (searchKey) =>
    api
      .post('ChainInstances::search', {
        criteria: [
          {
            name: 'primary_chain_link.link_contract.property.system_search_key',
            type: 'like',
            value: `%${searchKey}%`
          },
          {
            name: 'system_record_state',
            value: 'active'
          }
        ]
      })
      .then(({ data }) =>
        _.get(data, 'result.rows', []).map((instance) => ({
          value: _.get(instance, 'id'),
          label: `${_.get(
            instance,
            'primary_link.contract.listing.property.system_search_key'
          )}`,
          model: chainInstancesModel,
          data: instance
        }))
      )
};

export default chainInstancesModel;

export function getChainInstanceBuyerName(instance: ChainInstanceItem) {
  const contactNames = instance?.primary_link?.contact_names;
  if (contactNames) {
    return contactNames;
  }

  return instance?.primary_link?.contract?.related?.listing_contract_purchtenants?.map(
    (r) => r.purchtenant.name
  )[0];
}
