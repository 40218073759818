import React, { PureComponent } from 'react';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import Spinner from 'shared/components/spinner';
import { ZINDEX } from 'theme';

const spinnerOverrides = {
  Spinner: StyleSheet({
    container: {
      margin: '3px 15px 0px 15px'
    }
  })
};

const defaultStyles = StyleSheet({
  container: {
    zIndex: ZINDEX.SHELLNAVIGATION,
    padding: '20px 25px',
    background: 'rgba(0,0,0,0.8)',
    borderRadius: '0px 0px 3px 3px',
    position: 'absolute',
    left: '50%',
    top: 0,
    color: 'white',
    transform: 'translateY(-55px) translateX(-50%)',
    transition: 'transform 250ms ease',
    maxHeight: '55px'
  },
  message: {
    fontWeight: '600',
    fontSize: '16px',
    color: 'white',
    textTransform: 'lowercase',
    whiteSpace: 'nowrap',
    paddingRight: '10px'
  },
  show: {
    transform: 'translateY(0) translateX(-50%)'
  },
  showNewPosition: {
    transform: 'translateY(125px) translateX(-50%)',
    zIndex: 20
  },
  outsideHeader: {
    zIndex: 22
  },
  noAnimate: {
    transitionDuration: '0s'
  }
});

const ANIMATION_BUFFER_MS = 300;

@styled(defaultStyles)
class LoadingIndicator extends PureComponent {
  state = {
    hasMounted: false
  };

  componentDidMount() {
    setTimeout(() => this.setState({ hasMounted: true }), ANIMATION_BUFFER_MS);
  }

  render() {
    const {
      styles: s,
      show,
      message,
      className,
      newPosition,
      outsideHeader,
      'data-testid': testId
    } = this.props;

    return (
      <StylesProvider
        components={spinnerOverrides}
        prioritiseParentStyles={false}
      >
        <Box
          data-testid={testId}
          {...s.with(
            'container',
            { show: show && !newPosition },
            { showNewPosition: show && newPosition },
            { outsideHeader: outsideHeader },
            { noAnimate: !this.state.hasMounted }
          )({ className })}
        >
          {message && <span {...s('message')}>{message}</span>}
          <Spinner small />
        </Box>
      </StylesProvider>
    );
  }
}

export default LoadingIndicator;
