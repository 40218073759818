import { Generator } from 'shared/utils/models';
import { api, transformItem } from 'shared/utils/api-client';
import { identity } from 'lodash';

const initialState = {
  calendars: []
};

const selectors = {
  calendars: (state) => state?.connectableCalendars?.calendars
};

const actionCreators = {
  getLoginFlowUrl: {
    request: (payload) =>
      api.post('UserCalendars::getLoginFlowUrl', payload).then(transformItem),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  completeLoginFlow: {
    request: (payload) =>
      api.post('UserCalendars::completeLoginFlow', payload).then(transformItem),
    reduce: {
      initial: identity,
      success: (state, action) => ({
        ...state,
        calendars: action.payload.data
      }),
      failure: identity
    }
  },
  getExtendedAuthFlowUrl: {
    request: (payload) =>
      api
        .post('UserCalendars::getExtendedAuthFlowUrl', payload)
        .then(transformItem),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  completeExtendedAuthFlow: {
    request: (payload) =>
      api
        .post('UserCalendars::completeExtendedAuthFlow', payload)
        .then(transformItem),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getConnectableCalendars: {
    request: (payload) =>
      api
        .post('UserCalendars::getConnectableCalendars', payload)
        .then(transformItem),
    reduce: {
      initial: identity,
      success: (state, action) => ({
        ...state,
        calendars: action.payload?.data || []
      }),
      failure: identity
    }
  },
  connectCalendar: {
    request: (payload) =>
      api.post('UserCalendars::connect', payload).then(transformItem),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  completeAuthorisation: {
    request: (payload) =>
      api
        .post('UserCalendars::completeAuthorisation', payload)
        .then(transformItem),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  disconnectCalendar: {
    request: (payload) =>
      api.post('UserCalendars::disconnect', payload).then(transformItem),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

export default new Generator('connectableCalendars').createModel({
  initialState,
  actionCreators,
  selectors
});
