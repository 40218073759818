import { Generator } from 'shared/utils/models';
import Fixture from 'view/components/input/select/fixtures/agency.js';
import React from 'react';
import _ from 'lodash';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';
import { api } from 'shared/utils/api-client';

const TYPE = 'localAgency';
const marketLeadAgencyModel = new Generator(TYPE).createValueListModel();

const handleFixtureClick = (initialValue, cb) =>
  DialogsBridge.addAgency.openClassic({
    title: 'Add Local Agency',
    content: [
      {
        label: 'Agency Name',
        value: initialValue
      }
    ],
    callback: (fields) =>
      api
        .post('AdminValueLists::addValueToList', {
          list_name: 'local_agency',
          display_text: fields[0].value
        })
        .then((response) => {
          _.isFunction(cb) && cb(response);
        })
  });

marketLeadAgencyModel.select = {
  // Option: ContactsOption,
  Fixture: (props) => (
    <Fixture
      text='Add New Agency'
      onClick={() =>
        handleFixtureClick(
          props.term,
          _.get(props, 'fixturePrefillData.callback')
        )
      }
      {...props}
    />
  )
};

export default marketLeadAgencyModel;
