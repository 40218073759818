/* eslint-disable babel/no-invalid-this */
import Validator, {
  createValidationRules as originalValidationRules
} from '@rexlabs/validator';
import _ from 'lodash';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs-ext/plugin/customParseFormat';
dayjs.extend(customParseFormat);

Validator.register(
  'time',
  (value) => {
    const { time } = value;
    if (time.hour && time.minute && time.time) {
      return (
        time.hour < 25 &&
        time.minute < 60 &&
        time.time ===
          `${time.hour.padStart(2, '0')}:` +
            `${time.minute.padStart(2, '0')}:00`
      );
    }
    return false;
  },
  'Please enter a valid time for the :attribute field'
);

Validator.register(
  'date_before',
  function () {
    /**
     * Due to the way this function is bound we have access to the internal context which allows us
     * to have more control over the validation rule. The values that are passed in via the
     * arguments aren't helpful for this particular validation rule.
     */
    const inputDate = dayjs(this.inputValue, { format: 'DD/MM/YYYY' });
    const ruleDate = dayjs(this.ruleValue, { format: 'DD/MM/YYYY' });

    if (!inputDate.isValid() || !ruleDate.isValid()) {
      return false;
    }

    return inputDate.isBefore(ruleDate);
  },
  'The :attribute must be before :date_before'
);

Validator.register(
  'date_after',
  function () {
    const inputDate = dayjs(this.inputValue, { format: 'DD/MM/YYYY' });
    const ruleDate = dayjs(this.ruleValue, { format: 'DD/MM/YYYY' });

    if (!inputDate.isValid() || !ruleDate.isValid()) {
      return false;
    }

    return inputDate.isAfter(ruleDate);
  },
  'The :attribute must be after :date_after'
);

Validator.register(
  'document_type_regex',
  function () {
    let regex;
    let rule = this.ruleValue.replace(/____\$____/g, '|');

    try {
      // Note: this is removing the first and last characters + the flag from the user provided
      // 'complete' regex string (it already has the "/" at the start and end, when making a
      // `new RegExp(exp)` JS assumes it doesn't and it takes the flag separately as a second param)
      // Source: https://github.com/skaterdav85/validatorjs/blob/1dd01de4931815bfca88cd9ce2c6d19349e9fa52/src/rules.js#L338-L345
      const mod = /[g|i|m]{1,3}$/;
      let flag = rule.match(mod);
      flag = flag ? flag[0] : '';
      rule = rule.replace(mod, '').slice(1, -1);
      regex = new RegExp(rule, flag);
    } catch (e) {
      console.error(e);
    }

    if (!regex) {
      // Note: Custom for the add verification dialog, so the user knows the regex they've setup in the admin area/API is invalid
      this._customMessage =
        'The regex rule for that document type is invalid, please check document reference validation rule settings';
      return false;
    }

    return this.inputValue?.match(regex);
  },
  'The :attribute must match the regex'
);

Validator.registerImplicit(
  'duplicate_email',
  function () {
    const input = this.inputValue;
    const inputEmail = !input?.model
      ? input?.value
      : input?.email
      ? input.email
      : input.data.email_address;
    return inputEmail !== this.ruleValue;
  },
  'All signers must have a unique email'
);

Validator.registerImplicit(
  'duplicate_user',
  function () {
    return this.inputValue.value !== this.ruleValue;
  },
  'You can not use the same user for different roles'
);

export function createValidationRules(...args) {
  return (values, extra) => {
    // We don't want to run any validations until the user submitted the form
    // for the first time!
    if (_.get(extra, 'submitCount') < 1) {
      return {};
    }
    return originalValidationRules(...args)(values, extra);
  };
}
