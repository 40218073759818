export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

/**
 * Usage:
 * openNewTab(model.fetchEntity().then(url => url));
 */
export function openNewTab(promiseFunc: Promise<string>) {
  let windowRef: Window | null = null;
  if (isSafari()) windowRef = window.open();
  return promiseFunc.then((url) => {
    if (windowRef) windowRef.location.assign(url);
    else window.open(url, '_blank');
  });
}
