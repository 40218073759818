import { useState } from 'react';
import { useModelState } from '@rexlabs/model-generator';
import { reportingFiltersModel } from 'features/custom-reporting/data/reporting-filters';
import sessionModel from 'data/models/custom/session';
import { useSelectedUsersForPermissions } from 'features/custom-reporting/hooks/use-async-report-data';

export function usePersistedReportState() {
  const session = useModelState(sessionModel);
  const filters = useModelState(reportingFiltersModel);
  const selectedUsersForPermissions = useSelectedUsersForPermissions();

  const accountId = session?.office_details?.id;
  const location = filters[accountId]?.location || [];

  const initialStateFromLocalStorage = {
    ...(selectedUsersForPermissions
      ? {
          selectedUserFilter: selectedUsersForPermissions
        }
      : {}),
    ...(location
      ? {
          selectedLocationFilter: location
        }
      : {})
  };

  const [pinnedValue] = useState(initialStateFromLocalStorage);

  return pinnedValue;
}
