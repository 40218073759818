import React from 'react';
import { ICONS } from 'shared/components/icon';
import DialogBridge from 'data/classic-bridges/dialogs-shell';
import _ from 'lodash';
import { mapFormToApi } from 'utils/calendar';
import { api } from 'shared/utils/api-client';
import { store } from 'shell/src/store';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import { propertyActions } from 'features/properties/data/property-actions';

/**
 * This is housing the global add config for both the Classic configuration and the new Shell header configuration.
 *
 * The backIcon / frontImage / backImage are used for the Classic global add and the icon fields are used
 * with the new Shell global add tooltip.
 */
export default () => {
  return {
    LEFT_MENU: [
      {
        name: 'Add Contact',
        icon: ICONS.CONTACTS,
        backIcon: ICONS.ADD,
        accessRights: 'contacts.create',
        onClick: () => {
          DialogBridge.addContact.open({});
        }
      },
      {
        name: 'Add Property',
        icon: ICONS.PROPERTIES,
        backIcon: ICONS.ADD,
        accessRights: 'properties.create && !addon.hide_real_estate',
        onClick: ({ editProperties, errorDialog, notice, properties }) => {
          editProperties.open({
            callback: propertyActions({
              errorDialog,
              notice,
              properties
            }).createProperty
          });
        }
      },
      {
        name: 'Add Deal',
        icon: ICONS.DEALS,
        backIcon: ICONS.ADD,
        accessRights: 'addon.deals',
        onClick: ({ editDeal, deals }) =>
          editDeal.open({
            // This deferred  parameter has be passed through from the Classic dialog specifically
            // so Shell can return what the dialog expects then reject/resolve on Shell api client result
            callback: (dealData, deferred) => {
              deals
                .createItem({ data: dealData })
                .then(deferred.resolve)
                .catch(deferred.reject);

              return deferred;
            }
          })
      },
      {
        name: 'Add Company',
        icon: ICONS.COMPANY,
        backIcon: ICONS.ADD,
        accessRights: 'contacts.create',
        onClick: () => {
          DialogBridge.addCompany.open({});
        }
      }
    ],

    RIGHT_MENU: [
      // PAYWALL HERE - addon name: click_to_dial
      _.get(store.getState(), 'session.telephony_available_methods.length', 0) >
        0 && {
        name: 'Make Call',
        icon: ICONS.PHONE,
        frontImage: '/assets/images/icons/size-20-20/white-email.png',
        backImage: '/assets/images/icons/size-20-20/white-next.png',
        onClick: () =>
          window.parent.Rex2FrameWindow.AVM.shell.dialogs.makeCall.open()
      },
      {
        name: 'Send Email',
        icon: ICONS.EMAIL,
        frontImage: '/assets/images/icons/size-20-20/white-email.png',
        backImage: '/assets/images/icons/size-20-20/white-next.png',
        onClick: ({ editMailMerge }) =>
          editMailMerge.open({
            data: {
              mode: 'contact',
              medium: 'email',
              data: {}
            },
            options: {
              title: 'Send Quick Email'
            }
          })
      },
      {
        name: 'Send Letter',
        icon: ICONS.LETTER,
        frontImage: '/assets/images/icons/size-20-20/white-letter.png',
        backImage: '/assets/images/icons/size-20-20/white-next.png',
        onClick: ({ editMailMerge }) =>
          editMailMerge.open({
            data: {
              mode: 'contact',
              medium: 'letter',
              data: {}
            },
            options: {
              title: 'Send Quick Letter'
            }
          })
      },
      {
        name: 'Send SMS',
        icon: ICONS.SMS,
        frontImage: '/assets/images/icons/size-20-20/white-sms.png',
        backImage: '/assets/images/icons/size-20-20/white-next.png',
        accessRights: 'mail_merge.send_sms',
        onClick: ({ editMailMerge }) =>
          editMailMerge.open({
            data: {
              mode: 'contact',
              medium: 'sms',
              data: {}
            },
            options: {
              title: 'Send Quick SMS'
            }
          })
      },
      {
        name: 'Start Workflow',
        icon: ICONS.WAND,
        frontImage: '/assets/images/icons/wand-20.png',
        backImage: '/assets/images/icons/size-20-20/white-next.png',
        onClick: () => {
          // Probably needs an on submit
          // Currently nothing is intended to happen after starting a workflow, but eventually this
          // may or may not depend on the workflow being started
          DialogBridge.startSelectWorkflow.open({});
        },
        accessRights: 'addon.workflows || addon.system_workflows'
      }
    ].filter(Boolean),

    FOOTER_MENU: [
      {
        name: 'note',
        icon: () => <ICONS.NOTE style={{ width: '15px' }} />,
        image: '/assets/images/icons/size-20-20/dark-grey-note.png',
        onClick: ({ editNote, notes }) => {
          editNote.open({
            data: {},
            options: {},
            // This deferred  parameter has be passed through from the Classic dialog specifically
            // so Shell can return what the dialog expects then reject/resolve on Shell api client result
            callback: (noteData, deferred) => {
              noteData._related = noteData._related || noteData.related;
              noteData.note_type = noteData.noteType;
              notes
                .createItem({ data: noteData })
                .then(deferred.resolve)
                .catch(deferred.reject);

              return deferred;
            }
          });
        }
      },
      {
        name: 'reminder',
        icon: () => <ICONS.REMINDER style={{ width: '15px' }} />,
        image: '/assets/images/icons/size-20-20/dark-grey-reminder.png',
        onClick: ({ editReminder, reminders }) => {
          editReminder.open({
            data: {},
            // This deferred  parameter has be passed through from the Classic dialog specifically
            // so Shell can return what the dialog expects then reject/resolve on Shell api client result
            callback: (reminderData, deferred) => {
              reminders
                .createItem({ data: reminderData })
                .then(deferred.resolve)
                .catch(deferred.reject);

              return deferred;
            }
          });
        }
      },
      {
        name: 'appointment',
        icon: () => <ICONS.CALENDAR style={{ width: '20px' }} />,
        image: '/assets/images/icons/size-20-20/dark-grey-feedback.png',
        accessRights: 'calendars.create_event', // TODO: double check this priv
        onClick: () =>
          DialogBridge.editAppointment.open({
            handleSave: (formData) => {
              Analytics.track({
                event: EVENTS.CALENDAR.CREATE_APPOINTMENT,
                options: { integrations: { Intercom: true } }
              });
              if (formData.followup_reminder) {
                Analytics.track({
                  event: EVENTS.CALENDAR.CREATE_FOLLOWUP_REMINDER
                });
              }

              const apiData = mapFormToApi(formData);
              return api.post('CalendarEvents::create', { data: apiData });
            }
          })
      },
      {
        name: 'feedback',
        icon: () => <ICONS.FEEDBACK style={{ width: '15px' }} />,
        image: '/assets/images/icons/size-20-20/dark-grey-feedback.png',
        onClick: ({ editFeedback, feedback }) => {
          editFeedback.open({
            mode: 'both',
            // This deferred  parameter has be passed through from the Classic dialog specifically
            // so Shell can return what the dialog expects then reject/resolve on Shell api client result
            callback: (feedbackData, deferred) => {
              feedback
                .createItem({ data: feedbackData })
                .then(({ data }) => {
                  deferred.resolve(data.result);
                  // Feedback status will be automatically set to pending via the 'create' api
                  if (!feedbackData.system_approval_status) {
                    return;
                  }
                  feedback.trySetApprovalStatus({
                    id: _.get(data, 'result.id'),
                    status: feedbackData.system_approval_status
                  });
                })
                .catch(deferred.reject);

              return deferred;
            }
          });
        }
      }
    ]
  };
};
