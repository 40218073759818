import { Criteria } from 'types/criteria';
import { differenceBy } from 'lodash';
// Specification is this:
// Top level filter controls should override grid filters and advanced filters,
// grid filters should also override advanced filters.
// TODO: I haven't implemented overriding grid filters yet since I don't know the shape of that yet
// SC - https://app.shortcut.com/rexlabs/story/67281/fe-state-management-move-existing-grid-module-config-from-experiment
export function mergeReportCriteria({
  topLevelCriteria,
  saved_advanced_criteria,
  reportScopeCriteria = []
}: {
  topLevelCriteria: Record<string, any>[];
  saved_advanced_criteria?: Criteria[];
  reportScopeCriteria?: Criteria[];
}) {
  if (!saved_advanced_criteria && !reportScopeCriteria) {
    return topLevelCriteria;
  }
  // remove any saved advanced criteria that have the same name as top level criteria
  const advancedCriteria = differenceBy(
    saved_advanced_criteria,
    topLevelCriteria,
    'name'
  );

  return [...topLevelCriteria, ...advancedCriteria, ...reportScopeCriteria];
}
