import React, { Component } from 'react';
import EntityOption from 'view/components/input/select/options/entity';
import Contact from 'assets/icons/contact.svg';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

@connect((state) => ({ session: state.session }))
@autobind
class ContactsOption extends Component {
  getRecordOwner(contactRecordOwner) {
    const { session } = this.props;

    return contactRecordOwner?.id === session?.user_details?.id
      ? 'My Contact'
      : contactRecordOwner?.name;
  }

  pluckStatus(option) {
    const optionData = option?.data;
    const ownerName = this.getRecordOwner(optionData?.system_owner_user);

    return this.props.compactStatus
      ? [optionData?.phone_number, optionData?.email_address, ownerName].filter(
          Boolean
        )
      : [optionData?.email_address].filter(Boolean);
  }

  render() {
    return (
      <EntityOption
        {...this.props}
        Icon={Contact}
        pluckStatus={this.pluckStatus}
      />
    );
  }
}

export default ContactsOption;
