import React, { PureComponent } from 'react';
import DefaultValue from './default';

import { ListingPreviewPopout } from 'features/listings/popouts/listing-preview-popout';
import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';
import { PropertyPreviewPopout } from 'features/properties/popouts/property-preview-popout';

import CampaignTooltip from 'view/components/popout/campaign';
import ProjectTooltip from 'view/components/popout/project';
import ProjectStageTooltip from 'view/components/popout/project-stage';

import { ICONS } from 'shared/components/icon';

/**
 * Generally the key will be the TYPE variable defined
 * in the entity model.
 */
const iconMapping = (service) => {
  const mapping = {
    listings: ICONS.SELECT.LISTINGS,
    campaigns: ICONS.CAMPAIGN,
    contacts: ICONS.CONTACTS,
    marketLeads: ICONS.MARKET_LEAD,
    projects: ICONS.PROJECT,
    projectStages: ICONS.PROJECT_STAGE,
    postcodeAreas: ICONS.SELECT.VALUE.POSTCODE_AREA,
    postcodeDistricts: ICONS.SELECT.VALUE.POSTCODE_DISTRICT_SECTOR,
    postcodeSectors: ICONS.SELECT.VALUE.POSTCODE_DISTRICT_SECTOR,
    postcodes: ICONS.SELECT.VALUE.POSTCODE,
    properties: ICONS.SELECT.PROPERTIES,
    streetNames: ICONS.SELECT.VALUE.STREET,
    suburbs: ICONS.SELECT.VALUE.TOWN_CITY
  };

  return mapping[service] || null;
};

/**
 * Generally the key will be the TYPE variable defined
 * in the entity model.
 */
const tooltipMapping = (service) => {
  const mapping = {
    listings: ListingPreviewPopout,
    contacts: ContactPreviewPopout,
    properties: PropertyPreviewPopout,
    campaigns: CampaignTooltip,
    projects: ProjectTooltip,
    projectStages: ProjectStageTooltip
  };

  return mapping[service] || null;
};

class Value extends PureComponent {
  render() {
    const { service, ...rest } = this.props;
    const Icon = iconMapping(service);
    const Tooltip = tooltipMapping(service);

    return <DefaultValue {...rest} Tooltip={Tooltip} Icon={Icon} />;
  }
}

export default Value;
