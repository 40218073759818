import React, { useState } from 'react';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import { HorizontalDivider } from 'components/horizontal-divider';
import { Body } from 'components/text/body';
import { COLORS } from 'src/theme';
import { TextButton } from 'src/view/components/button';

const styles = StyleSheet({
  head: {
    backgroundColor: COLORS.CORAL_PINK_FAINT
  }
});

export interface ErrorListProps {
  errors: string[];
}

const ErrorList = ({ errors }: ErrorListProps) => {
  const s = useStyles(styles);
  const [showDetails, setShowDetails] = useState(false);
  return (
    <Box mb={30}>
      <Box
        pl={15}
        h={40}
        alignItems='center'
        justifyContent='space-between'
        {...s('head')}
      >
        <Body small dark semibold>
          {errors?.length === 1
            ? `${errors?.length} error was found`
            : `${errors?.length} errors were found`}
        </Body>
        <TextButton
          IconRight={() => (
            <Icon
              type={ICONS.DOWN}
              style={{
                display: 'flex',
                transform: showDetails ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          )}
          textDecoration='none'
          onClick={() => setShowDetails((prev) => !prev)}
        >
          {showDetails ? 'Hide details' : 'Show details'}
        </TextButton>
      </Box>
      {showDetails && (
        <Box>
          {errors?.map((error, i) => (
            <Box key={`error-${i}`}>
              <Box
                height={53}
                p={'0 15px'}
                flexDirection='row'
                alignItems='center'
                spacing={10}
              >
                <Icon
                  width={23}
                  heigth={23}
                  type={ICONS.WARNING_RED}
                  hasControlledColor={false}
                />
                <Body dark normal>
                  {error}
                </Body>
              </Box>
              <HorizontalDivider style={{ margin: 0 }} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ErrorList;
