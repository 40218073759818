import React from 'react';
import { Body } from 'components/text/body';
import { FormField } from 'view/components/form';
import { Select, ValueListSelect } from 'view/components/input/select';
import dayjs from 'dayjs';
import { COLORS, PADDINGS } from 'shared/theme';
import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import { Grid, Column } from 'shared/components/grid';
import {
  matchCampaignFrequency,
  matchCampaignFrequencySms
} from 'data/models/system-lists/match-campaign-frequency';
import { BuyerMatchSettingsData } from 'data/models/entities/admin-buyer-match';
import { dayOfWeek } from 'data/models/system-lists/day-of-week';
import { SettingsType } from './types';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

const TIME_OPTIONS = Array.from(Array(96).keys()).map((num) => {
  const time = dayjs('1/1/1 9:00 AM').add(15 * num, 'minute');
  return {
    value: time.format('HH:mm:00'),
    label: time.format('h:mm A')
  };
});

const EXPIRES_OPTIONS = [
  {
    value: 1,
    label: '1 week'
  },
  {
    value: 2,
    label: '2 weeks'
  },
  {
    value: 4,
    label: '4 weeks'
  },
  {
    value: 24,
    label: '6 months'
  },
  {
    value: 48,
    label: '12 months'
  },
  {
    value: -1,
    label: 'Indefinitely'
  }
];

interface AutoMatchCampaignProps {
  values?: BuyerMatchSettingsData & { apply_to_child_accounts: boolean };
  type: SettingsType;
}

interface TimeSelectProps extends AutoMatchCampaignProps {
  medium: 'sms' | 'email';
}

function TimeSelect({ values, medium, type }: TimeSelectProps) {
  const parentKey = `${type}_campaign_${medium}`;
  if (values?.[parentKey]?.frequency === 'never') return null;
  return (
    <>
      <Column width={1}>
        {values?.[parentKey]?.frequency === 'immediate' ? (
          <Box mt={12.5}>
            <Body semibold small dark>
              between
            </Body>
            <Box flexDirection='row' alignItems='flex-end' spacing={5} mt={-10}>
              <FormField
                name={`${parentKey}.send_time`}
                Input={Select}
                inputProps={{
                  options: TIME_OPTIONS
                }}
              />
              <p
                style={{
                  lineHeight: '29px',
                  fontSize: '29px',
                  color: '#D9D9D4'
                }}
              >
                -
              </p>
              <FormField
                name={`${parentKey}.send_time_end`}
                Input={Select}
                inputProps={{
                  options: TIME_OPTIONS
                }}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box flexDirection='row' alignItems='flex-end' spacing={10}>
              {values?.[parentKey]?.frequency !== 'daily' && (
                <FormField
                  name={`${parentKey}.send_day`}
                  label={'on'}
                  Input={ValueListSelect}
                  inputProps={{
                    models: [dayOfWeek]
                  }}
                />
              )}
              <FormField
                name={`${parentKey}.send_time`}
                label={'at'}
                Input={Select}
                inputProps={{
                  options: TIME_OPTIONS
                }}
              />
            </Box>
          </>
        )}
      </Column>
      <Column width={1} mt={-5}>
        <FormField
          name={`${parentKey}.expire_in_weeks`}
          label={'expires'}
          Input={Select}
          inputProps={{
            options: EXPIRES_OPTIONS
          }}
        />
      </Column>
    </>
  );
}

export function AutoMatchCampaign({ values, type }: AutoMatchCampaignProps) {
  const settingsType = type === 'res_sale' ? 'Sale' : 'Rental';
  return (
    <Box p={PADDINGS.M} m={'-20px 0 0 0'} spacing={PADDINGS.S}>
      <SubHeading semibold style={{ marginLeft: 0 }}>
        {hasFeatureFlags('auto_match_rentals')
          ? `Automatic Residential ${settingsType} Match Campaigns`
          : 'Automatic Match Campaigns'}
      </SubHeading>
      <Body regular dark style={{ marginLeft: 0, marginBottom: 15 }}>
        {hasFeatureFlags('auto_match_rentals')
          ? `Automate match campaigns for all residential ${settingsType.toLowerCase()} profiles created automatically. Only new Listings created from this point onwards will be sent to contacts.`
          : `Send automated match campaigns for every residential sale match profile
        created automatically. Only new Listings created from this point onwards
        will be sent to contacts`}
      </Body>
      <Body semibold dark style={{ marginLeft: 0 }}>
        Email Settings
      </Body>
      <Grid columns={2} mt={-10} mb={20}>
        <Column width={1}>
          <FormField
            name={`${type}_campaign_email.frequency`}
            label='status'
            Input={ValueListSelect}
            inputProps={{
              models: [matchCampaignFrequency]
            }}
          />
        </Column>
        <Column width={1} />
        <TimeSelect values={values} medium='email' type={type} />
      </Grid>
      <Body semibold dark style={{ marginLeft: 0 }}>
        SMS Settings
      </Body>
      <Grid columns={2} mt={-10}>
        <Column width={1}>
          <FormField
            name={`${type}_campaign_sms.frequency`}
            label='status'
            Input={ValueListSelect}
            inputProps={{
              models: [matchCampaignFrequencySms]
            }}
          />
        </Column>
        <Column width={1} />
        <TimeSelect values={values} medium='sms' type={type} />
      </Grid>
    </Box>
  );
}
