import { Criteria } from 'types/criteria';

import { identity } from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

import { UserItem } from 'data/models/value-lists/account-users';
import { UserGroupsItem } from 'data/models/entities/admin-user-groups';
import { reduceItemResponseToState } from 'data/models/utils/reduce-item-response-to-state';

import { ModuleName } from '../modules/module-config-types';

const TYPE = 'customReports';

interface CustomReportUserGroup {
  id: number;
  user_group: Pick<
    UserGroupsItem,
    'description' | 'id' | 'is_hidden' | 'library' | 'name'
  >;
}
export interface CustomReportItem {
  account_id: number;
  criteria: { name: string; type: string; value: string | null };
  description: string | null;
  etag: string;
  id: string;
  is_hidden: boolean | null;
  is_public: boolean;
  library: { id: string; library_name: string } | null;
  name: string;
  system_record_state: string;
  system_assigned_by_user: UserItem;
  system_assigned_time: string;
  system_completed_by_user: UserItem;
  system_completed_time: string;
  system_created_user: UserItem;
  system_ctime: string;
  system_modified_user: UserItem;
  system_modtime: string;
  security_user_rights: string[];
  module: {
    id: string;
    text: string;
  };
  module_group: {
    id: ModuleName;
    text: string;
  };
  report_details: Record<string, any> | null;
  is_favourite: boolean;
  related: {
    user_groups: CustomReportUserGroup[];
  };
}

export interface CreateCustomReportObject
  extends Partial<
    Pick<CustomReportItem, 'name' | 'report_details' | 'is_public'>
  > {
  module_id?: string;
  module_group_id?: string;
  library?: string | null;
  related?: {
    user_groups: {
      user_group_id: string;
    }[];
  };
}

export type UpdateCustomReportObject = CreateCustomReportObject &
  Pick<CustomReportItem, 'id'>;

interface GridFilter {
  filterType: string;
  type: string;
  filter: string;
}

export interface CustomReportStateStructure {
  module: string;
  top_level_filter_preference: {
    date: string;
    user: string;
  };
  should_filter_by_date: boolean;
  saved_advanced_criteria?: Criteria[];
  selected_columns?: Record<string, any>[];
  grid_filters: Record<string, GridFilter>;
}

export const mockState: CustomReportStateStructure[] = [
  {
    module: 'appointments',
    top_level_filter_preference: {
      date: 'system_ctime',
      user: 'owner_user_id'
    },
    should_filter_by_date: true,
    saved_advanced_criteria: [],
    // selected_columns: [],
    grid_filters: {}
  },
  {
    module: 'appointments',
    top_level_filter_preference: {
      date: 'system_ctime',
      user: 'owner_user_id'
    },
    should_filter_by_date: false,
    saved_advanced_criteria: [],
    // selected_columns: [],
    grid_filters: {}
  },
  {
    module: 'listings',
    top_level_filter_preference: {
      date: 'sold_date',
      user: 'listing.listing_agent_id'
    },
    should_filter_by_date: true,
    saved_advanced_criteria: [
      { name: 'property.attr_buildarea_max_m2', type: 'is', value: 'null' },
      {
        name: 'property.adr_state_or_region',
        type: 'in',
        value: ['Queensland']
      }
    ],
    selected_columns: [],
    grid_filters: {}
  }
];

const actionCreators = {
  toggleFavourite: {
    request: ({ id, is_favourite }: { id: string; is_favourite: boolean }) =>
      api.post('CustomReports::toggleFavourite', {
        id,
        is_favourite: is_favourite ? 1 : 0
      }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  // NOTE: I'm overriding the default createItem action for now so we get the correct types.
  createReport: {
    request: async ({ data }: { data: CreateCustomReportObject }, actions) => {
      const reportData = await api.post('CustomReports::create', { data });

      await actions.updateTemplateLibrary({
        data: reportData.data.result,
        libraryId: data?.library
      });

      return reportData;
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  /**
   * This action is to get the data with the updated related user groups.
   * After we've created a report, any time we need to update the user groups we
   * need to make sure that:
   *   - we don't send the same user group id
   *   - if we want to delete the relationship, then we need to send a object back with
   *     a destroy flag, and the id of the relationship (not the user group id)
   * This action is triggered on update, and if no changes to the user groups, it
   * returns the data as is.
   */
  updateRelatedGroups: {
    request: async (
      { data }: { data: UpdateCustomReportObject },
      actions,
      dispatch,
      getState
    ) => {
      const state = getState();

      const userGroups = data?.related?.user_groups || [];

      const shouldUpdateUserGroups =
        (userGroups.length > 0 && !data.is_public) || data.related;

      if (!shouldUpdateUserGroups) {
        return data;
      }

      // get current data from the the store
      const currentData: CustomReportItem =
        state.entities.customReports.items[data.id].data;

      const currentRelatedRecords = currentData.related.user_groups.map(
        (userGroup) => userGroup
      );

      /**
       * Filter through the user groups in the data, and find any ids that are not already
       * attached currently.
       */

      const newUserGroupsToAdd = userGroups?.filter(
        (newRelatedRecord) =>
          newRelatedRecord.user_group_id &&
          !currentRelatedRecords.find(
            (currentRelatedRecord) =>
              currentRelatedRecord.user_group.id.toString() ===
              newRelatedRecord?.user_group_id?.toString()
          )
      );

      /**
       * We then filter through the current user groups, and see which ones are no longer in the
       * updated data. From those, we create an array of objects that have a destroy flag set
       * to true, and the relationship id.
       */
      const relationsToDestroy = currentRelatedRecords
        .filter(
          (currentRelatedRecord) =>
            !userGroups?.find(
              (newRelatedRecord) =>
                newRelatedRecord.user_group_id.toString() ===
                currentRelatedRecord.user_group.id.toString()
            )
        )
        .map((relatedReocrdId) => ({
          _destroy: true,
          _id: relatedReocrdId.id
        }));

      return {
        ...data,
        related: {
          user_groups: [...newUserGroupsToAdd, ...relationsToDestroy]
        }
      };
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  updateTemplateLibrary: {
    request: (
      {
        data,
        libraryId
      }: { data?: CustomReportItem; libraryId?: string | null },
      actions,
      dispatch,
      getState
    ) => {
      const state = getState();
      const previousData =
        data?.id && state?.entities?.customReports?.items?.[data.id]?.data;

      const prevLibId = previousData?.library?.id;

      // prevLibId is sometimes null, sometimes undefined
      if ((!libraryId && !prevLibId) || prevLibId === libraryId) {
        return Promise.resolve();
      }

      if (!libraryId) {
        return api.post('AdminTemplateLibraries::removeTemplateFromLibrary', {
          service_name: 'CustomReports',
          service_object_id: data?.id
        });
      }

      if (libraryId) {
        return api.post('AdminTemplateLibraries::addTemplateToLibrary', {
          library_id: libraryId,
          service_name: 'CustomReports',
          service_object_id: data?.id
        });
      }
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  updateReport: {
    request: async ({ data }: { data: UpdateCustomReportObject }, actions) => {
      data = await actions.updateRelatedGroups({ data });

      await actions.updateTemplateLibrary({ data, libraryId: data?.library });

      return api.post('CustomReports::update', {
        data
      });
    },
    reduce: {
      initial: identity,
      success: (state, action) => reduceItemResponseToState(state, action),
      failure: identity
    }
  }
};

export const customReports = new Generator<
  CustomReportItem,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});
