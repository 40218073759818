import { useCallback } from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import uiModel from 'data/models/custom/ui';

import session from 'data/models/custom/session';

import { useErrorDialog } from 'hooks/use-error-dialog';

import { CustomReportItem, customReports } from '../data/custom-reports-model';

export function useToggleFavourite(customReport: CustomReportItem) {
  const { toggleFavourite, refreshLists, refreshItem } =
    useModelActions(customReports);
  const { updateFavouriteCustomReports } = useModelActions(session);

  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const errorDialog = useErrorDialog();

  return useCallback(async () => {
    try {
      loadingIndicatorOn({ message: 'Updating report' });

      await toggleFavourite({
        id: customReport.id,
        is_favourite: !customReport.is_favourite
      });

      await refreshItem({ id: customReport.id });
      await updateFavouriteCustomReports(true);

      refreshLists();

      return loadingIndicatorOff();
    } catch (error) {
      loadingIndicatorOff();
      return errorDialog.open(error as any);
    }
  }, [
    customReport.id,
    customReport.is_favourite,
    errorDialog,
    loadingIndicatorOff,
    loadingIndicatorOn,
    refreshItem,
    refreshLists,
    toggleFavourite,
    updateFavouriteCustomReports
  ]);
}
