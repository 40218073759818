import React from 'react';

import { ICONS } from 'shared/components/icon';

import { SavedListItemIconTooltip } from './icon-tooltip';

const EyeSvg: any = ICONS.EYE;

type SavedListItemPublicTooltipProps = Pick<
  React.ComponentProps<typeof SavedListItemIconTooltip>,
  'isVisible' | 'content'
>;

export function SavedListItemPublicTooltip(
  props: SavedListItemPublicTooltipProps
) {
  return <SavedListItemIconTooltip TooltipIcon={EyeSvg} {...props} />;
}
