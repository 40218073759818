import React from 'react';
import { usePermissions } from 'src/hooks/use-permissions';
import { getDisplayName } from 'shared/utils/react';
import { useSelector } from 'react-redux';

export function withPermissions(WrappedComponent) {
  function WithPermissions(props) {
    const permissions = usePermissions();

    // Mostly for backwards compatibility
    const session = useSelector((state: any) => state?.session);

    return (
      <WrappedComponent
        {...props}
        session={session}
        permissions={permissions}
      />
    );
  }

  const displayName = getDisplayName(WrappedComponent);
  WithPermissions.displayName = `withPermissions(${displayName})`;
  return WithPermissions;
}
