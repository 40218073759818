import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import {
  api,
  transformListArgs,
  transformAutocompleteArgs,
  transformList,
  transformAutocomplete,
  transformItemArgs,
  transformItem
} from 'shared/utils/api-client';
import {
  LinkItem,
  ContractMappingOutput
} from 'features/chains/data/chain-link-types';

// bit of a hack, #SorryNotSorry

const TYPE = 'chainLinks';

const actionCreators = {
  purge: {
    request: ({ id }) => {
      return api.post('ChainLinks::purge', { id });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

// TODO: Type the custom config
// https://app.shortcut.com/rexlabs/story/64680/update-types-for-custom-config-in-entity-models
const entities: any = {
  api: {
    fetchList: (type, args) => {
      const { method = 'search', ...rest } = args;
      return api
        .post(
          `${_.upperFirst(type)}::${method}`,
          method === 'search'
            ? transformListArgs({ args: rest })
            : transformAutocompleteArgs({ args: rest })
        )
        .then(method === 'search' ? transformList : transformAutocomplete);
    },
    fetchItem: (type, args, id) =>
      api
        .post(`${_.upperFirst(type)}::read`, transformItemArgs({ id, args }))
        .then(transformItem),
    updateItem: (type, args, id) =>
      api
        .post(`${_.upperFirst(type)}::update`, { data: { ...args, id: id } })
        .then(transformItem)
  }
};

const chainLinkModel = new Generator<any, typeof actionCreators>(TYPE, {
  entities
}).createEntityModel({
  actionCreators
});

export default chainLinkModel;

export function mapContractVendorData(linkData: LinkItem): LinkItem {
  const isContract = linkData?.contract;
  if (!isContract) {
    return linkData;
  }
  // The vendor of a contract is the listing owner, the vendor
  // agent accordingly the listing agent!
  const listingData = linkData?.contract?.listing;

  const agentContact = [
    listingData?.listing_agent_1 && {
      ...listingData.listing_agent_1,
      isAgent: true
    },
    listingData?.listing_agent_2 && {
      ...listingData.listing_agent_2,
      isAgent: true
    }
  ].filter(Boolean);

  return {
    ...linkData,
    property_address: listingData?.property?.system_search_key || '',
    agent_contact: agentContact.length ? agentContact[0] : null,
    contact_names:
      listingData?.related?.contact_reln_listing
        ?.filter?.(
          (contactReln) =>
            contactReln.contact && contactReln.reln_type.id === 'owner'
        )
        .map(({ contact }) => contact) || '',
    amount: linkData?.contract?.detail_sale_price_or_lease_pa,
    solicitor_contact: listingData?.legal_solicitor_contact,
    solicitor: listingData?.legal_solicitor
  };
}

export function mapContractPurchaserData(linkData: LinkItem): LinkItem {
  const isContract = !!linkData?.contract;
  if (!isContract) {
    return linkData;
  }
  // The purchaser of a contract is the contract purchtenant, the
  // purchase agent accordingly the contract agent
  return {
    ...linkData,
    property_address:
      linkData?.contract?.listing?.property?.system_search_key || '',
    agent_contact: linkData?.contract?.agent
      ? { ...linkData?.contract?.agent, isAgent: true }
      : null,
    contact_names: (
      linkData?.contract?.related?.listing_contract_purchtenants || []
    ).map(({ purchtenant }) => purchtenant),
    amount: linkData?.contract?.detail_sale_price_or_lease_pa,
    solicitor_contact: linkData?.contract?.purchtenant_solicitor_contact,
    solicitor: linkData?.contract?.purchtenant_solicitor
  };
}

export function getLinkData(link, primaryLink, allLinks) {
  if (!link) {
    return link;
  }

  const isContract = !!_.get(link, 'contract');
  let linkData = { ...link };

  // Map contract data onto link data if available
  if (isContract) {
    linkData = mapContractPurchaserData(linkData);
  }

  if (primaryLink && allLinks) {
    // Get vendor and purchaser information based on link, related link
    // and position in chain compared to primary link...
    const primaryIndex = allLinks.findIndex(
      (l) => parseInt(l.id) === parseInt(primaryLink.id)
    );
    const linkIndex = allLinks.findIndex(
      (l) => parseInt(l.id) === parseInt(linkData.id)
    );

    // Link above this link in the standard view
    // NOTE: even in reversed view we'd want to reference it this way! It basically just
    // matches the `appears_after_ids` fields from the BE!
    const nextLinks = allLinks.filter((l) =>
      l.position.appears_after_link_ids
        .map((id) => parseInt(id))
        .includes(parseInt(linkData.id))
    );

    // Link below this link in standardv view, also see above
    // TODO: we're just using the first one for splits here, needs to be changed!
    const prevIds = linkData.position.appears_after_link_ids;
    const prevLinks = prevIds.map((id) =>
      allLinks.find((l) => parseInt(l.id) === parseInt(id))
    );

    let purchaser: ContractMappingOutput;
    let vendor: ContractMappingOutput;

    if (primaryIndex === linkIndex || isContract) {
      // Link is contract, so use its information for all details reg
      // both vendor and purchaser
      purchaser = mapContractPurchaserData(linkData);
      vendor = mapContractVendorData(linkData);
    } else if (linkIndex < primaryIndex) {
      // Link is above primary link ("vendor's purchase")
      // The purchaser of this link is the vendor of the related link!
      purchaser = mapContractVendorData(prevLinks[0]);
      vendor = linkData;
    } else {
      // Link is below primary link ("purchaser's sale")
      // The vendor of this link is the purchaser of the related link!
      purchaser = linkData;
      vendor = mapContractPurchaserData(nextLinks[0]);
    }

    linkData = {
      ...linkData,
      purchaser,
      vendor,
      prevLinks,
      nextLinks,
      isPurchase: linkIndex < primaryIndex,
      isPrimary: parseInt(linkData.id) === parseInt(primaryLink.id)
    };
  }

  return linkData;
}
