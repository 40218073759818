import { useMemo } from 'react';
import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { makeEntity } from 'features/custom-reporting/modules/helpers';

import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

import {
  CommissionBySaleReportQuery,
  commissionBySaleReportQueryDefaultVariables
} from './commission-by-sale-report.query';
import { ModuleConfigItem } from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';

export const defaultColumns = [
  'listing.listing',
  'id',
  'comm_amount_net_of_tax',
  'cache_comm_distributable',
  'cache_comm_final_to_office',
  'cache_amount_invoiced',
  'cache_amount_paid',
  'contract.date_actual_accepted',
  'contract.date_actual_unconditional',
  'contract.date_actual_settlement'
];

// TODO: add default sort when possible via FE
//  Jira: https://rexsoftware.atlassian.net/browse/RADI-5980

export function useCommissionBySaleModule() {
  const region = useRegion();
  const ww = useWhichWordFactory();
  const {
    listings: listingCustomFields,
    properties: propertyCustomFields,
    contracts: contractCustomFields
  } = useCustomFields();

  const commissionBySaleModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.commission,
      moduleLabel: 'Commission by Sale',
      shortLabel: 'By Sale',
      exportPrivilege: 'exporting.commission_worksheets',
      queryConfig: {
        graphQlQuery: CommissionBySaleReportQuery,
        queryVariables: commissionBySaleReportQueryDefaultVariables
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing.listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Commission by sale',
          children: makeEntity({
            entity: 'commissionBySale',
            prefix: '',
            whichWord: ww
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property.listing.property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: ww('contract'),
          children: makeEntity({
            entity: 'contract',
            prefix: 'contract',
            subresource: 'contract',
            customFields: contractCustomFields,
            whichWord: ww,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultDateFieldPreference: 'contract.date_actual_settlement',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'contract.date_actual_accepted',
              label: `${ww('contract')} accepted`,
              isDefault: true
            },
            {
              fieldName: 'contract.date_actual_unconditional',
              label: `${ww('contract')} ${ww('unconditional').toLowerCase()}`
            },
            {
              fieldName: 'contract.date_actual_settlement',
              label: `${ww('contract')} ${ww('settled').toLowerCase()}`
            }
          ]
        },
        location: {
          availableFields: [
            { fieldName: 'contract.listing_location_id', label: 'Location' }
          ]
        }
      }
    }),
    [
      ww,
      region,
      listingCustomFields,
      propertyCustomFields,
      contractCustomFields
    ]
  );
  return commissionBySaleModuleConfig;
}
