import React from 'react';

import { ICONS } from 'shared/components/icon';

import { LinkIconButton } from 'components/button/link-icon-button';

type SavedListAddButtonProps = Omit<
  React.ComponentProps<typeof LinkIconButton>,
  'iconType'
>;
export function SavedListAddItemButton({ ...rest }: SavedListAddButtonProps) {
  return <LinkIconButton iconType={ICONS.ADD_12} undecorated light {...rest} />;
}
