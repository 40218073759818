import React from 'react';

import { PropertyStub } from 'data/models/entities/properties';

import { formatAddress } from 'utils/formatters';

import { PropertyPreviewPopout } from 'features/properties/popouts/property-preview-popout';

import { BasePopoutCellProps, PopoutCell } from './popout-cell';

interface PropertyCellProps extends BasePopoutCellProps {
  value?: Partial<PropertyStub> | null;
}

export function PropertyCell(props: PropertyCellProps) {
  const { value, ...rest } = props;
  const propertyId = value?.id;

  return (
    <PopoutCell
      RecordPopout={PropertyPreviewPopout}
      recordId={propertyId}
      data-testid='RecordList.PropertyCell'
      {...rest}
    >
      {formatAddress(value)}
    </PopoutCell>
  );
}
