import React, { ChangeEvent, useState } from 'react';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import PercentageInput from 'src/view/components/input/percentage';

interface PercentageInputCellProps extends DefaultCellProps {
  onChange: (
    e: ChangeEvent<HTMLInputElement>,
    data: Record<string, string> & { id: number }
  ) => void;
  data: Record<string, string> & { id: number };
  valueKey: string;
}

function PercentageInputCell({
  width,
  fullWidth,
  onChange,
  data,
  valueKey,
  ...rest
}: PercentageInputCellProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <DefaultCell
      {...rest}
      fullWidth={fullWidth}
      value={
        <PercentageInput
          decimals={2}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, data)}
          value={data[valueKey]}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          meta={{
            focused: isFocused,
            error: null
          }}
          width={'unset'}
          // Prevents 'please input correct format' message
          onInvalid={(e) => e.preventDefault()}
        />
      }
    />
  );
}

export default PercentageInputCell;
