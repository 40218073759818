import React from 'react';

import DefaultOption from 'view/components/input/select/defaults/option';

import { ICONS } from 'shared/components/icon';

import { withRegion } from 'src/hocs/with-region';

const {
  SELECT: {
    VALUE: { TOWN_CITY: SuburbIcon }
  }
} = ICONS;

function SuburbOption({ region: { isEU }, ...props }) {
  const description = isEU ? 'Town / City' : '';
  return (
    <DefaultOption {...props} description={description} Icon={SuburbIcon} />
  );
}

export default withRegion(SuburbOption);
