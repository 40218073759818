import PaddingBox from 'view/components/padding-box';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import React from 'react';

interface WarningBoxProps {
  prefix?: string;
  message: string;
}

export default function WarningBox({
  message,
  prefix = 'Warning'
}: WarningBoxProps) {
  return (
    <PaddingBox yellow>
      <Box flex={1}>
        {prefix && (
          <Box pr={10}>
            <Body dark bold>
              {prefix}
            </Body>
          </Box>
        )}
        <Body dark>{message}</Body>
      </Box>
    </PaddingBox>
  );
}
