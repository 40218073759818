import React from 'react';

import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

import Spinner from 'shared/components/spinner';

import { PADDINGS } from 'theme';

import PaddingBox, { PaddingBoxProps } from 'view/components/padding-box';

interface BannerProps extends PaddingBoxProps {
  Icon: any;
  children?: React.ReactNode;
  isLoading?: boolean;
}
const defaultStyles = StyleSheet({
  bannerContainer: {
    columnGap: PADDINGS.XS
  }
});

export function Banner({
  children,
  Icon,
  isLoading,
  ...boxProps
}: BannerProps) {
  const s = useStyles(defaultStyles);
  return (
    <PaddingBox
      flexDirection='row'
      mt={PADDINGS.S}
      {...s('bannerContainer')}
      {...boxProps}
    >
      {isLoading ? (
        <Spinner dark small centered />
      ) : (
        <>
          <Icon />
          <Box flexDirection='column'>{children}</Box>
        </>
      )}
    </PaddingBox>
  );
}
