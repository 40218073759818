import React, { useCallback, useState } from 'react';
import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';

import { ICONS } from 'shared/components/icon';

import contactsModel from 'data/models/entities/contacts';
import { Form, FormField, HiddenField, ReactForms } from 'view/components/form';
import { IconButton } from 'view/components/button';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import RecordSelect from 'view/components/custom-fields/record-select';
import { Heading } from 'components/_experiment/record-block/heading';

function EditContactPopoutContent({ data, onEdit, onRemove, close }) {
  return (
    <PaddingBox width='290px'>
      <Heading>Edit Related Contact</Heading>
      <ReactForms
        initialValues={{
          id: data.id,
          contact: data.contact.id,
          type: data.custom_reln_desc
        }}
        handleSubmit={async (...args) => {
          await onEdit?.(...args);
          close();
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <HiddenField name='id' />
            <FormField
              name='contact'
              Input={RecordSelect}
              inputProps={{
                models: [contactsModel],
                options: [
                  {
                    data: data.contact,
                    model: contactsModel,
                    label: data.contact.name,
                    value: data.contact.id
                  }
                ]
              }}
              label='contact'
            />
            <FormField name='type' Input={TextInput} label='relationship' />
            <Box flexDirection='row' alignItems='center'>
              <IconButton
                Icon={ICONS.REMOVE}
                red
                circle
                onClick={async () => {
                  await onRemove?.();
                  close();
                }}
              >
                Remove
              </IconButton>
              <SaveCancelButtonBar
                isLoading={isSubmitting}
                onSave={submitForm}
                onCancel={close}
              />
            </Box>
          </Form>
        )}
      </ReactForms>
    </PaddingBox>
  );
}

export function EditContactPopout({ data, onEdit, onRemove }) {
  const [isOpen, setOpen] = useState(false);

  const Content = useCallback(
    (props) => (
      <EditContactPopoutContent
        {...props}
        data={data}
        onEdit={onEdit}
        onRemove={onRemove}
      />
    ),
    [data, onEdit, onRemove]
  );
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Popout Content={Content} onChange={({ isOpen }) => setOpen(isOpen)}>
        <IconButton
          red
          circle
          Icon={ICONS.EDIT}
          data-edit-button
          data-force-show={isOpen}
        />
      </Popout>
    </div>
  );
}
