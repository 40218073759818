// Vendor
import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';

// Rexlabs
import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';

// Local
import { COLORS, PADDINGS } from 'theme';
import { checkDate } from 'utils/date';
import { DefaultButton } from 'view/components/button';
import { ButtonBar } from 'view/components/button-bar';
import { FormField, HiddenField } from 'view/components/form';
import AddressInputBlock from 'view/components/input/address';
import { RexTextArea } from 'view/components/input/text-area';
import Checkbox from 'view/components/input/checkbox';
import { DialogSectionHeading } from 'components/text/dialog-section-heading';
import AddressLookup from 'view/dialogs/address-lookup';
import PaddingBox from 'view/components/padding-box';
import { withPermissions } from 'src/hocs/with-permissions';

// Shared
import { Grid, Column } from 'shared/components/grid';
import { withDialog } from 'shared/hocs/with-dialog';

function validateDateOfBirth(newValue) {
  if (!newValue) {
    return null;
  }

  return checkDate(newValue, true);
}

@autobind
class DetailsContent extends PureComponent {
  static defaultProps = {
    requirements: {}
  };

  render() {
    const { values, setValues, type, requirements, readOnly } = this.props;
    return type === 'company' ? (
      <CompanyDetails
        values={values}
        readOnly={readOnly}
        setValues={setValues}
        requirements={requirements}
      />
    ) : (
      <PersonDetails
        values={values}
        readOnly={readOnly}
        setValues={setValues}
        requirements={requirements}
      />
    );
  }
}

const personStyles = StyleSheet({
  legalAddress: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  }
});

@styled(personStyles)
@autobind
class PersonDetails extends PureComponent {
  render() {
    const { readOnly, requirements, values, setValues, styles: s } = this.props;
    const {
      date_of_birth_is_required: dobRequired,
      legal_address_is_required: addressRequired,
      legal_name_is_required: nameRequired
    } = requirements;

    return (
      <Fragment>
        <PaddingBox>
          <DialogSectionHeading>Basic Information</DialogSectionHeading>
          <Grid>
            <Column width={6}>
              <FormField
                name='title'
                label={`title${nameRequired ? '*' : ''}`}
                Input={TextInput}
                inputProps={{
                  disabled: readOnly
                }}
              />
            </Column>
            <Column width={6}>
              <FormField
                name='first_name'
                label={`first${nameRequired ? '*' : ''}`}
                Input={TextInput}
                inputProps={{
                  disabled: readOnly
                }}
              />
            </Column>
            <Column width={6}>
              <FormField
                name='middle_name'
                label='middle'
                Input={TextInput}
                inputProps={{
                  disabled: readOnly
                }}
              />
            </Column>
            <Column width={6}>
              <FormField
                name='last_name'
                label={`last${nameRequired ? '*' : ''}`}
                Input={TextInput}
                inputProps={{
                  disabled: readOnly
                }}
              />
            </Column>
            <Column width={12}>
              <FormField
                name='date_of_birth'
                label={`date of birth${dobRequired ? '*' : ''}`}
                Input={TextInput}
                inputProps={{
                  placeholder: 'DD/MM/YYYY',
                  disabled: readOnly
                }}
                validate={validateDateOfBirth}
              />
            </Column>
          </Grid>
        </PaddingBox>
        <PaddingBox {...s('legalAddress')}>
          <DialogSectionHeading>
            Legal Address{addressRequired && '*'}
          </DialogSectionHeading>
          <AddressDetails
            values={values}
            setValues={setValues}
            disabled={readOnly}
          />
        </PaddingBox>
      </Fragment>
    );
  }
}

const companyStyles = StyleSheet({
  registeredAddress: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  }
});

@withPermissions
@styled(companyStyles)
@autobind
class CompanyDetails extends PureComponent {
  render() {
    const { readOnly, requirements, values, setValues, styles: s } = this.props;
    const {
      trading_name_is_required: nameRequired,
      registered_office_is_required: officeRequired,
      main_place_of_business_is_required: placeofBusinessRequired,
      company_reg_number_is_required: regNumberRequired
    } = requirements;

    return (
      <Fragment>
        <PaddingBox>
          <DialogSectionHeading>Basic Information</DialogSectionHeading>
          <FormField
            name='legal_name'
            label={`trading name${nameRequired ? '*' : ''}`}
            Input={TextInput}
            inputProps={{
              disabled: readOnly
            }}
          />
          <FormField
            name='company_number'
            label={`vat${regNumberRequired ? '*' : ''}`}
            Input={TextInput}
            inputProps={{
              disabled: readOnly
            }}
          />
        </PaddingBox>
        <PaddingBox {...s('registeredAddress')}>
          <DialogSectionHeading>
            Registered Office{officeRequired && '*'}
          </DialogSectionHeading>
          <AddressDetails
            values={values}
            prefix={'registered_'}
            setValues={setValues}
            disabled={readOnly}
          />
        </PaddingBox>
        <PaddingBox>
          <DialogSectionHeading>
            Main Place of Business{placeofBusinessRequired && '*'}
          </DialogSectionHeading>
          <FormField
            name='adr_same_as_registered'
            Input={Checkbox}
            sendImmediate
            inputProps={{
              label: 'same as registered office address',
              disabled: readOnly
            }}
          />
          <Box
            style={{
              display: values.adr_same_as_registered ? 'none' : undefined
            }}
          >
            <AddressDetails
              values={values}
              setValues={setValues}
              disabled={readOnly}
            />
          </Box>
        </PaddingBox>
      </Fragment>
    );
  }
}

@withDialog(AddressLookup, { propName: 'addressLookup' })
@autobind
class AddressDetails extends PureComponent {
  render() {
    const {
      addressLookup,
      values,
      setValues,
      prefix = '',
      disabled
    } = this.props;
    return (
      <Fragment>
        <HiddenField name={`${prefix}has_custom_address`} />
        <Box
          style={{
            display: values[`${prefix}has_custom_address`] ? undefined : 'none'
          }}
        >
          <Grid>
            <Column width={12}>
              <FormField
                name={`${prefix}custom_address`}
                label={'full address'}
                Input={RexTextArea}
                inputProps={{
                  disabled
                }}
              />
            </Column>
          </Grid>
          <Box pt={PADDINGS.S}>
            <DefaultButton
              isDisabled={disabled}
              left
              onClick={() =>
                setValues({ [`${prefix}has_custom_address`]: false })
              }
            >
              Change to address form
            </DefaultButton>
          </Box>
        </Box>
        <Box
          style={{
            display: values[`${prefix}has_custom_address`] ? 'none' : undefined
          }}
        >
          <AddressInputBlock
            values={values}
            setFieldValues={setValues}
            prefix={prefix}
            townFieldName={'adr_town'}
            disabled={disabled}
          />
          <Box pt={PADDINGS.S}>
            <ButtonBar>
              <DefaultButton
                isDisabled={disabled}
                left
                onClick={() =>
                  addressLookup.open({
                    callback: ({ data }) => {
                      const { ...rest } = data;
                      const prefixed = Object.keys(rest).reduce(
                        (acc, key) => {
                          const prefixed = `${prefix}adr_${key.replace(
                            /^suburb_or_/,
                            ''
                          )}`;
                          acc[prefixed] = rest[key];
                          return acc;
                        },
                        {
                          [`${prefix}custom_address`]: '',
                          [`${prefix}has_custom_address`]: false
                        }
                      );
                      setValues(prefixed);
                    }
                  })
                }
              >
                Address lookup
              </DefaultButton>
              <DefaultButton
                isDisabled={disabled}
                left
                onClick={() =>
                  setValues({
                    [`${prefix}has_custom_address`]: true,
                    [`${prefix}custom_address`]: ''
                  })
                }
              >
                Input custom address
              </DefaultButton>
            </ButtonBar>
          </Box>
        </Box>
      </Fragment>
    );
  }
}

export default DetailsContent;
