import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';
import { Heading } from 'components/text/heading';
import { IconButton, DefaultButton } from 'view/components/button';
import { ButtonBar } from 'view/components/button-bar';
import { ICONS } from 'shared/components/icon';
import metricsModel from 'data/models/entities/metrics';
import { withQuery, query } from '@rexlabs/model-generator';
import MetricHistoryList from 'view/components/lists/metrics/history';
import { withDialog } from 'shared/hocs/with-dialog';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import _ from 'lodash';
import { dateToValue } from 'view/components/input/date-time';
import { autobind } from 'core-decorators';

import AddMetricDialog from 'view/dialogs/admin/metric-write-rules/add-metric';
import MetricDetailsDialog from 'view/dialogs/admin/metric-write-rules/metric-details';

const getCriteria = (p) => [
  { name: 'service_name', value: p.service },
  { name: 'record_id', value: p.id }
];

const getOrderBy = () => ({ id: 'desc' });

const metricsQuery = query`{
  ${metricsModel} (criteria: ${getCriteria}, limit: 10, order_by: ${getOrderBy}) {
    id
    metric
    value
    recorded_at
    write_rule
    user_attributions
    labels
    time
  }
}`;

@withErrorDialog
@withDialog(AddMetricDialog, { propName: 'addMetric' })
@withDialog(MetricDetailsDialog, { propName: 'metricDetails' })
@withQuery(metricsQuery)
@autobind
class MetricHistoryDialog extends PureComponent {
  state = {
    currentPage: 1
  };

  memItems = [];
  getItems() {
    const { metrics } = this.props;
    const { currentPage } = this.state;
    const loadedItems = _.get(metrics, 'list.items', []);

    // We memoize the items here, since we need to slice in render, if we didn't memoize it would
    // create a new array reference EVERY render and cause massive performance issues, so please
    // don't remove this unless its being replaced with something better ;)
    const findCache = this.memItems.find(
      (i) => i.currentPage === currentPage && i.items === loadedItems
    );

    if (findCache) {
      return findCache.value;
    }

    const perPage = _.get(metrics, 'list.pagination.itemsPerPage');

    const fromNumber = (currentPage - 1) * perPage;
    const toNumber = currentPage * perPage - 1;

    const slicedItems = loadedItems.slice(fromNumber, toNumber);

    this.memItems.push({
      currentPage,
      items: loadedItems,
      value: slicedItems
    });

    return slicedItems;
  }

  handlePrev() {
    // NOTE: when switching to the previous page, we assume that it has been loaded
    // already (atm we don't allow skipping pages!). So we can simply set the
    // state here, no need for network requests :)
    this.setState((state) => ({
      currentPage: state.currentPage - 1
    }));
  }

  handleNext() {
    // This is a hacked in implementation of pagination for the moment, ideally this should
    // be abstracted out in some kind of `EntityList` that just takes the model and additional
    // args e.g. for filters
    const { errorDialog, metrics } = this.props;

    const currentPage = this.state.currentPage;
    const nextPage = currentPage + 1;

    const itemsLoaded = _.get(metrics, 'list.items');
    const perPage = _.get(metrics, 'list.pagination.itemsPerPage');

    // Only fetch more if we haven't fetched that page before ... if we have, just resolve
    // and set the state, since the items are already in the props!
    const loadNextPage =
      currentPage * perPage === itemsLoaded.length
        ? metrics.fetchMore()
        : Promise.resolve();

    return loadNextPage
      .then(() =>
        this.setState(() => ({
          currentPage: nextPage
        }))
      )
      .catch(errorDialog.open);
  }

  render() {
    const {
      onLoad,
      closeDialog,
      metrics,
      addMetric,
      service,
      id,
      metricDetails
    } = this.props;

    const isLoading = _.get(metrics, 'list.status') === 'loading';

    return (
      <Dialog
        onLoad={onLoad}
        closeDialog={closeDialog}
        title='Metric History'
        isLoading={isLoading}
        height={
          340 + 30 * Math.min(_.get(metrics, 'list.items', []).length, 20)
        }
        width={600}
      >
        <Box height={40} flexDirection='row' alignItems='center'>
          <Heading level={2}>Metrics</Heading>
          <Box ml={10}>
            <IconButton
              circle
              red
              Icon={ICONS.ADD_MEDIUM}
              onClick={() =>
                addMetric.open({
                  service,
                  id,
                  callback: () => metrics.refreshList()
                })
              }
            >
              manually enter metric
            </IconButton>
          </Box>
        </Box>
        <MetricHistoryList
          items={this.getItems()}
          onViewClick={(item) => metricDetails.open({ id: item.id })}
          onDuplicate={(item) =>
            addMetric.open({
              initialValues: {
                ...item,
                labels: item.labels.filter((v) =>
                  ['service_name', 'record_id'].includes(
                    _.get(v, 'value.label')
                  )
                ),
                time: dateToValue(new Date())
              },
              service,
              id,
              callback: () => metrics.refreshList()
            })
          }
          pagination={_.get(metrics, 'list.pagination', {})}
          currentPage={this.state.currentPage}
          onPrev={this.handlePrev}
          onNext={this.handleNext}
        />
        <ButtonBar>
          <DefaultButton light onClick={closeDialog}>
            Close
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default MetricHistoryDialog;
