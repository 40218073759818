import { useState, useEffect } from 'react';
import LocalStorage from 'shared/utils/local-storage';

export function usePersistedState(
  key: string,
  defaultState?: any,
  isPersisted?: boolean
) {
  const fromStorage = LocalStorage.get(key);
  const initialState = fromStorage ? JSON.parse(fromStorage)?.value : undefined;

  const [state, setState] = useState(initialState ?? defaultState);
  useEffect(() => {
    LocalStorage.set(key, JSON.stringify({ value: state }), !!isPersisted);
  }, [isPersisted, key, state]);

  return [state, setState];
}
