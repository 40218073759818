import { camelCase, filter } from 'lodash';
import { ICONS } from 'shared/components/icon';
import DialogsBridge from 'data/classic-bridges/dialogs-shell';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { SessionModel } from 'data/models/custom/session';
import { CustomReportItem } from 'features/custom-reporting/data/custom-reports-model';

export function reportsMenuConfig({ session }: { session: SessionModel }) {
  const reportingSubmenu = ({
    favouriteCustomReports = []
  }: {
    favouriteCustomReports: CustomReportItem[];
  }) => [
    ...(favouriteCustomReports.length > 0
      ? [
          {
            heading: true,
            icon: ICONS.STAR_15,
            name: 'My Favourite Reports',
            withFlags: 'custom_reporting',
            accessRights: 'custom_reports.view_all'
          },
          ...favouriteCustomReports.flatMap((report) => {
            const moduleId = report.module?.id;
            const modules = filter(
              moduleNameMap,
              (moduleMap) => moduleMap.moduleId === moduleId
            );

            return modules.map((module) => ({
              id: camelCase(report.name),
              name: report.name,
              href: `/report/${module.moduleName}#reportId=${report.id}`,
              withFlags: 'custom_reporting',
              accessRights: 'custom_reports.view_all',
              countries: module.regions
            }));
          })
        ]
      : []),
    {
      heading: true,
      icon: ICONS.REPORT_15,
      name: 'Custom Reports',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'openSavedReport',
      name: 'Open Saved Report',
      onClick: DialogsBridge.customReports.open,
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'appointmentReports',
      name: 'Appointments',
      href: '/report/appointments',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'appraisalReports',
      name: 'Appraisals',
      href: '/report/appraisals',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting',
      countries: ['AUS', 'NZ']
    },
    {
      id: 'commissionReports',
      name: 'Commission',
      href: '/report/commission_by_agent',
      withFlags: ['custom_reporting'],
      accessRights: `
         (custom_reports.view_all || custom_reports.view_own) &&
         custom_reports.view_financial && addon.custom_reporting`,
      countries: ['AUS', 'NZ']
    },
    {
      id: 'contactReports',
      name: 'Contacts',
      href: '/report/contacts',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'contractsReports',
      name: 'Contracts',
      href: '/report/contracts',
      withFlags: ['custom_reporting', 'custom_reporting_contracts'],
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting',
      countries: ['AUS', 'NZ']
    },
    {
      id: 'Keys',
      name: 'Keys',
      href: '/report/key_locations',
      withFlags: ['custom_reporting', 'custom_reporting_keys'],
      accessRights: 'custom_reports.view_all && addon.custom_reporting'
    },
    {
      id: 'leadReports',
      name: 'Leads',
      href: '/report/leads',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'listingReports',
      name: 'Listings',
      href: '/report/listings',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'offersReports',
      name: 'Offers',
      href: '/report/offers',
      withFlags: ['custom_reporting', 'custom_reporting_contracts'],
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting',
      countries: ['EU']
    },
    {
      id: 'projectReports',
      name: 'Projects',
      href: '/report/project_stages',
      withFlags: ['custom_reporting', 'custom_reporting_projects'],
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting && addon.projects'
    },
    {
      id: 'propertyReports',
      name: 'Properties',
      href: '/report/properties',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'oabReports',
      name: 'OABs',
      href: '/report/oabs',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      id: 'valuationReports',
      name: 'Valuations',
      href: '/report/valuations',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting',
      countries: ['EU', 'AE']
    },
    {
      id: 'tenancyApplicationReports',
      name: 'Tenancy Applications',
      href: '/report/tenancy_applications',
      withFlags: 'custom_reporting',
      accessRights:
        '(custom_reports.view_all || custom_reports.view_own) && addon.custom_reporting'
    },
    {
      heading: true,
      icon: ICONS.REPORT_NAV,
      name: 'Reporting Centre',
      accessRights: `
              reporting_center.general_reports ||
              reporting_center.activity_and_performance ||
              reporting_center.contracts_and_commission ||
              reporting_center.invoices_and_income ||
              reporting_center.trust ||
              reporting_center.custom_reports`
    },
    {
      id: 'classicReports',
      name: 'Reports',
      href: '/reporting/center/',
      accessRights: `
              reporting_center.general_reports ||
              reporting_center.activity_and_performance ||
              reporting_center.contracts_and_commission ||
              reporting_center.invoices_and_income ||
              reporting_center.trust ||
              reporting_center.custom_reports`
    }
  ];

  const baseReportingItem = {
    id: 'reporting',
    name: 'Reporting',
    icon: ICONS.REPORT_LARGE,
    match: ['^/reporting/center/', '^/report/'],
    isFixedDesktop: true,
    accessRights: `
        reporting_center.general_reports ||
        reporting_center.activity_and_performance ||
        reporting_center.contracts_and_commission ||
        reporting_center.invoices_and_income ||
        reporting_center.trust ||
        reporting_center.custom_reports ||
        custom_reports.view_all ||
        custom_reports.view_own
      `,
    subMenu: reportingSubmenu
  };

  const reportingItems = [
    { ...baseReportingItem, withFlags: 'custom_reporting' },
    // TODO: delete this when custom reporting hits GA
    {
      ...baseReportingItem,
      id: 'reporting-legacy',
      isNew: false,
      withoutFlags: 'custom_reporting'
    }
  ];

  return reportingItems;
}
