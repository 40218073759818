import _ from 'lodash';
import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';

const TYPE = 'adminKeyLocations';

const actionCreators = {
  fetchAll: {
    request: (args) => {
      return api.fetchAll(`${_.upperFirst(TYPE)}::search`, args);
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const adminKeyLocationsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default adminKeyLocationsModel;
