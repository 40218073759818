import _ from 'lodash';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';

import { APP_MENU } from 'theme/legacy/components';
import Option from './option';

@styled(APP_MENU.RECORDS)
class InvoiceOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles: s, option, isActive } = this.props;
    const { data: invoice, value, label } = option;

    const dueDate = _.get(invoice, 'due_date', '');

    return (
      <Option
        isActive={isActive}
        label={label}
        OptionMeta={[
          <span key='label' {...s('name', 'ellipsis')}>
            {label}
          </span>,
          <span key='date' {...s('meta', 'ellipsis')}>
            Due: {dueDate}
          </span>
        ]}
        OptionExtraMeta={[
          <span key='value' {...s('meta', 'extra', 'light')}>
            #{value}
          </span>
        ]}
      />
    );
  }
}

export default InvoiceOption;
