import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'userCalendars';

const config = {
  entities: {
    api: {
      trashItem: (type, args, id) =>
        api
          .post('UserCalendars::purge', { ...args, id: id })
          .then((response) => response.data.result)
    }
  }
};

const actionCreators = {
  resetPrivateiCalLink: {
    request: (payload) =>
      api.post('UserCalendars::resetPrivateiCalLink', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const userCalendarsModel = new Generator(TYPE, config).createEntityModel({
  actionCreators
});

userCalendarsModel.select = {
  autocomplete: (searchTerm, { exclude, useCalendarId }) =>
    api.post('UserCalendars::search').then(({ data }) =>
      data.result.rows
        .filter((c) => !exclude || !exclude.includes(c.id))
        .map((c) => ({
          value: useCalendarId ? _.get(c, 'calendar.id') : c.id,
          label:
            _.get(c, 'calendar.owner_user.name') === _.get(c, 'calendar.name')
              ? `${_.get(c, 'calendar.owner_user.name')}`
              : `${_.get(c, 'calendar.owner_user.name')} (${_.get(
                  c,
                  'calendar.name'
                )})`,
          model: userCalendarsModel,
          data: c
        }))
    )
};

export default userCalendarsModel;
