import React, { PureComponent } from 'react';
import { Autobind, Debounce } from 'utils/decorators';
import { getDisplayName } from 'shared/utils/react';

export const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile'
};

function getCurrentDeviceType() {
  const mobileMediaQueries = [
    'only screen and (min-width: 320px) and (max-width: 480px) and (pointer: coarse)'
    // 'only screen and (min-device-width: 320px) and (max-width: 768px) and (max-device-height: 823px)',
  ];
  const tabletMediaQueries = [
    'only screen and (min-width: 481px) and (max-width: 1224px) and (pointer: coarse)'
    // 'only screen and (min-width: 768px) and (max-width: 1224px) and (orientation: landscape) and (pointer: coarse)',
    // 'only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (pointer: coarse)',
    // 'only screen and (min-width: 768px) and (max-width: 1224px)',
    // 'only screen and (min-device-width: 768px) and (max-device-width: 1024px)',
    // 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (min-device-pixel-ratio: 2)',
    // 'only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-pixel-ratio: 1.5)'
  ];

  const isTabletSuitable =
    tabletMediaQueries.filter(
      (query) => window.parent.matchMedia(query).matches
    ).length > 0;
  const isMobileSuitable =
    mobileMediaQueries.filter(
      (query) => window.parent.matchMedia(query).matches
    ).length > 0;

  if (isMobileSuitable) {
    return DEVICE_TYPES.MOBILE;
  }

  if (isTabletSuitable) {
    return DEVICE_TYPES.TABLET;
  }

  return DEVICE_TYPES.DESKTOP;
}

export const withDevice = (WrappedComponent) => {
  class WithDevice extends PureComponent {
    state = { currentDevice: getCurrentDeviceType() };

    @Debounce(100)
    @Autobind
    handleWindowResize() {
      this.setState({ currentDevice: getCurrentDeviceType() });
    }

    componentDidMount() {
      window.parent.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
      window.parent.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
      const deviceProps = {
        device: {
          isDesktop: this.state.currentDevice === DEVICE_TYPES.DESKTOP,
          isTablet: this.state.currentDevice === DEVICE_TYPES.TABLET,
          isMobile: this.state.currentDevice === DEVICE_TYPES.MOBILE
        }
      };

      return <WrappedComponent {...this.props} {...deviceProps} />;
    }
  }

  WithDevice.displayName = `withDevice(${getDisplayName(WrappedComponent)})`;

  return WithDevice;
};
