import _ from 'lodash';
import { Autobind } from 'utils/decorators';

/*
|-------------------------------------------------------------------------------
| Utilities
*/

export function stopPropagation(callback) {
  return (event) => {
    event.stopPropagation();
    callback();
  };
}

function getPrototypesOf(obj, protos = []) {
  const proto = Object.getPrototypeOf(obj);
  // Have we reached the first prototype ("Object")?
  if (Object.getPrototypeOf(proto) === null) return protos;
  else {
    // If not then we drill down deeper...
    return getPrototypesOf(proto, protos.concat(proto));
  }
}

function getKeysOfPrototypeChain(obj) {
  return _.chain(getPrototypesOf(obj))
    .map((p) => Object.keys(p))
    .flatten()
    .uniq()
    .value();
}

export function onEnterPress(callback) {
  return (event) => {
    if (event.keyCode === 13) {
      callback();
    }
  };
}

/**
 * An simple EventEmitter implementation.
 *
 * @example
 *
 * const handler = (data) => console.log(data)
 * const emitter = new Emitter()
 * const off = emitter.subscribe('message', handler)
 * emitter.emit('message', 'Hey!') // log: Hey!
 *
 * off()
 * // or
 * emitter.off('message', handler)
 */
@Autobind
export class Emitter {
  subs = [];

  subscribe(name, handler) {
    this.subs = this.subs.concat({ name, handler });
    return this.unsubscribe.bind(this, name, handler);
  }

  unsubscribe(name, handler) {
    if (handler) {
      this.subs = this.subs.filter(
        (sub) => sub.name !== name || sub.fn !== handler
      );
    } else if (name) {
      this.subs = this.subs.filter((sub) => sub.name !== name);
    } else {
      this.subs = [];
    }
  }

  /** @type {(name: string, data?: any) => void} */
  emit(name, data = null) {
    this.subs.forEach((sub) => sub.name === name && sub.handler(data));
  }
}

/**
 * Shallow clones an event, using deep detection of non-enumerable keys.
 * "Aggressive Shallow Cloning"
 * @param event - The source event.
 */
export function cloneEvent(event) {
  const eventPropKeys = getKeysOfPrototypeChain(event);
  const EventClass = Object.getPrototypeOf(event).constructor;
  const eventInitConfig = _.reduce(
    eventPropKeys,
    (config, key) => {
      let descriptor = Object.getOwnPropertyDescriptor(event, key);
      if (!descriptor) {
        // We're dealing with a non-enumerable
        descriptor = { value: event[key], enumerable: false, writable: false };
      } else if (!('value' in descriptor)) {
        // The descriptor didn't include a value, derp
        descriptor.value = event[key];
      }

      config[key] = descriptor.value;
      return config;
    },
    {}
  );

  const clonedEvent = new EventClass(event.type, eventInitConfig);

  // NOTE: We must now check over each key from the config, and manually define
  //       missing properties that the constructor missed when making the instance
  for (const key in eventInitConfig) {
    // eslint-disable-next-line
    if (eventInitConfig.hasOwnProperty(key)) {
      const val = eventInitConfig[key];
      if (clonedEvent[key] !== val) {
        Object.defineProperty(clonedEvent, key, { value: val });
      }
    }
  }

  return clonedEvent;
}

const alwaysTrue = () => true;
const unwrapFn = (valOrFn) => (_.isFunction(valOrFn) ? valOrFn() : valOrFn);

/**
 * Given a list of events, this creates an tracking instance that you can
 * register any number of windows too that will receive the same event types
 * from any other registered window.
 */
@Autobind
export class NativeEventPropagator {
  static EVENT_MARKER = '__propagatedevent';
  constructor(events) {
    this._events = NativeEventPropagator.normaliseEventMap(events);
    this._emitter = new Emitter();
    this._increment = 0;
    this._registered = [];

    this._emitter.subscribe('propagate', ({ id, event }) => {
      const clonedEvent = cloneEvent(event);
      clonedEvent[NativeEventPropagator.EVENT_MARKER] = true;
      this._registered.forEach(({ id: regId, window }) => {
        // Short-circuit if the event originates from the registered window
        if (id !== regId) {
          // Sometimes dispatchEvent is undefined, which will cause a type error.
          window?.dispatchEvent?.(clonedEvent);
        }
      });
    });
  }

  /**
   * Registers a window against events given on construction of the Propagator.
   * @param {Window|Document|Function|Element} windowOrGetterOrElement
   */
  register(windowOrGetterOrElement) {
    const window = unwrapFn(windowOrGetterOrElement);

    // Unregister and re-register just in case, reason being that we garbage collect
    // window references between navigations, so even though we already registered a
    // listener it might not actually be listening anymore
    if (this._registered.findIndex((r) => r.window === window) !== -1) {
      this.unregister(window);
    }

    const id = this._increment++;
    const propagate = (ev) => this._propagateEvent(id, ev);
    this._registered = this._registered.concat({ id, window, propagate });
    this._events.forEach(({ type }) =>
      window.addEventListener(type, propagate)
    );
  }

  unregister(windowOrGetterOrElement) {
    const window = unwrapFn(windowOrGetterOrElement);
    const index = this._registered.findIndex((r) => r.window === window);
    if (index !== -1) {
      const { propagate } = this._registered[index];
      this._registered.splice(index, 1);
      this._events.forEach(({ type }) =>
        window.removeEventListener(type, propagate)
      );
    }
  }

  _propagateEvent(id, event) {
    // NOTE: We don't want to propagate propagations or we get an infinite crescendo
    if (NativeEventPropagator.EVENT_MARKER in event) return;
    const eventConfig = this._events.find((e) => {
      return e.type === event.type;
    });
    if (eventConfig.predicate(event)) {
      this._emitter.emit('propagate', { id, event });
    }
  }

  static normaliseEventMap(eventMap) {
    return eventMap.map((v) => {
      if (_.isString(v)) {
        return { type: v, predicate: alwaysTrue };
      } else if (_.isObject(v)) {
        return _.isFunction(v.predicate) ? v : { ...v, predicate: alwaysTrue };
      } else {
        throw new Error(
          'Unexpected configuration for NativeEventPropagator.\n' +
            JSON.stringify(eventMap, null, 2)
        );
      }
    });
  }
}

export const PressAndEscapePropagator = new NativeEventPropagator([
  {
    type: 'click',
    predicate: (event) => !event.metaKey && !event.ctrlKey && !event.shiftKey
  },
  'touchstart',
  'touchmove',
  'touchend',
  'mousedown',
  'mouseup',
  {
    type: 'key',
    predicate: (event) => event.keyCode === 27 /* Escape key */
  }
]);
