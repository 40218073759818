import React from 'react';

import { StyleSheet, StylesProvider } from '@rexlabs/styling';

import { COLORS } from 'theme';

import { Popout, PopoutProps } from 'view/components/popout';

const popoutOverrides = {
  Popout: StyleSheet({
    popout: {
      borderRadius: '3px',
      padding: '5px',
      maxWidth: 'none',
      background: COLORS.WHITE
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      '&:before': {
        background: COLORS.WHITE
      }
    }
  })
};

export function NavigationPopout(props: PopoutProps) {
  const { children } = props;

  return (
    <StylesProvider components={popoutOverrides} prioritiseParentStyles={false}>
      <Popout {...props}>{children}</Popout>
    </StylesProvider>
  );
}
