import React from 'react';
import { RowNode } from 'ag-grid-community';
import { useEditAppointment } from 'hooks/use-edit-appointment';
import { Link } from 'components/text/link';
import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';

export function AppointmentColumn({
  data,
  value,
  node
}: {
  data: Record<string, any>;
  value?: string;
  node: RowNode | null;
}) {
  const { reloadReportData } = useReportState();
  const openAppointmentDialog = useEditAppointment({
    id: data?.id,
    callback: reloadReportData
  });
  const id = data?.id;
  const canViewDetails = data?.show_event_details;
  // we don't get an id when grouping, so don't show the link
  return data?.id && canViewDetails ? (
    <Link blue undecorated record onClick={openAppointmentDialog}>
      {value}
    </Link>
  ) : (
    value || getEmptyValue({ node })
  );
}
