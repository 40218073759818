import React from 'react';
import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { ModelGeneratorLoaded } from '@rexlabs/model-generator';
import { api } from 'shared/utils/api-client';
import MarketLeadOption from 'view/components/input/select/options/market-lead';
import Value from 'view/components/input/select/values';
import { UserItem } from 'data/models/value-lists/account-users';
import { ContactStub } from './contacts';
import { PropertyStub } from './properties';

export interface MarketLeadStub {
  assignee: UserItem;
  id: string;
  lead_status: { id: string; text: string };
  system_label: string;
}

// Note: This is a partial typing based of what I was getting from BE at the time.
// Likely some of this is incorrect, but feel free to update with correct types as you go.
export interface MarketLeadItem {
  adr_country: string;
  adr_locality: string | null;
  adr_postcode: string | null;
  adr_state_or_region: string | null;
  adr_street_name: string | null;
  adr_street_number: string | null;
  adr_suburb_or_town: string | null;
  adr_unit_number: string | null;
  archive_reason: string | null;
  assignee: UserItem | null;
  assignee_claim_basis: string | null;
  contact: ContactStub | null;
  contact_email_address: string | null;
  contact_name: string | null;
  contact_phone_number: string | null;
  etag: string;
  id: string;
  lead_image_uri: string | null;
  lead_progress: {
    assigned: boolean;
    contact_attached: boolean;
    contact_details_added: boolean;
    property_attached: boolean;
    property_details_added: boolean;
  };
  lead_status: { id: string; text: string };
  property: PropertyStub;
  related: {
    market_lead_tags: string[];
    market_lead_origins: string[];
  };
  security_user_rights: string[];
  system_assigned_by_user: UserItem | null;
  system_assigned_time: number | null;
  system_contact_attached_by_user: UserItem | null;
  system_contact_attached_time: number | null;
  system_contact_details_added_by_user: UserItem | null;
  system_contact_details_added_time: number | null;
  system_created_user: UserItem;
  system_ctime: number;
  system_label: string;
  system_modified_user: UserItem | null;
  system_modtime: number | null;
  system_owner_user: UserItem;
  system_property_attached_by_user: UserItem | null;
  system_property_attached_time: number | null;
  system_property_details_added_by_user: UserItem | null;
  system_property_details_added_time: number | null;
}

export type MarketLeadModel = ModelGeneratorLoaded<MarketLeadItem, any>;

const TYPE = 'marketLeads';

const actionCreators = {
  archiveLead: {
    request: ({ id, ...data }) => {
      return api.post('MarketLeads::archiveWithReason', {
        id,
        ...data
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  unarchiveLead: {
    request: ({ id }) => {
      return api.post('MarketLeads::recoverFromArchive', {
        id
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const marketLeadsModel = new Generator<MarketLeadItem, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

marketLeadsModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('MarketLeads::autocomplete', {
        search_string: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((marketLead) => ({
          value: marketLead.id,
          label: marketLead.system_label,
          data: marketLead,
          model: marketLeadsModel
        }))
      ),
  Option: MarketLeadOption,
  Value: (props) => <Value {...props} service={TYPE} />
};

export default marketLeadsModel;
