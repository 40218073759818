import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Option from './core';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: '#FFFFFF',
    ...TEXTS.SETTINGS_NAVIGATION.MENU_ITEM,
    padding: '4px',
    paddingLeft: '10px',
    width: '100%',

    '&:hover': {
      backgroundColor: '#CAE8F8'
    }
  },

  address: {
    paddingLeft: '5px',
    color: 'rgb(146, 144, 136)',
    fontStyle: 'italic'
  }
});

@styled(defaultStyles)
class ContractOption extends PureComponent {
  render() {
    const { option, styles: s } = this.props;

    return (
      <Option {...this.props}>
        <Box {...s('container')}>
          <span>
            {option.labelMeta.amount}
            {option.labelMeta.names}
          </span>
          <span {...s('address')}>{option.labelMeta.address}</span>
        </Box>
      </Option>
    );
  }
}

export default ContractOption;
