import React, { useMemo, ReactNode, CSSProperties } from 'react';

import Button, { ButtonProps } from '@rexlabs/button';
import { StyleSheet, StylesProvider, useStyles } from '@rexlabs/styling';
import { COLORS, PADDINGS, FONT } from 'src/theme';
interface IconButtonProps extends ButtonProps {
  Icon?: any;
  /**
   * Most color boolean props require circle to be true to have any effect.
   */
  circle?: boolean;
  /**
   * The color prop applies to the text regardless and the icon when not in a circle
   */
  color?: string;
  red?: boolean;
  blue?: boolean;
  green?: boolean;
  /**
   * this color applies regardless of circle
   */
  lightGrey?: boolean;
  grey?: boolean;
  dark?: boolean;
  form?: boolean;
  isDisabled?: boolean;
  onClick?: Function;
  children?: ReactNode;
  'data-testid'?: string;
  iconStyles?: CSSProperties;
}

const defaultStyles = StyleSheet({
  label: {
    marginLeft: '2px'
  },

  circleLabel: {
    marginLeft: PADDINGS.XXS,
    color: COLORS.PRIMARY.GREY_LIGHTER,
    letterSpacing: '0.3px',
    fontSize: '12px',
    lineHeight: '19px'
  },

  wrapIcon: {
    display: 'flex',
    flexShrink: 0,
    width: '25px',
    height: '25px',
    borderRadius: '25px',
    alignItems: 'center',
    justifyContent: 'center'
  },

  wrapIconCircle: {
    '&:hover': {
      backgroundColor: 'currentColor !important'
    },

    ':active': {
      boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.3) inset'
    },

    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },

  icon: {
    width: '21px',
    height: 'auto',

    '& *': {
      fill: 'currentColor !important'
    }
  },

  iconCircle: {
    '& *': {
      fill: 'white !important'
    }
  }
});

function IconButton(props: IconButtonProps) {
  const {
    red,
    blue,
    green,
    dark,
    lightGrey,
    grey,
    children,
    circle,
    Icon,
    color,
    form,
    onClick,
    iconStyles,
    ...rest
  } = props;

  const s = useStyles(defaultStyles, 'iconButton');

  const propColor = useMemo(() => {
    let textColor = color;

    // lightGrey is a common case that is required for most FieldArrays and is unrelated to `circle`
    if (lightGrey) {
      return {
        default: COLORS.PRIMARY.SAND_LIGHT,
        text: '#b4b1a9'
      };
    }

    if (!circle) {
      return { text: textColor };
    }

    if (red) {
      return {
        default: COLORS.STATES.RED,
        hover: COLORS.STATES.RED_HOVER,
        text: textColor || COLORS.DARK_GREY
      };
    }

    if (!textColor) {
      textColor = '#B4B1A9';
    }

    if (blue) {
      return {
        default: COLORS.PRIMARY.BLUE,
        hover: COLORS.STATES.BLUE_HOVER,
        text: textColor
      };
    }

    if (green) {
      return {
        default: '#8BBF3C',
        hover: '#6aa510',
        text: textColor
      };
    }

    if (dark) {
      return {
        default: '#424242',
        hover: '#363636',
        text: textColor
      };
    }

    if (grey) {
      return {
        default: '#D7D9DA',
        hover: '#C9C8C4',
        text: textColor
      };
    }

    return {
      default: COLORS.PRIMARY.INFORMATIVE,
      hover: COLORS.PRIMARY.SAND_HOVER,
      text: textColor
    };
  }, [blue, circle, color, dark, green, grey, lightGrey, red]);

  const styles = useMemo(() => {
    const color = propColor.text;
    return {
      Button: StyleSheet({
        container: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          userSelect: 'none',
          cursor: 'help',
          whiteSpace: 'nowrap',
          backgroundColor: 'transparent',
          lineHeight: '16px',
          fontSize: '13px',
          fontWeight: FONT.WEIGHTS.SEMIBOLD,
          fontFamily: FONT.FAMILY.PROXIMA_NOVA,
          border: '0 none',
          position: 'relative',
          color: '#B4B1A9',

          '&:before': {
            position: 'absolute'
          }
        },

        content: {
          cursor: 'pointer',
          display: 'flex',
          color: color || COLORS.PRIMARY.GREY_DARK,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',

          '& span': {
            display: 'flex',
            alignItems: 'center'
          }
        },

        containerDisabled: {
          color: 'grey',
          pointerEvents: 'none'
        },

        disabled: {
          opacity: '.5',
          color: 'red'
        }
      })
    };
  }, [propColor.text]);

  return (
    <StylesProvider components={styles} prioritiseParentStyles={false}>
      <Button
        type={form ? 'submit' : 'button'}
        form={form}
        onClick={onClick}
        {...rest}
      >
        {!!Icon && (
          <span
            {...s.with('wrapIcon', { wrapIconCircle: circle })({
              backgroundColor: circle ? propColor.default : undefined,
              color: propColor.hover // HACK to be able to use currentColor in styles!
            })}
          >
            <Icon style={iconStyles} {...s('icon', { iconCircle: circle })} />
          </span>
        )}
        {!!children && (
          <span
            {...s('label', { circleLabel: circle })}
            style={{ color: propColor.text }}
          >
            {children}
          </span>
        )}
      </Button>
    </StylesProvider>
  );
}
export default IconButton;
