import Box from '@rexlabs/box';
import { useModelActions } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';
import { useRecordNavigation } from 'components/_experiment/record-details-screen/utils/use-record-navigation';
import { useDialog } from 'hooks/use-dialog';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import React, { useCallback } from 'react';
import { ICONS } from 'shared/components/icon';
import DefaultButton from 'src/view/components/button/default-button';
import uiModel from 'data/models/custom/ui';
import { isBoolean } from 'lodash';
import { ContractCondition } from 'features/contract-conditions/data/contract-conditions';

const ArrowIcon: any = ICONS.ARROW_RIGHT;
const ArrowBack: any = ICONS.ARROW_BACK;

const defaultStyles = StyleSheet({
  page: {
    marginRight: '4px',
    fontSize: '14px',
    fontSeight: 400
  },
  icon: {
    color: '#fff'
  },
  iconRight: {
    transform: 'rotate(180deg)'
  }
});

export const RecordActions = ({
  editCondition,
  contractCondition,
  setContractCondition,
  contractConditions,
  errorDialog,
  isParentAccount,
  hasManagedLibraries
}) => {
  const s = useStyles(defaultStyles);
  const { loadingIndicatorOff, loadingIndicatorOn } = useModelActions(
    uiModel
  ) as any;
  const { path, query, hashQuery } = useWhereaboutsWithViewpath();
  const { allIds, currIndex, goToPrev, goToNext } = useRecordNavigation();
  const confirmation = useDialog('confirmation');
  const editTemplateLibrarySettings = useDialog('editTemplateLibrarySettings');

  const { archiveContractCondition, removeContractCondition } =
    contractConditions;

  const goBack = () => {
    push({
      config: {
        path,
        query,
        hash: undefined,
        // eslint-disable-next-line
        // @ts-ignore
        hashQuery: { ...hashQuery, id: undefined }
      }
    });
  };

  const onArchive = useCallback(async () => {
    confirmation.open({
      title: `Archive ‘${contractCondition.name}’`,
      message: `Are you sure you want to archive ‘${contractCondition.name}’?`,
      confirmText: 'Confirm Archive',
      onConfirm: async () => {
        loadingIndicatorOn({ message: 'Archiving' });
        try {
          await archiveContractCondition(contractCondition.id);
          confirmation.close();
          loadingIndicatorOff();
          goBack();
        } catch (error) {
          loadingIndicatorOff();
          errorDialog.open(error as Error);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractCondition]);

  const onRemove = useCallback(async () => {
    confirmation.open({
      title: `Delete ‘${contractCondition.name}’`,
      message: `Are you sure you want to delete ‘${contractCondition.name}’?`,
      confirmText: 'Delete',
      onConfirm: async () => {
        loadingIndicatorOn({ message: 'Removing' });
        try {
          await removeContractCondition(contractCondition.id);
          confirmation.close();
          loadingIndicatorOff();
          goBack();
        } catch (error) {
          loadingIndicatorOff();
          errorDialog.open(error as Error);
        }
      }
    });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractCondition]);

  const onDuplicate = useCallback(() => {
    editCondition.open({
      mode: 'duplicate',
      contractCondition: {
        ...contractCondition,
        name: `${contractCondition.name} Copy`
      }
    });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractCondition]);

  const onEditLibrarySettings = () => {
    const { library } = contractCondition;
    editTemplateLibrarySettings.open({
      data: library
        ? {
            id: library.id,
            _library_name: library.library_name
          }
        : null,
      recordId: contractCondition.id,
      className: 'AdminContractConditions',
      callback: (res: boolean | { id: string; _library_name: string }) => {
        if (isBoolean(res) && res) {
          setContractCondition((prev: ContractCondition) => ({
            ...prev,
            library: null
          }));
        } else if (res) {
          setContractCondition((prev: ContractCondition) => ({
            ...prev,
            library: {
              ...res,
              library_name: res._library_name
            }
          }));
        }
      }
    });
  };

  return (
    <Box alignItems='center' justifyContent='space-between'>
      <Box alignItems='center' spacing={5}>
        <DefaultButton wide dark onClick={goBack}>
          <ArrowBack />
        </DefaultButton>
        {isParentAccount && (
          <>
            <DefaultButton onClick={onArchive}>Archive</DefaultButton>
            <DefaultButton onClick={onRemove}>Delete</DefaultButton>
          </>
        )}
        <DefaultButton onClick={onDuplicate}>Duplicate</DefaultButton>
        {isParentAccount && hasManagedLibraries && (
          <DefaultButton onClick={onEditLibrarySettings}>
            Library Settings
          </DefaultButton>
        )}
      </Box>
      {allIds.length > 1 && (
        <Box alignItems='center' spacing={2}>
          <Box {...s('page')}>
            {currIndex + 1} of {allIds.length}
          </Box>
          <DefaultButton
            padding={false}
            onClick={goToPrev}
            isDisabled={currIndex === 0}
          >
            <ArrowIcon {...s('icon')} />
          </DefaultButton>
          <DefaultButton
            padding={false}
            onClick={goToNext}
            isDisabled={currIndex === allIds.length - 1}
          >
            <ArrowIcon {...s('icon', 'iconRight')} />
          </DefaultButton>
        </Box>
      )}
    </Box>
  );
};
