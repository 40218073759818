import React, { PureComponent } from 'react';
import { FormField } from 'view/components/form';
import { Grid, Column } from 'shared/components/grid';
import { DateSelect, ValueListSelect } from 'view/components/input/select';
import { TextArea } from '@rexlabs/text-input';
import CurrencyInput from 'view/components/input/currency';

import { autobind } from 'core-decorators';
import PaddingBox from 'view/components/padding-box';
import { Hint } from 'components/text/hint';
import { styled, StyleSheet } from '@rexlabs/styling';

import Requires from 'view/containers/requires';
import _ from 'lodash';
import restrictedImage from './restricted-lock.png';
import { connect } from 'react-redux';
import { checkUserHasPermission } from 'utils/rights';

import accountUsersModel from 'data/models/value-lists/account-users';
import substantiationMethodModel from 'data/models/system-lists/substantiation-method';

const styles = StyleSheet({
  notAllowed: {
    height: '250px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  padlock: {
    position: 'absolute',
    right: '0',
    bottom: '0'
  }
});

@connect((state) => ({ session: state.session }))
@styled(styles)
@autobind
class TabDetails extends PureComponent {
  shapifyOption(option) {
    if (option) {
      return {
        label: option.text || option.label || option.name,
        value: option.id || option.value
      };
    }
  }

  substantationUserOptions() {
    const { initialSubstantiatedByUserIdValue } = this.props;
    if (initialSubstantiatedByUserIdValue) {
      return [this.shapifyOption(initialSubstantiatedByUserIdValue)];
    }
    return [];
  }

  checkUserHasPermission(accessRights) {
    const { session } = this.props;
    return checkUserHasPermission((dotNotation) => _.get(session, dotNotation))(
      accessRights
    );
  }

  render() {
    const { styles: s } = this.props;

    return (
      <PaddingBox>
        <Requires accessRights='contacts.view_substantiation_details'>
          <FormField
            label='maximum amount'
            name='amount'
            Input={CurrencyInput}
            inputProps={{
              symbol: '£',
              disabled: !this.checkUserHasPermission(
                'contacts.view_edit_substantiation_details'
              )
            }}
          />

          <FormField
            label='substantiation method'
            name='methodId'
            Input={ValueListSelect}
            inputProps={{
              disabled: !this.checkUserHasPermission(
                'contacts.view_edit_substantiation_details'
              ),
              models: [substantiationMethodModel]
            }}
          />

          <Grid>
            <Column width={6}>
              <FormField
                label='substantiated by'
                name='substantiatedByUserId'
                Input={ValueListSelect}
                inputProps={{
                  disabled: !this.checkUserHasPermission(
                    'contacts.view_edit_substantiation_details'
                  ),
                  models: [accountUsersModel],
                  hasFixtures: false,
                  options: this.substantationUserOptions()
                }}
              />
            </Column>
            <Column width={6}>
              <FormField
                sendImmediate
                label='substantiated on'
                name='substantiationDate'
                Input={DateSelect}
                inputProps={{
                  disabled: !this.checkUserHasPermission(
                    'contacts.view_edit_substantiation_details'
                  )
                }}
              />
            </Column>
          </Grid>

          <FormField
            label='evidence/note'
            name='note'
            Input={TextArea}
            inputProps={{
              disabled: !this.checkUserHasPermission(
                'contacts.view_edit_substantiation_details'
              )
            }}
          />
        </Requires>

        {/* TODO: insert correct permissions */}
        <Requires accessRights={'!contacts.view_substantiation_details'}>
          <div {...s('notAllowed')}>
            <Hint>You don’t have permission to view this information.</Hint>
            <img
              src={restrictedImage}
              height='100px'
              {...s('padlock')}
              alt='restricted access padlock'
            />
          </div>
        </Requires>
      </PaddingBox>
    );
  }
}

export default TabDetails;
