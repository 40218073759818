import React, { useCallback, useMemo } from 'react';
import { ReactForms } from '@rexlabs/form';

import { HiddenField } from 'view/components/form';

import { RecordDetailsContext } from './context';
import {
  RecordDetailsScreenContent,
  RecordDetailsScreenContentProps
} from './content';

import { useLoadingIndicator } from './utils/use-loading-indicator';
import { useCustomTabs } from './utils/use-custom-tabs';
import { deepDiff } from './utils/deep-diff';

interface RecordDetailsScreenProps
  extends Omit<RecordDetailsScreenContentProps, 'form' | 'customTabs'> {
  handleSubmit: (data: any, args: any) => any; // TODO: properly type this
}

export function RecordDetailsScreen({
  handleSubmit,
  data,
  status,
  serviceName,
  id,
  ...props
}: RecordDetailsScreenProps) {
  // Load custom tabs and custom values for the custom tabs
  const {
    customTabs,
    customData,
    updateCustomData,
    isLoading: customTabsLoading
  } = useCustomTabs({ id, serviceName });

  useLoadingIndicator(status, customTabsLoading);

  const initialValues = useMemo(
    () => ({
      ...data,
      __customFields: {
        ...customData,
        // HACK: we're addding this property to make sure the form doesn't
        // cast the values to an array on submit (because out of the box the
        // custom fields object only has numeric keys)
        __forceObject: true
      }
    }),
    [customData, data]
  );

  const handleSubmitWithCustomValues = useCallback(
    async (values, meta) => {
      // Get changed values by deep diffing the values with the initial values
      // We still pass both to the `handleSubmit`, so the record screen itself
      // can decide which one to use
      const changedValues = deepDiff(values, initialValues);

      // Grab custom fields from values, since we want to handle saving those
      // automatically
      const { __customFields, ...rest } = changedValues || {};

      // Run custom data save and `handleSubmit` in parallel
      return Promise.all([
        __customFields && updateCustomData(__customFields),
        handleSubmit({ changedValues: rest, values, __customFields }, meta)
      ]);
    },
    [handleSubmit, initialValues, updateCustomData]
  );

  if (status === 'loading' || customTabsLoading) {
    return null;
  }

  return (
    <ReactForms
      handleSubmit={handleSubmitWithCustomValues}
      initialValues={initialValues}
    >
      {(formProps) => (
        <RecordDetailsContext.Provider value={{ form: formProps }}>
          <HiddenField name='__customFields.__forceObject' value={true} />
          <RecordDetailsScreenContent
            {...props}
            data={data}
            status={status}
            serviceName={serviceName}
            id={id}
            customTabs={customTabs}
            form={formProps}
          />
        </RecordDetailsContext.Provider>
      )}
    </ReactForms>
  );
}
