import React, { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';

import { ICONS } from 'shared/components/icon';

import { IconValueStatusButton } from 'components/button/icon-value-status-button';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { useEditAppointment } from 'hooks/use-edit-appointment';

import { getActivityStatus } from 'features/leads/utils/get-activity-status';
import { getAppointmentData } from 'features/leads/utils/get-appointment-data';

type ButtonProps = Pick<
  ComponentProps<typeof IconValueStatusButton>,
  'status' | 'helpText'
>;

interface BookAppointmentActionButtonProps extends ButtonProps {
  data: LeadsResponse;
  callback: () => any;
  activitiesList?: Activity[];
}

export function BookAppointmentActionButton({
  data,
  activitiesList,
  callback
}: BookAppointmentActionButtonProps) {
  const appointmentStatus = getActivityStatus(
    'calendar_event_added',
    activitiesList
  );
  const appointmentData = useMemo(() => {
    return getAppointmentData(data);
  }, [data]);

  const onAddAppointmentClick = useEditAppointment({
    data: appointmentData,
    callback
  });

  return (
    <IconValueStatusButton
      iconType={ICONS.DASHBOARDS.CALENDAR}
      onClick={onAddAppointmentClick}
      status={appointmentStatus && 'Booked'}
      helpText={
        appointmentStatus &&
        `Appointment booked ${dayjs(
          appointmentStatus.system_ctime * 1000
        ).fromNow()} by ${appointmentStatus.system_created_user.name}`
      }
    >
      Book Appointment
    </IconValueStatusButton>
  );
}
