import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { Bridges } from 'data/classic-bridges';
import Fixture from './default';

@autobind
class ContactsFixture extends Component {
  handleFixtureClick() {
    const { option: meta, term } = this.props;

    const firstName = term.split(' ')[0];
    const lastName = term.substring(firstName.length + 1);

    Bridges.Dialogs.createContact.openClassic({
      data: {
        type: _.get(meta, 'fixturePrefillData.type'),
        company_name: _.get(meta, 'fixturePrefillData.company_name'),
        name_first: firstName || _.get(meta, 'fixturePrefillData.name_first'),
        name_last: lastName || _.get(meta, 'fixturePrefillData.name_last'),
        email_address: _.get(meta, 'fixturePrefillData.email_address'),
        phone_number: _.get(meta, 'fixturePrefillData.phone_number')
      },
      callback: _.get(meta, 'fixturePrefillData.callback')
    });
  }

  render() {
    return (
      <Fixture addNewEntity={this.handleFixtureClick}>ADD NEW CONTACT</Fixture>
    );
  }
}

export default ContactsFixture;
