import _ from 'lodash';
import React from 'react';
import { autobind } from 'core-decorators';

import { ICONS } from 'shared/components/icon';
import {
  ProviderInterface,
  VIEW_MODES
} from 'view/components/navigation/app-search/base';
import SearchSectionHeader from 'view/components/navigation/app-search/sections/section-header';
import SectionRecents from 'view/components/navigation/app-search/sections/section-recents';
import { PADDINGS } from 'theme';

@autobind
class RecentProvider extends ProviderInterface {
  static TYPE = VIEW_MODES.RECENT;

  get type() {
    return RecentProvider.TYPE;
  }

  search() {
    return null; // Ignore as we are passing down recentOptions prop to the Search
  }

  renderOption(props) {
    const { options, ...optionProps } = props;

    if (!options.length) {
      return null;
    } else {
      return (
        <div key='recent-screen' style={{ padding: `${PADDINGS.M} 0` }}>
          <SearchSectionHeader
            onClick={_.noop}
            type={'Recently Accessed'}
            icon={ICONS.APP_SEARCH.RECENT}
            key='recent-header'
          />
          <SectionRecents options={options} {...optionProps} />
        </div>
      );
    }
  }

  renderSkeleton() {
    return null;
  }
}

export default RecentProvider;
