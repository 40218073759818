import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import apiCacheModel from 'data/models/custom/api-cache';
import ThirdPartyServiceSpokeSocial from 'data/models/custom/third-party-service-spoke-social';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import IconButton from 'view/components/button/icon';
import { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import TextButton from 'view/components/button/text';
import { withDialog } from 'shared/hocs/with-dialog';
import { TEXTS, COLORS, PADDINGS, FONT } from 'theme';
import SpokeSocialDialog from 'view/dialogs/spoke-social';
import Spinner from 'shared/components/spinner';
import { StatusCircle } from 'view/components/status-circle';
import _ from 'lodash';

// No StyleSheet means the below styles are applied inline
// TODO: Refactor this to use styles prop, means we need to adjust TextButton to handle it
const textLinks = {
  Button: {
    container: {
      padding: `${PADDINGS.XXS} 0`,
      height: 'auto'
    },
    content: {
      justifyContent: 'flex-start'
    }
  }
};

const buttonStyles = StyleSheet({
  icon: {
    width: '14px'
  }
});

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.BACKGROUNDS.SAND_LIGHT,
    marginTop: '20px',
    ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_1,

    // Note: This handles showing / hiding the action button that appears beside the title
    '& > div > button': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0.3s, opacity 0.3s',
      padding: 0,
      marginLeft: '6px'
    },

    '&:hover': {
      '& > div > button': {
        visibility: 'visible',
        opacity: 1
      }
    }
  },

  text: {
    fontSize: FONT.SIZES.SMALL,
    fontWeight: FONT.WEIGHTS.BOLD,
    lineHeight: 1.3
  }
});

@withDialog(SpokeSocialDialog, { propName: 'spokeSocialDialog' })
@withModel(ThirdPartyServiceSpokeSocial)
@withModel(apiCacheModel)
@styled(defaultStyles)
@autobind
class SpokeWidget extends PureComponent {
  state = {
    loading: true,
    connectionId: null,
    spoke: {}
  };

  async componentDidMount() {
    await this.getSpoke();
  }

  async getSpoke(forceRetrieval) {
    const { cache, recordId, thirdPartyServiceSpokeSocial } = this.props;

    try {
      this.setState({ loading: true });

      const connections = await cache.fetch({
        method: 'ThirdPartyServices::getConnections',
        args: {
          connection_state: 'connected',
          include_inherited: true
        }
      });

      const connectionId = _.get(
        connections.find((c) => c.service_type_id === 'spoke_social'),
        'id'
      );

      const spokeResponse = await thirdPartyServiceSpokeSocial.getRecordLink({
        connectionId,
        recordId,
        serviceName: 'Listings',
        forceRetrieval
      });

      this.setState({
        connectionId,
        spoke: _.get(spokeResponse, 'data.result', {}),
        loading: false
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  openDialog(createLink) {
    const { spokeSocialDialog, spoke, recordId } = this.props;
    const { connectionId } = this.state;

    spokeSocialDialog.open({
      createLink,
      link: _.get(spoke, `links.${createLink ? 'create' : 'list'}`),
      connectionId,
      recordId,
      getSpoke: this.getSpoke
    });
  }

  renderSpokeDetails() {
    const { styles: s } = this.props;
    const { loading, spoke } = this.state;
    const {
      active_campaigns: activeCampaigns,
      finished_campaigns: finishedCampaigns
    } = spoke;

    if (loading) {
      return (
        <Box justifyContent='center' m={PADDINGS.XXS}>
          <Spinner small dark />
        </Box>
      );
    }

    if (activeCampaigns || finishedCampaigns) {
      return (
        <Fragment>
          {!!activeCampaigns && (
            <Box alignItems='center'>
              <StatusCircle green />
              <p {...s('text')}>
                {activeCampaigns} active campaign
                {activeCampaigns > 1 && "'s"}
              </p>
            </Box>
          )}
          {!!finishedCampaigns && (
            <Box alignItems='center'>
              <StatusCircle grey />
              <p {...s('text')}>
                {finishedCampaigns} finished campaign
                {finishedCampaigns > 1 && "'s"}
              </p>
            </Box>
          )}
          <StylesProvider components={textLinks} prioritiseParentStyles={false}>
            <TextButton
              blue
              textDecoration='none'
              onClick={() => this.openDialog(true)}
            >
              Create new campaign
            </TextButton>
          </StylesProvider>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p {...s('text')}>
            Advertise your listing on Facebook, Instagram and Google.
          </p>
          <StylesProvider components={textLinks} prioritiseParentStyles={false}>
            <TextButton
              blue
              textDecoration='none'
              onClick={() => this.openDialog(true)}
            >
              Advertise this listing
            </TextButton>
          </StylesProvider>
        </Fragment>
      );
    }
  }

  render() {
    const { styles: s } = this.props;

    return (
      <Box {...s('container')} flexDirection={'column'}>
        <Box alignItems={'center'} mb={5}>
          <SubHeading semibold>Spoke Campaigns</SubHeading>
          <IconButton
            red
            circle
            Icon={ICONS.EDIT}
            styles={buttonStyles}
            onClick={() => this.openDialog(false)}
          />
        </Box>
        {this.renderSpokeDetails()}
      </Box>
    );
  }
}

export default SpokeWidget;
