import { usePermissions } from 'hooks/use-permissions';
import { TenancyApplicationItem } from '../data/types';
import { useCallback } from 'react';

export type PermissionTypes = {
  getTenancyRights: (
    rights?: TenancyApplicationItem['security_user_rights']
  ) => {
    canCreate: boolean;
    canView: boolean;
    canUpdate: boolean;
    canPurge: boolean;
  };
};

export const useApplicationPermissions = (): PermissionTypes => {
  const { check } = usePermissions();
  const getTenancyRights = useCallback(
    (rights?: TenancyApplicationItem['security_user_rights']) => {
      // a user may have privilege (update / read) but it can vary on the user's security rights.
      const canUpdate =
        !!check('listing_applications.update') &&
        !!(rights && rights?.includes('update'));

      const canView =
        !!check('listing_applications.read') &&
        !!(rights && rights?.includes('read'));

      return {
        canCreate: !!check('listing_applications.create'),
        canUpdate,
        canView,
        canPurge: !!check('listing_applications.purge')
      };
    },
    [check]
  );

  return { getTenancyRights };
};
