import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { ModelGeneratorLoaded, Id } from '@rexlabs/model-generator';

const TYPE = 'chains';

// TODO  - type the chain item fully
// CH - https://app.clubhouse.io/rexlabs/story/55983/type-chains-model
export interface ChainStub {
  bottom_of_chain_is_closed: boolean;
  chain_is_complete: boolean;
  id: string;
  link_count: number;
  percent_complete: number;
  top_of_chain_is_closed: boolean;
}

type MarkAsClosedAction = (payload: {
  id: Id;
  closed: boolean;
}) => Promise<ChainStub>;

export interface ChainModel extends ModelGeneratorLoaded<ChainStub, any> {
  createFromOffer: ({ id }: { id: Id }) => Promise<ChainStub>;
  markEndAsClosed: MarkAsClosedAction;
  markStartAsClosed: MarkAsClosedAction;
}

const actionCreators = {
  createFromOffer: {
    request: (payload) =>
      api.post('ChainLinks::create', {
        data: {
          type_id: 'contract',
          contract_id: payload.id
        }
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  markEndAsClosed: {
    request: (payload, actions) =>
      api
        .post('Chains::update', {
          data: {
            id: payload.id,
            top_of_chain_is_closed: payload.closed !== false
          }
        })
        .then(() => actions.fetchItem({ id: payload.id })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  markStartAsClosed: {
    request: (payload, actions) =>
      api
        .post('Chains::update', {
          data: {
            id: payload.id,
            bottom_of_chain_is_closed: payload.closed !== false
          }
        })
        .then(() => actions.fetchItem({ id: payload.id })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator(TYPE).createEntityModel({ actionCreators });
