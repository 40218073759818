import { useCallback } from 'react';
import { useClassicDialog } from 'hooks/use-classic-dialog';

export function useGoToRelated({
  service_name,
  callback = null,
  options = {}
}) {
  const crossServiceNavigation = useClassicDialog('crossServiceNavigation');

  // Note: I intended to add the `callback` and `options` args here to decrease
  // chances of getting bugs (playsafe) although this is not being
  // used in cross-navigation atm.
  return useCallback(
    ({ selectionCriteria }) =>
      crossServiceNavigation?.open({
        service: service_name,
        criteria: selectionCriteria,
        callback,
        options
      }),
    [crossServiceNavigation, service_name, callback, options]
  );
}
