import React from 'react';

import { ICONS } from 'shared/components/icon';

import { ReportingFilterButton } from './index';

type FiltersReportingFilterButtonProps = Omit<
  React.ComponentProps<typeof ReportingFilterButton>,
  'Icon'
>;

export function FiltersReportingFilterButton({
  showArrowIcon = true,
  ...props
}: FiltersReportingFilterButtonProps) {
  return (
    <ReportingFilterButton
      Icon={ICONS.DASHBOARDS.FILTER}
      showArrowIcon={showArrowIcon}
      {...props}
    />
  );
}
