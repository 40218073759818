import React, { useCallback, useMemo } from 'react';

import { query, useEntityQuery, Id } from '@rexlabs/model-generator';

import campaignsModel from 'data/models/entities/campaigns';

import { RecordDetailsScreen } from 'components/_experiment/record-details-screen';

import { campaignsBlocks } from '../data/record-blocks';
import { campaignDetailsActions } from '../data/record-details-actions';

import { CampaignTitleBlock } from '../components/campaign-title-block';

interface CampaignDetailsScreenProps {
  id: Id;
}

const getCampaignQuery = (id: Id) => query`{
  ${campaignsModel} (id: ${id}, extra_fields: ${['_security_user_rights']}) {
    id
    archive_reason
    end_date
    start_date
    name
    status {
      id
      text
    }
    type {
      id
      text
    }
    security_user_rights
    related {
      campaign_member_statuses
    }
  }
}`;

export function CampaignDetailsScreen({ id }: CampaignDetailsScreenProps) {
  const projectQuery = useMemo(() => getCampaignQuery(id), [id]);
  const { data: campaignData, status, actions } = useEntityQuery(projectQuery);

  const handleSubmit = useCallback(
    ({ changedValues }) =>
      // Note: Converting to string here because the type it expects is a string.
      // probably something we want to change in vivid.
      // https://app.shortcut.com/rexlabs/story/62678/update-updateitem-type-in-vivid-so-id-is-string-or-number
      actions.updateItem({ id: id.toString(), data: changedValues }),
    [actions, id]
  );

  return (
    <RecordDetailsScreen
      serviceName='Projects'
      id={id}
      data={campaignData}
      status={status}
      actions={campaignDetailsActions}
      blocks={campaignsBlocks}
      TitleBlock={CampaignTitleBlock}
      handleSubmit={handleSubmit}
    />
  );
}
