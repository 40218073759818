import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

// This text component is used for pieces of information.
interface ContextTextProps extends BaseTextProps {
  heading?: boolean;
  dark?: boolean;
  truncated?: boolean;
  semibold?: boolean;
}

export function Context({
  heading,
  dark,
  truncated,
  semibold,
  ...props
}: ContextTextProps) {
  return (
    <Text
      is='span'
      {...props}
      type={map({
        CONTEXT: true,
        CONTEXT_HEADING: heading,
        DARK: dark,
        TRUNCATED: truncated,
        SEMIBOLD: semibold
      })}
    />
  );
}
