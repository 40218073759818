import _ from 'lodash';
import React from 'react';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import Value from 'view/components/input/select/values';
import ProjectsOption from 'view/components/input/select/options/projects';

const TYPE = 'projects';

export interface ProjectStub {
  id: string;
  name: string;
  _name: string;
  type: { id: string; text: string };
  developer_logo: any;
  estate_logo: any;
}

const projectsModel = new Generator<ProjectStub>(TYPE).createEntityModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

projectsModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('Projects::autocomplete', { search_string: searchTerm })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((project) => ({
          value: project.id,
          label: project.name,
          data: project,
          model: projectsModel
        }))
      ),
  Option: ProjectsOption,
  Value: (props) => <Value {...props} service={TYPE} />
};

export default projectsModel;
