import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '12px',
    lineHeight: '19px',
    fontWeight: 600,
    color: '#b4b1a9'
  }
});

export function Meta({ children }) {
  const s = useStyles(defaultStyles);
  return <Box {...s('container')}>{children}</Box>;
}
