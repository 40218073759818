import React, { Fragment, useCallback, useMemo } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';

import { Form, FormField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import FilterButton from 'src/view/components/button/filter-button';
import { Select } from 'src/view/components/input/select';
import { ChartOfAccountFilters } from 'features/finance/types/chart-of-accounts';
import { upperFirst } from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    width: '400px'
  },
  form: {
    '& input': {
      '&:hover': {
        border: '0px'
      },
      '&:focus': {
        border: '0px'
      }
    }
  },
  icon: {
    display: 'flex',
    gap: 5,
    margin: 0
  }
});

interface FilterPopoutProps {
  onFilter: (filters: ChartOfAccountFilters) => void;
  savedFilter: ChartOfAccountFilters | null;
  onClearFilter: () => void;
}

export function FilterPopout({
  onFilter,
  savedFilter,
  onClearFilter
}: FilterPopoutProps) {
  const s = useStyles(defaultStyles);
  const filterFields = useMemo(() => {
    if (
      !savedFilter ||
      (!savedFilter?.name && !savedFilter.system_record_state)
    ) {
      onClearFilter?.();
      return;
    }

    const filters: { readableField: string; readableValue: string }[] = [];
    const { name, system_record_state } = savedFilter;

    if (name) filters.push({ readableField: 'Name', readableValue: name });
    if (system_record_state)
      filters.push({
        readableField: 'State',
        readableValue: upperFirst(system_record_state)
      });

    return filters;
  }, [onClearFilter, savedFilter]);

  const Content = useCallback(
    ({ close }) => {
      const handleSubmit = (values) => {
        onFilter(values);
        close();
      };
      return (
        <Box {...s('container')}>
          <ReactForms<ChartOfAccountFilters, unknown>
            initialValues={savedFilter || {}}
            handleSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <PaddingBox {...s('form')}>
                  <FormField
                    name='name'
                    label='account name'
                    Input={TextInput}
                  />
                  <Box width='calc(100% - 4px)' mt={15}>
                    <FormField
                      name='system_record_state'
                      label='status'
                      Input={Select}
                      inputProps={{
                        options: [
                          { label: 'Active', value: 'active' },
                          { label: 'Archived', value: 'archived' }
                        ]
                      }}
                    />
                  </Box>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    marginTop={20}
                  >
                    <Fragment>
                      {onClearFilter && savedFilter && (
                        <TextButton blue onClick={onClearFilter}>
                          Clear Filter
                        </TextButton>
                      )}
                    </Fragment>

                    <ButtonBar isLoading={isSubmitting} hasPadding={false}>
                      <TextButton blue onClick={close}>
                        Cancel
                      </TextButton>
                      <DefaultButton blue onClick={submitForm}>
                        Apply Filter
                      </DefaultButton>
                    </ButtonBar>
                  </Box>
                </PaddingBox>
              </Form>
            )}
          </ReactForms>
        </Box>
      );
    },
    [onFilter, s, savedFilter, onClearFilter]
  );

  return (
    <Popout Content={Content} placement='bottom-start'>
      <FilterButton displayCriterias={filterFields} title='Filter Accounts' />
    </Popout>
  );
}
