import { useEffect, useMemo, useState } from 'react';
import { Selection } from 'components/record-list-screen/types';
import { Payment, SyncPaymentResponse } from '../types/payment';
import { LIMIT } from '../data/third-party-service-xero';
import {
  InvoicePagination,
  WingsXero
} from '../types/third-party-service-xero';
import { difference } from 'lodash';
import { ErrorListProps } from '../components/error-list';
import { useModelActions } from '@rexlabs/model-generator';
import uiModel from 'data/models/custom/ui';

export const usePayments = ({
  wingsXero,
  onFailed
}: {
  wingsXero: WingsXero;
  onFailed: () => void;
}) => {
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const [paymentsSelection, setPaymentsSelection] = useState<Selection>({
    type: 'include',
    ids: []
  });

  const [ignoredPayments, setIgnoredPayments] = useState<string[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [paymentIds, setPaymentIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState<Error | null>(null);
  const [syncErrors, setSyncErrors] = useState<ErrorListProps['errors']>([]);
  const [page, setPage] = useState(0);

  const paymentsPagination: InvoicePagination = useMemo(() => {
    return {
      recordIds: paymentIds?.slice?.(page * LIMIT, page * LIMIT + LIMIT) || [],
      pagination: {
        totalItems: paymentIds?.length,
        totalPages: Math.abs((paymentIds?.length || 0) / LIMIT)
      },
      page,
      setPage
    };
  }, [paymentIds, page]);

  const selectedPayments = useMemo(() => {
    return paymentsSelection.type === 'include'
      ? paymentsSelection.ids
      : difference(paymentIds, paymentsSelection.ids);
  }, [paymentIds, paymentsSelection]);

  useEffect(() => {
    const fetchPayments = async () => {
      if (!isLoading) {
        loadingIndicatorOn({ message: 'Loading...' });
      }
      try {
        if (paymentIds.length > 0) {
          const res = (await wingsXero(
            {
              return_ids: false,
              xero_payment_ids: paymentsPagination.recordIds as string[]
            },
            'ThirdPartyServiceXero::getPaymentsToSync'
          )) as Payment[];
          // API doesn't return an id
          setPayments(
            res.map((payment) => ({
              ...payment,
              id: payment.xero_payment_id
            }))
          );
        }
      } catch (error) {
        onFailed();
        setApiError(error as Error);
      }
      if (!isLoading) {
        loadingIndicatorOff();
      }
      setIsLoading(false);
    };
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIds, paymentsPagination.recordIds]);

  const getPayments = async (refresh?: boolean) => {
    if (refresh) {
      setApiError(null);
      setPayments([]);
      setPaymentsSelection({
        type: 'include',
        ids: []
      });
    }
    setIsLoading(true);
    try {
      const res = (await wingsXero(
        {
          return_ids: true,
          xero_payment_ids: []
        },
        'ThirdPartyServiceXero::getPaymentsToSync'
      )) as string[];
      setPaymentIds(res);
    } catch (error) {
      onFailed();
      setApiError(error as Error);
    }
  };

  const syncPayments = async () => {
    setSyncErrors([]);
    try {
      const res = (await wingsXero(
        {
          payments_to_sync: selectedPayments,
          payments_to_ignore: ignoredPayments
        },
        'ThirdPartyServiceXero::syncPaymentsFromXero'
      )) as SyncPaymentResponse;

      getPayments();

      setPaymentsSelection({
        type: 'include',
        ids: []
      });

      setIgnoredPayments([]);

      return {
        ...res,
        failed: []
      };
    } catch (error) {
      setSyncErrors((prev) => [...(prev || []), (error as Error).message]);
    }
    // Synced payments is either all failed or all success only
    return {
      synced: [],
      ignored: [],
      failed: selectedPayments
    };
  };

  return {
    paymentsSelection,
    setPaymentsSelection,
    ignoredPayments,
    setIgnoredPayments,
    payments,
    setPayments,
    isLoading,
    selectedPayments,
    getPayments,
    apiError,
    paymentsPagination,
    syncPayments,
    syncErrors,
    paymentIds
  };
};
