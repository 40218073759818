import { COMPONENTS } from 'data/models/custom/ui';

const REX2_BRIGHTNESS = {
  OFF: 1,
  DIALOG: 0.8,
  FULL: 0.1
};

export function getOverlaySync(ui) {
  const { isOverlayActive, isDialogActive, isClassicDialogActive, disabled } =
    ui;

  let brightness;
  if (isOverlayActive) brightness = REX2_BRIGHTNESS.FULL;
  else if (isDialogActive || isClassicDialogActive) {
    brightness = REX2_BRIGHTNESS.DIALOG;
  } else brightness = REX2_BRIGHTNESS.OFF;

  return {
    brightness,
    isBlocking: isOverlayActive || isDialogActive || isClassicDialogActive,
    isHeaderHidden: disabled.includes(COMPONENTS.HEADER),
    isMenuHidden: disabled.includes(COMPONENTS.MENU)
  };
}

export function getOverlaySyncStyles(overlaySync) {
  return {
    filter: `brightness(${overlaySync.brightness})`,
    pointerEvents: overlaySync.isBlocking ? 'none' : 'initial',
    cursor: overlaySync.isBlocking ? 'auto' : 'initial'
  };
}
