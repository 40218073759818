import React from 'react';
import { isString } from 'lodash';
import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';
import { Link } from 'components/text/link';
import { ListingPreviewPopout } from 'features/listings/popouts/listing-preview-popout';
import { PropertyPreviewPopout } from 'features/properties/popouts/property-preview-popout';
import ProjectPopout from 'view/components/popout/project';
import ProjectStagePopout from 'view/components/popout/project-stage';
import { AppointmentColumn } from 'features/custom-reporting/components/columns/appointment-column';
import { WorksheetIdColumn } from 'features/custom-reporting/components/columns/worksheet-column';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';
import { FeedbackColumn } from 'features/custom-reporting/components/columns/feedback-column';
import { AppraisalColumn } from './columns/appraisal-column';
import { AppointmentAppraisalColumn } from './columns/appointment-appraisal-column';
import { PropertyAppraisalColumn } from './columns/property-appraisal-column';

export const recordColumnTypes = {
  contactRecordColumn: {
    valueFormatter: ({ value, data, colDef }) => {
      const recordData = colDef.field ? value : data;
      return recordData?.contact_name;
    },
    // in order to get the id correctly, we need to be passing a full contact record as the value of this column
    // (or just an object with system_search_key and id)
    // - otherwise it's quite hard to figure out which id in the report data is the contact id.
    // Sometimes we can provide a path to the contact object in the report data - and then we can just use `value`
    // but for the contacts report, the contact *is* the top-level report data and there's no way to provide a path,
    // so we need to just get the id and name from the full `data` object

    // When grouping, the keyCreator is passing through a string as `value`,
    // so we give up showing the popout at that point and just show the string
    cellRenderer: ({ value, data, node, colDef }) => {
      if (isString(value)) {
        return value;
      }

      const recordData = colDef.field ? value : data;

      const name = recordData?.contact_name;
      return name ? (
        <ContactPreviewPopout id={recordData?.id}>
          <Link blue undecorated record>
            {name}
          </Link>
        </ContactPreviewPopout>
      ) : (
        getEmptyValue({ node })
      );
    }
  },
  listingRecordColumn: {
    valueFormatter: ({ value, data, colDef, node, context }) => {
      const recordData = colDef.field ? value : data;
      return (
        recordData?.property_address?.system_search_key ||
        getEmptyValue({ node, context })
      );
    },
    // for why we get both value and data, see contactRecordColumn above
    cellRenderer: ({ value, data, node, colDef }) => {
      // value is a string when grouping
      if (isString(value)) {
        return value;
      }

      const recordData = colDef.field ? value : data;

      const address = recordData?.property_address?.system_search_key;
      return address ? (
        <ListingPreviewPopout id={recordData?.id}>
          <Link blue undecorated record>
            {address}
          </Link>
        </ListingPreviewPopout>
      ) : (
        getEmptyValue({ node })
      );
    }
  },
  propertyRecordColumn: {
    valueFormatter: ({ value, data, colDef }) => {
      const recordData = colDef.field ? value : data;
      return recordData?.system_search_key;
    },
    // for why we get both value and data, see contactRecordColumn above
    cellRenderer: ({ value, data, colDef, node }) => {
      // value is a string when grouping
      if (isString(value)) {
        return value;
      }
      const recordData = colDef.field ? value : data;
      const address = recordData?.system_search_key;
      return address ? (
        <PropertyPreviewPopout id={recordData?.id}>
          <Link blue undecorated record>
            {address}
          </Link>
        </PropertyPreviewPopout>
      ) : (
        getEmptyValue({ node })
      );
    }
  },

  projectRecordColumn: {
    valueFormatter: ({ value, data, colDef }) => {
      const recordData = colDef.field ? value : data;
      return recordData?.name;
    },
    // for why we get both value and data, see contactRecordColumn above
    cellRenderer: ({ value, data, node, colDef }) => {
      // value is a string when grouping
      if (isString(value)) {
        return value;
      }
      const recordData = colDef.field ? value : data;
      const name = recordData?.name;
      return name ? (
        <ProjectPopout id={recordData?.id}>
          <Link blue undecorated record>
            {name}
          </Link>
        </ProjectPopout>
      ) : (
        getEmptyValue({ node })
      );
    }
  },
  projectStageRecordColumn: {
    valueFormatter: ({ value, data, colDef }) => {
      const recordData = colDef.field ? value : data;
      return recordData?.name;
    },
    // for why we get both value and data, see contactRecordColumn above
    cellRenderer: ({ value, data, node, colDef }) => {
      // value is a string when grouping
      if (isString(value)) {
        return value;
      }
      const recordData = colDef.field ? value : data;
      const name = recordData?.name;
      const projectId = recordData?.project?.id;
      return name ? (
        <ProjectStagePopout id={recordData?.id} projectId={projectId}>
          <Link blue undecorated record>
            {name}
          </Link>
        </ProjectStagePopout>
      ) : (
        getEmptyValue({ node })
      );
    }
  },
  appointmentDialogColumn: {
    cellRenderer: AppointmentColumn
  },
  worksheetDialogColumn: {
    cellRenderer: WorksheetIdColumn
  },
  feedbackDialogColumn: {
    cellRenderer: FeedbackColumn
  },
  appraisalDialogColumn: {
    filter: 'agDateColumnFilter',
    cellRenderer: AppraisalColumn
  },
  appointmentAppraisalColumn: {
    cellRenderer: AppointmentAppraisalColumn
  },
  propertyAppraisalColumn: {
    cellRenderer: PropertyAppraisalColumn
  }
};
