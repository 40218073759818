import React, { PureComponent, Children, Fragment } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import Spinner from './spinner';

// TODO - the left prop here on children angers typescript. Add a reusable mechanism for typing this correctly
// CH - https://app.clubhouse.io/rexlabs/story/59921/button-bar-left-prop-typescript
class ButtonBar extends PureComponent {
  static defaultProps = {
    hasPadding: true
  };

  render() {
    const { children, isLoading, hasPadding, ...props } = this.props;
    return (
      <Box
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        height='34px'
        mt={hasPadding ? '30px' : 0}
        {...props}
      >
        {isLoading ? (
          <Fragment>
            <span />
            <Box ml={PADDINGS.XL}>
              <Spinner />
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Box style={{ display: 'inline-flex' }} alignItems='center'>
              {Children.map(children, (child) => {
                return (
                  child &&
                  child.props &&
                  child.props.left && <Box mr={PADDINGS.XXS}>{child}</Box>
                );
              })}
            </Box>
            <Box style={{ display: 'inline-flex' }} alignItems='center'>
              {Children.map(
                children,
                (child) =>
                  child &&
                  child.props &&
                  !child.props.left && <Box ml={PADDINGS.XXS}>{child}</Box>
              )}
            </Box>
          </Fragment>
        )}
      </Box>
    );
  }
}

export default ButtonBar;
