import { Dispatch, SetStateAction, useState } from 'react';
import singleViewConnectionModel, {
  ConnectPayload,
  Connection
} from 'data/models/entities/single-view-connection-model';
import { useModelActions } from '@rexlabs/model-generator';
import { AxiosResponse } from 'axios';
import { useErrorDialog } from 'hooks/use-error-dialog';

export interface UseConnectionProps {
  connections?: Connection[];
  setConnections?: Dispatch<SetStateAction<Connection[]>>;
}

export interface UseConnectionReturn {
  fetch: () => Promise<Connection[]>;
  connect: (args: ConnectPayload) => Promise<Connection>;
  verify: (args: ConnectPayload) => Promise<Connection>;
  disconnect: (args: ConnectPayload) => Promise<void>;
  setAsConsent: (args: ConnectPayload) => Promise<void>;
  isLoading: boolean;
}

export const useConnections = ({
  setConnections
}: UseConnectionProps): UseConnectionReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const connection = useModelActions(singleViewConnectionModel);
  const errorDialog = useErrorDialog();

  const fetch = () => {
    setIsLoading(true);
    return connection
      .fetchConnections({})
      .then((response: { rows: Connection[] }) =>
        setConnections?.(response.rows)
      )
      .then(() => setIsLoading(false))
      .catch((error: Error) => {
        setIsLoading(false);
        errorDialog.open(error);
      });
  };

  const connect = ({ token }: ConnectPayload) => {
    setIsLoading(true);
    return connection
      .connect({ token })
      .then((response: AxiosResponse<{ result: Connection }>) => {
        setIsLoading(false);
        return response.data.result;
      })
      .catch((error: Error) => {
        setIsLoading(false);
        errorDialog.open(error);
      });
  };

  const verify = ({ token }: ConnectPayload) => {
    setIsLoading(true);
    return connection
      .verify({ token })
      .then((response: AxiosResponse<{ result: Connection }>) => {
        setIsLoading(false);
        return response.data.result;
      })
      .catch((error: Error) => {
        setIsLoading(false);
        errorDialog.open(error);
      });
  };

  const disconnect = ({ id }: ConnectPayload) => {
    setIsLoading(true);
    return connection
      .disconnect({ id })
      .then(() => setConnections?.((prev) => prev.filter((c) => c.id !== id)))
      .then(() => setIsLoading(false))
      .catch((error: Error) => {
        setIsLoading(false);
        errorDialog.open(error);
      });
  };

  const setAsConsent = ({ id }: ConnectPayload) => {
    setIsLoading(true);
    return connection
      .setAsConsent({ id })
      .then(() =>
        setConnections?.((prev) =>
          prev.map((c) => ({ ...c, is_consent_account: c.id === id }))
        )
      )
      .then(() => setIsLoading(false))
      .catch((error: Error) => {
        setIsLoading(false);
        errorDialog.open(error);
      });
  };

  return {
    fetch,
    connect,
    verify,
    disconnect,
    setAsConsent,
    isLoading
  };
};
