import types from 'prop-types';
import React, { PureComponent } from 'react';
import { ButtonBar } from 'view/components/button-bar';
import Dialog from 'view/components/dialog/index';
import { DefaultButton } from 'view/components/button';
import { DialogBody as Body } from 'components/text/dialog-body';

class AnnouncementDialog extends PureComponent {
  static propTypes = {
    announcement: types.object.isRequired
  };

  render() {
    const { announcement, onLoad, closeDialog } = this.props;

    return (
      <Dialog
        title={announcement.subject}
        height={400}
        width={700}
        closeDialog={closeDialog}
        onLoad={onLoad}
      >
        <Body>{announcement.body}</Body>
        <ButtonBar>
          <DefaultButton onClick={closeDialog}>Close</DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default AnnouncementDialog;
