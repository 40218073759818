import React, { ReactNode } from 'react';

import Box from '@rexlabs/box';

import { Link } from 'components/text/link';

import { DefaultCell, DefaultCellProps } from './default';

export type BasePopoutCellProps = Omit<DefaultCellProps, 'value'>;

export type DefaultRecordPopout = (props: {
  id: string;
  projectId?: string;
  children: ReactNode;
}) => JSX.Element;

interface PopoutCellProps extends BasePopoutCellProps {
  children: ReactNode;
  recordId?: string;
  projectId?: string;
  RecordPopout: DefaultRecordPopout;
}

export function PopoutCell(props: PopoutCellProps) {
  const { children, recordId, projectId, RecordPopout, ...rest } = props;
  return (
    <DefaultCell {...rest}>
      {recordId ? (
        <Box
          width={'fit-content'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <RecordPopout id={recordId} projectId={projectId}>
            <Link blue undecorated>
              {children}
            </Link>
          </RecordPopout>
        </Box>
      ) : (
        '—'
      )}
    </DefaultCell>
  );
}
