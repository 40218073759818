import { lazy } from 'react';

const AddMarketingLinkDialog = lazy(() => import('./add-marketing-link'));
const AddToChainDialog = lazy(() => import('./add-to-chain'));
const AddOfferDialog = lazy(() => import('./add-offer'));
const HistoryDetailsDialog = lazy(() => import('./history-details'));
const ViewEditOfferDialog = lazy(() => import('./view-edit-offer'));
const OfferMailMergeDialog = lazy(() => import('./offer-mail-merge'));
const OfferStatusDialog = lazy(() => import('./offer-status'));
const OfferFallenDialog = lazy(() => import('./mark-as-fallen'));

export {
  AddMarketingLinkDialog,
  AddToChainDialog,
  AddOfferDialog,
  HistoryDetailsDialog,
  ViewEditOfferDialog,
  OfferMailMergeDialog,
  OfferStatusDialog,
  OfferFallenDialog
};
