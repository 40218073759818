import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  illustration: {
    marginBottom: PADDINGS.L
  }
});

export interface HeadingIllustrationProps {
  error?: { message: string } | null;
  hasPermission?: boolean;
  Icon: React.ElementType;
}

function HeadingIllustration({
  error,
  hasPermission,
  Icon
}: HeadingIllustrationProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box>
      {error || !hasPermission ? (
        <Icon {...s('illustration')} />
      ) : (
        <Icon {...s('illustration')} />
      )}
    </Box>
  );
}

export default HeadingIllustration;
