import _ from 'lodash';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';

@autobind
class ImageWithError extends PureComponent {
  static propTypes = {
    src: types.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  handleError() {
    this.setState({ hasError: true });
  }

  render() {
    const { ErrorProp, ...props } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return _.isArray(ErrorProp) ? (
        <span>{ErrorProp.filter(Boolean)}</span>
      ) : (
        ErrorProp
      );
    } else {
      return <img {...props} onError={this.handleError} />;
    }
  }
}

export default ImageWithError;
