import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import Analytics from 'shared/utils/vivid-analytics';

@autobind
class ErrorBoundary extends PureComponent {
  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    console.error('Error caught');
    console.error(error);
    console.error(errorInfo);
    this.setState({ error });

    // Empty objects have on occasion been passed through as errors.
    // (this is not replicated, but is visible in fullstory)
    const errorOrFallback = _.isError(error)
      ? error
      : new Error(
          'Unnamed error caught by error boundary. Check reactTrace metaData'
        );

    Analytics.error({
      error: errorOrFallback,
      properties: {
        metaData: {
          reactTrace: errorInfo,
          caughtBy: 'components/error-boundary'
        }
      }
    });
  }

  clearError() {
    this.setState({ error: null });
  }

  render() {
    const { children, Placeholder } = this.props;
    return this.state.error ? (
      <Placeholder error={this.state.error} clearError={this.clearError} />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
