import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PADDINGS } from 'theme';

import PaddingBox from 'src/view/components/padding-box';

import { SavedListEmptyState } from './empty-state';
import { SavedListGroup } from './group';
import { SavedListHeaderContainer } from './header-container';
import { SavedListHeading } from './heading';
import { SavedListLoadingSate } from './loading-state';
import { SavedListSearchBar } from './search-bar';
import { SavedListAddItemButton } from './add-item-button';
import { SavedListGroupHeadingContainer } from './group-heading-container';
import { SavedListGroupList } from './group-list';
import { SavedListHeadingContainer } from './heading-container';
import { SavedListGroupEmptyState } from './group-empty-state';

type SavedListProps = React.ComponentProps<typeof PaddingBox>;

const defaultStyles = StyleSheet({
  container: {
    // TODO: We should add this to Box when the updated version of Box is available
    // https://rexsoftware.atlassian.net/browse/RADI-5717
    rowGap: PADDINGS.L
  }
});

export function SavedList(props: SavedListProps) {
  const { children, ...rest } = props;

  const s = useStyles(defaultStyles);

  return (
    <PaddingBox white flexDirection='column' {...s('container')} {...rest}>
      {children}
    </PaddingBox>
  );
}

SavedList.HeaderContainer = SavedListHeaderContainer;
SavedList.HeadingContainer = SavedListHeadingContainer;
SavedList.Heading = SavedListHeading;
SavedList.AddItemButton = SavedListAddItemButton;
SavedList.SearchBar = SavedListSearchBar;
SavedList.LoadingState = SavedListLoadingSate;
SavedList.EmptyState = SavedListEmptyState;
SavedList.Group = SavedListGroup;
SavedList.GroupHeadingContainer = SavedListGroupHeadingContainer;
SavedList.GroupHeading = SavedListHeading;
SavedList.GroupList = SavedListGroupList;
SavedList.GroupEmptyState = SavedListGroupEmptyState;
