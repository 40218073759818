import { set } from 'lodash';
import { parseQueryString, buildQueryString } from '@rexlabs/whereabouts';

import { api } from 'shared/utils/api-client';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

export function getUrlWithGivenViewpath(pathsearchhash) {
  const { pathname, search = '', hash = '' } = window.parent.location;
  const hashQuery = {
    ...parseQueryString(hash.replace(/^#/, '')),
    viewpath: pathsearchhash
  };
  const newHash = `#${buildQueryString(hashQuery)}`;
  return `${pathname}${search}${newHash}`;
}

const VIEWPATH_PATHS = [/^\/stepthrough/];
export function isViewpathActiveUrl() {
  return VIEWPATH_PATHS.some((r) => r.test(window.parent.location.pathname));
}

/*
|-------------------------------------------------------------------------------
| Handling for deep linking dialogs via hash parameter
*/

/**
 * Opens dialog via bridge from URL hash
 * Hash needs to have the following structure:
 *   url#dialogs=[["dialogName","param1:value1;param2:value2"]]
 *
 * e.g. http://localhost:3000/#dialogs=[["editAppointment","data:{}"]]
 *
 * - Note the param's values are JSON.parsed in a try-catch to attempt to create non string params first,
 *   otherwise default to strings
 *
 * @param  {Object} whereabouts  - e.g. from `withWhereabouts`
 * @param  {Object} Dialogs - dialog map (classic and shell), not imported here
 *                            as it creates circular imports :/
 */
export function openDialogsFromHash(whereabouts, Dialogs) {
  if (!whereabouts.hashQuery || !whereabouts.hashQuery.dialogs) {
    return;
  }

  // NOTE: we need to override most of the classic dialogs to make them functional,
  // since they usually expect a callback passed into the dialog :/
  // Shell dialogs should mostly work out of the box!
  Dialogs.editNote.open = (args) => {
    Dialogs.editNote.openClassic({
      ...args,
      callback: (data, deferred) =>
        api
          .post('Notes::create', { data })
          .then(deferred.resolve)
          .catch(deferred.reject)
    });
  };

  const openAppointmentDialog = Dialogs.editAppointment.open;
  Dialogs.editAppointment.open = (args) => {
    openAppointmentDialog({
      ...args,
      handleSave: (data, { apiData }) => {
        Analytics.track({
          event: EVENTS.CALENDAR.CREATE_APPOINTMENT,
          options: { integrations: { Intercom: true } }
        });

        if (data.followup_reminder) {
          Analytics.track({
            event: EVENTS.CALENDAR.CREATE_FOLLOWUP_REMINDER
          });
        }
        api.post('CalendarEvents::create', { data: apiData });
      }
    });
  };

  const openProcessLeadDialog = Dialogs.processLead.open;

  Dialogs.processLead.open = (args) => {
    openProcessLeadDialog({
      ...args
    });
  };

  try {
    const dialogs = JSON.parse(whereabouts.hashQuery.dialogs);
    dialogs.forEach((dialog) => {
      const [name, params] = dialog;
      const parsedParams = params.split(';').reduce((all, param) => {
        const [key, value] = param.split(':');
        try {
          set(all, key, JSON.parse(value));
        } catch {
          set(all, key, value === 'false' ? false : value);
        }
        return all;
      }, {});
      if (Dialogs[name]) {
        Dialogs[name].open
          ? Dialogs[name].open(parsedParams)
          : Dialogs[name].openClassic(parsedParams);
      } else {
        const e = new Error(`Could not find dialog for hash request: ${name}`);
        console.error(e);
        window.bugsnagClient &&
          window.bugsnagClient.notify &&
          window.bugsnagClient.notify(e);
      }
    });
  } catch (e) {
    e.message = `Dialog from hash failed: ${whereabouts.hash}\n\n${e.message}`;
    console.error(e);
    window.bugsnagClient &&
      window.bugsnagClient.notify &&
      window.bugsnagClient.notify(e);
  }
}
