import React from 'react';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import { IconButton } from 'src/view/components/button';
import { ICONS } from 'shared/components/icon';

interface RemoveCellProps extends DefaultCellProps {
  onRemove: (data: any) => void;
  index: number;
}

function RemoveCell({
  width,
  onRemove,
  data,
  index,
  ...rest
}: RemoveCellProps) {
  return (
    <DefaultCell
      {...rest}
      width={width}
      emptyValue={''}
      value={
        index === 0 ? (
          ''
        ) : (
          <IconButton
            lightGrey
            Icon={ICONS.CLOSE_MEDIUM}
            iconStyles={{ width: 21, height: 21 }}
            onClick={() => onRemove(data)}
          />
        )
      }
    />
  );
}

export default RemoveCell;
