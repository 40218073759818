import React from 'react';

import Dialog from 'view/components/dialog';
import Paywall from 'view/components/paywall';

function PaywallDialog({
  dialogTitle = 'Plan Upgrade Required',
  closeDialog,
  ...props
}) {
  return (
    <Dialog
      title={dialogTitle}
      width={600}
      height={500}
      closeDialog={closeDialog}
      hasPadding={false}
    >
      <Paywall {...props} />
    </Dialog>
  );
}

export default PaywallDialog;
