import { documentsBlock } from '../blocks/documents-block';

export const listingBlocks = {
  // Default mode
  default: [
    {
      id: 'documents',
      label: 'Documents',
      blocks: [documentsBlock]
    }
  ],

  // Financial mode
  financials: [
    {
      id: 'vpa',
      label: 'VPA',
      blocks: [{ id: 'vpa' }]
    },
    {
      id: 'invoicesAndPayments',
      label: 'Invoices & Payments',
      blocks: [{ id: 'invoicesAndPayments' }]
    },
    {
      id: 'trustAccounting',
      label: 'Trust Accounting',
      blocks: [{ id: 'trustAccounting' }]
    }
  ]
};
