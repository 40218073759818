export const projectsListActions = [
  {
    id: 'export',
    label: 'Export',
    // eslint-disable-next-line
    onClick: () => alert('Export')
  },
  {
    id: 'related',
    label: 'Go to Related',
    // eslint-disable-next-line
    onClick: () => alert('Go to Related')
  }
];
