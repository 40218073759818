import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  subHeading: {
    fontSize: '17px',
    fontWeight: 600
  }
});

export function SubHeading({ children }) {
  const s = useStyles(defaultStyles);
  return <h2 {...s('subHeading')}>{children}</h2>;
}
