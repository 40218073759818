import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

/*
|-------------------------------------------------------------------------------
| Model
*/

const initialState = {
  features: {
    match_campaigns: { letter: false, email: true, sms: true, phone: false },
    single_merge: { letter: true, email: true, sms: true, phone: false },
    bulk_merge: { letter: true, email: true, sms: true, phone: false },
    newsletters: { letter: false, email: true, sms: false, phone: false },
    phone_calls: { letter: false, email: false, sms: false, phone: true }
  },
  settings: []
};

const actionCreators = {
  refresh: {
    reduce: () => initialState
  },
  fetchCommunicationMethods: {
    request: () => {
      return api
        .post('AdminPrivacySettings::getSupportedCommunicationMethods')
        .then((response) => {
          return _.get(response, 'data.result');
        });
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          features: action.payload
        };
      },
      failure: _.identity
    }
  },
  fetch: {
    request: () => {
      return api.post('AdminPrivacySettings::getSettings').then((response) => {
        return _.get(response, 'data.result');
      });
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          settings: action.payload
        };
      },
      failure: _.identity
    }
  },
  update: {
    request: (payload) => {
      return api
        .post('AdminPrivacySettings::setSettings', { settings: payload })
        .then((response) => {
          return _.get(response, 'data.result');
        });
    },
    reduce: {
      initial: _.identity,
      success: (state, { payload }) => {
        /**
         * This updates only the feature whos settings have changed
         * as we don't get all feature settings in the response.
         */
        const updatedSettings = state.settings.map((setting) => {
          if (_.get(setting, 'feature.id') === _.get(payload, 'feature.id')) {
            return payload;
          }

          return setting;
        });

        return {
          ...state,
          settings: updatedSettings
        };
      },
      failure: _.identity
    }
  }
};

const selectors = {
  features: (state) => state?.privacySettings?.features,
  settings: (state) => state?.privacySettings?.settings
};

export default new Generator('privacySettings').createModel({
  initialState,
  actionCreators,
  selectors
});
