import React from 'react';

import { ContactStub } from 'data/models/entities/contacts';

import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';

import { BasePopoutCellProps, PopoutCell } from './popout-cell';
import { DefaultCell } from './default';

interface ContactCellProps extends BasePopoutCellProps {
  value?: Partial<ContactStub> | null;
}

export function ContactCell(props: ContactCellProps) {
  const { value, ...rest } = props;
  const contactId = value?.id;

  if (!contactId && value?.name) {
    return <DefaultCell {...rest} value={value.name} />;
  }

  return (
    <PopoutCell
      RecordPopout={ContactPreviewPopout}
      recordId={contactId}
      data-testid='RecordList.ContactCell'
      {...rest}
    >
      {value?.name}
    </PopoutCell>
  );
}
