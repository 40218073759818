import { Generator } from 'shared/utils/models';

import { SelectItem } from 'types/select';

export interface SoldStatusModel {
  errors: null;
  fetch: Function;
  items: SelectItem[];
  realStatus: string;
  status: string;
}

const TYPE = 'dashboardsSoldStatus';
export default new Generator(TYPE).createSystemListModel();
