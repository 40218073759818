import React, { useCallback } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, StylesProvider, useStyles } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { TextInput } from '@rexlabs/text-input';

import { Form, FormField, HiddenField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import { criteriaToFilters, filtersToCriteria } from '../utils/filters';
import { useClassicDialog } from 'hooks/use-classic-dialog';

const defaultStyles = StyleSheet({
  container: {
    width: '700px'
  },

  containerSmall: {
    width: '350px'
  },

  group: {
    '&:nth-child(even)': {
      background: '#f4f4f1'
    },

    '&:nth-child(odd):last-child': {
      paddingBottom: '0 !important'
    }
  }
});

const overrides = {
  InnerField: StyleSheet({
    fieldLabel: {
      textTransform: 'lowercase'
    }
  })
};

export function FilterPopout({
  serviceName,
  filtersConfig,
  displayCriteria,
  setCriteria,
  children
}) {
  const s = useStyles(defaultStyles);
  const advancedCriteria = useClassicDialog('advancedCriteria');

  const Content = useCallback(
    ({ close }) => {
      const initialValues = criteriaToFilters(displayCriteria);
      return (
        <Box
          {...s('container', {
            containerSmall: filtersConfig?.width === 'small'
          })}
        >
          <ReactForms
            initialValues={{ filtersConfig, ...initialValues }}
            handleSubmit={(values) => {
              setCriteria(filtersToCriteria(values, filtersConfig));
              close();
            }}
          >
            {({ submitForm, isSubmitting, values }) => (
              <StylesProvider
                components={overrides}
                prioritiseParentStyles={false}
              >
                <Form>
                  <HiddenField name='filters' />
                  <Box flexDirection='column'>
                    {filtersConfig?.groups?.map?.((group, index) => (
                      <PaddingBox key={index} {...s('group')}>
                        <Grid columns={filtersConfig?.columns || 2} sy='10px'>
                          {group.map((filter) => (
                            <Column
                              key={filter.id || filter.name}
                              width={Math.min(
                                filtersConfig?.columns || 2,
                                filter.width || 1
                              )}
                            >
                              <FormField
                                name={filter.id || filter.name}
                                label={filter.label || filter.name}
                                Input={filter.Input || TextInput}
                                inputProps={{
                                  ...(filter.inputProps || {}),
                                  placeholder: filter.placeholder
                                }}
                              />
                            </Column>
                          ))}
                        </Grid>
                      </PaddingBox>
                    ))}
                  </Box>
                  <PaddingBox>
                    <ButtonBar hasPadding={false} isLoading={isSubmitting}>
                      <DefaultButton
                        light
                        left
                        onClick={() => {
                          advancedCriteria.open({
                            serviceName,
                            criteria: filtersToCriteria(values, filtersConfig),
                            callback: setCriteria
                          });
                          close();
                        }}
                      >
                        Advanced
                      </DefaultButton>
                      <TextButton blue onClick={close}>
                        Cancel
                      </TextButton>
                      <DefaultButton blue onClick={submitForm}>
                        Apply Filter
                      </DefaultButton>
                    </ButtonBar>
                  </PaddingBox>
                </Form>
              </StylesProvider>
            )}
          </ReactForms>
        </Box>
      );
    },
    [
      advancedCriteria,
      displayCriteria,
      filtersConfig,
      s,
      serviceName,
      setCriteria
    ]
  );

  return (
    <Popout Content={Content} placement='bottom-start'>
      {children}
    </Popout>
  );
}
