import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import RexList, { RexHeadingCell, RexBasicCell } from 'view/components/list';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'theme';
import { Hint } from 'components/text/hint';
import moment from 'moment';

import _ from 'lodash';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';

const styles = StyleSheet({
  separator: {
    display: 'none'
  },
  emptyState: {
    height: '88px',
    background: COLORS.BACKGROUNDS.SAND_LIGHT
  },
  wrapItem: {
    '&:nth-child(even)': {
      backgroundColor: COLORS.PRIMARY.WHITE
    },
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

const UploaderTableRow = ({ item, index, actions }) => {
  const actionMenuItems = [
    {
      label: 'View',
      onClick: () => {
        actions.openFile(_.get(item, 'url'));
      }
    },
    {
      label: 'Edit',
      onClick: () => {
        const currentName = item.description;

        DialogsBridge.editValue.openClassic({
          dialogTitle: 'Edit File Name',
          fields: [
            {
              label: 'file name',
              kind: 'text',
              value: currentName
            }
          ],
          callback: ([fileNameField]) => {
            const { value } = fileNameField;
            actions.updateFileName(index, value);
          },
          options: {
            validator: function ([fileNameField]) {
              if (fileNameField.value()) {
                return [];
              } else {
                return ['a file name must be specified'];
              }
            }
          }
        });
      }
    },
    {
      label: 'Delete',
      onClick: () => {
        actions.deleteFile(index);
      }
    }
  ];

  return (
    <Box flex={1}>
      <RexBasicCell ellipsis actionMenuItems={actionMenuItems} width={'75%'}>
        {item.name || item.description}{' '}
      </RexBasicCell>
      <RexBasicCell width={'25%'}>
        {moment(item.lastModifiedDate).format('DD MMM YYYY')}
      </RexBasicCell>
    </Box>
  );
};

const UploaderTableHeader = () => {
  return (
    <Box flex={1}>
      <RexHeadingCell width={'75%'} key={'doc_name'}>
        document name
      </RexHeadingCell>

      <RexHeadingCell width={'25%'} key={'date_uploaded'}>
        date uploaded
      </RexHeadingCell>
    </Box>
  );
};

@styled(styles)
@autobind
class UploaderTable extends PureComponent {
  renderEmpty() {
    const { styles: s } = this.props;
    return (
      <Box
        alignItems={'center'}
        justifyContent={'center'}
        flex
        {...s('emptyState')}
      >
        <Hint>No documents added</Hint>
      </Box>
    );
  }

  render() {
    return (
      <RexList
        Header={UploaderTableHeader}
        renderItem={(item, i) => (
          <UploaderTableRow
            index={i}
            actions={this.props.actions}
            item={item}
          />
        )}
        {...this.props}
        EmptyView={this.renderEmpty}
        styles={styles}
        isLoading={false}
        alwaysRenderHeader
      />
    );
  }
}

export default UploaderTable;
