import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { DragHandle, SortableItem } from './sortable-item';

interface SortableListProps<T extends { id: UniqueIdentifier }> {
  items: T[];
  onDragEnd: (oldIndex: number, newIndex: number) => void;
  renderItem: (item: T, index: number) => JSX.Element;
}

function SortableList<T extends { id: UniqueIdentifier }>({
  items,
  onDragEnd,
  renderItem
}: SortableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function onDragEndHelper(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);

      return onDragEnd(oldIndex, newIndex);
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEndHelper}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ul>{items.map(renderItem)}</ul>
      </SortableContext>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;

export default SortableList;
