import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _, { memoize } from 'lodash';

import { useEntityQuery, useModelActions } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import leadsModel from 'data/models/entities/leads';

import Analytics from 'shared/utils/vivid-analytics';
import Spinner from 'shared/components/spinner';
import { EVENTS } from 'shared/utils/analytics';
import { COLORS } from 'shared/theme';

import Dialog from 'view/components/dialog';

import { useErrorDialog } from 'hooks/use-error-dialog';
import { usePermissions } from 'hooks/use-permissions';
import { useDialog } from 'hooks/use-dialog';

import { useRecordNavigation } from 'components/_experiment/record-details-screen/utils/use-record-navigation';

import { useLeadsDialogActions } from 'features/leads/hooks/use-leads-dialog-actions';
import { getLeadQuery } from 'features/leads/data/lead-query';
import { useLeadDialog } from 'features/leads/hooks/use-lead-dialog';
import { getRequiredRecordStatus } from 'features/leads/utils/get-required-record-status';
import { LeadsDialogLeftHandPane } from 'features/leads/components/leads-dialog-left-hand-pane';
import { LeadsDialogActions } from 'features/leads/components/leads-dialog-action-button';
import { LeadsDialogMessageHeader } from 'features/leads/components/leads-dialog-message-header';
import { LeadsDialogMessageContent } from 'features/leads/components/leads-dialog-message-content';
import { LeadsDialogDetailsContainer } from 'features/leads/components/leads-dialog-details-container';
import { Body } from 'components/text/body';
import Icon, { ICONS } from 'shared/components/icon';
import { getActivityStatus } from 'features/leads/utils/get-activity-status';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

export interface ProcessLeadDialogProps {
  closeDialog?: () => void;
  id: string;
}

const defaultStyles = StyleSheet({
  container: {
    maxHeight: '900px',
    minHeight: '580px'
  },
  content: {
    overflow: 'hidden'
  },
  loadingSpinnerContainer: {
    maxHeight: '900px',
    backgroundColor: COLORS.SAND_LIGHT
  }
});

export const leadsQuery = memoize(
  (id: string) => (id && getLeadQuery(id)) || undefined
);

export function ProcessLead({ id, closeDialog }: ProcessLeadDialogProps) {
  const s = useStyles(defaultStyles);
  const errorDialog = useErrorDialog();
  const { check } = usePermissions();
  const noticeDialog = useDialog('notice');

  // Note: Now that we're using the react list, the records update the list automatically,
  // but we still only want to refresh the whole list if we archive or complete a lead.
  // This just makes sure we keep the refreshing of the list to on time, if we have been going through
  // the pagination.
  const [isLeadUpdated, setIsLeadUpdated] = useState(false);
  const leads = useEntityQuery(leadsQuery(id));
  const { refreshLists } = useModelActions(leadsModel);

  // Fake state for completion of steps,
  const [stepsCompletion, setStepsCompletion] = useState({
    processed: false,
    autoFeedback: false
  });
  // isLoading is not accurate so we use this to know if lead is processing
  const [isProcessing, setIsProcessing] = useState(false);

  const { goToNext, goToList, allIds } = useRecordNavigation();

  const { markAsComplete, markAsIncomplete, archiveLead } =
    useLeadsDialogActions(id);

  const lead = leads?.data;
  const receivedFromEmail = lead?.received_from_email;
  const subject = lead?.subject;
  const leadType = lead?.lead_type?.id;

  const isFromList = !_.isEmpty(allIds);

  const feedbackStatus = getActivityStatus(
    'feedback_added',
    lead?.last_activity ? [lead.last_activity] : []
  );

  // HACK: When we open this dialog from a permalink, we sometimes don't get all
  // of the data back when the model status has changed from 'loading'. We only have
  // the id... So we're doing a check here for some data I know will always be true,
  // if the lead has loaded. Need to investigate further as to why this is happening.
  // https://app.shortcut.com/rexlabs/story/67426/react-lists-when-open-dialogs-from-permalinks-model-sometimes-doesn-t-return-all-record-data
  const isLoading = !lead?.system_ctime || leads?.status === 'loading';

  // NOTE: Changing the types of lead.status here as we don't have the status of updating
  // This status does exist, just needs to be added to model generator.
  // https://github.com/rexlabsio/vivid-private/issues/466
  const isUpdating = ['updating', 'refreshing'].includes(leads.status as any);

  const isEmailLead = lead?.is_email_lead;
  const isComplete = lead?.lead_status?.text.toLowerCase() === 'completed';
  const { hasRequiredRelatedRecords } = getRequiredRecordStatus(lead);
  const hasArchiveLeadPermission = check('leads.archive');

  const handleCloseDialog = useCallback(
    (refresh?: boolean) => {
      if (isLeadUpdated || refresh) {
        refreshLists();
      }

      if (closeDialog) {
        return closeDialog();
      }

      return goToList();
    },
    [closeDialog, goToList, isLeadUpdated, refreshLists]
  );

  const editLead = useLeadDialog({
    id
  });

  const goToNextLead = useCallback(() => {
    const activePosition = allIds.indexOf(id);

    if (activePosition === allIds.length - 1) {
      return noticeDialog?.open({
        title: 'End of Leads',
        message: "You've reached the end of the leads in this list",
        closeButtonText: 'Okay',
        callback: handleCloseDialog
      });
    }

    goToNext();
  }, [allIds, id, goToNext, noticeDialog, handleCloseDialog]);

  const markCompleteAndGoToNext = useCallback(() => {
    return markAsComplete()
      .then(() => setIsLeadUpdated(true))
      .then(goToNextLead)
      .catch(errorDialog?.open);
  }, [markAsComplete, goToNextLead, errorDialog]);

  const markCompleteAndClose = useCallback(() => {
    return markAsComplete()
      .then(() => handleCloseDialog(true))
      .catch(errorDialog?.open);
  }, [markAsComplete, handleCloseDialog, errorDialog]);

  const archiveAndGoToNext = useCallback(() => {
    archiveLead(id)
      .then(() => setIsLeadUpdated(true))
      .then(goToNextLead)
      .catch(errorDialog?.open);
  }, [archiveLead, id, goToNextLead, errorDialog]);

  const archiveAndClose = useCallback(() => {
    archiveLead(id)
      .then(() => handleCloseDialog(true))
      .catch(errorDialog?.open);
  }, [archiveLead, id, handleCloseDialog, errorDialog]);

  // TODO: We may need to move these logic to the <LeadsDialogLeftHandPane />
  // https://app.shortcut.com/rexlabs/story/65009/move-logic-markascomplete-archive-gotonext-etc-to-leadsdialoglefthandpane
  const markAsCompleteOrIncomplete = useCallback(() => {
    const onClickFunction = isComplete
      ? markAsIncomplete
      : isFromList
      ? markCompleteAndGoToNext
      : markCompleteAndClose;

    setTimeout(() => {
      setStepsCompletion((prev) => ({ ...prev, processed: true }));
    }, 700);
    setTimeout(() => {
      setStepsCompletion((prev) => ({ ...prev, autoFeedback: true }));
    }, 1200);
    return (async () => {
      setIsProcessing(true);
      await onClickFunction();
      setIsProcessing(false);
    })();
  }, [
    isComplete,
    isFromList,
    markAsIncomplete,
    markCompleteAndClose,
    markCompleteAndGoToNext
  ]);

  const leadsDialogActions: LeadsDialogActions[] = useMemo(() => {
    if (!isFromList) {
      return hasArchiveLeadPermission
        ? [{ label: 'Archive and close', onClick: archiveAndClose }]
        : [];
    }

    return [
      { label: 'Leave incomplete and go to next', onClick: goToNextLead },
      ...(hasArchiveLeadPermission
        ? [
            {
              label: 'Archive and go to next',
              onClick: archiveAndGoToNext
            }
          ]
        : []),

      ...(hasRequiredRelatedRecords
        ? [
            {
              label: 'Complete and close',
              onClick: markCompleteAndClose
            }
          ]
        : [])
    ];
  }, [
    isFromList,
    hasArchiveLeadPermission,
    archiveAndClose,
    goToNextLead,
    archiveAndGoToNext,
    hasRequiredRelatedRecords,
    markCompleteAndClose
  ]);

  const buttonLabel = isComplete
    ? 'Mark as incomplete'
    : isFromList
    ? 'Complete and go to next'
    : 'Complete and close';

  useEffect(() => {
    Analytics.track({
      event: EVENTS.LEADS_DIALOG.OPEN,
      properties: {
        lead_type: leadType
      },
      options: { integrations: { Intercom: true } }
    });
  }, [leadType]);

  return (
    <Dialog
      title='Process Lead'
      closeDialog={() => handleCloseDialog()}
      isLoading={isLoading}
      width={900}
      hasPadding={false}
      showLoadingSpinner
      data-testid='Leads.ProcessLead'
    >
      {!isLoading && lead && !isProcessing ? (
        // NOTE: The height prop in the Dialog component isn't actually used. It only
        // affects the placement in the DOM. So I'm forcing the height of the component below
        // https://app.shortcut.com/rexlabs/story/63824/update-dialog-component-to-actually-use-the-height-prop
        <Box {...s('container')} height={'85vh'} flexDirection={'row'}>
          <LeadsDialogLeftHandPane
            data={lead}
            status={leads.status}
            closeDialog={handleCloseDialog}
            isLoading={isUpdating}
            buttonLabel={buttonLabel}
            leadsDialogActions={leadsDialogActions}
            primaryButtonAction={markAsCompleteOrIncomplete}
          />
          <Box {...s('content')} flexDirection='column' flex={1}>
            <LeadsDialogDetailsContainer lead={lead} editLead={editLead} />
            {isEmailLead && (
              <>
                <LeadsDialogMessageHeader
                  from={receivedFromEmail}
                  subject={subject}
                />
              </>
            )}
            <LeadsDialogMessageContent lead={lead} isEmailLead={isEmailLead} />
          </Box>
        </Box>
      ) : (
        <Box
          {...s('loadingSpinnerContainer')}
          height={'85vh'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection='column'
          spacing={20}
          style={{ margin: 0 }}
        >
          <Body dark semibold large>
            Lead completion in progress...
          </Body>
          <Spinner small dark />
          {!feedbackStatus &&
            lead?.lead_type?.id === 'listing_enquiry' &&
            hasFeatureFlags('generate_auto_feedback') && (
              <Box w={214}>
                <Box alignItems='center' spacing={10} mb={10}>
                  {stepsCompletion.processed ? (
                    <Icon
                      type={ICONS.ROUND_CHECK}
                      style={{ display: 'flex' }}
                    />
                  ) : (
                    <Icon
                      type={ICONS.STEP_CHECK}
                      hasControlledColor={false}
                      style={{ display: 'flex' }}
                    />
                  )}
                  <Body
                    semibold
                    normal
                    style={{
                      color: stepsCompletion.processed
                        ? COLORS.DASHBOARDS.DARK
                        : COLORS.GREY_70
                    }}
                  >
                    Processing lead
                  </Body>
                </Box>
                <Box alignItems='center' spacing={10} mb={10}>
                  {stepsCompletion.autoFeedback ? (
                    <Icon
                      type={ICONS.ROUND_CHECK}
                      style={{ display: 'flex' }}
                    />
                  ) : (
                    <Icon
                      type={ICONS.STEP_CHECK}
                      hasControlledColor={false}
                      style={{ display: 'flex' }}
                    />
                  )}
                  <Body
                    semibold
                    normal
                    style={{
                      color: stepsCompletion.autoFeedback
                        ? COLORS.DASHBOARDS.DARK
                        : COLORS.GREY_70
                    }}
                  >
                    Generating auto-feedback
                  </Body>
                </Box>
                <Box alignItems='center' spacing={10}>
                  {stepsCompletion.autoFeedback ? (
                    <Icon
                      type={ICONS.ROUND_CHECK}
                      style={{ display: 'flex' }}
                    />
                  ) : (
                    <Icon
                      type={ICONS.STEP_CHECK}
                      hasControlledColor={false}
                      style={{ display: 'flex' }}
                    />
                  )}
                  <Body
                    semibold
                    normal
                    style={{
                      color: stepsCompletion.autoFeedback
                        ? COLORS.GREY_70
                        : COLORS.DASHBOARDS.DARK
                    }}
                  >
                    Adding to vendor report
                  </Body>
                </Box>
              </Box>
            )}
        </Box>
      )}
    </Dialog>
  );
}

export default ProcessLead;
