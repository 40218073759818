import { border, text, margin, padding } from '@rexlabs/styling';
import { TOKENS as LUNA_TOKENS } from '@rexlabs/theme-luna';

import { COLORS, TEXTS, PADDINGS } from 'src/theme';

import { merge } from 'lodash';

export const TOKENS = merge({}, LUNA_TOKENS, {
  color: {
    input: {
      selected: COLORS.PRIMARY.WHITE
    },

    border: {
      input: {
        idle: COLORS.STATES.IDLE,
        hover: COLORS.STATES.HOVER,
        active: COLORS.STATES.ACTIVE,
        selected: COLORS.STATES.HOVER,
        error: COLORS.STATES.RED
      }
    }
  },

  popout: {
    zIndex: 999
  },

  field: {
    ...margin.tokens({
      top: () => PADDINGS.S,
      bottom: 'none'
    }),

    maxWidth: 'auto',

    last: {
      ...margin.tokens({
        all: () => ''
      })
    },

    label: {
      color: COLORS.COAL_GREY
    }
  },

  checkbox: {
    ...border.tokens({
      all: {
        width: 'medium'
      }
    }),

    height: '17px',
    width: '17px',

    container: {
      ...padding.tokens({
        y: () => '0.7rem'
      })
    },

    checked: {
      ...border.tokens({
        all: {
          color: () => COLORS.STATES.IDLE
        }
      }),

      tick: {
        ...border.tokens({
          all: {
            color: () => COLORS.PRIMARY.BLACK
          }
        })
      }
    },

    label: {
      ...text.tokens({
        color: TEXTS.CONTENT.FORM_FIELD_LABEL_1.color,
        font: {
          family: TEXTS.CONTENT.FORM_FIELD_LABEL_1.fontFamily,
          size: TEXTS.CONTENT.FORM_FIELD_LABEL_1.fontSize,
          weight: `${TEXTS.CONTENT.FORM_FIELD_LABEL_1.fontWeight}`
        },
        lineHeight: TEXTS.CONTENT.FORM_FIELD_LABEL_1.lineHeight
      }),
      ...margin.tokens({
        left: () => '7px'
      })
    }
  }
});
