import React, { useState } from 'react';
import { Table, TableProps } from './table';

export function RelatedTable({
  Heading,
  items,
  limit = 10,
  columns,
  isLoading,
  emptyMessage,
  colorScheme = 'light',
  showFooterPagination = false,
  ...rest
}: TableProps) {
  const [page, setPage] = useState(0);

  const pageItems = items?.slice?.(page * limit, page * limit + limit) || [];
  const pagination = {
    totalItems: items?.length,
    totalPages: Math.abs((items?.length || 0) / limit)
  };

  return (
    <Table
      Heading={Heading}
      items={pageItems}
      limit={limit}
      pagination={pagination}
      columns={columns}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
      emptyMessage={emptyMessage}
      colorScheme={colorScheme}
      showFooterPagination={showFooterPagination}
      {...rest}
    />
  );
}
