import { parseUrlToRoute, useWhereabouts } from '@rexlabs/whereabouts';

export function useWhereaboutsWithViewpath() {
  const whereabouts = useWhereabouts();

  const location = whereabouts.hashQuery?.viewpath
    ? parseUrlToRoute(whereabouts.hashQuery?.viewpath)
    : whereabouts;

  return location;
}
