import React from 'react';
import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';

export const commissionBySaleFields = ({
  whichWord: ww
}): ReportColumnConfig => {
  return [
    {
      headerName: 'Worksheet ID',
      field: 'id',
      type: 'worksheetDialogColumn',
      colId: 'worksheet_id'
    },
    {
      headerName: `Gross commission (excl ${ww('GST')})`,
      field: 'comm_amount_net_of_tax',
      type: 'currencyColumn'
    },
    {
      headerName: `Gross commission (incl ${ww('GST')})`,
      field: 'comm_amount_inc_tax',
      type: 'currencyColumn'
    },
    {
      headerName: 'Distributable commission',
      field: 'cache_comm_distributable',
      type: 'currencyColumn'
    },
    {
      headerName: 'Net office commission',
      field: 'cache_comm_final_to_office',
      type: 'currencyColumn'
    },
    {
      headerName: 'Amount invoiced',
      field: 'cache_amount_invoiced',
      type: 'currencyColumn'
    },
    {
      headerName: 'Amount paid',
      field: 'cache_amount_paid',
      type: 'currencyColumn'
    }
  ];
};
