import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'embeddedApps';

const actionCreators = {
  archiveItem: {
    request: (payload) =>
      api
        .post('EmbeddedApps::archive', payload)
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const adminEmbeddedApps = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default adminEmbeddedApps;
