import React, { Component } from 'react';
import EntityOption from 'view/components/input/select/options/entity';
import { ICONS } from 'shared/components/icon';
import _ from 'lodash';

class PropertiesOption extends Component {
  pluckStatus(option) {
    return _.toUpper(_.get(option, 'data.category.text', 'N/A') || 'N/A');
  }

  render() {
    return (
      <EntityOption
        {...this.props}
        Icon={ICONS.SELECT.PROPERTIES}
        pluckStatus={this.pluckStatus}
      />
    );
  }
}

export default PropertiesOption;
