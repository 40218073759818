import React, { useCallback } from 'react';
import { ListingStub } from 'data/models/entities/listings';
import { ListingPreviewPopout } from 'features/listings/popouts/listing-preview-popout';
import {
  BasePopoutCellProps,
  PopoutCell
} from '../../../../../components/record-list-screen/cells/popout-cell';
import { ProjectStub } from 'data/models/entities/projects';
import { ProjectStageStub } from 'data/models/entities/project-stages';
import ProjectTooltip from 'src/view/components/popout/project';
import { Link } from 'components/text/link';
import ProjectStageTooltip from 'src/view/components/popout/project-stage';
import { DefaultCell } from 'components/record-list-screen/cells/default';
import { prefixedFormatAddress } from 'src/utils/formatters/addresses';

type Entities = 'listing' | 'project' | 'project_stage';

interface EntityCellProps extends BasePopoutCellProps {
  sequence: Array<Entities>;
  value: {
    listing: ListingStub;
    project: ProjectStub;
    project_stage: ProjectStageStub;
  };
}

export function EntityCell(props: EntityCellProps) {
  const { value, sequence, ...rest } = props;

  const getPopout = useCallback(
    (entity: Entities) => {
      if (entity === 'listing') {
        return (
          <PopoutCell
            RecordPopout={ListingPreviewPopout}
            recordId={value.listing.id}
            data-testid='RecordList.EntityCell'
            {...rest}
          >
            {prefixedFormatAddress(value?.listing.property)}
          </PopoutCell>
        );
      } else if (entity === 'project') {
        return (
          <PopoutCell
            // @ts-expect-error project tooltip is written in js
            RecordPopout={ProjectTooltip}
            recordId={value?.project?.id}
            data-testid='RecordList.EntityCell'
          >
            {value?.project?._name}
          </PopoutCell>
        );
      } else if (entity === 'project_stage') {
        return (
          <PopoutCell
            // @ts-expect-error project stage tooltip is written in js
            RecordPopout={ProjectStageTooltip}
            recordId={value?.project_stage?.id}
            projectId={value?.project_stage?._project?.id}
            data-testid='RecordList.EntityCell'
          >
            {value?.project_stage?._name}
          </PopoutCell>
        );
      }

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  if (sequence[0] && value?.[sequence[0]]) return getPopout(sequence[0]);
  if (sequence[1] && value?.[sequence[1]]) return getPopout(sequence[1]);
  if (sequence[2] && value?.[sequence[2]]) return getPopout(sequence[2]);
  return <DefaultCell {...rest}>-</DefaultCell>;
}
