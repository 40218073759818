import React from 'react';
import { PADDINGS, COLORS } from 'src/theme';
import Box from '@rexlabs/box';
import { Link } from 'components/text/link';
import { Body } from 'components/text/body';
import Icon, { ICONS } from 'shared/components/icon';
import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { ContactStub } from 'src/data/models/entities/contacts';

const defaultStyles = StyleSheet({
  iconContainer: {
    alignSelf: 'center',
    // we're inheriting line height differently in different places.
    // set it here so that it doesn't mess with the positioning of the svg
    lineHeight: '14px'
  },
  icon: {
    height: '12px',
    color: COLORS.PRIMARY.SAND
  }
});

interface ContactDetailsItemProps {
  contact: ContactStub;
  index?: number;
}

export function ContactDetailsItem({
  contact,
  index = 0
}: ContactDetailsItemProps) {
  const s = useStyles(defaultStyles);

  const contactPhone = contact.phone_number;

  return (
    <Box flexDirection='row' flexWrap='wrap' mt={index > 0 ? PADDINGS.XXS : 0}>
      <Box mr={PADDINGS.XS}>
        <ContactPreviewPopout key={contact.id} id={contact.id}>
          <Box>
            {contact.id && (
              <Link blue undecorated record>
                {contact.name}
              </Link>
            )}
          </Box>
        </ContactPreviewPopout>
      </Box>
      {contactPhone && (
        <Box alignItems='center'>
          <Box flexDirection='row' {...s('iconContainer')}>
            <Icon
              data-testid='ContactDetails.Icon'
              {...s('icon')}
              type={ICONS.PHONE_SMALL}
              width={'12px'}
              height={'12px'}
            />
          </Box>
          <Box ml={PADDINGS.XXS}>
            <Body dark small>
              {contactPhone}
            </Body>
          </Box>
        </Box>
      )}
    </Box>
  );
}
