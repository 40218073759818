import React, { useEffect, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { DefaultButton } from 'view/components/button';

const defaultStyles = StyleSheet({
  container: {},

  tabBar: {
    overflow: 'hidden',
    marginBottom: '10px',
    position: 'relative',

    '&:before': {
      content: '" "',
      position: 'absolute',
      width: '100%',
      height: '2px',
      background: '#B4B1A9',
      bottom: 0,
      left: 0,
      zIndex: 0
    },

    '& > button': {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      marginRight: '4px',
      position: 'relative',
      zIndex: 1,

      '&:last-child': {
        marginRight: 0
      },

      '&:active': {
        transform: 'translateY(2px)'
      }
    }
  },

  tab: {},

  tabContent: {},

  tabContentHidden: {
    display: 'none'
  }
});

interface RecordDetailsStreamsProps {
  streams: any;
  data: any;
}

export function RecordDetailsStreams({
  streams,
  data
}: RecordDetailsStreamsProps) {
  const s = useStyles(defaultStyles);

  const [activeTab, setActiveTab] = useState(streams?.[0]);
  const [visitedTabs, setVisitedTabs] = useState([activeTab]);

  useEffect(() => {
    setVisitedTabs((state) =>
      !state.includes(activeTab) ? [...state, activeTab] : state
    );
  }, [activeTab]);

  // Reset state when tabs change
  useEffect(() => {
    setActiveTab(streams?.[0]);
    setVisitedTabs([streams?.[0]]);
  }, [streams]);

  return (
    <Box {...s('container')}>
      <Box {...s('tabBar')}>
        {streams?.map?.((tab) => (
          <DefaultButton
            key={tab?.id}
            dark={activeTab?.id === tab?.id}
            onClick={() => setActiveTab(tab)}
          >
            {tab.label}
          </DefaultButton>
        ))}
      </Box>
      {visitedTabs?.map?.((tab) => (
        <Box
          key={tab?.id}
          {...s('tabContent', {
            tabContentHidden: activeTab?.id !== tab?.id
          })}
        >
          <tab.Component data={data} />
        </Box>
      ))}
    </Box>
  );
}
