import Box from '@rexlabs/box';
import { HorizontalDivider } from 'components/horizontal-divider';
import { Selection } from 'components/record-list-screen/types';
import { Body } from 'components/text/body';
import React, { Dispatch, SetStateAction } from 'react';
import Checkbox from 'src/view/components/input/checkbox';

export interface TotalInvoicesProps {
  selections: {
    selection: Selection;
    setSelection: Dispatch<SetStateAction<Selection>>;
    ignoredSelection: string[];
  }[];
}

const TotalInvoices = ({ selections }: TotalInvoicesProps) => {
  const allIsChecked = selections.every(
    ({ selection }) => selection?.type === 'exclude' && !selection?.ids?.length
  );

  return (
    <>
      <Box>
        <Checkbox
          id='all-invoices'
          label={
            <Body dark semibold>
              Select all
            </Body>
          }
          value={allIsChecked}
          onChange={() => {
            selections.forEach(({ setSelection }) => {
              setSelection({
                type: allIsChecked ? 'include' : 'exclude',
                ids: []
              });
            });
          }}
        />
      </Box>
      <HorizontalDivider />
    </>
  );
};

export default TotalInvoices;
