import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function convertTimestampToDayjs(timestamp: string | null) {
  if (!timestamp) {
    return null;
  }
  return dayjs(parseInt(timestamp) * 1000);
}

export function getDateFromString(string: string) {
  const dateRegex = /^([0-9]{2})[-/]([0-9]{2})[-/]([0-9]{4})$/;
  const matches = string.match(dateRegex);

  if (!matches) {
    return null;
  }

  // We want to support both slash and dash format
  const slashFormat = dayjs(string, {
    format: 'DD/MM/YYYY'
  });

  const dashFormat = dayjs(string, {
    format: 'DD-MM-YYYY'
  });

  return slashFormat.isValid()
    ? slashFormat
    : dashFormat.isValid()
    ? dashFormat
    : null;
}

export function checkDate(dateString: string) {
  const date = getDateFromString(dateString);
  if (!date) {
    return 'The date needs to be in a DD/MM/YYYY or DD-MM-YYYY format.';
  }

  return null;
}

export function checkDateBefore(
  dateString: string,
  dateBefore = dayjs(),
  errorMessage = 'The date must be equal or before today.'
) {
  const date = getDateFromString(dateString);
  if (!date) {
    return 'The date needs to be in a DD/MM/YYYY or DD-MM-YYYY format.';
  }

  if (!date.isBefore(dateBefore)) {
    return errorMessage;
  }

  return null;
}

export function isBeforeToday(dateString: string) {
  const todaysDate = dayjs();
  return dayjs(dateString, {
    format: 'YYYY-MM-DD'
  }).isBefore(todaysDate);
}

export function getFormattedDate({
  date,
  format = 'D MMM YYYY'
}: {
  date: string;
  format?: string;
}) {
  return date && dayjs(date).isValid() ? dayjs(date).format(format) : '';
}
