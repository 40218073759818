import React from 'react';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { Popout } from 'view/components/popout';
import { Form, FormField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import Box from '@rexlabs/box';
import ApplyCancelButtonBar from 'shared/components/button-bar/apply-cancel';
import { ICONS } from 'shared/components/icon';
import { ReportingFilterButton } from 'components/button/reporting-filter-button';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
interface FilterAppraisalTypeDropdownProps {
  onChange: (val: string) => void;
  value: string | null | undefined;
}

function FilterAppraisalTypeDropdown({
  onChange,
  value
}: FilterAppraisalTypeDropdownProps) {
  const ww = useWhichWordFactory();

  const options = [
    { value: 'all', label: 'All Types' },
    { value: 'sale', label: 'Sale' },
    { value: 'rent', label: ww('valuation_type_rent') }
  ];

  function getTextFromValue(value: string | null | undefined) {
    const option = options.find((o) => o.value === (value || 'all'));
    return `${ww('valuation')} Type: ${option?.label}`;
  }

  return (
    <Popout
      placement={PLACEMENTS.BOTTOM_END}
      distance={'3px'}
      Content={({ close }) => (
        <PaddingBox width='240px'>
          <ReactForms
            handleSubmit={async (values) => {
              onChange(values.appraisal_type_id);
              close();
            }}
            initialValues={{
              appraisal_type_id: value || 'all'
            }}
          >
            {({ submitForm, isSubmitting }) => {
              return (
                <Form>
                  <Box flex={1}>
                    <FormField
                      name='appraisal_type_id'
                      label={`${ww('valuation')} types`}
                      Input={RadioGroupInput}
                      inputProps={{
                        options: options
                      }}
                    />
                  </Box>
                  <ApplyCancelButtonBar
                    isLoading={isSubmitting}
                    onCancel={() => close()}
                    onSave={submitForm}
                    isDisabled={false}
                  />
                </Form>
              );
            }}
          </ReactForms>
        </PaddingBox>
      )}
    >
      <div data-intercom-target='Appraisals Pipeline Appraisal Type'>
        <ReportingFilterButton Icon={ICONS.DASHBOARDS.FILTER} showArrowIcon>
          {getTextFromValue(value)}
        </ReportingFilterButton>
      </div>
    </Popout>
  );
}

export { FilterAppraisalTypeDropdown };
