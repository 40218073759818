import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const actionCreators = {
  uploadFile: {
    request: ({ formData, onUploadProgress }) =>
      api.post('Upload::uploadFile', formData, {
        onUploadProgress,
        headers: { 'Content-Type': 'multipart/form' }
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('uploads').createEntityModel({ actionCreators });
