import React from 'react';
import { ICONS } from 'shared/components/icon';
import { PADDINGS } from 'theme';
import { PaddingBoxProps } from 'view/components/padding-box';
import { Heading } from 'components/text/heading';
import { Banner } from 'components/banner';

interface WarningBannerProps extends PaddingBoxProps {
  children?: React.ReactNode;
}

export function WarningBanner({ children, ...props }: WarningBannerProps) {
  return (
    <Banner
      yellow
      Icon={ICONS.ALERT_SMALL}
      padding={PADDINGS.XS}
      alignItems={'center'}
      mt={0}
      mb={PADDINGS.S}
      {...props}
    >
      <Heading level={4}>{children}</Heading>
    </Banner>
  );
}
