import { APIFieldSelect } from 'view/components/input/select/api-field-select';
import { EntitySelect } from 'view/components/input/select';
import adminPipelineStages, {
  PipelineStageData,
  PipelineTypeId
} from 'features/pipelines/data/admin-pipeline-stages';
import React from 'react';
import { Id } from '@rexlabs/model-generator';

interface PipelineStageSelectProps {
  value: null | undefined | PipelineStageData;
  onChange: (value: { id: Id }) => void;
  pipelineTypeId: PipelineTypeId;
}

export function mapPipelineStageToSelectOption(v: PipelineStageData) {
  return {
    label: v.name,
    value: v.id,
    data: v,
    model: adminPipelineStages
  };
}
export function PipelineStageSelect({
  pipelineTypeId,
  ...rest
}: PipelineStageSelectProps) {
  const value = rest.value;
  return (
    <APIFieldSelect
      SelectComponent={EntitySelect}
      toSelectOption={(v) => mapPipelineStageToSelectOption(v)}
      searchOnMount
      toApiValue={(o) => ({ id: o.value, ...o.data })}
      isSearchable={false}
      options={value ? [mapPipelineStageToSelectOption(value)] : []}
      pipelineTypeId={pipelineTypeId}
      models={[adminPipelineStages]}
      sortOptions={(a, b) => a.data?.percentage - b.data?.percentage}
      withTags={false}
      {...rest}
    />
  );
}
