import React, { useMemo } from 'react';
import { TooltipProps } from '@rexlabs/tooltip';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';

import Tooltip from 'view/components/tooltip';
import { Body } from 'components/text/body';
import { COLORS, PADDINGS } from 'src/theme';

export interface IconTooltipProps extends TooltipProps {
  // TODO: https://app.clubhouse.io/rexlabs/story/54145/work-out-how-to-add-typing-for-svg
  TooltipIcon?: any; // SVG
  content: React.ReactNode;
  small?: boolean;
  inline?: boolean; // Use this if you need to tooltip to appear aligned in paragraph text
  color?: string;
}

function IconTooltip(props: IconTooltipProps) {
  const { content, TooltipIcon, small, inline, color, ...rest } = props;

  const tooltipOverrides = useMemo(
    () => ({
      HoverTarget: StyleSheet({
        hoverContainer: {
          width: small ? '12px' : '16px',
          height: small ? '12px' : '16px',
          margin: `${inline ? '2px' : 0} ${PADDINGS.XXS}`,
          position: inline ? 'absolute' : 'relative'
        }
      }),
      Tooltip: StyleSheet({
        hoverable: {
          width: small ? '12px' : '16px',
          height: small ? '12px' : '16px',
          cursor: 'default'
        }
      })
    }),
    [inline, small]
  );

  return (
    <StylesProvider
      components={tooltipOverrides}
      prioritiseParentStyles={false}
    >
      <Tooltip
        inline
        hoverTimeout={1}
        distance={'10px'}
        placement='top'
        Content={() => (
          <Body normal white>
            {content}
          </Body>
        )}
        {...rest}
      >
        <TooltipIcon
          width={small ? '12px' : '16px'}
          height={small ? '12px' : '16px'}
          color={color || COLORS.PRIMARY.INFORMATIVE}
        />
      </Tooltip>
    </StylesProvider>
  );
}

export default IconTooltip;
