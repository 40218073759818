import { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { api } from 'shared/utils/api-client';

import { useDialog } from 'hooks/use-dialog';
import leadsModel from 'data/models/entities/leads';
import {
  useGetViewstateIdForBulkActions,
  useMassActionProgressDialog,
  useViewMassActionResult
} from 'components/record-list-screen/utils/mass-action-utils';
import { usePermissions } from 'hooks/use-permissions';

export function useUpdateAssigneeBatchAction({ service_name }) {
  const editValueDialog = useDialog('editValue');
  const getViewstateId = useGetViewstateIdForBulkActions({ service_name });
  const openProgressDialog = useMassActionProgressDialog();
  const viewMassActionResult = useViewMassActionResult();
  const { check } = usePermissions();
  const canAssignLeadsToAnyone = check('leads.assign_to_anyone');
  const leadActions = useModelActions(leadsModel);

  return useCallback(
    ({ selectionCriteria, afterMassAction }) => {
      getViewstateId({ selectionCriteria }).then(({ viewstate_id }) => {
        editValueDialog.open({
          dialogTitle: 'Assign Lead',
          fields: [
            {
              label: 'assignee',
              value: '',
              kind: 'valueListAutocomplete',
              source: canAssignLeadsToAnyone
                ? 'account_users'
                : 'account_users_in_permissions_group'
            }
          ],
          callback: function (dialogData) {
            const [assignee] = dialogData;

            const apiData = {
              service_name,
              viewstate_id,
              assignee_id: assignee?.value.id,
              record_ids: false
            };

            return api
              .post('MassActions::queueLeadsReassignment', apiData)
              .then((response: any) => {
                editValueDialog.close();
                const token = response?.data?.result;

                // TODO: move this into a useAsyncFileResult hook, make generic
                //  https://app.shortcut.com/rexlabs/story/66998/react-list-batch-actions-create-useasynctoken-and-useasyncfileresult-hooks
                openProgressDialog({ token }).then((result) => {
                  return viewMassActionResult({
                    resultTitle: 'Assign User',
                    refreshListFn: () => {
                      leadActions.refreshLists();
                      // as part of refreshing the list, ensure any checkboxes are deselected
                      afterMassAction?.();
                    },
                    className: 'leads',
                    mappingFn: (item) => item?.data?.body_snippet,
                    response: result
                  });
                });
              });
            // TODO: check error handling
            //  https://app.shortcut.com/rexlabs/story/67000/react-list-bulk-action-error-handling
          },
          options: {
            validator: (dialogData) => {
              // assignee is mandatory
              return dialogData[0]?.value?.().id
                ? []
                : ['assignee must be set'];
            }
          }
        });
      });
    },
    [
      canAssignLeadsToAnyone,
      editValueDialog,
      getViewstateId,
      leadActions,
      openProgressDialog,
      service_name,
      viewMassActionResult
    ]
  );
}
