import { isEqual } from 'lodash';
import { useMemoCompare } from 'hooks/use-memo-compare';

/**
 * If you are having referential equality issues with objects causing your useEffects to trigger more often than they
 * should, this utility can help.
 * Note that this is a sledgehammer. If you're using this it's a sign there's a deeper problem that needs solving.
 * Use lightly and reluctantly.
 */
export function useStabilizedObject(object) {
  return useMemoCompare(object, isEqual);
}
