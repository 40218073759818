export const userFields = [
  {
    field: 'id',
    headerName: 'ID'
  },
  {
    field: 'name',
    headerName: 'Name'
  },
  {
    field: 'email_address',
    headerName: 'Email'
  },
  {
    field: 'first_name',
    headerName: 'First Name'
  },
  {
    field: 'last_name',
    headerName: 'Last Name'
  }
];
