import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';
import { TextButton, DefaultButton } from 'view/components/button';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import SparklesSvg from 'assets/icons/sparkles.svg';
import ExternalLinkSvg from 'assets/icons/external-link.svg';
import paywallPng from 'assets/paywall.png';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    maxWidth: '700px',
    color: COLORS.DARK_GREY
  },

  containerBorder: {
    border: '1px solid #F5F6F8',
    borderRadius: '4px',
    overflow: 'hidden'
  },

  innerLeft: {
    width: '65%',
    flexShrink: 0,
    background: '#F5F6F8',
    textAlign: 'center'
  },

  content: {
    padding: '30px'
  },

  image: {
    width: '100%',

    '& img': {
      width: '100%',
      height: 'auto'
    }
  },

  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '1.1',
    // we want to be able to provide text with \n in it
    // to control where the heading breaks
    whiteSpace: 'pre-wrap'
  },

  body: {
    fontSize: '14px',
    lineHeight: '1.4',
    margin: '20px 0'
  },

  innerRight: {
    padding: '22px',
    textAlign: 'center'
  },

  sparkles: {
    color: '#9AA4B4'
  },

  supportTitle: {
    fontSize: '17px',
    fontWeight: 700,
    lineHeight: '1.3',
    margin: '20px 0 0'
  }
});

// Even if you don't need to display the featureName in your text,
// please pass it in for analytics!
function Paywall({
  title,
  body,
  featureName,
  externalUrl,
  styles: s,
  hasBorder = true
}) {
  return (
    <Box
      flexDirection='row'
      {...s('container', { containerBorder: hasBorder })}
    >
      <Box {...s('innerLeft')} flexDirection='column'>
        <Box {...s('image')}>
          <img src={paywallPng} />
        </Box>
        <Box flex={1} flexDirection='column' {...s('content')}>
          <h2 {...s('title')}>
            {title || 'This feature is not available on your current plan'}
          </h2>
          <Box {...s('body')}>
            {body ||
              `Become a true Rexpert - upgrade to use ${
                featureName || 'this feature'
              }`}
          </Box>
          {externalUrl && (
            <Box {...s('button')}>
              <DefaultButton
                blue
                IconRight={() => (
                  <Box
                    width={10}
                    height={10}
                    // Don't like to do this... but the default button has paddings that look a little wierd with the new icons
                    // This is because the old icons had white space around them, so the paddings complimented the dodgy icons.
                    // https://app.clubhouse.io/rexlabs/story/52342/cq-clean-up-icon-set-s
                    // https://app.clubhouse.io/rexlabs/story/20443/button
                    margin={'0 8px 0 5px'}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <ExternalLinkSvg />
                  </Box>
                )}
                onClick={() => {
                  Analytics.track({
                    event: EVENTS.PAYWALL.LEARN_MORE,
                    properties: { feature: featureName }
                  });
                  window.open(externalUrl, '_blank');
                }}
              >
                Learn more
              </DefaultButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        {...s('innerRight')}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <SparklesSvg {...s('sparkles')} />
        <h3 {...s('supportTitle')}>
          Talk to Rex Support about upgrading your account
        </h3>
        <TextButton
          grey
          onClick={() => {
            Analytics.track({
              event: EVENTS.PAYWALL.EMAIL_SUPPORT,
              properties: { feature: featureName }
            });
            window.open(
              'mailto:support@rexsoftware.com.au?subject=Upgrading my Rex account'
            );
          }}
        >
          Contact Support
        </TextButton>
      </Box>
    </Box>
  );
}

export default styled(defaultStyles)(Paywall);
