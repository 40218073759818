import _ from 'lodash';

export default class MemoizeCache extends Map {
  set(...args) {
    MemoizeCache.caches.add(this);
    return super.set(...args);
  }

  static caches = new Set();
  static clearCaches() {
    let entries = 0;
    MemoizeCache.caches.forEach((cache) => {
      entries += cache.size;
      cache.clear();
    });
    if (__DEV__) {
      // eslint-disable-next-line
      console.log(
        `Clearing ${entries} entry(ies) from ${MemoizeCache.caches.size} memoize cache(s)`
      );
    }
    MemoizeCache.caches.clear();
  }
}
_.memoize.Cache = MemoizeCache;

export function clearMemoizeCaches() {
  MemoizeCache.clearCaches();
}
