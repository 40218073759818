import DefaultOption from 'view/components/input/select/options/default';
import React, { ComponentProps, memo } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    height: '17px'
  },
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    position: 'relative',
    border: '1px solid white',
    top: '1px',
    marginRight: '5px',
    marginLeft: '-2px',
    display: 'inline-block'
  },
  percentage: {
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    right: 0
  }
});

const PipelineStagesOption = memo(function PipelineStagesOption(
  props: ComponentProps<typeof DefaultOption>
) {
  const s = useStyles(defaultStyles);
  return (
    <DefaultOption
      {...props}
      style={{
        flex: 1,
        backgroundColor: props.disabled ? 'transparent' : undefined
      }}
      pluckLabel={(option) => {
        return (
          <div {...s('container')}>
            {option?.data?.color ? (
              <div
                {...s('circle')}
                style={{ backgroundColor: option.data.color }}
              />
            ) : null}
            {option.label}
            {option?.data?.percentage !== undefined ? (
              <div {...s('percentage')}>{option.data.percentage}%</div>
            ) : null}
          </div>
        );
      }}
    />
  );
});

export { PipelineStagesOption };
