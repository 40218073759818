import React, { PureComponent } from 'react';
import { DateSelect, TimeSelect } from 'view/components/input/select';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import SplitValueInput from 'view/components/input/split-value';
import { autobind } from 'core-decorators';
import dayjs from 'dayjs';
import _ from 'lodash';
import utc from 'dayjs-plugin-utc';
import { COLORS } from 'src/theme';

dayjs.extend(utc);
export function dateToValue(date, timeZone) {
  const timeZoneSetting = timeZone ? { timeZone } : undefined;
  const d = dayjs(date);
  const hour = d.format('H', timeZoneSetting);
  const minute = d.format('m', timeZoneSetting);

  return {
    date: d.format('YYYY-MM-DD', timeZoneSetting),
    time: {
      hour,
      minute,
      time: `${hour.padStart(2, '0')}:` + `${minute.padStart(2, '0')}:00`
    }
  };
}

export function valueToDate(value) {
  if (!value.date && !value.time) return '';
  return `${value?.date} ${value.time?.time}`;
}

const dateInputOverrides = {
  TextInput: StyleSheet({
    suffix: {
      display: 'none !important',
      '& > *:first-child': {
        marginLeft: '0'
      }
    },
    prefix: {
      display: 'none'
    },
    container: {
      borderColor: 'transparent !important',
      height: '24px',
      minHeight: '0',
      padding: '0 2.5px 0',
      '&:focus-within': {
        outline: 'none'
      }
    },
    input: {
      border: 'none',
      padding: 0
    }
  }),
  Box: StyleSheet({
    container: {
      height: '23px'
    }
  })
};

const timeInputOverrides = {
  TextInput: StyleSheet({
    container: {
      border: 'none',
      height: 'auto',
      minHeight: 'auto',
      '&:focus-within': {
        outline: 'none'
      }
    },
    input: {
      border: 'none',
      padding: 0
    }
  }),
  Box: StyleSheet({
    container: {
      height: '23px'
    }
  })
};

const getSplitInputOverrides = _.memoize((disabled) => ({
  TextInput: StyleSheet({
    cosmeticWrapper: {
      padding: '0'
    },
    input: {
      border: '2px solid transparent'
    }
  }),
  SplitValueInput: StyleSheet({
    inputContainer: {
      backgroundColor: disabled ? '#E6E5E3' : 'none',
      height: '27px',
      borderRadius: 0,
      border: `2px solid ${COLORS.INPUT.BORDER}`,
      '&:hover': {
        borderColor: COLORS.INPUT.BORDER_ACTIVE
      },
      '&:focus-within': {
        outline: `2px solid ${COLORS.INPUT.OUTLINE}`,
        borderColor: COLORS.INPUT.BORDER_ACTIVE,
        boxShadow: 'none'
      }
    },
    divider: {
      height: '1.6rem'
    }
  })
}));

@autobind
class DateTimeInput extends PureComponent {
  DateInput(props) {
    return (
      <StylesProvider
        components={dateInputOverrides}
        prioritiseParentStyles={false}
      >
        <Box width={'100%'}>
          <DateSelect
            {...this.props}
            {...props}
            placeholder='e.g. 2nd Oct 2018'
          />
        </Box>
      </StylesProvider>
    );
  }

  TimeSelect(props) {
    return (
      <StylesProvider
        components={timeInputOverrides}
        prioritiseParentStyles={false}
      >
        <Box width={'100%'}>
          <TimeSelect {...this.props} {...props} placeholder='12:00 PM' />
        </Box>
      </StylesProvider>
    );
  }

  fakeEvent(e) {
    return {
      persist: _.noop,
      target: {
        value: {
          date: e.target.value.left,
          time: e.target.value.right
        }
      }
    };
  }

  handleChange(e) {
    const { onChange } = this.props;
    return onChange(this.fakeEvent(e));
  }

  handleBlur(e) {
    const { onBlur } = this.props;
    return onBlur(this.fakeEvent(e));
  }

  render() {
    return (
      <StylesProvider
        components={getSplitInputOverrides(this.props.disabled)}
        prioritiseParentStyles={false}
      >
        <SplitValueInput
          {...this.props}
          value={{
            left: this.props.value?.date,
            right: this.props.value?.time
          }}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          LeftInput={this.DateInput}
          RightInput={this.TimeSelect}
        />
      </StylesProvider>
    );
  }
}

export const DateValueSelect = ({ value, onChange, onBlur, ...props }) => (
  <DateSelect
    value={dayjs(`${value.date} 00:00:00`).toDate()}
    onChange={(event) =>
      onChange({
        persist: _.noop,
        target: {
          value: dateToValue(event.target.value)
        }
      })
    }
    onBlur={(event) =>
      onBlur?.({
        persist: _.noop,
        target: {
          value: dateToValue(event.target.value)
        }
      })
    }
    {...props}
  />
);

export default DateTimeInput;
