import _ from 'lodash';
import { useRegion } from 'hooks/use-region';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import {
  CustomField,
  getCustomFieldColumns
} from 'features/custom-reporting/utils/custom-field-utils';

export const contactFields = ({
  region,
  customFields,
  subresource
}: {
  region?: ReturnType<typeof useRegion>;
  customFields?: Record<string, CustomField>;
  subresource?: string;
}) => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'contact'
  });

  return [
    {
      headerName: 'Contact ID',
      field: 'id'
    },
    {
      headerName: 'Name',
      colId: 'name',
      type: 'contactRecordColumn',
      keyCreator: ({ value, data }) => {
        const recordData = value || data;
        return recordData?.system_search_key;
      },
      filterParams: {
        valueGetter: ({ data }) => {
          return data?.contact_name;
        }
      }
    },
    {
      headerName: 'Contact type',
      field: 'type',
      type: 'capitalizedValueListColumn',
      values: ['person', 'company']
    },
    {
      headerName: 'Email (primary)',
      field: 'emails',
      type: 'primaryEmailColumn',
      subresource: 'emails'
    },
    {
      headerName: 'Phone (primary)',
      field: 'phones',
      type: 'primaryPhoneColumn',
      subresource: 'phones'
    },
    {
      headerName: 'Address',
      field: 'address'
    },
    {
      headerName: 'Postal address',
      field: 'address_postal'
    },
    {
      headerName: 'Appraisal Appointment',
      field: 'record_stats.appraisal_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'appraisal_count')?.value
    },
    {
      headerName: 'Contracts',
      field: 'record_stats.contract_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'contract_count')?.value
    },
    {
      headerName: 'Inspections Attended',
      field: 'record_stats.inspection_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'inspection_count')?.value
    },
    {
      headerName: 'Open Homes Attended',
      field: 'record_stats.ofi_count',
      type: 'numberColumn',
      valueGetter: ({ data }) =>
        data?.record_stats?.find((f) => f.key === 'ofi_count')?.value
    },
    {
      headerName: 'Last contacted date',
      field: 'last_contacted_date',
      type: 'dateColumn'
    },
    {
      headerName: 'Birthday',
      field: 'marketing_birthday',
      type: 'dateColumn'
    },
    {
      headerName: 'Gender',
      field: 'marketing_gender',
      type: 'capitalizedValueListColumn',
      values: ['male', 'female', 'N/A']
    },
    {
      field: 'interest_level',
      headerName: 'Interest level',
      type: 'capitalizedValueListColumn',
      // There is a value list for this, but it comes back capitalized when the
      // values from graphQL are lowercase. Changing the values in the filter
      // could break existing saved reports, so I'm hardcoding and keeping the values the same.
      filterParams: { values: ['hot', 'warm', 'cold', 'N/A'] }
    },
    {
      headerName: 'Enquiry source',
      field: 'marketing_enquiry_source',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'enquiry_source' })
      }
    },
    {
      headerName: 'Enquiry method',
      field: 'marketing_enquiry_method.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'enquiry_method' })
      }
    },
    {
      headerName: 'Do not contact',
      field: 'is_dnd',
      type: 'booleanColumn'
    },
    {
      headerName: 'Contact tags',
      field: 'tags',
      type: 'tagColumn'
    },
    {
      headerName: 'Match profile min price',
      field: 'latest_match_profile.price_match_min',
      type: 'currencyColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile max price',
      field: 'latest_match_profile.price_match_max',
      type: 'currencyColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile min beds',
      field: 'latest_match_profile.attr_bedrooms_min',
      type: 'numberColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile max beds',
      field: 'latest_match_profile.attr_bedrooms_max',
      type: 'numberColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile min baths',
      field: 'latest_match_profile.attr_bathrooms_min',
      type: 'numberColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile max baths',
      field: 'latest_match_profile.attr_bathrooms_max',
      type: 'numberColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile min garages',
      field: 'latest_match_profile.attr_garages_min',
      type: 'numberColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Match profile max garages',
      field: 'latest_match_profile.attr_garages_max',
      type: 'numberColumn',
      subresource: 'latest_match_profile'
    },

    ...(!region?.isEU
      ? [
          {
            headerName: 'Match profile suburbs',
            field: 'latest_match_profile.suburbs',
            type: 'textJoinColumn',
            subresource: 'latest_match_profile'
          }
        ]
      : []),

    ...(region?.isEU
      ? [
          {
            headerName: 'Match profile streets',
            field: 'latest_match_profile.streets',
            type: 'textJoinColumn',
            subresource: 'latest_match_profile'
          },
          {
            headerName: 'Match profile postcodes',
            field: 'latest_match_profile',
            valueGetter: ({ data, colDef }) => {
              const value = _.get(data, colDef?.field || '');
              const {
                postcode_area = [],
                postcode_district = [],
                postcode_sector = [],
                postcodes = []
              } = value || {};

              const postcodeFragments = [
                ...postcode_area,
                ...postcode_district,
                ...postcode_sector,
                ...postcodes
              ];

              return postcodeFragments
                ?.map((postcode) => postcode?.text)
                ?.filter?.(Boolean)
                ?.join(', ');
            },
            subresource: 'latest_match_profile'
          },
          {
            headerName: 'Match profile towns',
            field: 'latest_match_profile.towns',
            type: 'textJoinColumn',
            subresource: 'latest_match_profile'
          }
        ]
      : []),
    {
      headerName: 'Match profile category',
      field: 'latest_match_profile.category.text',
      type: 'valueListColumn',
      subresource: 'latest_match_profile',
      filterParams: {
        values: getAsyncFilterValues({
          listName: 'match_profile_listing_category'
        })
      }
    },
    {
      headerName: 'Match profile last modified',
      field: 'latest_match_profile.system_modtime',
      type: 'dateColumn',
      subresource: 'latest_match_profile'
    },
    {
      headerName: 'Record owner',
      field: 'system_owner_user.name',
      type: 'agentValueListColumn',
      subresource: 'system_owner_user'
    },
    {
      headerName: 'Created date',
      field: 'system_ctime',
      type: 'dateColumn'
    },
    {
      headerName: 'Created by',
      field: 'system_created_user.name',
      type: 'agentValueListColumn',
      subresource: 'system_created_user'
    },
    {
      headerName: 'Last modified',
      field: 'system_modtime',
      type: 'dateColumn'
    },
    ...customFieldColumns
  ];
};
