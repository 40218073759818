import React, { ReactElement } from 'react';
import Box from '@rexlabs/box';

type BoxProps = React.ComponentProps<typeof Box>;

function HeadingContainer(props: BoxProps): ReactElement {
  return <Box alignItems={'center'} height={30} {...props} />;
}

export default HeadingContainer;
