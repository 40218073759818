import React from 'react';

import Box from '@rexlabs/box';

interface SavedListItemRightHandContainerProps {
  children: React.ReactNode;
}

export function SavedListItemRightHandContainer(
  props: SavedListItemRightHandContainerProps
) {
  const { children } = props;

  return (
    <Box flexDirection='row' alignItems='center' sx='8px'>
      {children}
    </Box>
  );
}
