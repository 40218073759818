import React from 'react';
import Requires from 'view/containers/requires';
import { useStyles } from '@rexlabs/styling';
import menuStyles from './styles';

function InfoItem({ item, index }) {
  const s = useStyles(menuStyles);
  const { accessRights, addons, countries, content, envConfigs } = item;

  return (
    <Requires
      key={`info-${index}`}
      accessRights={accessRights}
      countries={countries}
      addons={addons}
      envConfigs={envConfigs}
    >
      <p {...s('info')}>{content}</p>
    </Requires>
  );
}

export default InfoItem;
