/* eslint-disable max-lines */
import Box from '@rexlabs/box';
import Types from 'prop-types';
import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';
import SaveCancelOverlay from 'view/components/save-cancel-overlay';
import AppSearch from 'view/components/navigation/app-search/index';

import GlobalAdd from '../popouts/global-add';
import Hamburger from '../components/hamburger';
import AgencySelector from '../popouts/agency-selector';

import { HEIGHTS, COLORS, PADDINGS } from 'theme';

const OPTION_STYLES = StyleSheet({
  name: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  meta: {
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '500'
  }
});

// TODO: Move these somewhere like a global theme file
const appSearchOverrides = {
  RexSearch: StyleSheet({
    wrapper: {
      position: 'fixed',
      width: '100vw',
      top: '0',
      left: '0',

      '& input': {
        '&::placeholder': {
          color: COLORS.APP_SEARCH.GREY_MEDIUM,
          fontSize: '20px',
          lineHeight: '26px'
        }
      }
    }
  }),

  SearchSectionHeader: StyleSheet({
    wrapper: {
      paddingBottom: '11px'
    },
    heading: {
      fontSize: '16px',
      lineHeight: '22px',
      color: COLORS.GREY_MEDIUM
    }
  }),
  Icon: StyleSheet({
    icon: {
      width: 'auto',
      height: '18px'
    }
  }),
  AppSearchInput: StyleSheet({
    container: {
      width: '100%'
    }
  }),
  MenuSwitcher: StyleSheet({
    container: {
      maxHeight: 'none',
      height: '100vh',
      boxShadow: 'none',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: COLORS.GREY_FEINT
    }
  }),
  TextInput: StyleSheet({
    container: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: 'none',
      lineHeight: '36px',
      background: 'white'
    },
    input: {
      minHeight: HEIGHTS.SHELL_HEADER.MOBILE, // Ensure we match the headers height
      color: COLORS.GREY_DARK,
      boxSizing: 'border-box',
      fontSize: '20px',
      lineHeight: '26px',
      padding: 0,
      paddingLeft: PADDINGS.L,
      height: '36px'
    }
  }),
  SectionRecents: StyleSheet({
    description: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      width: 'initial',
      display: 'block'
    },
    container: {
      minHeight: '56px'
    },
    id: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#A8AFB4',
      display: 'block'
    },
    details: {
      maxWidth: 'initial',
      width: 'initial',
      flex: '1',
      minWidth: 0
    }
  }),
  Option: StyleSheet({
    container: {
      height: '84px',
      alignItems: 'center'
    },
    iconBox: {
      borderRadius: '100%',
      color: '#C1C6D6',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.5px',
      margin: 0,
      marginLeft: '16px',
      width: '64px',
      height: '64px',

      '& img': {
        height: '64px',
        width: '64px',
        borderRadius: '100%'
      }
    },
    details: {
      marginLeft: '11px'
    },
    extraDetails: {
      alignSelf: 'flex-start'
    }
  }),
  ContactOption: OPTION_STYLES,
  ListingOption: OPTION_STYLES,
  BuildingOption: OPTION_STYLES,
  InvoiceOption: OPTION_STYLES,
  ProjectOption: OPTION_STYLES,
  ProjectStageOption: OPTION_STYLES,
  PropertyOption: OPTION_STYLES,
  ProviderOptionScreen: StyleSheet({
    canScroll: {
      height: `calc(100vh - ${HEIGHTS.SHELL_HEADER.MOBILE} - 20px)` // Remove the excess padding at the top of the list view
    }
  })
};

// TODO: Replace global add tooltip with shell dialog once we have created a dialog system
const mobileOverrides = {
  Hamburger: StyleSheet({
    iconWrapper: {
      height: 'auto',
      width: 'auto',

      '& svg': {
        width: '26px',
        height: '26px',
        marginRight: '2px'
      }
    }
  }),
  Tether: StyleSheet({
    container: {
      height: 'inherit',
      '& > div, & > div > div': {
        height: 'inherit'
      },
      '& > div:nth-of-type(2)': {
        // Overriding inline styles so need to have !important
        transform: 'none !important',
        right: 0,
        position: 'fixed !important',
        zIndex: '3 !important'
      }
    }
  }),
  Popout: StyleSheet({
    container: {
      height: 'inherit'
    },
    clickable: {
      height: 'inherit'
    },
    arrow: {
      display: 'none'
    },
    tooltip: {
      padding: 0
    }
  }),
  GlobalAdd: StyleSheet({
    container: {
      width: '60px',
      height: '60px',
      borderRadius: 0,
      backgroundColor: 'rgba(0,3,6,0.1)'
    }
  }),
  GlobalAddMenu: StyleSheet({
    container: {
      padding: 0,
      minWidth: '100%'
    },
    mainWrapper: {
      flexDirection: 'column',
      padding: '15px'
    },
    divider: {
      margin: '10px 5px',
      minHeight: '1px'
    },
    footerWrapper: {
      flexDirection: 'column'
    },
    footerSegment: {
      minWidth: '50%',
      minHeight: '50px'
    }
  })
};

@styled(
  StyleSheet({
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#3AA6F1',
      height: '60px',
      width: '100vw',
      zIndex: 12,
      userSelect: 'none',
      position: 'relative'
    },
    searchBackground: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    loadingIndicator: {
      bottom: 'auto',
      top: '-55px'
    },
    searchIcon: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        width: '18px',
        height: '18px',
        color: 'white'
      }
    },
    searchButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '60px',
      height: 'inherit',
      backgroundColor: 'rgba(0,3,6,0.2)',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgba(0,3,6,0.5)'
      }
    },
    closeContainer: {
      position: 'fixed',
      zIndex: 1,
      top: 0,
      right: 0,
      padding: '21px'
    },
    closeButton: {
      cursor: 'pointer',
      width: 'auto',
      height: '18px',

      '& > svg': {
        height: 'inherit',
        width: 'inherit'
      }
    }
  })
)
@autobind
class MobileHeader extends PureComponent {
  static propTypes = {
    agencies: Types.array.isRequired,
    currentAgencyName: Types.string.isRequired,
    currentAgencyColor: Types.string.isRequired,
    handleHamburgerClick: Types.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      shouldShowSearch: false
    };
  }

  toggleAppSearch() {
    this.setState({ shouldShowSearch: !this.state.shouldShowSearch });
  }

  renderCloseIcon() {
    const { styles: s } = this.props;

    return (
      <div
        {...s('closeContainer')}
        onClick={this.toggleAppSearch}
        onTouchEnd={this.toggleAppSearch}
      >
        <Icon {...s('closeButton')} type={ICONS.CLOSE} />
      </div>
    );
  }

  render() {
    const {
      styles: s,
      agencies,
      currentAgencyName,
      currentAgencyColor,
      handleHamburgerClick
    } = this.props;

    const agencySelectorProps = {
      agencies,
      currentAgencyName,
      currentAgencyColor
    };

    return (
      <StylesProvider
        components={mobileOverrides}
        prioritiseParentStyles={false}
      >
        <Box
          {...s('container')}
          style={{ backgroundColor: currentAgencyColor }}
        >
          <SaveCancelOverlay />

          <Box flex={1}>
            <Hamburger onClick={handleHamburgerClick} />

            <AgencySelector isMobile {...agencySelectorProps} />
          </Box>
          <Box flex={1}>
            <GlobalAdd isMobile />

            <Box {...s('searchButton')} onClick={this.toggleAppSearch}>
              <Icon type={ICONS.SEARCH} {...s('searchIcon')} />
            </Box>

            {this.state.shouldShowSearch && (
              <div
                {...s('searchBackground')}
                style={{ backgroundColor: currentAgencyColor }}
              />
            )}

            <StylesProvider
              components={appSearchOverrides}
              prioritiseParentStyles={false}
            >
              <AppSearch
                autoOpen
                shouldOpenOnFocus
                isClearable={false}
                shouldCloseOnBlur={false}
                onChosen={this.toggleAppSearch}
                selectSuffix={this.renderCloseIcon()}
                hidden={!this.state.shouldShowSearch}
              />
            </StylesProvider>
          </Box>
        </Box>
      </StylesProvider>
    );
  }
}

export default MobileHeader;
