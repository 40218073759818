import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import FileUploadInput from '@rexlabs/file-upload-input';
import { FormField } from 'view/components/form';
import UploaderTable from './uploader-table';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, TEXTS } from 'theme';
import Box from '@rexlabs/box';
import { SubText } from 'components/text/sub-text';
import Icon, { ICONS } from 'shared/components/icon';
import _ from 'lodash';
import { Select } from 'view/components/input/select';
import handleFileUpload from './upload-api';
import Spinner from 'shared/components/spinner';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

const uploaderstyles = StyleSheet({
  hiddenUploader: {
    display: 'none'
  },
  documentHeader: {
    ...TEXTS.CONTENT.INPUT_TEXT_SEMIBOLD,
    fontSize: '16px',
    fontWeight: 600
  },
  icon: {
    marginLeft: '8px',
    marginRight: '6px',
    padding: '6px',
    paddingLeft: '6px',
    width: '25px',
    height: '25px',
    background: COLORS.PRIMARY.RED,
    color: 'white',
    '&:hover': {
      background: COLORS.STATES.RED_HOVER,
      cursor: 'pointer'
    },
    '&:active': {
      boxShadow: 'inset 0 0 5px -1px #000000'
    }
  },
  uploadText: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

/**
 * @deprecated This `UploaderWithForm` is deprecated.
 * Consider using the `upload/documents/uploader-with-form` instead
 */

@withErrorDialog
@styled(uploaderstyles)
@autobind
class UploaderWithForm extends PureComponent {
  // TODO: implement propTypes and defaults
  static defaultProps = {};
  static propTypes = {};

  state = {
    activeFiles: _.get(this.props, 'initialFiles', []),
    deletedFiles: []
  };

  files = [];

  actions = {
    openFile: (url) => {
      window.open(url, '', 'noopener,noreferrer');
    },
    updateFileName: (index, newName) => {
      const { form } = this.props;
      const newArray = [...this.state.activeFiles];

      if (index >= 0) {
        newArray[index].description = newName;

        this.setState(
          {
            activeFiles: newArray
          },
          () => {
            form.setFieldValue('documents', this.state.activeFiles);
          }
        );
      }
    },
    deleteFile: (index) => {
      const { form } = this.props;
      const { activeFiles, deletedFiles } = this.state;
      const newArray = [...activeFiles];

      if (index >= 0) {
        newArray[index]._destroy = true;

        this.setState(
          {
            activeFiles: newArray.filter((file) => !file._destroy),
            deletedFiles: [
              ...deletedFiles,
              ...newArray.filter((file) => file._destroy)
            ]
          },
          () => {
            form.setFieldValue('documents', this.state.activeFiles);
            form.setMeta({ deletedFiles: this.state.deletedFiles });
          }
        );
      }
    }
  };

  uploadFile(file) {
    const { form, errorDialog } = this.props;

    this.setState(
      {
        fileUploading: true
      },
      () =>
        handleFileUpload(file)
          .then((response) => {
            const newFile = {
              ...response,
              description: file.name
            };

            this.setState(
              {
                fileUploading: false,
                activeFiles: [...this.state.activeFiles, newFile]
              },
              () => {
                form.setFieldValue('documents', this.state.activeFiles);
              }
            );
          })
          .catch((error) => {
            this.setState({ fileUploading: false });
            errorDialog.open(error);
          })
    );
  }

  handleFileUpdate(files, removeFiles) {
    if (files.length > 0) {
      files.forEach(this.uploadFile);
      removeFiles();
    }

    return files;
  }

  render() {
    const { fileUploadProps, fieldName, styles: s } = this.props;
    const { activeFiles } = this.state;

    return (
      <Fragment>
        <div {...s('hiddenUploader')}>
          <FormField
            label='status'
            name='documents'
            Input={Select}
            inputProps={{
              options: []
            }}
          />
        </div>
        <FormField
          name={fieldName}
          Input={FileUploadInput}
          inputProps={{
            ...fileUploadProps,
            children: ({
              InputContainer,
              inputProps,
              dragEvents,
              files,

              triggerInput,
              removeFiles
            }) => {
              this.files = this.handleFileUpdate(files, removeFiles);

              return (
                <Fragment>
                  <Box height='30px' alignItems='center' flex>
                    <span {...s('documentHeader')}>Documents</span>
                    {!this.state.fileUploading ? (
                      <Box marginBottom='5px' alignItems='center' flex>
                        <Box alignItems='center' flex>
                          <InputContainer
                            {...inputProps}
                            {...dragEvents}
                            styles={{
                              container: {
                                flexBasis: '50%',
                                border: 'none'
                              }
                            }}
                          />
                          <Icon
                            onClick={() => triggerInput()}
                            {...s('icon')}
                            type={ICONS.ADD}
                            width='13px'
                            height='13px'
                            inCircle
                          />
                        </Box>
                        <SubText
                          onClick={() => triggerInput()}
                          {...s('uploadText')}
                        >
                          upload document
                        </SubText>
                      </Box>
                    ) : (
                      <Box marginLeft='30px' alignItems='center' flex>
                        <Spinner small dark />
                      </Box>
                    )}
                  </Box>

                  <UploaderTable
                    actions={{ ...this.actions }}
                    items={activeFiles}
                  />
                </Fragment>
              );
            }
          }}
        />
      </Fragment>
    );
  }
}

export default UploaderWithForm;
