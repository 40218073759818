import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'view/components/button';
import Dropdown from 'view/components/action-menu/dropdown';

import { ListActionsConfig, Selection, QueryCriteria } from './types';
import { useSelectionCriteria } from './utils/use-selection-criteria';

const AddIcon: any = ICONS.ADD_MEDIUM_THICK;

const defaultStyles = StyleSheet({
  hintText: {
    color: '#929089',
    fontStyle: 'italic',
    padding: '0 5px',
    fontSize: '14px'
  }
});

export interface RecordListActionsProps {
  actions?: ListActionsConfig;
  onAdd?: () => void;
  selection: Selection;
  queryCriteria?: QueryCriteria[];
  afterMassAction?: () => void;
}

export function RecordListActions({
  actions,
  onAdd,
  selection,
  queryCriteria,
  afterMassAction
}: RecordListActionsProps) {
  const s = useStyles(defaultStyles);

  const selectionCriteria = useSelectionCriteria({ selection, queryCriteria });

  const permittedActions = actions?.filter((action) => {
    return action.hasPermission !== undefined ? action.hasPermission : action;
  });

  return (
    <>
      {!!onAdd && (
        <DefaultButton
          dark
          wide
          onClick={onAdd}
          data-testid='RecordListScreen.Add'
        >
          <AddIcon />
        </DefaultButton>
      )}
      {permittedActions?.length ? (
        <>
          {selection.type === 'exclude' ||
          (selection.type === 'include' && selection.ids.length) ? (
            permittedActions?.map((action, index) => {
              if (action.items) {
                const items = action.items.map((item) => ({
                  ...item,
                  onClick: () =>
                    item?.onClick
                      ? item?.onClick({
                          selection,
                          selectionCriteria,
                          afterMassAction
                        })
                      : null
                }));
                return (
                  <Dropdown
                    key={index}
                    items={items}
                    data-testid='RecordListScreen.MassActionsButton'
                  >
                    {action.label}
                  </Dropdown>
                );
              }

              return (
                <DefaultButton
                  key={index}
                  onClick={() =>
                    action.onClick?.({
                      selection,
                      selectionCriteria,
                      afterMassAction
                    })
                  }
                  data-testid='RecordListScreen.MassActionButton'
                >
                  {action.label}
                </DefaultButton>
              );
            })
          ) : (
            <span {...s('hintText')}>
              select one or more records in order to see actions
            </span>
          )}
        </>
      ) : null}
    </>
  );
}
