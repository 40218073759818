import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import {
  CustomField,
  getCustomFieldColumns
} from 'features/custom-reporting/utils/custom-field-utils';

export const projectStageFields = ({
  customFields,
  subresource
}: {
  customFields?: Record<string, CustomField>;
  subresource?: string;
}): ReportColumnConfig => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'project_stage'
  });
  return [
    { headerName: 'Project stage ID', field: 'id' },
    {
      headerName: 'Name',
      colId: 'project_stage_name',
      type: 'projectStageRecordColumn'
    },
    {
      headerName: 'Marketing status',
      field: 'project_marketing_state.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'project_marketing_state' })
      }
    },
    { headerName: 'Marketing tag line', field: 'marketing_tag_line' },
    {
      headerName: 'Construction commencement',
      field: 'construction_start_date',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Construction completion initial projection',
      field: 'construction_date_quoted',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Construction completion current projection',
      field: 'construction_current_projection',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Construction completion last updated',
      field: 'construction_last_updated',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Construction completion actual release',
      field: 'construction_actual_finished_date',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Release date initial projection',
      field: 'release_date_quoted',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Release date current projection',
      field: 'release_current_projection',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Release date last updated',
      field: 'release_last_updated',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Release date actual release',
      field: 'release_actual_finished_date',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Title issue initial projection',
      field: 'title_date_quoted',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Title issue current projection',
      field: 'title_current_projection',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Title issue last updated',
      field: 'title_last_updated',
      type: 'dateColumn',
      width: 330
    },
    {
      headerName: 'Title issue actual release',
      field: 'title_actual_finished_date',
      type: 'dateColumn',
      width: 330
    },
    { headerName: 'Created date', field: 'system_ctime', type: 'dateColumn' },
    {
      headerName: 'Created by',
      field: 'system_created_user.name',
      type: 'agentValueListColumn'
    },
    {
      headerName: 'Record Owner',
      field: 'system_owner_user.name',
      type: 'agentValueListColumn',
      subresource: 'record_owner'
    },
    {
      headerName: 'Last modified',
      field: 'system_modtime',
      type: 'dateColumn'
    },
    ...customFieldColumns
  ];
};
