import PostcodeDistrictValue from 'view/components/input/select/postcode-district/value';
import PostcodeDistrictOption from 'view/components/input/select/postcode-district/option';

import normalizer, {
  normalizerNoId
} from 'view/components/input/select/postcode-district/normalizer';

export {
  PostcodeDistrictOption,
  PostcodeDistrictValue,
  normalizer,
  normalizerNoId
};
