import { gql } from 'hooks/use-graphql-query';

export const Project = gql`
  fragment Project on Project {
    id
    name
    type {
      text
    }
    size {
      text
      id
    }
    is_single_building
    adr_street_name
    adr_locality
    adr_suburb_or_town
    adr_postcode
    developer_name
    price_list_disclaimer
    terms_of_sale
    system_ctime
    system_modtime
    system_created_user {
      name
    }
  }
`;
