import invariant from 'invariant';

function normalizer(postcodeDistrict) {
  if (postcodeDistrict.label && postcodeDistrict.value) {
    return postcodeDistrict;
  }

  const id = postcodeDistrict._id || postcodeDistrict.id;
  const name =
    postcodeDistrict._text ||
    postcodeDistrict.text ||
    postcodeDistrict._value ||
    postcodeDistrict.value;

  invariant(
    id && name,
    "Items must have either an '_id' or 'id' and '_text' or 'text'"
  );

  return {
    label: name,
    value: {
      id,
      name
    },
    type: 'postcodeDistrict'
  };
}

export function normalizerNoId(postcodeDistrict) {
  if (postcodeDistrict.label && postcodeDistrict.value) {
    return postcodeDistrict;
  }

  const id = postcodeDistrict._id || postcodeDistrict.id;
  const name =
    postcodeDistrict._text ||
    postcodeDistrict.text ||
    postcodeDistrict._value ||
    postcodeDistrict.value;

  return {
    label: name,
    value: {
      id: `${name}-postcodeDistrict`,
      originalId: id,
      name
    },
    type: 'postcodeDistrict'
  };
}

export default normalizer;
