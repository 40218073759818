import { COLORS } from './colors';

export const SHADOWS = {
  ICON_BUTTON: {
    ACTIVE: '0 4px 5px 0 rgba(0,0,0,0.3) inset'
  },

  DIALOG: '0px 0px 20px rgba(0,0,0,0.3)',

  APP_SEARCH: {
    DROP_DOWN: `2px 2px 6px 2px ${COLORS.APP_SEARCH.BOX_SHADOW}`,
    HEADER_BACK: `0 2px 4px 0px ${COLORS.BLACK_10_OPACITY}`
  }
} as const;
