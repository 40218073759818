import { useModelActions } from '@rexlabs/model-generator';
import uiModel from 'data/models/custom/ui';
import { useEffect } from 'react';
import SaveCancelContext from 'data/save-cancel-context';

interface SaveCancelOverlayHandlerProps {
  isVisible: boolean;
  onSave: () => Promise<unknown>;
  onCancel: () => void;
}

export default function SaveCancelOverlayHandler({
  isVisible,
  onSave,
  onCancel
}: SaveCancelOverlayHandlerProps) {
  const { updateSaveCancelOverlayState } = useModelActions(uiModel);

  useEffect(() => {
    if (isVisible) {
      // Warns user that they are leaving the page with unsaved changes
      window.onbeforeunload = () => true;
      SaveCancelContext.push({
        save: async () => {
          updateSaveCancelOverlayState('IN_PROGRESS');

          try {
            await onSave();
            updateSaveCancelOverlayState('OFF');
          } catch (e) {
            updateSaveCancelOverlayState('ON');
          }
        },
        cancel: () => {
          onCancel();
          updateSaveCancelOverlayState('OFF');
        }
      });
      updateSaveCancelOverlayState('ON');
    } else {
      window.onbeforeunload = null;
      SaveCancelContext.reset();
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isVisible]);
  return null;
}
