import React, { PureComponent, Fragment } from 'react';
import { Grid, Column } from 'shared/components/grid';
import { FormField } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';
import { EntitySelect } from 'view/components/input/select';
import { withRegion } from 'src/hocs/with-region';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import postcodesModel from 'data/models/entities/postcodes';
import suburbModel from 'data/models/system-lists/suburbs';
import ukSuburbModel from 'data/models/system-lists/uk-suburbs';
import { SelectValue } from 'view/components/input/select/values/model';

@withRegion
@autobind
class AddressInputBlock extends PureComponent {
  handlePostcodeSelect(selected) {
    const { values, townFieldName, setFieldValue, prefix = '' } = this.props;
    if (setFieldValue && values) {
      if (!values.adr_suburb_or_town) {
        setFieldValue(
          `${prefix}${townFieldName || 'adr_suburb_or_town'}`,
          _.get(selected, 'data.suburb_or_town')
        );
      }
    }
  }

  renderNonEUAddress() {
    const { disabled, prefix = '' } = this.props;
    return (
      <Fragment>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_unit_number`}
            label='unit no'
            Input={TextInput}
          />
        </Column>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_street_number`}
            label='street no'
            Input={TextInput}
            inputProps={{ disabled }}
          />
        </Column>
        <Column width={12}>
          <FormField
            name={`${prefix}adr_street_name`}
            label='street name'
            Input={TextInput}
            inputProps={{ disabled }}
          />
        </Column>
        <Column width={12}>
          <FormField
            name={`${prefix}suburb`}
            label='suburb'
            Input={EntitySelect}
            inputProps={{
              models: [suburbModel],
              valueAsObject: true,
              disabled
            }}
          />
        </Column>
      </Fragment>
    );
  }

  renderEUAddress() {
    const { disabled, values, townFieldName, prefix = '' } = this.props;
    return (
      <Fragment>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_unit_number`}
            label='flat no'
            Input={TextInput}
            inputProps={{ disabled }}
          />
        </Column>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_street_number`}
            label='street no'
            Input={TextInput}
            inputProps={{ disabled }}
          />
        </Column>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_street_name`}
            label='street name'
            Input={TextInput}
            inputProps={{ disabled }}
          />
        </Column>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_locality`}
            label='locality'
            Input={TextInput}
            inputProps={{ disabled }}
          />
        </Column>
        <Column width={6}>
          <FormField
            name={`${prefix}${townFieldName || 'adr_suburb_or_town'}`}
            label='town'
            Input={EntitySelect}
            inputProps={{
              models: [ukSuburbModel],
              options:
                values &&
                (_.get(values, `${prefix}${townFieldName}`) ||
                  _.get(values, `${prefix}adr_suburb_or_town`))
                  ? [
                      {
                        value: _.get(
                          values,
                          `${prefix}${townFieldName || 'adr_suburb_or_town'}`
                        ),
                        label: _.get(
                          values,
                          `${prefix}${townFieldName || 'adr_suburb_or_town'}`
                        )
                      }
                    ]
                  : [],
              withTags: false,
              Value: SelectValue,
              freeform: true,
              suggestive: true,
              disabled
            }}
          />
        </Column>
        <Column width={6}>
          <FormField
            name={`${prefix}adr_postcode`}
            label='postcode'
            Input={EntitySelect}
            inputProps={{
              models: [postcodesModel],
              onSelect: this.handlePostcodeSelect,
              options:
                values && _.get(values, `${prefix}adr_postcode`)
                  ? [
                      {
                        value: _.get(values, `${prefix}adr_postcode`),
                        label: _.get(values, `${prefix}adr_postcode`)
                      }
                    ]
                  : [],
              freeform: true,
              disabled,
              suggestive: true
            }}
          />
        </Column>
      </Fragment>
    );
  }

  render() {
    const { region } = this.props;
    return (
      <Grid>
        {region.isEU ? this.renderEUAddress() : this.renderNonEUAddress()}
      </Grid>
    );
  }
}

export default AddressInputBlock;
