import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import React, { ComponentProps } from 'react';
import { TextInput } from '@rexlabs/text-input';
import { COLORS, TEXTS } from 'theme';

const inputStyleOverrides = {
  TextInput: StyleSheet({
    container: {
      border: 'none !important',
      outline: 'none !important',
      boxShadow: 'none !important',
      padding: '6px 8px',
      height: '34px',
      borderRadius: '6px',
      '&:focus, &:hover, &:active': {
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none !important'
      }
    },
    input: {
      ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
      border: 'none',
      color: COLORS.DARK_GREY
    }
  })
};

export function AiTextInput(props: ComponentProps<typeof TextInput>) {
  return (
    <StylesProvider
      components={inputStyleOverrides}
      prioritiseParentStyles={false}
    >
      <TextInput {...props} />
    </StylesProvider>
  );
}
