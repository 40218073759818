import { useEffect, useMemo, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useEntityListQuery, Query } from '@rexlabs/model-generator';

import { Criteria } from 'types/criteria';

import { OrderDir } from './use-order-by';

import { LensesConfig } from '../types';

let uuid = 0;

const EMPTY_LIST = [];

type PageData = Record<'string', any>[];

interface UseListDataArgs {
  query: Query<any>;
  page: number;
  limit: number;
  criteria?: Criteria[];
  orderBy: string;
  orderDir: OrderDir;
  lenses?: LensesConfig;
  fetchAllIds?: boolean;
}

export function useListData({
  query,
  page,
  limit,
  criteria,
  orderBy,
  orderDir,
  fetchAllIds
}: UseListDataArgs) {
  // HACK: we enhance the criteria with meta information, which might require extra
  // API calls + will change the criteria object. in order to still only trigger a
  // new API call here when the core value/name changes of any of the criteria, we
  // create a hash here to use below in the dependency array
  const criteriaHash = criteria
    ?.map(({ name, value, type }) => `${name}--${value}--${type}`)
    .join(',');

  const listQuery = useMemo(
    () => {
      const finalQuery = cloneDeep(query);
      finalQuery.uuid = `${query.uuid}--${++uuid}`;

      finalQuery.args.limit = limit;
      if (page) {
        finalQuery.args.page = page + 1;
      }

      if (criteria?.length) {
        finalQuery.args.criteria = criteria;
      }

      // TODO: criteria from lens
      // TODO: handle viewstate

      if (orderBy) {
        finalQuery.args.order_by = { [orderBy]: orderDir };
      }

      return finalQuery;
    },
    // eslint-disable-next-line
    [query, limit, criteriaHash, orderBy, orderDir]
  );

  const { data, status, actions, ...rest } = useEntityListQuery(listQuery);

  // For lists that go to a details screen, additionally to the main list API
  // call we also fetch the first 300 ids for the list to provide the pagination
  // functionality on the details view
  // We're cloning the `listQuery` here to ensure we have the same filters, sorting
  // etc applied to this `allIds` query
  // TODO: check with UX if we can drop this to remove the extra API call
  const allIdsQuery = useMemo(() => {
    const finalQuery = cloneDeep(listQuery);

    // Change uuid to make sure it's stored as separate list by model generator
    finalQuery.uuid = `${listQuery.uuid}--ids`;

    // Set result format and limit
    finalQuery.args.result_format = 'ids';
    finalQuery.args.limit = 300;

    return finalQuery;
  }, [listQuery]);

  const { data: allIds } = useEntityListQuery(allIdsQuery, {
    fetch: !!fetchAllIds
  });

  const allIdsMemoised = useMemo(
    () => allIds?.map?.((row: any) => row?.id) || EMPTY_LIST,
    [allIds]
  );

  // Load specific page if we're on a page that doesn't have data loaded yet
  // We store the currently loaded page in a separate state to be able to keep
  // showing the old page until the new requested page is loaded
  const [loadedPage, setLoadedPage] = useState(page);

  const fetching = useRef<number | undefined>();

  useEffect(
    () => {
      const firstIndex = page * limit;
      const firstIndexEmpty =
        data?.length &&
        (data?.[firstIndex] === null || data?.[firstIndex] === undefined);

      const needsFetch =
        firstIndexEmpty &&
        page !== loadedPage &&
        status !== 'loading' &&
        fetching?.current !== page;

      if (needsFetch) {
        fetching.current = page;
        actions
          .fetchPage({ args: { page: page + 1 } })
          .then(() => setLoadedPage(page));
      } else {
        setLoadedPage(page);
      }
    },
    // eslint-disable-next-line
    [page, limit]
  );

  const pageData = useMemo(
    () =>
      data?.slice?.(loadedPage * limit, loadedPage * limit + limit) ||
      EMPTY_LIST,
    [data, loadedPage, limit]
  ) as PageData;

  // TODO: Lens title

  const [loadedData, setLoadedData] = useState<PageData | undefined>(undefined);

  // Here, we do not let any change on pageData until the loading is done.
  useEffect(() => {
    if (status === 'loaded') {
      setLoadedData(pageData);
    }
  }, [pageData, setLoadedData, status]);

  return {
    ...rest,
    actions,
    pageData: loadedData,
    loadedPage,
    allIds: allIdsMemoised,
    status
  };
}
