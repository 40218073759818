import React, { PureComponent } from 'react';
import { KEYFRAMES } from 'theme';
import { styled, StyleSheet } from '@rexlabs/styling';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import { RenderMatchedRoutes } from '@rexlabs/whereabouts';
import ROUTES from 'routes/public-actions';

const defaultStyles = StyleSheet({
  container: {
    boxSizing: 'border-box',
    backgroundColor: '#F0F2F4',
    height: 'auto',
    width: '100vw',
    display: 'flex'
  }
});

@styled(defaultStyles)
class PublicApp extends PureComponent {
  render() {
    const { styles: s } = this.props;

    return (
      <div {...s('container')}>
        <RenderMatchedRoutes routes={ROUTES}>
          {(matches) => (
            <CSSAnimationGroup
              leaveAnimation={KEYFRAMES.SCREEN_FADE_OUT}
              leaveDuration='300ms'
              enterAnimation={KEYFRAMES.SCREEN_FADE_IN}
              enterDuration='300ms'
              {...s('container')}
            >
              {matches}
            </CSSAnimationGroup>
          )}
        </RenderMatchedRoutes>
      </div>
    );
  }
}

export default PublicApp;
