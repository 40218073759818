import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

const actionCreators = {
  getEffectiveSetting: {
    request: ({ key }) =>
      api.post('AccountSettingsCompliance::getEffectiveSetting', {
        key
      }),

    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

export default new Generator('accountSettingsCompliance').createModel({
  actionCreators
});
