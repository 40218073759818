import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface HeadingProps extends BaseTextProps {
  level?: 1 | 2 | 3 | 4;
  white?: boolean;
}

export function Heading({ level, white, ...props }: HeadingProps) {
  return (
    <Text
      is={level && `h${level}`}
      {...props}
      type={map({
        HEADING: true,
        HEADING_1: level === 1,
        HEADING_2: level === 2,
        HEADING_3: level === 3,
        HEADING_4: level === 4,
        WHITE: white
      })}
    />
  );
}
