import { gql } from 'hooks/use-graphql-query';

export const CommissionWorksheetAgentAllocation = gql`
  fragment CommissionWorksheetAgentAllocation on CommissionWorksheetAgentAllocation {
    id
    agent_role {
      text
    }
    cache_gross_comm_net_deductions
    gross_comm_alloc_percentage
    cache_gross_comm
    net_comm_amount
    net_comm_amount_unit {
      id
    }
    cache_final_comm
  }
`;
