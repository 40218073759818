import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import LocalStorage from 'shared/utils/local-storage';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

/*
|-------------------------------------------------------------------------------
| Model
*/

const navMenuStorage = LocalStorage.get('navMenu');

const updateFrameStyling = (isOpen) => {
  if (_.get(window, 'Rex2FrameWindow.document.documentElement.classList')) {
    const cl = window.Rex2FrameWindow.document.documentElement.classList;
    if (isOpen) {
      cl.add('js-fixed-shell-nav');
    } else {
      cl.remove('js-fixed-shell-nav');
    }
  }
  return isOpen;
};

const initialState = {
  isOpen: navMenuStorage?.isOpen,
  hasDarkenedOverlay: navMenuStorage?.isOpen
};

const actionCreators = {
  toggleOpen: {
    reduce: (state) => {
      const isOpen = !state.isOpen;

      Analytics.track({
        event: isOpen
          ? EVENTS.NAVIGATION_MENU_TOGGLE.OPEN
          : EVENTS.NAVIGATION_MENU_TOGGLE.CLOSED,
        options: { integrations: { Intercom: true } }
      });

      LocalStorage.set('navMenu', { isOpen });

      return {
        ...state,
        hasDarkenedOverlay: isOpen,
        isOpen: updateFrameStyling(isOpen)
      };
    }
  },
  toggleDarkenedOverlay: {
    reduce: (state) => {
      return {
        ...state,
        hasDarkenedOverlay: !state.hasDarkenedOverlay
      };
    }
  }
};

const selectors = {
  isOpen: (state) => state?.nav?.isOpen,
  hasDarkenedOverlay: (state) => state?.name?.hasDarkenedOverlay
};

export default new Generator('nav').createModel({
  initialState,
  actionCreators,
  selectors
});
