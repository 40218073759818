import { useMemo } from 'react';
import { ColumnsConfig } from 'components/record-list-screen/types';
import { IdCell } from 'components/record-list-screen/cells/id-cell';
import { ListingCell } from 'components/record-list-screen/cells/listing-cell';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';
import dayjs from 'dayjs';
import { MultiContactCell } from 'components/record-list-screen/cells/multi-contact-cell';
import { ReinzForm } from 'features/reinz-forms/types/reinz-form';
import { upperFirst } from 'lodash';

export function useReinzFormsColumns() {
  const region = useRegion();
  const currencySymbol = region.financial.currency.symbol;

  return useMemo(
    (): ColumnsConfig<ReinzForm> => [
      {
        label: 'ID',
        id: 'id',
        selector: (row) => row.id,
        Cell: IdCell,
        hidden: true
      },
      {
        label: 'Listing',
        id: 'listing',
        selector: (row) => row.contract?.listing,
        Cell: ListingCell,
        forced: true
      },
      {
        label: 'Purchaser(s)',
        id: 'purchasers',
        selector: (row) =>
          row.contract?.related.listing_contract_purchtenants.map(
            (purchaser) => purchaser.purchtenant
          ),
        Cell: MultiContactCell,
        forced: true
      },
      {
        label: 'Sale Price',
        id: 'sale_price',
        selector: (row) =>
          `${currencySymbol}${formatCurrency(
            row.contract?.detail_sale_price_or_lease_pa
          )}`,
        forced: true
      },
      {
        label: 'Date Added',
        id: 'system_ctime',
        selector: (row) =>
          row.system_ctime
            ? dayjs.unix(row.system_ctime).format('D MMM YYYY')
            : '',
        sortable: true,
        forced: true,
        width: '100px'
      },
      {
        label: 'Agent',
        id: 'agent',
        selector: (row) => row.contract?.agent.name,
        forced: true
      },
      {
        label: 'Status',
        id: 'status',
        selector: (row) => upperFirst(row.status),
        forced: true
      },
      {
        label: 'Last Update',
        id: 'system_modtime',
        selector: (row) =>
          row.system_modtime
            ? dayjs.unix(row.system_modtime).format('D MMM YYYY')
            : '',
        sortable: true,
        forced: true,
        width: '100px'
      },
      {
        label: 'Modified By',
        id: 'system_modified_user',
        selector: (row) => row.system_modified_user.name
      },
      {
        label: 'Agreement',
        id: 'template_type',
        selector: (row) =>
          row.template_type === 'sale_purchase'
            ? 'Sale & Purchase'
            : upperFirst(row.template_type)
      }
    ],
    [currencySymbol]
  );
}
