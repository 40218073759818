import React, { useMemo } from 'react';
import { get, isObject, noop, omit } from 'lodash';

import Box from '@rexlabs/box';
import { useModelActions } from '@rexlabs/model-generator';

import adminMergeTemplatesModel, {
  AdminMergeTemplateItem,
  EditCustomMergeTemplateItem
} from 'data/models/custom/admin-merge-templates';

import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

import { MergeTemplateOTFProps } from 'utils/dialogs/mapping-classic-interfaces';

import { useDialog } from 'hooks/use-dialog';

import { SplitButton } from 'view/components/action-menu';
import { DefaultButton } from 'view/components/button';

import { Body } from 'components/text/body';
import { MergeObject } from 'data/models/entities/mail-merge';
import { COLORS } from 'src/theme';

interface MergeTemplateOtfDialogProps {
  open: (args: MergeTemplateOTFProps) => void;
  close: (args: any) => void;
}

type CustomTemplateObj = EditCustomMergeTemplateItem;
type PartialTemplateObj = Pick<EditCustomMergeTemplateItem, 'id'>;
type SelectedTemplateValue = CustomTemplateObj | PartialTemplateObj;
type TemplateValue = string | SelectedTemplateValue;

interface MergeTemplateSelectProps {
  value?: { value: TemplateValue; label: string } | null;
  modules: string[];
  medium: 'email' | 'sms' | 'letter';
  onChange: (args: FakeEvent) => void;
  propertyId?: string;
  // Edit raw template (with merge tags)
  enableOnTheFly?: boolean;
  // Edit resolved template (with merge tags populated)
  mergeObject?: MergeObject;
  vertical?: boolean;
}

type SelectedTemplateSelectValue = {
  label: string;
  value: SelectedTemplateValue;
} | null;

type FakeEvent = {
  persist: () => void;
  target: { value: SelectedTemplateSelectValue };
} | null;

function useMergeTemplateActions({
  onChange,
  value,
  modules,
  medium,
  enableOnTheFly,
  propertyId,
  mergeObject
}: MergeTemplateSelectProps) {
  const { fetchItem } = useModelActions(adminMergeTemplatesModel);

  const previewMergeTemplateDialog = useDialog('previewMergeTemplate');
  const mergeTemplateOtfDialog = useDialog(
    'mergeTemplateOtf'
  ) as MergeTemplateOtfDialogProps;

  function fakeEvent(value: SelectedTemplateSelectValue) {
    return {
      persist: noop,
      target: { value }
    };
  }

  async function getTemplateForCustomisation() {
    // Fetch template data for merge object if we don't have it yet
    if (mergeObject && value) {
      if (!isObject(value.value)) {
        return await fetchItem({ id: value.value }).then(({ data }) => ({
          ...omit(data, 'related'),
          attachments: (data.related.documents || []).map((attachment) => ({
            label: attachment.description,
            link: attachment.url
          }))
        }));
      } else {
        return value.value as EditCustomMergeTemplateItem;
      }
    }

    // We should only be using this function if the value is an object.
    // The reason for this is we need to pass back the template values
    // which are returned if your using the valueAsObject prop.
    if (!value || !value.value || !isObject(value.value)) {
      return;
    }

    const templateObj = value.value;

    const { id, ...rest } = templateObj;

    return !id
      ? ({ ...rest } as EditCustomMergeTemplateItem)
      : await fetchItem({ id: value.value.id }).then(({ data }) => ({
          ...omit(data, 'related'),
          attachments: (data.related.documents || []).map((attachment) => ({
            label: attachment.description,
            link: attachment.url
          }))
        }));
  }

  function onChangeCallback(selected: AdminMergeTemplateItem) {
    // If we allow edit of resolved template we will only store
    // the whole data when it is edited
    onChange(
      fakeEvent({
        value:
          enableOnTheFly || (mergeObject && get(selected, 'isDirty'))
            ? selected
            : get(selected, '_id') || get(selected, 'id'),
        label: get(selected, 'template_name')
      })
    );
  }

  function customiseMergeTemplate(
    template: EditCustomMergeTemplateItem | AdminMergeTemplateItem
  ) {
    return mergeTemplateOtfDialog.open({
      template,
      options: {
        propertyId,
        mergeObject
      },
      callback: onChangeCallback
    });
  }

  async function onCustomiseMergeTemplate() {
    const templateToCustomise = await getTemplateForCustomisation();
    return templateToCustomise && customiseMergeTemplate(templateToCustomise);
  }

  function onCreateOTFTemplate() {
    return previewMergeTemplateDialog.open({
      options: { modules, medium, otf: true },
      callback: (template) =>
        customiseMergeTemplate({
          ...template,
          // Blank OTF templates don't template_module, they just have the template_module_id
          template_module: template.template_module_id
            ? {
                id: template.template_module_id,
                text: template.template_module_id
              }
            : template.template_module
        })
    });
  }

  function onSelectMergeTemplate() {
    previewMergeTemplateDialog.open({
      options: { modules, medium },
      callback: onChangeCallback
    });
  }

  function onClearSelection() {
    onChange?.(fakeEvent(null));
  }

  return {
    onClearSelection,
    onCreateOTFTemplate,
    onSelectMergeTemplate,
    onCustomiseMergeTemplate
  };
}

export function MergeTemplateSelect({
  onChange,
  value,
  modules,
  medium,
  enableOnTheFly,
  propertyId,
  mergeObject,
  vertical
}: MergeTemplateSelectProps) {
  const {
    onClearSelection,
    onCreateOTFTemplate,
    onCustomiseMergeTemplate,
    onSelectMergeTemplate
  } = useMergeTemplateActions({
    onChange,
    value,
    modules,
    medium,
    enableOnTheFly,
    propertyId,
    mergeObject
  });

  // Can remove this once feature is released, and just use enableOnTheFly
  // https://rexsoftware.atlassian.net/browse/RADI-6144
  const showOnTheFlyOptions =
    enableOnTheFly && hasFeatureFlags('leads_autoresponder_otf');

  const valueLabel = value?.label;

  const changeTemplateButton = useMemo(() => {
    return valueLabel ? (
      <SplitButton
        onClick={onSelectMergeTemplate}
        light
        items={[
          {
            label: 'Change Template',
            onClick: onSelectMergeTemplate
          },
          ...(showOnTheFlyOptions || mergeObject
            ? [{ label: 'Edit Template', onClick: onCustomiseMergeTemplate }]
            : []),
          {
            label: 'Remove Template',
            onClick: onClearSelection
          }
        ]}
      >
        Change Template
      </SplitButton>
    ) : showOnTheFlyOptions ? (
      <SplitButton
        onClick={onSelectMergeTemplate}
        light
        items={[
          {
            label: 'Choose Template',
            onClick: onSelectMergeTemplate
          },
          {
            label: 'Create an On-The-Fly Template',
            onClick: onCreateOTFTemplate
          }
        ]}
      >
        Choose a Template
      </SplitButton>
    ) : (
      <DefaultButton light onClick={onSelectMergeTemplate}>
        Choose a Template
      </DefaultButton>
    );
  }, [
    mergeObject,
    onClearSelection,
    onCreateOTFTemplate,
    onCustomiseMergeTemplate,
    onSelectMergeTemplate,
    showOnTheFlyOptions,
    valueLabel
  ]);

  if (vertical) {
    return (
      <Box flexDirection='column' alignItems='flex-end'>
        {changeTemplateButton}
        {valueLabel && (
          <Body dark style={{ textAlign: 'right', marginTop: 10 }}>
            {valueLabel}
          </Body>
        )}
        {mergeObject && isObject(value?.value) && (
          <Body
            normal
            semibold
            small
            style={{
              color: COLORS.PRIMARY.SAND,
              textAlign: 'right',
              marginTop: 5
            }}
          >
            Edited
          </Body>
        )}
      </Box>
    );
  }

  return (
    <Box flexDirection='row' alignItems='center'>
      <Box mr={10}>
        <Body dark={!!valueLabel} italic={!valueLabel}>
          {valueLabel || 'No Template'}
        </Body>
      </Box>
      {changeTemplateButton}
    </Box>
  );
}

export default MergeTemplateSelect;
