import React from 'react';
import dayjs from 'dayjs';

import { Value, ValueProps } from '.';

interface DateValueProps extends Omit<ValueProps, 'value'> {
  value?: number;
  format?: string;
}

export function DateValue({
  value,
  format = 'YYYY/MM/DD',
  ...props
}: DateValueProps) {
  return (
    <Value
      value={value ? dayjs(value * 1000).format(format) : null}
      {...props}
    />
  );
}
