import React, { PropsWithChildren } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS } from 'src/theme';
import { MultiLineTruncate } from 'components/_experiment/muli-line-truncate';

const defaultStyles = StyleSheet({
  heading: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    whiteSpace: 'pre-line',

    '& b': {
      fontWeight: 600
    },

    '& a': {
      fontWeight: 600,
      color: COLORS.PRIMARY.BLUE
    }
  }
});

interface HeadingProps {
  maxLines?: number;
}

export function Heading({
  children,
  maxLines
}: PropsWithChildren<HeadingProps>) {
  const s = useStyles(defaultStyles);

  return (
    <MultiLineTruncate as='h1' {...s('heading')} maxLines={maxLines}>
      {children}
    </MultiLineTruncate>
  );
}
