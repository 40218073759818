import { useCallback } from 'react';
import { ErrorDialogProps } from 'components/error-dialog';
import { useDialog } from './use-dialog';

export function useErrorDialog() {
  const errorDialog = useDialog('errorDialog');

  const open = useCallback(
    ({
      message,
      errors,
      problem,
      sourceAction,
      onClose,
      hasCloseButton
    }: ErrorDialogProps) => {
      return errorDialog.open({
        message,
        errors,
        problem,
        sourceAction,
        onClose,
        hasCloseButton
      });
    },
    [errorDialog]
  );

  return { ...errorDialog, open };
}
