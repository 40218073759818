import React from 'react';
import { uniqBy } from 'lodash';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { EntitySelect } from '@rexlabs/select';

import SuburbValue from './value';
import SuburbOption from './option';
import normalizer, { normalizerNoId } from './normalizer';

import Loading from '../defaults/loading';
import NoResultsFound from '../defaults/not-found';

import { api } from 'shared/utils/api-client';

// HACK: this is copy/pasted atm for all "new" selects, instead
// we should create a wrapper component if really needed that takes
// care of this in one central place
export const overrides = {
  TextInput: StyleSheet({
    wrapper: {
      flex: 1,
      justifyContent: 'center',
      minHeight: '22px',
      minWidth: '120px'
    },
    container: {
      height: '100%',
      border: 'none',
      padding: '0'
    },
    cosmeticWrapper: {
      height: '100%'
    },
    input: {
      height: 'auto'
    }
  })
};

async function autoCompleteSuburbs(searchTerm) {
  const { data } = await api.post('SystemValues::autocompleteCategoryValues', {
    list_name: 'suburbs',
    search_string: `%${searchTerm}%`
  });
  return uniqBy((data.result || []).map(normalizerNoId), 'value.id');
}

function SuburbMultiSelect(props) {
  return (
    <StylesProvider components={overrides} prioritiseParentStyles={false}>
      <EntitySelect
        multi
        shouldOpenOnInputFocus={false}
        shouldOpenOnInputClick={false}
        defaultHighlightedIndex={0}
        placeholder='Search for a suburb or postcode'
        autocomplete={autoCompleteSuburbs}
        Value={SuburbValue}
        Option={SuburbOption}
        Loading={Loading}
        NoResultsFound={NoResultsFound}
        {...props}
      />
    </StylesProvider>
  );
}

export { SuburbOption, SuburbValue, normalizer, normalizerNoId };
export default SuburbMultiSelect;
