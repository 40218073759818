// Test usage without queries:

// import keyRegistryModel from 'data/models/entities/key-register';

// @withModel(keyRegistryModel)
// class CheckOutKey extends PureComponent {

//   componentDidMount () {
//     const { keyRegister } = this.props;

//     Promise.all([
//       keyRegister.fetchList({ id: 'hello' }), //note that you will need to fill in a fake id here.
//       keyRegister.fetchItem({ id: 13 }),
//       keyRegister.getHistory(13)
//     ])
//       .then(console.log)
//       .then(() =>
//         keyRegister.checkOut({ userId: 1, keyId: 12, reason: 'because' })
//       )
//       .then(console.log)
//       .then(() =>
//         keyRegister.checkIn({ userId: 1, keyId: 12 })
//       )
//       .then(console.log);
//   }
//   render(){
//   }
// }

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const actionCreators = {
  getHistory: {
    request: (keyId) =>
      api
        .post('KeyRegister::getHistory', {
          key_id: keyId
        })
        .then(({ data }) => data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  checkOut: {
    request: ({ keyId, reason, userId, contactId }) =>
      api
        .post('KeyRegister::checkOut', {
          key_id: keyId,
          user_id: userId,
          contact_id: contactId,
          reason
        })
        .then(({ data }) => data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  checkIn: {
    request: ({ keyId, userId }) =>
      api
        .post('KeyRegister::checkIn', {
          key_id: keyId,
          user_id: userId
        })
        .then(({ data }) => data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const TYPE = 'keyRegister';
const keyRegisterModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default keyRegisterModel;
