import React, { HTMLProps } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'theme';

const defaultStyles = StyleSheet({
  horizontalDivider: {
    borderBottom: `0.1rem solid ${COLORS.STATES.IDLE}`,
    margin: `${PADDINGS.S} 0`
  }
});

export function HorizontalDivider(props: HTMLProps<HTMLDivElement>) {
  const s = useStyles(defaultStyles);
  return <div {...s('horizontalDivider')} {...props} />;
}
