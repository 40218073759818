/*
|-------------------------------------------------------------------------------
| App Search Provider
|-------------------------------------------------------------------------------
|
| A Provider's main function is to retrieve search results and then format them to be rendered
| inside of the dropdown.
|
| State:
|  - TYPE
|
| Actions
|  - search
|  - renderOption
|  - renderSkeleton
|
| Helpers:
|  - ProviderException
|
*/

import { autobind } from 'core-decorators';

// HACK: Not really extending the builtin Error class just creating an object and throwing that
// NOTE: To extend builtin Error class we need this https://github.com/loganfsmyth/babel-plugin-transform-builtin-extend
function ProviderException(message) {
  this.message = message;
  this.name = 'ProviderException';
}

@autobind
class ProviderInterface {
  static TYPE = '';

  /**
   * Performs a search and
   *
   * @param {String} term - The current search term that has been entered by the user.
   * @param {String} scopedType - The current scope that the search is in. E.g. One of GLOBAL, CONTACT, LISTING etc.
   * @returns {Promise} Must returned a Promise to that will resolve with an array of results. The results are passed through @renderOption.
   */
  search() {
    throw new ProviderException(
      'You have not implemented the search function!'
    );
  }

  /**
   * This function will be used to render the body of an option.
   *
   * @param {Object} props - This will contain the current result option.
   * @param {String} scopedType - The current scope that the search is in. E.g. One of GLOBAL,
   * CONTACT, LISTING etc. Generally used to prevent rendering the current option.
   */
  renderOption() {
    throw new ProviderException(
      'You have not implemented the renderOption function!'
    );
  }

  /**
   * Optional method that can be used to customise your recent options.
   *
   * @param props
   * @param scopedType
   */
  renderRecentOption() {
    // TODO?
  }

  renderSkeleton() {
    throw new ProviderException(
      'You have not implemented the renderSkeleton function!'
    );
  }
}

export default ProviderInterface;
