import React, { ReactNode } from 'react';
import Tooltip from 'view/components/tooltip';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { ICONS } from 'shared/components/icon';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';

import Box from '@rexlabs/box';

const tooltipStyles = {
  Tether: StyleSheet({
    content: {
      top: '15px'
    }
  })
};

const defaultStyles = StyleSheet({
  sparkle: {
    height: '10px',
    width: 'auto',
    marginRight: '6px'
  }
});

interface UpgradeProps {
  children: ReactNode;
  styles: Function;
}

// TODO - remove this any - https://app.clubhouse.io/rexlabs/story/48810/ts-cleanup-tracking
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const Sparkle: any = ICONS.SPARKLE;

function UpgradeTooltip({ children, styles: s }: UpgradeProps) {
  return (
    <StylesProvider components={tooltipStyles} prioritiseParentStyles={false}>
      <Tooltip
        width={350}
        inline
        styles={{
          tooltip: {
            maxWidth: '350px'
          }
        }}
        Content={() => (
          <Box>
            <Sparkle {...s('sparkle')} /> Not available on your plan
          </Box>
        )}
        placement={PLACEMENTS.TOP}
        hoverTimeout={1}
        distance='25px'
      >
        <Box>{children}</Box>
      </Tooltip>
    </StylesProvider>
  );
}

export default styled(defaultStyles)(UpgradeTooltip);
