import React from 'react';
import Box from '@rexlabs/box';
import { AutoMatchProfileSettings } from '../components/auto-match-profile-settings';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { Body } from 'components/text/body';
import { SubHeading } from 'components/text/sub-heading';
import { TabsStateful } from 'src/view/components/tabs';
import { ResidentialSaleSettings } from './residential-sale-settings';
import { ResidentialRentalSettings } from './residential-rental-settings';

export function MatchProfileSettings() {
  if (!hasFeatureFlags('auto_match_rentals')) {
    return <AutoMatchProfileSettings type='res_sale' />;
  }

  return (
    <Box width='100%'>
      <Box p={'28px 20px 25px'}>
        <SubHeading semibold style={{ marginLeft: 0, marginBottom: 7.5 }}>
          Automatic Match Profiles & Campaigns
        </SubHeading>
        <Body regular dark style={{ marginLeft: 0 }}>
          Create automated match profiles and campaigns for residential sales
          and residential rentals. Match profiles are based on the details of
          related listings. An automatic profile will not be created if the
          contact already has a residential sale or residential rental match
          profile. Automated campaigns will only be available if automatic match
          profiles are enabled.
        </Body>
      </Box>
      <TabsStateful
        withContentBorder
        tabBarProps={{
          withPadding: true
        }}
        items={[
          {
            name: 'res_sale',
            label: 'Residential Sale',
            Tab: ResidentialSaleSettings
          },
          {
            name: 'res_rental',
            label: 'Residential Rental',
            Tab: ResidentialRentalSettings
          }
        ]}
      />
    </Box>
  );
}
