import { Generator } from 'shared/utils/models';
import OriginFixture from 'view/components/input/select/fixtures/origin';
import { api } from 'shared/utils/api-client';

import _ from 'lodash';

const TYPE = 'marketLeadOrigin';
const marketLeadOriginModel = new Generator(TYPE).createValueListModel();

marketLeadOriginModel.select = {
  autocomplete: () =>
    api
      .post('AdminValueLists::getListValues', {
        list_name: 'market_lead_origin'
      })
      .then(({ data }) =>
        _.get(data, 'result', []).map((origin) => ({
          value: origin.id,
          label: origin.name,
          data: origin,
          model: marketLeadOriginModel
        }))
      ),
  Fixture: OriginFixture
};

export default marketLeadOriginModel;
