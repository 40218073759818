import React, { useMemo } from 'react';
import { RowNode } from 'ag-grid-community';
import { Link } from 'components/text/link';
import { FeedbackData } from 'src/utils/dialogs/mapping-classic-interfaces';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { useAddFeedback } from 'hooks/use-add-feedback';
import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import { getEmptyValue } from '../data-grid-column-types';
import { getFormattedDate } from 'src/utils/date';
import { convertUtcToLocalDate } from 'src/utils/calendar';

export function FeedbackColumn({
  data,
  value,
  node
}: {
  data: Record<string, unknown> & { feedback: FeedbackData };
  value?: string;
  node: RowNode | null;
}) {
  const { reloadReportData } = useReportState();
  const errorDialog = useErrorDialog();
  const feedbackData = useMemo(
    () => (data.feedback?.[0] ? data.feedback[0].stub : {}),
    [data.feedback]
  );

  const onAddFeedbackClick = useAddFeedback({
    id: feedbackData.id,
    data: feedbackData,
    mode: 'both',
    errorDialog,
    options: { isEditing: true },
    callback: reloadReportData
  });

  if (!feedbackData.id) {
    return value || getEmptyValue({ node });
  }

  return (
    <Link blue undecorated record onClick={onAddFeedbackClick}>
      {getFormattedDate({ date: convertUtcToLocalDate(value) })}
    </Link>
  );
}
