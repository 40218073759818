import React, { useCallback } from 'react';
import _ from 'lodash';

import { ICONS } from 'shared/components/icon';
import { EVENTS } from 'shared/utils/analytics';
import Analytics from 'shared/utils/vivid-analytics';

import DialogsBridge from 'data/classic-bridges/dialogs-classic';

import {
  RecordPreviewActionBarButton,
  SendMailMergeRecordData
} from '../record-preview-action-bar';

// TODO: Remove this and switch over to the new useSendMessage hook
// https://app.shortcut.com/rexlabs/story/63410/migrate-previewpopout-email-actions-to-use-new-hook
export function useSendEmail({ record }: { record?: SendMailMergeRecordData }) {
  return useCallback(() => {
    if (record) {
      DialogsBridge?.editMailMerge?.openClassic({
        data: {
          mode: record.service,
          medium: 'email',
          data: {
            [record.service]: { id: record.id, name: record.name }
          }
        },
        options: {
          title: `Email ${_.capitalize(record.service)}`
        },

        // TODO: Time to make a util/hook for this. We're repeating this code all over the place.
        // https://app.shortcut.com/rexlabs/story/63409/add-util-hook-to-perform-basic-analytics
        callback: () => {
          Analytics.track({
            event: EVENTS.PREVIEW_POPOUTS.SEND_EMAIL
          });
        }
      });
    }
  }, [record]);
}

export function RecordPreviewSendEmailAction({
  record
}: {
  record: SendMailMergeRecordData;
}) {
  const onSendEmailClick = useSendEmail({ record });
  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.REMINDER}
      onClick={onSendEmailClick}
    >
      Email
    </RecordPreviewActionBarButton>
  );
}
