import { cache as globalCache, css, cx, keyframes } from 'emotion';
import createEmotion from 'create-emotion';
import weakMemoize from '@emotion/weak-memoize';
import { insertStyles } from '@emotion/utils';
import { StyleSheet } from '@rexlabs/styling';

const createIframeEmotion = weakMemoize((container) =>
  createEmotion({ container })
);

let windows = [];
const allKeyframes = [];

// intercept new cache inserts and sync those new styles to the registered iframes
const oldInsert = globalCache.insert;
const cacheInsertObserver = (...args) => {
  const [selector] = args;
  const className = selector.replace(/^\./, '');
  const result = oldInsert(...args);
  syncStyles({ className });
  return result;
};
globalCache.insert = cacheInsertObserver;

function syncStyles({ className, window } = {}) {
  const classesToSync = className
    ? [className]
    : Object.keys(globalCache.registered);

  const windowsToSync = window ? [window] : windows;

  windowsToSync.forEach(({ window }) => {
    if (window?.document?.head) {
      const { cache } = createIframeEmotion(window?.document?.head);
      classesToSync.map((key) => {
        const styles = globalCache.registered[key];
        const name = key.replace(/^css-/, '');
        const serialised = {
          name: name,
          styles: styles
        };
        insertStyles(cache, serialised, false);
      });
    }
  });
}

// For more details please see shell/src/utils/styles.md
StyleSheet?.setCreateClass?.((styles) => {
  const shellClass = css(styles);
  syncStyles({ className: shellClass });
  return shellClass;
});

StyleSheet?.setKeyframes?.((frames) => {
  allKeyframes.push(frames);

  const shellKeyframes = keyframes(frames);
  windows.forEach(({ window }) => {
    if (window?.document?.head) {
      const { keyframes: iframeKeyframes } = createIframeEmotion(
        window?.document?.head
      );
      iframeKeyframes(frames);
    }
  });
  return shellKeyframes;
});

let winId = 0;

window.styleFix = {
  register: (win) => {
    const uniqueId = winId++;
    const { keyframes } = createIframeEmotion(win?.document?.head);
    allKeyframes.forEach((frames) => keyframes(frames));

    windows.push({ window: win, id: uniqueId });
    syncStyles({ window: win });
    return () => {
      // We have to filter by a unique ID because when navigating
      // using the back button we are unregistering the current window
      // twice in the teardown event, once in `componentWillReceiveProps`
      // and once again in `componentWillUnmount` (not sure on the order)
      // with one of the ungregistration calls happening last.
      // See `shell/src/view/classic-bridges/app-frame.js` for the event dispatch point

      // When just using the window identity to filter this array
      // the second unregistration call will clear the windows array
      // and make it so that new styles do not get applied

      // The problem is exacerbated because when creating the new
      // emotion instances for the new window (within the iframe)
      // we are using the current styles within the document head.
      // This still contains the old styles of components that
      // have been rendered previously, so emotion will create a
      // new className for styles that already exist so as not to
      // clash (I am assuming most of this, but the logic checks out)
      // which means that the old classNames are not applied to the
      // components that are rendered for a second time, meaning the
      // stylesheet for the iframe window needs to be updated with the
      // new styles

      // This has created a small memory leak as new classNames are
      // created, added to the shell document (and the `allStyles` array here)
      // as well as the current iframe window document, but never cleared
      windows = windows.filter(({ id }) => id !== uniqueId);
    };
  }
};

// if we omit legacyMergeClasses the stylesprovider should use cx, but for some reason it's not, so here we need
// to manually pass in cx.  I wonder if the cx instance used by the stylesprovider is in some way different to the
// instance we need.
export const legacyMergeClasses = cx;
