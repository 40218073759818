import React, { useMemo } from 'react';
import { DefaultCell, DefaultCellProps } from './default';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';
import { isFunction } from 'lodash';

interface SideNoteCellProps extends DefaultCellProps {
  note?: string | ((data: DefaultCellProps['data']) => string);
  blue?: boolean;
}

export function SideNoteCell({
  note,
  data,
  value,
  blue,
  ...rest
}: SideNoteCellProps) {
  const sideNoteValue = useMemo(
    () => (note ? (isFunction(note) ? note(data) : note) : null),
    [note, data]
  );
  return (
    <DefaultCell
      data={data}
      {...rest}
      value={
        <Box flexDirection='row' alignItems='center' spacing={5}>
          {value}
          {sideNoteValue && (
            <span
              style={{
                fontSize: 12,
                fontWeight: 600,
                whiteSpace: 'pre',
                paddingTop: 2,
                paddingLeft: 2,
                color: blue ? COLORS.PRIMARY.REX_BLUE : COLORS.GREY_DARK
              }}
            >
              {sideNoteValue}
            </span>
          )}
        </Box>
      }
    />
  );
}
