import React, { ComponentProps, useMemo } from 'react';
import dayjs from 'dayjs';

import { ICONS } from 'shared/components/icon';

import { LeadsResponse } from 'data/models/entities/leads';
import { Activity } from 'data/models/entities/activities-model';

import { useAddNote } from 'hooks/use-add-note';

import { EditNoteData } from 'utils/dialogs/mapping-classic-interfaces';

import { IconValueStatusButton } from 'components/button/icon-value-status-button';

import { getActivityStatus } from 'features/leads/utils/get-activity-status';

type ButtonProps = Pick<
  ComponentProps<typeof IconValueStatusButton>,
  'status' | 'helpText'
>;

interface AddNoteActionButtonProps extends ButtonProps {
  data: LeadsResponse;
  callback: () => any;
  activitiesList?: Activity[];
}

export function getNoteData(data: LeadsResponse): EditNoteData {
  const { contact, listing, property, project, project_stage } = data;

  const properties: { property: typeof property }[] = [];

  if (property) {
    properties.push({ property: property });
  }

  // NOTE: Notes are not linked to a listing - they are always stored on the property record.
  // What we're doing below is if - in the unlikely event - there is a property and a listing
  // that are at different locations, add both of them as related records in the note.
  const listingProperty = listing?.property;
  const isListingPropertySameAsProperty = listingProperty?.id === property?.id;

  if (listingProperty && !isListingPropertySameAsProperty) {
    properties.push({ property: listingProperty });
  }

  return {
    id: '',
    // TODO: Update editNote dialog so we can pass in related without a prefix
    // https://app.shortcut.com/rexlabs/story/63564/update-classic-edit-note-dialog-so-we-don-t-have-to-pass-in-prefix-on-related
    _related: {
      note_properties: properties,
      note_contacts: contact ? [{ contact }] : [],
      note_projects: project ? [{ project }] : [],
      note_project_stages: project_stage ? [{ project_stage }] : []
    },
    lead_id: data.id
  };
}

export function AddNoteActionButton({
  data,
  activitiesList,
  callback
}: AddNoteActionButtonProps) {
  const noteStatus = getActivityStatus('note_added', activitiesList);

  const noteData = useMemo(() => {
    return getNoteData(data);
  }, [data]);

  const onAddNoteClick = useAddNote({
    data: noteData,
    callback
  });

  return (
    <IconValueStatusButton
      iconType={ICONS.NOTE}
      onClick={onAddNoteClick}
      status={noteStatus && 'Added'}
      helpText={
        noteStatus &&
        `Note added ${dayjs(noteStatus.system_ctime * 1000).fromNow()} by ${
          noteStatus.system_created_user.name
        }`
      }
    >
      Add Note
    </IconValueStatusButton>
  );
}
