import { RecordListTable } from 'components/record-list-screen/table';
import React, { useMemo, useState } from 'react';
import { ListingDocument } from '../../types/common';
import { ColumnConfig, Selection } from 'components/record-list-screen/types';
import { StateView } from 'components/record-list-screen/state-view';
import { Body } from 'components/text/body';
import Box from '@rexlabs/box';
import {
  accessColumn,
  descriptionColumn,
  dateUploadedColumn,
  fileSizeColumn,
  typeColumn
} from 'features/listings/components/documents-table/basic-columns';
import { usePermissions } from 'hooks/use-permissions';
import { sortMailMergeListingDocuments } from 'features/listings/components/documents-table/helpers';
import PaddingBox from 'view/components/padding-box';
import Spinner from 'shared/components/spinner';

const columns: ColumnConfig<ListingDocument>[] = [
  descriptionColumn,
  { ...typeColumn, sortable: false },
  { ...accessColumn, sortable: false },
  { ...dateUploadedColumn, sortable: false },
  { ...fileSizeColumn, sortable: false }
];

interface MailMergeDocumentsTableProps {
  documents: ListingDocument[];
  onSelect?: (x: any) => void;
  isLoading: boolean;
}

const MailMergeDocumentsTable = ({
  documents,
  onSelect,
  isLoading
}: MailMergeDocumentsTableProps) => {
  const [selection, setSelection] = useState<Selection>({
    type: 'include',
    ids: []
  });
  const { check } = usePermissions();

  const sortedDocuments = useMemo(() => {
    return documents
      .filter((document) => !document._destroy)
      .sort(sortMailMergeListingDocuments);
  }, [documents]);

  const disabledSelections = useMemo(() => {
    const userCanSendAnyDocuments = check('documents.send_any');
    return userCanSendAnyDocuments
      ? []
      : sortedDocuments
          .filter((i) => i.exclude_from_mail_merge)
          .map((i) => i._id);
  }, [sortedDocuments]);

  return (
    <Box>
      <RecordListTable
        items={sortedDocuments}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        hasSelection={true}
        selection={selection}
        setSelection={(selection) => {
          onSelect?.(selection);
          setSelection(selection);
        }}
        disabledSelections={disabledSelections}
        isLoading={isLoading}
        LoadingView={() => (
          <PaddingBox>
            <Spinner dark classic small centered />
          </PaddingBox>
        )}
        EmptyView={() => (
          <StateView noPadding>
            <Body italic regular informative>
              No documents attached to the selected record
            </Body>
          </StateView>
        )}
        variant={'compact'}
        colorScheme={'light'}
        disableSelectAll={true}
      />
    </Box>
  );
};

export default MailMergeDocumentsTable;
