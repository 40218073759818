/*
|-------------------------------------------------------------------------------
| Shell Account Info
|-------------------------------------------------------------------------------
|
| All navigation events and interactions in Classic should be delegated to
| Shell's redux based Router, singling out the source of truth.
|
*/

import {
  replace,
  push,
  parseUrlToRoute,
  parseQueryString
} from '@rexlabs/whereabouts';

import { getUrlWithGivenViewpath } from 'utils/routing';
import externalPages from 'data/external-rex2-pages.json';

/**
 * Matches a uri to an inclusive collection of pages.
 * @param uri
 */
function isUriMatchingExternalPages(uri) {
  return externalPages.some((page) => uri.startsWith(page));
}

function redirectIfExternalPage(uri, cb) {
  /*
  |---------------------------------------------------------------------------
  | We should always hard-direct the page to external screens, to avoid the
  | shell interrupting the expected experience.
  |
  | If an external page should redirect back to the app, it will be rewritten
  | by nginx to then reload into the Shell.
  */
  if (isUriMatchingExternalPages(uri)) {
    window.location = uri;
  } else {
    const pathSearchAndHash = window.location.href.replace(
      window.location.origin,
      ''
    );
    if (uri.includes('#')) {
      const firstHashIndex = uri.indexOf('#');
      const uriPartsArray = [
        uri.slice(0, firstHashIndex),
        uri.slice(firstHashIndex + 1)
      ];
      // Checking if uri contains hashes after the hash
      if (uriPartsArray[1].includes('#')) {
        // replacing all other hashes with %23
        uriPartsArray[1] = uriPartsArray[1].replace(/(?!^)#/g, '%23');
        // Put uri back together
        uri = uriPartsArray[0] + '#' + uriPartsArray[1];
      }
    }
    const isNewUrl = pathSearchAndHash !== uri;
    if (isNewUrl) {
      cb(uri);
    }
  }
}

const NavigatorBridge = {
  replace(uri) {
    redirectIfExternalPage(uri, (uri) =>
      replace({ config: parseUrlToRoute(uri) })
    );
  },

  push(uri) {
    redirectIfExternalPage(uri, (uri) =>
      push({ config: parseUrlToRoute(uri) })
    );
  },

  pushViewpath(uri) {
    const hasViewpath = !!window.parent.location.href
      .toString()
      .match(/#.*viewpath=/);
    if (hasViewpath) {
      NavigatorBridge.push(getUrlWithGivenViewpath(uri));
    } else {
      // We never want to be in a state where we've loaded the stepthrough &
      // classic frames, have a viewpath in the url, and have the immediate last
      // history be a stepthrough route WITHOUT a viewpath... This means if we
      // press BACK the app will be through in a weird state where the classic
      // frame gets unloaded without any recourse.
      NavigatorBridge.replace(getUrlWithGivenViewpath(uri));
    }
  },

  popViewpath() {
    const { viewpath } = parseQueryString(location.hash.replace(/^#/, ''));
    replace({ config: parseUrlToRoute(viewpath.length > 0 ? viewpath : '/') });
  },

  /**
   * Forces the shell frame to reload with the new URI.
   * @param uri
   */
  redirect(uri) {
    window.parent.location = uri;
  },

  open(uri, target, options) {
    window.parent.open(uri, target, options);
  }
};

export default NavigatorBridge;
