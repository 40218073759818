import _ from 'lodash';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';
import { APP_MENU } from 'theme/legacy/components';
import Icon, { ICONS } from 'shared/components/icon';
import ImageWithError from 'view/components/image-with-error';
import { OPTION_TYPES } from 'view/components/navigation/app-search/utils';
import Option from './option';
import { getHazardly } from 'shared/utils/prefix-hell';

@styled(APP_MENU.RECORDS)
class ProjectOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles, option, isActive } = this.props;
    const { data: project, value, label } = option;
    const developerLogo = getHazardly(
      project,
      'developer_logo._thumbs.200x100._url'
    );
    const developer = _.get(project, 'developer_name', '');
    const ProjectIcon = (
      <Icon key='project-icon' type={ICONS.APP_SEARCH[OPTION_TYPES.PROJECT]} />
    );

    return (
      <Option
        isActive={isActive}
        label={label}
        IconBox={[
          developerLogo && (
            <ImageWithError
              key='image'
              src={developerLogo}
              width='45px'
              height='45px'
              ErrorProp={ProjectIcon}
            />
          ),
          !developerLogo && ProjectIcon
        ]}
        OptionMeta={[
          <span key='label' {...styles('name', 'ellipsis')}>
            {label}
          </span>,
          <span key='developer' {...styles('meta', 'ellipsis')}>
            {developer}
          </span>
        ]}
        OptionExtraMeta={[
          <span key='value' {...styles('meta', 'extra', 'light')}>
            #{value}
          </span>
        ]}
      />
    );
  }
}

export default ProjectOption;
