import React from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import { ICONS } from 'shared/components/icon';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { RecordPreviewActionBarButton } from '../record-preview-action-bar';

import notesModel, { NoteData } from 'data/models/entities/notes';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';

import { EditNoteData } from 'utils/dialogs/mapping-classic-interfaces';

interface RecordPreviewAddNoteActionProps {
  noteData: EditNoteData;
}

export function RecordPreviewAddNoteAction({
  noteData
}: RecordPreviewAddNoteActionProps) {
  const { createItem } = useModelActions(notesModel);

  // TODO: Update component to use new useAddNote Hook
  // https://app.shortcut.com/rexlabs/story/63442/migrate-popoutpreview-add-note-action-to-use-new-useaddnote-hook
  function onAddNoteClick() {
    DialogsBridge.editNote?.openClassic({
      data: noteData,
      options: {},
      callback: (noteData: NoteData, deferred) => {
        return (
          createItem({
            data: { note_type: noteData?.noteType, ...noteData }
          })
            .then(() => {
              Analytics.track({
                event: EVENTS.PREVIEW_POPOUTS.ADD_NOTE
              });
            })
            .then(deferred.resolve)
            // Error catching is handled from the note dialog
            .catch(deferred.reject)
        );
      }
    });
  }

  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.NOTE}
      onClick={onAddNoteClick}
    >
      Note
    </RecordPreviewActionBarButton>
  );
}
