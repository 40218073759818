import React, { ReactNode } from 'react';

import { DefaultCell, DefaultCellProps } from './default';

export interface IconCellProps extends DefaultCellProps {
  isVisible: (data: Record<string, any> | undefined) => boolean;
  Icon: ReactNode;
}

export function IconCell({ value, Icon, isVisible, ...props }: IconCellProps) {
  const { data } = props;
  return <DefaultCell value={isVisible(data) ? Icon : ''} {...props} />;
}
