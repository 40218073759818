import { useMemo, useCallback } from 'react';

// TODO: decide on conventions around where these hooks live and move them.
//  Currently there are hooks in /hooks, components/_experiment/record-list-screen/utils/, features/leads/hooks/, and
//  features/leads/screens/leads-list-screen/
//  https://app.shortcut.com/rexlabs/story/66997/react-list-rename-and-move-files
import { useGoToRelated } from 'hooks/use-goto-related-record';
import { useChangeLeadStatusBatchAction } from 'features/leads/hooks/use-change-lead-status-batch-action';
import { useListExportAction } from 'components/record-list-screen/utils/use-list-export-action';
import { useUpdateAssigneeBatchAction } from './use-update-assignee-batch-action';
import { useArchiveLeadsBatchAction } from './use-archive-batch-action';
import { usePermissions } from 'hooks/use-permissions';
import { useErrorDialog } from 'hooks/use-error-dialog';

const service_name = 'Leads';

export function useLeadsListActions() {
  const { check } = usePermissions();
  const errorDialog = useErrorDialog();

  const canExportLead = check('exporting.leads');
  const canArchiveLead = check('leads.archive');

  const exportList = useListExportAction({ service_name });
  const assigneeBatchAction = useUpdateAssigneeBatchAction({ service_name });
  const goToRelated = useGoToRelated({ service_name });
  const changeLeadStatus = useChangeLeadStatusBatchAction({ service_name });
  const archiveLeadsBatchAction = useArchiveLeadsBatchAction({ service_name });

  const showNoPermissionMessage = useCallback(
    () =>
      errorDialog.open({
        message: "You don't have rights to perform this action"
      }),
    [errorDialog]
  );

  return useMemo(
    () => [
      {
        label: 'Export',
        onClick: exportList,
        hasPermission: canExportLead
      },
      {
        label: 'Mass Actions',
        items: [
          {
            label: 'Change Lead Status',
            onClick: changeLeadStatus
          },
          {
            label: 'Assign User',
            onClick: assigneeBatchAction
          },
          {
            label: 'Archive',
            onClick: canArchiveLead
              ? archiveLeadsBatchAction
              : showNoPermissionMessage
          }
        ]
      },
      {
        label: 'Go to Related',
        onClick: goToRelated
      }
    ],
    [
      exportList,
      canExportLead,
      changeLeadStatus,
      assigneeBatchAction,
      canArchiveLead,
      archiveLeadsBatchAction,
      showNoPermissionMessage,
      goToRelated
    ]
  );
}
