import ApplicationsTable from 'features/tenancy-applications/components/applications-table';
import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from 'view/components/button';
import { ICONS } from 'shared/components/icon';
import { PADDINGS } from 'theme';
import { useDialog } from 'hooks/use-dialog';
import Box from '@rexlabs/box';
import { ListingStub } from 'data/models/entities/listings';
import { TenancyApplicationItem } from '../../data/types';
import { withModel } from '@rexlabs/model-generator';
import tenancyApplicationsModel, {
  TenancyApplicationsModel
} from '../../data/tenancy-applications';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { uniqueId } from 'lodash';
import { Selection } from 'components/record-list-screen/types';
import { useSendToListingOwner } from '../../hooks/use-send-to-listing-owner';
import dayjs from 'dayjs';
import { dateFormat } from 'features/tenancy-applications/data/formatter';
import { useApplicationPermissions } from 'features/tenancy-applications/hooks/use-application-permissions';

interface ApplicationsTabProps {
  listing: ListingStub;
  tenancyApplications: TenancyApplicationsModel;
}

function ApplicationsTab({
  listing,
  tenancyApplications
}: ApplicationsTabProps) {
  const { fetchList } = tenancyApplications;
  const errorDialog = useErrorDialog();
  const addEditApplication = useDialog('addEditApplication');
  const [applicationList, setApplicationList] = useState<
    TenancyApplicationItem[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState<Selection>({
    type: 'include',
    ids: []
  });
  const { getTenancyRights } = useApplicationPermissions();

  const selectedApplications = useMemo(() => {
    return applicationList.filter((application) =>
      selection.type === 'include'
        ? selection.ids.includes(application.id)
        : !selection.ids.includes(application.id)
    );
  }, [selection, applicationList]);

  const sendToListingOwner = useSendToListingOwner(
    listing,
    selectedApplications,
    () => {
      setApplicationList((prev) =>
        prev.map((prevItem) => {
          if (
            selectedApplications.find(
              (application) => prevItem.id === application.id
            )
          ) {
            return {
              ...prevItem,
              date_communicated: dayjs().format(dateFormat),
              application_status: { id: 'communicated', text: 'Communicated' }
            };
          }
          return prevItem;
        })
      );
      setSelection({
        type: 'include',
        ids: []
      });
    }
  );

  useEffect(() => {
    if (!listing._id) return;
    setIsLoading(true);
    fetchList({
      id: uniqueId(),
      args: {
        limit: 100,
        criteria: [{ name: 'application.listing_id', value: listing._id }]
      }
    })
      .then((res) => {
        setApplicationList(res.data.map((row) => row.item));
        setIsLoading(false);
      })
      .catch((error) => {
        errorDialog.open(error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing._id]);

  return (
    <Box pl={PADDINGS.L} pr={PADDINGS.L} pb={PADDINGS.M} pt={'15px'}>
      <Box alignItems={'center'} mb={10}>
        <h2 className={'h2'}>Applications</h2>
        {getTenancyRights().canCreate && (
          <IconButton
            circle
            red
            Icon={ICONS.ADD_MEDIUM_THICK}
            onClick={() =>
              addEditApplication.open({
                listing,
                onSuccessfulAdd: (application: TenancyApplicationItem) =>
                  setApplicationList((prev) => [...prev, application])
              })
            }
          >
            add application
          </IconButton>
        )}

        <IconButton
          circle
          red={selectedApplications.length > 0}
          Icon={ICONS.SEND_EMAIL}
          iconStyles={{ width: 13, height: 13 }}
          onClick={sendToListingOwner}
          isDisabled={selectedApplications.length === 0}
        >
          Send applications to listing owner
        </IconButton>
      </Box>
      {/* <ProgressBar percentComplete={50} />
      <Tag
        color={COLORS.PRIMARY.WHITE}
        backgroundColor={COLORS.DATA_VIS.GREAT}
        roundedEdges
        noMargin
      >
        In Progress
      </Tag>
      <Tag
        color={COLORS.PRIMARY.WHITE}
        backgroundColor={COLORS.PRIMARY.SAND_LIGHT}
        roundedEdges
        noMargin
      >
        Not Started
      </Tag> */}
      <ApplicationsTable
        applicationList={applicationList}
        setApplicationList={setApplicationList}
        isLoading={isLoading}
        selection={selection}
        setSelection={setSelection}
      />
    </Box>
  );
}

export default withModel(tenancyApplicationsModel)(ApplicationsTab);
