import React, { useRef, useState, useEffect } from 'react';
import { compose } from 'redux';
import { TextArea } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withDialog } from 'shared/hocs/with-dialog';
import { withModel } from '@rexlabs/model-generator';

import adminMergeTemplatesModel from 'data/models/custom/admin-merge-templates';

import { COLORS } from 'theme';

import { ICONS } from 'shared/components/icon';
import { IconButton } from 'view/components/button';
import TemplateField from 'view/dialogs/esign/template-field';
import MergeTags from 'view/dialogs/merge-tags';

const styles = StyleSheet({
  container: {
    backgroundColor: COLORS.STATES.IDLE
  },
  buttonContainer: {
    padding: '5px'
  }
});

function MergeTagTextArea(props) {
  const {
    styles: s,
    minHeight,
    mergeTagsDialog,
    mergeTagCategoryDialog,
    moduleId,
    categoryId,
    adminMergeTemplates,
    ...rest
  } = props;

  // If the user has never clicked inside the textarea, this will be false
  // As soon as they click in the textarea it will set to true
  // This way if they immediate add a merge tag it will append the tag,
  // but if they click inside the textarea place the cursor at the start of the window
  // it will prepend the tag

  // This is a semi-false positive if they click inside the textarea, then click out of it
  // before adding the merge tag
  // That is inline with the classic implementation
  // We're using state and following this behaviour because there doesn't appear to be
  // a reliable way to differentiate between an input never clicked, and one
  // that has the cursor at the start of the value
  // i.e. selectionStart and selectionEnd are 0 in both cases
  const [hasFocus, setHasFocus] = useState(false);
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);

  const containerRef = useRef();

  function insertMergeTag(newTag) {
    const { onChange, value } = props;

    const textArea =
      containerRef?.current?.getElementsByTagName('textarea')?.[0];
    const selectionStart = textArea?.selectionStart;
    const selectionEnd = textArea?.selectionEnd;

    // Unlikely that there will be no input here, but we probably don't want to continue
    if (!textArea) {
      return;
    }

    // If the user has not selected the textarea yet, we just append the merge tag
    // See above for context on the use of the hasFocus state
    if (!hasFocus) {
      onChange({ target: { value: `${value.trim()} ${newTag}` } });
      return;
    }

    const valueStart = value.slice(0, selectionStart);
    const valueEnd = value.slice(selectionEnd);

    // Trim removes whitespace from the ends of the string
    // Therefore the final result will be a single space before and after the new tag
    // Final trim ensures we don't have leading or trailing spaces
    // if either part of the value is empty
    const newValue = `${valueStart.trim()} ${newTag} ${valueEnd.trim()}`.trim();

    onChange({ target: { value: newValue } });
  }

  function handleMergeTagCategorySubmit(tagValue) {
    insertMergeTag(`{{${tagValue}}}`);
  }

  function handleMergeTagsSubmit(tagValue) {
    const newTag = tagValue?.mergeTag?.value
      ? `{{${tagValue?.mergeTag?.value}}}`
      : '';

    insertMergeTag(newTag);
  }

  function handleAddMergeTagClick() {
    // Decides which modal to open - if there is a categoryId or not
    // opens the correct one
    if (!categoryId) {
      mergeTagsDialog.open({
        moduleId,
        title: 'Insert Merge Tag',
        onSubmit: handleMergeTagsSubmit
      });

      return;
    }

    mergeTagCategoryDialog.open({
      category,
      options: tags,
      onSave: handleMergeTagCategorySubmit
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!categoryId) {
      return;
    }

    adminMergeTemplates
      .getMergeTagsForRecordType({
        recordType: moduleId
      })
      .then((response) => {
        const result = response?.data?.result || [];
        const tagCategory = result.find(
          (category) => categoryId === category.category_id
        );
        const tagOptions = tagCategory.tags.map((tag) => ({
          label: tag.description,
          value: tag.tag
        }));

        setCategory(tagCategory.category_description);
        setTags(tagOptions);
      });
  }, [categoryId, moduleId]);
  /* eslint-enable */

  return (
    <div {...s('container')} ref={containerRef}>
      <div {...s('buttonContainer')}>
        <IconButton
          onClick={handleAddMergeTagClick}
          Icon={ICONS.ADD_MEDIUM}
          color={COLORS.PRIMARY.GREY_DARK}
          circle
          dark
          hasControlledColor={false}
        >
          add merge tag
        </IconButton>
      </div>
      <TextArea
        {...rest}
        style={{ minHeight }}
        onFocus={() => setHasFocus(true)}
      />
    </div>
  );
}

export default compose(
  styled(styles),
  withDialog(TemplateField, { propName: 'mergeTagsDialog' }),
  withDialog(MergeTags, { propName: 'mergeTagCategoryDialog' }),
  withModel(adminMergeTemplatesModel)
)(MergeTagTextArea);
