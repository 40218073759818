import { useCallback } from 'react';

import { api } from 'shared/utils/api-client';
import { useDialog } from 'hooks/use-dialog';
import {
  useMassActionProgressDialog,
  useGetTotalForBulkActions
} from './mass-action-utils';
import { useShowAsyncExportResults } from './use-show-async-export-results';
import { useErrorDialog } from 'hooks/use-error-dialog';

export function useListExportAction({ service_name, bMailMerge = false }) {
  const exportRecordDialog = useDialog('exportRecord');
  const openProgressDialog = useMassActionProgressDialog();
  // TODO: consistent naming with Dialog suffix
  //  https://app.shortcut.com/rexlabs/story/66997/react-list-rename-and-move-files
  const openAsyncFileResultDialog = useShowAsyncExportResults();
  const getIdsForBulkActions = useGetTotalForBulkActions({ service_name });
  const errorDialog = useErrorDialog();

  return useCallback(
    ({ selection, selectionCriteria }) => {
      // TODO: Create an export model rather than direct API calls
      // https://app.shortcut.com/rexlabs/story/66999/react-list-create-an-export-model-with-modelgenerator
      return api
        .post('Export::describeExportOptions', { service_name })
        .then((response) => {
          const options = { export_options: response.data.result };

          const exportCallback = (
            export_format = 'default',
            features = [],
            contact_types = [],
            output = 'csv'
          ) => {
            const options = {
              export_format,
              contact_types,
              export_fields: !bMailMerge ? features : null
            };

            return api
              .post('Export::queueExport', {
                service_name,
                options,
                criteria: selectionCriteria,
                output_format: output
              })
              .then((response) => {
                const token = response?.data?.result;

                // TODO: move this into a useAsyncFileResult hook, make generic
                //  https://app.shortcut.com/rexlabs/story/66998/react-list-batch-actions-create-useasynctoken-and-useasyncfileresult-hooks
                return openProgressDialog({ token })
                  .then((result) => {
                    openAsyncFileResultDialog({
                      data: result,
                      humanName: service_name,
                      className: 'leads',
                      mappingFn: (record) => {
                        return record?.data?.body_snippet;
                      },
                      records: undefined
                    });
                  }, errorDialog.open)
                  .catch(errorDialog.open);
              });
          };

          exportRecordDialog?.open({
            ids: getIdsForBulkActions({
              selection,
              selectionCriteria
            }),
            options,
            criteria: selectionCriteria,
            callback: exportCallback
          });
        });
    },
    [
      bMailMerge,
      errorDialog.open,
      exportRecordDialog,
      getIdsForBulkActions,
      openAsyncFileResultDialog,
      openProgressDialog,
      service_name
    ]
  );
}
