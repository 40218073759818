import React from 'react';

import DefaultValue from 'view/components/input/select/defaults/value';

import { ICONS } from 'shared/components/icon';

const {
  SELECT: {
    VALUE: { POSTCODE: PostcodeIcon }
  }
} = ICONS;

function LocalityValue(props) {
  return <DefaultValue {...props} Icon={PostcodeIcon} />;
}

export default LocalityValue;
