import React from 'react';
import Box from '@rexlabs/box';
import Tag from 'view/components/tag';
import { COLORS, PADDINGS } from 'theme';

export function RecordPreviewGreyTag({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <Box pt={PADDINGS.XS}>
      <Tag
        roundedEdges
        backgroundColor={COLORS.PRIMARY.LIGHT_GREY}
        color={COLORS.PRIMARY.GREY_DARK}
      >
        {children}
      </Tag>
    </Box>
  );
}
