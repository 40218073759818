import React, { PureComponent } from 'react';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import OriginalFileUpload from 'view/components/input/file-upload';
import { autobind } from 'core-decorators';
import ActionMenu from 'view/components/action-menu/arrow-dropdown';
import _ from 'lodash';
import EditDocumentDialog from 'view/dialogs/documents/edit';
import { withDialog } from 'shared/hocs/with-dialog';
import withClassicDialog from 'view/containers/with-classic-dialog';
import shareUrlDialog from 'view/dialogs/documents/share-url';

const overrides = {
  Button: StyleSheet({
    container: {
      height: '28px !important',
      borderRadius: '0 !important'
    }
  }),

  ActionMenu: StyleSheet({
    menu: {
      overflowX: 'hidden'
    }
  })
};

@withDialog(EditDocumentDialog)
@withDialog(shareUrlDialog, { propName: 'shareUrl' })
@withClassicDialog('editMailMerge')
class FileActionMenu extends PureComponent {
  state = {
    actionMenu: []
  };

  componentDidMount() {
    const file = _.get(this.props, 'value') || _.get(this.props, 'selected.0');
    this.setState({ actionMenu: this.constructActionMenu(file) });
  }

  componentDidUpdate(prevProps) {
    const oldFile = _.get(prevProps, 'value') || _.get(prevProps, 'selected.0');
    const newfile =
      _.get(this.props, 'value') || _.get(this.props, 'selected.0');

    if (oldFile !== newfile) {
      this.setState({ actionMenu: this.constructActionMenu(newfile) });
    }
  }

  constructActionMenu(file) {
    return [
      {
        label: 'View',
        onClick: () => {
          if (file.url) {
            window.open(`https:${file.url}`, '_blank', 'noopener,noreferrer');
          }
        }
      },
      {
        label: 'Rename',
        onClick: () =>
          this.props.editDocumentDialog.open({
            file,
            callback: this.props.handleEdit
          })
      },
      {
        label: 'Send Link',
        onClick: () => {
          this.props.editMailMerge.open({
            data: {
              mode: 'contact',
              medium: 'email',
              data: {
                attachments: [{ label: file.description, link: file.url }],
                customSubject: file.description
              }
            },
            options: {
              title: 'Send File Link'
            }
          });
        }
      },

      {
        label: 'Get Share Link',
        onClick: () => {
          this.props.shareUrl.open({ url: `https:${file.url}` });
        }
      },
      { label: 'Delete', onClick: this.props.reset }
    ];
  }

  render() {
    return (
      <ActionMenu
        items={this.state.actionMenu}
        {...this.props}
        stopPropagation
      />
    );
  }
}

const ACCEPT_TYPES = [
  'application/pdf',
  'application/msword',
  'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/bmp',
  'image/tiff',
  'text/plain',
  'application/zip',
  'application/vnd.rar',
  'image/vnd.adobe.photoshop',
  'application/x-mspublisher',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-outlook',
  'application/rtf',
  'text/csv',
  'text/html',
  'application/vnd.oasis.opendocument.text'
];

const ACCEPT_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.jpeg',
  '.jpg',
  '.gif',
  '.png',
  '.bmp',
  '.tiff',
  '.txt',
  '.zip',
  '.rar',
  '.psd',
  '.pub',
  '.ppt',
  '.pptx',
  '.msg',
  '.rtf',
  '.csv',
  '.tif',
  '.html',
  '.htm',
  '.odt'
];

// NOTE: just wrapping it, because of the special value handling for custom fields!
@autobind
class FileUpload extends PureComponent {
  handleChange(e) {
    const { onChange } = this.props;
    const actualEvent = {
      ...e,
      target: {
        ...e.target,
        files:
          e.target.files && e.target.files.uri
            ? {
                ...e.target.files,
                // Just adding description to change event payload
                description:
                  e.target.files.description || // Don't overwrite desc if it already exists
                  _.get(e, 'target.files.url', '').split('/').reverse()[0]
              }
            : null
      }
    };
    onChange(actualEvent);
  }

  render() {
    return (
      <StylesProvider components={overrides} prioritiseParentStyles={false}>
        <OriginalFileUpload
          ActionMenu={FileActionMenu}
          acceptTypes={ACCEPT_TYPES}
          acceptExtensions={ACCEPT_EXTENSIONS}
          {...this.props}
          onChange={this.handleChange}
        />
      </StylesProvider>
    );
  }
}

export default FileUpload;
