import _ from 'lodash';
import { autobind as _autobind, decorate as _decorate } from 'core-decorators';

export const Autobind = _autobind;

export const Decorate = _decorate;

export const Memoize = (...args) => Decorate(_.memoize, ...args);

export const Debounce = (limit, options) => {
  let debounce;
  if (options && options.prebounce) {
    debounce = (fn) => {
      const debounced = _.debounce(fn, limit, options);
      return (...args) => {
        options.prebounce(...args);
        debounced(...args);
      };
    };
  } else {
    debounce = _.debounce;
  }
  return Decorate(debounce, limit, options);
};

export const DebounceInput = (limit = 200, options) =>
  Debounce(
    limit,
    _.defaults(options, {
      prebounce: (ev) => ev && ev.persist && ev.persist()
    })
  );
