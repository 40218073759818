import invariant from 'invariant';

function normalizer(postcodeSector) {
  if (postcodeSector.label && postcodeSector.value) {
    return postcodeSector;
  }

  const id = postcodeSector._id || postcodeSector.id;
  const name =
    postcodeSector._text ||
    postcodeSector.text ||
    postcodeSector._value ||
    postcodeSector.value;

  invariant(
    id && name,
    "Items must have either an '_id' or 'id' and '_text' or 'text'"
  );

  return {
    label: name,
    value: {
      id,
      name
    },
    type: 'postcodeSector'
  };
}

export function normalizerNoId(postcodeSector) {
  if (postcodeSector.label && postcodeSector.value) {
    return postcodeSector;
  }

  const id = postcodeSector._id || postcodeSector.id;
  const name =
    postcodeSector._text ||
    postcodeSector.text ||
    postcodeSector._value ||
    postcodeSector.value;

  return {
    label: name,
    value: {
      id: `${name}-postcodeSector`,
      originalId: id,
      name
    },
    type: 'postcodeSector'
  };
}

export default normalizer;
