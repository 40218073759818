import React, { useCallback } from 'react';
import _ from 'lodash';

import { ICONS } from 'shared/components/icon';
import { EVENTS } from 'shared/utils/analytics';
import Analytics from 'shared/utils/vivid-analytics';

import DialogsBridge from 'data/classic-bridges/dialogs-classic';

import {
  RecordPreviewActionBarButton,
  SendMailMergeRecordData
} from '../record-preview-action-bar';

export function useSendSMS({ record }: { record?: SendMailMergeRecordData }) {
  return useCallback(() => {
    if (record) {
      DialogsBridge?.editMailMerge?.openClassic({
        data: {
          mode: record.service,
          medium: 'sms',
          data: {
            [record.service]: { id: record.id, name: record.name }
          }
        },
        options: {
          title: `Send SMS | ${_.capitalize(record.service)}`
        },
        callback: () => {
          Analytics.track({
            event: EVENTS.PREVIEW_POPOUTS.SEND_SMS
          });
        }
      });
    }
  }, [record]);
}

export function RecordPreviewSendSMSAction({
  record
}: {
  record: SendMailMergeRecordData;
}) {
  const onSendSMSClick = useSendSMS({ record });
  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.REMINDER}
      onClick={onSendSMSClick}
    >
      SMS
    </RecordPreviewActionBarButton>
  );
}
