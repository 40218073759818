import { store } from 'store';

const NotificationBridge = {
  setRecordDetails: ({ recordId, serviceId }) => {
    store.dispatch({
      type: 'notification/UPDATE_RECORD_DETAILS',
      payload: { recordId, serviceId }
    });
  }
};

export default NotificationBridge;
