import { identity } from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { Id } from '@rexlabs/model-generator';
import { SystemProperties } from '../types/common';

export interface ConnectPayload {
  id?: Id;
  token?: string | null;
}

export interface Silo {
  id: string;
  name: string;
  display_name: string;
  region: {
    region: string;
    origin: string;
  };
}

export interface Connection extends SystemProperties {
  api_secret: string;
  app_type: string | null;
  public_key: null;
  id: string;
  name: string;
  silo: Silo;
  silo_id: string;
  status: 'not_configured' | 'feeding' | 'dormant';
  webhook_url: string | null;

  etag: string;
  is_consent_account: boolean | null;
  is_view_account: boolean | null;
  remote_data_source_id: string;
  svc_silo_id: string;
}

const defaultReducer = {
  initial: identity,
  success: identity,
  failure: identity
};

const actionCreators = {
  fetchConnections: {
    request: () =>
      api
        .get('SingleViewConnections::search')
        .then((response) => response.data.result),
    reduce: defaultReducer
  },
  verify: {
    request: ({ token }: ConnectPayload) =>
      api.post('SingleViewConnections::verify', {
        token
      }),
    reduce: defaultReducer
  },
  connect: {
    request: ({ token }: ConnectPayload) =>
      api.post('SingleViewConnections::connect', {
        token
      }),
    reduce: defaultReducer
  },
  disconnect: {
    request: ({ id }: ConnectPayload) =>
      api.post('SingleViewConnections::purge', {
        id
      }),
    reduce: defaultReducer
  },
  setAsConsent: {
    request: ({ id }: ConnectPayload) =>
      api.post('SingleViewConnections::setAsConsentAccount', {
        id
      }),
    reduce: defaultReducer
  }
};

const TYPE = 'singleViewConnection';

const singleViewConnectionModel = new Generator<
  Connection,
  typeof actionCreators
>(TYPE).createModel({
  actionCreators
});

export default singleViewConnectionModel;
