import { useMemo } from 'react';
import { Criteria } from 'types/criteria';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { usePermissions } from 'hooks/use-permissions';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  ProjectStageReportQuery,
  projectStageReportQueryDefaultVariables
} from './project-stage-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'project_stage_name',
  'construction_current_projection',
  'construction_actual_finished_date',
  'release_current_projection',
  'release_actual_finished_date',
  'title_current_projection',
  'title_actual_finished_date',
  'system_owner_user.name',
  'project-project_name',
  'project.type.text',
  'project.adr_locality',
  'project.adr_suburb_or_town',
  'project.adr_postcode',
  'project.developer_name'
];

export function useProjectStageModule() {
  const region = useRegion();
  const {
    projects: projectCustomFields,
    project_stages: projectStageCustomFields
  } = useCustomFields();

  const projectStageModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.project_stages,
      moduleLabel: 'Project Stages',
      exportPrivilege: 'exporting.project_stages',
      queryConfig: {
        graphQlQuery: ProjectStageReportQuery,
        queryVariables: projectStageReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'release_current_projection',
            direction: 'desc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Project Stage',
          children: makeEntity({
            entity: 'projectStage',
            prefix: '',
            customFields: projectStageCustomFields,
            region
          })
        },
        {
          headerName: 'Project',
          children: makeEntity({
            entity: 'project',
            prefix: 'project',
            subresource: 'project',
            customFields: projectCustomFields,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'system_owner_user_id',
      defaultDateFieldPreference: 'project_stage.release_current_projection',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'project_stage.construction_current_projection',
              label: 'Construction completion current projection'
            },

            {
              fieldName: 'project_stage.construction_actual_finished_date',
              label: 'Construction completion actual release'
            },

            {
              fieldName: 'project_stage.release_current_projection',
              label: 'Release date current projection',
              isDefault: true
            },

            {
              fieldName: 'project_stage.release_actual_finished_date',
              label: 'Release date actual release'
            },

            {
              fieldName: 'project_stage.title_current_projection',
              label: 'Title issue current projection'
            },

            {
              fieldName: 'project_stage.title_actual_finished_date',
              label: 'Title issue actual release'
            },
            { fieldName: 'system_ctime', label: 'Project stage creation' }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'system_owner_user_id',
              label: 'Record Owner',
              isDefault: true
            }
          ]
        }
      }
    }),
    [region, projectCustomFields, projectStageCustomFields]
  );
  return projectStageModuleConfig;
}
