import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import React, { PureComponent } from 'react';

import Dialog from 'view/components/dialog';
import { PADDINGS } from 'src/theme';

import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { TextInput } from '@rexlabs/text-input';
import { DialogBody } from 'components/text/dialog-body';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton } from 'view/components/button';

@withErrorDialog
@autobind
class ShareUrl extends PureComponent {
  render() {
    const {
      closeDialog,
      onLoad,

      url,
      title,
      instructions
    } = this.props;
    return (
      <Dialog
        title={title || 'File Share Link'}
        width={500}
        closeDialog={closeDialog}
        onLoad={onLoad}
        height={150}
      >
        <Box mb={PADDINGS.S}>
          <DialogBody>
            {instructions ||
              `Click in the field below and copy the link. When pasted in a browser
            the link will allow someone to download this file.`}
          </DialogBody>
        </Box>
        <TextInput label='file URL' value={url} />
        <ButtonBar>
          <DefaultButton dark onClick={closeDialog}>
            Done
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default ShareUrl;
