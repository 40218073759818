import React from 'react';
import { getDisplayName } from 'shared/utils/react';
import { useWhichWordFactory } from 'hooks/use-which-word';

const withWhichWord = (WrappedComponent) => {
  function WithWhichWord(props) {
    const ww = useWhichWordFactory();
    return <WrappedComponent {...props} ww={ww} />;
  }

  WithWhichWord.displayName = `withWhichWord(${getDisplayName(
    WrappedComponent
  )})`;
  return WithWhichWord;
};

export { withWhichWord };
