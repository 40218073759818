import React, { PureComponent } from 'react';
import { Hint } from 'components/text/hint';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton } from 'view/components/button';
import PaddingBox from 'view/components/padding-box';

class SubmissionComplete extends PureComponent {
  render() {
    const { closeDialog } = this.props;
    return (
      <PaddingBox>
        <Hint dark semibold normal>
          This contact record has been submitted
        </Hint>
        <ButtonBar>
          <DefaultButton light onClick={closeDialog}>
            Close
          </DefaultButton>
        </ButtonBar>
      </PaddingBox>
    );
  }
}

export default SubmissionComplete;
