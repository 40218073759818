import React, { useState } from 'react';
import Box from '@rexlabs/box';
import { DefaultCell, DefaultCellProps } from './default';
import { Select } from 'src/view/components/input/select';
import { SelectItem } from 'src/types/select';

interface SelectCellProps extends DefaultCellProps {
  onChange?: (args?: string) => void;
  options?: SelectItem[];
  groupOptions?: SelectItem[];
  value?: string | number;
}

export function SelectCell({
  onChange,
  groupOptions,
  options,
  value,
  width,
  ...rest
}: SelectCellProps) {
  const [selectValue, setSelectValue] = useState(value);

  const onSelectChange = (e) => {
    setSelectValue(e.target.value);
    onChange?.(e);
  };

  return (
    <DefaultCell
      width={width}
      value={
        <Box>
          <Select
            width={width}
            onChange={onSelectChange}
            value={selectValue}
            groupOptions={groupOptions}
            options={options}
            {...rest}
          />
        </Box>
      }
    />
  );
}
