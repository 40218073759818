import React from 'react';
import dayjs from 'dayjs';
import { compose } from 'redux';

import { ICONS } from 'shared/components/icon';

import { getModuleName } from 'data/module-names';
import { IconButton, TextButton } from 'view/components/button';
import withClassicDialog from 'view/containers/with-classic-dialog';
import { RecordDetailsWidget } from 'components/_experiment/record-details-screen/widget';
import { HeadingWithButtons } from 'components/_experiment/record-widget/heading';
import { Info } from 'components/_experiment/record-widget/info';

interface PermissionsWidgetProps {
  data?: {
    id?: string | number;
    system_owner_user?: {
      name?: string;
    };
    system_created_user?: {
      name?: string;
    };
    system_modified_user?: {
      name?: string;
    };
    system_ctime?: number;
    system_modtime?: number;
  };
  serviceName: string; // TODO: type with action service names enum

  recordPermissions?: any;
  auditLogs?: any;
}

const decorate = compose<any>(
  withClassicDialog('recordPermissions'),
  withClassicDialog('auditLogs')
);

export const PermissionsWidget = decorate(
  ({
    serviceName,
    data,
    recordPermissions,
    auditLogs
  }: PermissionsWidgetProps) => {
    return (
      <RecordDetailsWidget>
        <HeadingWithButtons
          Buttons={() => (
            <IconButton
              red
              circle
              Icon={ICONS.EDIT}
              // eslint-disable-next-line
              onClick={() =>
                recordPermissions.open({
                  recordId: data?.id,
                  recordType: getModuleName(serviceName)
                })
              }
            />
          )}
        >
          Permissions
        </HeadingWithButtons>

        <Info>You have rights to read and update this record.</Info>
        <Info>
          Record is owned by <b>{data?.system_owner_user?.name}</b>
        </Info>

        <Info grey>
          Created by <b>{data?.system_created_user?.name}</b> on
          {data?.system_ctime && (
            <> {dayjs(data.system_ctime * 1000).format('D MMM YYYY')}</>
          )}
        </Info>
        <Info grey>
          Last changed by <b>{data?.system_modified_user?.name}</b>
          {data?.system_modtime && (
            <> on {dayjs(data.system_modtime * 1000).format('D MMM YYYY')}</>
          )}
        </Info>

        <Info>
          <TextButton
            onClick={() => auditLogs.open({ id: data?.id, serviceName, data })}
          >
            view change log
          </TextButton>
        </Info>
      </RecordDetailsWidget>
    );
  }
);
