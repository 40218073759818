import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { IconButton } from 'view/components/button';
import { ICONS } from 'shared/components/icon';

const EditSvg: any = ICONS.EDIT;

const defaultStyles = StyleSheet({
  heading: {
    fontSize: '27px',
    fontWeight: 600
  }
});

export function Heading({ children, onEdit }) {
  const s = useStyles(defaultStyles);
  return (
    <Box flexDirection='row' alignItems='center' sx='8px'>
      <h1 {...s('heading')}>{children}</h1>
      {onEdit && (
        <IconButton data-hover circle red Icon={EditSvg} onClick={onEdit} />
      )}
    </Box>
  );
}
