import React, { PureComponent } from 'react';
import { DefaultButton } from 'view/components/button';
import { ICONS } from 'shared/components/icon';

const Arrow = ICONS.ARROW_DOWN;

class DropdownButton extends PureComponent {
  render() {
    return (
      <DefaultButton
        IconRight={(props) => (
          <Arrow {...props} style={{ transform: 'rotate(270deg)' }} />
        )}
        {...this.props}
      />
    );
  }
}

export default DropdownButton;
