import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const actionCreators = {
  fetchCampaignsForRecord: {
    request: async ({ recordType, recordId }) => {
      const members = await api.post('CampaignMembers::search', {
        criteria: [
          { name: 'service_type_id', type: '=', value: recordType },
          { name: 'service_record_id', type: '=', value: recordId }
        ]
      });
      return _.get(members, 'data.result.rows', []);
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  createBulkItems: {
    request: async (items) => {
      const requests = items.map((item) => ['CampaignMembers::create', item]);
      const bulkRequest = await api.batch(requests);

      return _.get(bulkRequest, 'data.result');
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const TYPE = 'campaignMembers';
export default new Generator(TYPE).createEntityModel({ actionCreators });
