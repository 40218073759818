import React from 'react';

import { ICONS } from 'shared/components/icon';

import { ReportingFilterButton } from './index';

type UserReportingFilterButtonProps = Omit<
  React.ComponentProps<typeof ReportingFilterButton>,
  'Icon'
>;

export function UserReportingFilterButton(
  props: UserReportingFilterButtonProps
) {
  return (
    <ReportingFilterButton Icon={ICONS.DASHBOARDS.USERS} showArrowIcon>
      {props.children}
    </ReportingFilterButton>
  );
}
