import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api, transformFormValuesToObjects } from 'shared/utils/api-client';

const TYPE = 'workflowInstances';

const actionCreators = {
  // Currently not working on the backend.
  // The reduce object may need changing but currently is just acting as a placeholder
  execute: {
    request: ({ id, formValues, context, recordId, recordType }) => {
      let requestData = {
        context,
        record_id: recordId,
        service_id: recordType
      };

      if (formValues) {
        requestData = {
          ...requestData,
          form_submission: transformFormValuesToObjects(formValues)
        };
      }

      return api.post('WorkflowInstances::executeWorkflow', {
        workflow_id: id,
        data: requestData
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  end: {
    request: ({ id, ids }) =>
      api.post('WorkflowInstances::terminateWorkflow', {
        execution_ids: ids || [id],
        cause: 'Manually terminated'
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getHistory: {
    request: ({ id }) => {
      return api
        .post('WorkflowInstances::getExecutionStatus', {
          execution_id: id
        })
        .then((response) => ({ data: response.data.result }));
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getDebuggerHistory: {
    request: ({ id }) => {
      return api
        .post('WorkflowInstances::getExecution', {
          execution_id: id,
          extra_fields: ['timeline']
        })
        .then((response) => ({ data: response.data.result }));
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  submitForm: {
    request: ({ id, formValues, context }) => {
      return api.post('WorkflowInstances::submitWorkflowForm', {
        context,
        execution_id: id,
        form_data: transformFormValuesToObjects(formValues)
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const workflowInstancesModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default workflowInstancesModel;
