import FeatureFlags from 'shared/utils/feature-flags';

// Run whenever imported just to be sure
export const flag = new FeatureFlags();
flag.checkFeatureFlags();

/**
 * @deprecated
 */
export const checkFeatureFlags = flag.checkFeatureFlags;
