import { useMemo } from 'react';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  CommissionByAgentReportQuery,
  commissionByAgentReportQueryDefaultVariables
} from './commission-by-agent-report.query';
import { ModuleConfigItem } from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';

import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'listing.contract.listing',
  'worksheet.id',
  'agent.name',
  'agent_role.text',
  'worksheet.comm_amount_net_of_tax',
  'cache_gross_comm_net_deductions',
  'gross_comm_alloc_percentage',
  'cache_gross_comm',
  'cache_final_comm',
  'contract.contract.date_actual_accepted',
  'contract.contract.date_actual_unconditional',
  'contract.contract.date_actual_settlement'
];

export function useCommissionByAgentModule() {
  const region = useRegion();
  const ww = useWhichWordFactory();
  const {
    listings: listingCustomFields,
    properties: propertyCustomFields,
    contracts: contractCustomFields
  } = useCustomFields();

  const commissionByAgentModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.commission_by_agent,
      moduleLabel: 'Commission by Agent Allocation',
      shortLabel: 'By Agent Allocation',
      exportPrivilege: 'exporting.commission_worksheets',
      queryConfig: {
        graphQlQuery: CommissionByAgentReportQuery,
        queryVariables: commissionByAgentReportQueryDefaultVariables
        // TODO: add default sort when available on BE
        //  Jira: https://rexsoftware.atlassian.net/browse/RADI-5980
        // defaultSort: [
        //   {
        //     name: 'contract.date_actual_settlement',
        //     direction: 'descending' as OrderByDirectionType
        //   }
        // ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing.contract.listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Commission by agent',
          children: makeEntity({
            entity: 'commissionByAgent',
            prefix: '',
            whichWord: ww
          })
        },
        {
          headerName: ww('contract'),
          children: makeEntity({
            entity: 'contract',
            prefix: 'contract.contract',
            subresource: 'contract',
            customFields: contractCustomFields,
            whichWord: ww,
            region
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property.contract.listing.property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultDateFieldPreference: 'contract.date_actual_settlement',
      defaultUserFieldPreference: 'agent_id',
      topLevelFilters: {
        user: {
          availableFields: [
            {
              fieldName: 'agent_id',
              label: 'Allocated agent'
            }
          ]
        },
        date: {
          availableFields: [
            {
              fieldName: 'contract.date_actual_accepted',
              label: `${ww('contract')} accepted`,
              isDefault: true
            },
            {
              fieldName: 'contract.date_actual_unconditional',
              label: `${ww('contract')} ${ww('unconditional').toLowerCase()}`
            },
            {
              fieldName: 'contract.date_actual_settlement',
              label: `${ww('contract')} ${ww('settled').toLowerCase()}`
            }
          ]
        },
        location: {
          availableFields: [
            { fieldName: 'contract.listing_location_id', label: 'Location' }
          ]
        }
      }
    }),
    [
      ww,
      region,
      listingCustomFields,
      propertyCustomFields,
      contractCustomFields
    ]
  );
  return commissionByAgentModuleConfig;
}
