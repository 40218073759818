import React, { ReactElement } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import BoldLabelText from 'shared/components/text/bold-label-text';
import { RegionCode } from 'src/hooks/use-region';

dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(dayOfYear);

interface DateRangesObject {
  last_7_days: DateRangesItem;
  last_30_days: DateRangesItem;
  last_90_days: DateRangesItem;
  next_30_days: DateRangesItem;
  this_month_to_date: DateRangesItem;
  this_quarter_to_date: DateRangesItem;
  previous_quarter: DateRangesItem;
  previous_month: DateRangesItem;
  this_year_to_date: DateRangesItem;
  this_financial_year_to_date: DateRangesItem;
  previous_financial_year: DateRangesItem;
  previous_year: DateRangesItem;
}

export type DateRangeSelectValue =
  | keyof DateRangesObject
  | 'none'
  | 'fixed_period';

export interface SelectItem {
  value: DateRangeSelectValue;
  label: string | ReactElement;
  labelText?: string; // plain text version of the label. Required if label is a ReactElement
}

export interface DateRangesItem {
  end: string;
  start: string;
}

const financialStartDayOfYearMap = {
  AUS: {
    // July 1st
    month: 6,
    date: 1
  },
  NZ: {
    // April 1st
    month: 3,
    date: 1
  },
  UK: {
    // Note that the Tax Year in UK is different from financial year -
    // Fin year starts on April 1st, tax year starts on April 6th
    // We're going with financial year since it applies to companies.
    month: 3,
    date: 1
  },
  FR: {
    // Jan 1st
    month: 0,
    date: 1
  },
  AE: {
    // Jan 1st
    month: 0,
    date: 1
  }
};

const radioButtonOptions: SelectItem[] = [
  {
    value: 'last_7_days',
    label: <span>Last 7 days</span>,
    labelText: 'Last 7 days'
  },
  {
    value: 'last_30_days',
    label: <span>Last 30 days</span>,
    labelText: 'Last 30 days'
  },
  {
    value: 'last_90_days',
    label: <span>Last 90 days</span>,
    labelText: 'Last 90 days'
  },
  {
    value: 'next_30_days',
    label: <span>Next 30 days</span>,
    labelText: 'Next 30 days'
  },
  {
    value: 'this_month_to_date',
    label: (
      <span>
        This <BoldLabelText>month</BoldLabelText> to date
      </span>
    ),
    labelText: 'This month to date'
  },
  {
    value: 'previous_month',
    label: (
      <span>
        Previous <BoldLabelText>month</BoldLabelText>
      </span>
    ),
    labelText: 'Previous month'
  },
  {
    value: 'this_quarter_to_date',
    label: (
      <span>
        This <BoldLabelText>quarter</BoldLabelText> to date
      </span>
    ),
    labelText: 'This quarter to date'
  },
  {
    value: 'previous_quarter',
    label: (
      <span>
        Previous <BoldLabelText>quarter</BoldLabelText>
      </span>
    ),
    labelText: 'Previous quarter'
  },
  {
    value: 'this_year_to_date',
    label: (
      <span>
        This <BoldLabelText>year</BoldLabelText> to date
      </span>
    ),
    labelText: 'This year to date'
  },
  {
    value: 'previous_year',
    label: (
      <span>
        Previous <BoldLabelText>year</BoldLabelText>
      </span>
    ),
    labelText: 'Previous year'
  },
  {
    value: 'this_financial_year_to_date',
    label: (
      <span>
        This <BoldLabelText>financial year</BoldLabelText> to date
      </span>
    ),
    labelText: 'This financial year to date'
  },
  {
    value: 'previous_financial_year',
    label: (
      <span>
        Previous <BoldLabelText>financial year</BoldLabelText>
      </span>
    ),
    labelText: 'Previous financial year'
  },
  {
    value: 'fixed_period',
    label: 'Fixed period',
    labelText: ''
  }
];

function getFixedPeriod({
  fixedTo,
  fixedFrom
}: {
  fixedTo?: string;
  fixedFrom?: string;
}) {
  const today = dayjs().format('YYYY-MM-DD');
  let filterToValue = fixedTo;
  let filterFromValue = fixedFrom;

  const isFromAfterTo = fixedTo && dayjs(fixedFrom).isAfter(fixedTo);
  const isFromSameOrLessThanTodayWithNoTo =
    dayjs(fixedFrom).isSameOrBefore(today) && !fixedTo;

  if (isFromAfterTo) {
    filterFromValue = fixedTo;
    filterToValue = fixedFrom;
  }

  if (isFromSameOrLessThanTodayWithNoTo) {
    filterFromValue = fixedFrom;
    filterToValue = today;
  }

  return {
    end: filterToValue,
    start: filterFromValue,
    comparison: {
      end: dayjs(filterToValue).subtract(1, 'year').format('YYYY-MM-DD'),
      start: dayjs(filterFromValue).subtract(1, 'year').format('YYYY-MM-DD')
    }
  };
}

function getFinancialStartDate(regionCode: RegionCode) {
  const financialStartDayOfYear = financialStartDayOfYearMap[regionCode];
  if (
    dayjs().isAfter(
      dayjs()
        .month(financialStartDayOfYear.month)
        .date(financialStartDayOfYear.date)
    )
  ) {
    return dayjs()
      .month(financialStartDayOfYear.month)
      .date(financialStartDayOfYear.date);
  }
  return dayjs()
    .subtract(1, 'year')
    .month(financialStartDayOfYear.month)
    .date(financialStartDayOfYear.date);
}

function getDateRanges(regionCode: RegionCode): DateRangesObject {
  const today = dayjs().format('YYYY-MM-DD');
  const financialYearStartObj: Dayjs = getFinancialStartDate(regionCode);

  return {
    this_quarter_to_date: {
      end: today,
      start: dayjs().startOf('quarter').format('YYYY-MM-DD')
    },
    previous_quarter: {
      end: dayjs().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
      start: dayjs()
        .startOf('quarter')
        .subtract(1, 'quarter')
        .format('YYYY-MM-DD')
    },
    last_7_days: {
      end: today,
      start: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    },
    last_30_days: {
      end: today,
      start: dayjs().subtract(30, 'day').format('YYYY-MM-DD')
    },
    last_90_days: {
      end: today,
      start: dayjs().subtract(90, 'day').format('YYYY-MM-DD')
    },
    next_30_days: {
      end: dayjs().add(30, 'day').format('YYYY-MM-DD'),
      start: today
    },
    this_month_to_date: {
      end: today,
      start: dayjs().startOf('month').format('YYYY-MM-DD')
    },
    previous_month: {
      end: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      start: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    },
    this_year_to_date: {
      end: today,
      start: dayjs().startOf('year').format('YYYY-MM-DD')
    },
    previous_year: {
      end: dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      start: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
    },
    this_financial_year_to_date: {
      end: today,
      start: financialYearStartObj.format('YYYY-MM-DD')
    },
    previous_financial_year: {
      end: financialYearStartObj.subtract(1, 'day').format('YYYY-MM-DD'),
      start: financialYearStartObj.subtract(1, 'year').format('YYYY-MM-DD')
    }
  };
}

export { getFixedPeriod, getDateRanges, radioButtonOptions };
