import { useCallback } from 'react';

import { useDialog } from 'hooks/use-dialog';

import { useReportState } from './use-report-state';
import { AddCustomReportProps } from '../dialogs/add-custom-report';

export function useAddCustomReportDialog() {
  const addCustomReport: {
    open: (props: AddCustomReportProps) => void;
  } = useDialog('addCustomReport');

  const { moduleName, reportDetailsToSave } = useReportState();

  const openAddCustomReportDialog = useCallback(() => {
    addCustomReport.open({
      moduleName,
      reportDetailsToSave
    });
  }, [addCustomReport, moduleName, reportDetailsToSave]);

  return { openAddCustomReportDialog };
}
