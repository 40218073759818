import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { parseRouteToUrl, replace, useWhereabouts } from '@rexlabs/whereabouts';
import { useDebounce } from 'hooks/use-debounce';
import { encodeParams } from 'features/custom-reporting/utils/url-state-persist';
import { UserSelectedReportState } from './use-user-selected-report-state';

function encodeHashQuery(reportUrlState: UserSelectedReportState) {
  const hashQueryParams = [] as string[];

  // NOTE: We had a problem with url sharing and using saved reports together,
  // where the ordering would get destroyed, as the incoming url would only have the
  // visible columns. We decided that the best solution for now is, if it's a saved
  // report, we only store the users and location in the url for sharing
  if (!reportUrlState?.reportId) {
    if (!isEmpty(reportUrlState?.selectedGridFilters)) {
      hashQueryParams.push(
        `gridFilters=${encodeParams(reportUrlState?.selectedGridFilters)}`
      );
    }

    if (!isEmpty(reportUrlState?.selectedGridColumns)) {
      hashQueryParams.push(
        `cols=${encodeParams(
          stripUnnecessaryColumnDefData(reportUrlState?.selectedGridColumns)
        )}`
      );
    }

    if (
      reportUrlState?.selectedUserFilter !== undefined &&
      !isEmpty(reportUrlState?.selectedUserFieldPreference)
    ) {
      hashQueryParams.push(
        `userField=${reportUrlState?.selectedUserFieldPreference}`
      );
    }
    hashQueryParams.push(
      `datePreset=${reportUrlState?.selectedDateFilter?.preset}`
    );
    if (!isEmpty(reportUrlState?.selectedDateFieldPreference)) {
      hashQueryParams.push(
        `dateField=${reportUrlState?.selectedDateFieldPreference}`
      );
    }
    if (reportUrlState?.selectedDateFilter?.preset === 'fixed_period') {
      hashQueryParams.push(
        `dateRange=${encodeParams(reportUrlState?.selectedDateFilter.range)}`
      );
    }
  }

  if (reportUrlState?.selectedUserFilter !== undefined) {
    hashQueryParams.push(
      `users=${encodeParams(reportUrlState?.selectedUserFilter)}`
    );
  }

  // TODO: location should only be persisted on reports that support it. Also location should just be a single value, not array
  if (reportUrlState?.selectedLocationFilter !== undefined) {
    hashQueryParams.push(
      `location=${encodeParams(reportUrlState?.selectedLocationFilter)}`
    );
  }

  if (reportUrlState?.selectedPivotMode) {
    hashQueryParams.push('pivot=true');
  }

  if (reportUrlState?.reportId) {
    hashQueryParams.push(`reportId=${reportUrlState.reportId}`);
  }

  return hashQueryParams.join('&');
}

// filter out the following default values as they don't need to be set
const keysWhereWeFilterOutTheDefaultValue = {
  width: 200
};

// the following keys only need to be preserved if a value is present. null/false/0 values don't need to be stored for these.
const keysWeOnlyNeedIfTruthy = [
  'aggFunc',
  'pinned',
  'pivot',
  'rowGroup',
  'sort'
];

// the following keys only need to be preserved if a value is present. false/0 values DO need to be stored for these
const keysWeOnlyNeedIfNotNull = [
  'rowGroupIndex',
  'pivotIndex',
  'sortIndex',
  'flex'
];

function stripUnnecessaryColumnDefData(
  gridColumns: UserSelectedReportState['selectedGridColumns']
) {
  return gridColumns
    ?.filter((gridColumn) => {
      return (
        (gridColumn.hide !== true && gridColumn.pivot !== true) ||
        gridColumn.colId === 'ag-Grid-AutoColumn'
      );
    })
    .map(({ hide, ...gridColumn }) =>
      Object.keys(gridColumn).reduce((accum, key) => {
        if (keysWeOnlyNeedIfTruthy.includes(key)) {
          return gridColumn[key]
            ? {
                ...accum,
                [key]: gridColumn[key]
              }
            : accum;
        } else if (keysWeOnlyNeedIfNotNull.includes(key)) {
          return gridColumn[key] !== null && gridColumn[key] !== undefined
            ? {
                ...accum,
                [key]: gridColumn[key]
              }
            : accum;
        } else if (keysWhereWeFilterOutTheDefaultValue[key]) {
          return gridColumn[key] !== null &&
            gridColumn[key] !== undefined &&
            gridColumn[key] !== keysWhereWeFilterOutTheDefaultValue[key]
            ? {
                ...accum,
                [key]: gridColumn[key]
              }
            : accum;
        } else {
          return {
            ...accum,
            [key]: gridColumn[key]
          };
        }
        return accum;
      }, {})
    );
}

// TODO: test this. https://rexsoftware.atlassian.net/browse/RADI-5413
export function useSaveReportStateToUrl({ userSelectedReportState }) {
  const { path } = useWhereabouts();

  const debouncedReportState = useDebounce(userSelectedReportState, 10);

  useEffect(() => {
    const hashQuery = encodeHashQuery(debouncedReportState);

    const newRoute = {
      path,
      hashQuery: {
        rest: '__HASHQUERY__'
      }
    };
    const newPath = parseRouteToUrl(newRoute);
    const replacedPath = newPath.replace('rest=__HASHQUERY__', hashQuery);
    replace({ config: { path: replacedPath } });
  }, [debouncedReportState]);
}
