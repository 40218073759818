import _ from 'lodash';

import { Id, ModelGeneratorLoaded } from '@rexlabs/model-generator';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

import { ContactStub } from 'data/models/entities/contacts';
import { ListingItem } from 'data/models/entities/listings';
import { LeadsPropertyStub } from 'data/models/entities/properties';
import { SystemOptionObj } from 'data/models/system-lists/types';
import { UserItem } from 'data/models/value-lists/account-users';
import { Activity } from 'data/models/entities/activities-model';
import { reduceItemResponseToState } from 'data/models/utils/reduce-item-response-to-state';

export interface LeadsResponse {
  system_account_reassigned_from_account: { id: Id; name: string } | null;
  assignee?: UserItem;
  body_snippet: string;
  contact: ContactStub | null;
  auto_created_feedback_id: Id | null;
  email_id: Id;
  etag: string;
  html_body: string;
  is_email_lead: string | null;
  is_spam: string | null;
  last_activity: Activity;
  lead_source: SystemOptionObj;
  lead_status: SystemOptionObj;
  lead_type: SystemOptionObj | null;
  listing: ListingItem | null;
  meta: {
    custom: {
      extractor_used: 'RexSoftwareLeadEmailParserLeadInfoDefaultExtractor';
    };
    dkim: string | null;
    headers: string | null;
    is_forwarded: boolean;
    sender_ip: string | null;
    spam_report: string | null;
    spf: string | null;
  };
  note: string | null;
  plain_body: string | null;
  // TODO: Projects need to be typed
  // https://app.shortcut.com/rexlabs/story/63148/add-typing-for-project-and-project-stage
  project: any;
  // TODO: Project Stages need to be
  // typed https://app.shortcut.com/rexlabs/story/63148/add-typing-for-project-and-project-stage
  project_stage: any;
  property: LeadsPropertyStub | null;
  received_from_email: string;
  spam_score: string;
  subject?: string;
  system_assigned_by_user: UserItem;
  system_assigned_time: string;
  system_completed_by_user: null;
  system_completed_time: null;
  system_created_user: UserItem;
  system_ctime: string | null;
  system_modified_user: UserItem;
  system_modtime: string | null;
  system_record_state: string;
  id: Id;
  security_user_rights: string[];
}

export interface UpdateAssigneePayload {
  id: Id;
  assignee: {
    id: Id;
  };
}
export interface LeadsModel extends ModelGeneratorLoaded<LeadsResponse, any> {
  updateAssignee: ({
    id,
    assignee
  }: UpdateAssigneePayload) => Promise<LeadsResponse>;
}

const actionCreators = {
  updateCompletionStatus: {
    request: (payload: { id: Id; is_completed: boolean }) =>
      api.post('Leads::toggleCompletionState', payload),
    reduce: {
      initial: _.identity,
      success: (state, action) => reduceItemResponseToState(state, action),
      failure: _.identity
    }
  },
  archive: {
    request: ({ id }) => api.post('Leads::archive', { id }),
    reduce: {
      initial: _.identity,
      success: (state, action) => reduceItemResponseToState(state, action),
      failure: _.identity
    }
  },
  unarchive: {
    request: ({ id }) => api.post('Leads::recoverFromArchive', { id }),
    reduce: {
      initial: _.identity,
      success: (state, action) => reduceItemResponseToState(state, action),
      failure: _.identity
    }
  }
};

const TYPE = 'leads';

const leadsModel = new Generator<LeadsResponse, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});

export default leadsModel;
