import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: '#b4b1a9'
  },

  containerTruncate: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    overflow: 'hidden'
  },

  textTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    width: '300px'
  },

  viewButton: {
    display: 'block',
    border: '0 none',
    text: 'inherit',
    fontSize: '12px',
    padding: 0,
    margin: '0 0 0 6px',
    color: COLORS.PRIMARY.BLUE,
    cursor: 'pointer',
    fontWeight: 600,
    background: 'transparent'
  }
});

interface ContentProps {
  truncate?: boolean;
  viewText?: ReactNode;
  onViewClick?: (e: MouseEvent) => void;
}

export function Content({
  children,
  truncate,
  viewText = 'view more',
  onViewClick,
  ...props
}) {
  const s = useStyles(defaultStyles);
  return (
    <Box {...s.with('container', { containerTruncate: truncate })(props)}>
      <span {...s('text', { textTruncate: truncate })}>{children}</span>
      {truncate && (
        <button onClick={onViewClick} {...s('viewButton')}>
          {viewText}
        </button>
      )}
    </Box>
  );
}
