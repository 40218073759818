import { formatAddress } from 'utils/formatters/addresses';

import { LeadsResponse } from 'data/models/entities/leads';
import {
  AppointmentRecordData,
  EventLocationData
} from 'hooks/use-edit-appointment';

import listingsModel from 'data/models/entities/listings';

export const getAppointmentData = (lead: LeadsResponse) => {
  const {
    contact,
    listing,
    property,
    lead_status,
    security_user_rights,
    system_created_user
  } = lead;

  const contactRecords: AppointmentRecordData[] = contact
    ? [
        {
          id: contact.id,
          label: contact.name,
          service: 'Contacts',
          stub: {
            name: contact.name,
            phone_number: contact.phone_number || undefined,
            email_address: contact.email_address || undefined,
            id: contact.id
          }
        }
      ]
    : [];

  const listingRecords: AppointmentRecordData[] = listing
    ? [
        {
          id: listing.id,
          label: listing.property.system_search_key,
          service: 'Listings',
          stub: {
            id: listing.id,
            address: listing.property.system_search_key,
            category: listing.listing_category,
            ...(listing.listing_primary_image &&
              listing.listing_primary_image.uri && {
                image: listing.listing_primary_image.uri
              }),
            status: lead_status.text,
            security_user_rights: security_user_rights,
            system_owner_user: system_created_user
          }
        }
      ]
    : [];

  const propertyRecods: AppointmentRecordData[] = property
    ? [
        {
          id: property.id,
          label: formatAddress(property),
          service: 'Properties',
          stub: {
            id: property.id,
            address: formatAddress(property),
            status: lead_status.text,
            security_user_rights: security_user_rights,
            system_owner_user: system_created_user
          }
        }
      ]
    : [];

  const records = [...contactRecords, ...listingRecords, ...propertyRecods];

  const listingEventLocation: EventLocationData | null = listing
    ? {
        data: listing,
        label: listing.property.system_search_key,
        model: listingsModel,
        value: listing.id
      }
    : null;

  const propertyEventLocation: EventLocationData | null = property
    ? {
        data: property,
        label: formatAddress(property),
        model: listingsModel,
        value: property.id
      }
    : null;

  const event_location = listingEventLocation || propertyEventLocation;

  return {
    records,
    ...(event_location && { event_location }),
    lead_id: lead.id
  };
};
