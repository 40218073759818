import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';
import Types from 'prop-types';
import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';

@styled(StyleSheet({ container: { backgroundColor: 'white' } }))
@autobind
class OptionEventHandler extends PureComponent {
  static propTypes = {
    /** Component to use for rendering, */
    Container: Types.any,
    /** The active index of the Option */
    index: Types.any,
    /** The data to display. */
    option: Types.object.isRequired,
    /** Whether this Option is actively hovered or reached by up/down. */
    isActive: Types.bool,
    /** Whether this Option is actively hovered or reached by up/down. */
    isPassive: Types.bool,
    /** Fires when the mouse/touch hovers over the Option: function (event) */
    onHover: Types.func,
    /** Fires when the Option is clicked or touched: function (event) */
    onSelect: Types.func,
    /** Helper util to call when this Option becomes active: function (element) */
    scrollIntoView: Types.func
  };

  static defaultProps = {
    Container: 'div',
    onHover: _.noop,
    onSelect: _.noop,
    scrollIntoView: _.noop
  };

  componentDidUpdate({ isActive: prevIsActive }) {
    const { isActive, scrollIntoView } = this.props;
    // When the option becomes active, we should scroll to it's position.
    if (isActive && prevIsActive !== isActive) {
      scrollIntoView(this.el);
    }
  }

  render() {
    const { Container, isPassive, styles: s, ...props } = this.props;
    return (
      <Container
        {...s('container')}
        {...props}
        ref={this.setRef}
        onMouseEnter={isPassive ? this.nullEvent : this.handleMouseEnter}
        onMouseMove={isPassive ? undefined : this.handleMouseMove}
        onMouseDown={isPassive ? this.nullEvent : this.handleMouseDown}
        onTouchStart={isPassive ? undefined : this.handleTouchStart}
        onTouchMove={isPassive ? undefined : this.handleTouchMove}
        onTouchEnd={isPassive ? this.nullEvent : this.handleTouchEnd}
      />
    );
  }

  setRef(ref) {
    const { isActive, scrollIntoView } = this.props;
    if (ref instanceof Element) {
      this.el = ref;
    }
    if (this.el && isActive) {
      // NOTE: Using Promise to take advantage of browser micro-task queue.
      Promise.resolve().then(() => scrollIntoView(this.el));
    }
  }

  handleSelect(event) {
    const { option, onSelect } = this.props;
    onSelect(option, event);
  }

  handleMouseHover(event) {
    const { index, option, onHover } = this.props;
    onHover(index, option, event);
  }

  handleMouseEnter(event) {
    this.handleMouseHover(event);
  }

  handleMouseMove(event) {
    this.handleMouseHover(event);
  }

  handleMouseDown(event) {
    this.handleSelect(event);
  }

  handleTouchStart() {
    // When a touch starts from inside an Option, we know the screen *is not*
    // being dragged; we should hand it's end event with an `onSelect`.
    this.isBeingDragged = false;
  }

  handleTouchMove() {
    // As soon as the user starts dragging there finger on an Option, we no
    // longer want to action it's end with an `onSelect`.
    this.isBeingDragged = true;
  }

  handleTouchEnd(event) {
    if (this.isBeingDragged) return;

    this.handleSelect(event);
  }

  nullEvent(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}

export default OptionEventHandler;
