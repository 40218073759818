import React, { KeyboardEventHandler, useRef, useState } from 'react';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { Popout } from 'view/components/popout/index';
import Box from '@rexlabs/box';
import {
  keyframes,
  StyleSheet,
  StylesProvider,
  useStyles
} from '@rexlabs/styling';
import { COLORS } from 'theme';

import { AiGradientButton } from 'features/ai/components/primitives/ai-gradient-button';
import { api } from 'shared/utils/api-client/index';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { AiTooltip } from 'features/ai/components/primitives/ai-tooltip';
import { useWhichWordFactory } from 'hooks/use-which-word';
import {
  contactsPrompts,
  listingsPrompts,
  propertiesPrompts
} from 'features/ai/components/generate-filter-popout/example-prompts';
import _ from 'lodash';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';
import classnames from 'classnames';
import { usePermissions } from 'hooks/use-permissions';
import { AiLogo } from 'features/ai/components/ai-logo';
import { Link } from 'components/text/link';

type AiFilterPopoutProps = {
  onFilter: (criteria: unknown[]) => void;
  service: 'Contacts' | 'Listings' | 'Properties';
};

type AiFilterPopoutContentProps = {
  closePopout: () => void;
  promptText: string;
  onFilter: AiFilterPopoutProps['onFilter'];
  setPromptText: (text: string) => void;
  setPreviousPromptText: (text: string) => void;
  service: AiFilterPopoutProps['service'];
};

const CLOSE_DURATION_SECONDS = 0.1;

const popoutOverrides = {
  Popout: StyleSheet({
    popout: {
      maxWidth: 'none',
      willChange: 'unset',
      backgroundColor: 'unset'
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      '&:before': {
        background: COLORS.WHITE
      }
    }
  })
};

const enterAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.97) translateY(-5px)'
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1) translateY(0px)'
  }
});

const exitAnimation = keyframes({
  '0%': {
    opacity: 1,
    transform: 'scale(1) translateY(0px)'
  },
  '100%': {
    opacity: 0,
    transform: 'scale(0.97) translateY(-5px)'
  }
});

const tetherStyles = StyleSheet({
  wrapContent: {
    backgroundColor: 'unset'
  },
  content: {
    overflow: 'hidden',
    background: 'rgba(195,207,227,0.6)',
    borderRadius: '6px',
    boxShadow:
      '0px 1.2px 29.92px 0px rgba(81, 160, 221, 0.2), 10px 10px 29px 0px rgba(255, 255, 255, 0.25) inset, 0 0 0 1px rgba(255, 255, 255, 0.45) inset, 0 0 0 1px rgba(0, 10, 40, 0.15)',
    backdropFilter: 'blur(5px) saturate(3) contrast(1.25)'
  },
  opening: {
    animation: `${enterAnimation} forwards normal 0.2s cubic-bezier(.24,.9,.27,1)`
  },
  closing: {
    animation: `${exitAnimation} forwards normal ${CLOSE_DURATION_SECONDS}s cubic-bezier(.24,.9,.27,1)`
  }
});

const styles = StyleSheet({
  topLevelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px'
  },
  container: {
    position: 'relative'
  },
  button: {
    height: '34px',
    width: '34px',
    marginLeft: '-5px',
    position: 'relative',
    zIndex: 2,
    borderLeft: '2px solid rgba(255, 255, 255, 0.2)',
    borderRight: 0,
    borderTop: 0,
    borderBottom: 0,
    cursor: 'pointer',
    webkitAppearance: 'none',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    background: COLORS.PRIMARY.BLUE,
    transition: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: '#0a8dc8'
    }
  },
  buttonLogoWrapper: {
    width: '18px',
    height: '18px',
    background: COLORS.WHITE,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    fontSize: '12px',
    lineHeight: '14px',
    padding: '7px 7px 7px 2px',
    color: COLORS.DARK_GREY,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '6px'
  },
  item: {
    backgroundColor: COLORS.WHITE,
    padding: '5px 10px',
    border: 0,
    fontSize: '14px',
    lineHeight: '17px',
    webkitAppearance: 'none',
    borderRadius: '4px',
    color: COLORS.DARK_GREY,
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(208, 231, 246, 1)'
    }
  },
  writingBox: {
    position: 'absolute',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    padding: '15px'
  },
  textarea: {
    resize: 'none',
    border: 'none !important',
    outline: 'none !important',
    padding: '10px',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.DARK_GREY,
    '&::placeholder': {
      color: COLORS.GREY_MEDIUM
    }
  },
  hasError: {
    boxShadow: `0 0 0 1px ${COLORS.STATUS.DANGER} inset !important`
  },
  error: {
    fontSize: '12px',
    marginTop: '3px',
    fontWeight: 600,
    lineHeight: '13px',
    color: COLORS.STATUS.DANGER
  }
});

export function AiFilterPopout({ onFilter, service }: AiFilterPopoutProps) {
  const [promptText, setPromptText] = useState('');
  const [previousPromptText, setPreviousPromptText] = useState('');
  const [isClosingAfterSuccess, setIsClosingAfterSuccess] = useState(false);
  const permissions = usePermissions();

  const s = useStyles(styles);

  function applyAiFilter(criteria: unknown[]) {
    setPreviousPromptText(promptText);
    onFilter(criteria);
  }

  if (
    !permissions.hasAddon('ai_features') ||
    !hasFeatureFlags('initial_ai_features')
  ) {
    return null;
  }

  function closeMainListPopout() {
    const classicFrame =
      document.querySelector<HTMLIFrameElement>('#AppFrame')?.contentDocument;
    classicFrame
      ?.querySelector<HTMLButtonElement>('.r2-popout2 .btn.cancel')
      ?.click?.();
  }

  function giveFeedback() {
    Analytics.track({
      event: EVENTS.AI_FILTERS.AI_FILTERS_FEEDBACK,
      properties: {
        lastPrompt: previousPromptText,
        service
      }
    });
  }

  return (
    <StylesProvider components={popoutOverrides}>
      <div {...s('topLevelContainer')}>
        <Popout
          placement={PLACEMENTS.BOTTOM}
          distance={'5px'}
          tetherStyles={tetherStyles}
          onChange={({ isOpen, event, isClosing }) => {
            if (isOpen) {
              setIsClosingAfterSuccess(false);
              Analytics.track({
                event: EVENTS.AI_FILTERS.AI_FILTERS_OPENED,
                properties: {
                  service,
                  source: event?.detail?.isFromPromoBanner
                    ? 'Promo Banner'
                    : 'List Button'
                }
              });
            } else if (isClosing && !isClosingAfterSuccess) {
              Analytics.track({
                event: EVENTS.AI_FILTERS.AI_FILTERS_CLOSED,
                properties: { service }
              });
            }
            setTimeout(() => {
              setPromptText('');
            }, CLOSE_DURATION_SECONDS * 1000);
          }}
          Content={({ close }) => (
            <AiFilterPopoutContent
              service={service}
              setPromptText={setPromptText}
              setPreviousPromptText={setPreviousPromptText}
              closePopout={() => {
                setIsClosingAfterSuccess(true);
                close();
              }}
              promptText={promptText}
              onFilter={applyAiFilter}
            />
          )}
        >
          <AiTooltip content={'Generate filter with AI'}>
            <button
              onClick={closeMainListPopout}
              className={classnames(s('button').className, 'ai__filter-button')}
            >
              <div {...s('buttonLogoWrapper')}>
                <AiLogo style={{ width: '12px', height: '12px' }} />
              </div>
            </button>{' '}
          </AiTooltip>
        </Popout>
        {previousPromptText ? (
          <Link blue undecorated small onClick={giveFeedback}>
            Give AI Filters Feedback
          </Link>
        ) : null}
      </div>
    </StylesProvider>
  );
}

function AiFilterPopoutContent({
  setPromptText,
  closePopout,
  promptText,
  service,
  setPreviousPromptText,
  onFilter
}: AiFilterPopoutContentProps) {
  const s = useStyles(styles);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ww = useWhichWordFactory();

  const promptExamples = {
    Contacts: contactsPrompts(ww),
    Listings: listingsPrompts(ww),
    Properties: propertiesPrompts(ww)
  }[service];

  async function onSubmit() {
    Analytics.track({
      event: EVENTS.AI_FILTERS.AI_FILTERS_GENERATED,
      properties: { service, prompt: promptText }
    });
    setIsLoading(true);
    setIsFailed(false);
    try {
      const {
        data: { result }
      } = await api.post('AiCompletions::completeFilters', {
        data: {
          filter_prompt: promptText,
          service
        }
      });
      if (result.length === 0) {
        Analytics.track({
          event: EVENTS.AI_FILTERS.AI_FILTERS_FAILED,
          properties: { service, prompt: promptText }
        });
        setPreviousPromptText(promptText);
        throw new Error();
      }
      onFilter(result);
      closePopout();
    } catch (error) {
      textAreaRef.current?.focus();
      setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  }

  return (
    <Box
      {...s('container')}
      width='325px'
      padding='10px'
      display={'flex'}
      flexDirection={'column'}
      alignItems={'stretch'}
    >
      <span {...s('header')}>
        <AiLogo style={{ width: '15px', height: '15px' }} />
        Use Rex AI to generate a filter
      </span>
      <Box display={'flex'} flex={1} flexDirection={'column'}>
        <textarea
          {...s('textarea', { hasError: isFailed })}
          ref={textAreaRef}
          autoFocus
          placeholder={`E.g. ${_.sample(promptExamples)}`}
          onKeyDown={
            handleKeyDown as unknown as KeyboardEventHandler<HTMLTextAreaElement>
          }
          onChange={(e) => {
            if (isFailed) {
              setIsFailed(false);
            }
            setPromptText(e.target?.value);
          }}
        >
          {promptText}
        </textarea>
        {isFailed ? (
          <span {...s('error')}>
            Unable to generate filter, try rephrasing your prompt
          </span>
        ) : null}
        <Box
          flexDirection={'row'}
          justifyContent={'flex-end'}
          marginTop={'10px'}
          style={{ gap: '10px' }}
        >
          <AiGradientButton
            disabled={isLoading || promptText.length === 0}
            withIcon
            loading={isLoading}
            onClick={() => {
              onSubmit();
            }}
          >
            Filter
          </AiGradientButton>
        </Box>
      </Box>
    </Box>
  );
}
