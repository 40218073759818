import React, { ComponentType, useMemo } from 'react';
import Button from '@rexlabs/button';
import {
  styled,
  StyleSheet,
  StylesProps,
  StylesProvider
} from '@rexlabs/styling';
import { BORDER_RADIUS, COLORS, FONT, PADDINGS } from 'src/theme';

export const DEFAULT_BUTTON_FONT_SIZE = FONT.SIZES.TAG;

const defaultStyles = StyleSheet({
  icon: {
    display: 'flex',
    flexShrink: 0,

    '& svg': {
      width: '22px',
      height: 'auto',

      '& *': {
        fill: 'currentColor !important'
      }
    }
  },

  iconLeft: {
    marginRight: '3px'
  },

  iconRight: {
    marginLeft: '3px'
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
});

export interface DefaultButtonProps {
  red?: boolean;
  blue?: boolean;
  dark?: boolean;
  light?: boolean;
  veryLight?: boolean;
  ghost?: boolean;
  children?: React.ReactNode;
  padding?: boolean;
  IconLeft?: ComponentType;
  IconRight?: ComponentType;
  large?: boolean;
  small?: boolean;
  tiny?: boolean;
  wide?: boolean;
  width?: string;
  autoWidth?: boolean;
  active?: boolean;
  form?: boolean;
  href?: string;
  isDisabled?: boolean;
  onClick?: Function;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  backgroundColor?: string;
  borderColor?: string;
  shouldDim?: boolean;
  left?: boolean;
  noRightBorderRadius?: boolean;
  noLeftBorderRadius?: boolean;
}

// @styled(defaultStyles)
function DefaultButton({
  IconLeft,
  IconRight,
  ...buttonProps
}: DefaultButtonProps & StylesProps<typeof defaultStyles>) {
  const {
    red,
    blue,
    dark,
    light,
    veryLight,
    ghost,
    children,
    large,
    small,
    tiny,
    wide,
    width,
    autoWidth = false,
    padding = true,
    active,
    form,
    href,
    isDisabled,
    backgroundColor,
    noLeftBorderRadius,
    noRightBorderRadius,
    styles: s
  } = buttonProps;

  const className = useMemo(() => {
    if (red) return 'btn-red';
    if (blue) return 'btn-blue';
    if (dark) return 'btn-dark';
    if (light) return 'btn-light';
    if (veryLight) return 'btn-very-light';
    if (ghost) return 'btn-ghost';
    return '';
  }, [blue, dark, light, red, veryLight, ghost]);

  const styles = useMemo(() => {
    return {
      Button: StyleSheet({
        container: {
          backgroundColor: backgroundColor || COLORS.PRIMARY.INFORMATIVE,
          border: '0 none',
          height: large ? '44px' : small ? '25px' : tiny ? '19px' : '34px',
          width: width,
          minWidth: wide
            ? '80px'
            : large
            ? '88px'
            : small
            ? '25px'
            : tiny
            ? '19px'
            : autoWidth
            ? 'auto'
            : '34px',
          lineHeight: '16px',
          fontSize: DEFAULT_BUTTON_FONT_SIZE,
          fontWeight: 600,
          borderRadius: BORDER_RADIUS.BUTTONS,
          transition: 'background 0.3s',
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: padding ? (IconLeft ? PADDINGS.XXS : PADDINGS.S) : 0,
          paddingRight: padding ? (IconRight ? PADDINGS.XXS : PADDINGS.S) : 0,
          position: 'relative',
          color: COLORS.WHITE,
          boxShadow: active
            ? '0 4px 5px 0 rgba(0, 0, 0, 0.3) inset'
            : undefined,
          ...(noRightBorderRadius
            ? {
                borderTopRightRadius: '0px !important',
                borderBottomRightRadius: '0px !important',
                marginRight: 0
              }
            : {}),
          ...(noLeftBorderRadius
            ? {
                borderTopLeftRadius: '0px !important',
                borderBottomLeftRadius: '0px !important',
                borderLeft: '1px solid rgba(255, 255, 255, .2) !important',
                marginLeft: 0
              }
            : {}),
          '&:hover': {
            backgroundColor: COLORS.PRIMARY.SAND_HOVER
          },
          '&:active': {
            boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.3) inset'
          },
          '&.btn-red': {
            backgroundColor: COLORS.STATES.RED,
            '&:hover': {
              backgroundColor: COLORS.STATES.RED_HOVER
            }
          },
          '&.btn-blue': {
            backgroundColor: COLORS.PRIMARY.BLUE,
            '&:hover': {
              backgroundColor: COLORS.STATES.BLUE_HOVER
            }
          },
          '&.btn-dark': {
            backgroundColor: '#424242',
            // These are the styles applied to all buttons in classic in the disabled state
            // /public/lib/r2/ui/widgets/buttons/buttons.css :736
            ...(isDisabled && dark
              ? {
                  background: COLORS.BORDERS.GREY,
                  color: COLORS.WHITE,
                  boxShadow: 'none'
                }
              : {}),
            '&:hover': {
              backgroundColor: '#363636'
            }
          },
          '&.btn-light': {
            backgroundColor: '#d4d3d0',
            color: COLORS.GREY_DARK,
            '&:hover': {
              backgroundColor: '#B4B1A9'
            }
          },
          '&.btn-very-light': {
            backgroundColor: '#EBEBE7',
            color: COLORS.GREY_DARK,
            '&:hover': {
              backgroundColor: COLORS.STATES.GREY
            }
          },
          '&.btn-ghost': {
            backgroundColor: 'transparent',
            color: COLORS.PRIMARY.SLATE,
            '&:hover': {
              color: COLORS.GREY_DARK
            }
          }
        },

        containerDisabled: {
          pointerEvents: 'none'
        },

        content: {
          transition: 'color 0.3s',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: COLORS.WHITE,

          '> span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },

          '.btn-light &': {
            color: COLORS.GREY_DARK
          },
          '.btn-very-light &': {
            color: COLORS.GREY_DARK
          }
        }
      })
    };
  }, [
    backgroundColor,
    large,
    small,
    tiny,
    width,
    wide,
    autoWidth,
    padding,
    IconLeft,
    IconRight,
    active,
    noRightBorderRadius,
    noLeftBorderRadius,
    isDisabled,
    dark
  ]);

  return (
    <StylesProvider components={styles} prioritiseParentStyles={false}>
      <Button
        type={href ? undefined : form ? 'submit' : 'button'}
        form={form}
        href={href}
        className={className}
        {...buttonProps}
      >
        {IconLeft && (
          <span {...s('icon', 'iconLeft')}>
            <IconLeft />
          </span>
        )}
        <span {...s('label')}>{children}</span>
        {IconRight && (
          <span {...s('icon', 'iconRight')}>
            <IconRight />
          </span>
        )}
      </Button>
    </StylesProvider>
  );
}

export default styled(defaultStyles)(DefaultButton);
