import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { Body } from 'components/text/body';
import { ReactForms, Form, FormField } from 'view/components/form';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { TextInput } from '@rexlabs/text-input';
import Checkbox from 'view/components/input/checkbox';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';

@styled(
  StyleSheet({
    info: {
      paddingTop: PADDINGS.M
    }
  })
)
class AddUserInput extends PureComponent {
  static defaultProps = {
    title: 'Add User Input',
    height: 300
  };

  getInitialValues() {
    const { userInput, protected: isProtected } = this.props;

    return { userInput: userInput, isProtected: isProtected };
  }

  render() {
    const {
      styles: s,
      title,
      closeDialog,
      onAdd,
      onLoad,
      height,
      width
    } = this.props;

    return (
      <Dialog
        title={title}
        width={width}
        closeDialog={closeDialog}
        onLoad={onLoad}
        height={height}
      >
        <ReactForms
          handleSubmit={onAdd}
          initialValues={this.getInitialValues()}
        >
          {({ submitForm }) => (
            <Form>
              <FormField
                name='userInput'
                label='user input'
                Input={TextInput}
              />
              <Body {...s('info')}>
                Text added to a protected field is hidden from the user. For
                security reasons, we recommend all passwords and sensitive data
                be a protected field.
              </Body>
              <FormField
                name='isProtected'
                Input={Checkbox}
                inputProps={{
                  label: 'user input is a protected field'
                }}
              />
              <ButtonBar>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton
                  dark
                  onClick={() => {
                    submitForm();
                    closeDialog();
                  }}
                >
                  Add
                </DefaultButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default AddUserInput;
