import React, { ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { query } from '@rexlabs/model-generator';
import listingsModel from 'data/models/entities/listings';
import { COLORS, TEXTS } from 'theme';

const getListingQuery = (id) => query`{
  ${listingsModel} (id: ${id}) {
    id
    listing_category
    property {
    system_search_key
      attr_bedrooms
      attr_bathrooms
      attr_carports
      attr_build_year
      attr_buildarea_m2
      price_match
    }
    related {
      property_features
      listing_subcategories
      listing_highlights
      listing_idealfors
      property_views
      listing_views
    }
  }
}`;

const styles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1px',
    overflow: 'hidden'
  },
  label: {
    ...TEXTS.FORM_FIELD,
    color: COLORS.DARK_GREY
  },
  value: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 400,
    textWrap: 'nowrap',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  isEmpty: {
    color: COLORS.STATES.RED
  }
});

type TextGenerationListingDetailsLabelValueProps = {
  label: string;
  value: string | ReactNode;
};

export function TextGenerationListingDetailsLabelValue({
  label,
  value
}: TextGenerationListingDetailsLabelValueProps) {
  const s = useStyles(styles);

  const isEmpty = value === null || value === '';

  return (
    <div {...s('container')}>
      <label {...s('label', { isEmpty })}>{label}</label>
      <p {...s('value', { isEmpty })}>{isEmpty ? 'No data' : value}</p>
    </div>
  );
}
