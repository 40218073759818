/* eslint-disable max-lines */
import React, { PureComponent, Fragment } from 'react';
import Dialog from 'view/components/dialog';
import { ReactForms, Form, FormField } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';
import {
  ValueListSelect,
  Select,
  DateSelect
} from 'view/components/input/select';
import { SaveCancelButtonBar } from 'view/components/button-bar';

import { TabsStatefulDeprecated } from 'view/components/tabs';
import { autobind } from 'core-decorators';
import { Grid, Column } from 'shared/components/grid';
import Box from '@rexlabs/box';
import { withQuery, query, withValueLists } from '@rexlabs/model-generator';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import _ from 'lodash';
import CampaignRecordStatusesList from 'view/components/lists/campaigns/record-statuses';
import { Body } from 'components/text/body';
import { arrayMove } from '@rexlabs/react-sortable-hoc';
import { withDialog } from 'shared/hocs/with-dialog';
import { createValidationRules } from 'shared/utils/form';

import ConfirmCampaignStatusUpdateDialog from 'view/dialogs/campaigns/confirm-status-update';

import campaignsModel from 'data/models/entities/campaigns';
import adminCampaignTemplatesModel from 'data/models/entities/admin-campaign-templates';
import campaignMembersModel from 'data/models/entities/campaign-members';
import campaignStatusModel from 'data/models/system-lists/campaign-status';

import campaignRecordBaseStatusModel from 'data/models/system-lists/campaign-record-base-status';

@withErrorDialog
@autobind
class EditCampaignDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statuses:
        _.get(props, 'campaigns.item.data.related.campaign_member_statuses') ||
        []
    };

    this.setValues = _.noop;
    this.lastTemplate = null;
  }

  handleSubmit(values) {
    const { campaigns, callback, closeDialog, errorDialog, id } = this.props;

    let relatedStatuses = this.state.statuses.map((status, index) => ({
      ...status,
      base_status_id: _.get(status, 'base_status.id'),
      priority: index + 1
    }));

    if (id) {
      const oldStatuses = _.get(
        campaigns,
        'item.data.related.campaign_member_statuses'
      );

      oldStatuses.forEach((status) => {
        if (!relatedStatuses.find((s) => s.id === status.id)) {
          // Status has been deleted
          relatedStatuses = [...relatedStatuses, { ...status, destroy: true }];
        }
      });

      // For the rest we want to send ALL statuses to ensure we send
      // priority updates as well!
    }

    const promise = id
      ? campaigns.updateItem({
          id,
          data: {
            id,
            name: values.name,
            status_id: values.status,
            start_date: values.start_date,
            end_date: values.end_date,
            related: {
              campaign_member_statuses: relatedStatuses
            }
          }
        })
      : campaigns
          .createFromTemplate({
            templateId: values.template,
            data: {
              id,
              name: values.name,
              status_id: values.status,
              start_date: values.start_date,
              end_date: values.end_date
            }
          })
          .then(({ data }) => {
            return campaigns.updateItem({
              id: _.get(data, 'id'),
              data: {
                id: _.get(data, 'id'),
                related: {
                  campaign_member_statuses: [
                    ...relatedStatuses,
                    ..._.get(data, 'related.campaign_member_statuses', []).map(
                      (s) => ({
                        ...s,
                        destroy: true
                      })
                    )
                  ]
                }
              }
            });
          });

    return promise
      .then(({ data }) => {
        if (callback) {
          callback(data);
        }
        closeDialog();
      })
      .catch(errorDialog.open);
  }

  addStatus(item) {
    this.setState((state) => ({
      statusEdited: true,
      statuses: [
        ...state.statuses,
        {
          id: item.id,
          label: item.label,
          priority: state.statuses.length,
          base_status: item.base_status
        }
      ]
    }));
  }

  editStatus(item) {
    this.setState((state) => ({
      statusEdited: true,
      statuses: state.statuses.map((status) => {
        // NOTE: this will delete temporary statuses by label atm, we might wanna
        // add temporary ids for created statuses to fix this behaviour
        if (
          (status.id && status.id === item.id) ||
          (!status.id && status.label === item.label)
        ) {
          return { ...status, ...item };
        } else {
          return status;
        }
      })
    }));
  }

  removeStatus(item) {
    const { campaignMembers, errorDialog } = this.props;

    const hasMembers = _.get(campaignMembers, 'list.items', []).find(
      (member) => member.status.id === item.id
    );

    if (hasMembers) {
      const error = new Error(
        'You cannot delete statuses that have records assigned to them.'
      );
      errorDialog.open(error);
      return;
    }

    this.setState((state) => ({
      // NOTE: this will delete temporary statuses by label atm, we might wanna
      // add temporary ids for created statuses to fix this behaviour
      statusEdited: true,
      statuses: state.statuses.filter((s) =>
        s.id ? s.id !== item.id : s.label !== item.label
      )
    }));
  }

  moveStatus({ oldIndex, newIndex }) {
    this.setState((state) => ({
      statusEdited: true,
      statuses: arrayMove(state.statuses, oldIndex, newIndex)
    }));
  }

  maybeLoadNewStatuses(selected) {
    const { confirmStatusChange } = this.props;

    if (this.state.statusEdited) {
      confirmStatusChange.open({
        onConfirm: () => this.loadNewStatuses(selected),
        onCancel: () => this.setValues({ template: this.lastTemplate })
      });
    } else {
      this.loadNewStatuses(selected);
    }
  }

  loadNewStatuses(selected) {
    const { adminCampaignTemplates, errorDialog } = this.props;

    const id = _.get(selected, 'data.id');
    this.lastTemplate = id;

    adminCampaignTemplates
      .fetchItem({ id })
      .then(({ data }) => {
        const statuses = _.get(
          data,
          'related.admin_campaign_template_member_statuses',
          []
        );
        this.setState({ statuses });
      })
      .catch(errorDialog.open);
  }

  renderDetailsTab() {
    const { id, adminCampaignTemplates } = this.props;
    return (
      <Fragment>
        <Grid mt={15} mb={10}>
          <Column width={6}>
            <FormField label='campaign name' name='name' Input={TextInput} />
          </Column>
          <Column width={6}>
            {id ? (
              <FormField
                label='campaign type'
                name='template'
                Input={TextInput}
                inputProps={{
                  disabled: true
                }}
              />
            ) : (
              <FormField
                label='campaign type'
                name='template'
                Input={Select}
                inputProps={{
                  options: _.get(adminCampaignTemplates, 'list.items', []).map(
                    (template) => ({
                      value: template.id,
                      label: template.name,
                      data: template
                    })
                  ),
                  onSelect: this.maybeLoadNewStatuses
                }}
              />
            )}
          </Column>
          <Column width={12}>
            <FormField
              label='campaign status'
              name='status'
              Input={ValueListSelect}
              inputProps={{
                models: [campaignStatusModel]
              }}
            />
          </Column>
          <Column width={6}>
            <FormField
              sendImmediate
              label='start date'
              name='start_date'
              Input={DateSelect}
            />
          </Column>
          <Column width={6}>
            <FormField
              sendImmediate
              label='end date'
              name='end_date'
              Input={DateSelect}
            />
          </Column>
        </Grid>
      </Fragment>
    );
  }

  renderRecordStatusesTab({ values }) {
    return (
      <Box mt={15}>
        <Body dark>
          Customise the record statuses used in this campaign. Changing the
          campaign status will lose any changes you made here.
        </Body>
        <Box mt={15}>
          {values.template ? (
            <CampaignRecordStatusesList
              items={this.state.statuses}
              onAdd={this.addStatus}
              onEdit={this.editStatus}
              onRemove={this.removeStatus}
              onSortItem={this.moveStatus}
            />
          ) : (
            <Body>Select a type first.</Body>
          )}
        </Box>
      </Box>
    );
  }

  render() {
    const { closeDialog, campaigns, id, prefillName } = this.props;
    const campaignData = _.get(campaigns, 'item.data');
    const initialValues = id
      ? {
          name: _.get(campaignData, 'name'),
          status: _.get(campaignData, 'status.id'),
          start_date: _.get(campaignData, 'start_date'),
          end_date: _.get(campaignData, 'end_date'),
          template:
            _.get(campaignData, 'system_created_from_template_name') ||
            _.get(campaignData, 'type.text')
        }
      : {};

    if (!id && prefillName) {
      initialValues.name = prefillName;
    }

    return (
      <ReactForms
        initialValues={initialValues}
        handleSubmit={this.handleSubmit}
        validate={createValidationRules({
          template: 'required',
          name: 'required',
          status: 'required'
        })}
      >
        {({ values, setValues, submitForm, isSubmitting }) => {
          this.setValues = setValues;
          return (
            <Form name='editCampaign'>
              <TabsStatefulDeprecated
                nested
                alwaysRenderAll
                values={values}
                items={[
                  {
                    name: 'key_details',
                    label: 'Key Details',
                    Tab: this.renderDetailsTab
                  },
                  {
                    name: 'record_statuses',
                    label: 'Record Statuses',
                    Tab: this.renderRecordStatusesTab
                  }
                ]}
              />
              <SaveCancelButtonBar
                isLoading={isSubmitting}
                onSave={submitForm}
                onCancel={closeDialog}
              />
            </Form>
          );
        }}
      </ReactForms>
    );
  }
}

const campaignQuery = query`{
  ${campaignsModel} (id: ${(p) => p.id}) {
    id
    name
    status
    type
    start_date
    end_date
    related
    system_created_from_template_name
  }
}`;

// We're also loading campaign members, so we can check before removing
// a status if it has any members assigned to it atm
function getCriteria(props) {
  return [{ name: 'campaign.id', type: 'in', value: [props.id] }];
}

const campaignMembersQuery = query`{
  ${campaignMembersModel} (criteria: ${getCriteria}, limit: 100) {
    id
    status
  }
}`;

const adminCampaignTemplatesQuery = query`{
  ${adminCampaignTemplatesModel} (limit: 100) {
    id
    name
    type
  }
}`;

@withDialog(ConfirmCampaignStatusUpdateDialog, {
  propName: 'confirmStatusChange'
})
@withValueLists(campaignRecordBaseStatusModel)
@withQuery(campaignMembersQuery)
@withQuery(campaignQuery)
@withQuery(adminCampaignTemplatesQuery)
class Core extends PureComponent {
  render() {
    const {
      id,
      campaigns,
      campaignMembers,
      onLoad,
      closeDialog,
      adminCampaignTemplates
    } = this.props;

    const isLoading = id
      ? _.get(campaigns, 'item.status') === 'loading' ||
        _.get(campaignMembers, 'list.status') === 'loading' ||
        _.get(adminCampaignTemplates, 'list.status') === 'loading'
      : false;

    return (
      <Dialog
        title={`${id ? 'Edit' : 'Add'} Campaign`}
        closeDialog={closeDialog}
        onLoad={onLoad}
        height={600}
        isLoading={isLoading}
      >
        <EditCampaignDialog {...this.props} />
      </Dialog>
    );
  }
}

export default Core;
