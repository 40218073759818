import { PADDINGS } from 'shared/theme';
import { SubHeading } from 'components/text/sub-heading';
import Box from '@rexlabs/box';
import React from 'react';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { StateView } from 'components/record-list-screen/state-view';
import Spinner from 'shared/components/spinner';
import { RecordListTable } from 'components/record-list-screen/table';
import { ColumnConfig } from 'components/record-list-screen/types';
import adminPipelineStages, {
  PipelineStageData
} from 'features/pipelines/data/admin-pipeline-stages';
import { query, useEntityListQuery } from '@rexlabs/model-generator';
import { RecordListActions } from 'components/record-list-screen/actions';
import { useDialog } from 'hooks/use-dialog';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import { isSystemPipelineStage } from 'features/pipelines/data/utils/date-grouping';
import { useErrorDialog } from 'hooks/use-error-dialog';

const pipelineStagesQuery = query`{
  ${adminPipelineStages} (order_by: ${{ percentage: 'asc' }}) {
    id
    name
    color
    percentage
    pipeline_type_id
  }
}`;

export default function PipelineStagesSettings() {
  const ww = useWhichWordFactory();
  const { data, status, actions } = useEntityListQuery(pipelineStagesQuery);
  const addEditPipelineStage = useDialog('addEditPipelineStage');
  const errorDialog = useErrorDialog();
  const confirmationDialog = useDialog('confirmation');

  const columns: ColumnConfig<PipelineStageData>[] = [
    {
      id: 'name',
      label: 'name',
      forced: true,
      cellProps: {
        items: (data: PipelineStageData) => {
          return !isSystemPipelineStage(data)
            ? [
                {
                  label: 'Delete',
                  onClick: () =>
                    confirmationDialog.open({
                      title: 'Delete Pipeline Stage',
                      message:
                        'Are you sure you want to delete this pipeline stage?',
                      onConfirm: () => actions.trashItem({ id: data.id })
                    })
                }
              ]
            : [];
        }
      }
    },
    {
      id: 'color',
      label: 'color',
      forced: true,

      Cell: (props: DefaultCellProps) => (
        <DefaultCell {...props}>
          <div
            style={{
              width: '48px',
              height: '24px',
              backgroundColor: props?.data?.color
            }}
          />
        </DefaultCell>
      )
    },
    {
      id: 'percentage',
      label: 'likelihood percentage',
      selector: (data: PipelineStageData) => data.percentage + '%',
      forced: true
    }
  ];

  return (
    <Box p={PADDINGS.M}>
      <Box mb={PADDINGS.S}>
        <SubHeading semibold>{ww('valuations')} Stages</SubHeading>
      </Box>
      <Box mb={PADDINGS.M}>
        <RecordListActions
          onAdd={() =>
            addEditPipelineStage.open({
              pipelineTypeId: 'appraisal',
              callback: () => actions.refreshLists({})
            })
          }
          selection={{ type: 'include', ids: [] }}
        />
      </Box>
      <RecordListTable
        selection={{ type: 'include', ids: [] }}
        setSelection={() => null}
        hasSelection={false}
        setOrderBy={() => null}
        isLoading={status === 'loading'}
        items={data}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        onItemClick={(item: PipelineStageData) =>
          item.system_purpose
            ? errorDialog.open(
                new Error(
                  'Special system stages such as "Archived" or "Won" cannot be edited.'
                )
              )
            : addEditPipelineStage.open({
                id: item.id,
                pipelineTypeId: 'appraisal'
              })
        }
        LoadingView={() => (
          <StateView noPadding>
            <Spinner small dark />
          </StateView>
        )}
        EmptyView={() => (
          <StateView noPadding>
            No {ww('valuation').toLowerCase()} pipeline stages
          </StateView>
        )}
        colorScheme={'light'}
      />
    </Box>
  );
}
