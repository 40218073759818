import ShellDialogsBridge from 'data/classic-bridges/dialogs-shell';
import ClassicDialogsBridge from 'data/classic-bridges/dialogs-classic';
import mappingsClassic from 'utils/dialogs/mapping-classic';
import mappingsShell from 'utils/dialogs/mapping-shell';

import invariant from 'invariant';
import { useMemo } from 'react';

// we merge shell second here so as soon as the dialog is available in shell it overrides the classic dialog.
export const DialogsBridge = {
  ...ClassicDialogsBridge,
  ...ShellDialogsBridge
} as unknown as Record<
  keyof typeof mappingsClassic | keyof typeof mappingsShell,
  any
>;

export function useDialog(dialogName: keyof typeof DialogsBridge) {
  invariant(
    DialogsBridge[dialogName],
    `Dialog ${dialogName} not present in either the classic or shell dialogsBridges`
  );

  return useMemo(
    () => ({
      open: (...args) => {
        return DialogsBridge[dialogName].open(...args);
      },
      close: (...args) => {
        return DialogsBridge[dialogName].close(...args);
      }
    }),
    [dialogName]
  );
}
