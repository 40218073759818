import _ from 'lodash';
import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';

const TYPE = 'userProfile';

const actionCreators = {
  getGlobalSettings: {
    request: () =>
      api
        .post('UserProfile::getGlobalSettings')
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  setGlobalSettings: {
    request: (payload) =>
      api
        .post('UserProfile::setGlobalSettings', payload)
        .then((response) => response.data.result),
    reduce: {
      intial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const userProfileModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default userProfileModel;
