import React from 'react';

import { RecordScreen } from 'components/_experiment/record-screen';

import { ProjectDetailsScreen } from './project-details-screen';
import { ProjectsListScreen } from './projects-list-screen';

export function ProjectsRecordScreen() {
  return (
    <RecordScreen
      RecordListScreen={ProjectsListScreen}
      RecordDetailsScreen={ProjectDetailsScreen}
    />
  );
}
