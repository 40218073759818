import React, { Component } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    height: '80px',
    display: 'flex',
    backgroundColor: 'white',
    padding: '0 20px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer'
  },
  userImage: {
    width: '50px',
    height: '50px',
    marginRight: '15px',
    borderRadius: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  userInitials: {
    fontWeight: '600',
    fontSize: '14px',
    color: 'white',
    backgroundColor: 'rgb(58, 166, 241)'
  },
  userDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  usersName: {
    color: '#424242',
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '22px'
  },
  usersRole: {
    color: '#A8AFB4',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '22px'
  },
  openChevron: {
    transform: 'rotate(-90deg)'
  }
});

@styled(defaultStyles)
class UserDetails extends Component {
  static propTypes = {};

  render() {
    const { styles: s, user, ...props } = this.props;
    const { name, role, profilePicture } = user;
    const names = name.split(' ');
    const initials = `${_.first(_.first(names))}${_.first(_.last(names))}`;

    return (
      <Box {...s('container')} {...props}>
        <Box
          {...s.with('userImage', { userInitials: !profilePicture })({
            backgroundImage: `url(${profilePicture})`
          })}
        >
          {!profilePicture && initials}
        </Box>
        <Box {...s('userDetails')}>
          <Box {...s('usersName')}>{name}</Box>
          <Box {...s('usersRole')}>{role}</Box>
        </Box>
        <Icon {...s('openChevron')} type={ICONS.CHEVRON} />
      </Box>
    );
  }
}

export default UserDetails;
