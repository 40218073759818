import React, { useCallback } from 'react';
import { compose } from 'redux';

import { Id, useModelActions } from '@rexlabs/model-generator';

import DialogsBridge from 'data/classic-bridges/dialogs-classic';
import feedbackModel from 'data/models/entities/feedback';

import { ICONS } from 'shared/components/icon';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import { FeedbackData } from 'utils/dialogs/mapping-classic-interfaces';

import { RecordPreviewActionBarButton } from '../record-preview-action-bar';

interface RecordPreviewAddFeedbackActionProps {
  feedbackData?: FeedbackData;
  listingId?: Id;
}

interface UseAddFeedbackArgs extends RecordPreviewAddFeedbackActionProps {
  errorDialog: ErrorDialog;
}

export function useAddFeedback({
  feedbackData,
  listingId,
  errorDialog
}: UseAddFeedbackArgs) {
  const { createItem } = useModelActions(feedbackModel);

  return useCallback(() => {
    if (feedbackData) {
      DialogsBridge?.editFeedback?.openClassic({
        data: feedbackData,
        options: {},
        listingId,
        mode: listingId ? 'listing' : 'contact',
        callback: (feedbackData) => {
          createItem({
            data: feedbackData
          })
            .then(() => {
              Analytics.track({
                event: EVENTS.PREVIEW_POPOUTS.ADD_FEEDBACK
              });
            })
            .catch((error: Error) => errorDialog.open(error));
        }
      });
    }
  }, [createItem, errorDialog, feedbackData, listingId]);
}

// TODO: Update to use new useFeedBack hook
// https://app.shortcut.com/rexlabs/story/63573/migrate-preview-popout-to-use-new-useaddfeedback-hook
export function RecordPreviewAddFeedbackActionComponent({
  feedbackData,
  listingId,
  errorDialog
}: RecordPreviewAddFeedbackActionProps & { errorDialog: ErrorDialog }) {
  const onAddFeedbackClick = useAddFeedback({
    feedbackData,
    listingId,
    errorDialog
  });
  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.REMINDER}
      onClick={onAddFeedbackClick}
    >
      Reminder
    </RecordPreviewActionBarButton>
  );
}

export const RecordPreviewAddFeedbackAction = compose<
  React.ComponentType<RecordPreviewAddFeedbackActionProps>
>(withErrorDialog)(RecordPreviewAddFeedbackActionComponent);
