import React from 'react';
import { RadioGroupInput as VividRadioGroupInput } from '@rexlabs/radio-input';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

const defaultOverrides = {
  Checkbox: StyleSheet({
    container: {
      paddingTop: 0,
      paddingBottom: 0,
      // HACK: this is to increase specificity
      '&&': {
        height: 'auto'
      }
    },

    label: {
      '&&': {
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '14px',
        width: '100%',
        textDecoration: 'none'
      }
    },

    input: {
      '&& ~ .styled-label': {
        margin: '6px 2px 6px 0',
        height: '17px',
        width: '17px',
        border: `2px solid ${COLORS.STATES.IDLE}`,

        '&:focus': {
          // NOTE: this is bad practise from a11y standpoint and only
          // here to match backwards compatibility of styles :/
          outline: 'none'
        },

        '&:before': {
          background: `${COLORS.DARK_GREY}`
        }
      },

      '&:checked ~ .styled-label': {
        borderColor: `${COLORS.STATES.IDLE} !important`
      },

      '&:hover ~ .styled-label': {
        borderColor: `${COLORS.STATES.HOVER} !important`
      }
    }
  })
};

export default function RadioGroupInput({ overrides, ...radioGroupProps }) {
  return (
    <StylesProvider
      components={overrides ?? defaultOverrides}
      prioritiseParentStyles={false}
    >
      <VividRadioGroupInput {...radioGroupProps} />
    </StylesProvider>
  );
}
