import React, { SyntheticEvent, useCallback, ReactNode } from 'react';
import Box from '@rexlabs/box';

import { RedirectButton } from './redirect-button';

export interface TextContentProps {
  redirectURL?: string | null;
  closeOverlay?: () => void;
  title: string;
  children?: ReactNode;
}

export function RedirectSuccessContent({
  redirectURL,
  closeOverlay,
  title,
  children
}: TextContentProps) {
  const handleNavigation = useCallback(
    (e: SyntheticEvent) => {
      // HACK: It's not entirely clear why this is necessary, but sometimes
      // the click events for this button seem to propagate in a way where
      // multiple click events fire - likely a Vivid bug
      e.stopPropagation();

      if (redirectURL) window.parent.open(redirectURL, '_blank');
      closeOverlay?.();
    },
    [closeOverlay, redirectURL]
  );

  return (
    <Box alignItems='center' flexDirection='column' width='100%'>
      {children}
      <RedirectButton onClick={handleNavigation}>Open {title}</RedirectButton>
    </Box>
  );
}
