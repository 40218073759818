import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { DefaultCell, DefaultCellProps } from './default';
import { isString, isNumber } from 'lodash';

export interface DateCellProps extends DefaultCellProps {
  format?: string;
}

export function DateCell({
  value,
  format = 'D MMM YYYY',
  ...props
}: DateCellProps) {
  const formattedValue = useMemo(() => {
    if (isNumber(value)) {
      return dayjs(value * 1000).format(format);
    }

    if (isString(value)) {
      return dayjs(parseInt(value) * 1000).format(format);
    }

    return null;
  }, [format, value]);

  return <DefaultCell value={formattedValue} {...props} />;
}
