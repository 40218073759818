import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';
import { AxiosResponse } from 'axios';

export interface AccountSettingsCommissionWorksheet {
  default_worksheet_version_id: number | string;
}

export interface AccountSettingsCommissionWorksheetModel {
  getEffectiveSettings: () => Promise<
    AxiosResponse<{ result: AccountSettingsCommissionWorksheet }>
  >;
  updateSettings: (payload: {
    settings: AccountSettingsCommissionWorksheet;
  }) => Promise<AxiosResponse<AccountSettingsCommissionWorksheet>>;
}

const TYPE = 'accountSettingsCommissionWorksheet';

const actionCreators = {
  getEffectiveSettings: {
    request: () =>
      api.post('AccountSettingsCommissionWorksheet::getEffectiveSettings'),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  updateSettings: {
    request: (payload) =>
      api.post('AccountSettingsCommissionWorksheet::updateSettings', payload),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const accountSettingsCommissionWorksheetModel = new Generator(
  TYPE
).createEntityModel({
  actionCreators
});

export default accountSettingsCommissionWorksheetModel;
