import React, {
  IframeHTMLAttributes,
  LegacyRef,
  ReactEventHandler,
  useEffect,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import { StateView } from 'components/record-list-screen/state-view';

interface IframeProps {
  ref: LegacyRef<HTMLIFrameElement> | undefined;
  onLoad?: ReactEventHandler<HTMLIFrameElement> | undefined;
}

const EmptyView = () => <StateView>No content available</StateView>;

export function Iframe({
  children,
  width = '100%',
  height = '100%',
  ...props
}: IframeHTMLAttributes<HTMLIFrameElement>) {
  const [iframeProps, setIframeProps] = useState<IframeProps | null>(null);
  const [mountNode, setMountNode] = useState<HTMLElement | undefined>(
    undefined
  );

  const [chromeContentRef, setChromeContentRef] =
    useState<HTMLIFrameElement | null>(null);

  const [firefoxContentRef, setFirefoxContentRef] =
    useState<HTMLIFrameElement | null>(null);

  // HACK: this is a very interesting solution
  // Shortcut story - https://app.shortcut.com/rexlabs/story/64566/iframes-clean-up-firefox-iframe-fixes

  // Essentially there's a bug in firefox which resets the iframe content after it receives the load event,
  // which results in a blank iframe. So for firefox, we need to set the iframe ref on or after the onload event.
  // HOWEVER there's an equivalent issue in chrome where chrome doesn't run onload events for iframes.

  // So here we're doing both the chrome way and the firefox way and we use whichever ref actually exists.
  useEffect(() => {
    setIframeProps({
      ref: setChromeContentRef,
      onLoad: (e) => setFirefoxContentRef(e.target as HTMLIFrameElement)
    });
  }, [children]);

  useEffect(() => {
    const chromeMountNode = chromeContentRef?.contentDocument?.body;
    const firefoxMountNode = firefoxContentRef?.contentDocument?.body;
    const mountNode = chromeMountNode || firefoxMountNode;

    setMountNode(mountNode);
  }, [chromeContentRef, firefoxContentRef]);

  if (!props?.src && !children) {
    return <EmptyView />;
  }

  return (
    <iframe
      width={width}
      height={height}
      frameBorder={0}
      {...props}
      {...iframeProps}
    >
      {mountNode && children && createPortal(children, mountNode)}
    </iframe>
  );
}
