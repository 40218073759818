import React from 'react';
import { RedirectLink } from './redirect-link-text';
import { RedirectParagraph } from './redirect-paragraph-text';

export function SupportLink() {
  return (
    <RedirectParagraph>
      Any problems?{' '}
      <RedirectLink href='mailto:support@rexsoftware.com.au'>
        Contact our Support team.
      </RedirectLink>
    </RedirectParagraph>
  );
}
