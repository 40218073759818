// Copied from Spoke
import _ from 'lodash';
import { defaultFilter } from '@rexlabs/select-input';

export const selectFilter = (options, props) => {
  const filteredOptions = defaultFilter(options, props).filter(
    (opt) => opt && !opt.isFixture
  );
  const fixtureOptions = options.filter((opt) => Boolean(opt && opt.isFixture));
  return filteredOptions.concat(fixtureOptions);
};

export const pluckValue = (option) => _.get(option, 'value', null);

export const filterOptionsByValue = (
  options,
  value,
  valueAsObject,
  alwaysShowAll,
  models,
  suggestive
) => {
  let allOptions = options;

  if (suggestive) {
    if (valueAsObject) {
      if (_.isArray(value)) {
        const addOptions = value
          .filter(
            (v) =>
              !options.find(
                (option) => _.get(option, 'value') === _.get(v, 'value')
              )
          )
          .map((v) => ({
            value: _.get(v, 'value'),
            label: _.get(v, 'value')
          }));
        allOptions = [...allOptions, ...addOptions];
      } else {
        if (
          !options.find(
            (option) => _.get(option, 'value') === _.get(value, 'value')
          )
        ) {
          allOptions = [
            ...allOptions,
            {
              value: _.get(value, 'value'),
              label: _.get(value, 'value')
            }
          ];
        }
      }
    } else {
      if (_.isArray(value)) {
        const addOptions = value
          .filter(
            (v) => !options.find((option) => _.get(option, 'value') === v)
          )
          .map((v) => ({
            value: v,
            label: v
          }));
        allOptions = [...allOptions, ...addOptions];
      } else {
        if (!options.find((option) => _.get(option, 'value') === value)) {
          allOptions = [
            ...allOptions,
            {
              value,
              label: value
            }
          ];
        }
      }
    }
  }

  if (alwaysShowAll) {
    return allOptions;
  }

  const filteredOptions = allOptions.filter((option) => {
    if (_.isArray(value)) {
      // Multiselect
      if (valueAsObject) {
        return value.find((v) => {
          // Compare value but also model name if available!
          // This is imporant, because entity models with multiple models
          // can potentially have duplicates of ids (=values) across them
          if (models && models.length > 1 && _.get(v, 'model')) {
            const suggestiveOption = _.get(option, 'model') === undefined;

            if (suggestiveOption) {
              return _.get(v, 'value') === _.get(option, 'value');
            }

            return (
              _.get(v, 'value') === _.get(option, 'value') &&
              _.get(v, 'model.modelName') === _.get(option, 'model.modelName')
            );
          }
          return _.get(v, 'value') === _.get(option, 'value');
        });
      } else {
        return value.find((v) => v === _.get(option, 'value'));
      }
    } else {
      // Single select
      if (valueAsObject) {
        return _.get(value, 'value') === _.get(option, 'value');
      }
      return value === _.get(option, 'value');
    }
  });
  return filteredOptions;
};

// TODO: add loading indicator when the icon is added to sketch files
