import React from 'react';
import { api } from 'shared/utils/api-client';
import ExternalLinkRedirect from 'view/components/external-link-redirect';
import { withModel } from '@rexlabs/model-generator';
import sessionModel, { SessionModel } from 'data/models/custom/session';

function BillingPortalRedirect({ session }: { session: SessionModel }) {
  return (
    <ExternalLinkRedirect
      fetchRedirectUrl={() =>
        api.post('AccountBilling::generateBillingPortalLink')
      }
      title={'Billing Portal'}
      hasPermission={session.checkUserHasPermission(
        'billing_and_subscriptions.manage_billing'
      )}
      showBackButton={false}
      missingPermissionMessage={
        'To use the billing portal you need the "Access Billing Centre" privilege. Contact your Rex administrator to get access.'
      }
    />
  );
}

export default withModel(sessionModel)(BillingPortalRedirect);
