import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { cloneDeep } from 'lodash';

import {
  query,
  useEntityListQuery,
  useModelActions
} from '@rexlabs/model-generator';

import savedFiltersModel, {
  SavedFilterItem
} from 'data/models/entities/saved-filters';

import { SavedList } from 'components/saved-list';

import { FilterItem } from './filter-item';
import { SavedFiltersPopoutProps } from '.';

const filtersBaseQuery = query`{
  ${savedFiltersModel} {
    id
    name
  }
}`;

export interface SavedFiltersPopoutContentProps
  extends SavedFiltersPopoutProps {
  close?: () => void;
}

export function SavedFiltersPopoutContent({
  close,
  serviceName,
  setCriteria,
  savedFilterDialog
}: SavedFiltersPopoutContentProps) {
  // NOTE: two separate states to be able to debounce the API search
  const [searchValue, setSearchValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // NOTE: We're using this to replicate the loading state in classic, when we change a
  // saved filters favourite status. We want to show the whole list as loading, but because
  // we first need to update the item in the list, there is a brief moment, where nothing
  // appears to be happening - because the lists status don't change to loading when updating
  // an item in the list.
  const [savedFilterIsLoading, setSavedFilterIsLoading] = useState(false);

  const { favourite, unfavourite } = useModelActions(savedFiltersModel);

  const timer = useRef<any>(null);
  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setSearchTerm(searchValue);
    }, 500);
  }, [searchValue]);

  // NOTE: to simplify the code a bit we construct all 3 queries from a single
  // base query and just change the arguments accordingly
  const [recentQuery, favouritesQuery, allQuery] = useMemo(() => {
    const baseCriteria = [
      { name: 'service_name', value: serviceName },
      searchTerm && { name: 'name', type: 'like', value: `%${searchTerm}%` }
    ].filter(Boolean);

    const recentQuery = cloneDeep(filtersBaseQuery);
    recentQuery.uuid = `${recentQuery.uuid}--recent`;
    recentQuery.args = {
      criteria: baseCriteria,
      order_by: { last_run_ts: 'DESC' },
      limit: 5
    };

    const favouritesQuery = cloneDeep(filtersBaseQuery);
    favouritesQuery.uuid = `${recentQuery.uuid}--favourites`;
    favouritesQuery.args = {
      criteria: [...baseCriteria, { name: 'favourite', value: true }],
      order_by: { name: 'ASC' },
      limit: 30
    };

    const allQuery = cloneDeep(filtersBaseQuery);
    allQuery.uuid = `${recentQuery.uuid}--all`;
    allQuery.args = {
      criteria: [...baseCriteria, { name: 'favourite', value: false }],
      order_by: { name: 'ASC' },
      limit: 30
    };

    return [recentQuery, favouritesQuery, allQuery];
  }, [serviceName, searchTerm]);

  const recent = useEntityListQuery(recentQuery);
  const favourites = useEntityListQuery(favouritesQuery);
  const all = useEntityListQuery(allQuery);

  const loadingStates = ['loading', 'refreshing'];

  // NOTE: We show the loading state if the lists are loading, or an item is
  // updating its favourite status.
  const isLoading =
    savedFilterIsLoading ||
    loadingStates.includes(recent.status) ||
    loadingStates.includes(favourites.status) ||
    loadingStates.includes(all.status);

  const isEmpty =
    !recent.data?.length && !favourites.data?.length && !all.data?.length;

  const onFavouriteChange = useCallback(
    (filter: SavedFilterItem) => {
      setSavedFilterIsLoading(true);

      const favouriteAction = filter.favourite ? unfavourite : favourite;

      return favouriteAction({ id: filter.id }).then(() =>
        Promise.all([
          favourites.actions.refreshList(),
          all.actions.refreshList()
        ]).then(() => setSavedFilterIsLoading(false))
      );
    },
    [all.actions, favourite, favourites.actions, unfavourite]
  );

  return (
    <SavedList w={590}>
      <SavedList.HeaderContainer>
        <SavedList.Heading>Saved Filters</SavedList.Heading>

        <SavedList.SearchBar
          handleSearch={setSearchValue}
          placeholder='search saved filters...'
          searchValue={searchValue}
        />
      </SavedList.HeaderContainer>

      {isLoading ? (
        <SavedList.LoadingState />
      ) : isEmpty ? (
        <SavedList.EmptyState>
          {searchTerm
            ? 'No saved filters match your search criteria'
            : 'No saved filters for the current list'}
        </SavedList.EmptyState>
      ) : (
        <>
          <SavedList.Group>
            <SavedList.GroupHeadingContainer>
              <SavedList.GroupHeading>Recent Filters</SavedList.GroupHeading>
            </SavedList.GroupHeadingContainer>
            <SavedList.GroupList>
              {recent.data?.filter(Boolean)?.map?.((filter: any) => (
                <FilterItem
                  key={filter.id}
                  serviceName={serviceName}
                  filter={filter}
                  setCriteria={setCriteria}
                  close={close}
                  savedFilterDialog={savedFilterDialog}
                  onFavouriteChange={onFavouriteChange}
                />
              ))}
            </SavedList.GroupList>
          </SavedList.Group>

          {!!favourites.data?.length && (
            <SavedList.Group>
              <SavedList.GroupHeadingContainer>
                <SavedList.GroupHeading>Favourites</SavedList.GroupHeading>
              </SavedList.GroupHeadingContainer>
              <SavedList.GroupList>
                {favourites.data.filter(Boolean).map((filter: any) => (
                  <FilterItem
                    key={filter.id}
                    serviceName={serviceName}
                    filter={filter}
                    setCriteria={setCriteria}
                    close={close}
                    savedFilterDialog={savedFilterDialog}
                    onFavouriteChange={onFavouriteChange}
                  />
                ))}
              </SavedList.GroupList>
            </SavedList.Group>
          )}
          <SavedList.Group>
            <SavedList.GroupHeadingContainer>
              <SavedList.GroupHeading>All Filters</SavedList.GroupHeading>
            </SavedList.GroupHeadingContainer>
            <SavedList.GroupList>
              {all.data?.filter(Boolean)?.map?.((filter: any) => (
                <FilterItem
                  key={filter.id}
                  serviceName={serviceName}
                  filter={filter}
                  setCriteria={setCriteria}
                  close={close}
                  savedFilterDialog={savedFilterDialog}
                  onFavouriteChange={onFavouriteChange}
                />
              ))}
            </SavedList.GroupList>
          </SavedList.Group>
        </>
      )}
    </SavedList>
  );
}
