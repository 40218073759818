import React, { Component } from 'react';
import {
  Select as SelectContainer,
  SelectInput,
  SelectMenu
} from '@rexlabs/select-input';
import { styled, StyleSheet } from '@rexlabs/styling';

@styled(
  StyleSheet({
    container: {
      position: 'relative'
    }
  })
)
class AppSearchDropdown extends Component {
  render() {
    const { selectContainerProps, selectInputProps, styles: s } = this.props;

    return (
      <SelectContainer {...s('container')} {...selectContainerProps}>
        <SelectInput
          {...s('input')}
          name='category_select'
          autoBlur
          shouldOpenOnFocus
          shouldCloseOnBlur
          shouldBackspaceRemove={false}
          shouldDeleteRemove={false}
          shouldTabSelectValue={false}
          shouldBlurResetInput={false}
          shouldCloseResetInput={false}
          isClearable={false}
          {...selectInputProps}
        />
        <SelectMenu {...s('menu')} />
      </SelectContainer>
    );
  }
}

export default AppSearchDropdown;
