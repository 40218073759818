import identityVerificationsModel from 'data/models/entities/contact-identity-verifications';
import { query } from '@rexlabs/model-generator';

export function getArgs(id) {
  const value = Array.isArray(id) ? [...id] : [id];
  return id
    ? [
        {
          name: 'contact_id',
          type: 'in',
          value
        }
      ]
    : [];
}

export const getVerificationsQuery = (getId) => {
  return query`{
    idVerifications:${identityVerificationsModel} (criteria: ${(props) =>
    getArgs(getId(props))}) {
      id
      date_of_birth
      company_number
      legal_name
      title
      first_name
      middle_name
      last_name
      adr_unit_number
      adr_street_number
      adr_street_name
      adr_postcode
      adr_town
      adr_locality
      custom_address
      verified_at
      verified_by_user
      is_international_address
      contact
      related {
        contact_identity_verification_contacts
        contact_identity_verification_documents
      }
    }
  }`;
};
