import React, {
  createContext,
  useMemo,
  useContext,
  useState,
  useEffect
} from 'react';
import flagsmith from 'flagsmith';
import { isObject } from 'lodash';
import WrapperLayout from '../layouts/wrapper';

/**
 * This implementation of the Flagsmith is a bit of a hack. And because our analytics
 * middleware is also a bit of a hack they're quite coupled together.
 *
 * Flagsmith do provide their own context provider, but at this stage we can't use it, so
 * in the meantime we're using this one. The reason why we need this is to block rendering
 * of the wrapped app until the flags have been fecthed. This prevents users from accessing
 * features that they don't have access to.
 */

/**
 * First we create a temporary callback variable. You'll see in the FlagsProvider
 * we have an effect that sets this variable to be a state setter.
 */
let onChangeCallback;

/**
 * This is used to initialise the Flagsmith SDK. The onChangeCallback is called when the
 * flags change - so straight after we identify the user, it returns the users flags
 */
export function initFlags(flagsmithId) {
  return new Promise<void>((resolve, reject) => {
    if (!flagsmithId || window.flagsmith?.initialised) {
      resolve();
    }

    flagsmith.init({
      environmentID: flagsmithId,
      onChange: (prevFlags, params) => {
        if (window.flagsmith.identity) {
          onChangeCallback?.(window.flagsmith.flags, prevFlags, params);
        }
        resolve?.();
      },
      onError: (error) => {
        // If we get an error, we still want to resolve the promise, so that the app
        // can render. We will set the flags to be an empty object.
        onChangeCallback?.({}, {}, {});
        reject?.(error);
      }
    });
  });
}

export type FlagsContextType = {
  // Initially the flags will be undefined so we don't render the app.
  flags:
    | {
        [flagName: string]: {
          enabled: boolean;
          id: number;
          value: string | null;
        };
      }
    | undefined;
};

export const FlagsContext = createContext<FlagsContextType>({
  flags: undefined
});

export function FlagsProvider({ children }: { children: React.ReactNode }) {
  const [flags, setFlags] = useState<FlagsContextType['flags']>(undefined);

  // We have a fallback here in the case we don't initialise Flagsmith.
  // For example, public actions in Rex don't init shell app, but require the
  // rex app to generate the routes to those actions. The reason for the
  // setTimeout, rather than just checking the path for '/actions/', is to
  // make the sure we catch any other cases where we don't init flagsmith in
  // other apps.
  useEffect(() => {
    if (window.location.href.match(/\/actions\//)) {
      setFlags({});
    }

    setTimeout(() => {
      if (!flagsmith.initialised && !isObject(flags)) {
        setFlags({});
      }
    }, 3000);
  }, []);

  useEffect(() => {
    // As mentioned above, we set the onChangeCallback to be the state setter. Once we
    // get back the users flags, it updates the flags in the state, and we can render the app.
    onChangeCallback = (newFlags) => {
      setFlags((flags) => ({ ...(isObject(flags) ? flags : {}), ...newFlags }));
    };
  }, []);

  const value = useMemo(() => ({ flags }), [flags]);

  return (
    <FlagsContext.Provider value={value}>
      <WrapperLayout
        loadingMessage={!isObject(flags) ? 'Authenticating user' : null}
      >
        {isObject(flags) && children}
      </WrapperLayout>
    </FlagsContext.Provider>
  );
}

export function useFlags(): any {
  return useContext(FlagsContext);
}
