import React, { PureComponent, Suspense } from 'react';
import OverlaysBridge from 'data/classic-bridges/overlays';

class ShellOverlayFrame extends PureComponent {
  render() {
    const { OverlayComponent, callName, lazy } = this.props.overlayConfig;

    if (lazy === undefined || lazy) {
      return (
        <Suspense fallback={null}>
          <OverlayComponent
            overlayConfig={this.props.overlayConfig}
            closeOverlay={() => OverlaysBridge[callName].close(callName)}
            {...this.props}
          />
        </Suspense>
      );
    }

    return (
      <OverlayComponent
        overlayConfig={this.props.overlayConfig}
        closeOverlay={() => OverlaysBridge[callName].close(callName)}
        {...this.props}
      />
    );
  }
}

export default ShellOverlayFrame;
