import React from 'react';
import { TextInput, TextArea } from '@rexlabs/text-input';

import { Heading } from 'components/_experiment/record-block/heading';
import { Grid, Column } from 'components/_experiment/record-block/grid';
import { Value } from 'components/_experiment/value';
import { FormField } from 'components/_experiment/record-block/form-field';

export const keyDetailsBlock = {
  id: 'key_details',
  View: ({ values }) => (
    <>
      <Heading>Key Details</Heading>
      <Grid columns={2}>
        <Column width={1}>
          <Value label='developer name' value={values?.developer_name} />
        </Column>
        <Column width={2}>
          <Value
            label='price list disclaimer'
            value={values?.price_list_disclaimer}
            vertical
          />
        </Column>
        <Column width={2}>
          <Value label='terms of sale' value={values?.terms_of_sale} vertical />
        </Column>
      </Grid>
    </>
  ),
  Edit: () => (
    <>
      <Heading>Key Details</Heading>
      <Grid columns={2}>
        <Column width={1}>
          <FormField
            name='developer_name'
            label='developer name'
            Input={TextInput}
          />
        </Column>
        <Column width={2}>
          <FormField
            name='price_list_disclaimer'
            label='price list disclaimer'
            Input={TextArea}
            vertical
          />
        </Column>
        <Column width={2}>
          <FormField
            name='terms_of_sale'
            label='terms of sale'
            Input={TextArea}
            vertical
          />
        </Column>
      </Grid>
    </>
  )
};
