import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const actionCreators = {
  getRecordLink: {
    request: ({ connectionId, recordId, serviceName, forceRetrieval }) =>
      api.post('ThirdPartyServiceSpokeSocial::getRecordLink', {
        connection_id: connectionId,
        record_id: recordId,
        service_name: serviceName,
        force_retrieval: forceRetrieval
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  createRecordLink: {
    request: ({ connectionId, recordId, serviceName, forceRetrieval }) =>
      api.post('ThirdPartyServiceSpokeSocial::createRecordLink', {
        connection_id: connectionId,
        record_id: recordId,
        service_name: serviceName,
        force_retrieval: forceRetrieval
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('thirdPartyServiceSpokeSocial').createModel({
  actionCreators
});
