import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PADDINGS } from 'theme';

import PaddingBox from 'view/components/padding-box';

import { SummaryStat, Stat } from './summary-stat';

// NOTE: Can remove this when we finally update the box component
// https://rexsoftware.atlassian.net/browse/RADI-5575
const defaultStyles = StyleSheet({
  statContainer: {
    gap: PADDINGS.M
  }
});

export function RecordSummaryStats({ stats }: { stats: Stat[] }) {
  const s = useStyles(defaultStyles);

  if (stats.length === 0) return null;

  return (
    <PaddingBox light flexWrap='wrap' {...s('statContainer')}>
      {stats.map((stat: Stat, index: number) => (
        <SummaryStat key={`${stat.label}-${index}`} stat={stat} />
      ))}
    </PaddingBox>
  );
}
