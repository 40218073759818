import React from 'react';

import { DateCell, DateCellProps } from './date-cell';

export function DateTimeCell({
  format = 'D MMM YYYY H:mm A',
  ...props
}: DateCellProps) {
  return <DateCell format={format} {...props} />;
}
