import React from 'react';

import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';

import { dialogEmitter, DIALOG_ACTIONS } from 'data/emitters/dialogs';

import Link from 'view/components/link';
import DefaultButton from 'view/components/button/default-button';

interface RecordPreviewViewButtonProps {
  children: React.ReactNode;
  viewPath: string;
}

export function RecordPreviewViewButton({
  children,
  viewPath
}: RecordPreviewViewButtonProps) {
  return (
    <Box pt={PADDINGS.XS} data-testid='RecordPreviewViewButton'>
      <Link
        path={viewPath}
        // NOTE: We shouldn't have to do this - we're navigating to a new page, so
        // any dialogs should close automatically. This should live higher up - possibly
        // in the Link component, but could go higher than that.
        onClick={() => dialogEmitter.emit(DIALOG_ACTIONS.CLOSE_ALL)}
      >
        {({ onClick }) => (
          <DefaultButton
            blue
            width='100%'
            onClick={(e) => {
              e.stopPropagation();
              onClick(e);
            }}
          >
            {children}
          </DefaultButton>
        )}
      </Link>
    </Box>
  );
}
