import React, { PureComponent } from 'react';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS, TEXTS, PADDINGS } from 'theme/index';
import Tabs from './tabs';
import TabBar from 'view/components/tabs/tab-bar';

const tabButtonBarStyles = {
  Tab: StyleSheet({
    container: {
      ...TEXTS.BUTTON.CTA_WHITE_REGULAR,
      display: 'flex',
      flex: '0',
      alignItems: 'center',
      padding: PADDINGS.XS,
      justifyContent: 'center',
      marginRight: '4px',
      boxSizing: 'border-box',
      width: 'auto',
      textTransform: 'none',
      height: '34px',
      color: COLORS.PRIMARY.WHITE,
      backgroundColor: COLORS.STATES.ACTIVE,
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '&:hover': {
        color: COLORS.PRIMARY.WHITE
      },
      '&:active': {
        transform: 'translateY(4px)',
        transition: 'all 0ms'
      }
    },

    containerActive: {
      backgroundColor: COLORS.PRIMARY.GREY_DARK,
      color: COLORS.PRIMARY.WHITE
    },

    containerDisabled: {
      color: 'lightgrey',
      cursor: 'default',
      '&:hover': {
        color: 'lightgrey'
      }
    }
  }),
  TabBar: StyleSheet({
    list: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0rem',
      alignItems: 'flex-start'
    },

    indicator: {
      height: '0'
    }
  })
};

class RexTabs extends PureComponent {
  render() {
    return (
      <StylesProvider
        components={tabButtonBarStyles}
        prioritiseParentStyles={false}
      >
        <Tabs {...this.props} TabBar={TabBar} />
      </StylesProvider>
    );
  }
}

export default RexTabs;
