import { TextInput, TextArea } from '@rexlabs/text-input';

import Checkbox from 'view/components/input/checkbox';
import FileUploadInput from 'view/components/input/file-upload';
import { DateSelect, Select, TimeSelect } from 'view/components/input/select';

import { ArrayValue } from 'components/_experiment/value/array';
import { DateValue } from 'components/_experiment/value/date';
import { FileValue } from 'components/_experiment/value/file';

export const MAP = {
  checkbox: {
    Input: Checkbox
  },

  longtext: {
    Input: TextArea,
    width: 2
  },

  select: {
    Input: Select,
    getProps: (field) => ({
      options:
        field.options?.map?.((option) => ({
          label: option,
          value: option
        })) || []
    })
  },

  multi_select: {
    Input: Select,
    getProps: (field) => ({
      multi: true,
      options:
        field.options?.map?.((option) => ({
          label: option,
          value: option
        })) || []
    }),
    Value: ArrayValue
  },

  date: {
    Input: DateSelect,
    Value: DateValue
  },

  double: {
    Input: TextInput // TODO
  },

  time: {
    Input: TimeSelect
  },

  file: {
    Input: FileUploadInput,
    width: 2,
    Value: FileValue
  },

  record: {
    Input: TextInput, // TODO
    width: 2
  },

  multi_record: {
    Input: TextInput, // TODO
    width: 2
  },

  text: {
    Input: TextInput
  }
};
