const DEFAULT_NAMESPACE = '__$framepair';
const FramePairers = {
  prepareParentFrame: (
    childIFrameOrWindow,
    onReady,
    namespaceKey = DEFAULT_NAMESPACE
  ) => {
    const theWindow = childIFrameOrWindow.contentWindow || childIFrameOrWindow;
    const namespace = (theWindow[namespaceKey] = theWindow[namespaceKey] || {});
    if (namespace.ready) {
      onReady();
    } else {
      namespace.readyqueue = onReady;
    }
  },
  activateChildFrame: (namespaceKey = DEFAULT_NAMESPACE) => {
    const namespace = (window[namespaceKey] = window[namespaceKey] || {});
    if (typeof namespace.readyqueue === 'function') {
      namespace.readyqueue();
      namespace.readyqueue = null;
    }
    window.ready = true;
  }
};

export default FramePairers;
