import React, { CSSProperties } from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface DialogBodyProps extends BaseTextProps {
  italic?: boolean;
  newline?: boolean;
  light?: boolean;
  style?: CSSProperties;
}

export function DialogBody({
  italic,
  newline,
  light,
  ...props
}: DialogBodyProps) {
  return (
    <Text
      is='p'
      {...props}
      type={map({
        BODY_DIALOG: true,
        ITALIC: italic,
        NEWLINE: newline,
        LIGHT: light
      })}
    />
  );
}
