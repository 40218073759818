import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { ButtonBar } from 'view/components/button-bar';
import { IconButton, TextButton, DefaultButton } from 'view/components/button';
import { ReactForms, Form, FormField, HiddenField } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';

import { autobind } from 'core-decorators';

import { withErrorDialog } from 'src/hocs/with-error-dialog';
import _ from 'lodash';

import { Heading } from 'components/text/heading';
import { withDialog } from 'shared/hocs/with-dialog';
import Box from '@rexlabs/box';
import { ICONS } from 'shared/components/icon';
import { withModel } from '@rexlabs/model-generator';
import DateTimeInput, {
  valueToDate,
  dateToValue
} from 'view/components/input/date-time';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import MetricRelatedDataList from 'view/components/lists/metrics/related-data';
import MetricUserAttributions from 'view/components/lists/metrics/user-attributions';

import metricsModel from 'data/models/entities/metrics';

import EditRelatedDataDialog from 'view/dialogs/admin/metric-write-rules/edit-related-data';
import EditUserAttributionDialog from 'view/dialogs/admin/metric-write-rules/edit-user-attribution';

@connect((state) => ({ currentUser: _.get(state, 'session.user_details') }))
@withModel(metricsModel)
@withDialog(EditRelatedDataDialog, { propName: 'editRelatedData' })
@withDialog(EditUserAttributionDialog, { propName: 'editUserAttribution' })
@withErrorDialog
@autobind
class AddMetricDialog extends PureComponent {
  handleSubmit(values) {
    const { errorDialog, callback, closeDialog, service, id, metrics } =
      this.props;

    metrics
      .createItem({
        service_name: service,
        record_id: id,
        data: {
          metric: values.metric,
          time: dayjs(valueToDate(values.time)).unix(),
          user_attributions: _.get(values, 'user_attributions', []).map(
            (a) => ({
              percentage: a.percentage,
              user_id: _.get(a, 'user.id')
            })
          ),
          value: values.value,
          labels: [
            { label: 'service_name', value: service },
            { label: 'record_id', value: id },
            ...values.labels.map((l) => ({
              label: l.label,
              value: _.get(l, 'value.value', l.value)
            }))
          ]
        }
      })
      .then(({ data }) => Promise.all([callback ? callback(data) : null]))
      .then(() => closeDialog())
      .catch(errorDialog.open);
  }

  getInitialValues() {
    const { currentUser, initialValues } = this.props;

    const now = dayjs(new Date());
    const currentTime = now.add(5 - (dayjs().minute() % 5), 'minute');

    if (initialValues) {
      return {
        ...initialValues,
        time: dateToValue(currentTime.toDate())
      };
    }

    return {
      labels: [],
      user_attributions: [
        {
          user: { id: currentUser.id, name: currentUser.full_name },
          percentage: 100
        }
      ],
      time: dateToValue(currentTime.toDate()),
      value: 1
    };
  }

  render() {
    const {
      onLoad,
      closeDialog,
      service,
      id,
      editRelatedData,
      editUserAttribution
    } = this.props;

    return (
      <Dialog
        closeDialog={closeDialog}
        onLoad={onLoad}
        title='Add Metric'
        height={800}
        width={400}
      >
        <ReactForms
          initialValues={this.getInitialValues()}
          handleSubmit={this.handleSubmit}
        >
          {({ submitForm, isSubmitting, values, setValues }) => (
            <Form>
              <FormField name='metric' label='identifier' Input={TextInput} />

              <FormField
                sendImmediate
                name='time'
                label='effective date'
                Input={DateTimeInput}
              />
              <FormField name='value' label='metric value' Input={TextInput} />

              <Box mt={25} flexDirection='row' height={40} alignItems='center'>
                <Heading level={2}>Attributed Users</Heading>

                <Box ml={8}>
                  <IconButton
                    circle
                    red
                    Icon={ICONS.ADD_MEDIUM}
                    onClick={() =>
                      editUserAttribution.open({
                        excludeIds: values.user_attributions.map((a) =>
                          _.get(a, 'user.id')
                        ),
                        callback: (attribution) => {
                          setValues({
                            user_attributions: [
                              ...values.user_attributions,
                              attribution
                            ]
                          });
                        }
                      })
                    }
                  >
                    add user
                  </IconButton>
                </Box>
              </Box>
              <HiddenField name='user_attributions' />
              <MetricUserAttributions
                items={_.get(values, 'user_attributions', [])}
                onEdit={(item, index) =>
                  editUserAttribution.open({
                    data: item,
                    callback: (attribution) => {
                      const newItems = [...values.user_attributions];
                      newItems[index] = attribution;
                      setValues({ user_attributions: newItems });
                    }
                  })
                }
                onDelete={(item) => {
                  setValues({
                    user_attributions: (values.user_attributions || []).filter(
                      (i) => i !== item
                    )
                  });
                }}
              />

              <Box mt={15} flexDirection='row' height={40} alignItems='center'>
                <Heading level={2}>Related Data</Heading>

                <Box ml={8}>
                  <IconButton
                    circle
                    red
                    Icon={ICONS.ADD_MEDIUM}
                    onClick={() =>
                      editRelatedData.open({
                        invertable: false,
                        forceLiteral: true,
                        callback: (relatedData) => {
                          setValues({
                            labels: [...values.labels, relatedData]
                          });
                        }
                      })
                    }
                  >
                    add data
                  </IconButton>
                </Box>
              </Box>

              <HiddenField name='labels' />
              <MetricRelatedDataList
                items={[
                  {
                    label: 'service_name',
                    value: { value: service },
                    isSystem: true
                  },
                  {
                    label: 'record_id',
                    value: { value: id },
                    isSystem: true
                  },
                  ..._.get(values, 'labels', [])
                ]}
                onEdit={(item, index) =>
                  editRelatedData.open({
                    data: item,
                    invertable: false,
                    forceLiteral: true,
                    callback: (relatedData) => {
                      const newLabels = [...values.labels];
                      // `-2` because of the 2 system labels we force manually
                      newLabels[index - 2] = relatedData;
                      setValues({ labels: newLabels });
                    }
                  })
                }
                onDelete={(item) => {
                  setValues({
                    labels: (values.labels || []).filter((i) => i !== item)
                  });
                }}
                invertable={false}
              />

              <ButtonBar isLoading={isSubmitting}>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton red onClick={submitForm}>
                  Save
                </DefaultButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default AddMetricDialog;
