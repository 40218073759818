import React, { PropsWithChildren } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  info: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.2,
    padding: '3px 0',

    '& b': {
      fontWeight: 600
    }
  },

  infoGrey: {
    color: '#b4b1a9'
  },

  link: {}
});

interface InfoProps {
  grey?: boolean;
}

export function Info({
  grey,
  children,
  ...props
}: PropsWithChildren<InfoProps>) {
  const s = useStyles(defaultStyles);
  return (
    <p {...props} {...s.with('info', { infoGrey: grey })(props)}>
      {children}
    </p>
  );
}
