import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import RexTable from 'view/components/table';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, TEXTS, FONT } from 'theme';
import { PafHeaderCell, PafDataCell } from './paf-history-cells';
import { ArrowDropdown } from 'view/components/action-menu';

import moment from 'moment';
import Icon, { ICONS } from 'shared/components/icon';
import _ from 'lodash';

@styled(
  StyleSheet({
    tableHeader: {
      fontWeight: FONT.WEIGHTS.BOLD,
      fontSize: '12px'
    },
    errorLink: {
      ...TEXTS.CONTENT.RECORD_LINK,
      fontSize: '13px',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      color: COLORS.PRIMARY.BLUE,
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    errorLinkContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%'
    },
    errorIcon: {
      color: COLORS.PRIMARY.YELLOW,
      size: '12px',
      paddingTop: PADDINGS.XXS,
      paddingLeft: PADDINGS.XXS
    },
    filenameContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      '&:hover': {
        '& > div': {
          opacity: 1,
          transition: '0.15s'
        }
      }
    },
    filename: {
      display: 'flex',
      alignItems: 'center'
    },
    rollbackDropdown: {
      opacity: 0
    }
  })
)
@autobind
class PafFileView extends PureComponent {
  errorRate() {
    const { ingested, total } = this.props.fileData;

    if (!total || !ingested) {
      return 0;
    }

    return (1 - ingested / total) * 100;
  }

  rollbackDropdown() {
    const { isCurrent, rollbackFile } = this.props;

    if (!isCurrent) {
      return null;
    }

    return (
      <Box>
        <ArrowDropdown
          items={[{ id: 0, label: 'Roll Back File', onClick: rollbackFile }]}
          isDisabled={false}
        />
      </Box>
    );
  }

  renderFilename() {
    const { styles: s, fileData } = this.props;

    return (
      <div {...s('filenameContainer')}>
        <div {...s('filename')}>
          {fileData.name || `${fileData.file_id.slice(0, 8)}...`}
        </div>
        <div {...s('rollbackDropdown')}>{this.rollbackDropdown()}</div>
      </div>
    );
  }

  renderErrorRate() {
    const { fileData, styles: s, openErrorDialog } = this.props;

    const { rows_total: rowsTotal, rows_ingested: rowsIngested } = fileData;
    const errorRate = this.errorRate();

    return (
      <Box styles={{ padding: 0 }} {...s('errorLinkContainer')}>
        <span {...s('errorLink')} onClick={openErrorDialog(fileData)}>
          {errorRate}% ({rowsTotal - rowsIngested} Rows)
        </span>
        {errorRate >= 10 ? (
          <Icon {...s('errorIcon')} type={ICONS.WARNING} />
        ) : null}
      </Box>
    );
  }

  tableData() {
    const { fileData } = this.props;

    const dateUploaded = _.get(fileData, 'system_ctime');
    const uploadedByName = _.get(fileData, 'uploaded_by.name');

    return {
      columns: [
        {
          label: 'filename',
          type: 'text',
          dataKey: 'filename',
          align: 'left'
        },
        {
          label: 'date uploaded',
          type: 'text',
          dataKey: 'dateUploaded',
          align: 'left'
        },
        {
          label: 'uploaded by',
          type: 'text',
          dataKey: 'uploadedBy',
          align: 'left'
        },
        {
          label: 'error rate',
          type: 'text',
          dataKey: 'errorRate',
          align: 'left'
        }
      ],
      data: [
        {
          key: fileData.file_id,
          filename: this.renderFilename(),
          dateUploaded: moment(dateUploaded * 1000).format('DD MMM YYYY'),
          uploadedBy: uploadedByName,
          errorRate: this.renderErrorRate()
        }
      ],
      DataCell: PafDataCell,
      HeaderCell: PafHeaderCell
    };
  }

  render() {
    const { fileData } = this.props;

    if (!fileData) {
      return null;
    }

    return <RexTable tableData={this.tableData()} />;
  }
}

export default PafFileView;
