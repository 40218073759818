import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  ComponentType
} from 'react';
import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';

import HeadingIllustration from 'view/components/external-link-redirect/heading-illustration';

import { SupportLink } from './support-link';
import { RedirectContentContainer } from './redirect-content-container';
import { ExternalLinkScreenLayout } from './external-link-screen-layout';
import { RedirectButton } from './redirect-button';

export interface ExternalLinkRedirectProps {
  onClose?: () => void;
  title: string;
  missingPermissionMessage?: string;
  hasPermission: boolean;
  fetchRedirectUrl: () => Promise<{ data: { result: string } }>;
  Icon?: any;
  errorMessage?: ReactNode;
  showBackButton?: boolean;
  TextContent?: ComponentType<any>;
}

function ExternalLinkRedirect({
  onClose,
  title,
  missingPermissionMessage,
  errorMessage,
  showBackButton = true,
  hasPermission,
  fetchRedirectUrl,
  Icon
}: ExternalLinkRedirectProps) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectURL, setRedirectURL] = useState<string | null>(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    fetchRedirectUrl()
      .then((data) => {
        if (!isMounted.current) return;
        setLoading(false);
        setRedirectURL(data.data.result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    return () => {
      isMounted.current = false;
    };
  }, [fetchRedirectUrl]);

  return (
    <ExternalLinkScreenLayout>
      {!!Icon && (
        <HeadingIllustration
          Icon={Icon}
          error={error}
          hasPermission={hasPermission}
        />
      )}

      <RedirectContentContainer
        error={error}
        errorMessage={errorMessage}
        missingPermissionMessage={missingPermissionMessage}
        hasPermission={hasPermission}
        loading={loading}
        title={title}
        redirectURL={redirectURL}
        closeOverlay={onClose}
      />

      {(error || !hasPermission) && showBackButton && (
        <Box mt={PADDINGS.XL} width='100%'>
          <RedirectButton onClick={onClose}>Return to Rex</RedirectButton>
        </Box>
      )}
      {!error && (
        <Box mt={PADDINGS.XL}>
          <SupportLink />
        </Box>
      )}
    </ExternalLinkScreenLayout>
  );
}

export default ExternalLinkRedirect;
