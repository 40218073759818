import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { Container } from 'components/_experiment/stream-item/container';
import { Heading } from 'components/_experiment/stream-item/heading';
import { Content } from 'components/_experiment/stream-item/content';
import { Meta } from 'components/_experiment/stream-item/meta';

dayjs.extend(relativeTime);

// Mapping lead sub types to actual titles
const TYPES = {
  general: 'General Lead',
  listing_enquiry: 'Listing Enquiry Lead'
};

const defaultStyles = StyleSheet({
  timeFromNow: {
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'right'
  },

  time: {
    fontSize: '16px'
  }
});

interface LeadStreamItemProps {
  data: any;
  pinned?: boolean;
}

export function LeadStreamItem({ data, pinned, togglePinned }) {
  const s = useStyles(defaultStyles);

  const receivedDate = dayjs(data.record.system_ctime * 1000);
  const formattedDate = receivedDate.format('D MMM YYYY H:mm A');
  const timeFromNow = receivedDate.fromNow(true);

  const source = data.record.lead_source?.text;

  return (
    <Container
      pinned={pinned}
      data={data}
      actions={[
        {
          label: data?.is_pinned ? 'Unpin' : 'Pin',
          onClick: togglePinned
        }
      ]}
    >
      <Box flexDirection='row' sx='20px'>
        <Box flexDirection='column' sy='3px' flex={1}>
          <Heading>
            <b>{TYPES[data.sub_type] || 'Lead'}</b>
          </Heading>
          <Content
            truncate
            viewText='view lead'
            // eslint-disable-next-line
            onViewClick={() => alert('view lead')}
          >
            {data?.record?.body_snippet}
          </Content>
          <Meta>
            Source: {source} · received at {formattedDate}
          </Meta>
        </Box>
        <Box
          {...s('timeFromNow')}
          flexDirection='column'
          flexShrink={0}
          sy='1px'
        >
          <span {...s('time')}>{timeFromNow}</span>
          <span>since received</span>
        </Box>
      </Box>
    </Container>
  );
}
