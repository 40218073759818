import React, { PureComponent } from 'react';
import Types from 'prop-types';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { keyframes, styled, StyleSheet } from '@rexlabs/styling';
import nav from 'data/models/custom/nav';
import { withModel } from '@rexlabs/model-generator';

import MenuSwitcher from './menu-switcher';

const defaultStyles = StyleSheet({
  container: {},
  enterAnimation: {
    animationName: keyframes({
      '0%': { transform: 'translateX(-100%)' },
      '100%': { transform: 'translateX(0)' }
    }),
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease'
  },
  exitAnimation: {
    animationName: keyframes({
      '0%': { transform: 'translateX(0)' },
      '100%': { transform: 'translateX(-100%)' }
    }),
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease'
  },
  instantAnimation: {
    animationDuration: '0ms'
  }
});

@withModel(nav)
@styled(defaultStyles)
@autobind
class MobileMenu extends PureComponent {
  static propTypes = {
    items: Types.array,
    /** Allows overriding the width of the mobile menu, by default it will consume 100% width of its parent  */
    width: Types.string,
    /** Allows overriding the height of the mobile menu, by default it will consume 100vh (fills the height of the viewport) */
    height: Types.string,
    /** This state is passed through to the menu items meta function to allow constructing dynamic content */
    localState: Types.object,
    /** The current URL that will help styling a menu item if it matches */
    activeRoute: Types.string,
    /** Params that are passed through to any onClick handlers of a menu item */
    onClickParams: Types.object
  };

  static defaultProps = {
    width: null,
    height: null
  };

  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'MAIN'
    };
    this.initialLoad = true;
  }

  get isOpen() {
    return this.props.nav.isOpen;
  }

  componentDidMount() {
    this.initialLoad = false;
  }

  handleViewModeChange(newViewMode) {
    this.setState({ viewMode: newViewMode });
  }

  render() {
    const { viewMode } = this.state;
    const {
      styles: s,
      items,
      width,
      height,
      localState,
      activeRoute,
      onClickParams
    } = this.props;

    const menuSwitcherProps = {
      viewMode,
      localState,
      activeRoute,
      onClickParams,
      isOpen: this.isOpen,
      items: items.filter((item) => !item.isEmpty),
      onViewModeChange: this.handleViewModeChange
    };

    return (
      <Box
        {...s(
          'container',
          { instantAnimation: this.initialLoad },
          { enterAnimation: this.isOpen },
          { exitAnimation: !this.isOpen }
        )}
        style={{
          overflow: 'hidden',
          width: width ? `${width}px` : '85vw',
          height: height ? `${height}px` : '100%',
          position: 'absolute',
          top: 0,
          pointerEvents: this.isOpen ? 'all' : 'none'
        }}
      >
        <MenuSwitcher {...menuSwitcherProps} />
      </Box>
    );
  }
}

export default MobileMenu;
