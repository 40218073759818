import React, { CSSProperties, ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  CellActionMenu,
  CellActionMenuProps
} from '../components/cell-action-menu';

const defaultStyles = StyleSheet({
  container: {},
  text: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export interface EllipsisCellProps {
  children?: ReactNode;
  value?: ReactNode;
  emptyValue?: ReactNode;
  width?: string;
  data?: Record<string, any>;
  items?: CellActionMenuProps['items'];
  'data-testid'?: string;
  style?: CSSProperties;
}

export function EllipsisCell({
  children,
  value,
  emptyValue = '—',
  width,
  items,
  data,
  'data-testid': testId,
  style = {}
}: EllipsisCellProps) {
  const s = useStyles(defaultStyles);
  return (
    <td style={style} {...s.with('container')({ width, ...style })}>
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <span
          {...s('text')}
          data-testid={testId || 'RecordList.Cell'}
          style={{ width }}
        >
          {children || value || emptyValue}
        </span>
        {items?.length ? <CellActionMenu data={data} items={items} /> : null}
      </Box>
    </td>
  );
}
