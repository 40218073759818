import React from 'react';

import { Heading } from '../heading';
import { Grid, Column } from '../grid';
import { FormField } from '../form-field';

import { MAP } from './map';

export function Edit({ group }) {
  return (
    <>
      <Heading>{group.label}</Heading>
      <Grid columns={2}>
        {Object.keys(group.fields)?.map?.((fieldId) => {
          const field = group.fields[fieldId];
          const fieldMap = MAP[field.field_type?.id] || MAP.text;
          return (
            <Column width={fieldMap.width || 1}>
              <FormField
                name={`__customFields.${field.id}`}
                label={field.label}
                Input={fieldMap.Input}
                inputProps={fieldMap.getProps?.(field)}
                vertical={fieldMap.width === 2}
              />
            </Column>
          );
        })}
      </Grid>
    </>
  );
}
