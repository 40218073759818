import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';

export const keyActivityFields: ReportColumnConfig = [
  {
    headerName: 'Key',
    field: 'key_register.label'
  },
  {
    headerName: 'Key type',
    field: 'type',
    valueGetter: ({ data }) => {
      // This column specifies no column type and so will have the default column type.
      // This means that the data will pass through the default valueFormatter
      // and the empty state will correctly be displayed as '-'
      if (!data) {
        return '';
      }
      if (data.key_register?.listing?.id) {
        return 'Listing';
      }
      return 'Property';
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Listing', 'Property']
    }
  },
  {
    headerName: 'Checked out',
    field: 'system_checked_out_time',
    type: 'dateColumn'
  },
  {
    headerName: 'Checked out by',
    field: 'system_checked_out_user.name',
    type: 'agentValueListColumn',
    subresource: 'system_checked_out_user'
  },
  {
    headerName: 'Checked out to',
    colId: 'checked_out_to',
    valueGetter: ({ data }) => {
      return data.checked_out_to_user?.name === 'System User'
        ? data.checked_out_to_contact?.system_search_key
        : data.checked_out_to_user?.name;
    },
    // not showing full filter values here. The number of contacts would be too huge.
    filter: 'agSetColumnFilter',
    subresource: 'checked_out_to'
  },
  { headerName: 'Reason', field: 'reason' },
  {
    headerName: 'Returned',
    field: 'system_checked_in_time',
    type: 'dateColumn'
  },
  {
    headerName: 'Returned by',
    field: 'checked_in_by_user.name',
    type: 'agentValueListColumn',
    subresource: 'checked_in_by'
  }
];
