import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import DialogBridge from 'data/classic-bridges/dialogs-shell';
import _ from 'lodash';

// TODO: Update this when DialogsBridge is updated
// https://app.shortcut.com/rexlabs/story/61580/type-shell-dialogbridge
const ShellDialogsBridge = DialogBridge as any;

const actionCreators = {
  // Logic taken from rex-app/public/assets/js/data-models/feedback.js's `trySetApprovalStatus` method
  trySetApprovalStatus: {
    request: ({ id, status = 'pending' }) =>
      api
        .post('Feedback::updateApprovalStatus', {
          id,
          status
        })
        .catch(() =>
          ShellDialogsBridge.notice.open({
            title: 'Warning',
            message:
              "The feedback entry was created successfully although we couldn't set its approval status. Please update the entry manually."
          })
        ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const TYPE = 'feedback';

const feedbackModel = new Generator<any, typeof actionCreators>(
  TYPE
).createEntityModel({ actionCreators });
export default feedbackModel;
