import { capitalize } from 'lodash';

import { SelectItem } from 'types/select';

import { andList } from 'utils/formatters/and-list';

interface GetIsAllOptionsArgs {
  selectedOptions?: SelectItem[];
  allOptions: SelectItem[];
}

export interface GetFilterHeaderArgs extends GetIsAllOptionsArgs {
  recordType: string;
}

export function getIsAllOptions({
  selectedOptions,
  allOptions
}: GetIsAllOptionsArgs) {
  return (
    allOptions.length !== 1 &&
    (selectedOptions?.length === 0 ||
      selectedOptions?.length === allOptions.length)
  );
}

export function getFilterHeader({
  recordType,
  selectedOptions = [],
  allOptions
}: GetFilterHeaderArgs) {
  if (getIsAllOptions({ selectedOptions, allOptions })) {
    return `All ${capitalize(recordType)}`;
  }

  if (selectedOptions?.length <= 3) {
    return andList({
      list: selectedOptions.map((selectedOption) => selectedOption.label)
    });
  }

  if (selectedOptions?.length >= 4) {
    return `${selectedOptions.length} ${recordType}`;
  }

  return '';
}
