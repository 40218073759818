import React from 'react';
import { autobind } from 'core-decorators';

import {
  ProviderInterface,
  VIEW_MODES
} from 'view/components/navigation/app-search/base';
import SkeletonOption from 'view/components/navigation/app-search/skeletons/skeleton';
import ProviderOptionScreen from 'view/components/navigation/app-search/screens/provider-option-screen';
import {
  PROVIDER_REQUEST_MAPPING,
  getFilteredProviders
} from 'view/components/navigation/app-search/utils';

export default function generateProviderClasses() {
  return Object.keys(getFilteredProviders()).map((providerKey) => {
    const { OptionType, Option } = PROVIDER_REQUEST_MAPPING[providerKey];

    @autobind
    class Provider extends ProviderInterface {
      get type() {
        return OptionType;
      }

      // NOTE: Unused as we are batching all requests in the app-search.js files doSearch method
      search() {
        return null;
      }

      renderOption(props, scopedType) {
        const isScoped = this.type === scopedType;
        const { options, optionTotals, ...optionProps } = props;
        const scopedOptions = options.filter(
          (option) => option.type === this.type
        );

        if (optionTotals[this.type] && optionTotals[this.type] > 0) {
          return (
            <ProviderOptionScreen
              key={`provider-screen-${this.type}`}
              {...optionProps}
              options={scopedOptions}
              Option={Option}
              optionCount={scopedOptions.length}
              totalCount={optionTotals[this.type]}
              isScoped={isScoped}
              scopedType={scopedType}
              type={this.type}
            />
          );
        }
      }

      renderRecentOption() {
        // TODO: Could probably remove this as we have a separate recents provider and it utilises the renderOptionMiddleware function
      }

      renderSkeleton(scopedType, count) {
        const isScoped = scopedType === this.type;
        const isGlobal = scopedType === VIEW_MODES.GLOBAL_SEARCH;

        if ((!isGlobal && isScoped) || (!isScoped && isGlobal)) {
          return (
            <SkeletonOption
              key={`${this.type.toLowerCase()}-skeleton`}
              hasHeader
              isScoped={isScoped}
              type={this.type}
              count={count}
            />
          );
        }
      }
    }

    return new Provider();
  });
}
