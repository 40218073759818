import React from 'react';

import { ProjectStub } from 'data/models/entities/projects';

import ProjectTooltip from 'view/components/popout/project';

import {
  PopoutCell,
  DefaultRecordPopout,
  BasePopoutCellProps
} from './popout-cell';

interface ProjectCellProps extends BasePopoutCellProps {
  value?: Partial<ProjectStub> | null;
}

export function ProjectCell(props: ProjectCellProps) {
  const { value } = props;
  const projectId = value?.id;

  // Because the Project Tooltip (Popout) is not typed, we're forcing it's type
  // When it is updated, we can remove this
  // https://app.shortcut.com/rexlabs/story/60591/popouts-migrate-project-popout-to-react
  const RecordPopout: DefaultRecordPopout = ProjectTooltip as any;

  return (
    <PopoutCell RecordPopout={RecordPopout} recordId={projectId} {...props}>
      {value?.name}
    </PopoutCell>
  );
}
