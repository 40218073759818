import React, { ChangeEvent, useState } from 'react';
import Box from '@rexlabs/box';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import CurrencyInput from 'src/view/components/input/currency';
import { Body } from 'components/text/body';
import { SlidingScaleTier } from '../data/types';

interface TierProps extends DefaultCellProps {
  index: number;
  prefix: string;
  itemsLength: number;
  onChange: (e: ChangeEvent, index: number) => void;
  data: SlidingScaleTier;
}

function TierCell({ width, index, data, itemsLength, onChange }: TierProps) {
  const isLast = itemsLength - 1 === index;
  const [focused, setFocused] = useState('');

  return (
    <DefaultCell
      width={width}
      value={
        <Box width={'100%'} flexDirection='row' alignItems='center' spacing={5}>
          <Box width={110} style={{ margin: 0 }}>
            <CurrencyInput
              name={'start_of_range'}
              value={data.start_of_range}
              onChange={(e: ChangeEvent) => onChange(e, index)}
              disabled={true}
              onFocus={() => setFocused('start_of_range')}
              onBlur={() => setFocused('')}
              meta={{
                focused: focused === 'start_of_range',
                error: null
              }}
              width={'unset'}
            />
          </Box>
          <Body dark normal style={{ whiteSpace: 'nowrap' }}>
            {isLast ? 'and over' : '-'}
          </Body>
          <Box
            width={110}
            style={{
              display: isLast ? 'none' : 'block',
              margin: 0
            }}
          >
            <CurrencyInput
              name={'end_of_range'}
              value={data.end_of_range}
              onChange={(e: ChangeEvent) => onChange(e, index)}
              onFocus={() => setFocused('end_of_range')}
              onBlur={() => setFocused('')}
              meta={{
                focused: focused === 'end_of_range',
                error: null
              }}
              width={'unset'}
            />
          </Box>
        </Box>
      }
    />
  );
}

export default TierCell;
