import React from 'react';

import Box from '@rexlabs/box';
import { useModelState } from '@rexlabs/model-generator';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { formatNumber } from 'shared/utils/formatters';

import sessionModel from 'data/models/custom/session';

import { PADDINGS } from 'theme';

import { HorizontalDivider } from 'components/horizontal-divider';
import { Heading } from 'components/text/heading';
import { Body } from 'components/text/body';

import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import {
  useCurrentModuleConfig,
  useGroupForModule
} from 'features/custom-reporting/modules/module-provider';
import { UserFilterPopout } from './popouts/user-filter-popout';
import { DateFilterPopout } from './popouts/date-filter-popout';
import { LocationFilterPopout } from './popouts/location-filter-popout';
import { ModuleSwitcherPopout } from './popouts/module-switcher-popout';
import { ExportButton } from './export-button';
import { FiltersButton } from './filters-button';
import { ColumnsButton } from './columns-button';
import { OpenCustomReportButton } from './open-custom-report-button';
import { SaveReportButton } from './save-report-button';
import { useSavedReport } from '../hooks/use-async-report-data';
import { MoreButton } from './more-button';
import { usePermissions } from 'hooks/use-permissions';
import { WarningBanner } from 'features/custom-reporting/components/warning-banner';
import { TOTAL_LIMIT } from 'features/custom-reporting/hooks/use-report-query';

// TODO: replace with box props when available
//  JIRA: https://rexsoftware.atlassian.net/browse/RADI-5575
const defaultStyles = StyleSheet({
  headingContainer: {
    columnGap: PADDINGS.XXS
  },
  topHeadingContainer: {
    columnGap: PADDINGS.M
  },
  exportContainer: {
    columnGap: PADDINGS.M
  },
  filterContainer: {
    columnGap: PADDINGS.M
  }
});

export function CustomReportingHeader() {
  const s = useStyles(defaultStyles);
  const {
    userSelectedReportState,
    setUserFilterState,
    setLocationFilterState,
    moduleName,
    isSavedReportStateModified,
    reportRows
  } = useReportState();
  const { moduleLabel, topLevelFilters, exportPrivilege } =
    useCurrentModuleConfig();
  const group = useGroupForModule(moduleName);
  const { selectedSavedReport } = useSavedReport();

  const session = useModelState(sessionModel);
  const { check } = usePermissions();

  const hasExportPermissions = check(exportPrivilege);

  const isMultiLocationAccount = session.office_locations.length > 1;
  const reportHeading = selectedSavedReport?.name || `${moduleLabel} Report`;

  return (
    <>
      <Box justifyContent='space-between' {...s('topHeadingContainer')}>
        <Box flexDirection='row' alignItems='center'>
          <Box
            pr={PADDINGS.M}
            flexDirection={'row'}
            alignItems='baseline'
            {...s('headingContainer')}
          >
            <Heading>{reportHeading}</Heading>
            {isSavedReportStateModified && (
              <Body light normal semibold>
                Edited
              </Body>
            )}
          </Box>

          <Box flex={1} {...s('headingContainer')}>
            <SaveReportButton />
            {selectedSavedReport?.id && (
              <MoreButton selectedSavedReport={selectedSavedReport} />
            )}
            <OpenCustomReportButton moduleName={moduleName} />
          </Box>
        </Box>
        <Box flexDirection='row' alignItems='center' {...s('exportContainer')}>
          {group?.groupId === 'appointments' && (
            <Body small regular light normal>
              Appointments can take up to 5 minutes to update
            </Body>
          )}
          {hasExportPermissions && <ExportButton />}
        </Box>
      </Box>

      <HorizontalDivider />

      <Box mb={PADDINGS.S} flexDirection='row' justifyContent={'space-between'}>
        <Box flexDirection='row' {...s('filterContainer')}>
          {group?.modules && group.modules.length > 1 && (
            <ModuleSwitcherPopout />
          )}
          {topLevelFilters?.date && <DateFilterPopout />}
          {topLevelFilters?.user && (
            <UserFilterPopout
              users={userSelectedReportState?.selectedUserFilter}
              moduleName={moduleName}
              initialFilterField={
                userSelectedReportState?.selectedUserFieldPreference
              }
              onSave={setUserFilterState}
            />
          )}
          {isMultiLocationAccount && topLevelFilters?.location && (
            <LocationFilterPopout
              location={userSelectedReportState?.selectedLocationFilter}
              onSave={setLocationFilterState}
            />
          )}
        </Box>

        <Box flexDirection='row' {...s('filterContainer')}>
          <ColumnsButton />
          <FiltersButton />
        </Box>
      </Box>
      {reportRows?.length >= TOTAL_LIMIT && (
        <WarningBanner>
          You&apos;ve reached the maximum of {formatNumber(TOTAL_LIMIT)} results
          &mdash; try applying a shorter date range or reducing the number of
          users above
        </WarningBanner>
      )}
    </>
  );
}
