import { useState } from 'react';

export function useTextGenerationFormState<
  FormValues extends Record<string, any>
>(initialValues: FormValues): [FormValues, (values: FormValues) => void] {
  const [state, setState] = useState<FormValues>(initialValues);
  const setFormValues = (values: typeof state) => {
    setState({ ...state, ...values });
  };

  return [state, setFormValues];
}
