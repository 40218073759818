import React from 'react';

import { Container } from 'components/_experiment/stream-item/container';
import { Heading } from 'components/_experiment/stream-item/heading';
import { Meta } from 'components/_experiment/stream-item/meta';
import dayjs from 'dayjs';

interface NoteStreamItemProps {
  data: any;
  pinned?: boolean;
}

export function NoteStreamItem({ data, pinned }) {
  const date = dayjs(data.record.system_ctime * 1000).format(
    'D MMM YYYY H:mm A'
  );
  return (
    <Container pinned={pinned} data={data}>
      <Heading maxLines={3}>{data?.record?.note}</Heading>
      <Meta>
        {date} · added by {data.record.system_created_user?.name}
      </Meta>
    </Container>
  );
}
