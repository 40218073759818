import { KanbanBoardColumnData } from 'components/kanban-board/kanban-board';
import { InfiniteList, RecordWithId } from 'components/infinite-list';
import Box from '@rexlabs/box';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { KanbanBoardItem } from 'components/kanban-board/kanban-board-item';
import { useDroppable } from '@dnd-kit/core';
import { COLORS } from 'theme';
import { Criteria } from 'types/criteria';
import { ArrowDropdown } from 'view/components/action-menu/index';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  container: {
    minWidth: '300px',
    backgroundColor: COLORS.GREY_FEINT,
    borderRadius: '6px 6px 0 0',
    position: 'relative',
    '& [data-column-hover]': {
      opacity: 0
    },
    '&:hover [data-column-hover]': {
      opacity: 1
    }
  },
  dropdownActive: {
    opacity: '1 !important'
  },
  actions: {
    position: 'absolute',
    top: 55,
    right: 15
  }
});

const KanbanBoardColumn = memo(function KanbanBoardColumn<
  ItemData extends RecordWithId,
  ColumnCriteria extends Criteria[]
>(
  props: KanbanBoardColumnData<ItemData, ColumnCriteria> & {
    id: string | number;
    name: string;
  }
) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const s = useStyles(styles);
  const { setNodeRef, isOver, over, active } = useDroppable({
    id: props.id,

    data: props
  });

  const renderKanbanBoardItem = useCallback(
    (itemProps, index) => (
      <KanbanBoardItem columnProps={props} item={itemProps} index={index} />
    ),
    [props]
  );

  const bulkActionsMemo = useMemo(() => {
    if (!props.criteria) return [];
    return props.bulkActions?.map((action) => ({
      ...action,
      onClick: () =>
        action.onClick({ criteria: props.criteria as ColumnCriteria })
    }));
  }, [props.criteria, props.bulkActions]);

  const renderColumnFooterMemo = useCallback(
    () =>
      props.renderColumnFooter
        ? props.renderColumnFooter({
            id: props.id,
            hasNextPage: props.hasNextPage,
            pipelineStage: props.pipelineStage
          })
        : undefined,
    [props.id, props.hasNextPage, props.pipelineStage]
  );

  return (
    <Box
      flex={1}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      style={{
        opacity:
          isOver && over?.id !== active?.data?.current?.columnProps?.id
            ? 0.5
            : 1
      }}
      ref={setNodeRef}
      {...s('container')}
      data-column-id={String(props.id)}
    >
      <Box>
        {props.renderColumnHeader ? props.renderColumnHeader(props) : null}
        {bulkActionsMemo?.length ? (
          <Box
            data-column-hover
            data-intercom-target='Appraisals Pipeline Bulk Actions'
            {...s('actions', {
              dropdownActive: isDropdownOpen
            })}
          >
            <ArrowDropdown
              small
              items={bulkActionsMemo}
              onChange={(isOpen) => setIsDropdownOpen(isOpen)}
            />
          </Box>
        ) : null}
      </Box>
      <Box flex={1}>
        <InfiniteList
          {...props}
          renderItem={renderKanbanBoardItem}
          renderFooter={renderColumnFooterMemo}
        />
      </Box>
    </Box>
  );
});

export { KanbanBoardColumn };
