import React, { useMemo } from 'react';
import { RowNode } from 'ag-grid-community';
import { Link } from 'components/text/link';
import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import { getEmptyValue } from '../data-grid-column-types';
import { getFormattedDate } from 'src/utils/date';
import { convertUtcToLocalDate } from 'src/utils/calendar';
import { useDialog } from 'hooks/use-dialog';

export function PropertyAppraisalColumn({
  data,
  value,
  node
}: {
  data: Record<string, unknown> & { active_appraisal: { id: string } };
  value?: string;
  node: RowNode | null;
}) {
  const { reloadReportData } = useReportState();
  const appraisalId = useMemo(() => data?.active_appraisal?.id ?? '', [data]);

  const editAppraisalShell = useDialog('addEditAppraisal');

  const onEditAppraisal = () =>
    editAppraisalShell.open({
      id: appraisalId,
      callback: () => {
        reloadReportData();
      }
    });

  if (!appraisalId) {
    return value || getEmptyValue({ node });
  }

  return (
    <Link blue undecorated record onClick={onEditAppraisal}>
      {getFormattedDate({ date: convertUtcToLocalDate(value) })}
    </Link>
  );
}
