import React from 'react';

import { useDialog } from 'hooks/use-dialog';

import { DefaultButton } from 'view/components/button';

import { CustomReportDialogProps } from '../dialogs/custom-report-dialog';
import { ModuleName } from '../modules/module-config-types';

interface OpenCustomReportButtonProps {
  moduleName: ModuleName;
}

export function OpenCustomReportButton(props: OpenCustomReportButtonProps) {
  const { moduleName } = props;

  const CustomReportsDialog: {
    open: (props: Omit<CustomReportDialogProps, 'closeDialog'>) => void;
  } = useDialog('customReports');

  return (
    <DefaultButton
      dark
      onClick={() =>
        CustomReportsDialog.open({
          moduleName
        })
      }
    >
      Open Saved Report
    </DefaultButton>
  );
}
