import React from 'react';
import { DocumentsBlockView } from 'components/_experiment/record-blocks/documents-block';

export const documentsBlock = {
  id: 'documents',
  View: ({ values }) => {
    return (
      <DocumentsBlockView
        values={values}
        fieldName={'related.project_documents'}
      />
    );
  }
};
