import React from 'react';
import { Dropdown } from 'src/view/components/action-menu';

import { CustomReportItem } from '../data/custom-reports-model';
import { useReportActions } from '../hooks/use-report-actions';

interface MoreButtonProps {
  selectedSavedReport: CustomReportItem;
}

export function MoreButton({ selectedSavedReport }: MoreButtonProps) {
  const arrowDropdownOptions = useReportActions({
    customReport: selectedSavedReport
  });

  return <Dropdown items={arrowDropdownOptions}>More</Dropdown>;
}
