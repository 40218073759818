import { identity } from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

import { SystemOptionObj } from 'data/models/system-lists/types';
import { UserItem } from 'data/models/value-lists/account-users';
import { UserGroupsItem } from './admin-user-groups';
import { OrderBy } from 'features/pipelines/components/order-by-dropdown';

const TYPE = 'savedFilters';

export interface SavedFilterUserGroupItem {
  id: string;
  saved_filter_id: string;
  user_group: Pick<
    UserGroupsItem,
    'description' | 'id' | 'is_hidden' | 'library' | 'name'
  >;
}

export interface SavedFilterItem {
  account_id: number;
  criteria: { name: string; type: string; value: string | null };
  description: string | null;
  etag: string;
  favourite: boolean;
  id: number;
  is_hidden: boolean | null;
  is_public: boolean;
  last_run_ts: string | null;
  library: SystemOptionObj;
  name: string;
  service_name: string;
  user_groups: SavedFilterUserGroupItem[];
  system_record_state: string;
  system_assigned_by_user: UserItem;
  system_assigned_time: string;
  system_completed_by_user: UserItem;
  system_completed_time: string;
  system_created_user: UserItem;
  system_ctime: string;
  system_modified_user: UserItem;
  system_modtime: string;
  security_user_rights: string[];
  order_by: OrderBy | null;
}

interface UpdateSavedFilterObject
  extends Partial<
    Pick<SavedFilterItem, 'name' | 'service_name' | 'criteria' | 'is_public'>
  > {
  user_groups?: number[];
}

const actionCreators = {
  touchLastRunDate: {
    request: ({ id }) =>
      api.post('SavedFilters::touchLastRunDate', { filter_id: id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  favourite: {
    request: ({ id }) => api.post('SavedFilters::favourite', { filter_id: id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  unfavourite: {
    request: ({ id }) =>
      api.post('SavedFilters::unfavourite', { filter_id: id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },

  updateItem: {
    request: ({
      id,
      data
    }: {
      id: string;
      data: {
        newData: UpdateSavedFilterObject;
        prevData: SavedFilterItem;
      };
    }) => {
      const { prevData, newData } = data;

      const isPublic = newData?.is_public;

      const newDataGroupIds = newData?.user_groups || [];
      const prevDataGroups = prevData?.user_groups || [];

      /**
       * Backend doesn't like us sending the same id's to a saved filter, if they
       * are already attached. So what we're doing below, is filtering through the
       * array of ids from the form, and only returning the ones that don't exist in
       * the initial values you provide.
       */
      const newUserGroupsToAdd = newDataGroupIds
        ?.filter(
          (newUserGroupId) =>
            !prevDataGroups.find(
              (prevGroup) => prevGroup.user_group.id === newUserGroupId
            )
        )
        .map((newUserGroupId) => ({
          user_group_id: newUserGroupId
        }));

      /**
       * What we're doing below is, if the saved filter is no longer sharing with
       * user groups, we're removing those user groups from the saved filter. Otherwise
       * we're looking at the initial user groups, and seeing if any are missing
       * from the form - if they are, we want to remove those in our request.
       */
      function addDestroyProp(userGroup) {
        return {
          // NOTE: we need to include the prefixes, as BE expects it. I think this has
          // something to do with the relation.
          _destroy: true,
          _id: userGroup.id
        };
      }

      function getRemovedUserGroups() {
        if (isPublic || (!isPublic && newDataGroupIds.length === 0)) {
          return prevDataGroups.map(addDestroyProp);
        }

        return prevDataGroups
          .filter(
            (prevGroupId) =>
              !newDataGroupIds?.find(
                (newGroupId) => newGroupId === prevGroupId.user_group.id
              )
          )
          .map(addDestroyProp);
      }

      return api.post('SavedFilters::update', {
        data: {
          id,
          ...newData,
          user_groups: [...newUserGroupsToAdd, ...getRemovedUserGroups()]
        }
      });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const savedFiltersModel = new Generator<SavedFilterItem, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});

export default savedFiltersModel;
