import React, { PureComponent, Fragment } from 'react';
import Dialog from 'view/components/dialog';
import { ReactForms, FormField } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';
import { ValueListSelect } from 'view/components/input/select';
import campaignRecordBaseStatusModel from 'data/models/system-lists/campaign-record-base-status';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import { createValidationRules } from 'shared/utils/form';
import { autobind } from 'core-decorators';
import _ from 'lodash';

@autobind
class EditCampaignMemberStatusDialog extends PureComponent {
  handleSubmit(values) {
    const { callback: callbackFn, closeDialog } = this.props;
    callbackFn({
      base_status: {
        id: values.base_status.value,
        text: values.base_status.label
      },
      label: values.label
    });
    closeDialog();
  }

  render() {
    const { onLoad, closeDialog, baseStatus, label } = this.props;
    return (
      <Dialog
        title={baseStatus ? 'Edit Record Status' : 'Add Record Status'}
        height={400}
        width={400}
        onLoad={onLoad}
        closeDialog={closeDialog}
        isLoading={
          _.get(this.props, 'valueLists.campaignRecordBaseStatus.status') ===
          'loading'
        }
      >
        <ReactForms
          validate={createValidationRules({
            label: 'required',
            base_status: 'required'
          })}
          initialValues={{
            base_status: {
              value: _.get(baseStatus, 'id'),
              label: _.get(baseStatus, 'text'),
              model: campaignRecordBaseStatusModel
            } || {
              value: 'pending',
              model: campaignRecordBaseStatusModel
            },
            label
          }}
          handleSubmit={this.handleSubmit}
        >
          {({ submitForm }) => (
            <Fragment>
              <FormField
                label='base status'
                name='base_status'
                Input={ValueListSelect}
                inputProps={{
                  models: [campaignRecordBaseStatusModel],
                  valueAsObject: true
                }}
              />
              <FormField label='status label' name='label' Input={TextInput} />
              <SaveCancelButtonBar onSave={submitForm} onCancel={closeDialog} />
            </Fragment>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default EditCampaignMemberStatusDialog;
