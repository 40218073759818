/* eslint-disable max-lines */

import React, { ComponentProps, ReactNode, useEffect, useState } from 'react';
import {
  KanbanBoard,
  KanbanBoardColumnData,
  KanbanBoardDropEvent
} from 'components/kanban-board/kanban-board';
import { PipelineColumn } from 'features/pipelines/components/pipeline-column';
import { EntityModel, Query } from '@rexlabs/model-generator';
import { PipelineGroupBy } from 'data/models/entities/appraisals';
import { useCriteria } from 'components/record-list-screen/utils/use-criteria';
import {
  FiltersConfig,
  LensesConfig
} from 'components/record-list-screen/types';
import Box from '@rexlabs/box';
import { PipelineItemAppraisal } from 'features/pipelines/modules/appraisals/components/pipeline-item-appraisal';
import _ from 'lodash';
import { Criteria } from 'types/criteria';
import { OrderBy } from 'features/pipelines/components/order-by-dropdown';
import LocalStorage from 'shared/utils/local-storage';
import { PipelineStageData } from 'features/pipelines/data/admin-pipeline-stages';
import { getCriteriaForGroupBy } from 'features/pipelines/data/utils/date-grouping';
import { useWhereabouts } from '@rexlabs/whereabouts';
import { PipelineFilters } from 'features/pipelines/components/pipeline-filters';
import { useSelector } from 'react-redux';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics/index';
import { usePipelineBulkActions } from 'features/pipelines/modules/appraisals/hooks/use-pipeline-bulk-actions';
import { KanbanBoardColumn } from 'components/kanban-board/kanban-board-column';

export interface PipelineScreenProps<M extends EntityModel<any>> {
  serviceName: string;
  lensesConfig: LensesConfig;
  filtersConfig: FiltersConfig;
  title: string;
  fetchColumns: () => Promise<PipelineStageData[]>;
  getQuery: (criteria: Criteria[], orderBy: OrderBy) => Query<M>;
  bulkActions: ReturnType<typeof usePipelineBulkActions>;
  onItemColumnChange: (
    event: KanbanBoardDropEvent<any, any> & { groupBy: PipelineGroupBy }
  ) => void;
  onQueryChange?: (q: Query<M> | null, groupBy: PipelineGroupBy) => void;
  renderColumnHeader: (
    props: KanbanBoardColumnData<any, any>,
    groupBy: PipelineGroupBy
  ) => ReactNode;
  renderColumnFooter?: ComponentProps<
    typeof KanbanBoardColumn
  >['renderColumnFooter'];
  itemHeight: number;
  onAddNew: (groupBy: PipelineGroupBy) => void;
}

/**
 * A screen that shows an interactive kanban board of items from an API service.
 *
 * General flow of composition:
 *
 * ```
 *    PipelinesScreen (filters, query generation, get columns)
 *         │
 *         │
 *         └───────► KanbanBoard (render columns and items, drag-n-drop)
 *                       │
 *                       │
 *                       └───────► InfiniteScroll (virtualised scrollviews, load next page)
 *```
 *
 * PipelinesScreen is service-agnostic, see /modules/appraisals folder for reference
 * on how to implement a pipeline for a specific service or feature.
 */
function PipelinesScreenInner<M extends EntityModel<any>>({
  serviceName,
  lensesConfig,
  filtersConfig,
  title,
  fetchColumns,
  onItemColumnChange,
  itemHeight,
  getQuery,
  onQueryChange,
  renderColumnHeader,
  renderColumnFooter,
  bulkActions,
  onAddNew
}: PipelineScreenProps<M>) {
  const getQueryWithUuid = (
    uuid: string,
    criteria: Criteria[],
    orderBy: OrderBy
  ) => {
    const q = getQuery(criteria, orderBy);
    q.uuid = uuid;
    return q;
  };

  const orderByStorageKey = `pipeline-${serviceName.toLowerCase()}-order-by`;
  const groupByStorageKey = `pipeline-${serviceName.toLowerCase()}-group-by`;

  const [columns, setColumns] = useState<PipelineStageData[] | null>(null);
  const [fetchedColumns, setFetchedColumns] = useState<
    PipelineStageData[] | null
  >(null);

  const loadingItem = useSelector((state: any) =>
    Object.keys(state.entities.appraisals.items).find((k) =>
      ['loading', 'updating'].includes(
        state.entities.appraisals.items[k].status
      )
    )
  );

  const [groupBy, setGroupBy] = useState<PipelineGroupBy>(
    LocalStorage.get(groupByStorageKey) || 'pipeline_stage'
  );
  const [orderBy, setOrderBy] = useState<OrderBy>(
    LocalStorage.get(orderByStorageKey) || {
      'property.system_owner_last_contacted_date': 'asc'
    }
  );

  const filterState = useCriteria({
    serviceName,
    lenses: lensesConfig,
    filtersConfig,
    setOrderBy
  });

  const { savedFilter, savedFilterId, queryCriteria } = filterState;

  useEffect(() => {
    fetchColumns().then((c) => {
      setFetchedColumns(c);
    });
  }, [fetchColumns]);

  useEffect(() => {
    if (!_.isEqual(savedFilter?.order_by, orderBy))
      LocalStorage.set(orderByStorageKey, orderBy, true);
  }, [orderBy, savedFilter, orderByStorageKey]);

  useEffect(() => {
    LocalStorage.set(groupByStorageKey, groupBy, true);
  }, [groupBy, groupByStorageKey]);

  return (
    <Box
      margin={'35px 3% 40px 3%'}
      paddingLeft={'30px'}
      paddingRight={'30px'}
      style={{ background: 'white' }}
      height={'calc(100% - 60px)'}
      display={'flex'}
      flexDirection={'column'}
    >
      <PipelineFilters
        filtersConfig={filtersConfig}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        filterState={filterState}
        onAddNew={onAddNew}
        lensesConfig={lensesConfig}
        fetchedColumns={fetchedColumns}
        setColumns={setColumns}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        serviceName={serviceName}
        title={title}
      />
      {columns && fetchedColumns ? (
        <KanbanBoard
          onItemColumnChange={(e) => {
            Analytics.track({
              event: EVENTS.PIPELINES.ITEM_DRAGGED,
              properties: {
                toColumn: e.toColumn.id,
                fromColumn: e.fromColumn.id
              }
            });
            onItemColumnChange({ ...e, groupBy });
          }}
        >
          {columns.map((col) => {
            return (
              <PipelineColumn
                key={col.id + groupBy}
                serviceName={serviceName}
                name={col.name}
                query={
                  savedFilterId && !savedFilter
                    ? null
                    : getQueryWithUuid(
                        getListIdFromColumnId(col.id, groupBy),
                        [
                          ...getCriteriaForGroupBy(col, groupBy),
                          ...queryCriteria
                        ],
                        orderBy
                      )
                }
                renderItem={(data) => <PipelineItemAppraisal data={data} />}
                id={col.id}
                groupBy={groupBy}
                pipelineStage={col}
                bulkActions={bulkActions}
                onQueryChange={onQueryChange}
                itemHeight={itemHeight}
                renderColumnHeader={(props) =>
                  renderColumnHeader(props, groupBy)
                }
                renderColumnFooter={renderColumnFooter}
                isDraggingEnabled={(item) =>
                  item.security_user_rights?.includes('update') &&
                  String(item.id) !== String(loadingItem)
                }
              />
            );
          })}
        </KanbanBoard>
      ) : null}
    </Box>
  );
}

export function PipelineScreen<M extends EntityModel<any>>(
  props: PipelineScreenProps<M>
) {
  const whereabouts = useWhereabouts();
  const lensId = whereabouts.hashQuery?.lens;

  // We key (and thus, re-mount) the component by lens ID to avoid
  // filtering bugs when switching lenses.
  return <PipelinesScreenInner key={lensId || 'all'} {...props} />;
}

export function getListIdFromColumnId(
  columnId: string | number | null | undefined,
  groupBy: PipelineGroupBy
) {
  return `pipeline-column-${groupBy === 'pipeline_stage' ? 'stage' : 'date'}-${
    columnId || 'unqualified'
  }`;
}
