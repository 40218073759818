import React, { CSSProperties } from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface SubHeadingProps extends BaseTextProps {
  semibold?: boolean;
  white?: boolean;
  style?: CSSProperties;
}

export function SubHeading({
  semibold,
  white = false,
  ...props
}: SubHeadingProps) {
  return (
    <Text
      is='h2'
      {...props}
      type={map({ SUBHEADING: true, SEMIBOLD: semibold, WHITE: white })}
    />
  );
}
