import { map } from 'lodash';

import { api } from 'shared/utils/api-client';

import { VIEW_MODES } from './base';
import {
  getFilteredProviders,
  MAXIMUM_GLOBAL_RESULTS,
  MAXIMUM_SCOPED_RESULTS,
  OPTION_TYPES
} from './utils';

// TODO: We could look at actually typing the results here, but not all
// record types have had their autocompletes typed yet.
type SearchRecord = Record<string, string>;

interface Service {
  className: string;
  OptionType: string;
  labelPlucker: (data: SearchRecord) => string;
  valuePlucker: (data: SearchRecord) => string;
  Option: React.ReactNode;
  hasPermission: boolean;
  requestArgs?: {
    listing_states: string[];
  };
}

const SEARCH_ENDPOINT = 'GlobalSearch::autocomplete';

// NOTE: This is a temporary solution until we transition to the new search.
// Once we have, we can just modify the mapping in util, and delete this function.
// https://rexsoftware.atlassian.net/browse/RADI-6135
function getClassName(service: Service) {
  if (OPTION_TYPES.CURRENT_LISTING === service.OptionType) {
    return 'CurrentListings';
  }

  if (OPTION_TYPES.ARCHIVED_LISTING === service.OptionType) {
    return 'ArchivedListings';
  }

  return service.className;
}

function mapOptions(
  seachResult: { [key: string]: { rows: SearchRecord[]; total_hits: number } },
  services
) {
  return map(seachResult, (result, resultKey) => {
    const service = services.find(
      (service) => getClassName(service) === resultKey
    ) as Service;

    if (!service) {
      return null;
    }

    const { OptionType, labelPlucker, valuePlucker } = service;

    return {
      total: result.total_hits,
      rows: result.rows.map((option) => ({
        data: option,
        type: OptionType,
        label: labelPlucker(option),
        value: valuePlucker(option)
      }))
    };
  }).filter(Boolean);
}

function onSearchAll(searchTerm: string, services: Service[]) {
  const searchArgs = {
    query_string: searchTerm,
    limit: 3, // TODO: Update and use MAXIMUM_GLOBAL_RESULTS once search is generally released
    return_viewstate: false,
    services: services.map((service) => getClassName(service))
  };
  const searchRequest = api
    .post(SEARCH_ENDPOINT, {
      ...searchArgs
    })

    .then(({ data: { result } }) => mapOptions(result, services));

  return services.map((serviceName, index) => {
    return searchRequest.then((result) => result[index] || []);
  });
}

function onScopedSearch(
  searchTerm: string,
  services: Service[],
  viewMode: string
) {
  const className = getClassName(getFilteredProviders()[viewMode]);

  const searchArgs = {
    query_string: searchTerm,
    services: [className],
    limit: MAXIMUM_SCOPED_RESULTS
    // We're missing the requestArgs here - previously we were passing in additional
    // args for filtering the modules. For example, we should be searching by current
    // listings and archived listings - but we're currently lumping them into the
    // same results.
  };

  const viewStateArgs = { ...searchArgs, return_viewstate: true };

  return Promise.all([
    api
      .post(SEARCH_ENDPOINT, searchArgs)
      .then(({ data: { result } }) => mapOptions(result, services)),

    api
      .post(SEARCH_ENDPOINT, viewStateArgs)
      .then(({ data }) => data.result[className].view_state_id as string)
  ]);
}

export function onElasticSearch(searchTerm: string, viewMode: string) {
  if (!searchTerm) {
    return;
  }

  if (viewMode === VIEW_MODES.RECENT) {
    return Promise.resolve(null);
  }
  const FILTERED_PROVIDERS_REQUEST_MAPPING = getFilteredProviders();

  const services: Service[] = map(
    FILTERED_PROVIDERS_REQUEST_MAPPING,
    (service) => service
  );

  if (viewMode === VIEW_MODES.GLOBAL_SEARCH) {
    return onSearchAll(searchTerm, services);
  }

  return onScopedSearch(searchTerm, services, viewMode);
}
