import Types from 'prop-types';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS, SHADOWS, FONT, TEXTS, HEIGHTS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';

@styled(
  StyleSheet({
    wrapper: {
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 2
    },
    container: {
      position: 'absolute',
      userSelect: 'none',
      cursor: 'pointer',
      width: '100%',
      padding: `${PADDINGS.XS} ${PADDINGS.S}`,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 35,
      boxShadow: SHADOWS.APP_SEARCH.HEADER_BACK,
      backgroundColor: COLORS.COOL_GREY,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      boxSizing: 'border-box'
    },
    icon: {
      width: PADDINGS.S,
      height: PADDINGS.S,
      position: 'relative',
      color: COLORS.BLUE_GREY
    },
    text: {
      ...TEXTS.APP_SEARCH.MENU_HEADING,
      paddingLeft: PADDINGS.S
    }
  })
)
@autobind
class SearchSectionBack extends Component {
  static propTypes = {
    children: Types.string.isRequired
  };

  handleClick(event) {
    const { onClick } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onClick();
  }

  render() {
    const { styles: s } = this.props;
    return (
      <Box {...s.with('wrapper')('needsclick')}>
        <Box {...s.with('container')('needsclick')} onClick={this.handleClick}>
          <Icon
            type={ICONS.APP_SEARCH.ARROW}
            {...s.with('icon')('needsclick')}
          />
          <div {...s.with('text')('needsclick')}>Show all matching results</div>
        </Box>
        <div
          style={{ paddingBottom: HEIGHTS.APP_MENU.HEADING }}
          className='needsclick'
        />
      </Box>
    );
  }
}

export default SearchSectionBack;
