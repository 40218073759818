import React, { ComponentProps } from 'react';

import { ActionMenu } from 'view/components/action-menu';
import DropdownButton from 'view/components/button/dropdown';

import { ActionMenuProps } from './core';
import { DefaultButton } from 'view/components/button';

function Dropdown({
  items,
  placement,
  ...props
}: ActionMenuProps & ComponentProps<typeof DefaultButton>) {
  return (
    <ActionMenu
      Button={({ isOpen }) => <DropdownButton {...props} active={isOpen} />}
      {...props}
      placement={placement}
      style={{ zIndex: '100' }}
      items={items}
      distance='14px'
      offset='0px'
    />
  );
}

export default Dropdown;
