import { CampaignsRecordScreen } from '../screens/campaigns-record-screen';

export const campaignsRoutes = {
  CAMPAIGNS_RECORD: {
    config: ({ hasFlags }) => ({
      // only replace classic route if the user has the respective feature flag active
      path: hasFlags('record_screen_campaigns')
        ? '/campaigns/'
        : '/experiment/campaigns/',
      Component: CampaignsRecordScreen
    })
  }
};
