import React from 'react';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { Popout } from 'view/components/popout';
import { keyframes, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS } from 'theme';

type AIPopoutProps = {
  children: React.ReactNode;
  renderContent: (args: { close: () => void }) => React.ReactNode;
  onClose?: () => void;
};

export const AI_POPOUT_CLOSE_DURATION_SECONDS = 0.1;

const popoutOverrides = {
  Popout: StyleSheet({
    popout: {
      overflow: 'hidden',
      borderRadius: '6px',
      maxWidth: 'none',
      background: COLORS.WHITE,
      boxShadow: '0px 1px 30px 0px rgba(69, 42, 124, 0.1)'
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      '&:before': {
        background: COLORS.WHITE
      }
    }
  })
};

const enterAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(0.97) translateY(5px)'
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1) translateY(0px)'
  }
});

const exitAnimation = keyframes({
  '0%': {
    opacity: 1,
    transform: 'scale(1) translateY(0px)'
  },
  '100%': {
    opacity: 0,
    transform: 'scale(0.97) translateY(5px)'
  }
});

const tetherStyles = StyleSheet({
  opening: {
    animation: `${enterAnimation} forwards normal 0.2s cubic-bezier(.24,.9,.27,1)`
  },
  closing: {
    animation: `${exitAnimation} forwards normal ${AI_POPOUT_CLOSE_DURATION_SECONDS}s cubic-bezier(.24,.9,.27,1)`
  },

  content: {
    transition: 'none',
    transformOrigin: 'bottom left'
  }
});

export function AiPopout({ children, onClose, renderContent }: AIPopoutProps) {
  return (
    <StylesProvider components={popoutOverrides}>
      <Popout
        placement={PLACEMENTS.TOP_START}
        distance={'5px'}
        tetherStyles={tetherStyles}
        onChange={({ isOpen }) => {
          if (!isOpen) {
            onClose?.();
          }
        }}
        Content={renderContent}
      >
        {children}
      </Popout>
    </StylesProvider>
  );
}
