import { gql } from 'hooks/use-graphql-query';
import {
  CustomFields,
  Property,
  User
} from 'features/custom-reporting/data/fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';
import {
  TenancyApplication,
  Tenant
} from './tenancy-applications-data-fragments';
import {
  Contact,
  ContactEmails,
  ContactPhones
} from '../contacts/contact-data-fragments';

export const tenancyApplicationReportQueryDefaultVariables = {
  include_subresource_listing: false,
  include_subresource_agent: false,
  include_subresource_tenants: true,
  include_subresource_property: false,
  include_subresource_listing_owner: false,
  include_listing_custom_fields: false,
  include_listing_owner_custom_fields: false,
  include_property_custom_fields: false,
  property_custom_field_ids: [],
  listing_custom_field_ids: []
};

export const TenancyApplicationReportQuery = gql`
  ${Listing}
  ${Property}
  ${TenancyApplication}
  ${Tenant}
  ${Contact}
  ${ContactEmails}
  ${ContactPhones}
  ${User}
  ${CustomFields}

  query (
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
    $include_subresource_listing: Boolean!
    $include_subresource_agent: Boolean!
    $include_subresource_tenants: Boolean!
    $include_listing_custom_fields: Boolean!
    $include_property_custom_fields: Boolean!
    $include_subresource_listing_owner: Boolean!
    $include_subresource_property: Boolean!
    $listing_custom_field_ids: [Int!]!
    $property_custom_field_ids: [Int!]!
  ) {
    rowData: tenancy_applications(
      limit: $limit
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...TenancyApplication
        listing @include(if: $include_subresource_listing) {
          ...Listing
          custom_field_values(field_ids: $listing_custom_field_ids)
            @include(if: $include_listing_custom_fields) {
            ...CustomFields
          }
          property @include(if: $include_subresource_property) {
            ...Property
            custom_field_values(field_ids: $property_custom_field_ids)
              @include(if: $include_property_custom_fields) {
              ...CustomFields
            }
            system_owner_user {
              ...User
            }
          }
          owner: contact_relationships(limit: 1, type: "owner")
            @include(if: $include_subresource_listing_owner) {
            relationship_type {
              id
            }
            contact {
              ...Contact
              phones {
                ...ContactPhones
              }
              emails {
                ...ContactEmails
              }
              system_owner_user {
                ...User
              }
            }
          }
          system_owner_user {
            ...User
          }
        }
        agent @include(if: $include_subresource_agent) {
          ...User
        }
        tenants @include(if: $include_subresource_tenants) {
          ...Tenant
          contact {
            ...Contact
          }
        }
      }
    }
  }
`;
