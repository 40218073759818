import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { DefaultButton } from 'view/components/button';
import { ActionMenu } from 'view/components/action-menu';
import { ICONS } from 'shared/components/icon';
import { ActionMenuProps } from 'view/components/action-menu/core';
import { DefaultButtonProps } from 'view/components/button/default-button';

/**
 * Notes: This button is a replicated and improved version of old `view/components/action-menu/split-button`.
 * It is now a typescript, functional-component compared to the old which is a js, class-component.
 * Main change is that it allows passing of separate props for actionMenu via `actionButtonProps`.
 * If there is no `actionButtonProps` passed, the default props of the main button will be used.
 *
 * const defaultProps = { ...props, ...actionButtonProps };
 *
 * Example Usage:
 * <SplitButton ... actionButtonProps={{ blue: true, isDisabled: false }} /> <- action button will be blue and active.
 * <SplitButton red actionButtonProps={{ isDisabled: true }} /> <- action button will be red but disabled.
 */

const Arrow: any = ICONS.ARROW_DOWN;
interface SplitButtonProps extends ActionMenuProps {
  actionButtonProps?: DefaultButtonProps;
}

const defaultStyles = StyleSheet({
  actionMenu: {
    zIndex: 20
  },
  arrow: {
    transform: 'rotate(270deg)',
    fill: 'currentColor'
  }
});

export function SplitButton({
  items,
  onClick,
  placement,
  actionButtonProps,
  children,
  IconLeft,
  IconRight,
  width,
  ...props
}: SplitButtonProps) {
  const s = useStyles(defaultStyles);

  const defaultProps = { ...props, ...actionButtonProps };

  return (
    <Box display='flex' flexDirection='row' width={width}>
      <DefaultButton
        onClick={onClick}
        width={width ? '100%' : 'auto'}
        children={children}
        IconRight={IconRight}
        IconLeft={IconLeft}
        noRightBorderRadius
        {...props}
      />

      <ActionMenu
        {...s('actionMenu')}
        items={items}
        placement={placement}
        Button={({ isOpen }) => (
          <DefaultButton
            noLeftBorderRadius
            padding={false}
            active={isOpen}
            {...defaultProps}
          >
            <Arrow {...s('arrow')} />
          </DefaultButton>
        )}
      />
    </Box>
  );
}
