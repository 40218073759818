/*
|-------------------------------------------------------------------------------
| We setup a singleton dispatcher, much like redux, but as a separate object
| because we mainly want to be passing around non-serializable data (functions)
| in Rex2 overlays.
*/
import { Emitter } from 'utils/events';

export const overlayEmitter = new Emitter();
export const OVERLAY_ACTIONS = {
  OPEN: 'overlays/open',
  CLOSE: 'overlays/close'
};
