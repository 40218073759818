import React, { Suspense, PureComponent } from 'react';
import DialogsBridge from 'data/classic-bridges/dialogs-shell';

class ShellDialogFrame extends PureComponent {
  // This stops the loading behind the dialog
  // We automatically stop here because Dialog from 'view/components/dialog'
  // already has a loading we can use
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { DialogComponent, callName, uuid, variant } =
      this.props.dialogConfig;
    return (
      <Suspense fallback={null} {...this.props}>
        <DialogComponent
          {...this.props}
          closeDialog={() => DialogsBridge[callName].close(uuid)}
        />
      </Suspense>
    );
  }
}

export default ShellDialogFrame;
