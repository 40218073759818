import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import Spinner from 'shared/components/spinner';

import { FiltersConfig, LensesConfig } from '../types';
import { useLens } from '../utils/use-lens';
import { SavedFiltersPopout } from '../popouts/saved-filters-popout';

import { FilterButtons } from './filter-buttons';
import { useViewstate } from '../utils/use-viewstate';
import { OrderBy } from 'features/pipelines/components/order-by-dropdown';

const defaultStyles = StyleSheet({
  wrapHeading: {
    color: '#424242',
    fontSize: '27px',
    paddingRight: '14px'
  },

  heading: {
    fontWeight: 700,
    position: 'relative',
    paddingRight: '22px',

    '&:before': {
      content: '" "',
      position: 'absolute',
      right: '5px',
      top: '50%',
      marginTop: '-1px',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '5px solid currentColor'
    }
  },

  viewstateFilter: {
    fontWeight: 500,
    color: '#b4b1a9',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& button': {
      cursor: 'pointer',
      border: '0 none',
      background: 'transparent',
      font: 'inherit',
      color: 'inherit',
      padding: 0,
      width: '24px',
      height: '24px',
      lineHeight: '24px',
      marginTop: '1px',
      fontWeight: 600
    }
  },

  savedFilter: {
    fontWeight: 500
  }
});

export interface RecordListFiltersProps {
  title?: ReactNode;
  filtersConfig?: FiltersConfig;
  displayCriteria?: any;
  setCriteria: (criteria: any, filterId: any) => void;
  serviceName: string;
  savedFilter?: any;
  lenses?: LensesConfig;
  isLoading?: boolean;
  orderBy?: OrderBy;
  setOrderBy?: (orderBy: OrderBy) => void;
  truncateDisplayedFilters?: boolean;
}

export function RecordListFilters({
  title,
  filtersConfig,
  displayCriteria,
  setCriteria,
  serviceName,
  savedFilter,
  lenses,
  orderBy,
  setOrderBy,
  isLoading,
  truncateDisplayedFilters
}: RecordListFiltersProps) {
  const s = useStyles(defaultStyles);

  const { id: viewstateId, setViewstate } = useViewstate();
  const { title: lensTitle } = useLens(lenses);
  const canUpdate = savedFilter?.security_user_rights.includes('update');

  return (
    <Box flexDirection='row' alignItems='center' {...s('container')}>
      <Box flexDirection='row' alignItems='center' {...s('wrapHeading')}>
        <SavedFiltersPopout serviceName={serviceName} setCriteria={setCriteria}>
          <h1 {...s('heading')} data-testid='RecordList.ListTitle'>
            {lensTitle || title}
          </h1>
        </SavedFiltersPopout>

        {!!viewstateId && (
          <span {...s('viewstateFilter')}>
            <span> / Subset</span>
            <button onClick={() => setViewstate()}>&times;</button>
          </span>
        )}

        {!!savedFilter?.name && (
          <span {...s('savedFilter')}> / {savedFilter.name}</span>
        )}
      </Box>

      {isLoading ? (
        <Spinner small dark />
      ) : (
        <FilterButtons
          title={title}
          filtersConfig={filtersConfig}
          displayCriteria={displayCriteria}
          setCriteria={setCriteria}
          serviceName={serviceName}
          savedFilter={savedFilter}
          canUpdate={canUpdate}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          truncateDisplayedFilters={truncateDisplayedFilters}
        />
      )}
    </Box>
  );
}
