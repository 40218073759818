import React from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { useDownshiftContext } from '@rexlabs/core-select';

import { Tag } from 'components/text/tag';

import { ICONS } from 'shared/components/icon';

import { COLORS, PADDINGS, BORDER_RADIUS } from 'src/theme';

import { get } from 'lodash';

const { CLOSE_MEDIUM: CloseIcon } = ICONS;

const defaultInputTagStyles = StyleSheet({
  item: {
    width: '100%',
    height: '20px',
    borderRadius: BORDER_RADIUS.TAGS,
    backgroundColor: COLORS.PRIMARY.TAG_GREY,
    overflow: 'hidden'
  },
  multi: {
    width: 'auto',
    maxWidth: '100%',
    margin: `${PADDINGS['3XS']} 0px 0px ${PADDINGS['3XS']}`
  },
  overflow: {
    overflow: 'hidden'
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '18px',
    width: '18px',
    fill: COLORS.PRIMARY.SLATE,
    color: COLORS.PRIMARY.SLATE,
    cursor: 'pointer'
  },
  static: {
    cursor: 'default'
  }
});

const InputTag = styled(defaultInputTagStyles)(
  ({ styles: s, item, multi, onClear, Icon }) => {
    return (
      <Box
        {...s('item', { multi })}
        alignItems='center'
        padding={PADDINGS['3XS']}
      >
        <Box {...s('overflow')} flex={1} alignItems='center'>
          <Box height={'20px !important'} marginRight={PADDINGS.XXS}>
            {Icon && <Icon {...s('icon', 'static')} />}
          </Box>
          <Box {...s('overflow')} flex={1}>
            <Tag {...s('label')}>{get(item, 'label')}</Tag>
          </Box>
          <Box marginLeft={PADDINGS.XXS}>
            <CloseIcon {...s('icon')} onClick={onClear} />
          </Box>
        </Box>
      </Box>
    );
  }
);

function DefaultValue({
  index,

  Popout,

  ...props
}) {
  const { item, multi } = props;

  const { clearSelection, clearSelectionAtIndex } = useDownshiftContext();

  const handleClear = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    clearSelectionAtIndex ? clearSelectionAtIndex(index) : clearSelection();
  });

  return Popout ? (
    <Box
      width={!multi ? '100%' : undefined}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popout id={item?.value?.id} data={item?.value}>
        <InputTag {...props} onClear={handleClear} />
      </Popout>
    </Box>
  ) : (
    <InputTag {...props} onClear={handleClear} />
  );
}

export default DefaultValue;
