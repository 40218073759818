import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { ColumnType } from '../../';

interface Props {
  col?: ColumnType;
  children?: ReactNode;
}

function ColumnContainer({ col, children }: Props) {
  return (
    <td
      className={classnames('primary', col?.className)}
      style={{ maxWidth: col?.noOverflow ? '0' : 'none' }}
    >
      <div className='container'>{children}</div>
    </td>
  );
}

export default ColumnContainer;
