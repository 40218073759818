import _ from 'lodash';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import {
  CustomField,
  getCustomFieldColumns
} from 'features/custom-reporting/utils/custom-field-utils';

export const propertyFields = ({
  customFields,
  subresource
}: {
  customFields?: Record<string, CustomField>;
  subresource?: string;
}) => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'property'
  });
  return [
    {
      headerName: 'Property ID',
      field: 'id'
    },
    {
      headerName: 'Address',
      colId: 'property_address',
      type: 'propertyRecordColumn',
      keyCreator: ({ value, data }) => {
        const recordData = value || data;
        return recordData?.system_search_key;
      },
      filterParams: {
        valueGetter: ({ data }) => data?.system_search_key
      },
      width: 400
    },
    {
      headerName: 'Building name',
      field: 'adr_building.name'
    },
    {
      headerName: 'Unit no',
      field: 'adr_unit_number',
      withRegions: ['AUS', 'NZ']
    },
    {
      headerName: 'Flat no',
      field: 'adr_unit_number',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Street no',
      field: 'adr_street_number',
      withRegions: ['AUS', 'NZ']
    },
    {
      headerName: 'Street no / house name',
      field: 'adr_street_number',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Street name',
      field: 'adr_street_name'
    },
    {
      headerName: 'Locality',
      field: 'adr_locality',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Suburb',
      field: 'adr_suburb_or_town',
      type: 'valueListColumn',
      withRegions: ['AUS', 'NZ']
    },
    {
      headerName: 'Town',
      field: 'adr_suburb_or_town',
      type: 'valueListColumn',
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'State',
      field: 'adr_state_or_region',
      type: 'valueListColumn',
      withRegions: ['AUS', 'NZ']
    },
    {
      headerName: 'Postcode',
      field: 'adr_postcode'
    },
    {
      headerName: 'Property type',
      field: 'property_category.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'property_category' })
      }
    },
    {
      headerName: 'Property subcategory',
      field: 'property_subcategory.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'property_subcategory' })
      }
    },
    {
      headerName: 'Tenure type',
      field: 'attr_tenure_id.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'property_tenure' })
      },
      withRegions: ['UK', 'FR']
    },
    {
      headerName: 'Bedrooms',
      field: 'attr_bedrooms',
      type: 'numberColumn'
    },
    {
      headerName: 'Bathrooms',
      field: 'attr_bathrooms',
      type: 'numberColumn'
    },
    {
      headerName: 'Ensuites',
      field: 'attr_ensuites',
      type: 'numberColumn'
    },
    {
      headerName: 'Toilets',
      field: 'attr_toilets',
      type: 'numberColumn'
    },
    {
      headerName: 'Living Areas',
      field: 'attr_living_areas',
      type: 'numberColumn'
    },
    {
      headerName: 'Garages',
      field: 'attr_garages',
      type: 'numberColumn'
    },
    {
      headerName: 'Carports',
      field: 'attr_carports',
      type: 'numberColumn'
    },
    {
      headerName: 'Open spaces',
      field: 'attr_open_spaces',
      type: 'numberColumn'
    },
    {
      headerName: 'Year built',
      field: 'attr_build_year',
      type: 'numberColumn'
    },
    {
      headerName: 'Build area',
      colId: 'build_area',
      valueGetter: ({ data, colDef }) => {
        const recordData = _.get(data, colDef.field) || data;
        return [
          recordData?.attr_buildarea,
          recordData?.attr_buildarea_unit?.text
        ]
          .filter(Boolean)
          .join(' ');
      }
    },
    {
      headerName: 'Land area',
      colId: 'land_area',
      valueGetter: ({ data, colDef }) => {
        const recordData = _.get(data, colDef.field) || data;
        return [recordData?.attr_landarea, recordData?.attr_landarea_unit?.text]
          .filter(Boolean)
          .join(' ');
      }
    },
    {
      headerName: 'Do not contact',
      field: 'is_dnd',
      type: 'booleanColumn'
    },
    {
      headerName: 'Property tags',
      field: 'tags',
      type: 'tagColumn'
    },
    {
      headerName: 'Record owner',
      field: 'system_owner_user.name',
      type: 'agentValueListColumn',
      subresource: 'record_owner'
    },
    {
      headerName: 'Created date',
      field: 'system_ctime',
      type: 'dateColumn'
    },
    {
      headerName: 'Created by',
      field: 'system_created_user.name',
      type: 'agentValueListColumn',
      subresource: 'system_created_user'
    },
    {
      headerName: 'Last modified',
      field: 'system_modtime',
      type: 'dateColumn'
    },
    ...customFieldColumns
  ];
};
