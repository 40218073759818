import React from 'react';
import { useStyles } from '@rexlabs/styling';
import menuStyles from './styles';

function Separator({ index }) {
  const s = useStyles(menuStyles);
  return <li key={`separator-${index}`} {...s('separator')} />;
}

export default Separator;
