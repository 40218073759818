import React, { ComponentType } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { useModelActions } from '@rexlabs/model-generator';

import { withDialog } from 'shared/hocs/with-dialog';
import uiModel from 'data/models/custom/ui';
import savedFiltersModel from 'data/models/entities/saved-filters';
import { DefaultButton } from 'view/components/button';
import withClassicDialog from 'view/containers/with-classic-dialog';
import { SplitButton } from 'view/components/action-menu';

import { SavedFilterDialog } from '../dialogs/saved-filter-dialog';
import { FilterPopout } from '../popouts/filter-popout';
import { FilterTag } from './filter-tag';

const decorate = compose<ComponentType<any>>(
  withClassicDialog('advancedCriteria'),
  withDialog(SavedFilterDialog)
);

export const FilterButtons = decorate(function FilterButtons({
  title,
  filtersConfig,
  displayCriteria,
  setCriteria,
  serviceName,
  savedFilter,
  advancedCriteria,
  savedFilterDialog,
  orderBy,
  setOrderBy,
  canUpdate,
  truncateDisplayedFilters
}) {
  const savedFilterActions = useModelActions(savedFiltersModel);

  // TODO: Once the UI Model is typed, we should be able to remove any type
  // https://app.shortcut.com/rexlabs/story/64595/type-ui-model
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  // NOTE: we want to show advanced filters instead of the filters popout
  // either if there are no custom filers defined, or if at least one of the
  // active criteria is not part of the custom filters
  const filterNames = filtersConfig?.groups?.reduce?.((all, group) => {
    group.forEach((filter) => {
      all.push(filter.name);
    });
    return all;
  }, []);

  // We don't want to show lens criteria in the filters
  const filteredCriteria = displayCriteria?.filter?.(
    (c) => !c.isLens && !c.isViewstate
  );

  const showAdvancedDialog =
    !filtersConfig?.groups?.length ||
    !!filteredCriteria?.find?.(
      (criteria) => !filterNames?.includes(criteria?.filterConfig?.name)
    );

  if (filteredCriteria?.length) {
    /**
     * Buttons with applied filters, which allows saving the criteria as
     * well as shows the current criteria and allows to remove them
     */
    return (
      <Box sx='4px' justifyContent='row' alignItems='center'>
        {savedFilter?.id && canUpdate ? (
          <SplitButton
            onClick={() => {
              loadingIndicatorOn({ message: 'Updating saved filter...' });
              savedFilterActions
                .updateItem({
                  id: savedFilter.id,
                  data: {
                    newData: { criteria: filteredCriteria, order_by: orderBy }
                  }
                })
                .then(() => {
                  loadingIndicatorOff();
                })
                .catch((e) => {
                  console.error(e);
                  loadingIndicatorOff();
                });
            }}
            items={[
              {
                label: 'Save As',
                onClick: () =>
                  savedFilterDialog.open({
                    serviceName,
                    initialValues: {
                      name: `${savedFilter.name} (Copy)`,
                      shareWith: 'private'
                    },
                    criteria: filteredCriteria,
                    orderBy,
                    onClose: (record) => {
                      setCriteria(filteredCriteria, record?.result?.id);
                      if (setOrderBy && record.result?.order_by) {
                        setOrderBy(record.result?.order_by);
                      }
                    }
                  })
              }
            ]}
          >
            Save
          </SplitButton>
        ) : (
          <DefaultButton
            onClick={() =>
              savedFilterDialog.open({
                serviceName,
                criteria: filteredCriteria,
                orderBy,
                onClose: (record) => {
                  setCriteria(filteredCriteria, record?.result?.id);
                  if (setOrderBy && record.result?.order_by) {
                    setOrderBy(record.result?.order_by);
                  }
                }
              })
            }
          >
            Save As
          </DefaultButton>
        )}

        {showAdvancedDialog ? (
          <FilterTag
            displayCriteria={filteredCriteria}
            setCriteria={setCriteria}
            truncateDisplayedFilters={truncateDisplayedFilters}
            onClick={() =>
              advancedCriteria.open({
                serviceName,
                criteria: filteredCriteria,
                callback: (c) => {
                  setCriteria(c, savedFilter?.id);
                }
              })
            }
          />
        ) : (
          <FilterPopout
            serviceName={serviceName}
            filtersConfig={filtersConfig}
            displayCriteria={displayCriteria}
            setCriteria={setCriteria}
          >
            <FilterTag
              displayCriteria={filteredCriteria}
              setCriteria={setCriteria}
              truncateDisplayedFilters={truncateDisplayedFilters}
            />
          </FilterPopout>
        )}
      </Box>
    );
  }

  if (showAdvancedDialog) {
    /**
     * Advanced filters only
     */
    return (
      <DefaultButton
        blue
        onClick={() =>
          advancedCriteria.open({
            serviceName,
            criteria: filteredCriteria,
            callback: (c) => {
              setCriteria(c);
            }
          })
        }
      >
        Filter {title}
      </DefaultButton>
    );
  }

  /**
   * Filters popout populated based on the filters config
   */
  return (
    <FilterPopout
      serviceName={serviceName}
      filtersConfig={filtersConfig}
      displayCriteria={displayCriteria}
      setCriteria={setCriteria}
    >
      <DefaultButton blue>Filter {title}</DefaultButton>
    </FilterPopout>
  );
});
