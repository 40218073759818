import React from 'react';

import DefaultOption from 'view/components/input/select/defaults/option';

import { ICONS } from 'shared/components/icon';

import { get, startCase } from 'lodash';

const {
  SELECT: {
    VALUE: { POSTCODE_DISTRICT_SECTOR: PostcodeIcon }
  }
} = ICONS;

function PostcodeDistrictOption({ ...props }) {
  const { item } = props;

  const description = startCase(get(item, 'type'));

  return (
    <DefaultOption {...props} description={description} Icon={PostcodeIcon} />
  );
}

export default PostcodeDistrictOption;
