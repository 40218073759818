import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { withModel } from '@rexlabs/model-generator';

import GlobalAddConfig from 'data/configs/global-add';
import dealsModel from 'data/models/entities/deals';
import notesModel from 'data/models/entities/notes';
import remindersModel from 'data/models/entities/reminders';
import feedbackModel from 'data/models/entities/feedback';

import Icon, { ICONS } from 'shared/components/icon';
import { EVENTS } from 'shared/utils/analytics';
import { createKey } from 'shared/utils/react';
import Analytics from 'shared/utils/vivid-analytics';
import { withDialog } from 'shared/hocs/with-dialog';

import { withPermissions } from 'hocs/with-permissions';
import { withRegion } from 'hocs/with-region';

import withClassicDialog from 'view/containers/with-classic-dialog';
import Requires from 'view/containers/requires';
import { StartSelectWorkflowDialog } from 'view/dialogs/workflow';

import { NavigationPopout } from './navigation-popout';
import propertiesModel from 'data/models/entities/properties';
import { withErrorDialog } from 'hocs/with-error-dialog';
import NoticeDialog from 'src/view/dialogs/notice';
import { headerColorContrast } from 'view/components/navigation/app-search/utils';

const defaultStyles = StyleSheet({
  container: {
    width: 'inherit',
    minWidth: '432px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px'
  },

  mainWrapper: {
    display: 'flex'
  },

  leftSegment: {
    flex: 1,

    '& > .menuItem': {
      '& svg': {
        color: '#3AA6F1'
      }
    }
  },

  rightSegment: {
    flex: 1,

    '& > .menuItem': {
      '& svg': {
        color: '#69D795'
      }
    }
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0,0,0,0.75)',
    minHeight: '50px',
    fontSize: '15px',
    lineHeight: '22px',
    cursor: 'pointer',

    '& svg': {
      marginRight: '15px',
      marginLeft: '20px',

      width: '15px',
      height: '15px'
    },

    '&:hover': {
      backgroundColor: '#F7F8F9',
      color: 'black'
    }
  },

  divider: {
    minWidth: '1px',
    height: 'inherit',
    backgroundColor: '#E1E1E1',
    margin: '20px 5px'
  },

  footerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '60px',
    backgroundColor: '#F9FAFB',
    margin: '15px -5px -5px -5px',
    padding: '0 25px'
  },

  footerSegment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',

    '& svg': {
      marginRight: '7px',
      width: '15px',
      height: 'auto'
    }
  },

  footerSegmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#424242',
    cursor: 'pointer',

    '&:hover': {
      color: '#000000'
    }
  },

  closeContainer: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    padding: '21px'
  },

  closeButton: {
    cursor: 'pointer',
    width: 'auto',
    height: '18px',

    '& > svg': {
      height: 'inherit',
      width: 'inherit'
    }
  }
});

@withRegion
@withPermissions
@withModel(dealsModel)
@withModel(notesModel)
@withModel(remindersModel)
@withModel(feedbackModel)
@withModel(propertiesModel)
@withClassicDialog('editDeal')
@withClassicDialog('editMailMerge')
@withClassicDialog('editNote')
@withClassicDialog('editReminder')
@withClassicDialog('editFeedback')
@withClassicDialog('editProperties')
@withDialog(StartSelectWorkflowDialog, { propName: 'startSelectWorkflow' })
@styled(defaultStyles)
@autobind
class GlobalAddMenu extends PureComponent {
  state = {
    config: {
      LEFT_MENU: [],
      RIGHT_MENU: [],
      FOOTER_MENU: []
    }
  };

  componentDidMount() {
    this.setState({
      config: GlobalAddConfig({ errorDialog: this.props.errorDialog })
    });
  }

  componentDidUpdate(prevProps) {
    const { region } = this.props;
    const { region: prevRegion } = prevProps;

    if (region.isEU !== prevRegion?.isEU) {
      // Setting the config to state this way ensures that it changes when the user changes account
      this.setState({
        config: GlobalAddConfig()
      });
    }
  }

  // TODO - we can't see analytics event properties in Intercom,
  // change event name to include action info if required
  // https://app.clubhouse.io/rexlabs/story/51891/add-details-for-intercom-events

  handleTrackIconClick(actionName) {
    Analytics.track({
      event: EVENTS.GLOBAL_ADD,
      properties: { actionName },
      options: { integrations: { Intercom: true } }
    });
  }

  renderMenuItem(menuItem) {
    const { onClick, icon, name } = menuItem;
    const { styles: s } = this.props;
    const key = createKey(name);

    const onClickHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.handleTrackIconClick(name);
      this.props.closePopout();
      onClick(this.props);
    };

    return (
      <Requires
        key={menuItem.name}
        accessRights={menuItem.accessRights}
        countries={menuItem.countries}
      >
        <span
          key={key}
          onClick={onClickHandler}
          {...s.with('menuItem')('menuItem')}
        >
          <Icon type={icon} style={{ display: 'flex' }} />
          {name}
        </span>
      </Requires>
    );
  }

  renderFooterItem(menuItem) {
    const { onClick, name, icon: Icon } = menuItem;
    const { styles: s } = this.props;
    const key = createKey(name);

    const onClickHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.handleTrackIconClick(name);
      this.props.closePopout();
      onClick(this.props);
    };

    return (
      <Requires
        key={menuItem.name}
        accessRights={menuItem.accessRights}
        countries={menuItem.countries}
      >
        <Box onClick={onClickHandler} key={key} {...s('footerSegment')}>
          <Box {...s('footerSegmentWrapper')}>
            <Icon />
            {name}
          </Box>
        </Box>
      </Requires>
    );
  }

  render() {
    const { styles: s, isMobile, closePopout } = this.props;
    const { config } = this.state;

    return (
      <Box {...s('container')} data-testid='GlobalAdd.menu'>
        {isMobile && (
          <div {...s('closeContainer')} onClick={closePopout}>
            <Icon type={ICONS.CLOSE} {...s('closeButton')} />
          </div>
        )}

        <Box {...s('mainWrapper')}>
          <Box {...s('leftSegment')}>
            {config.LEFT_MENU.map(this.renderMenuItem)}
          </Box>

          <Box {...s('divider')} />

          <Box {...s('rightSegment')}>
            {config.RIGHT_MENU.map(this.renderMenuItem)}
          </Box>
        </Box>

        <Box {...s('footerWrapper')}>
          {config.FOOTER_MENU.map(this.renderFooterItem)}
        </Box>
      </Box>
    );
  }
}

@styled(
  StyleSheet({
    container: {
      color: 'white',
      height: '36px',
      width: '4.8vw',
      minWidth: '43px',
      maxWidth: '80px',
      borderRadius: '3px 0 0 3px',
      backgroundColor: 'rgba(0,0,0,0.15)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        width: '15px',
        height: '15px'
      },

      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.15)'
      }
    }
  })
)
@withErrorDialog
@withDialog(NoticeDialog, { propName: 'notice' })
@autobind
class GlobalAdd extends PureComponent {
  render() {
    const {
      styles: s,
      isMobile,
      notice,
      errorDialog,
      currentAgencyColor
    } = this.props;
    return (
      <NavigationPopout
        placement={PLACEMENTS.BOTTOM}
        Content={({ close }) => (
          <GlobalAddMenu
            isMobile={isMobile}
            closePopout={close}
            notice={notice}
            errorDialog={errorDialog}
          />
        )}
      >
        <Box {...s('container')} data-testid='GlobalAdd.button'>
          <Icon
            color={headerColorContrast(currentAgencyColor)}
            type={ICONS.ADD}
          />
        </Box>
      </NavigationPopout>
    );
  }
}

export default GlobalAdd;
