import React from 'react';

import { Dropdown } from 'view/components/action-menu';

import { ActionMenuProps } from './core';

interface MergeActionMenuProps extends Omit<ActionMenuProps, 'items'> {
  sendEmail: () => void;
  sendSms: () => void;
}

function MergeActionMenu({
  sendEmail,
  sendSms,
  placement = 'bottom-start',
  ...props
}: MergeActionMenuProps) {
  const menuItems = [
    {
      label: 'Send Email',
      onClick: sendEmail
    },
    {
      label: 'Send SMS',
      onClick: sendSms
    }
  ];

  return (
    <Dropdown placement={placement} {...props} items={menuItems}>
      Merge
    </Dropdown>
  );
}

export default MergeActionMenu;
