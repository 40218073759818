import _ from 'lodash';
import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { APP_MENU } from 'theme/legacy/components';
import Icon, { ICONS } from 'shared/components/icon';
import {
  isOwner,
  hasPermission
} from 'view/components/navigation/app-search/utils';
import Option from './option';
import ImageWithError from 'view/components/image-with-error';
import { getHazardly } from 'shared/utils/prefix-hell';

const override = {
  Option: StyleSheet({
    iconBox: {
      width: '45px',
      borderRadius: '50%',

      '& img': {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        objectFit: 'cover'
      }
    }
  })
};

@styled(APP_MENU.RECORDS)
class ContactOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles: s, option, isActive } = this.props;
    const { data: contact, label, value } = option;

    const splitNames = label.split(' ');
    const firstName = _.first(splitNames);
    const lastName = _.last(splitNames);
    const email = _.get(contact, 'email_address', '');
    const mobile = _.get(contact, 'phone_number', '');
    const address = (_.get(contact, 'address') || '').split('\n').join(', ');
    const profileImage = getHazardly(contact, 'contact_image.url');
    const userSecurityRights = getHazardly(contact, '_security_user_rights');
    const systemOwnerUser = _.get(contact, 'system_owner_user');
    const myContact = isOwner(systemOwnerUser);
    let ContactInitials = [];

    if (splitNames.length === 1) {
      ContactInitials = [
        firstName.toUpperCase()[0],
        firstName.toUpperCase()[1]
      ];
    } else {
      ContactInitials = [firstName.toUpperCase()[0], lastName.toUpperCase()[0]];
    }

    return (
      <StylesProvider components={override} prioritiseParentStyles={false}>
        <Option
          key={`contact-option-${value}`}
          isActive={isActive}
          label={label}
          IconBox={[
            myContact && (
              <Icon
                key='owner-flag'
                {...s('ownerIcon')}
                type={ICONS.APP_SEARCH.OWNER}
              />
            ),
            !hasPermission(userSecurityRights, 'read') && (
              <Icon
                key='permission-flag'
                {...s('permissionIcon')}
                type={ICONS.APP_SEARCH.LOCK}
              />
            ),
            profileImage && (
              <ImageWithError
                key='profile-image'
                src={profileImage}
                width='auto'
                height='45px'
                ErrorProp={ContactInitials}
              />
            ),
            !profileImage && ContactInitials
          ]}
          OptionMeta={[
            <span {...s('name', 'ellipsis')} key='name'>
              {splitNames.join(' ')}
            </span>,
            <span key='mobile_email' {...s('meta', 'ellipsis', 'light')}>
              {[mobile, email].filter(Boolean).join(' • ')}
            </span>,
            <span key='address' {...s('meta', 'ellipsis', 'light')}>
              {address}
            </span>
          ]}
          OptionExtraMeta={[
            <span key='id' {...s('meta', 'extra', 'feint')}>
              #{value}
            </span>,
            myContact && (
              <span key='owner' {...s('meta', 'extra', 'light')}>
                My Contact
              </span>
            ),
            !myContact && systemOwnerUser.name && (
              <span key='owner' {...s('meta', 'extra', 'light')}>
                {systemOwnerUser.name}
              </span>
            )
          ]}
        />
      </StylesProvider>
    );
  }
}

export default ContactOption;
