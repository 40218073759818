import Box from '@rexlabs/box';
import types from 'prop-types';
import React, { PureComponent } from 'react';
import { ButtonBar } from 'view/components/button-bar';
import Dialog from 'view/components/dialog/index';
import { DefaultButton } from 'view/components/button';
import { DialogBody as Body } from 'components/text/dialog-body';
import _ from 'lodash';

class NoticeDialog extends PureComponent {
  static propTypes = {
    title: types.string,
    closeButtonText: types.string,
    closeButtonColor: types.string,
    message: types.string.isRequired,
    onClose: types.func
  };

  static defaultProps = {
    title: 'Notice',
    closeButtonText: 'Close',
    closeButtonColor: 'light',
    top: Math.max(window.innerHeight / 2 - 200, 0),
    width: 500
  };

  render() {
    const {
      title,
      closeButtonText,
      closeButtonColor,
      message,
      children,
      closeDialog,
      onLoad,
      top,
      width,
      messageStyles,
      callback
    } = this.props;

    function handleClose() {
      closeDialog();
      if (callback) callback();
    }

    return (
      <Dialog
        title={title}
        width={width}
        closeDialog={closeDialog}
        onLoad={onLoad}
        top={top}
      >
        <Box>
          {children ? (
            _.isString(children) ? (
              <Body>{children}</Body>
            ) : (
              <Box>{children}</Box>
            )
          ) : (
            <Body dark {...messageStyles}>
              {message}
            </Body>
          )}
        </Box>

        <ButtonBar>
          <DefaultButton
            key='close'
            {...{ [closeButtonColor]: true }}
            onClick={handleClose}
          >
            {closeButtonText}
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default NoticeDialog;
