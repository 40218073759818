import { LeadsResponse } from 'data/models/entities/leads';

export function getRequiredRecordStatus(data?: LeadsResponse) {
  const missingRequiredRecordTypes: string[] = [];

  if (!data) {
    return {
      missingRequiredRecordTypes,
      hasRequiredRelatedRecords: !missingRequiredRecordTypes.length
    };
  }

  const {
    listing,
    contact,
    property,
    project_stage: projectStage,
    project,
    lead_type
  } = data;

  const leadType = {
    appraisalRequest: 'appraisal_request',
    listingEquiry: 'listing_enquiry',
    general: 'general'
  };

  const isListingEnquiry = lead_type?.id === leadType.listingEquiry;
  const isAppraisalRequest = lead_type?.id === leadType.appraisalRequest;

  // NOTE: When refering to 'listing' here we're refering to any other listed property type.
  // We do not need seperate constants for projects and stages as we treat them all the same.
  const hasListing = listing || projectStage || project;

  if (!contact) {
    missingRequiredRecordTypes.push('contact');
  }

  if (isListingEnquiry && !hasListing) {
    missingRequiredRecordTypes.push('listing');
  }

  if (isAppraisalRequest && !property) {
    missingRequiredRecordTypes.push('property');
  }

  return {
    missingRequiredRecordTypes,
    hasRequiredRelatedRecords: !missingRequiredRecordTypes.length
  };
}
