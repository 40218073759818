import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS } from 'theme';

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.BACKGROUNDS.SAND_LIGHT
  },
  icon: {
    height: '60%',
    width: '60%',
    color: '#D4D3CD'
  }
});

@styled(defaultStyles)
class ImagePlaceholder extends Component {
  static defaultProps = {
    Icon: () => null
  };

  render() {
    const { styles: s, Icon } = this.props;
    return (
      <Box
        {...s('container')}
        alignItems='center'
        justifyContent='center'
        height='100%'
        width='100%'
      >
        <Icon {...s('icon')} />
      </Box>
    );
  }
}

export default ImagePlaceholder;
