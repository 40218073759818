import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Property,
  CustomFields
} from 'features/custom-reporting/data/fragments';
import {
  Contact,
  ContactEmails,
  ContactPhones,
  MatchProfile
} from 'features/custom-reporting/modules/contacts/contact-data-fragments';
import { Lead } from 'features/custom-reporting/modules/leads/leads-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const leadsReportQueryDefaultVariables = {
  include_subresource_property: false,
  include_subresource_listing: false,
  include_subresource_contact: false,
  include_subresource_assignee: false,
  include_contact_custom_fields: false,
  contact_custom_field_ids: [],
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_property_custom_fields: false,
  property_custom_field_ids: []
};

export const LeadsReportQuery = gql`
  ${User}
  ${Listing}
  ${Property}
  ${Contact}
  ${ContactPhones}
  ${ContactEmails}
  ${MatchProfile}
  ${Lead}
  ${CustomFields}

  query (
    $include_subresource_property: Boolean!
    $include_subresource_listing: Boolean!
    $include_subresource_contact: Boolean!
    $include_subresource_assignee: Boolean!
    $include_contact_custom_fields: Boolean!
    $contact_custom_field_ids: [Int!]!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: leads(
      use_case: reporting
      limit: $limit
      cursor: $cursor
      criteria: $criteria
      order_bys: $order_bys
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...Lead
        assignee @include(if: $include_subresource_assignee) {
          ...User
        }
        listing @include(if: $include_subresource_listing) {
          ...Listing
          custom_field_values(field_ids: $listing_custom_field_ids)
            @include(if: $include_listing_custom_fields) {
            ...CustomFields
          }
          listing_agent_1 {
            name
          }
          listing_agent_2 {
            name
          }
          system_owner_user {
            ...User
          }
        }
        property @include(if: $include_subresource_property) {
          ...Property
          custom_field_values(field_ids: $property_custom_field_ids)
            @include(if: $include_property_custom_fields) {
            ...CustomFields
          }
          system_owner_user {
            ...User
          }
        }
        contact @include(if: $include_subresource_contact) {
          ...Contact
          custom_field_values(field_ids: $contact_custom_field_ids)
            @include(if: $include_contact_custom_fields) {
            ...CustomFields
          }
          phones {
            ...ContactPhones
          }
          emails {
            ...ContactEmails
          }
          system_owner_user {
            ...User
          }
          latest_match_profile {
            ...MatchProfile
          }
        }
      }
    }
  }
`;
