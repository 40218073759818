import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';
import SubstantiationsList from 'view/components/lists/substantiations';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import substantiationsModel from 'data/models/entities/substantiations';
import { autobind } from 'core-decorators';
import { withDialog } from 'shared/hocs/with-dialog';

import EditSubstantiationDialog from './edit';

@withDialog(EditSubstantiationDialog, { propName: 'editDialog' })
@withModel(substantiationsModel)
@autobind
class SubstantiationsListDialog extends PureComponent {
  state = {
    isLoading: true
  };

  openAddDialog() {
    const { contacts, editDialog } = this.props;
    editDialog.open({
      contacts,
      // HACK: passing this in hard coded because the check is for length and we don't want any dialog launched from here to have the View all option
      substantiationCount: 2,
      refreshList: this.fetchLists,
      callback: (data, openNewSubstantiation) => {
        if (openNewSubstantiation) {
          setTimeout(() => {
            openNewSubstantiation();
          }, 0);
        }
        // TODO: rejig this so that it doesn't make another query
        this.setState({ isLoading: true }, () => {
          this.fetchLists();
        });
      }
    });
  }

  openEditDialog(data) {
    const { editDialog } = this.props;
    editDialog.open({
      id: data.id,
      // NOTE: same as above.
      substantiationCount: 2,
      refreshList: this.fetchLists,
      callback: (data, openNewSubstantiation) => {
        if (openNewSubstantiation) {
          setTimeout(() => {
            openNewSubstantiation();
          }, 0);
        }

        // TODO: rejig this so that it doesn't make another query
        this.setState({ isLoading: true }, () => {
          this.fetchLists();
        });
      }
    });
  }

  fetchLists() {
    const queries = _.get(this.props, 'substantiations');
    const ids = _.map(this.props.contacts, (contact) => {
      return _.get(contact, 'contact.id');
    });

    const activeSubs = queries.getActiveSubstantiations({ ids });
    const archivedSubs = queries.getArchivedSubstantiations({ ids });
    Promise.all([activeSubs, archivedSubs]).then(
      ([activeSubs, archivedSubs]) => {
        this.setState({
          activeSubs,
          archivedSubs,
          isLoading: false
        });
      }
    );
  }

  componentDidMount() {
    this.fetchLists();
  }

  closeDialog() {
    const { callback, closeDialog } = this.props;
    if (callback) {
      callback();
    }
    closeDialog();
  }

  render() {
    return (
      <Dialog
        title='All Substantiations'
        height={400}
        closeDialog={this.closeDialog}
      >
        <SubstantiationsList
          isLoading={this.state.isLoading}
          onAddClick={this.openAddDialog}
          onViewClick={this.openEditDialog}
          items={_.get(this.state, 'activeSubs.data.result.rows', [])}
        />
        <Box mt={20}>
          <SubstantiationsList
            isLoading={this.state.isLoading}
            isArchived
            onViewClick={this.openEditDialog}
            items={_.get(this.state, 'archivedSubs.data.result.rows', [])}
          />
        </Box>
      </Dialog>
    );
  }
}

// list.items[""0""].status.id

export default SubstantiationsListDialog;
