import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'listingPortalUploads';

const actionCreators = {
  updatePortal: {
    request: ({ listingId, premiumSettings }) =>
      api.post('ListingPortalUploads::updatePortals', {
        listing_id: listingId,
        update_map: premiumSettings
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator(TYPE).createEntityModel({ actionCreators });
