import React, { PureComponent } from 'react';
import DefaultOption from './default';
import _ from 'lodash';

class ModelOption extends PureComponent {
  render() {
    const Option = _.get(
      this.props,
      'option.model.select.Option',
      DefaultOption
    );
    return <Option {...this.props} />;
  }
}

export default ModelOption;
