import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface InputTextProps extends BaseTextProps {
  formField?: boolean;
  bold?: boolean;
  clickable?: boolean;
}

export function InputLabel({
  formField,
  bold,
  clickable,
  ...props
}: InputTextProps) {
  return (
    <Text
      is='label'
      {...props}
      type={map({
        INPUT_LABEL: true,
        FORM_FIELD: formField,
        BOLD: bold,
        CLICKABLE: clickable
      })}
    />
  );
}
