import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS, TEXTS } from 'theme';
import { OptionEventHandler } from 'view/components/navigation/app-search/base';
import { OPTION_TYPES } from 'view/components/navigation/app-search/utils';
import Icon, { ICONS } from 'shared/components/icon';

@styled(
  StyleSheet({
    container: {
      userSelect: 'none',
      cursor: 'pointer',
      width: '100%',
      padding: `0 ${PADDINGS.S}`,
      height: '35px'
    },
    resultBoxLeft: {
      width: '8%',
      textAlign: 'left'
    },
    resultBoxMiddle: {
      width: '72%',
      textAlign: 'left'
    },
    resultBoxRight: {
      width: '20%',
      textAlign: 'right'
    },
    media: {
      marginRight: PADDINGS.M
    },
    body: {
      width: '100%',
      '& > *:first-child': {
        marginBottom: PADDINGS.XXS
      }
    },
    isActive: {
      background: COLORS.COOL_GREY
    },
    iconBox: {
      backgroundColor: COLORS.APP_SEARCH.TILE_BACKGROUND,
      color: COLORS.BLUE_GREY,
      width: '45px',
      height: '45px',
      marginRight: PADDINGS.S
    },
    details: {},
    description: {
      whiteSpace: 'nowrap',
      width: '280px',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    name: {
      ...TEXTS.APP_SEARCH.RECORD_NAME
    },
    meta: {
      ...TEXTS.APP_SEARCH.META_DATA
    },
    metaDark: {
      color: COLORS.DARK_GREY
    },
    feint: {
      color: COLORS.BLACK_30_OPACITY
    },
    id: {
      textAlign: 'right'
    },
    icon: {
      color: COLORS.BLUE_GREY
    }
  })
)
@autobind
class SectionRecents extends Component {
  /*
  |-------------------------------------------------------------------------------
  | getRecordMeta
  |-------------------------------------------------------------------------------
  |
  | Retrieves the current options title as each options data object is very
  | uniquely constructed.
  |
  */
  getRecordMeta(option) {
    const { type, name, id, ...rest } = option;
    return {
      type:
        type.toLowerCase() === 'person'
          ? OPTION_TYPES.CONTACT
          : type.toUpperCase(),
      description: name,
      label: name,
      value: id,
      ...rest
    };
  }

  render() {
    const {
      styles: s,
      options,
      onHover,
      onSelect,
      scrollIntoView,
      activeIndex,
      // Prevent sending term through
      ...props
    } = this.props;

    return (
      <Box {...props}>
        {options.map((option) => {
          const { description, value, type, label, index } =
            this.getRecordMeta(option);
          const isActive = activeIndex.toString() === option.index.toString();

          return (
            <OptionEventHandler
              key={value}
              index={index}
              option={option}
              onSelect={onSelect}
              onHover={onHover}
              scrollIntoView={scrollIntoView}
            >
              <Box
                {...s('container', { isActive })}
                flexDirection='row'
                flexWrap='no-wrap'
                justifyContent='space-between'
                alignItems='center'
                title={label}
              >
                <Box {...s('resultBoxLeft')} pr={PADDINGS.S}>
                  <Icon {...s('icon')} type={ICONS.APP_SEARCH[type]} />
                </Box>

                <Box
                  {...s('resultBoxMiddle')}
                  flexDirection='column'
                  flexWrap='no-wrap'
                >
                  <span {...s('metaDark', 'description')}>{description}</span>
                </Box>

                <Box {...s('resultBoxRight')} flexDirection='column'>
                  <span {...s('id', 'feint')}>#{value}</span>
                </Box>
              </Box>
            </OptionEventHandler>
          );
        })}
      </Box>
    );
  }
}

export default SectionRecents;
