import React from 'react';
import { Generator } from 'shared/utils/models';
import { api, transformItem } from 'shared/utils/api-client';
import Value from 'view/components/input/select/values';
import Fixture from 'view/components/input/select/fixtures/campaigns';
import _ from 'lodash';
import LocalStorage from 'shared/utils/local-storage';

const actionCreators = {
  generateReport: {
    request: () => Promise.resolve({}),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  addLog: {
    request: () => Promise.resolve({}),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  createFromTemplate: {
    request: ({ templateId, data }) =>
      api
        .post('Campaigns::createFromTemplate', {
          template_id: templateId,
          data
        })
        .then(transformItem),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchItemWithMembers: {
    request: (campaignId) =>
      api
        .batch([
          ['Campaigns::read', { id: campaignId }],
          [
            'CampaignMembers::search',
            {
              criteria: [
                {
                  name: 'campaign.id',
                  type: '=',
                  value: campaignId
                }
              ]
            }
          ]
        ])
        .then(({ data }) => {
          const [campaignResponse, campaignMembersResponse] = data.result;

          return {
            campaignResponse,
            campaignMembersResponse
          };
        }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getCampaignsFromIds: {
    request: (campaignIds) =>
      api.post('Campaigns::search', {
        criteria: [{ name: 'id', type: 'in', value: campaignIds }]
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const TYPE = 'campaigns';
const campaignsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

const handleFixtureClick = (callback, props) => callback(props.term);
let shouldShowFixture = false;

try {
  shouldShowFixture = !!(_.get(
    LocalStorage.get('account_info'),
    'privileges'
  ) || {})['campaigns.create'];
} catch (e) {}

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
campaignsModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('Campaigns::autocomplete', {
        search_string: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((campaign) => ({
          value: campaign.id,
          label: campaign.name,
          data: campaign,
          model: campaignsModel
        }))
      ),

  Fixture: shouldShowFixture
    ? (props) => (
        <Fixture
          text='Create New Campaign'
          onClick={(cb) => handleFixtureClick(cb, props)}
          {...props}
        />
      )
    : null,
  Value: (props) => <Value {...props} service={TYPE} />
};

export default campaignsModel;
