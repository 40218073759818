import React from 'react';

import Box from '@rexlabs/box';

import { Body } from 'components/text/body';
import { Heading } from 'components/text/heading';

export function StatusTotal({
  total,
  status
}: {
  total: number;
  status: string;
}) {
  return (
    <Box flexDirection='row' alignItems='baseLine'>
      <Box pr='5px'>
        <Heading>{total}</Heading>
      </Box>
      <Body normal regular medium dark>
        {status}
      </Body>
    </Box>
  );
}
