import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Portal } from '@rexlabs/portal';
import { autobind } from 'core-decorators';
import { COLORS, PADDINGS, SHADOWS, TEXTS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import Spinner from 'shared/components/spinner';
import Draggable from 'react-draggable';
import Analytics from 'shared/utils/vivid-analytics';
import BetaTag from 'components/beta-tag';

const defaultStyles = StyleSheet({
  wrapOuter: {
    position: 'absolute',
    zIndex: 5
  },

  container: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.DIALOG,
    margin: '20px'
  },

  titleContainer: {
    backgroundColor: COLORS.PRIMARY.GREY_DARK,
    padding: `0 ${PADDINGS.S}`,
    cursor: 'move'
  },

  title: {
    ...TEXTS.HEADERS.DEFAULT_MODAL_HEADING,
    userSelect: 'none',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },

  content: {},

  contentPadding: {
    padding: PADDINGS.M
  },

  closeIcon: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.STATES.ACTIVE,
    cursor: 'pointer',
    userSelect: 'none'
  },

  wrapSpinner: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10
  },

  spinner: {
    position: 'relative',
    zIndex: 20
  },

  alwaysRenderChildrenLoading: {
    display: 'none !important'
  },

  isDragging: {
    userSelect: 'none'
  },

  frostedContainer: {
    transform: 'translateZ(0)',
    backfaceVisibility: 'hidden',
    background: 'rgba(205,214,220,0.5)',
    boxShadow:
      '0px 1.2px 29.92px 0px rgba(81, 160, 221, 0.2), 10px 10px 29px 0px rgba(255, 255, 255, 0.25) inset, 0 0 0 1px rgba(255, 255, 255, 0.35) inset',
    backdropFilter: 'blur(25px) saturate(3) contrast(1.35)',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  frostedTitleContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    background: 'none'
  },
  frostedTitle: {
    color: 'rgba(33, 58, 106, 1)',
    fontWeight: 600
  },
  frostedCloseIcon: {
    color: 'rgba(33, 58, 106, 1)'
  },
  isHidden: {
    opacity: 0
  }
});

let counter = 0;

@styled(defaultStyles)
@autobind
class Dialog extends PureComponent {
  static propTypes = {
    title: types.string.isRequired,
    width: types.number,
    height: types.number,
    isVisible: types.bool,
    buttonBar: types.element,
    closeDialog: types.func.isRequired,
    hasPadding: types.bool,
    'data-testid': types.string,
    variant: types.oneOf(['frosted', 'normal'])
  };

  static defaultProps = {
    title: 'Default Dialog Heading',
    width: 500,
    top: 0,
    isVisible: true,
    hasPadding: true,
    variant: 'normal'
  };

  static isDialog = true;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: props.isLoading,
      isDragging: false
    };
    // This is a instance unique id, used to ensure we don't reuse the Draggable component
    // when closing 1 dialog as we open another
    this.instanceId = ++counter;
    this.hasAlertedSegment = false;
  }

  handleSegment() {
    Analytics.track({
      event: 'Dialog Open',
      properties: {
        name: this.props.title
      }
    });
  }

  onLoad() {
    this.props.onLoad && this.props.onLoad();
    this.handleSegment();
  }

  componentDidMount() {
    if (!this.props.isLoading && this.props.onLoad) {
      this.onLoad();
    }
  }

  componentDidUpdate() {
    if (this.state.isLoading && !this.props.isLoading) {
      this.setState({ isLoading: false });
      this.onLoad();
    }
  }

  onStartDrag() {
    this.setState({ isDragging: true });
  }

  onStopDrag() {
    this.setState({ isDragging: false });
  }

  render() {
    const {
      styles: s,
      width,
      height,
      top,
      title,
      showBetaTag,
      children,
      variant,
      closeDialog,
      zIndex,
      isVisible,
      hasPadding,
      alwaysOnTop,
      showLoadingSpinner,
      useKey,
      // NOTE: the `alwaysRenderChildren` prop is a hack, to make sure whatever the
      // children need to do can be done while the overlay is showing the loading state
      // I discourage using it unless there are good reasons for it, in 99.9% this
      // can be avoided! ;)
      alwaysRenderChildren,
      'data-testid': testId
    } = this.props;

    const { isDragging } = this.state;

    return (
      <Portal target='dialogOverlayStack'>
        {this.state.isLoading && !alwaysRenderChildren ? (
          showLoadingSpinner ? (
            <Box
              {...s('wrapSpinner')}
              key='show-loading-spinner'
              alignItems='center'
              justifyContent='center'
              isDialog={true}
            >
              <Spinner {...s('spinner')} />
            </Box>
          ) : null
        ) : (
          <Draggable
            // NOTE: the key caused issues for "input request" dialogs for workflows for some
            // reason, so we disable it now by default and only opt in where we really need it!
            key={useKey ? `instance-${this.instanceId}` : undefined}
            id={`instance-${this.instanceId}`}
            isDialog={true}
            onStart={this.onStartDrag}
            onStop={this.onStopDrag}
            alwaysOnTop={alwaysOnTop}
            // enableUserSelectHack causes very expensive CSS reflows as it modifies
            // global body styles. So instead, we're handling disabling of user select
            // locally via our own isDragging class on the dialog.
            enableUserSelectHack={false}
            defaultPosition={{
              x: window.innerWidth / 2 - width / 2,
              y: height ? Math.max(window.innerHeight / 2 - height / 2, 0) : top
            }}
            handle={`.dialog-handle`}
            variant={variant}
            bounds='parent'
            {...(zIndex ? { zIndexOverride: zIndex } : {})}
          >
            <Box
              data-testid={testId}
              role={'dialog'}
              flexDirection={'column'}
              {...s.with('container', {
                isHidden: !isVisible,
                frostedContainer: variant === 'frosted',
                isDragging,
                alwaysRenderChildrenLoading:
                  alwaysRenderChildren && this.state.isLoading
              })({ width })}
            >
              <Box
                flexDirection={'row'}
                justifyContent={'space-between'}
                {...s.with('titleContainer', {
                  frostedTitleContainer: variant === 'frosted'
                })({ className: 'dialog-handle' })}
              >
                <Box
                  data-testid='dialog-title'
                  {...s('title', { frostedTitle: variant === 'frosted' })}
                >
                  {showBetaTag ? <BetaTag color={'#213A6A'} /> : null}
                  {title}
                </Box>
                <Icon
                  onClick={closeDialog}
                  type={ICONS.CLOSE_LARGE}
                  data-testid='Dialog.HeaderCloseButton'
                  {...s('closeIcon', {
                    frostedCloseIcon: variant === 'frosted'
                  })}
                />
              </Box>
              <Box {...s('content', { contentPadding: hasPadding })}>
                {children}
              </Box>
            </Box>
          </Draggable>
        )}
      </Portal>
    );
  }
}

export default Dialog;
