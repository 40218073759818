import _ from 'lodash';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

import LocalStorage from 'shared/utils/local-storage';
import { Generator } from 'shared/utils/models';
import { AdminMergeTemplateItem } from './admin-merge-templates';

type AccountId = string;

export type EmailTemplate = {
  value: string | AdminMergeTemplateItem;
  label: string;
};

export interface BaseSendSalesMemoFormValues {
  vendor: boolean;
  vendor_merge_template?: EmailTemplate;
  include_commission_details: boolean;
  purchaser: boolean;
  purchaser_merge_template?: EmailTemplate;
  vendor_solicitor: boolean;
  vendor_solicitor_merge_template?: EmailTemplate;
  vendor_solicitor_show_purchaser_contact_details: boolean;
  vendor_solicitor_show_vendor_contact_details: boolean;
  vendor_solicitor_include_chain_addresses: boolean;
  purchaser_solicitor: boolean;
  purchaser_solicitor_merge_template?: EmailTemplate;
  purchaser_solicitor_include_chain_addresses: boolean;
  purchaser_solicitor_show_purchaser_contact_details: boolean;
  purchaser_solicitor_show_vendor_contact_details: boolean;
  email_template: EmailTemplate;
  vendor_solicitor_include_vendor_contact_details_address: boolean;
  vendor_solicitor_include_vendor_contact_details_phone: boolean;
  vendor_solicitor_include_vendor_contact_details_email: boolean;
  vendor_solicitor_include_purchaser_contact_details_address: boolean;
  vendor_solicitor_include_purchaser_contact_details_phone: boolean;
  vendor_solicitor_include_purchaser_contact_details_email: boolean;
  purchaser_solicitor_include_vendor_contact_details_address: boolean;
  purchaser_solicitor_include_vendor_contact_details_phone: boolean;
  purchaser_solicitor_include_vendor_contact_details_email: boolean;
  purchaser_solicitor_include_purchaser_contact_details_address: boolean;
  purchaser_solicitor_include_purchaser_contact_details_phone: boolean;
  purchaser_solicitor_include_purchaser_contact_details_email: boolean;
  vendor_include_commission_details_percentage: boolean;
  vendor_solicitor_include_commission_details_percentage: boolean;
  purchaser_include_vendor_contact_details_address: boolean;
}

type SendSalesMemoFormValuesModelState = Record<
  AccountId,
  BaseSendSalesMemoFormValues
>;

const modelName = 'sendSalesMemoFormValues';

export const initialSendSalesMemoFormValues = {
  vendor: true,
  vendor_merge_template: undefined,
  include_commission_details: false,
  purchaser: true,
  purchaser_merge_template: undefined,
  vendor_solicitor: true,
  vendor_solicitor_merge_template: undefined,
  vendor_solicitor_show_vendor_contact_details: false,
  vendor_solicitor_show_purchaser_contact_details: false,
  vendor_solicitor_include_chain_addresses: false,
  purchaser_solicitor: true,
  purchaser_solicitor_merge_template: undefined,
  purchaser_solicitor_include_chain_addresses: false,
  purchaser_solicitor_show_vendor_contact_details: false,
  purchaser_solicitor_show_purchaser_contact_details: false,
  email_template: undefined
};

const actionCreators = {
  update: {
    reduce: (
      state: SendSalesMemoFormValuesModelState,
      action: { payload: Partial<BaseSendSalesMemoFormValues> }
    ) => {
      const accountId: AccountId =
        LocalStorage.get('account_info').office_details.id;

      const { payload } = action;

      const newState = {
        ...state,
        [accountId]: {
          ...state[accountId],
          ...payload
        }
      };

      return {
        ...newState
      };
    }
  }
};

export const sendSalesMemoFormValuesModel = new Generator<
  SendSalesMemoFormValuesModelState,
  typeof actionCreators
>(modelName).createModel({
  actionCreators
});

export const persistSendSalesMemoFormValues = persistReducer(
  {
    key: `rex.${modelName}`,
    storage: localForage
  },
  sendSalesMemoFormValuesModel
);
