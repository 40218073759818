import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  container: {
    padding: '5px',
    fontSize: '14px',
    fontWeight: 600,
    color: '#B2B1AB'
  }
});

@styled(defaultStyles)
class OptionCore extends PureComponent {
  render() {
    const { option, styles: s } = this.props;
    return <Box {...s('container')}>{option.label || option.value}</Box>;
  }
}

export default OptionCore;
