import React from 'react';
import { query } from '@rexlabs/model-generator';
import { RecordListScreen } from 'components/record-list-screen';
import listingsModel from 'data/models/entities/listings';
import { useListingsListActions } from '../data/record-list-actions';
import { useListingsListColumns } from '../data/record-list-columns';

const listingsQuery = query`{
  ${listingsModel} {
    id
    system_owner_user {
      name
    }
    system_ctime
    system_created_user {
      name
    }
    system_modtime
    system_modified_user {
      name
    }
  }
}`;

export function ListingsListScreen(props) {
  const listingsColumns = useListingsListColumns();
  const actions = useListingsListActions();
  return (
    <RecordListScreen
      {...props}
      query={listingsQuery}
      columns={listingsColumns}
      serviceName='Listings'
      actions={actions}
    />
  );
}
