import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { ZINDEX, BASE64_IMAGES } from 'theme';
import { autobind } from 'core-decorators';

const defaultStyles = StyleSheet({
  button: {
    position: 'absolute',
    top: '40px',
    right: '40px',
    width: '70px',
    height: '70px',
    background: `url(${BASE64_IMAGES.OVERLAY_CLOSE}) top left no-repeat`,
    cursor: 'pointer',
    zIndex: ZINDEX.REXDIALOG,
    border: '0 none',

    ':focus': {
      outline: 'none'
    }
  },

  hide: {
    display: 'none'
  },

  show: {
    display: 'block'
  }
});

@styled(defaultStyles)
@autobind
class OverlayCloseButton extends PureComponent {
  render() {
    const { styles: s, onClick, hide, show } = this.props;
    return <button {...s('button', { hide, show })} onClick={onClick} />;
  }
}

export default OverlayCloseButton;
