import {
  formatStandardNotification,
  useFileDownloadDialog,
  useViewMassActionResultList
} from 'components/record-list-screen/utils/mass-action-utils';
import { useClassicDialog } from 'hooks/use-classic-dialog';
import { useCallback } from 'react';
import { partition } from 'lodash';

export function useShowAsyncExportResults() {
  const viewMassActionResultList = useViewMassActionResultList();
  const asyncFileResultDialog = useClassicDialog('asyncFileResult');
  const openFileDownloadDialog = useFileDownloadDialog('export');

  const openAsyncFileResultDialog = useCallback(
    ({ data, humanName, className, mappingFn, records }) => {
      // TODO: here we have diseased classic code having to live within shell.
      //  We should cut it off as soon as we can before the infection spreads.
      //  Need to look at finding a cleaner abstraction for these configs and
      //  hide all the classic specific code somewhere specific to the classic bridge.
      const options = {
        title: humanName + ' Exported',
        resultButtons: [
          {
            class: '',
            text: 'Download or Email Exported Data',
            click: () => openFileDownloadDialog({ file: data.url })
          }
        ],
        icon: 'icon-down-white size-35-35 text-bottom hspace-0-s',
        message: () => [
          {
            class: 'h2 large white-font',
            text: 'Export Complete',
            click: null
          }
        ],
        notifications: (data) => {
          const mappedResults = Object.keys(data.result).map((key) => ({
            ...data.result[key],
            id: key,
            data: records?.find((record) => record.id === key)
          }));

          const [successes, failures] = partition(
            mappedResults,
            ({ result_type }) => result_type === 'success'
          );

          return [
            formatStandardNotification({
              value: successes.length + failures.length,
              label: 'records chosen'
            }),
            formatStandardNotification({
              value: failures.length,
              label: 'could not be exported',
              linkText: failures.length === 0 ? '' : 'view error log',
              linkAction: () =>
                viewMassActionResultList({
                  records: failures,
                  options: {
                    title: 'Failed ' + humanName,
                    mappingFn,
                    className
                  }
                })
            }),
            formatStandardNotification({
              value: successes.length,
              label: 'exported successfully',
              linkText: successes.length === 0 ? '' : 'view list',
              linkAction: () =>
                viewMassActionResultList({
                  records: successes,
                  options: {
                    title: 'Exported ' + humanName,
                    mappingFn,
                    className
                  }
                })
            })
          ];
        }
      };

      asyncFileResultDialog.open({
        data,
        options
      });
    },
    [asyncFileResultDialog, openFileDownloadDialog, viewMassActionResultList]
  );

  return openAsyncFileResultDialog;
}
