import React from 'react';

import { ColumnType } from '../';

interface Props {
  col: ColumnType;
  onColumnClick?: (object, Event) => any;
}

function ClasicListColumnHeader({ col, onColumnClick }: Props) {
  return (
    <th
      onMouseDown={(e) => onColumnClick?.(col, e)}
      onTouchStart={(e) => onColumnClick?.(col, e)}
      className={col.sortable ? 'sortable' : ''}
      style={{ width: col.width }}
    >
      <span className={col.orderBy || ''}>{col.name}</span>
    </th>
  );
}

export default React.memo(ClasicListColumnHeader);
