import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { TextInput } from '@rexlabs/text-input';
import { EntitySelect } from 'view/components/input/select';
import { autobind } from 'core-decorators';

import { withRegion } from 'src/hocs/with-region';
import _ from 'lodash';

import { mapContractToOption } from 'features/listings/data/utils';
import { contractsModel } from 'features/listings/data';
import listingsModel from 'data/models/entities/listings';

const defaultStyles = StyleSheet({
  inputs: {
    marginRight: PADDINGS.XS,
    width: '50%'
  },
  hiddenField: {
    display: 'none'
  },
  fieldContainer: {
    width: '100%'
  }
});

@withRegion
@styled(defaultStyles)
@autobind
class ListingOfferInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lastListingValue: '',
      shouldDoNoValueContractSearch: false
    };
  }

  handleListingChange({ target }) {
    const { onChange } = this.props;
    const fakeEvent = {
      target: {
        value: {
          listingValue: target.value,
          offerValue: ''
        }
      }
    };

    onChange(fakeEvent);
  }

  handleOfferChange({ target }) {
    const { value, onChange } = this.props;
    const fakeEvent = {
      target: {
        value: {
          listingValue: _.get(value, 'listingValue'),
          offerValue: target.value
        }
      }
    };
    onChange(fakeEvent);
  }

  render() {
    const {
      styles: s,
      value,
      name,
      initialListingOptions,
      initialContractOptions,
      region
    } = this.props;
    const { shouldDoNoValueContractSearch, lastListingValue } = this.state;
    // Doing this in the render so the contract EntitySelect can get the updated listingId prop for the search
    if (
      _.get(value, 'listingValue') !== lastListingValue &&
      _.get(value, 'listingValue') !== undefined
    ) {
      this.setState({
        shouldDoNoValueContractSearch: true,
        lastListingValue: _.get(value, 'listingValue')
      });
    } else if (shouldDoNoValueContractSearch) {
      this.setState({ shouldDoNoValueContractSearch: false });
    }

    const hasListingValue = !!_.get(value, 'listingValue', null);

    return (
      <Box {...s('fieldContainer')} flexDirection={'row'}>
        <div {...s('hiddenField')}>
          <TextInput value={_.get(value, 'ltoID', null)} />
        </div>
        <Box {...s('inputs')}>
          <EntitySelect
            name={`${name}-listing`}
            models={[listingsModel]}
            onChange={this.handleListingChange}
            value={_.get(value, 'listingValue')}
            options={initialListingOptions}
            hasFixtures={false}
          />
        </Box>
        <Box {...s('inputs')}>
          <EntitySelect
            name={`${name}-offer`}
            shouldDoNoValueSearch={shouldDoNoValueContractSearch}
            models={[contractsModel]}
            listingId={_.get(value, 'listingValue')}
            onChange={this.handleOfferChange}
            region={region}
            value={_.get(value, 'offerValue')}
            options={initialContractOptions.map((contract) =>
              mapContractToOption(contract, region)
            )}
            disabled={!hasListingValue}
          />
        </Box>
      </Box>
    );
  }
}

export default ListingOfferInput;
