import { CalendarEventData } from 'data/models/entities/calendar-events';
import { ICONS } from 'shared/components/icon';

export const DEFAULT_CALENDAR_ICONS = {
  OPEN_HOME: ICONS.OPEN_HOME,
  AUCTION: ICONS.AUCTION,
  BUSY: ICONS.EYE_CROSSED,
  UNCONFIRMED: ICONS.UNCONFIRMED_APPOINTMENT
};

export function isPrivateEvent(event: CalendarEventData) {
  return event?.access_level?.id === 'availability';
}

export function getCalendarEventStatus({ event, iconOverrides = {} }) {
  const icons = {
    ...DEFAULT_CALENDAR_ICONS,
    ...iconOverrides
  };
  const title = event?.title;
  const appointmentTypeCategory = event?.appointment_type?.category?.id;

  const isEventPrivate = isPrivateEvent(event);
  const isEventCancelled =
    !isEventPrivate && event?.event_status?.id === 'cancelled';
  const isEventPending =
    !isEventPrivate && event?.event_status?.id === 'pending';
  const isEventTransparent =
    !isEventPrivate && event?.event_status?.id === 'transparent';
  const isEventBusy =
    event?.security_user_rights?.includes('read_availability');

  const isInspection = [
    'private_inspection',
    'unaccompanied_inspection'
  ].includes(appointmentTypeCategory);

  const isUnconfirmed =
    !isEventPrivate &&
    isInspection &&
    !(event.guests_confirmed && event.vendors_confirmed);

  const Icon =
    title === 'Open Home'
      ? icons.OPEN_HOME
      : title === 'Auction'
      ? icons.AUCTION
      : isEventBusy
      ? icons.BUSY
      : isUnconfirmed
      ? icons.UNCONFIRMED
      : null;

  return {
    isEventPrivate,
    isInspection,
    isEventPending,
    isEventTransparent,
    isUnconfirmed,
    isEventCancelled,
    isEventBusy,
    Icon
  };
}
