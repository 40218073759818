import React, { useState, useEffect } from 'react';
import { compose } from 'redux';

import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';

import { withPermissions } from 'src/hocs/with-permissions';

import { Body } from 'components/text/body';
import { Link as TextLink } from 'components/text/link';
import PaywallDialog from 'view/dialogs/paywall.js';

import LocalStorage from 'shared/utils/local-storage';
import { withDialog } from 'shared/hocs/with-dialog';
import { ICONS } from 'shared/components/icon';

// TODO: Move this to withPermissions when that is converted to TS
// https://app.clubhouse.io/rexlabs/story/53874/type-withpermissions
interface AddonsObj {
  advanced_custom_fields: number | string | null;
  agent_ledgers: number | string | null;
  alpha_enabled: number | string | null;
  beta_enabled: number | string | null;
  calendar: number | string | null;
  campaigns: number | string | null;
  click_to_dial: number | string | null;
  dashboards: number | string | null;
  deals: number | string | null;
  external_calendars: number | string | null;
  hide_real_estate: number | string | null;
  iframes: number | string | null;
  inbound_feeds: number | string | null;
  leads: number | string | null;
  live_support_chat: number | string | null;
  market_leads: number | string | null;
  projects: number | string | null;
  scheduled_reminders: number | string | null;
  system_workflows: number | string | null;
  trust_accounts: number | string | null;
  user_validation: number | string | null;
  workflows: number | string | null;
}

// TODO: Move this to withPermissions when that is converted to TS
// https://app.clubhouse.io/rexlabs/story/53874/type-withpermissions
interface PermissionsObj {
  addons: AddonsObj;
  check: (props: string) => boolean;
  hasAddon: (props: string) => boolean;
  hasAlphaEnabled: boolean;
  hasBetaEnabled: boolean;
}

// TODO: Below into the paywall dialog
// https://app.clubhouse.io/rexlabs/story/53875/type-the-paywall-dialog
interface PaywallDialogOptions {
  featureName: string;
  title: string;
  body: string;
  externalUrl: string;
}

interface AdminRecordViewPaywallBannerProps {
  addOn: string;
  paywallStoreRef: string;
  paywallDescription: string;
  paywallDialogOptions: PaywallDialogOptions;
  paywallDialog: { open: (props: PaywallDialogOptions) => void };
  permissions: PermissionsObj;
}

const { SPARKLES, CLOSE }: { SPARKLES: any; CLOSE: any } = ICONS;

const defaultStyles = StyleSheet({
  bannerContainer: {
    padding: '20px',
    margin: '30px 20px -10px 20px',
    backgroundColor: '#CDEBF9',
    flexDirection: 'row',
    width: 'auto'
  },

  textContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '18px',
    width: '100%'
  },

  sparklesIcon: {
    height: '18px',
    width: '18px',
    marginRight: '10px'
  },

  textLink: {
    marginLeft: '5px'
  },

  closeIcon: {
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '0',
    cursor: 'pointer'
  }
});

function AdminRecordViewPaywallBanner(
  props: AdminRecordViewPaywallBannerProps
) {
  const s = useStyles(defaultStyles);
  const {
    addOn,
    paywallStoreRef,
    paywallDescription,
    paywallDialogOptions,
    paywallDialog,
    permissions
  } = props;

  const [bannerVisible, setBannerVisible] = useState(
    !permissions.hasAddon(addOn) && LocalStorage.get(paywallStoreRef)
  );

  useEffect(() => {
    if ([undefined, null].includes(bannerVisible)) {
      LocalStorage.set(paywallStoreRef, true, true);
      setBannerVisible(true);
    }
  }, [bannerVisible, paywallStoreRef]);

  function hidePaywall() {
    LocalStorage.set(paywallStoreRef, false, true);
    setBannerVisible(false);
  }

  return (
    !!bannerVisible && (
      <Box {...s('bannerContainer')}>
        <Box {...s('textContainer')}>
          <SPARKLES {...s('sparklesIcon')} />
          <Body normal dark regular>
            {paywallDescription}
          </Body>
          <TextLink
            regular
            {...s('textLink')}
            onClick={() => {
              paywallDialog.open({
                ...paywallDialogOptions
              });
            }}
          >
            Learn More
          </TextLink>

          <CLOSE onClick={() => hidePaywall()} {...s('closeIcon')} />
        </Box>
      </Box>
    )
  );
}

export default compose(
  withPermissions,
  withDialog(PaywallDialog, { propName: 'paywallDialog' })
)(AdminRecordViewPaywallBanner);
