import Icon, { ICONS } from 'shared/components/icon';
import React from 'react';
import { useStyles } from '@rexlabs/styling';
import menuStyles from './styles';

function ExpandMenuItemToggle({
  handleModeUpdate,
  setActiveHoverId,
  navIsOpen,
  clearSelection
}) {
  const s = useStyles(menuStyles);
  return (
    <div
      onClick={() => {
        handleModeUpdate();
        clearSelection();
      }}
      onMouseEnter={() => setActiveHoverId('toggle')}
      onMouseLeave={() => setActiveHoverId(null)}
      {...s('expandMenu')}
      id={'menu-list-item-toggle'}
    >
      <Icon
        {...s('expandMenuIcon')}
        type={ICONS.CHEVRON_RIGHT}
        style={{ transform: navIsOpen ? 'rotateY(180deg)' : undefined }}
      />
    </div>
  );
}
export default React.memo(ExpandMenuItemToggle);
