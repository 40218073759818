import React from 'react';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import Dropdown from 'view/components/action-menu/dropdown';
import { useDataGrid } from 'features/custom-reporting/hooks/use-data-grid';
import { ActionMenuItem } from 'src/view/components/action-menu/core';

function trackExport(format) {
  Analytics.track({
    event: EVENTS.CUSTOM_REPORTING.EXPORT_DATA,
    properties: {
      format
    }
  });
}

export function ExportButton() {
  const { exportDataAsCsv, exportDataAsExcel } = useDataGrid();

  const exportItems: ActionMenuItem[] = [
    {
      label: 'Download as CSV file (.csv)',
      onClick: () => {
        trackExport('CSV');
        exportDataAsCsv?.();
      }
    },
    {
      label: 'Download as Excel file (.xls)',
      onClick: () => {
        trackExport('Excel');
        exportDataAsExcel?.();
      }
    }
  ];

  return <Dropdown items={exportItems}>Export</Dropdown>;
}
