import React from 'react';
import Box from '@rexlabs/box';

interface RecordPreviewContainerProps {
  children: React.ReactNode;
  width?: number;
}

export function RecordPreviewContainer({
  children,
  width = 380
}: RecordPreviewContainerProps) {
  return <Box width={width}>{children}</Box>;
}
