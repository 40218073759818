import _ from 'lodash';
import React from 'react';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import SuburbOption from 'view/components/input/select/options/suburb';
import Value from 'view/components/input/select/values';

const TYPE = 'suburbs';

const suburbModels = new Generator(TYPE).createSystemListModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
suburbModels.select = {
  autocomplete: (searchTerm) => {
    return api
      .post('SystemValues::autocompleteCategoryValues', {
        search_string: searchTerm,
        list_name: TYPE
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((suburb) => ({
          value: `${suburb.suburb_or_town}@${suburb.postcode}@${suburb.locality}`,
          label: suburb.text,
          data: suburb,
          model: suburbModels
        }))
      );
  },
  Option: SuburbOption,
  Value: (props) => <Value {...props} service={TYPE} />
};

export default suburbModels;
