import React from 'react';
import { useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS, PADDINGS } from 'theme';
import Tag from 'view/components/tag';
import Requires from 'view/containers/requires';
import { matchesActiveRoute } from '../utils';
import Separator from './separator';
import InfoItem from './info-item';
import menuStyles from './styles';

function MenuItem({
  item,
  index,
  navIsOpen,
  selected,
  hasMounted,
  activeRoute,
  agencyColor,
  setActiveHoverId,
  handleItemClick
}) {
  const s = useStyles(menuStyles);
  const {
    id,
    name,
    icon: ItemIcon,
    isSeparator,
    isInfo,
    match,
    accessRights,
    addons,
    countries,
    envConfigs,
    isExternalLink,
    isNew,
    withFlags,
    withoutFlags
  } = item;

  const labelVisible = navIsOpen || selected;

  if (isSeparator) {
    return <Separator index={index} />;
  }

  if (isInfo) {
    return <InfoItem item={item} index={index} />;
  }

  if (name) {
    return (
      <Requires
        key={`menu-${id}`}
        accessRights={accessRights}
        countries={countries}
        addons={addons}
        envConfigs={envConfigs}
        withFlags={withFlags}
        withoutFlags={withoutFlags}
      >
        <li
          {...s('listItem', 'mainListItem', {
            listItemActive: selected === id,
            listItemCurrent: matchesActiveRoute(activeRoute, match)
          })}
          id={`menu-list-item-${id}`}
          onClick={(e) => handleItemClick(item, e)}
          onMouseEnter={() => setActiveHoverId(id)}
          onMouseLeave={() => setActiveHoverId(null)}
        >
          {matchesActiveRoute(activeRoute, match) && (
            <div
              {...s('listItemCurrentIndicator')}
              style={{ backgroundColor: agencyColor }}
            />
          )}
          {ItemIcon && (
            <span {...s('icon')}>
              <ItemIcon />
            </span>
          )}
          <div
            {...s('label', {
              labelHidden: !labelVisible,
              labelVisible: labelVisible,
              noAnimate: !hasMounted
            })}
          >
            <span>{name}</span>
            {isNew && (
              <Box pl={PADDINGS.S} pr={PADDINGS.S}>
                <Tag
                  roundedEdges
                  white
                  backgroundColor={COLORS.PRIMARY.BLUE}
                  color={COLORS.PRIMARY.WHITE}
                  noMargin
                >
                  New
                </Tag>
              </Box>
            )}
            {isExternalLink && labelVisible && (
              <Box {...s('externalLinkIconWrapper')}>
                <Icon type={ICONS.EXTERNAL_LINK} {...s('externalLinkIcon')} />
              </Box>
            )}
          </div>
        </li>
      </Requires>
    );
  }

  return null;
}

export default React.memo(MenuItem);
