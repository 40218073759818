import { push, useWhereabouts } from '@rexlabs/whereabouts';
import { useCallback } from 'react';

export function useMode(): [string, (mode: string) => void] {
  const whereabouts = useWhereabouts();

  const mode: string = whereabouts.hashQuery?.section || 'default';

  const setMode = useCallback(
    (mode: string) => {
      push({
        config: {
          ...whereabouts,
          hash: undefined,
          hashQuery: {
            ...whereabouts.hashQuery,
            section: mode
          }
        }
      });
    },
    [whereabouts]
  );

  return [mode, setMode];
}
