import React from 'react';

import { LeadsResponse } from 'data/models/entities/leads';

import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import { ListingCell } from 'components/record-list-screen/cells/listing-cell';
import { ProjectCell } from 'components/record-list-screen/cells/project-cell';
import { ProjectStageCell } from 'components/record-list-screen/cells/project-stage-cell';
import { PropertyCell } from 'components/record-list-screen/cells/property-cell';

interface LeadAddressCellProps extends Omit<DefaultCellProps, 'record'> {
  data: LeadsResponse;
}
const testId = 'RecordList.LeadAddressCell';

export function LeadAddressCell(props: LeadAddressCellProps) {
  const { data } = props;
  const { listing, property, project, project_stage: projectStage } = data;

  return listing ? (
    <ListingCell {...props} value={listing} data-testid={testId} />
  ) : property ? (
    <PropertyCell {...props} value={property} data-testid={testId} />
  ) : project ? (
    <ProjectCell {...props} value={project} data-testid={testId} />
  ) : projectStage ? (
    <ProjectStageCell {...props} value={projectStage} data-testid={testId} />
  ) : (
    <DefaultCell {...props} value={null} data-testid={testId} />
  );
}
