import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

export interface MemoParameters {
  id?: string;
  location_id?: string;
  include_purchaser_contact_details: boolean;
  include_vendor_contact_details: boolean;
  include_commission_details?: boolean;
  include_chain_addresses?: boolean;
}

const generateReportRequest = (parameters: MemoParameters) =>
  api
    .post('ReportGenerator::generateReport', {
      id: 'contract',
      output_format: 'pdf_file_download',
      parameters
    })
    .then((response) => response.data.result);

const actionCreators = {
  generateReport: {
    request: (parameters: MemoParameters) => generateReportRequest(parameters),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  downloadReport: {
    request: (parameters: MemoParameters) =>
      generateReportRequest(parameters).then((downloadLink: any) => {
        const url = `https:${downloadLink}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator<{}, typeof actionCreators>(
  'sendSalesMemo'
).createModel({ actionCreators });
