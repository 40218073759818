import { LensesConfig } from 'components/record-list-screen/types';
import { useMemo } from 'react';

export function useBillsLenses() {
  return useMemo(
    (): LensesConfig => ({
      all: {
        title: 'All Bills',
        orderBy: {
          field: 'id',
          direction: 'desc'
        },
        criteria: []
      }
    }),
    []
  );
}
