import { useModelActions, useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import React, { useEffect } from 'react';
import { api } from 'shared/utils/api-client';

export default function RemoteLogout() {
  const session = useModelState(sessionModel);
  const sessionActions = useModelActions(sessionModel);

  useEffect(() => {
    if (session?.api_token && session?.api_region) {
      api.setBaseUrl(`${session.api_region.base_url}/v1/rex/`);
      sessionActions.logout({ silent: true, clearRemoteToken: true });
    }
  }, [session?.api_token]);

  return null;
}
