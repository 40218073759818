import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS, PADDINGS } from 'theme';

import { ICONS } from 'shared/components/icon';

import { Body } from 'components/text/body';

import { WrapperButton } from '../wrapper-button';

const ArrowDownIcon: any = ICONS.DASHBOARDS.TRIANGLE_DOWN;

type ReportingFilterButtonProps = React.PropsWithChildren<{
  Icon: any;
  showArrowIcon?: boolean;
  onClick?: (args?: any) => any;
  isActive?: boolean;
}>;

const defaultStyles = StyleSheet({
  icon: {
    height: '12px',
    color: COLORS.DARK_GREY
  },
  arrowIcon: {
    color: COLORS.PRIMARY.SAND
  }
});

export function ReportingFilterButton(props: ReportingFilterButtonProps) {
  const { Icon, children, showArrowIcon, isActive, onClick } = props;

  const s = useStyles(defaultStyles);

  return (
    <WrapperButton onClick={onClick}>
      <Box flexDirection='row' alignItems='center' sx={PADDINGS.XXS}>
        <Icon {...s('icon')} />
        <Body semibold dark normal underline={isActive}>
          {children}
        </Body>
        {showArrowIcon && <ArrowDownIcon {...s('arrowIcon')} />}
      </Box>
    </WrapperButton>
  );
}
