import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS, TEXTS } from 'theme';

const barEdge = 'inset 4px 4px 0px -2px rgba(0,0,0,0.22)';

@styled(
  StyleSheet({
    container: {
      width: '100%'
    },
    barFrame: {
      position: 'relative',
      width: '100%',
      height: PADDINGS.XXL,
      backgroundColor: COLORS.BACKGROUNDS.SAND,
      boxShadow: barEdge
    },
    progressBar: {
      width: '1rem',
      transition: 'width 0.4s',
      // boxShadow: SHADOWS.ICON_BUTTON.ACTIVE
      backgroundColor: COLORS.PRIMARY.BLUE,
      boxShadow: barEdge,
      height: '100%'
    },
    error: {
      backgroundColor: COLORS.PRIMARY.RED,
      boxShadow: barEdge
    },
    paused: {
      backgroundColor: COLORS.PRIMARY.RED,
      boxShadow: barEdge
    },
    percentage: {
      ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_3,
      backgroundColor: COLORS.GREY_DARK,
      color: COLORS.PRIMARY.WHITE,
      width: PADDINGS.XL,
      height: PADDINGS.M,
      position: 'absolute',
      display: 'flex',
      top: '7px',
      left: '44%',
      borderRadius: '0.2rem'
    },
    uploadStatus: {
      paddingBottom: PADDINGS.XXS,
      ...TEXTS.APP_SEARCH.RECORD_NAME,
      fontSize: '12px'
    }
  })
)
class ProgressBar extends PureComponent {
  render() {
    const {
      percent,
      styles: s,
      isErrored,
      isPaused,
      color = '',
      children
    } = this.props;

    return (
      <Box {...s('container')}>
        <Box {...s('uploadStatus')}>{children}</Box>
        <Box {...s('barFrame')} justifyContent='start'>
          <Box
            {...s.with({
              error: isErrored,
              paused: isPaused,
              progressBar: true
            })({ width: `${percent}%`, backgroundColor: color })}
          />
          <Box {...s('percentage')} justifyContent='center' alignItems='center'>
            {percent}%
          </Box>
        </Box>
      </Box>
    );
  }
}

export default ProgressBar;
