import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';

import { RecordPreviewHeading } from 'components/record-preview/record-preview-heading';
import { DoNotContactTooltip } from 'features/contacts/tooltips/do-not-contact-tooltip';

export function ContactPreviewHeading({
  children,
  isDoNotContact
}: {
  children: ReactNode;
  isDoNotContact?: boolean;
}) {
  return (
    <Box alignItems='center' height={20}>
      <RecordPreviewHeading>{children}</RecordPreviewHeading>
      {isDoNotContact && (
        <DoNotContactTooltip
          data-testid={'RecordPreview.DoNotContactTooltip'}
        />
      )}
    </Box>
  );
}
