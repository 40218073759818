import React from 'react';

import { ICONS } from 'shared/components/icon';

import { SavedListItemIconTooltip } from './icon-tooltip';

const PadlockSvg: any = ICONS.PADLOCK_18;

type SavedListItemLockedTooltipProps = Pick<
  React.ComponentProps<typeof SavedListItemIconTooltip>,
  'isVisible' | 'content'
>;

export function SavedListItemLockedTooltip(
  props: SavedListItemLockedTooltipProps
) {
  return <SavedListItemIconTooltip TooltipIcon={PadlockSvg} {...props} />;
}
