import { identity } from 'lodash';
import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import { getConnectionId } from 'src/view/dialogs/listings/manage-epc/epc-service';

const TYPE = 'epc';

const actionCreators = {
  getEpcStats: {
    request: (listingId: number) => {
      return api.post('ThirdPartyServiceOdc::getEpcStatsForListing', {
        listing_id: listingId,
        connection_id: getConnectionId()
      });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getChart: {
    request: (listingId: number) => {
      return api.post('Listings/downloadEpcGraph', {
        id: listingId,
        type: 'combined'
      });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  setListingCertificate: {
    request: ({
      listingId,
      certificateId
    }: {
      listingId: number;
      certificateId: number;
    }) => {
      const connectionId = getConnectionId();

      if (!connectionId) {
        return Promise.resolve();
      }

      return api.post('ThirdPartyServiceOdc::setListingCertificateId', {
        listing_id: listingId,
        connection_id: connectionId,
        certificate_id: certificateId
      });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  trashEpcData: {
    request: (listingId: number) => {
      const connectionId = getConnectionId();

      if (!connectionId) {
        return Promise.resolve();
      }

      return api.post('ThirdPartyServiceOdc::setListingCertificateId', {
        listing_id: listingId,
        connection_id: connectionId,
        certificate_id: null
      });
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const epcModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default epcModel;
