import React, { PureComponent, Fragment } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { TEXTS, PADDINGS } from 'theme';
import { ButtonBar } from 'view/components/button-bar';
import Dialog from 'view/components/dialog';
import { Select } from 'view/components/input/select';
import { TextButton, DefaultButton } from 'view/components/button';
import { ReactForms, FormField } from 'view/components/form';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { Heading } from 'components/text/heading';
import { Body } from 'components/text/body';

const defaultStyles = StyleSheet({
  label: {
    ...TEXTS.CONTENT.FORM_FIELD_LABEL_1,
    paddingBottom: PADDINGS.XS
  },
  content: {
    ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
    paddingBottom: PADDINGS.XS
  },
  buttonBar: {
    marginTop: PADDINGS.M
  }
});

@withErrorDialog
@styled(defaultStyles)
@autobind
class MergeTagsDialog extends PureComponent {
  state = {
    value: ''
  };

  handleSubmit(values) {
    const { onSave, closeDialog, errorDialog } = this.props;
    try {
      onSave(values.tag);
      closeDialog();
    } catch (e) {
      errorDialog.open(e);
    }
  }

  handleSelect(e) {
    this.setState({
      value: e.value
    });
  }

  render() {
    const { category, closeDialog, options, onLoad } = this.props;

    return (
      <Dialog
        title='Insert Merge Tag'
        height={400}
        closeDialog={closeDialog}
        onLoad={onLoad}
      >
        <ReactForms handleSubmit={this.handleSubmit}>
          {({ submitForm }) => (
            <Fragment>
              <Heading level={3}>category</Heading>
              <Body dark>{category}</Body>

              <FormField
                name='tag'
                label='merge tag'
                Input={Select}
                inputProps={{
                  options
                }}
              />

              <ButtonBar>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton dark onClick={submitForm}>
                  Add
                </DefaultButton>
              </ButtonBar>
            </Fragment>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default MergeTagsDialog;
