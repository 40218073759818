import { useCallback } from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import uiModel from 'data/models/custom/ui';

import { useErrorDialog } from 'hooks/use-error-dialog';

import {
  customReports,
  UpdateCustomReportObject
} from '../data/custom-reports-model';

export function useUpdateCustomReport() {
  const errorDialog = useErrorDialog();

  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const { updateReport } = useModelActions(customReports);

  const updateCustomReport = useCallback(
    async ({ data }: { data: UpdateCustomReportObject }) => {
      try {
        loadingIndicatorOn({ message: 'Updating report' });

        await updateReport({
          data
        });

        loadingIndicatorOff();
      } catch (error: any) {
        errorDialog.open(error);
      }
    },
    []
  );

  return { updateCustomReport };
}
