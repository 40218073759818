import Box from '@rexlabs/box';
import UpgradeTooltip from 'view/components/tooltip/upgrade';
import React from 'react';

const PaywallTooltip = ({ paywall, children }) => {
  if (!paywall) {
    return <Box>{children}</Box>;
  }

  return <UpgradeTooltip>{children}</UpgradeTooltip>;
};

export default PaywallTooltip;
