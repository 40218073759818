import { useCallback } from 'react';

import { useGetViewstateIdForBulkActions } from 'components/record-list-screen/utils/mass-action-utils';
import { useDialog } from 'hooks/use-dialog';

export function useChangeLeadStatusBatchAction({ service_name }) {
  const changeLeadStatus = useDialog('changeLeadStatus');
  const getViewstateId = useGetViewstateIdForBulkActions({ service_name });

  return useCallback(
    ({ selectionCriteria }) => {
      getViewstateId({ selectionCriteria }).then(({ viewstate_id, total }) => {
        const apiData = {
          service_name,
          viewstate_id
        };

        changeLeadStatus.open({
          total,
          apiData
        });
      });
    },
    [changeLeadStatus, getViewstateId, service_name]
  );
}
