import { Generator } from 'shared/utils/models';
import { api, transformList } from 'shared/utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      trashItem: (type, args, id) =>
        api
          .post('Calendars::purge', { ...args, id: id })
          .then((response) => response.data.result),
      // NOTE: sushi doesn't do normal pagination and wings returns the rows
      // straight on result, that's why we avoid param mapping here
      // Also we want to avoid `page` and `limit` being sent on refresh
      fetchList: (_, { ...rest }) =>
        api.post('Calendars::search', rest).then(transformList)
    }
  }
};

const actionCreators = {
  // HACK: model generator merges data, which bites us in the butt here,
  // so as a quick fix we just added a sync method to set permissions
  // in the store manually
  updatePermissions: {
    reduce: (state, action) => {
      const id = _.get(action, 'payload.id');
      const permissions = _.get(action, 'payload.permissions');
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ..._.get(state, `items.${id}`, {}),
            data: {
              ..._.get(state, `items.${id}.data`, {}),
              permissions
            },
            etag: new Date().getTime()
          }
        }
      };
    }
  }
};

const TYPE = 'calendars';
const calendarsModel = new Generator(TYPE, config).createEntityModel({
  actionCreators
});

calendarsModel.select = {
  autocomplete: (searchTerm, { exclude }) =>
    api.post('Calendars::search').then((response) => {
      const data = transformList(response);
      data.result
        .filter((c) => !exclude || !exclude.includes(c.id))
        .map((c) => ({
          value: c.id,
          label: `${_.get(c, 'owner_user.name')} (${c.name})`,
          model: calendarsModel,
          data: c
        }));
    })
};

export default calendarsModel;
