import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';

interface OptionDetailProps extends BaseTextProps {
  active?: boolean;
}

export function OptionDetail({ active, ...props }: OptionDetailProps) {
  return (
    <Text
      is='p'
      {...props}
      type={[active ? 'OPTION_DETAIL_ACTIVE' : 'OPTION_DETAIL']}
    />
  );
}
