import React, { PureComponent } from 'react';
import types from 'prop-types';

import { TextButton, PrimaryButton } from 'shared/components/button';
import ButtonBar from './button-bar';

class SaveCancelButtonBar extends PureComponent {
  static propTypes = {
    saveButtonText: types.string,
    isLoading: types.bool,
    onSave: types.func,
    onCancel: types.func
  };

  render() {
    const { onCancel, onSave, isDisabled, ...props } = this.props;
    return (
      <ButtonBar {...props}>
        <TextButton key='cancel' blue onClick={onCancel}>
          Cancel
        </TextButton>
        <PrimaryButton isDisabled={isDisabled} key='save' red onClick={onSave}>
          Save
        </PrimaryButton>
      </ButtonBar>
    );
  }
}

export default SaveCancelButtonBar;
