import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'view/components/button';
import { Body } from 'components/text/body';
import Spinner from 'shared/components/spinner';
import { autobind } from 'core-decorators';
import _ from 'lodash';

@autobind
class ListPagination extends PureComponent {
  state = {
    loading: false
  };

  handlePrev() {
    const { onPrev } = this.props;
    this.setState({ loading: true }, () => {
      // If `onPrev` returns a promise, we want to show the loading state in the pagination
      // until that promise is resolved!
      Promise.all([onPrev()]).then(() => this.setState({ loading: false }));
    });
  }

  handleNext() {
    const { onNext } = this.props;
    this.setState({ loading: true }, () => {
      Promise.all([onNext()]).then(() => this.setState({ loading: false }));
    });
  }

  render() {
    const { pagination, currentPage } = this.props;

    const total = parseInt(_.get(pagination, 'totalItems'));
    const perPage = parseInt(_.get(pagination, 'itemsPerPage'));

    const fromNumber = perPage * (currentPage - 1) + 1;
    let toNumber = perPage * currentPage;

    if (toNumber > total) {
      toNumber = total;
    }

    return total ? (
      <Box
        flexDirection='row'
        justifyContent='flex-end'
        alignItems='center'
        mt={5}
      >
        {this.state.loading && (
          <Box width={80} alignItems='center' justifyContent='center'>
            <Spinner small dark />
          </Box>
        )}
        <Body dark small>
          {fromNumber} to {toNumber} of {total}
        </Body>
        <Box ml={8}>
          <DefaultButton
            padding={false}
            isDisabled={currentPage <= 1}
            onClick={this.handlePrev}
          >
            <ICONS.ARROW_DOWN
              style={{
                fill: 'currentColor',
                position: 'absolute'
              }}
            />
          </DefaultButton>
        </Box>
        <Box ml={3}>
          <DefaultButton
            padding={false}
            isDisabled={toNumber === total}
            onClick={this.handleNext}
          >
            <ICONS.ARROW_DOWN
              style={{
                transform: 'rotate(180deg)',
                fill: 'currentColor',
                position: 'absolute'
              }}
            />
          </DefaultButton>
        </Box>
      </Box>
    ) : null;
  }
}

export default ListPagination;
