import _ from 'lodash';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';
import { APP_MENU } from 'theme/legacy/components';
import ImageWithError from 'view/components/image-with-error';
import { OPTION_TYPES } from 'view/components/navigation/app-search/utils';
import Option from './option';
import { getHazardly } from 'shared/utils/prefix-hell';

@styled(APP_MENU.RECORDS)
@autobind
class PropertyOption extends Component {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles, option, isActive } = this.props;
    const { data: property, label, value } = option;
    const image = getHazardly(property, 'image._thumbs.80x60._url');
    const category = _.get(property, 'category.text', '');
    const status = _.get(property, 'status.primary_status.type_text');
    const PropertyIcon = (
      <Icon
        key='property-icon'
        type={ICONS.APP_SEARCH[OPTION_TYPES.PROPERTY]}
      />
    );

    return (
      <Option
        isActive={isActive}
        label={label}
        IconBox={[
          image && (
            <ImageWithError
              key='image'
              src={image}
              width='45px'
              height='45px'
              ErrorProp={PropertyIcon}
            />
          ),
          !image && PropertyIcon
        ]}
        OptionMeta={[
          <span key='label' {...styles('name', 'ellipsis')}>
            {label}
          </span>,
          <span key='category' {...styles('meta', 'ellipsis')}>
            {category}
          </span>
        ]}
        OptionExtraMeta={[
          <span key='value' {...styles('meta', 'extra', 'light')}>
            #{value}
          </span>,
          <span key='status' {...styles('meta', 'extra', 'dark')}>
            {status}
          </span>
        ]}
      />
    );
  }
}

export default PropertyOption;
