import React from 'react';

import Box from '@rexlabs/box';

type DataStateContainerProps = React.ComponentProps<typeof Box>;

export function DataStateContainer(props: DataStateContainerProps) {
  const { children, ...rest } = props;
  return (
    <Box
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
      padding={50}
      {...rest}
    >
      {children}
    </Box>
  );
}
