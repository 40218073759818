/* eslint-disable max-lines */
import React, { Component } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import {
  defaultFilter,
  Select as VividSelect,
  SelectInput as VividSelectInput,
  SelectMenu as VividSelectMenu
} from '@rexlabs/select-input';
import { filterOptionsByValue, selectFilter } from './utils';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS, TEXTS } from 'theme';
import Box from '@rexlabs/box';
import { DynamicDropdown } from 'view/components/action-menu';
import { DefaultButton } from 'view/components/button';

import DefaultOption from './options/default';
import OptionNotFound from './options/not-found';
import OptionLoading from './options/loading';
import DefaultValue from './values/default';

import _ from 'lodash';

const getMultiStyles = _.memoize(
  ({ isSearchable, withTags }) => ({
    TextInput: StyleSheet({
      cosmeticWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxWidth: 'calc(100% - 24px)'
      },

      input: {
        display: isSearchable ? 'flex' : 'none',
        flex: 1,
        order: 1,
        minWidth: '50px',
        paddingLeft: '2.5px',
        maxWidth: '100%',
        ...TEXTS.CONTENT.INPUT_TEXT_REGULAR
      },

      cosmetic: {
        zIndex: 2,
        display: 'flex',
        gap: '2px',
        order: 0,
        position: 'static',
        flexWrap: 'wrap',
        pointerEvents: withTags ? 'all' : 'none',
        padding: 0,
        overflow: 'visible',
        maxWidth: '100%'
      },

      disabled: {
        opacity: 0.97,
        filter: 'unset'
      }
    })
  }),
  ({ isSearchable, withTags }) => `${isSearchable}-${withTags}`
);

const getSingleStyles = _.memoize(
  ({ withTags, overlayStyles, value, inputError, height }) => ({
    TextInput: StyleSheet({
      cosmeticWrapper: {
        maxWidth: '100%',

        ...(overlayStyles
          ? {
              height: '100%',
              width: '100%',
              padding: '0'
            }
          : {})
      },

      suffix: {
        display: withTags && value ? 'none' : 'flex',

        ...(overlayStyles
          ? {
              position: 'absolute',
              right: '0',
              top: '50%',
              transform: 'translate(0%, -50%)'
            }
          : {})
      },

      input: {
        maxHeight: '22px',
        padding: 0,
        paddingLeft: '5px',
        ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,

        ...(overlayStyles
          ? {
              width: '100%',
              fontSize: '27px !important',
              height: '100% !important',
              maxHeight: '100% !important',
              padding: '5px 10px !important',
              fontWeight: '600 !important',
              '&:focus': {
                backgroundColor: 'white'
              },
              ...(value
                ? {
                    backgroundColor: 'white !important'
                  }
                : {})
            }
          : {})
      },

      cosmetic: {
        zIndex: 2,
        padding: '0.5px 0',
        pointerEvents: withTags && value ? 'all' : 'none',
        overflow: 'visible',
        maxWidth: '100%'
      },

      container: {
        padding: '0',
        height: height || 27,
        borderColor: inputError && COLORS.STATES.IDLE,
        '&:hover': {
          borderColor: inputError && COLORS.STATES.HOVER
        },
        ...(overlayStyles
          ? {
              border: 'none !important',
              backgroundColor: 'rgba(255, 255, 255, 0.3) !important'
            }
          : {})
      },

      disabled: {
        opacity: 0.97,
        filter: 'unset'
      }
    }),

    SelectValue: StyleSheet({
      container: {
        paddingLeft: '6px',
        ...(overlayStyles
          ? {
              width: '100%',
              fontSize: '27px !important',
              padding: '5px 10px !important',
              fontWeight: '600 !important'
            }
          : {})
      }
    }),
    SelectInput: StyleSheet({
      indicators: {
        height: '23px'
      }
    })
  }),
  ({ withTags, overlayStyles, value, inputError }) =>
    `${withTags}-${overlayStyles}-${value}-${inputError}`
);
@autobind
class Select extends Component {
  static defaultProps = {
    debounce: false,
    filter: selectFilter,
    shouldClearOptionsOnSelect: false,
    shouldSelectResetInput: true,
    shouldBlurResetInput: true,
    isClearable: false,
    isSearchable: true,
    SelectInput: VividSelectInput,
    placeholder: null,
    OptionNotFound,
    sortOptions: null,
    OptionLoading,
    shouldCloseOnBlur: false,
    // Extra
    isMulti: false,
    onChange: () => _.noop(),
    onBlur: () => _.noop(),
    onSelect: () => _.noop(),
    dedupe: (searchTerm, option) =>
      option.value.toString().toLowerCase() === searchTerm.toLowerCase()
  };

  constructor(props) {
    super(props);

    let selected;
    let selectedSourceProp;
    if (props.value && props.options) {
      selected = filterOptionsByValue(
        props.options,
        props.value,
        props.valueAsObject,
        props.alwaysShowAll,
        props.models
      );
      selectedSourceProp = 'value';
    } else {
      selected = props.selected;
      selectedSourceProp = 'selected';
    }

    // NOTE: why do we need this?!
    if (props.selected && props.value) {
      console.warn(
        'The "selected" and "value" prop were both provided to Select on startup. ' +
          `"${selectedSourceProp}" was chosen to manage initial selected options.`
      );
    }

    this.state = { selected };

    // Note: Previously we were not setting this to true, till after it had already rendered.
    // THis meant that items were still showing in the list of options, until you seelcted something else
    this.shouldHideSelected = !!this?.props?.hideSelected;

    this.components = props.multi
      ? getMultiStyles(props)
      : getSingleStyles(props);
  }

  componentDidUpdate() {
    /**
     * As part of our UX behaviour we always want to hide the selected values from the dropdown
     * menu when we are using a mutli-select. If the developer has passed in the hideSelected prop,
     * we want that to override the mutli-select condition.
     */
    this.shouldHideSelected =
      this.props.hideSelected !== undefined
        ? this.props.hideSelected
        : !!this.props.multi;
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, options, valueAsObject, selected, suggestive } = this.props;

    const changedValue = nextProps.value !== value;
    const changedOptions = nextProps.value && nextProps.options !== options;
    const changedSelected = nextProps.selected !== selected;

    if (changedValue || changedOptions) {
      // Note: Handling behaviour for redux-form to work.
      const selectedOptions = filterOptionsByValue(
        nextProps.options,
        nextProps.value,
        valueAsObject,
        undefined,
        nextProps.models,
        suggestive
      );

      this.setState({ selected: selectedOptions });
    } else if (changedSelected) {
      // Note: Developer wants to handle the options.
      // Note regarding note: then he should do it via the `value` prop!?
      this.setState({ selected: nextProps.selected });
    }
  }

  fakeInputEvent(e) {
    const { name } = this.props;
    return {
      persist: () => null,
      target: {
        name,
        id: name,
        value: this.fakeInputEventValue(e)
      }
    };
  }

  fakeInputEventValue(e) {
    const {
      value,
      multi,
      suggestive,
      valueAsObject,
      dedupe,
      options = []
    } = this.props;

    if (!suggestive) {
      return value;
    }

    if (!e.target.value) {
      return value;
    }

    // HACK: This is here to ensure that suggestive values
    // have labels, specifically for address select
    // Might not work when valueAsObject is false
    let suggestiveValue = valueAsObject
      ? { value: e.target.value, label: e.target.value }
      : e.target.value;

    // We don't want to needlessly replicate options in suggestive selects,
    // so if we can find a similar-value'd option, use that instead.
    // Default dedupe is compare both with `.toLowerCase()`
    const dedupeOption = options.find((option) =>
      dedupe(e.target.value, option)
    );

    if (dedupeOption !== undefined) {
      suggestiveValue = valueAsObject ? dedupeOption : dedupeOption.value;
    }

    if (multi) {
      suggestiveValue = [...(value || []), suggestiveValue];
    }

    return suggestiveValue;
  }

  handleInputKeyDown(focusedOption, e) {
    const { multi, suggestive, onChange, shouldSelectResetInput } = this.props;

    const shouldAddOnTab = e.key === 'Tab' && suggestive && multi;

    if ((suggestive && onChange && e.key === 'Enter') || shouldAddOnTab) {
      const fakeEvent = this.fakeInputEvent(e);
      onChange(fakeEvent);
      if (shouldSelectResetInput) {
        this._context.actions.clearValue();
      } else {
        if (focusedOption) {
          this._context.actions.setValue(focusedOption.label);
        }
      }
      e.preventDefault();
    }
  }

  handleInputBlur(e) {
    const { onBlur, onChange, suggestive, cancelBlurDelay } = this.props;

    if (this.clearOnBlur || cancelBlurDelay) {
      this._context.actions.clearValue();
      return;
    }

    const fakeEvent = this.fakeInputEvent(e);
    // If suggestive select, trigger on change with the current select
    // input value

    if (suggestive && onChange) {
      onChange(fakeEvent);
    }

    if (onBlur) {
      // NOTE: we need to wait a tick in case we change the value
      // for suggestive selects above, otherwise the form will
      // keep the old field stats until the next blur :/
      setTimeout(() => onBlur(fakeEvent), 0);
    }
  }

  handleSelect(selected) {
    const {
      onChange,
      onSelect,
      onBlur,
      name,
      multi,
      valueAsObject,
      suggestive,
      shouldSelectResetInput,
      shouldBlurResetInput
    } = this.props;

    const fakeEvent = {
      persist: () => null,
      target: {
        source: 'handleSelect',
        type: 'select',
        name,
        id: name,
        value: selected
          ? multi
            ? selected.map((s) => (valueAsObject ? s : s.value))
            : valueAsObject
            ? selected
            : selected.value
          : multi
          ? []
          : ''
      }
    };

    if (onChange) {
      onChange(fakeEvent);
    } else {
      // Note: When redux-form isn't present, we manage the selected state manually.
      this.setState({ selected });
    }

    if (onSelect) {
      // Note: Developer may want to handle the options, so we put this handler last.
      onSelect(selected);
    }

    if (suggestive && !shouldSelectResetInput && selected) {
      this._context.actions.setValue(selected.label);
    } else if (shouldBlurResetInput) {
      // HACK: onBlur needs to wait for the onChange to change the local
      // state of the form field, so it passes it up to the form correctly
      // The timeout ensures we run onBlur on the next tick, at which point
      // the value should be stored locally!
      setTimeout(() => {
        // HACK: for selective selects we add the value on blur
        this.clearOnBlur = true;
        window.document.activeElement?.blur?.();
        this.clearOnBlur = false;

        if (onBlur) {
          onBlur(fakeEvent);
        }
      });
    }
  }

  renderValue(props) {
    const { Value = DefaultValue } = this.props;

    return (
      <Value
        {...this.props}
        {...props}
        selected={this.state.selected}
        handleSelect={this.handleSelect}
      />
    );
  }

  renderOption(props) {
    const { Option = DefaultOption } = this.props;
    return <Option {...this.props} {...props} />;
  }

  renderDropdownIndicator() {
    const { suggestive } = this.props;
    return (
      <Icon
        style={{
          color: COLORS.STATES.IDLE,
          height: '23px',
          width: '24px',
          transform: suggestive ? undefined : 'rotate(270deg)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        type={suggestive ? ICONS.ARROW_DOWN_HOLLOW : ICONS.ARROW_DOWN}
      />
    );
  }

  getOptionsFromRelatedRecords(relatedRecords) {
    let items = [];

    _.isArray(relatedRecords) &&
      relatedRecords.forEach((recordsForService) => {
        // If there are multiple record types in related records, and it has options, add a group heading of the service name
        if (relatedRecords.length > 1 && recordsForService.options.length > 0) {
          items = items.concat({
            label: _.get(recordsForService, 'serviceName'),
            isGroupHeading: true,
            onclick: _.noop()
          });
        }
        _.get(recordsForService, 'options', []).forEach((option) => {
          items = items.concat({
            label: option.label,
            onClick: () => this.handleSelect(option)
          });
        });
      });

    return items;
  }

  renderRelatedRecordsDropdown(relatedRecords) {
    return (
      <DynamicDropdown
        left
        placement='bottom-end'
        items={this.getOptionsFromRelatedRecords(relatedRecords)}
        isLoading={relatedRecords === 'loading'}
        Button={({ isOpen }) => (
          <Box ml={3}>
            <DefaultButton
              veryLight
              padding={false}
              autoWidth={true}
              active={isOpen}
            >
              <Icon
                style={{
                  color: '#BDBDB5',
                  height: '24px',
                  width: '27px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                type={ICONS.RELATED_RECORD}
              />
            </DefaultButton>
          </Box>
        )}
      />
    );
  }

  render() {
    const props = this.props;

    const { SelectInput } = props;

    const SelectProps = {
      Container: props.Container,
      options: props.options,
      selected: props.selected,
      multi: props.multi,
      // isLoading: props.isLoading,
      ignoreAccents: props.ignoreAccents,
      ignoreCase: props.ignoreCase,
      filter: props.alwaysShowAll ? () => props.options : props.filter,
      pluckLabel: props.pluckLabel,
      pluckValue: props.pluckValue,
      onSelect: props.onSelect
    };

    const InputProps = {
      styles: props.inputStyles,
      DropdownIndicator:
        props.DropdownIndicator || this.renderDropdownIndicator,
      loadingIndicatorProps: props.loadingIndicatorProps,
      ClearIndicator: props.ClearIndicator,
      Value: props.Value,
      name: props.id || props.name || 'unknown-select',
      placeholder: props.placeholder,
      isClearable: props.isClearable,
      autoFocus: props.autoFocus,
      required: props.required,
      disabled: props.disabled,
      // NOTE: Disabled because we don't want forms handling them - we want Select handling them.
      // onBlur: props.onBlur,
      // onFocus: props.onFocus,
      // onChange: props.onChange,
      Container: 'div',
      onChange: props.onInputChange,
      onKeyDown: props.onKeyDown,
      autoBlur: props.autoBlur,
      isSearchable: props.isSearchable,
      shouldOpenOnFocus: props.shouldOpenOnFocus,
      shouldBackspaceRemove: props.shouldBackspaceRemove,
      shouldDeleteRemove: props.shouldDeleteRemove,
      shouldEscapeClearValue: props.shouldEscapeClearValue,
      shouldTabSelectValue: props.shouldTabSelectValue,
      shouldBlurResetInput: props.shouldBlurResetInput,
      shouldCloseResetInput: props.shouldCloseResetInput,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      shouldCloseOnBlur: props.shouldCloseOnBlur,
      shouldMenuOpen: props.shouldMenuOpen,
      onCleared: props.onCleared,
      readOnly: props.readOnly
    };

    // https://github.com/erikras/redux-form/blob/master/src/propTypes.js
    // Note: We don't want the other prop's, as out Select accounts for most cases.
    const FieldProps = {
      meta: props.meta
    };

    const MenuProps = {
      styles: props.menuStyles,
      Option: this.renderOption,
      OptionNotFound: props.isLoading
        ? props.OptionLoading
        : props.OptionNotFound,
      hideSelected: this.shouldHideSelected,
      OptionSelected: props.OptionSelected,
      onOpen: props.onOpen,
      onClose: props.onClose,
      onScrollToBottom: props.onScrollToBottom,
      onOptionHover: props.onOptionHover,
      onOptionSelect: props.onOptionSelect,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      shouldSelectResetInput: props.shouldSelectResetInput,
      outerClickListeners: [window.top, window.parent.Rex2FrameWindow].filter(
        Boolean
      )
    };

    const width =
      MenuProps.styles && MenuProps.styles.width
        ? MenuProps.styles.width
        : '100%';

    const position =
      MenuProps.styles && MenuProps.styles.position
        ? MenuProps.styles.position
        : 'static';

    let options = props.isLoading
      ? []
      : props.fixtures
      ? (props.options || []).concat(
          props.fixtures.map((fixture) => ({
            ...fixture,
            fixturePrefillData: props.fixturePrefillData,
            isFixture: true,
            value: fixture.value || fixture.label
          }))
        )
      : props.options || [];

    if (props.groupOptions) {
      const groups = options.reduce((all, option) => {
        const { value: groupValue, label: groupLabel } =
          props.groupOptions(option);
        const index = all.findIndex((group) => group.value === groupValue);
        if (index > -1) {
          all[index].options.push(option);
          return all;
        }
        all.push({ value: groupValue, label: groupLabel, options: [option] });
        return all;
      }, []);

      options = groups.reduce((all, group) => {
        all = [
          ...all,
          {
            isGroupHeading: true,
            value: group.value,
            label: group.label,
            options: group.options
          },
          ...group.options
        ];
        return all;
      }, []);
    }

    if (props.sortOptions) {
      options.sort(props.sortOptions);
    }

    const components = props.multi
      ? getMultiStyles(props)
      : getSingleStyles(props);

    return (
      <StylesProvider components={components} prioritiseParentStyles={false}>
        <VividSelect
          {...SelectProps}
          options={options}
          onSelect={this.handleSelect}
          selected={this.state.selected}
        >
          <Box flexDirection={'row'}>
            <SelectInput
              {...InputProps}
              {...FieldProps}
              data-lpignore='true'
              onBlur={this.handleInputBlur}
              onKeyDown={this.handleInputKeyDown}
              Value={props.Value || props.multi ? this.renderValue : undefined}
              allowCustomInput={props.suggestive}
            />
            {/* TODO: move this into EntitySelect OR make EntitySelectWithRelatedRecords.. */}
            {props.relatedRecords &&
              this.renderRelatedRecordsDropdown(props.relatedRecords)}
          </Box>
          <div style={{ position, width }}>
            <div style={{ position: 'relative' }}>
              <VividSelectMenu {...MenuProps} />
            </div>
          </div>
          <ContextHelper setContext={(context) => (this._context = context)} />
        </VividSelect>
      </StylesProvider>
    );
  }
}

// HACK: NO, REALLY, THIS IS PRETTY HACKY: select handles the value of the
// text input in local state and only exposes methods to change this value
// via context, which is provided by the `<VividSelect>` itself, so we don't
// have access to it in our wrapper, but we need
class ContextHelper extends React.Component {
  static contextTypes = {
    '@@rexlabs-select-model': types.object
  };

  componentDidMount() {
    this.props.setContext(this.context['@@rexlabs-select-model']);
  }

  render() {
    return null;
  }
}

export default Select;
export { defaultFilter };
