function formatAddress(data, region = {}) {
  if (!data) return '';

  const {
    adr_street_name,
    adr_unit_number,
    adr_street_number,
    adr_locality,
    adr_state_or_region,
    adr_suburb_or_town,
    adr_postcode,
    property_category,
    adr_building
  } = data;
  let propertyCategory;

  if (property_category) {
    propertyCategory = property_category.text;
  }

  let firstLineAddress = [
    adr_street_name &&
      adr_unit_number &&
      (propertyCategory === 'Land'
        ? `Lot ${adr_unit_number} ` + '/'
        : `${adr_unit_number} ` + '/'),
    adr_street_name && adr_street_number,
    adr_street_name
  ];

  if (region.isAE) {
    if (!adr_street_number) {
      firstLineAddress = [adr_unit_number, adr_street_name];
    } else {
      firstLineAddress = [
        `${adr_unit_number}, `,
        adr_street_number,
        adr_street_name
      ];
    }
  }

  return [firstLineAddress.filter(Boolean).join(' ')]
    .concat([
      Array.from(
        new Set([
          ...(region.isAE
            ? [adr_locality, adr_suburb_or_town]
            : [adr_suburb_or_town, adr_locality]),
          adr_state_or_region
        ])
      )
        .filter(Boolean)
        .join(', '),
      adr_postcode
    ])
    .filter(Boolean)
    .join(' ');
}

function prefixedFormatAddress(data, region = {}) {
  if (!data) return '';

  const {
    _adr_street_name,
    _adr_unit_number,
    _adr_street_number,
    _adr_locality,
    _adr_state_or_region,
    _adr_suburb_or_town,
    _adr_postcode,
    _property_category,
    _adr_building
  } = data;
  let propertyCategory;

  if (_property_category) {
    propertyCategory = _property_category.text;
  }

  let firstLineAddress = [
    _adr_street_name &&
      _adr_unit_number &&
      (propertyCategory === 'Land'
        ? `Lot ${_adr_unit_number} ` + '/'
        : `${_adr_unit_number} ` + '/'),
    _adr_street_name && _adr_street_number,
    _adr_street_name
  ];

  if (region.isAE) {
    if (!_adr_street_number) {
      firstLineAddress = [_adr_unit_number, _adr_street_name];
    } else {
      firstLineAddress = [
        `${_adr_unit_number}, `,
        _adr_street_number,
        _adr_street_name
      ];
    }
  }

  return [firstLineAddress.filter(Boolean).join(' ')]
    .concat([
      Array.from(
        new Set([
          ...(region.isAE
            ? [_adr_locality, _adr_suburb_or_town]
            : [_adr_suburb_or_town, _adr_locality]),
          _adr_state_or_region
        ])
      )
        .filter(Boolean)
        .join(', '),
      _adr_postcode
    ])
    .filter(Boolean)
    .join(' ');
}

export { formatAddress, prefixedFormatAddress };
