import { useState, useCallback, useMemo } from 'react';
import { BoxProps } from '@rexlabs/box';

export interface HoverToggle
  extends Pick<BoxProps, 'onMouseEnter' | 'onMouseLeave'> {
  hoverProps: Pick<BoxProps, 'onMouseEnter' | 'onMouseLeave'>;
  hover: boolean;
}

/**
 * @deprecated use use-show-on-hover instead as that's a pure css solution
 *  https://app.clubhouse.io/rexlabs/story/55821/remove-usages-of-deprecated-usehovertoggle
 */
function useHoverToggle(): HoverToggle {
  const [hover, setHover] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);

  return useMemo(
    () => ({
      onMouseEnter,
      onMouseLeave,
      hoverProps: {
        onMouseEnter,
        onMouseLeave
      },
      hover
    }),
    [onMouseEnter, onMouseLeave, hover]
  );
}

export default useHoverToggle;
