import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TextInputProps } from '@rexlabs/text-input';
import { AmountUnit } from 'features/commission-worksheet/types/commission-worksheet';
import { useRegion } from 'hooks/use-region';
import React, { useState } from 'react';
import { COLORS, TRANSITIONS } from 'src/theme';
import CurrencyInput from 'src/view/components/input/currency';
import PercentageInput from 'src/view/components/input/percentage';

const styles = StyleSheet({
  btn: {
    width: 27,
    lineHeight: '27px',
    backgroundColor: COLORS.PRIMARY.INFORMATIVE,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    transition: TRANSITIONS.REGULAR.BACKGROUND,
    color: COLORS.WHITE,
    outline: 'none',
    border: 'none'
  },
  btnActive: {
    backgroundColor: COLORS.DARK_GREY
  },
  btnLeft: {
    borderRadius: '2px 0 0 2px'
  },
  btnRight: {
    borderRadius: '0 2px 2px 0'
  }
});

interface PercentageSwitchInputProps extends Omit<TextInputProps, 'ref'> {
  amountUnit: AmountUnit;
  setAmountUnit: (value: AmountUnit) => void;
}

const PercentageSwitchInput = ({
  amountUnit,
  setAmountUnit,
  ...rest
}: PercentageSwitchInputProps) => {
  const s = useStyles(styles);
  const currencySymbol = useRegion().financial.currency.symbol;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Box alignItems='center' spacing={5}>
      {amountUnit.id === 'exact_amount' ? (
        <CurrencyInput
          pattern={undefined}
          type='number'
          width={'unset'}
          decimals={2}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          meta={{
            focused: isFocused,
            error: null
          }}
          {...rest}
        />
      ) : (
        <PercentageInput
          pattern={undefined}
          type='number'
          width={'unset'}
          decimals={2}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          meta={{
            focused: isFocused,
            error: null
          }}
          {...rest}
        />
      )}
      <Box alignItems='center'>
        <button
          {...s('btn', 'btnLeft', {
            btnActive: amountUnit.id === 'exact_amount'
          })}
          onClick={() =>
            setAmountUnit({
              id: 'exact_amount',
              text: 'Exact Amount'
            })
          }
        >
          {currencySymbol}
        </button>
        <button
          {...s('btn', 'btnRight', {
            btnActive: amountUnit.id === 'percentage'
          })}
          onClick={() =>
            setAmountUnit({
              id: 'percentage',
              text: 'Percentage'
            })
          }
        >
          %
        </button>
      </Box>
    </Box>
  );
};

export default PercentageSwitchInput;
