import _ from 'lodash';
import React from 'react';

import {
  EntityListQueryReturnType,
  EntityQueryReturnType,
  Id,
  ModelGeneratorLoaded
} from '@rexlabs/model-generator';

import { Generator } from 'shared/utils/models';
import { UserItem } from 'data/models/value-lists/account-users';
import { api } from 'shared/utils/api-client';

import ContactsOption from 'view/components/input/select/options/contacts';
import Value from 'view/components/input/select/values';
import ContactsFixture from 'view/components/input/select/fixtures/contacts';

// TODO: Move this somewhere more reusable.
export type SecurityUserRights =
  | 'read'
  | 'update'
  | 'archive'
  | 'trash'
  | 'purge'
  | 'perms';

type InterestLevel = 'hot' | 'warn' | 'cold';

type SmartCategory =
  | 'Purchaser / Tenant'
  | 'Listing Owner'
  | 'Property Owner'
  | 'Past Owner'
  | 'Looking to Buy'
  | 'Looking to Rent';

interface imageObject {
  uri: string | null;
  url: string | null;
}

interface ContactImage extends imageObject {
  thumbs: {
    '80x80': imageObject;
    '200x200': imageObject;
  } | null;
}

interface ContactDocumentsItem {
  description: string;
  id: number | string;
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_size_mb: number;
  uri: string;
  url: string;
}

export interface ContactsEmailItem {
  email_address: string;
  email_desc: string;
  email_primary: boolean | null;
  email_secondary: boolean | null;
  id: number | string;
  is_invalid: string | null;
}

interface ContactMailingListsItem {
  id: number | string;
  mailing_list_name: string;
  subscribe_timestamp: number;
  subscription_status: 'unsubscribed' | 'subscribed';
  unsubscribe_reason: string | null;
  unsubscribe_timestamp: number | null;
}

export interface ContactNamesItem {
  id: number;
  name_first: string;
  name_last: string | null;
  name_middle: string | null;
  name_title: string | null;
}

export interface ContactPhoneItem {
  id: number;
  is_invalid: boolean | null;
  phone_number: string;
  phone_primary: boolean;
  phone_primary_sms: boolean;
  phone_type: string | null;
  system_e164_phone_number: string | null;
  wash: Wash;
}

interface ContactRelationshipsItem {
  custom_reln_desc: string | null;
  id: string | number;
  related_contact: ContactAutocomplete;
  relationship_type: {
    id: string;
    text: string;
  };
}

interface ContactRelnListing {
  do_not_contact: boolean | null;
  id: string | number;
  // TODO: TYPE listings
  listing: any;
  reln_type: {
    id: string;
    text: string;
  };
}

interface Wash {
  allow: boolean;
  present_on_lists: string[];
  user_override: string | boolean | null;
}

// You can see that ModelGeneratorLoaded now takes two arguments, one is the shape
// of the item that is returned by BE, the other is the shape of the data used to create the item.
export interface ContactModel extends ModelGeneratorLoaded<ContactItem, any> {
  findPossibleDuplicates: (
    payload: Partial<ContactItem>
  ) => Promise<ContactItem>;
}

export type ContactSelectItem = {
  value: ContactStub;
  label: string;
};

export type ContactOption = {
  value: Id;
  id: Id;
  label: string;
  data: ContactItem | ContactStub;
  model: ContactModel;
};

export interface ContactDetailStub {
  email: string | null;
  id: Id;
  name: string;
  phone: string | null;
}

// This is usually returned when a contact is related to another record
export interface ContactStub {
  address: string | null;
  address_postal: string | null;
  email_address: string | null;
  fax_number: string | null;
  id: string;
  name: string;
  phone_number: string | null;
}

// This is returned when performing a search, and sometimes when related to another record
export interface ContactAutocomplete extends ContactStub {
  contact_image: ContactImage | null;
  etag: string | null;
  interest_level: InterestLevel | null;
  is_dnd: 1 | 0 | null;
  last_contacted_date: string | null;
  marketing_birthday: string | null;
  marketing_enquiry_method: {
    id: string | number;
    text: string;
  } | null;
  marketing_enquiry_source: string | null;
  marketing_gender: string | null;
  marketing_postcode: string | null;
  name_addressee: string | null;
  name_first: string | null;
  name_last: string | null;
  name_legal: string | null;
  name_salutation: string | null;
  security_user_rights: SecurityUserRights[];
  system_owner_user: UserItem;
  system_record_state: 'active' | 'archived';
  type: 'person' | 'company';
}

// This is returned when performing a contact read request. Id is actually a number, but BE doesn't seem to mind
// when returning a string. So rather than running toString on all ids when sending to BE, we just pretend that they are strings.
export interface ContactItem
  extends Omit<
    ContactAutocomplete,
    | 'email_address'
    | 'name'
    | 'name_first'
    | 'name_last'
    | 'fax_number'
    | 'phone_number'
  > {
  last_contacted_at: number | null;
  address_postal_wash: Wash | null;
  address_wash: Wash | null;
  company_abn: string | null;
  company_name: string | null;
  company_size: string | null;
  notes: string | null;
  related: {
    contact_documents: ContactDocumentsItem[];
    contact_emails: ContactsEmailItem[];
    contact_mailing_lists: ContactMailingListsItem[];
    contact_names: ContactNamesItem[];
    contact_phones: ContactPhoneItem[];
    contact_relationships: ContactRelationshipsItem[];
    contact_reln_listing: ContactRelnListing[];
    contact_reln_property: [];
    contact_tags: [];
  };
  single_view_identity_id: string | null;
  single_view_profile_id: string | null;
  smart_categories: SmartCategory[];
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_search_key: string;
  website_url: string | null;
}

const TYPE = 'contacts';

const actionCreators = {
  findPossibleDuplicates: {
    request: (payload) => api.post('Contacts::findPossibleDuplicates', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

// Generator type takes two args - the types for the data that is returned by back end, and the types of the actions.
const contactsModel = new Generator<ContactItem, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

contactsModel.select = {
  resultsToSelectOptions: (results) =>
    (results || []).map((contact) => ({
      value: contact.id,
      id: contact.id,
      label: (contact as unknown as ContactStub).name,
      data: contact,
      model: contactsModel
    })),
  autocomplete: function (searchTerm, { contactType }) {
    return api
      .post('Contacts::autocomplete', {
        search_string: searchTerm,
        limit_to_contact_type: contactType || false
      })
      .then(
        ({ data }) => this.resultsToSelectOptions?.(_.get(data, 'result')) || []
      );
  },
  Option: ContactsOption,
  Value: (props) => <Value {...props} service={TYPE} />,
  Fixture: ContactsFixture,
  getPopoutData: (id) => api.post('Contacts::read', { id })
};

// We can use these types for when we pass the model from a parent component to a child.
// You would do this in instances where you want to update the store in redux. While you could use
// updateItem by using useModelActions in a child component, it won't update the initial data in the store.
// For example, you get a list of items in the parent and you update one from a child. If you use useModelActions
// to get the updateItem action, it will update the item on the BE, but not refresh the list. So the item
// will not appear updated in the list in the parent. But if you passed the model from the parent down to
// the item, then used model.updateItem, it would update the list in the parent component.
export type ContactsEntityList = EntityListQueryReturnType<
  typeof contactsModel
>;
export type ContactsEntityItem = EntityQueryReturnType<typeof contactsModel>;

export default contactsModel;
