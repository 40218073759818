import React, { Component } from 'react';

import Fixture from './default';
import { autobind } from 'core-decorators';
import { Bridges } from 'data/classic-bridges';

@autobind
class CampaignsFixture extends Component {
  handleFixtureClick() {
    const { option, term } = this.props;
    Bridges.Dialogs.editCampaign.open({
      callback: (data) => option.fixturePrefillData.callback(data),
      prefillName: term
    });
  }

  render() {
    return (
      <Fixture addNewEntity={this.handleFixtureClick}>ADD NEW CAMPAIGN</Fixture>
    );
  }
}

export default CampaignsFixture;
