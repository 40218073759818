import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import collisionAvoidance from 'data/models/custom/collision-avoidance';
import Tooltip from 'view/components/tooltip';
import { ViewAttributes } from 'view/dialogs/collision-avoidance';
import { recordTypeFromServiceName } from 'view/dialogs/collision-avoidance/utils';
import { withDialog } from 'shared/hocs/with-dialog';
import { COLORS, PADDINGS } from '../../../theme';
import { Link } from 'components/text/link';
import { Tag } from 'components/text/tag';
import { withPermissions } from 'src/hocs/with-permissions';

const DELAY = 2000;

const styles = StyleSheet({
  collisionBar: {
    height: '40px',
    width: '100%',
    backgroundColor: COLORS.PRIMARY.BLUE,
    display: 'flex',
    alignItems: 'center',
    padding: `${PADDINGS.XS} ${PADDINGS.XL}`
  },
  hasChange: {
    backgroundColor: COLORS.PRIMARY.WARM_ORANGE
  },
  editIcon: {
    color: COLORS.PRIMARY.WHITE,
    height: '22px',
    width: '22px',
    marginRight: '4px'
  },
  eyeCon: {
    color: COLORS.PRIMARY.WHITE,
    height: '16px',
    width: '16px',
    marginRight: PADDINGS.XS
  }
});

function isValidRecordId(recordId) {
  const valid = ['number', 'string'].indexOf(typeof recordId) !== -1;
  if (!valid && recordId) {
    console.warn('Received invalid, truthy recordId prop', recordId);
  }
  return valid;
}

@withModel(collisionAvoidance)
@withDialog(ViewAttributes, { propName: 'viewAttributes' })
@withPermissions
@styled(styles)
@autobind
class CollisionAvoidanceBar extends PureComponent {
  componentDidMount() {
    const { collisionAvoidance, recordId, serviceName } = this.props;
    if (this.isDisabled()) {
      return;
    }
    if (isValidRecordId(recordId) && serviceName) {
      collisionAvoidance.openRecord({ recordId, serviceName });
    }
  }

  componentDidUpdate(prevProps) {
    const { collisionAvoidance, recordId, serviceName } = this.props;
    const { recordId: prevRecordId, serviceName: prevServiceName } = prevProps;
    if (this.isDisabled()) {
      return;
    }
    if (recordId !== prevRecordId || serviceName !== prevServiceName) {
      if (isValidRecordId(prevRecordId) && prevServiceName) {
        collisionAvoidance.closeRecord({
          recordId: prevRecordId,
          serviceName: prevServiceName
        });
      }
      if (isValidRecordId(recordId) && serviceName) {
        collisionAvoidance.openRecord({ recordId, serviceName });
      }
    }
  }

  componentWillUnmount() {
    const { collisionAvoidance, recordId, serviceName } = this.props;
    if (this.isDisabled()) {
      return;
    }
    if (isValidRecordId(recordId) && serviceName) {
      collisionAvoidance.closeRecord({ recordId, serviceName });
    }
  }

  render() {
    const { collisionAvoidance } = this.props;
    if (this.isDisabled()) {
      return null;
    }
    return collisionAvoidance.change
      ? this.renderEditor()
      : this.renderViewers();
  }

  handleViewChange() {
    const { collisionAvoidance, viewAttributes } = this.props;

    viewAttributes.open({
      data: collisionAvoidance.change
    });
  }

  handleReload() {
    const { collisionAvoidance, onReload, recordId, serviceName } = this.props;
    if (onReload) {
      onReload();
    }
    collisionAvoidance.reloadRecord({ recordId, serviceName });
  }

  isDisabled() {
    const { disabled } = this.props;
    return disabled;
  }

  renderEditor() {
    const {
      collisionAvoidance,
      serviceName,
      userRecordPermissions,
      styles: s
    } = this.props;
    const { change } = collisionAvoidance;

    const { attributes, related } = collisionAvoidance.change.change;
    if (!serviceName) {
      return null;
    }

    const recordType = recordTypeFromServiceName(serviceName);

    const hasMaterialChanges = !(
      attributes.length === 0 && related.length === 0
    );

    // Because the bar can show up in dialogs too, we need the userHasPermission to be true,
    // if there's no userRecordPermissions. As you need some form of rights to open the edit
    // dialogs, we don't need to pass userRecordPermissions.
    const userHasPermission = !userRecordPermissions
      ? true
      : userRecordPermissions.includes('read');

    return (
      <div
        {...s('collisionBar', {
          hasChange: true
        })}
      >
        <Box width='100%' alignItems='center' justifyContent='space-between'>
          <Box alignItems='center'>
            <Icon
              height='22px'
              width='22px'
              {...s('editIcon')}
              type={ICONS.EDIT}
            />
            <Tag light>{`${change.user.name} edited this ${recordType}.`}</Tag>
            {hasMaterialChanges && userHasPermission && (
              <Box alignItems='center' paddingLeft={PADDINGS.XS}>
                <Link white onClick={this.handleViewChange}>
                  View Edits
                </Link>
              </Box>
            )}
          </Box>
          <Link white onClick={this.handleReload}>
            Reload
          </Link>
        </Box>
      </div>
    );
  }

  renderViewers() {
    const { collisionAvoidance, serviceName, styles: s } = this.props;
    const names = collisionAvoidance.viewers.map((viewer) => viewer.name);

    if (names.length === 0) {
      return null;
    }

    const recordType = recordTypeFromServiceName(serviceName);
    const renderViewersTypes = () => {
      switch (names.length) {
        case 1:
          return (
            <Tag light>{`${names[0]} is also viewing this ${recordType}.`}</Tag>
          );
        case 2:
          return (
            <Tag
              light
            >{`${names[0]} and ${names[1]} are also viewing this ${recordType}.`}</Tag>
          );
        case 3:
          return (
            <Tag
              light
            >{`${names[0]}, ${names[1]} and ${names[2]} are also viewing this ${recordType}.`}</Tag>
          );
        default:
          return (
            <div style={{ display: 'flex' }}>
              <div>
                <Tag light>
                  {`${names[0]}, ${names[1]} and`}
                  &nbsp;
                </Tag>
              </div>
              <div>
                <Tooltip
                  Content={() => <p>{names.slice(2).join(', ')}</p>}
                  placement={PLACEMENTS.TOP}
                  inline
                >
                  <Tag light>{`${names.length - 2} others`}</Tag>
                </Tooltip>
              </div>
              <div>
                <Tag light>
                  &nbsp;
                  {`are also viewing this ${recordType}.`}
                </Tag>
              </div>
            </div>
          );
      }
    };
    return (
      <div
        {...s('collisionBar', {
          hasChange: false
        })}
      >
        <Box alignItems='center'>
          <Icon height='16px' width='16px' {...s('eyeCon')} type={ICONS.EYE} />
          {renderViewersTypes()}
        </Box>
      </div>
    );
  }
}

@autobind
class Delayer extends PureComponent {
  // We want to delay the rendering of the CA bar for a bit, since it automatically
  // triggers API calls and its not crucial for screens to appear immediately,
  // so by delaying the render we make sure critical API calls are executed first
  // TODO: replace with advanced `delayTask` util once available, that checks
  // network idle state instead of hard coded timeout
  state = {
    elapsed: false
  };

  componentDidMount() {
    this.timerId = setTimeout(() => this.setState({ elapsed: true }), DELAY);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  render() {
    const { elapsed } = this.state;
    return elapsed ? <CollisionAvoidanceBar {...this.props} /> : null;
  }
}

export default Delayer;
