import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { IconButton } from 'view/components/button';
import { Hint } from 'components/text/hint';
import { RightBarHeading } from 'components/text/right-bar-heading';
import { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import List, { RexHeadingCell, RexBasicCell } from 'view/components/list';
import { COLORS } from 'theme';
import dayjs from 'dayjs';

const defaultStyles = StyleSheet({
  emptyContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    minHeight: '112px',
    width: '100%'
  },

  headingCellContainer: {
    flex: 1,
    flexShrink: 0
  },

  cellContainer: {
    flex: 1,
    flexShrink: 0
  },

  viewLink: {
    color: COLORS.PRIMARY.SAND_LIGHT,
    cursor: 'pointer',
    fontWeight: 600
  },
  listContainer: {
    width: '460px'
  }
});

const listStyles = StyleSheet({
  separator: {
    display: 'none'
  },
  wrapItem: {
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

@styled(defaultStyles)
@autobind
class SubstantiationsList extends PureComponent {
  static propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.object),
    removeContactCallback: PropTypes.func.isRequired,
    addContactCallback: PropTypes.func.isRequired
  };

  renderEmptyState() {
    const { styles: s, isArchived, isLoading } = this.props;
    return (
      <Box
        {...s('emptyContainer')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {isLoading ? (
          <Spinner small dark />
        ) : (
          <Hint semibold>
            No {isArchived ? 'archived' : 'active'} substantiations found.
          </Hint>
        )}
      </Box>
    );
  }

  renderHeader() {
    const { styles: s, isArchived } = this.props;
    const headerItems = [
      'Status',
      'Date added',
      isArchived ? 'Date modified' : '',
      ''
    ];

    return (
      <Box flexDirection={'row'}>
        {headerItems.map((item, index) => {
          return (
            <div {...s('headingCellContainer')} key={index}>
              <RexHeadingCell>{item}</RexHeadingCell>
            </div>
          );
        })}
      </Box>
    );
  }

  renderItem(item) {
    const { styles: s, isArchived, onViewClick } = this.props;
    const {
      status,
      system_ctime: systemCtime,
      system_modtime: systemModtime
    } = item;

    const creationTime = dayjs.unix(systemCtime);
    const modifiedTime = dayjs.unix(systemModtime);

    return (
      <Box {...s('listRow')} flexDirection={'row'}>
        <div {...s('cellContainer', 'primaryCellContainer')}>
          <RexBasicCell>{status.text}</RexBasicCell>
        </div>
        <div {...s('cellContainer')}>
          <RexBasicCell emptyCellText='-'>
            {!!systemCtime && dayjs(creationTime).format('DD MMM YYYY')}
          </RexBasicCell>
        </div>
        <div {...s('cellContainer')}>
          <RexBasicCell emptyCellText={isArchived ? '-' : ' '}>
            {!!isArchived &&
              !!systemModtime &&
              dayjs(modifiedTime).format('DD MMM YYYY')}
          </RexBasicCell>
        </div>
        <div {...s('cellContainer')}>
          <RexBasicCell>
            <a {...s('viewLink')} onClick={(e) => onViewClick(item, e)}>
              view details
            </a>
          </RexBasicCell>
        </div>
      </Box>
    );
  }

  render() {
    const { styles: s, items, isArchived, onAddClick } = this.props;

    return (
      <div>
        <Box height={40} alignItems={'center'}>
          <Box mr={9}>
            <RightBarHeading>
              {isArchived
                ? 'Archived Substantiations'
                : 'Active Substantiations'}
            </RightBarHeading>
          </Box>
          {!isArchived && (
            <IconButton circle red Icon={ICONS.ADD_MEDIUM} onClick={onAddClick}>
              add new
            </IconButton>
          )}
        </Box>

        <Box {...s('listContainer')}>
          <List
            styles={listStyles}
            Header={this.renderHeader}
            EmptyView={this.renderEmptyState}
            items={items}
            renderItem={this.renderItem}
            alwaysRenderHeader
          />
        </Box>
      </div>
    );
  }
}

export default SubstantiationsList;
