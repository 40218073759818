import { useCallback } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import { ErrorDialog } from 'hocs/with-error-dialog';

import remindersModel, { ReminderItem } from 'data/models/entities/reminders';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';

import { EditReminderData } from 'utils/dialogs/mapping-classic-interfaces';

export function useAddReminder({
  reminderData,
  callback,
  errorDialog
}: {
  reminderData: EditReminderData;
  callback?: (data?: ReminderItem) => void;
  errorDialog: ErrorDialog;
}) {
  const { createItem, updateItem } = useModelActions(remindersModel);
  const remindersAction = reminderData?.id ? updateItem : createItem;

  const remindersCallback = useCallback(
    (data) => {
      return remindersAction({ data })
        .then(() => callback?.())
        .catch((error: Error) => errorDialog?.open(error));
    },
    [callback, errorDialog, remindersAction]
  );

  return useCallback(() => {
    DialogsBridge?.editReminder?.openClassic({
      data: reminderData,
      callback: remindersCallback
    });
  }, [reminderData, remindersCallback]);
}
