import { Id } from '@rexlabs/model-generator';
import { api, transformList } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';
import { ContactAutocomplete } from './contacts';
import { ListingStub } from './listings';
import { PropertyStub } from './properties';
import { AppointmentTypeData } from 'features/calendar/data/admin-appointment-types';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import { UserItem } from '../value-lists/account-users';
import { ValueListItem } from '../value-lists/value-list';

const TYPE = 'calendarEvents';

export interface RelatedRecord {
  account_id: Id | null;
  id: Id;
  label: string;
  service: string;
  stub: ContactAutocomplete | ListingStub | PropertyStub;
}

export interface CalendarProp {
  id: Id;
  access_level: ValueListItem;
  application_object_id: string;
  ical_private: string;
  ical_public: string;
  is_default: boolean;
  name: string;
  owner_user: UserItem & {
    phone_direct: string | null;
    phone_mobile: string | null;
    position: string | null;
    profile_image: string | null;
    is_account_user: '1' | '0' | null | boolean;
  };
  permissions: {
    account: string[];
  };
  remote_calendar: {
    id: string;
    name: string;
    owner_email: string;
    permission_level: ValueListItem;
  };
  provider: string;
  security_user_rights: string[];
  sync_connection_status: ValueListItem;
  system_ctime: number;
  system_modtime: number;
}

export interface EventLocation {
  description: string;
  latitude: string;
  longitude: string;
}

// TODO - on going: Type calendar event data from BE -
//  JIRA - https://rexsoftware.atlassian.net/browse/RADI-5933
export interface CalendarEventData {
  id: Id;
  title: string;
  calendar: CalendarProp;
  records: RelatedRecord[];
  appointment_type: AppointmentTypeData;
  guests_confirmed: boolean;
  vendors_confirmed: boolean;
  update_recurring_events: boolean;
  security_user_rights: string[];
  access_level: {
    id: string;
    text: string;
  };
  event_status: {
    id: string;
  };
}

const config = {
  entities: {
    api: {
      // NOTE: sushi doesn't do normal pagination and wings returns the rows
      // straight on result, that's why we avoid param mapping here
      // Also we want to avoid `page` and `limit` being sent on refresh
      fetchList: (_, { ...rest }) =>
        api
          .post('CalendarEvents::search', { ...rest, limit: 200 })
          // HACK: `10000`, just to make sure refresh list doesn't just refresh
          // the first 20 items!
          // TODO: Type this
          // https://app.shortcut.com/rexlabs/story/64596/type-calendars-custom-action-for-fetchlist
          .then((r) => transformList(r, { pageLimit: 10000 })) as any,

      trashItem: (type, args, id) =>
        api
          .post(`${_.upperFirst(type)}::purge`, { ...args, id: id })
          .then((response) => response.data.result)
    }
  }
};

const actionCreators = {
  getSuggestedTimes: {
    request: ({ from, to, userIds }) =>
      api.post('CalendarAvailabilities::getSuggestedTimes', {
        user_ids: userIds,
        times: { from, to }
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getAvailability: {
    request: ({ from, to, userId }) =>
      api.post('CalendarAvailabilities::getAvailability', {
        user_ids: [userId],
        times: [{ from, to }]
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getConfirmationDetails: {
    // ID of the calendar event
    request: ({ id }) =>
      api.post('CalendarEvents::getConfirmationDetails', {
        id,
        dedupe_valid: true,
        dedupe_invalid: true
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  sendConfirmationMessages: {
    request: ({ data }) =>
      api
        .post('CalendarEvents::sendConfirmationMessages', data)
        .then((response) => {
          // Note: Below we're just checking the data to see if either
          // the guest got a confirmation, or the vendor. Then sending
          // an event to Segment.
          const data = response.data.result;

          const guestSentTotals = data?.guests.sent_totals;
          const vendorSentTotals = data?.vendors.sent_totals;

          const hasSentToGuests =
            guestSentTotals?.email || guestSentTotals?.sms;
          const hasSentToVendors =
            vendorSentTotals?.email || vendorSentTotals?.sms;

          if (hasSentToGuests) {
            Analytics.track({
              event: EVENTS.CALENDAR.GUEST_CONFIRMATION_SENT
            });
          }

          if (hasSentToVendors) {
            Analytics.track({
              event: EVENTS.CALENDAR.VENDOR_CONFIRMATION_SENT
            });
          }
          return response;
        }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  markRecordsAsConfirmed: {
    request: ({ eventId, calendarId, updateRecurringEvents }) =>
      api.post('CalendarEvents::update', {
        data: {
          id: eventId,
          calendar_id: calendarId,
          update_recurring_events: updateRecurringEvents, // this isn't related but is required to update an event
          guests_confirmed: true,
          vendors_confirmed: true
        }
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  cancelItem: {
    request: ({ data }, actions) =>
      api.post('CalendarEvents::cancel', data).then(() =>
        actions.fetchItem({
          id: data.id,
          args: { options: { calendar_id: data.calendar_id } }
        })
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  purgeItem: {
    request: ({ data }) => api.post('CalendarEvents::purge', data),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getScheduledAlertsForEvent: {
    request: ({ id }) =>
      api
        .post('CalendarEvents::getScheduledAlertsForEvent', { id })
        .then(({ data }) => data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  scheduleAlertMessages: {
    request: ({ id, alerts }) =>
      api.post('CalendarEvents::scheduleAlertMessages', { id, alerts }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator(TYPE, config).createEntityModel({
  actionCreators
});
