import React, { PureComponent } from 'react';
import types from 'prop-types';
import { EntitySelect, ValueListSelect } from 'view/components/input/select';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import accountUsersModel from 'data/models/value-lists/account-users';
import contactsModel from 'data/models/entities/contacts';
import listingsModel from 'data/models/entities/listings';
import propertiesModel from 'data/models/entities/properties';
import { contractsModel } from 'features/listings/data';
import marketLeadsModel from 'data/models/entities/market-leads';
import campaignsModel from 'data/models/entities/campaigns';
import dealsModel from 'data/models/entities/deals';
import leadsModel from 'data/models/entities/leads';
import projectsModel from 'data/models/entities/projects';
import projectStagesModel from 'data/models/entities/project-stages';

import { withRegion } from 'src/hocs/with-region';
import idVerificationDocCategory from 'shared/data/models/system-lists/id-verification-doc-category';

const VALUE_SHAPE = types.shape({
  id: types.number.isRequired,
  text: types.string.isRequired,
  stub: types.shape.isRequired
});

@withRegion
@autobind
class RecordSelect extends PureComponent {
  static propTypes = {
    value: types.oneOfType([VALUE_SHAPE, types.arrayOf(VALUE_SHAPE)])
  };

  state = {
    newValue: null,
    entityOptions: []
  };

  updateNewValue() {
    /**
     * We do this so that the value passed in by Knockout has already been set,
     * previously the value was undefined or null during mount which prevented
     * the Select component from showing the selected options.
     *
     * Previously we were passing setting the value via the prop directly but
     * we want to control the value through state so we can set it once the
     * component has mounted.
     */
    const { model } = this.getInfo();
    const { value } = this.props;
    const newValue = this.transformValue(value, model);
    const entityOptionsFromValues = newValue?.length
      ? newValue.map((item) => ({ label: item?.label, value: item?.value }))
      : [];
    this.setState({
      newValue,
      entityOptions: [
        ...(_.get(this.props, 'options') || []).map((v) => ({
          ...v,
          model
        })),
        ...entityOptionsFromValues
      ]
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { newValue } = prevState;
    const { value } = this.props;
    const { model } = this.getInfo();
    const transformedValue = this.transformValue(value, model);

    if (
      !_.isEqual(prevProps, this.props) &&
      !_.isEqual(transformedValue, newValue) &&
      _.isEqual(prevState, this.state)
    ) {
      this.updateNewValue();
    }
  }

  componentDidMount() {
    this.updateNewValue();
  }

  handleChange(e) {
    const { model } = this.getInfo();
    const { serviceName, multi, onChange } = this.props;
    let actualValue;

    if (multi) {
      actualValue =
        e.target.value && e.target.value.length
          ? e.target.value.map((v) => ({
              record: { id: v.value, text: v.label, stub: v.data },
              service_name: serviceName
            }))
          : null;
    } else {
      // NOTE: Even if the Select is not a multi select the empty value is sometimes
      // an empty array.
      actualValue =
        e.target.value && !_.isArray(e.target.value)
          ? {
              record: {
                id: e.target.value.value,
                text: e.target.value.label,
                stub: e.target.value.data
              },
              service_name: serviceName
            }
          : null;
    }

    onChange({
      ...e,
      target: {
        ...e.target,
        value: actualValue
      }
    });
    const newValue = this.transformValue(actualValue, model);
    this.setState({
      newValue,
      entityOptions: [
        ...(_.get(this.props, 'options') || []).map((v) => ({
          ...v,
          model
        })),
        { label: newValue, value: newValue }
      ]
    });
  }

  getInfo() {
    const { serviceName } = this.props;
    const modelMap = {
      AccountUsers: { model: accountUsersModel, isValueList: true },
      Contacts: { model: contactsModel },
      Listings: { model: listingsModel },
      Properties: { model: propertiesModel },
      Contracts: { model: contractsModel },
      MarketLeads: { model: marketLeadsModel },
      Campaigns: { model: campaignsModel },
      Deals: { model: dealsModel },
      Leads: { model: leadsModel },
      Projects: { model: projectsModel },
      ProjectStages: { model: projectStagesModel },
      IdVerificationDocCategories: {
        model: idVerificationDocCategory,
        isValueList: true
      }
    };

    Object.defineProperty(modelMap, '__default', {
      get: function () {
        console.warn('Unknown service', { serviceName });

        return {
          model: contactsModel
        };
      }
    });

    return modelMap[serviceName] || modelMap.__default;
  }

  transformValue(values, model) {
    if (!values || !model) {
      return null;
    }

    if (values && values.length) {
      return values.map((v) => ({
        value: _.get(v, 'record.id'),
        label: _.get(v, 'record.text'),
        data: _.get(v, 'record.stub'),
        model
      }));
    }

    if (values) {
      return {
        value: _.get(values, 'record.id'),
        label: _.get(values, 'record.text'),
        data: _.get(values, 'record.stub'),
        model
      };
    }
  }

  render() {
    const { newValue } = this.state;
    const { ...rest } = this.props;
    const { model, isValueList } = this.getInfo();

    const options = (_.get(this.props, 'options') || []).map((v) => ({
      ...v,
      model
    }));

    if (isValueList) {
      return (
        <ValueListSelect
          {...rest}
          value={newValue}
          initialOptions={options}
          models={[model]}
          onChange={this.handleChange}
          valueAsObject
        />
      );
    }
    return (
      <EntitySelect
        {...rest}
        value={newValue}
        options={this.state.entityOptions}
        models={[model]}
        onChange={this.handleChange}
        valueAsObject
      />
    );
  }
}

export default RecordSelect;
