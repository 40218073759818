import * as _ from 'lodash';

const regexp = {
  /* Extract the components of a mobile phone number */
  ausPhone: {
    /**
     * Extract the components of a mobile phone number
     * "0401134879"
     * -> [..., "0401", "134", "879"]
     */
    mobile: function () {
      return /^(04\d{2})(\d{3})(\d{3})$/;
    },

    /**
     * "131215"
     * -> [..., "13", "12", "15"]
     */
    freeCall: function () {
      return /^(13)(\d{2})(\d{2})$/;
    },

    /**
     * "1800444999"
     * -> [..., "1800", "444", "999"]
     */
    hotline: function () {
      return /^((?:13|18|19)00)(\d{3})(\d{3})$/;
    },

    /**
     * "0738432313"
     * -> [..., "07", "3843", "2313"]
     */
    landline: function () {
      return /^(0[12356789])(\d{4})(\d{4})$/;
    },

    /**
     * "38432313"
     * -> [..., "3843", "2313"]
     */
    local: function () {
      return /^([1-9]\d{3})(\d{4})$/;
    }
  },
  /**
   * Checks that a phone number is formatted to some
   * australian standard after being stripped of spaces.
   * 		0413134879 -> true
   * 		38432313 -> true
   * 		(07)38432313 -> true
   * 		+610401134879 -> false
   * 		112 124 587 -> true
   * 		112 1a4 587 -> false
   * 		+(07) 112 124 587 -> false
   * 		etc.
   */
  isValidAusPhone: function () {
    return /^(?:(\(?0[12356789]\)?\d{8})|(13\d{4})|([1-9]\d{7})|(04\d{8})|((?:13|18|19)00\d{6}))$/;
  },

  nzPhone: {
    mobile: function () {
      return /^(02(?!409)\d{1})(\d{3})(\d{3,5})$/;
    },
    landline: function () {
      return /^(0(?:[34679]|2(?=409)))(\d{3})(\d{4})$/;
    },
    freeCall: function () {
      return /^(0508|0800)(\d{3})(\d{3,4})$/;
    }
  },
  isValidNzPhone: function () {
    return /^(?:(\(?0[234679]\)?\d{7})|((?:0508|0800)\d{6,7})|(02\d{7,9}))$/;
  }
};

const formatAusPhone = function (number, options) {
  // Strip spaces
  const stripped = String(number).replace(/\s/g, '');
  let formatted;
  const landLineExt = _.get(options, 'landLineExt');

  const valid = _.some(regexp.ausPhone, function (fn, key) {
    const split = fn().exec(stripped);

    if (fn().test(stripped)) {
      switch (key) {
        case 'mobile':
        case 'freeCall':
        case 'hotline':
          formatted = split.slice(1).join(' ');
          return true;
        case 'landline':
          formatted = '(' + split[1] + ') ' + split.slice(2).join(' ');
          return true;
        case 'local':
          formatted =
            (landLineExt ? '(' + landLineExt + ') ' : '') +
            split.slice(1).join(' ');
          return true;
      }
    }
  });

  if (valid) {
    return formatted;
  } else {
    return number;
  }
};

const stripAusPhone = function (number) {
  // If it is one of our formats, strip the non numerical stuff
  if (regexp.isValidAusPhone().test(number)) {
    return number.replace(/[^\d]/g, '');
  } else {
    return number;
  }
};

const formatNzPhone = function (number) {
  // Strip spaces
  const stripped = String(number).replace(/\s/g, '');
  let formatted;

  const valid = _.some(regexp.nzPhone, function (fn, key) {
    const split = fn().exec(stripped);

    if (fn().test(stripped)) {
      switch (key) {
        case 'mobile':
        case 'freeCall':
          formatted = split.slice(1).join(' ');
          return true;
        case 'landline':
          formatted = '(' + split[1] + ') ' + split.slice(2).join(' ');
          return true;
      }
    }
  });

  if (valid) {
    return formatted;
  } else {
    return number;
  }
};

const stripNzPhone = function (number) {
  // If it is one of our formats, strip the non numerical stuff
  if (regexp.isValidNzPhone().test(number)) {
    return number.replace(/[^\d]/g, '');
  } else {
    return number;
  }
};

export const formatPhone = function (number, region, options) {
  if (region.isAUS) {
    return formatAusPhone(number, options);
  }
  if (region.isNZ) {
    return formatNzPhone(number, options);
  }
  return number;
};

export const stripPhone = function (number, region, options) {
  if (region.isAUS) {
    return stripAusPhone(number, options);
  }
  if (region.isNZ) {
    return stripNzPhone(number, options);
  }
  return number;
};
