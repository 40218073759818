import React, { ComponentType, Fragment, ReactNode } from 'react';
import { get, isFunction } from 'lodash';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ColumnsConfig } from 'components/record-list-screen/types';
import { DefaultCell } from 'components/record-list-screen/cells/default';

import { Pagination } from './pagination';
import Spinner from 'shared/components/spinner';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  heading: {
    marginBottom: '8px',

    '& > *': {
      marginBottom: '0 !important'
    }
  },

  table: {
    width: '100%',
    marginTop: 20,
    tableLayout: 'fixed',
    marginBottom: 20,

    '& thead': {
      position: 'relative'
    },

    '& thead tr th': {
      background: '#b4b1a9',
      height: '24px',
      textAlign: 'left',
      padding: '8px 15px',
      fontSize: '12px',
      fontWeight: 600,
      verticalAlign: 'middle',
      color: '#fff',

      '&:last-child': {
        padding: '0 20px'
      }
    },

    '& tbody tr td': {
      fontSize: '14px',
      fontWeight: 400,
      background: '#fff',
      padding: '8px 15px',
      '&:last-child': {
        padding: '12px 20px'
      }
    },

    '& tbody tr:nth-child(odd) td': {
      background: '#f4f4f1'
    },

    '& tbody tr:hover td': {}
  },
  light: {
    '& thead tr th': {
      background: COLORS.BACKGROUNDS.SAND_LIGHT,
      color: COLORS.PRIMARY.GREY_DARK,
      borderBottom: `2px solid ${COLORS.STATES.IDLE}`,
      fontSize: 14
    },
    '& tbody tr td': {
      background: COLORS.BACKGROUNDS.SAND_LIGHT
    },
    '& tbody tr:nth-child(odd) td': {
      background: COLORS.BACKGROUNDS.WHITE
    },
    '& td div span': {
      fontWeight: 600
    }
  },
  rowInteractive: {
    '& td': {
      cursor: 'pointer'
    },

    '&:hover td': {
      background: '#e9e9e5'
    },

    '&:hover:nth-child(odd) td': {
      background: '#e9e9e5'
    }
  },

  loading: {
    width: '100%',
    padding: '40px !important',
    background: '#f4f4f1'
  },

  empty: {
    width: '100%',
    padding: '40px !important',
    background: '#f4f4f1',
    color: '#b4b1a9',
    fontSize: '14px',
    fontStyle: 'italic',
    textAlign: 'center'
  }
});

interface TablePaginationProps extends TableProps {
  pagination?: any;
  page?: number;
  setPage?: any;
}

export interface TableProps {
  Heading?: ComponentType<{}>;
  limit?: number;
  showFooterPagination?: boolean;
  items: any[];
  columns: ColumnsConfig;
  onItemClick?: any;
  isLoading?: boolean;
  emptyMessage?: string;
  colorScheme?: 'light' | 'dark';
}

export function Table({
  Heading,
  items,
  columns,
  onItemClick,
  pagination,
  page,
  setPage,
  showFooterPagination = false,
  limit,
  isLoading,
  emptyMessage = 'No items found',
  colorScheme = 'dark'
}: TablePaginationProps) {
  const s = useStyles(defaultStyles);
  return (
    <Fragment>
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        {...s('heading')}
      >
        {Heading ? (
          Heading.prototype.isReactComponent ? (
            <Heading />
          ) : (
            (Heading as () => ReactNode)()
          )
        ) : (
          <div />
        )}
        {pagination?.totalPages > 1 ? (
          <Pagination
            pagination={pagination}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        ) : (
          <div />
        )}
      </Box>

      <table {...s('table', { light: colorScheme === 'light' })}>
        <thead>
          <tr>
            {columns?.map?.((column) => {
              return (
                <th
                  key={column.id}
                  {...s({ sortable: !!column.sortable })}
                  {...(column.width ? { style: { width: column.width } } : {})}
                >
                  {column?.label}
                </th>
              );
            })}
          </tr>
        </thead>
        {items?.length ? (
          <tbody>
            {items?.map?.((item: any, index) => {
              return (
                <tr
                  {...s('row', { rowInteractive: !!onItemClick })}
                  key={item?.id || index}
                  onClick={() => onItemClick?.(item)}
                >
                  {columns?.map?.((column, i) => {
                    const Cell = column?.Cell || DefaultCell;
                    const value = isFunction(column.selector)
                      ? column.selector(item)
                      : get(item, column.selector || column.id);

                    const cellProps = isFunction(column.cellProps)
                      ? column.cellProps(item)
                      : column.cellProps;

                    return (
                      <Cell
                        key={`${item.id}_${i}`}
                        value={value}
                        emptyValue={column.emptyValue}
                        data={item}
                        width={column.width}
                        {...(cellProps || {})}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : isLoading ? (
          <tbody>
            <tr>
              <td colSpan={columns.length} {...s('loading')}>
                <Box flex={1} alignItems='center' justifyContent='center'>
                  <Spinner small dark />
                </Box>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={columns.length} {...s('empty')}>
                {emptyMessage}
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {showFooterPagination && pagination?.totalPages > 1 && (
        <Box flexDirection='row' justifyContent='flex-end'>
          <Pagination
            pagination={pagination}
            limit={limit}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
    </Fragment>
  );
}
