import { useMemo } from 'react';

import { useGoToRelated } from 'hooks/use-goto-related-record';
import { useListExportAction } from 'components/record-list-screen/utils/use-list-export-action';

const service_name = 'TenancyApplications';

export function useApplicationsActions() {
  const exportList = useListExportAction({ service_name });
  const goToRelated = useGoToRelated({ service_name });

  return useMemo(
    () => [
      {
        label: 'Export',
        onClick: exportList
      },
      {
        label: 'Go to Related',
        onClick: goToRelated
      }
    ],
    [exportList, goToRelated]
  );
}
