import { Generator } from 'shared/utils/models';

export interface FollowUpReminderCustomDueDateUnitsValue {
  id: string;
  text: string;
}

const TYPE = 'followUpReminderCustomDueDateUnits';

export default new Generator(TYPE).createSystemListModel();
