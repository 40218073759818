import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    padding: '10px',
    maxHeight: '300px',
    overflow: 'auto',
    width: '100%',
    fontSize: '12px',
    lineHeight: 1.3,
    fontFamily: 'monospace',
    background:
      'repeating-linear-gradient(-45deg, #ffe1e9, #ffe1e9 25px, #ffd8e2 25px, #ffd8e2 50px)'
  }
});

export function Placeholder({ data }) {
  const s = useStyles(defaultStyles);
  return <pre {...s('container')}>{JSON.stringify(data, null, 2)}</pre>;
}
