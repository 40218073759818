import React, { useMemo, useCallback } from 'react';

import { ReactForms } from '@rexlabs/form';
import { PLACEMENTS } from '@rexlabs/tooltip';

import { SelectItem } from 'types/select';

import ApplyCancelButtonBar from 'shared/components/button-bar/apply-cancel';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { UserReportingFilterButton } from 'components/button/reporting-filter-button/user-reporting-filter-button';

import PaddingBox from 'view/components/padding-box';
import { Select } from 'view/components/input/select';
import { FormField, Form } from 'view/components/form';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import { Popout } from 'view/components/popout';

import {
  SetUserFilterStateFunction,
  UserParams
} from 'features/custom-reporting/hooks/use-user-selected-report-state';

import { ModuleName } from 'features/custom-reporting/modules/module-config-types';

import {
  useAvailableFieldsSelectOptions,
  useFilterConfigItem
} from 'features/custom-reporting/utils/get-filter-field-config';

import { useUserFilterHeaderSettings } from 'hooks/use-user-filter-header-settings';
import { usePermissions } from 'hooks/use-permissions';
import { capitalize } from 'lodash';

type FormValues = {
  users: SelectItem[];
  user_filter_preference?: string;
};

interface UsersPopoutComponentProps {
  moduleName: ModuleName;
  onSave: SetUserFilterStateFunction;
  initialFilterField?: string;
  users?: UserParams['userValue'];
}

interface PopoutContentProps {
  close: () => void;
  handleSave: (values: FormValues) => void;
  selectedUsers: SelectItem[];
  userOptions: SelectItem[];
  moduleName: ModuleName;
  user_filter_preference?: string;
  initialFilterField?: string;
  disabled: boolean;
}

function PopoutContent(props: PopoutContentProps) {
  const {
    close,
    handleSave,
    selectedUsers,
    userOptions,
    moduleName,
    initialFilterField,
    disabled
  } = props;

  const filterConfig = useFilterConfigItem({
    filterType: 'user',
    fieldPreference: initialFilterField
  });

  const radioButtonOptions = useAvailableFieldsSelectOptions('user');

  const initialValues = useMemo(() => {
    return {
      users: selectedUsers,
      user_filter_preference: filterConfig?.fieldName
    };
  }, [filterConfig?.fieldName, selectedUsers]);

  const formattedModuleName = useMemo(
    () =>
      moduleName
        .split('_')
        .map((name) => capitalize(name))
        .join(' '),
    [moduleName]
  );

  return (
    <PaddingBox width='340px'>
      <ReactForms
        handleSubmit={async (values: FormValues) => {
          await handleSave(values);
          close();
        }}
        initialValues={initialValues}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <Form>
              <FormField
                name='users'
                helpText='The user filter will not be saved with the report'
                label='Users'
                Input={Select}
                inputProps={{
                  options: userOptions,
                  placeholder: 'select users',
                  multi: true,
                  withTags: true,
                  isSearchable: true,
                  valueAsObject: true,
                  hideSelected: true,
                  disabled
                }}
              />
              {radioButtonOptions.length > 1 && (
                <FormField
                  name={'user_filter_preference'}
                  label={`Only include ${formattedModuleName} where:`}
                  Input={RadioGroupInput}
                  inputProps={{
                    options: radioButtonOptions
                  }}
                />
              )}
              <ApplyCancelButtonBar
                isLoading={isSubmitting}
                onCancel={close}
                onSave={submitForm}
              />
            </Form>
          );
        }}
      </ReactForms>
    </PaddingBox>
  );
}

export function UserFilterPopout(props: UsersPopoutComponentProps) {
  const { onSave, moduleName, initialFilterField, users } = props;

  const { check } = usePermissions();
  const hasViewAllPermission = check('custom_reports.view_all');

  const {
    filterHeader,
    visibleSelectedUsers,
    allAccessibleUsers,
    hasAccessToAllUsers
  } = useUserFilterHeaderSettings({
    recordType: 'users',
    selectedOptions: users,
    hasViewAllPermission
  });

  const handleSave = useCallback(
    (values: FormValues) => {
      Analytics.track({
        event: EVENTS.CUSTOM_REPORTING.MODIFY_USER_FILTER
      });

      const { users, user_filter_preference: userFilterPreference } = values;
      return onSave({
        userValue:
          // If we can see all of the users, we don't want to save them all, as BE
          // assumes all from an empty array.
          users.length === 0 && !hasAccessToAllUsers
            ? allAccessibleUsers
            : users || [],
        ...(userFilterPreference ? { userFilterPreference } : {})
      });
    },
    [onSave, allAccessibleUsers, hasAccessToAllUsers]
  );

  return useMemo(
    () => (
      <Popout
        placement={PLACEMENTS.BOTTOM_START}
        Content={({ close }) => (
          <PopoutContent
            close={close}
            userOptions={allAccessibleUsers}
            selectedUsers={visibleSelectedUsers as SelectItem[]}
            handleSave={handleSave}
            moduleName={moduleName}
            initialFilterField={initialFilterField}
            disabled={allAccessibleUsers.length === 1}
          />
        )}
      >
        <UserReportingFilterButton>{filterHeader}</UserReportingFilterButton>
      </Popout>
    ),
    [
      filterHeader,
      allAccessibleUsers,
      visibleSelectedUsers,
      handleSave,
      moduleName,
      initialFilterField
    ]
  );
}
