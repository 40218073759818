import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Link from 'view/components/link';
import { styled, StyleSheet } from '@rexlabs/styling';
import uiModel from 'data/models/custom/ui';
import { connect } from 'react-redux';

const defaultStyles = StyleSheet({
  container: {
    margin: '60px 120px'
  },

  heading: {
    fontWeight: 100,
    fontSize: '42px',
    lineHeight: '54px'
  },

  paragraph: {
    fontSize: '21px',
    lineHeight: '24px',

    '& a': {
      color: '#00a4ec'
    }
  },

  paddingTop: {
    paddingTop: '8px'
  }
});

const mapDispatchToProps = {
  loadingIndicatorOff: uiModel.actionCreators.loadingIndicatorOff
};

@connect(null, mapDispatchToProps)
@styled(defaultStyles)
class ErrorScreen extends PureComponent {
  componentDidMount() {
    this.props.loadingIndicatorOff();
  }

  render() {
    const { styles: s } = this.props;
    return (
      <Box {...s('container')}>
        <h1 {...s('heading')}>Something went wrong</h1>
        <p {...s('paragraph', 'paddingTop')}>
          Oops, looks an error occurred and we couldn’t recover from it.
        </p>
        <p {...s('paragraph', 'paddingTop')}>
          Try{' '}
          <a href='#' onClick={() => window.location.reload(true)}>
            refreshing the current page
          </a>
        </p>
        <p {...s('paragraph')}>
          or go back{' '}
          <Link path='/home'>
            {({ target, onClick }) => (
              <a href={target} onClick={onClick}>
                home
              </a>
            )}
          </Link>
          .
        </p>
      </Box>
    );
  }
}

export default ErrorScreen;
