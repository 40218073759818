import React, { ImgHTMLAttributes } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const styles = StyleSheet({
  img: {
    width: '16px',
    height: '16px',
    imageRendering: 'crisp-edges'
  }
});

export function AiLogo(props: ImgHTMLAttributes<HTMLImageElement>) {
  const s = useStyles(styles);
  return (
    <img
      src={require('features/ai/assets/ai-logo.png').default}
      alt='Rex AI'
      {...s('img')}
      {...props}
    />
  );
}
