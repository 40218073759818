import React, { Component } from 'react';
import { TextInput } from '@rexlabs/text-input';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { Input } from 'components/text/input';
import { COLORS, PADDINGS } from 'src/theme';
import { formatNumber } from 'shared/utils/formatters';
import { withRegion } from 'src/hocs/with-region';
import _ from 'lodash';

const inputStyles = (width) => ({
  TextInput: StyleSheet({
    prefix: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: PADDINGS['3XS'],
      background: COLORS.WHITE,
      position: 'absolute',
      width: width || '90%',
      '& span': {
        width: '100%'
      }
    }
  })
});

const inputStylesDisabled = (width) => ({
  TextInput: StyleSheet({
    prefix: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: PADDINGS['3XS'],
      background: COLORS.BACKGROUNDS.SAND,
      position: 'absolute',
      width: width || '90%',
      '& span': {
        width: '100%'
      }
    }
  })
});

@withRegion
class CurrencyInput extends Component {
  handleChange(e) {
    const { onChange } = this.props;

    e.target.value = e.target.value
      .toString()
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    if (onChange) {
      onChange(e);
    }
  }

  render() {
    const { disabled, decimals, region, width, ...rest } = this.props;
    const symbol = _.get(region, 'financial.currency.symbol', '$');

    return (
      <StylesProvider
        components={disabled ? inputStylesDisabled(width) : inputStyles(width)}
        prioritiseParentStyles={false}
      >
        <TextInput
          pattern='[0-9]*'
          disabled={disabled}
          prefix={
            !rest.meta?.focused ? (
              rest.value !== null ? (
                <Input>{`${symbol}${formatNumber(
                  rest.value,
                  decimals
                )}`}</Input>
              ) : null
            ) : null
          }
          {...rest}
          onChange={this.handleChange.bind(this)}
        />
      </StylesProvider>
    );
  }
}

export default CurrencyInput;
