import dayjs from 'dayjs';

function formatDate(date, unix = false) {
  if (!date) {
    return date;
  }

  if (unix) {
    return dayjs.unix(date).format('D MMM YYYY');
  }

  return dayjs(date).format('D MMM YYYY');
}

export { formatDate };
