import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import { api } from 'shared/utils/api-client';

export const appointmentFields = [
  {
    headerName: 'Appointment ID',
    field: 'id'
  },
  { headerName: 'Title', field: 'title', type: 'appointmentDialogColumn' },
  {
    headerName: 'Type',
    field: 'appointment_type.name',
    subresource: 'appointment_type',
    type: 'valueListColumn',
    filterParams: {
      values: ({ success }) => {
        api
          .post('AdminAppointmentTypes::search')
          .then((response) => [
            ...(response?.data?.result?.rows || []).map(({ name }) => name),
            'N/A'
          ])
          .then(success);
      }
    },
    valueGetter: ({ data }) =>
      data?.show_event_details && data?.appointment_type?.name
  },
  {
    headerName: 'Category',
    field: 'appointment_type.category.text',
    subresource: 'appointment_type',
    type: 'valueListColumn',
    valueGetter: ({ data }) =>
      data?.show_event_details && data?.appointment_type?.category?.text,
    filterParams: {
      values: getAsyncFilterValues({ listName: 'appointment_type_category' })
    }
  },
  {
    headerName: 'Start date',
    colId: 'startDate',
    field: 'starts_at.time',
    type: 'dateColumn'
  },
  {
    headerName: 'Start time',
    colId: 'startTime',
    field: 'starts_at.time',
    type: 'localTimeColumn'
  },
  {
    headerName: 'End date',
    colId: 'endDate',
    field: 'ends_at.time',
    type: 'dateColumn'
  },
  {
    headerName: 'End time',
    colId: 'endTime',
    field: 'ends_at.time',
    type: 'localTimeColumn'
  },
  {
    headerName: 'Time zone',
    field: 'starts_at.tzid'
  },
  {
    headerName: 'Location',
    field: 'event_location.description'
  },
  {
    headerName: 'Calendar',
    field: 'calendar.name',
    subresource: 'calendar',
    type: 'valueListColumn'
  },
  {
    headerName: 'Organiser',
    field: 'organiser_user.name',
    subresource: 'organiser_user',
    type: 'agentValueListColumn'
  },
  {
    headerName: 'Booked by',
    field: 'system_created_user.name',
    subresource: 'system_created_user',
    type: 'agentValueListColumn'
  },
  {
    headerName: 'Created date',
    field: 'system_ctime',
    type: 'localDateTimeColumn'
  },
  {
    headerName: 'Description',
    field: 'description'
  },
  {
    headerName: 'Appointment confirmed',
    field: 'appointment_confirmed',
    type: 'booleanColumn'
  },
  {
    headerName: 'Reminder to follow up',
    field: 'reminder_to_follow_up_status.text',
    type: 'valueListColumn',
    filterParams: {
      values: ['Pending', 'Completed', 'None']
    }
  },
  {
    headerName: 'Reminder to follow up completed date',
    field: 'followup_reminder.reminder.system_completed_time',
    subresource: 'followup_reminder',
    type: 'dateColumn'
  }
];
