import React, { Component } from 'react';

const DialogTooltipContext = React.createContext();
const { Provider, Consumer: DialogTooltipConsumer } = DialogTooltipContext;

class DialogTooltipProvider extends Component {
  render() {
    const { children, target } = this.props;

    return (
      <Provider
        value={{
          target: target
        }}
      >
        {children}
      </Provider>
    );
  }
}

export { DialogTooltipProvider, DialogTooltipConsumer };
