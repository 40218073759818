import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { IconButton } from 'view/components/button';
import { Hint } from 'components/text/hint';
import { RightBarHeading } from 'components/text/right-bar-heading';
import { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';
import {
  RexHeadingCell,
  RexBasicCell,
  RexPrimaryTextCell
} from 'view/components/list';
import { COLORS } from 'theme';
import { withDialog } from 'shared/hocs/with-dialog';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from '@rexlabs/react-sortable-hoc';
import _ from 'lodash';

import EditCampaignMemberStatusDialog from 'view/dialogs/campaigns/edit-member-status';

const defaultStyles = StyleSheet({
  listContainer: {
    userSelect: 'none'
  },

  listContainerDragging: {},

  emptyContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    minHeight: '112px',
    width: '100%'
  },

  cellContainer: {
    flex: 1,
    flexShrink: 0,
    width: '50%'
  },

  label: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  viewLink: {
    color: COLORS.PRIMARY.SAND_LIGHT,
    cursor: 'pointer',
    fontWeight: 600
  }
});

@withDialog(EditCampaignMemberStatusDialog, { propName: 'editStatus' })
@styled(defaultStyles)
@autobind
class CampaignRecordStatusesList extends PureComponent {
  state = {
    isDragging: false
  };

  renderHeader() {
    const { styles: s } = this.props;
    return (
      <Box flexDirection='row' flex={1}>
        <Box width={30}>
          <RexHeadingCell />
        </Box>
        <Box flex={1} flexDirection='row'>
          <div {...s('cellContainer')}>
            <RexHeadingCell>status type</RexHeadingCell>
          </div>
          <div {...s('cellContainer')}>
            <RexHeadingCell>label</RexHeadingCell>
          </div>
        </Box>
      </Box>
    );
  }

  renderItem(item) {
    const { styles: s, onRemove, onEdit, editStatus } = this.props;
    return (
      <Box flexDirection='row' flex={1}>
        <Handle />
        <Box flex={1} flexDirection='row' style={{ width: '430px' }}>
          <div {...s('cellContainer')}>
            <RexPrimaryTextCell
              actionMenuItems={[
                { label: 'Remove', onClick: () => onRemove(item) },
                {
                  label: 'Edit',
                  onClick: () =>
                    editStatus.open({
                      baseStatus: _.get(item, 'base_status'),
                      label: item.label,
                      callback: (newValues) => onEdit({ ...item, ...newValues })
                    })
                }
              ]}
            >
              <span {...s('label')}>{_.get(item, 'base_status.text')}</span>
            </RexPrimaryTextCell>
          </div>
          <div {...s('cellContainer')}>
            <RexBasicCell>{item.label}</RexBasicCell>
          </div>
        </Box>
      </Box>
    );
  }

  renderEmptyState() {
    const { styles: s } = this.props;
    return (
      <Box {...s('emptyContainer')} justifyContent='center' alignItems='center'>
        <Hint semibold>No statuses found.</Hint>
      </Box>
    );
  }

  render() {
    const {
      items,
      editStatus,
      onAdd,
      title,
      onSortItem,
      styles: s
    } = this.props;
    return (
      <Fragment>
        <Box height={40} alignItems='center'>
          <Box mr={9}>
            <RightBarHeading>{title || 'Record Statuses'}</RightBarHeading>
          </Box>
          <IconButton
            circle
            red
            Icon={ICONS.ADD_MEDIUM}
            onClick={() => editStatus.open({ callback: onAdd })}
          >
            add status
          </IconButton>
        </Box>

        <Box
          {...s('listContainer', {
            listContainerDragging: this.state.isDragging
          })}
        >
          {/* NOTE: this is a HACKED together sortable list, which we will need to pull out later! */}
          {this.renderHeader()}
          {items.length ? (
            <SortableList
              useDragHandle
              axis='y'
              onSortEnd={onSortItem}
              distance={5}
              items={items.map((item, index) => (
                <SortableItem key={index} index={index} odd={index % 2}>
                  {this.renderItem(item)}
                </SortableItem>
              ))}
            />
          ) : (
            this.renderEmptyState()
          )}
        </Box>
      </Fragment>
    );
  }
}

const sortableItemStyles = StyleSheet({
  container: {
    zIndex: 3000,
    width: '100%',
    backgroundColor: COLORS.WHITE
  },

  containerOdd: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  }
});

@SortableElement
@styled(sortableItemStyles)
class SortableItem extends PureComponent {
  render() {
    const { styles: s, odd, ...props } = this.props;
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        {...s('container', { containerOdd: odd })}
        {...props}
      />
    );
  }
}

const sortableHandleStyles = StyleSheet({
  container: {
    width: '30px',
    height: '44px',
    cursor: 'move',

    '& svg': {
      width: '8px',
      height: 'auto',
      color: '#c8c7c3'
    }
  }
});

@SortableHandle
@styled(sortableHandleStyles)
class Handle extends PureComponent {
  render() {
    const { styles: s, ...props } = this.props;
    return (
      <Box
        alignItems='center'
        justifyContent='center'
        flexShrink='0'
        {...s('container')}
        {...props}
      >
        <ICONS.DRAG_HANDLE />
      </Box>
    );
  }
}

const sortableContainerStyles = StyleSheet({
  container: {}
});

@SortableContainer
@styled(sortableContainerStyles)
class SortableList extends PureComponent {
  render() {
    const { styles: s, items, ...props } = this.props;
    return (
      <Box {...s('container')} {...props}>
        {items}
      </Box>
    );
  }
}

export default CampaignRecordStatusesList;
