import DefaultOption from 'view/components/input/select/options/default';
import React, { ComponentProps, memo } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import EntityOption from 'view/components/input/select/options/entity';
import { getChainInstanceBuyerName } from 'features/chains/data/chain-instances';

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    height: '17px'
  },
  circle: {
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    position: 'relative',
    border: '1px solid white',
    top: '1px',
    marginRight: '5px',
    marginLeft: '-2px',
    display: 'inline-block'
  },
  percentage: {
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    right: 0
  }
});

const ChainInstanceOption = memo(function ChainInstanceOption(
  props: ComponentProps<typeof DefaultOption>
) {
  const s = useStyles(defaultStyles);
  return (
    <EntityOption
      {...props}
      style={{
        flex: 1,
        backgroundColor: props.disabled ? 'transparent' : undefined
      }}
      pluckStatus={({ data }) =>
        [
          `#${data.id}`,
          getChainInstanceBuyerName(data)
            ? `Primary Link: ${getChainInstanceBuyerName(data)}`
            : null,
          `${data.chain.link_count} link${
            data.chain.link_count !== 1 ? 's' : ''
          }`
        ]
          .filter(Boolean)
          .join(' | ')
      }
      pluckLabel={(option) => {
        return (
          <div {...s('container')}>
            <div>{option.label}</div>
          </div>
        );
      }}
    />
  );
});

export { ChainInstanceOption };
