import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const initialState = {
  filename: '',
  filesize: 0,
  uris: [],
  columnMappings: [],
  currentChunk: 0,
  totalChunks: 0,
  isPaused: false,
  isPending: false,
  isProcessing: false,
  hasFailed: false
};

const selectors = {
  filename: (state) => _.get(state, 'pafUpload.filename'),
  filesize: (state) => _.get(state, 'pafUpload.filesize'),
  uris: (state) => _.get(state, 'pafUpload.uris'),
  columnMappings: (state) => _.get(state, 'pafUpload.columnMappings'),
  currentChunk: (state) => _.get(state, 'pafUpload.currentChunk'),
  totalChunks: (state) => _.get(state, 'pafUpload.totalChunks'),

  isPaused: (state) => _.get(state, 'pafUpload.isPaused'),
  isPending: (state) => _.get(state, 'pafUpload.isPending'),
  isProcessing: (state) => _.get(state, 'pafUpload.isProcessing'),
  hasFailed: (state) => _.get(state, 'pafUpload.hasFailed')
};

const actionCreators = {
  setFile: {
    reduce: (state, action) => ({
      ...state,
      filename: action.payload.filename,
      filesize: action.payload.filesize
    })
  },
  addUri: {
    reduce: (state, action) => {
      if (state.isPaused) {
        return state;
      }

      return {
        ...state,
        uris: [...state.uris, action.payload.uri]
      };
    }
  },
  setColumns: {
    reduce: (state, action) => ({
      ...state,
      columnMappings: action.payload.columnMappings,
      isPending: false,
      isPaused: false,
      isProcessing: false
    })
  },
  setChunk: {
    reduce: (state, action) => ({
      ...state,
      currentChunk: action.payload.currentChunk
    })
  },
  pause: {
    reduce: (state, action) => ({
      ...state,
      currentChunk: action.payload.currentChunk || state.currentChunk,
      isPaused: true,
      isPending: false,
      isProcessing: false
    })
  },
  resume: {
    reduce: (state, action) => ({
      currentChunk: action.payload.currentChunk || state.currentChunk,
      isPaused: false,
      isPending: false,
      isProcessing: false
    })
  },
  clear: {
    reduce: () => initialState
  },
  pending: {
    reduce: (state) => ({
      ...state,
      isProcessing: false,
      isPending: true
    })
  },
  processing: {
    reduce: (state) => ({
      ...state,
      isPending: false,
      isProcessing: true
    })
  },
  failed: {
    reduce: (state) => ({
      ...state,
      isPending: false,
      isProcessing: false,
      isPaused: false,
      hasFailed: true
    })
  },
  startUpload: {
    reduce: (state, action) => ({
      ...state,
      isPending: false,
      isProcessing: false,
      isPaused: false,
      totalChunks: action.payload.totalChunks
    })
  }
};

export default new Generator('pafUpload').createModel({
  initialState,
  actionCreators,
  selectors
});
