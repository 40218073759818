import React from 'react';

import Box from '@rexlabs/box';
import { Whereabouts, parseRouteToUrl } from '@rexlabs/whereabouts';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PADDINGS, COLORS } from 'theme';

import Link from 'view/components/link';

import { Body } from 'components/text/body';
import { Heading } from 'components/text/heading';

export type Stat = {
  count: number | string;
  label: string;
  whereAboutsConfig?: Whereabouts;
};

const defaultStyles = StyleSheet({
  link: {
    cursor: 'pointer',
    borderBottomWidth: 1,
    borderBottomColor: COLORS.STATES.IDLE,
    borderBottomStyle: 'solid'
  },
  description: {
    lineHeight: '20px !important'
  }
});

export function SummaryStat({
  stat: { count, label, whereAboutsConfig }
}: {
  stat: Stat;
}) {
  const s = useStyles(defaultStyles);

  return (
    <Link path={whereAboutsConfig && parseRouteToUrl(whereAboutsConfig)}>
      {({ onClick }) => (
        <Box
          onClick={whereAboutsConfig ? onClick : undefined}
          flexDirection='row'
          sx={PADDINGS['XXS']}
          {...s({
            link: !!whereAboutsConfig
          })}
        >
          <Heading level={2}>{count}</Heading>

          <Body regular dark {...s('description')}>
            {label}
          </Body>
        </Box>
      )}
    </Link>
  );
}
