import { ReportColumn } from 'features/custom-reporting/modules/module-config-types';
import { RegionCode } from 'hooks/use-region';
import { isObject } from 'lodash';

function isFieldDefaultVisible(column: ReportColumn, visibleColumns): boolean {
  return (
    visibleColumns.includes(column.field || '') ||
    visibleColumns.includes(column.colId || '')
  );
}

function keyCreator(params) {
  // Note we can't just add a key creator to the column type def because
  // the key creator here on the column will always overwrite it.
  // so we're drawing on the valueFormatter function to create the key.
  if (isObject(params.value) && params.colDef?.valueFormatter) {
    return params.colDef.valueFormatter({
      ...params,
      context: 'keyCreator'
    });
  }
  return params.value || 'N/A';
}

export const getReportColumns = ({
  columns,
  visibleColumns,
  regionCode
}: {
  columns: ReportColumn[];
  visibleColumns: string[];
  regionCode: RegionCode;
}): ReportColumn[] => {
  // Note that: if we add even more logic here to filter out columns
  // then we probably want to refactor our column defs to hooks instead!
  return columns.reduce((finalColumns, c) => {
    if (c.withRegions && !c.withRegions?.includes(regionCode)) {
      return finalColumns;
    }

    const mappedColumn = {
      keyCreator: keyCreator,
      colId: c.field?.replace(/\.|\[|\]/g, '-'),
      type: 'defaultColumn',
      ...c,
      ...(c.children
        ? {
            children: getReportColumns({
              columns: c.children,
              visibleColumns,
              regionCode
            })
          }
        : {})
    };

    mappedColumn.hide = !isFieldDefaultVisible(mappedColumn, visibleColumns);

    return [...finalColumns, mappedColumn];
  }, [] as ReportColumn[]);
};
