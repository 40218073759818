import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useWhereabouts } from '@rexlabs/whereabouts';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { Screen } from 'components/screen';

import { ReportStateProvider } from 'features/custom-reporting/hooks/use-report-state';
import { CustomReportingHeader } from 'features/custom-reporting/components/custom-reporting-header';
import { DataGridProvider } from 'features/custom-reporting/hooks/use-data-grid';
import { ModuleName } from 'features/custom-reporting/modules/module-config-types';
import { CustomReportingGrid } from 'features/custom-reporting/components/custom-reporting-grid';
import { ModuleProvider } from 'features/custom-reporting/modules/module-provider';
import { AsyncReportDataProvider } from '../hooks/use-async-report-data';

interface CustomReportingScreenProps {
  moduleName: ModuleName;
}

function CustomReportingInnerComponent({ moduleName, reportId }) {
  return (
    <AsyncReportDataProvider reportId={reportId}>
      <ModuleProvider moduleName={moduleName}>
        <ReportStateProvider moduleName={moduleName}>
          <DataGridProvider>
            <Screen>
              <CustomReportingHeader />
              <CustomReportingGrid />
            </Screen>
          </DataGridProvider>
        </ReportStateProvider>
      </ModuleProvider>
    </AsyncReportDataProvider>
  );
}

export function CustomReportingScreen({
  moduleName
}: CustomReportingScreenProps) {
  const { id, hashQuery: { reportId, ...rest } = {} } = useWhereabouts();

  useEffect(() => {
    Analytics.track({
      event: EVENTS.CUSTOM_REPORTING.LOAD_PAGE,
      properties: {
        moduleName
      }
    });
  }, [moduleName]);

  const moduleConfig = { moduleName, reportId };
  // HACK: Unfortunately the way our router works, if you have 2 routes that match the same component, it never
  // unmounts the component, it just re-renders the component with new props. This causes problems because the
  // DataGridProvider will keep the current grid ref, which causes problems with all the downstream consumers.
  // We could implement cleanup operations in a bunch of places to reset state on change of the module name, but
  // I feel the cleaner approach is to unmount and remount the component.
  const [updatedModuleConfig, setUpdatedModuleConfig] = useState(moduleConfig);

  // By wrapping this in a useEffect, when the module name or reportId change we cause a brief delay in updating the cached
  // module name or reportId, which is enough time for 1 render with the component unmounted.
  useEffect(() => {
    setUpdatedModuleConfig({ moduleName, reportId });
  }, [moduleName, reportId]);

  const [key, setKey] = useState(id);

  const shouldUpdateKey = Object.keys(rest || {}).length === 0 && id !== key;

  useEffect(() => {
    if (shouldUpdateKey) {
      setKey(id);
    }
  }, [id, shouldUpdateKey]);

  return isEqual(moduleConfig, updatedModuleConfig) ? (
    <CustomReportingInnerComponent
      key={key}
      moduleName={moduleConfig.moduleName}
      reportId={moduleConfig.reportId}
    />
  ) : null;
}
