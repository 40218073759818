import React from 'react';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';

export function RecordPreviewLoadingSpinner() {
  return (
    <Box
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='100%'
      data-testid='RecordPreview.LoadingSpinner'
    >
      <Spinner small dark />
    </Box>
  );
}
