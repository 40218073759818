import React from 'react';
import { query, useModelActions } from '@rexlabs/model-generator';

import { RecordListScreen } from 'components/record-list-screen';
import { ColumnsConfig } from 'components/record-list-screen/types';
import { DateTimeCell } from 'components/record-list-screen/cells/date-time-cell';
import projectsModel from 'data/models/entities/projects';
import withClassicDialog from 'view/containers/with-classic-dialog';

import { projectsListActions } from '../data/record-list-actions';

const projectsQuery = query`{
  ${projectsModel} {
    id
    name
    system_owner_user {
      name
    }
    system_ctime
    system_created_user {
      name
    }
    system_modtime
    system_modified_user {
      name
    }
  }
}`;

const projectColumns: ColumnsConfig = [
  {
    id: 'name',
    label: 'Name',
    forced: true,
    sortable: true
  },
  {
    id: 'id',
    label: 'ID'
  },
  {
    id: 'system_owner_user.name',
    label: 'Owned By'
  },
  {
    id: 'system_ctime',
    label: 'Created Date',
    Cell: DateTimeCell,
    sortable: true
  },
  {
    id: 'system_created_user.name',
    label: 'Created By'
  },
  {
    id: 'system_modtime',
    label: 'Last Modified',
    Cell: DateTimeCell,
    sortable: true
  },
  {
    id: 'system_modified_user.name',
    label: 'Modified By'
  }
];

export const ProjectsListScreen = withClassicDialog('editProject')(
  function ProjectsListScreen({ editProject, ...props }) {
    const { refreshLists } = useModelActions(projectsModel);
    return (
      <RecordListScreen
        {...props}
        query={projectsQuery}
        columns={projectColumns}
        serviceName='Projects'
        actions={projectsListActions}
        onAdd={() => editProject.open({ callback: () => refreshLists() })}
      />
    );
  }
);
