import accountUsersModel from 'data/models/value-lists/account-users';

import { usePermissionGroupOptions } from 'hooks/use-permission-group-options';
import { useValueList } from 'hooks/use-value-list';

/**
 * This hook returns an array of users that the current logged in user has access to.
 * You can use this list in selects that restrict who the user can choose.
 */
export function useAccessibleUsers({
  hasViewAllPermission
}: {
  hasViewAllPermission: boolean;
}) {
  const { items: accountUsers } = useValueList(accountUsersModel);

  const { groupOptions: groupMemberUsers } = usePermissionGroupOptions();

  if (hasViewAllPermission) {
    return accountUsers;
  }

  return groupMemberUsers;
}
