import React from 'react';

import Box from '@rexlabs/box';

import { PADDINGS } from 'theme';

import { DialogBody } from 'components/text/dialog-body';

interface SavedListGroupEmptyStateProps {
  children: React.ReactNode;
}

export function SavedListGroupEmptyState({
  children
}: SavedListGroupEmptyStateProps) {
  return (
    <Box pt={PADDINGS.S} pb={PADDINGS.XXS}>
      <DialogBody italic light>
        {children}
      </DialogBody>
    </Box>
  );
}
