import _ from 'lodash';
import React from 'react';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import Value from 'view/components/input/select/values';
import ProjectStagesOption from 'view/components/input/select/options/project-stages';
import { ProjectStub } from 'data/models/entities/projects';

const TYPE = 'projectStages';
const projectStagesModel = new Generator(TYPE).createEntityModel();

export interface ProjectStageStub {
  id: string;
  name: string;
  _name: string;
  project: ProjectStub;
  _project: ProjectStub;
  full_name: string;
}

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

projectStagesModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('ProjectStages::autocomplete', { search_string: searchTerm })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((projectStage) => ({
          value: projectStage.id,
          label: projectStage.name,
          data: projectStage,
          model: projectStagesModel
        }))
      ),
  Option: ProjectStagesOption,
  Value: (props) => (
    <Value
      {...props}
      service={TYPE}
      tooltipProps={{ projectId: props?.value?.data?.project?.id }}
    />
  )
};

export default projectStagesModel;
