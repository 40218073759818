import { gql } from 'hooks/use-graphql-query';

export const CommissionWorksheet = gql`
  fragment CommissionWorksheet on CommissionWorksheet {
    id
    comm_amount_net_of_tax
    comm_amount_inc_tax
    cache_comm_distributable
    cache_comm_final_to_office
    cache_amount_invoiced
    cache_amount_paid
  }
`;
