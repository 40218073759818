import { COLORS } from 'theme';
import Tag from 'view/components/tag';
import React from 'react';
import { Body } from 'components/text/body';
import Tooltip from 'view/components/tooltip';
import { Placement, PLACEMENTS } from '@rexlabs/tooltip';

interface BetaTagProps {
  label?: string;
  tooltipText?: string;
  showTooltip?: boolean;
  color?: string;
  placement?: Placement;
}

export default function BetaTag({
  placement = PLACEMENTS.BOTTOM,
  tooltipText = 'This feature is in testing, so you may encounter some minor issues.',
  label = 'BETA',
  color,
  showTooltip = true
}: BetaTagProps) {
  const tag = (
    <Tag
      roundedEdges
      noMargin
      backgroundColor={color || COLORS.PRIMARY.BLUE}
      color={COLORS.PRIMARY.WHITE}
    >
      {label}
    </Tag>
  );

  return showTooltip ? (
    <Tooltip
      hoverTimeout={50}
      placement={placement}
      distance={'0px'}
      Content={() => (
        <Body normal white>
          {tooltipText}
        </Body>
      )}
    >
      {tag}
    </Tooltip>
  ) : (
    tag
  );
}
