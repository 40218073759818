import { useMemo } from 'react';
import { toLower } from 'lodash';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { useRegion } from 'hooks/use-region';

import {
  ContractReportQuery,
  contractReportQueryDefaultVariables
} from './contract-report.query';

import { makeEntity } from 'features/custom-reporting/modules/helpers';
import { OrderByDirectionType, ModuleConfigItem } from '../module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'listing',
  'agent.name',
  'contract_status.text',
  'detail_sale_price_or_lease_pa',
  'date_actual_accepted',
  'date_actual_unconditional',
  'date_actual_settlement'
];
const UkAdditionalDefaults = [
  'days_until_accepted',
  'days_from_accepted_to_settled'
];

export function useContractsModule() {
  const ww = useWhichWordFactory();
  const region = useRegion();

  const {
    contacts: contactCustomFields,
    listings: listingCustomFields,
    properties: propertyCustomFields,
    contracts: contractCustomFields
  } = useCustomFields();

  const contractsModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap[`${ww('contract').toLowerCase()}s`],
      moduleLabel: `${ww('contract')}s`,
      exportPrivilege: 'exporting.contracts',
      queryConfig: {
        graphQlQuery: ContractReportQuery,
        queryVariables: contractReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'date_actual_accepted',
            direction: 'desc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: ww('contract'),
          children: makeEntity({
            entity: 'contract',
            prefix: '',
            whichWord: ww,
            region,
            customFields: contractCustomFields
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property.property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Vendor',
          children: makeEntity({
            entity: 'contact',
            prefix: 'owner.contact_relationships.0.contact',
            subresource: 'vendor',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Vendor solicitor',
          children: makeEntity({
            entity: 'contact',
            prefix: 'vendor_solicitor.legal_solicitor',
            subresource: 'vendor_solicitor',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Vendor solicitor contact',
          children: makeEntity({
            entity: 'contact',
            prefix: 'vendor_solicitor_contact.legal_solicitor_contact',
            subresource: 'vendor_solicitor_contact',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser 1',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchtenant_contacts.0',
            subresource: 'purchasers',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser 2',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchtenant_contacts.1',
            subresource: 'purchasers',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser 3',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchtenant_contacts.2',
            subresource: 'purchasers',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser solicitor',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchtenant_solicitor',
            subresource: 'purchaser_solicitor',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser solicitor contact',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchtenant_solicitor_contact',
            subresource: 'purchaser_solicitor_contact',
            customFields: contactCustomFields,
            region
          })
        },
        ...(!region.isEU
          ? [
              {
                headerName: 'Commission',
                children: makeEntity({
                  entity: 'commissionBySale',
                  prefix: 'commission_worksheet',
                  subresource: 'commissions',
                  whichWord: ww
                })
              }
            ]
          : [])
      ], // these need to be updated
      defaultVisibleColumns: [
        ...defaultColumns,
        ...(region.isUK ? UkAdditionalDefaults : [])
      ],
      defaultDatePreset: 'this_year_to_date',
      defaultDateFieldPreference: 'contract.date_actual_offer',
      topLevelFilters: {
        user: {
          availableFields: [
            {
              fieldName: 'contract.agent_id',
              label: `${ww('contract')} agent`
            }
          ]
        },
        date: {
          availableFields: [
            {
              fieldName: 'contract.date_actual_offer',
              label: `${ww('contract')} received`,
              isDefault: true
            },
            {
              fieldName: 'contract.date_actual_accepted',
              label: `${ww('contract')} accepted`
            },
            {
              fieldName: 'contract.date_actual_unconditional',
              label: `${ww('contract')} ${toLower(ww('unconditional'))}`
            },
            {
              fieldName: 'contract.date_actual_settlement',
              label: `${ww('contract')} ${toLower(ww('settled'))}`
            },
            {
              fieldName: 'contract.date_actual_fallen',
              label: `${ww('contract')} fallen`
            }
          ]
        },
        location: {
          availableFields: [
            { fieldName: 'contract.listing_location_id', label: 'Location' }
          ]
        }
      }
    }),
    [
      ww,
      region,
      contactCustomFields,
      listingCustomFields,
      propertyCustomFields,
      contractCustomFields
    ]
  );

  return contractsModuleConfig;
}
