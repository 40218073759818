import React, { useCallback, useMemo } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ICONS } from 'shared/components/icon';
import Checkbox from 'view/components/input/checkbox';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';

const ArrowIcon: any = ICONS.ARROW_RIGHT;

const defaultStyles = StyleSheet({
  container: {
    minWidth: '190px'
  },

  button: {
    width: '34px',
    height: '33px',
    border: '0 none',
    background: 'rgba(0,0,0,0.15)',
    cursor: 'pointer',

    '& svg': {
      transform: 'rotate(-90deg)'
    },

    '&:hover': {
      background: 'rgba(0,0,0,0.2)'
    },

    '&:active': {
      background: 'rgba(0,0,0,0.3)'
    }
  }
});

export function ColumnsPopout({ columns, visibleColumns, setVisibleColumns }) {
  const s = useStyles(defaultStyles);
  const optionalFields = useMemo(() => {
    return columns.filter((column) => !column.forced);
  }, [columns]);

  const hasOptionalFields = !!optionalFields.length;

  const Content = useCallback(() => {
    return (
      <PaddingBox
        flexDirection='column'
        data-testid='RecordListScreen.ColumnsPopoutContent'
        {...s('container')}
      >
        {optionalFields.map((column) => (
          <Checkbox
            key={column.id}
            id={column.id}
            value={visibleColumns.includes(column.id)}
            label={column.label}
            onChange={() => {
              setVisibleColumns(
                visibleColumns.includes(column.id)
                  ? visibleColumns.filter((id) => id !== column.id)
                  : [...visibleColumns, column.id]
              );
            }}
          />
        ))}
      </PaddingBox>
    );
  }, [optionalFields, s, setVisibleColumns, visibleColumns]);

  if (!hasOptionalFields) return null;

  return (
    <Popout Content={Content} placement='bottom-end'>
      <button {...s('button')} data-testid='RecordListScreen.ColumnsButton'>
        <ArrowIcon />
      </button>
    </Popout>
  );
}
