export const SALE_AND_PURCHASE_FIELDS = [
  {
    name: 'stove',
    label: 'Stove'
  },
  {
    name: 'rangehood',
    label: 'Rangehood'
  },
  {
    name: 'wall_oven',
    label: 'Wall oven'
  },
  {
    name: 'cooktop',
    label: 'Cooktop'
  },
  {
    name: 'dishwasher',
    label: 'Dishwasher'
  },
  {
    name: 'waste_disposal',
    label: 'Kitchen waste disposal'
  },
  {
    name: 'light_fittings',
    label: 'Light fittings'
  },
  {
    name: 'smoke_detectors',
    label: 'Smoke detectors'
  },
  {
    name: 'burglar_alarm',
    label: 'Burglar alarm'
  },
  {
    name: 'heated_towel_rail',
    label: 'Heated towel rail'
  },
  {
    name: 'heat_pump',
    label: 'Heat pump'
  },
  {
    name: 'garage_door_remote',
    label: 'Garage door remote control'
  },
  {
    name: 'garden_shed',
    label: 'Garden shed'
  },
  {
    name: 'blinds',
    label: 'Blinds',
    noCount: true
  },
  {
    name: 'blinds_motor',
    label: 'Blinds motor',
    noCount: true
  },
  {
    name: 'curtains',
    label: 'Curtains',
    noCount: true
  },
  {
    name: 'curtains_motor',
    label: 'Curtains motor',
    noCount: true
  },
  {
    name: 'drapes',
    label: 'Drapes',
    noCount: true
  },
  {
    name: 'fixed_floor_coverings',
    label: 'Fixed floor coverings',
    noCount: true
  },
  {
    name: 'bathroom_extractor_fan',
    label: 'Bathroom extractor fan',
    noCount: true
  }
];

export const AUCTION_FIELDS = SALE_AND_PURCHASE_FIELDS.filter(
  (field) => !['blinds_motor', 'curtains_motor'].includes(field.name)
);
