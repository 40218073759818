import { RegionCode } from 'hooks/use-region';
// Source of truth for mapping FE moduleName to BE moduleId
// This was created so we don't have to pull the module provider further up
// Add moduleNames and moduleIds here instead of coding in the individual module providers,
// Otherwise the favourite reports in the menu will break

interface MapItem {
  moduleId: string;
  moduleName: string;
  regions?: RegionCode[];
  permissions?: string[];
}

interface ModuleMap {
  [key: string]: MapItem;
}

export const moduleNameMap: ModuleMap = {
  appointments: { moduleId: 'appointment', moduleName: 'appointments' },
  appraisals: {
    moduleId: 'appraisal',
    moduleName: 'appraisals',
    regions: ['AUS', 'NZ']
  },
  valuations: {
    moduleId: 'appraisal',
    moduleName: 'valuations',
    regions: ['UK', 'FR', 'AE']
  },
  listings: { moduleId: 'listing', moduleName: 'listings' },
  commission_by_agent: {
    moduleId: 'commission_allocation',
    moduleName: 'commission_by_agent'
  },
  commission: {
    moduleId: 'commission_sale',
    moduleName: 'commission'
  },
  contacts: { moduleId: 'contact', moduleName: 'contacts' },
  contract_conditions: {
    moduleId: 'contract_condition',
    moduleName: 'contract_conditions',
    regions: ['AUS', 'NZ']
  },
  contracts: {
    moduleId: 'contract',
    moduleName: 'contracts',
    regions: ['AUS', 'NZ']
  },
  offer_conditions: {
    moduleId: 'contract_condition',
    moduleName: 'offer_conditions',
    regions: ['UK', 'FR']
  },
  offers: { moduleId: 'contract', moduleName: 'offers', regions: ['UK', 'FR'] },
  key_activity: {
    moduleId: 'key_activity',
    moduleName: 'key_activity'
  },
  key_locations: {
    moduleId: 'key_location',
    moduleName: 'key_locations'
  },
  leads: { moduleId: 'lead', moduleName: 'leads' },
  projects: { moduleId: 'project', moduleName: 'projects' },
  project_stages: {
    moduleId: 'project_stage',
    moduleName: 'project_stages'
  },
  properties: {
    moduleId: 'property',
    moduleName: 'properties'
  },
  oabs: {
    moduleId: 'oab',
    moduleName: 'oabs'
  },
  tenancy_applications: {
    moduleId: 'tenancy_application',
    moduleName: 'tenancy_applications'
  }
};
