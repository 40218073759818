import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { TEXTS, COLORS } from 'src/theme';

import Option from './core';
import GroupHeading from './group-heading';

const defaultStyles = StyleSheet({
  container: {
    padding: '0.25rem',
    paddingLeft: '1rem',
    cursor: 'pointer'
  }
});

@styled(defaultStyles)
@autobind
class SelectOption extends Component {
  static defaultProps = {
    isPassive: false,
    onHover: _.noop,
    onSelect: _.noop
  };

  render() {
    const {
      styles: s,
      option,
      pluckLabel,
      isActive,
      isPassive,
      isSelected
    } = this.props;
    const isFixture = _.get(option, 'isFixture');
    const isGroupHeading = _.get(option, 'isGroupHeading');

    const Fixture = _.get(option, 'model.select.Fixture');

    return isGroupHeading ? (
      <GroupHeading {...this.props} />
    ) : isFixture ? (
      <Fixture {...this.props} />
    ) : (
      <Option
        {...this.props}
        {...s('container', {
          isActive: isActive && !isPassive,
          isSelected: isSelected && !isPassive,
          isPassive,
          scrollIntoView: _.noop
        })}
      >
        {pluckLabel(option, this.props)}
      </Option>
    );
  }
}

export default SelectOption;
