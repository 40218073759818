/* eslint-disable max-lines */
import _ from 'lodash';
import React from 'react';
import { mapToOptions, separateAndCapitalize, VIEW_MODES } from './utils';
import { SEARCH_DEBOUNCE_RATE } from 'view/components/navigation/app-search';
import Search from './components/search';

const LOADING_STATE_CHANGE_DEBOUNCE = 150;

function init({ providers, recentOptions }) {
  const viewModes = [
    {
      value: VIEW_MODES.GLOBAL_SEARCH,
      label: _.capitalize(VIEW_MODES.GLOBAL_SEARCH),
      index: 0
    },
    ...providers.map((provider, currentIndex) => ({
      value: provider.type,
      label: separateAndCapitalize(provider.type, '_'),
      index: currentIndex + 1
    }))
  ];

  return {
    viewModes,
    searchTerm: '',
    isLoading: false,
    partiallyLoading: false,
    searchErrors: null,
    viewAllInListValue: null,
    viewMode: _.first(viewModes),
    options: mapToOptions(recentOptions),
    optionTotals: {},
    preservedOptions: {
      options: [],
      searchTerm: null,
      optionTotals: {},
      resolvedQueries: {}
    },
    resolvedQueries: {}
  };
}

function createReducer({
  recentOptions,
  maximumGlobalListOptions,
  maximumScopedListOptions
}) {
  return function reducer(state, action) {
    const {
      searchTerm,
      viewMode,
      viewModes,
      options,
      optionTotals,
      preservedOptions,
      resolvedQueries
    } = state;

    function getLimitedOptionsAndTotals(optionsToFilter, append, total) {
      const groupedByType = _.groupBy(optionsToFilter, (option) => option.type);

      // Construct option totals to be used later
      const newTotals = _.reduce(
        groupedByType,
        (acc, value, key) => {
          acc[key] = total;
          return acc;
        },
        {}
      );

      // Strip excess options up to max
      const limitedOptions = _.reduce(
        groupedByType,
        (acc, value) => {
          acc.push(
            viewMode.value === VIEW_MODES.GLOBAL_SEARCH
              ? value.slice(0, maximumGlobalListOptions)
              : value.slice(0, maximumScopedListOptions)
          );
          return acc;
        },
        []
      );

      return {
        options: _.flatten(limitedOptions),
        // Append current state option totals after the new ones
        // since the new ones are just counting the limited options
        optionTotals: append ? { ...newTotals, ...optionTotals } : newTotals
      };
    }

    switch (action.type) {
      case 'SET_LOADING':
        return {
          ...state,
          isLoading: action.payload
        };
      case 'SET_SEARCH_TERM': {
        return {
          ...state,
          searchTerm: action.payload,
          isLoading: action.payload.length >= 2,
          partiallyLoading: false,
          options: !action.payload.length ? mapToOptions(recentOptions) : [],
          optionTotals:
            viewMode.value !== 'ALL' ? { [viewMode.value]: 10 } : {},
          resolvedQueries: {},
          preservedOptions: {
            options: [],
            searchTerm: null,
            optionTotals: {},
            resolvedQueries: {}
          }
        };
      }
      case 'SET_OPTIONS':
        return {
          ...state,
          ...getLimitedOptionsAndTotals(
            mapToOptions(action.payload),
            false,
            action.payload.total
          )
        };
      case 'CLEAR_OPTION_TOTALS':
        return {
          ...state,
          optionTotals: {}
        };
      case 'APPEND_OPTIONS':
        return {
          ...state,
          ...getLimitedOptionsAndTotals(
            mapToOptions([...options, ...action.payload.options]),
            true,
            action.payload.total
          ),
          resolvedQueries: {
            ...resolvedQueries,
            ...action.payload.resolvedQueries
          },
          partiallyLoading: true
        };
      case 'COMPLETE_GLOBAL_SEARCH':
        return {
          ...state,
          isLoading: false,
          partiallyLoading: false,
          // Always preserve global search results
          preservedOptions: {
            options,
            searchTerm,
            optionTotals,
            resolvedQueries
          }
        };
      case 'COMPLETE_SCOPED_SEARCH':
        return {
          ...state,
          ...getLimitedOptionsAndTotals(
            mapToOptions(action.payload.options),
            false,
            action.payload.total
          ),
          isLoading: false,
          viewAllInListValue: action.payload.viewAllInListValue
        };
      case 'SET_SEARCH_ERROR':
        return {
          ...state,
          isLoading: false,
          searchErrors: action.payload
        };
      case 'CHANGE_CATEGORY':
        return {
          ...state,
          isLoading: true,
          viewMode: viewModes.find((mode) => mode.value === action.payload)
        };
      case 'SELECT':
        return {
          ...state,
          partiallyLoading: false,
          searchTerm: '',
          options: mapToOptions(recentOptions),
          optionTotals: {},
          resolvedQueries: {},
          preservedOptions: {
            options: [],
            searchTerm: null,
            optionTotals: {},
            resolvedQueries: {}
          }
        };
      case 'BACK': {
        const isPreservedTerm = searchTerm === preservedOptions.searchTerm;

        return {
          ...state,
          isLoading: searchTerm.length >= 2 && !isPreservedTerm,
          partiallyLoading: false,
          viewMode: viewModes.find(
            (mode) => mode.value === VIEW_MODES.GLOBAL_SEARCH
          ),
          // Set options to recents if the search term isn't long enough or
          // preserved options if the search term is the same
          options: isPreservedTerm
            ? preservedOptions.options
            : !searchTerm.length
            ? mapToOptions(recentOptions)
            : [],
          optionTotals: isPreservedTerm ? preservedOptions.optionTotals : {},
          resolvedQueries: isPreservedTerm
            ? preservedOptions.resolvedQueries
            : {}
        };
      }
      case 'CLEAR':
        return {
          ...state,
          searchTerm: '',
          isLoading: false,
          partiallyLoading: false,
          options: mapToOptions(recentOptions),
          optionTotals: {},
          resolvedQueries: {},
          preservedOptions: {
            options: [],
            searchTerm: null,
            optionTotals: {},
            resolvedQueries: {}
          }
        };
      default:
        return state;
    }
  };
}

function SearchContainer({ debounce, doSearch, ...props }) {
  const {
    recentOptions,
    maximumGlobalListOptions = 3,
    maximumScopedListOptions = 100
  } = props;

  const [state, dispatch] = React.useReducer(
    createReducer({
      recentOptions,
      maximumGlobalListOptions,
      maximumScopedListOptions
    }),
    props,
    init
  );

  const { viewMode, searchTerm, preservedOptions } = state;

  React.useEffect(() => {
    dispatch({
      type: 'SET_OPTIONS',
      payload: recentOptions
    });
  }, [recentOptions]);

  // Using useEffect here doesn't run the cleanup function
  // early enough to be able to effectively cancel the resolvers
  // before we've set our new (correct) state with SET_SEARCH_TERM
  React.useLayoutEffect(() => {
    if (searchTerm.length === 0) {
      dispatch({
        type: 'BACK'
      });
    }

    if (searchTerm.length < 2) {
      return;
    }

    // Don't run the search if the search term is the same
    // as the preserved search term
    if (viewMode.value === VIEW_MODES.GLOBAL_SEARCH) {
      if (searchTerm === preservedOptions.searchTerm) {
        return;
      }
    }

    let cancelled = false;

    const debouncedSetLoading = _.debounce(
      (isLoading) => dispatch({ type: 'SET_LOADING', payload: isLoading }),
      LOADING_STATE_CHANGE_DEBOUNCE
    );

    const debouncedSearch = _.debounce(() => {
      const search = doSearch(searchTerm, viewMode.value);

      if (_.isArray(search)) {
        let highestRenderedIndex = -1;
        const delayedRenderOptions = [];

        search.forEach((request, index) => {
          request.then((response) => {
            const canRender = index === highestRenderedIndex + 1;

            const rows = response.rows || [];

            if (cancelled) {
              return;
            }

            if (!canRender) {
              delayedRenderOptions[index] = rows;
              return;
            }

            const newResolvedQueries = {
              [index]: true
            };

            // Clear on the first rendered set of options and not when
            // we start searching so that the loading skeleton has
            // the right number of rows and we don't keep totals from
            // the previous global search (because we're appending here)
            if (index === 0) {
              dispatch({ type: 'CLEAR_OPTION_TOTALS' });
            }

            highestRenderedIndex = index;

            const newOptions = [
              ...rows,
              // Find all of the options that can also be rendered now that
              // we have found another set of options that can be rendered
              ..._.flatten(
                delayedRenderOptions.map((options, i) => {
                  if (i === highestRenderedIndex + 1) {
                    newResolvedQueries[i] = true;
                    highestRenderedIndex = i;
                    return options;
                  }
                })
              ).filter(Boolean)
            ];

            dispatch({
              type: 'APPEND_OPTIONS',
              payload: {
                index,
                options: newOptions,
                total: response.total,
                resolvedQueries: newResolvedQueries
              }
            });

            if (newOptions.length) {
              debouncedSetLoading(false);
            }

            if (search.length === highestRenderedIndex + 1) {
              dispatch({ type: 'COMPLETE_GLOBAL_SEARCH' });
            }
          });
        });
      } else {
        search.then((response) => {
          if (cancelled) {
            return;
          }

          const hasViewStateId =
            response.length &&
            _.isArray(_.first(response)) &&
            _.isString(_.last(response));

          // We are doing a scoped search and need to attempt to grab the viewAllInListValue
          // the response length will always be 2 upon providing a view all in list value
          const searchResponse = hasViewStateId ? _.first(response) : response;
          const viewAllInListValue = hasViewStateId ? _.last(response) : null;

          const options = searchResponse[0].rows;

          if (response.error) {
            dispatch({ type: 'SET_SEARCH_ERROR', payload: response.error });
          } else {
            dispatch({
              type: 'COMPLETE_SCOPED_SEARCH',
              payload: {
                viewAllInListValue,
                options,
                total: searchResponse[0].total
              }
            });
          }
        });
      }
    }, debounce || SEARCH_DEBOUNCE_RATE);

    debouncedSearch();

    return () => {
      cancelled = true;
      debouncedSearch.cancel();
      debouncedSetLoading.cancel();
    };
  }, [viewMode, searchTerm, debounce, preservedOptions.searchTerm, doSearch]);

  return <Search {...props} {...state} dispatch={dispatch} />;
}

export default SearchContainer;
