import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS, PADDINGS } from 'theme';

import { SavedListHeadingContainer } from './heading-container';

interface SavedListGroupHeadingContainerProps {
  children: React.ReactNode;
}

const defaultStyles = StyleSheet({
  subHeading: {
    borderBottom: `2px solid ${COLORS.PRIMARY.LIGHT_GREY}`,
    paddingBottom: PADDINGS.S,
    width: '100%'
  }
});

export function SavedListGroupHeadingContainer(
  props: SavedListGroupHeadingContainerProps
) {
  const { children } = props;

  const s = useStyles(defaultStyles);

  return (
    <Box {...s('subHeading')}>
      <SavedListHeadingContainer>{children}</SavedListHeadingContainer>
    </Box>
  );
}
