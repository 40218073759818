import uiModel from 'data/models/custom/ui';
import { useModelActions, ListOfItems } from '@rexlabs/model-generator';
import { useDialog } from 'hooks/use-dialog';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { useCallback, useState } from 'react';
import { SlidingScaleSetting } from '../data/types';
import { Criteria } from 'src/types/criteria';
import { ApiActions } from 'data/models/custom/api-model';

export const useSlidingScaleSetting = ({
  slidingScaleActions
}: {
  slidingScaleActions: ApiActions<SlidingScaleSetting>;
}) => {
  const errorDialog = useErrorDialog();
  const confirmation = useDialog('confirmation');
  const addSlidingScale = useDialog('addSlidingScaleSetting');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [savedFilters, setSavedFilters] = useState<SlidingScaleSetting>();
  const [slidingScaleSettings, setSlidingScaleSettings] = useState<
    SlidingScaleSetting[]
  >([]);

  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;

  const { archive, remove, search } = slidingScaleActions;

  const fetchSlidingScaleSetting = async (id: string) => {
    setIsLoading(true);
    try {
      // await and do api call here
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorDialog.open(error as Error);
    }
  };

  const fetchSlidingScaleSettings = async () => {
    setIsLoading(true);
    try {
      const criteria: Criteria[] = [];

      if (savedFilters) {
        const { name, system_record_state } = savedFilters;
        if (name)
          criteria.push({
            name: 'name',
            type: 'like',
            value: `%${name}%`
          });

        if (system_record_state)
          criteria.push({
            name: 'system_record_state',
            value: system_record_state
          });
      }

      const res = await search({ criteria, offset: 0, limit: 100 });
      setSlidingScaleSettings(res);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorDialog.open(error as Error);
    }
  };

  const onArchive = useCallback(
    async (data: SlidingScaleSetting, onArchiveSuccess?: () => void) => {
      confirmation.open({
        title: `Archive ‘${data.name}’`,
        message: `Are you sure you want to archive this agent sliding scale?`,
        confirmText: 'Confirm Archive',
        onConfirm: async () => {
          loadingIndicatorOn({ message: 'Archiving' });
          try {
            await archive(data.id);
            confirmation.close();
            loadingIndicatorOff();
            onArchiveSuccess?.();
          } catch (error) {
            loadingIndicatorOff();
            errorDialog.open(error as Error);
          }
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const onRemove = useCallback(
    async (data: SlidingScaleSetting, onRemoveSuccess?: () => void) => {
      confirmation.open({
        title: `Delete ‘${data.name}’`,
        message: `Are you sure you want to delete this agent sliding scale?`,
        confirmText: 'Confirm Delete',
        onConfirm: async () => {
          loadingIndicatorOn({ message: 'Removing' });
          try {
            await remove(data.id);
            confirmation.close();
            loadingIndicatorOff();
            onRemoveSuccess?.();
          } catch (error) {
            loadingIndicatorOff();
            errorDialog.open(error as Error);
          }
        }
      });
      //  eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const onDuplicate = useCallback(
    async (dataToDuplicate: SlidingScaleSetting) => {
      addSlidingScale.open({
        slidingScaleSetting: dataToDuplicate,
        action: 'duplicate'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    isLoading,
    setIsLoading,
    savedFilters,
    setSavedFilters,
    onArchive,
    onRemove,
    onDuplicate,
    slidingScaleSettings,
    setSlidingScaleSettings,
    fetchSlidingScaleSetting,
    fetchSlidingScaleSettings
  };
};
