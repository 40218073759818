import React, { SyntheticEvent, ReactNode } from 'react';

import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import { FONT } from 'theme';

// Shared
import { DefaultButton } from 'shared/components/button';

const buttonOverrides = {
  Button: StyleSheet({
    container: {
      width: '100%',
      maxWidth: '300px',
      // height is using !important here -> https://github.com/rexlabsio/rex-app/blob/a547720f3afdc3865483ad12d859e1c3bceb15f8/shell/src/view/components/portal/dialog-overlay.js#L69
      // so we use minHeight to get around that problem
      minHeight: '50px'
    }
  })
};

/**
 * This is used to override the DefaultButton component styles.
 */
const buttonStyles = StyleSheet({
  label: {
    fontSize: FONT.SIZES.LARGE
  }
});

export interface TextContentProps {
  children: ReactNode;
  onClick?: (e: SyntheticEvent) => any;
}

export function RedirectButton({ onClick, children }: TextContentProps) {
  return (
    <StylesProvider components={buttonOverrides} prioritiseParentStyles={false}>
      <DefaultButton styles={buttonStyles} softblue large onClick={onClick}>
        {children}
      </DefaultButton>
    </StylesProvider>
  );
}
