import { Generator } from 'shared/utils/models';
import { ModelGeneratorLoaded } from '@rexlabs/model-generator';

type filterNames = 'agent' | 'date_range' | 'listing_category' | 'sold_status';

export interface Widget {
  columns?: Record<string, any>[] | null;
  data_type: { type: string; format: string };
  id: number;
  label: string;
  type_category_id?:
    | 'default'
    | 'pipeline'
    | 'ratio'
    | 'comparison'
    | 'comparison_ratio';
  type_id: 'scorecard' | 'table' | 'bar' | 'container';
  widget_options: Record<string, any>;
  widgets?: Widget[];
}

interface ConnectedWidget {
  priority: number;
  id: number;
  widget: Widget;
}
export interface DashboardEntity {
  title: string;
  supported_filters: filterNames[];
  widgets: ConnectedWidget[];
  id: string;
}

export type DashboardsModel = ModelGeneratorLoaded<DashboardEntity, any>;

const TYPE = 'reportingDashboards';

const dashboardsModel = new Generator<DashboardEntity>(
  TYPE
).createEntityModel();

export default dashboardsModel;
