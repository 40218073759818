import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { withRegion } from 'src/hocs/with-region';
import Fixture from 'view/components/input/select/fixtures/default';
import { DialogsBridge } from 'hooks/use-dialog';

@withRegion
@autobind
class AppraisalsFixture extends Component {
  handleFixtureClick() {
    const {
      option: { fixturePrefillData }
    } = this.props;
    const { propertyId, propertyCategoryId, appraisalTypeId, callback } =
      fixturePrefillData || {};

    // Using DialogsBridge rather than @withDialog,
    // as the dialog needs to stay open even
    // when the fixture unmounts
    DialogsBridge.addEditAppraisal.open({
      propertyId: propertyId,
      propertyCategoryId,
      appraisalTypeId,
      callback: (appraisal) => {
        callback(appraisal);
      }
    });
  }

  render() {
    const type = this.props.region.isEU ? 'VALUATION' : 'APPRAISAL';
    return (
      <Fixture addNewEntity={this.handleFixtureClick}>ADD NEW {type}</Fixture>
    );
  }
}

export default AppraisalsFixture;
