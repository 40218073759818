import React from 'react';
import { get } from 'lodash';

import { ICONS } from 'shared/components/icon';
import { HiddenField } from 'view/components/form';
import { IconButton } from 'view/components/button';
import { RelatedTable } from 'src/view/components/record-details-screen/related-table';
import { HeadingWithButtons } from 'components/_experiment/record-block/heading';

import { DateTimeCell } from 'components/record-list-screen/cells/date-time-cell';
import { ActionsCell } from 'components/record-list-screen/cells/actions-cell';
import { ColumnsConfig } from 'components/record-list-screen/types';

const AddSvg: any = ICONS.ADD_MEDIUM;

const columns: ColumnsConfig = [
  {
    id: 'description',
    label: 'filename',
    Cell: ActionsCell,
    cellProps: {
      items: [
        {
          label: 'View',
          // eslint-disable-next-line
          onClick: ({ data }) => alert('view document:' + data.id)
        },
        {
          label: 'Send Link',
          // eslint-disable-next-line
          onClick: () => alert('send link')
        },
        {
          label: 'Get Share Link',
          // eslint-disable-next-line
          onClick: () => alert('send link')
        },
        {
          label: 'Rename',
          // eslint-disable-next-line
          onClick: () => alert('rename')
        },
        {
          label: 'Delete',
          // eslint-disable-next-line
          onClick: () => alert('delete')
        }
      ]
    }
  },
  {
    id: 'system_ctime',
    label: 'date uploaded',
    Cell: DateTimeCell,
    width: '180px'
  }
];

interface DocumentsBlockViewProps {
  fieldName: string;
  values: Record<string, any>;
}

export function DocumentsBlockView({
  fieldName,
  values
}: DocumentsBlockViewProps) {
  return (
    <>
      <HiddenField name={fieldName} />
      <RelatedTable
        Heading={() => (
          <HeadingWithButtons
            Buttons={() => (
              <>
                <IconButton
                  red
                  circle
                  Icon={AddSvg}
                  // eslint-disable-next-line
                  onClick={() => alert('upload file')}
                >
                  upload document
                </IconButton>
              </>
            )}
          >
            Documents
          </HeadingWithButtons>
        )}
        items={get(values, fieldName)}
        columns={columns}
      />
    </>
  );
}
