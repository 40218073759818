import { gql } from 'hooks/use-graphql-query';
import { CustomFields, User } from 'features/custom-reporting/data/fragments';
import { Project } from 'features/custom-reporting/modules/projects/project-data-fragments';

export const projectsReportQueryDefaultVariables = {
  include_subresource_record_owner: false,
  include_project_custom_fields: false,
  project_custom_field_ids: []
};

export const ProjectsReportQuery = gql`
  ${User}
  ${Project}
  ${CustomFields}

  query (
    $include_subresource_record_owner: Boolean!
    $include_project_custom_fields: Boolean!
    $project_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: projects(
      limit: $limit
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...Project
        custom_field_values(field_ids: $project_custom_field_ids)
          @include(if: $include_project_custom_fields) {
          ...CustomFields
        }
        system_owner_user @include(if: $include_subresource_record_owner) {
          ...User
        }
      }
    }
  }
`;
