import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { checkUserHasPermission } from 'utils/rights';

type Permissions = {
  check: (rights: any) => boolean;
  addons: any[];
  hasAddon: (addonName: string) => boolean;
  hasAlphaEnabled: () => boolean;
  hasBetaEnabled: () => boolean;
};

export function usePermissions(): Permissions {
  const session = useSelector((state: any) => state?.session);

  const check = useCallback(
    (accessRights) => {
      return checkUserHasPermission((dotNotation) => get(session, dotNotation))(
        accessRights
      );
    },
    [session]
  );

  const hasAddon = useCallback(
    (addon) => {
      // NOTE: BE stores the string `'0'` for the false value, which is why we have
      // this slightly awkward check here :D
      return get(session, `subscription_limits.add_ons.${addon}`) === '0'
        ? false
        : !!get(session, `subscription_limits.add_ons.${addon}`);
    },
    [session]
  );

  const hasBetaEnabled = useCallback(() => {
    return hasAddon('beta_enabled');
  }, [hasAddon]);

  const hasAlphaEnabled = useCallback(() => {
    return hasAddon('alpha_enabled');
  }, [hasAddon]);

  return useMemo(
    () => ({
      check,
      addons: session?.subscription_limits?.add_ons,
      hasAddon,
      hasAlphaEnabled,
      hasBetaEnabled
    }),
    [check, hasAddon, hasAlphaEnabled, hasBetaEnabled, session]
  );
}
