import { overlayEmitter, OVERLAY_ACTIONS } from 'data/emitters/overlays';
import mappings from 'utils/overlays/mapping';
import invariant from 'invariant';

const OverlaysBridge = Object.keys(mappings).reduce((all, callName) => {
  all[callName] = {
    // Open shell overlay (from classic)
    open: (props) => {
      const { ShellComponent: OverlayComponent } = mappings[callName];
      invariant(
        OverlayComponent,
        `Couldn't find a component in the mapping for the overlay with the callName "${callName}".`
      );

      overlayEmitter.emit(OVERLAY_ACTIONS.OPEN, {
        OverlayComponent,
        callName,
        props: props || {}
      });
    },

    // Close overlay
    close: () => {
      overlayEmitter.emit(OVERLAY_ACTIONS.CLOSE, {
        callName
      });
    }
  };

  return all;
}, {});

export default OverlaysBridge;
