import { popoutEmitter, POPOUT_ACTIONS } from 'data/emitters/popouts';

/**
 * Helper util to fire the close all event.
 */
const PopoutsBridge = {
  closeAll: () => {
    popoutEmitter.emit(POPOUT_ACTIONS.CLOSE_ALL);
  },
  unsubscribeCloseAll: () => {
    popoutEmitter.unsubscribe(POPOUT_ACTIONS.CLOSE_ALL);
  },
  popoutEmitter,
  POPOUT_ACTIONS
};

export default PopoutsBridge;
