import { SetFieldValue } from '@rexlabs/form';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { ContractCondition } from 'features/contract-conditions/data/contract-conditions';
import React from 'react';
import { COLORS } from 'src/theme';
import { FormField } from 'src/view/components/form';
import Checkbox from 'src/view/components/input/checkbox';

const defaultStyles = StyleSheet({
  head: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    borderBottom: `2px solid ${COLORS.STATES.IDLE}`
  },
  table: {
    width: '100%',
    '& td': {
      padding: '8px 15px',
      fontSize: '14px',
      fontWeight: 600
    },
    '& td:not(:first-child)': {
      textAlign: 'center',
      '& div:first-child': {
        display: 'flex',
        justifyContent: 'center'
      }
    },
    '& tr:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

const LISTING_TYPES = [
  {
    label: 'Business Sale',
    name: 'business_sale'
  },
  {
    label: 'Commercial Rental',
    name: 'commercial_rental'
  },
  {
    label: 'Commercial Sale',
    name: 'commercial_sale'
  },
  {
    label: 'Commercial Sale or Rental',
    name: 'commercial_sale_rental'
  },
  {
    label: 'Land',
    name: 'land_sale'
  },
  {
    label: 'Residential Sale',
    name: 'residential_sale'
  },
  {
    label: 'Rural',
    name: 'rural_sale'
  }
];

export const ListingTypesTableForm = ({
  setFieldValue,
  values
}: {
  setFieldValue: SetFieldValue<any>;
  values?: ContractCondition;
}) => {
  const s = useStyles(defaultStyles);
  return (
    <table {...s('table')}>
      <thead {...s('head')}>
        <td>Listing Type</td>
        <td>Allow</td>
        <td>Auto-add</td>
      </thead>
      {LISTING_TYPES.map((type) => (
        <tr key={type.name}>
          <td>{type.label}</td>
          <td>
            <FormField
              sendImmediate
              Input={Checkbox}
              onChange={(e: { target: { checked: boolean } }) => {
                if (!e.target.checked) {
                  setFieldValue(`add_${type.name}`, false);
                }
              }}
              name={`allow_${type.name}`}
            />
          </td>
          <td>
            <FormField
              sendImmediate
              Input={Checkbox}
              onChange={(e: { target: { checked: boolean } }) => {
                if (e.target.checked && !values?.[`allow_${type.name}`]) {
                  setFieldValue(`allow_${type.name}`, true);
                }
              }}
              name={`add_${type.name}`}
            />
          </td>
        </tr>
      ))}
    </table>
  );
};
