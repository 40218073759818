import { upperFirst, identity } from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

import { UserItem } from '../value-lists/account-users';
import { MergeType } from '../entities/mail-merge';
import { SystemOptionObj } from '../system-lists/types';

export interface EditCustomMergeTemplateItem {
  documents?: SystemOptionObj[];
  template_body: string;
  template_description?: string;
  template_email_subject: string;
  template_medium: MergeType;
  template_module: SystemOptionObj;
  template_name: string;
  id: string;
}

export interface RelatedDocument {
  description: string;
  id: number;
  system_created_user: UserItem;
  system_ctime: number | Date;
  system_modified_user: UserItem;
  system_modtime: Date;
  system_size_mb: number | null;
  uri: string;
  url: string;
}

export interface AdminMergeTemplateItem
  extends Required<EditCustomMergeTemplateItem> {
  account_id: number;
  do_not_append_email_footer: boolean;
  etag: string;
  is_hidden: null | boolean;
  library: null | { id: number; library_name: string };
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_owner_user: UserItem;
  system_record_state: 'active' | 'archived';
  related: { document_types: any[]; documents: RelatedDocument[] };
  simple_html_body: string;
  attachments?: SystemOptionObj[];
  isDirty?: boolean;
}

const TYPE = 'adminMergeTemplates';

const actionCreators = {
  getLetterTemplates: {
    request: () =>
      api.post(`${upperFirst(TYPE)}::search`, {
        criteria: [
          {
            name: 'template_medium',
            value: 'letter'
          }
        ]
      }),

    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getMergeTagsForRecordType: {
    request: ({ recordType, returnFormat = 'group_by_category' }) =>
      api.post(`${upperFirst(TYPE)}::getMergeTagsForRecordType`, {
        record_type: recordType,
        return_format: returnFormat
      }),

    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

export default new Generator<AdminMergeTemplateItem, typeof actionCreators>(
  TYPE
).createEntityModel({
  actionCreators
});
