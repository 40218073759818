import React, { PureComponent } from 'react';
import { EntitySelect, ValueListSelect } from 'view/components/input/select';
import { autobind } from 'core-decorators';
import _ from 'lodash';

import accountUsersModel from 'data/models/value-lists/account-users';
import contactsModel from 'data/models/entities/contacts';
import listingsModel from 'data/models/entities/listings';
import propertiesModel from 'data/models/entities/properties';
import projectsModel from 'data/models/entities/projects';
import projectStagesModel from 'data/models/entities/project-stages';

@autobind
class RecordSelect extends PureComponent {
  static defaultProps = {
    options: []
  };

  state = {
    newValue: null,
    entityOptions: []
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (!_.isEqual(value, prevProps.value)) {
      const newValue = Array.isArray(value)
        ? value.map((v) => this.transformValue(v))
        : this.transformValue(value);

      this.setState({
        newValue,
        entityOptions: Array.isArray(newValue)
          ? newValue
          : newValue
          ? [newValue]
          : []
      });
    }
  }

  componentDidMount() {
    /**
     * We do this so that the value passed in by Knockout has already been set,
     * previously the value was undefined or null during mount which prevented
     * the Select component from showing the selected options.
     *
     * Previously we were passing setting the value via the prop directly but
     * we want to control the value through state so we can set it once the
     * component has mounted.
     */
    const { value } = this.props;

    const newValue = Array.isArray(value)
      ? value.map((v) => this.transformValue(v))
      : this.transformValue(value);

    this.setState({
      newValue,
      entityOptions: Array.isArray(newValue)
        ? newValue
        : newValue
        ? [newValue]
        : []
    });
  }

  handleChange(e) {
    const {
      target: { value }
    } = e;
    const { serviceNames, multi, onChange } = this.props;
    let actualValue;

    if (multi) {
      actualValue = Array.isArray(value)
        ? value.map((v) => ({
            record: {
              id: _.get(v, 'value'),
              text: _.get(v, 'label'),
              stub: _.get(v, 'data')
            },
            service_name: _.get(v, 'model.serviceName') || _.first(serviceNames)
          }))
        : null;
    } else {
      actualValue = !_.isEmpty(value)
        ? {
            record: {
              id: _.get(value, 'value'),
              text: _.get(value, 'label'),
              stub: _.get(value, 'data')
            },
            service_name:
              _.get(value, 'model.serviceName') || _.first(serviceNames)
          }
        : null;
    }

    onChange({
      ...e,
      target: {
        ...e.target,
        value: actualValue
      }
    });

    this.setState({
      newValue: value,
      entityOptions: [value]
    });
  }

  // TODO: Don't allow mixing of value lists + entity lists, maybe throw?
  getInfo() {
    const { serviceNames } = this.props;
    const modelMap = {
      AccountUsers: { model: accountUsersModel, isValueList: true },
      Contacts: { model: contactsModel },
      Listings: { model: listingsModel },
      Properties: { model: propertiesModel },
      Projects: { model: projectsModel },
      ProjectStages: { model: projectStagesModel },
      __default: {
        model: contactsModel
      }
    };

    return serviceNames.reduce(
      (result, currentName) => {
        let currentModel = null;

        if (!modelMap[currentName]) {
          console.warn(
            '[Record Select]: Unknown service name used to reference a model.'
          );
          currentModel = modelMap.__default;
        } else {
          currentModel = modelMap[currentName];
        }

        const mappings = [...result.mappings, currentModel];
        const isValueList = mappings.every((m) => m.isValueList);

        if (mappings.some((m) => m.isValueList) && !isValueList) {
          throw new Error(
            'You cannot mix value list models with entity models.'
          );
        }

        return {
          mappings,
          models: mappings.map((m) => m.model),
          isValueList: mappings.every((m) => m.isValueList)
        };
      },
      { models: [], isValueList: true, mappings: [] }
    );
  }

  transformValue(value) {
    if (!value) {
      return;
    }

    const modelMap = {
      Properties: propertiesModel,
      Listings: listingsModel,
      Contacts: contactsModel,
      Projects: projectsModel,
      ProjectStages: projectStagesModel,
      AccountUsers: accountUsersModel
    };
    const serviceName = _.get(value, 'service_name');

    return {
      value:
        _.get(value, 'record.id') ||
        _.get(value, 'value') ||
        _.get(value, 'id') ||
        _.get(value, '_id'),
      label:
        _.get(value, 'record.text') ||
        _.get(value, 'record.system_search_key') ||
        _.get(value, 'name') ||
        _.get(value, 'label'),
      data:
        _.get(value, 'record.stub') ||
        _.get(value, 'record') ||
        _.get(value, 'data') ||
        value,
      model: modelMap[serviceName]
    };
  }

  getTagErrors() {
    const { error } = this.props;

    return {
      inputError: !!error,
      meta: { error }
    };
  }

  render() {
    const { newValue, entityOptions } = this.state;
    const { ...rest } = this.props;
    const { models, isValueList = false } = this.getInfo();
    const { inputError, meta } = this.getTagErrors();

    if (isValueList) {
      return (
        <ValueListSelect
          {...rest}
          value={newValue}
          models={models}
          onChange={this.handleChange}
          valueAsObject
        />
      );
    }

    return (
      <EntitySelect
        {...rest}
        value={newValue}
        options={entityOptions}
        models={models}
        onChange={this.handleChange}
        valueAsObject
        inputError={inputError}
        meta={meta}
      />
    );
  }
}

export default RecordSelect;
