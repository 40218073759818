import { getListIdFromColumnId } from 'features/pipelines';
import { getGroupIdForDateString } from 'features/pipelines/data/utils/date-grouping';
import { AppraisalData } from 'data/models/entities/appraisals';

export const pipelineActionCreators = {
  setAggregatesForListId: {
    reduce: (state, { payload: { listId, aggregates } }) => {
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: {
            ...state.lists[listId],
            aggregates
          }
        }
      };
    }
  },
  moveItemToNewLists: {
    reduce: (
      state,
      { payload: { item } }: { payload: { item: AppraisalData } }
    ) => {
      const newPipelineStageListId = getListIdFromColumnId(
        item?.pipeline_stage?.id,
        'pipeline_stage'
      );
      const newDateListId = getListIdFromColumnId(
        getGroupIdForDateString(item.expected_close_date, item?.pipeline_stage),
        'days_to_expected_close_30_day_bucketed'
      );

      const newLists = { ...state.lists };

      function addToList(listId: string) {
        newLists[listId] = {
          ...newLists[listId],
          pagination: {
            ...newLists[listId].pagination,
            totalItems: newLists[listId].pagination.totalItems + 1
          },
          items: [item.id].concat(newLists[listId].items)
        };
      }

      function removeFromList(listId: string) {
        newLists[listId] = {
          ...newLists[listId],
          pagination: {
            ...newLists[listId].pagination,
            totalItems: newLists[listId].pagination.totalItems - 1
          },
          items: newLists[listId].items.filter((itemId) => itemId !== item.id)
        };
      }

      function recalculateAggregatesForList(listId: string) {
        newLists[listId] = {
          ...newLists[listId],
          aggregates: {
            count: newLists[listId].pagination.totalItems,
            sum_comm_est_amount_net_of_tax: newLists[listId].items.reduce(
              (accum, itemId) => {
                accum +=
                  state.items[itemId]?.data.comm_est_amount_net_of_tax || 0;
                return accum;
              },
              0
            )
          }
        };
      }

      for (const listId of Object.keys(state.lists)) {
        if (newLists[listId].items.includes(item.id)) {
          removeFromList(listId);
          recalculateAggregatesForList(listId);
        }
      }

      for (const listId of [newDateListId, newPipelineStageListId]) {
        if (newLists[listId]) {
          addToList(listId);
          recalculateAggregatesForList(listId);
        }
      }

      return {
        ...state,
        lists: newLists
      };
    }
  }
};
