import { StyleSheet, useStyles } from '@rexlabs/styling';
import { DefaultCell } from 'components/record-list-screen/cells/default';
import Box from '@rexlabs/box';
import Tooltip from 'view/components/tooltip';
import Icon, { ICONS } from 'shared/components/icon';
import React, { ReactNode } from 'react';

const styles = StyleSheet({
  noMailIcon: {
    color: '#929088',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px'
  },
  text: {
    width: '43px'
  }
});

interface DocumentsTableAccessCellProps {
  value?: ReactNode;
  data?: Record<string, any>;
}
export function DocumentsTableAccessCell({
  value,
  data
}: DocumentsTableAccessCellProps) {
  const s = useStyles(styles);
  return (
    <DefaultCell>
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        height={'20px'}
        sx={'5px'}
      >
        <p {...s('text')}>{value}</p>
        {data?.exclude_from_mail_merge ? (
          <Tooltip
            hoverTimeout={1}
            placement='right'
            distance={0}
            Content={() => 'Excluded from mail merge attachment'}
          >
            <Icon
              type={ICONS.EMAIL_STRIKETHROUGH}
              hasControlledColor={false}
              {...s('noMailIcon')}
            />
          </Tooltip>
        ) : null}
      </Box>
    </DefaultCell>
  );
}
