import React, { Component } from 'react';

class DefaultErrorComponent extends Component {
  render() {
    const { errors } = this.props;
    return (
      <div
        key='error'
        errors={errors}
        style={{ userSelect: 'none', cursor: 'default' }}
      >
        <div>
          Well, this is embarrassing. We have encountered an error. We’ll get
          one of our developers to give the server a kick.
        </div>
      </div>
    );
  }
}

export default DefaultErrorComponent;
