import React from 'react';
import {
  Checkbox as LunaCheckbox,
  CheckboxGroup as LunaCheckboxGroup
} from '@rexlabs/checkbox';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';

const overrides = {
  Checkbox: StyleSheet({
    input: {
      // NOTE: The Vivid Checkbox hides the input element with opacity and position:absolute, which
      // lines it up with the styled checkbox we use. When we use the checkbox in the list screens, there
      // is a visual bug when a check box is clicked on short displays - the list jumps and there is a huge
      // empty space between the bottom of the page and the table. Setting the display to none, hides the
      // input element completely so visual bug is fixed. Ideally, this should be fixed in vivid.
      // https://github.com/rexlabsio/vivid-private/issues/474
      display: 'none'
    }
  })
};

export function Checkbox(props) {
  return (
    <StylesProvider components={overrides} prioritiseParentStyles={false}>
      <LunaCheckbox {...props} />
    </StylesProvider>
  );
}

export function CheckboxGroup({ items, horizontal, ...props }) {
  return (
    <LunaCheckboxGroup
      {...props}
      orientation={horizontal ? 'horizontal' : 'vertical'}
      options={items}
    />
  );
}

export default Checkbox;
