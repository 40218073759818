import { StyleSheet, useStyles } from '@rexlabs/styling';
import React, { CSSProperties, HTMLProps } from 'react';
import { COLORS } from 'src/theme';

const styles = StyleSheet({
  container: {
    position: 'relative',
    display: 'flex',
    width: 'max-content'
  },
  checkbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    margin: 0,
    pointerEvents: 'none'
  },
  button: {
    display: 'block',
    width: '60px',
    padding: '5.5px 0',
    margin: '0px',
    border: 'solid 2px #d9d9d4',
    backgroundColor: '#d9d9d4',
    fontWeight: 600,
    textAlign: 'center',
    color: '#a1a19e',
    fontSize: '13px',
    cursor: 'pointer'
  },
  primary: {
    borderLeftStyle: 'solid',
    borderRadius: '4px 0px 0px 4px'
  },
  primaryActive: {
    borderColor: '#1c94d4',
    backgroundColor: COLORS.PRIMARY.BLUE,
    color: COLORS.WHITE
  },
  primaryDisabled: {
    borderColor: 'rgba(255, 255, 255, 0.10)',
    backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
    color: COLORS.WHITE,
    cursor: 'default'
  },
  secondary: {
    borderRightStyle: 'solid',
    borderRadius: '0px 4px 4px 0px'
  },
  secondaryActive: {
    borderColor: '#2e2e2e',
    backgroundColor: '#424242',
    color: '#fff'
  },
  secondaryDisabled: {
    borderColor: 'rgba(255, 255, 255, 0.10)',
    backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
    color: COLORS.WHITE,
    cursor: 'default'
  }
});

interface ToggleProps extends HTMLProps<HTMLInputElement> {
  labels?: [string, string];
  width?: CSSProperties['width'];
}

export function Toggle({
  value,
  onChange,
  name,
  labels = ['Yes', 'No'],
  width,
  disabled = false,
  ...rest
}: ToggleProps) {
  const s = useStyles(styles);
  return (
    <div {...s('container')} style={{ width }}>
      <input
        {...s('checkbox')}
        type='checkbox'
        name={name}
        checked={!!value}
        {...rest}
      />
      <button
        {...s('button', 'primary', {
          primaryActive: !!value && !disabled,
          primaryDisabled: disabled
        })}
        style={{ width }}
        type='button'
        disabled={disabled}
        onClick={() =>
          onChange?.({
            target: {
              // @ts-expect-error name exists
              name,
              value: true
            }
          })
        }
      >
        {labels[0]}
      </button>
      <button
        {...s('button', 'secondary', {
          secondaryActive: value !== '' && !value && !disabled,
          secondaryDisabled: value !== '' && !value && disabled
        })}
        style={{ width }}
        type='button'
        disabled={disabled}
        onClick={() =>
          onChange?.({
            target: {
              // @ts-expect-error name exists
              name,
              value: false
            }
          })
        }
      >
        {labels[1]}
      </button>
    </div>
  );
}
