import React from 'react';
import { Body } from 'components/text/body';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import KeyboardKey from 'components/keyboard-key';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    zIndex: 1,
    background: 'white',
    gap: '8px',
    padding: '8px 4px 6px 4px',
    display: 'flex',
    borderTop: `1px solid ${COLORS.BACKGROUNDS.SAND_DARK}`,
    flexDirection: 'row'
  }
});

export default function SearchHelpBar() {
  const s = useStyles(defaultStyles);

  // One of the rare instances where detecting platform
  // is needed (to determine ctrl vs cmd). navigator.platform
  // is deprecated, so we check userAgentData first
  const isMac = (
    (navigator as any)?.userAgentData?.platform ||
    navigator.platform ||
    ''
  )
    .toUpperCase()
    .includes('MAC');

  return (
    <Box {...s('container')}>
      <Box flex={'0 0 auto'}>
        <KeyboardKey text={`${isMac ? '\u2318' : 'ctrl'} + click`} />
        <Body light small normal regular>
          open in new tab
        </Body>
      </Box>
      <Box flex={'0 0 auto'}>
        <KeyboardKey text={'tag:'} />
        <Body light small normal regular>
          search for tags
        </Body>
      </Box>
      <Box flex={'0 0 auto'}>
        <KeyboardKey text={'key:'} />
        <Body light small normal regular>
          search for keys
        </Body>
      </Box>
      <Box flex={'0 0 auto'}>
        <KeyboardKey text={'"term"'} />
        <Body light small normal regular>
          search for exact match
        </Body>
      </Box>
    </Box>
  );
}
