import React from 'react';
import { Body } from 'components/text/body';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    padding: '3px 6px',
    borderRadius: '3px',
    boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 0px 0px',
    background: COLORS.BACKGROUNDS.SAND_LIGHT,
    display: 'inline-block',
    margin: '-3px 4px 0 4px'
  }
});

interface KeyboardKeyProps {
  text: string;
}

export default function KeyboardKey({ text }: KeyboardKeyProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box {...s('container')}>
      <Body small normal dark medium>
        {text}
      </Body>
    </Box>
  );
}
