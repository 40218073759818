import { useMemo } from 'react';
import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  ContractConditionsReportQuery,
  contractConditionsReportQueryDefaultVariables
} from './contract-conditions-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'listing.listing',
  'contract.agent.name',
  'contract.contract_status.text',
  'condition_type',
  'condition_date_of',
  'condition_date_completed',
  'contract.detail_deposit_full_or_prepayment'
];

export function useContractConditionsModule() {
  const region = useRegion();
  const ww = useWhichWordFactory();
  const {
    contacts: contactCustomFields,
    listings: listingCustomFields,
    properties: propertyCustomFields,
    contracts: contractCustomFields
  } = useCustomFields();

  const contractConditionsConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap[`${ww('contract').toLowerCase()}_conditions`],
      moduleLabel: `${ww('contract')} Conditions`,
      exportPrivilege: 'exporting.contracts',
      queryConfig: {
        graphQlQuery: ContractConditionsReportQuery,
        queryVariables: contractConditionsReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'condition_date_of',
            direction: 'desc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing.listing',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: `${ww('contract')} conditions`,
          children: makeEntity({
            entity: 'contractConditions',
            prefix: '',
            whichWord: ww
          })
        },
        {
          headerName: ww('contract'),
          children: makeEntity({
            entity: 'contract',
            prefix: 'contract',
            subresource: 'contract',
            whichWord: ww,
            customFields: contractCustomFields,
            region
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property.listing.property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Vendor',
          children: makeEntity({
            entity: 'contact',
            prefix: 'owner.listing.contact_relationships.0.contact',
            subresource: 'vendor',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Vendor solicitor',
          children: makeEntity({
            entity: 'contact',
            prefix: 'vendor_solicitor.listing.legal_solicitor',
            subresource: 'vendor_solicitor',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Vendor solicitor contact',
          children: makeEntity({
            entity: 'contact',
            prefix: 'vendor_solicitor_contact.listing.legal_solicitor_contact',
            subresource: 'vendor_solicitor_contact',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser 1',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchasers.purchtenant_contacts.0',
            subresource: 'purchasers',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser 2',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchasers.purchtenant_contacts.1',
            subresource: 'purchasers',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser 3',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchasers.purchtenant_contacts.2',
            subresource: 'purchasers',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser solicitor',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchaser_solicitor.purchtenant_solicitor',
            subresource: 'purchaser_solicitor',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Purchaser solicitor contact',
          children: makeEntity({
            entity: 'contact',
            prefix: 'purchaser_solicitor_contact.purchtenant_solicitor_contact',
            subresource: 'purchaser_solicitor_contact',
            customFields: contactCustomFields,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'next_30_days',
      defaultDateFieldPreference: 'event.condition_date_of',
      defaultUserFieldPreference: 'contract.agent_id',
      topLevelFilters: {
        user: {
          availableFields: [
            {
              fieldName: 'contract.agent_id',
              label: 'Condition agent'
            }
          ]
        },
        date: {
          availableFields: [
            {
              fieldName: 'event.condition_date_of',
              label: 'Condition due'
            }
          ]
        }
      }
    }),
    [
      ww,
      region,
      contactCustomFields,
      listingCustomFields,
      propertyCustomFields,
      contractCustomFields
    ]
  );
  return contractConditionsConfig;
}
