import { gql } from 'hooks/use-graphql-query';

export const User = gql`
  fragment User on User {
    id
    name
    email_address
    first_name
    last_name
  }
`;

export const Property = gql`
  fragment Property on Property {
    id
    system_search_key
    adr_building {
      name
    }
    adr_unit_number
    adr_street_number
    adr_street_name
    adr_locality
    adr_suburb_or_town
    adr_state_or_region
    adr_postcode
    attr_tenure_id {
      text
    }
    property_category {
      text
    }
    property_subcategory {
      text
    }
    is_dnd
    system_ctime
    attr_bedrooms
    attr_bathrooms
    attr_ensuites
    attr_toilets
    attr_living_areas
    attr_garages
    attr_carports
    attr_open_spaces
    attr_build_year
    attr_buildarea
    attr_buildarea_unit {
      text
    }
    attr_landarea
    attr_landarea_unit {
      text
    }
    tags
    system_modtime
    system_created_user {
      name
    }
  }
`;

export const Feedback = gql`
  fragment Feedback on Feedback {
    id
    date_of
    feedback_type {
      text
    }
    amount_of
    interest_level {
      text
    }
    system_approval_status {
      id
    }
    system_created_user {
      name
    }
    system_ctime
    note
  }
`;

export const Appraisal = gql`
  fragment Appraisal on Appraisal {
    id
    appraisal_date
    price_min
    price_max
    price_asking
    price_rent
    interest_level
    expected_close_date
    pipeline_stage {
      name
    }
    appraisal_type {
      text
    }
    price_rent_period {
      text
    }
    price_rent_tax {
      text
    }
    comm_est_based_on_amount
    comm_base_amount_override
    comm_amount_fixed
    comm_amount_model {
      text
    }
    comm_amount_percentage
    comm_est_amount_net_of_tax
    comm_est_amount_inc_tax
    comm_is_inc_tax
    archive_date
    archive_lost_agency {
      text
    }
    archive_reason {
      text
    }
  }
`;

export const Oab = gql`
  fragment Oab on Oab {
    id
    oab_date
    board_type {
      text
    }
    agency {
      text
    }
    price
  }
`;

export const KeyActivity = gql`
  fragment KeyRegisterHistory on KeyRegisterHistory {
    id
    reason
    system_checked_out_time
    system_checked_in_time
  }
`;

export const KeyRegister = gql`
  fragment KeyRegister on KeyRegister {
    id
    system_record_state
    system_ctime
    system_modtime
    label
    description
  }
`;

export const KeyLocation = gql`
  fragment KeyLocation on KeyLocation {
    id
    system_record_state
    system_ctime
    system_modtime
    name
    description
    checkout_enabled
  }
`;

export const Chain = gql`
  fragment Chain on Chain {
    link_count
    chain_is_complete
  }
`;

export const CustomFields = gql`
  fragment CustomFields on CustomFieldValue {
    field {
      id
    }

    value {
      __typename
      ... on CustomFieldValueBoolean {
        boolean: value
      }
      ... on CustomFieldValueNumeric {
        number: value
      }
      ... on CustomFieldValueDate {
        date: value
      }
      ... on CustomFieldValueTime {
        time: value
      }
      ... on CustomFieldValueString {
        string: value
      }
      ... on CustomFieldValueListOfString {
        string_array: value
      }
      ... on CustomFieldValueFile {
        file: value {
          uri
          description
          system_size_mb
        }
      }
      ... on CustomFieldValueRecord {
        record: value {
          record {
            ... on Project {
              id
              name
            }
            ... on Contact {
              id
              contact_name: system_search_key
            }
            ... on Listing {
              id
              property_address: property {
                system_search_key
              }
            }
            ... on ProjectStage {
              id
              name
              project {
                id
              }
            }
            ... on Property {
              id
              system_search_key
            }
          }
        }
      }
      ... on CustomFieldValueListOfRecord {
        record_array: value {
          record {
            ... on Project {
              id
              name
            }
            ... on Contact {
              id
              contact_name: system_search_key
            }
            ... on Listing {
              id
              property_address: property {
                system_search_key
              }
            }
            ... on ProjectStage {
              id
              name
              project {
                id
              }
            }
            ... on Property {
              id
              system_search_key
            }
          }
        }
      }
    }
  }
`;
