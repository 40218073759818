import { useCallback } from 'react';
import { Weekday, Options } from 'rrule';
import { Dayjs } from 'dayjs';
import { EntityModel, Id } from '@rexlabs/model-generator';

import DialogsBridge from 'data/classic-bridges/dialogs-shell';

import { mapFormToApi } from 'utils/calendar';
import { api } from 'shared/utils/api-client';

import listingsModel, { ListingCategory } from 'data/models/entities/listings';
import propertiesModel, {
  PropertyCategory
} from 'data/models/entities/properties';
import {
  CalendarEventData,
  EventLocation,
  RelatedRecord
} from 'data/models/entities/calendar-events';
import { UserItem } from 'data/models/value-lists/account-users';

import adminAppointmentTypesModel, {
  AppointmentTypeData
} from 'features/calendar/data/admin-appointment-types';
import { dateToValue } from 'view/components/input/date-time';
import externalAddressModel from 'data/models/custom/external-address';

interface BaseAppointmentRecordStub {
  id: Id;
}

interface RecurringData {
  interval: string;
  frequency: { id: number };
  show_not_after: boolean;
  repeats_until: Dayjs | null;
  recurs_on_weekday: Weekday;
  recur_on: 'day_of_month' | 'day_of_week' | null;
  recurs_every_interval: Options['interval'];
  recurs_every_weekday?: Weekday | Weekday[];
  recurs_on_month_day?: Options['bymonthday'] | Options['bymonthday'][];
}

interface CalendarSelectObject {
  value: string;
  label: string;
  model: typeof adminAppointmentTypesModel;
  // TODO: For some reason, we're assigning the whole calendar event to the data.
  data: CalendarEventData;
  appointment_type: AppointmentTypeData;
}

type DateFormValue = ReturnType<typeof dateToValue>;

interface FormRecord extends RelatedRecord {
  model: EntityModel<any>;
}

// TODO - on going: Type calendar event data from BE -
//  JIRA - https://rexsoftware.atlassian.net/browse/RADI-5933
//  This type has some overlap with the event data and should help.
interface CalendarFormData {
  id: string;
  title: string;
  description: string;
  private_note: string;
  rule: RecurringData;
  is_recurring: boolean;
  event_status_id: string;
  account: any;
  calendar_id: string;
  original_calendar_id: string;
  appointment_type: CalendarSelectObject | null;
  starts_at: DateFormValue;
  ends_at: DateFormValue;
  start_time_zone: string;
  end_time_zone: string;
  freebusy: 'busy' | 'free';
  event_location: {
    value: string;
    label: string;
    model: typeof externalAddressModel;
    data: EventLocation;
  } | null;
  show_private_note: boolean;
  organiser_user: UserItem;
  records: FormRecord[];
  alerts: any;
  external_guests: any;
  guest_calendars: any;
  organiser_name: string;
  organiser_email: string;
  sync_event_status: string;
  remote_permission_can_update: boolean | null;
  remote_permission_can_delete: boolean | null;

  followup_reminder_action?: string;
  followup_reminder_type_id: string;
  followup_reminder_due_date_type_id: string;
  followup_reminder_custom_due_date?: null;
}

interface AppoinmentListingRecordStub extends BaseAppointmentRecordStub {
  address: string;
  category: ListingCategory;
  image?: string;
  status: string;
  security_user_rights: string[];
  system_owner_user: UserItem;
}

interface AppoinmentPropertyRecordStub extends BaseAppointmentRecordStub {
  address: string;
  category?: PropertyCategory;
  image?: string;
  status: string;
  security_user_rights: string[];
  system_owner_user: UserItem;
}

interface AppointmentContactRecordStub extends BaseAppointmentRecordStub {
  name: string;
  phone_number?: string;
  email_address?: string;
}
export interface AppointmentRecordData {
  id: Id;
  label: string;
  service: string;
  stub:
    | AppoinmentListingRecordStub
    | AppointmentContactRecordStub
    | AppoinmentPropertyRecordStub;
}

export interface EventLocationData {
  data: {
    id: string;
  };
  label: string;
  model: typeof propertiesModel | typeof listingsModel;
  value: Id;
}

export interface AppointmentData {
  event_location?: EventLocationData;
  records?: AppointmentRecordData[];
}

export type CalendarCallbackProp = ({
  event,
  needsRefresh
}: {
  event?: CalendarEventData;
  needsRefresh?: boolean;
}) => any;

export interface DialogProps {
  onCloseCallback?: () => void;
  onLoad?: () => void;
  closeDialog?: () => void;
  handleCancel?: CalendarCallbackProp;
  handleDelete?: CalendarCallbackProp;
  handleReload?: (formData: CalendarFormData) => void;
  handleRestore?: CalendarCallbackProp;
}

export interface UseAddAppointmentArgs {
  data?: AppointmentData;
  callback?: () => void;
  id?: string;
  dialogProps?: DialogProps;
}

export const useEditAppointment = ({
  id,
  data = {},
  callback,
  dialogProps
}: UseAddAppointmentArgs) =>
  useCallback(() => {
    // TODO: DialogBridge needs to be typed - it's a big job and not one I'm prepared to do yet
    // https://app.shortcut.com/rexlabs/story/61580/type-shell-dialogbridge
    const ShellDialogBridge: any = DialogsBridge;

    const endpoint = id ? 'CalendarEvents::update' : 'CalendarEvents::create';

    ShellDialogBridge?.editAppointment?.open({
      id,
      initialValues: data,

      handleSave: async (formData) => {
        const apiData = mapFormToApi(formData);
        const event = await api.post(endpoint, {
          data: apiData
        });

        callback?.();

        return event;
      },
      ...dialogProps
    });
  }, [data, callback]);
