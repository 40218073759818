import React, {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  createContext
} from 'react';

import { Form as RexlabsForm } from '@rexlabs/form';

import ErrorMessage from '../error/message';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

const formStyles = StyleSheet({
  errorList: {
    listStyle: 'none',
    paddingLeft: '14px',
    position: 'relative',

    '> li': {
      position: 'relative'
    },

    '> li:before': {
      content: '" "',
      position: 'absolute',
      top: '7px',
      left: '-10px',
      height: '4px',
      width: '4px',
      borderRadius: '4px',
      background: COLORS.BLACK
    }
  }
});

interface FormProps extends HTMLAttributes<HTMLElement> {
  disableFields?: boolean;
  sendImmediate?: boolean;
  name?: string;
  showErrors?: boolean;
  hasErrorPadding?: boolean;
  hasCompleteErrorPadding?: boolean;
  formErrorStyles?: CSSProperties;
}

interface FormContextType {
  disableFields?: boolean;
  sendImmediate?: boolean;
}

export const FormContext = createContext<FormContextType>({
  disableFields: false,
  sendImmediate: false
});

const Form = ({
  disableFields,
  sendImmediate,
  name,
  showErrors,
  hasErrorPadding,
  hasCompleteErrorPadding,
  formErrorStyles,
  ...rest
}: PropsWithChildren<FormProps>) => {
  const s = useStyles(formStyles);
  return (
    <FormContext.Provider value={{ disableFields, sendImmediate }}>
      <RexlabsForm
        FormError={({ errors, touched }) => {
          const actualErrors = Object.keys(errors)
            .filter((key) => touched && errors[key] && touched[key])
            .map((key) => errors[key]);

          if (!showErrors || !actualErrors.length) {
            return null;
          }

          return (
            <ErrorMessage
              styles={formErrorStyles}
              hasPadding={hasErrorPadding}
              hasCompletePadding={hasCompleteErrorPadding}
            >
              <ul {...s('errorList')}>
                {actualErrors.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </ErrorMessage>
          );
        }}
        id={name}
        name={name}
        autocomplete={'off'}
        {...rest}
      />
    </FormContext.Provider>
  );
};

export default Form;
