import _ from 'lodash';

import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { AxiosResponse } from 'axios';

interface HidePayload {
  service_name: string;
  service_object_id: string | number;
}

export interface AdminTemplateLibrariesModel {
  hide: (payload: HidePayload) => Promise<AxiosResponse<{ result: boolean }>>;
  unhide: (payload: HidePayload) => Promise<AxiosResponse<{ result: boolean }>>;
}

const TYPE = 'adminTemplateLibraries';

const actionCreators = {
  hide: {
    request: (payload: HidePayload) =>
      api.post('AdminTemplateLibraries::hideLibraryTemplate', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  unhide: {
    request: (payload: HidePayload) =>
      api.post('AdminTemplateLibraries::unhideLibraryTemplate', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const adminTemplateLibrariesModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default adminTemplateLibrariesModel;
