import { useMemo } from 'react';
import { useRegion } from 'hooks/use-region';
import { makeEntity } from 'features/custom-reporting/modules/helpers';
import {
  ContactReportQuery,
  contactReportQueryDefaultVariables
} from './contact-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  '',
  'type',
  'emails',
  'phones',
  'address',
  'address_postal',
  'last_contacted_date',
  'latest_match_profile.price_match_max',
  'latest_match_profile.attr_bedrooms_min',
  'system_owner_user.name'
];

export function useContactModule() {
  const region = useRegion();
  const { contacts: contactCustomFields } = useCustomFields();
  const contactModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.contacts,
      moduleLabel: 'Contacts',
      exportPrivilege: 'exporting.contacts',
      queryConfig: {
        graphQlQuery: ContactReportQuery,
        queryVariables: contactReportQueryDefaultVariables,
        defaultSort: [
          {
            direction: 'desc' as OrderByDirectionType,
            name: 'last_contacted_date'
          }
        ]
      },
      columns: [
        {
          headerName: 'Contact',
          children: makeEntity({
            entity: 'contact',
            prefix: '',
            customFields: contactCustomFields,
            region
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'last_90_days',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: 'Contact creation',
              isDefault: true
            },
            {
              fieldName: 'contact.last_contacted_date',
              label: 'Last contacted'
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'system_owner_user_id',
              label: 'Contact Owner'
            }
          ]
        }
      }
    }),
    [region, contactCustomFields]
  );

  return contactModuleConfig;
}
