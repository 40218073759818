import React, { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'view/components/form';
import { PassThroughProps, ReactForms } from '@rexlabs/form';
import SaveCancelOverlayHandler from 'view/components/save-cancel-overlay-handler';
import Box from '@rexlabs/box';
import { useModelActions, useModelState } from '@rexlabs/model-generator';
import adminBuyerMatchModel, {
  AdminBuyerMatchState,
  BuyerMatchSettingsData,
  DEFAULT_CAMPAIGN_SETTINGS
} from 'data/models/entities/admin-buyer-match';
import Spinner from 'shared/components/spinner';
import { useErrorDialog } from 'hooks/use-error-dialog';
import useShouldUpdateChildAccounts from 'features/settings/hooks/use-should-update-child-accounts';
import { AutoArchive } from './auto-archive';
import { AutomaticResidential } from './automatic-residential';
import { AutoMatchCampaign } from './auto-match-campaign';
import { SettingsType } from './types';
import { useRegion } from 'hooks/use-region';

interface AutoMatchProfileSettingsProps {
  type: SettingsType;
}

export function AutoMatchProfileSettings({
  type
}: AutoMatchProfileSettingsProps) {
  const { isUK } = useRegion();
  const errorDialog = useErrorDialog();
  const shouldUpdateChildAccounts = useShouldUpdateChildAccounts();
  const { readSettings, updateSettings } =
    useModelActions(adminBuyerMatchModel);
  const { data: settings, isLoading }: AdminBuyerMatchState =
    useModelState(adminBuyerMatchModel);

  const onSubmit = useCallback(
    async (
      values: BuyerMatchSettingsData & { apply_to_child_accounts: boolean },
      { resetForm }
    ) => {
      if (type === 'res_rental') {
        errorDialog.open({ message: 'No API yet' });
        return;
      }

      const updateChildAccounts = await shouldUpdateChildAccounts();

      if (updateChildAccounts.didCancel) {
        resetForm();
        return;
      }

      if (updateChildAccounts.result) {
        values.apply_to_child_accounts = true;
      }

      await updateSettings(values)
        .then(() => resetForm(values))
        .catch((error) => {
          resetForm();
          errorDialog.open(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateSettings]
  );

  const showSettings = useCallback(
    (
      values?: BuyerMatchSettingsData & { apply_to_child_accounts: boolean }
    ) => {
      const createFrom = [
        `${type}_create_from_calendar_events`,
        `${type}_create_from_leads`
      ];

      if (!isUK) createFrom.push(`${type}_create_from_feedback`);
      if (type === 'res_rental')
        createFrom.push(`${type}_create_from_tenancy_applications`);

      return createFrom.some((key) => values?.[`${type}_create_from`]?.[key]);
    },
    [type, isUK]
  );

  useEffect(() => {
    readSettings({}).catch(errorDialog.open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !settings) {
    return (
      <Box flex={1} justifyContent={'center'} alignItems={'center'} p={20}>
        <Spinner dark />
      </Box>
    );
  }

  return (
    <Box width='100%'>
      <ReactForms
        handleSubmit={onSubmit}
        initialValues={
          type === 'res_sale'
            ? {
                ...settings,
                res_sale_campaign_email:
                  settings.res_sale_campaign_email ?? DEFAULT_CAMPAIGN_SETTINGS,
                res_sale_campaign_sms:
                  settings.res_sale_campaign_sms ?? DEFAULT_CAMPAIGN_SETTINGS
              }
            : {}
        }
      >
        {({
          submitForm,
          isDirty,
          resetForm,
          values,
          setFieldValue
        }: PassThroughProps<
          BuyerMatchSettingsData & { apply_to_child_accounts: boolean },
          unknown
        >) => (
          <Form hasErrorPadding>
            <AutomaticResidential
              showSettings={showSettings}
              values={values}
              type={type}
              setFieldValue={setFieldValue}
            />
            {showSettings(values) && (
              <AutoMatchCampaign values={values} type={type} />
            )}
            <AutoArchive />
            <SaveCancelOverlayHandler
              isVisible={isDirty}
              onSave={submitForm}
              onCancel={resetForm}
            />
          </Form>
        )}
      </ReactForms>
    </Box>
  );
}
