import { getCLS, getFID, getLCP, getFCP } from 'web-vitals';
import { get, noop } from 'lodash';

import config from '../config';
import LocalStorage from '../local-storage';

let accountInfo;

export function webVitals({ applicationName, webVitalsHandler = noop }) {
  function newRelicHandler({ name, data }) {
    if (config.ENABLE_NEW_RELIC && get(window, 'newrelic.addPageAction')) {
      if (!accountInfo) {
        accountInfo = LocalStorage.get('account_info');
      }

      const payload = {
        ...data,
        applicationName,
        officeId: get(accountInfo, 'office_details.id'),
        officeName: get(accountInfo, 'office_details.name'),
        userId: get(accountInfo, 'user_details.id'),
        userEmail: get(accountInfo, 'user_details.email'),
        prettyUrl: window.location.href.replace(/id=([0-9]+)/g, 'id=:id'),
        networkType: get(window, 'navigator.connection.effectiveType') || null,
        releaseVersion: get(config, 'RELEASE.VERSION') || null,
        releaseStage: get(config, 'RELEASE.STAGE') || null,
        type: name
      };

      window.newrelic.addPageAction('fe_web_vitals', payload);
    }
  }

  function handleData({ entries, name, ...data }) {
    newRelicHandler({ name, data });
    webVitalsHandler({ name, data });
  }

  getCLS(handleData);
  getFID(handleData);
  getLCP(handleData);
  getFCP(handleData);
}
