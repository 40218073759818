import React from 'react';

import Box, { BoxProps } from '@rexlabs/box';

import PaddingBox from 'view/components/padding-box';

interface LeadsDialogLeftHandPaneContainerProps extends BoxProps {
  header: React.ReactNode;
  footer: React.ReactNode;
}

export function LeadsDialogLeftHandPaneContainer({
  header,
  footer
}: LeadsDialogLeftHandPaneContainerProps) {
  return (
    // Note: Even though we set padding to 0, we still want to use padding box to get the 'light' color prop
    <PaddingBox p={0} flexDirection='column' light height='100%' width={340}>
      <Box height='100%' flexDirection='column'>
        {header}
      </Box>
      <Box height='100%' flexDirection='column' justifyContent='flex-end'>
        {footer}
      </Box>
    </PaddingBox>
  );
}
