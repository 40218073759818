/* eslint-disable prefer-spread */

// TODO: clean up

import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import Analytics from 'shared/utils/vivid-analytics';

export const createValSetterActionCreator = (prop) => ({
  reduce: (state, { payload }) => {
    // Short-circuit reducer to avoid new obj refs
    if (state[prop] === payload) return state;
    return { ...state, [prop]: payload };
  }
});

const createLoadingIndicatorStateAction = (type) => ({
  reduce: (state, { payload = {} }) => {
    // NOTE: This If statement is currently never true as this is being done in classic.
    // This is a workaround to avoid unnecessary re-renders because of our faulty/buggy redux connectors
    // Once below story is fixed, it should work.
    // https://app.clubhouse.io/rexlabs/story/58294/spike-investigate-why-adding-to-redux-is-expensive-from-classic
    /* If we've been asked for an off state and state is already off, let's leave as is. */
    if (type === 'OFF' && state.loadingIndicator === 'OFF') {
      return state;
    }

    const newState = {
      ...state,
      loadingIndicator: type
    };

    if (payload.message) {
      payload.message = payload.message.toLowerCase();
      newState.loadingIndicatorMessage = payload.message;
    }

    if (
      type === 'ON' &&
      state.hasHiddenFirstLoadingIndicator === true &&
      payload?.message === 'navigating'
    ) {
      newState.isNavigationTriggered = true;
      Analytics.performance({
        task: 'Navigation',
        event: 'loadingAnimationShow'
      });
    }

    if (type === 'OFF') {
      if (state.hasHiddenFirstLoadingIndicator === false) {
        newState.hasHiddenFirstLoadingIndicator = true;
        Analytics.performance({
          task: 'Shell-FullInit',
          event: 'loadingAnimationHide',
          hasCompletedTask: true
        });
      } else if (state.isNavigationTriggered) {
        Analytics.performance({
          task: 'Navigation',
          event: 'loadingAnimationHide',
          hasCompletedTask: true
        });
        newState.isNavigationTriggered = false;
      }
    }

    return _.isEqual(state, newState) ? state : newState;
  }
});

export const COMPONENTS = {
  MENU: 'menu',
  HEADER: 'header'
};

/*
|-------------------------------------------------------------------------------
| Models
*/

const initialState = {
  isOverlayActive: false,
  isDialogActive: false,
  isClassicDialogActive: false,
  isNavigationTriggered: false,
  isHeaderHidden: false,
  isOverlayCloseShowing: false,
  loadingIndicator: 'ON', // NOTE: We want to show it on app-load
  loadingIndicatorMessage: 'loading app',
  hasHiddenFirstLoadingIndicator: false,
  saveCancelOverlayState: 'OFF',
  disabled: [], // Disable parts of Shell
  showLagRadar: false
};

const actionCreators = {
  toggleLagRadar: {
    reduce: (state) => ({ ...state, showLagRadar: !state.showLagRadar })
  },
  reloadRexFrame: {
    request: (payload = {}, actions) => {
      const { forceUrl } = payload;
      if (window.Rex2FrameWindow) {
        actions.loadingIndicatorOn({ message: 'navigating' });
        const prevOnLoad = window.Rex2IFrame.onload;
        return Promise.resolve()
          .then(
            () =>
              new Promise((resolve) => {
                window.Rex2IFrame.onload = resolve;
                if (forceUrl) {
                  // Hack: We allow a "forceUrl" to work around an issue in
                  //       whereabouts that makes it difficult to deterministically
                  //       signal a 'force' reload in route (PUSH) changes.
                  window.Rex2FrameWindow.location = forceUrl;
                } else {
                  window.Rex2FrameWindow.location.reload();
                }
              })
          )
          .then(() => {
            if (prevOnLoad) {
              prevOnLoad();
              window.Rex2IFrame.onload = prevOnLoad;
            }
          })
          .then(actions.loadingIndicatorOff);
      } else {
        return Promise.resolve();
      }
    },
    reduce: {
      initial: (state) => state,
      success: (state) => state,
      failure: (state) => state
    }
  },

  updateClassicDialogActive: createValSetterActionCreator(
    'isClassicDialogActive'
  ),
  updateDialogActive: createValSetterActionCreator('isDialogActive'),
  updateOverlayActive: createValSetterActionCreator('isOverlayActive'),
  updateHeaderHidden: createValSetterActionCreator('isHeaderHidden'),
  updateOverlayCloseShowing: createValSetterActionCreator(
    'isOverlayCloseShowing'
  ),
  updateQuickNav: {
    reduce: (state, action) => {
      return _.flow(
        _.partial(
          createValSetterActionCreator('isOverlayActive').reduce,
          _,
          action
        ),
        _.partial(
          createValSetterActionCreator('isHeaderHidden').reduce,
          _,
          action
        )
      )(state, action);
    }
  },

  loadingIndicatorOff: createLoadingIndicatorStateAction('OFF'),
  loadingIndicatorOn: createLoadingIndicatorStateAction('ON'),

  updateSaveCancelOverlayState: createValSetterActionCreator(
    'saveCancelOverlayState'
  ),
  updateSaveCancelOverlaySpinner: {
    reduce: (state, { payload }) => {
      return {
        ...state,
        saveCancelOverlayState:
          state.saveCancelOverlayState === 'OFF'
            ? 'OFF'
            : payload
            ? 'IN_PROGRESS'
            : 'ON'
      };
    }
  },

  hideComponents: {
    reduce: (state, { payload }) => {
      const newComponents = payload.filter(
        (component) => !state.disabled.includes(component)
      );
      // Append component name to HTML class names so we can style depending on what is hidden
      // So we can adjust some existing styles, like overlay padding.
      const topClassicHtmlElement =
        window.Rex2FrameWindow.document.firstElementChild;
      topClassicHtmlElement.classList.add.apply(
        topClassicHtmlElement.classList,
        newComponents.map((componentName) => `without-shell-${componentName}`)
      );

      return {
        ...state,
        disabled: [...state.disabled, ...newComponents]
      };
    }
  },
  showComponents: {
    reduce: (state, { payload }) => {
      // Remove extra class names as we are showing the components again
      const topClassicHtmlElement =
        window.Rex2FrameWindow.document.firstElementChild;
      topClassicHtmlElement.classList.remove.apply(
        topClassicHtmlElement.classList,
        payload.map((componentName) => `without-shell-${componentName}`)
      );

      return {
        ...state,
        disabled: state.disabled.filter(
          (component) => !payload.includes(component)
        )
      };
    }
  }
};

const selectors = {
  isOverlayActive: (state) => _.get(state, 'ui.isOverlayActive'),
  isDialogActive: (state) => _.get(state, 'ui.isDialogActive'),
  isClassicDialogActive: (state) => _.get(state, 'ui.isClassicDialogActive'),
  isOverlayCloseShowing: (state) => _.get(state, 'ui.isOverlayCloseShowing'),

  loadingIndicator: (state) => _.get(state, 'ui.loadingIndicator'),
  loadingIndicatorMessage: (state) =>
    _.get(state, 'ui.loadingIndicatorMessage'),
  saveCancelOverlayState: (state) => _.get(state, 'ui.saveCancelOverlayState'),

  disabled: (state) => _.get(state, 'ui.disabled'),
  showLagRadar: (state) => _.get(state, 'ui.showLagRadar')
};

// TODO: Type this model
// https://app.shortcut.com/rexlabs/story/64595/type-ui-model
export default new Generator('ui').createModel({
  initialState,
  actionCreators,
  selectors
});
