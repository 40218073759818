import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';
import VividFileUploadInput from '@rexlabs/file-upload-input';
import Box from '@rexlabs/box';
import { Tag } from 'components/text/tag';
import uploadsModel from 'data/models/entities/uploads';
import { DefaultButton } from 'view/components/button';
import { InputTag } from 'view/components/tag';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { COLORS, PADDINGS, BORDER_RADIUS, UTILS } from 'src/theme';
import RemoveIcon from 'assets/icons/remove.svg';
import DocumentIcon from 'assets/icons/document.svg';
import _ from 'lodash';
import { withPopoutContext } from 'view/context/popout';

const defaultStyles = StyleSheet({
  wrapContainer: {},

  container: {
    height: '34px',
    border: `2px solid ${COLORS.STATES.IDLE}`
  },
  noRightBorder: {
    borderRight: 'none'
  },
  input: {
    display: 'none'
  },
  button: {
    width: '85px',
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important'
  },
  removeIcon: {
    fill: COLORS.PRIMARY.RED,
    cursor: 'pointer'
  },
  inputTag: {
    fontSize: 13,
    fontWeight: 600,
    display: 'inline-block',
    maxWidth: 400,
    marginTop: 3,
    ...UTILS.truncate
  }
});

const progressStyles = StyleSheet({
  container: {
    position: 'relative'
  },

  bar: {
    transformOrigin: 'left center',
    background: COLORS.PRIMARY.BLUE
  },

  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  indicator: {
    height: '19px',
    borderRadius: BORDER_RADIUS.BUTTONS,
    background: COLORS.PRIMARY.GREY_LIGHTER
  }
});

function ProgressCore({ styles: s, progress }) {
  return (
    <Box {...s('container')} width='100%' height='100%'>
      <Box
        {...s('bar')}
        style={{ transform: `scaleX(${progress})` }}
        width='100%'
        height='100%'
      />
      <Box {...s('progress')}>
        <Box
          {...s('indicator')}
          alignItems='center'
          pl={PADDINGS['3XS']}
          pr={PADDINGS['3XS']}
        >
          <Tag light>{`${progress * 100}%`}</Tag>
        </Box>
      </Box>
    </Box>
  );
}

const Progress = styled(progressStyles)(ProgressCore);

const inputContainerStyles = {
  InputContainer: StyleSheet({
    container: {
      // HACK: to increase specificity of selector
      '&&': {
        display: 'none'
      }
    }
  })
};

@withPopoutContext
@withErrorDialog
@withModel(uploadsModel)
@styled(defaultStyles)
@autobind
class FileUploadInput extends Component {
  state = {
    isUploading: false,
    uploadComplete: false,
    progress: 0
  };

  handleUpload(file) {
    const {
      uploads: { uploadFile }
    } = this.props;
    this.setState({ isUploading: true });
    const formData = new FormData();
    formData.append('file', file);
    return uploadFile({ formData, onUploadProgress: this.handleProgress });
  }

  handleProgress(progressEvent) {
    const progress = Math.round(progressEvent.loaded / progressEvent.total);
    this.setState({ progress });
  }

  handleChange(e) {
    const { isUploading } = this.state;
    const { errorDialog, onChange } = this.props;
    if (e.target.files.length === 1 && !isUploading) {
      this.handleUpload(e.target.files[0])
        .then((res) => {
          const fakeEvent = {
            ...e,
            target: { ...e.target, files: res.data.result }
          };
          onChange(fakeEvent);
          this.setState({ isUploading: false, uploadComplete: true });
        })
        .catch((e) => {
          this.handleReset();
          errorDialog.open(e);
        });
    }
  }

  handleEdit(changes) {
    const { value, onChange } = this.props;
    const fakeEvent = {
      target: { files: { ...value, ...changes } }
    };
    onChange(fakeEvent);
  }

  handleReset() {
    const { name, onChange } = this.props;
    onChange({
      persist: _.noop,
      target: {
        type: 'file',
        name,
        id: name,
        files: {}
      }
    });
    this.setState({ isUploading: false, uploadComplete: false, progress: 0 });
  }

  render() {
    const { isUploading, progress } = this.state;
    const {
      styles: s,

      ActionMenu,

      value,
      ...rest
    } = this.props;

    return (
      <VividFileUploadInput {...rest} onChange={this.handleChange}>
        {(input) => {
          const {
            files,
            inputProps,
            dragEvents,
            InputContainer,
            triggerInput,
            removeFiles
          } = input;

          const reset = () => {
            removeFiles();
            this.handleReset();
          };

          const selectedFiles = files.length
            ? files
            : value
            ? _.isArray(value)
              ? value
              : [value]
            : [];

          // File may be an object from the api or a file
          const filteredFiles = selectedFiles.filter(
            (file) => file?.name || file?.uri || file?.url
          );

          return (
            <Box flex={1} flexDirection='column'>
              <Box
                {...s('container', {
                  noRightBorder: filteredFiles.length <= 0
                })}
                flex={1}
                alignItems='center'
                justifyContent='space-between'
              >
                {filteredFiles.length <= 0 && (
                  <Box flex={1} pl={PADDINGS.XS} {...s('noFileLabel')}>
                    <Tag>No file chosen</Tag>
                  </Box>
                )}

                {isUploading && <Progress progress={progress} />}

                {filteredFiles.length > 0 && !isUploading && (
                  <InputTag
                    style={{ margin: PADDINGS['3XS'], height: '22px' }}
                    label={
                      <span {...s('inputTag')}>
                        {(value && value.description) || filteredFiles[0].name}
                      </span>
                    }
                    Icon={DocumentIcon}
                    onClose={reset}
                  />
                )}

                <StylesProvider
                  components={inputContainerStyles}
                  prioritiseParentStyles={false}
                >
                  <InputContainer {...inputProps} {...dragEvents} />
                </StylesProvider>
              </Box>

              {filteredFiles.length <= 0 && (
                <DefaultButton
                  {...s('button')}
                  styles={{ content: { color: COLORS.WHITE } }}
                  light
                  type='button'
                  onClick={(e) => {
                    triggerInput();
                    // prevent switch to view state in classic - so we can see upload bar.
                    e.stopPropagation();
                  }}
                >
                  Browse...
                </DefaultButton>
              )}

              {filteredFiles.length > 0 && isUploading && (
                <Box alignItems='center' justifyContent='center'>
                  <RemoveIcon {...s('removeIcon')} onClick={reset} />
                </Box>
              )}

              {filteredFiles.length > 0 && !isUploading && ActionMenu && (
                <Box ml={PADDINGS['3XS']}>
                  <ActionMenu
                    target={this.props.context}
                    selected={filteredFiles}
                    value={value}
                    files={files}
                    reset={reset}
                    handleChange={this.handleChange}
                    handleEdit={this.handleEdit}
                  />
                </Box>
              )}
            </Box>
          );
        }}
      </VividFileUploadInput>
    );
  }
}

export default FileUploadInput;
