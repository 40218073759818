import React from 'react';

import { TextInput } from '@rexlabs/text-input';

interface SavedListSearchBarProps {
  placeholder?: string;
  handleSearch: (args?: any) => any;
  searchValue: string;
}

export function SavedListSearchBar(props: SavedListSearchBarProps) {
  const { placeholder, handleSearch, searchValue } = props;

  return (
    <>
      {/* Really dodgy types from @rexlabs/text-input */}
      {/* @ts-ignore */}
      <TextInput
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => handleSearch(e.target?.value)}
      />
    </>
  );
}
