import { touchEtag } from '@rexlabs/model-generator';

/**
 * NOTE: This helper function takes the response you get back from succesfull request, and
 * replaces the record in the model state, in both lists and item. Just pass in the model state
 * add the action in the reduce object, and it will do the rest.
 */
export function reduceItemResponseToState(state, { payload }) {
  const data = payload?.data?.result || payload;
  const id = data?.id;

  if (!id) {
    return state;
  }

  return {
    ...state,
    items: {
      ...state.items,
      [id]: touchEtag({
        ...state.items[id],
        status: 'loaded',
        data: {
          ...state.items[id].data,
          ...data
        }
      })
    }
  };
}
