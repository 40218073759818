import React, { PureComponent, createRef } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import { Select } from './select';
import { PADDINGS, COLORS } from 'src/theme';
import RemoveIcon from 'assets/icons/remove.svg';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  protocol: {
    width: '100px'
  },

  removeIcon: {
    fill: COLORS.PRIMARY.RED,
    cursor: 'pointer'
  }
});

const textInputOverrides = {
  TextInput: StyleSheet({
    wrapper: {
      flex: 1
    }
  })
};

const selectOptions = [
  { value: 'http://', label: 'http://' },
  { value: 'https://', label: 'https://' },
  { value: 'mailto:', label: 'mailto:' },
  { value: 'custom', label: 'Custom protocol' }
];

@styled(defaultStyles)
@autobind
class UrlInput extends PureComponent {
  state = {
    customProtocolValue: null,
    protocolValue: 'https://',
    urlValue: '',
    alreadySplit: false
  };

  customProtocol = createRef();

  componentDidMount() {
    this.setState(this.splitUrl(this.props.value));
  }

  splitUrl(value) {
    const { protocolValue, alreadySplit } = this.state;
    const splitVal = (value || '').split(/:(.+)/);
    if (splitVal.length > 1 && !alreadySplit) {
      let newProtocolValue = `${splitVal[0]}:`;
      let newUrlValue = splitVal[1];
      if (splitVal[1].substr(0, 2) === '//') {
        newProtocolValue = `${newProtocolValue}//`;
        newUrlValue = newUrlValue.slice(2);
      }
      const isInProtocolList = selectOptions
        .map((option) => option.value)
        .includes(newProtocolValue);
      return {
        customProtocolValue: !isInProtocolList ? newProtocolValue : null,
        protocolValue: isInProtocolList ? newProtocolValue : protocolValue,
        urlValue: newUrlValue,
        alreadySplit: true
      };
    }
    return null;
  }

  handleChange() {
    const { customProtocolValue, protocolValue, urlValue } = this.state;
    const { onChange } = this.props;
    onChange &&
      onChange({
        persist: _.noop,
        target: {
          name,
          id: name,
          type: 'text',
          value: `${
            customProtocolValue !== null ? customProtocolValue : protocolValue
          }${urlValue}`
        }
      });
  }

  handleCustomProtocolChange(e) {
    this.setState({ customProtocolValue: e.target.value || null });
  }

  handleProtocolChange(e) {
    const value = e.target.value;
    if (value === 'custom') {
      this.setState({ customProtocolValue: '' }, () => {
        setTimeout(() => {
          this.customProtocol.current.focus();
        });
      });
    } else {
      this.setState({ protocolValue: e.target.value }, this.handleUpdateValue);
    }
  }

  handleUrlChange(e) {
    this.setState({ urlValue: e.target.value, alreadySplit: false });
  }

  handleUpdateValue() {
    this.setState(this.splitUrl(this.state.urlValue), this.handleChange);
  }

  handleProtocolBlur(e) {
    if (!e.target.value) {
      this.setState({ customProtocolValue: null });
    }
    this.handleUpdateValue(e);
  }

  renderSuffix() {
    const { styles: s } = this.props;
    return (
      <RemoveIcon
        {...s('removeIcon')}
        onClick={() => {
          this.setState({ customProtocolValue: null }, this.handleUpdateValue);
        }}
      />
    );
  }

  render() {
    const { customProtocolValue, protocolValue, urlValue } = this.state;
    const {
      styles: s,
      name,
      meta,
      urlInputMargin,
      customProtocolProps,
      protocolProps,
      urlInputProps
    } = this.props;

    return (
      <Box alignItems='center'>
        <Box {...s('protocol')} alignItems='center'>
          {customProtocolValue != null ? (
            <TextInput
              {...customProtocolProps}
              ref={this.customProtocol}
              meta={meta}
              name={`${name}_custom_protocol`}
              value={customProtocolValue}
              // suffix={this.renderSuffix()}
              onChange={this.handleCustomProtocolChange}
              onBlur={this.handleProtocolBlur}
            />
          ) : (
            <Select
              {...protocolProps}
              meta={meta}
              name={`${name}_protocol`}
              options={selectOptions}
              value={protocolValue}
              isSearchable={false}
              onChange={this.handleProtocolChange}
            />
          )}
        </Box>
        <Box margin={urlInputMargin} flex={1} ml={PADDINGS['3XS']}>
          <StylesProvider
            components={textInputOverrides}
            prioritiseParentStyles={false}
          >
            <TextInput
              {...urlInputProps}
              meta={meta}
              name={`${name}_url`}
              value={urlValue}
              onChange={this.handleUrlChange}
              onBlur={this.handleUpdateValue}
            />
          </StylesProvider>
        </Box>
      </Box>
    );
  }
}

export default UrlInput;
