import { useMemo } from 'react';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';
import invariant from 'invariant';

/**
 * Hook that allows to handle a classic dialog in the shell
 * DialogOverlayStack (via iframe), using the dialogName as identifier.
 *
 * @deprecated use useDialog instead.
 */
export function useClassicDialog(dialogName) {
  // Make sure the dialogName is valid!
  invariant(
    DialogsBridge[dialogName],
    `Couldn't find any meta data for the dialog with the callName "${dialogName}"! ` +
      'Make sure you add proper data mapping to "shell/src/utils/dialogs/mapping-classic.js".'
  );

  return useMemo(
    () => ({
      open: (...args) => {
        return DialogsBridge[dialogName].open(...args);
      },
      close: (...args) => {
        return DialogsBridge[dialogName].close(...args);
      }
    }),
    [dialogName]
  );
}
