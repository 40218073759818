import { Generator } from 'shared/utils/models';

export type SendViaId = 'sms_and_email' | 'email' | 'sms' | 'dont_send';

export interface AppointConfirmationSendViaValue {
  id: SendViaId;
  text: string;
}
const TYPE = 'appointmentConfirmationSendVia';
export default new Generator(TYPE).createSystemListModel();
