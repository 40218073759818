/*
|-------------------------------------------------------------------------------
| We setup a singleton dispatcher, much like redux, but as a separate object
| because we mainly want to be passing around non-serializable data (functions).
*/
import { Emitter } from 'utils/events';

export const popoutEmitter = new Emitter();
export const POPOUT_ACTIONS = {
  CLOSE_ALL: 'popouts/closeAll'
};
