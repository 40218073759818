import { autobind } from 'core-decorators';

import LocalStorage from 'shared/utils/local-storage';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import _ from 'lodash';

const FEATURE_FLAG_STORAGE_KEY = 'featureFlags';

// NOTE: If a flag does not specify a name key then it will not appear in the Experimental page on the users profile: /user/profile/#page=experiments
export const FLAG_CONFIG = [
  {
    name: 'Upgraded Navigation Menu',
    storageKey: 'newNav',
    urlToggle: 'toggleNewNav',
    description: 'Enables the new redesigned Rex navigation menu and header.'
  },
  {
    storageKey: 'customValidations',
    urlToggle: 'toggleCustomValidations'
  },
  {
    storageKey: 'workflowWidget',
    urlToggle: 'toggleWorkflowWidget'
  },
  {
    storageKey: 'workflowMenu',
    urlToggle: 'toggleWorkflowMenu'
  },
  {
    storageKey: 'adminWorkflows',
    urlToggle: 'toggleAdminWorkflows'
  },
  {
    name: 'Upgraded ID Verification',
    storageKey: 'newIDVerification',
    urlToggle: 'toggleNewIDVerification',
    description:
      'Enables the new redesigned ID verification dialog and admin area.'
  }
];

@autobind
class FeatureFlags {
  static get allStoredFlags() {
    return LocalStorage.get(FEATURE_FLAG_STORAGE_KEY, {});
  }

  static get forceNewMenu() {
    const accountInfo = LocalStorage.get('account_info');
    // TODO: move `withRegion` helper into shared
    const isEU = ['uk', 'fr'].includes(
      _.get(accountInfo, 'office_details.locale.code')
    );

    if (isEU) {
      return true;
    }
    return false;
  }

  get userFlags() {
    return FeatureFlags.allStoredFlags[this.userSelector];
  }

  constructor(props) {
    this.userSelector = `user_${(props && props.userId) || 0}`;
    this.checkFeatureFlags();
  }

  checkFeatureFlags(hashString = window.parent.location.hash) {
    window.parent.featureFlags = window.parent.featureFlags || {};

    let hashWithoutFlags = hashString;

    FLAG_CONFIG.forEach((flag) => {
      let hasFlagSet = this.userHasFlag(flag.storageKey);
      const isKeyInUrl =
        hashString &&
        hashString.match(new RegExp(`${flag.urlToggle}(?!=false)`));

      if (isKeyInUrl) {
        if (hasFlagSet) {
          this.unsetUsersFlag(flag.storageKey);
          hasFlagSet = false;
        } else {
          this.setUsersFlag(flag.storageKey);
          hasFlagSet = true;
        }
      }

      // Remove the toggle from the URL so the user doesn't re-toggle the feature on refresh of the page
      hashWithoutFlags = hashWithoutFlags
        .split('&')
        .filter((currentFlag) => currentFlag !== flag.urlToggle)
        .join('&');

      if (flag.storageKey === 'newNav') {
        if (FeatureFlags.forceNewMenu) {
          // Force new menu for Spicer Haart accounts!
          hasFlagSet = true;
        }
      }

      window.parent.featureFlags[flag.storageKey] = hasFlagSet;
    });

    if (hashString) {
      window.parent.location.hash = hashWithoutFlags;
    }
  }

  userHasFlag(flagName) {
    const allFlags = FeatureFlags.allStoredFlags;

    if (!this.userFlags) {
      const initialiseUserFlags = {
        ...allFlags,
        [this.userSelector]: []
      };

      LocalStorage.set(FEATURE_FLAG_STORAGE_KEY, initialiseUserFlags, true);
    }

    return this.userFlags && this.userFlags.indexOf(flagName) > -1;
  }

  /**
   * If the flag isn't found we extend allFlags in local storage with the current users array
   * of enabled flags.
   * @param flagName
   */
  setUsersFlag(flagName) {
    const allFlags = FeatureFlags.allStoredFlags;

    if (this.userFlags.indexOf(flagName) === -1) {
      const withNewFlag = {
        ...allFlags,
        [this.userSelector]: [...this.userFlags, flagName]
      };
      LocalStorage.set(FEATURE_FLAG_STORAGE_KEY, withNewFlag, true);
      Analytics.track({
        event: EVENTS.FEATURE_FLAGS.ENABLED,
        properties: { Flag: flagName }
      });
    }
  }

  unsetUsersFlag(flagName) {
    const allFlags = FeatureFlags.allStoredFlags;
    const userFlags = allFlags[this.userSelector] || [];

    const flagIndex = userFlags.indexOf(flagName);
    if (flagIndex !== -1) {
      userFlags.splice(flagIndex, 1);
      LocalStorage.set(FEATURE_FLAG_STORAGE_KEY, allFlags, true);
      Analytics.track({
        event: EVENTS.FEATURE_FLAGS.DISABLED,
        properties: { Flag: flagName }
      });
    }
  }
}

export default FeatureFlags;
