import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';

import { APP_MENU } from 'theme/legacy/components';
import Option from './option';
import { getHazardly } from 'shared/utils/prefix-hell';
import Icon, { ICONS } from 'shared/components/icon';
import {
  hasPermission,
  isOwner
} from 'view/components/navigation/app-search/utils';

@styled(APP_MENU.RECORDS)
class MarketLeadOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles: s, option, isActive } = this.props;
    const { data: marketLead, value, label } = option;

    const userSecurityRights = getHazardly(marketLead, 'security_user_rights');
    const systemOwnerUser = getHazardly(marketLead, 'system_owner_user');
    const assigneeName = getHazardly(marketLead, 'assignee.name');
    const myMarketLead = isOwner(systemOwnerUser);
    const MarketLeadIcon = <Icon key='listing-icon' type={ICONS.MARKET_LEAD} />;

    return (
      <Option
        isActive={isActive}
        label={label}
        IconBox={[
          myMarketLead && (
            <Icon
              key='owner-flag'
              {...s('ownerIcon')}
              type={ICONS.APP_SEARCH.OWNER}
            />
          ),
          !hasPermission(userSecurityRights, 'read') && (
            <Icon
              key='permission-flag'
              {...s('permissionIcon')}
              type={ICONS.APP_SEARCH.LOCK}
            />
          ),
          MarketLeadIcon
        ]}
        OptionMeta={[
          <span key='label' {...s('name', 'ellipsis')}>
            {label}
          </span>,
          assigneeName && (
            <span key='date' {...s('meta', 'ellipsis')}>
              Assigned to: {assigneeName}
            </span>
          )
        ]}
        OptionExtraMeta={[
          <span key='value' {...s('meta', 'extra', 'light')}>
            #{value}
          </span>
        ]}
      />
    );
  }
}

export default MarketLeadOption;
