import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Hint } from 'components/text/hint';

import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import List, {
  RexHeadingCell,
  RexBasicCell,
  RexPrimaryTextCell
} from 'view/components/list';
import { COLORS } from 'theme';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  emptyContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    minHeight: '50px',
    width: '100%'
  },

  viewLink: {
    color: COLORS.PRIMARY.SAND_LIGHT,
    cursor: 'pointer',
    fontWeight: 600
  }
});

const listStyles = StyleSheet({
  separator: {
    display: 'none'
  },

  wrapItem: {
    '&:nth-child(odd)': {
      backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
    }
  }
});

@styled(defaultStyles)
@autobind
class MetricUserAttributions extends PureComponent {
  renderEmptyState() {
    const { styles: s, isLoading } = this.props;
    return (
      <Box
        {...s('emptyContainer')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {isLoading ? (
          <Spinner small dark />
        ) : (
          <Hint semibold>No user attributions found.</Hint>
        )}
      </Box>
    );
  }

  renderHeader() {
    return (
      <Box flexDirection='row' width='100%'>
        <RexHeadingCell width='50%'>name</RexHeadingCell>
        <RexHeadingCell flex={1}>percentage</RexHeadingCell>
      </Box>
    );
  }

  renderItem(item, index) {
    const { onEdit, onDelete } = this.props;

    return (
      <Box width='100%' flexDirection='row'>
        {onEdit ? (
          <RexPrimaryTextCell
            width='50%'
            actionMenuItems={[
              {
                label: 'Edit',
                onClick: () => onEdit(item, index)
              },
              {
                label: 'Delete',
                onClick: () => onDelete(item, index)
              }
            ]}
          >
            {_.get(item, 'user.name')}
          </RexPrimaryTextCell>
        ) : (
          <RexBasicCell width='50%'>{_.get(item, 'user.name')}</RexBasicCell>
        )}
        <RexBasicCell flex={1}>{_.get(item, 'percentage')}%</RexBasicCell>
      </Box>
    );
  }

  render() {
    return (
      <List
        styles={listStyles}
        Header={this.renderHeader}
        EmptyView={this.renderEmptyState}
        items={this.props.items}
        renderItem={this.renderItem}
      />
    );
  }
}

export default MetricUserAttributions;
