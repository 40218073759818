export function htmlToNewlines(html: string) {
  const parser = new DOMParser();

  const doc = parser.parseFromString(html, 'text/html');

  // // Replace each <p> element with its content followed by double newlines
  // doc.querySelectorAll('p').forEach((p) => {
  //   const replacementText = document.createTextNode(p.textContent + '\n');
  //   p.parentNode?.replaceChild(replacementText, p);
  // });

  doc.querySelectorAll('br').forEach((br) => {
    const replacementText = document.createTextNode('\n');
    br.parentNode?.replaceChild(replacementText, br);
  });

  return doc.body.textContent?.trim() || '';
}

export function newlinesToHtml(text: string) {
  return text
    .split('\n\n')
    .map((line) => `<p>${line}</p>`)
    .join('')
    .replaceAll('\n', '<br />');
}
