import React from 'react';
import { compose } from 'redux';

import { useAddReminder } from 'hooks/use-add-reminder';

import { ICONS } from 'shared/components/icon';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import { RecordPreviewActionBarButton } from '../record-preview-action-bar';

import { EditReminderData } from 'utils/dialogs/mapping-classic-interfaces';

const trackAddReminder = () => {
  Analytics.track({
    event: EVENTS.PREVIEW_POPOUTS.ADD_REMINDER
  });
};
interface RecordPreviewAddNoteActionProps {
  reminderData: EditReminderData;
}

function RecordPreviewAddReminderActionComponent({
  reminderData,
  errorDialog
}: RecordPreviewAddNoteActionProps & {
  errorDialog: ErrorDialog;
}) {
  const onAddReminderClick = useAddReminder({
    reminderData,
    callback: trackAddReminder,
    errorDialog
  });
  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.REMINDER}
      onClick={onAddReminderClick}
    >
      Reminder
    </RecordPreviewActionBarButton>
  );
}

export const RecordPreviewAddReminderAction = compose<
  React.ComponentType<RecordPreviewAddNoteActionProps>
>(withErrorDialog)(RecordPreviewAddReminderActionComponent);
