import React, { Component } from 'react';
import { query, withQuery } from '@rexlabs/model-generator';
import campaignsModel from 'data/models/entities/campaigns';
import _ from 'lodash';

import CampaignTooltipCore from './core';

const campaignQuery = query`{
  ${campaignsModel} (id: ${(p) => p.id}) {
    id
    system_ctime
    name
    type
    status
    start_date
    end_date
  }
}`;

@withQuery(campaignQuery)
class CampaignTooltip extends Component {
  render() {
    const { campaigns } = this.props;
    const isLoading = _.get(campaigns, 'item.status') === 'loading';

    const itemData = _.get(campaigns, 'item.data');

    const data = {
      ...(itemData || {})
    };

    return (
      <CampaignTooltipCore
        {...this.props}
        campaign={itemData}
        data={data}
        isLoading={isLoading}
      />
    );
  }
}

export default CampaignTooltip;
