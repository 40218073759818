import React, { useMemo } from 'react';

import Box from '@rexlabs/box';

import { COLORS, PADDINGS } from 'theme';

import Tag from 'view/components/tag';

import { RecordPreviewBlackTag } from 'components/record-preview/record-preview-black-tag';

export function ContactPreviewPopoutHotTag() {
  return (
    <Tag
      roundedEdges
      backgroundColor={COLORS.PRIMARY.RED}
      color={COLORS.PRIMARY.WHITE}
    >
      Hot
    </Tag>
  );
}

export function ContactPreviewPopoutWarmTag() {
  return (
    <Tag
      roundedEdges
      backgroundColor={COLORS.PRIMARY.YELLOW}
      color={COLORS.PRIMARY.WHITE}
    >
      Warm
    </Tag>
  );
}

export function ContactPreviewPopoutColdTag() {
  return (
    <Tag
      roundedEdges
      backgroundColor={COLORS.PRIMARY.REX_BLUE}
      color={COLORS.PRIMARY.WHITE}
    >
      Cold
    </Tag>
  );
}

function ContactPreviewPopoutNeutralTag() {
  return <RecordPreviewBlackTag>Neutral</RecordPreviewBlackTag>;
}

export function ContactPreviewPopoutHeatIndication({
  tagType
}: {
  tagType: string;
}) {
  const HeatIndicationTag = useMemo(() => {
    if (tagType === 'hot') {
      return ContactPreviewPopoutHotTag;
    }
    if (tagType === 'warm') {
      return ContactPreviewPopoutWarmTag;
    }
    if (tagType === 'cold') {
      return ContactPreviewPopoutColdTag;
    }
    return ContactPreviewPopoutNeutralTag;
  }, [tagType]);

  return (
    <Box pt={PADDINGS.XS} data-testid='RecordPreview.HeatIndicationTag'>
      <HeatIndicationTag />
    </Box>
  );
}
