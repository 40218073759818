import { Generator } from 'shared/utils/models';
import { ModelGeneratorLoaded, Id } from '@rexlabs/model-generator';
import { UserItem } from 'data/models/value-lists/account-users';
import { SystemOptionObj } from 'data/models/system-lists/types';

interface ActivityOutcomes {
  id: Id;
  related_object: any; // Type this when we need it. Looks to the be the related action object - so the mailmerge, or note.
  related_object_id: Id;
  related_object_type: string;
  type: SystemOptionObj;
}
export interface Activity {
  etag: string;
  id: Id;
  parent_object_id: Id;
  parent_object_type: string;
  related: {
    activity_outcomes: ActivityOutcomes[];
  };
  system_ctime: number;
  system_created_user: UserItem;
  type: SystemOptionObj;
}

export type ActivityModel = ModelGeneratorLoaded<Activity, any>;

export default new Generator<Activity>('activities').createEntityModel();
