import { useMemo } from 'react';
import { useModelState } from '@rexlabs/model-generator';
import session from 'data/models/custom/session';

const newInProgressCriteria = [
  {
    name: 'lead.lead_status_id',
    type: 'in',
    value: ['in_progress', 'new']
  }
];

export function useLeadsListLenses() {
  const { user_details, group_member_ids = [] } = useModelState(session);

  return useMemo(
    () => ({
      all: {
        title: 'All Leads',
        orderBy: {
          field: 'system_ctime',
          direction: 'desc'
        },
        criteria: [...newInProgressCriteria]
      },
      team: {
        title: 'Assigned to Team',
        orderBy: {
          field: 'system_ctime',
          direction: 'desc'
        },
        criteria: [
          {
            name: 'lead.assignee_id',
            type: 'in',
            value: [user_details.id, ...group_member_ids]
          },
          ...newInProgressCriteria
        ]
      },
      me: {
        title: 'Assigned to Me',
        orderBy: {
          field: 'system_ctime',
          direction: 'desc'
        },
        criteria: [
          {
            name: 'lead.assignee_id',
            value: user_details.id
          },
          ...newInProgressCriteria
        ]
      },
      unassigned: {
        title: 'Unassigned Leads',
        orderBy: {
          field: 'system_ctime',
          direction: 'desc'
        },
        criteria: [
          {
            name: 'lead.assignee_id',
            type: 'is',
            value: 'null'
          },
          ...newInProgressCriteria
        ]
      }
    }),
    [user_details, group_member_ids]
  );
}
