import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';

import { APP_MENU } from 'theme/legacy/components';
import Option from './option';
import { getHazardly } from 'shared/utils/prefix-hell';
import Icon, { ICONS } from 'shared/components/icon';
import {
  hasPermission,
  isOwner
} from 'view/components/navigation/app-search/utils';

@styled(APP_MENU.RECORDS)
class MarketLeadOption extends PureComponent {
  static propTypes = {
    isActive: types.bool.isRequired
  };

  render() {
    const { styles: s, option, isActive } = this.props;
    const { data: campaign, value, label } = option;

    const userSecurityRights = getHazardly(campaign, 'security_user_rights');
    const systemOwnerUser = getHazardly(campaign, 'system_owner_user');
    const type = getHazardly(campaign, 'type.text');
    const status = getHazardly(campaign, 'status.text');
    const myCampaign = isOwner(systemOwnerUser);
    const CampaignIcon = <Icon key='listing-icon' type={ICONS.CAMPAIGN} />;
    return (
      <Option
        isActive={isActive}
        label={label}
        IconBox={[
          myCampaign && (
            <Icon
              key='owner-flag'
              {...s('ownerIcon')}
              type={ICONS.APP_SEARCH.OWNER}
            />
          ),
          !hasPermission(userSecurityRights, 'read') && (
            <Icon
              key='permission-flag'
              {...s('permissionIcon')}
              type={ICONS.APP_SEARCH.LOCK}
            />
          ),
          CampaignIcon
        ]}
        OptionMeta={[
          <span key='label' {...s('name', 'ellipsis')}>
            {label}
          </span>,
          type && (
            <span key='type' {...s('meta', 'ellipsis')}>
              Type: {type}
            </span>
          ),
          status && (
            <span key='type' {...s('meta', 'ellipsis')}>
              Status: {status}
            </span>
          )
        ]}
        OptionExtraMeta={[
          <span key='value' {...s('meta', 'extra', 'light')}>
            #{value}
          </span>
        ]}
      />
    );
  }
}

export default MarketLeadOption;
