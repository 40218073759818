import flagsmith from 'flagsmith';
import { isArray } from 'lodash';

/**
 * Wrapper for the Flagsmith hasFeature function. This function will only work after
 * the Flagsmith has initialised. As our FlagsContext prevents children from rendering
 * and should be wrapping your app, you should be fine to use this function anywhere.
 */
export function hasFeatureFlags(flagsToFind: string | string[]) {
  const flagsAsArray = isArray(flagsToFind) ? flagsToFind : [flagsToFind];
  return flagsAsArray.reduce((hasFlags, flag) => {
    return hasFlags && flagsmith.hasFeature(flag);
  }, true);
}
