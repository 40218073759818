import React, { useMemo } from 'react';

import Box from '@rexlabs/box';
import { Id, query, useEntityQuery } from '@rexlabs/model-generator';

import marketLeadsModel from 'data/models/entities/market-leads';

import { ICONS } from 'shared/components/icon';

import { Popout } from 'view/components/popout';

import { RecordPreviewImage } from 'components/record-preview/record-preview-image';
import { RecordPreviewRecordType } from 'components/record-preview/record-preview-record-type';
import { RecordPreviewHeading } from 'components/record-preview/record-preview-heading';
import { RecordPreviewSubheading } from 'components/record-preview/record-preview-subheading';
import { RecordPreviewGreyTag } from 'components/record-preview/record-preview-grey-tag';
import { RecordPreviewContentContainer } from 'components/record-preview/record-preview-content-container';
import { RecordPreviewViewButton } from 'components/record-preview/record-preview-view-button';
import { RecordPreviewActionBar } from 'components/record-preview/record-preview-action-bar';
import { RecordPreviewLoadingSpinner } from 'components/record-preview/record-preview-loading-spinner';
import { RecordPreviewAddNoteAction } from 'components/record-preview/actions/record-preview-add-note-action';
import { RecordPreviewAddReminderAction } from 'components/record-preview/actions/record-preview-add-reminder-action';

interface MarketLeadPreviewPopoutCoreProps {
  id: Id;
}

const getMarketLeadQuery = (marketLeadId) => {
  return query`{
  ${marketLeadsModel} (id: ${marketLeadId}) {
    id
    contact_name
    system_label
    lead_status
    contact
    property
  }
}`;
};

export function MarketLeadPreviewPopoutCore({
  id
}: MarketLeadPreviewPopoutCoreProps) {
  const marketLeadQuery = useMemo(
    () => (id && getMarketLeadQuery(id)) || undefined,
    [id]
  );
  const marketLeads = useEntityQuery(marketLeadQuery);
  const marketLead = marketLeads?.data;

  const isLoading = marketLeads?.status !== 'loaded';

  const leadAddress = marketLead?.system_label;
  const contact = marketLead?.contact_name;
  const leadStatus = marketLead?.lead_status?.text;
  const viewPath = `/market-leads/#id=${id}`;

  const newNoteData = useMemo(() => {
    return {
      id: '',
      _related: {
        note_properties: marketLead?.property
          ? [{ property: marketLead.property }]
          : [],
        note_contacts: marketLead?.contact
          ? [{ contact: marketLead.contact }]
          : [],
        note_market_leads: [{ market_lead: marketLead }]
      }
    };
  }, [marketLead]);

  const newReminderData = useMemo(() => {
    return {
      market_lead: marketLead,
      contact: marketLead?.contact || undefined
    };
  }, [marketLead]);

  return (
    <Box width={380}>
      <RecordPreviewContentContainer
        leftColumn={
          <>
            <RecordPreviewImage
              height={100}
              placeHolderIcon={ICONS.MARKET_LEAD}
            />
            <RecordPreviewViewButton viewPath={viewPath}>
              View
            </RecordPreviewViewButton>
          </>
        }
        rightColumn={
          <>
            <RecordPreviewRecordType>Market Lead</RecordPreviewRecordType>
            {!!isLoading && <RecordPreviewLoadingSpinner />}
            {leadAddress && (
              <RecordPreviewHeading>Lead at {leadAddress}</RecordPreviewHeading>
            )}
            {contact && (
              <RecordPreviewSubheading>{contact}</RecordPreviewSubheading>
            )}
            {leadStatus && (
              <RecordPreviewGreyTag>{leadStatus}</RecordPreviewGreyTag>
            )}
          </>
        }
      />
      <RecordPreviewActionBar>
        {!isLoading && (
          <>
            <RecordPreviewAddNoteAction noteData={newNoteData} />
            <RecordPreviewAddReminderAction reminderData={newReminderData} />
          </>
        )}
      </RecordPreviewActionBar>
    </Box>
  );
}

export function MarketLeadPreviewPopout(props) {
  // HACK: current version of popout doesn't let us manage state and causes rerender
  // loop if we don't memoize.
  // https://app.shortcut.com/rexlabs/story/58232/popouts-the-vivid-popout-component-cannot-maintain-state-within-the-popout-content
  const PopoutContent = useMemo(
    () => () => <MarketLeadPreviewPopoutCore id={props.id} />,
    [props.id]
  );

  return <Popout {...props} distance='11px' Content={PopoutContent} />;
}
