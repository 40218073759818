import { useMemo } from 'react';
import { useDebounce } from 'hooks/use-debounce';
import { GraphqlCriteria, QueryStatus } from 'hooks/use-graphql-query';
import {
  PaginatedResultFormat,
  useBatchedGraphQlQuery
} from 'features/custom-reporting/hooks/use-batched-graphql-query';
import { Criteria } from 'types/criteria';
import { useLoadingIndicator } from 'components/_experiment/record-details-screen/utils/use-loading-indicator';
import {
  QueryConfig,
  ReportColumnConfig
} from 'features/custom-reporting/modules/module-config-types';

import { getColumnsWeNeedDataFor } from 'features/custom-reporting/modules/helpers';
import { getCustomFieldsForVisibleColumns } from 'features/custom-reporting/utils/custom-field-utils';
import {
  ColumnState,
  FilterModel
} from 'features/custom-reporting/components/data-grid';

export type ReportQueryResponse = PaginatedResultFormat;

interface ReportQueryOutput {
  data: null | ReportQueryResponse;
  status: QueryStatus;
  reload: () => void;
}
interface ReportQueryParams {
  selectedGridColumns?: ColumnState[];
  filteredGridColumns?: FilterModel;
  allColumnsMapped: ReportColumnConfig;
  queryConfig: QueryConfig;
  criteria: Criteria[];
}

type SubresourceConfig = Record<string, boolean>;

const PAGE_LIMIT = 1000;
const NUM_OF_PAGES = 10;
export const TOTAL_LIMIT = PAGE_LIMIT * NUM_OF_PAGES;

const defaultQueryVariables = {
  criteria: [],
  cursor: null,
  limit: PAGE_LIMIT
};

function transformCriteria(criteria: Criteria[]): GraphqlCriteria[] {
  return criteria?.map(({ value, ...criterion }) => ({
    ...criterion,
    // value_as_json works for all kinds of values, including arrays, so we use that here to avoid complexity in
    // the consumers
    value_as_json: JSON.stringify(value)
  }));
}

function getSubresourcesFromVisibleColumns(
  allColumnsMapped,
  columnState,
  filterState,
  startingValue: SubresourceConfig = {}
): SubresourceConfig {
  const visibleColumns = getColumnsWeNeedDataFor({
    columnState,
    filterState,
    allColumnsMapped
  });
  return visibleColumns.reduce((subresources, selectedColumn) => {
    return selectedColumn?.subresource
      ? {
          ...subresources,
          [`include_subresource_${selectedColumn.subresource}`]: true
        }
      : subresources;
  }, startingValue);
}

/**
 * We use this hook to generate the list of subresources we need to query for, based on the column selections.
 * This also debounces the value to ensure we only re-query when the user has finished selecting columns.
 *
 * TODO: Currently the subresources requeries even when you deselect columns, which isn't ideal, but is also in no way
 * a blocker for release.
 *
 * TODO: This code is actually worth testing
 *  https://rexsoftware.atlassian.net/browse/RADI-5413
 */
export function useReportQuery({
  selectedGridColumns,
  filteredGridColumns,
  allColumnsMapped,
  queryConfig,
  criteria
}: ReportQueryParams): ReportQueryOutput {
  const subresources = useMemo(
    () =>
      getSubresourcesFromVisibleColumns(
        allColumnsMapped,
        selectedGridColumns,
        filteredGridColumns,
        queryConfig?.queryVariables
      ),
    [
      allColumnsMapped,
      selectedGridColumns,
      filteredGridColumns,
      queryConfig?.queryVariables
    ]
  );
  const customFieldVariables = getCustomFieldsForVisibleColumns({
    selectedGridColumns,
    filteredGridColumns,
    allColumnsMapped
  });

  // debounce the value so the grid only requeries when the user has finished selecting columns
  // debounce the subresources and the custom fields together, we get a request each otherwise
  const debouncedVariables = useDebounce(
    { subresources, customFieldVariables },
    1000
  );

  const queryVariables = useMemo(() => {
    return {
      ...defaultQueryVariables,
      ...debouncedVariables.subresources,
      ...debouncedVariables.customFieldVariables,
      criteria: transformCriteria(criteria),
      order_bys: queryConfig.defaultSort
    };
  }, [debouncedVariables, criteria, queryConfig.defaultSort]);

  const { data, status, reload } = useBatchedGraphQlQuery({
    query: queryConfig.graphQlQuery,
    variables: queryVariables,
    pages: NUM_OF_PAGES
  });

  useLoadingIndicator(status);

  return {
    data,
    status,
    reload
  };
}
