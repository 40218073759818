import React from 'react';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { SlidingScaleSettingRecord } from './sliding-scale-setting-record';
import SlidingScaleSettings from './sliding-scale-settings';
import { withModel } from '@rexlabs/model-generator';
import { slidingScaleSettingsModel } from 'data/models/entities/sliding-scale-settings';
import { ApiActions } from 'data/models/custom/api-model';
import { SlidingScaleSetting } from 'features/settings/components/sliding-scale-settings/data/types';

function SlidingScaleSettingsScreen({
  adminCommAgentSlidingScales: slidingScaleActions
}: {
  adminCommAgentSlidingScales: ApiActions<SlidingScaleSetting>;
}) {
  const whereabouts = useWhereaboutsWithViewpath();

  if (!whereabouts.path?.includes('/admin/finance_and_commissions/sliding'))
    return null;

  return whereabouts.hashQuery?.id ? (
    <SlidingScaleSettingRecord slidingScaleActions={slidingScaleActions} />
  ) : (
    <SlidingScaleSettings slidingScaleActions={slidingScaleActions} />
  );
}

export default withModel(slidingScaleSettingsModel)(SlidingScaleSettingsScreen);
