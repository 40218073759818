import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { UserItem } from '../value-lists/account-users';

export interface UserGroupsItem {
  account_id: string | null;
  description: string;
  etag: string;
  id: number;
  is_hidden: number;
  library: { id: number; library_name: string };
  name: string;
  system_created_user: UserItem;
  system_ctime: number;
  system_modified_user: UserItem;
  system_modtime: number;
  system_record_state: string;
  users: UserItem[];
}

const TYPE = 'adminUserGroups';
const adminUserGroupsModel = new Generator<UserGroupsItem>(
  TYPE
).createEntityModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model

adminUserGroupsModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('AdminUserGroups::search', {
        criteria: [{ name: 'name', type: 'like', value: `%${searchTerm}%` }]
      })
      .then(({ data }) => {
        return data.result.rows.map((group) => ({
          value: group.id,
          label: group.name,
          model: adminUserGroupsModel,
          data: group
        }));
      })
};

export default adminUserGroupsModel;
