import React, { Component } from 'react';
import { StyleSheet, styled, keyframes } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS, FONT } from 'theme/index';
import Icon, { ICONS } from 'shared/components/icon';

const slideOut = keyframes({
  '0%': {
    transform: 'translateX(0)',
    zIndex: '-10'
  },
  '50%': {
    transform: 'translateX(9px)',
    zIndex: '-10'
  },
  '99.9%': {
    transform: 'translateX(18px)',
    zINdex: '-10'
  },
  '100%': {
    transform: 'translateX(18px)',
    zIndex: '100'
  }
});
const slideIn = keyframes({
  '0%': {
    transform: 'translateX(18px)',
    zIndex: '100'
  },
  '0.1%': {
    transform: 'translateX(18px)',
    zINdex: '-10'
  },
  '50%': {
    transform: 'translateX(9px)',
    zIndex: '-10'
  },
  '100%': {
    transform: 'translateX(0)',
    zIndex: '-10'
  }
});

// NOTE: Need to come back to these and tidy up the style sheets
const defaultStyles = StyleSheet({
  tag: {
    width: 'fit-content',
    height: '22px',
    display: 'flex',
    position: 'relative',

    '&:hover': {
      '& #clickable': {
        animation: `${slideOut} 0.2s ease-in`,
        animationFillMode: 'forwards'
      }
    }
  },
  tagIcon: {
    width: '15px',
    height: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexDirection: 'column',
    '& svg': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  iconLeft: {
    marginRight: '5px',
    marginLeft: '-3px'
  },
  iconRight: {
    marginLeft: '5px',
    marginRight: '-3px'
  },
  whiteIcon: {
    '& svg': {
      color: COLORS.WHITE
    }
  },
  blackIcon: {
    '& svg': {
      color: COLORS.BLACK
    }
  },
  cursor: {
    cursor: 'pointer'
  },
  shared: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.DARK_GREY
  },
  innerDiv: {
    padding: '0 9px',

    '& svg': {
      display: 'flex',
      alignItems: 'center'
    },

    '& span': {
      position: 'relative',
      font: FONT.FAMILY.PROXIMA_NOVA,
      fontSize: '12px',
      fontWeight: '600'
    }
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    color: 'white'
  },
  clickable: {
    position: 'absolute',
    zIndex: '-10',
    right: '0',
    backgroundColor: COLORS.DARK_GREY,
    height: '22px',
    width: '22px',
    backgroundImage: '0',
    animation: `${slideIn} 0.2s ease-in`,
    animationFillMode: 'forwards'
  },
  initialAnimation: {
    animation: `${slideIn} 0s ease-in`
  },
  roundedEdges: {
    borderRadius: '2px'
  },
  withMargin: {
    margin: '0 5px 5px 0'
  }
});

@styled(defaultStyles)
@autobind
class Tag extends Component {
  initAnimation = true;

  scrollOutDone() {
    if (this.initAnimation === true) {
      this.initAnimation = false;
    }
  }

  render() {
    const {
      styles: s,
      children,
      backgroundColor,
      color,
      onClick,
      iconType,
      iconLeft,
      iconRight,
      truncate,
      width,
      white,
      roundedEdges,
      noMargin,
      ...rest
    } = this.props;

    return (
      <div
        onClick={onClick}
        {...s('tag', {
          cursor: onClick,
          withMargin: !noMargin
        })}
        {...rest}
      >
        <div
          {...s.with('innerDiv', 'shared', { roundedEdges: roundedEdges })({
            backgroundColor,
            color
          })}
        >
          {iconLeft && iconType ? (
            <Icon
              width='15px'
              height='15px'
              type={iconType}
              {...s('tagIcon', 'iconLeft', {
                whiteIcon: white,
                blackIcon: !white
              })}
            />
          ) : null}
          <span style={{ maxWidth: width || 'auto' }} {...s({ truncate })}>
            {children}
          </span>
          {iconRight && iconType ? (
            <Icon
              width='15px'
              height='15px'
              type={iconType}
              {...s('tagIcon', 'iconRight', {
                whiteIcon: white,
                blackIcon: !white
              })}
            />
          ) : null}
        </div>
        {/* Conditionally renders an icon on the scroll out tab if the tag has a function pass through props */}
        {onClick && (
          <div
            id='clickable'
            onAnimationEnd={this.scrollOutDone}
            {...s.with('clickable', 'shared', {
              initialAnimation: this.initAnimation
            })({ backgroundColor })}
          >
            <Icon height='18px' width='18px' type={ICONS.EDIT} {...s('icon')} />
          </div>
        )}
      </div>
    );
  }
}

export default Tag;
