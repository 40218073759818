import { WhichWord } from 'hooks/use-which-word';

export const contactsPrompts = (ww: WhichWord) => [
  "owners that I haven't contacted in 90 days",
  'contacts looking to rent in...',
  'hot contacts without a phone number',
  `my hot ${ww('buyer').toLowerCase()}s I haven't spoken to in 30 days`,
  `${ww('buyer').toLowerCase()}s looking for 2 beds around 800k`,
  "Alex's owners"
];

export const listingsPrompts = (ww: WhichWord) => [
  "residential listings that aren't published",
  'published listings with portal errors',
  'sold listings in the past 3 months',
  `listings expiring in the next 2 weeks`,
  `listings between 600k - 700k`
];

export const propertiesPrompts = (ww: WhichWord) => [
  '3 bed 2 bath',
  `current ${ww('valuation').toLowerCase()}s for agent...`,
  'resi properties in...',
  `failed ${ww('valuation').toLowerCase()}s from 1 year ago`
];
