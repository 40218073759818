import React, { ReactNode } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import PaddingBox from 'view/components/padding-box';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    paddingTop: '0 !important'
  },
  noPadding: {
    padding: '0 !important'
  },
  inner: {
    width: '100%',
    padding: '60px !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '17px',
    fontWeight: 600,
    color: '#b4b1a9'
  }
});

interface StateViewProps {
  noPadding?: boolean;
  children: ReactNode;
}

export function StateView({ children, noPadding }: StateViewProps) {
  const s = useStyles(defaultStyles);
  return (
    <PaddingBox {...s('container', { noPadding })}>
      <PaddingBox light {...s('inner')}>
        {children}
      </PaddingBox>
    </PaddingBox>
  );
}
