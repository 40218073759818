import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { ReactForms, Form, FormField } from 'view/components/form';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import { autobind } from 'core-decorators';
import { TextInput } from '@rexlabs/text-input';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import { createValidationRules } from 'shared/utils/form';

@autobind
class EditDocumentDialog extends PureComponent {
  handleSubmit(values) {
    const { callback: onSubmit, closeDialog, managePrivacy } = this.props;
    Promise.all([
      onSubmit
        ? onSubmit({
            description: values.description,
            ...(managePrivacy
              ? {
                  private: {
                    id: values.private.id,
                    text: values.private.id === 'private' ? 'Private' : 'Public'
                  },
                  private_id: values.private.id
                }
              : {})
          })
        : null
    ]).then(() => closeDialog());
  }

  render() {
    const { closeDialog, onLoad, file, managePrivacy } = this.props;
    return (
      <Dialog
        title='Edit Document'
        closeDialog={closeDialog}
        onLoad={onLoad}
        height={400}
      >
        <ReactForms
          initialValues={file}
          handleSubmit={this.handleSubmit}
          validate={createValidationRules({
            description: ['required', 'filename']
          })}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <FormField
                name='description'
                label='filename'
                Input={TextInput}
              />
              {managePrivacy && (
                <FormField
                  name='private.id'
                  label='document access'
                  Input={RadioGroupInput}
                  inputProps={{
                    name: 'radio-group',
                    options: [
                      {
                        value: 'private',
                        label:
                          'Private - only visible to the organiser and other users'
                      },
                      {
                        value: 'public',
                        label: 'Public - Visible to all guests'
                      }
                    ]
                  }}
                />
              )}
              <ButtonBar isLoading={isSubmitting}>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton dark onClick={submitForm}>
                  Done
                </DefaultButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default EditDocumentDialog;
