import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
const modelName = 'dashboardReportingData';

const actionCreators = {
  getDataForWidgets: {
    request: ({ widgetIds, filters, dashboardId }) => {
      const widgetsArg = widgetIds?.map?.((widgetId) => ({
        id: widgetId
      }));

      const filtersArgs = filters
        ? {
            date_range: filters.dateRange?.value,
            category:
              filters.category?.length > 0
                ? filters.category.map((c) => c.value)
                : undefined,
            agent:
              filters.users?.length > 0
                ? filters.users.map((u) => u.value)
                : undefined,
            comparison_date_range: filters.dateRange?.comparison
          }
        : undefined;
      const filterMappingArgs = {
        sold_status: filters.soldStatus?.value,
        agent: filters.agent?.value
      };

      return api
        .post('ReportingData::getDataForWidgets', {
          dashboard_id: dashboardId,
          filters: filtersArgs,
          filter_mapping: filterMappingArgs,
          widgets: widgetsArg
        })
        .then(({ data }) => data.result);
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getDataForWidget: {
    request: ({ widgetId, widgetOverrides, dashboardId }) => {
      return api.post('ReportingData::getDataForWidget', {
        widget_id: widgetId,
        dashboard_id: dashboardId,
        widget_overrides: widgetOverrides
      });
    },

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getRecordIdsForWidget: {
    request: ({
      dashboardId,
      widgetId,
      widgetOverrides,
      returnViewstate = true
    }) => {
      return api.post('ReportingData::getRecordIdsForWidget', {
        widget_overrides: widgetOverrides,
        dashboard_id: dashboardId,
        widget_id: widgetId,
        return_viewstate: returnViewstate
      });
    },

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  getLastExportedTime: {
    request: () => {
      return api.post('ReportingData::getLastExportedTime');
    },

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator(modelName).createModel({
  actionCreators
});
