/*
|-------------------------------------------------------------------------------
| Menu Recents
|-------------------------------------------------------------------------------
|
| The following component looks after rendering only the recent options which come
| from the specific Provider type "RECENT".
|
*/

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { selectController } from '@rexlabs/select-input';

@selectController
@styled(
  StyleSheet({
    container: {
      position: 'absolute',
      background: 'white',
      top: '0',
      right: '0',
      left: '0',
      height: '100%'
    }
  })
)
@autobind
class MenuRecents extends Component {
  render() {
    const {
      styles,
      select,
      onHover,
      onSelect,
      scrollIntoView,
      viewMode,
      renderRecents
    } = this.props;
    const {
      state: { index }
    } = select;

    const optionsProps = {
      activeIndex: index,
      onHover: onHover,
      onSelect: onSelect,
      scrollIntoView: scrollIntoView
    };

    return (
      <div {...styles('container')}>
        {renderRecents(optionsProps, viewMode)}
      </div>
    );
  }
}

export default MenuRecents;
