import { useCallback, useMemo } from 'react';

import { useModelActions } from '@rexlabs/model-generator';

import leadsModel, { LeadsResponse } from 'data/models/entities/leads';

import { usePermissions } from 'hooks/use-permissions';

import { ColumnsConfig } from 'components/record-list-screen/types';
import { TagCell } from 'components/record-list-screen/cells/tag-cell';
import { DateTimeRelativeCell } from 'components/record-list-screen/cells/date-time-relative-cell';
import { DateTimeCell } from 'components/record-list-screen/cells/date-time-cell';
import { ContactCell } from 'components/record-list-screen/cells/contact-cell';
import { useRecordNavigation } from 'components/_experiment/record-details-screen/utils/use-record-navigation';

import { getRequiredRecordStatus } from 'features/leads/utils/get-required-record-status';
import { LeadAddressCell } from 'features/leads/components/lead-address-cell';
import { IdCell } from 'components/record-list-screen/cells/id-cell';

const getLeadStatus = (data: LeadsResponse) => {
  const isComplete = data?.lead_status?.text.toLowerCase() === 'completed';
  const isArchived = data?.system_record_state?.toLowerCase() === 'archived';

  return { complete: isComplete, archived: isArchived };
};

export function useLeadsListColumns() {
  const { archive, unarchive, updateCompletionStatus, refreshLists } =
    useModelActions(leadsModel);

  const { check } = usePermissions();
  const { goToRecord } = useRecordNavigation();

  const processSelectedLead = useCallback(
    // TODO: Would be good to get all ids. Should be able to pass those into that actions
    // the same way we pass them into the row onclick
    // https://app.shortcut.com/rexlabs/story/67345/pass-allids-to-row-actions
    ({ data }) => goToRecord({ id: data.id }),
    [goToRecord]
  );

  const markAsCompleteOrIncomplete = useCallback(
    ({ data }) => {
      const { hasRequiredRelatedRecords } = getRequiredRecordStatus(data);

      if (!hasRequiredRelatedRecords) return processSelectedLead({ data });

      const isComplete = getLeadStatus(data).complete;
      return updateCompletionStatus({
        id: data.id,
        is_completed: !isComplete
      }).then(refreshLists);
    },
    [processSelectedLead, refreshLists, updateCompletionStatus]
  );

  const archiveOrUnarchive = useCallback(
    ({ data }) => {
      const action = getLeadStatus(data).archived ? unarchive : archive;
      return action({ id: data?.id }).then(refreshLists);
    },
    [archive, refreshLists, unarchive]
  );

  const getRowActions = useCallback(
    (rowData) => {
      return [
        {
          label: 'Process Lead',
          onClick: processSelectedLead
        },
        {
          label: getLeadStatus(rowData).complete
            ? 'Mark As Incomplete'
            : 'Mark As Complete',
          onClick: markAsCompleteOrIncomplete
        },
        {
          label: getLeadStatus(rowData).archived ? 'Unarchive' : 'Archive',
          onClick: archiveOrUnarchive,
          hasPermission: check('leads.archive')
        }
      ];
    },
    [processSelectedLead, markAsCompleteOrIncomplete, archiveOrUnarchive, check]
  );

  return useMemo(
    (): ColumnsConfig<LeadsResponse> => [
      {
        label: 'ID',
        id: 'id',
        selector: (row) => row.id,
        Cell: IdCell,
        hidden: true
      },
      {
        label: 'Type',
        id: 'lead_type',
        selector: (row) => row.lead_type?.text,
        hidden: false,
        cellProps: { items: getRowActions }
      },
      {
        label: 'Source',
        id: 'lead_source',
        selector: (row) => row?.lead_source?.text,
        hidden: false
      },
      {
        label: 'Contact',
        id: 'contact',
        Cell: ContactCell,
        hidden: false
      },
      {
        label: 'Listing/Property',
        id: 'lead_listing_property',
        selector: (row) => row,
        Cell: LeadAddressCell as typeof TagCell,
        hidden: false
      },
      {
        label: 'Assignee',
        id: 'assignee',
        selector: (row) => row.assignee?.name,
        hidden: false
      },
      {
        label: 'Received',
        id: 'received',
        selector: (row) => row.system_ctime,
        hidden: false,
        Cell: DateTimeRelativeCell,
        sortable: 'system_ctime'
      },
      {
        label: 'Status',
        id: 'lead_status',
        selector: ({ lead_status }) => {
          if (lead_status.id === 'completed') {
            return [{ style: 'dark', text: lead_status.text }];
          }
          if (lead_status.id === 'new') {
            return [{ style: 'normal', text: lead_status.text }];
          }
          if (lead_status.id === 'in_progress') {
            return [{ style: 'warning', text: lead_status.text }];
          }
          return [];
        },
        hidden: false,
        Cell: TagCell
      },
      {
        label: 'Last Action',
        id: 'last_activity',
        selector: (row) => {
          return row?.last_activity?.related?.activity_outcomes[0]?.type.text;
        },
        hidden: false
      },
      {
        label: 'Last Modified',
        id: 'system_modtime',
        selector: (row) => row.system_modtime,
        Cell: DateTimeCell,
        hidden: true
      },
      {
        label: 'Modified By',
        id: 'system_modified_user',
        selector: (row) => row.system_modified_user.name,
        hidden: true
      },
      {
        label: 'Creation Date',
        id: 'system_ctime',
        selector: (row) => row.system_ctime,
        sortable: 'system_ctime',
        Cell: DateTimeCell,
        hidden: true
      },
      {
        label: 'Created By',
        id: 'system_created_user',
        selector: (row) => row.system_created_user.name,
        hidden: true
      }
    ],
    [getRowActions]
  );
}
