import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TEXTS, COLORS, PADDINGS } from 'theme';
import { MAXIMUM_SCOPED_RESULTS } from 'view/components/navigation/app-search/utils';

interface Props {
  optionCount: number;
  totalCount: number;
  isScoped: boolean;
  onClick: () => any;
}

const defaultStyles = StyleSheet({
  container: {
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${PADDINGS.S} ${PADDINGS.S} ${PADDINGS.S}`,
    cursor: 'pointer',
    width: '100%',
    bottom: 0
  },
  total: {
    color: COLORS.PRIMARY.SAND,
    lineHeight: '17px'
  },
  info: {
    ...TEXTS.APP_SEARCH.MENU_HEADING,
    color: COLORS.DARK_GREY,
    textDecoration: 'underline',
    lineHeight: '17px'
  }
});

function SectionShowList({
  optionCount,
  totalCount,
  isScoped,
  onClick
}: Props) {
  const s = useStyles(defaultStyles);

  if (!isScoped || !optionCount) return null;

  const total = totalCount === MAXIMUM_SCOPED_RESULTS ? 'many' : totalCount;

  return (
    <div {...s('container')} onClick={onClick}>
      <div {...s('total')}>
        1-{optionCount} of {total}
      </div>
      <div {...s('info')}>View all in a list</div>
    </div>
  );
}

export default React.memo(SectionShowList);
