import { omit, pick } from 'lodash';
import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';

const TYPE = 'adminBuyerMatch';

export const DEFAULT_CAMPAIGN_SETTINGS = {
  frequency: 'never',
  send_day: null,
  send_time: null,
  send_time_end: null,
  expire_in_weeks: null
};

export interface CampaignSettings {
  expire_in_weeks?: number | null;
  frequency?: string | null;
  send_day: string | null;
  send_time: string | null;
  send_time_end: string | null;
}

export interface BuyerMatchSettingsData {
  archive_after_months: number | null | string;
  archive_with_active_campaign?: boolean;
  res_sale_create_from: {
    res_sale_create_from_calendar_events: boolean;
    res_sale_create_from_feedback: boolean;
    res_sale_create_from_leads: boolean;
  };
  res_sale_campaign_email: CampaignSettings | null;
  res_sale_campaign_sms: CampaignSettings | null;
  res_sale_has_min_price: boolean;
  res_sale_bedroom_min_count: 'equal' | 'one' | 'none' | null;
  res_sale_suburbs: 'limit' | 'any' | null;
  res_sale_max_price: number | string;
  res_sale_min_price: number | string;
}

export type BuyerMatchSettingsResponse = Omit<
  BuyerMatchSettingsData,
  'res_sale_create_from' | 'res_sale_suburbs'
> &
  BuyerMatchSettingsData['res_sale_create_from'] & {
    res_sale_limit_to_suburb_or_district: boolean;
  };

export interface AdminBuyerMatchState {
  isLoading: boolean;
  data: BuyerMatchSettingsData;
}

function mapToApi(
  formValues: BuyerMatchSettingsData
): BuyerMatchSettingsResponse {
  const {
    res_sale_create_from: createFrom,
    archive_after_months,
    res_sale_campaign_email,
    res_sale_campaign_sms
  } = formValues;
  const archiveAfterMonths =
    archive_after_months === '0' ? null : formValues.archive_after_months;

  const campaigns = {
    res_sale_campaign_email:
      res_sale_campaign_email?.frequency === 'never'
        ? null
        : {
            ...DEFAULT_CAMPAIGN_SETTINGS,
            ...res_sale_campaign_email,
            expire_in_weeks:
              res_sale_campaign_email?.expire_in_weeks === -1
                ? null
                : res_sale_campaign_email?.expire_in_weeks
          },
    res_sale_campaign_sms:
      res_sale_campaign_sms?.frequency === 'never'
        ? null
        : {
            ...DEFAULT_CAMPAIGN_SETTINGS,
            ...res_sale_campaign_sms,
            expire_in_weeks:
              res_sale_campaign_sms?.expire_in_weeks === -1
                ? null
                : res_sale_campaign_sms?.expire_in_weeks
          }
  };

  if (Object.keys(createFrom).some((key) => createFrom[key]))
    return {
      ...omit(formValues, [
        'res_sale_create_from',
        'res_sale_suburbs',
        'res_sale_campaign_email',
        'res_sale_campaign_sms'
      ]),
      ...createFrom,
      ...campaigns,
      res_sale_limit_to_suburb_or_district:
        formValues.res_sale_suburbs === 'limit',
      archive_after_months: archiveAfterMonths
    };

  // Return default values for automatic residential when there is no 'create' checkbox selected
  // Coz api still needs them even if they are hidden in the UI
  return {
    ...createFrom,
    res_sale_campaign_email: null,
    res_sale_campaign_sms: null,
    res_sale_limit_to_suburb_or_district: true,
    res_sale_min_price: '20',
    res_sale_max_price: '10',
    res_sale_has_min_price: true,
    res_sale_bedroom_min_count: 'equal',
    archive_after_months: archiveAfterMonths
  };
}

function mapFromApi(
  apiValues: BuyerMatchSettingsResponse
): BuyerMatchSettingsData {
  const {
    res_sale_limit_to_suburb_or_district,
    res_sale_min_price,
    archive_after_months,
    res_sale_campaign_email,
    res_sale_campaign_sms
  } = apiValues;
  return {
    ...omit(apiValues, [
      'res_sale_create_from_calendar_events',
      'res_sale_create_from_feedback',
      'res_sale_create_from_leads',
      'res_sale_campaign_email',
      'res_sale_campaign_sms'
    ]),
    res_sale_campaign_email: res_sale_campaign_email
      ? {
          ...res_sale_campaign_email,
          expire_in_weeks:
            res_sale_campaign_email.expire_in_weeks === null
              ? -1
              : res_sale_campaign_email.expire_in_weeks
        }
      : null,
    res_sale_campaign_sms: res_sale_campaign_sms
      ? {
          ...res_sale_campaign_sms,
          expire_in_weeks:
            res_sale_campaign_sms.expire_in_weeks === null
              ? -1
              : res_sale_campaign_sms.expire_in_weeks
        }
      : null,
    res_sale_suburbs:
      res_sale_limit_to_suburb_or_district === null
        ? null
        : res_sale_limit_to_suburb_or_district
        ? 'limit'
        : 'any',
    res_sale_has_min_price: !!res_sale_min_price,
    res_sale_create_from: pick(apiValues, [
      'res_sale_create_from_calendar_events',
      'res_sale_create_from_feedback',
      'res_sale_create_from_leads'
    ]),
    archive_after_months: String(Number(archive_after_months))
  };
}

const actionCreators = {
  readSettings: {
    request: () =>
      api
        .post('AccountSettingsMatchProfiles::getEffectiveSettings')
        .then((response) => mapFromApi(response.data.result)),
    reduce: {
      intial: (state) => ({ ...state, isLoading: true }),
      success: (state, action) => ({
        ...state,
        data: action.payload,
        isLoading: false
      }),
      failure: (state) => ({ ...state, isLoading: false })
    }
  },
  updateSettings: {
    request: (payload) =>
      api
        .post('AccountSettingsMatchProfiles::updateSettings', {
          settings: mapToApi(payload)
        })
        .then((response) => mapFromApi(response.data.result)),
    reduce: {
      intial: (state) => ({ ...state, isLoading: true }),
      success: (state, action) => ({
        ...state,
        data: action.payload,
        isLoading: false
      }),
      failure: (state) => ({ ...state, isLoading: false })
    }
  }
};

const adminBuyerMatchModel = new Generator<AdminBuyerMatchState>(
  TYPE
).createEntityModel({
  actionCreators
});

export default adminBuyerMatchModel;
