import {
  ModelGeneratorLoaded,
  EntityListQueryReturnType,
  EntityQueryReturnType
} from '@rexlabs/model-generator';

import { Generator } from 'shared/utils/models';
import {
  ContactAutocomplete,
  ContactStub
} from 'data/models/entities/contacts';
import { PropertyStub } from 'data/models/entities/properties';
import { UserItem } from 'data/models/value-lists/account-users';
import { MarketLeadStub } from 'data/models/entities/market-leads';
import { ChainLinkStub } from 'features/chains/data/chain-link-types';
import { ProjectStageStub } from 'data/models/entities/project-stages';
import { ProjectStub } from 'data/models/entities/projects';

export type NoteModel = ModelGeneratorLoaded<NoteItem, any>;

export interface NoteContactItem {
  id: string | number;
  contact: ContactAutocomplete | ContactStub | null;
}

export interface NotePropertyItem {
  id: string;
  property: PropertyStub | null;
}

export interface NoteMarketLeadItem {
  id: string;
  market_lead: MarketLeadStub | null;
}

export interface NoteProjectItem {
  id: string;
  project: ProjectStub | null;
}

export interface NoteProjectStage {
  id: string;
  project_stage: ProjectStageStub | null;
}

interface NoteChainLink {
  id: string;
  chain_link: ChainLinkStub;
}

interface NoteType {
  id: 'note';
  text: 'Note';
}

interface SmsType {
  id: 'sms';
  text: 'SMS';
}

interface EmailType {
  id: 'email';
  text: 'Email';
}

interface PhoneType {
  id: 'phonecall';
  text: 'Phone Call';
}

interface MeetingType {
  id: 'meeting';
  text: 'Meeting';
}

interface LetterType {
  id: 'letter';
  text: 'Letter';
}

export type PossibleNoteTypes =
  | NoteType
  | EmailType
  | SmsType
  | LetterType
  | MeetingType
  | PhoneType;

export interface NoteItem {
  system_record_state: string;
  system_ctime: number;
  system_modtime: string;
  reminder_id: string;
  etag: string;
  security_user_rights: string[];
  note: string;
  related: {
    note_contacts: NoteContactItem[];
    note_market_leads: NoteMarketLeadItem[];
    note_properties: NotePropertyItem[];
    note_projects: NoteProjectItem[];
    note_project_stages: NoteProjectStage[];
    note_chain_links: NoteChainLink[];
    custom_related_contacts: NoteContactItem[];
  };
  system_modified_user: UserItem;
  system_created_user: UserItem;
  note_type: PossibleNoteTypes;

  id: string;
}

export type NoteData = Omit<NoteItem, 'note_type'> & {
  noteType: PossibleNoteTypes;
};

const TYPE = 'notes';
const notesModel = new Generator<NoteItem>(TYPE).createEntityModel();

export type NotesEntityList = EntityListQueryReturnType<typeof notesModel>;
export type NotesEntityItem = EntityQueryReturnType<typeof notesModel>;

export default notesModel;
