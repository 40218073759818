import { useMemo } from 'react';
import { Criteria } from 'types/criteria';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';
import { usePermissions } from 'hooks/use-permissions';

import { makeEntity } from 'features/custom-reporting/modules/helpers';

import {
  ListingReportQuery,
  listingReportQueryDefaultVariables
} from './listing-report.query';
import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'listing_address',
  'listing_category.text',
  'status',
  'listing_agent_1.name',
  'system_publication_time',
  'price_match',
  'comm_est_amount_inc_tax',
  'property.attr_tenure_id.text',
  'owner.0.contact',
  'owner.0.contact.phones'
];

export function useListingModule() {
  const region = useRegion();
  const ww = useWhichWordFactory();
  const { hasAddon } = usePermissions();
  const hasProjectsAddon = hasAddon('projects');
  const {
    properties: propertyCustomFields,
    contacts: contactCustomFields,
    listings: listingCustomFields,
    projects: projectCustomFields,
    project_stages: projectStageCustomFields,
    contracts: contractCustomFields
  } = useCustomFields();

  const listingModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.listings,
      moduleLabel: 'Listings',
      exportPrivilege: 'exporting.listings',
      queryConfig: {
        graphQlQuery: ListingReportQuery,
        queryVariables: listingReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'system_publication_time',
            direction: 'descending' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: '',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Listing Owner',
          children: makeEntity({
            entity: 'contact',
            prefix: 'owner.0.contact',
            subresource: 'listing_owner',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: ww('contract'),
          children: makeEntity({
            entity: 'contract',
            prefix: 'primary_active_contract',
            subresource: 'contract',
            customFields: contractCustomFields,
            whichWord: ww,
            region
          })
        },
        ...(!region.isEU
          ? [
              {
                headerName: 'Commission',
                children: makeEntity({
                  entity: 'commissionBySale',
                  prefix: 'commissions.commission_worksheet',
                  subresource: 'commissions',
                  whichWord: ww
                })
              }
            ]
          : []),
        ...(hasProjectsAddon
          ? [
              {
                headerName: 'Project',
                children: makeEntity({
                  entity: 'project',
                  prefix: 'project.project',
                  subresource: 'project',
                  customFields: projectCustomFields,
                  region
                })
              },
              {
                headerName: 'Project Stages',
                children: makeEntity({
                  entity: 'projectStage',
                  prefix: 'project_stage',
                  subresource: 'project_stage',
                  customFields: projectStageCustomFields,
                  region
                })
              }
            ]
          : [])
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'this_year_to_date',
      defaultUserFieldPreference: 'listing.listing_agent_1_id',
      defaultDateFieldPreference: 'system_ctime',
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'system_ctime',
              label: 'Listing Creation',
              isDefault: true
            },
            {
              fieldName: 'listing.system_publication_time',
              label: 'Publish'
            },
            {
              fieldName: 'listing.available_from_date',
              label: `${ww('letting')} available`
            },
            {
              fieldName: 'listing.authority_date_expires',
              label: 'Expiry'
            },
            {
              fieldName: 'sold_date',
              label: 'Sold',
              getCriteria: ({ start, end }) =>
                [
                  { name: 'listing.state_date', type: '>=', value: start },
                  { name: 'listing.state_date', type: '<=', value: end },
                  { name: 'listing.system_listing_state', value: 'sold' }
                ] as Criteria[]
            },
            {
              fieldName: 'leased_date',
              label: 'Leased',
              getCriteria: ({ start, end }) =>
                [
                  { name: 'listing.state_date', type: '>=', value: start },
                  { name: 'listing.state_date', type: '<=', value: end },
                  { name: 'listing.system_listing_state', value: 'leased' }
                ] as Criteria[]
            },
            {
              fieldName: 'withdrawn_date',
              label: 'Withdrawn',
              getCriteria: ({ start, end }: { start: string; end: string }) =>
                [
                  { name: 'listing.state_date', type: '>=', value: start },
                  { name: 'listing.state_date', type: '<=', value: end },
                  { name: 'listing.system_listing_state', value: 'withdrawn' }
                ] as Criteria[]
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'listing.listing_agent_1_id',
              label: 'User is listing agent 1',
              isDefault: true
            },
            {
              fieldName: 'listing.listing_agent_id',
              label: 'User is listing agent 1 or listing agent 2'
            }
          ]
        },
        location: {
          availableFields: [
            { fieldName: 'listing.location_id', label: 'Location' }
          ]
        }
      }
    }),
    [
      ww,
      region,
      hasProjectsAddon,
      propertyCustomFields,
      contactCustomFields,
      listingCustomFields,
      projectCustomFields,
      projectStageCustomFields,
      contractCustomFields
    ]
  );
  return listingModuleConfig;
}
