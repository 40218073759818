import { Generator } from 'shared/utils/models';

import { createValSetterActionCreator } from './ui';

const initialState = {
  isOverlayBannerActive: false,
  isHeaderCollapsed: false
};

const actionCreators = {
  updateOverlayBannerActive: createValSetterActionCreator(
    'isOverlayBannerActive'
  ),
  updateHeaderCollapse: createValSetterActionCreator('isHeaderCollapsed')
};

const selectors = {
  isOverlayBannerActive: (state) =>
    state?.uiClassicHeader?.isOverlayBannerActive,
  isHeaderCollapsed: (state) => state?.uiClassicHeader?.isHeaderCollapsed
};

export default new Generator('uiClassicHeader').createModel({
  initialState,
  actionCreators,
  selectors
});
