import invariant from 'invariant';

function normalizer(postcodeArea) {
  if (postcodeArea.label && postcodeArea.value) {
    return postcodeArea;
  }

  const id = postcodeArea._id || postcodeArea.id;
  const name =
    postcodeArea._text ||
    postcodeArea.text ||
    postcodeArea._value ||
    postcodeArea.value;

  invariant(
    id && name,
    "Items must have either an '_id' or 'id' and '_text' or 'text'"
  );

  return {
    label: name,
    value: {
      id,
      name
    },
    type: 'postcodeArea'
  };
}

export function normalizerNoId(postcodeArea) {
  if (postcodeArea.label && postcodeArea.value) {
    return postcodeArea;
  }

  const id = postcodeArea._id || postcodeArea.id;
  const name =
    postcodeArea._text ||
    postcodeArea.text ||
    postcodeArea._value ||
    postcodeArea.value;

  return {
    label: name,
    value: {
      id: `${name}-postcodeArea`,
      originalId: id,
      name
    },
    type: 'postcodeArea'
  };
}

export default normalizer;
