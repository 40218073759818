import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Tag } from 'components/text/tag';
import { COLORS, PADDINGS, BORDER_RADIUS } from 'src/theme';
import Close from 'assets/icons/close-medium.svg';
import Spinner from 'shared/components/spinner';

const defaultStyles = StyleSheet({
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '19px',
    width: '19px',
    fill: COLORS.PRIMARY.SLATE,
    color: COLORS.PRIMARY.SLATE
  },
  item: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: BORDER_RADIUS.TAGS,
    backgroundColor: COLORS.PRIMARY.TAG_GREY
  },
  innerContainer: {
    minWidth: 0
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  closeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: PADDINGS.XXS,
    cursor: 'pointer'
  },
  close: {
    height: '19px',
    width: '19px',
    color: COLORS.PRIMARY.SLATE
  },
  clickable: {
    cursor: 'pointer'
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none'
  }
});

@styled(defaultStyles)
@autobind
class InputTag extends Component {
  handleClick() {
    const { item, onClick } = this.props;
    onClick && onClick(item);
  }

  handleClose(e) {
    const { item, onClose } = this.props;
    e.stopPropagation();
    onClose && onClose(item);
  }

  handleMouseDown(e) {
    e.stopPropagation();
  }

  render() {
    const {
      styles: s,
      multi,
      label,
      clickable,
      disabled,
      onClose,
      Icon,
      loading
    } = this.props;
    return (
      <Box
        {...s('item', {
          clickable,
          disabled,
          multi
        })}
        style={{
          width: !multi ? '100%' : undefined,
          height: '22px',
          padding: PADDINGS['3XS']
        }}
        alignItems='center'
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
      >
        {Icon && <Box mr={PADDINGS.XXS}>{<Icon {...s('icon')} />}</Box>}
        <Box {...s('innerContainer')} flex={1} alignItems='center'>
          <Box {...s('innerContainer')} flex={1}>
            <Tag {...s('label')}>{label}</Tag>
            {loading && (
              <Box pl={'20px'}>
                <Spinner small dark />
              </Box>
            )}
          </Box>
          {onClose && !disabled && (
            <Box {...s('closeContainer')} onClick={this.handleClose}>
              <Close {...s('close')} />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default InputTag;
