/**
 * WIP: This will be the Workflow Widget component that will sit in the right bar of a record.
 *
 * Will need to create a call to fetch all the instances for this particular record and then only
 * show the last 5 recorded instances. If there are more than 5 just show 4 and then have a link to
 * show more items.
 *
 *
 *
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { ICONS } from 'shared/components/icon';
import WorkflowStatus from 'view/components/workflow/status';
import { WorkflowDetailsDialog } from 'view/dialogs/workflow';
import { withDialog } from 'shared/hocs/with-dialog';
import Widget, { TextButton } from './widget/index';
import _ from 'lodash';

@withDialog(WorkflowDetailsDialog, { propName: 'workflowDetails' })
@autobind
class WorkflowWidget extends PureComponent {
  static propTypes = {
    onAddClick: PropTypes.func.isRequired,
    onHistoryClick: PropTypes.func.isRequired,
    instances: PropTypes.array
  };

  static defaultProps = {
    instances: []
  };

  get showViewMore() {
    return this.props.instances.length > 5;
  }

  get viewMoreCount() {
    return this.props.instances.length - 4;
  }

  state = { showAllInstances: false };

  handleViewMore() {
    this.setState({ showAllInstances: true });
  }

  handleOpenWorkflowDetails(instance) {
    const { workflowDetails, removeInstance } = this.props;
    return workflowDetails.open({
      id: instance.id,
      onEndWorkflow: () => removeInstance(instance)
    });
  }

  render() {
    const { instances, onAddClick, onHistoryClick } = this.props;
    const limitedInstances =
      this.showViewMore && !this.state.showAllInstances
        ? instances.slice(0, 4)
        : instances;
    const hasVisibleWorkflows = limitedInstances.length > 0;

    return (
      <Widget
        heading={'Workflows'}
        iconButtonOnClick={onAddClick}
        iconButtonIcon={ICONS.ADD_MEDIUM_THICK}
      >
        {hasVisibleWorkflows &&
          limitedInstances.map((instance) => {
            const id = _.get(instance, 'id');
            const clickHandler = () => this.handleOpenWorkflowDetails(instance);
            return (
              <WorkflowStatus
                key={id}
                instance={instance}
                onClick={clickHandler}
              />
            );
          })}
        {!hasVisibleWorkflows && 'No pending workflows'}
        {this.showViewMore && !this.state.showAllInstances && (
          <TextButton onClick={this.handleViewMore}>
            view {this.viewMoreCount} more
          </TextButton>
        )}
        <TextButton onClick={onHistoryClick}>view history</TextButton>
      </Widget>
    );
  }
}

export default WorkflowWidget;
