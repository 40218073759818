import { contractsModel } from 'features/listings/data';
import { query } from '@rexlabs/model-generator';

export const contractsQuery = query`{
  ${contractsModel} (id: ${(p) => p.contractId}) {
    id
    related
    detail_sale_price_or_lease_pa

    commission_worksheet
    contract_status

    time_actual_offer
    time_actual_accepted
    time_actual_communicated

    date_actual_offer
    date_actual_accepted
    date_actual_communicated
    date_actual_deposit
    date_actual_fallen
    date_actual_finance
    date_actual_unconditional
    date_actual_settlement
    date_expec_unconditional
    date_expec_settlement

    fallen_reason
    agent
    purchtenant_position
    purchtenant_position_id
    purchtenant_legal_name
    purchtenant_residence
    disclosable_interest_exists
    disclosable_interest_note
    detail_finance_lender_contact
    offer_history
    vendor_details
    detail_deposit_full_or_prepayment
    purchtenant_solicitor
    purchtenant_solicitor_contact

    system_verified_unconditional_date
    system_verified_settlement_date
    system_verified_fallen_date

    detail_finance_amount
    chattels
    listing

    primary_chain_instance
    stability_indicator

    is_backup_offer
    is_primary_backup_offer
  }
}`;
