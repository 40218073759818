import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { DefaultCell, DefaultCellProps } from './default';
import { isString } from 'lodash';

// TODO: all dayjs extensions should be handled in the one place
//  https://app.shortcut.com/rexlabs/story/64206/implement-all-dayjs-extensions-in-the-one-spot
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export function DateTimeRelativeCell({ value, ...props }: DefaultCellProps) {
  const formattedValue = useMemo(() => {
    if (isString(value)) {
      return dayjs().to(dayjs(parseInt(value) * 1000));
    }

    return null;
  }, [value]);

  return <DefaultCell value={formattedValue} {...props} />;
}
