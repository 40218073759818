import { Generator } from 'shared/utils/models';
import { Id } from '@rexlabs/model-generator';

export interface AppointmentTypeCategory {
  id: Id;
  text: string;
}

const TYPE = 'appointmentTypeCategory';

export default new Generator(TYPE).createSystemListModel();
