import React, { Component } from 'react';
import DefaultRenderer, {
  FormRenderer as Renderer
} from '@rexlabs/form-renderer';
import { TextInput } from '@rexlabs/text-input';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import { RexResponsive } from 'view/components/input/text-area';
import CheckboxInput from 'view/components/input/checkbox';
import { Select, DateSelect, TimeSelect } from 'view/components/input/select';
import { Form, FormField, HiddenField } from 'view/components/form';

const LabelOverride = () => null;
const ButtonOverride = () => null;
const MultiSelectInput = (props) => <Select {...props} multi />;

class FormRenderer extends Component {
  render() {
    return (
      <Renderer
        Form={Form}
        Field={FormField}
        TextInput={TextInput}
        TextAreaInput={RexResponsive}
        CheckboxInput={CheckboxInput}
        RadioGroupInput={RadioGroupInput}
        DateInput={DateSelect}
        TimeInput={TimeSelect}
        SelectInput={Select}
        MultiSelectInput={MultiSelectInput}
        HiddenInput={HiddenField}
        {...this.props}
      />
    );
  }
}

class DefaultFormRenderer extends Component {
  render() {
    return (
      <DefaultRenderer
        Form={Form}
        Field={FormField}
        TextInput={TextInput}
        TextAreaInput={RexResponsive}
        CheckboxInput={CheckboxInput}
        RadioGroupInput={RadioGroupInput}
        DateInput={DateSelect}
        TimeInput={TimeSelect}
        SelectInput={Select}
        MultiSelectInput={MultiSelectInput}
        HiddenInput={HiddenField}
        Label={LabelOverride}
        SubmitButton={ButtonOverride}
        {...this.props}
      />
    );
  }
}

export { DefaultFormRenderer };
export default FormRenderer;
