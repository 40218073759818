import React, { PureComponent } from 'react';
import types from 'prop-types';
import { StyleSheet, styled } from '@rexlabs/styling';
import { COLORS, TEXTS, FONT } from 'theme';

@styled(
  StyleSheet({
    container: {
      backgroundColor: COLORS.PRIMARY.SAND_LIGHT,
      height: '2.4rem',
      padding: '0.5rem 1rem',
      display: 'flex',
      alignItems: 'center',
      ...TEXTS.CONTENT.DESCRIPTIVE_SUBTEXT_3
    },

    reportingContainer: {
      height: '4rem',
      paddingLeft: '1.6rem',
      paddingTop: '1.6rem',
      paddingBottom: '0.9rem',
      display: 'flex',
      alignItems: 'center',
      color: '#868E96',
      fontWeight: FONT.WEIGHTS.MEDIUM,
      fontSize: '12px',
      borderBottom: '1px solid #E0E3E8'
    },

    left: {
      justifyContent: 'flex-start'
    },
    center: {
      justifyContent: 'center'
    },
    right: {
      justifyContent: 'flex-end'
    }
  })
)
class RexHeadingCell extends PureComponent {
  static propTypes = { align: types.oneOf(['left', 'center', 'right']) };
  static defaultProps = { align: 'left' };

  render() {
    const { styles: s, children, align, reporting } = this.props;

    return (
      <div {...s(reporting ? 'reportingContainer' : 'container', align)}>
        <span>{children}</span>
      </div>
    );
  }
}

export default RexHeadingCell;
