import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { DefaultButton } from 'view/components/button';
import ImagePlaceholder from 'view/components/image-placeholder';
import { Body } from 'components/text/body';
import { Tag } from 'components/text/tag';
import { Context } from 'components/text/context';
import { PADDINGS, COLORS, UTILS } from 'src/theme';
import Spinner from 'shared/components/spinner';
import { api } from 'shared/utils/api-client';

import NoteIconPNG from 'assets/icons/note.png';
import FeedbackIconPNG from 'assets/icons/feedback.png';
import Icon, { ICONS } from 'shared/components/icon';
import Link from 'view/components/link';
import { dialogEmitter, DIALOG_ACTIONS } from 'data/emitters/dialogs';

const defaultStyles = StyleSheet({
  icon: {
    color: COLORS.PRIMARY.BLUE,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.65
    },
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    '& svg': {
      width: '20px',
      height: '18px'
    }
  },

  image: {
    objectFit: 'contain'
  },

  imageBackground: {
    background: '#E6E6E2'
  },

  imageContainer: {
    width: '100px'
  },

  isLandscape: {
    width: '129px'
  },

  heading: {
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '19px',
    marginTop: '2px',
    wordBreak: 'break-all'
  },

  secondHeading: {
    height: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    marginTop: '2px'
  },

  status: {
    color: COLORS.WHITE,
    background: COLORS.BLACK_75_OPACITY
  },

  neutral: { background: COLORS.BLACK_75_OPACITY },
  hot: { background: COLORS.INTEREST_LEVEL.HOT_SELECTED },
  warm: { background: COLORS.INTEREST_LEVEL.WARM_SELECTED },
  cold: { background: COLORS.INTEREST_LEVEL.COLD_SELECTED },

  linkStyles: {
    '& a': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  subtextEmail: {
    ...UTILS.truncate
  },
  subtext: {
    paddingBottom: PADDINGS.XXS,
    ...UTILS.truncate
  }
});

const buttonStylesOverrides = {
  Button: StyleSheet({
    container: {
      width: '100%'
    }
  })
};

@styled(defaultStyles)
@autobind
class DefaultView extends Component {
  static defaultProps = {
    isLoading: false,
    subtexts: [],
    viewLabel: 'view'
  };

  handleViewClick() {
    const { onViewClick, toggle } = this.props;
    dialogEmitter.emit(DIALOG_ACTIONS.CLOSE_ALL);
    onViewClick && onViewClick();
    toggle?.();
  }

  handleAddReminder() {
    const { onReminderClick } = this.props;
    onReminderClick &&
      onReminderClick((data) => api.post('Reminders::create', { data }));
  }

  handleAddNote() {
    const { onNoteClick } = this.props;
    onNoteClick &&
      onNoteClick((data) => {
        api.post('Notes::create', { data });
      });
  }

  handleAddFeedback() {
    const { onFeedbackClick } = this.props;
    onFeedbackClick &&
      onFeedbackClick((data) => api.post('Feedback::create', { data }));
  }

  handleSendEmail() {
    const { onEmailClick } = this.props;
    onEmailClick && onEmailClick();
  }

  render() {
    const {
      styles: s,
      image,
      toggle,
      heading,
      secondHeading,
      subtexts,
      context,
      status,
      setTagColor,
      isLoading,
      ImageIcon,
      viewPath,
      onFeedbackClick,
      onNoteClick,
      onReminderClick,
      onEmailClick,
      viewLabel,
      isLandscape,
      width
    } = this.props;

    return (
      <Box flexDirection='row' width={width}>
        <Box
          {...s('imageContainer', {
            isLandscape
          })}
          flexDirection='column'
          mr={PADDINGS.S}
        >
          <Box height='100px' mb={PADDINGS.XS} {...s('imageBackground')}>
            {image ? (
              <img
                height='100%'
                width='100%'
                src={image}
                {...s('image')}
                alt='image'
              />
            ) : (
              <ImagePlaceholder Icon={ImageIcon} />
            )}
          </Box>
          <Box {...s('linkStyles')} flexDirection='row' mb={PADDINGS.XS}>
            {/* NOTE: passing in children components to the Link seems to disable the CMD click action of Link */}
            <StylesProvider
              components={buttonStylesOverrides}
              prioritiseParentStyles={false}
            >
              <Link path={viewPath} onClick={this.handleViewClick}>
                {({ onClick }) => (
                  <DefaultButton
                    blue
                    onClick={onClick}
                    data-testid='RecordPreviewViewButton'
                  >
                    {viewLabel}
                  </DefaultButton>
                )}
              </Link>
            </StylesProvider>
          </Box>
          {!isLoading && (
            <Box flexDirection='row'>
              {!!onEmailClick && (
                <Icon
                  type={ICONS.EMAIL}
                  style={{
                    marginRight: isLandscape ? PADDINGS.XS : PADDINGS.XXS
                  }}
                  {...s('icon')}
                  onClick={this.handleSendEmail}
                />
              )}
              {!!onNoteClick && (
                // NOTE: (hehehe) this note icon does not match the old popout note icon. I have left this code here in case we want to replace the old one with the new one once Shell has taken over all of the popouts. [Same for feedback below]
                // <Icon
                //   type={ICONS.NOTE}
                //   style={{ marginRight: isLandscape ? PADDINGS.XS : PADDINGS.XXS }}
                //   {...s('icon')}
                //   onClick={this.handleAddNote}
                // />
                <img
                  height='20px'
                  width='20px'
                  src={NoteIconPNG}
                  style={{
                    marginRight: isLandscape ? PADDINGS.XS : PADDINGS.XXS
                  }}
                  {...s('image', 'icon')}
                  alt='image'
                  onClick={this.handleAddNote}
                />
              )}
              {!!onReminderClick && (
                <Icon
                  type={ICONS.REMINDER}
                  style={{
                    marginRight: isLandscape ? PADDINGS.XS : PADDINGS.XXS
                  }}
                  {...s('icon')}
                  onClick={this.handleAddReminder}
                />
              )}
              {!!onFeedbackClick && (
                // <Icon
                //   type={ICONS.FEEDBACK}
                //   {...s('icon')}
                //   style={{ marginRight: isLandscape ? PADDINGS.XS : PADDINGS.XXS }}
                //   onClick={this.handleAddFeedback}
                // />
                <img
                  style={{
                    marginRight: isLandscape ? PADDINGS.XS : PADDINGS.XXS
                  }}
                  height='20px'
                  width='20px'
                  src={FeedbackIconPNG}
                  {...s('image', 'icon')}
                  alt='image'
                  onClick={this.handleAddFeedback}
                />
              )}
            </Box>
          )}
        </Box>
        <Box width={isLandscape ? '165px' : '200px'} flexDirection='column'>
          {isLoading ? (
            <Box flex={1} justifyContent='center' alignItems='center'>
              <Spinner small dark />
            </Box>
          ) : (
            <>
              {heading && (
                <Box pb={!secondHeading && PADDINGS.XXS}>
                  <h3 {...s('heading')}>{heading}</h3>
                </Box>
              )}
              {secondHeading && (
                <Box pb={PADDINGS.XXS}>
                  <h5 {...s('secondHeading')}>{secondHeading}</h5>
                </Box>
              )}

              {subtexts &&
                subtexts.map((subtext, index) => {
                  if (typeof subtext === 'function') {
                    return (
                      <Body dark {...s('subtext')}>
                        {subtext()}
                      </Body>
                    );
                  }
                  if (typeof subtext === 'object') {
                    // eslint-disable-next-line no-prototype-builtins
                    if (subtext && subtext.hasOwnProperty('email')) {
                      return (
                        <Body dark {...s('subtextEmail')}>
                          {subtext.email}
                        </Body>
                      );
                    }
                  }
                  if (subtext) {
                    return (
                      <Box key={index} pb={PADDINGS.XXS}>
                        <Body dark {...s('subtext')}>
                          {subtext}
                        </Body>
                      </Box>
                    );
                  }
                })}

              {status && (
                <Box pb={PADDINGS.XXS} pt={PADDINGS.XS}>
                  <Box flexDirection='row'>
                    {/* not sure how to get this API to work with dark text
                    At least not without another prop,
                    since the Tag component controls whether the text is light or dark */}
                    <Box
                      {...s('status', {
                        [status]: status,
                        [setTagColor]: setTagColor
                      })}
                      p={`${PADDINGS.XXS} ${PADDINGS.XS}`}
                    >
                      <Tag light>{status}</Tag>
                    </Box>
                  </Box>
                </Box>
              )}

              {context && <Context>{context}</Context>}
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export { DefaultView };
