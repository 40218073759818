import React, { useState } from 'react';
import { isFunction } from 'lodash';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';
import { ListActionMenuProps } from '../types';
import ArrowDropdownV2 from 'src/view/components/action-menu/arrow-dropdown-v2';

const defaultStyles = StyleSheet({
  actions: {
    opacity: 0,
    transition: 'opacity .2s',
    paddingLeft: '8px',
    margin: '-4px 0',

    'tr:hover &': {
      opacity: 1
    },

    'li:hover &': {
      opacity: 1
    }
  },

  actionsActive: {
    opacity: 1
  }
});
export interface CellActionMenuProps {
  data: any;
  items: ListActionMenuProps[] | ((row) => ListActionMenuProps[]);
}

function CellActionMenuInternal({
  data,
  items,
  errorDialog
}: CellActionMenuProps & { errorDialog: ErrorDialog }) {
  const s = useStyles(defaultStyles);

  const [isOpen, setIsOpen] = useState(false);

  const actions = isFunction(items) ? items(data) : items;

  if (!actions?.length) return null;

  const permittedActions = actions
    .map((item) => ({
      ...item,
      onClick: () => {
        Promise.resolve()
          .then(() => item?.onClick?.({ data }))
          .catch((error) => errorDialog.open(error));
      }
    }))
    .filter((item) => item.hasPermission === undefined || item.hasPermission);

  return (
    <Box {...s('actions', { actionsActive: isOpen })}>
      <ArrowDropdownV2
        small
        stopPropagation
        items={permittedActions}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placement='bottom-start'
        testId='RecordListScreen.CellActionMenu'
      />
    </Box>
  );
}

export const CellActionMenu = withErrorDialog(CellActionMenuInternal);
