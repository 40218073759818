import React, { ReactElement } from 'react';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';

function RightBarWidgetLoadingSpinner(): ReactElement {
  return (
    <Box m={20} data-testid={'RightBarWidgetLoadingSpinner.Container'}>
      <Spinner dark extraSmall centered />
    </Box>
  );
}

export default RightBarWidgetLoadingSpinner;
