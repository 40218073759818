import { useMemo } from 'react';
import { useRegion } from 'src/hooks/use-region';
import { DateRangeItem } from 'features/custom-reporting/data/reporting-filters';
import {
  getDateRanges,
  getFixedPeriod
} from 'features/custom-reporting/utils/date-ranges';

export function useReportingDateValues(dateRange?: DateRangeItem) {
  const region = useRegion();

  const [start, end] = dateRange?.range || [];
  const isFixedPeriod = dateRange?.preset === 'fixed_period';

  const fixedPeriod = useMemo(
    () =>
      !!start &&
      !!end &&
      getFixedPeriod({
        fixedTo: start,
        fixedFrom: end
      }),
    [start, end]
  );

  const savedDateRange = useMemo(
    () => getDateRanges(region.code)[dateRange?.preset || ''],
    [region, dateRange?.preset]
  );

  if (dateRange?.preset && isFixedPeriod) {
    return fixedPeriod;
  }

  if (dateRange?.preset) {
    return savedDateRange;
  }
}
