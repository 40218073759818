import React from 'react';

import StreetSuburbPostcodeMultiSelect from 'view/components/input/select/street-suburb-postcode-eu';
import SuburbMultiSelect from 'view/components/input/select/suburb';
import { withRegion } from 'src/hocs/with-region';

function MatchProfileSearch({ region, ...props }) {
  return region.isEU ? (
    <StreetSuburbPostcodeMultiSelect
      key={JSON.stringify(props.initialSelectedItems)}
      {...props}
    />
  ) : (
    <SuburbMultiSelect
      key={JSON.stringify(props.initialSelectedItems)}
      {...props}
    />
  );
}

export default withRegion(MatchProfileSearch);
