import React, { Component } from 'react';
import EntityOption from 'view/components/input/select/options/entity';
import DefaultOption from 'view/components/input/select/options/default';
import User from 'assets/icons/user.svg';

import { autobind } from 'core-decorators';

@autobind
class UsersOption extends Component {
  pluckStatus() {
    return 'User';
  }

  render() {
    const { optionIsStyled } = this.props;

    if (!optionIsStyled) {
      return <DefaultOption {...this.props} />;
    }

    return (
      <EntityOption
        {...this.props}
        Icon={User}
        pluckStatus={this.pluckStatus}
      />
    );
  }
}

export default UsersOption;
