import React, { useState } from 'react';
import { isFunction } from 'lodash';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { ArrowDropdown } from 'view/components/action-menu';

import { DefaultCell, DefaultCellProps } from './default';

const defaultStyles = StyleSheet({
  actions: {
    opacity: 0,
    transition: 'opacity .2s',
    paddingLeft: '8px',
    margin: '-4px 0',

    'tr:hover &': {
      opacity: 1
    }
  },

  actionsActive: {
    opacity: 1
  }
});

interface ActionsCellProps extends DefaultCellProps {
  items?: any[] | ((row) => any[]);
  errorDialog: ErrorDialog;
}

function ActionsCellInternal({
  value,
  items,
  data,
  errorDialog,
  ...props
}: ActionsCellProps) {
  const s = useStyles(defaultStyles);
  const [isOpen, setOpen] = useState(false);

  const finalItems = isFunction(items) ? items(data) : items || [];

  return (
    <DefaultCell
      value={
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <span>{value}</span>
          {!!items?.length && (
            <Box {...s('actions', { actionsActive: isOpen })}>
              <ArrowDropdown
                small
                stopPropagation
                items={finalItems.map((item) => ({
                  ...item,
                  onClick: () => {
                    Promise.resolve()
                      .then(() => item.onClick({ data }))
                      .catch((error) => errorDialog.open(error));
                  }
                }))}
                onChange={setOpen}
              />
            </Box>
          )}
        </Box>
      }
      {...props}
    />
  );
}

export const ActionsCell = withErrorDialog(ActionsCellInternal);
