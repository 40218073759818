import { recordsBlock } from '../blocks/records-block';

export const campaignsBlocks = {
  // Default mode
  default: [
    {
      id: 'records',
      label: 'Records',
      blocks: [recordsBlock]
    }
  ]
};
