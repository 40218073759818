import React, { PureComponent, ComponentType } from 'react';
import { StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';

import { ActionMenu } from 'view/components/action-menu';
import ArrowDropdownButton from 'view/components/button/arrow-dropdown';
import { DialogTooltipConsumer } from 'view/context/tooltip';

import { ActionMenuProps } from './core';

const tetherStyles = StyleSheet({
  container: {
    zIndex: 10
  }
});

interface ArrowDropdownProps extends ActionMenuProps {
  onChange?: (isOpen: boolean) => any;
  BeforeItems?: ComponentType<any>;
  AfterItems?: ComponentType<any>;
  target?: Document;
  stopPropagation?: boolean;
}

class ArrowDropdown extends PureComponent<ArrowDropdownProps> {
  isOpen = false;

  render() {
    const {
      items,
      placement,
      onChange,
      BeforeItems,
      AfterItems,
      target,
      stopPropagation,
      ...props
    } = this.props;
    return (
      <DialogTooltipConsumer>
        {(value) => {
          return (
            <ActionMenu
              stopPropagation={stopPropagation}
              target={target || _.get(value, 'target.current')}
              items={items}
              placement={placement}
              tetherStyles={tetherStyles}
              Button={({ isOpen }) => {
                // NOTE: this is a loooong reach hack!! E.g. in thumbnails we currently
                // need to change styles depending on weather or not the action menu is
                // active (in order to get around the overflow hidden issue) ... this
                // should be removed asap once we have proper portalling in
                // @rexlabs/tether!!!
                if (onChange && isOpen !== this.isOpen) {
                  this.isOpen = isOpen;
                  onChange(isOpen);
                }
                return (
                  <ArrowDropdownButton
                    {...props}
                    padding={false}
                    active={isOpen}
                  />
                );
              }}
              distance='14px'
              offset='0px'
              shouldAutoClose={true}
              BeforeItems={BeforeItems}
              AfterItems={AfterItems}
            />
          );
        }}
      </DialogTooltipConsumer>
    );
  }
}

export default ArrowDropdown;
