import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Select, EntitySelect } from 'view/components/input/select';
import postcodesModel from 'data/models/entities/postcodes';
import addressLookupModel from 'data/models/entities/address-lookup';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {}
});

@withErrorDialog
@withModel(addressLookupModel)
@styled(defaultStyles)
@autobind
class AddressLookup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      postcode: props.postcode,
      loadingAddresses: false,
      addressOptions: [],
      address: null
    };
  }

  handlePostcodeChange({ target }) {
    const { addressLookup, errorDialog } = this.props;
    this.setState(
      {
        postcode: target.value,
        loadingAddresses: true
      },
      () => {
        if (target.value) {
          addressLookup
            .getAddresses({ postcode: target.value })
            .then(({ data }) => {
              this.setState({
                loadingAddresses: false,
                addressOptions: data.result.map((address) => ({
                  value: _.get(address, 'formats.postal_address'),
                  label: _.get(address, 'formats.single_line'),
                  data: address
                }))
              });
            })
            .catch(errorDialog.open);
        } else {
          this.setState({
            loadingAddresses: false,
            addressOptions: []
          });
        }
      }
    );
  }

  handleAddressChange({ target }) {
    this.setState({
      address: target.value
    });
  }

  handleChange(selected) {
    if (this.props.onChange) {
      this.props.onChange({
        address: selected,
        postcode: this.state.postcode
      });
    }
  }

  render() {
    const { styles: s, name } = this.props;
    return (
      <Box {...s.with('container')(this.props)}>
        <Box mb='10px'>
          <label {...s('label')}>Search postcode</label>
          <EntitySelect
            name={`${name}-postcode`}
            models={[postcodesModel]}
            onChange={this.handlePostcodeChange}
            value={this.state.postcode}
          />
        </Box>

        <label {...s('label')}>Address</label>
        <Select
          name={`${name}-address`}
          isLoading={this.state.loadingAddresses}
          onChange={this.handleAddressChange}
          onSelect={this.handleChange}
          value={this.state.address}
          disabled={!this.state.postcode}
          options={this.state.addressOptions}
        />
      </Box>
    );
  }
}

export default AddressLookup;
