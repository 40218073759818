import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { TextInput } from '@rexlabs/text-input';
import { Select } from 'view/components/input/select';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { SubText } from 'components/text/sub-text';

const defaultStyles = StyleSheet({
  inputs: {
    marginRight: PADDINGS.XS,
    maxWidth: 100
  },
  amountInput: {
    width: 70
  },
  fieldContainer: {
    width: '100%'
  }
});

@styled(defaultStyles)
@autobind
class AlertInput extends PureComponent {
  handleChange({ target }, key) {
    const { onChange, value } = this.props;
    const fakeEvent = {
      target: {
        value: {
          ...(value || {}),
          [key]: target.value
        }
      }
    };
    onChange(fakeEvent);
  }

  render() {
    const { styles: s, value, name } = this.props;
    return (
      <Box {...s('fieldContainer')} alignItems='center'>
        <Box {...s('inputs')}>
          <Select
            name={`${name}-alert-type`}
            value={_.get(value, 'alert_type')}
            options={[
              { label: 'SMS', value: 'sms' },
              { label: 'Email', value: 'email' }
            ]}
            shouldBackspaceRemove={false}
            shouldDeleteRemove={false}
            onChange={(e) => this.handleChange(e, 'alert_type')}
          />
        </Box>
        <Box {...s('inputs', 'amountInput')}>
          <TextInput
            value={_.get(value, 'alert_send_value')}
            onChange={(e) => this.handleChange(e, 'alert_send_value')}
          />
        </Box>
        <Box {...s('inputs')}>
          <Select
            name={`${name}-unit`}
            value={_.get(value, 'alert_send_unit')}
            options={[
              { label: 'Minute(s)', value: 'minutes' },
              { label: 'Hour(s)', value: 'hours' },
              { label: 'Day(s)', value: 'days' },
              { label: 'Week(s)', value: 'weeks' }
            ]}
            onChange={(e) => this.handleChange(e, 'alert_send_unit')}
            shouldBackspaceRemove={false}
            shouldDeleteRemove={false}
          />
        </Box>
        <SubText>before appointment</SubText>
      </Box>
    );
  }
}

export default AlertInput;
