import { Id, useModelActions } from '@rexlabs/model-generator';
import recordStream, {
  LatestNoteData,
  RecordStreamServiceName
} from 'data/models/custom/record-stream';
import React, { useEffect, useMemo, useState } from 'react';
import { RecordPreviewLoadingSpinner } from './record-preview-loading-spinner';
import { RecordPreviewNoteContent } from './record-preview-note-content';
import { RecordPreviewNoteDate } from './record-preview-note-date';

interface RecordPreviewLatestNoteProps {
  id: Id;
  serviceName: RecordStreamServiceName;
}

export function RecordPreviewLatestNote({
  id,
  serviceName
}: RecordPreviewLatestNoteProps) {
  const [listingNoteData, setListingNoteData] = useState<LatestNoteData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getLatestNote } = useModelActions(recordStream);

  useEffect(() => {
    if (id && getLatestNote) {
      getLatestNote({ id, serviceName }).then((data) => {
        setListingNoteData(data);
        setIsLoading(false);
      });
    }
  }, [getLatestNote, id, serviceName]);

  const { latestNote, latestNoteDate } = useMemo(() => {
    const latestNoteDate =
      listingNoteData?.date && `Note from ${listingNoteData.date}`;

    const latestNote = listingNoteData?.text;

    return { latestNoteDate, latestNote };
  }, [listingNoteData]);

  return isLoading ? (
    <RecordPreviewLoadingSpinner />
  ) : (
    <>
      <RecordPreviewNoteDate>{latestNoteDate}</RecordPreviewNoteDate>
      <RecordPreviewNoteContent>{latestNote}</RecordPreviewNoteContent>
    </>
  );
}
