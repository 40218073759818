import { useCallback } from 'react';
import { noop } from 'lodash';

import { useModelActions } from '@rexlabs/model-generator';

import { LeadDialogProps } from 'utils/dialogs/mapping-classic-interfaces';

import { useDialog } from 'hooks/use-dialog';

import leadsModel from 'data/models/entities/leads';
import { SystemOptionObj } from 'data/models/system-lists/types';
import { UserItem } from 'data/models/value-lists/account-users';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import { stripPrefixFromProperties } from 'shared/utils/prefix-hell';
import { useErrorDialog } from 'hooks/use-error-dialog';

interface EditLeadRecord {
  id: number;
  address: string;
}
interface EditLeadFormData {
  assignee: UserItem;
  contact: { id: string; name: string };
  lead_source: SystemOptionObj;
  lead_type: SystemOptionObj;
  listing: EditLeadRecord | null;
  note: string | null;
  project: EditLeadRecord | null;
  project_stage: EditLeadRecord | null;
  property: EditLeadRecord | null;
  id: string;
  related: { [key: string]: any };
}

export function useLeadDialog({
  options = {},
  id,
  callback = noop
}: LeadDialogProps) {
  const editLead = useDialog('editLead');
  const errorDialog = useErrorDialog();

  const { updateItem, createItem } = useModelActions(leadsModel);
  const leadAction = id ? updateItem : createItem;

  return useCallback(() => {
    return editLead?.open({
      id,
      options,
      callback: async (leadData) => {
        try {
          const cleanData: EditLeadFormData =
            stripPrefixFromProperties(leadData);

          const updatedLeadData = await leadAction({
            id,
            data: cleanData
          }).then(callback);

          Analytics.track({
            event: EVENTS.LEADS_DIALOG.LEAD_EDITED,
            properties: {
              lead_type: cleanData?.lead_type.text
            },
            options: { integrations: { Intercom: true } }
          });

          return updatedLeadData;
        } catch (error) {
          return errorDialog?.open(error as any);
        }
      }
    });
  }, [callback, editLead, errorDialog, id, leadAction, options]);
}
