import React from 'react';
import { Iframe } from './iframe';
import { FONT } from 'shared/theme';

type IframeSandboxedProps = React.ComponentProps<typeof Iframe> & {
  html: string;
};

export function IframeSandboxed({ html, ...props }: IframeSandboxedProps) {
  return (
    <Iframe
      sandbox={'allow-same-origin allow-popups allow-popups-to-escape-sandbox'}
      referrerPolicy='origin'
      {...props}
    >
      <>
        <style type={'text/css'}>
          {`
        body, html { 
          margin: 0; 
          padding: 0;
        }
        body { 
          font-family: ${FONT.FAMILY.PROXIMA_NOVA}; 
          font-size: ${FONT.SIZES.REGULAR}; 
        }` +
            /* 
        HACK: force elements to have a max-width to avoid having email content cut off. This is to solve for:
        https://rexsoftware.atlassian.net/browse/RADI-3125.
        Solution copied from the classic implementation here: classic/public/assets/js/dialogs/leads/process.js
        An alternative might be to introduce horizontal scrolling to the iframe.
        */
            `
        body div, body table, body tbody, body td { 
          max-width: 560px !important;
        }
        `}
        </style>
        <base target='_blank' />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </>
    </Iframe>
  );
}
