import { useMemo } from 'react';
import { useModelActions } from '@rexlabs/model-generator';
import { ColumnsConfig } from 'components/record-list-screen/types';
import { IdCell } from 'components/record-list-screen/cells/id-cell';
import { useDialog } from 'hooks/use-dialog';
import { TenancyApplicationItem } from '../data/types';
import { ListingCell } from 'components/record-list-screen/cells/listing-cell';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';
import dayjs from 'dayjs';
import tenancyApplicationsModel from '../data/tenancy-applications';
import { MultiContactCell } from 'components/record-list-screen/cells/multi-contact-cell';
import { useGoToRelated } from 'hooks/use-goto-related-record';

const service_name = 'TenancyApplications';

export function useApplicationsColumns() {
  const { refreshLists } = useModelActions(tenancyApplicationsModel);
  const region = useRegion();
  const currencySymbol = region.financial.currency.symbol;
  const goToRelated = useGoToRelated({ service_name });
  const editApplication = useDialog('addEditApplication');

  const rowActions = useMemo(() => {
    return [
      {
        label: 'View / Edit',
        onClick: ({ data }) =>
          editApplication.open({
            application: data,
            onApplicationChange: refreshLists
          })
      },
      {
        label: 'View Related',
        onClick: ({ data }) =>
          goToRelated({
            selectionCriteria: [{ name: 'id', type: 'in', value: [data.id] }]
          })
      }
    ];
  }, [editApplication, refreshLists, goToRelated]);

  return useMemo(
    (): ColumnsConfig<TenancyApplicationItem> => [
      {
        label: 'ID',
        id: 'id',
        selector: (row) => row.id,
        Cell: IdCell
      },
      {
        label: 'Listing',
        id: 'listing',
        selector: (row) => row.listing,
        Cell: ListingCell,
        cellProps: { items: rowActions }
      },
      {
        label: 'Agent',
        id: 'agent',
        selector: (row) => row.agent?.name
      },
      {
        label: 'Offer',
        id: 'offer',
        selector: (row) =>
          currencySymbol +
          formatCurrency(row.offer_amount) +
          ' ' +
          row.offer_amount_period?.text.toLowerCase().replace('per ', 'p/')
      },
      {
        label: 'Status',
        id: 'status',
        selector: (row) => row.application_status?.text,
        hidden: true
      },
      {
        label: 'Start Date',
        id: 'start_date',
        selector: (row) =>
          row.start_date ? dayjs(row.start_date).format('D MMM YYYY') : '',
        hidden: true,
        sortable: true
      },
      {
        label: 'End Date',
        id: 'end_date',
        selector: (row) =>
          row.end_date ? dayjs(row.end_date).format('D MMM YYYY') : '',
        hidden: true,
        sortable: true
      },
      {
        label: 'Agreement Length',
        id: 'agreement_length_months',
        selector: (row) =>
          row.agreement_length_months !== null
            ? `${row.agreement_length_months} months`
            : '-',
        hidden: true,
        sortable: true
      },
      {
        label: 'Lease Type',
        id: 'lease_type',
        selector: (row) => row.lease_type?.text,
        hidden: true
      },
      {
        label: 'Received Date',
        id: 'date_received',
        selector: (row) =>
          row.date_received
            ? dayjs(row.date_received).format('D MMM YYYY')
            : '',
        hidden: true,
        sortable: true
      },
      {
        label: 'Communicated Date',
        id: 'date_communicated',
        selector: (row) =>
          row.date_communicated
            ? dayjs(row.date_communicated).format('D MMM YYYY')
            : '',
        hidden: true,
        sortable: true
      },
      {
        label: 'Accepted Date',
        id: 'date_accepted',
        selector: (row) =>
          row.date_accepted
            ? dayjs(row.date_accepted).format('D MMM YYYY')
            : '',
        hidden: true,
        sortable: true
      },
      {
        label: 'Tenants',
        id: 'tenants',
        selector: (row) =>
          row.related.listing_application_tenants.map(
            (tenant) => tenant.contact
          ),
        Cell: MultiContactCell,
        hidden: true
      }
    ],
    [rowActions, currencySymbol]
  );
}
