import { useEffect, useState, useRef, useCallback } from 'react';
import { Canceler } from 'axios';
// TODO - get a version of api-client with a later version of axios
//  so that we can use just use the AbortController web api
//  https://rexsoftware.atlassian.net/browse/RADI-5516
import { CancelToken } from '@rexlabs/api-client';
import { api } from 'shared/utils/api-client';
import { useStabilizedObject } from 'hooks/use-stabilized-object';

import { Criteria } from 'types/criteria';

export type DefaultResultFormat = Record<string, any>;

export type GraphqlCriteria = Omit<Criteria, 'value'> & {
  value_as_json?: string;
};

export interface UseGraphqlQueryArgs {
  query: string;
  variables: Record<string, any>;
}

export type QueryStatus = 'ready' | 'loading';

export function makeGraphqlQuery<ResultFormat = DefaultResultFormat>({
  query,
  variables,
  cancelFunctionRef
}): Promise<ResultFormat> {
  return api
    .post(
      'Graph::graphql',
      {
        query,
        variables
      },
      {
        cancelToken: new CancelToken((providedCancel: Canceler) => {
          cancelFunctionRef.current = providedCancel;
        })
      }
    )
    .then((response) => response.data.data);
}

/**
 * Note this is a fairly basic implementation which serves our immediate use cases for reporting.
 * Some potential improvements if we use graphql elsewhere:
 *
 * - Persisting the data in redux
 * - Specifying noFetch in order to support imperative querying
 *
 */

export function useGraphQlQuery<ResultFormat = DefaultResultFormat>({
  query,
  variables
}: UseGraphqlQueryArgs): {
  data: ResultFormat | null;
  status: QueryStatus;
  reload: () => Promise<void | ResultFormat>;
} {
  const [data, setData] = useState<ResultFormat | null>(null);
  const [status, setStatus] = useState<'ready' | 'loading'>('ready');
  const cancelFunctionRef = useRef<Canceler | null>(null);
  // we want to make sure that we only re-query when the variables REALLY change
  const stabilizedQueryVariables = useStabilizedObject(variables);

  const doQuery = useCallback(() => {
    if (status === 'loading') {
      cancelFunctionRef.current?.('Cancel any previous request');
    }
    setStatus('loading');
    return makeGraphqlQuery<ResultFormat>({
      query,
      variables: stabilizedQueryVariables,
      cancelFunctionRef
    })
      .then((data) => {
        setData(data);
        setStatus('ready');
        return data;
      })
      .catch((e) => {
        if (e?.problem === 'CANCEL_ERROR') {
          // Do nothing, we cancelled in favour of an incoming request
        } else {
          throw e;
        }
      });
  }, [query, stabilizedQueryVariables]);

  useEffect(() => {
    doQuery();
  }, [doQuery]);

  return {
    data,
    status,
    reload: doQuery
  };
}

export function gql(...strings) {
  return strings.join('');
}
