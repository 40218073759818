import React, { Children, Fragment } from 'react';
import Box, { BoxProps } from '@rexlabs/box';

import { PADDINGS } from 'src/theme';
import Spinner from './spinner';

interface ButtonBarProps extends BoxProps {
  children: JSX.Element[];
  isLoading?: boolean;
  hasPadding?: boolean;
}

function ButtonBar({
  children,
  isLoading,
  hasPadding = true,
  ...rest
}: ButtonBarProps) {
  return (
    <Box
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      height='34px'
      mt={hasPadding ? '15px' : 0}
      {...rest}
    >
      {isLoading ? (
        <Fragment>
          <span />
          <Box ml={PADDINGS.S} mr={PADDINGS.S}>
            <Spinner />
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Box style={{ display: 'inline-flex' }} alignItems='center'>
            {Children.map(children, (child) => {
              return (
                child &&
                child.props &&
                child.props.left && <Box mr={PADDINGS.XXS}>{child}</Box>
              );
            })}
          </Box>
          <Box style={{ display: 'inline-flex' }} alignItems='center'>
            {Children.map(
              children,
              (child) =>
                child &&
                child.props &&
                !child.props.left && <Box ml={PADDINGS.XXS}>{child}</Box>
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
}

export default ButtonBar;
