import React, { Component } from 'react';
import _ from 'lodash';
import Fixture from './default';
import { autobind } from 'core-decorators';

@autobind
class AgencyFixture extends Component {
  handleFixtureClick(propsSnapShot) {
    const { onClick } = this.props;

    onClick(_.get(propsSnapShot, 'option.fixturePrefillData.callback'));
  }

  render() {
    return (
      <Fixture addNewEntity={this.handleFixtureClick}>ADD NEW AGENCY</Fixture>
    );
  }
}

export default AgencyFixture;
