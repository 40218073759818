import { api } from 'shared/utils/api-client';

const documentUploader = (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    api
      .post('Upload::uploadFile', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default documentUploader;
