import { Activity } from 'data/models/entities/activities-model';

export function getActivityStatus(action: string, activitiesList?: Activity[]) {
  if (activitiesList?.length) {
    return activitiesList?.find((activity) =>
      activity?.related?.activity_outcomes?.find?.(
        (outcome) => outcome?.type?.id === action
      )
    );
  }
}
