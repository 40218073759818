import React, { Component } from 'react';

import _ from 'lodash';

import { Popout } from 'view/components/popout';
import { DefaultView } from 'view/components/tooltip/entity';

import { autobind } from 'core-decorators';
import PaddingBox from 'view/components/padding-box';
import { ICONS } from 'shared/components/icon';

@autobind
class CampaignTooltipCore extends Component {
  renderContent() {
    const { data, id } = this.props;
    const { name } = data;

    const type = _.get(data, 'type.text');
    const status = _.get(data, 'status.text');

    return (
      <PaddingBox white>
        <DefaultView
          width={310}
          heading={name || 'Campaign'}
          subtexts={[type]}
          status={status}
          ImageIcon={ICONS.CAMPAIGN}
          viewPath={`/campaigns/#id=${id}`}
        />
      </PaddingBox>
    );
  }

  render() {
    return (
      <Popout
        {...this.props}
        distance={'11px'}
        Content={this.renderContent}
        placement='left'
      />
    );
  }
}

export default CampaignTooltipCore;
