import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { COLORS, PADDINGS } from 'theme';

import { ICONS } from 'shared/components/icon';

import { WrapperButton } from 'components/button/wrapper-button';

const FavouriteOutlineSvg: any = ICONS.STAR_OUTLINE_18;
const FavouriteSvg: any = ICONS.STAR_18;

const defaultStyles = StyleSheet({
  favouriteButton: {
    color: COLORS.PRIMARY.INFORMATIVE,

    '&:hover': {
      color: COLORS.PRIMARY.SAND_HOVER
    }
  },

  favouriteButtonActive: {
    color: COLORS.PRIMARY.BLUE,

    '&:hover': {
      color: COLORS.STATES.BLUE_HOVER
    }
  }
});

interface FavouriteButtonProps {
  toggleFavourite: (args?: any) => any;
  isFavourite: boolean;
}

export function SavedListItemFavouriteButton(props: FavouriteButtonProps) {
  const { isFavourite, toggleFavourite } = props;

  const s = useStyles(defaultStyles);

  function handleToggleFavourite(e) {
    e.preventDefault();
    e.stopPropagation();
    toggleFavourite();
  }

  return (
    <WrapperButton onClick={handleToggleFavourite}>
      <Box
        w={18}
        h={18}
        {...s('favouriteButton', {
          favouriteButtonActive: isFavourite
        })}
      >
        {isFavourite ? <FavouriteSvg /> : <FavouriteOutlineSvg />}
      </Box>
    </WrapperButton>
  );
}
