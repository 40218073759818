import React, { PureComponent, Fragment } from 'react';
import Dialog from 'view/components/dialog';
import { ReactForms, Form, FormField } from 'view/components/form';

import { TextInput } from '@rexlabs/text-input';
import Checkbox from 'view/components/input/checkbox';
import { Select, DateSelect } from 'view/components/input/select';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { createValidationRules } from 'shared/utils/form';
import { autobind } from 'core-decorators';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

import { Grid, Column } from 'shared/components/grid';
import { addOrdinalSuffix } from 'shared/utils/formatters';
import dayjs from 'dayjs';
import { RRule } from 'rrule';
import _ from 'lodash';

const frequencyMap = {
  daily: RRule.DAILY,
  weekly: RRule.WEEKLY,
  monthly: RRule.MONTHLY,
  yearly: RRule.YEARLY
};

const dayMap = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU
};

export function mapFormToRRUle(values, startsAt) {
  const rule = new RRule({
    freq: frequencyMap[_.get(values, 'frequency.id')],
    interval: values.interval,
    dtstart: dayjs(startsAt).toDate(),
    until: values.repeats_until ? dayjs(values.repeats_until).toDate() : null,
    bymonthday: values.recurs_on_month_day,

    // Convert any day strings into RRule day objects
    byweekday: values.recurs_every_weekday
      ? _.map(values.recurs_every_weekday, (day) => dayMap[day])
      : _.map(values.recurs_on_weekday, (day) => dayMap[day])
  });
  return rule;
}

export function mapRRuleToForm(rruleString) {
  if (!rruleString) return null;

  // The string sent by the backend includes the "DTEND" property, which
  // is technically part of the spec, but not supported by our JS parsing
  // library. So we exclude it.
  rruleString = rruleString
    .split(';')
    .filter((segment) => segment.includes('DTEND') === false)
    .join(';');

  const rule = RRule.parseString(rruleString || '');

  const formData = {
    interval: rule.interval,
    frequency: { id: _.findKey(frequencyMap, (v) => v === rule.freq) },
    show_not_after: !!rule.until,
    // Normalising the format as it comes back differently from the server
    repeats_until: rule.until ? dayjs(rule.until) : null,
    recurs_on_weekday: rule.byweekday,
    recur_on: rule.bymonthday
      ? 'day_of_month'
      : rule.byweekday
      ? 'day_of_week'
      : null,
    recurs_every_interval: rule.interval,
    recurs_every_weekday:
      _.isArray(rule.byweekday) || !rule.byweekday
        ? rule.byweekday
        : [rule.byweekday],
    recurs_on_month_day:
      _.isArray(rule.bymonthday) || !rule.byweekday
        ? rule.bymonthday
        : [rule.bymonthday]
  };

  return formData;
}

const validate = createValidationRules({
  interval: 'required|integer',
  frequency_id: 'required',
  recurs_on_weekday: 'required',
  recur_on: 'required',
  recurs_on_month_day: 'required',
  recurs_every_interval: 'required',
  recurs_every_weekday: 'required',
  repeats_until: 'required'
});

const unitOptions = [
  {
    value: 'daily',
    label: 'Day(s)'
  },
  {
    value: 'weekly',
    label: 'Week(s)'
  },
  {
    value: 'monthly',
    label: 'Month(s)'
  },
  {
    value: 'yearly',
    label: 'Year(s)'
  }
];

const weekdayOptions = [
  {
    value: 'MO',
    label: 'Monday'
  },
  {
    value: 'TU',
    label: 'Tuesday'
  },
  {
    value: 'WE',
    label: 'Wednesday'
  },
  {
    value: 'TH',
    label: 'Thursday'
  },
  {
    value: 'FR',
    label: 'Friday'
  },
  {
    value: 'SA',
    label: 'Saturday'
  },
  {
    value: 'SU',
    label: 'Sunday'
  }
];

const monthRecurOptions = [
  {
    value: 'day_of_month',
    label: 'Day of the Month'
  },
  {
    value: 'day_of_week',
    label: 'Day of the Week'
  }
];

const dayOfMonthOptions = [
  {
    value: 1,
    label: 'First Day of Every Month'
  },
  {
    value: -1,
    label: 'Last Day of Every Month'
  },
  ...Array.from(Array(30)).map((x, i) => ({
    value: i + 2,
    label: addOrdinalSuffix(i + 2)
  }))
];

const dayOfWeekOptions = [
  {
    value: '1',
    label: 'First'
  },
  {
    value: '2',
    label: 'Second'
  },
  {
    value: '3',
    label: 'Third'
  },
  {
    value: '4',
    label: 'Fourth'
  }
];

@withErrorDialog
@autobind
class RecurAppointmentDialog extends PureComponent {
  getInitialValues() {
    const { rule } = this.props;
    return {
      ...rule,
      show_not_after: !!_.get(rule, 'repeats_until'),
      repeats_until: _.get(rule, 'repeats_until')
        ? dayjs(_.get(rule, 'repeats_until')).format('YYYY-MM-DD')
        : null,
      recur_on: _.get(rule, 'recurs_every_weekday')
        ? 'day_of_week'
        : _.get(rule, 'frequency.id') === 'monthly'
        ? 'day_of_month'
        : null,
      frequency_id: _.get(rule, 'frequency.id')
    };

    // if (!rule) {
    //   return {
    //     frequency_id: 'daily'
    //   };
    // }
    //
    // const repeatsUntil = this.getRepeatsUntilValue(
    //   _.get(rule, 'repeats_until')
    // );
    //
    // return {
    //   interval: _.get(rule, 'interval'),
    //   frequency_id: _.get(rule, 'frequency.id', 'daily'),
    //   show_not_after: !_.get(rule, 'is_infinite'),
    //   // Normalising the format as it comes back differently from the server
    //   repeats_until: repeatsUntil,
    //   recurs_on_weekday: _.get(rule, 'recurs_on_weekdays.0'),
    //   recur_on:
    //     _.get(rule, 'frequency.id') === 'monthly'
    //       ? (_.get(rule, 'recurs_every') || []).length
    //         ? 'day_of_week'
    //         : 'day_of_month'
    //       : null,
    //   recurs_every_interval: _.get(rule, 'recurs_every.0'),
    //   recurs_every_weekday: _.get(rule, 'recurs_every.1'),
    //   recurs_on_month_day: _.get(rule, 'recurs_on_month_day')
    // };
  }

  getRepeatsUntilValue(rule) {
    if (!rule) {
      return null;
    }

    return (dayjs.unix(rule).isValid() ? dayjs.unix(rule) : dayjs(rule)).format(
      'YYYY-MM-DD'
    );
  }

  handleSubmit(values) {
    const { callback, closeDialog, errorDialog } = this.props;

    const recurData = {
      interval: values.interval,
      frequency: { id: values.frequency_id },
      is_infinite: !values.repeats_until,
      repeats_until: values.show_not_after ? dayjs(values.repeats_until) : null,
      recurs_on_weekday:
        values.frequency_id === 'weekly' ? [values.recurs_on_weekday] : null,
      recurs_every_interval:
        values.recur_on === 'day_of_week' ? values.recurs_every_interval : null,
      recurs_every_weekday:
        values.recur_on === 'day_of_week'
          ? !Array.isArray(values.recurs_every_weekday)
            ? [values.recurs_every_weekday]
            : values.recurs_every_weekday
          : null,
      recurs_on_month_day:
        values.frequency_id === 'monthly' && values.recur_on === 'day_of_month'
          ? values.recurs_on_month_day
          : null
    };

    return Promise.all([callback ? callback(recurData) : null])
      .then(() => closeDialog())
      .catch(errorDialog.open);
  }

  render() {
    const { closeDialog, onLoad } = this.props;
    return (
      <Dialog
        title='Recur Appointment'
        closeDialog={closeDialog}
        onLoad={onLoad}
        height={400}
        width={350}
      >
        <ReactForms
          handleSubmit={this.handleSubmit}
          initialValues={this.getInitialValues()}
          validate={validate}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form>
              <Grid mt={-10}>
                <Column width={5}>
                  <FormField
                    name='interval'
                    label='recur every'
                    Input={TextInput}
                  />
                </Column>
                <Column width={7}>
                  <FormField
                    name='frequency_id'
                    label='&nbsp;'
                    Input={Select}
                    inputProps={{ options: unitOptions }}
                  />
                </Column>
              </Grid>

              {values.frequency_id === 'weekly' && (
                <FormField
                  name='recurs_on_weekday'
                  label='recur on'
                  Input={Select}
                  inputProps={{ options: weekdayOptions }}
                />
              )}

              {values.frequency_id === 'monthly' && (
                <Fragment>
                  <FormField
                    name='recur_on'
                    label='recur on'
                    Input={Select}
                    inputProps={{ options: monthRecurOptions }}
                  />
                  {values.recur_on === 'day_of_month' && (
                    <FormField
                      name='recurs_on_month_day'
                      label='which day of the month'
                      Input={Select}
                      inputProps={{ options: dayOfMonthOptions }}
                    />
                  )}
                  {values.recur_on === 'day_of_week' && (
                    <Grid>
                      <Column width={6}>
                        <FormField
                          name='recurs_every_interval'
                          label='which day of the week'
                          Input={Select}
                          inputProps={{ options: dayOfWeekOptions }}
                        />
                      </Column>
                      <Column width={6}>
                        <FormField
                          name='recurs_every_weekday'
                          label='&nbsp;'
                          Input={Select}
                          inputProps={{ options: weekdayOptions }}
                        />
                      </Column>
                    </Grid>
                  )}
                </Fragment>
              )}

              <FormField
                name='show_not_after'
                Input={Checkbox}
                inputProps={{ label: 'do not recur after set date' }}
                sendImmediate
              />
              {!!values.show_not_after && (
                <FormField
                  sendImmediate
                  name='repeats_until'
                  Input={DateSelect}
                />
              )}
              <ButtonBar isLoading={isSubmitting}>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton light onClick={submitForm}>
                  Done
                </DefaultButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default RecurAppointmentDialog;
