import React from 'react';
import { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import { IconTooltip } from 'view/components/tooltip';

type AlertToolTipProps = React.ComponentProps<typeof IconTooltip>;

export default function AlertTooltip(props: AlertToolTipProps) {
  const { small } = props;
  return (
    <IconTooltip
      {...props}
      color={COLORS.PRIMARY.YELLOW}
      TooltipIcon={small ? ICONS.ALERT_SMALL : ICONS.ALERT}
    />
  );
}
