import { useEffect } from 'react';
import { useModelActions, useModelState } from '@rexlabs/model-generator';
import { GeneralSystemListModel } from 'shared/utils/models';
import sessionModel from 'data/models/custom/session';
import { SelectItem } from 'data/models/value-lists/account-users';

export interface ValueListState {
  items: SelectItem[];
  status: 'loading' | 'loaded' | 'error';
}

type ValueListFetched = Record<GeneralSystemListModel['modelName'], string>;

const valueListCache: ValueListFetched = {};

export function useValueList(model: GeneralSystemListModel) {
  const valueListActions = useModelActions(model);
  const session = useModelState(sessionModel);
  const accountId = session.office_details?.id;
  // TODO: type useModelState correctly or make adjustments to SystemListModel type
  //  to infer state type properly
  // JIRA: https://rexsoftware.atlassian.net/browse/RADI-6130
  const valueListState = useModelState(model) as ValueListState;

  useEffect(() => {
    // Null status occurs when the list hasn't started loading
    if (
      !valueListState.status &&
      valueListCache[model.modelName] !== accountId
    ) {
      valueListActions?.fetch?.();

      valueListCache[model.modelName] = accountId;
    }
  }, [accountId]);
  return valueListState;
}
