import _ from 'lodash';
import { Region } from 'hooks/use-region';
import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';
import {
  CustomField,
  getCustomFieldColumns
} from 'features/custom-reporting/utils/custom-field-utils';

export const projectFields = ({
  region,
  customFields,
  subresource
}: {
  customFields?: Record<string, CustomField>;
  subresource?: string;
  region?: Region;
}): ReportColumnConfig => {
  const customFieldColumns = getCustomFieldColumns({
    customFields,
    customFieldResource: subresource || 'project'
  });
  return [
    {
      headerName: 'Project ID',
      field: 'id'
    },
    {
      headerName: 'Name',
      colId: 'project_name',
      type: 'projectRecordColumn'
    },
    {
      headerName: 'Type',
      field: 'type.text',
      type: 'valueListColumn',
      filterParams: {
        values: getAsyncFilterValues({ listName: 'project_type' })
      }
    },
    { headerName: 'Size', field: 'size.id', type: 'numberColumn' },
    {
      headerName: 'Single Building',
      field: 'is_single_building',
      type: 'booleanColumn'
    },
    ...(region?.isEU
      ? [
          {
            headerName: 'Address',
            colId: 'adr_address',
            valueGetter: ({ data, colDef, node }) => {
              const recordData = _.get(data, colDef.field) || data;

              const addressParts = [
                recordData.adr_street_number,
                recordData.adr_street_name,
                recordData.adr_suburb_or_town,
                recordData.adr_postcode
              ].filter(Boolean);

              if (!addressParts.length) {
                return getEmptyValue({ node });
              }

              return addressParts.join(' ');
            }
          },
          { headerName: 'Street name', field: 'adr_street_name' },
          { headerName: 'Locality', field: 'locality' },
          { headerName: 'Town/city', field: 'adr_suburb_or_town' },
          { headerName: 'Postcode', field: 'adr_postcode' }
        ]
      : [
          {
            headerName: 'Address',
            colId: 'adr_address',
            valueGetter: ({ data, node, colDef }) => {
              const recordData = _.get(data, colDef.field) || data;

              if (!recordData) {
                return getEmptyValue({ node });
              }

              const addressParts = [
                recordData.adr_street_number,
                recordData.adr_street_name,
                recordData.adr_suburb_or_town
              ].filter(Boolean);

              if (!addressParts.length) {
                return getEmptyValue({ node });
              }

              return addressParts.join(' ');
            }
          },
          { headerName: 'Street name', field: 'adr_street_name' },
          { headerName: 'Suburb', field: 'adr_suburb_or_town' }
        ]),
    { headerName: 'Developer name', field: 'developer_name' },
    { headerName: 'Price list disclaimer', field: 'price_list_disclaimer' },
    { headerName: 'Terms of sale', field: 'terms_of_sale' },
    { headerName: 'Created Date', field: 'system_ctime', type: 'dateColumn' },
    {
      headerName: 'Created by',
      field: 'system_created_user.name',
      type: 'agentValueListColumn'
    },
    {
      headerName: 'Record Owner',
      field: 'system_owner_user.name',
      type: 'agentValueListColumn',
      subresource: 'record_owner'
    },
    {
      headerName: 'Last Modified',
      field: 'system_modtime',
      type: 'dateColumn'
    },
    ...customFieldColumns
  ];
};
