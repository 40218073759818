const __TEST__ = process.env.NODE_ENV === 'test';

const { Proxy, Reflect, __DEV__ } = window;
const canUseProxy = !!(Proxy && Reflect);

const errorAccess = 'Attempted to access an object in a torn-down realm.';
const errorCall = 'Attempted to call a function in a torn-down realm.';

const wrappers = new Set();

if (__DEV__ && !canUseProxy) {
  console.warn('What? No Proxy? Why are you developing using this browser?');
}

function captureStack() {
  const stack = new Error().stack;
  return stack
    .replace(/^Error\s*/, '')
    .replace(/\n\s*/g, '\n')
    .split('\n');
}

function needsProxy(value) {
  // We have a simple check here as we are unable to test two JS realms using Jest & JSDom.
  // The "!(value instanceof Object)" is useded to determine if the value is from another JS realm
  // as each realm has its own Object constructor so they are different instances.
  if (__TEST__) {
    return value && /^(function|object)$/.test(typeof value);
  }

  return (
    value &&
    /^(function|object)$/.test(typeof value) &&
    !(value instanceof Object)
  );
}

export function clearBridgeProxies() {
  if (!canUseProxy) {
    return;
  }
  wrappers.forEach((wrapper) => {
    if (Array.isArray(wrapper)) {
      wrapper.splice(0, wrapper.length);
    } else if (typeof wrapper === 'object') {
      // Ensure that any properties that have been copied from the target to
      // the wrapper are nulled. Most properties in the wrapper will be null,
      // but some - like `style` - might be non-null - see below for more
      // information.
      Object.keys(wrapper).forEach((key) => {
        wrapper[key] = null;
      });
    }
    wrapper.__cleared = true;
    wrapper.__target = null;
  });
  if (__DEV__) {
    // eslint-disable-next-line
    console.log(`Clearing ${wrappers.size} bridge proxy(ies)`);
  }
  wrappers.clear();
}

class BridgeProxyMarker {}

export function isBridgeProxied(target) {
  return target && target.__marker instanceof BridgeProxyMarker;
}

export function createBridgeProxy(target) {
  if (!canUseProxy || !needsProxy(target)) {
    return target;
  }

  if (isBridgeProxied(target)) {
    return target;
  }

  let wrapper;
  if (Array.isArray(target)) {
    wrapper = Array(target.length).fill(undefined);
  } else if (typeof target === 'function') {
    wrapper = function (...args) {
      // eslint-disable-next-line
      return wrapper.__target.apply(this, args);
    };
  } else {
    // Create a wrapper that contains properties for all of the keys in the
    // actual target. Set the values to null. Unless a wrapper property is
    // specifically assigned a non-null value, the proxy handler will retrieve
    // the value from the actual target.
    //
    // There are a number of invariants that are enforced for proxy targets, so
    // the wrapper handles some proxied calls itself and does not forward to
    // the actual target. E.g. the getOwnPropertyDescriptor handler does not
    // reflect using the actual target. This is the reason all of the
    // properties are added, even though they are set to null. If the property
    // names weren't added, some language features - like `hasOwnProperty`
    // would not work as expected with the proxy instance.
    //
    // For more information, see:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/defineProperty#Invariants
    wrapper = {};
    Object.keys(target).forEach((key) => {
      wrapper[key] = null;
    });

    // eslint-disable-next-line
    if (target.hasOwnProperty('style')) {
      // In development mode, React freezes `style` props:
      //
      // https://github.com/facebook/react/blame/66f280c87b05885ee55320a5e107a534a50f9375/packages/react-dom/src/client/ReactDOMComponent.js#L314-L320
      // https://github.com/facebook/react/issues/11520#issuecomment-343731614
      //
      // Spread the properties within the style into a non-frozen object so
      // that they can be nulled when the proxies are cleared. BTW, don't
      // bother calling Object.isFrozen, it - or, more likely, its polyfill -
      // returns false.
      //
      // Store the spread, no-longer-frozen style property in the wrapper,
      // non-null properties in the wrapper will be used in preference to
      // properties in the actual target.
      const style = target.style;
      if (style && typeof style === 'object' && !Array.isArray(style)) {
        wrapper.style = { ...style };
      }
    }
  }

  /**
   * The wrapper Proxy.
   *
   * @typedef {Object} WrapperProxy
   * @property {boolean} __cleared Whether the Proxy has been reset or is still valid.
   * @property {Object} __marker A marker to identify proxied values. Also useful for locating proxied values on the DevTools memory tab.
   * @property {Object} __stack The error stack at time of accessing the property on the Proxy.
   * @property {Object} __target The orginal value that has now been wrapped with a Proxy.
   * @property {Object} __proxies Used to cache the Proxied value to improve performance.
   */
  Object.defineProperties(wrapper, {
    __cleared: {
      configurable: false,
      enumerable: false,
      value: false,
      writable: true
    },
    __marker: {
      configurable: false,
      enumerable: false,
      value: new BridgeProxyMarker(),
      writable: false
    },
    __stack: {
      configurable: false,
      enumerable: false,
      value: __DEV__ ? captureStack() : null,
      writable: false
    },
    __target: {
      configurable: false,
      enumerable: false,
      value: target,
      writable: true
    },
    __proxies: {
      configurable: false,
      enumerable: false,
      value: {},
      writable: false
    },
    // TL;DR: The proxy nulls out the value property on objects going through it
    //        then overriding the getter to grab values from a custom property `__target`.
    //        Therefore, logging the entire proxy will show the value incorrectly,
    //        but using the property accessors will still give the correct values.

    // The background of this proxy and the way it works appears to be related to memory leak issues
    // when sharing data between shell and classic via bridge.
    // The proxy is written to allow objects to clear themselves of data when the user navigates away from
    // the components where they are used.
    // Without knowing the details, it seems that this is achieved by clearing the value of objects that
    // run through the bridge, and changing the property accessors to read from a custom `__target` property.
    // As a result, logging the entire proxy will show a value that is nulled out, however if you log (or use)
    // an individual property it will appear correctly.
    // Keep in mind that this is recursive, so in the case of nested objects/arrays, you will need to log all the way down
    // the chain (or access the `__target` property instead of reading the value normally).
    __dontLogTheWholeProxyItWillNotShowYouTheCorrectValues: {
      value: 'See the file bridge-proxy.js:line 144, for more details',
      writable: false
    }
  });
  wrappers.add(wrapper);

  return new Proxy(wrapper, {
    apply(wrapper, context, args) {
      if (wrapper.__cleared) {
        throw new Error(errorCall);
      }
      // If the context is a proxied wrapper, use its target as the context.
      if (context && context.__marker && context.__target) {
        context = context.__target;
      }
      return createBridgeProxy(Reflect.apply(wrapper.__target, context, args));
    },
    construct(wrapper, args) {
      if (wrapper.__cleared) {
        throw new Error(errorCall);
      }
      return createBridgeProxy(Reflect.construct(wrapper.__target, args));
    },
    defineProperty(wrapper, key, descriptor) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.defineProperty(wrapper, key, descriptor);
    },
    deleteProperty(wrapper, key) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      // NOTE: Clears the proxied value that is cached.
      delete wrapper.__proxies[key];
      return Reflect.deleteProperty(wrapper, key);
    },
    /**
     * Returns the original value if it is a primitive, otherwise Proxies the value and returns that.
     *
     * @param {WrapperProxy} wrapper The proxy wrapper that is returned instead of the original object.
     * @param {*} key The key that the value is associated with inside of the object.
     */
    get(wrapper, key) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      // Most properties should be read from the target, but if there is a
      // non-null property - like `style` - in the wrapper, it was put there
      // on purpose, so use it instead.
      let value = wrapper[key];
      // Don't wrap the internal wrapper properties in proxies. Direct access
      // to the wrapped target is requried in the apply handler.
      if (typeof key === 'string' && /^__/.test(key)) {
        return value;
      }

      // mechanism for unwrapping a proxy, for console logging purposes
      // usage: console.log(myValue[Symbol.for('BRIDGEPROXY_ORIGINAL'))
      if (key === Symbol.for('BRIDGEPROXY_ORIGINAL')) return wrapper;

      if (value === null || value === undefined || Array.isArray(wrapper)) {
        value = Reflect.get(wrapper.__target, key);
      }

      if (!wrapper.__proxies[key]) {
        wrapper.__proxies[key] = createBridgeProxy(value);
      }

      return wrapper.__proxies[key];
    },
    getOwnPropertyDescriptor(wrapper, key) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.getOwnPropertyDescriptor(wrapper, key);
    },
    getPrototypeOf(wrapper) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.getPrototypeOf(wrapper.__target);
    },
    has(wrapper, key) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.has(wrapper, key);
    },
    isExtensible(wrapper) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.isExtensible(wrapper);
    },
    ownKeys(wrapper) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.ownKeys(wrapper);
    },
    preventExtensions(wrapper) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.preventExtensions(wrapper);
    },
    set(wrapper, key, value) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      // NOTE: Clears the proxied value that is cached.
      delete wrapper.__proxies[key];
      // If the wrapper contains a non-null value for the property, set it,
      // too, as non-null properties in the wrapper are used in preference to
      // properties in the actual target when getting.
      if (wrapper[key] !== null) {
        wrapper[key] = value;
      }
      return Reflect.set(wrapper.__target, key, value);
    },
    setPrototypeOf(wrapper, value) {
      if (wrapper.__cleared) {
        throw new Error(errorAccess);
      }
      return Reflect.setPrototypeOf(wrapper.__target, value);
    }
  });
}
