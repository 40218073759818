import invariant from 'invariant';
import { useMemo } from 'react';
import OverlaysBridge from 'data/classic-bridges/overlays';
import mappings from 'utils/overlays/mapping';

export function useOverlay(overlayName: keyof typeof mappings) {
  invariant(
    OverlaysBridge[overlayName],
    `Overlay ${overlayName} not present in either the classic or shell dialogsBridges`
  );

  return useMemo(
    () => ({
      open: (...args) => {
        return OverlaysBridge[overlayName].open(...args);
      },
      close: (...args) => {
        return OverlaysBridge[overlayName].close(...args);
      }
    }),
    [overlayName]
  );
}
