import { gql } from 'hooks/use-graphql-query';

export const ContractEvent = gql`
  fragment ContractEvent on ContractEvent {
    id
    condition_type
    condition_notes
    condition_date_of
    condition_date_completed
  }
`;
