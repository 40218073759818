import _ from 'lodash';
import {
  IEntityContentJson,
  IPluginColumn,
  IPluginModule,
  IPluginRow
} from '@mailupinc/bee-plugin/dist/types/bee';
import {
  NewsletterJson,
  OfficeLocation
} from 'features/newsletter-builder/types';

export function pathOfRexType(obj, targetRexType, path = '') {
  // Check if current object has the target rexType
  if (_.get(obj, 'metadata.rexType') === targetRexType) {
    return path;
  }

  // Recursively search in nested objects and arrays
  for (const [key, value] of Object.entries(obj)) {
    if (_.isPlainObject(value) || _.isArray(value)) {
      const newPath = path ? `${path}.${key}` : key;
      const result = pathOfRexType(value, targetRexType, newPath);
      if (result) return result;
    }
  }

  // If we've searched everything and found nothing, return null
  return null;
}

export function toggleLocksForNewsletterSections(
  jsonData: IEntityContentJson,
  isLocked = true
) {
  const lockedPaths = [
    pathOfRexType(jsonData, 'rex-unsubscribe-text'),
    pathOfRexType(jsonData, 'rex-unsubscribe-row'),
    pathOfRexType(jsonData, 'rex-footer'),
    pathOfRexType(jsonData, 'rex-powered-by-text'),
    pathOfRexType(jsonData, 'rex-logo')
  ];

  lockedPaths.forEach((path) => {
    _.set(jsonData, path + '.locked', isLocked);
  });

  return jsonData;
}

/**
 * Updates the branding of a newsletter builder json object
 * This is mainly to cover things that aren't adequately covered by the
 * Beefree merge tags system - currently, this is just the location color
 * which can't be set to a dynamic backend-resolved merge tag.
 * @param jsonData
 * @param officeLocations
 * @param newLocationId
 */
export function updateNewsletterBranding(
  jsonData: NewsletterJson,
  officeLocations: OfficeLocation[],
  newLocationId: string
): NewsletterJson {
  const newLocation = officeLocations.find(
    (l) => String(l.id) === String(newLocationId)
  ) as OfficeLocation;

  const oldLocation = officeLocations.find(
    (l) => String(l.id) === String(jsonData.metadata?.rexLocationId)
  );

  const newData = structuredClone(jsonData) as IEntityContentJson & {
    metadata?: { rexLocationId: string };
  };

  newData.metadata = {
    ...newData.metadata,
    rexLocationId: String(newLocation.id)
  };

  const headerPath = pathOfRexType(newData, 'rex-header');
  if (headerPath) {
    _.set(
      newData,
      `${headerPath}.container.style.background-color`,
      newLocation.report_color
    );
  }

  const logoPath = pathOfRexType(newData, 'rex-agency-logo');
  if (logoPath) {
    _.set(
      newData,
      `${logoPath}.descriptor.image.src`,
      'https:' + newLocation.logo_image?.url
    );
  }

  // Update link color
  _.set(
    newData,
    'page.body.content.computedStyle.linkColor',
    newLocation.report_color
  );

  // Update button background colors
  const updateButtonColors = (obj: any) => {
    if (_.isPlainObject(obj)) {
      if (
        oldLocation &&
        obj.descriptor?.button?.style?.['background-color'] ===
          oldLocation.report_color
      ) {
        obj.descriptor.button.style['background-color'] =
          newLocation.report_color;
      }
      Object.values(obj).forEach(updateButtonColors);
    } else if (_.isArray(obj)) {
      obj.forEach(updateButtonColors);
    }
  };

  updateButtonColors(newData);

  return newData;
}

/**
 * Takes JSON for a BeeFree row and cleans it up for general re-use
 * - Replaces hardcoded uuids with random uuids
 * - Appends necessary metadata
 * @param rowJson JSOn template for a BeeFree row
 * @param contentDialogId BeeFree content dialog id
 * @param groupId A unique identifier for the group this row should be associated with
 */
export function processRowTemplate(
  rowJson: IPluginRow,
  { contentDialogId, groupId }: { contentDialogId: string; groupId: string }
) {
  const newRow: IPluginRow = { ...rowJson };

  newRow.uuid = crypto.randomUUID();

  // @ts-ignore
  newRow.type = 'rowAddon';

  const updateModule = (module: IPluginModule) => {
    module.uuid = crypto.randomUUID();
  };

  const updateColumn = (column: IPluginColumn) => {
    column.uuid = crypto.randomUUID();
  };

  newRow.columns.forEach((column) => {
    updateColumn(column);
    column.modules.forEach((module) => {
      updateModule(module);
    });
  });

  return {
    ...newRow,
    metadata: {
      rexType: contentDialogId,
      rexGroupId: groupId
    },
    rowInternal: {
      uid: contentDialogId,
      entity: _.startCase(contentDialogId.split('_').join(' ')),
      icon: null,
      placeholder: null,
      ctaLabel: 'Select Records',
      configurationUi: {
        contentDialogId: contentDialogId,
        external: null
      }
    }
  };
}
