import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Option from './core';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TEXTS, PADDINGS } from 'src/theme';
import Icon, { ICONS } from 'shared/components/icon';
import { Body } from 'components/text/body';
import _ from 'lodash';
import DefaultOption from './default';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: '#FFFFFF',
    ...TEXTS.SETTINGS_NAVIGATION.MENU_ITEM,
    padding: '10px',
    paddingLeft: '10px',

    '&:hover': {
      backgroundColor: '#CAE8F8'
    }
  }
});

@styled(defaultStyles)
class SuburbOption extends PureComponent {
  render() {
    const { option, optionIsStyled, styles: s } = this.props;
    const { data } = option;
    const label =
      _.get(data, 'suburb_or_town') ||
      _.get(data, 'text') ||
      _.get(data, 'value');

    if (!optionIsStyled) {
      return <DefaultOption {...this.props} label={label} />;
    }

    return (
      <Option {...this.props}>
        <Box {...s('container')} flexDirection={'row'} alignItems={'center'}>
          <Icon
            type={ICONS.SELECT.OPTION.TOWN_CITY}
            hasControlledColor={false}
          />
          <Box pl={PADDINGS.XS} flexDirection={'column'}>
            <Body normal black small short>
              {label}
            </Body>
            <Body normal small short>
              Town/City
            </Body>
          </Box>
        </Box>
      </Option>
    );
  }
}

export default SuburbOption;
