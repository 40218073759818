import DialogsBridge from 'data/classic-bridges/dialogs-classic';
import { EditMailMergeProps } from 'utils/dialogs/mapping-classic-interfaces';
import _ from 'lodash';

interface Record {
  service: string;
  id: string;
  stub: object;
}

interface CalendarEvent {
  records: Record[];
  id: string;
}

interface GetRecordsForMergeProps {
  event: CalendarEvent;
  sms?: boolean;
}

function getRecordsForMerge({
  event,
  sms
}: GetRecordsForMergeProps): EditMailMergeProps {
  const servicesMapped = {
    Listings: 'listing',
    Properties: 'property',
    Projects: 'project',
    ProjectStages: 'project_stage'
  };

  const records = event?.records || [];
  const regarding = records.find((record) => record.service !== 'Contacts');

  return {
    data: {
      mode: regarding ? servicesMapped[regarding.service] : 'quick',
      medium: sms ? 'sms' : 'email',
      data: {
        listing: regarding?.service === 'Listings' ? regarding : undefined,
        property: regarding?.service === 'Properties' ? regarding : undefined,
        project: regarding?.service === 'Projects' ? regarding : undefined,
        project_stage:
          regarding?.service === 'ProjectStages' ? regarding : undefined,
        calendar_event_id: event?.id
      }
    },
    options: {
      title: `${sms ? 'SMS' : 'Email'} ${
        !regarding
          ? 'Contact'
          : `${_.capitalize(servicesMapped[regarding?.service])}`
      }`
    }
  };
}

export function sendEmail(event: CalendarEvent) {
  return DialogsBridge.editMailMerge?.openClassic(
    getRecordsForMerge({ event })
  );
}

export function sendSms(event: CalendarEvent) {
  return DialogsBridge.editMailMerge?.openClassic(
    getRecordsForMerge({ event, sms: true })
  );
}
