import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const actionCreators = {
  setSettings: {
    request: ({ connectionId, settings }) =>
      api.post('ThirdPartyServiceClickToDial::setSettings', {
        connection_id: connectionId,
        settings: settings
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getSettings: {
    request: ({ connectionId }) =>
      api.post('ThirdPartyServiceClickToDial::getSettings', {
        connection_id: connectionId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  makeTestPhoneCall: {
    request: (args) =>
      api.post('ThirdPartyServiceClickToDial::makeTestPhoneCall', args),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('thirdPartyServiceClickToDial').createModel({
  actionCreators
});
