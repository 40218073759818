/* eslint-disable camelcase */

export function formatKey(payload) {
  // NOTE: this is causing problems, not sure why out of a sudden
  // but to quickfix it I added this check
  if (!payload) {
    return;
  }
  const { id, recordId, record_id, serviceName, service_name } = payload;
  return `${serviceName || service_name}-${recordId || record_id || id}`;
}

type PusherConnection = any;
type Props = { [key: string]: any };

let pusherConnection: PusherConnection = null;
export function resetPusherConnection() {
  if (pusherConnection) {
    pusherConnection.disconnect();
    pusherConnection = null;
  }
}

export class Channel {
  type: { pattern: any; config: any } = {
    pattern: undefined,
    config: undefined
  };

  auth: any = undefined;
  props: Props = {};
  pusher: PusherConnection = undefined;
  subscription: any = undefined;

  constructor(type, auth, props = {}) {
    this.type = type;
    this.auth = auth;
    this.props = props;
  }

  open() {
    const { config } = this.type;
    return import('pusher-js').then(({ default: Pusher }) => {
      if (!pusherConnection) {
        pusherConnection = new Pusher(config.key, {
          authorizer: this.auth,
          cluster: config.cluster,
          forceTLS: config.force_tls
        });
      }

      this.pusher = pusherConnection;
      this.subscription = this.pusher?.subscribe?.(this.name);
    });
  }

  close() {
    this.pusher?.unsubscribe?.(this.name);
    this.pusher = null;
    this.subscription = null;
  }

  bind(eventNames, callback) {
    eventNames.forEach((eventName) =>
      this.subscription?.bind?.(eventName, callback)
    );
  }

  get key() {
    return formatKey(this.props);
  }

  get members() {
    return this.subscription?.members;
  }

  get name() {
    const { type, props } = this;
    return Object.keys(props).reduce(
      (name, key) => name.replace(new RegExp(`\\{${key}\\}`, 'g'), props[key]),
      type?.pattern
    );
  }
}
