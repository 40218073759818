import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const actionCreators = {
  setOrderTypesMap: {
    request: ({ connectionId, orderTypeMap }) =>
      api.post('ThirdPartyServiceEaPrint::setOrderTypesMap', {
        connection_id: connectionId,
        map: orderTypeMap
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getOrderTypesMap: {
    request: ({ connectionId }) =>
      api.post('ThirdPartyServiceEaPrint::getOrderTypesMap', {
        connection_id: connectionId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('thirdPartyServiceEaPrint').createModel({
  actionCreators
});
