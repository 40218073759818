import { Generator } from 'shared/utils/models';
import _ from 'lodash';

import { formatKey } from 'utils/pusher';

const empty = [];

const initialState = {
  changes: {},
  viewers: {}
};

const actionCreators = {
  openRecord: {
    reduce: _.identity
  },
  reloadRecord: {
    reduce: (state, action) => {
      const key = formatKey(action.payload);
      const { [key]: change, ...restChanges } = state.changes;
      return {
        ...state,
        changes: restChanges
      };
    }
  },
  closeRecord: {
    reduce: (state, action) => {
      const key = formatKey(action.payload);
      const { [key]: change, ...restChanges } = state.changes;
      const { [key]: viewers, ...restViewers } = state.viewers;
      return {
        ...state,
        changes: restChanges,
        viewers: restViewers
      };
    }
  },
  closeAllRecords: {
    reduce: () => initialState
  },
  updateViewers: {
    reduce: (state, action) => ({
      ...state,
      viewers: {
        ...state.viewers,
        [formatKey(action.payload)]: action.payload.viewers
      }
    })
  },
  updateChange: {
    reduce: (state, action) => {
      // HACKY AS SH**: when we get new changes, we want to merge them with the old changes
      // Due to the format we're getting, what that means is for all attributes that already
      // exist in the store, we want to keep their "previous" value!
      const stateKey = formatKey(action.payload);
      const currAttributes = _.get(
        state,
        `changes.${stateKey}.change.attributes`
      );
      const currRelated = _.get(state, `changes.${stateKey}.change.related`);

      const { change } = action.payload.change;
      change.related = _.isEmpty(change.related) ? {} : change.related;

      const omitAttributes = [
        'system_modified_user',
        'security_user_rights',
        'system_modified_user',
        'system_modtime',
        'etag'
      ];

      // Remove system_modified_user from list of attributes
      change.attributes = _.omit(change.attributes, 'system_modified_user');

      // Remove system_modified_user from the property object
      if (change.attributes.property) {
        change.attributes.property.new = _.omit(
          change.attributes.property.new,
          omitAttributes
        );
        change.attributes.property.previous = _.omit(
          change.attributes.property.previous,
          omitAttributes
        );
        if (
          // After omitting the attributes we want to leave out,
          // if the objects are still equal, remove the property object
          _.isEqual(
            change.attributes.property.new,
            change.attributes.property.previous
          )
        ) {
          change.attributes = _.omit(change.attributes, 'property');
        }
      }

      _.forEach(change.related, (relatedValue, relatedKey) => {
        // Remove system_modified_user from any other related change
        _.forEach(change.related[relatedKey], (value, key) => {
          const newArray = [];
          change.related[relatedKey][key].forEach((item) => {
            const attributes = item?.attributes;
            const newAttributes = _.omit(attributes, 'system_modified_user');

            if (!_.isEmpty(newAttributes)) {
              const newUpdate = {
                ...item,
                attributes: newAttributes
              };
              newArray.push(newUpdate);
            }
          });
          change.related[relatedKey][key] = newArray;
        });
      });

      const newAttributes = change.attributes;
      const newRelated = change.related;

      !_.isEmpty(newAttributes) &&
        !_.isEmpty(currAttributes) &&
        _.forEach(currAttributes, (value, key) => {
          if (newAttributes[key]) {
            newAttributes[key].previous =
              (newAttributes[key].previous !== undefined &&
                typeof newAttributes[key].previous === 'object') ||
              (value.previous !== undefined &&
                typeof value.previous === 'object')
                ? _.merge({}, newAttributes[key].previous, value.previous)
                : value.previous || newAttributes[key].previous;
          }
        });

      !_.isEmpty(newRelated) &&
        !_.isEmpty(currRelated) &&
        _.forEach(currRelated, (relatedValue, relatedKey) => {
          _.forEach(newRelated[relatedKey], (value, key) => {
            const newArray = _.concat(
              newRelated[relatedKey][key],
              currRelated[relatedKey][key]
            );
            newRelated[relatedKey][key] = newArray;
          });
        });

      const newChanges = {
        ...state,
        changes: {
          ...state.changes,
          [stateKey]: _.merge(
            {},
            state.changes[stateKey],
            action.payload.change,
            {
              change: {
                attributes: !_.isEmpty(newAttributes)
                  ? newAttributes
                  : currAttributes,
                related: !_.isEmpty(newRelated) ? newRelated : currRelated
              }
            }
          )
        }
      };

      return newChanges;
    }
  }
};

const selectors = {
  change: (state, props) =>
    state?.collisionAvoidance?.changes?.[formatKey(props)],
  viewers: (state, props) =>
    state?.collisionAvoidance?.viewers?.[formatKey(props)] || empty
};

export default new Generator('collisionAvoidance').createModel({
  initialState,
  actionCreators,
  selectors
});
