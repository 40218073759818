import config from 'shared/utils/config';

const ROUTES = {
  WORKFLOW_REQUEST: {
    config: {
      path: '/actions/form_request',
      Component: require('view/screens/public-actions/workflow-form-request')
        .default
    }
  },
  RESET_PASSWORD: {
    config: {
      path: '/actions/password_reset',
      Component: (props) => {
        window.location.href = `${config.AUTH_APP_URL}/actions/password_reset/#${props.whereabouts.hash}`;
        return null;
      }
    }
  },
  REGISTER_USER: {
    config: {
      path: '/actions/register_user/',
      Component: (props) => {
        window.location.href = `${config.AUTH_APP_URL}/actions/register_user/#${props.whereabouts.hash}`;
        return null;
      }
    }
  },
  USER_INVITATION: {
    config: {
      path: '/actions/user_invitation/',
      Component: (props) => {
        window.location.href = `${config.AUTH_APP_URL}/actions/user_invitation/#${props.whereabouts.hash}`;
        return null;
      }
    }
  },
  REMOTE_LOGOUT: {
    config: {
      path: '/actions/remote_logout',
      Component: require('view/screens/public-actions/remote-logout').default
    }
  }
};

export default ROUTES;
