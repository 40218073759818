import { useMemo } from 'react';

import { useRegion } from 'hooks/use-region';
import { useWhichWordFactory } from 'hooks/use-which-word';

import {
  ModuleConfigItem,
  OrderByDirectionType
} from 'features/custom-reporting/modules/module-config-types';
import { moduleNameMap } from 'features/custom-reporting/modules/module-name-map';

import {
  AppointmentsReportQuery,
  appointmentsReportQueryDefaultVariables
} from './appointment-report.query';

import { makeEntity } from 'features/custom-reporting/modules/helpers';
import { useCustomFields } from 'features/custom-reporting/hooks/use-async-report-data';

export const defaultColumns = [
  'title',
  'appointment_type.name',
  'startDate',
  'event_location.description',
  'organiser_user.name',
  'system_created_user.name',
  'guests.0.stub',
  'guests.0.stub.emails',
  'guests.0.stub.phones'
];

export function useAppointmentModule() {
  const region = useRegion();
  const ww = useWhichWordFactory();
  const {
    properties: propertyCustomFields,
    contacts: contactCustomFields,
    listings: listingCustomFields
  } = useCustomFields();

  const appointmentModuleConfig: ModuleConfigItem = useMemo(
    () => ({
      ...moduleNameMap.appointments,
      moduleLabel: 'Appointments',
      exportPrivilege: 'exporting.appointments',
      queryConfig: {
        graphQlQuery: AppointmentsReportQuery,
        queryVariables: appointmentsReportQueryDefaultVariables,
        defaultSort: [
          {
            name: 'starts_at',
            direction: 'desc' as OrderByDirectionType
          }
        ]
      },
      columns: [
        {
          headerName: 'Appointment',
          children: makeEntity({
            entity: 'appointment',
            prefix: ''
          })
        },
        {
          headerName: 'Contact Guest 1',
          children: makeEntity({
            entity: 'contact',
            prefix: 'guests.0.stub',
            subresource: 'guests',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Contact Guest 2',
          children: makeEntity({
            entity: 'contact',
            prefix: 'guests.1.stub',
            subresource: 'guests',
            customFields: contactCustomFields,
            region
          })
        },
        {
          headerName: 'Listing',
          children: makeEntity({
            entity: 'listing',
            prefix: 'listing.0.stub',
            subresource: 'listing',
            customFields: listingCustomFields,
            region,
            whichWord: ww
          })
        },
        {
          headerName: 'Listing Owner',
          children: makeEntity({
            entity: 'contact',
            prefix: 'listing.0.stub.owner.0.contact',
            subresource: 'listing_owner',
            region
          })
        },
        {
          headerName: 'Property',
          children: makeEntity({
            entity: 'property',
            prefix: 'property.0.stub',
            subresource: 'property',
            customFields: propertyCustomFields
          })
        },
        {
          headerName: 'Feedback',
          children: makeEntity({
            entity: 'feedback',
            prefix: 'feedback.0.stub',
            subresource: 'feedback'
          })
        },
        {
          headerName: ww('valuation'),
          children: makeEntity({
            entity: 'appointmentAppraisal',
            prefix: 'appraisal.0.stub',
            subresource: 'appraisal',
            whichWord: ww
          })
        }
      ],
      defaultVisibleColumns: defaultColumns,
      defaultDatePreset: 'last_7_days',
      defaultUserFieldPreference: 'organiser_user_id',
      defaultDateFieldPreference: 'starts_at',
      reportScopeCriteria: [
        {
          name: 'status_id',
          type: '!=',
          value: 'cancelled'
        },
        { name: 'is_private', value: false }
      ],
      topLevelFilters: {
        date: {
          availableFields: [
            {
              fieldName: 'starts_at',
              label: 'Appointment start time',
              isDefault: true
            },
            {
              fieldName: 'system_ctime',
              label: 'Appointment creation'
            }
          ]
        },
        user: {
          availableFields: [
            {
              fieldName: 'organiser_user_id',
              label: 'User is the appointment organiser',
              isDefault: true
            },
            {
              fieldName: 'system_created_user_id',
              label: 'User booked the appointment'
            }
          ]
        }
      }
    }),
    [region, contactCustomFields, listingCustomFields, propertyCustomFields, ww]
  );
  return appointmentModuleConfig;
}
