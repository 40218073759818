import React, { useMemo } from 'react';
import { query, useEntityListQuery } from '@rexlabs/model-generator';

import workflowInstancesModel from 'data/models/entities/workflow-instances';
import { RecordDetailsWidget } from 'components/_experiment/record-details-screen/widget';
import { Heading } from 'components/_experiment/record-widget/heading';
import { Placeholder } from 'components/placeholder';

const getWorkflowsQuery = ({ id, serviceName }) => {
  const criteria = [
    { name: 'started_from_service_id', value: serviceName },
    { name: 'started_from_record_id', value: id }
  ];
  const orderBy = { id: 'DESC' };
  return query`{
    ${workflowInstancesModel} (criteria: ${criteria}, order_by: ${orderBy}) {
      id
      name
    }
  }`;
};

export function RelatedWorkflowsWidget({ id, serviceName }) {
  const workflowsQuery = useMemo(
    () => getWorkflowsQuery({ id, serviceName }),
    [id, serviceName]
  );
  const { data: workflows, status } = useEntityListQuery(workflowsQuery);

  return (
    <RecordDetailsWidget>
      <Heading>Workflows</Heading>
      <Placeholder data={{ status, workflows }} />
    </RecordDetailsWidget>
  );
}
