import React, { useCallback } from 'react';

import { Form, FormField, ReactForms } from 'view/components/form';
import { Select } from 'view/components/input/select';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';

export function PaginationPopout({
  pagination,
  limit,
  setLimit,
  setPage,
  children
}) {
  const Content = useCallback(
    ({ close }) => {
      const handleSubmit = (values) => {
        if (values.limit !== limit) {
          setLimit(values.limit);
        }
        if (values.goToPage) {
          setPage(values.goToPage - 1);
        }
        close();
      };

      return (
        <PaddingBox>
          <ReactForms initialValues={{ limit }} handleSubmit={handleSubmit}>
            {({ submitForm, isSubmitting, values }) => {
              // Calculates number of pages based on the current total number of items
              // based on the pagination information we got from the BE and the currently
              // selected limit based on the form values
              const maxPages = Math.ceil(
                pagination?.totalItems && values?.limit
                  ? pagination.totalItems / values.limit
                  : 0
              );

              return (
                <Form>
                  <FormField
                    name='limit'
                    label='number per page'
                    Input={Select}
                    inputProps={{
                      options: [10, 20, 50, 100].map((nmb) => ({
                        label: `${nmb}`,
                        value: nmb
                      }))
                    }}
                  />
                  <FormField
                    name='goToPage'
                    label='jump to page'
                    Input={Select}
                    inputProps={{
                      options: Array.from(new Array(maxPages)).map((_, i) => ({
                        label: `${i + 1}`,
                        value: i + 1
                      }))
                    }}
                  />

                  <SaveCancelButtonBar
                    blue
                    saveLabel='Apply'
                    onCancel={close}
                    onSave={submitForm}
                    isLoading={isSubmitting}
                  />
                </Form>
              );
            }}
          </ReactForms>
        </PaddingBox>
      );
    },
    [limit, pagination, setLimit, setPage]
  );

  return (
    <Popout Content={Content}>
      <>{children}</>
    </Popout>
  );
}
