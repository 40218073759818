import React from 'react';

import { ICONS } from 'shared/components/icon';

import { ReportingFilterButton } from './index';

type SubmoduleSelectorButtonProps = Omit<
  React.ComponentProps<typeof ReportingFilterButton>,
  'Icon'
>;

export function SubmoduleSelectorButton(props: SubmoduleSelectorButtonProps) {
  return (
    <ReportingFilterButton Icon={ICONS.ROWS_12} showArrowIcon>
      {props.children}
    </ReportingFilterButton>
  );
}
