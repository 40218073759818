import React, { PureComponent } from 'react';
import { DefaultButton } from 'view/components/button';
import { ICONS } from 'shared/components/icon';

const Arrow = ICONS.ARROW_DOWN;

class ArrowDropdownButton extends PureComponent {
  render() {
    const { light } = this.props;
    return (
      <DefaultButton light={light} padding={false} {...this.props}>
        <Arrow
          style={{
            transform: 'rotate(270deg)',
            fill: 'currentColor',
            position: 'absolute'
          }}
        />
      </DefaultButton>
    );
  }
}

export default ArrowDropdownButton;
