import {
  EntityModel,
  query,
  useEntityListQuery
} from '@rexlabs/model-generator';
import { Criteria } from 'types/criteria';
import { useMemo, useState } from 'react';

export function useAdminListScreenQuery<ModelType, CustomActions>(
  model: EntityModel<ModelType, CustomActions>,
  initialCriteria: Criteria[]
) {
  const [criteria, setCriteria] = useState<Criteria[] | null>(initialCriteria);
  const q = useMemo(
    () => query`{
    ${model} (criteria: ${criteria}) {
      id
    }
  }`,
    [model, criteria]
  );

  const entityList = useEntityListQuery(q);

  entityList.actions = entityList.actions as (typeof entityList)['actions'] & {
    purge: (id: string) => Promise<void>;
    archive: (id: string) => Promise<void>;
    recoverFromArchive: (id: string) => Promise<void>;
    duplicate: (id: string) => Promise<void>;
  };

  return {
    entityList,
    setCriteria,
    criteria
  };
}
