import Box from '@rexlabs/box';
import React, { memo } from 'react';
import { RefinementType } from 'features/ai/data/refinement-type';
import { RefinementPopout } from 'features/ai/components/refinement-popout';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';
import RefineIcon from 'assets/icons/ai/refine.svg';
import LongerIcon from 'assets/icons/ai/longer.svg';
import ShorterIcon from 'assets/icons/ai/shorter.svg';
import ToneIcon from 'assets/icons/ai/tone.svg';
import AudienceIcon from 'assets/icons/ai/audience.svg';
import { AiTooltip } from 'features/ai/components/primitives/ai-tooltip';
import { TonePopout } from 'features/ai/components/tone-popout';
import { AudiencePopout } from 'features/ai/components/audience-popout';

type TextGenerationFormBottomBarProps = {
  isDisabled: boolean;
  onCustomRefinement: (refinementText: string) => void;
  onBuiltInRefinement: (refinementType: RefinementType) => void;
  tone: RefinementType | null;
  audience: RefinementType | null;
};

const textGenerationBottomBarStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
    position: 'absolute',
    left: 15,
    right: 15,
    bottom: 15,
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: COLORS.WHITE
  },
  button: {
    backgroundColor: COLORS.WHITE,
    paddingLeft: '10px',
    paddingRight: '10px',
    border: 0,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '14px',
    webkitAppearance: 'none',
    borderRadius: '8px',
    color: COLORS.DARK_GREY,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    gap: '5px',
    height: '36px',
    transition: 'background, opacity 0.1s ease-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#DFDEDC'
    },
    '&[disabled]': {
      opacity: 0.6
    },
    '&[disabled]:hover': {
      cursor: 'not-allowed',
      backgroundColor: COLORS.WHITE
    }
  },
  buttonIcon: {
    marginLeft: '-1px',
    marginRight: '2px',
    marginTop: '-1px'
  }
});

export const TextGenerationFormBottomBar = memo(
  function TextGenerationFormBottomBar({
    isDisabled,
    onCustomRefinement,
    onBuiltInRefinement,
    tone,
    audience
  }: TextGenerationFormBottomBarProps) {
    const s = useStyles(textGenerationBottomBarStyles);
    return (
      <Box {...s('container')}>
        <RefinementPopout onCustomRefinement={onCustomRefinement}>
          <button {...s('button')} disabled={isDisabled}>
            <RefineIcon {...s('buttonIcon')} />
            Refine
          </button>
        </RefinementPopout>
        <AiTooltip content={'Make shorter'}>
          <button
            {...s('button')}
            disabled={isDisabled}
            onClick={() => onBuiltInRefinement(RefinementType.SHORTER)}
          >
            <ShorterIcon />
          </button>
        </AiTooltip>
        <AiTooltip content={'Make longer'}>
          <button
            {...s('button')}
            disabled={isDisabled}
            onClick={() => onBuiltInRefinement(RefinementType.LONGER)}
          >
            <LongerIcon />
          </button>
        </AiTooltip>
        <TonePopout tone={tone} onRefinement={onBuiltInRefinement}>
          <AiTooltip content={'Adjust tone'}>
            <button {...s('button')} disabled={isDisabled}>
              <ToneIcon />
            </button>
          </AiTooltip>
        </TonePopout>
        <AudiencePopout audience={audience} onRefinement={onBuiltInRefinement}>
          <AiTooltip content={'Audience'}>
            <button {...s('button')} disabled={isDisabled}>
              <AudienceIcon />
            </button>
          </AiTooltip>
        </AudiencePopout>
      </Box>
    );
  }
);
