import React, { PureComponent } from 'react';
import { TextArea } from '@rexlabs/text-input';

class RexResponsive extends PureComponent {
  render() {
    return <TextArea {...this.props} />;
  }
}

export default RexResponsive;
