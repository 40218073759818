import React, { ReactNode, ReactElement } from 'react';
import Tooltip from 'view/components/tooltip';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { Body } from 'components/text/body';

interface Props {
  showTooltip: boolean;
  children?: ReactNode;
  content: ReactElement;
}

// style overrides are required here to ensure that text-overflow: ellipsis continues to work correctly
// when wrapped in a tooltip. Note maxWidth is the only style we actually needed, but because styleOverrides aren't
// merged we have to provide all the styles here.
const styleOverrides = {
  HoverTarget: StyleSheet({
    hoverContainer: {
      display: 'inline-block',
      width: 'fit-content',
      maxWidth: '100%'
    }
  }),
  Tooltip: StyleSheet({
    hoverable: {
      maxWidth: '100%'
    }
  })
};

function TableCellTooltip({ showTooltip, children, content, ...props }: Props) {
  if (!showTooltip) {
    return <>{children}</>;
  }

  return (
    <StylesProvider components={styleOverrides} prioritiseParentStyles={false}>
      <Tooltip
        inline
        hoverTimeout={300}
        placement='top'
        Content={() => (
          <Body normal white>
            {content}
          </Body>
        )}
        target={
          // TODO: individual consumers should not care about the use of Rex2FrameWindow
          //  https://app.clubhouse.io/rexlabs/story/53598/remove-dependencies-on-window-top-rex2framewindow-from-individual-components
          window && window.top && window.top.Rex2FrameWindow
            ? window.top.Rex2FrameWindow.document.body
            : window.document.body
        }
        {...props}
      >
        {children}
      </Tooltip>
    </StylesProvider>
  );
}

export default TableCellTooltip;
