import { gql } from 'hooks/use-graphql-query';
import {
  User,
  Chain,
  Property,
  CustomFields
} from 'features/custom-reporting/data/fragments';

import { Contract } from '../contracts/contract-data-fragments';
import { CommissionWorksheet } from 'features/custom-reporting/modules/commission-by-sale/commission-by-sale-data-fragments';
import { Listing } from 'features/custom-reporting/modules/listings/listing-data-fragments';

export const commissionBySaleReportQueryDefaultVariables = {
  include_subresource_listing: false,
  include_subresource_contract: false,
  include_subresource_property: false,
  include_listing_custom_fields: false,
  listing_custom_field_ids: [],
  include_contract_custom_fields: false,
  contract_custom_field_ids: [],
  include_property_custom_fields: false,
  property_custom_field_ids: []
};

export const CommissionBySaleReportQuery = gql`
  ${User}
  ${Listing}
  ${Contract}
  ${Chain}
  ${CommissionWorksheet}
  ${Property}
  ${CustomFields}

  query (
    $include_subresource_listing: Boolean!
    $include_subresource_contract: Boolean!
    $include_subresource_property: Boolean!
    $include_listing_custom_fields: Boolean!
    $listing_custom_field_ids: [Int!]!
    $include_contract_custom_fields: Boolean!
    $contract_custom_field_ids: [Int!]!
    $include_property_custom_fields: Boolean!
    $property_custom_field_ids: [Int!]!
    $criteria: [Criterion!]
    $order_bys: [OrderBy!]
    $limit: Int!
    $cursor: String
  ) {
    rowData: commission_worksheets(
      criteria: $criteria
      use_case: reporting
      order_bys: $order_bys
      limit: $limit
      cursor: $cursor
    ) {
      pagination {
        next_page {
          cursor
        }
      }
      rows {
        ...CommissionWorksheet
        contract @include(if: $include_subresource_contract) {
          ...Contract
          custom_field_values(field_ids: $contract_custom_field_ids)
            @include(if: $include_contract_custom_fields) {
            ...CustomFields
          }
          agent {
            ...User
          }
          chain_instance {
            assignee_user {
              ...User
            }
            chain {
              ...Chain
            }
          }
        }
        listing: contract @include(if: $include_subresource_listing) {
          listing {
            ...Listing
            custom_field_values(field_ids: $listing_custom_field_ids)
              @include(if: $include_listing_custom_fields) {
              ...CustomFields
            }
            listing_agent_1 {
              name
            }
            listing_agent_2 {
              name
            }
            system_owner_user {
              ...User
            }
          }
        }
        property: contract @include(if: $include_subresource_property) {
          listing {
            property {
              ...Property
              custom_field_values(field_ids: $property_custom_field_ids)
                @include(if: $include_property_custom_fields) {
                ...CustomFields
              }
              system_owner_user {
                ...User
              }
            }
          }
        }
      }
    }
  }
`;
