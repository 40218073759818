import { useEffect } from 'react';
import { useModelActions } from '@rexlabs/model-generator';

import uiModel from 'data/models/custom/ui';
import SaveCancelContext from 'data/save-cancel-context';

export function useSaveCancelOverlay(form: any) {
  // TODO: Once the UI Model is typed, we should be able to remove any type
  // https://app.shortcut.com/rexlabs/story/64595/type-ui-model
  const { updateSaveCancelOverlayState } = useModelActions(uiModel) as any;

  useEffect(
    () => {
      if (form?.isDirty) {
        window.onbeforeunload = () => true;
        SaveCancelContext.push({
          save: () => {
            form
              ?.submitForm?.()
              ?.then?.(() => updateSaveCancelOverlayState('OFF'))
              ?.catch((e) => {
                // open error dialog?
                console.error(e);
                updateSaveCancelOverlayState('ON');
              });
            updateSaveCancelOverlayState('IN_PROGRESS');
          },
          cancel: () => {
            form?.resetForm?.();
            updateSaveCancelOverlayState('OFF');
          }
        });
        updateSaveCancelOverlayState('ON');
      } else {
        window.onbeforeunload = null;
        SaveCancelContext.reset();
      }

      return () => {
        window.onbeforeunload = () => true;
      };
    },
    // eslint-disable-next-line
    [form?.isDirty]
  );
}
