import invariant from 'invariant';

function normalizer(locality) {
  if (locality.label && locality.value) {
    return locality;
  }

  const id = locality._id || locality.id;
  const name =
    locality._text || locality.text || locality._value || locality.value;

  invariant(
    id && name,
    "Items must have either an '_id' or 'id' and '_text' or 'text'"
  );

  return {
    label: name,
    value: {
      id,
      name
    },
    type: 'locality'
  };
}

export function normalizerNoId(locality) {
  if (locality.label && locality.value) {
    return locality;
  }

  const id = locality._id || locality.id;
  const name =
    locality._text || locality.text || locality._value || locality.value;

  return {
    label: name,
    value: {
      id: name,
      originalId: id,
      name
    },
    type: 'locality'
  };
}

export default normalizer;
