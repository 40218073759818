import React from 'react';

import Box from '@rexlabs/box';

import Spinner from 'shared/components/spinner';
import { getWidthOfText } from 'shared/utils/overflow';

import DefaultButton, {
  DEFAULT_BUTTON_FONT_SIZE,
  DefaultButtonProps
} from 'view/components/button/default-button';

interface LoadingButtonProps
  extends React.ComponentProps<typeof DefaultButton>,
    DefaultButtonProps {
  isLoading: boolean;
  children: string;
  darkSpinner?: boolean;
}

export function LoadingButton({
  isLoading,
  children,
  isDisabled,
  darkSpinner = true,
  ...rest
}: LoadingButtonProps) {
  const width = getWidthOfText(children, {
    fontSize: DEFAULT_BUTTON_FONT_SIZE
  });

  return (
    <DefaultButton isDisabled={isDisabled || isLoading} {...rest}>
      {isLoading ? (
        <Box width={width}>
          <Spinner dark={darkSpinner} extraSmall />
        </Box>
      ) : (
        children
      )}
    </DefaultButton>
  );
}
