import _ from 'lodash';

import {
  AppointmentRecordData,
  EventLocationData
} from 'hooks/use-edit-appointment';
import { SendMailMergeRecordData } from 'components/record-preview/record-preview-action-bar';

import listingsModel, { ListingItem } from 'data/models/entities/listings';
import { formatAddress } from 'utils/formatters';
import { Region } from 'hooks/use-region';

function getAppointmentData({
  fullAddress,
  listingImage,
  listing
}: {
  fullAddress: string;
  listingImage?: string;
  listing: ListingItem;
}) {
  const stub = {
    id: listing?.id,
    address: fullAddress,
    category: listing?.listing_category,
    image: listingImage,
    status: listing?.system_publication_status,
    security_user_rights: listing?.security_user_rights,
    system_owner_user: listing?.system_owner_user
  };

  const listingSelectItem: AppointmentRecordData = {
    id: listing?.id,
    label: fullAddress,
    service: 'Listings',
    stub
  };

  const eventLocation: EventLocationData = {
    data: stub,
    label: fullAddress,
    model: listingsModel,
    value: listing?.id
  };

  return {
    event_location: eventLocation,
    records: [listingSelectItem]
  };
}

export function getListingPreviewPopoutData({
  listing,
  region
}: {
  listing?: ListingItem;
  region?: Region;
}) {
  const property = listing?.property;

  const listingImage =
    listing?.related?.listing_images[0]?.thumbs?.['200x150']?.url;

  const listingCategory = listing?.listing_category?.text;

  const recordTypeHeading = `Listing${
    listingCategory ? ` — ${listingCategory}` : ''
  }`;

  const listingStatus = listing?.system_listing_state;
  const isCurrent = listingStatus === 'current' && listingStatus;
  const isDraft = listing?.system_publication_status === 'draft';

  const streetAddress = formatAddress(
    _.pick(property, [
      'adr_street_name',
      'adr_unit_number',
      'adr_street_number',
      'adr_building',
      'property_category'
    ]),
    region
  );

  const suburb = formatAddress(
    _.pick(property, ['adr_postcode', 'adr_locality', 'adr_suburb_or_town']),
    region
  );

  const fullAddress = formatAddress(
    _.pick(property, [
      'adr_street_name',
      'adr_unit_number',
      'adr_street_number',
      'adr_building',
      'property_category',
      'adr_postcode',
      'adr_locality',
      'adr_suburb_or_town'
    ]),
    region
  );

  const newNoteData = {
    id: '',
    _related: {
      note_properties: property ? [{ property: property }] : []
    }
  };

  const newReminderData = {
    listing: listing
  };

  const newAppointmentData =
    listing &&
    getAppointmentData({
      fullAddress,
      listingImage,
      listing
    });

  const newMailMergeData: SendMailMergeRecordData | undefined = listing && {
    id: listing?.id,
    name: fullAddress,
    service: 'listing'
  };

  return {
    listingImage,
    listingCategory,
    recordTypeHeading,
    listingStatus,
    isCurrent,
    isDraft,
    streetAddress,
    suburb,
    newNoteData,
    newReminderData,
    newAppointmentData,
    newMailMergeData
  };
}
