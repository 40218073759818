import { useMemo } from 'react';
import { useStabilizedObject } from 'hooks/use-stabilized-object';

import { UserSelectedReportState } from 'features/custom-reporting/hooks/use-user-selected-report-state';
import { useCurrentModuleConfig } from 'features/custom-reporting/modules/module-provider';
import { CustomReportStateStructure } from 'features/custom-reporting/data/custom-reports-model';
import { mergeReportCriteria } from 'features/custom-reporting/utils/merge-report-criteria';

import { useDateCriteria } from './use-date-criteria';
import { useUserCriteria } from './use-user-criteria';
import { useLocationCriteria } from './use-location-criteria';

export function useReportCriteria({
  moduleName,
  reportData,
  userSelectedReportState
}: {
  moduleName: string;
  reportData?: CustomReportStateStructure;
  setSelectedUserFieldPreference: any;
  setSelectedDateFieldPreference: any;
  setShouldFilterByDate: any;
  userSelectedReportState: UserSelectedReportState;
}) {
  const { saved_advanced_criteria } = reportData || {};

  const { topLevelFilters, reportScopeCriteria } = useCurrentModuleConfig();

  const dateCriteria = useDateCriteria({
    moduleName,
    userSelectedReportState,
    fieldPreference: userSelectedReportState.selectedDateFieldPreference,
    shouldFilterByDate: userSelectedReportState.shouldFilterByDate
  });

  const userCriteria = useUserCriteria({
    moduleName,
    userSelectedReportState,
    fieldPreference: userSelectedReportState.selectedUserFieldPreference
  });

  const locationCriteria = useLocationCriteria({
    moduleName,
    userSelectedReportState
  });

  const topLevelCriteria = useMemo(
    () => [
      ...(topLevelFilters.date ? dateCriteria : []),
      ...(topLevelFilters.user ? userCriteria : []),
      ...(topLevelFilters.location ? locationCriteria : [])
    ],
    [
      dateCriteria,
      locationCriteria,
      userCriteria,
      topLevelFilters.date,
      topLevelFilters.location,
      topLevelFilters.user
    ]
  );

  const mergedCriteria = useMemo(
    () =>
      mergeReportCriteria({
        topLevelCriteria,
        saved_advanced_criteria,
        reportScopeCriteria
      }),
    [topLevelCriteria, saved_advanced_criteria, reportScopeCriteria]
  );

  // workaround: sometimes the inputs will change but will result in the same criteria.
  // we want to ensure that the criteria object reference only changes when the value does.
  const stabilizedCriteria = useStabilizedObject(mergedCriteria);

  // Memo one but only in need
  return {
    criteria: stabilizedCriteria
  };
}
