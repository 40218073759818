import React from 'react';

import { View } from './view';
import { Edit } from './edit';

export function mapCustomGroup(group) {
  return {
    id: group.id,
    isCustom: true,
    View: (props) => <View group={group} {...props} />,
    Edit: (props) => <Edit group={group} {...props} />
  };
}
