import React from 'react';

import { ActivityStream } from 'components/_experiment/activity-stream';

export const listingStreams = {
  default: [
    {
      id: 'stream',
      label: 'Stream',
      Component: ({ data }) => (
        <ActivityStream serviceName='Listings' id={data?.id} />
      )
    },
    {
      id: 'reminders',
      label: 'Reminders',
      Component: () => <p>Reminders Stream</p>
    },
    {
      id: 'feedback',
      label: 'Feedback',
      Component: () => <p>Feedback Stream</p>
    }
  ]
};
