import Types from 'prop-types';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import Icon, { ICONS } from 'shared/components/icon';
import { TEXTS } from 'theme';
import { APP_MENU } from 'theme/legacy/components';
import { formatOptionToHeading } from 'view/components/navigation/app-search/utils';

function preventDefault(e) {
  e.stopPropagation();
  e.preventDefault();
}

@styled(APP_MENU.HEADER)
@autobind
class SearchSectionHeader extends Component {
  static propTypes = {
    type: Types.string.isRequired,
    disabled: Types.bool
  };

  render() {
    const { styles: s, type, onClick, disabled, icon } = this.props;
    const heading = formatOptionToHeading(type).toUpperCase();

    return (
      <Box {...s('container')} onMouseDown={preventDefault}>
        <Box {...s('inner')}>
          {onClick && (
            <Box
              alignItems='center'
              disabled={disabled}
              onClick={onClick}
              {...s.with('wrapper')(TEXTS.APP_SEARCH.HEADING)}
            >
              <Icon
                style={{ display: 'flex', alignItems: 'center' }}
                type={icon || ICONS.APP_SEARCH[type]}
              />
              <span {...s('heading')}>{heading}</span>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default SearchSectionHeader;
