import React from 'react';

import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { PADDINGS } from 'theme';

const defaultStyles = StyleSheet({
  container: {
    rowGap: PADDINGS.XS
  }
});

interface SavedListHeaderContainer {
  children: React.ReactNode;
}

export function SavedListHeaderContainer(props: SavedListHeaderContainer) {
  const { children } = props;

  const s = useStyles(defaultStyles);

  return (
    <Box flexDirection='column' {...s('container')}>
      {children}
    </Box>
  );
}
