import React from 'react';
import Analytics from 'shared/utils/vivid-analytics';
import { ColumnType, RowType } from '../../';
import {
  TemplateDefault,
  TemplateRights,
  TemplateSentStatus,
  TemplateProgress,
  TemplateListMarker
} from './templates';

import ColumnContainer from './column-container';

const templates = {
  'listcol-rights': TemplateRights,
  'listcol-sent-status': TemplateSentStatus,
  'listcol-progress': TemplateProgress,
  'template-libraries/list-marker': TemplateListMarker
};

interface Props {
  col: ColumnType;
  row: RowType;
  listProps?: Record<string, any>;
}

export interface TemplateProps {
  row: RowType;
  col: ColumnType;
}

function ClassicListColumn({ col, row }: Props) {
  if (col.template && templates[col.template] === undefined) {
    // Custom templates
    Analytics.error({
      error: new Error(`list template not found - ${col.template}`),
      properties: {
        metaData: {
          caughtBy:
            'shell/src/view/components/classic-styled/list/column-view/column'
        }
      }
    });
    return <ColumnContainer col={col} />;
  }

  const ColumnComponent = col.template
    ? templates[col.template]
    : TemplateDefault;

  return (
    <ColumnContainer col={col}>
      <ColumnComponent row={row} col={col} />
    </ColumnContainer>
  );
}

export default ClassicListColumn;
