import React from 'react';
import _ from 'lodash';
import ColumnView from './column-view';
import { autobind } from 'core-decorators';

export type RowType = {
  data: {
    id: string | number;
    libraryName?: string;
    is_hidden?: boolean;
    [x: string]: any;
    library: {
      id: string | number;
      library_name?: string;
      isHidden?: boolean;
    };
  };
  security_user_rights?: any;
  // in knockout, for all the columns that are visible, the values are pulled out of row.data, formatted using their formatter
  // functions, then hoisted to the top level of row (see self.mapData in biglist.class.js). So currently you must pull
  // the data for display out of the top level row, rather than from row.data.
  [x: string]: any;
};

export type ColumnActionType = {
  label: string;
  callback: Function;
  isDisabled?: string;
  checkRights?: string;
};

export type ColumnType = {
  name: string;
  id: string;
  sortable?: string;
  template?: string;
  width?: string;
  orderBy?: string;
  openActionMenu?: Function;
  rightIcon?: Function | false;
  nameSuffix?: Function;
  className?: string;
  noOverflow?: boolean;
  actions?: (() => ColumnActionType[]) | ColumnActionType[];
};

export type ListPropsType = {
  rows: [RowType];
  onColumnClick: () => any;
  columns: [ColumnType];
  options: Record<string, any>;
  allChecked: boolean;
  rowIdField: string;
  setAllChecked?: Function;
  tickedIds: [string?];
  setTickedIds: Function;
  onRowClick: () => any;
  rowClasses: Function;
};

export type ListStateType = {
  lastCheckedId?: string;
};

export interface ListControllerPropsType {
  checkAllClicked: (event: React.MouseEvent | React.TouchEvent) => void;
  handleCheckboxUpdate: (object: any, Event: any) => any;
  isChecked: Function;
  getIdFromRow: Function;
}

// TODO: convert to react function component when we can use hooks
//  https://app.clubhouse.io/rexlabs/story/53592/convert-react-classic-list-components-to-functional-components-with-hooks

@autobind
class ClassicStyledList extends React.PureComponent<
  ListPropsType,
  ListStateType
> {
  state = {
    lastCheckedId: undefined
  };

  static defaultProps = {
    tickedIds: [],
    setTickedIds: _.noop,
    rowIdField: 'id'
  };

  isChecked(row) {
    const { allChecked, tickedIds = [] } = this.props;
    return allChecked
      ? !tickedIds.includes(this.getIdFromRow(row))
      : tickedIds.includes(this.getIdFromRow(row));
  }

  getIdFromRow(row) {
    return _.get(row.data, this.props.rowIdField);
  }

  checkAllClicked() {
    this.props.setAllChecked?.(!this.props.allChecked);
  }

  updateTickedIds(thisId) {
    const { rows, tickedIds = [] } = this.props;
    const { lastCheckedId } = this.state;
    const omit = !tickedIds.includes(thisId);
    const thisIndex = _.findIndex(
      rows,
      (row) => this.getIdFromRow(row) === thisId
    );
    const lastIndex = _.findIndex(
      rows,
      (row) => this.getIdFromRow(row) === lastCheckedId
    );

    const startIndex = Math.min(thisIndex, lastIndex);
    const endIndex = Math.max(thisIndex, lastIndex);

    const ids = _.slice(rows, startIndex, endIndex + 1).map(this.getIdFromRow);

    return omit ? _.union(tickedIds, ids) : _.difference(tickedIds, ids);
  }

  handleCheckboxUpdate(row, e) {
    const { tickedIds, setTickedIds } = this.props;
    const id = this.getIdFromRow(row);

    if (e.shiftKey && this.state.lastCheckedId) {
      const newTickedIds = this.updateTickedIds(id);
      setTickedIds(newTickedIds);
    } else {
      const newTickedIds = tickedIds.includes(id)
        ? _.pull(tickedIds, id)
        : _.union(tickedIds, [id]);
      setTickedIds(newTickedIds);
    }

    this.setState({
      lastCheckedId: id
    });
  }

  render() {
    return (
      <ColumnView
        {...this.props}
        checkAllClicked={this.checkAllClicked}
        handleCheckboxUpdate={this.handleCheckboxUpdate}
        isChecked={this.isChecked}
        getIdFromRow={this.getIdFromRow}
      />
    );
  }
}

export default ClassicStyledList;
