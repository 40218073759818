import React, { PureComponent, Fragment } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import Dialog from 'view/components/dialog';
import { ButtonBar } from 'view/components/button-bar';
import PaddingBox from 'view/components/padding-box';
import { DefaultButton } from 'view/components/button';
import { ICONS } from 'shared/components/icon';
import ToggleStatusDialog from './toggle-status';
import { withDialog } from 'shared/hocs/with-dialog';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { Link } from 'components/text/link';
import { InputLabel } from 'components/text/input-label';
import { Hint } from 'components/text/hint';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'theme';
import Box from '@rexlabs/box';
import dayjs from 'dayjs';
import { withModel } from '@rexlabs/model-generator';
import washListsModel from 'data/models/custom/wash-lists';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  serviceIcon: {
    position: 'relative',
    top: '-3px',
    marginRight: '3px',
    left: '-3px',
    color: COLORS.STATES.RED
  },
  overriddenIcon: {
    color: COLORS.STATES.SLATE
  },
  infoTextContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  },
  infoText: {
    display: 'inline',
    cursor: 'auto'
  },
  externalLinkIcon: {
    position: 'relative',
    left: '-3px',
    top: '1px',
    color: COLORS.PRIMARY.SAND
  }
});

@withModel(washListsModel)
@withErrorDialog
@withDialog(ToggleStatusDialog, { propName: 'toggleStatus' })
@styled(defaultStyles)
@autobind
class UpdateStatusDialog extends PureComponent {
  static propTypes = {
    phone: types.string,
    nameFirst: types.string,
    nameLast: types.string,
    address: types.string,
    service: types.string.isRequired,
    override: types.string.isRequired,
    recordString: types.string.isRequired
  };

  state = {
    washItem: {},
    override: {},
    isLoading: true
  };

  onOverrideToggleClick() {
    const { toggleStatus } = this.props;
    const { washItem } = this.state;

    toggleStatus.open({
      ...washItem,
      callback: this.overrideToggleCallback
    });
  }

  overrideToggleCallback(override) {
    const { callback } = this.props;
    const { washItem } = this.state;

    const washStatus = override.status === 'allow' ? 'allow' : 'disallow';

    this.setState(
      {
        washItem: { ...washItem, override: washStatus },
        override: { ...override }
      },
      () => {
        callback(washStatus);
      }
    );
  }

  componentDidMount() {
    const {
      phone,
      nameFirst,
      nameLast,
      address,
      service,
      override,
      recordString,
      washLists,
      errorDialog
    } = this.props;

    this.setState({
      washItem: {
        phone,
        nameFirst,
        nameLast,
        address,
        service,
        override,
        recordString
      }
    });

    if (override !== 'allow') {
      this.setState({ isLoading: false });
      return;
    }

    const method =
      service === 'TPS'
        ? washLists.getPhoneNumberOverride
        : washLists.getMailAddressOverride;
    const requestBody =
      service === 'TPS'
        ? { phoneNumber: phone }
        : { nameFirst, nameLast, address };

    method(requestBody)
      .then(({ data: { result } }) =>
        this.setState({ override: result, isLoading: false })
      )
      .catch(errorDialog.open);
  }

  renderActive() {
    const { styles: s } = this.props;
    const { washItem } = this.state;

    const Icon =
      washItem.service === 'TPS' ? (
        <div {...s('serviceIcon')}>
          <ICONS.DONT_CALL />
        </div>
      ) : (
        <div {...s('serviceIcon')}>
          <ICONS.DONT_MAIL />
        </div>
      );

    return (
      <Box alignItems={'flex-start'}>
        {Icon}
        <Hint normal dark {...s('itemText')}>
          {washItem.recordString} on {washItem.service} Registry - Contact not
          permitted.
        </Hint>
      </Box>
    );
  }

  renderOverridden() {
    const { styles: s } = this.props;
    const { washItem, override } = this.state;

    const Icon =
      washItem.service === 'TPS' ? (
        <div {...s('serviceIcon', 'overriddenIcon')}>
          <ICONS.DONT_CALL />
        </div>
      ) : (
        <div {...s('serviceIcon', 'overriddenIcon')}>
          <ICONS.DONT_MAIL />
        </div>
      );

    return (
      <Fragment>
        <Box pb={25} alignItems={'flex-start'}>
          {Icon}
          <Hint normal dark {...s('itemText')}>
            {washItem.recordString} on {washItem.service} Registry.{' '}
            {washItem.service} Override - Contact permitted.
          </Hint>
        </Box>
        <Box pb={25}>
          <Box pb={15}>
            <InputLabel formField>override details</InputLabel>
          </Box>
          <Hint normal dark>
            Override by {_.get(override, 'system_created_user.name')} on{' '}
            {dayjs(override.system_ctime).format('D MMMM YYYY')}
          </Hint>
        </Box>
        <Box>
          <Box pb={15}>
            <InputLabel formField>reason for override</InputLabel>
          </Box>
          <Hint normal dark>
            {override.reason}
          </Hint>
        </Box>
      </Fragment>
    );
  }

  render() {
    const { styles: s, closeDialog } = this.props;
    const { washItem, isLoading } = this.state;

    const ServiceLink =
      washItem.service === 'TPS' ? (
        <Link
          light
          href={'https://www.tpsonline.org.uk/tps/index.html'}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          Telephone Preference Service
        </Link>
      ) : (
        <Link
          light
          href={'https://www.mpsonline.org.uk/'}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          Mailing Preference Service
        </Link>
      );

    return (
      <Dialog
        title={`Update ${washItem.service} Status`}
        closeDialog={closeDialog}
        height={400}
        width={500}
        hasPadding={false}
        isLoading={isLoading}
      >
        <Box
          {...s('infoTextContainer')}
          alignItems={'center'}
          p={'15px 20px 15px'}
        >
          <Link {...s('infoText')} is={'p'} light undecorated unlinked regular>
            Information provided by the {ServiceLink}
          </Link>
          <ICONS.EXTERNAL_LINK_DEPRECATED {...s('externalLinkIcon')} />
        </Box>
        <Box p={'30px 20px 15px'}>
          {washItem.override === 'allow'
            ? this.renderOverridden()
            : this.renderActive()}
        </Box>
        <PaddingBox>
          <ButtonBar hasPadding={false}>
            <DefaultButton light left onClick={this.onOverrideToggleClick}>
              {washItem.override === 'allow' ? 'Remove Override' : 'Override'}
            </DefaultButton>
            <DefaultButton dark onClick={closeDialog}>
              Close
            </DefaultButton>
          </ButtonBar>
        </PaddingBox>
      </Dialog>
    );
  }
}

export default UpdateStatusDialog;
