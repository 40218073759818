import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import Select from './select';
import Option from './options/default';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import { FONT } from 'theme';
import _ from 'lodash';
import { getTimeValue, getDurationLabel } from 'utils/time';
import { withRegion } from 'src/hocs/with-region';

const timeComponentOverrides = {
  SelectOption: StyleSheet({
    container: {
      fontWeight: FONT.WEIGHTS.REGULAR
    }
  }),
  SelectValue: StyleSheet({
    container: {
      fontWeight: FONT.WEIGHTS.REGULAR
    }
  })
};

const getLabels = (hour, minute, durationStartTime) => {
  const hourDisplay = hour < 13 ? hour : hour - 12;
  const meridian = hour > 11 ? 'PM' : 'AM';
  const label = `${hourDisplay === 0 ? 12 : hourDisplay}:${`${minute}`.padStart(
    2,
    '0'
  )} ${meridian}`;
  const labelWithDuration = durationStartTime
    ? `${label} ${getDurationLabel(durationStartTime, hour, minute)}`
    : label;
  return { label, labelWithDuration };
};

const getOptionValues = (hour, minute) => {
  // TODO: This should be an object. Vivid component only allows number or string so this should be refactored.
  const value = `${hour}-${minute}`;
  const sortableValue = `${hour.toString().padStart(2, '0')}-${minute
    .toString()
    .padStart(2, '0')}`;
  return { value, sortableValue };
};

const optionComparer = (a, b) => {
  return a.sortableValue < b.sortableValue
    ? -1
    : a.sortableValue > b.sortableValue
    ? 1
    : 0;
};

const getOptions = _.memoize(
  (step, durationStartTime, defaultFirstOption) => {
    let options = [];
    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += step) {
        options.push({
          ...getLabels(hour, minute, durationStartTime),
          ...getOptionValues(hour, minute)
        });
      }
    }
    options = options.sort(optionComparer);
    if (defaultFirstOption) {
      options = []
        .concat(
          options.filter((option) => option.sortableValue >= defaultFirstOption)
        )
        .concat(
          options.filter((option) => option.sortableValue < defaultFirstOption)
        );
    }
    return options;
  },
  (...args) => JSON.stringify(args)
);

@withRegion
@autobind
class TimeSelect extends PureComponent {
  state = {
    extraOption: []
  };

  defaultFirstOption = this.props.region.isEU ? '08-00' : '09-00';

  addSuggestiveOption(value) {
    const { step = 5, durationStartTime } = this.props;

    if (!value) {
      return;
    }
    const { minute = 0, hour } = value;
    if ((hour || hour === 0) && minute % step !== 0) {
      const hourNumber = parseInt(hour, 10);
      const minuteNumber = parseInt(minute, 10);
      this.setState({
        extraOption: [
          {
            ...getLabels(hourNumber, minuteNumber, durationStartTime),
            ...getOptionValues(hourNumber, minuteNumber)
          }
        ]
      });
    }
  }

  componentDidMount() {
    const { suggestive } = this.props;
    if (suggestive) {
      this.addSuggestiveOption(this.props.value);
    }
  }

  componentDidUpdate(prevProps) {
    // Provide a label for valid values passed in by the form or
    // reset to previous value if it's 'NaN'
    // (don't replace with isNaN, we don't want to catch non-numeric strings).

    const { value, onChange, suggestive, durationStartTime } = this.props;
    if (!value) {
      return value;
    }
    if (prevProps.value !== value && value.hour !== 'NaN' && suggestive) {
      this.addSuggestiveOption(value, durationStartTime);
    } else if (prevProps.value !== value && value.hour === 'NaN' && onChange) {
      onChange({
        target: {
          value: prevProps.value
        }
      });
    }
  }

  handleChange(e) {
    const { onChange, suggestive, durationStartTime } = this.props;
    const value = getTimeValue(e.target.value);
    if (suggestive) {
      this.addSuggestiveOption(value, durationStartTime);
    }
    if (onChange) {
      onChange({
        persist: e.persist,
        target: {
          value
        }
      });
    }
  }

  handleBlur(e) {
    const { onBlur, suggestive, durationStartTime } = this.props;
    const value = getTimeValue(e.target.value);
    if (suggestive) {
      this.addSuggestiveOption(value, durationStartTime);
    }
    if (onBlur) {
      onBlur({
        persist: e.persist,
        target: {
          value
        }
      });
    }
  }

  render() {
    const {
      name,
      placeholder,
      disabled,
      value,
      step,
      durationStartTime,
      defaultFirstOption,
      ...props
    } = this.props;

    // It seems like it should not necessary to pass the OptionSelected prop
    // here - as the menu should default to wrapping Option component - but for
    // some reason it needs to be. Otherwise, the isSelected prop gets 'lost'.
    return (
      <StylesProvider
        components={timeComponentOverrides}
        prioritiseParentStyles={false}
      >
        <Select
          disabled={disabled}
          value={
            value
              ? value.hour || value.hour === 0 // 0 is midnight
                ? `${value.hour}-${value.minute || '0'}`
                : value
              : ''
          }
          multi={false}
          options={[
            ...this.state.extraOption,
            ...getOptions(
              step || 5,
              durationStartTime,
              defaultFirstOption || this.defaultFirstOption
            )
          ]}
          name={name}
          placeholder={placeholder}
          pluckLabel={(option, optionProps) =>
            optionProps && optionProps.hasDuration
              ? option.labelWithDuration
              : option.label
          }
          debounce={null}
          {...props}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          Option={(props) => (
            <Option {...props} hasDuration={!!durationStartTime} />
          )}
          OptionSelected={(props) => (
            <Option {...props} hasDuration={!!durationStartTime} isSelected />
          )}
          shouldCloseOnBlur
        />
      </StylesProvider>
    );
  }
}

export default TimeSelect;
