import React, { Component } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { PADDINGS, COLORS, FONT, HEIGHTS } from 'theme';

import NO_SEARCH_RESULTS from 'assets/no-search-results.png';

const GreyCircle = () => {
  return (
    <Box
      mt={PADDINGS.XXL}
      style={{
        backgroundImage: `url(${NO_SEARCH_RESULTS})`,
        // backgroundColor: COLORS.BLUE_GREY_LIGHT,
        // borderRadius: '50%',
        width: '100px',
        height: '100px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    />
  );
};

@styled(
  StyleSheet({
    title: {
      color: COLORS.DARK_GREY_80,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontSize: FONT.SIZES.LARGE
    },
    description: {
      maxWidth: '250px'
    },
    link: {
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      color: COLORS.BLUE_GREY,
      '&:hover': {
        color: COLORS.BLUE_GREY
      }
    }
  })
)
class EmptySearch extends Component {
  render() {
    const { styles: s, isScoped } = this.props;
    return (
      <div
        style={{ ...(isScoped && { paddingTop: HEIGHTS.APP_MENU.HEADING }) }}
      >
        <Box mb={PADDINGS.XXL} alignItems='center' flexDirection='column'>
          <GreyCircle />
          <Box pt={PADDINGS.S} pb={PADDINGS.XS} {...s('title')}>
            No results
          </Box>
          <Box {...s('description')}>
            If you’re having trouble finding what you need,{' '}
            <a
              href='https://support.rexsoftware.com/hc/en-us/articles/360033596793-Global-Search-in-Rex'
              target='_blank'
              rel='noopener noreferrer'
              {...s('link')}
            >
              check out our search tips
            </a>
          </Box>
        </Box>
      </div>
    );
  }
}

export default EmptySearch;
