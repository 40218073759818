import { useMemo } from 'react';
import { useModelState } from '@rexlabs/model-generator';

import sessionModel from 'data/models/custom/session';
import { usePermissions } from 'hooks/use-permissions';
import { useFilterConfigItem } from 'features/custom-reporting/utils/get-filter-field-config';
import { UserSelectedReportState } from 'features/custom-reporting/hooks/use-user-selected-report-state';

function getUserCriteria({ name, value }: { name?: string; value: string[] }) {
  return [{ name, type: 'in', value }];
}

export function useUserCriteria({
  fieldPreference,
  userSelectedReportState
}: {
  moduleName: string;
  fieldPreference?: string;
  userSelectedReportState?: UserSelectedReportState;
}) {
  const users = userSelectedReportState?.selectedUserFilter;
  const session = useModelState(sessionModel);
  const { check } = usePermissions();
  const hasViewAllPermissions = check('custom_reports.view_all');
  const groupMemberIds: string[] = session.group_member_ids || [];

  const { getCriteria = getUserCriteria, fieldName } =
    useFilterConfigItem({
      filterType: 'user',
      fieldPreference
    }) || {};

  const criteria = useMemo(
    () => {
      if (users?.length) {
        const value = users?.map((user) => user?.value);

        return getCriteria({ name: fieldName, value });
      }
      if (!hasViewAllPermissions) {
        return getCriteria({ name: fieldName, value: groupMemberIds });
      }
      return [];
    },
    // TODO - update default based on permissions -
    // SC - https://app.shortcut.com/rexlabs/story/67300/user-select-permissions-for-default-state
    [fieldName, getCriteria, users]
  );

  return criteria;
}
