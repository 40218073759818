import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import DefaultOption from 'view/components/input/select/options/default';
import BuildingsFixture from 'view/components/input/select/fixtures/building';

const TYPE = 'buildings';

const buildingsModel = new Generator(TYPE).createEntityModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
buildingsModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('Buildings::autocomplete', {
        search_string: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((building) => {
          return {
            value: building.id,
            label: building.name,
            data: building,
            model: buildingsModel
          };
        })
      ),
  Option: DefaultOption,
  Fixture: BuildingsFixture
};

export default buildingsModel;
