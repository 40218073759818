import React from 'react';

import { InfoTooltip } from 'view/components/tooltip';

import { SavedListItemTooltipContainer } from '../tooltip-container';

type InfoTooltipProps = React.ComponentProps<typeof InfoTooltip>;

export function SavedListiItemInfoTooltip(props: InfoTooltipProps) {
  const { content } = props;

  return (
    <SavedListItemTooltipContainer>
      <InfoTooltip content={content} />
    </SavedListItemTooltipContainer>
  );
}
