import React, { useCallback } from 'react';
import { Id } from '@rexlabs/model-generator';

import { ICONS } from 'shared/components/icon';
import { EVENTS } from 'shared/utils/analytics';
import Analytics from 'shared/utils/vivid-analytics';

import ShellDialogsBridge from 'data/classic-bridges/dialogs-shell';

import { RecordPreviewActionBarButton } from '../record-preview-action-bar';

export function useClickToDial({ contactId }: { contactId: Id }) {
  return useCallback(() => {
    Analytics.track({
      event: EVENTS.PREVIEW_POPOUTS.MAKE_CALL
    });

    ShellDialogsBridge.makeCall.open({
      contactId
    });
  }, [contactId]);
}

export function RecordPreviewClickToDialAction({
  contactId
}: {
  contactId: Id;
}) {
  const onCallClick = useClickToDial({ contactId });
  return (
    <RecordPreviewActionBarButton
      iconType={ICONS.PHONE_SMALL}
      onClick={onCallClick}
    >
      Call
    </RecordPreviewActionBarButton>
  );
}
