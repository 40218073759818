import { get, sortBy } from 'lodash';
import {
  filterColumns,
  getColumnsWeNeedDataFor
} from 'features/custom-reporting/modules/helpers';
import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';

export interface CustomField {
  core_tab_id: string | null;
  field_fq_name: string;
  field_id: string;
  field_name: string;
  field_options: null | string[];
  field_priority: number;
  field_settings: {
    record_service?: string;
    display_as?: string;
    decimals?: number;
    format?: string;
  } | null;
  field_type: {
    id: string;
    text: string;
  };
  group_id: string;
  group_priority: number;
  is_privilege_locked: null;
  tab_id: string;
  tab_priority: number;
}

export type CustomFieldDefinitions = Record<string, CustomField>;

const fieldMap = {
  checkbox: ['booleanColumn', 'customFieldBooleanColumn'],
  date: ['dateColumn', 'customFieldDateColumn'],
  time: ['defaultColumn', 'customFieldTimeColumn'],
  double: ['numberColumn', 'customFieldNumberColumn'],
  text: ['defaultColumn', 'customFieldStringColumn'],
  longtext: ['defaultColumn', 'customFieldStringColumn'],
  file: ['defaultColumn', 'customFieldFileColumn'],
  select: ['defaultColumn', 'customFieldStringColumn'],
  multi_select: ['tagColumn', 'customFieldSelectColumn'],
  record: {
    Contacts: ['contactRecordColumn', 'customFieldRecordColumn'],
    Listings: ['listingRecordColumn', 'customFieldRecordColumn'],
    Properties: ['propertyRecordColumn', 'customFieldRecordColumn'],
    Projects: ['projectRecordColumn', 'customFieldRecordColumn'],
    ProjectStages: ['projectStageRecordColumn', 'customFieldRecordColumn'],
    default: ['customFieldRecordColumn', 'customFieldDefaultRecordColumn']
  },
  multi_record: {
    // TODO add all supported multi record columns
    // JIRA: https://rexsoftware.atlassian.net/browse/RADI-6184
    Listings: ['customFieldMultiListingColumn', 'customFieldMultiRecordColumn'],
    default: ['customFieldRecordColumn', 'customFieldMultiRecordColumn']
  }
};

function getCustomFieldColumnType(
  customField: CustomField
): string[] | undefined {
  const fieldType = customField.field_type.id;
  const format = customField.field_settings?.format;

  if (fieldType === 'double' && format === 'currency') {
    return ['currencyColumn', 'customFieldNumberColumn'];
  }

  if (fieldType === 'record') {
    const recordService = customField.field_settings?.record_service || '';
    return fieldMap.record[recordService] || fieldMap.record.default;
  }

  if (fieldType === 'multi_record') {
    const recordService = customField.field_settings?.record_service || '';
    return (
      fieldMap.multi_record[recordService] || fieldMap.multi_record.default
    );
  }

  // maybe TODO, depending on whether we want urls to be clickable
  // if fieldType === 'text' && field.field_settings.display_as === 'url' {
  // return ['customFieldUrlColumn'];
  // }

  return fieldMap[fieldType];
}

function fieldIsSupported(field) {
  const isSupportedFieldType = Object.keys(fieldMap).includes(
    field.field_type?.id
  );
  const isSupportedRecordType =
    field.field_type?.id === 'record'
      ? Object.keys(fieldMap.record).includes(
          field.field_settings?.record_service || ''
        )
      : true;
  const isSupportedMultiRecordType =
    field.field_type?.id === 'multi_record'
      ? Object.keys(fieldMap.multi_record).includes(
          field.field_settings?.record_service || ''
        )
      : true;

  return (
    isSupportedFieldType && isSupportedRecordType && isSupportedMultiRecordType
  );
}

interface GetCustomFieldColumnsParams {
  customFields?: CustomFieldDefinitions;
  customFieldResource: string;
}

// TODO: Add unit tests for the below 2 functions
// JIRA: https://rexsoftware.atlassian.net/browse/RADI-6186
export function getCustomFieldColumns({
  customFields = {},
  customFieldResource
}: GetCustomFieldColumnsParams) {
  const customFieldArray = Object.values(customFields).filter(fieldIsSupported);

  const customFieldColumns: ReportColumnConfig = customFieldArray.length
    ? customFieldArray.map((customFieldDef) => {
        const { field_fq_name, field_id } = customFieldDef;
        return {
          headerName: field_fq_name.split('.').slice(-2).join(': '),
          field: 'custom_field_values',
          colId: field_id,
          width: 300,
          // previously we were building this info into the colId but the string wrangling
          // got ugly fast
          customFieldId: field_id,
          customFieldResource,
          type: getCustomFieldColumnType(customFieldDef)
        };
      })
    : [];

  return sortBy(customFieldColumns, 'headerName');
}

export function getCustomFieldsForVisibleColumns({
  allColumnsMapped,
  selectedGridColumns,
  filteredGridColumns
}) {
  const allCustomFieldColumns = filterColumns(
    allColumnsMapped,
    (column) => !!column?.customFieldId
  );

  const customFieldColumnDefs = getColumnsWeNeedDataFor({
    columnState: selectedGridColumns,
    filterState: filteredGridColumns,
    allColumnsMapped: allCustomFieldColumns
  }).filter(Boolean);

  return customFieldColumnDefs?.reduce((customFieldVariables, column) => {
    if (column?.customFieldId) {
      const { customFieldResource: resource, customFieldId } = column;
      const resourceVariableName = `${resource}_custom_field_ids`;
      const numberId = parseInt(customFieldId, 10);

      if (!customFieldVariables[resourceVariableName]) {
        return {
          ...customFieldVariables,
          [resourceVariableName]: [numberId],
          [`include_${resource}_custom_fields`]: true
        };
      }

      const prevCustomIds = customFieldVariables[resourceVariableName];
      if (!prevCustomIds.includes(customFieldId)) {
        return {
          ...customFieldVariables,
          [resourceVariableName]: [...prevCustomIds, numberId]
        };
      }
      return customFieldVariables;
    }
    return customFieldVariables;
  }, {});
}

export function getCustomFieldValue(valueKey) {
  return ({ data, colDef }) => {
    const customFieldValues = get(data, colDef?.field || '');
    const customFieldId = colDef.customFieldId;

    const value = customFieldValues?.find?.(
      (item) => item?.field?.id === parseInt(customFieldId, 10)
    );
    // we're not supplying empty value here, that's what the valueFormatter is for
    return get(value, `value.${valueKey}`);
  };
}
