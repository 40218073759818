import React, { ReactNode } from 'react';
import Box, { BoxStylingProps } from '@rexlabs/box';
import { Body } from 'components/text/body';
import Icon, { ICONS } from 'shared/components/icon';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import { PADDINGS } from 'shared/theme';

interface AlertBannerProps {
  children?: ReactNode;
  type?: 'warning' | 'error' | 'silent' | 'disabled';
  message?: string | ReactNode;
  style?: BoxStylingProps;
}

const styles = StyleSheet({
  alert: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: COLORS.PRIMARY.SUNSHINE_YELLOW,
    padding: 10,
    margin: `${PADDINGS.S} 0 0`,
    borderRadius: '2px'
  },
  silent: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  },
  disabled: {
    backgroundColor: COLORS.CHECKBOX.DISABLED
  },
  message: {
    lineHeight: 0
  },
  icon: {
    margin: '1px 10px 0 0',
    color: COLORS.PRIMARY.GREY_DARK
  }
});

const AlertBanner = ({
  children,
  type = 'warning', // for future use. (success, info banner)
  message,
  style
}: AlertBannerProps) => {
  const s = useStyles(styles);
  return (
    <Box
      {...s(
        'alert',
        { silent: type === 'silent' },
        { disabled: type === 'disabled' }
      )}
      {...style}
    >
      <Box alignItems='flex-start'>
        <Icon type={ICONS.INFO_SMALL} height={12} width={12} {...s('icon')} />
        <Body semibold small dark {...s('message')}>
          {message}
        </Body>
      </Box>
      {children &&
        (typeof children === 'string' ? (
          <Box mt={5} ml={22} dangerouslySetInnerHTML={{ __html: children }} />
        ) : (
          <Box mt={5} ml={22}>
            {children}
          </Box>
        ))}
    </Box>
  );
};

export default AlertBanner;
