import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { ButtonBar } from 'view/components/button-bar';
import { TextButton, DefaultButton } from 'view/components/button';
import { ReactForms, Form, FormField } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';
import { Grid, Column } from 'shared/components/grid';
import { ValueListSelect } from 'view/components/input/select';
import { autobind } from 'core-decorators';

import { withErrorDialog } from 'src/hocs/with-error-dialog';
import _ from 'lodash';

import accountUsersModel from 'data/models/value-lists/account-users';

@withErrorDialog
@autobind
class EditUserAttributionDialog extends PureComponent {
  handleSubmit(values) {
    const { callback: onSave, closeDialog, errorDialog } = this.props;
    return Promise.all([
      onSave({
        ...values,
        user: {
          id: _.get(values, 'user.value'),
          name: _.get(values, 'user.label')
        }
      })
    ])
      .then(closeDialog)
      .catch(errorDialog.open);
  }

  render() {
    const { onLoad, closeDialog, data, excludeIds } = this.props;
    return (
      <Dialog
        title='Attributed User'
        height={200}
        onLoad={onLoad}
        closeDialog={closeDialog}
      >
        <ReactForms
          handleSubmit={this.handleSubmit}
          initialValues={
            data
              ? {
                  percentage: data.percentage,
                  user: {
                    value: _.get(data, 'user.id'),
                    label: _.get(data, 'user.name')
                  }
                }
              : undefined
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid mt={-15}>
                <Column width={6}>
                  <FormField
                    name='user'
                    label='user'
                    Input={ValueListSelect}
                    inputProps={{
                      valueAsObject: true,
                      models: [accountUsersModel],
                      filter: (options) =>
                        options.filter(
                          (option) =>
                            !excludeIds || !excludeIds.includes(option.value)
                        )
                    }}
                  />
                </Column>
                <Column width={6}>
                  <FormField
                    name='percentage'
                    label='percentage'
                    Input={TextInput}
                  />
                </Column>
              </Grid>
              <ButtonBar isLoading={isSubmitting}>
                <TextButton blue onClick={closeDialog}>
                  Cancel
                </TextButton>
                <DefaultButton dark onClick={submitForm}>
                  Done
                </DefaultButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default EditUserAttributionDialog;
