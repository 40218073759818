import contactsModel, { ContactStub } from 'data/models/entities/contacts';
import { ContactSelectValue } from 'features/listings/dialogs/view-edit-offer/types';

// TODO: We do this a fair amount of times in the code base, where we need to add
// data returned from BE, back into a select in a form.
// https://app.clubhouse.io/rexlabs/story/55229/add-util-for-where-we-change-the-shape-of-data-returned-for-select
export function mapContactToSelectValue(
  contactData: ContactStub
): ContactSelectValue {
  return {
    data: contactData,
    id: contactData.id,
    value: contactData.id,
    label: contactData.name,
    model: contactsModel
  };
}
