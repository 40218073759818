import React from 'react';
import { ICONS } from 'shared/components/icon';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'theme';

const ChevronIcon = ICONS.CHEVRON_RIGHT_12 as any;

const backButtonStyles = StyleSheet({
  backButton: {
    fontSize: '12px',
    lineHeight: '14px',
    color: COLORS.DARK_GREY,
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    background: 'none',
    border: 0,
    alignSelf: 'flex-start',
    gap: '3px',
    webkitAppearance: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  backButtonChevron: {
    transform: 'rotate(180deg)'
  }
});

type AiBackButtonProps = {
  onClick: () => void;
  label: string;
};

export function AiBackButton({ onClick, label }: AiBackButtonProps) {
  const s = useStyles(backButtonStyles);

  return (
    <button {...s('backButton')} onClick={onClick}>
      <ChevronIcon {...s('backButtonChevron')} />
      {label}
    </button>
  );
}
