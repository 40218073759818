import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, TEXTS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';

import { VIEW_MODES } from 'view/components/navigation/app-search/base';
import {
  formatOptionToHeading,
  MAXIMUM_GLOBAL_SHOWN_RESULTS
} from 'view/components/navigation/app-search/utils';

@styled(
  StyleSheet({
    container: {
      cursor: 'pointer',
      marginTop: `-${PADDINGS.M}`,
      padding: `${PADDINGS.L} 0`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    text: {
      ...TEXTS.APP_SEARCH.MENU_HEADING,
      paddingRight: PADDINGS.XS
    },
    icon: {
      transform: 'rotate(-90deg)',
      color: COLORS.BLUE_GREY
    }
  })
)
@autobind
class ShowAll extends Component {
  handleClick(event) {
    const { onClick } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onClick();
  }

  render() {
    const { totalCount, type, scopedType, styles: s } = this.props;

    if (
      !totalCount ||
      totalCount < MAXIMUM_GLOBAL_SHOWN_RESULTS ||
      scopedType !== VIEW_MODES.GLOBAL_SEARCH
    ) {
      return null;
    }

    return (
      <div {...s.with('container')('needsclick')} onClick={this.handleClick}>
        <div {...s.with('text')('needsclick')}>
          Show all {formatOptionToHeading(type)} (<span>{totalCount}</span>)
        </div>
        <Icon type={ICONS.CHEVRON} {...s.with('icon')('needsclick')} />
      </div>
    );
  }
}

export default ShowAll;
