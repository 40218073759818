import React from 'react';

import Box from '@rexlabs/box';

interface SavedListHeadingContainerProps {
  children: React.ReactNode;
}

export function SavedListHeadingContainer(
  props: SavedListHeadingContainerProps
) {
  const { children } = props;

  return (
    <Box flexDirection='row' alignItems='center' justifyContent='space-between'>
      {children}
    </Box>
  );
}
