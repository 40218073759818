import _ from 'lodash';

import {
  AppointmentRecordData,
  EventLocationData
} from 'hooks/use-edit-appointment';
import { SendMailMergeRecordData } from 'components/record-preview/record-preview-action-bar';

import propertiesModel, { PropertyStub } from 'data/models/entities/properties';
import { formatAddress } from 'utils/formatters';
import { Region } from 'hooks/use-region';

function getAppointmentData({
  fullAddress,
  propertyImage,
  property
}: {
  fullAddress: string;
  propertyImage?: string;
  property: PropertyStub;
}) {
  const stub = {
    id: property?.id,
    address: fullAddress,
    category: property?.property_category,
    image: propertyImage,
    status: '',
    security_user_rights: [],
    system_owner_user: property?.system_owner_user
  };

  const propertySelectItem: AppointmentRecordData = {
    id: property?.id,
    label: fullAddress,
    service: 'Properties',
    stub
  };

  const eventLocation: EventLocationData = {
    data: stub,
    label: fullAddress,
    model: propertiesModel,
    value: property?.id
  };

  return {
    event_location: eventLocation,
    records: [propertySelectItem]
  };
}

export function getPropertyPreviewPopoutData({
  property,
  region
}: {
  property?: PropertyStub;
  region?: Region;
}) {
  const imageProperty =
    property?.property_image || property?.default_property_image;
  const propertyImage = imageProperty?.thumbs['200x150']?.url;

  const propertyCategory = property?.property_category?.text;

  const recordTypeHeading = `Property${
    propertyCategory ? ` — ${propertyCategory}` : ''
  }`;

  const streetAddress = formatAddress(
    _.pick(property, [
      'adr_street_name',
      'adr_unit_number',
      'adr_street_number',
      'adr_building',
      'property_category'
    ]),
    region
  );

  const suburb = formatAddress(
    _.pick(property, ['adr_postcode', 'adr_locality', 'adr_suburb_or_town']),
    region
  );

  const fullAddress = formatAddress(
    _.pick(property, [
      'adr_street_name',
      'adr_unit_number',
      'adr_street_number',
      'adr_building',
      'property_category',
      'adr_postcode',
      'adr_locality',
      'adr_suburb_or_town'
    ]),
    region
  );

  const newNoteData = {
    id: '',
    _related: {
      note_properties: property ? [{ property: property }] : []
    }
  };

  const newReminderData = {
    property: property
  };

  const newAppointmentData =
    property &&
    getAppointmentData({
      fullAddress,
      propertyImage,
      property
    });

  const newMailMergeData: SendMailMergeRecordData | undefined = property && {
    id: property?.id,
    name: fullAddress,
    service: 'property'
  };

  return {
    propertyImage,
    propertyCategory,
    recordTypeHeading,
    streetAddress,
    suburb,
    newNoteData,
    newReminderData,
    newAppointmentData,
    newMailMergeData
  };
}
