import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import { styled } from '@rexlabs/styling';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';
import navModel from 'data/models/custom/nav';
import uiModel from 'data/models/custom/ui';
import { withModel } from '@rexlabs/model-generator';
// TODO: revert this 'rexfest notification number' logic after rexfest https://app.clubhouse.io/rexlabs/story/53016/rexfest-button-notification-revert-temp-hacks-that-enable-notifications-to-hide-be-reused
import announcementsModel from 'data/models/custom/announcements';
import { checkUserHasPermission } from 'utils/rights';

import { getHref } from '../utils';
import MenuTooltips from 'view/components/navigation/shell/menu/components/tooltips';
import menuStyles from './styles';
import MenuItem from './menu-item';
import SubMenuItem from './sub-menu-item';
import ExpandMenuItemToggle from './expand-menu-item';

const ANIMATION_BUFFER_MS = 300;

@withModel(announcementsModel)
@withModel(navModel)
@withModel(uiModel)
@connect((state) => ({ session: state.session }))
@styled(menuStyles)
@autobind
class DesktopTabletMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      hasMounted: false
    };
  }

  _refs = {};

  // TODO: This is a hack to get around some referential identity issues that exist in withModel
  shouldComponentUpdate(nextProps, nextState) {
    return (
      _.some(
        Object.keys(nextProps),
        (propKey) => !_.isEqual(nextProps[propKey], this.props[propKey])
      ) ||
      _.some(
        Object.keys(nextState),
        (stateKey) => !_.isEqual(nextState[stateKey], this.state[stateKey])
      )
    );
  }

  componentDidMount() {
    setTimeout(() => {
      window.document.body.addEventListener('click', this.handleDocumentClick);
      this.setState({ hasMounted: true });
    }, ANIMATION_BUFFER_MS);
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('click', this.handleDocumentClick);
  }

  checkUserHasPermission(accessRights) {
    const { session } = this.props;
    return checkUserHasPermission((dotNotation) => _.get(session, dotNotation))(
      accessRights
    );
  }

  handleDocumentClick(e) {
    if (
      this.state.selected &&
      this._refs.menu &&
      !this._refs.menu.contains(e.target)
    ) {
      this.clearSelection();
    }
  }

  clearSelection() {
    this.setState({ selected: null });
  }

  handleItemClick(item, event) {
    const { ui, device, nav } = this.props;

    if (item.subMenu && item.subMenu.length) {
      this.setState({
        selected: this.state.selected === item.id ? null : item.id,
        activeHoverId: null
      });
      return;
    }

    const { onClick, isExternalLink } = item;
    const { session } = this.props;
    const href = getHref(item, session);

    onClick?.();

    const openInNewTab =
      event.metaKey || event.ctrlKey || event.button === 1 || isExternalLink;
    if (openInNewTab) {
      return window.open(href, '_blank', 'noopener,noreferrer');
    }

    const activeRoute = decodeURI(
      window.location.href.substr(window.location.origin.length)
    );

    if (href) {
      if (activeRoute !== href) {
        ui.loadingIndicatorOn({ message: 'navigating' });

        if (device.isTablet) {
          // On tablet we want to close the nav when the user selects
          // a menu item that will lead to a new route
          nav.toggleOpen();
        }

        push({ config: parseUrlToRoute(href) });

        this.clearSelection();
      }
    }
  }

  setActiveHoverId(id) {
    if (this.state.selected) return;
    this.setState({
      activeHoverId: id
    });
  }

  splitItems = _.memoize((items) =>
    items.reduce(
      ({ mainItems, fixedItems }, item) => {
        if (!item.isFixedDesktop) {
          mainItems.push(item);
        } else {
          fixedItems.push(item);
        }
        return { mainItems, fixedItems };
      },
      { mainItems: [], fixedItems: [] }
    )
  );

  render() {
    const {
      styles: s,
      style,
      hide,
      nav,
      items,
      localState,
      handleModeUpdate,
      announcement
    } = this.props;
    const { activeHoverId } = this.state;

    const containerExpanded = nav.isOpen || this.state.selected;
    const selectedItem = items.find((item) => item.id === this.state.selected);

    let subItems = selectedItem ? selectedItem.subMenu || [] : [];
    if (_.isFunction(subItems)) {
      subItems = subItems(localState);
    }

    const SubMenuComponent = selectedItem
      ? selectedItem.SubMenuComponent
      : null;

    const { mainItems, fixedItems } = this.splitItems(items);

    return (
      <menu
        style={style}
        {...s('container', {
          containerExpanded,
          containerWithAnnouncement: announcement,
          hide
        })}
        ref={(e) => (this._refs.menu = e)}
      >
        <div {...s('listWrapper')} id={'menu-list-wrapper'}>
          {/* Main menu list, scrollable */}
          <ul {...s('listMain')}>
            {mainItems.map((item, index) => (
              <MenuItem
                key={index}
                index={index}
                item={item}
                navIsOpen={nav.isOpen}
                selected={this.state.selected}
                hasMounted={this.state.hasMounted}
                activeRoute={this.props.activeRoute}
                agencyColor={this.props.agencyColor}
                setActiveHoverId={this.setActiveHoverId}
                handleItemClick={this.handleItemClick}
              />
            ))}
            {/* This fake item is just for rendering underneath all menu items! */}
            <li {...s('fakeItem')} />
          </ul>

          {/* Fixed top level menu item at the bottom of the menu sidebar */}
          <ul {...s('listFixed')}>
            {fixedItems.map((item, index) => (
              <MenuItem
                key={index}
                index={index}
                item={item}
                navIsOpen={nav.isOpen}
                selected={this.state.selected}
                hasMounted={this.state.hasMounted}
                activeRoute={this.props.activeRoute}
                agencyColor={this.props.agencyColor}
                setActiveHoverId={this.setActiveHoverId}
                handleItemClick={this.handleItemClick}
              />
            ))}
          </ul>
        </div>

        {/* This is the second tier menu, it will automatically be populated
         with the currently selected menu's submenus */}
        <ul
          {...s('listSub', {
            listSubVisible: !!this.state.selected,
            noAnimate: !this.state.hasMounted
          })}
        >
          <div {...s('innerSub', { innerSubVisible: !!this.state.selected })}>
            {SubMenuComponent ? (
              <SubMenuComponent {...localState} />
            ) : (
              subItems.map((item, index) => (
                <SubMenuItem
                  key={index}
                  index={index}
                  item={item}
                  handleItemClick={this.handleItemClick}
                  checkUserHasPermission={this.checkUserHasPermission}
                />
              ))
            )}
          </div>
        </ul>

        {!nav.isOpen && (
          <MenuTooltips
            fixedItems={fixedItems}
            mainItems={mainItems}
            activeHoverId={activeHoverId}
          />
        )}

        <ExpandMenuItemToggle
          setActiveHoverId={this.setActiveHoverId}
          navIsOpen={nav.isOpen}
          handleModeUpdate={handleModeUpdate}
          clearSelection={this.clearSelection}
        />
      </menu>
    );
  }
}

export default DesktopTabletMenu;
