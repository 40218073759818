import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import UkSuburbOption from 'view/components/input/select/options/uk-suburb';
import UkSuburbValue from 'view/components/input/select/values/uk-suburb';

const TYPE = 'uksuburbs';

const ukSuburbModel = new Generator(TYPE).createSystemListModel();

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
ukSuburbModel.select = {
  autocomplete: (searchTerm) => {
    return api
      .post('SystemValues::autocompleteUkSuburbs', {
        search_suburb: searchTerm
      })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((suburb) => ({
          value: suburb.suburb_or_town,
          label: suburb.suburb_or_town,
          data: suburb,
          model: ukSuburbModel
        }))
      );
  },
  Option: UkSuburbOption,
  Value: UkSuburbValue
};

export default ukSuburbModel;
