import React from 'react';
import { Context } from 'components/text/context';

export function RecordPreviewRecordType({
  children
}: {
  children: React.ReactNode;
}) {
  return <Context>{children}</Context>;
}
