import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

const actionCreators = {
  getDefaultConnectionForDeliveryType: {
    request: (data) =>
      api.post(
        'AdminOutboundSettings::getDefaultConnectionForDeliveryType',
        data
      ),

    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

export default new Generator('adminOutboundSettings').createModel({
  actionCreators
});
