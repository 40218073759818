import React, { PureComponent } from 'react';
import types from 'prop-types';
import { StyleSheet, styled, StylesProvider } from '@rexlabs/styling';
import { TEXTS, UTILS } from 'theme';
import Box from '@rexlabs/box';
import { ArrowDropdown } from 'view/components/action-menu';

const overrides = {
  Icon: StyleSheet({
    container: {
      marginLeft: '5px',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  })
};

const defaultStyles = StyleSheet({
  container: {
    height: '44px',
    padding: '0 10px',
    position: 'relative',
    overflow: 'visible',
    ...TEXTS.CONTENT.INPUT_TEXT_MEDIUM
  },

  textSpanContainer: {
    display: 'flex',
    maxWidth: '100%'
  },

  actionMenu: {
    position: 'absolute',
    right: '0'
  },

  textSpan: {
    maxWidth: '100%'
  },

  textSpanEllipsis: {
    ...UTILS.truncate
  },

  textSpanWithActionMenu: {
    marginRight: '10px'
  },

  textSpanContainerWithActionMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  left: {
    justifyContent: 'flex-start'
  },

  center: {
    justifyContent: 'center'
  },

  right: {
    justifyContent: 'flex-end'
  },

  emptyCell: {
    ...TEXTS.CONTENT.PLACEHOLDER
  }
});

@styled(defaultStyles)
class RexBasicCell extends PureComponent {
  static propTypes = {
    align: types.oneOf(['left', 'center', 'right'])
  };

  static defaultProps = {
    align: 'left'
  };

  render() {
    const {
      styles: s,
      children,
      align,
      actionMenuItems,
      style,
      emptyCellText,
      ellipsis,
      ...props
    } = this.props;
    return (
      <StylesProvider components={overrides} prioritiseParentStyles={false}>
        <Box
          alignItems='center'
          flexWrap='no-wrap'
          {...s.with('container', align)(style)}
          {...props}
        >
          {children ? (
            <div
              {...s('textSpanContainer', {
                textSpanContainerWithActionMenu: actionMenuItems
              })}
            >
              <span
                {...s('textSpan', {
                  textSpanEllipsis: ellipsis,
                  textSpanWithActionMenu: actionMenuItems
                })}
              >
                {children}
              </span>
            </div>
          ) : (
            <div {...s('emptyCell')}>{emptyCellText || '-'}</div>
          )}
          {actionMenuItems && (
            <span {...s('actionMenu')}>
              <ArrowDropdown tiny items={actionMenuItems} />
            </span>
          )}
        </Box>
      </StylesProvider>
    );
  }
}

export default RexBasicCell;
