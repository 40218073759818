import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { store } from 'src/store';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

// As the custom mock may not exist, we need to fallback to the default mock.
// Once feature has been released, we can delete this block of code.
let mock;
// try {
//   mock = require(`./mock-privacy-settings-response`)
//     .mockPrivacySettingsWingsResponse;
// } catch (error) {
// eslint-disable-next-line prefer-const
mock =
  require('./mock-privacy-settings-response.default').mockPrivacySettingsWingsResponse;
// }

type CommunicationMethodName = 'letter' | 'email' | 'sms' | 'phone';
type FeatureName =
  | 'match_campaigns'
  | 'single_merge'
  | 'bulk_merge'
  | 'newsletters'
  | 'phone_calls';

type Action = { id: 'block' | 'warn'; text: 'Block' | 'Warn' } | null;

interface ConsentObj {
  consent_given: boolean;
  action: Action;
}

type CommunicationMethod = {
  [key in CommunicationMethodName]: ConsentObj;
};

interface Feature extends ConsentObj {
  communication_methods: CommunicationMethod;
}

export type PrivacySettingsItem = {
  [key in FeatureName]?: Feature;
};

export interface PrivacyProfile {
  consents: [];
  contact_by_email: boolean | null;
  contact_by_letter: boolean | null;
  contact_by_phone: boolean | null;
  contact_by_sms: boolean | null;
  do_not_contact: boolean | null;
}

const initialState = {
  consents: [],
  communicationPreferences: {}
};

function bypassErrors(currentAction) {
  const { getState } = store;

  const ignoreErrorsPrivilege = _.get(
    _.get(getState(), 'session.privileges'),
    'privacy.ignore_blocking_errors'
  );
  const actionId = _.get(currentAction, 'action.id') || null;
  const hasAction = actionId !== null;

  return ignoreErrorsPrivilege && hasAction ? 'warn' : actionId;
}

function getActionsForFeature(
  featureActions: PrivacySettingsItem,
  featureId: string
) {
  const {
    communication_methods: { letter, email, sms, phone },
    consent_given,
    action
  } = featureActions[featureId];

  return {
    feature: !consent_given ? bypassErrors({ action }) : null,
    letter: !letter.consent_given ? bypassErrors(letter) : null,
    email: !email.consent_given ? bypassErrors(email) : null,
    sms: !sms.consent_given ? bypassErrors(sms) : null,
    phone: !phone.consent_given ? bypassErrors(phone) : null
  };
}

async function getActionsForFeatures(allfeatureActions: PrivacySettingsItem) {
  return Object.keys(allfeatureActions).reduce((result, fId) => {
    const actions = getActionsForFeature(allfeatureActions, fId);
    return {
      ...result,
      [fId]: actions
    };
  }, {});
}

const actionCreators = {
  // Returns the privacy profile for a contact. This is used to populate the
  // Privacy widget in the contact view. Mainly, we need to check to see if the
  // user has the privilege to ignore blocking errors.
  fetchPrivacyProfile: {
    request: (payload: string) =>
      api
        .post('ContactSingleView::getPrivacyProfile', {
          contact_id: payload
        })
        .then((response) => response.data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  // Fetches the privacy settings for a contact. Most likely you will just use checkConsentForFeature
  // and checkConsentForFeatures as there are few others things we need to check.
  fetchPrivacySettings: {
    request: async ({
      contactId,
      featureId
    }: {
      contactId: string;
      featureId: string;
    }) => {
      const useMock = hasFeatureFlags('enhanced_privacy_mock_settings');

      // You can use the enhanced_privacy_mock_settings flag in Flagsmith to toggle between the mock and the real API.
      // WARNING: If you use the real api, when you toggle the settings in {app}/admin/settings/privacy, it will update
      // for all users in of that account. For example, if you turned block for mail merge in the support account,
      // all users in the support account would be blocked from sending mail merges.
      return useMock
        ? { data: { result: mock } }
        : api.post('ContactSingleView::getPrivacySettings', {
            contact_id: contactId,
            feature_id: featureId
          });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  // Returns the contacts privacy settings for a specific feature.
  checkConsentForFeature: {
    request: (
      {
        contactId,
        featureId
      }: {
        contactId: string;
        featureId: string;
      },
      actions
    ) =>
      actions
        .fetchPrivacySettings({ contactId, featureId })
        .then(({ data: { result } }) =>
          getActionsForFeature(result, featureId)
        ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  // Returns the contacts privacy settings for all features.
  checkConsentForFeatures: {
    request: (contactId: string, actions) =>
      actions
        .fetchPrivacySettings({ contactId })
        .then(({ data: { result } }) => getActionsForFeatures(result)),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchConsumerDashboardUrl: {
    request: (contactId: string) =>
      api
        .post('ContactSingleView::getCustomerDashboardUrl', {
          contact_id: contactId,
          type: 'user'
        })
        .then(({ data }) => data.result),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const selectors = {
  consents: (state: { contactPrivacy: typeof initialState }) =>
    state.contactPrivacy.consents,
  communicationPreferences: (state: { contactPrivacy: typeof initialState }) =>
    state?.contactPrivacy.communicationPreferences
};

export default new Generator<Record<string, any>, typeof actionCreators>(
  'contactPrivacy'
).createModel({
  initialState,
  actionCreators,
  selectors
});
