import React from 'react';
import { Body } from 'components/text/body';
import { FormField } from 'view/components/form';
import { Select } from 'view/components/input/select';
import Checkbox from 'view/components/input/checkbox';
import { COLORS, PADDINGS } from 'shared/theme';
import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import { Grid, Column } from 'shared/components/grid';

const durationOptions = [
  { label: '1 month', value: '1' },
  { label: '2 months', value: '2' },
  { label: '3 months', value: '3' },
  { label: '6 months', value: '6' },
  { label: '9 months', value: '9' },
  { label: '12 months', value: '12' },
  // Select doesn't allow falsey values, so using string '0'
  { label: "Don't auto archive match profiles", value: '0' }
];

export function AutoArchive() {
  return (
    <Box
      p={PADDINGS.M}
      m='0px'
      spacing={PADDINGS.S}
      style={{ backgroundColor: COLORS.SAND_LIGHT }}
    >
      <SubHeading semibold style={{ marginLeft: 0 }}>
        Auto-Archive Match Profiles
      </SubHeading>
      <Body regular dark style={{ marginLeft: 0 }}>
        When a match profile has not been modified for the selected time period
        it will be automatically archived.
      </Body>
      <Grid columns={2}>
        <Column width={1}>
          <FormField
            name='archive_after_months'
            label='auto-archive after'
            Input={Select}
            inputProps={{
              options: durationOptions
            }}
          />
          <FormField
            name='archive_with_active_campaign'
            Input={Checkbox}
            inputProps={{
              label: "don't auto-archive match profiles with active campaigns"
            }}
          />
        </Column>
      </Grid>
    </Box>
  );
}
