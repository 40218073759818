import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';

import { TextInput } from '@rexlabs/text-input';
import {
  query,
  useEntityListQuery,
  useModelActions
} from '@rexlabs/model-generator';

import { createValidationRules } from 'shared/utils/form';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import adminUserGroupsModel from 'data/models/entities/admin-user-groups';
import savedFiltersModel, {
  SavedFilterItem
} from 'data/models/entities/saved-filters';

import Dialog from 'view/components/dialog';
import { Form, FormField, ReactForms } from 'view/components/form';
import { Select } from 'view/components/input/select';
import { SaveCancelButtonBar } from 'view/components/button-bar';
import { DisplayCriteria } from 'components/record-list-screen/types';
import { OrderBy } from 'features/pipelines/components/order-by-dropdown';

export type SavedFilterDialogType = {
  open: (props: SavedFilterDialogProps) => React.ReactNode;
};

interface SavedFilterFormValues {
  name?: string;
  shareWith: string;
  userGroups?: number[];
}
interface SavedFilterDialogProps {
  onClose?: (props?: any) => any;
  criteria?: DisplayCriteria[];
  serviceName: string;
  filter?: SavedFilterItem;
  orderBy?: OrderBy;
}

const validate = createValidationRules({
  name: 'required',
  shareWith: 'required'
});

const userGroupsQuery = query`{
  ${adminUserGroupsModel} (limit: 100) {
    id
    name
  }
}`;

const getBackendCriteria = (criteria: DisplayCriteria[]) => {
  return criteria.map((criterion) => {
    if (!criterion.filterConfig) {
      return criterion;
    }
    return {
      name: criterion.filterConfig.name,
      type: criterion.type,
      value: criterion.value
    };
  });
};

export function SavedFilterDialog({
  closeDialog,
  serviceName,
  criteria,
  filter,
  onClose,
  orderBy
}: SavedFilterDialogProps & {
  closeDialog: () => void;
}) {
  const savedFilter = useModelActions(savedFiltersModel);
  const userGroups = useEntityListQuery(userGroupsQuery);

  const filterId = filter?.id;
  const initialUserGroups = filter?.user_groups || [];

  const initialValues: SavedFilterFormValues = {
    name: filter?.name,
    shareWith: initialUserGroups.length
      ? 'userGroups'
      : filter?.is_public
      ? 'public'
      : 'private',
    userGroups: initialUserGroups.map((group) => group.user_group.id)
  };

  const handleSubmit = useCallback(
    async (values: SavedFilterFormValues) => {
      let response: AxiosResponse<SavedFilterItem>;

      const userGroupsFromForm = values?.userGroups || [];
      const requestCriteria = criteria ? getBackendCriteria(criteria) : [];

      if (filterId) {
        response = await savedFilter.updateItem({
          id: filterId.toString(),
          data: {
            newData: {
              name: values.name,
              service_name: serviceName,
              order_by: orderBy || null,
              criteria: requestCriteria,
              user_groups: userGroupsFromForm,
              is_public: values.shareWith === 'public'
            },
            prevData: filter
          }
        });
      } else {
        response = await savedFilter.createItem({
          data: {
            name: values.name,
            service_name: serviceName,
            criteria: requestCriteria,
            order_by: orderBy || null,
            user_groups:
              values.shareWith !== 'userGroups'
                ? null
                : values.userGroups?.map?.((id) => ({ user_group_id: id })),
            is_public: values.shareWith === 'public'
          }
        });

        Analytics.track({
          event: EVENTS.RECORD_LIST_SCREEN.SAVED_FILTERS.CREATED
        });
      }

      await Promise.resolve(onClose?.(response?.data));
      closeDialog();
    },
    [filterId, onClose, closeDialog, savedFilter, serviceName, criteria, filter]
  );
  const isLoading = userGroups.status === 'loading';

  return (
    <Dialog
      closeDialog={closeDialog}
      title={filterId ? 'Edit filter...' : 'Save Filter As'}
      height={500}
      isLoading={isLoading}
    >
      <ReactForms
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <FormField name='name' label='filter name' Input={TextInput} />
            <FormField
              name='shareWith'
              label='share with'
              Input={Select}
              inputProps={{
                options: [
                  {
                    value: 'private',
                    label: 'Myself (and my permission group)'
                  },
                  { value: 'userGroups', label: 'Specific User Groups' },
                  { value: 'public', label: 'Everyone' }
                ]
              }}
            />
            {values?.shareWith === 'userGroups' && (
              <FormField
                name='userGroups'
                label='user group(s)'
                Input={Select}
                inputProps={{
                  multi: true,
                  options:
                    userGroups.data?.map?.((userGroup) => ({
                      value: userGroup.id,
                      label: userGroup.name
                    })) || [],
                  // Note: Incase you're wondering, this lets you remove individual items from the select.
                  // Otherwise you only have the option to clear all.
                  withTags: true,
                  hideSelected: true
                }}
              />
            )}
            <SaveCancelButtonBar
              onSave={submitForm}
              onCancel={closeDialog}
              isLoading={isSubmitting}
              isDisabled={
                values?.userGroups?.length === 0 &&
                values.shareWith === 'userGroups'
              }
            />
          </Form>
        )}
      </ReactForms>
    </Dialog>
  );
}
