import React, { Fragment, useCallback, useMemo } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';

import { Form, FormField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import { ContractCondition } from 'features/contract-conditions/data/contract-conditions';
import { ContractConditionsFilter } from 'features/contract-conditions/data/types';
import FilterButton from 'src/view/components/button/filter-button';
import { Select } from 'src/view/components/input/select';

const defaultStyles = StyleSheet({
  container: {
    width: '400px'
  },
  form: {
    '& input': {
      '&:hover': {
        border: '0px'
      },
      '&:focus': {
        border: '0px'
      }
    }
  },
  icon: {
    display: 'flex',
    gap: 5,
    margin: 0
  }
});

interface SettingsPopoutProps {
  onFilter: ({ is_enabled, name }: ContractConditionsFilter) => void;
  savedFilter?: ContractConditionsFilter;
  onClearFilter?: () => void;
}

export function SettingsPopout({
  onFilter,
  savedFilter,
  onClearFilter
}: SettingsPopoutProps) {
  const s = useStyles(defaultStyles);
  const filterFields = useMemo(() => {
    if (
      !savedFilter ||
      (!savedFilter?.name &&
        !savedFilter?.is_enabled &&
        !savedFilter?.is_hidden)
    ) {
      onClearFilter?.();
      return;
    }

    const filters: { readableField: string; readableValue: string }[] = [];
    const { name, is_enabled, is_hidden } = savedFilter;

    if (name) filters.push({ readableField: 'NAME', readableValue: name });
    if (is_enabled)
      filters.push({
        readableField: 'HIDDEN',
        readableValue: is_enabled === 'enabled' ? 'Yes' : 'No'
      });
    if (is_hidden)
      filters.push({
        readableField: 'HIDDEN TEMPLATES',
        readableValue: is_hidden === 'yes' ? 'Yes' : 'No'
      });

    return filters;
  }, [onClearFilter, savedFilter]);

  const Content = useCallback(
    ({ close }) => {
      const handleSubmit = (values) => {
        onFilter(values);
        close();
      };
      return (
        <Box {...s('container')}>
          <ReactForms<ContractConditionsFilter, unknown>
            initialValues={savedFilter || {}}
            handleSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <PaddingBox {...s('form')}>
                  <FormField
                    name='name'
                    label='condition name'
                    Input={TextInput}
                  />
                  <FormField
                    name='is_enabled'
                    label='status'
                    Input={Select}
                    inputProps={{
                      options: [
                        { label: 'Enabled', value: 'enabled' },
                        { label: 'Disabled', value: 'disabled' }
                      ]
                    }}
                  />
                  <FormField
                    name='is_hidden'
                    label='hidden templates'
                    Input={Select}
                    inputProps={{
                      options: [
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' }
                      ]
                    }}
                  />
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    marginTop={20}
                  >
                    <Fragment>
                      {onClearFilter && savedFilter && (
                        <TextButton blue onClick={onClearFilter}>
                          Clear Filter
                        </TextButton>
                      )}
                    </Fragment>

                    <ButtonBar isLoading={isSubmitting} hasPadding={false}>
                      <TextButton blue onClick={close}>
                        Cancel
                      </TextButton>
                      <DefaultButton blue onClick={submitForm}>
                        Apply Filter
                      </DefaultButton>
                    </ButtonBar>
                  </Box>
                </PaddingBox>
              </Form>
            )}
          </ReactForms>
        </Box>
      );
    },
    [onFilter, s, savedFilter, onClearFilter]
  );

  return (
    <Popout Content={Content} placement='bottom-start'>
      <FilterButton displayCriterias={filterFields} title='Filter Conditions' />
    </Popout>
  );
}
