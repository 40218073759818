import invariant from 'invariant';

function normalizer(suburb) {
  if (suburb.label && suburb.value) {
    return suburb;
  }

  const id = suburb._id || suburb.id;
  const suburbOrTown = suburb.suburb_or_town || suburb.value;
  const text = suburb.text;
  const country = suburb.country;
  const postcode = suburb.postcode;
  const stateOrRegion = suburb.state_or_region;
  const locality = suburb.locality;

  invariant(
    suburbOrTown,
    "Values and options must have either a '_suburb_or_town', 'suburb_or_town', '_value' or 'value' property"
  );

  return {
    label: text || suburbOrTown,
    value: {
      id: id || `${suburbOrTown}${postcode ? `-${postcode}` : ''}`,
      text,
      country,
      postcode,
      locality,
      suburb_or_town: suburbOrTown,
      state_or_region: stateOrRegion
    },
    type: 'suburb'
  };
}

export function normalizerNoId(suburb) {
  const id = suburb._id || suburb.id;
  const country = suburb.country;
  const postcode = suburb.postcode;
  const stateOrRegion = suburb.state_or_region;
  const locality = suburb.locality;
  const suburbOrTown = suburb.suburb_or_town || suburb.value;
  // AU suburbs have a text property which we can utilise. It also helps to keep suburbs with the same name in different states unique.
  const label =
    suburb.text || country === 'AUS'
      ? `${suburbOrTown} ${stateOrRegion} ${postcode}`
      : suburbOrTown;

  return {
    label,
    value: {
      id: `${label.replace(/\s/, '-')}-suburb`,
      originalId: id,
      country,
      postcode,
      locality,
      suburb_or_town: suburbOrTown,
      state_or_region: stateOrRegion
    },
    type: 'suburb'
  };
}

export default normalizer;
