import React from 'react';

import { TemplateProps } from '../';

function TemplateProgress({ row, col }: TemplateProps) {
  const data = row[col.id];
  return (
    <div className='list-progress'>
      <div className='list-progress-inner' style={{ width: `${data}%` }} />
    </div>
  );
}

export default TemplateProgress;
