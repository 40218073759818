/* eslint-disable max-lines */
/**
 |-------------------------------------------------------------------------------
 | App Search
 |-------------------------------------------------------------------------------
 |
 | The following component gives search functionality based on an array of Providers.
 |
 | This top level component structures the select-input components and provides props
 | for customising the search look and feel. You are able to customise the options, loading skeletons
 | and and navigation sections that are rendered.
 |
 | State:
 |  - focused
 |  - categoryFocused
 |  - searchTerm
 |  - options
 |  - viewModes
 |  - viewMode
 |  - isLoading
 |
 | Actions:
 |  - renderOptions
 |  - changeCategory
 |  - handleBack
 |  - debouncedUpdateOptions
 |  - handleInputChange
 |  - handleSelect
 |  - handleFocus
 |  - handleBlur
 |  - handleScrollBottom
 |  - handleLeftMenuFocus
 |  - handleLeftMenuBlur
 |  - handleLeftMenuSelect
 |
 | Helpers:
 |  - leftMenuOptions
 |
 */

import React, { Component } from 'react';
import dayjs from 'dayjs';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import Types from 'prop-types';

import Box from '@rexlabs/box';
import { styled } from '@rexlabs/styling';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';

import AppSearchDropdown from './dropdown';
import AppSearchInput from './input';
import {
  getNull,
  ignoreFilter,
  mapToOptions,
  ProviderPropType,
  VIEW_MODES
} from '../utils';

@styled({
  container: {
    position: 'relative'
  }
})
@autobind
class Search extends Component {
  static propTypes = {
    /** Whether or not the user can filter from the search input. */
    canQuickFilter: Types.bool,
    /** Whether or not to show the category dropdown list */
    showLeftDropdown: Types.bool,
    /** Whether to hide the search input icon upon the input being focused */
    /** Whether the result menu should open on focus or not */
    shouldOpenOnFocus: Types.bool,
    shouldCloseOnBlur: Types.bool,
    shouldKeepValueOnSelect: Types.bool,
    shouldShowSectionBack: Types.bool,
    isHidden: Types.bool,
    autoOpen: Types.bool,
    SectionBack: Types.oneOfType([Types.func, Types.string]),
    /** Array of providers that the search will be using for fetching options and rendering them */
    providers: Types.arrayOf(ProviderPropType).isRequired,
    /** An icon that will sit at the front of the search input */
    selectPrefix: Types.node,
    /** Placeholder text for the search input */
    placeholder: Types.string,
    /** Fired when a user selects an option from any menu list. E.g. the recents menu or the scoped options menu */
    onChosen: Types.oneOfType([Types.func, Types.string]).isRequired,
    /** The maximum amount of options that a scoped list can render. */
    maximumScopedListOptions: Types.number,
    /** The maximum amount of options that the global search list can render. */
    maximumGlobalListOptions: Types.number,
    /** Navigates the user to a paginated list where they can view more results than the scoped list maximum. */
    scopedListOverflowLink: Types.oneOfType([Types.func, Types.string]),
    /** Dependency inject Zenscroll to allow us to control when it is initialised */
    zenscroll: Types.any.isRequired,
    /** Allows detection of scrolling to the bottom of the menu */
    handleScrollBottom: Types.func
  };

  static defaultProps = {
    canQuickFilter: false,
    showLeftDropdown: false,
    shouldOpenOnFocus: true,
    shouldCloseOnBlur: true,
    shouldBackspaceRemove: false,
    shouldDeleteRemove: false,
    shouldTabSelectValue: false,
    shouldBlurResetInput: false,
    shouldCloseResetInput: false,
    isHidden: false,
    autoOpen: false,
    selectPrefix: null,
    LoadingIndicator: getNull,
    ClearIndicator: getNull,
    clearIndicatorProps: {},
    placeholder: 'Search for something...',
    onChosen: _.noop,
    scopedListOverflowLink: '#',
    handleScrollBottom: _.noop,
    appCuesId: 'global-search'
  };

  state = {
    focused: null,
    categoryFocused: false,
    showPrefix: true,
    numberOfSearches: 0,
    numberOfResults: 0
  };

  getCurrentRecentOptions() {
    const { recentOptions } = this.props;
    return mapToOptions(recentOptions) || [];
  }

  getProviderTypes() {
    const { providers } = this.props;
    return providers.map((provider) => provider.type);
  }

  render() {
    const { focused } = this.state;
    const {
      options,
      viewMode,
      viewModes,
      isLoading,
      searchTerm,
      searchErrors,

      placeholder,
      Error,
      showLeftDropdown,
      selectPrefix,
      selectSuffix,
      LoadingIndicator,
      ClearIndicator,
      clearIndicatorProps,
      recentProvider,
      recentOptions,
      shouldOpenOnFocus,
      shouldCloseOnBlur,
      shouldBackspaceRemove,
      shouldDeleteRemove,
      shouldTabSelectValue,
      shouldBlurResetInput,
      shouldCloseResetInput,
      isHidden,
      autoOpen,
      onFocus,
      onBlur,
      emptySearch,
      canQuickFilter,
      styles: s,
      SectionBack,
      shouldShowSectionBack,
      zenscroll,
      isClearable,
      hasScopedScroll,

      appCuesId,
      partiallyLoading,
      ...props
    } = this.props;

    const AppSearchDropdownProps = {
      selectContainerProps: {
        options: viewModes,
        onSelect: this.handleLeftMenuSelect,
        selected: viewMode
      },
      selectInputProps: {
        autoBlur: true,
        shouldOpenOnFocus: true,
        shouldCloseOnBlur: true,
        shouldBackspaceRemove: false,
        shouldDeleteRemove: false,
        shouldTabSelectValue: false,
        shouldBlurResetInput: false,
        shouldCloseResetInput: false,
        isClearable: false,
        onFocus: this.handleLeftMenuFocus,
        onBlur: this.handleLeftMenuBlur
      }
    };

    const AppSearchInputProps = {
      selectContainerProps: {
        options: options,
        isLoading: isLoading,
        onSelect: this.handleSelect,
        filter: ignoreFilter
      },
      selectInputProps: {
        name: 'global_search',
        tabIndex: 1,
        debounce: 0,
        autoBlur: true,
        shouldOpenOnFocus: shouldOpenOnFocus,
        shouldCloseOnBlur: shouldCloseOnBlur,
        shouldBackspaceRemove: shouldBackspaceRemove,
        shouldDeleteRemove: shouldDeleteRemove,
        shouldTabSelectValue: shouldTabSelectValue,
        shouldBlurResetInput: shouldBlurResetInput,
        shouldCloseResetInput: shouldCloseResetInput,
        isClearable,
        placeholder: placeholder,
        prefix: selectPrefix,
        SuffixIcon: selectSuffix,
        onChange: this.handleInputChange,
        onFocus: onFocus || this.handleFocus,
        onBlur: onBlur || this.handleBlur,
        DropdownIndicator: null,
        LoadingIndicator,
        ClearIndicator,
        clearIndicatorProps,
        onCleared: this.handleClear
      },
      menuSwitcherProps: {
        isHidden: isHidden,
        autoOpen: autoOpen,
        searchTerm: searchTerm,
        showLoadingInState: isLoading,
        viewMode: viewMode.value,
        onScrollBottom: this.handleScrollBottom,
        renderOptions: this.renderOptions,
        renderRecents: this.renderRecents,
        renderLoadingInState: this.renderLoadingInState,
        recentOptions: this.getCurrentRecentOptions(),
        emptySearch: emptySearch,
        Error: Error,
        searchErrors: searchErrors,
        canQuickFilter: canQuickFilter,
        quickFilterTypes: this.getProviderTypes(),
        SectionBack: SectionBack,
        handleBack: this.handleBack,
        shouldShowSectionBack,
        hasScopedScroll,
        zenscroll,
        partiallyLoading
      }
    };

    return (
      <Box {...s('container')} flexDirection='row' data-appcues={appCuesId}>
        {showLeftDropdown && <AppSearchDropdown {...AppSearchDropdownProps} />}
        <AppSearchInput {...AppSearchInputProps} {...s({ focused: focused })} />
      </Box>
    );
  }

  renderOptions({ options, ...props }, scopedType) {
    const {
      providers,
      optionTotals,
      resolvedQueries,
      viewAllInListValue,
      viewMode: { value }
    } = this.props;

    return providers.map((provider, index) =>
      value !== VIEW_MODES.GLOBAL_SEARCH || resolvedQueries[index]
        ? provider.renderOption(
            {
              options,
              optionTotals,
              viewAllInListValue,
              onShowAll: this.changeCategory,
              onBack: this.handleBack,
              ...props
            },
            scopedType
          )
        : provider.renderSkeleton(value, 3)
    );
  }

  renderRecents(props, scopedType) {
    const { recentProvider } = this.props;

    return recentProvider.renderOption(
      {
        options: this.getCurrentRecentOptions(),
        ...props
      },
      scopedType
    );
  }

  componentDidUpdate(prevProps) {
    // NOTE: When the debounced search is triggered, the preservedOptions searchTerm is reset.
    // Using this, we can see how many times the user searched
    if (
      !prevProps.preservedOptions.searchTerm &&
      this.props.preservedOptions.searchTerm
    ) {
      this.setState({
        numberOfSearches: this.state.numberOfSearches + 1,
        numberOfResults:
          this.state.numberOfResults +
          this.props.preservedOptions.options.length
      });
    }
  }

  renderLoadingInState() {
    const {
      providers,
      optionTotals,
      viewMode: { value }
    } = this.props;

    const currentCount =
      (value === VIEW_MODES.GLOBAL_SEARCH && 3) || optionTotals[value];

    return providers.map((provider) => {
      return provider.renderSkeleton(value, currentCount);
    });
  }

  changeCategory(newCategory) {
    return () => {
      const { dispatch } = this.props;

      dispatch({
        type: 'CHANGE_CATEGORY',
        payload: newCategory
      });
    };
  }

  handleBack() {
    const { dispatch } = this.props;

    dispatch({
      type: 'BACK'
    });
  }

  handleClear() {
    const { dispatch } = this.props;

    dispatch({ type: 'CLEAR' });
  }

  handleInputChange(event) {
    const { dispatch } = this.props;

    const val = _.get(event, 'target.value', '');

    dispatch({ type: 'SET_SEARCH_TERM', payload: val });
  }

  handleSelect(option) {
    const { onChosen, dispatch } = this.props;

    if (!option) return;

    if (_.isFunction(onChosen)) {
      onChosen(option);
      this.handleBack();
    }

    const viewModes = (this?.props?.viewModes || []).map(
      (viewMode) => viewMode.value
    );

    dispatch({
      type: 'SELECT'
    });

    // If the user selects from the recent records we don't want to track the event
    if (viewModes.includes(option.type)) {
      Analytics.track({
        event: EVENTS.GLOBAL_SEARCH.SELECTED_ITEM_FROM_APP_SEARCH,
        properties: {
          recordType: option.type,
          viewMode: this.props.viewMode.value,
          itemIndexInList: this.props.options
            .filter((searchOption) => searchOption.type === option.type)
            .findIndex(
              (filteredOption) => filteredOption.value === option.value
            ),
          milliSecondsFromFocusToSelect: dayjs().valueOf() - this.state.focused
        }
      });
    }

    this.setState({
      focused: null,
      numberOfSearches: 0
    });
  }

  handleFocus(event) {
    this.setState({
      focused: dayjs().valueOf()
    });

    event.preventDefault();
  }

  handleBlur() {
    const { numberOfResults, numberOfSearches, focused } = this.state;
    if ((focused, !!numberOfSearches)) {
      Analytics.track({
        event: EVENTS.GLOBAL_SEARCH.ABANDONED_SEARCH,
        properties: {
          milliSecondsFromFocusToAbandonment: dayjs().valueOf() - focused,
          numberOfSearchesBeforeAbandonment: numberOfSearches,
          numberOfResultsBeforeAbandonment: numberOfResults
        }
      });
    }

    this.setState({
      focused: null,
      numberOfSearches: 0,
      numberOfResults: 0
    });
  }

  handleLeftMenuFocus() {
    this.setState({ categoryFocused: true });
  }

  handleLeftMenuBlur() {
    this.setState({ categoryFocused: false });
  }

  handleScrollBottom(event) {
    const { handleScrollBottom } = this.props;
    handleScrollBottom(event);
  }

  handleLeftMenuSelect(option) {
    const { dispatch } = this.props;

    dispatch({ type: 'CHANGE_CATEGORY', payload: option.value });
  }
}

export default Search;
