import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    paddingBottom: '10px'
  },

  containerWhite: {
    color: COLORS.WHITE
  },

  hasPadding: {
    padding: '20px 20px 0 20px'
  },

  hasCompletePadding: {
    padding: '20px'
  },

  warning: {
    backgroundColor: COLORS.STATES.YELLOW
  },

  icon: {
    backgroundColor: COLORS.STATES.RED,
    color: COLORS.WHITE,
    fontSize: '18px',
    width: '25px',
    height: '25px',
    borderRadius: '13px',
    padding: 0,
    fontWeight: 900,
    border: '0 none',
    transition: 'background .2s'
  },

  message: {
    fontSize: '14px',
    lineHeight: '19px',
    paddingLeft: '4px',
    paddingTop: '3px',
    minWidth: 0
  },

  prefix: {
    fontWeight: 600,
    paddingLeft: '9px',
    color: COLORS.GREY_DARK,
    paddingTop: '6px',
    display: 'flex',
    fontSize: '12px'
  }
});

@styled(defaultStyles)
class ErrorMessage extends PureComponent {
  render() {
    const {
      styles: s,
      children,
      white,
      hasPadding,
      hasCompletePadding,
      warning
    } = this.props;
    return (
      <Box
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
        {...s(
          'container',
          { containerWhite: white },
          { hasPadding: hasPadding },
          { hasCompletePadding }
        )}
      >
        <Box
          justifyContent='center'
          alignItems='center'
          {...s('icon', { warning })}
        >
          <span>!</span>
        </Box>
        <Box flexDirection='row' {...s('prefix')}>
          {warning ? 'WARNING' : 'ERROR'}
        </Box>
        <Box flex={1} {...s('message')}>
          {children}
        </Box>
      </Box>
    );
  }
}

export default ErrorMessage;
