import { RecordListTable } from 'components/record-list-screen/table';
import React, { useMemo, useState } from 'react';
import { ListingDocument } from '../../types/common';
import { ColumnConfig } from 'components/record-list-screen/types';
import { StateView } from 'components/record-list-screen/state-view';
import { Body } from 'components/text/body';
import { orderBy as orderByFunc } from 'lodash';
import { OrderDir } from 'components/record-list-screen/utils/use-order-by';
import { ActionMenuItem } from 'view/components/action-menu/core';
import Box from '@rexlabs/box';
import sessionModel from 'data/models/custom/session';
import { useModelState } from '@rexlabs/model-generator';
import { usePermissions } from 'hooks/use-permissions';
import {
  accessColumn,
  dateUploadedColumn,
  descriptionColumn,
  typeColumn
} from 'features/listings/components/documents-table/basic-columns';

interface DocumentsActionMenuItem extends Omit<ActionMenuItem, 'label'> {
  label: string;
}

interface DocumentsTableProps {
  documents: ListingDocument[];
  actionMenu: DocumentsActionMenuItem[];
}

const DocumentsTable = ({ documents, actionMenu }: DocumentsTableProps) => {
  const session = useModelState(sessionModel);
  const { check } = usePermissions();

  const [order, setOrder] = useState<{
    orderBy: string | null;
    orderDir: OrderDir | null;
  }>({
    orderBy: 'system_ctime',
    orderDir: 'asc'
  });

  const sortedDocuments = useMemo(() => {
    const { orderBy, orderDir } = order;
    const filtered = documents.filter((document) => !document._destroy);
    if (!orderBy || !orderDir) return filtered;
    return orderByFunc(filtered, [orderBy], [orderDir]);
  }, [documents, order]);

  const columns: ColumnConfig<ListingDocument>[] = useMemo(
    () => {
      return [
        {
          ...descriptionColumn,
          cellProps: {
            items: (row: ListingDocument) => {
              const { privacy, exclude_from_mail_merge } = row;

              // If there is no system_created_user it means it is new and the owner is the current user
              const isUserOwner = row.system_created_user
                ? row.system_created_user.id === session.user_details.id
                : true;

              const userCanSendAnyDocuments = check('documents.send_any');
              const restrictDocumentLinkSharing =
                privacy.id === 'private' && exclude_from_mail_merge;

              const hasUpdateRights = isUserOwner
                ? check('documents.update_any') ||
                  check('documents.update_created')
                : check('documents.update_any');

              return actionMenu.filter((action) => {
                // Edit & Delete actions require update privileges
                const actionRequiresUpdatePrivileges =
                  action.checkRights === '{{updateDocuments}}';

                if (actionRequiresUpdatePrivileges) return hasUpdateRights;

                const actionRequiresSharingPrivileges = [
                  'Send Link',
                  'Get Share Link'
                ].includes(action.label);

                if (
                  actionRequiresSharingPrivileges &&
                  restrictDocumentLinkSharing
                )
                  return userCanSendAnyDocuments;

                return true;
              });
            }
          }
        },
        typeColumn,
        accessColumn,
        dateUploadedColumn
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box pt={4} pr={25}>
      <RecordListTable
        items={sortedDocuments}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        hasSelection={false}
        orderBy={order.orderBy || undefined}
        orderDir={order.orderDir || undefined}
        setOrderBy={(orderBy: string, orderDir: OrderDir) => {
          setOrder({ orderBy, orderDir });
        }}
        isLoading={false}
        LoadingView={() => null}
        EmptyView={() => (
          <StateView noPadding>
            <Body italic regular informative>
              No documents added
            </Body>
          </StateView>
        )}
        variant={'compact'}
        colorScheme={'light'}
      />
    </Box>
  );
};

export default DocumentsTable;
