import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface SectionHeadingProps extends BaseTextProps {
  semibold?: boolean;
  white?: boolean;
}

export function SectionHeading({
  semibold,
  white,
  ...props
}: SectionHeadingProps) {
  return (
    <Text
      is='h3'
      {...props}
      type={map({
        SECTIONHEADING: true,
        WHITE: white,
        SEMIBOLD: semibold
      })}
    />
  );
}
