import React from 'react';

import { RecordScreen } from 'components/_experiment/record-screen';

import { ListingDetailsScreen } from './listing-details-screen';
import { ListingsListScreen } from './listings-list-screen';

export function ListingsRecordScreen() {
  return (
    <RecordScreen
      RecordListScreen={ListingsListScreen}
      RecordDetailsScreen={ListingDetailsScreen}
    />
  );
}
