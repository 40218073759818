import React, { useCallback } from 'react';
import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import { ColumnsReportingFilterButton } from 'components/button/reporting-filter-button/columns-reporting-filter-button';
import { useDataGrid } from '../hooks/use-data-grid';

export function ColumnsButton() {
  const { visiblePanel, togglePanel } = useDataGrid();

  const columnsClick = useCallback(() => {
    togglePanel?.('columns', ({ instance, ...rest }) => {
      if (instance.displayed) {
        Analytics.track({
          event: EVENTS.CUSTOM_REPORTING.OPEN_COLUMN_PANEL
        });
      }
      instance.collapseColumnGroups();
    });
  }, [togglePanel]);

  return (
    <ColumnsReportingFilterButton
      showArrowIcon={false}
      onClick={columnsClick}
      isActive={visiblePanel === 'columns'}
    >
      Columns
    </ColumnsReportingFilterButton>
  );
}
