import React, { useCallback, useMemo } from 'react';
import { query, useEntityQuery, Id } from '@rexlabs/model-generator';

import projectsModel from 'data/models/entities/projects';
import { RecordDetailsScreen } from 'components/_experiment/record-details-screen';

import { ProjectTitleBlock } from '../components/project-title-block';
import { projectsModes } from '../data/record-modes';
import { projectsBlocks } from '../data/record-blocks';
import { projectsWidgets } from '../data/record-widgets';
import { projectsStreams } from '../data/record-streams';
import { projectDetailsActions } from '../data/record-details-actions';

const getProjectQuery = (id) => query`{
  ${projectsModel} (id: ${id}, extra_fields: ${['_security_user_rights']}) {
    id
    security_user_rights
    related {
      project_documents
      project_reln_contact
      project_tags
    }
  }
}`;

interface ProjectDetailsScreenProps {
  id: Id;
}

export function ProjectDetailsScreen({ id }: ProjectDetailsScreenProps) {
  const projectQuery = useMemo(() => getProjectQuery(id), [id]);
  const { data, status, actions } = useEntityQuery(projectQuery);

  const handleSubmit = useCallback(
    ({ changedValues }) =>
      actions.updateItem({ id: id.toString(), data: changedValues }),
    [actions, id]
  );

  return (
    <RecordDetailsScreen
      serviceName='Projects'
      id={id}
      data={data}
      status={status}
      modes={projectsModes}
      actions={projectDetailsActions}
      blocks={projectsBlocks}
      widgets={projectsWidgets}
      streams={projectsStreams}
      TitleBlock={ProjectTitleBlock}
      handleSubmit={handleSubmit}
    />
  );
}
