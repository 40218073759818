import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import React from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'src/view/components/button';

export interface ApiErrorProps {
  apiErrors: (Error | null)[];
  onRetry: () => void;
}

const ApiError = ({ apiErrors, onRetry }: ApiErrorProps) => {
  if (apiErrors.length === 0) return null;
  return (
    <Box
      flexDirection='column'
      width={'100%'}
      height={200}
      alignItems='center'
      justifyContent='center'
      spacing={20}
    >
      <Icon
        type={ICONS.ROUND_CROSS_BIG}
        width={40}
        height={40}
        hasControlledColor={false}
      />
      {apiErrors.map((error, i) => (
        <Body key={i} semibold dark>
          &bull; {error?.message}
        </Body>
      ))}
      <DefaultButton dark onClick={onRetry}>
        <Icon
          type={ICONS.REFRESH}
          style={{ display: 'flex', marginRight: 8 }}
        />
        Try Again
      </DefaultButton>
    </Box>
  );
};

export default ApiError;
