import TemplateDefault from './default';
import TemplateRights from './rights';
import TemplateSentStatus from './sent-status';
import TemplateProgress from './progress';
import TemplateListMarker from './list-marker';

export {
  TemplateDefault,
  TemplateRights,
  TemplateSentStatus,
  TemplateProgress,
  TemplateListMarker
};
