import { EditReminderData } from 'utils/dialogs/mapping-classic-interfaces';
import { LeadsResponse } from 'data/models/entities/leads';
import { formatAddress } from 'utils/formatters';

export const getReminderData = (lead: LeadsResponse) => {
  const { contact, property, listing, project, project_stage } = lead;

  const reminderData: EditReminderData = {
    lead_id: lead.id,
    ...(contact && { contact }),
    ...(property && {
      property: {
        ...property,
        system_search_key: formatAddress(property)
      }
    }),
    ...(listing && { listing }),
    ...(project && { project }),
    ...(project_stage && { project_stage })
  };

  return reminderData;
};
