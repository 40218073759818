import React, { ReactNode } from 'react';

import { RedirectHeading } from './redirect-heading';
import { RedirectContent } from './redirect-content';
import { RedirectSuccessContent } from './redirect-success-content';

export interface RedirectContentContainerProps {
  loading: boolean;
  hasPermission: boolean;
  error?: { message: string } | null;
  errorMessage?: ReactNode;
  missingPermissionMessage?: string;
  redirectURL?: string | null;
  closeOverlay?: () => void;
  title: string;
}

export function RedirectContentContainer({
  loading,
  hasPermission,
  error,
  title,
  redirectURL,
  closeOverlay,
  errorMessage = 'Please try again in a few minutes. If the problem persists, contact our Support team.',
  missingPermissionMessage = 'Additional permissions are required. Contact an administrator for your account to request access.'
}: RedirectContentContainerProps) {
  if (!hasPermission) {
    return (
      <RedirectContent
        heading='Unable to log in'
        bodyText={missingPermissionMessage}
      />
    );
  }

  if (error) {
    return (
      <RedirectContent
        heading='Unable to log in'
        bodyText={error.message || errorMessage}
      />
    );
  }

  if (loading) {
    return (
      <RedirectContent
        loading
        heading='Logging you in'
        bodyText='This should only take a moment.'
      />
    );
  }

  return (
    <>
      <RedirectHeading>Login complete</RedirectHeading>
      <RedirectSuccessContent
        title={title}
        redirectURL={redirectURL}
        closeOverlay={closeOverlay}
      />
    </>
  );
}
