import { Generator } from 'shared/utils/models';
import { ChartOfAccount } from '../types/chart-of-accounts';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';

const TYPE = 'adminChartOfAccounts';

const actionCreators = {
  archive: {
    request: (id: string) => api.post('AdminChartOfAccounts::archive', { id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  recover: {
    request: (id: string) =>
      api.post('AdminChartOfAccounts::recoverFromArchive', { id }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const adminChartOfAccountsModel = new Generator<
  ChartOfAccount,
  typeof actionCreators
>(TYPE).createEntityModel({
  actionCreators
});

export const chartOfAccountsCategory = new Generator(
  'chartOfAccountsCategory'
).createSystemListModel();

export const chartOfAccountsTaxType = new Generator(
  'chartOfAccountsTaxType'
).createSystemListModel();

export default adminChartOfAccountsModel;
