import React, { PureComponent, Fragment } from 'react';
import List from '@rexlabs/list';
import Pagination from './pagination';

class RexList extends PureComponent {
  render() {
    const { pagination, currentPage, onPrev, onNext, ...props } = this.props;
    return (
      <Fragment>
        <List {...props} />
        {!!pagination && (
          <Pagination
            pagination={pagination}
            currentPage={currentPage}
            onPrev={onPrev}
            onNext={onNext}
          />
        )}
      </Fragment>
    );
  }
}

export default RexList;
