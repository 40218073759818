// NOTE: Offers and Contracts use same API endpoints
import React from 'react';
import { ModelGeneratorLoaded } from '@rexlabs/model-generator';

import { Generator } from 'shared/utils/models';
import { api, transformItem, transformItemArgs } from 'shared/utils/api-client';
import _ from 'lodash';
import ContractOption from 'view/components/input/select/options/contract';
import Value from 'view/components/input/select/values';
import { mapContractToOption } from 'features/listings/data/utils/map-contract-to-option';
import { ContractsItem } from './types';

export type ContractModel = ModelGeneratorLoaded<ContractsItem, any>;

const TYPE = 'contracts';

function getVendors(listing) {
  return _.get(listing, 'related.contact_reln_listing', [])
    .filter((contact) => {
      return _.get(contact, 'reln_type.id') === 'owner';
    })
    .map((contact) => {
      return contact.contact;
    });
}

// TODO: Type the custom config
// https://app.shortcut.com/rexlabs/story/64680/update-types-for-custom-config-in-entity-modelsconst
const config: any = {
  entities: {
    api: {
      fetchItem: async (type, args, id) => {
        const [contract, offerHistory] = await Promise.all([
          api
            .post('Contracts::read', transformItemArgs({ id, args }))
            .then(transformItem),
          api
            .post('Contracts::getOfferHistory', {
              contract_id: id
            })
            .then(transformItem)
        ]);

        const listing = (
          await api.post('Listings::read', {
            id: contract.data.listing.id
          })
        ).data.result;

        return {
          data: {
            // NOTE: we're setting here a bunch of defaults, so withQuery doesn't
            // get confused through different response shapes
            // BACKGROUND: if not all requested fields are in redux, withQuery will
            // trigger another fetch, which leads to infinite fetches if the response
            // doesn't have all requested fields!
            related: {},
            detail_sale_price_or_lease_pa: null,

            commission_worksheet: null,
            contract_status: null,

            time_actual_offer: null,
            time_actual_accepted: null,
            time_actual_communicated: null,

            date_actual_offer: null,
            date_actual_accepted: null,
            date_actual_communicated: null,
            date_actual_deposit: null,
            date_actual_fallen: null,
            date_actual_finance: null,
            date_expec_unconditional: null,
            date_expec_settlement: null,

            agent: null,
            purchtenant_position: null,
            purchtenant_position_id: null,
            purchtenant_legal_name: null,
            purchtenant_residence: null,
            disclosable_interest_exists: null,
            disclosable_interest_note: null,
            detail_finance_lender_contact: null,
            detail_deposit_full_or_prepayment: null,
            purchtenant_solicitor: null,
            purchtenant_solicitor_contact: null,

            detail_finance_amount: null,
            chattels: null,
            listing: null,

            // Actual data
            ...contract.data,
            offer_history: [...offerHistory.data],
            vendor_details: {
              legal_solicitor: _.get(listing, 'legal_solicitor', {}),
              legal_solicitor_contact: _.get(
                listing,
                'legal_solicitor_contact',
                {}
              ),
              vendors: getVendors(listing),
              legal_vendor_residence: listing.legal_vendor_residence
            }
          }
        };
      }
    }
  }
};

const actionCreators = {
  createCommissionWorksheet: {
    request: ({ contractId }) =>
      api.post('CommissionWorksheets::create', {
        data: {
          contract_id: contractId
        }
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  verifyContractStatus: {
    request: ({ id, event, date }) => {
      return api.post('CommissionWorksheets::verifyContractStatus', {
        worksheet_id: id,
        event_to_verify: event,
        event_date: date
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  createOfferHistoryEntry: {
    request: ({ id, amount, date, time, note }) => {
      return api.post('Contracts::createOfferHistoryEntry', {
        contract_id: id,
        data: {
          amount: amount,
          offer_date: date,
          offer_time: time,
          note: note
        }
      });
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const contractsModel = new Generator<ContractsItem, typeof config>(
  TYPE,
  config
).createEntityModel({
  actionCreators
});

contractsModel.select = {
  autocomplete: (searchTerm, { listingId, region }) => {
    const searchByContact = api.post('Contracts::search', {
      extra_options: {
        extra_fields: ['purchtenant']
      },
      criteria: [
        {
          name: 'purchtenant.contact.name_full',
          type: 'like',
          value: `%${searchTerm}%`
        },
        ...(listingId
          ? [
              {
                name: 'contract.listing_id',
                type: '=',
                value: listingId
              }
            ]
          : [])
      ]
    });

    const searchByProperty = api.post('Contracts::search', {
      extra_options: {
        extra_fields: ['purchtenant']
      },
      criteria: [
        {
          name: 'property.system_search_key',
          type: 'like',
          value: `%${searchTerm}%`
        },
        ...(listingId
          ? [
              {
                name: 'contract.listing_id',
                type: '=',
                value: listingId
              }
            ]
          : [])
      ],
      limit: 100
    });

    return Promise.all([searchByContact, searchByProperty]).then(
      ([{ data: dataContacts }, { data: dataProperties }]) => {
        const allContracts = [
          ..._.get(dataContacts, 'result.rows', []),
          ..._.get(dataProperties, 'result.rows', [])
        ];
        return allContracts.map((contract) =>
          mapContractToOption(contract, region)
        );
      }
    );
  },
  Option: ContractOption,
  Value: (props) => <Value {...props} service={TYPE} />
};

export default contractsModel;
