import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Box from '@rexlabs/box';

import { NEW_TEXTS } from 'theme';

import { map } from 'shared/utils/text';
import { ICONS } from 'shared/components/icon';

import { Link } from 'components/text/link';

import { WrapperButton } from './wrapper-button';

interface LinkArrowButtonProps extends React.ComponentProps<typeof Link> {
  active?: boolean;
}

const { BLUE, WHITE, LIGHT, DARK } = NEW_TEXTS;

const ICON: any = ICONS.ARROW_DOWN;

const iconStyles = StyleSheet({
  BLUE,
  WHITE,
  LIGHT,
  DARK,
  icon: {
    transform: 'rotate(270deg)'
  }
});

export function LinkArrowButton(props: LinkArrowButtonProps) {
  const { blue, white, light, dark } = props;

  const s = useStyles(iconStyles);

  const colorsMapped = map({
    BLUE: blue,
    WHITE: white,
    LIGHT: light,
    DARK: dark
  });

  const iconColor = colorsMapped[colorsMapped.length - 1] || 'DARK';

  return (
    <WrapperButton>
      <Box
        // NOTE: We have this custom class name here because we need to target this class from classic.
        // This helps us decide if we should close the classic popout or not.
        className='link-arrow-button'
        flexDirection='row'
        alignItems='center'
      >
        <Link {...props} />
        <ICON {...s('icon', iconColor)} />
      </Box>
    </WrapperButton>
  );
}
