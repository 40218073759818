import React, { PureComponent } from 'react';

import Box from '@rexlabs/box';

import Spinner from 'shared/components/spinner';

import { PADDINGS } from 'src/theme';

class OptionLoading extends PureComponent {
  render() {
    return (
      <Box justifyContent='center' alignItems='center' padding={PADDINGS.XS}>
        <Spinner small dark />
      </Box>
    );
  }
}

export default OptionLoading;
