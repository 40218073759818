import React, { Component } from 'react';
import { withModel } from '@rexlabs/model-generator';
import projectsModel from 'data/models/entities/projects';
import _ from 'lodash';
import Core from './core';
import { formatAddress } from 'utils/formatters';
import { autobind } from 'core-decorators';

@withModel(projectsModel)
@autobind
class ProjectTooltip extends Component {
  state = {
    isLoading: true
  };

  /**
   * Handles fetching the stream data which is shown inside of the popout. We want to fetch this extra data
   * when the popout is opened by the user and no sooner.
   *
   * The call should only ever be made once per record.
   *
   * @param {Object} { isOpen, isClosing } - Indicates the current transitioning states of the popout
   */
  handleFetchPopoutData({ isOpen }) {
    const { projects, id } = this.props;
    const { isLoading } = this.state;

    if (isOpen && isLoading) {
      projects.fetchItem({ id }).then(({ data }) => {
        this.setState({ project: data, isLoading: false });
      });
    }
  }

  render() {
    const { project, isLoading } = this.state;

    const serviceName = 'projects';
    const recordType = 'project';

    const data = {
      address: formatAddress(
        _.pick(project, ['adr_postcode', 'adr_locality', 'adr_suburb_or_town'])
      ),
      image: _.get(project, 'developer_logo'),
      serviceName: serviceName,
      recordType: recordType
    };

    return (
      <Core
        {...this.props}
        onChange={this.handleFetchPopoutData}
        data={data}
        project={project}
        isLoading={isLoading}
      />
    );
  }
}

export default ProjectTooltip;
