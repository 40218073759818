import React, { PureComponent } from 'react';

import { TextArea } from '@rexlabs/text-input';

class RexTextArea extends PureComponent {
  render() {
    if (__DEV__) {
      console.error(
        'DEPRECATED: There is no reason for this component to exist ' +
          'and we will remove it. Use textarea straight from vivid instead!!!'
      );
    }
    const { style, ...props } = this.props;
    return <TextArea {...props} style={{ ...style, resize: 'none' }} />;
  }
}

export default RexTextArea;
