import { gql } from 'hooks/use-graphql-query';

export const Oab = gql`
  fragment Oab on Oab {
    board_type {
      id
      text
    }
    first_price
    first_price_text
    first_seen_date
    id
    oab_date
    oab_state
    price
    price_text
    system_ctime
    system_modtime
    web_link
    agency {
      text
    }
  }
`;
