import React, { useMemo, ReactNode } from 'react';
import { compose } from 'redux';
import { Id } from '@rexlabs/model-generator';

import { ErrorDialog, withErrorDialog } from 'hocs/with-error-dialog';

import { ICONS } from 'shared/components/icon';

import { Popout } from 'view/components/popout';

import { RecordPreviewImage } from 'components/record-preview/record-preview-image';
import { RecordPreviewRecordType } from 'components/record-preview/record-preview-record-type';
import { RecordPreviewHeading } from 'components/record-preview/record-preview-heading';
import { RecordPreviewSubheading } from 'components/record-preview/record-preview-subheading';
import { RecordPreviewContentContainer } from 'components/record-preview/record-preview-content-container';
import { RecordPreviewLoadingSpinner } from 'components/record-preview/record-preview-loading-spinner';
import { RecordPreviewAddNoteAction } from 'components/record-preview/actions/record-preview-add-note-action';
import { RecordPreviewAddReminderAction } from 'components/record-preview/actions/record-preview-add-reminder-action';
import { RecordPreviewViewButton } from 'components/record-preview/record-preview-view-button';
import { RecordPreviewAddAppoinmentAction } from 'components/record-preview/actions/record-preview-add-appointment-action';
import { RecordPreviewContainer } from 'components/record-preview/record-preview-container';
import { RecordPreviewLatestNote } from 'components/record-preview/record-preview-latest-note';
import {
  RecordPreviewActionBar,
  RecordPreviewActionBarMoreMenu
} from 'components/record-preview/record-preview-action-bar';

import { usePropertyPreviewPopout } from './use-property-preview-popout';
import { useRegion } from 'hooks/use-region';

interface PropertyPreviewPopoutProps {
  id: Id;
}

export function PropertyPreviewPopoutCoreComponent({
  id
}: PropertyPreviewPopoutProps & { errorDialog: ErrorDialog }) {
  const region = useRegion();
  const {
    propertyImage,
    recordTypeHeading,
    isLoading,
    viewPath,
    streetAddress,
    suburb,
    hasReadRights,
    newNoteData,
    newReminderData,
    newAppointmentData,
    onSendEmailClick,
    onSendSMSClick
  } = usePropertyPreviewPopout({ id, region });

  return (
    <RecordPreviewContainer>
      <RecordPreviewContentContainer
        leftColumn={
          <>
            <RecordPreviewImage
              height={75}
              recordImage={propertyImage}
              placeHolderIcon={ICONS.PROPERTIES}
            />
            <RecordPreviewViewButton viewPath={viewPath}>
              View
            </RecordPreviewViewButton>
          </>
        }
        rightColumn={
          <>
            <RecordPreviewRecordType>
              {recordTypeHeading}
            </RecordPreviewRecordType>
            {isLoading ? (
              <RecordPreviewLoadingSpinner />
            ) : (
              <>
                <RecordPreviewHeading>{streetAddress}</RecordPreviewHeading>
                <RecordPreviewSubheading>{suburb}</RecordPreviewSubheading>
                <RecordPreviewLatestNote id={id} serviceName='Properties' />
              </>
            )}
          </>
        }
      />
      {/* TODO: Really need to reduce the number of conditions here to show this bar.
      https://app.shortcut.com/rexlabs/story/64802/record-popout-preview-clean-up-actions-so-less-conditions-to-render-the-action-bar */}
      <RecordPreviewActionBar>
        {hasReadRights && !isLoading && (
          <>
            {newNoteData && (
              <RecordPreviewAddNoteAction noteData={newNoteData} />
            )}
            {newReminderData && (
              <RecordPreviewAddReminderAction reminderData={newReminderData} />
            )}
            {newAppointmentData && (
              <RecordPreviewAddAppoinmentAction
                appointmentData={newAppointmentData}
              />
            )}
            <RecordPreviewActionBarMoreMenu
              items={[
                {
                  label: 'Send Email',
                  onClick: onSendEmailClick
                },
                {
                  label: 'Send SMS',
                  onClick: onSendSMSClick
                }
              ]}
            />
          </>
        )}
      </RecordPreviewActionBar>
    </RecordPreviewContainer>
  );
}

export const PropertyPreviewPopoutCore = compose<
  React.ComponentType<PropertyPreviewPopoutProps>
>(withErrorDialog)(PropertyPreviewPopoutCoreComponent);

export function PropertyPreviewPopout({
  id,
  children,
  stopPropagation
}: {
  id: Id;
  children: ReactNode;
  stopPropagation?: boolean;
}) {
  // HACK: current version of popout doesn't let us manage state and causes rerender
  // loop if we don't memoize.
  // https://app.shortcut.com/rexlabs/story/58232/popouts-the-vivid-popout-component-cannot-maintain-state-within-the-popout-content
  const PopoutContent = useMemo(
    () => () => <PropertyPreviewPopoutCore id={id} />,
    [id]
  );

  return (
    <Popout
      distance='11px'
      Content={PopoutContent}
      stopPropagation={stopPropagation}
    >
      {children}
    </Popout>
  );
}
