import React from 'react';

import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import { withModel } from '@rexlabs/model-generator';
import contractConditionsModel, {
  ContractConditionsModel
} from 'features/contract-conditions/data/contract-conditions';
import { ContractConditionsRecord } from './contract-conditions-record';
import ContractConditionsSettings from './contract-conditions-settings';

export interface ContractConditionsProps {
  contractConditions: ContractConditionsModel;
}

function ContractConditions({ contractConditions }: ContractConditionsProps) {
  const whereabouts = useWhereaboutsWithViewpath();

  if (!whereabouts.path?.includes('/admin/settings/contract_conditions'))
    return null;

  return whereabouts.hashQuery?.id ? (
    <ContractConditionsRecord contractConditions={contractConditions} />
  ) : (
    <ContractConditionsSettings contractConditions={contractConditions} />
  );
}

export default withModel(contractConditionsModel)(ContractConditions);
