import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import Tab from './tab';
import { COLORS, PADDINGS } from 'theme/index';
import _ from 'lodash';
import { ActionMenu } from 'view/components/action-menu';
import Button from '@rexlabs/button';
import { ICONS } from 'shared/components/icon';

const BAR_HEIGHT = '7px';
const Arrow = ICONS.ARROW_DOWN;

const defaultStyles = StyleSheet({
  container: {
    position: 'relative',
    overflow: 'hidden'
  },

  list: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0rem',
    alignItems: 'flex-start',
    ' > li': {
      height: '35px'
    }
  },

  indicator: {
    height: '0'
  },

  hideTabButton: {
    display: 'none'
  },

  noActionMenu: {
    '& > li:last-child': {
      marginRight: 0
    }
  },

  withBottomBar: {
    marginBottom: BAR_HEIGHT
  },

  withPadding: {
    paddingLeft: PADDINGS.M,
    paddingRight: PADDINGS.M
  },

  bottomBar: {
    borderBottom: '5px solid white',
    height: BAR_HEIGHT,
    position: 'absolute',
    backgroundColor: COLORS.DARK_GREY,
    width: '100%',
    zIndex: '5'
  },

  lightBar: {
    backgroundColor: '#b4b1a9'
  }
});

// TODO: do proper, just hacked in for now due to time contraints
const getActionButtonStyles = _.memoize(
  (isOpen, isActive) =>
    StyleSheet({
      container: {
        backgroundColor: isActive ? '#B3B2AC' : '#F4F4F1',
        border: '0 none',
        height: '35px',
        lineHeight: '16px',
        fontSize: '13px',
        fontWeight: 600,
        borderRadius: '2px 2px 0 0',
        transition: 'background 0.3s',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: PADDINGS.S,
        paddingRight: PADDINGS.S,
        position: 'relative',
        color: isActive ? '#FFFFFF' : '#B3B2AC'
      },

      containerDisabled: {
        pointerEvents: 'none'
      },

      content: {
        transition: 'color 0.3s',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: isActive ? '#FFFFFF' : '#B3B2AC',

        '> span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }),
  (isOpen, isActive) => `${isOpen}-${isActive}`
);

const lightTabItems = {
  container: {
    backgroundColor: '#f4f4f1 !important',
    color: '#b4b1a9 !important'
  },

  containerActive: {
    backgroundColor: '#b4b1a9 !important',
    color: `${COLORS.WHITE} !important`,
    '&:hover': {
      backgroundColor: '#9e9b93 !important'
    }
  }
};

@styled(defaultStyles)
@autobind
class TabBar extends PureComponent {
  constructor(props) {
    super(props);

    this._refs = {};
    this.state = {
      indicatorStyles: {}
    };
    this.throttle = null;

    this.combinedItemStyles = StyleSheet(
      _.merge({ ...(props.light && lightTabItems) }, props.tabItemStyles)
    );
  }

  componentDidMount() {
    this.setActiveTab(this.props.activeTab, this.props, false);
    window.addEventListener('resize', this.setActiveTabThrottled);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setActiveTabThrottled);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab !== this.props.activeTab) {
      this.setActiveTab(nextProps.activeTab, nextProps, true);
    }
  }

  setActiveTabThrottled() {
    clearTimeout(this.throttle);
    this.throttle = setTimeout(
      () => this.setActiveTab(this.props.activeTab),
      200
    );
  }

  setActiveTab(activeTab, props = this.props, setTransition = true) {
    const { styles: s } = props;

    this.setState({
      indicatorStyles: {
        ...s('tabBarIndicator').style,
        transform: `translateX(${
          _.get(this._refs, `${activeTab}.offsetLeft`) -
          _.get(this._refs, 'parent.offsetLeft')
        }px) scaleX(${
          _.get(this._refs, `${activeTab}.offsetWidth`) /
          _.get(this._refs, 'parent.offsetWidth')
        })`,
        ...(setTransition ? { transition: 'transform 150ms ease-out ' } : {})
      }
    });
  }

  setTabRef(ref, { name }) {
    this._refs[name] = ref;
  }

  getActionMenuItems(items) {
    let actionMenuItems = [];
    items.forEach((item) => {
      if (item.customTab) {
        if (!item.onClick) {
          item.onClick = (e) => {
            this.handleTabClick(e, item);
          };
        }
        actionMenuItems = actionMenuItems.concat([item]);
      }
    });
    return actionMenuItems;
  }

  handleTabClick(event, { name }) {
    const { onChange, items, activeTab } = this.props;
    if (onChange) {
      onChange({
        name,
        newIndex: items.findIndex((i) => i.name === name),
        oldIndex: items.findIndex((i) => i.name === activeTab)
      });
    }
  }

  render() {
    const {
      activeTab,
      items,
      withBottomBar,
      withPadding,
      light,
      nested,
      styles: s
    } = this.props;

    const actionMenuItems = this.getActionMenuItems(items);
    const hasActionMenu = actionMenuItems.length > 0;

    return (
      <div {...s('container', { withPadding, withBottomBar })}>
        <ul
          {...s('list', { noActionMenu: !hasActionMenu })}
          ref={(r) => (this._refs.parent = r)}
          role='tablist'
        >
          {items
            .filter((t) => !t.customTab)
            .map(({ name, label, isDisabled }, index) => (
              <Tab
                key={name}
                index={index}
                nested={nested}
                isDisabled={isDisabled}
                styles={this.combinedItemStyles}
                name={name}
                label={label}
                isActive={name === activeTab || (!activeTab && index === 0)}
                setRef={this.setTabRef}
                onClick={this.handleTabClick}
              />
            ))}
          {hasActionMenu && (
            <ActionMenu
              items={actionMenuItems}
              Button={({ isOpen }) => (
                <Button
                  type={'button'}
                  styles={getActionButtonStyles(
                    isOpen,
                    !!actionMenuItems.find((i) => i.name === activeTab)
                  )}
                >
                  <Arrow
                    style={{
                      transform: 'rotate(270deg)',
                      marginLeft: '-1px',
                      fill: 'currentColor',
                      position: 'absolute'
                    }}
                  />
                </Button>
              )}
              distance='14px'
              offset='0px'
              shouldAutoClose={true}
            />
          )}
        </ul>
        {withBottomBar && <div {...s('bottomBar', { lightBar: light })} />}
      </div>
    );
  }
}

export default TabBar;
