import React, { PureComponent } from 'react';
import types from 'prop-types';
import { styled } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { APP_MENU } from 'theme/legacy/components';

@styled(APP_MENU.RECORDS)
class Option extends PureComponent {
  static propTypes = {
    IconBox: types.array,
    OptionMeta: types.array.isRequired,
    OptionExtraMeta: types.array
  };

  render() {
    const {
      styles: s,
      label,
      isActive,
      IconBox,
      OptionMeta,
      OptionExtraMeta
    } = this.props;

    const canShowIconBox = IconBox && IconBox.filter(Boolean).length > 0;

    return (
      <Box
        {...s('container', { isActive })}
        flexDirection='row'
        flexWrap='no-wrap'
        justifyContent='space-between'
        title={label}
      >
        {canShowIconBox && (
          <Box justifyContent='center' alignItems='center' {...s('iconBox')}>
            {IconBox.filter(Boolean)}
          </Box>
        )}
        {!canShowIconBox && <Box {...s('noIconBox')} />}
        <Box
          flexDirection='column'
          flexWrap='no-wrap'
          flex={'1'}
          justifyContent={'center'}
          {...s('details')}
        >
          {OptionMeta.filter(Boolean)}
        </Box>
        {OptionExtraMeta && (
          <Box flexDirection='column' {...s('extraDetails')}>
            {OptionExtraMeta.filter(Boolean)}
          </Box>
        )}
      </Box>
    );
  }
}

export default Option;
