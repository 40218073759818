import { Generator } from 'shared/utils/models';

export interface FollowUpReminderDueDateValue {
  id: 'same_day' | 'next_day' | 'next_business_day' | 'custom';
  text: 'Same Day' | 'Next Day' | 'Next Business Day' | 'Custom Date';
}

const TYPE = 'followUpReminderDueDates';

export default new Generator(TYPE).createSystemListModel();
