import React, { useCallback, useRef } from 'react';
import { useStyles } from '@rexlabs/styling';
import Requires from 'view/containers/requires';
import { ICONS } from 'shared/components/icon';
import PaywallTooltip from './paywall-tooltip';
import Separator from './separator';
import InfoItem from './info-item';
import menuStyles from './styles';
import Box from '@rexlabs/box';
import { COLORS, PADDINGS } from 'theme';
import Tag from 'view/components/tag';

function SubMenuItem({ item, index, handleItemClick, checkUserHasPermission }) {
  const s = useStyles(menuStyles);
  const {
    id,
    name,
    icon: Icon,
    isSeparator,
    isInfo,
    heading,
    accessRights,
    addons,
    countries,
    envConfigs,
    paywall,
    withFlags,
    withoutFlags,
    isNew
  } = item;

  const menuItemRef = useRef();

  const onClick = useCallback(
    (e) => {
      handleItemClick(item, e);
    },
    [handleItemClick, item]
  );

  /**
   * onAuxClick allows for menu items to be opened in a new tab via the middle button on some mice.
   * The onAuxClick event is supported by most browsers, except ie and safari. Safari seems to work
   * with the onClick event using e.button === 1. BUT I think someone added the onMouseUp event
   * because there are browsers that don't support onAuxClick, which will not trigger an onClick for a
   * middle button click, but will trigger an onMouseUp.
   *
   * TODO: I believe this might be all completely unnecessary with the current state of supported
   *  browsers so would like to investigate and remove it if possible.
   *  https://app.clubhouse.io/rexlabs/story/58214/investigate-weather-the-onmouseup-callback-is-needed-for-submenuitems
   */
  const onAuxClick = useCallback(
    (e) => {
      if ('onauxclick' in menuItemRef.current && e.button === 1) {
        handleItemClick(item, e);
      }
    },
    [handleItemClick, item]
  );

  const onMouseUp = useCallback(
    (e) => {
      if ('onauxclick' in menuItemRef.current === false && e.button === 1) {
        handleItemClick(item, e);
      }
    },
    [handleItemClick, item]
  );

  if (isSeparator) {
    return <Separator index={index} />;
  }

  if (isInfo) {
    return <InfoItem item={item} index={index} />;
  }

  if (name) {
    return (
      <Requires
        key={`submenu-${id}-${index}`}
        accessRights={accessRights}
        countries={countries}
        addons={addons}
        envConfigs={envConfigs}
        withFlags={withFlags}
        withoutFlags={withoutFlags}
      >
        <li
          {...s('listItem', 'subListItem', { subListItemHeading: heading })}
          ref={menuItemRef}
          onClick={onClick}
          onMouseUp={onMouseUp}
          onAuxClick={onAuxClick}
        >
          <PaywallTooltip paywall={paywall && !checkUserHasPermission(paywall)}>
            {heading && Icon && (
              <span {...s('icon', 'subIcon')}>
                <Icon />
              </span>
            )}
            <span {...s('label', 'subLabel', { subLabelHeading: heading })}>
              <span {...s('subLabelText')}>{name}</span>
              {paywall && !checkUserHasPermission(paywall) && (
                <ICONS.SPARKLE {...s('sparkle')} />
              )}
            </span>
            {isNew && (
              <Box {...s('newTagSubItem')}>
                <Tag
                  roundedEdges
                  white
                  backgroundColor={COLORS.PRIMARY.BLUE}
                  color={COLORS.PRIMARY.WHITE}
                  noMargin
                >
                  New
                </Tag>
              </Box>
            )}
          </PaywallTooltip>
        </li>
      </Requires>
    );
  }

  return null;
}

export default React.memo(SubMenuItem);
