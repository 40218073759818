import Box from '@rexlabs/box';
import { Info } from 'components/_experiment/record-widget/info';
import React, { useMemo } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { TextButton } from 'view/components/button';
import ActionWidget from 'src/view/components/widgets/widget/action-widget';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { useDialog } from 'hooks/use-dialog';

const styles = StyleSheet({
  actions: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    paddingTop: 1,
    marginLeft: 3
  }
});

type HandoffStatus =
  | 'unlinked'
  | 'pending'
  | 'has_content'
  | 'in_progress'
  | 'linked';

function HandoffWidget({
  handoffStatus = 'unlinked'
}: {
  handoffStatus: HandoffStatus;
}) {
  const handoffToRex = useDialog('handoffRexPM');
  const manageLink = useDialog('manageLink');
  const s = useStyles(styles);

  const status = useMemo(() => {
    if (handoffStatus === 'unlinked')
      return {
        label: 'Not linked to Rex PM',
        nextAction: 'Handoff to Rex PM',
        onClick: handoffToRex.open
      };
    else if (handoffStatus === 'pending')
      return {
        label: 'Handoff request sent to Rex PM',
        nextAction: 'Resend handoff'
      };
    else if (handoffStatus === 'has_content' || handoffStatus === 'in_progress')
      return {
        label: 'Linked in Rex PM',
        nextAction: 'Manage handoff'
      };

    return {
      label: 'Linked in Rex PM',
      nextAction: 'Manage link',
      onClick: manageLink.open
    };
  }, [handoffStatus, manageLink]);

  return (
    <ActionWidget heading={'Rex PM'} icon={ICONS.REX_PM_LOGO}>
      <Info>{status.label}</Info>
      <Box {...s('actions')} spacing={6}>
        <TextButton
          sand
          padding={'0px'}
          height={'fit-content'}
          onClick={status.onClick}
        >
          {status.nextAction}
        </TextButton>
        {handoffStatus !== 'pending' && handoffStatus !== 'unlinked' && (
          <Box {...s('actions')}>
            <TextButton padding={'0px'} height={'fit-content'}>
              View in Rex PM
            </TextButton>
            <Icon
              type={ICONS.EXTERNAL_LINK}
              width={9}
              height={9}
              {...s('icon')}
            />
          </Box>
        )}
      </Box>
    </ActionWidget>
  );
}

export default HandoffWidget;
