import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  label: {
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'lowercase',
    fontWeight: 600,
    marginTop: '1px',
    marginRight: '12px',
    whiteSpace: 'nowrap',
    padding: '1px 0'
  },

  value: {
    fontSize: '14px',
    lineHeight: '20px',
    padding: '1px 0'
  }
});

export interface ValueProps {
  label: ReactNode;
  value: any;
  vertical?: boolean;
  emptyValue?: ReactNode;
}

export function Value({
  label,
  value,
  vertical,
  emptyValue = '—'
}: ValueProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box flexDirection={vertical ? 'column' : 'row'}>
      <span {...s('label')}>{label}</span>
      <span {...s('value')}>{value || value === 0 ? value : emptyValue}</span>
    </Box>
  );
}
