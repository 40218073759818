import { useEffect } from 'react';
import env from 'shared/utils/config';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

function FullviewScript() {
  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' ||
      !env.FULLVIEW_ID ||
      !hasFeatureFlags('fullview')
    ) {
      return;
    }

    const scriptId = 'fullview-script';
    let script = document.querySelector<HTMLScriptElement>(`#${scriptId}`);

    if (!script) {
      script = document.createElement('script');
      script.async = true;
      script.src = 'https://install.fullview.io';
      script.id = scriptId;
      script.dataset.org = env.FULLVIEW_ID;

      document.head.appendChild(script);
    }

    return () => {
      if (script && document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return null;
}

export { FullviewScript };
