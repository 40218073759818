import React, { useState, useEffect, useMemo, useCallback } from 'react';

import Box from '@rexlabs/box';
import { useModelActions } from '@rexlabs/model-generator';

import { embeddedAppsWithUrlModel } from 'data/models/entities/embedded-apps';
import Spinner from 'shared/components/spinner';
import { Iframe } from 'components/iframe/iframe';
import { useErrorDialog } from 'hooks/use-error-dialog';

type RecordType = 'listings' | 'contacts' | 'properties';

export interface EmbeddedAppFrameProps {
  /**
   * Array of embeddedApp ids.
   */
  ids: string[];
  /**
   * The record type for use with url tag replacement
   * NOTE: If you have `recordType`, you must also have `recordId` and vice versa.
   */
  recordType?: RecordType;
  /**
   * The id of the record to use for url tag replacement
   * NOTE: If you have `recordType`, you must also have `recordId` and vice versa.
   */
  recordId?: string | number;
  /**
   * Iframe `width` and `height`
   * Overrides the width and height of the embeddedApp container.
   */
  width?: string | number;
  height?: string | number;
}

export function EmbeddedAppFrame({
  ids,
  recordType,
  recordId,
  width = '100%',
  height = '100%'
}: EmbeddedAppFrameProps) {
  const errorDialog = useErrorDialog();
  const [embeddedApps, setEmbeddedApps] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(ids.length > 0);
  const { getMergedUrls } = useModelActions(embeddedAppsWithUrlModel);

  const hasIdAndType = !!(recordId && recordType);

  const matchRecordType = (embedApp) =>
    embedApp.record_types.find((r) => r.record_type.id === recordType);

  useEffect(() => {
    if (ids.length > 0 || hasIdAndType) {
      const fetchEmbeddedAppsWithMergedUrls = () =>
        getMergedUrls({ recordType, recordId, ids })
          .then((response) => {
            const appResult = response.data.result.filter(
              (app) => app.is_enabled
            );
            return hasIdAndType ? appResult.filter(matchRecordType) : appResult;
          })
          .then((embeddedApps) =>
            embeddedApps.map((embeddedApp) => ({
              ...embeddedApp,
              url: embeddedApp.merged_url
            }))
          );

      fetchEmbeddedAppsWithMergedUrls()
        .then(setEmbeddedApps)
        .then(() => setIsLoading(false))
        .catch(errorDialog.open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasIdAndType, ids]);

  const listener = (message) => {
    if (message.data === 'reloadRecord') {
      /*
        Note that the `window.location.reload()` call should stay as-is, 
        because regardless of where the component is rendered 
        it's the shell window that we want to reload.
      */
      window.location.reload();
    }
  };

  useEffect(() => {
    const listeningWindow = window.parent.Rex2FrameWindow || window;

    if (embeddedApps?.length > 0) {
      /* 
        The way Rex renders react components in `classic`: 
        The component is actually rendered in the shell app and then injected into the classic iframe DOM. 
        So when you reference window in your component, you're referencing the `shell` window, 
        but in this case it's the iframed `classic` window that will receive the postmessage event 
        because that's the embedded app's window.parent. So the listener is on the wrong window.
      */

      // This means that if the component is in classic it will use the classic window (`window.parent.Rex2FrameWindow`),
      // and if we ever use the component directly in shell then it will use the current `window`.
      // TODO: https://rexsoftware.atlassian.net/browse/RADI-5587
      listeningWindow.addEventListener('message', listener);
    }

    return () => {
      listeningWindow?.removeEventListener('message', listener);
    };
  }, [embeddedApps]);

  return isLoading ? (
    <Box
      flex={1}
      justifyContent={'center'}
      alignItems={'center'}
      width={width}
      height={height}
    >
      <Spinner large dark centered />
    </Box>
  ) : (
    embeddedApps.map((embeddedApp) => (
      <Iframe
        key={embeddedApp.id}
        width={embeddedApp.width || width}
        height={embeddedApp.height || height}
        src={embeddedApp.url}
      />
    ))
  );
}
