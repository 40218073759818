import invariant from 'invariant';

function normalizer(postcode) {
  if (postcode.label && postcode.value) {
    return postcode;
  }

  const id = postcode._id || postcode.id;
  const name =
    postcode._text || postcode.text || postcode._value || postcode.value;

  invariant(
    id && name,
    "Items must have either an '_id' or 'id' and '_text' or 'text'"
  );

  return {
    label: name,
    value: {
      id,
      name
    },
    type: 'postcode'
  };
}

export function normalizerNoId(postcode) {
  if (postcode.label && postcode.value) {
    return postcode;
  }

  const id = postcode._id || postcode.id;
  const name =
    postcode._text || postcode.text || postcode._value || postcode.value;

  return {
    label: name,
    value: {
      id: `${name}-postcode`,
      originalId: id,
      name
    },
    type: 'postcode'
  };
}

export default normalizer;
