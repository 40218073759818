/*
|-------------------------------------------------------------------------------
| We setup a singleton dispatcher, much like redux, but as a separate object
| because we mainly want to be passing around non-serializable data (functions)
| in Rex2 dialogs.
*/
import { Emitter } from 'utils/events';

export const dialogEmitter = new Emitter();
export const DIALOG_ACTIONS = {
  OPEN: 'dialogs/open',
  OPEN_CLASSIC: 'dialogs/openClassic',
  CLOSE: 'dialogs/close',
  CLOSE_ALL: 'dialogs/closeAll'
};
