import React, { PureComponent } from 'react';
import { PADDINGS, TEXTS, COLORS } from 'theme';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';
import Tick from 'assets/icons/tick_circle2.svg';
import { styled, StyleSheet } from '@rexlabs/styling';

@styled(
  StyleSheet({
    heading: {
      ...TEXTS.HEADERS.HEADING_2,
      paddingBottom: PADDINGS.XS
    },
    content: {
      ...TEXTS.CONTENT.DEFAULT_TEXT,
      paddingBottom: PADDINGS.XS
    },
    // icon: { color: 'green', paddingLeft: PADDINGS.XXS, width: '40px' },
    circle: {
      borderRadius: '50%',
      border: `2px solid ${COLORS.GREEN}`,
      overflow: 'hidden',
      width: '20px',
      height: '20px'
    }
  })
)
class SuccessDialog extends PureComponent {
  render() {
    const { styles: s, onSubmit, ...props } = this.props;

    return (
      <Dialog
        title='Upload Successful'
        top={Math.floor(window.innerHeight / 2) - 200}
        {...props}
        closeDialog={onSubmit}
      >
        <Box flexDirection='row'>
          <Box mr={PADDINGS.S}>
            <Tick />
          </Box>
          <Box>
            <h2 {...s('heading')}>
              Your PAF file has been successfully uploaded
            </h2>
            <p {...s('content')}>
              The file is now pending processing. You will receive an email when
              the file has been processed. This usually takes about a day.
            </p>
            <p {...s('content')}>
              Note: you can now close this tab or leave the page.
            </p>
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default SuccessDialog;
