import React, { ReactElement } from 'react';
import Box from '@rexlabs/box';

type BoxProps = React.ComponentProps<typeof Box>;

function RightBarWidgetContentContainer(props: BoxProps): ReactElement {
  return <Box flexDirection={'column'} {...props} />;
}

export default RightBarWidgetContentContainer;
