import React, { PureComponent } from 'react';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import { PADDINGS, TEXTS } from 'theme';
import { styled, StyleSheet } from '@rexlabs/styling';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';

function topDistance() {
  return Math.floor(window.innerHeight / 2) - 200;
}

const dialogStyles = StyleSheet({
  bodyText: {
    ...TEXTS.CONTENT.INPUT_TEXT_REGULAR,
    marginBottom: PADDINGS.M
  },
  cancelButton: {
    marginRight: PADDINGS.M,
    lineHeight: '34px'
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-stat'
  }
});

@styled(dialogStyles)
class SimpleDialog extends PureComponent {
  render() {
    const {
      styles: s,
      bodyText,
      cancelText,
      submitText,
      title,
      onCancel,
      onSubmit
    } = this.props;

    return (
      <Dialog title={title} top={topDistance()} closeDialog={onCancel}>
        <p {...s('bodyText')}>{bodyText}</p>
        <ButtonBar>
          <div {...s('container')}>
            <span {...s('cancelButton')} onClick={onCancel}>
              <TextButton>{cancelText}</TextButton>
            </span>
            <DefaultButton onClick={onSubmit}>{submitText}</DefaultButton>
          </div>
        </ButtonBar>
      </Dialog>
    );
  }
}

@styled(dialogStyles)
class CancelUploadDialog extends PureComponent {
  render() {
    const { styles: s, onCancel, onSubmit } = this.props;

    return (
      <Dialog
        title={'Cancel Upload'}
        top={topDistance()}
        closeDialog={onCancel}
      >
        <p {...s('bodyText')}>
          Are you sure you would like to cancel this upload?
        </p>
        <ButtonBar>
          <div {...s('container')}>
            <span {...s('cancelButton')} onClick={onCancel}>
              <TextButton blue>No, continue this upload</TextButton>
            </span>
            <DefaultButton red onClick={onSubmit}>
              Yes, cancel this upload
            </DefaultButton>
          </div>
        </ButtonBar>
      </Dialog>
    );
  }
}

@styled(dialogStyles)
class UploadNewFileDialog extends PureComponent {
  render() {
    const { styles: s, onCancel, onSubmit } = this.props;

    return (
      <Dialog
        title={'Upload a New File'}
        top={topDistance()}
        closeDialog={onCancel}
      >
        <Box>
          <p {...s('bodyText')}>
            Are you sure you would like to upload a new file? Uploading a new
            file will abort the upload in progress.
          </p>
          <ButtonBar>
            <div {...s('container')}>
              <span {...s('cancelButton')} onClick={onCancel}>
                <TextButton blue>No, continue this upload</TextButton>
              </span>
              <DefaultButton red onClick={onSubmit}>
                Yes, cancel this upload
              </DefaultButton>
            </div>
          </ButtonBar>
        </Box>
      </Dialog>
    );
  }
}

const ReplaceFileDialog = (props) => (
  <SimpleDialog
    {...props}
    title={'Replacing File'}
    cancelText={'Cancel'}
    submitText={'Continue'}
    bodyText={
      'Uploading a new PAF file will replace the currently uploaded file. Are you sure you would like to continue?'
    }
  />
);

const RollbackDialog = (props) => (
  <SimpleDialog
    {...props}
    title={'Roll Back PAF'}
    cancelText={'Cancel'}
    submitText={'Roll Back'}
    bodyText={
      'Are you sure you want to roll back this file? The current file will be stored in history and the file in history will become the current file.'
    }
  />
);

export {
  CancelUploadDialog,
  UploadNewFileDialog,
  ReplaceFileDialog,
  RollbackDialog
};
