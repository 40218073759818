import { CustomReportingScreen } from '../screens/custom-reporting-screen';

export const reportingRoutes = {
  REPORTING: {
    config: ({ hasFlags }) => ({
      path: '/report/:moduleName',
      enabled: hasFlags('custom_reporting'),
      Component: CustomReportingScreen
    })
  }
};
