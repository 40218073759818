import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const actionCreators = {
  getAvailableMethods: {
    request: () => api.post('Telephony::getAvailableMethods', {}),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  makePhoneCall: {
    request: ({ methodId, contactId, contactPhoneId }) =>
      api.post('Telephony::makePhoneCall', {
        method_id: methodId,
        contact_id: contactId,
        contact_phone_id: contactPhoneId
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('telephony').createModel({ actionCreators });
