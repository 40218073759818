import { push, useWhereabouts } from '@rexlabs/whereabouts';
import { useCallback, useMemo } from 'react';

export function useViewstate() {
  const whereabouts = useWhereabouts();
  const id = whereabouts.hashQuery?.viewstate;

  const criteria = useMemo(() => {
    if (id) {
      return [
        { name: 'viewstate_id', type: '=', value: id, isViewstate: true }
      ];
    }
    return [];
  }, [id]);

  const setViewstate = useCallback(
    (id?: string | number) => {
      push({
        config: {
          ...whereabouts,
          // eslint-disable-next-line
          // @ts-ignore
          hash: undefined,
          hashQuery: {
            ...whereabouts.hashQuery,
            // eslint-disable-next-line
            // @ts-ignore
            viewstate: id
          }
        }
      });
    },
    [whereabouts]
  );

  return { id, criteria, setViewstate };
}
