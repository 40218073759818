// TODO: Rather than re-exporting using named imports and exports, it would be
// better to use a barrel-style re-export, like this. However, in webpack 2.7.0
// that is effecting the error reported in this issue:
//
// https://github.com/webpack/webpack/issues/3973
//
// export * from './currency';
// export * from './numbers';
// export * from './units';

import { formatNumber } from './currency';
import { addOrdinalSuffix } from './numbers';
import { formatPhone, stripPhone } from './phone';
import {
  convertFeetToInches,
  convertInchesToFeet,
  convertInchesToMeters,
  convertMetersToInches,
  renderDimensions,
  METER_FEET_CONVERSION
} from './units';

export {
  formatNumber,
  addOrdinalSuffix,
  formatPhone,
  stripPhone,
  convertFeetToInches,
  convertInchesToFeet,
  convertInchesToMeters,
  convertMetersToInches,
  renderDimensions,
  METER_FEET_CONVERSION
};
