import Box from '@rexlabs/box';
import { RecordListTable } from 'components/record-list-screen/table';
import { ColumnConfig } from 'components/record-list-screen/types';
import { Body } from 'components/text/body';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useMemo
} from 'react';
import TierCell from './table-cells/tier-cell';
import PercentageCell from './table-cells/percentage-cell';
import { HorizontalDivider } from 'components/horizontal-divider';
import { ICONS } from 'shared/components/icon';
import { IconButton } from 'src/view/components/button';
import RemoveCell from './table-cells/remove-cell';
import { SlidingScaleTier } from './data/types';
import { uniqueId } from 'lodash';

interface TieredComponentProps {
  tiers: SlidingScaleTier[];
  setTiers: Dispatch<SetStateAction<SlidingScaleTier[]>>;
}

const TieredComponent = ({ tiers, setTiers }: TieredComponentProps) => {
  const visibleTiers = tiers.filter((tier) => !tier._destroy);

  const onRemoveTier = (data: SlidingScaleTier) => {
    const indexToBeRemoved = tiers.findIndex(
      (tier) =>
        tier.id === data.id || (tier.tempId === data.tempId && data.tempId)
    );

    const nextTier = tiers[indexToBeRemoved + 1];

    if (!nextTier) {
      setTiers((prev) =>
        prev.filter(
          (tier) =>
            tier.id !== data.id || (data.tempId && tier.tempId !== data.tempId)
        )
      );
      return;
    }

    setTiers((prev) => {
      const adjustedRange = prev.map((tier, index) => {
        if (indexToBeRemoved === index)
          return {
            ...tier,
            _destroy: true
          };
        if (indexToBeRemoved + 1 !== index) return tier;
        return {
          ...tier,
          start_of_range: tiers[index - 1].start_of_range
        };
      });
      return adjustedRange;
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setTiers((prev) => {
      return prev.map((prevTier, prevIndex) => {
        if (prevIndex === index)
          return {
            ...prevTier,
            [e.target.name]: e.target.value
          };

        // Auto pre-fill next start of range
        if (
          e.target.name === 'end_of_range' &&
          index + 1 === prevIndex &&
          prev[index + 1]
        ) {
          return {
            ...prevTier,
            start_of_range: +e.target.value
          };
        }

        return prevTier;
      });
    });
  };

  const columns: ColumnConfig<SlidingScaleTier>[] = useMemo(
    () => [
      {
        id: 'tier',
        label: 'Tier',
        Cell: TierCell as FC,
        cellProps: {
          prefix: 'related.admin_comm_agent_sliding_scale_tiers',
          itemsLength: visibleTiers.length,
          onChange
        },
        forced: true,
        width: 230
      },
      {
        id: 'listing_amount',
        label: 'Listing Amount',
        Cell: PercentageCell as FC,
        cellProps: {
          prefix: 'related.admin_comm_agent_sliding_scale_tiers',
          name: 'listing_amount_percentage',
          onChange
        },
        forced: true
      },
      {
        id: 'selling_amount',
        label: 'Selling Amount',
        Cell: PercentageCell as FC,
        cellProps: {
          prefix: 'related.admin_comm_agent_sliding_scale_tiers',
          name: 'selling_amount_percentage',
          onChange
        },
        forced: true
      },
      {
        id: 'listing_selling_amount',
        label: 'Listing/Selling Amount',
        Cell: PercentageCell as FC,
        cellProps: {
          prefix: 'related.admin_comm_agent_sliding_scale_tiers',
          name: 'listing_selling_amount_percentage',
          style: {
            paddingRight: 0
          },
          onChange
        },
        forced: true
      },
      {
        id: 'remove',
        label: '',
        Cell: RemoveCell as FC,
        cellProps: {
          onRemove: onRemoveTier,
          style: { padding: 0 }
        },
        forced: true,
        width: 35
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleTiers]
  );

  const addTier = () => {
    const lastTier = tiers[tiers.length - 1];
    setTiers((prev) => [
      ...prev,
      {
        tempId: uniqueId('temp'),
        start_of_range:
          lastTier && lastTier.end_of_range !== null
            ? +lastTier.end_of_range + 1
            : 0,
        end_of_range: 0,
        listing_amount_percentage: 0,
        selling_amount_percentage: 0,
        listing_selling_amount_percentage: 0
      }
    ]);
  };

  return (
    <Box>
      <Body semibold dark large style={{ marginBottom: 10 }}>
        Tiered Component
      </Body>
      <RecordListTable
        items={visibleTiers}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        hasSelection={false}
        LoadingView={() => null}
        EmptyView={() => null}
        variant={'compact'}
      />
      <HorizontalDivider />
      <IconButton
        lightGrey
        Icon={ICONS.ADD}
        iconStyles={{ width: 16, height: 16 }}
        onClick={addTier}
      >
        Add tier
      </IconButton>
    </Box>
  );
};

export default TieredComponent;
