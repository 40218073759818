import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { api } from 'shared/utils/api-client';
import { usePersistedState } from 'shared/hooks/use-persisted-state';
import { ICONS } from 'shared/components/icon';
import Spinner from 'shared/components/spinner';
import { DefaultButton, IconButton } from 'view/components/button';
import { HeadingWithButtons } from 'components/_experiment/record-block/heading';
import { StreamItem } from 'components/_experiment/stream-item/item';

import { FilterPopout } from './popouts/filter';

const SortAscSvg: any = ICONS.OTHER;
const SortDescSvg: any = ICONS.OTHER;

const supportsIntersectionObserver = 'IntersectionObserver' in window;

const defaultStyles = StyleSheet({
  loading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px'
  }
});

export function ActivityStream({ serviceName, id }) {
  const s = useStyles(defaultStyles);

  const [inViewport, setInViewport] = useState(!supportsIntersectionObserver);

  const [isLoading, setLoading] = useState(false);
  const [pinned, setPinned] = useState([]);
  const [items, setItems] = useState([]);

  const [direction, setDirection] = usePersistedState(
    '@rex/activitySream/direction',
    'normal'
  );

  useLayoutEffect(() => {
    if (inViewport) {
      setLoading(true);
      setPinned([]);
      setItems([]);

      api
        .post('BatchRequests::execute', {
          requests: {
            PinnedStream: {
              method: 'RecordStream::getPinnedRecords',
              args: {
                service_name: serviceName,
                record_id: id,
                options: null
              }
            },
            RecordStream: {
              method: 'RecordStream::getRecordStream',
              args: {
                service_name: serviceName,
                record_id: id,
                direction,
                last_post: null,
                options: null
              }
            }
          }
        })
        .then(({ data }) => {
          setPinned(data?.result?.PinnedStream || []);
          setItems(data?.result?.RecordStream || []);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [direction, id, inViewport, serviceName]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const target = containerRef.current;
    if (!target || !supportsIntersectionObserver) {
      return;
    }

    function handleIntersection(entries) {
      if (entries?.[0]?.isIntersecting) {
        setInViewport(true);
      }
    }

    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(target);
    return () => observer.unobserve(target);
  }, []);

  return (
    <Box ref={containerRef}>
      <HeadingWithButtons
        Buttons={() => (
          <>
            <Box flex={1}>
              <IconButton
                red
                circle
                Icon={ICONS.ADD_MEDIUM}
                // eslint-disable-next-line
                onClick={() => alert('Add note')}
              >
                add a note
              </IconButton>
            </Box>
            <FilterPopout serviceName={serviceName} />
            <DefaultButton
              padding={false}
              onClick={() =>
                setDirection((direction) =>
                  direction === 'normal' ? 'reversed' : 'normal'
                )
              }
            >
              {direction === 'normal' ? <SortAscSvg /> : <SortDescSvg />}
            </DefaultButton>
          </>
        )}
      >
        Stream
      </HeadingWithButtons>

      {/* Don't render anything until the stream is actually in the users viewport,
          which will help deferring the API calls to when they are actually needed */}
      {!inViewport ? (
        <div style={{ height: 10 }} />
      ) : (
        <Box flexDirection='column' mt='15px' sy='12px'>
          {!isLoading && !pinned.length && !items.length && (
            <Box {...s('empty')}>No items found</Box>
          )}

          {pinned.map((item: any) => (
            <StreamItem key={item.id} data={item} pinned />
          ))}

          {items.map((item: any) => (
            <StreamItem key={item.id} data={item} />
          ))}

          {isLoading && (
            <Box {...s('loading')}>
              <Spinner small dark />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
