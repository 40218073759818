import {
  PipelineStageData,
  PipelineTypeId
} from 'features/pipelines/data/admin-pipeline-stages';

export const groupByColumns = (
  pipelineTypeId: PipelineTypeId,
  fetchedColumns: PipelineStageData[] | null
) => [
  {
    id: 'unqualified',
    pipeline_type_id: pipelineTypeId,
    percentage: 0,
    color: '#000000',
    name: 'Unqualified'
  },
  {
    id: '0-30',
    pipeline_type_id: pipelineTypeId,
    percentage: 80,
    color: '#000000',
    name: '0-30 days'
  },
  {
    id: '31-60',
    pipeline_type_id: pipelineTypeId,
    percentage: 60,
    color: '#000000',
    name: '31-60 days'
  },
  {
    id: '61-90',
    pipeline_type_id: pipelineTypeId,
    percentage: 40,
    color: '#000000',
    name: '61-90 days'
  },
  {
    id: '91+',
    pipeline_type_id: pipelineTypeId,
    percentage: 20,
    color: '#000000',
    name: '91+ days'
  },
  {
    ...(fetchedColumns?.find(
      (c) => c?.system_purpose?.id === 'lost'
    ) as PipelineStageData)
  },
  {
    ...(fetchedColumns?.find(
      (c) => c?.system_purpose?.id === 'won'
    ) as PipelineStageData)
  }
];
