/* eslint-disable max-lines */
import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'theme';
import { InputLabel } from 'components/text/input-label';
import { Heading } from 'components/text/heading';
import { Body } from 'components/text/body';
import PaddingBox from 'view/components/padding-box';
import { DefaultButton } from 'view/components/button';
import IconButton from 'view/components/button/icon';
import { ICONS } from 'shared/components/icon';
import { Form } from 'view/components/form';
import { Grid, Column } from 'shared/components/grid';
import FormField from 'view/components/form/field';
import { TextInput } from '@rexlabs/text-input';
import { Select } from 'view/components/input/select';
import { FieldArray, ReactForms } from '@rexlabs/form';
import KeyValueInput from 'view/components/input/repeating-fields/key-value-input';
import { withModel } from '@rexlabs/model-generator';
import ThirdPartyServiceClickToDial from 'data/models/custom/third-party-service-click-to-dial';
import _ from 'lodash';
import RexTable from 'shell/src/view/components/table';
import { withDialog } from 'shared/hocs/with-dialog';
import AddUserInputDialog from 'view/dialogs/click-to-dial/add-user-input';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import HiddenField from 'view/components/form/hidden-field';
import Spinner from 'shared/components/spinner';
import ArrowDropdown from 'view/components/action-menu/arrow-dropdown';
import MakeCall from 'view/dialogs/click-to-dial/make-call';
import { connect } from 'react-redux';
import ConfirmationDialog from 'view/dialogs/confirmation';
import { withWhereabouts } from '@rexlabs/whereabouts';

// Overwriting negative margin on Grid component container so we can avoid
// backgroundColor protrusion issues when using Grid as the outer container
// https://user-images.githubusercontent.com/11394076/56219067-70387b00-60a9-11e9-8762-639a5bf3fe18.png
const overrides = {
  Grid: StyleSheet({
    container: {
      margin: '0 !important',
      width: 'auto'
    }
  }),

  Column: StyleSheet({
    container: {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0'
    },

    hidden: {
      display: 'none !important'
    }
  }),

  Table: StyleSheet({
    th: {
      ' > div': {
        maxHeight: '24px'
      }
    },
    tbody: {
      '> div:nth-child(2n)': {
        backgroundColor: '#f4f4f1'
      }
    }
  })
};

const defaultStyles = StyleSheet({
  container: {
    padding: `${PADDINGS.XL} 0`
  },
  providerHeader: {
    backgroundColor: COLORS.GREY_MEDIUM,
    height: '90px',
    width: '100%',
    backgroundSize: '33%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  verticalPaddingBox: {
    padding: `${PADDINGS.M} 0`
  },
  lightBackground: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&:hover': {
      '& > div': {
        opacity: 1,
        transition: '0.15s'
      }
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  dropDown: {
    opacity: 0
  },
  headerKeyValueLabel: {
    width: `calc(50% - ${PADDINGS.M})`
  },
  headingWithoutPaddingBox: {
    paddingTop: '40px'
  },
  connectionNameContainer: {
    backgroundColor: '#343A40',
    opacity: '0.56',
    width: 'fit-content'
  },
  connectionName: {
    paddingLeft: PADDINGS.XS,
    paddingRight: PADDINGS.XS,
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: '600',
    opacity: '1',
    color: COLORS.PRIMARY.WHITE,
    fontStyle: 'normal'
  },

  addHeaderButton: {
    padding: '10px 0px 0px 0px'
  }
});

@withDialog(ConfirmationDialog, { propName: 'confirmation' })
@connect((state) => ({ session: state.session }))
@withWhereabouts
@withErrorDialog
@withDialog(MakeCall, { propName: 'makeCall' })
@withDialog(AddUserInputDialog, { propName: 'addUserInput' })
@withModel(ThirdPartyServiceClickToDial)
@styled(defaultStyles)
@autobind
class ClickToDial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialSettings: {},
      isLoading: true,
      saveBannerIsSubmitting: false
    };

    this.userInputTableColumns = [
      {
        label: 'user input',
        type: 'text',
        dataKey: 'userInput',
        align: 'left',
        width: '50%'
      },
      {
        label: 'merge tag',
        type: 'text',
        dataKey: 'mergeTag',
        align: 'left',
        width: '50%'
      }
    ];
    this.connectionId = _.get(props, 'whereabouts.hashQuery.connection_id');
    this.integration = _.get(props, 'session.third_party_extensions', []).find(
      (integration) => {
        return integration.id === this.connectionId;
      }
    );
    this.connectionName = _.get(this.integration, 'connection_name');
    this.connectionBrand = _.get(this.integration, 'definition.brand');
    this.userInputTempIdCounter = 0; // Required because the userInput's don't have an id until sent and received from the BE

    // Screen's form instance variables
    this.hasBoundSaveCancelFunctions = false;
    this.resetForm = _.noop;
    this.setFieldValue = _.noop;
    this.values = {};
    this.formIsDirty = false;
    this.userInputTableKey = 0; // Required because the table is not a form field and doesn't render with correct values after the form is reset
    this.resetFields = _.noop;
  }

  testCall() {
    this.props.makeCall.open({
      isTest: true,
      userInputs: this.values.user_input.map((input) => {
        return {
          name: input.id,
          label: input.label,
          inputProps: {
            type: input.protected ? 'password' : 'text'
          }
        };
      }),
      settings: this.formatHeadersForApi(this.values)
    });
  }

  editUserInput(input) {
    this.props.addUserInput.open({
      userInput: input.label,
      protected: input.protected,
      onAdd: ({ userInput: label, isProtected }) => {
        this.setFieldValue(
          'user_input',
          this.values.user_input.map((userInput) => {
            if (userInput.id === input.id) {
              return {
                label: label,
                protected: isProtected,
                tag: `{{${label.toLowerCase().replace(/ /g, '_')}}}`,
                id: input.id
              };
            }
            return userInput;
          })
        );
      }
    });
  }

  deleteUserInput({ tag }) {
    const newUserInputs = this.values.user_input.filter((userInput) => {
      return userInput.tag !== tag;
    });
    this.setFieldValue('user_input', newUserInputs);
  }

  renderUserInputActionMenu(input) {
    const { screenBodyPath, confirmation } = this.props;

    return (
      <Box>
        <ArrowDropdown
          small
          items={[
            { id: 0, label: 'Edit', onClick: () => this.editUserInput(input) },
            {
              id: 1,
              label: 'Delete',
              onClick: () =>
                confirmation.open({
                  onConfirm: () => this.deleteUserInput(input),
                  confirmText: 'Delete Anyway',
                  buttonColor: 'dark',
                  title: 'Warning',
                  message:
                    'The User Input you wish to delete is currently in use. Configuration errors may occur for account users if this User Input is deleted.'
                })
            }
          ]}
          // screenBodyPath is required because the body needs to be accessed from Shell, not passed from Classic
          // due to a restriction in ReactDOM.createPortal. Can be omitted during Shell use
          // More info: https://rexsoftware.slack.com/archives/C04G30JKX/p1555462972103800?thread_ts=1555456857.092100&cid=C04G30JKX
          target={_.get(window, screenBodyPath)}
        />
      </Box>
    );
  }

  renderUserInputLabel(input) {
    const { styles: s } = this.props;

    return (
      <div {...s('labelContainer')}>
        <div {...s('label')}>{input.label}</div>
        <div {...s('dropDown')}>{this.renderUserInputActionMenu(input)}</div>
      </div>
    );
  }

  mapUserInputsToTableData(values) {
    const data = _.get(values, 'user_input', []);

    return data.map((input) => {
      const { id, tag } = input;

      return {
        key: id,
        userInput: this.renderUserInputLabel(input),
        mergeTag: tag
      };
    });
  }

  getInitialValues() {
    const { initialSettings } = this.state;

    return {
      request_method: initialSettings.request_method,
      request_url: initialSettings.request_url,
      request_body: initialSettings.request_body,
      user_input: initialSettings.user_input || []
    };
  }

  getInitialFields() {
    const { initialSettings } = this.state;

    const headers = _.get(initialSettings, 'request_headers');

    // Adds an initial empty (prompting) request header
    if (!headers || headers.length === 0) {
      return [
        {
          initialValue: {
            headerKey: '',
            headerValue: ''
          }
        }
      ];
    }

    let frontendFormattedHeaders = [];
    for (const key in headers) {
      frontendFormattedHeaders = frontendFormattedHeaders.concat([
        { initialValue: { headerKey: key, headerValue: headers[key] } }
      ]);
    }

    return frontendFormattedHeaders;
  }

  bindSaveCancelBannerFunctions(submitForm, resetForm) {
    const { bindSaveCancelClick } = this.props;

    bindSaveCancelClick({
      save: () => {
        submitForm();
      },
      cancel: () => {
        resetForm();
        this.resetFields();
      }
    });
    this.hasBoundSaveCancelFunctions = true;
  }

  formatHeadersForApi(values) {
    const apiFormattedRequestHeaders = {};
    _.get(values, 'request_headers', []).forEach((header) => {
      // Doesn't include the empty (prompting) request header input
      if (header.headerKey.length !== 0 || header.headerValue.length !== 0) {
        apiFormattedRequestHeaders[header.headerKey] = header.headerValue;
      }
    });

    return {
      ...values,
      request_headers: apiFormattedRequestHeaders
    };
  }

  handleSubmit(values) {
    const {
      thirdPartyServiceClickToDial,

      showHeaderEditOverlaySpinner
    } = this.props;
    const apiFormattedValues = this.formatHeadersForApi(values);
    this.setState({ saveBannerIsSubmitting: true });

    return thirdPartyServiceClickToDial
      .setSettings({
        connectionId: this.connectionId,
        settings: apiFormattedValues
      })
      .then(({ data }) => {
        const result = _.get(data, 'result');
        let frontendFormattedHeaders = [];

        for (const key in result.request_headers) {
          frontendFormattedHeaders = frontendFormattedHeaders.concat([
            { headerKey: key, headerValue: result.request_headers[key] }
          ]);
        }

        // Adding back the empty (prompting) request header input if no headers were sent
        if (frontendFormattedHeaders.length === 0) {
          frontendFormattedHeaders = frontendFormattedHeaders.concat([
            { headerKey: '', headerValue: '' }
          ]);
        }

        this.resetFields(frontendFormattedHeaders);
        this.resetForm({
          ...result,
          request_headers: frontendFormattedHeaders
        });
        this.userInputTableKey++;
        this.userInputTempIdCounter = 0;
      })
      .catch((e) => {
        this.props.errorDialog.open(e);
        this.setState({ saveBannerIsSubmitting: false });
        showHeaderEditOverlaySpinner(false);
      });
  }

  renderRequestHeaderFieldArray({
    fields,
    getFieldProps,
    push,
    remove,
    resetFields,
    isDirty
  }) {
    const {
      styles: s,
      showHeaderEditOverlay,
      showHeaderEditOverlaySpinner,
      confirmation
    } = this.props;
    const { saveBannerIsSubmitting } = this.state;
    this.resetFields = resetFields;

    if (saveBannerIsSubmitting && !(isDirty || this.formIsDirty)) {
      this.setState({ saveBannerIsSubmitting: false });
      showHeaderEditOverlaySpinner(false);
    }

    showHeaderEditOverlay(isDirty || this.formIsDirty);
    showHeaderEditOverlaySpinner(saveBannerIsSubmitting);

    return (
      <Fragment>
        {fields.length > 0 && (
          <Box flexDirection={'row'} alignItems={'flex-end'}>
            <InputLabel
              {...s('headerKeyValueLabel')}
              style={{ marginRight: '10px' }}
              formField
            >
              header key
            </InputLabel>
            <InputLabel {...s('headerKeyValueLabel')} formField>
              header value
            </InputLabel>
          </Box>
        )}
        {fields.map((field, index) => {
          const { key, ...rest } = getFieldProps(field, index);

          return (
            <Box key={key} flexDirection={'row'} {...s('fieldContainer')}>
              <Box width={'100%'}>
                <FormField {...rest} />
              </Box>
              <IconButton
                Icon={ICONS.REMOVE}
                circle
                red
                type='button'
                onClick={() => {
                  confirmation.open({
                    onConfirm: () => remove(index),
                    confirmText: 'Delete Anyway',
                    buttonColor: 'dark',
                    title: 'Warning',
                    message:
                      'The Header you wish to delete is currently in use. Configuration errors may occur for account users if this Header is deleted.'
                  });
                }}
                style={{
                  padding: '0px',
                  height: '25px',
                  marginTop: 'calc(37px / 2)'
                }}
              />
            </Box>
          );
        })}
        <IconButton
          {...s('addHeaderButton')}
          lightGrey
          Icon={ICONS.ADD_MEDIUM_THICK}
          type='button'
          onClick={() => {
            push({
              initialValue: {
                headerKey: '',
                headerValue: ''
              }
            });
          }}
        >
          add header
        </IconButton>
      </Fragment>
    );
  }

  workerRemoved = false;
  removeErrors() {
    // TODO: Remove 'Rex2FrameWindow's when moving to screen to Shell
    if (!this.workerRemoved) {
      // NOTE: 'request_body' will be the element's ID because that's what is being set to by ReactForms below
      const aceEditorElement =
        window.Rex2FrameWindow.document.getElementById('request_body');
      if (aceEditorElement && window.Rex2FrameWindow.ace) {
        // NOTE: when AceEditor is initialised, the window it has access to will be given the `ace`
        // property which can be used to edit the AceEditor's session, settings and behaviour
        window.Rex2FrameWindow.ace
          .edit(aceEditorElement)
          .getSession()
          .setUseWorker(false);
        this.workerRemoved = true;
      }
    }
  }

  componentDidMount() {
    const { thirdPartyServiceClickToDial } = this.props;
    thirdPartyServiceClickToDial
      .getSettings({ connectionId: this.connectionId })
      .then(({ data }) => {
        const result = _.get(data, 'result');
        this.setState({ initialSettings: result, isLoading: false });
      });

    this.removeErrors();
  }

  componentDidUpdate() {
    this.removeErrors();
  }

  render() {
    const { styles: s, addUserInput } = this.props;
    const { isLoading } = this.state;

    return (
      <StylesProvider components={overrides} prioritiseParentStyles={false}>
        <Box {...s('container')}>
          <Box
            {...s('providerHeader')}
            style={{
              backgroundImage: `url(${_.get(
                this.connectionBrand,
                'strip.artwork'
              )}`,
              backgroundColor: _.get(this.connectionBrand, 'strip.color')
            }}
          >
            <Box {...s('connectionNameContainer')}>
              <Box {...s('connectionName')}>{this.connectionName}</Box>
            </Box>
          </Box>
          {isLoading ? (
            <Spinner small />
          ) : (
            <ReactForms
              handleSubmit={this.handleSubmit}
              initialValues={this.getInitialValues()}
            >
              {({
                submitForm,
                isSubmitting,
                values,
                isDirty,
                resetForm,
                setFieldValue
              }) => {
                this.setFieldValue = setFieldValue;
                this.values = values;
                this.resetForm = resetForm;
                this.formIsDirty = isDirty;
                this.formIsSubmitting = isSubmitting;

                const userInputTableConfig = {
                  columns: this.userInputTableColumns,
                  data: this.mapUserInputsToTableData(values)
                };
                if (!this.hasBoundSaveCancelFunctions && this.resetFields) {
                  this.bindSaveCancelBannerFunctions(submitForm, resetForm);
                }
                return (
                  <Form style={{ display: 'inline' }}>
                    <Grid>
                      <Box {...s('verticalPaddingBox')}>
                        <PaddingBox>
                          <Body informative>
                            Configuration settings for each IP Phone Provider
                            are different. In many cases a high level of
                            technical proficiency is required to configure an IP
                            Phone Provider integration. We recommend contacting
                            your IP Phone Provider for assistance, as Rex cannot
                            provide support for this.
                          </Body>
                        </PaddingBox>
                        <PaddingBox>
                          <Box
                            flexDirection={'row'}
                            alignItems={'center'}
                            mt='20px'
                          >
                            <Heading level={2}>User Inputs</Heading>
                            <IconButton
                              Icon={ICONS.ADD_MEDIUM}
                              circle
                              red
                              onClick={() =>
                                addUserInput.open({
                                  onAdd: ({ userInput, isProtected }) => {
                                    setFieldValue('user_input', [
                                      ...values.user_input,
                                      {
                                        label: userInput,
                                        protected: isProtected,
                                        tag: `{{${userInput
                                          .toLowerCase()
                                          .replace(/ /g, '_')}}}`,
                                        // NOTE: Backend ignores ids that don't already exist and
                                        // overwrites them with a backend generated id,
                                        // so this is just to help FE with editing the correct userInput
                                        id: `tempId-${this.userInputTempIdCounter}`
                                      }
                                    ]);
                                    this.userInputTempIdCounter++;
                                  }
                                })
                              }
                            >
                              add user input
                            </IconButton>
                          </Box>
                          <Body informative>
                            Each user can provide their credentials by accessing
                            the
                            {'"'}Integrations{'"'} section in their Profile.
                            These inputs can then be merged into headers or the
                            request body. Most IP Phones require Username,
                            Password and Handset ID credentials, but we
                            recommend checking with your provider if further
                            inputs are required.
                          </Body>
                        </PaddingBox>
                        <PaddingBox>
                          <Column width={9}>
                            <HiddenField name='user_input' />
                            <RexTable
                              key={this.userInputTableKey}
                              tableData={userInputTableConfig}
                            />
                          </Column>
                        </PaddingBox>
                      </Box>
                      <Box {...s('verticalPaddingBox', 'lightBackground')}>
                        <PaddingBox>
                          <Heading
                            style={{ paddingBottom: PADDINGS.XS }}
                            level={2}
                          >
                            HTTP Request Details
                          </Heading>
                          <Body informative>
                            First, set the Request Method and Request URL. Then
                            complete the Headers section. Multiple Headers can
                            be added to the HTTP Request. The Request Method,
                            Request URL, and at least one Header is required for
                            a connection between Rex and an IP Phone provider.
                            Some IP Phone connections require body text. We
                            recommend checking with your IP Phone provider what
                            the required request details are for your
                            connection.
                          </Body>
                          <br />
                          <Body informative>
                            The user inputs defined above can be added as merge
                            tags to the Request URL, Header Key, Header Value,
                            and Body. The merge tag {'{{phone_number}}'} can
                            also be added.
                          </Body>
                          <Column width={9}>
                            <FormField
                              name='request_method'
                              label='request method'
                              Input={Select}
                              inputProps={{
                                options: [
                                  { label: 'POST', value: 'POST' },
                                  { label: 'GET', value: 'GET' },
                                  { label: 'PUT', value: 'PUT' }
                                ]
                              }}
                            />
                            <FormField
                              name='request_url'
                              label='request URL'
                              Input={TextInput}
                            />
                            <Heading
                              {...s('headingWithoutPaddingBox')}
                              level={2}
                            >
                              Headers
                            </Heading>
                            <FieldArray
                              name={'request_headers'}
                              Input={KeyValueInput}
                              initialFields={this.getInitialFields(values)}
                              useIsDirtyWrapper={true}
                            >
                              {this.renderRequestHeaderFieldArray}
                            </FieldArray>
                            <Heading
                              {...s('headingWithoutPaddingBox')}
                              level={2}
                            >
                              Body
                            </Heading>
                            <FormField
                              name='request_body'
                              label='body text'
                              // TEMP HACK: AceEditor Needs to be initialised in the frame it's going to be used in...
                              Input={_.get(
                                window,
                                'top.Rex2FrameWindow.r3.components.complex.AceEditor'
                              )}
                              inputProps={{
                                mode: 'javascript'
                              }}
                            />
                          </Column>
                        </PaddingBox>
                      </Box>
                    </Grid>
                  </Form>
                );
              }}
            </ReactForms>
          )}

          <Box {...s('verticalPaddingBox')}>
            <PaddingBox>
              <Heading style={{ paddingBottom: PADDINGS.XS }} level={2}>
                Test Integration
              </Heading>
              <Body style={{ paddingBottom: PADDINGS.M }} informative>
                Test that the connection is working by triggering a phone call
                to your IP Phone using the details provided above.
              </Body>
              <DefaultButton blue onClick={() => this.testCall()}>
                Test Integration
              </DefaultButton>
            </PaddingBox>
          </Box>
        </Box>
      </StylesProvider>
    );
  }
}

export default ClickToDial;
