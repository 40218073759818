import React from 'react';
import Box from '@rexlabs/box';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, FONT } from 'theme';

import { RedirectLink } from 'view/components/external-link-redirect/redirect-link-text';
import { RedirectContent } from 'view/components/external-link-redirect/redirect-content';

const defaultStyles = StyleSheet({
  boldText: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  }
});
export interface FirstLoginContentProps {
  closeOverlay?: () => void;
}

export function FirstLoginContent({ closeOverlay }: FirstLoginContentProps) {
  const s = useStyles(defaultStyles);
  return (
    <>
      <RedirectContent
        heading='Please check your emails'
        bodyText={
          <>
            You will receive an email from{' '}
            <span {...s('boldText')}>Coassemble</span> to create your Rex
            Training account
          </>
        }
      ></RedirectContent>

      <Box mt={PADDINGS.XL}>
        <RedirectLink onClick={closeOverlay}>Return to Rex</RedirectLink>
      </Box>
    </>
  );
}
