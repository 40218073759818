import React, { useMemo } from 'react';
import { Field, FieldProps } from '@rexlabs/form';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';

interface FormFieldProps extends FieldProps {
  vertical?: boolean;
}

export function FormField({ vertical, ...props }: FormFieldProps) {
  const overrides = useMemo(
    () => ({
      // Override the field styles to support horizontal alignment
      // as well as ellisis on the labels
      InnerField: StyleSheet({
        field: {
          display: 'flex',
          flexDirection: vertical ? 'column' : 'row'
        },

        fieldLabel: {
          width: vertical ? '100%' : '27%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'lowercase',
          margin: 0,
          padding: '2px 3px 2px 0',
          lineHeight: '20px',

          '& + *': vertical
            ? {}
            : {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                marginTop: '-1px'
              }
        }
      }),

      // Override input styles
      TextInput: StyleSheet({
        wrapper: {
          minHeight: '28px'
        },

        container: {
          '&&': {
            padding: 0,
            borderRadius: 0
          }
        },

        cosmeticWrapper: {
          '&&': {
            padding: 0,
            minHeight: 0
          }
        },

        cosmetic: {
          '&&': {
            paddingLeft: 0,
            overflow: 'hidden'
          }
        },

        input: {
          '&&': {
            padding: '3px 0px 3px 5px'
          }
        }
      }),

      SelectOption: StyleSheet({
        container: {
          padding: '3px 0px 3px 5px'
        }
      }),

      FileUploadInput: StyleSheet({
        container: {
          '&&': {
            height: '28px',

            '> div p': {
              lineHeight: '17px',
              fontSize: '14px',
              fontWeight: 400
            },

            '& button': {
              height: '28px',
              borderRadius: 0
            }
          }
        },

        noFileLabel: {
          '&&': {
            paddingLeft: '5px'
          }
        }
      }),

      InputTag: StyleSheet({
        item: {
          '&&': {
            height: '18px',
            margin: '1px 2px 2px'
          }
        },

        multi: {
          maxWidth: 'initial'
        },

        label: {
          display: 'flex',
          alignItems: 'center'
        },

        icon: {
          height: '18px',
          width: '18px'
        },

        close: {
          pointerEvents: 'all'
        }
      })
    }),
    [vertical]
  );

  return (
    <StylesProvider components={overrides} prioritiseParentStyles={false}>
      <Field {...props} />
    </StylesProvider>
  );
}
