import React, { PureComponent } from 'react';
import { getDisplayName } from 'shared/utils/react';
import DialogsBridge from 'data/classic-bridges/dialogs-classic';
import invariant from 'invariant';

/**
 * Higher order component that allows to handle a classic dialog in the shell
 * DialogOverlayStack (via iframe), using the callName as identifier.
 * @param callName - identifier of the dialog, will be used to map system name etc.
 * @returns {WithClassicDialog}
 */
const withClassicDialog = (callName) => (WrappedComponent) => {
  // Make sure the callName is valid!
  invariant(
    DialogsBridge[callName],
    `Couldn't find any meta data for the dialog with the callName "${callName}"! ` +
      'Make sure you add proper data mapping to "shell/src/utils/dialogs/mapping-classic.js".'
  );

  class WithClassicDialog extends PureComponent {
    constructor() {
      super();

      this.dialogProps = {
        [callName]: {
          open: DialogsBridge[callName].openClassic,
          close: DialogsBridge[callName].close
        }
      };
    }

    render() {
      return <WrappedComponent {...this.props} {...this.dialogProps} />;
    }
  }

  const name = getDisplayName(WrappedComponent);
  WithClassicDialog.displayName = `WithClassicDialog:${callName}(${name})`;

  return WithClassicDialog;
};

export default withClassicDialog;
