import React, { ChangeEvent } from 'react';
import { Column, Grid } from 'shared/components/grid';
import { MaximumPayableCommissionFormula } from 'features/commission-worksheet/components/max-payable-commission-formula';
import { Body } from 'components/text/body';
import PercentageSwitchInput from 'components/inputs/percentage-switch/input';
import { formatCurrency } from 'utils/formatters';
import { useRegion } from 'hooks/use-region';
import { AgentAllocation } from 'features/commission-worksheet/types/commission-worksheet';

interface AgentFixedCommissionsProps {
  worksheetVersionId: string;
  agentAllocation: AgentAllocation;
  distributableCommissions: number;
  availableCommission: number;
  onChange: (name: string, value: unknown, silent?: boolean) => void;
}

export const AgentFixedCommissions = ({
  worksheetVersionId,
  agentAllocation,
  distributableCommissions,
  availableCommission,
  onChange
}: AgentFixedCommissionsProps) => {
  const currencySymbol = useRegion().financial.currency.symbol;
  if (worksheetVersionId === '3') return null;

  return (
    <Grid columns={12}>
      <Column width={5.9} spacing={4}>
        <MaximumPayableCommissionFormula
          currencySymbol={currencySymbol}
          distributableCommissions={distributableCommissions}
          agentAllocation={agentAllocation}
          availableCommission={availableCommission}
        />
      </Column>
      <Column width={3.7}>
        <Body dark small semibold>
          amount to agent
        </Body>
        <PercentageSwitchInput
          amountUnit={agentAllocation.net_comm_amount_unit}
          setAmountUnit={(amountUnit) =>
            onChange('net_comm_amount_unit', amountUnit)
          }
          value={agentAllocation.net_comm_amount?.toString() ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange('net_comm_amount', e.target.value)
          }
        />
      </Column>
      <Column width={2.4} alignItems='flex-end' spacing={4}>
        <Body dark small semibold>
          {currencySymbol} amount
        </Body>
        <Body dark semibold>
          {currencySymbol}
          {formatCurrency(
            agentAllocation.net_comm_amount_unit.id === 'exact_amount'
              ? agentAllocation.net_comm_amount
              : availableCommission *
                  Number(agentAllocation.net_comm_amount / 100),
            2
          )}
        </Body>
      </Column>
    </Grid>
  );
};
