import _ from 'lodash';
import React from 'react';
import { TooltipStateful } from './vivid-tooltip';
import { DialogTooltipConsumer } from 'view/context/tooltip';
import { withPopoutContext } from 'view/context/popout';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';

const overrides = {
  Arrow: StyleSheet({
    arrow: {
      pointerEvents: 'none',
      '&:before': {
        boxShadow: '0px 0px 21px rgba(0, 0, 0, .3)',
        width: '10px',
        height: '10px',
        background: 'rgba(0, 0, 0, .9)'
      }
    }
  })
};

const Tooltip = ({ context, ...props }) => {
  return (
    <StylesProvider components={overrides} prioritiseParentStyles={false}>
      <DialogTooltipConsumer>
        {(value) => (
          <TooltipStateful
            target={_.get(value, 'target.current') || context}
            {...props}
            hoverTimeout={250}
          />
        )}
      </DialogTooltipConsumer>
    </StylesProvider>
  );
};

export default withPopoutContext(Tooltip);
