import React from 'react';
import Text, { BaseTextProps } from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface HintProps extends BaseTextProps {
  semibold?: boolean;
  bold?: boolean;
  dark?: boolean;
  normal?: boolean;
  emptyState?: boolean;
  light?: boolean;
}

export function Hint({
  semibold,
  bold,
  dark,
  normal,
  emptyState,
  light,
  ...props
}: HintProps) {
  return (
    <Text
      is='p'
      {...props}
      type={map({
        HINT: true,
        EMPTY_STATE_HINT: emptyState,
        SEMIBOLD: semibold,
        BOLD: bold,
        DARK: dark,
        NORMAL: normal,
        LIGHT: light
      })}
    />
  );
}
