import React from 'react';

import { query, useModelActions } from '@rexlabs/model-generator';

import { withDialog } from 'shared/hocs/with-dialog';

import campaignsModel from 'data/models/entities/campaigns';

import EditCampaign from 'view/dialogs/campaigns/edit';

import { RecordListScreen } from 'components/record-list-screen';

import { campaignsListActions } from '../data/record-list-actions';
import { campaignColumns } from '../data/record-list-columns';
import { campaignFilters } from '../data/record-list-filters';

const campaignsQuery = query`{
  ${campaignsModel} {
    name
    type {
      text
    }
    status {
      text
    }
    start_date
    end_date
    id
  }
}`;

export const CampaignsListScreen = withDialog(EditCampaign, {
  propName: 'editCampaign'
})(function CampaignsListScreen({ editCampaign, ...props }) {
  const { refreshLists } = useModelActions(campaignsModel);

  return (
    <RecordListScreen
      {...props}
      query={campaignsQuery}
      columns={campaignColumns}
      filtersConfig={campaignFilters}
      serviceName='Campaigns'
      actions={campaignsListActions}
      onAdd={() => editCampaign.open({ callback: () => refreshLists() })}
    />
  );
});
