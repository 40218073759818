import dayjs from 'dayjs';

export function getDateLabel({
  range,
  suffix
}: {
  range?: { start: Date; end: Date };
  suffix?: string;
}) {
  if (!range) {
    return;
  }
  const isSameDay = dayjs(range.start).isSame(range.end);
  const isStartSameMonth = dayjs(range.start).isSame(range.end, 'month');
  const isStartSameYear = dayjs(range.start).isSame(range.end, 'year');
  const startFormat = `D${!isStartSameMonth ? ' MMM' : ''}${
    !isStartSameYear ? ' YYYY' : ''
  }`;

  const startString = !isSameDay
    ? `${dayjs(range.start).format(startFormat)} \u2013 `
    : '';

  const endString = `${dayjs(range.end).format('D MMM YYYY')}`;

  return `${startString}${endString}${suffix || ''}`;
}
