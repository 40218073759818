import React from 'react';

import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS } from 'theme';

import { DataStateContainer } from './data-state-container';

const defaultStyles = StyleSheet({
  emptyStateText: {
    color: COLORS.PRIMARY.INFORMATIVE,
    fontWeight: 600,
    fontSize: '17px'
  }
});

type SavedListEmptyStateProps = React.ComponentProps<typeof DataStateContainer>;

export function SavedListEmptyState(props: SavedListEmptyStateProps) {
  const { children, ...rest } = props;

  const s = useStyles(defaultStyles);

  return (
    <DataStateContainer {...rest}>
      <h2 {...s('emptyStateText')}>{children}</h2>
    </DataStateContainer>
  );
}
