import dayjs from 'dayjs';
import { get } from 'lodash';
import { ReportColumnConfig } from 'features/custom-reporting/modules/module-config-types';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';
import { durationComaparator } from '../helpers';

function formatLeadDurations(startTime: string, endTime: string) {
  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);

  // Using the below instead of dayjs's relativetime plugin because relative time
  // can't be customised without using a later version of dayjs
  // and by default it is quite inaccurate.
  // Use relative time once we've upgraded:
  // https://rexsoftware.atlassian.net/browse/RADI-6042
  // If it's exactly 60 min difference, that will be taken care of by
  // a 1 in the hour diff, same for hours.
  const minDiff = endDate.diff(startDate, 'minute') % 60;
  const hourDiff = endDate.diff(startDate, 'hour') % 24;
  const dayDiff = endDate.diff(startDate, 'day');
  // Dayjs diff returns an integer - so if there's less than 1 day
  // it'll return 0.
  const dayDiffText = dayDiff ? `${dayDiff}d` : '';
  const hourDiffText = hourDiff ? `${hourDiff}h` : '';
  const minDiffText = !dayDiff ? `${minDiff}min` : '';

  return [dayDiffText, hourDiffText, minDiffText].filter(Boolean).join(' ');
}

export const leadsFields: ReportColumnConfig = [
  {
    headerName: 'Lead ID',
    field: 'id'
  },
  {
    headerName: 'Lead type',
    type: 'valueListColumn',
    field: 'lead_type.text',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'lead_type' })
    }
  },
  {
    headerName: 'Assignee',
    type: 'agentValueListColumn',
    field: 'assignee.name',
    subresource: 'assignee'
  },
  {
    field: 'system_ctime',
    headerName: 'Created date',
    type: 'dateColumn'
  },
  {
    headerName: 'Source',
    type: 'valueListColumn',
    field: 'lead_source.text',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'enquiry_source' })
    }
  },
  {
    headerName: 'Lead status',
    type: 'valueListColumn',
    field: 'lead_status.text',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'lead_status' })
    }
  },
  {
    headerName: 'Last action',
    field: 'last_activity.outcomes.0.type.text'
  },
  {
    field: 'system_completed_time',
    headerName: 'Completed date',
    type: 'dateColumn'
  },
  {
    colId: 'time_to_first_action',
    headerName: 'Time to first action',
    valueGetter: ({ data, node, context, colDef }) => {
      const value = get(data, colDef?.field || '', data);

      const createdTime = value?.system_ctime;
      const firstAction = value?.activities?.[0]?.system_ctime;
      if (!createdTime || !firstAction) {
        return getEmptyValue({ node, context });
      }

      return formatLeadDurations(createdTime, firstAction);
    },
    comparator: (_val, _prevVal, valNode, prevValNode) =>
      durationComaparator({
        startRange: {
          startDate: valNode.data.system_ctime,
          endDate: valNode.data?.activities?.[0]?.system_ctime
        },
        endRange: {
          startDate: prevValNode.data?.system_ctime,
          endDate: prevValNode.data?.activities?.[0]?.system_ctime
        }
      })
  },
  {
    colId: 'time_to_completion',
    headerName: 'Time to completion',
    valueGetter: ({ data, node, context, colDef }) => {
      const value = get(data, colDef?.field || '', data);

      const createdTime = value?.system_ctime;
      const completedTime = value?.system_completed_time;

      if (!createdTime || !completedTime) {
        return getEmptyValue({ node, context });
      }
      return formatLeadDurations(createdTime, completedTime);
    },
    comparator: (_val, _prevVal, valNode, prevValNode) =>
      durationComaparator({
        startRange: {
          startDate: valNode.data.system_ctime,
          endDate: valNode.data?.system_completed_time
        },
        endRange: {
          startDate: prevValNode.data?.system_ctime,
          endDate: prevValNode.data?.system_completed_time
        }
      })
  }
];
