import React from 'react';

import DefaultValue from 'view/components/input/select/defaults/value';

import { ICONS } from 'shared/components/icon';

const {
  SELECT: {
    VALUE: { STREET: StreetIcon }
  }
} = ICONS;

function StreetValue(props) {
  return <DefaultValue {...props} Icon={StreetIcon} />;
}

export default StreetValue;
