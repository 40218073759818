import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS } from 'src/theme';
import { ICONS } from 'shared/components/icon';
import { withModel } from '@rexlabs/model-generator';
import announcementsModel from 'data/models/custom/announcements';
import { withDialog } from 'shared/hocs/with-dialog';
import AnnouncementDialog from 'view/dialogs/announcement';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    height: '42px',
    width: '100%',
    background: COLORS.WHITE,
    padding: '0 20px',
    fontSize: '14px',
    fontWeight: 400
  },

  containerAlert: {
    background: COLORS.PRIMARY.RED,
    color: COLORS.WHITE
  },

  containerNotice: {
    background: COLORS.PRIMARY.YELLOW_LIGHT
  },

  prefix: {
    fontWeight: 600,
    paddingRight: '4px'
  },

  readMore: {
    color: '#2aa2e3',
    fontWeight: 600,
    marginLeft: '4px',
    cursor: 'pointer'
  },

  readMoreAlert: {
    color: COLORS.WHITE
  },

  closeButton: {
    border: '0 none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  },

  closeButtonAlert: {
    color: COLORS.WHITE
  },

  closeIcon: {
    width: '12px',
    height: '12px'
  }
});

@withDialog(AnnouncementDialog)
@withModel(announcementsModel)
@styled(defaultStyles)
@autobind
class AnnouncementToast extends PureComponent {
  render() {
    const {
      styles: s,
      announcement,
      announcements,
      announcementDialog
    } = this.props;

    const level = _.get(announcement, 'display_notice_level');
    const prefixes = {
      warning: 'Warning',
      notice: 'Update'
    };

    return (
      <Box
        {...s('container', {
          containerAlert: level === 'warning',
          containerNotice: level === 'notice'
        })}
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box flexDirection='row' alignItems='center'>
          <b {...s('prefix')}>{prefixes[level] || 'News:'} </b>
          <span {...s('message')}>{announcement.subject}</span>
          {!!announcement.body && (
            <a
              href='#'
              onClick={() => announcementDialog.open({ announcement })}
              {...s('readMore', { readMoreAlert: level === 'warning' })}
            >
              read more
            </a>
          )}
        </Box>
        <button
          {...s('closeButton', { closeButtonAlert: level === 'warning' })}
          onClick={() => announcements.dismiss({ id: announcement.id })}
        >
          <ICONS.CLOSE {...s('closeIcon')} />
        </button>
      </Box>
    );
  }
}

export default AnnouncementToast;
