import React, { useCallback } from 'react';

import { DefaultButton } from 'view/components/button';

import { SplitButton } from 'components/button/split-button';

import { useReportState } from '../hooks/use-report-state';
import { useSavedReport } from '../hooks/use-async-report-data';
import { useReportPermissions } from '../hooks/use-report-permissions';
import { useUpdateCustomReport } from '../hooks/use-update-custom-report';
import { useAddCustomReportDialog } from '../hooks/use-add-custom-report-dialog';

export function SaveReportButton() {
  const { selectedSavedReport } = useSavedReport();

  const { reportDetailsToSave, isSavedReportStateModified } = useReportState();

  const { updateCustomReport } = useUpdateCustomReport();

  const { openAddCustomReportDialog } = useAddCustomReportDialog();

  const handleUpdateReport = useCallback(async () => {
    if (selectedSavedReport) {
      updateCustomReport({
        data: {
          id: selectedSavedReport.id,
          // our FE logic for managing libraries for this means we need make sure to
          // supply the library id otherwise the template will get removed from its library
          library: selectedSavedReport?.library?.id,
          report_details: reportDetailsToSave
        }
      });
    }
  }, [reportDetailsToSave, selectedSavedReport, updateCustomReport]);

  const { canEditReport } = useReportPermissions({
    customReport: selectedSavedReport
  });

  return !selectedSavedReport?.id ? (
    <DefaultButton onClick={openAddCustomReportDialog}>Save</DefaultButton>
  ) : isSavedReportStateModified && canEditReport ? (
    <SplitButton
      onClick={handleUpdateReport}
      items={[{ label: 'Save As', onClick: openAddCustomReportDialog }]}
    >
      Save
    </SplitButton>
  ) : (
    <DefaultButton onClick={openAddCustomReportDialog}>Save As</DefaultButton>
  );
}
