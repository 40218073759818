// Copied from Spoke
import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import FormField from './field';
import _ from 'lodash';
import { autobind } from 'core-decorators';

@autobind
class HiddenField extends PureComponent {
  onChange = _.noop;

  inputOnChange = _.noop;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.changeValue(nextProps);
    }
  }

  changeValue(props) {
    const e = {
      persist: _.noop,
      target: {
        id: props.name,
        name: props.name,
        type: 'hidden',
        value: props.value
      }
    };
    this.onChange(e);
    this.inputOnChange(e);
  }

  render() {
    const { name } = this.props;
    return (
      <Box style={{ display: 'none' }}>
        <FormField
          name={name}
          Input={({ value: v, onChange }) => {
            this.inputOnChange = onChange;
            this.onChange = this?.props?.onChange;
            return <input name={name} type='hidden' value={v} />;
          }}
          sendImmediate
        />
      </Box>
    );
  }
}

export default HiddenField;
