import React from 'react';

import { ListPropsType, ListControllerPropsType } from '../';

import ColumnHeader from './column-header';
import Row from './row';
import Column from './column';

function ClassicStyledColumnView(
  props: ListPropsType & ListControllerPropsType
) {
  const {
    onColumnClick,
    columns,
    options,
    rows,
    allChecked,
    onRowClick,
    rowClasses,
    tickedIds = [],
    checkAllClicked,
    isChecked,
    handleCheckboxUpdate,
    getIdFromRow
  } = props;

  return (
    <table className={options.fixedTable ? 'table-fixed' : ''}>
      <thead style={{ boxSizing: 'unset' }}>
        <tr>
          {options.useCheckbox && (
            <th className='checkbox'>
              <button
                className={`checkbox${allChecked ? ' checked' : ''}`}
                onClick={checkAllClicked}
                onTouchStart={checkAllClicked}
              >
                <span className='icon' />
              </button>
            </th>
          )}
          {columns.map((col) => (
            <ColumnHeader
              col={col}
              key={col.name}
              onColumnClick={onColumnClick}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <Row
            useCheckbox={options.useCheckbox}
            isChecked={isChecked(row, tickedIds, allChecked)}
            row={row}
            key={getIdFromRow(row)}
            onRowClick={onRowClick}
            className={rowClasses(row)}
            handleCheckboxUpdate={handleCheckboxUpdate}
          >
            {columns.map((col) => (
              <Column
                key={`${getIdFromRow(row)}-${col.name}`}
                col={col}
                row={row}
                listProps={props}
              />
            ))}
          </Row>
        ))}
      </tbody>
    </table>
  );
}

export default ClassicStyledColumnView;
