/* eslint-disable max-lines */
/*
 |-------------------------------------------------------------------------------
 | Menu Entities
 |-------------------------------------------------------------------------------
 |
 | The following component handles rendering the loading-in state, result options,
 | empty search and an invalid search.
 |
 | Actions:
 |  - renderAssistant
 |  - renderLoadingInStates
 |  - renderEntities
 |
 | Helpers:
 |  - getOptionProps
 |
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Types from 'prop-types';
import _ from 'lodash';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import { keyframes, styled, StyleSheet } from '@rexlabs/styling';
import AnimationGroup from '@rexlabs/css-animation-group';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';
import Icon, { ICONS } from 'shared/components/icon';
import Requires from 'view/containers/requires';
import { withModel } from '@rexlabs/model-generator';
import ui from 'data/models/custom/ui';
import { COLORS } from 'src/theme';
// import { withRegion } from 'src/hocs/with-region';

import { delayNavigation } from '../';
import { MENU_ITEM_HEIGHT } from '../constants';
import { matchesActiveRoute, getHref } from '../utils';
import MenuHeader from './menu-header';

const MAIN_MENU = 'MAIN';
const activeStateAnimationGroupProps = {
  enterAnimation: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),
  enterDuration: '300ms',
  enterTimingFunction: 'ease',
  leaveAnimation: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  }),
  leaveDuration: '300ms',
  leaveTimingFunction: 'ease'
};

const ENTITY_TYPE = {
  FIXED: 'fixedEntities',
  SCROLLABLE: 'scrollableEntities'
};

@withModel(ui)
@connect((state) => ({ session: state.session }))
@styled(
  StyleSheet({
    container: {
      height: 'inherit',
      width: 'inherit',
      backgroundColor: 'transparent',
      position: 'inherit',
      top: 0,
      left: 0,
      transform: 'translateZ(0)', // Fixed position items become relative to this transform
      paddingTop: '60px'
    },
    fixed: {
      position: 'fixed',
      width: 'inherit',
      bottom: 0,
      left: 0,
      borderTopWidth: '1px',
      borderTopStyle: 'solid'
    },
    scrollable: {
      overflowY: 'auto',
      height: '100%',
      width: 'inherit',
      msOverflowStyle: '-ms-autohiding-scrollbar',

      '::-webkit-scrollbar': {
        display: 'none'
      },

      '::-webkit-scrollbar-track': {
        display: 'none'
      },

      '::-webkit-scrollbar-thumb': {
        display: 'none'
      }
    },

    separator: {
      height: '1px',
      width: '100%',
      boxSizing: 'border-box',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      paddingTop: '5px',
      marginBottom: '5px'
    },

    genericLabelWrapper: {
      flex: 1,
      padding: '19px 0',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '22px',
      boxSizing: 'content-box',
      paddingRight: '35px',
      color: 'currentColor'
    },
    mobileHeadingLabelWrapper: {
      textTransform: 'uppercase'
    },
    countBadge: {
      minWidth: '20px',
      width: 'auto',
      height: '20px',
      color: 'white',
      fontWeight: '600',
      fontSize: '14px',
      borderRadius: '85px',
      padding: '0 6px',
      lineHeight: '17px',
      display: 'flex',
      alignItems: 'center'
    },
    countBadgeWrapper: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between'
    },
    activeStateBorder: {},
    entityContainer: {
      display: 'flex',
      alignItems: 'center',
      minHeight: `${MENU_ITEM_HEIGHT}px`,
      position: 'relative',

      '&:active': {
        backgroundColor: '#F9FAFB'
      }
    },
    headingEntityContainer: {
      color: '#A1A7B7'
    },
    iconWrapper: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `${MENU_ITEM_HEIGHT}px`,
      width: `${MENU_ITEM_HEIGHT}px`
    },
    iconSvg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',
      color: 'currentColor'
    },
    activeRoute: {},
    headingUnderline: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      height: '2px',
      width: 'calc(100% - 54px)',
      transform: 'translateX(-50%)'
    },
    scrollableShadow: {
      boxShadow: '0 3px 20px rgba(154, 164, 180, 0.2)',
      border: 'none'
    },
    externalLinkIcon: {
      '> svg': {
        color: `${COLORS.PRIMARY.BLUE} !important`,
        width: '1.2rem',
        heigth: '1.2rem'
      }
    }
  })
)
@autobind
class MenuEntities extends PureComponent {
  static propTypes = {
    viewMode: Types.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showBottomScrollShadow: false,
      showTopScrollShadow: false
    };

    this.debouncedHandleShadowRendering = _.throttle(
      this.handleShadowRendering,
      80
    );
  }

  get isScoped() {
    const { viewMode } = this.props;
    return viewMode !== MAIN_MENU;
  }

  get hasItems() {
    const { items } = this.props;
    return items.length > 0;
  }

  componentWillUnmount() {
    this[ENTITY_TYPE.SCROLLABLE] &&
      this[ENTITY_TYPE.SCROLLABLE].removeEventListener(
        'scroll',
        this.debouncedHandleShadowRendering
      );
  }

  handleViewModeChange(itemId) {
    return () => {
      const {
        ui,
        items,
        onClose,
        activeRoute,
        onClickParams,
        onViewModeChange,
        localState,
        session
      } = this.props;

      if (itemId === 'MAIN') return onViewModeChange('MAIN');

      let currItems = items;
      if (_.isFunction(currItems)) {
        currItems = currItems(localState);
      }

      const currentMenuOption = currItems.find((item) => item.id === itemId);

      let subMenu = _.get(currentMenuOption, 'subMenu', []);
      const subMenuComponent = _.get(
        currentMenuOption,
        'SubMenuComponent',
        null
      );

      if (_.isFunction(subMenu)) {
        subMenu = subMenu(localState);
      }

      if (subMenu.length > 0 || subMenuComponent) {
        onViewModeChange(itemId);
      } else {
        const { onClick, isExternalLink } = currentMenuOption;
        const href = getHref(currentMenuOption, session);

        if (onClick && _.isFunction(onClick)) {
          onClick({ ...onClickParams, ...{ origin: 'mobile' } }); // pass down params to the onClick in the menu config item
        }

        onClose(); // Close the nav

        if (isExternalLink) {
          return window.open(href, '_blank', 'noopener,noreferrer');
        }
        // The frame won't fire an update event so no point triggering loading early
        // This is only here to ensure the loading indicator loads smoothly before the route gets updated
        if (href !== activeRoute) {
          ui.loadingIndicatorOn({ message: 'navigating' });
        }

        if (href) {
          delayNavigation(() => push({ config: parseUrlToRoute(href) }));
        }
      }
    };
  }

  /**
   * Helper to render/de-render the top and bottom shadows of the menu item list. This helps clear up any
   * confusion a user may have when getting to the absolute top or bottom of the list. If we were to always
   * render the shadow it would indicate to the user that there are more menu items available.
   *
   * @param event
   */
  handleShadowRendering(event) {
    const currentScroll = event.target.scrollTop;
    const absoluteBottomScrollPosition =
      event.target.scrollHeight - event.target.offsetHeight;
    const disableTopShadow = currentScroll <= MENU_ITEM_HEIGHT / 2;
    const disableBottomShadow =
      currentScroll >= absoluteBottomScrollPosition - MENU_ITEM_HEIGHT / 2;

    this.setState({ showBottomScrollShadow: !disableBottomShadow });
    this.setState({ showTopScrollShadow: !disableTopShadow });
  }

  /**
   * Set ref for the element based on a particular key name.
   *
   * - Helps to set the scrollable height of the menu list
   * - Sets up the scroll event listener to deal with shadow rendering
   *
   * @param key
   * @returns {Function}
   */
  setRef(key) {
    return (element) => {
      if (!this[key]) {
        this[key] = element; // Store element on the component

        if (key === ENTITY_TYPE.FIXED) {
          // Update the height of the scrollable entity list as we can get the fixed height accurately now
          if (this[ENTITY_TYPE.SCROLLABLE]) {
            this[
              ENTITY_TYPE.SCROLLABLE
            ].style.height = `calc(100% - ${element.offsetHeight}px)`;
          }
        }

        if (key === ENTITY_TYPE.SCROLLABLE) {
          this[ENTITY_TYPE.SCROLLABLE].addEventListener(
            'scroll',
            this.debouncedHandleShadowRendering
          );
          this.setState({
            showBottomScrollShadow: element.scrollHeight > element.offsetHeight
          });
        }
      }
    };
  }

  mapLocalStateToMeta(item) {
    const { localState } = this.props;
    return (
      (item && item.meta && _.isFunction(item.meta) && item.meta(localState)) ||
      null
    );
  }

  mapMenuItems(item, index) {
    const { styles: s, activeRoute } = this.props;
    const {
      heading: isHeading,
      icon,
      id,
      name,
      countries,
      useSVGColours,
      isExternalLink
    } = item;

    const onClick = this.handleViewModeChange(id);
    const metaData = this.mapLocalStateToMeta(item);
    const count = _.get(metaData, 'count.value');
    const accessRights = _.get(item, 'accessRights', '');

    let countBadge = null;
    if (count) {
      countBadge = (
        <Box {...s.with('countBadge')({ backgroundColor: '#3AA6F1' })}>
          {count}
        </Box>
      );
    }

    const key = `${name}-${index}`;

    let activeBorder = null;
    if (matchesActiveRoute(activeRoute, item.match)) {
      activeBorder = <Box {...s('activeStateBorder')} />;
    }
    return (
      <Requires key={key} accessRights={accessRights} countries={countries}>
        <Box
          {...s('entityContainer', { headingEntityContainer: isHeading })}
          onClick={onClick}
        >
          {!!icon && (
            <Box
              {...s(
                'iconWrapper',
                { headingIconWrapper: isHeading },
                { activeRoute: !!activeBorder }
              )}
            >
              {activeBorder}
              <Icon
                type={icon}
                hasControlledColor={!useSVGColours}
                {...s('iconSvg')}
              />
            </Box>
          )}
          <Box
            {...s(
              'genericLabelWrapper',
              { mobileHeadingLabelWrapper: isHeading },
              { subMenuLabelWrapper: !icon },
              { activeRoute: !!activeBorder }
            )}
          >
            <span>{name}</span>
            {countBadge}
            {isExternalLink && (
              <Icon type={ICONS.EXTERNAL_LINK} {...s('externalLinkIcon')} />
            )}
          </Box>
          {isHeading && <Box {...s('headingUnderline')} />}
        </Box>
      </Requires>
    );
  }

  render() {
    const { showTopScrollShadow } = this.state;
    const { styles: s, scoped, headingLabel } = this.props;

    const menuHeaderProps = {
      isScoped: scoped,
      headingLabel: headingLabel,
      showTopScrollShadow,
      handleViewModeChange: this.handleViewModeChange
    };

    const scrollableChildren = this.renderEntities();
    const fixedChildren = this.renderEntities(true);

    return (
      <Box {...s('container')}>
        <MenuHeader {...menuHeaderProps} />
        {scrollableChildren}
        {fixedChildren}
      </Box>
    );
  }

  renderEntities(fixed = false) {
    const { showBottomScrollShadow } = this.state;
    const {
      styles: s,
      items,
      Component,
      localState,
      previousItems
    } = this.props;

    if (Component) return Component;

    const key = fixed ? ENTITY_TYPE.FIXED : ENTITY_TYPE.SCROLLABLE;
    let currentItems = this.hasItems ? items : previousItems;

    if (_.isFunction(currentItems)) {
      currentItems = currentItems(localState);
    }

    const filteredItems = currentItems.filter((item) =>
      fixed ? item.isFixedMobile : !item.isFixedMobile
    );
    const hasCurrentItems = filteredItems.length > 0;

    return hasCurrentItems ? (
      <div
        ref={this.setRef(key)}
        {...s(
          { scrollable: !fixed },
          { fixed: fixed },
          { scrollableShadow: showBottomScrollShadow && fixed }
        )}
      >
        {filteredItems.map((item, index) => {
          const { isSeparator, Component, props } = item;
          const name = _.get(item, 'name');
          const metaData = this.mapLocalStateToMeta(item);
          const metaName = _.get(metaData, 'name');
          const itemName = name || metaName;
          const accessRights = _.get(item, 'accessRights', '');

          if (isSeparator) {
            return (
              <Requires key={`separator-${index}`} accessRights={accessRights}>
                <Box {...s('separator')} />
              </Requires>
            );
          }

          if (Component) {
            return (
              <Requires
                key={`${itemName}-component-${index}`}
                accessRights={accessRights}
              >
                <Component
                  {...props(localState)}
                  onClick={this.handleViewModeChange(item.id)}
                />
              </Requires>
            );
          }

          return this.mapMenuItems({ ...item, name: itemName }, index);
        })}
      </div>
    ) : null;
  }
}

export default MenuEntities;
