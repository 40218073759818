import React from 'react';

import { query, useModelActions } from '@rexlabs/model-generator';
import { RecordListScreen } from 'components/record-list-screen';
import { useWhereaboutsWithViewpath } from 'hooks/use-whereabouts-with-viewpath';
import tenancyApplicationsModel from 'features/tenancy-applications/data/tenancy-applications';
import { useApplicationsColumns } from 'features/tenancy-applications/hooks/use-applications-columns';
import { useApplicationsActions } from 'features/tenancy-applications/hooks/use-applications-actions';
import { useApplicationsFilters } from 'features/tenancy-applications/hooks/use-applications-filters';
import { useDialog } from 'hooks/use-dialog';

const tenancyApplicationsQuery = query`{
  ${tenancyApplicationsModel} {
    id
  }
}`;

function ApplicationsListScreen(props) {
  const { refreshLists } = useModelActions(tenancyApplicationsModel);
  const editApplication = useDialog('addEditApplication');
  const columns = useApplicationsColumns();
  const actions = useApplicationsActions();
  const filtersConfig = useApplicationsFilters();

  const whereabouts = useWhereaboutsWithViewpath();

  const { path } = whereabouts;

  return (
    // HACK: So we really need to work out what is going on here...
    // I've had to add this path check as changing from one shell screen
    // to another, causes the list to load again, and a read request.
    // So looks like this page is refreshing during the transient state.
    // This extra check makes sure the path is what we're after. If not
    // it clears out the ui.
    // https://app.shortcut.com/rexlabs/story/67425/react-lists-investigate-why-list-views-performs-api-requests-when-changing-to-another-react-screen
    <>
      {path === '/tenancy-applications/' && (
        <>
          <RecordListScreen
            {...props}
            query={tenancyApplicationsQuery}
            columns={columns}
            filtersConfig={filtersConfig}
            title={'Tenancy Applications'}
            serviceName='TenancyApplications'
            actions={actions}
            onAdd={null}
            onItemClick={(data) => {
              editApplication.open({
                application: data,
                onApplicationChange: refreshLists
              });
            }}
          />
        </>
      )}
    </>
  );
}

export default ApplicationsListScreen;
