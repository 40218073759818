import { get } from 'lodash';
import { getAsyncFilterValues } from 'features/custom-reporting/utils/get-async-filter-values';

export const feedbackFields = [
  {
    headerName: 'Feedback ID',
    field: 'id'
  },
  {
    headerName: 'Agent',
    field: 'agent.name',
    type: 'agentValueListColumn',
    subresource: 'agent'
  },
  {
    headerName: 'Feedback date',
    field: 'date_of',
    type: 'feedbackDialogColumn'
  },
  {
    headerName: 'Feedback type',
    field: 'feedback_type.text',
    type: 'valueListColumn',
    filterParams: {
      values: getAsyncFilterValues({ listName: 'listing_feedback_type' })
    }
  },
  {
    headerName: 'Price indication',
    field: 'amount_of',
    type: 'currencyColumn'
  },
  {
    headerName: 'General feedback',
    field: 'note'
  },
  {
    field: 'interest_level',
    headerName: 'Interest level',
    type: 'capitalizedValueListColumn',
    // There is a value list for this, but it comes back capitalized when the
    // values from graphQL are lowercase. Changing the values in the filter
    // could break existing saved reports, so I'm hardcoding and keeping the values the same.
    filterParams: { values: ['hot', 'warm', 'cold', 'N/A'] }
  },
  {
    headerName: 'Approved for reporting',
    field: 'system_approval_status.id',
    valueGetter: ({ data, colDef }) => {
      const value = get(data, colDef?.field || '');
      return value === 'approved';
    },
    type: 'booleanColumn'
  },
  {
    headerName: 'Created date',
    field: 'system_ctime',
    type: 'localDateTimeColumn'
  },
  {
    headerName: 'Created by',
    field: 'system_created_user.name',
    subresource: 'system_created_user',
    type: 'agentValueListColumn'
  }
];
