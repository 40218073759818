import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import TableCellTooltip from '../table-cell-tooltip';

import { TemplateProps } from '../';

interface State {
  showTooltip: boolean;
}

// TODO: Update this to a functional component with hooks
//  https://app.clubhouse.io/rexlabs/story/53592/convert-react-classic-list-components-to-functional-components-with-hooks
class TemplateDefault extends React.Component<TemplateProps, State> {
  private tableCellRef = React.createRef<HTMLTableDataCellElement>();

  state = {
    showTooltip: false
  };

  updateOverflow = () => {
    if (this.props.col?.noOverflow) {
      const { offsetWidth = 0, scrollWidth = 0 } =
        this.tableCellRef.current || {};
      if (offsetWidth && scrollWidth) {
        const showTooltip = offsetWidth < scrollWidth;
        if (showTooltip !== this.state.showTooltip) {
          this.setState({ showTooltip });
        }
      }
    }
  };

  componentDidMount() {
    this.updateOverflow();
    if (this.props.col?.noOverflow) {
      window.addEventListener('resize', _.debounce(this.updateOverflow, 100));
    }
  }

  componentWillUnmount() {
    if (this.props.col?.noOverflow) {
      window.removeEventListener(
        'resize',
        _.debounce(this.updateOverflow, 100)
      );
    }
  }

  showActionMenu = (e) => {
    const { row, col } = this.props;
    col.openActionMenu?.call(row, col, e, row?.security_user_rights);
  };

  render() {
    const { row, col } = this.props;
    const { showTooltip } = this.state;
    const rightIcon = col.rightIcon ? col.rightIcon?.(row) : false;
    const rowValue = row[col.id] || '–';

    return (
      <TableCellTooltip showTooltip={showTooltip} content={rowValue}>
        <>
          <span
            ref={this.tableCellRef}
            className={classnames({
              'no-overflow': col.noOverflow,
              'has-right-icon': rightIcon
            })}
          >
            {rowValue}
          </span>
          {col.nameSuffix && col.nameSuffix(row) && (
            <span
              className='hspace-s'
              dangerouslySetInnerHTML={{
                __html: col.nameSuffix(row)
              }}
            />
          )}
          {rightIcon && (
            <div
              className={'right-icon big'}
              dangerouslySetInnerHTML={{
                __html: rightIcon
              }}
            />
          )}
          {col.actions && col.openActionMenu && (
            <button
              className='action-menu big'
              onMouseDown={this.showActionMenu}
              onTouchStart={this.showActionMenu}
            >
              <span className='icon' />
            </button>
          )}
        </>
      </TableCellTooltip>
    );
  }
}

export default TemplateDefault;
