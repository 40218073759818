class SaveCancelContext {
  static stack = [];

  /**
   * Adds a new save and cancel context to the stack so the overlay is able to call the
   * new context functions.
   *
   * @param {Object} context
   */
  static push(context) {
    SaveCancelContext.stack.push(context);
  }

  /**
   * Removes the last, added context, from the stack.
   */
  static pop() {
    SaveCancelContext.stack.pop();
  }

  /**
   * Empties the stack, no context are available.
   */
  static reset() {
    if (SaveCancelContext.stack.length) {
      SaveCancelContext.stack = [];
    }
  }
}

export default SaveCancelContext;
