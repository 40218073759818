import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'adminUserValidationTriggerMap';

const config = {
  valueLists: {
    api: {
      fetch: () => api.post('AdminUserValidationRules::getServiceTriggerMap')
    }
  }
};

export default new Generator(TYPE, config).createValueListModel();
