import React from 'react';
import Box from '@rexlabs/box';
import { RecordPreviewSubheading } from 'components/record-preview/record-preview-subheading';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS, PADDINGS } from 'theme';

function getIconColor({ phoneIsOnTPS, shouldOverrideTPS }) {
  if (phoneIsOnTPS && shouldOverrideTPS) {
    return COLORS.PRIMARY.SAND_LIGHT;
  }
  if (phoneIsOnTPS) {
    return COLORS.STATES.RED;
  }
}

export function ContactPreviewPhone({
  contactPhoneNumber,
  phoneIsOnTPS,
  shouldOverrideTPS
}: {
  contactPhoneNumber: string;
  phoneIsOnTPS: boolean;
  shouldOverrideTPS: boolean;
}) {
  const iconColor = getIconColor({ phoneIsOnTPS, shouldOverrideTPS });

  return (
    <Box flexDirection='row' sx={PADDINGS['3XS']}>
      <RecordPreviewSubheading testId='RecordPreview.PhoneNumber'>
        {contactPhoneNumber}
      </RecordPreviewSubheading>
      {phoneIsOnTPS && (
        <Icon type={ICONS.DONT_CALL} color={iconColor} width={21} />
      )}
    </Box>
  );
}
