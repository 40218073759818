import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import Spinner from 'shared/components/spinner';

const defaultStyles = StyleSheet({
  heading: {
    color: COLORS.DARKER_GREY,
    fontWeight: 700,
    fontSize: '32px',
    paddingBottom: PADDINGS.M
  },

  spinner: {
    marginLeft: '12px'
  }
});

export interface HeadingContentProps {
  children: ReactNode;
  loading?: boolean;
}

export function RedirectHeading({ loading, children }: HeadingContentProps) {
  const s = useStyles(defaultStyles);
  return (
    <Box flexDirection='row' alignItems='baseline'>
      <h1 {...s('heading')}>{children}</h1>
      {loading && <Spinner {...s('spinner')} dark extraSmall />}
    </Box>
  );
}
