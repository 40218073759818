import React from 'react';
import { RowNode } from 'ag-grid-community';
import { Link } from 'components/text/link';
import { useDialog } from 'hooks/use-dialog';
import { useReportState } from 'features/custom-reporting/hooks/use-report-state';
import { getEmptyValue } from 'features/custom-reporting/components/data-grid-column-types';

export function WorksheetIdColumn({
  value,
  node
}: {
  value?: string;
  node: RowNode | null;
}) {
  const { reloadReportData } = useReportState();
  const commissionWorksheetDialog = useDialog('viewCommissionWorksheet');

  return value ? (
    <Link
      blue
      undecorated
      record
      onClick={() =>
        commissionWorksheetDialog.open({
          id: value,
          callback: reloadReportData,
          options: { showListingAddress: false }
        })
      }
    >
      {value}
    </Link>
  ) : (
    getEmptyValue({ node })
  );
}
