import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';
import { Heading } from 'components/text/heading';
import { Body } from 'components/text/body';
import { DefaultButton } from 'view/components/button';
import { ButtonBar } from 'view/components/button-bar';

import { withQuery, query } from '@rexlabs/model-generator';
import Tag from 'view/components/tag';
import { Grid, Column } from 'shared/components/grid';
import _ from 'lodash';
import dayjs from 'dayjs';

import MetricRelatedDataList from 'view/components/lists/metrics/related-data';
import MetricUserAttributions from 'view/components/lists/metrics/user-attributions';

import metricsModel from 'data/models/entities/metrics';

const metricsQuery = query`{
  ${metricsModel} (id: ${(p) => p.id}) {
    id
    metric
    time
    recorded_by_user
    recorded_at
    user_attributions
    labels
    value
    write_rule
  }
}`;

@withQuery(metricsQuery)
class MetricDetailsDialog extends PureComponent {
  render() {
    const { onLoad, closeDialog, metrics } = this.props;

    const isLoading = _.get(metrics, 'item.status') === 'loading';
    const itemData = _.get(metrics, 'item.data');

    return (
      <Dialog
        onLoad={onLoad}
        closeDialog={closeDialog}
        title='Metric Details'
        isLoading={isLoading}
        height={450 + 40 * _.get(itemData, 'labels', []).length}
        width={400}
      >
        <Box height={40} flexDirection='row' alignItems='center'>
          <Heading level={2}>{_.get(itemData, 'metric')}</Heading>
          {!!_.get(itemData, 'is_reversal') && <Tag>Reversed</Tag>}
        </Box>
        <Grid>
          <Column width={6} mt={12}>
            <Heading level={3}>metric value</Heading>
            <Body dark>{_.get(itemData, 'value')}</Body>
          </Column>
          {/* <Column width={6} mt={12}>
            <Heading level={3}>metric rule</Heading>
            <Body dark>{_.get(itemData, 'write_rule.name', '-')}</Body>
          </Column> */}
          <Column width={6} mt={12}>
            <Heading level={3}>effective date</Heading>
            <Body dark>
              {dayjs(_.get(itemData, 'time') * 1000).format('DD MMM YYYY')}
            </Body>
          </Column>
          <Column width={6} mt={12}>
            <Heading level={3}>date written</Heading>
            <Body dark>
              {dayjs(_.get(itemData, 'recorded_at') * 1000).format(
                'DD MMM YYYY, h:mma'
              )}
            </Body>
          </Column>
          <Column width={6} mt={12}>
            <Heading level={3}>triggered by</Heading>
            <Body dark>{_.get(itemData, 'recorded_by_user.name')}</Body>
          </Column>
        </Grid>

        <Box mt={15}>
          <Box height={40} alignItems='center'>
            <Heading level={2}>Attributed Users</Heading>
          </Box>
          <MetricUserAttributions
            items={_.get(itemData, 'user_attributions', [])}
          />
        </Box>

        <Box mt={15}>
          <Box height={40} alignItems='center'>
            <Heading level={2}>Related Data</Heading>
          </Box>
          <MetricRelatedDataList
            items={_.get(itemData, 'labels', [])}
            invertable={false}
          />
        </Box>

        <ButtonBar>
          <DefaultButton light onClick={closeDialog}>
            Close
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default MetricDetailsDialog;
