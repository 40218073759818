import React from 'react';

import { useStyles, StyleSheet } from '@rexlabs/styling';
import { Id } from '@rexlabs/model-generator';

import { COLORS, PADDINGS } from 'theme';

import PaddingBox from 'view/components/padding-box';
import { LeadsResponse } from 'data/models/entities/leads';

import { LeadsDialogDetailsSection } from 'features/leads/components/leads-dialog-details-section';
import PermalinkPopout from 'view/components/popout/permalink-popout';

interface LeadsDialogContainerSectionProps {
  lead: LeadsResponse;
  editLead?: (event: React.MouseEvent<HTMLElement>) => any;
}

const defaultStyles = StyleSheet({
  container: {
    borderBottom: `2px solid ${COLORS.BACKGROUNDS.SAND}`
  }
});

// TODO - get the permalink working across accounts
// SC - https://app.shortcut.com/rexlabs/story/64120/permalink-add-account-id-to-leads-dialog-permalink
const getPermalink = ({ id }: { id: Id; accountId: Id }) =>
  `${window.location.origin}/leads/#id=${id}&lens=all`;

export function LeadsDialogDetailsContainer({
  lead,
  editLead
}: LeadsDialogContainerSectionProps) {
  const s = useStyles(defaultStyles);

  return (
    <PaddingBox
      flexDirection='row'
      justifyContent='space-between'
      pb={PADDINGS.S}
      {...s('container')}
    >
      <LeadsDialogDetailsSection data={lead} editLead={editLead} />

      <PermalinkPopout id={lead.id} getPermalink={getPermalink} />
    </PaddingBox>
  );
}
