import { useDialog } from 'hooks/use-dialog';
import { useModelActions, useModelState } from '@rexlabs/model-generator';
import sessionModel, { SessionModel } from 'data/models/custom/session';

export default function useShouldUpdateChildAccounts() {
  const updateChildAccountSetting = useDialog('updateChildAccountSetting');
  let { childAccountIds } = useModelState(sessionModel) as SessionModel;
  const { refresh } = useModelActions(sessionModel);

  return () => {
    return new Promise<{ result?: boolean; didCancel?: boolean }>((resolve) => {
      (async () => {
        if (!childAccountIds) {
          const { childAccountIds: childAccountIdsNew } = await refresh({});
          childAccountIds = childAccountIdsNew;
        }

        if (childAccountIds.length === 0) {
          resolve({ result: false });
          return;
        }

        updateChildAccountSetting.open({
          childAccountsCount: childAccountIds.length,
          onSubmit: (shouldUpdateChildAccounts) => {
            resolve({ result: shouldUpdateChildAccounts });
          },
          onCancel: () => {
            resolve({ didCancel: true });
          }
        });
      })();
    });
  };
}
